/*Componente tabela de clientes
/*Descrição: Exibe todos os clientes cadastrado em sistema
/*Desenvolvido por: Equipe Rafael Cezario
*/

//libraries
import React, { useEffect, useState } from "react";
import { FiPlus, FiSearch, FiArrowDown, FiCopy } from "react-icons/fi";
import MUIDataTable from "mui-datatables";
import { Tooltip, IconButton, useRadioGroup } from "@material-ui/core";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableSortLabel from "@mui/material/TableSortLabel";
import { ThemeProvider,createTheme } from '@mui/material/styles';
//hooks
import { useModal } from "../../../../contexts/useModal";
import { useAuth } from "../../../../contexts/useAuth";
//services
import { api } from "../../../../services/api";
import { getAllClients } from "../../../../services/asyncFunctions";
import { getBrokerLeader } from "../../../../services/asyncFunctions";
//components
import { ArrowLoader } from "../../../../components/loaders/arrowLoader";
//utils
import { titleize } from "../../../../utils/formatNumberAndText/formatTextUpperFirstLetter";
import { ModalSizeFitToScreen } from "../../../../utils/responsiveFunctions/modalSizeFitToScreen";
//styles
import "./styles/brokers.advisor.table.css";

export function BrokerLeader() {
    const { show, setShow, setType, type, edited, setEdited } = useModal();
    const { user,tokenKey } = useAuth();
    const [brokerLeader, setBrokerLeader] = useState([]);
    const [loading, setLoading] = useState(true);
    const token = localStorage.getItem("@auth:token");

    useEffect(() => {''
        async function searchAdvisorBrokers() {
            try {
                // const brokerLeaderResult = await getBrokerLeader(tokenKey,false);
                const brokerLeaderExtra = await api.get(`/api/v1/users/brokerLeader?is_leader=1`, {
                    headers: {
                        authorization: `Bearer ${tokenKey}`
                    }
                })
                // console.log(brokerLeaderResult);
                setBrokerLeader(brokerLeaderExtra?.data?.data);
                setLoading(false);
            } catch (error) {
                alert(error?.response?.data?.message);
            }
        }
        searchAdvisorBrokers();
    }, [show, edited]);

    function copyToClipboard() {
        const output = [];

        const titles = [
            "Código XP",
            "Nome",
            "Filial",
            "Id Líder",
            "Nome Líder",
            "Filial Líder"
        ];
        output.push(titles.join("\t"));

        const keys = [
            "broker_id",
            "broker_name",
            "filial",
            "leader_id",
            "leader_name",
            "leader_filial"
        ];
        brokerLeader.forEach((x) => {
            const tmp = keys.map((y) => x[y]);
            output.push(tmp.join("\t"));
        });
        const table = output.join("\n");
        navigator.clipboard.writeText(table);
        alert("Conteúdo da tabela copiado para a área de transferência");
    }

    async function advisorBrokersExport() {
        setLoading(true);
        try {
            const myExport = await getBrokerLeader(tokenKey, true);
            const responseURL = URL.createObjectURL(new Blob([myExport]));
            const link = document.createElement("a");
            link.href = responseURL;
            link.setAttribute("download", "lideres_dos_brokers.xlsx");
            document.body.appendChild(link);
            link.click();
            setLoading(false);
        } catch (error) {
            setType({
                name: "alertMsg",
                type: "information",
                value: {
                    message:
                        error?.response?.data?.message === undefined
                            ? "Erro ao buscar dados para exportação"
                            : error?.response?.data?.message,
                },
            });
            setShow(true);
            setLoading(false);
        }
    }

    const columns = [
        {
            name: "broker_name",
            label: "Nome do Broker",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "broker_id",
            label: "Id do Broker",
            options: {
                filter: true,
                sort: true,
                display: false,
            },
        },
        {
            name: "filial",
            label: "Filial do Broker",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "leader_name",
            label: "Nome do Líder",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "leader_id",
            label: "Id Líder",
            options: {
                filter: true,
                sort: true,
                display: false,
            },
        },
        {
            name: "leader_filial",
            label: "Filial do Líder",
            options: {
                filter: true,
                sort: true,
            },
        },
    ];

    // Adiciona um botão para chamar a função de copiar a tabela para a área de tranferência
    const AddButton = () => (
        <Tooltip disableFocusListener title="Copiar para a área de transferência">
            <IconButton onClick={copyToClipboard}>
                <ContentCopyIcon />
            </IconButton>
        </Tooltip>
    );

    const options = {
        filterType: "multiselect",
        download: true,
        print: false,
        responsive: "vertical",
        tableBodyHeight: "72vh",
        selectableRowsHideCheckboxes: true,
        viewColumns: false,
        textLabels: {
            body: {
                noMatch: loading ? <ArrowLoader /> : "Não foram encontrados dados",
                toolTip: "Ordenar",
                columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
            },
            pagination: {
                next: "Próxima",
                previous: "Anterior",
                rowsPerPage: "Linhas por página:",
                displayRows: "de",
            },
            toolbar: {
                search: "Pesquisar",
                downloadCsv: "Download XLSX",
                print: "Imprimir",
                viewColumns: "Ver colunas",
                filterTable: "Filtrar",
            },
            filter: {
                all: "Todos",
                title: "Filtros",
                reset: "Limpar",
            },
            viewColumns: {
                title: "Mostrar Colunas",
                titleAria: "Mostrar/Ocultar colunas",
            },
            selectedRows: {
                text: "linha(s) selecionadas",
                delete: "Excluir",
                deleteAria: "Excluir linhas Selecionadas",
            },
        },
        downloadOptions: {
            filename: "previa_receita.csv",
            separator: ";",
        },
        onDownload: () => {
            advisorBrokersExport();
            return false;
        },
        customToolbar: AddButton,
        onRowClick: (rowData, rowMeta) => {
            if (user.type == 1){
                setType({ name: "brokerLeaderEdit", data: rowData });
                setShow(true);
            }
        },
    };

    const getMuiTheme = () =>
    createTheme({
      components: {
        MuiTableCell: {
            styleOverrides: {
                root:{
                        justifyContent: 'center',
                        whiteSpace: "nowrap",
                        textAlign: 'center',
                }
            },
          },
        MUIDataTableHeadCell: {
            styleOverrides: {
                contentWrapper: {
                    justifyContent: 'center',
                    whiteSpace: "nowrap",
                }
            }
        },
      },
    })

    return (
        <div>
            <ThemeProvider theme={getMuiTheme()}>
                <MUIDataTable title={"Lideranças"} data={brokerLeader} columns={columns} options={options}></MUIDataTable>
            </ThemeProvider>
        </div>
    );
}
