import React, { useEffect, useState, useReducer, useRef } from "react"
import { useAuth } from "../../../contexts/useAuth"
import { useModal } from "../../../contexts/useModal"
import { getSettlementCalendar } from "../../../services/asyncFunctions"
import { datetimeToDatePtBR } from "../../../utils/formatDate/dateTimeToPTBR"
import { AiFillCloseCircle } from "react-icons/ai"
import { styleClientsInput } from "../../../components/styles/autocompletField"

import "./styles/dashboardAdvisorCalendar.css"
import { ArrowLoader } from "../../../components/loaders/arrowLoader"
import TextField from "@material-ui/core/TextField"
import { FiSave } from "react-icons/fi"
import { api } from "../../../services/api"
import { FiTrash2, FiEdit } from "react-icons/fi"
import { MdArrowForwardIos, MdContentCopy, MdDownload } from "react-icons/md"
import { FaCheck } from "react-icons/fa"
import { toBRL } from "../../../utils/formatNumberAndText/toBRLCurrency"
import { saveAs } from "file-saver"
import { utils, write } from "xlsx"
import Radio from "@mui/material/Radio"
import RadioGroup from "@mui/material/RadioGroup"
import FormControlLabel from "@mui/material/FormControlLabel"
import FormControl from "@mui/material/FormControl"
import feriados from "../../../utils/feriados/feriados"

export function SettlementCalendarModal(props) {
    console.log('propsCalendario', props)
    let productsWithSettlement = props.prop?.data
    const [loading, setLoading] = useState(false)
    const { tokenKey, user } = useAuth()
    const { show, setShow, setType } = useModal()
    const [listCalendar, setListCalendar] = useState([])
    const [listCalendarFiltered, setListCalendarFiltered] = useState([])
    const classes = styleClientsInput()
    const [tabActive, setTabActive] = useState(0)
    const [copying, setCopying] = useState([])
    const [newSettlement, setNewSettlement] = useState({
        date: "",
        product: "",
    })
    const [, forceUpdate] = useReducer((x) => x + 1, 0)
    function disableCopying() {
        var aux = copying.map((l) => false)
        setCopying(aux)
    }

    async function getCalendar() {
        setLoading(true)
        let listCalendarAux = await getSettlementCalendar(tokenKey)
        if (listCalendarAux?.isError) {
            setType({
                name: "alertMsg",
                type: "information",
                value: {
                    message: listCalendarAux?.error,
                },
            })
            setShow(true)
            setListCalendar([])
            setLoading(false)
        } else {
            listCalendarAux.forEach((x) => (x.onEdit = false))
            productsWithSettlement.map((x) => {
                if (x != undefined) {
                    x.open = false
                    x?.clients?.map((y) => {
                        y.onEdit = false
                    })
                }
            })

            //junta o listCalendarAux com o produtcsWithSttlement
            listCalendarAux = listCalendarAux.concat(productsWithSettlement)

            //ordena a lista por data
            listCalendarAux.sort((a, b) => {
                if (a.date < b.date) {
                    return -1
                }
                if (a.date > b.date) {
                    return 1
                }
                return 0
            })

            //coloca o copiar link como false
            listCalendarAux.forEach((x, i) => {
                copying.push(false)
            })

            setListCalendar(listCalendarAux)

            setLoading(false)
        }
    }

    useEffect(() => {
        getSettlementsByDay(tabActive)
    }, [listCalendar])

    async function updateCalendar(x) {
        setLoading(true)
        //confere se o produto ou a data foram alterados ou estao vazios
        if (x?.product == "" || x?.date == "") {
            alert("Preencha todos os campos")
            setLoading(false)
            return false
        }

        //envia os dados para o backend
        const response = await api.put(
            `/api/v1/alocacao/calendar/settlements/${x?.id}`,
            {
                date: x?.date,
                product: x?.product,
            },
            {
                headers: {
                    Authorization: `Bearer ${tokenKey}`,
                },
            }
        )

        if (response?.data?.isError) {
            setType({
                name: "alertMsg",
                type: "information",
                value: {
                    message: response?.data?.error,
                },
            })
            setShow(true)
            setLoading(false)
        } else {
            alert("Calendário atualizado com sucesso!")
            setLoading(false)
            return true
        }
    }

    async function updateClientLiquidationInfos(client, productId) {
        setLoading(true)
        const pre = client?.codigo ? productId + '__' : "c" + productId + "__"
        console.log('enviando pro back: ',{
            pre: pre,
            liquidation_check: client[pre + "liquidation_check"],
            liquidation_comment: client[pre + "comment"],
            advisors_responsibility: client[pre + "advisors_responsibility"],
        })
        const response = await api.put(
            `api/v1/abc/calendarSettlements/${client[pre + "operation_id"]}`,
            {
                liquidation_check: client[pre + "liquidation_check"],
                liquidation_comment: client?.codigo ? client[pre + "liquidation_comment"] : client[pre + "comment"],
                advisors_responsibility: client[pre + "advisors_responsibility"],
            },
            {
                headers: {
                    Authorization: `Bearer ${tokenKey}`,
                },
            }
        )

        if (response?.data?.isError) {
            setType({
                name: "alertMsg",
                type: "information",
                value: {
                    message: response?.data?.error,
                },
            })
            setShow(true)
            setLoading(false)
        } else {
            alert("Informações atualizadas com sucesso!")
            setLoading(false)
            return true
        }
    }

    async function deleteData(x) {
        setLoading(true)
        const response = await api.delete(`/api/v1/alocacao/calendar/settlements/${x.id}`, {
            headers: {
                Authorization: `Bearer ${tokenKey}`,
            },
        })

        if (response?.data?.isError) {
            setType({
                name: "alertMsg",
                type: "information",
                value: {
                    message: response?.data?.error,
                },
            })
            setShow(true)
            setLoading(false)
        } else {
            alert("Calendário atualizado com sucesso!")
            setLoading(false)
        }
    }

    async function submitNewSettlement() {
        setLoading(true)

        //confere se o produto ou a data nao estao vazios
        if (newSettlement?.product == "" || newSettlement?.date == "") {
            alert("Preencha todos os campos")
            setLoading(false)
            return
        }

        //envia os dados para o backend
        const response = await api.post(
            `api/v1/alocacao/calendar/settlements`,
            {
                date: newSettlement?.date,
                product: newSettlement?.product,
            },
            {
                headers: {
                    Authorization: `Bearer ${tokenKey}`,
                },
            }
        )

        if (response?.data?.isError) {
            setType({
                name: "alertMsg",
                type: "information",
                value: {
                    message: response?.data?.error,
                },
            })
            setShow(true)
            setLoading(false)
        } else {
            alert("Data de liquidação enviada com sucesso!")
            setLoading(false)
            setNewSettlement({
                date: "",
                product: "",
            })
            getCalendar()
        }
    }

    useEffect(() => {
        if (show) {
            getCalendar()
            setTabActive(props?.prop?.d)
        }
    }, [show])

    function getSettlementsByDay(d) {
        //d = 0, 1, 2, 3, 4
        //D0 - Dia atual
        //D1 - Dia util seguinte
        //D2 - Dia util seguinte do dia util seguinte
        //D3 - Dia util seguinte do dia util seguinte do dia util seguinte
        //D3+ - Demais dias

        //pega a data de hoje
        let today = new Date()

        var d0 = today
        var d1 = today
        var d2 = today
        var d3 = today
        var d3plus = today

        //pega o dia da semana
        var day = today.getUTCDay()

        var lastDayVerified = today

        //verifica se hoje é feriado ou final de semana. se for, pega o primeiro dia util seguinte
        while (day == 0 || day == 6 || feriados.includes(lastDayVerified.toISOString().split("T")[0])) {
            lastDayVerified.setDate(lastDayVerified.getDate() + 1)
            day = lastDayVerified.getUTCDay()
        }
        d0 = lastDayVerified.toISOString().split("T")[0]

        lastDayVerified.setDate(lastDayVerified.getDate() + 1)
        day = lastDayVerified.getUTCDay()

        //verifica se hoje é feriado ou final de semana. se for, pega o primeiro dia util seguinte
        while (day == 0 || day == 6 || feriados.includes(lastDayVerified.toISOString().split("T")[0])) {
            lastDayVerified.setDate(lastDayVerified.getDate() + 1)
            day = lastDayVerified.getUTCDay()
        }
        d1 = lastDayVerified.toISOString().split("T")[0]
        lastDayVerified.setDate(lastDayVerified.getDate() + 1)
        day = lastDayVerified.getUTCDay()

        //verifica se hoje é feriado ou final de semana. se for, pega o primeiro dia util seguinte
        while (day == 0 || day == 6 || feriados.includes(lastDayVerified.toISOString().split("T")[0])) {
            lastDayVerified.setDate(lastDayVerified.getDate() + 1)
            day = lastDayVerified.getUTCDay()
        }
        d2 = lastDayVerified.toISOString().split("T")[0]
        lastDayVerified.setDate(lastDayVerified.getDate() + 1)
        day = lastDayVerified.getUTCDay()

        //verifica se hoje é feriado ou final de semana. se for, pega o primeiro dia util seguinte
        while (day == 0 || day == 6 || feriados.includes(lastDayVerified.toISOString().split("T")[0])) {
            lastDayVerified.setDate(lastDayVerified.getDate() + 1)
            day = lastDayVerified.getUTCDay()
        }
        d3 = lastDayVerified.toISOString().split("T")[0]
        lastDayVerified.setDate(lastDayVerified.getDate() + 1)
        day = lastDayVerified.getUTCDay()

        //verifica se hoje é feriado ou final de semana. se for, pega o primeiro dia util seguinte
        while (day == 0 || day == 6 || feriados.includes(lastDayVerified.toISOString().split("T")[0])) {
            lastDayVerified.setDate(lastDayVerified.getDate() + 1)
            day = lastDayVerified.getUTCDay()
        }
        d3plus = lastDayVerified.toISOString().split("T")[0]

        //faz uma copia doobjeto listCalendar
        let listCalendarAux = Object.assign([], listCalendar)

        const dx = d == 4 ? d3plus : d == 3 ? d3 : d == 2 ? d2 : d == 1 ? d1 : d0
        const previousDx = d == 4 ? d3 : d == 3 ? d2 : d == 2 ? d1 : d0

        //filtra a lista de calendario para pegar apenas as datas que sao iguais a lastDayVerified, ou maiores caso seja D3+
        listCalendarAux = listCalendarAux.filter((x) => {
            //verifica se x.date existe
            if (x?.date) {
                if (d == 4) {
                    let date = new Date(x.date)
                    return date.toISOString().split("T")[0] >= dx || (date.toISOString().split("T")[0] < dx && date.toISOString().split("T")[0] > previousDx)
                } else {
                    let date = new Date(x.date)
                    //verifica se a data é igual a lastDayVerified, desconsiderando a hora e fuso horario
                    return date.toISOString().split("T")[0] == dx || (date.toISOString().split("T")[0] < dx && date.toISOString().split("T")[0] > previousDx)
                }
            }
        })

        setListCalendarFiltered(listCalendarAux)
    }

    return (
        <div className="dashboardAdvisorCalendar">
            {loading ? (
                <ArrowLoader />
            ) : (
                <>
                    <div className="boxSettlementProducts">
                        <div className="dashboardCalendarTable">
                            <div className="editBrancheBoxHeaderDash">
                                <div></div>
                                <div style={{ fontSize: "32px" }}>Calendário de Liquidações</div>
                                <button
                                    onClick={() => {
                                        setShow(false)
                                    }}
                                    className="modalCloseButtonDashboard"
                                    style={{ marginRight: "12px", height: "36px", width: "36px" }}
                                >
                                    <AiFillCloseCircle />
                                </button>
                            </div>
                        </div>
                        <div className="bodyCalendarBox" style={{ gridTemplateRows: `${user.type < 3 ? "13.5rem 1fr" : "1.5rem 1fr"}` }}>
                            <div>
                                {user.type < 3 ? (
                                    <>
                                        <div className="bodyConfigBlocHeaderCenarios" style={{ color: "#4C4C4C" }}>
                                            Adicionar Data de liquidação
                                        </div>
                                        <div className="bodyConfigBlocCenarios" style={{ height: "7rem", marginTop: "1rem" }}>
                                            <div className="boxNewCenario">
                                                <div className="newSettlementBox">
                                                    <p className="titileNewCenarioALocacao">Data</p>
                                                    <p className="titileNewCenarioALocacao">Produto</p>
                                                    <div className="itemNewCenarioAlocacao">
                                                        <TextField
                                                            label="Data"
                                                            defaultValue={newSettlement.date}
                                                            InputLabelProps={{ shrink: true }}
                                                            type="date"
                                                            style={{ height: "3rem" }}
                                                            className={classes.styleForClients}
                                                            onChange={(e) => {
                                                                newSettlement.date = e.target.value
                                                            }}
                                                            variant="outlined"
                                                        />
                                                    </div>
                                                    <div className="itemNewCenarioAlocacao">
                                                        <TextField
                                                            style={{ height: "3rem" }}
                                                            variant="outlined"
                                                            defaultValue={newSettlement.product}
                                                            label="Produto"
                                                            className={classes.styleForClients}
                                                            onChange={(e) => {
                                                                newSettlement.product = e.target.value
                                                            }}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="itemNewCenarioAlocacao">
                                                    <div
                                                        className="buttonTableAlocacaoAdd"
                                                        onClick={() => {
                                                            submitNewSettlement()
                                                        }}
                                                        style={{ textAlign: "center", marginTop: "2rem", marginLeft: "3rem", marginRight: "3rem", padding: "0.9rem" }}
                                                    >
                                                        Adicionar
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="bodyConfigBlocHeaderCenarios" style={{ color: "#4C4C4C", marginTop: "1rem" }}>
                                            Datas de liquidação
                                        </div>
                                    </>
                                ) : (
                                    <></>
                                )}
                                <div className="estructuredsTab" style={{ marginTop: "0.5rem", marginLeft: "1rem" }}>
                                    <div
                                        className={tabActive == 0 ? "estructuredsTabOption360" : "estructuredsInactiveTabOption360"}
                                        onClick={() => {
                                            setTabActive(0)
                                            getSettlementsByDay(0)
                                        }}
                                    >
                                        <p>D+0</p>
                                    </div>
                                    <div
                                        className={tabActive == 1 ? "estructuredsTabOption360" : "estructuredsInactiveTabOption360"}
                                        onClick={() => {
                                            setTabActive(1)
                                            getSettlementsByDay(1)
                                        }}
                                    >
                                        <p>D+1</p>
                                    </div>
                                    <div
                                        className={tabActive == 2 ? "estructuredsTabOption360" : "estructuredsInactiveTabOption360"}
                                        onClick={() => {
                                            setTabActive(2)
                                            getSettlementsByDay(2)
                                        }}
                                    >
                                        <p>D+2</p>
                                    </div>
                                    <div
                                        className={tabActive == 3 ? "estructuredsTabOption360" : "estructuredsInactiveTabOption360"}
                                        onClick={() => {
                                            setTabActive(3)
                                            getSettlementsByDay(3)
                                        }}
                                    >
                                        <p>D+3</p>
                                    </div>
                                    <div
                                        className={tabActive == 4 ? "estructuredsTabOption360" : "estructuredsInactiveTabOption360"}
                                        onClick={() => {
                                            setTabActive(4)
                                            getSettlementsByDay(4)
                                        }}
                                    >
                                        <p> &gt;D+3</p>
                                    </div>
                                </div>
                            </div>

                            <div className="headProdutos">
                                {listCalendarFiltered.length > 0 ? (
                                    <table style={{ fontSize: "0.8rem" }}>
                                        <tr style={{ height: "3rem" }}>
                                            <th></th>
                                            <th style={{ maxWidth: "4rem" }}>Data</th>
                                            <th colSpan="3" style={{ minWidth: "7rem" }}>
                                                Produto
                                            </th>
                                            <th style={{ minWidth: "7rem" }}>Valor Reservado</th>
                                            <th style={{ maxWidth: "7rem" }}>Clientes Reservados</th>
                                            <th style={{ maxWidth: "4rem" }}>Copiar</th>
                                            <th style={{ maxWidth: "4rem" }}>Exportar</th>
                                            {user.type < 3 && (
                                                <>
                                                    <th style={{ maxWidth: "4rem" }}>Editar</th>
                                                    <th style={{ maxWidth: "4rem" }}>Deletar</th>
                                                </>
                                            )}
                                        </tr>
                                        {listCalendarFiltered?.map((x, i) => {
                                            // a cor da linha depende se i é par ou impar
                                            const bgColor = i % 2 == 0 ? "#F5F5F5" : "#FFFFFF"
                                            var volumeTotal = 0
                                            if (x?.clients?.length > 0) {
                                                volumeTotal = x.clients.reduce((total, item) => {
                                                    return total + item[`c${x.id}__value`]
                                                }, 0)
                                            }
                                            return (
                                                <>
                                                    <tr className="listCalendarSettlement" style={{ color: "#4C4C4C", textAlign: "center", backgroundColor: bgColor, height: "2rem" }}>
                                                        <td>
                                                            {x?.name ? (
                                                                x.open === true ? (
                                                                    <button
                                                                        className="arrowCalendar"
                                                                        onClick={() => {
                                                                            x.open = !x.open
                                                                            forceUpdate()
                                                                        }}
                                                                    >
                                                                        <MdArrowForwardIos style={{ transform: "rotate(90deg)" }} />
                                                                    </button>
                                                                ) : (
                                                                    <button
                                                                        className="arrowCalendar"
                                                                        onClick={() => {
                                                                            if (x?.clients?.length > 0) x.open = !x.open
                                                                            forceUpdate()
                                                                        }}
                                                                    >
                                                                        <MdArrowForwardIos color={x?.clients?.length > 0 ? "#000000" : "#b5b5b5"} />
                                                                    </button>
                                                                )
                                                            ) : (
                                                                ""
                                                            )}
                                                        </td>
                                                        {!x?.name ? (
                                                            x?.onEdit ? (
                                                                <TextField
                                                                    label="Data"
                                                                    defaultValue={x.date}
                                                                    InputLabelProps={{ shrink: true }}
                                                                    type="date"
                                                                    className={classes.styleForClients}
                                                                    onChange={(e) => {
                                                                        x.date = e.target.value
                                                                    }}
                                                                    variant="outlined"
                                                                />
                                                            ) : (
                                                                <td>{datetimeToDatePtBR(x?.date)}</td>
                                                            )
                                                        ) : (
                                                            <td
                                                                onClick={() => {
                                                                    if (x?.clients?.length > 0) x.open = !x.open
                                                                    forceUpdate()
                                                                }}
                                                            >
                                                                {datetimeToDatePtBR(x?.date)}
                                                            </td>
                                                        )}
                                                        {!x?.name ? (
                                                            x?.onEdit ? (
                                                                <td colSpan="3">
                                                                    <TextField
                                                                        style={{ height: "2.4rem" }}
                                                                        variant="outlined"
                                                                        defaultValue={x.product}
                                                                        label="Produto"
                                                                        className={classes.styleForClients}
                                                                        onChange={(e) => {
                                                                            x.product = e.target.value
                                                                        }}
                                                                    />
                                                                </td>
                                                            ) : (
                                                                <td
                                                                    colSpan="3"
                                                                    onClick={() => {
                                                                        if (x?.clients?.length > 0) x.open = !x.open
                                                                        forceUpdate()
                                                                    }}
                                                                >
                                                                    {x?.product}
                                                                </td>
                                                            )
                                                        ) : (
                                                            <td
                                                                colSpan="3"
                                                                onClick={() => {
                                                                    if (x?.clients?.length > 0) x.open = !x.open
                                                                    forceUpdate()
                                                                }}
                                                            >
                                                                {x?.description}{" "}
                                                            </td>
                                                        )}

                                                        <td
                                                            onClick={() => {
                                                                if (x?.clients?.length > 0) x.open = !x.open
                                                                forceUpdate()
                                                            }}
                                                        >
                                                            {x?.name ? (volumeTotal ? toBRL(volumeTotal) : "-") : "Não se aplica"}
                                                        </td>
                                                        <td
                                                            onClick={() => {
                                                                if (x?.clients?.length > 0) x.open = !x.open
                                                                forceUpdate()
                                                            }}
                                                        >
                                                            {x?.clients ? x?.clients.length : "Não se aplica"}
                                                        </td>
                                                        <td style={{ maxWidth: "4rem" }}>
                                                            {x?.name && x?.clients.length > 0 ? (
                                                                <div
                                                                    className="containerIconCopyContentAbcActions"
                                                                    style={{ cursor: "pointer", borderRadius: "0px", margin: "auto", paddingLeft: "1rem", paddingRight: "1rem" }}
                                                                    onClick={async () => {
                                                                        let aux = copying.map((v, k, a) => (k == i ? true : v))
                                                                        await setCopying(aux)
                                                                        //copiar todas as linhas dos clientes com código, nome valor comentario verificado para o produto no formato para colar no excel
                                                                        let text = ""
                                                                        //primeira linha com o nome do produto
                                                                        text += `${x.description}\n`
                                                                        //segunda linha com os nomes das colunas
                                                                        text += `Código\tCliente\tValor Reservado\tResponsável\tAssessor\tComentário\tSaldo disp. para liquidação\n`
                                                                        x.clients.forEach((y) => {
                                                                            if (y.client_id) {
                                                                                text += `${y.client_id}\t${y.client}\t${y[`c${x.id}__value`]}\t${y[`c${x.id}__advisors_responsibility`] ? 'Assessor' : 'Broker'}\t${y.advisor}\t${y[`c${x.id}__comment`] && y[`c${x.id}__comment`] != 'null' ? y[`c${x.id}__comment`] : ""}`
                                                                                if (y[`c${x.id}__liquidation_check`]) {
                                                                                    text += "\tSim\n"
                                                                                } else {
                                                                                    text += "\tNão\n"
                                                                                }
                                                                            }
                                                                        })

                                                                        navigator.clipboard.writeText(text)
                                                                        setTimeout(disableCopying, 1000)
                                                                    }}
                                                                >
                                                                    <div className="linkMenuModalBoxAbc" style={{ color: "black" }}>
                                                                        {!copying[i] ? <MdContentCopy size={20} /> : <FaCheck size={20} className="iconCopyContentAbcActionsAtive" />}
                                                                    </div>
                                                                </div>
                                                            ) : (
                                                                <></>
                                                            )}
                                                        </td>
                                                        <td style={{ maxWidth: "4rem" }}>
                                                            {x?.name && x?.clients.length > 0 ? (
                                                                <div
                                                                    className="containerIconCopyContentAbcActions"
                                                                    style={{ cursor: "pointer", borderRadius: "0px", margin: "auto", paddingLeft: "1rem", paddingRight: "1rem" }}
                                                                    onClick={async () => {
                                                                        var json = []
                                                                        x.clients.forEach((y) => {
                                                                            if (y.client_id) {
                                                                                json.push({
                                                                                    Código: y.client_id || y?.codigo,
                                                                                    Cliente: y.client || y.nome,
                                                                                    "Valor Reservado": y[`c${x.id}__value`],
                                                                                    Responsável: y[`c${x.id}__advisors_responsibility`] ? "Assessor" : "Broker",
                                                                                    Assessor: y.advisor,
                                                                                    Comentário: y?.codigo ? y[x.name + '__liquidation_comment'] && y[x.name + '__liquidation_comment'] != 'null' ? y[x.name + '__liquidation_comment'] : y[`c${x.id}__comment`] ? y[`c${x.id}__comment`] && y[`c${x.id}__comment`] != "null" ? y[`c${x.id}__comment`] : "" : '' : y[`c${x.id}__comment`] && y[`c${x.id}__comment`] != "null" ? y[`c${x.id}__comment`] : "",
                                                                                    "Saldo disp. para liquidação": y[`c${x.id}__liquidation_check`] ? "Sim" : "Não",
                                                                                })
                                                                            }
                                                                        })

                                                                        const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8"
                                                                        const fileExtension = ".xlsx"
                                                                        const fileName = `${x.description}${fileExtension}`
                                                                        const ws = utils.json_to_sheet(json)
                                                                        const wb = { Sheets: { data: ws }, SheetNames: ["data"] }
                                                                        const excelBuffer = write(wb, { bookType: "xlsx", type: "array" })
                                                                        const data = new Blob([excelBuffer], { type: fileType })
                                                                        saveAs(data, fileName)
                                                                        return false
                                                                    }}
                                                                >
                                                                    <MdDownload size={20} />
                                                                </div>
                                                            ) : (
                                                                <></>
                                                            )}
                                                        </td>

                                                        {user.type < 3 && (
                                                            <>
                                                                {!x?.name ? (
                                                                    <td
                                                                        className="buttonTableAlocacaoEdit"
                                                                        style={{ textAlign: "center", maxWidth: "4rem" }}
                                                                        title={x?.onEdit ? "Salvar" : "Editar"}
                                                                        onClick={() => {
                                                                            var sucess = false
                                                                            if (x?.onEdit == true) {
                                                                                sucess = updateCalendar(x)
                                                                            }
                                                                            if ((sucess && x?.onEdit == true) || (!sucess && x?.onEdit == false)) {
                                                                                x.onEdit = !x?.onEdit
                                                                                forceUpdate()
                                                                            }
                                                                        }}
                                                                    >
                                                                        {x?.onEdit == true ? <FiSave size={20} /> : <FiEdit size={20} />}
                                                                    </td>
                                                                ) : (
                                                                    <td style={{ maxWidth: "4rem" }}>Campanha</td>
                                                                )}

                                                                {!x?.name ? (
                                                                    <td
                                                                        className="buttonTableAlocacaoTrash"
                                                                        title="Excluir"
                                                                        style={{ textAlign: "center", cursor: "pointer", maxWidth: "4rem" }}
                                                                        onClick={() => {
                                                                            deleteData(x)
                                                                            setListCalendar(listCalendar.filter((item) => item?.id !== x?.id))
                                                                        }}
                                                                    >
                                                                        <FiTrash2 size={20} />
                                                                    </td>
                                                                ) : (
                                                                    <td style={{ maxWidth: "4rem" }}></td>
                                                                )}
                                                            </>
                                                        )}
                                                    </tr>
                                                    {/* Nível de visualização de clientes */}
                                                    {x?.open === true && x?.clients?.length > 0 ? (
                                                        <>
                                                            <tr className="headProdutos" style={{ textAlign: "center", backgroundColor: bgColor, height: "2rem" }}>
                                                                <td></td>
                                                                <td>Cód. Cliente</td>
                                                                <td colSpan={user.type < 3 ? 3 : user.is_leader ? 1 : 2}>Cliente</td>
                                                                <td>Valor Reservado</td>
                                                                <td>Responsável</td>
                                                                {user.is_leader || user.type < 3 ? <td>Assessor</td> : <></>}
                                                                <td>Comentário</td>
                                                                <td style={{ maxWidth: "5rem", paddingLeft: "1rem", paddingRight: "1rem" }}>Saldo disp. para liquidação</td>
                                                                <td>Salvar</td>
                                                            </tr>

                                                            {x.clients.map((y, J) => {
                                                                const bgColor2 = J % 2 == 0 ? "#feffe8" : "#fefff7"

                                                                return (
                                                                    <tr className="listCalendarSettlement" style={{ color: "#4C4C4C", textAlign: "center", backgroundColor: bgColor2, height: "2rem" }}>
                                                                        <td></td>
                                                                        <td>{y?.client_id || y?.codigo}</td>
                                                                        <td colSpan={user.type < 3 ? 3 : user.is_leader ? 1 : 2} style={{ maxWidth: "7rem" }}>
                                                                            {y?.client || y?.nome}
                                                                        </td>
                                                                        <td>{toBRL(y[`c${x.id}__value`])}</td>
                                                                        <td>
                                                                            {x?.area == "RENDA VARIÁVEL" ? (
                                                                                <FormControl>
                                                                                    <RadioGroup
                                                                                        row
                                                                                        style={{
                                                                                            display: "grid",
                                                                                            marginLeft: "auto",
                                                                                            marginRight: "auto",
                                                                                        }}
                                                                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                                                                        name="row-radio-buttons-group"
                                                                                        value={y?.codigo ? y[x.name + '__advisors_responsibility'] : y[`c${x.id}__advisors_responsibility`]}
                                                                                        onChange={(e) => {
                                                                                            y?.codigo ? y[`c${x.id}__advisors_responsibility`] = e.target.value : y[x.name + "__advisors_responsibility"] = e.target.value
                                                                                        
                                                                                            y.onEdit = true
                                                                                            forceUpdate()
                                                                                        }}
                                                                                    >
                                                                                        <FormControlLabel
                                                                                            value={true}
                                                                                            sx={{
                                                                                                "& .MuiSvgIcon-root": {
                                                                                                    fontSize: 18,
                                                                                                },
                                                                                            }}
                                                                                            style={{ marginRight: "0rem  !important", marginTop: "0.3rem", marginLeft: "0rem", height: "1.1rem" }}
                                                                                            control={<Radio />}
                                                                                            label={<span style={{ fontSize: "0.8rem" }}>Assessor</span>}
                                                                                        />
                                                                                        <FormControlLabel
                                                                                            value={false}
                                                                                            sx={{
                                                                                                "& .MuiSvgIcon-root": {
                                                                                                    fontSize: 18,
                                                                                                },
                                                                                            }}
                                                                                            style={{ marginRight: "0rem  !important", marginLeft: "0rem", height: "1.1rem", marginBottom: "0.3rem" }}
                                                                                            control={<Radio />}
                                                                                            label={<span style={{ fontSize: "0.8rem" }}>Broker</span>}
                                                                                        />
                                                                                    </RadioGroup>
                                                                                </FormControl>
                                                                            ) : (
                                                                                "-"
                                                                            )}
                                                                        </td>
                                                                        {user.is_leader || user.type < 3 ? <td style={{ maxWidth: "7rem" }}>{y?.advisor}</td> : <></>}
                                                                        <td>
                                                                            {
                                                                                <TextField
                                                                                    variant="outlined"
                                                                                    defaultValue={y[`c${x.id}__comment`] && y[`c${x.id}__comment`] != 'null' ? y[`c${x.id}__comment`] : y?.codigo ? y[x.name + "__liquidation_comment"] : ' '}
                                                                                    style={{ padding: "4px" }}
                                                                                    className={classes.textFieldCalendar}
                                                                                    onChange={(e) => {
                                                                                        y?.codigo ? y[x.name + "__liquidation_comment"] = e.target.value : y[`c${x.id}__comment`] = e.target.value 
                                                                                        y.onEdit = true
                                                                                        forceUpdate()
                                                                                    }}
                                                                                />
                                                                            }
                                                                        </td>
                                                                        <td>
                                                                            <input
                                                                                type="checkbox"
                                                                                value={y?.codigo ? y[x.name + '__liquidation_check'] : y[`c${x.id}__liquidation_check`]}
                                                                                checked={y?.codigo ? y[x.name + '__liquidation_check'] : y[`c${x.id}__liquidation_check`]}
                                                                                onClick={() => {
                                                                                    y?.codigo ? y[x.name + '__liquidation_check'] = !y[x.name + '__liquidation_check'] : y[`c${x.id}__liquidation_check`] = !y[`c${x.id}__liquidation_check`]
                                                                                    y.onEdit = true
                                                                                    forceUpdate()
                                                                                }}
                                                                            ></input>
                                                                        </td>
                                                                        <td
                                                                            className="buttonTableAlocacaoEdit"
                                                                            style={{ textAlign: "center" }}
                                                                            title={y?.onEdit ? "Salvar" : "-"}
                                                                            onClick={() => {
                                                                                var sucess = false
                                                                                if (y?.onEdit == true) {
                                                                                    sucess = updateClientLiquidationInfos(y, y?.codigo? x?.name : x.id  )
                                                                                }
                                                                                if ((sucess && y?.onEdit == true) || (!sucess && y?.onEdit == false)) {
                                                                                    y.onEdit = !y?.onEdit
                                                                                    forceUpdate()
                                                                                }
                                                                            }}
                                                                        >
                                                                            {y?.onEdit == true ? <FiSave size={20} /> : "-"}
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })}
                                                        </>
                                                    ) : (
                                                        <></>
                                                    )}
                                                </>
                                            )
                                        })}
                                    </table>
                                ) : (
                                    <div className="myReservationEmptyBody">
                                        <h2>Não há liquidações para esse período</h2>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    )
}
