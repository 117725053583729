/**
 * Densenvolvido por: Equipe Tiago Murilo
 * Descrição: Formulário para cadastro de FAQ
 **/

//libraries
import React, { useState, useEffect } from 'react'
import { TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { createFilterOptions } from '@material-ui/lab'
//hooks
import { useModal } from '../../../contexts/useModal'
import { useAuth } from '../../../contexts/useAuth';
import { ArrowLoader } from '../../../components/loaders/arrowLoader'
//services
import { api } from '../../../services/api';
//styles
import { styleClientsInput } from '../../../components/styles/autocompletField';
import styles from './styles/faqEducationCadastration.module.css'

export function FaqCadastration(props){
    const filter = createFilterOptions()
    const [loading, setLoading] = useState(false)
    const classes = styleClientsInput()
    const [title, setTitle] = useState('')
    const [subject, setSubject] = useState('')
    const [tag, setTag] = useState('')
    const [link, setLink] = useState('')
    const [file, setFile] = useState('')
    const [general, setGeneral] = useState(true)
    const [isDocument, setIsDocument] = useState(true)
    const [selectedTypeAccess, setSelectedTypeAccess] = useState(null)
    const [typeAccess, setTypeAccess] = useState([])
    const [selectedTag, setSelectedTag] = useState(null)
    const token = localStorage.getItem('@auth:token')
    // const tagsData = props.tagsData.tagsData
    const { show, setShow, setEdited } = useModal()
    const [tagList, setTagList] = useState([])
    
    let tempTags = []
    props.tagsData.forEach(x => {
        tempTags.push(x.tag)
    });

    useEffect(()=>{
        
        async function getDatas(){
            try{
                const selectType = await api.get(`/api/v1/types`, {
                    headers: {
                        authorization: `Bearer ${token}`
                    }
                })
                setTypeAccess(selectType.data.data)
            }catch(error){
                return alert(error.response.data.message)   
            }           
        }
        getDatas()
    }, [token])


    useEffect(()=>{
        
       if(show == false){
            setTitle('')
            setSubject('')
            setTag('')
            setLink('')
            setSelectedTypeAccess(null)
            setSelectedTag(null)
            setGeneral('')
            setFile('')
        }

    }, [show])

    async function handleSubmit(e){
        e.preventDefault()

        if(isDocument == false){
            if(general){
                if(title == '' || subject == '' || tag == '' || link == ''){
                    return alert(`Você precisa informar os campos: \nTítulo, Subtítulo, Tag e Link`)
                }
            }else{
                if(title == '' || subject == '' || tag == '' || link == '' || selectedTypeAccess == 0 || selectedTypeAccess == null){
                    return alert(`Você precisa informar os campos: \nTipo de Acesso, Título, Subtítulo, Tag e Link`)
                }
            }
            
            setLoading(true)
            try{

                await api.post(`/api/v1/faq`, {
                    general: general == true ? 1 : 0,
                    title: title,
                    subject: subject,
                    tag: tag.toLowerCase().replace(/(?:^|\s)\S/g, function(a) {
                        return a.toUpperCase();
                      }),
                    path_link: link,
                    is_file: isDocument == true ? 1 : 0,
                    type_access: selectedTypeAccess ==  null || selectedTypeAccess == undefined || selectedTypeAccess == '' ? undefined : selectedTypeAccess.type_access
                }, {
                    headers: {
                        authorization: `Bearer `+ token
                    }
                })
                
                setShow(false)
                setEdited(true)
                setLoading(false)
                return;
            }catch(error){
                setLoading(false)
                return alert(error?.response?.data?.message)
            }
        }else{
        
            if(general){
                if(title == '' || subject == '' || tag == '' || file == '' || file ==  undefined){
                    return alert(`Você precisa informar os campos: \nTítulo, Subtítulo, Tag e Arquivo`)
                }
            }else{
                if(title == '' || subject == '' || tag == '' || file == '' || file ==  undefined || selectedTypeAccess == 0 || selectedTypeAccess == null){
                    return alert(`Você precisa informar os campos: \nTipo de Acesso, Título, Subtítulo, Tag e Arquivo`)
                }
            }
            setLoading(true)
            try {
                const responseCreateFAQ = await api.post(`/api/v1/faq`, {
                    general: general == true ? 1 : 0,
                    title: title,
                    subject: subject,
                    tag: tag.toLowerCase().replace(/(?:^|\s)\S/g, function(a) {
                        return a.toUpperCase();
                    }),
                    is_file: isDocument == true ? 1 : 0,
                    type_access: selectedTypeAccess ==  null || selectedTypeAccess == undefined || selectedTypeAccess == '' ? undefined : selectedTypeAccess.type_access
                }, {
                    headers: {
                        authorization: `Bearer `+ token
                    }
                })
    
                const formData = new FormData();
                formData.append('faq', file)
    
                await api.post(`/api/v1/faq/${responseCreateFAQ.data.data.id}/upload`, formData, {
                    headers:{
                        "Authorization": 'Bearer ' + token,
                        "Content-Type": "multipart/form-data"
                    }
                })
                setShow(false)
                setEdited(true)
                setLoading(false)
                return 
            } catch (error) {
                setLoading(true)
                return alert(error.response.data.message)
            }
        }

    }

    return (
        <div className={styles.faqCadastration}>
            <div className={styles.faqCadastrationHeader}>
                <p>Efetue o cadastro de FAQ abaixo</p>
                <button onClick={() => setShow(false)}>x</button>
            </div>
            <br />
            {
                loading ? 
                <ArrowLoader/> :

            <form action="post" className={styles.formFormat} onSubmit={handleSubmit}>
                <div className={styles.radioSelectContainer}>
                    <div>
                        <input onKeyPress={(e) => {if(e.code === 'Backquote') e.preventDefault()}} type="checkbox" id='public' checked={general == true ? true : false} onClick={(e) => setGeneral(true)}/>
                        <label htmlFor="public">Público</label>
                    </div>
                    <div>
                        <input onKeyPress={(e) => {if(e.code === 'Backquote') e.preventDefault()}} type="checkbox" id='private' checked={general == true ? false : true} onClick={(e) => setGeneral(false)}/>
                        <label htmlFor="private">Privado</label>
                    </div>
                </div>
                <br />
                {
                    general ? '' : (
                        <>
                            <label>
                                <Autocomplete
                                    options={typeAccess}
                                    value={selectedTypeAccess}
                                    onChange={(event, newValue) => {
                                        setSelectedTypeAccess(newValue);
                                    }}
                                    getOptionLabel={(x) => x.type_description}
                                    getOptionSelected={(option, val) => option.value === val.value}
                                    id='userType'
                                    renderInput={(params) => <TextField
                                        label='Tipo de acesso'
                                        onKeyPress={(e) => {if(e.code === 'Backquote') e.preventDefault()}}
                                        className={classes.autocompleteFAQ}
                                        {...params} variant="outlined" />}
                                />
                            </label>
                            <br />
                        </>
                    )
                }
                 <div className={styles.InputToFormat_two}>
                    <div>
                        <input onKeyPress={(e) => {if(e.code === 'Backquote') e.preventDefault()}} type="text" value={title} onChange={(e) => setTitle(e.target.value)} placeholder=' '/>
                        <label htmlFor="">Título</label>
                    </div>
                </div>
                <br />
                <div className={styles.InputToFormat_two}>
                    <div>
                        <input onKeyPress={(e) => {if(e.code === 'Backquote') e.preventDefault()}} type="text" value={subject} onChange={(e) => setSubject(e.target.value)} placeholder=' '/>
                        <label htmlFor="">Subtítulo</label>
                    </div>
                </div>
                <br />
                        <>
                            <label>
                                <Autocomplete
                                    options={tempTags}
                                    freeSolo
                                    selectOnFocus
                                    value={tag}
                                    onChange={(event, tag) => {
                                        if(typeof tag === 'string'){
                                            setTag(tag);
                                        } else if (tag && tag.inputValue) {
                                            setTag(tag.inputValue)
                                        } else {
                                            setTag(tag);
                                        }
                                    }}
                                    
                                    filterOptions={(options, params) => {
                                    
                                        const filtered = filter(options, params);

                                        if (params.inputValue !== '') {
                                        filtered.push({
                                            inputValue: params.inputValue,
                                            title: `Criar Tag "${params.inputValue}"`,
                                        });
                                        }

                                        return filtered;
                                    }}
                                    getOptionLabel={(x) => {
                                        if (typeof x === 'string'){
                                            return x; 
                                        }
                                        if (x.title) {
                                            return x.title;
                                        }
                                        return x.inputValue;
                                    }
                                }
                                    getOptionSelected={(option, val) => option.inputValue === val.inputValue}
                                    id='tag'
                                    renderInput={(params) => <TextField
                                        label='Tag'
                                        onKeyPress={(e) => {if(e.code === 'Backquote') e.preventDefault()}}
                                        className={classes.autocompleteFAQ}
                                        {...params} variant="outlined" />}
                                />
                            </label>
                            <br />
                        </>
                <br />
                <div className={styles.radioSelectContainerColumn}>
                    <div className={styles.radioSelectContainerColumnLine}>
                        <div>
                            <input onKeyPress={(e) => {if(e.code === 'Backquote') e.preventDefault()}} type="checkbox" id='archive' checked={isDocument == true ? true : false} onClick={(e) => setIsDocument(true)}/>
                            <label htmlFor="archive">Arquivo</label>
                        </div>
                        <div>
                            <input onKeyPress={(e) => {if(e.code === 'Backquote') e.preventDefault()}} type="checkbox" id='link' checked={isDocument == true ? false : true} onClick={(e) => setIsDocument(false)}/>
                            <label htmlFor="link">Link</label>
                        </div>
                    </div>
                    {
                        !isDocument ? 
                        <div className={styles.InputToFormat}>
                            <div>
                                <input onKeyPress={(e) => {if(e.code === 'Backquote') e.preventDefault()}} type="text" value={link} onChange={(e) => setLink(e.target.value)} placeholder=' '/>
                                <label htmlFor="">Link</label>
                            </div>
                        </div> :
                        <div className={styles.fileInputAdjust}>
                            <label htmlFor="fileSelect">{file === '' || file === undefined || file === null ? 'Selecione um arquivo' : file.name}</label>
                            <input onKeyPress={(e) => {if(e.code === 'Backquote') e.preventDefault()}} type="file" id='fileSelect' onChange={(e) => setFile(e.target.files[0])}/>
                        </div>
                    }  
                </div>
                <br />
                <button type='submit' className='buttonWhiteBg'>Cadastrar</button>
            </form>         
            }
        </div>
    )
}