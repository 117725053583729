/*Tela Dashboard
/*Descrição: Traz os principais atalhos do sistema de acordo com o usuário que está logado
/*Desenvolvido por: Equipe Rafael Cezário
/* Reescrito por: Equipe Tech RV
*/

import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
/* Mui Datatable */
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
/*Styles*/
import './dashboard.css';
import { BsArrowRight, BsArrowBarLeft } from 'react-icons/bs';
import { FiEdit2, FiPlus, FiTrash2, FiEdit } from 'react-icons/fi'
import { BiDetail } from 'react-icons/bi'
import { CiImport } from 'react-icons/ci'
import { AiOutlineFileSync, AiOutlineFileDone } from 'react-icons/ai'
import { MdExpandMore, MdExpandLess} from 'react-icons/md'
import { TileLoader } from '../../../components/loaders/tileLoader';
/*Utils*/
import { titleize } from '../../../utils/formatNumberAndText/formatTextUpperFirstLetter';
// import { toBRL } from "../../../utils/formatNumberAndText/toBRLCurrency";

/*Contexts*/
import { useAuth } from '../../../contexts/useAuth';
import { useModal } from '../../../contexts/useModal';
/*Services*/
import { api } from '../../../services/api'
import { ArrowLoaderWhiteWOText } from '../../../components/loaders/loaderWhitoutTextWhite';
import { getDashboardDetails, getAdvisorWisirPanel } from '../../../services/asyncFunctions'
/*components*/
import Modal from '../../../components/modal/modal'
import Alert from '../../../components/alert/alert'
import { DashboardTextEditor } from './dashboardTextEditor';
import { ConfirmationBox } from '../../../components/confirmationBox/confirmationBox'
import { toBRL } from '../../../utils/formatNumberAndText/toBRLCurrency'
import img from './WisirIcon.png';
import defaultAvatar from '../../../assets/user_avatar.png'
import { useMenu } from '../../../contexts/useMenu'

import { NegativeBalanceTbl } from "./tables/negativeBalance.table";
import { OpportunityBalanceTbl } from './tables/opportunityBalance.table';
import { NegativeBTCTable } from './tables/negativeBTC.table';
import { AssetForwardTbl } from './tables/assetForward.table';
import { NotExecutedTbl } from './tables/notExecuted.table';
import { IndexProjectedTbl } from './tables/brokerProjected.table';

import {ChangeContentComponent} from './changeContentComponent';

import { InfoBoxDash } from './infoBoxDash';

import {useDropzone} from 'react-dropzone';
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import ConvertApi from 'convertapi-js'
import { AiFillCloseCircle } from 'react-icons/ai';

export function Dashboard() {
    const { menuStatus,setMenuStatus} = useMenu();
    const [periodicReports, setPeriodicReports] = useState([])
    const [reports, setReports] = useState([])
    const {user, userAvatar, tokenKey} = useAuth();
    const token = localStorage.getItem('@auth:token');
    const { show, setShow, type, setType, edited, setEdited } = useModal()
    const history = useHistory();
    const [loading, setLoading] = useState(false)
    const [dashboardTextList, setDashboardTextList] = useState([])
    const [clientsMesa, setClientsMesa] = useState({ total: 0, atual: 0, atualMin10k: 0, alocated: 0.00, potential: 0 })
    const [wisirAcoes, setWisirAcoes] = useState({ clients: 0, custody: 0, alocatedDinamica: 0.00 })
    const [wisirFiis, setWisirFiis] = useState({ clients: 0, custody: 0, alocatedFiis: 0.00 })
    const [wisirRenda, setWisirRenda] = useState({ clients: 0, custody: 0, alocatedFiis: 0.00 })
    const [wisirDividendos, setWisirDividendos] = useState({ clients: 0, custody: 0, alocatedDividendos: 0.00 })
    const [myBroker, setMyBroker] = useState();
    const [myBrokerLeader, setMyBrokerLeader] = useState();

    const [blobBrokerPrivate, setBlobBrokerPrivate] = useState('');
    const [blobBrokerMesaDigital, setBlobBrokerMesaDigital] = useState('');
    const [blobBrokerHome, setBlobBrokerHome] = useState('');
    const [ShowInfoBrokerPrivate, setShowInfoBrokerPrivate] = useState(false);
    const [ShowInfoBrokerMesaDigital, setShowInfoBrokerMesaDigital] = useState(false);
    const [ShowInfoBrokerHome, setShowInfoBrokerHome] = useState(false);
    const [InfoBrokerPrivate, setInfoBrokerPrivate] = useState({celular: null,email: null, filial: null, x: 0, y: 0});
    const [InfoBrokerMesaDigital, setInfoBrokerMesaDigital] = useState({celular: null,email: null, filial: null, x: 0, y: 0});
    const [InfoBrokerHome, setInfoBrokerHome] = useState({celular: null,email: null, filial: null, x: 0, y: 0});
    const [fixedBrokerHomeInfo, setFixedBrokerHomeInfo] = useState(false)
    const [fixedBrokerPrivateInfo, setFixedBrokerPrivateInfo] = useState(false)
    const [fixedBrokerMesaDigitalInfo, setFixedBrokerMesaDigitalInfo] = useState(false)

    const [showLideresPorBroker, setShowLideresPorBroker] = useState(false)
    const [blobLiderPrivate, setBlobLiderPrivate] = useState('');
    const [blobLiderMesaDigital, setBlobLiderMesaDigital] = useState('');
    const [blobLiderHome, setBlobLiderHome] = useState('');
    const [ShowInfoLiderPrivate, setShowInfoLiderPrivate] = useState(false);
    const [ShowInfoLiderMesaDigital, setShowInfoLiderMesaDigital] = useState(false);
    const [ShowInfoLiderHome, setShowInfoLiderHome] = useState(false);
    const [InfoLiderPrivate, setInfoLiderPrivate] = useState({nome: null, celular: null,email: null, filial: null, x: 0, y: 0});
    const [InfoLiderMesaDigital, setInfoLiderMesaDigital] = useState({nome: null, celular: null,email: null, filial: null, x: 0, y: 0});
    const [InfoLiderHome, setInfoLiderHome] = useState({nome: null, celular: null,email: null, filial: null, x: 0, y: 0});
    const [fixedLiderHomeInfo, setFixedLiderHomeInfo] = useState(false)
    const [fixedLiderPrivateInfo, setFixedLiderPrivateInfo] = useState(false)
    const [fixedLiderMesaDigitalInfo, setFixedLiderMesaDigitalInfo] = useState(false)
    const [messageTransfer, setMessageTransfer] = useState("")
    const [showMessageTransfer, setShowMessageTransfer] = useState( localStorage.getItem("showMessageTransfer") ? ( localStorage.getItem("showMessageTransfer") === "true" ? true : false) : localStorage.setItem("showMessageTransfer", "true") )


    const [blobDefaultBroker, setBlobDefaultBroker] = useState('');
    const [ShowInfoDefaultBroker, setShowInfoDefaultBroker] = useState(false);
    const [InfoDefaultBroker, setInfoDefaultBroker] = useState({celular: null,email: null, filial: null, x: 0, y: 0});
    const [fixedDefaultBrokerInfo, setFixedDefaultBrokerInfo] = useState(false)
    const [blobBrokerLeader, setBlobBrokerLeader] = useState('');
    const [ShowInfoBrokerLeader, setShowInfoBrokerLeader] = useState(false);
    const [InfoBrokerLeader, setInfoBrokerLeader] = useState({celular: null,email: null, filial: null, x: 0, y: 0});
    const [fixedBrokerLeaderInfo, setFixedBrokerLeaderInfo] = useState(false)

    const [scrollPosition, setScrollPosition] = useState(0)

    const [tabActive, setTabActive] = useState(1);

    const [defaultBrokerId, setDefaultBrokerId] = useState()

    const [currentContent, setCurrentContent] = useState(1)

    const [selectedDocs, setSelectedDocs] = useState([]);
    const [docName, setDocName] = useState([])
    const [docSize, setDocSize] = useState([])

    const [showAlertVar, setShowAlertVar] = useState(false)

    const [viewMessage, setViewMessage] = useState(true)

    const rows = useRef([]);


    useEffect(async () => {
        try {
            const getMessage = await api.get(`/api/v1/message`, {
                headers: {
                    authorization: `Bearer ${token}`
                },
                responseType: 'blob'
            })
            // var blobMessage = new Blob([getMessage.data], { type: 'application/pdf' });
            setSelectedDocs([getMessage.data])
        } catch (error) {
            console.log(error)
        }
    },[tokenKey])

    async function getDbMessage(){
        try {
            const getMessage = await api.get(`/api/v1/message`, {
                headers: {
                    authorization: `Bearer ${token}`
                },
                responseType: 'blob'
            })
            console.log(getMessage)
            // var blobMessage = new Blob([getMessage.data], { type: 'application/pdf' });
            setSelectedDocs([getMessage.data])
        } catch (error) {
            console.log(error)
        }
    }

    // const fileReader = new FileReader();
    const onDrop = useCallback(async acceptedFiles => {
        if(acceptedFiles[0].type == "application/pdf"){
            setSelectedDocs(acceptedFiles)
            setDocName(acceptedFiles[0].path)
            setDocSize(parseInt((parseInt(acceptedFiles[0].size)/1024)))
        }
        else{
            setShowAlertVar(true)
        }
      }, [])
    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})

    let pageLocation = '/';
    user.type === 4 ? pageLocation = '/indicarClientes' :
        user.type === 3 ? pageLocation = '/' :
            pageLocation = '/indicarClientes';

    async function sendMessage(){
        try {
            const formData = new FormData();
            formData.append('message', selectedDocs[0]);

            const updateMessageFile = await api.post(`/api/v1/message`, formData, {
                headers: {
                    authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data',
                }
            })
            setType({
                name: 'alertMsg',
                type: 'information',
                value: {
                    message: 'Recado atualizado com sucesso!'
                }
            })
            setShow(true)
            setViewMessage(true)
        }
        catch (err) {
            setType({ name: 'alertMsg', type: 'information', value: { message: err?.response?.data?.message } })
            setShow(true);
        }
    }

    async function getData() {

        setLoading(true)
        try {

            if (user.type === 4) {
                const wisirData = await getAdvisorWisirPanel(tokenKey);
                // console.log('wisirData.potential_rv.potencialClients',wisirData.potential_rv.potencialClients)
                // clientes
                const total = wisirData.total_clients;
                const atual = wisirData.mesa;
                const netStockAbove10k = parseInt(wisirData.potential_rv.netStockAbove10k)
                const alocated = (atual / total * 100).toPrecision(3);
                //clientes potenciais rv (net_stock > 10k)
                const potentialClients = parseInt(wisirData.potential_rv.potencialClients)
                // acoes
                const acoesClients = parseInt(wisirData.acoes.clients);
                const acoesCustody = parseFloat(wisirData.acoes.aported);
                const alocatedDynamic = (acoesClients / total * 100).toPrecision(3);
                // fiis
                const fiisClients = parseInt(wisirData.fiis.clients);
                const fiisCustody = parseFloat(wisirData.fiis.aported);
                const alocatedFii = (fiisClients / total * 100).toPrecision(3);
                // Renda
                const rendaClients = parseInt(wisirData.renda.clients);
                const rendaCustody = parseFloat(wisirData.renda.aported);
                const alocatedRenda = (rendaClients / total * 100).toPrecision(3);
                // Dividendos
                const dividendosClients = parseInt(wisirData.dividendos.clients);
                const dividendosCustody = parseFloat(wisirData.dividendos.aported);
                const alocatedDividendos = (dividendosClients / total * 100).toPrecision(3);

                setClientsMesa({ total, atual, netStockAbove10k, alocated, potentialClients});
    
                setWisirAcoes({ clients: acoesClients, custody: acoesCustody, alocatedDinamica: alocatedDynamic});
                setWisirFiis({ clients: fiisClients, custody: fiisCustody, alocatedFiis: alocatedFii});
                setWisirRenda({ clients: rendaClients, custody: rendaCustody, alocatedFiis: alocatedRenda});
                setWisirDividendos({ clients: dividendosClients, custody: dividendosCustody, alocatedFiis: alocatedDividendos});


                rows.current = [
                    { name: 'Wisir Dinâmica Ações', link: '/wisirAcompanhamento', clients: acoesClients, custody: acoesCustody ? toBRL(acoesCustody) : toBRL(0), alocated: `${alocatedDynamic ? alocatedDynamic : 0} %` },
                    { name: 'Wisir Fundos Imobiliários', link: '/wisirAcompanhamento', clients: fiisClients, custody: fiisCustody ? toBRL(fiisCustody) : toBRL(0), alocated: `${alocatedFii ? alocatedFii : 0} %` },
                    { name: 'Wisir Renda Imobiliária', link: '/wisirAcompanhamento', clients: rendaClients, custody: rendaCustody ? toBRL(rendaCustody) : toBRL(0), alocated: `${alocatedRenda ? alocatedRenda : 0} %` },
                    { name: 'Wisir Dinâmica Dividendos', link: '/wisirAcompanhamento', clients: dividendosClients ? dividendosClients : 0, custody: dividendosCustody ? toBRL(dividendosCustody) : toBRL(0), alocated: `${alocatedDividendos ? alocatedDividendos : 0} %` }
                ];
                //brokers
                const brokerAndLeaderInfo = wisirData.brokers[0]
                // console.log(brokerAndLeaderInfo)
                const brokerInfo = {
                    advisor_id: brokerAndLeaderInfo.advisor_id,
                    avatar: brokerAndLeaderInfo.avatar,
                    description: brokerAndLeaderInfo.description,
                    broker_id: brokerAndLeaderInfo.broker_id,
                    email: brokerAndLeaderInfo.email,
                    filial: brokerAndLeaderInfo.filial,
                    full_name:brokerAndLeaderInfo.full_name,
                    tel: brokerAndLeaderInfo.tel,
                }
                const brokerLeaderInfo = {
                    advisor_id: brokerAndLeaderInfo.advisor_id,
                    avatar: brokerAndLeaderInfo.leader_avatar,
                    leader_id: brokerAndLeaderInfo.leader_id,
                    email: brokerAndLeaderInfo.leader_email,
                    filial: brokerAndLeaderInfo.leader_filial,
                    leader_name:brokerAndLeaderInfo.leader_name,
                    tel: brokerAndLeaderInfo.leader_tel,
                }
                // console.log(brokerInfo)
                // console.log(brokerLeaderInfo)
                await setMyBroker(brokerInfo)
                await setMyBrokerLeader(brokerLeaderInfo)
                
            }

            const getReports = await api.get('/api/v1/reportsfaqs', {
                headers: {
                    authorization: `Bearer ${tokenKey}`
                }
            })


            let lastUpdatesReports = []

            setReports(getReports?.data?.data)
            let periodicReports = []
            getReports?.data?.data?.filter((isPeriodicReport) => {
                if (isPeriodicReport.periodic) {
                    periodicReports.push(isPeriodicReport)
                }

                //Tratativa para pegar relatórios dos ultimos 5 dias
                let dateFormatted = (isPeriodicReport.updated_at.split('/'))
                let yearFormatted = dateFormatted[dateFormatted.length - 1]
                let hourFormatted = dateFormatted[dateFormatted.length - 2]
                hourFormatted = yearFormatted.slice(yearFormatted.indexOf(' '), yearFormatted.length)
                hourFormatted = hourFormatted.slice(1, hourFormatted.length)
                yearFormatted = yearFormatted.slice(0, yearFormatted.indexOf(' '))
                let dateReport = `${yearFormatted}-${dateFormatted[1]}-${dateFormatted[0]}T${hourFormatted}.000Z`
                let now = new Date()
                dateReport = new Date(dateReport)
                if ((now?.getFullYear() === dateReport?.getFullYear()) && (now?.getMonth() === dateReport?.getMonth()) && ((now?.getDate() - dateReport?.getDate()) < 6)) {
                    //Insere estes relatórios no array
                    if (now.getDate() === dateReport.getDate()) {
                        lastUpdatesReports.push({
                            asset: isPeriodicReport?.asset,
                            asset_company: isPeriodicReport?.asset_company,
                            id: isPeriodicReport?.id,
                            is_file: isPeriodicReport?.is_file,
                            path_link: isPeriodicReport?.path_link,
                            periodic: isPeriodicReport?.periodic,
                            segment: isPeriodicReport.segment,
                            source: isPeriodicReport?.source,
                            title: isPeriodicReport?.title,
                            updated_at: isPeriodicReport?.updated_at,
                            users_external_id: isPeriodicReport?.users_external_id,
                            is_today_report: true,
                            type: isPeriodicReport?.type
                        })
                    } else {
                        lastUpdatesReports.push({
                            asset: isPeriodicReport?.asset,
                            asset_company: isPeriodicReport?.asset_company,
                            id: isPeriodicReport?.id,
                            is_file: isPeriodicReport?.is_file,
                            path_link: isPeriodicReport?.path_link,
                            periodic: isPeriodicReport?.periodic,
                            segment: isPeriodicReport.segment,
                            source: isPeriodicReport?.source,
                            title: isPeriodicReport?.title,
                            updated_at: isPeriodicReport?.updated_at,
                            users_external_id: isPeriodicReport?.users_external_id,
                            is_today_report: false,
                            type: isPeriodicReport?.type
                        })
                    }

                    lastUpdatesReports = lastUpdatesReports.sort(function (a, b) {
                        return a.updated_at > b.updated_at ? -1 : a.updated_at < b.updated_at ? 1 : 0;
                    })
                }
            })
            setReports(lastUpdatesReports)
            setPeriodicReports(periodicReports)
            setLoading(false)
        } catch (error) {
            setLoading(false)
            return alert(`Erro ao realizar busca de relatórios.`)
        }
    }


    useEffect(() => {
        (async () => {
            //++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
            if(myBroker?.avatar != null){
                try {
                    const avatarBroker = await api.get(`/api/v1/uploads/avatar/${myBroker.avatar}`, {
                        headers: {
                            authorization: `Bearer ${tokenKey}`,
                        },
                        responseType: 'blob'
                    })
                    await setBlobDefaultBroker(URL.createObjectURL(avatarBroker.data))
                } catch (error) {
                    setBlobDefaultBroker(null)
                }
            }
            else{
                setBlobDefaultBroker(null)
            }
            //++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
            if(myBrokerLeader?.avatar != null){
                try {
                    const avatarLeader = await api.get(`/api/v1/uploads/avatar/${myBrokerLeader.avatar}`, {
                        headers: {
                            authorization: `Bearer ${tokenKey}`,
                        },
                        responseType: 'blob'
                    })
                    // console.log(avatarLeader)
                    await setBlobBrokerLeader(URL.createObjectURL(avatarLeader.data))
                } catch (error) {
                    setBlobBrokerLeader(null)
                }
            }
            else{
                setBlobBrokerLeader(null)
            }
            await setInfoBrokerLeader({nome: myBrokerLeader?.leader_name, celular: myBrokerLeader?.tel,email: myBrokerLeader?.email, filial: myBrokerLeader?.filial, x: 0, y: 0})
            await setInfoDefaultBroker({celular: myBroker?.tel,email: myBroker?.email, filial: myBroker?.filial, x: 0, y: 0})
        })()

    }, [myBrokerLeader])

    async function getDashboardText() {

        const texts = await getDashboardDetails(tokenKey)
        if (texts?.isError) {
            setType({
                name: 'alertMsg',
                type: 'information',
                value: {
                    message: texts?.error
                }
            })
            // setShow(true);
        } else {

            let newDetails = texts

            while (newDetails.indexOf(`!!!!`) > -1) {
                newDetails = newDetails.replace(`!!!!`, `"`)
            }

            while (newDetails.indexOf(`<p></p>`) > -1) {
                newDetails = newDetails.replace(`<p></p>`, `<br>`)
            }

            setDashboardTextList(newDetails)
        }
    }

    useEffect(() => {

        if ((tokenKey !== undefined && !!tokenKey) || edited) {
            getDashboardText()
            getData()
            setEdited(false)
        }

    }, [tokenKey, edited])

    function handleTextEditor(dashboardText) {

        if (user.type < 3) {
            setType({
                name: 'showDashboardTextEditor',
                type: 'edit',
                state: dashboardText?.details,
                title: dashboardText?.title,
                idText: dashboardText?.id
            });
            setShow(true)
        }
    }

    async function getReport(pathLink, title, isFile) {

        if (pathLink == undefined || pathLink == '') {
            return alert('Não foi atrelado nenhum Link ou Arquivo a este FAQ.')
        }
        if (isFile == 0) {
            try {

                if (new RegExp("^(https?://)", "i").test(pathLink)) {
                    return window.open(pathLink)
                }
                return window.open("http://" + pathLink)
            } catch (error) {
                alert('Ocorreu um erro ao abrir o link, solicite ao adm para lhe enviar o relatório')
            }
        } else {
            setLoading(true)
            try {
                const fileExtensio = ['.xls', '.xlsx', '.png', '.jpeg', '.gif', '.pjpeg', '.pdf', 'text/plain', '.zip']
                const fileContentTypes = [
                    'application/vnd.ms-excel',
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                    'image/png',
                    'image/jpeg',
                    'image/gif',
                    'image/pjpeg',
                    'application/pdf',
                    'text/plain',
                    'application/zip'
                ]
                const responseFile = await api.get('/api/v1/uploads/faq/' + pathLink, {
                    headers: {
                        authorization: `Bearer ${tokenKey}`
                    },
                    responseType: 'blob'
                })

                const url = URL.createObjectURL(new Blob([responseFile.data]))
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${title}${fileExtensio[fileContentTypes.indexOf(responseFile.data.type)]}`)
                document.body.appendChild(link)
                link.click()
                setLoading(false)
                return;
            } catch (error) {
                setLoading(false)
                if (error.response?.data?.message == undefined) {
                    return alert('Erro ao tentar buscar relatório. Se o problema persistir entre em contato com algum Backoffice ou Administrador do sistema.')
                }
                return alert(error.response?.data?.message)
            }
        }
    }

    async function handleDeleteText(idText) {

        setLoading(true)
        try {

            const deleteText = await api.delete(`/api/v1/dashboard/${idText}`, {
                headers: {
                    authorization: `Bearer ${tokenKey}`
                }
            })

            setType({ name: 'alertMsg', type: 'information', value: { message: 'Texto removido com sucesso!' } })
            setShow(true)
            setLoading(false)
            setEdited(true)

        } catch (error) {
            setType({ name: 'alertMsg', type: 'information', value: { message: error?.response?.data?.message === undefined ? 'Erro ao excluir texto' : error?.response?.data?.message } })
            setShow(true)
            setLoading(false)
        }

    }
    function teste(props){
        // console.log(props)
    }
    return (

        user.token !== '' &&
        <>
            <Modal>
                {
                    type.name === 'alertMsg' ?
                        <ConfirmationBox msgData={type} /> :
                        type.name === 'showDashboardTextEditor' &&
                        <DashboardTextEditor />
                }
            </Modal>
            {user?.type != 4 ?
                <ChangeContentComponent props={{func: setCurrentContent}}/>
                :
                <></>
            }
            
            {currentContent == 1? <div className='dashboardContainer'>
                {/* <div className='dashboardLeftSide'>
                    <div className='dashboardUpSide'>
                        {
                            user?.type === 4 ?

                                (
                                    <label>
                                        <a id='dashboardMyclientsText'>Meus clientes mesa</a>
                                        <div className='dashboardCustomButton' onClick={() => history.push('/clientesMesa')}>
                                            <a>Acessar</a>
                                            <BsArrowRight />
                                        </div>
                                        <div id='dashboardIndicationButton' onClick={() => history.push(pageLocation)}>
                                            <a>Indicar um cliente para mesa RV</a>
                                            <BsArrowRight />
                                        </div>
                                    </label>
                                ) :
                                user?.type < 3 ?
                                    (
                                        <label>
                                            <div id='dashboardIndicationButton' onClick={() => history.push(pageLocation)}>
                                                <a>Aprovar indicações de clientes</a>
                                                <BsArrowRight />
                                            </div>
                                        </label>
                                    ) :
                                    (<></>)
                        }

                        <label>
                            <a id='dashboardMyclientsText'>Ofertas Publicas</a>
                            <div className='dashboardCustomButton' onClick={() => history.push('/acoes')}>
                                <a>Ações</a>
                                <BsArrowRight />
                            </div>
                            <div className='dashboardCustomButton' onClick={() => history.push('/fiis')}>
                                <a>Fundos imobiliários</a>
                                <BsArrowRight />
                            </div>

                        </label>
                        <label>
                            <a id='dashboardMyclientsText'>Estruturadas</a>
                            <div className='dashboardCustomButton' onClick={() => history.push('/clientesMesa')}>
                                <a>Vencimento Hoje</a>
                                <BsArrowRight />
                            </div>
                            <div className='dashboardCustomButton' onClick={() => history.push('/clientesMesa')}>
                                <a>Vencimento do Mês</a>
                                <BsArrowRight />
                            </div>
                        </label>
                        <label>
                            <a id='dashboardMyclientsText'>Relatórios Periódicos</a>

                            {
                                loading ?
                                    <div className='dashboardCustomButton' style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                        <div className='tileLoaderFullW'>
                                            <TileLoader />
                                        </div>
                                    </div> :
                                    periodicReports.map((report) => {
                                        return <div onClick={() => getReport(report.path_link, report.title, report.is_file)} className='dashboardCustomButton'>
                                            <a>{report.title}</a>
                                            <BsArrowRight />
                                        </div>
                                    })
                            }
                        </label>
                    </div>
                </div> */}
                <div className='dashboardRightSide' onScroll={(e) => setScrollPosition(e.currentTarget.scrollTop)}>
                    <div className='dashboardRightSideContent'>
                        <label id='dashboardWelcomeMsg'>
                            <h2>Bem vindo, {titleize(user?.full_name)}</h2>
                        </label>
                        {/* AQUI */}
                        {
                            user?.type === 4 &&
                            <>
                                <div className='wisirContainer'>
                                    <div id='dashboardWisir' style={{gridColumnStart: '1', gridColumnEnd: '2', width: '85%'}}>
                                        <label className='dashboardListTitle'>
                                            <h2>Índice de Penetração Acima de R$10k {clientsMesa.potentialClients > 0 ? '(' + (clientsMesa.netStockAbove10k * 100 / (clientsMesa.potentialClients + clientsMesa.netStockAbove10k)).toFixed(2) + '%)' : '(100.00%)'}</h2>

                                        </label>
                                        {
                                            loading ?
                                                <>
                                                    <div className='dashboardList'>
                                                        <div className='tileLoaderSm'>
                                                            <TileLoader />
                                                        </div>
                                                        <div className='dashBoardListLoaderRightSide'>
                                                            <div className='tileLoaderLg'>
                                                                <TileLoader />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='dashboardList'>
                                                        <div className='tileLoaderSm'>
                                                            <TileLoader />
                                                        </div>
                                                        <div className='dashBoardListLoaderRightSide'>
                                                            <div className='tileLoaderLg'>
                                                                <TileLoader />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='dashboardList'>
                                                        <div className='tileLoaderSm'>
                                                            <TileLoader />
                                                        </div>
                                                        <div className='dashBoardListLoaderRightSide'>
                                                            <div className='tileLoaderLg'>
                                                                <TileLoader />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                                :
                                                <div className='dashWisirItensBox'>
                                                    
                                                    
                                                    <div className='dashWisirItens'>
                                                        {/* <label title='Quantidade de clietes na Mesa' className='dashboardList' onClick={(e) => history.push('/clientesMesa') }>
                                                            <div id='dashboardLeftSide'>
                                                                <span title='Quantia Total de Clientes Registrados na Mesa de Renda Variável'>Clientes na Mesa RV</span>
                                                            </div>
                                                            <div id='dashboardListRightSide'>
                                                                <span>{clientsMesa.atual}</span>
                                                            </div>
                                                        </label> */}
                                                        <label title='Quantidade de clietes na Mesa' className='dashboardList' onClick={(e) => history.push('/clientesMesa') }>
                                                            <div id='dashboardLeftSide'>
                                                                <span title='Total de Clientes com Renda Variável'>Clientes na Mesa RV Acima de R$10k</span>
                                                            </div>
                                                            <div id='dashboardListRightSide'>
                                                                <span>{clientsMesa.netStockAbove10k}</span>
                                                            </div>
                                                        </label>
                                                        <label title='Quantidade de clietes na Mesa' className='dashboardList' onClick={(e) => history.push('/indicarClientes') }>
                                                            <div id='dashboardLeftSide'>
                                                                <span title='Clientes com Renda Variável Superior a R$10.000,00 Não Registrados na Mesa de Renda Variável'>Clientes com RV Fora da Mesa Acima de R$10k</span>
                                                            </div>
                                                            <div id='dashboardListRightSide'>
                                                                <span>{clientsMesa.potentialClients}</span>
                                                            </div>
                                                        </label>
                                                        
                                                        <label title='Quantidade de clietes Totais' className='dashboardListIndicate' style={{placeContent: 'center'}} onClick={(e) => history.push('/indicarClientes')}>
                                                            <div>
                                                                <h4 title='Indique seus Clientes para a Mesa de Renda Variável!'>Indicar Clientes</h4>
                                                            </div>
                                                        </label>
                                                    </div>
                                                </div>
                                        }
                                    </div>
                                    <div className='dashboardWisirTable' >
                                        <TableContainer component={Paper}>
                                            <Table aria-label="simple table" >
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell align="center">Produto</TableCell>
                                                        <TableCell align="right">Clientes</TableCell>
                                                        <TableCell align="right">Custódia</TableCell>
                                                        <TableCell align="right">Alocação</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {rows?.current?.map((row) => (
                                                        <TableRow key={row.name} sx={{ '&:last-child td, &:last-child th': { border: 0 }}} onClick={(e) => history.push(row.link)}>
                                                            <TableCell component="th" scope="row">{row.name}</TableCell>
                                                            <TableCell align="right">{row.clients}</TableCell>
                                                            <TableCell align="right">{row.custody}</TableCell>
                                                            <TableCell align="right">{row.alocated}</TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                        {/* <label className='dashboardListTitle'>
                                            <h2>Wisir Total Return ({wisirAcoes.alocatedDinamica}% alocados)</h2>
                                        </label>
                                        {
                                            loading ?
                                                <>
                                                    <div className='dashboardList'>
                                                        <div className='tileLoaderSm'>
                                                            <TileLoader />
                                                        </div>
                                                        <div className='dashBoardListLoaderRightSide'>
                                                            <div className='tileLoaderLg'>
                                                                <TileLoader />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='dashboardList'>
                                                        <div className='tileLoaderSm'>
                                                            <TileLoader />
                                                        </div>
                                                        <div className='dashBoardListLoaderRightSide'>
                                                            <div className='tileLoaderLg'>
                                                                <TileLoader />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='dashboardList'>
                                                        <div className='tileLoaderSm'>
                                                            <TileLoader />
                                                        </div>
                                                        <div className='dashBoardListLoaderRightSide'>
                                                            <div className='tileLoaderLg'>
                                                                <TileLoader />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                                :
                                                <div className='dashWisirItensBox2'>
                                                    <div className='dashWisirItens'>
                                                        <label title='Clientes Wisir Ações' className='dashboardList' onClick={(e) => history.push('/wisirAcoes')}>
                                                            <div id='dashboardLeftSide'>
                                                                <span title='Quantia Total de Clientes'>Qtd. Clientes</span>
                                                            </div>
                                                            <div id='dashboardListRightSide'>
                                                                <span>{wisirAcoes.clients}</span>
                                                            </div>
                                                        </label>
                                                        <label title='Financeiro Wisir Ações' className='dashboardList' onClick={(e) => history.push('/wisirAcoes')}>
                                                            <div id='dashboardLeftSide'>
                                                                <span title='Financeiro Total Ações Wisir'>Financeiro Alocado</span>
                                                            </div>
                                                            <div id='dashboardListRightSide'>
                                                                <span>{toBRL(wisirAcoes.custody)}</span>
                                                            </div>
                                                        </label>
                                                    </div>
                                                </div>
                                        } */}
                                    </div>
                                    {/* <div id='dashboardWisir'>
                                        <label className='dashboardListTitle'>
                                            <h2>Wisir Fundos Imobiliários ({wisirFiis.alocatedFiis}% alocados)</h2>
                                        </label>
                                        {
                                            loading ?
                                                <>
                                                    <div className='dashboardList'>
                                                        <div className='tileLoaderSm'>
                                                            <TileLoader />
                                                        </div>
                                                        <div className='dashBoardListLoaderRightSide'>
                                                            <div className='tileLoaderLg'>
                                                                <TileLoader />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='dashboardList'>
                                                        <div className='tileLoaderSm'>
                                                            <TileLoader />
                                                        </div>
                                                        <div className='dashBoardListLoaderRightSide'>
                                                            <div className='tileLoaderLg'>
                                                                <TileLoader />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='dashboardList'>
                                                        <div className='tileLoaderSm'>
                                                            <TileLoader />
                                                        </div>
                                                        <div className='dashBoardListLoaderRightSide'>
                                                            <div className='tileLoaderLg'>
                                                                <TileLoader />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                                :
                                                <div className='dashWisirItensBox2'>
                                                <div className='dashWisirItens'>
                                                    <label title='Clientes Wisir FIIs' className='dashboardList' onClick={(e) => history.push('/wisirFii')}>
                                                        <div id='dashboardLeftSide'>
                                                            <span title='Quantia Total de Clientes'>Qtd. Clientes</span>
                                                        </div>
                                                        <div id='dashboardListRightSide'>
                                                            <span>{wisirFiis.clients}</span>
                                                        </div>
                                                    </label>
                                                    <label title='Financeiro Wisir FIIs' className='dashboardList' onClick={(e) => history.push('/wisirFii')}>
                                                        <div id='dashboardLeftSide'>
                                                            <span title='Financeiro Total FIIs Wisir'>Financeiro Alocado</span>
                                                        </div>
                                                        <div id='dashboardListRightSide'>
                                                            <span>{toBRL(wisirFiis.custody)}</span>
                                                        </div>
                                                    </label>
                                                </div>
                                                </div>
                                        }
                                    </div> */}
                                    {/* <div id='dashboardWisir'> 
                                        <label className='dashboardListTitle'>
                                            <h2>Wisir Renda Imobiliária ({wisirRenda.alocatedFiis}% alocados)</h2>
                                        </label>
                                        {
                                            loading ?
                                                <>
                                                    <div className='dashboardList'>
                                                        <div className='tileLoaderSm'>
                                                            <TileLoader />
                                                        </div>
                                                        <div className='dashBoardListLoaderRightSide'>
                                                            <div className='tileLoaderLg'>
                                                                <TileLoader />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='dashboardList'>
                                                        <div className='tileLoaderSm'>
                                                            <TileLoader />
                                                        </div>
                                                        <div className='dashBoardListLoaderRightSide'>
                                                            <div className='tileLoaderLg'>
                                                                <TileLoader />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='dashboardList'>
                                                        <div className='tileLoaderSm'>
                                                            <TileLoader />
                                                        </div>
                                                        <div className='dashBoardListLoaderRightSide'>
                                                            <div className='tileLoaderLg'>
                                                                <TileLoader />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                                :
                                                <div className='dashWisirItensBox2'>
                                                <div className='dashWisirItens'>
                                                    <label title='Clientes Wisir Renda' className='dashboardList' onClick={(e) => history.push('/wisirRenda')}>
                                                        <div id='dashboardLeftSide'>
                                                            <span title='Quantia Total de Clientes'>Qtd. Clientes</span>
                                                        </div>
                                                        <div id='dashboardListRightSide'>
                                                            <span>{wisirRenda.clients}</span>
                                                        </div>
                                                    </label>
                                                    <label title='Financeiro Wisir Renda' className='dashboardList' onClick={(e) => history.push('/wisirRenda')}>
                                                        <div id='dashboardLeftSide'>
                                                            <span title='Financeiro Total Renda Wisir'>Financeiro Alocado</span>
                                                        </div>
                                                        <div id='dashboardListRightSide'>
                                                            <span>{toBRL(wisirRenda.custody)}</span>
                                                        </div>
                                                    </label>
                                                </div>
                                                </div>
                                        }
                                    </div> */}
                                </div>
                                <div className=''>
                                    {/* <label className='dashboardListTitle2'>
                                        <div className='cardBrokerTitle'>Contatos</div>
                                    </label> */}
                                    {
                                        loading ?
                                        <></>
                                        :
                                        <>
                                        <div className='containerCardsBrokers'>
                                            <div className='labelCardBroker' onMouseLeave={(e) => {if(fixedDefaultBrokerInfo == false){setShowInfoDefaultBroker(false);setFixedDefaultBrokerInfo(false)}}}>
                                                <span>Broker ({myBroker?.description})</span>
                                                <div className='hoverAreaBrokerDash' style={{transform: "translateY(-"+scrollPosition+"px)"}} onMouseEnter={(e) => {
                                                    setShowInfoDefaultBroker(true)
                                                    if(fixedDefaultBrokerInfo == false){
                                                        if(menuStatus == 0){
                                                            setInfoDefaultBroker({celular: InfoDefaultBroker.celular, email: InfoDefaultBroker.email, filial: InfoDefaultBroker.filial, x: e.clientX, y: e.clientY})
                                                        }
                                                        else{
                                                            setInfoDefaultBroker({celular: InfoDefaultBroker.celular, email: InfoDefaultBroker.email, filial: InfoDefaultBroker.filial, x: e.clientX-200, y: e.clientY})
                                                        }
                                                    }
                                                }} onMouseLeave={(e) => {if(fixedDefaultBrokerInfo == false){setShowInfoDefaultBroker(false);setFixedDefaultBrokerInfo(false)}}} onMouseMove={(e) => {
                                                        if(fixedDefaultBrokerInfo == false){
                                                            if(menuStatus == 0){
                                                                setInfoDefaultBroker({celular: InfoDefaultBroker.celular, email: InfoDefaultBroker.email, filial: InfoDefaultBroker.filial, x: e.clientX, y: e.clientY})
                                                            }
                                                            else{
                                                                setInfoDefaultBroker({celular: InfoDefaultBroker.celular, email: InfoDefaultBroker.email, filial: InfoDefaultBroker.filial, x: e.clientX-200, y: e.clientY})
                                                            }
                                                        }
                                                    }} onClick={(e) => {
                                                        setFixedDefaultBrokerInfo(!fixedDefaultBrokerInfo)
                                                        if(menuStatus == 0){
                                                            setInfoDefaultBroker({celular: InfoDefaultBroker.celular, email: InfoDefaultBroker.email, filial: InfoDefaultBroker.filial, x: e.clientX, y: e.clientY})
                                                        }
                                                        else{
                                                            setInfoDefaultBroker({celular: InfoDefaultBroker.celular, email: InfoDefaultBroker.email, filial: InfoDefaultBroker.filial, x: e.clientX-200, y: e.clientY})
                                                        }
                                                    }}></div>
                                                <div className='cardBrokerImgframe'> 
                                                    {fixedDefaultBrokerInfo ? 
                                                        <div className='overlayBrokerImgInfoFixed' style={{transform: "translate(-0.2rem,calc(-0.2rem - "+scrollPosition+"px))"}}><BiDetail color='gray' size='100'style={{paddingTop: "3rem"}}/></div> : <></>
                                                    }
                                                    {blobDefaultBroker == null ? 
                                                        (<img className='cardBrokerImg' srcSet={defaultAvatar} />) : 
                                                        (<img className='cardBrokerImg' srcSet={blobDefaultBroker} />)
                                                    }
                    
                                                </div>
                                                <span>{myBroker?.full_name}</span>
                                            </div>
                                            <div className='labelCardBroker' onMouseLeave={(e) => {if(fixedBrokerLeaderInfo == false){setShowInfoBrokerLeader(false);setFixedBrokerLeaderInfo(false)}}}>
                                                <span>Líder</span>
                                                <div className='hoverAreaBrokerDash' style={{transform: "translateY(-"+scrollPosition+"px)"}} onMouseEnter={(e) => {setShowInfoBrokerLeader(true)
                                                    if(fixedBrokerLeaderInfo == false){
                                                        if(menuStatus == 0){
                                                            setInfoBrokerLeader({celular: InfoBrokerLeader.celular, email: InfoBrokerLeader.email, filial: InfoBrokerLeader.filial, x: e.clientX, y: e.clientY})
                                                        }
                                                        else{
                                                            setInfoBrokerLeader({celular: InfoBrokerLeader.celular, email: InfoBrokerLeader.email, filial: InfoBrokerLeader.filial, x: e.clientX-200, y: e.clientY})
                                                        }
                                                    }
                                                }} onMouseLeave={(e) => {if(fixedBrokerLeaderInfo == false){setShowInfoBrokerLeader(false);setFixedBrokerLeaderInfo(false)}}} onMouseMove={(e) => {
                                                        if(fixedBrokerLeaderInfo == false){
                                                            if(menuStatus == 0){
                                                                setInfoBrokerLeader({celular: InfoBrokerLeader.celular, email: InfoBrokerLeader.email, filial: InfoBrokerLeader.filial, x: e.clientX, y: e.clientY})
                                                            }
                                                            else{
                                                                setInfoBrokerLeader({celular: InfoBrokerLeader.celular, email: InfoBrokerLeader.email, filial: InfoBrokerLeader.filial, x: e.clientX-200, y: e.clientY})
                                                            }
                                                        }
                                                    }} onClick={(e) => {
                                                        setFixedBrokerLeaderInfo(!fixedBrokerLeaderInfo)
                                                        if(menuStatus == 0){
                                                            setInfoBrokerLeader({celular: InfoBrokerLeader.celular, email: InfoBrokerLeader.email, filial: InfoBrokerLeader.filial, x: e.clientX, y: e.clientY})
                                                        }
                                                        else{
                                                            setInfoBrokerLeader({celular: InfoBrokerLeader.celular, email: InfoBrokerLeader.email, filial: InfoBrokerLeader.filial, x: e.clientX-200, y: e.clientY})
                                                        }
                                                    }}></div>
                                                <div className='cardBrokerImgframe'>  
                                                    {fixedBrokerLeaderInfo ? 
                                                        <div className='overlayBrokerImgInfoFixed' style={{transform: "translate(-0.2rem,calc(-0.2rem - "+scrollPosition+"px))"}}><BiDetail color='gray' size='100'style={{paddingTop: "3rem"}}/></div> : <></>
                                                    }
                                                    {blobBrokerLeader == null ? 
                                                        (<img className='cardBrokerImg' srcSet={defaultAvatar} />) : 
                                                        (<img className='cardBrokerImg' srcSet={blobBrokerLeader} />)
                                                    }
                                                </div>
                                                <span>{myBrokerLeader?.leader_name}</span>
                                            </div>
                                            {ShowInfoDefaultBroker ? 
                                                <InfoBoxDash key={"infoBroker"} fixed={fixedDefaultBrokerInfo} style={{transform: "translate(-50%,-"+scrollPosition+"px)"}} msgData={InfoDefaultBroker}/> :
                                                <div></div>
                                            }
                                            {ShowInfoBrokerLeader ? 
                                                <InfoBoxDash key={"infoBrokerLeader"} fixed={fixedBrokerLeaderInfo} style={{transform: "translate(-50%,-"+scrollPosition+"px)"}} msgData={InfoBrokerLeader}/> :
                                                <div></div>
                                            }
                                        </div>
                                        </>
                                    }
                                </div>
                            </>
                        }
                        {
                            (user?.type === 3 || user?.type === 2 || user?.type === 1)  &&
                            <>
                            
                            <div className='testeBrokerDiv'>
                                <div className="unsuitedStructuredContainer">
                                    <Modal>{type.name === "alertMsg" ? <ConfirmationBox msgData={type} /> : <></>}</Modal>
                                    <div className="estructuredsBody">
                                        <div className="estructuredsTab">
                                            <div
                                                className={tabActive === 1 ? "estructuredsTabOption" : "estructuredsInactiveTabOption"}
                                                onClick={() => setTabActive(1)}
                                            >
                                                <p>Saldo Negativo</p>
                                                <div></div>
                                            </div>
                                            <div className={tabActive === 2 ? 'estructuredsTabOption' : 'estructuredsInactiveTabOption'} onClick={() => setTabActive(2)}>
                                                <p>Saldo Positivo</p>
                                                <div></div>
                                            </div>
                                            <div className={tabActive === 3 ? 'estructuredsTabOption' : 'estructuredsInactiveTabOption'} onClick={() => setTabActive(3)}>
                                                <p>Posições Vendidas</p>
                                                <div></div>
                                            </div>
                                            <div className={tabActive === 4 ? 'estructuredsTabOption' : 'estructuredsInactiveTabOption'} onClick={() => setTabActive(4)}>
                                                <p>Termos a Vencer</p>
                                                <div></div>
                                            </div>
                                            <div className={tabActive === 5 ? 'estructuredsTabOption' : 'estructuredsInactiveTabOption'} onClick={() => setTabActive(5)}>
                                                <p>Operações não Executadas</p>
                                                <div></div>
                                            </div>
                                            {/* <div className={tabActive === 6 ? 'estructuredsTabOption' : 'estructuredsInactiveTabOption'} onClick={() => setTabActive(6)}>
                                                <p>Receita Parcial</p>
                                                <div></div>
                                            </div> */}
                                        </div>
                                        {tabActive === 1 ? 
                                        <NegativeBalanceTbl /> : tabActive === 2 ? 
                                        <OpportunityBalanceTbl /> : tabActive === 3 ? 
                                        <NegativeBTCTable /> : tabActive === 4 ?
                                        <AssetForwardTbl /> : <NotExecutedTbl /> 
                                        // <NotExecutedTbl /> : <IndexProjectedTbl />
                                    }
                                    </div>
                                </div>
                            </div>
                            
                            </>



                        }
                        {/* <div className='dashboardBoxContainer'>
                            <div id='dasboardTopTradersContent'>
                                <div className='dashboardListTitle'>
                                    <h2>Top Trades</h2>
                                    {user.type < 3 && <FiPlus id='dashboardNewTextButton' title='Clique para cadastrar um novo texto' onClick={() => { setType({ name: 'showDashboardTextEditor' }); setShow(true) }}>Novo texto</FiPlus>}
                                </div>
                                <div className='dasboardListContent'>
                                    {
                                        loading ?
                                            <>
                                                <div className='dashboardList'>
                                                    <div className='tileLoaderSm'>
                                                        <TileLoader />
                                                    </div>
                                                    <div className='dashBoardListLoaderRightSide'>
                                                        <div className='tileLoaderLg'>
                                                            <TileLoader />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='dashboardList'>
                                                    <div className='tileLoaderSm'>
                                                        <TileLoader />
                                                    </div>
                                                    <div className='dashBoardListLoaderRightSide'>
                                                        <div className='tileLoaderLg'>
                                                            <TileLoader />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='dashboardList'>
                                                    <div className='tileLoaderSm'>
                                                        <TileLoader />
                                                    </div>
                                                    <div className='dashBoardListLoaderRightSide'>
                                                        <div className='tileLoaderLg'>
                                                            <TileLoader />
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            dashboardTextList?.length > 0 ?
                                                dashboardTextList.map((dashboardText) => {
                                                    return (
                                                        <div id='dashboardTopTradesContainer'>
                                                            <div id='dashboardTopTradeButtons'>
                                                                {user.type < 3 && <FiTrash2 title='Clique para remover essa linha' onClick={() => handleDeleteText(dashboardText?.id)} />}
                                                                {user.type < 3 && <FiEdit2 title='Clique para editar essa linha'  onClick={() => handleTextEditor(dashboardText)} />}
                                                            </div>
                                                            <div className='dashboardList'>
                                                                <div id='dashboardListRigthSide'>
                                                                    <a>{dashboardText?.title}</a>
                                                                </div>
                                                                <div className='dashboardListLeftSide' dangerouslySetInnerHTML={{ __html: dashboardText?.details }}></div>
                                                            </div>
                                                        </div>
                                                    )
                                                }) :
                                                <label className='dashboardList'>
                                                    <div id='dashboardListRigthSide'>
                                                        <a>Sem itens cadastrados</a>
                                                    </div>
                                                </label>
                                    }
                                </div>
                            </div>
                        </div> */}
                        <div className='dashboardBoxContainer'>
                            <div id='dasboardLastUpdatesContent'>
                                <label className='dashboardListTitle'>
                                    <h2>Últimas atualizações</h2>
                                </label>
                                <div className='dasboardListContent'>
                                    {
                                        loading ?
                                            <>
                                                <div className='dashboardList'>
                                                    <div className='tileLoaderSm'>
                                                        <TileLoader />
                                                    </div>
                                                    <div className='dashBoardListLoaderRightSide'>
                                                        <div className='tileLoaderLg'>
                                                            <TileLoader />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='dashboardList'>
                                                    <div className='tileLoaderSm'>
                                                        <TileLoader />
                                                    </div>
                                                    <div className='dashBoardListLoaderRightSide'>
                                                        <div className='tileLoaderLg'>
                                                            <TileLoader />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='dashboardList'>
                                                    <div className='tileLoaderSm'>
                                                        <TileLoader />
                                                    </div>
                                                    <div className='dashBoardListLoaderRightSide'>
                                                        <div className='tileLoaderLg'>
                                                            <TileLoader />
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            reports.map((report) => {
                                                return (
                                                    <label title='Clique para acessar' className={report?.is_today_report ? 'dashboardListToday' : 'dashboardList'} onClick={() => getReport(report.path_link, report.title, report.is_file)}>
                                                        <div id='dashboardListRigthSide'>
                                                            <a>{report?.type === 'rep' ? "Relatório" : "FAQ"}</a>
                                                        </div>
                                                        <div id='dashboardListMiddleSide'>
                                                            <a>{report?.updated_at}</a>
                                                        </div>
                                                        <div id='dashboardLeftSide'>
                                                            <a title={report?.title}>{report?.title}</a>
                                                        </div>
                                                    </label>
                                                )
                                            })
                                    }
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            :
            <>
                {currentContent == 2?
                    <IndexProjectedTbl />
                    :
                    <>
                        {viewMessage ? 
                            <>
                                <div className='containerViewMessage'>
                                    <div className='containerTitleViewMessage'>
                                        {user.type === 1 ?
                                            <button onClick={() => {setViewMessage(false);setSelectedDocs([])}} className='containerButtonChangeMessage'>
                                                <div className='iconChangeMessage'><FiEdit size={22}/></div>
                                                <div className='textReloadFile'>Trocar recado</div>
                                            </button>
                                            :
                                            <div></div>
                                        }
                                        <div className='titleViewMessage'>Recados Mesa RV</div>
                                        {/* <div className='dateViewMessage'>Postado em: 29/10/2001</div> */}
                                    </div>
                                    <div className='containerDocViewerViewMessage'>
                                        <DocViewer
                                            documents={selectedDocs.map((file) => ({
                                            uri: window.URL.createObjectURL(file),
                                            fileName: file.name,
                                            }))}
                                            pluginRenderers={DocViewerRenderers}
                                            prefetchMethod="GET"
                                            config={{
                                                header: {
                                                    disableHeader: true,
                                                    disableFileName: false,
                                                    retainURLParams: false,
                                                },
                                                pdfZoom: {
                                                defaultZoom: 1, // 1 as default,
                                                zoomJump: 0.2, // 0.1 as default,
                                                },
                                                pdfVerticalScrollByDefault: true, // false as default
                                            }}
                                        />
                                    </div>
                                </div>
                            </>
                            :
                            <>
                            <button onClick={() => {setViewMessage(true);setSelectedDocs([]);getDbMessage()}} className='containerButtonChangeViewMessage'>
                                <div className='iconChangeView'><BsArrowBarLeft size={22}/></div>
                                <div className='textReloadFile'>Voltar para visualização</div>
                            </button>
                            {selectedDocs?.length > 0?
                                <>
                                    <div className='containerHeaderRecadosDash'>
                                        <button onClick={() => {setSelectedDocs([])}} className='containerButtonRecadosDashLeft'>
                                            <div className='iconReloadFileLeft'><AiOutlineFileSync size={22}/></div>
                                            <div className='textReloadFile'>Carregar outro arquivo</div>
                                        </button>
                                        <div className='containerFileNameRecadosDash'><div className='fileNameRecadosDash'>{docName} - {docSize} KB</div></div>
                                        <button onClick={() => {sendMessage()}} className='containerButtonRecadosDashRight'>
                                            <div className='textReloadFile'>Utilizar arquivo selecionado</div>
                                            <div className='iconReloadFileRight'><AiOutlineFileDone size={22}/></div>
                                        </button>
                                    </div>
                                    <div className='containerDocViewerRecadosDash'>
                                        <DocViewer
                                            documents={selectedDocs?.map((file) => ({
                                            uri: window.URL.createObjectURL(file),
                                            fileName: file.name,
                                            }))}
                                            pluginRenderers={DocViewerRenderers}
                                            prefetchMethod="GET"
                                            config={{
                                                header: {
                                                disableHeader: true,
                                                disableFileName: false,
                                                retainURLParams: false,
                                                },
                                                pdfZoom: {
                                                defaultZoom: 1, // 1 as default,
                                                zoomJump: 0.2, // 0.1 as default,
                                                },
                                                pdfVerticalScrollByDefault: true, // false as default
                                            }}
                                        />
                                    </div>
                                    <div className='containerFooterRecadosDash'></div>
                                </>    
                                :
                                <div className='containerDragNDrop'>
                                    <div className='titleDragNDrop'>Selecione o arquivo que será utilizado nos recados.</div>
                                    <div {...getRootProps()} className="dragNDropArea">
                                        <div className='iconDragNDrop'><CiImport size={200}/></div>
                                        <input {...getInputProps()} />
                                        {
                                        isDragActive ?
                                            <p className='dragNDropText'>Drop the files here ...</p> :
                                            <p className='dragNDropText'>Arraste seu arquivo .PDF para a caixa, ou clique para selecionar o arquivo através do explorador de arquivo</p>
                                        }
                                    </div>
                                </div>
                            }
                            </>
                        }
                    </>
                }
            </>
            }
            {showAlertVar?
                <Alert data={{
                    type: "message",
                    // title: "ASDSAD",
                    description:"Faça o upload de um arquivo no formato .PDF!",
                    closeAlertVarFunc: setShowAlertVar,
                }}/>
                :
                <></>
            }
        </>
    )
}