/*Contexto para ser utilizado nas operações*/
/*Desenvolvido por: Equipe Rafael Cezário*/

import React, { createContext, useContext, useState } from 'react'

export const BooksContext = createContext({});
export const BooksProvider = ({ children }) => {
    const [loadingOrder, setLoadingOrder] = useState(false);
    const [ordersWithErrors, setOrdersWithErrors] = useState([]);
    const [loadOrders, setLoadOrders] = useState(false);

    return (
        <BooksContext.Provider value={{
            loadingOrder,
            setLoadingOrder,
            ordersWithErrors,
            setOrdersWithErrors,
            loadOrders,
            setLoadOrders

        }}>
            {children}
        </BooksContext.Provider>
    )
}

export function useBooks(){
    return useContext(BooksContext);
}