/*Tela Dashboard Assessor*/
/*Desenvolvido por: Equipe Tech RV*/

import React, { useEffect, useState, useRef, useReducer } from "react"
import { Link } from "react-router-dom"
import MUIDataTable from "mui-datatables"
import { debounceSearchRender } from "mui-datatables"
import { ThemeProvider, createTheme } from "@mui/material/styles"
import { Slide, TableCell, Fade } from "@mui/material"
import { IconButton, Grow } from "@material-ui/core"
import { datetimeToDatePtBR } from "../../../utils/formatDate/dateTimeToPTBR"
import { Tooltip } from "@mui/material"

/*Styles*/
import "../dashboard/dashboard.css"
import "../dashboard/styles/stylesPortal360.css"
import ContentCopyIcon from "@mui/icons-material/ContentCopy"

import { ArrowLoader } from "../../../components/loaders/arrowLoader"
import { FormControl, ListItemText, Checkbox, Select, InputLabel, MenuItem } from "@mui/material"
import { BsQuestionCircle } from "react-icons/bs"
import { AiOutlineLeftSquare, AiOutlineRightSquare } from "react-icons/ai"
import { AiOutlineArrowUp, AiOutlineArrowDown } from "react-icons/ai"
import { FaCheck, FaTimes, FaStar, FaQuestion, FaArrowAltCircleUp, FaArrowAltCircleDown } from "react-icons/fa"
import { BsFillSquareFill } from "react-icons/bs"
/*Utils*/
import { titleize } from "../../../utils/formatNumberAndText/formatTextUpperFirstLetter"
import CustomFooter from "../dashboard/CustomFooterTableDashAdvisor"
import CustomFooterConfig from "../dashboard/CustomFooterTableDashAdvisorConfig"
import { saveAs } from "file-saver"
import { utils, write } from "xlsx"

/*Contexts*/
import { useAuth } from "../../../contexts/useAuth"
import { useModal } from "../../../contexts/useModal"
/*Services*/
import { api } from "../../../services/api"
import { getDashAlocacaoTableCampains, getDashAlocacaoTableConfiguration, getDashAlocacaoTableOptions, getDashAlocacaoEvents, getDatabaseReferenceTime, getCampaignsRV } from "../../../services/asyncFunctions"
/*components*/
import { toBRL, toBRLInteger } from "../../../utils/formatNumberAndText/toBRLCurrency"

import { BsFire } from "react-icons/bs"
import { BiCalendar, BiCalendarStar, BiDownload, BiLock } from "react-icons/bi"
import { TbCalendarStats } from "react-icons/tb"
import { MdPermContactCalendar } from "react-icons/md"

import { RiEqualizerFill } from "react-icons/ri"
//importar carrousel
import CampainsCarrousel from "../dashboard/components/campainsCarrousel"
import { toPercent } from "../../../utils/formatNumberAndText/formatNumberPercent"
import TableViewColDash from "../../../utils/muiDatatables/TableviewColDash"


export function ClientsAbcTable(props) {
    const { user, tokenKey, userType } = useAuth()
    const token = localStorage.getItem("@auth:token")
    const { show, setShow, setType, edited } = useModal()
    const [loading, setLoading] = useState(false)
    const [loadingTable, setLoadingTable] = useState(false)
    const variavel = useRef({ grupo1: false })
    const [, forceUpdate] = useReducer((x) => x + 1, 0)
    const columnsToDisplay = useRef() // Inicializa como um array vazio
    const [okay, setOkay] = useState(false)
    const sumNetStock = useRef(0)
    const sumRoaStock = useRef(0)
    const sumNetFii = useRef(0)
    const myClient = useRef()
    const changedDay = useRef(false) // em caso de mudança de dia, os produtos ficarão todos visiveis novamente
    const [columnVisibility, setColumnVisibility] = useState({})
    const sumPosRV = useRef(0);
    const columnsExceptionView = ["codigo","nome", 'profile', 'campain_base','pl_custom','ple','knowledge','products','objective','propensity_to_accept_campains','max_term', 'necessary_liq']
    const serviceModel = [
        { value: 1, description: 'Ultra High' },
        { value: 2, description: 'Top Tier'},
        { value: 3, description: 'Private'},
        { value: 4, description: 'Digital'},
        { value: 5, description: 'On Demand'},
    ]
    const getMuiTheme = () =>
        createTheme({
            components: {
                MuiTableCell: {
                    styleOverrides: {
                        root: {
                            textAlign: "center",
                            justifyContent: "center",
                            whiteSpace: "nowrap",
                            backgrounColor: "white",
                        },
                    },
                },
                MuiToolbar: {
                    styleOverrides: {
                        root: {
                            minHeight: "30px !important",
                        },
                    },
                },
                MuiCheckbox: {
                    styleOverrides: {
                        root: {
                            marginLeft: "10px", // Aumenta o padding dos checkboxes para 10px
                        },
                    },
                },

                MUIDataTableHeadCell: {
                    styleOverrides: {
                        contentWrapper: {
                            justifyContent: "center",
                            whiteSpace: "pre-wrap",
                        },
                    },
                },

                MUIDataTableFilter: {
                    styleOverrides: {
                        root: {
                            minWidth: "40vw",
                            maxWidth: "50vw",
                        },
                    },
                },
                MuiTableRow: {
                    styleOverrides: {
                        root: {
                            "&:hover ": {
                                backgroundColor: "#e7e7e7 !important",
                            },
                            "&: hover td": {
                                backgroundColor: "#e7e7e7 !important",
                            },
                        },
                    },
                },
            },
            root: {
                "&::-webkit-scrollbar": {
                    width: 7,
                },
                "&::-webkit-scrollbar-track": {
                    boxShadow: `inset 0 0 6px #bea032`,
                },
                "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "#bea032",
                    outline: `1px solid slategrey`,
                },
            },
        })

    function changeVisibleColumns(grupo) {
        variavel.current[grupo] = !variavel.current[grupo]
        assembleTable()
        forceUpdate()
    }

    const updateColumnVisibility = (columnName, isVisible) => {
        setColumnVisibility((prev) => ({ ...prev, [columnName]: isVisible }))
    }

    const clientsResult = useRef()
    const listClients = useRef()
    const listProducts = useRef()
    const listProductsPermissionsAll = useRef()
    const listProductsPermissionsMacro = useRef()
    const listProductsPermissionsMicro = useRef()
    const listCampaignsPermissions = useRef()
    const visibleColumnsRef = useRef([])
    const sum = useRef({})
    const sumNet = useRef(0)
    const sumRoa = useRef(0)
    const sumSaldo = useRef(0)
    const permissions = useRef([])
    const [lastPage, setLastPage] = useState(0)
    const [currentPage, setCurrentPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(150)
    const [search, setSearch] = useState("")
    const [databaseUpdateTime, setDatabaseUpdateTime] = useState("")
    const [crmUpdateTime, setCrmUpdateTime] = useState("")
    const [positivadorUpdateTime, setPositivadorUpdateTime] = useState("")
    const [visibleInteractionDaysType, setVisibleInteractionDaysType] = useState(0);
    const teamClients = useRef([])
    const [loadClients, setLoadClients] = useState(false)
    const [clientsMyTeam, setClientsMyTeam] = useState([])
    const tabActive = useRef(1)
    // tabActive === 0 ? tabActive.current = 1 : tabActive.current = 0

    const [selectedCampaign, setSelectedCampaign] = useState(null)
    const listFilterStatusProducts = useRef({})
    const listSearchedClients = useRef([])
    const listFilteredClients = useRef([])
    const filtersList = useRef({})
    const configuration = useRef({})
    const optionsConfiguration = useRef({})
    const [clientsSettlements, setClientsSettlements] = useState({})
    const [eventsDash, setEventsDash] = useState([])
    const [currentFilters, setCurrentFilters] = useState({})
    const [juntarColunas, setJuntarColunas] = useState(false)
    const tableChanged = useRef(false)
    const clientsDash = useRef([])
    const lastSortOrder = useRef({
        name: "nome",
        direction: "asc",
    })
    const refDataTable = React.useRef(null)

    const positions = useRef({
        rowsPerPage: 50,
        currentPage: 0,
    })
    // const feeFixo = useRef({})
    const campaignPriority = useRef([])
    const linesCount = useRef(0)
    const [alturaTabela, setAlturaTabela] = useState(null)
    const [listaClientesPorProduto, setListaClientesPorProduto] = useState([])
    const [retracaoCarrousel, setRetracaoCarrousel] = useState(false)

    async function getDataOptions() {
        const optionsAux = await getDashAlocacaoTableOptions(tokenKey)
        optionsConfiguration.current = optionsAux
    }
    async function getDataEvents() {
        const eventsAux = await getDashAlocacaoEvents(tokenKey)
        setEventsDash(eventsAux)
    }

    async function getDataPermissions() {
        const configurationAux = await getDashAlocacaoTableConfiguration(tokenKey)
        configuration.current = configurationAux

        //todas as keys de uma linha da tabela
        const keys = Object?.keys(configurationAux?.table[0])
        //apenas as colunas de campanhas
        const listCampaignsPermissionsAux = keys.filter((item) => item.startsWith("c") && item.endsWith("__permissions_id"))
        listCampaignsPermissions.current = listCampaignsPermissionsAux

        permissions.current = configuration.current.permissions

        // apenas as grandes areas dos produtos : ex: RV,cambio,crédito...
        const listProductsPermissionsAux2 = permissions.current.filter((item) => item.name.split("__").length < 3)

        //apenas as subareas dos produtos : ex: mesa_rv, wisir_fii, wisir_acoes...
        const listProductsPermissionsAux3 = permissions.current.filter((item) => item.name.split("__").length > 2)

        listProductsPermissionsMacro.current = listProductsPermissionsAux2
        listProductsPermissionsAll.current = permissions.current

        permissions.current.forEach((p, pi) => {
            variavel.current["grupo" + (pi + 2)] = false
        })

        listProductsPermissionsMicro.current = listProductsPermissionsAux3
    }

    // async function setColumnsLocal(  ) {
    //     let viewColumns;

    //     if (localStorage.getItem("columnsAbc")) {
    //         // Carrega do localStorage
    //         viewColumns = JSON.parse(localStorage.getItem("columnsAbc"));
    //         const updatedColumns = visibleColumnsRef.current.map(col => {
    //             const localStorageColumn = viewColumns.find(vc => vc.column === col.name);
    //             return {
    //                 ...col,
    //                 options: {
    //                     ...col.options,
    //                     display: localStorageColumn ? localStorageColumn.display : true
    //                 }
    //             };
    //         });
    //         setColumnsLocal()
    //         setOkay(true); // Garante que 'setOkay' esteja definido e utilizado corretamente
    //         return viewColumns
    //     } else {
    //         viewColumns = visibleColumnsRef.current.map(e => ({
    //             column: e.name,
    //             display: true // Aqui você pode definir um valor padrão para 'display'
    //         }));
    //         const updatedColumns = visibleColumnsRef.current.map(col => {
    //             const localStorageColumn = viewColumns.find(vc => vc.column === col.name);
    //             return {
    //                 ...col,
    //                 options: {
    //                     ...col.options,
    //                     display: localStorageColumn ? localStorageColumn.display : true
    //                 }
    //             };
    //         });
    //         localStorage.setItem("columnsAbc", JSON.stringify(viewColumns));
    //         return setColumnsLocal()
    //     }

    //     // Atualizando as colunas para exibir com base nos dados carregados
    //     const updatedColumns = visibleColumnsRef.current.map(col => {
    //         const localStorageColumn = viewColumns.find(vc => vc.column === col.name);
    //         return {
    //             ...col,
    //             options: {
    //                 ...col.options,
    //                 display: localStorageColumn ? localStorageColumn.display : true
    //             }
    //         };
    //     });

    //     setColumnsToDisplay(updatedColumns);
    //     setOkay(true); // Garante que 'setOkay' esteja definido e utilizado corretamente
    // }
    


    let viewColumns = []    
    async function  setColumnsLocal() {
        const today = new Date()
        const lastResetDayString = localStorage.getItem("lastResetDate")
        const lastResetDay = new Date(lastResetDayString)

        today.setHours(0, 0, 0, 0)
        lastResetDay.setHours(0, 0, 0, 0)

        if (localStorage?.getItem("columnsAbc")) {
            viewColumns = JSON.parse(localStorage.getItem("columnsAbc"))

        } else {
            viewColumns = visibleColumnsRef.current.map(e => ({
                column: e.name,
                display: true,
            }))
            viewColumns = viewColumns.concat(listProducts.current.map(p => {
                const finishDate = new Date(p.finish)
                finishDate.setHours(0, 0, 0, 0)

                const display = finishDate >= today ? true : false
                return {
                    column: `c${p.id}__objective`,
                    display: true,
                }
            }))
            visibleColumnsRef.current.map(e => {
                const finishDate = new Date(e.finish)
                finishDate.setHours(0, 0, 0, 0)

                const display = finishDate >= today ? true : false
                return {
                    column: `c${e.id}__objective`,
                    display: display,
                }
            })
            localStorage.setItem("columnsAbc", JSON.stringify(viewColumns))

            localStorage.setItem("columnsAbc", JSON.stringify(viewColumns))
            ;
            viewColumns = viewColumns.concat(listProducts.current.map(p => {
                const finishDate = new Date(p.finish)
                finishDate.setHours(0, 0, 0, 0)
                finishDate.setDate(finishDate.getDate() + 1)

                const display = finishDate >= today ? true : false
                
                return {
                    column: `c${p.id}__objective`,
                    display: display,
                }
            }));

            viewColumns = viewColumns.concat(listProducts.current.map(p => {
                const finishDate = new Date(p.finish)
                finishDate.setHours(0, 0, 0, 0)
                finishDate.setDate(finishDate.getDate() + 1)

                const display = finishDate >= today ? true : false
                
                return {
                    column: `c${p.id}__value`,
                    display: display,
                }
            }));

            viewColumns = viewColumns.concat(listProducts.current.map(p => {
                const finishDate = new Date(p.finish)
                finishDate.setHours(0, 0, 0, 0)
                finishDate.setDate(finishDate.getDate() + 1)

                const display = finishDate >= today ? true : false
                
                return {
                    column: `c${p.id}__receita_estimada`,
                    display: display,
                }
            }));
            
            viewColumns = viewColumns.concat(listProducts.current.map(p => {
                const finishDate = new Date(p.finish)
                finishDate.setHours(0, 0, 0, 0)
                finishDate.setDate(finishDate.getDate() + 1)

                const display = finishDate >= today ? true : false
                
                return {
                    column: `c${p.id}__status`,
                    display: display,
                }
            }));
        
            localStorage.setItem("columnsAbc", JSON.stringify(viewColumns));
        }
        

        // Nomes das colunas da campanha selecionada
        listProducts?.current?.forEach((product) => {
            ;["objective", "value", "receita_estimada", "status"].forEach((suffix) => {
                const columnName = `c${product.id}__${suffix}`
                if (!viewColumns.some((vc) => vc.column === columnName)) {
                    viewColumns.push({ column: columnName, display: true })
                }
            })
        })

        // Compara as datas  colocando displat como true, mas apenas se a data de hoje for maior que a data do ultimo reset quero fazer um verificação também  no visibleColumnsRef.current, se o e.finish for maior que a data de hoje, então eu coloco como true, se não eu coloco como false se nao tiver e.finish eu coloco como true
        if (today > lastResetDay || !localStorage.getItem("lastResetDate")) {
            viewColumns = visibleColumnsRef.current.map(e => ({
                column: e.name,
                display: true,
            }));
        
            // Concatena os produtos ao array de colunas
            viewColumns = viewColumns.concat(listProducts.current.map(p => {
                const finishDate = new Date(p.finish)
                finishDate.setHours(0, 0, 0, 0)
                finishDate.setDate(finishDate.getDate() + 1)

                const display = finishDate >= today ? true : false
                
                return {
                    column: `c${p.id}__objective`,
                    display: display,
                }
            }));

            viewColumns = viewColumns.concat(listProducts.current.map(p => {
                const finishDate = new Date(p.finish)
                finishDate.setHours(0, 0, 0, 0)
                finishDate.setDate(finishDate.getDate() + 1)

                const display = finishDate >= today ? true : false
                
                return {
                    column: `c${p.id}__value`,
                    display: display,
                }
            }));

            viewColumns = viewColumns.concat(listProducts.current.map(p => {
                const finishDate = new Date(p.finish)
                finishDate.setHours(0, 0, 0, 0)
                finishDate.setDate(finishDate.getDate() + 1)

                const display = finishDate >= today ? true : false
                
                return {
                    column: `c${p.id}__receita_estimada`,
                    display: display,
                }
            }));

            viewColumns = viewColumns.concat(listProducts.current.map(p => {
                const finishDate = new Date(p.finish)
                finishDate.setHours(0, 0, 0, 0)
                finishDate.setDate(finishDate.getDate() + 1)

                const display = finishDate >= today ? true : false
                
                return {
                    column: `c${p.id}__status`,
                    display: display,
                }
            }));
        
            localStorage.setItem("columnsAbc", JSON.stringify(viewColumns));
            localStorage.setItem("lastResetDate", today);
        } else {
            viewColumns = JSON.parse(localStorage.getItem("columnsAbc"))
        }

        // // Atualiza columnsToDisplay e localStorage
        // columnsToDisplay.current = viewColumns;
        // localStorage.setItem('columnsAbc', JSON.stringify(viewColumns));
        // const updatedColumns = visibleColumnsRef.current.map(col => {
        //     const localStorageColumn = columnsToDisplay.current?.find(vc => vc.column === col.name);
        //     return {
        //         ...col,
        //         options: {
        //             ...col.options,
        //             display: localStorageColumn ? localStorageColumn.display : true
        //         }
        //     };

        // });

        columnsToDisplay.current = viewColumns
        setOkay(true)
    }

    // useEffect(() => {
    //     let viewColumns = [];
    //     if (localStorage.getItem("columnsAbc")) {
    //       // Carrega do localStorage
    //       viewColumns = JSON.parse(localStorage.getItem("columnsAbc"));
    //     } else {
    //       // Define as colunas padrões
    //       viewColumns = visibleColumnsRef.current.map(e => ({
    //         column: e.name,
    //         display: true
    //       }));
    //     }

    //     // Nomes das colunas da campanha selecionada
    //     listProducts?.current?.forEach(product => {
    //         ["objective", "value", "receita_estimada", "status"].forEach(suffix => {
    //           const columnName = `c${product.id}__${suffix}`;
    //           if (!viewColumns.some(vc => vc.column === columnName)) {
    //             viewColumns.push({ column: columnName, display: true });
    //           }
    //         });
    //       });

    //     // Atualiza columnsToDisplay e localStorage
    //     columnsToDisplay.current = viewColumns;
    //     localStorage.setItem('columnsAbc', JSON.stringify(viewColumns));
    //   }, []);

    async function client(clients) {
        try {
            const results = await api.get(`api/v1/abc/vbaNew`, {
                headers: {
                    authorization: `Bearer ${tokenKey}`,
                    abcClients: clients
                },
                body: {
                    abcClients: clients
                }
            })
            return results?.data?.data
        } catch (error) {
            console.log(error?.response)
            return ({
                isError: true,
                notExist: error?.response?.status === 404 ? true : false,
                error: error?.response?.data?.message === undefined ? 
                    'Erro ao realizar a busca das informações da dashboard.' :
                    error?.response?.data?.message
            })
        }
    }
    async function obtendoDados() {
        try {
            const results = await api.get(`api/v1/alocacao/table/campains/rv`, {
                headers: {
                    authorization: `Bearer ${tokenKey}`,
                },
            })

            return results?.data?.data
        } catch (error) {
            console.log(error?.response)
            return ({
                isError: true,
                notExist: error?.response?.status === 404 ? true : false,
                error: error?.response?.data?.message === undefined ? 
                    'Erro ao realizar a busca das informações da dashboard.' :
                    error?.response?.data?.message
            })
        }
    }
    // useEffect(() => {
    //     obtendoDados()
    // }, [])
    const tableClientsModal = useRef()

    async function getData() {
        setLoading(true)
        try {
                // const essentials = await getCampaignsRV(tokenKey)
                const essentials = await client(1)
                const essentialsClientTable = await obtendoDados()
                myClient.current = essentials
                
                tableClientsModal.current = essentialsClientTable

                
                listProducts.current = tableClientsModal.current.products
                clientsDash.current = user.type == 3 ? tableClientsModal.current.table.filter((c) => c.codigo_broker == user.id) : tableClientsModal.current.table
                

                clientsResult.current = essentials
                listClients.current = user.type == 3 && user.is_leader ? essentials.table.filter((c) => c.codigo_broker == user.external_id).slice(0, rowsPerPage + 501) : essentials.table.slice(0, rowsPerPage + 501)
                linesCount.current = user.type == 3 && user.is_leader ? essentials.table.filter((c) => c.codigo_broker == user.external_id).length : essentials.table.length
                listSearchedClients.current = user.type == 3 ? listClients?.current.filter((c) => c.codigo_broker == user.external_id) : listClients?.current.filter((c) => c.codigo_broker != null)
                listFilteredClients.current = user.type == 3 ? listClients?.current.filter((c) => c.codigo_broker == user.external_id) : listClients?.current.filter((c) => c.codigo_broker != null)


                essentials.products.forEach((p) => {
                    if (p.campain == true) {
                        sum.current["c" + p.id] = { oportunity: 0, planned_value: 0, done: 0, receita: 0, receitaOp: 0, receitaRe: 0 }
                    }
                })
                setColumnsLocal()
                doSum()
                loadSettlementsCalendar()
                // getBalance()
                teamClients.current = clientsResult.current.table.filter((c) => c.codigo_broker != user.external_id)
                
                
                setLoadClients(true)
                assembleTable()
                setLoading(false)
            // getFeeFixo()
        } catch (error) {
            setLoading(false)
            return alert(`Erro ao realizar busca de relatórios.`)
        }
    }

    // const passandoPorColumntoDisplay = columnsToDisplay?.map((e) => {
    //     visibleColumnsRef.current.push(e.options.display)


    // });

    function loadSettlementsCalendar() {
        //para o calendário de liquidações
        const listaClientesPorProdutoAux = listProducts.current.map((p) => {
            if (p.data_liquidacao && p.data_liquidacao !== null) {
                const listFilteredClients = clientsResult.current.table.filter((c) => c[p.name + "__status"] == "BOLETADO")
                return {
                    id: p.id,
                    description: p.description,
                    name: p.name,
                    date: p.data_liquidacao,
                    sum: sum.current[p.name],
                    clients: listFilteredClients,
                    area: p.type.description?.split(" - ")[0],
                }
            }
        })
        setListaClientesPorProduto(listaClientesPorProdutoAux)

        //pega a data de hoje
        let today = new Date()

        var d0 = today
        var d1 = today
        var d2 = today
        var d3 = today
        var d3plus = today

        //pega o dia da semana
        var day = today.getDay()

        var lastDayVerified = today

        const feriados = [
            "2022-01-01",
            "2022-02-28",
            "2022-03-01",
            "2022-04-15",
            "2022-04-21",
            "2022-05-01",
            "2022-06-16",
            "2022-09-07",
            "2022-10-12",
            "2022-11-02",
            "2022-11-15",
            "2022-12-25",
            "2023-01-01",
            "2023-02-20",
            "2023-02-21",
            "2023-04-07",
            "2023-04-21",
            "2023-05-01",
            "2023-06-08",
            "2023-09-07",
            "2023-10-12",
            "2023-11-02",
            "2023-11-15",
            "2023-12-25",
            "2024-01-01",
            "2024-02-12",
            "2024-02-13",
            "2024-03-29",
            "2024-04-21",
            "2024-05-01",
            "2024-05-30",
            "2024-09-07",
            "2024-10-12",
            "2024-11-02",
            "2024-11-15",
            "2024-12-25",
            "2025-01-01",
            "2025-03-03",
            "2025-03-04",
            "2025-04-18",
            "2025-04-21",
            "2025-05-01",
            "2025-06-19",
            "2025-09-07",
            "2025-10-12",
            "2025-11-02",
            "2025-11-15",
            "2025-12-25",
            "2026-01-01",
            "2026-02-16",
            "2026-02-17",
            "2026-04-03",
            "2026-04-21",
            "2026-05-01",
            "2026-06-04",
            "2026-09-07",
            "2026-10-12",
            "2026-11-02",
            "2026-11-15",
            "2026-12-25",
            "2027-01-01",
            "2027-02-08",
            "2027-02-09",
            "2027-03-26",
            "2027-04-21",
            "2027-05-01",
            "2027-05-27",
            "2027-09-07",
            "2027-10-12",
            "2027-11-02",
            "2027-11-15",
            "2027-12-25",
            "2028-01-01",
            "2028-02-28",
            "2028-02-29",
            "2028-04-14",
            "2028-04-21",
            "2028-05-01",
            "2028-06-15",
            "2028-09-07",
            "2028-10-12",
            "2028-11-02",
            "2028-11-15",
            "2028-12-25",
            "2029-01-01",
            "2029-02-12",
            "2029-02-13",
            "2029-03-30",
            "2029-04-21",
            "2029-05-01",
            "2029-05-31",
            "2029-09-07",
            "2029-10-12",
            "2029-11-02",
            "2029-11-15",
            "2029-12-25",
            "2030-01-01",
            "2030-03-04",
            "2030-03-05",
            "2030-04-19",
            "2030-04-21",
            "2030-05-01",
            "2030-06-20",
            "2030-09-07",
            "2030-10-12",
            "2030-11-02",
            "2030-11-15",
            "2030-12-25",
            "2031-01-01",
            "2031-02-24",
            "2031-02-25",
            "2031-04-11",
            "2031-04-21",
            "2031-05-01",
            "2031-06-12",
            "2031-09-07",
            "2031-10-12",
            "2031-11-02",
            "2031-11-15",
            "2031-12-25",
            "2032-01-01",
            "2032-02-09",
            "2032-02-10",
            "2032-03-26",
            "2032-04-21",
            "2032-05-01",
            "2032-05-27",
            "2032-09-07",
            "2032-10-12",
            "2032-11-02",
            "2032-11-15",
            "2032-12-25",
            "2033-01-01",
            "2033-02-28",
            "2033-03-01",
            "2033-04-15",
            "2033-04-21",
            "2033-05-01",
            "2033-06-16",
            "2033-09-07",
            "2033-10-12",
            "2033-11-02",
            "2033-11-15",
            "2033-12-25",
            "2034-01-01",
            "2034-02-20",
            "2034-02-21",
            "2034-04-07",
            "2034-04-21",
            "2034-05-01",
            "2034-06-08",
            "2034-09-07",
            "2034-10-12",
            "2034-11-02",
            "2034-11-15",
            "2034-12-25",
            "2035-01-01",
            "2035-02-05",
            "2035-02-06",
            "2035-03-23",
            "2035-04-21",
            "2035-05-01",
            "2035-05-24",
            "2035-09-07",
            "2035-10-12",
            "2035-11-02",
            "2035-11-15",
            "2035-12-25",
            "2036-01-01",
            "2036-02-25",
            "2036-02-26",
            "2036-04-11",
            "2036-04-21",
            "2036-05-01",
            "2036-06-12",
            "2036-09-07",
            "2036-10-12",
            "2036-11-02",
            "2036-11-15",
            "2036-12-25",
            "2037-01-01",
            "2037-02-16",
            "2037-02-17",
            "2037-04-03",
            "2037-04-21",
            "2037-05-01",
            "2037-06-04",
            "2037-09-07",
            "2037-10-12",
            "2037-11-02",
            "2037-11-15",
            "2037-12-25",
            "2038-01-01",
            "2038-03-08",
            "2038-03-09",
            "2038-04-21",
            "2038-04-23",
            "2038-05-01",
            "2038-06-24",
            "2038-09-07",
            "2038-10-12",
            "2038-11-02",
            "2038-11-15",
            "2038-12-25",
            "2039-01-01",
            "2039-02-21",
            "2039-02-22",
            "2039-04-08",
            "2039-04-21",
            "2039-05-01",
            "2039-06-09",
            "2039-09-07",
            "2039-10-12",
            "2039-11-02",
            "2039-11-15",
            "2039-12-25",
            "2040-01-01",
            "2040-02-13",
            "2040-02-14",
            "2040-03-30",
            "2040-04-21",
            "2040-05-01",
            "2040-05-31",
            "2040-09-07",
            "2040-10-12",
            "2040-11-02",
            "2040-11-15",
            "2040-12-25",
            "2041-01-01",
            "2041-03-04",
            "2041-03-05",
            "2041-04-19",
            "2041-04-21",
            "2041-05-01",
            "2041-06-20",
            "2041-09-07",
            "2041-10-12",
            "2041-11-02",
            "2041-11-15",
            "2041-12-25",
            "2042-01-01",
            "2042-02-17",
            "2042-02-18",
            "2042-04-04",
            "2042-04-21",
            "2042-05-01",
            "2042-06-05",
            "2042-09-07",
            "2042-10-12",
            "2042-11-02",
            "2042-11-15",
            "2042-12-25",
            "2043-01-01",
            "2043-02-09",
            "2043-02-10",
            "2043-03-27",
            "2043-04-21",
            "2043-05-01",
            "2043-05-28",
            "2043-09-07",
            "2043-10-12",
            "2043-11-02",
            "2043-11-15",
            "2043-12-25",
            "2044-01-01",
            "2044-02-29",
            "2044-03-01",
            "2044-04-15",
            "2044-04-21",
            "2044-05-01",
            "2044-06-16",
            "2044-09-07",
            "2044-10-12",
            "2044-11-02",
            "2044-11-15",
            "2044-12-25",
            "2045-01-01",
            "2045-02-20",
            "2045-02-21",
            "2045-04-07",
            "2045-04-21",
            "2045-05-01",
            "2045-06-08",
            "2045-09-07",
            "2045-10-12",
            "2045-11-02",
            "2045-11-15",
            "2045-12-25",
            "2046-01-01",
            "2046-02-05",
            "2046-02-06",
            "2046-03-23",
            "2046-04-21",
            "2046-05-01",
            "2046-05-24",
            "2046-09-07",
            "2046-10-12",
            "2046-11-02",
            "2046-11-15",
            "2046-12-25",
            "2047-01-01",
            "2047-02-25",
            "2047-02-26",
            "2047-04-12",
            "2047-04-21",
            "2047-05-01",
            "2047-06-13",
            "2047-09-07",
            "2047-10-12",
            "2047-11-02",
            "2047-11-15",
            "2047-12-25",
            "2048-01-01",
            "2048-02-17",
            "2048-02-18",
            "2048-04-03",
            "2048-04-21",
            "2048-05-01",
            "2048-06-04",
            "2048-09-07",
            "2048-10-12",
            "2048-11-02",
            "2048-11-15",
            "2048-12-25",
            "2049-01-01",
            "2049-03-01",
            "2049-03-02",
            "2049-04-16",
            "2049-04-21",
            "2049-05-01",
            "2049-06-17",
            "2049-09-07",
            "2049-10-12",
            "2049-11-02",
            "2049-11-15",
            "2049-12-25",
            "2050-01-01",
            "2050-02-21",
            "2050-02-22",
            "2050-04-08",
            "2050-04-21",
            "2050-05-01",
            "2050-06-09",
            "2050-09-07",
            "2050-10-12",
            "2050-11-02",
            "2050-11-15",
            "2050-12-25",
            "2051-01-01",
            "2051-02-13",
            "2051-02-14",
            "2051-03-31",
            "2051-04-21",
            "2051-05-01",
            "2051-06-01",
            "2051-09-07",
            "2051-10-12",
            "2051-11-02",
            "2051-11-15",
            "2051-12-25",
            "2052-01-01",
            "2052-03-04",
            "2052-03-05",
            "2052-04-19",
            "2052-04-21",
            "2052-05-01",
            "2052-06-20",
            "2052-09-07",
            "2052-10-12",
            "2052-11-02",
            "2052-11-15",
            "2052-12-25",
            "2053-01-01",
            "2053-02-17",
            "2053-02-18",
            "2053-04-04",
            "2053-04-21",
            "2053-05-01",
            "2053-06-05",
            "2053-09-07",
            "2053-10-12",
            "2053-11-02",
            "2053-11-15",
            "2053-12-25",
            "2054-01-01",
            "2054-02-09",
            "2054-02-10",
            "2054-03-27",
            "2054-04-21",
            "2054-05-01",
            "2054-05-28",
            "2054-09-07",
            "2054-10-12",
            "2054-11-02",
            "2054-11-15",
            "2054-12-25",
            "2055-01-01",
            "2055-03-01",
            "2055-03-02",
            "2055-04-16",
            "2055-04-21",
            "2055-05-01",
            "2055-06-17",
            "2055-09-07",
            "2055-10-12",
            "2055-11-02",
            "2055-11-15",
            "2055-12-25",
            "2056-01-01",
            "2056-02-14",
            "2056-02-15",
            "2056-03-31",
            "2056-04-21",
            "2056-05-01",
            "2056-06-01",
            "2056-09-07",
            "2056-10-12",
            "2056-11-02",
            "2056-11-15",
            "2056-12-25",
            "2057-01-01",
            "2057-03-05",
            "2057-03-06",
            "2057-04-20",
            "2057-04-21",
            "2057-05-01",
            "2057-06-21",
            "2057-09-07",
            "2057-10-12",
            "2057-11-02",
            "2057-11-15",
            "2057-12-25",
            "2058-01-01",
            "2058-02-25",
            "2058-02-26",
            "2058-04-12",
            "2058-04-21",
            "2058-05-01",
            "2058-06-13",
            "2058-09-07",
            "2058-10-12",
            "2058-11-02",
            "2058-11-15",
            "2058-12-25",
            "2059-01-01",
            "2059-02-10",
            "2059-02-11",
            "2059-03-28",
            "2059-04-21",
            "2059-05-01",
            "2059-05-29",
            "2059-09-07",
            "2059-10-12",
            "2059-11-02",
            "2059-11-15",
            "2059-12-25",
            "2060-01-01",
            "2060-03-01",
            "2060-03-02",
            "2060-04-16",
            "2060-04-21",
            "2060-05-01",
            "2060-06-17",
            "2060-09-07",
            "2060-10-12",
            "2060-11-02",
            "2060-11-15",
            "2060-12-25",
            "2061-01-01",
            "2061-02-21",
            "2061-02-22",
            "2061-04-08",
            "2061-04-21",
            "2061-05-01",
            "2061-06-09",
            "2061-09-07",
            "2061-10-12",
            "2061-11-02",
            "2061-11-15",
            "2061-12-25",
            "2062-01-01",
            "2062-02-06",
            "2062-02-07",
            "2062-03-24",
            "2062-04-21",
            "2062-05-01",
            "2062-05-25",
            "2062-09-07",
            "2062-10-12",
            "2062-11-02",
            "2062-11-15",
            "2062-12-25",
            "2063-01-01",
            "2063-02-26",
            "2063-02-27",
            "2063-04-13",
            "2063-04-21",
            "2063-05-01",
            "2063-06-14",
            "2063-09-07",
            "2063-10-12",
            "2063-11-02",
            "2063-11-15",
            "2063-12-25",
            "2064-01-01",
            "2064-02-18",
            "2064-02-19",
            "2064-04-04",
            "2064-04-21",
            "2064-05-01",
            "2064-06-05",
            "2064-09-07",
            "2064-10-12",
            "2064-11-02",
            "2064-11-15",
            "2064-12-25",
            "2065-01-01",
            "2065-02-09",
            "2065-02-10",
            "2065-03-27",
            "2065-04-21",
            "2065-05-01",
            "2065-05-28",
            "2065-09-07",
            "2065-10-12",
            "2065-11-02",
            "2065-11-15",
            "2065-12-25",
            "2066-01-01",
            "2066-02-22",
            "2066-02-23",
            "2066-04-09",
            "2066-04-21",
            "2066-05-01",
            "2066-06-10",
            "2066-09-07",
            "2066-10-12",
            "2066-11-02",
            "2066-11-15",
            "2066-12-25",
            "2067-01-01",
            "2067-02-14",
            "2067-02-15",
            "2067-04-01",
            "2067-04-21",
            "2067-05-01",
            "2067-06-02",
            "2067-09-07",
            "2067-10-12",
            "2067-11-02",
            "2067-11-15",
            "2067-12-25",
            "2068-01-01",
            "2068-03-05",
            "2068-03-06",
            "2068-04-20",
            "2068-04-21",
            "2068-05-01",
            "2068-06-21",
            "2068-09-07",
            "2068-10-12",
            "2068-11-02",
            "2068-11-15",
            "2068-12-25",
            "2069-01-01",
            "2069-02-25",
            "2069-02-26",
            "2069-04-12",
            "2069-04-21",
            "2069-05-01",
            "2069-06-13",
            "2069-09-07",
            "2069-10-12",
            "2069-11-02",
            "2069-11-15",
            "2069-12-25",
            "2070-01-01",
            "2070-02-10",
            "2070-02-11",
            "2070-03-28",
            "2070-04-21",
            "2070-05-01",
            "2070-05-29",
            "2070-09-07",
            "2070-10-12",
            "2070-11-02",
            "2070-11-15",
            "2070-12-25",
            "2071-01-01",
            "2071-03-02",
            "2071-03-03",
            "2071-04-17",
            "2071-04-21",
            "2071-05-01",
            "2071-06-18",
            "2071-09-07",
            "2071-10-12",
            "2071-11-02",
            "2071-11-15",
            "2071-12-25",
            "2072-01-01",
            "2072-02-22",
            "2072-02-23",
            "2072-04-08",
            "2072-04-21",
            "2072-05-01",
            "2072-06-09",
            "2072-09-07",
            "2072-10-12",
            "2072-11-02",
            "2072-11-15",
            "2072-12-25",
            "2073-01-01",
            "2073-02-06",
            "2073-02-07",
            "2073-03-24",
            "2073-04-21",
            "2073-05-01",
            "2073-05-25",
            "2073-09-07",
            "2073-10-12",
            "2073-11-02",
            "2073-11-15",
            "2073-12-25",
            "2074-01-01",
            "2074-02-26",
            "2074-02-27",
            "2074-04-13",
            "2074-04-21",
            "2074-05-01",
            "2074-06-14",
            "2074-09-07",
            "2074-10-12",
            "2074-11-02",
            "2074-11-15",
            "2074-12-25",
            "2075-01-01",
            "2075-02-18",
            "2075-02-19",
            "2075-04-05",
            "2075-04-21",
            "2075-05-01",
            "2075-06-06",
            "2075-09-07",
            "2075-10-12",
            "2075-11-02",
            "2075-11-15",
            "2075-12-25",
            "2076-01-01",
            "2076-03-02",
            "2076-03-03",
            "2076-04-17",
            "2076-04-21",
            "2076-05-01",
            "2076-06-18",
            "2076-09-07",
            "2076-10-12",
            "2076-11-02",
            "2076-11-15",
            "2076-12-25",
            "2077-01-01",
            "2077-02-22",
            "2077-02-23",
            "2077-04-09",
            "2077-04-21",
            "2077-05-01",
            "2077-06-10",
            "2077-09-07",
            "2077-10-12",
            "2077-11-02",
            "2077-11-15",
            "2077-12-25",
            "2078-01-01",
            "2078-02-14",
            "2078-02-15",
            "2078-04-01",
            "2078-04-21",
            "2078-05-01",
            "2078-06-02",
            "2078-09-07",
            "2078-10-12",
            "2078-11-02",
            "2078-11-15",
            "2078-12-25",
        ]

        //verifica se hoje é feriado ou final de semana. se for, pega o primeiro dia util seguinte
        while (day == 0 || day == 6 || feriados.includes(datetimeToDatePtBR(lastDayVerified))) {
            lastDayVerified.setDate(lastDayVerified.getDate() + 1)
            day = lastDayVerified.getDay()
        }
        d0 = lastDayVerified.toISOString().split("T")[0]

        lastDayVerified.setDate(lastDayVerified.getDate() + 1)
        day = lastDayVerified.getDay()

        //verifica se hoje é feriado ou final de semana. se for, pega o primeiro dia util seguinte
        while (day == 0 || day == 6 || feriados.includes(datetimeToDatePtBR(lastDayVerified))) {
            lastDayVerified.setDate(lastDayVerified.getDate() + 1)
            day = lastDayVerified.getDay()
        }
        d1 = lastDayVerified.toISOString().split("T")[0]
        lastDayVerified.setDate(lastDayVerified.getDate() + 1)
        day = lastDayVerified.getDay()

        //verifica se hoje é feriado ou final de semana. se for, pega o primeiro dia util seguinte
        while (day == 0 || day == 6 || feriados.includes(datetimeToDatePtBR(lastDayVerified))) {
            lastDayVerified.setDate(lastDayVerified.getDate() + 1)
            day = lastDayVerified.getDay()
        }
        d2 = lastDayVerified.toISOString().split("T")[0]
        lastDayVerified.setDate(lastDayVerified.getDate() + 1)
        day = lastDayVerified.getDay()

        //verifica se hoje é feriado ou final de semana. se for, pega o primeiro dia util seguinte
        while (day == 0 || day == 6 || feriados.includes(datetimeToDatePtBR(lastDayVerified))) {
            lastDayVerified.setDate(lastDayVerified.getDate() + 1)
            day = lastDayVerified.getDay()
        }
        d3 = lastDayVerified.toISOString().split("T")[0]
        lastDayVerified.setDate(lastDayVerified.getDate() + 1)
        day = lastDayVerified.getDay()

        //verifica se hoje é feriado ou final de semana. se for, pega o primeiro dia util seguinte
        while (day == 0 || day == 6 || feriados.includes(datetimeToDatePtBR(lastDayVerified))) {
            lastDayVerified.setDate(lastDayVerified.getDate() + 1)
            day = lastDayVerified.getDay()
        }
        d3plus = lastDayVerified.toISOString().split("T")[0]

        //qtd clientes em dx
        var qtdD0 = 0
        var qtdD1 = 0
        var qtdD2 = 0
        var qtdD3 = 0
        var qtdD3plus = 0

        listaClientesPorProdutoAux.map((p) => {
            if (p?.clients) {
                if (p.date.split("T")[0] == d0) qtdD0 += p.clients.length
                if (p.date.split("T")[0] == d1 || (p.date.split("T")[0] < d1 && p.date.split("T")[0] > d0)) qtdD1 += p.clients.length
                if (p.date.split("T")[0] == d2 || (p.date.split("T")[0] < d2 && p.date.split("T")[0] > d1)) qtdD2 += p.clients.length
                if (p.date.split("T")[0] == d3 || (p.date.split("T")[0] < d3 && p.date.split("T")[0] > d2)) qtdD3 += p.clients.length
                if (p.date.split("T")[0] >= d3plus || (p.date.split("T")[0] < d3plus && p.date.split("T")[0] > d3)) qtdD3plus += p.clients.length
            }
        })

        setClientsSettlements({
            d0: qtdD0,
            d1: qtdD1,
            d2: qtdD2,
            d3: qtdD3,
            d3plus: qtdD3plus,
        })
    }

    async function configurationExport() {
        try {
            const exportClientsConfigurationResult = await api.get(`api/v1/alocacao/table/configuration/export`, {
                headers: {
                    authorization: `Bearer ${tokenKey}`,
                },
                responseType: "blob",
            })

            const responseURL = URL.createObjectURL(new Blob([exportClientsConfigurationResult.data]))
            const link = document.createElement("a")
            link.href = responseURL
            link.setAttribute("download", "configurações_de_clientes.xlsx")
            document.body.appendChild(link)
            link.click()
        } catch (error) {
            setType({
                name: "alertMsg",
                type: "information",
                value: {
                    message: error?.response?.data?.message === undefined ? "Erro ao buscar dados para exportação" : error?.response?.data?.message,
                },
            })
            setShow(true)
        }
    }
    async function copyToClipboard() {
        let output = []
        let titles = []
        let keys = []

        visibleColumnsRef.current.forEach((c) => {
            if (c?.options?.display == true) {
                titles.push(c?.label)
                keys.push(c?.name)
            }
        })
    
    
    listProducts.current.forEach((p) => {
        // Verifica e adiciona cada coluna e título relacionado ao produto
        if (columnsToDisplay.current?.some(x => x.column === `c${p.id}__objective` && x.display == true)) {
            titles.push(`${p.description}: Objetivo`);
            keys.push(`c${p.id}__objective`);
        }
        if (columnsToDisplay.current?.some(x => x.column === `c${p.id}__value` && x.display == true)) {
            titles.push(`${p.description}: Valor`);
            keys.push(`c${p.id}__value`);
        }
        if (columnsToDisplay.current?.some(x => x.column === `c${p.id}__receita_estimada` && x.display == true)) {
            titles.push(`${p.description}: Receita Estimada`);
            keys.push(`c${p.id}__receita_estimada`);
        }
        if (columnsToDisplay.current?.some(x => x.column === `c${p.id}__status` && x.display == true)) {
            titles.push(`${p.description}: Status`);
            keys.push(`c${p.id}__status`);
        }
    });
    output.push(titles.join("\t"))
    
        
        // listSearchedClients.current.forEach((c) => {
        //     const tmp = keys.map((k) => {
        //         if (k === "fee_fixo_comission" && c[k]) {
        //             // Multiplicar por 100 se existir e for um valor numérico
        //             return String(c[k] * 100).replace(".", ",");
        //         } 
        //     output.push(tmp.join("\t"));
        // })});
        
        // listProducts.current.forEach((p) => {
        //     // Verifica cada coluna relacionada ao produto
        //     if (columnsToDisplay.current?.some(x => x.column === `c${p.id}__objective` && x.display == true)) {
        //         keys.push(`c${p.id}__objective`);
        //     }
        //     if (columnsToDisplay.current?.some(x => x.column === `c${p.id}__value` && x.display == true)) {
        //         keys.push(`c${p.id}__value`);
        //     }
        //     if (columnsToDisplay.current?.some(x => x.column === `c${p.id}__receita` && x.display == true)) {
        //         keys.push(`c${p.id}__receita`);
        //     }
        //     if (columnsToDisplay.current?.some(x => x.column === `c${p.id}__status` && x.display == true)) {
        //         keys.push(`c${p.id}__status`);
        //     }
        // });
        

        clientsResult.current.table.forEach((c) => {
            const tmp = keys.map((k) => {
                if (k === "fee_fixo_comission" && c[k]) {
                    // Multiplicar por 100 se existir e for um valor numérico
                    return String(c[k] * 100).replace(".", ",");
                } else {
                return c[k] ? (k === "net_total" || k === "roa" /* || k === "fee_fixo_comission" */ || k.endsWith("_objective") || k.endsWith("_value") || k.endsWith("_receita") ? String(c[k]).replace(".", ",") : c[k]) : "-"
        }})
            output.push(tmp.join("\t"))
        })
        const table = output.join("\n")
        navigator.clipboard.writeText(table)
        alert("Conteúdo da tabela copiado para a área de transferência.")
    }

    async function exportDashOld() {
        var titles = ["CÓDIGO DO CLIENTE", "NOME DO CLIENTE"]
        titles.push("EMAIL","PERFIL", "SEGMENTO BROKER",'CARTEIRA DINÂMICA AÇÕES', 'CARTEIRA DINÂMICA FII', "CARTEIRA DINÂMICA RENDA IMOBILIÁRIA","ATIVO CARTEIRAS WISIR", "SEGMENTO ASSESSORIA", 'APELIDO ADMINISTRADOR DA CONTA', 'CÓDIGO LÍDER ASSESSOR',
        'TIME ASSESSOR', 'CÓDIGO ASSESSOR', 'ASSESSOR', 'FILIAL', 'STATUS DA RECEITA','OPERA SOZINHO','OBSERVAÇÃO AÇÕES', 'COD. TIME', 'TIME', 'COD. BROKER', 'NOME BROKER',
        'NET TOTAL', 'NET AÇÕES' ,  '% NET AÇÕES', 'NET FII', '% NET FII', 'POS RV', '% RV', 'FREQUÊNCIA DE CONTATO','SERVIÇO HB?','EXCEÇÃO PERFORMANCE','ÚLTIMA INTERAÇÃO (LIGAÇÃO)','ÚLTIMA INTERAÇÃO (WHATS) ','ÚLTIMA INTERAÇÃO (REUNIÃO) ','ÚLTIMA INTERAÇÃO (E-MAIL) ','CONTATO PREFERENCIAL', 'DATA DE INDICAÇÃO', 'RELACIONAMENTO BROKER', 'CÓDIGO BROKER REFERêNCIA', 'BROKER REFERÊNCIA',
        )
        listProducts.current.forEach((p) => {
            titles.push(`${p.description}: Objetivo`, `${p.description}: Valor`, `${p.description}: Receita Estimada`, `${p.description}: Status`)

            listSearchedClients.current?.forEach((c) => {
                c[c.name + "__receita"] =
                    c[c.name + "__value"] > 0 && c[c.name + "__value"] != null
                        ? c[c.name + "__value"] * p.fee
                        : c[c.name + "__objective"] > 0 && c[c.name + "__objective"] != null
                        ? c[c.name + "__objective"] * p.fee
                        : 0
            })
        })

        var keys = ["codigo", "client"]
        keys.push("email","perfil", "categoria", 'dinamica_acoes', 'dinamica_fiis','dinamica_renda', 'ativo_dinamica' ,"seg_assessoria", 'adm_conta', 'codigo_lider_assessor',
        'time_assessor', 'codigo_assessor', 'assessor', 'filial', 'status','opera_sozinho','observacao', 'codigo_lider_broker', 'time_broker', 'codigo_broker', 'broker',
        'net_total', 'net_stock' ,  'percent_net_stock', 'net_fii', 'percent_net_fii', 'pos_rv', 'percent_rv', 'frequency_contact_description', 'hb_service', 'lastCommentLigacao','lastCommentWhats','performance_exception', 'preferred_contact_description', 'inclusao_acoes', 'broker_relationship', 'def_broker_id', 'def_broker',)

        listProducts.current.forEach((p) => {
            keys.push(`c${p.id}__objective`, `c${p.id}__value`, `c${p.id}__receita`, `c${p.id}__status`)
        })

        const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8"
        const fileExtension = ".xlsx"

        const json = listSearchedClients.current?.reduce((result, val) => {
            const temp = {}
            keys.forEach((v, idx) => {
                v == "fee_fixo_comission" ? (temp[titles[idx]] = val[v] ? val[v] * 100 : "-") : (temp[titles[idx]] = val[v] ? val[v] : "-")
            })

            result.push(temp)
            return result
        }, [])

        const fileName = `Dashboard Antiga - Campanhas`
        const ws = utils.json_to_sheet(json)
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] }
        const excelBuffer = write(wb, { bookType: "xlsx", type: "array" })
        const data = new Blob([excelBuffer], { type: fileType })
        saveAs(data, fileName + fileExtension)
        return false
    }

    async function exportDash() {
        var titles = []
        visibleColumnsRef?.current?.forEach((c) => {
            if(c?.options?.display == true) titles.push(c?.label)
        })
        listProducts.current.forEach((p) => {
            titles.push(`${p.description}: Objetivo`, `${p.description}: Valor`, `${p.description}: Receita Estimada`, `${p.description}: Status`)

            listSearchedClients.current?.forEach((c) => {
                c[c.name + "__receita"] =
                    c[c.name + "__value"] > 0 && c[c.name + "__value"] != null
                        ? c[c.name + "__value"] * p.fee
                        : c[c.name + "__objective"] > 0 && c[c.name + "__objective"] != null
                        ? c[c.name + "__objective"] * p.fee
                        : 0
            })
        })

        var keys = []
        visibleColumnsRef.current.forEach((c) => {
            if(c?.options?.display == true) keys.push(c?.name)
        })
        listProducts.current.forEach((p) => {
            keys.push(`${p.name}__objective`, `${p.name}__value`, `${p.name}__receita`, `${p.name}__status`)
        })

        const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8"
        const fileExtension = ".xlsx"

        const json = clientsResult?.current?.table.reduce((result, val) => {
            const temp = {}
            keys.forEach((v, idx) => {
                v == "fee_fixo_comission" ? (temp[titles[idx]] = val[v] ? val[v] * 100 : "-") : (temp[titles[idx]] = val[v] ? val[v] : "-")
            })

            result.push(temp)
            return result
        }, [])

        const fileName = `Dashboard - Campanhas`
        const ws = utils.json_to_sheet(json)
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] }
        const excelBuffer = write(wb, { bookType: "xlsx", type: "array" })
        const data = new Blob([excelBuffer], { type: fileType })
        saveAs(data, fileName + fileExtension)
        return false
    }

    useEffect(() => {
        getPriorities()
        getData()
        getDatabaseUpdateTimeValues()
    }, [edited])

    async function getPriorities() {
        const priorities = await api.get(`/api/v1/abc/config/priorities`, {
            headers: {
                authorization: `Bearer ${token}`,
            },
        })
        campaignPriority.current = priorities?.data?.data
    }

    // async function getFeeFixo() {
    //     const feeFixo = await api.get(`/api/v1/abc/config/fee`, {
    //         headers: {
    //             authorization: `Bearer ${token}`,
    //         },
    //     })

    //     feeFixo.current = feeFixo?.data?.data
    // }

    // useEffect(() => {

    //     if (tabActive.current == 1) {
    //         tableChanged.current = true
    //         clientsResult.current = myClient.current
    //         // listClients.current = myClient.current.table

    //     } else if (tabActive.current == 2) {
    //         if(loadClients) {
    //             tableChanged.current = true
    //             clientsResult.current = teamClients.current
    //     } }
    //     assembleTable()
    //     forceUpdate()
    // }
    // , [tabActive.current])

    async function getDatabaseUpdateTimeValues() {
        const bases = "campanhas,positivador"
        const databaseUpdateTime = await getDatabaseReferenceTime(tokenKey, bases)
        if (getDatabaseReferenceTime?.isError) {
            setType({
                name: "alertMsg",
                type: "information",
                value: {
                    message: getDatabaseReferenceTime?.error,
                },
            })
            setShow(true)
        } else {
            try {
                setCrmUpdateTime(databaseUpdateTime[0].file_reference_time)
                setPositivadorUpdateTime(databaseUpdateTime[1].file_reference_time)
            } catch (e) {
                let dateFormat = ""
                setDatabaseUpdateTime(dateFormat)
            }
        }
    }

    useEffect(
        () => {
            forceUpdate()
        },
        [listClients.current],
        [listFilteredClients.current]
    )

    // useEffect(() => {
    //     const currentDay = new Date().getDate(); // Obtém o dia atual
    //     const storedDay = localStorage.getItem('dayLastAccessDash'); // Obtém o dia armazenado

    //     if (storedDay && currentDay.toString() === storedDay) {
    //       // Se o dia armazenado é igual ao dia atual, carregue os filtros do local storage
    //       const storedFilters = JSON.parse(localStorage.getItem('filtersDash'));
    //       if (storedFilters) {
    //         setFilters(storedFilters); // Sua função para definir os filtros na UI
    //       }
    //     } else {
    //       // Se o dia mudou, resete os filtros
    //       localStorage.setItem('dayLastAccessDash', currentDay.toString()); // Atualize o dia armazenado
    //     }
    //   }, []); // Este useEffect roda apenas quando o componente é montado

    // Função para atualizar os filtros

    useEffect(() => {
        const updateAlturaTabela = () => {
            const altura =
            window.innerHeight <= 850 && window.devicePixelRatio < 1.2
                ? window.innerHeight * window.devicePixelRatio * 1.4
                : window.innerHeight <= 600 && window.devicePixelRatio > 1.2
                    ? window.innerHeight * window.devicePixelRatio * 1.4
                    : window.innerHeight * window.devicePixelRatio
        setAlturaTabela(altura - (!retracaoCarrousel ? 462 : 238))
        }
        updateAlturaTabela()

        window.addEventListener("resize", updateAlturaTabela)

        return () => {
            window.removeEventListener("resize", updateAlturaTabela)
        }
    }, [[] , [retracaoCarrousel]])

    const atualizadorModalStatus = () => {
        setCurrentPage(0)
        setLastPage(0)
    }
    useEffect(() => {
            forceUpdate()
            assembleTable()
        
    }, [selectedCampaign])

    // useEffect(() => {
        

    //     var dadosAbcLocal = JSON.parse(localStorage.getItem("columnsAbc"))
    //     dadosAbcLocal?.current?.forEach((e) => {
    //         columnsToDisplay.current?.forEach((c) => {
    //            if (c.column.includes(e.name))
    //             {
    //                 c.display = e.display
    //             }
    //         }
    //         )
    //     })

    //     localStorage.setItem("columnsToDisplay", JSON.stringify(columnsToDisplay.current))
    //     assembleTable()
    // }, [columnsToDisplay.current, visibleColumnsRef.current])



    const separarLinhas = (e) => {
        let tamanhoFrase = e.description.length
        let palavras = e.description.split(" ")
        let primeiraLinha = ""

        for (let i = 0; i < palavras.length; i++) {
            primeiraLinha += palavras[i] + " "
            if (primeiraLinha.length > tamanhoFrase / 2) {
                break
            }
        }
        let segundaLinha = palavras.slice(primeiraLinha.split(" ").length).join(" ")

        return (
            <>
                {tamanhoFrase >= 40 ? (
                    <div style={{ textAlign: "left", paddingRight: "1rem" }}>
                        <span>{primeiraLinha}</span>
                        <br />
                        <span>{segundaLinha}</span>
                    </div>
                ) : (
                    e?.description
                )}
            </>
        )
    }
    // useEffect(() => {
    //     var sumNetTotalAux = 0;
    //     var sumNetStockAux = 0;
    //     var sumNetFiiAux = 0;
    //     clientsResult?.current?.table.forEach((e) => {
    //         sumNetTotalAux += e.net_total;
    //         sumNetStockAux += e.net_stock;
    //         sumNetFiiAux += e.net_fii;
    //     });
    //     var sumPosRvAux = sumNetStockAux + sumNetFiiAux;
    //     setSumNetTotal(sumNetTotalAux);
    //     setSumNetStock(sumNetStockAux);
    //     setSumNetFii(sumNetFiiAux);
    //     setSumPosRV(sumPosRvAux);
    // }, [listFilteredClients]);

    async function changeInteractionDaysType(value) {
        var tempRefreshColumns = visibleColumnsRef.current;
        tempRefreshColumns[30].options.display = value === 0 ? true : false;
        tempRefreshColumns[31].options.display = value === 1 ? true : false;
        tempRefreshColumns[32].options.display = value === 2 ? true : false;
        tempRefreshColumns[33].options.display = value === 3 ? true : false;
        tempRefreshColumns[34].options.display = value === 4 ? true : false;

        visibleColumnsRef.current = tempRefreshColumns;
        await setVisibleInteractionDaysType(value);
    }

    const colors = { green: "#69e055", orange: "#f5b75f", red: "#ff8282" };


    useEffect(() => {
        // Atualiza o localStorage com as novas colunas sempre que houver uma alteração
        const updatedColumnsToDisplay = columnsToDisplay.current?.map((column) => ({
            ...column,
            // Define o display como true para colunas de novas campanhas
            display: column?.column?.includes(`c${selectedCampaign?.id}__`) ? true : column.display,
        }))

        // Salva as novas colunas no estado e no localStorage
        columnsToDisplay.current = updatedColumnsToDisplay
        localStorage.setItem("columnsToDisplay", JSON.stringify(columnsToDisplay.current))
        assembleTable()
    }, [selectedCampaign]) // Dependência: selectedCampaign
    // useEffect(() => {
    //     setFilters([])
    //     visibleColumnsRef.current.map((e) => {
    //         if(e.name == props?.prop?.prop?.data){
    //             e.options.filterList = (props.prop.prop.listFilterStatusProducts.current[props?.prop?.prop?.data])
    //             filtersList.current[props?.prop?.prop?.data] = (props.prop.prop.listFilterStatusProducts.current[props?.prop?.prop?.data]);
    //         }
    //     })
    //     localStorage.setItem("filterDash", JSON.stringify(filtersList.current))

    //     const day = (new Date()).getDate()
    //     if (localStorage.getItem("dayLastAccessDash")) {
    //         const dayLastAccess = localStorage.getItem('dayLastAccessDash')
    //         if(dayLastAccess != day){
    //             localStorage.setItem("dayLastAccessDash", day)
    //             changedDay.current = true
    //         }
    //     }
    //     else{
    //         localStorage.setItem("dayLastAccessDash", day)
    //     }

    //     forceUpdate()

    // }, [show]);

    // setFilters(clientsResult?.data?.data?.products.map((x) => `${x.name}`))

    async function assembleTable() {
        visibleColumnsRef.current = [
            {
                name: "codigo",
                label: "CÓDIGO DO CLIENTE",
                options: {
                    filter: true,
                    sort: true,
                    sortDescFirst: true,
                    viewColumns: false,
                    display: true,
                    filterType: "custom",
                    filterList: filtersList.current["codigo"],
                    customFilterListOptions: {
                        render: (v) => v,
                        update: (filterList, filterPos, index) => {
                            filterList[index].splice(filterPos, 1)
                            return filterList
                        },
                    },
                    filterOptions: {
                        logic: (location, filters, row) => {
                            if (filters.length) return !filters.includes(location)
                            return false
                        },
                        display: (filterList, onChange, index, column) => {
                            const optionValues = tabActive.current == 1 ? 
                                listClients?.current.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v)).sort((a, b) => a - b) : 
                                teamClients?.current.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v)).sort((a, b) => a - b)                            
                                return (
                                <FormControl>
                                    <InputLabel htmlFor="select-multiple-chip" variant="standard">
                                        {column.label}
                                    </InputLabel>
                                    <Select
                                        multiple
                                        variant="standard"
                                        value={filterList[index]}
                                        renderValue={(selected) => selected.join(", ")}
                                        onChange={(event) => {
                                            filterList[index] = event.target.value
                                            onChange(filterList[index], index, column)
                                        }}
                                    >
                                        {optionValues.map((item) => (
                                            <MenuItem key={item} value={item}>
                                                <Checkbox color="primary" checked={filterList[index].indexOf(item) > -1} />
                                                <ListItemText primary={item} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            )
                        },
                    },
                    customHeadRender: ({ index, name, label, ...column }, sortColumn, tableMeta) => {
                        return (
                            <>
                                <TableCell
                                    className="TableCell"
                                    onClick={() => {
                                        sortColumn(index)
                                    }}
                                    style={{
                                        textAlign: "center",
                                        minWidth: "13rem",
                                        whiteSpace: "nowrap",
                                        position: "sticky",
                                        left: 0,
                                        zIndex: 121,
                                        backgroundColor: "white",
                                    }}
                                >
                                    <div className="containerTableCellButton">
                                        <div>
                                            {label}
                                            <BsQuestionCircle
                                                color="rgb(117, 117, 117)"
                                                size={16}
                                                style={{
                                                    display: "inline-block",
                                                    verticalAlign: "middle",
                                                    marginTop: "-0.2rem",
                                                    cursor: "pointer",
                                                    marginLeft: "0.5rem",
                                                    marginRight: "0.5rem",
                                                }}
                                                title="Informa o código do cliente."
                                            />
                                            <div className="arrowSortAbc">
                                                {tableMeta.name === name ? tableMeta.direction == "asc" ? <AiOutlineArrowUp size={18} /> : <AiOutlineArrowDown size={18} /> : <></>}
                                            </div>
                                        </div>
                                    </div>
                                </TableCell>
                            </>
                        )
                    },
                    setCellProps: () => ({
                        style: {
                            position: "sticky",
                            left: "0",
                            backgroundColor: "white",
                            zIndex: 120,
                        },
                    }),
                },
            },
            {
                name: "nome",
                label: "NOME",
                options: {
                    filter: true,
                    sort: true,
                    viewColumns: false,
                    display: true,
                    filterType: "custom",
                    filterList: filtersList.current["nome"],
                    customFilterListOptions: {
                        render: (v) => v,
                        update: (filterList, filterPos, index) => {
                            filterList[index].splice(filterPos, 1)
                            return filterList
                        },
                    },
                    filterOptions: {
                        logic: (location, filters, row) => {
                            if (filters.length) return !filters.includes(location)
                            return false
                        },
                        display: (filterList, onChange, index, column) => {
                            const optionValues = tabActive.current == 1 ? 
                                listClients?.current.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v)).sort((a, b) => a - b) : 
                                teamClients?.current.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v)).sort((a, b) => a - b)                            
                                return (
                                <FormControl>
                                    <InputLabel htmlFor="select-multiple-chip" variant="standard">
                                        {column.label}
                                    </InputLabel>
                                    <Select
                                        multiple
                                        variant="standard"
                                        value={filterList[index]}
                                        renderValue={(selected) => selected.join(", ")}
                                        onChange={(event) => {
                                            filterList[index] = event.target.value
                                            onChange(filterList[index], index, column)
                                        }}
                                    >
                                        {optionValues.map((item) => (
                                            <MenuItem key={item} value={item}>
                                                <Checkbox color="primary" checked={filterList[index].indexOf(item) > -1} />
                                                <ListItemText primary={item} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            )
                        },
                    },
                    customHeadRender: ({ index, name, label, ...column }, sortColumn, tableMeta) => {
                        return (
                            <>
                                <TableCell
                                    className="TableCell"
                                    onClick={() => {
                                        sortColumn(index)
                                    }}
                                    style={{
                                        paddingRight: "0.5rem",
                                        whiteSpace: "nowrap",
                                        position: "sticky",
                                        top: "0px",
                                        left: "13rem",
                                        zIndex: 121,
                                        backgroundColor: "white",
                                    }}
                                >
                                    <div className="containerTableCellButton">
                                        <div>
                                            {label}
                                            <BsQuestionCircle
                                                color="rgb(117, 117, 117)"
                                                size={16}
                                                style={{
                                                    display: "inline-block",
                                                    verticalAlign: "middle",
                                                    marginTop: "-0.2rem",
                                                    cursor: "pointer",
                                                    marginLeft: "0.5rem",
                                                    marginRight: "0.5rem",
                                                }}
                                                title="Informa o nome do cliente."
                                            />
                                            <div className="arrowSortAbc">
                                                {tableMeta.name === name ? tableMeta.direction == "asc" ? <AiOutlineArrowUp size={18} /> : <AiOutlineArrowDown size={18} /> : <></>}
                                            </div>
                                        </div>
                                    </div>
                                </TableCell>
                            </>
                        )
                    },
                    setCellProps: () => ({
                        style: {
                            paddingLeft: "0.5rem",
                            paddingRight: "0.5rem",
                            position: "sticky",
                            left: "13rem",
                            backgroundColor: "white",
                            zIndex: 120,
                        },
                    }),
                },
            },
        ]
        //se o usuario for lider, entao insere uma coluna de assessor na tabela para saber de qual dos liderados é o cliente
            visibleColumnsRef.current.push(
                
            {
                name: "clientemesa",
                label: "CLIENTE MESA",
                options: {
                    filter: true,
                    sort: true,
                    viewColumns: false,
                    display: true,
                    filterType: "custom",
                    filterList: filtersList.current["clientemesa"],
                    customFilterListOptions: {
                        render: (v) => v,
                        update: (filterList, filterPos, index) => {
                            filterList[index].splice(filterPos, 1)
                            return filterList
                        },
                    },
                    filterOptions: {
                        logic: (location, filters, row) => {
                            if (filters.length) return !filters.includes(location)
                            return false
                        },
                        display: (filterList, onChange, index, column) => {
                            const optionValues = tabActive.current == 1 ? 
                                listClients?.current.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v)).sort((a, b) => a - b) : 
                                teamClients?.current.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v)).sort((a, b) => a - b)  
                                
                                console.log(optionValues)
                                console.log('column', column)
                                console.log('filterLisr', filterList)
                                return (
                                <FormControl>
                                    <InputLabel htmlFor="select-multiple-chip" variant="standard">
                                        {column.label}
                                    </InputLabel>
                                    <Select
                                        multiple
                                        variant="standard"
                                        value={filterList[index]}
                                        renderValue={(selected) => selected.join(", ")}
                                        onChange={(event) => {
                                            filterList[index] = event.target.value
                                            onChange(filterList[index], index, column)
                                        }}
                                    >
                                        {optionValues.map((item) => (
                                            <MenuItem key={item ? 'Sim' : 'Não'} value={item ? 'Sim' : 'Não'}>
                                                <Checkbox color="primary" checked={filterList[index].indexOf(item) > -1} />
                                                <ListItemText primary={item ? 'Sim' : 'Não'} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            )
                        },
                    },
                    setCellProps: () => ({
                        style: {
                            whiteSpace: "nowrap",
                            paddingLeft: "0.5rem",
                            paddingRight: "0.5rem",
                        },
                    }),
                    customBodyRender: (val) => {
                        return val == true  ? 'Sim' : val === false ? 'Não' : "-";
                    },
                },
            },
                {
                    name: "email",
                    label: "EMAIL",
                    options: {
                        filter: true,
                        sort: true,
                        display: tabActive.current == 1 ? columnsToDisplay.current?.some((x) => x.column === "email" && x.display) ?? true : false,
                        filterType: "custom",
                        filterList: filtersList.current["email"],
                        sortDescFirst: true,
                        customFilterListOptions: {
                            render: (v) => v,
                            update: (filterList, filterPos, index) => {
                                filterList[index].splice(filterPos, 1)
                                return filterList
                            },
                        },
                        filterOptions: {
                            logic: (location, filters, row) => {
                                if (filters.length) return !filters.includes(location)
                                return false
                            },
                            display: (filterList, onChange, index, column) => {
                            const optionValues = tabActive.current == 1 ? 
                                listClients?.current.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v)).sort((a, b) => a - b) : 
                                teamClients?.current.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v)).sort((a, b) => a - b)
                                   
                                return (
                                    <FormControl>
                                        <InputLabel htmlFor="select-multiple-chip" variant="standard">
                                            {column.label}
                                        </InputLabel>
                                        <Select
                                            multiple
                                            variant="standard"
                                            value={filterList[index]}
                                            renderValue={(selected) => selected.join(", ")}
                                            onChange={(event) => {
                                                filterList[index] = event.target.value;
                                                onChange(filterList[index], index, column);
                                            }}>
                                            {optionValues.map((item) => (
                                                <MenuItem key={item} value={item}>
                                                    <Checkbox color="primary" checked={filterList[index].indexOf(item) > -1} />
                                                    <ListItemText primary={item} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                )
                            },
                        },
                        setCellProps: () => ({
                            style: {
                                whiteSpace: "nowrap",
                                paddingLeft: "0.5rem",
                                paddingRight: "0.5rem",
                            },
                        }),
                    },
                },
                
            )
        

            visibleColumnsRef.current.push(
                
                {
                    name: "perfil",
                    label: "PERFIL",
                    options: {
                        filter: true,
                        sort: true,
                        display: tabActive.current == 1 ? columnsToDisplay.current?.some((x) => x.column === "perfil" && x.display) ?? true : false,
                        filterType: "custom",
                        filterList: filtersList.current["perfil"],
                        sortDescFirst: true,
                        customFilterListOptions: {
                            render: (v) => v,
                            update: (filterList, filterPos, index) => {
                                filterList[index].splice(filterPos, 1)
                                return filterList
                            },
                        },
                        filterOptions: {
                            logic: (location, filters, row) => {
                                if (filters.length) return !filters.includes(location)
                                return false
                            },
                            display: (filterList, onChange, index, column) => {
                                const optionValues = tabActive.current == 1 ? 
                                listClients?.current.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v)).sort((a, b) => a - b) : 
                                teamClients?.current.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v)).sort((a, b) => a - b)

                                return (
                                    <FormControl>
                                        <InputLabel htmlFor="select-multiple-chip" variant="standard">
                                            {column.label}
                                        </InputLabel>
                                        <Select
                                            multiple
                                            variant="standard"
                                            value={filterList[index]}
                                            renderValue={(selected) => selected.join(", ")}
                                            onChange={(event) => {
                                                filterList[index] = event.target.value;
                                                onChange(filterList[index], index, column);
                                            }}>
                                            {optionValues.map((item) => (
                                                <MenuItem key={item} value={item}>
                                                    <Checkbox color="primary" checked={filterList[index].indexOf(item) > -1} />
                                                    <ListItemText primary={item} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                )
                            },
                        },
                        setCellProps: () => ({
                            style: {
                                paddingLeft: "0.5rem",
                                paddingRight: "0.5rem",
                                whiteSpace: "nowrap",
                            },
                        }),
                    },
                },
                {
                    name: "categoria",
                    label: "SEGMENTO BROKER",
                    options: {
                        filter: true,
                        sort: true,
                        display: tabActive.current == 1 ? columnsToDisplay.current?.some((x) => x.column === "categoria" && x.display) ?? true : false,
                        filterType: "custom",
                        filterList: filtersList.current["categoria"],
                        sortDescFirst: true,
                        customFilterListOptions: {
                            render: (v) => v,
                            update: (filterList, filterPos, index) => {
                                filterList[index].splice(filterPos, 1)
                                return filterList
                            },
                        },
                        filterOptions: {
                            logic: (location, filters, row) => {
                                if (filters.length) return !filters.includes(location)
                                return false
                            },
                            display: (filterList, onChange, index, column) => {
                            const optionValues = tabActive.current == 1 ? 
                                    listClients?.current.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v)) : 
                                    teamClients?.current.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v))
                                
                                return (
                                    <FormControl>
                                        <InputLabel htmlFor="select-multiple-chip" variant="standard">
                                            {column.label}
                                        </InputLabel>
                                        <Select
                                            multiple
                                            variant="standard"
                                            value={filterList[index]}
                                            renderValue={(selected) => selected.join(", ")}
                                            onChange={(event) => {
                                                filterList[index] = event.target.value;
                                                onChange(filterList[index], index, column);
                                            }}>
                                            {optionValues.map((item) => (
                                                <MenuItem key={item} value={item}>
                                                    <Checkbox color="primary" checked={filterList[index].indexOf(item) > -1} />
                                                    <ListItemText primary={item} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                )
                            },
                        },
                        setCellProps: () => ({
                            style: {
                                paddingLeft: "0.5rem",
                                paddingRight: "0.5rem",
                                whiteSpace: "nowrap",
                            },
                        }),
                    },
                },
            )
        
        visibleColumnsRef.current.push(
            {
                name: "seg_assessoria",
                label: "SEGMENTO ASSESSORIA",
                options: {
                    filter: true, //true,
                    sort: true,
                    display: tabActive.current == 1 ? columnsToDisplay.current?.some((x) => x.column === "seg_assessoria" && x.display) ?? true : false,
                    sortDescFirst: true,
                    customFilterListOptions: {
                        render: (v) => v,
                        update: (filterList, filterPos, index) => {
                            filterList[index].splice(filterPos, 1);
                            return filterList;
                        },
                    },
                    filterOptions: {
                        logic: (location, filters, row) => {
                            if (filters.length) return !filters.includes(location);
                            return false;
                        },
                        display: (filterList, onChange, index, column) => {
                                                        const optionValues = tabActive.current == 1 ? 
                                listClients?.current.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v)).sort((a, b) => a - b) : 
                                teamClients?.current.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v)).sort((a, b) => a - b)
    
                            return (
                                <FormControl>
                                    <InputLabel htmlFor="select-multiple-chip" variant="standard">
                                        {column.label}
                                    </InputLabel>
                                    <Select
                                        multiple
                                        variant="standard"
                                        value={filterList[index]}
                                        renderValue={(selected) => selected.join(", ")}
                                        onChange={(event) => {
                                            filterList[index] = event.target.value;
                                            onChange(filterList[index], index, column);
                                        }}>
                                        {optionValues.map((item) => (
                                            <MenuItem key={item} value={item}>
                                                <Checkbox color="primary" checked={filterList[index].indexOf(item) > -1} />
                                                <ListItemText primary={item} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            );
                        },
                    },
                    setCellProps: () => ({
                        style: {
                            whiteSpace: "nowrap",
                            paddingLeft: "0.5rem",
                            paddingRight: "0.5rem",
                        },
                    }),
                },
            },
            {
                name: "adm_conta",
                    label: "Apelido Administrador da conta",
                options: {
                    filter: tabActive.current == 1 ? true : false,
                    sort: true,
                    display: tabActive.current == 1 ? columnsToDisplay.current?.some((x) => x.column === "adm_conta" && x.display) ?? true : false,
                    filterType: "custom",
                    filterList: filtersList.current["adm_conta"],
                    customFilterListOptions: {
                        render: (v) => v,
                        update: (filterList, filterPos, index) => {
                            filterList[index].splice(filterPos, 1);
                            return filterList;
                        },
                    },
                    filterOptions: {
                        logic: (location, filters, row) => {
                            if (filters.length) return !filters.includes(location);
                            return false;
                        },
                        display: (filterList, onChange, index, column) => {
                            const optionValues = tabActive.current == 1 ? 
                                listClients?.current.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v)).sort((a, b) => a - b) : 
                                teamClients?.current.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v)).sort((a, b) => a - b)    
                            return (
                                <FormControl>
                                    <InputLabel htmlFor="select-multiple-chip" variant="standard">
                                        {column.label}
                                    </InputLabel>
                                    <Select
                                        multiple
                                        variant="standard"
                                        value={filterList[index]}
                                        renderValue={(selected) => selected.join(", ")}
                                        onChange={(event) => {
                                            filterList[index] = event.target.value;
                                            onChange(filterList[index], index, column);
                                        }}>
                                        {optionValues.map((item) => (
                                            <MenuItem key={item} value={item}>
                                                <Checkbox color="primary" checked={filterList[index].indexOf(item) > -1} />
                                                <ListItemText primary={item} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            );
                        },
                    },
                    setCellProps: () => ({
                        style: {
                            whiteSpace: "nowrap",
                            paddingLeft: "0.5rem",
                            paddingRight: "0.5rem",
                        },
                    }),
                    customBodyRender: (val) => {
                        return val ? val : "-";
                    },
                },
            },
            {
                name: "codigo_lider_assessor",
                    label: "CÓDIGO LÍDER ASSESSOR",
                options: {
                    filter: tabActive.current == 1 ? true : false,
                    sort: true,
                    display: tabActive.current == 1 ? columnsToDisplay.current?.some((x) => x.column === "codigo_lider_assessor" && x.display) ?? true : true,
                    filterType: "custom",
                    filterList: filtersList.current["codigo_lider_assessor"],
                    customFilterListOptions: {
                        render: (v) => v,
                        update: (filterList, filterPos, index) => {
                            filterList[index].splice(filterPos, 1);
                            return filterList;
                        },
                    },
                    filterOptions: {
                        logic: (location, filters, row) => {
                            if (filters.length) return !filters.includes(location);
                            return false;
                        },
                        display: (filterList, onChange, index, column) => {
                            const optionValues = tabActive.current == 1 ? 
                                listClients?.current.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v)).sort((a, b) => a - b) : 
                                teamClients?.current.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v)).sort((a, b) => a - b)    
                            return (
                                <FormControl>
                                    <InputLabel htmlFor="select-multiple-chip" variant="standard">
                                        {column.label}
                                    </InputLabel>
                                    <Select
                                        multiple
                                        variant="standard"
                                        value={filterList[index]}
                                        renderValue={(selected) => selected.join(", ")}
                                        onChange={(event) => {
                                            filterList[index] = event.target.value;
                                            onChange(filterList[index], index, column);
                                        }}>
                                        {optionValues.map((item) => (
                                            <MenuItem key={item} value={item}>
                                                <Checkbox color="primary" checked={filterList[index].indexOf(item) > -1} />
                                                <ListItemText primary={item} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            );
                        },
                    },
                    setCellProps: () => ({
                        style: {
                            whiteSpace: "nowrap",
                            paddingLeft: "0.5rem",
                            paddingRight: "0.5rem",
                        },
                    }),
                },
            },
            {
                name: "time_assessor",
                    label: "TIME ASSESSOR",
                options: {
                    filter: tabActive.current == 1 ? true : false,
                    sort: true,
                    display: tabActive.current == 1 ? columnsToDisplay.current?.some((x) => x.column === "time_assessor" && x.display) ?? true : true,
                    filterType: "custom",
                    filterList: filtersList.current["time_assessor"],
                    customFilterListOptions: {
                        render: (v) => v,
                        update: (filterList, filterPos, index) => {
                            filterList[index].splice(filterPos, 1);
                            return filterList;
                        },
                    },
                    filterOptions: {
                        logic: (location, filters, row) => {
                            if (filters.length) return !filters.includes(location);
                            return false;
                        },
                        display: (filterList, onChange, index, column) => {
                            const optionValues = tabActive.current == 1 ? 
                                listClients?.current.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v)).sort((a, b) => a - b) : 
                                teamClients?.current.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v)).sort((a, b) => a - b)    
                            return (
                                <FormControl>
                                    <InputLabel htmlFor="select-multiple-chip" variant="standard">
                                        {column.label}
                                    </InputLabel>
                                    <Select
                                        multiple
                                        variant="standard"
                                        value={filterList[index]}
                                        renderValue={(selected) => selected.join(", ")}
                                        onChange={(event) => {
                                            filterList[index] = event.target.value;
                                            onChange(filterList[index], index, column);
                                        }}>
                                        {optionValues.map((item) => (
                                            <MenuItem key={item} value={item}>
                                                <Checkbox color="primary" checked={filterList[index].indexOf(item) > -1} />
                                                <ListItemText primary={item} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            );
                        },
                    },
                    setCellProps: () => ({
                        style: {
                            whiteSpace: "nowrap",
                            paddingLeft: "0.5rem",
                            paddingRight: "0.5rem",
                        },
                    }),
                },
            },
            {
                name: "codigo_assessor",
                label: "CÓDIGO ASSESSOR",
                options: {
                    filter: tabActive.current == 1 ? true : false,
                    sort: true,
                    display: tabActive.current == 1 ? columnsToDisplay.current?.some((x) => x.column === "codigo_assessor" && x.display) ?? true : true,
                    filterType: "custom",
                    filterList: filtersList.current["codigo_assessor"],
                    customFilterListOptions: {
                        render: (v) => v,
                        update: (filterList, filterPos, index) => {
                            filterList[index].splice(filterPos, 1);
                            return filterList;
                        },
                    },
                    filterOptions: {
                        logic: (location, filters, row) => {
                            if (filters.length) return !filters.includes(location);
                            return false;
                        },
                        display: (filterList, onChange, index, column) => {
                            const optionValues = tabActive.current == 1 ? 
                                listClients?.current.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v)).sort((a, b) => a - b) : 
                                teamClients?.current.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v)).sort((a, b) => a - b)    
                            return (
                                <FormControl>
                                    <InputLabel htmlFor="select-multiple-chip" variant="standard">
                                        {column.label}
                                    </InputLabel>
                                    <Select
                                        multiple
                                        variant="standard"
                                        value={filterList[index]}
                                        renderValue={(selected) => selected.join(", ")}
                                        onChange={(event) => {
                                            filterList[index] = event.target.value;
                                            onChange(filterList[index], index, column);
                                        }}>
                                        {optionValues.map((item) => (
                                            <MenuItem key={item} value={item}>
                                                <Checkbox color="primary" checked={filterList[index].indexOf(item) > -1} />
                                                <ListItemText primary={item} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            );
                        },
                    },
                    setCellProps: () => ({
                        style: {
                            whiteSpace: "nowrap",
                            paddingLeft: "0.5rem",
                            paddingRight: "0.5rem",
                        },
                    }),
                    customBodyRender: (val) => {
                        return val ? val : "-";
                    },
                },
            },
            {
                name: "assessor",
                label: "ASSESSOR",
                options: {
                    filter: tabActive.current == 1? true : false,
                    sort: true,
                    sortDescFirst: true,
                    display: tabActive.current == 1? columnsToDisplay.current?.some((x) => x.column === "assessor" && x.display) ?? true : true,
                    filterType: "custom",
                    filterList: filtersList.current["assessor"],
                    customFilterListOptions: {
                        render: (v) => v,
                        update: (filterList, filterPos, index) => {
                            filterList[index].splice(filterPos, 1);
                            return filterList;
                        },
                    },
                    filterOptions: {
                        logic: (location, filters, row) => {
                            if (filters.length) return !filters.includes(location);
                            return false;
                        },
                        display: (filterList, onChange, index, column) => {
                            const optionValues = tabActive.current == 1 ? 
                                listClients?.current.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v)).sort((a, b) => a - b) : 
                                teamClients?.current.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v)).sort((a, b) => a - b)    
                            return (
                                <FormControl>
                                    <InputLabel htmlFor="select-multiple-chip" variant="standard">
                                        {column.label}
                                    </InputLabel>
                                    <Select
                                        multiple
                                        variant="standard"
                                        value={filterList[index]}
                                        renderValue={(selected) => selected.join(", ")}
                                        onChange={(event) => {
                                            filterList[index] = event.target.value;
                                            onChange(filterList[index], index, column);
                                        }}>
                                        {optionValues.map((item) => (
                                            <MenuItem key={item} value={item}>
                                                <Checkbox color="primary" checked={filterList[index].indexOf(item) > -1} />
                                                <ListItemText primary={item} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            );
                        },
                    },
                    setCellProps: () => ({
                        style: {
                            whiteSpace: "nowrap",
                            paddingLeft: "0.5rem",
                            paddingRight: "0.5rem",
                        },
                    }),
                    customBodyRender: (val) => {
                        return val ? val : "-";
                    },
                },
            },
            {
                name: "filial",
                label: "FILIAL",
                options: {
                    filter: tabActive.current == 1? true : false,
                    sort: true,
                    display: tabActive.current == 1? columnsToDisplay.current?.some((x) => x.column === "filial" && x.display) ?? true : true,
                    filterType: "custom",
                    filterList: filtersList.current["filial"],
                    customFilterListOptions: {
                        render: (v) => v,
                        update: (filterList, filterPos, index) => {
                            filterList[index].splice(filterPos, 1);
                            return filterList;
                        },
                    },
                    filterOptions: {
                        logic: (location, filters, row) => {
                            if (filters.length) return !filters.includes(location);
                            return false;
                        },
                        display: (filterList, onChange, index, column) => {
                            const optionValues = tabActive.current == 1 ? 
                                listClients?.current.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v)).sort((a, b) => a - b) : 
                                teamClients?.current.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v)).sort((a, b) => a - b)    
                            return (
                                <FormControl>
                                    <InputLabel htmlFor="select-multiple-chip" variant="standard">
                                        {column.label}
                                    </InputLabel>
                                    <Select
                                        multiple
                                        variant="standard"
                                        value={filterList[index]}
                                        renderValue={(selected) => selected.join(", ")}
                                        onChange={(event) => {
                                            filterList[index] = event.target.value;
                                            onChange(filterList[index], index, column);
                                        }}>
                                        {optionValues.map((item) => (
                                            <MenuItem key={item} value={item}>
                                                <Checkbox color="primary" checked={filterList[index].indexOf(item) > -1} />
                                                <ListItemText primary={item} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            );
                        },
                    },
                    setCellProps: () => ({
                        style: {
                            whiteSpace: "nowrap",
                            paddingLeft: "0.5rem",
                            paddingRight: "0.5rem",
                        },
                    }),
                    customBodyRender: (val) => {
                        return val ? val : "-";
                    },
                },
            },
            {
                name: "gap_receita",
                label: "GAP RECEITA",
                options: {
                    filter: false,
                    sort: true,
                    viewColumns: false,
                    display: true,
                    filterType: "custom",
                    filterList: filtersList.current["gap_receita"],
                    customFilterListOptions: {
                        render: (v) => v,
                        update: (filterList, filterPos, index) => {
                            filterList[index].splice(filterPos, 1)
                            return filterList
                        },
                    },
                    filterOptions: {
                        logic: (location, filters, row) => {
                            if (filters.length) return !filters.includes(location)
                            return false
                        },
                        display: (filterList, onChange, index, column) => {
                            const optionValues = tabActive.current == 1 ? 
                                listClients?.current.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v)).sort((a, b) => a - b) : 
                                teamClients?.current.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v)).sort((a, b) => a - b)                            
                                return (
                                <FormControl>
                                    <InputLabel htmlFor="select-multiple-chip" variant="standard">
                                        {column.label}
                                    </InputLabel>
                                    <Select
                                        multiple
                                        variant="standard"
                                        value={filterList[index]}
                                        renderValue={(selected) => selected.join(", ")}
                                        onChange={(event) => {
                                            filterList[index] = event.target.value
                                            onChange(filterList[index], index, column)
                                        }}
                                    >
                                        {optionValues.map((item) => (
                                            <MenuItem key={item} value={item}>
                                                <Checkbox color="primary" checked={filterList[index].indexOf(item) > -1} />
                                                <ListItemText primary={item} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            )
                        },
                    },
                    setCellProps: () => ({
                        style: {
                            whiteSpace: "nowrap",
                            paddingLeft: "0.5rem",
                            paddingRight: "0.5rem",
                        },
                    }),
                    customBodyRender: (val) => {
                        return val == null ?  toBRL(0) : val < 0 ? "Ativo" : toBRL(val) ;
                    },
                },
            },
            
            {
                name: "statusReceita",
                label: "STATUS RECEITA",
                options: {
                    filter: true,
                    sort: true,
                    viewColumns: false,
                    display: true,
                    filterType: "custom",
                    filterList: filtersList.current["statusReceita"],
                    customFilterListOptions: {
                        render: (v) => v,
                        update: (filterList, filterPos, index) => {
                            filterList[index].splice(filterPos, 1)
                            return filterList
                        },
                    },
                    filterOptions: {
                        logic: (location, filters, row) => {
                            if (filters.length) return !filters.includes(location)
                            return false
                        },
                        display: (filterList, onChange, index, column) => {
                            const optionValues = tabActive.current == 1 ? 
                                listClients?.current.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v)).sort((a, b) => a - b) : 
                                teamClients?.current.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v)).sort((a, b) => a - b)                            
                                return (
                                <FormControl>
                                    <InputLabel htmlFor="select-multiple-chip" variant="standard">
                                        {column.label}
                                    </InputLabel>
                                    <Select
                                        multiple
                                        variant="standard"
                                        value={filterList[index]}
                                        renderValue={(selected) => selected.join(", ")}
                                        onChange={(event) => {
                                            filterList[index] = event.target.value
                                            onChange(filterList[index], index, column)
                                        }}
                                    >
                                        {optionValues.map((item) => (
                                            <MenuItem key={item} value={item}>
                                                <Checkbox color="primary" checked={filterList[index].indexOf(item) > -1} />
                                                <ListItemText primary={item} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            )
                        },
                    },
                    setCellProps: () => ({
                        style: {
                            whiteSpace: "nowrap",
                            paddingLeft: "0.5rem",
                            paddingRight: "0.5rem",
                        },
                    }),
                    customBodyRender: (val) => {
                        return val == '-' ? 'Ativo' : val;
                    },
                },
            },
            
            // {
            //     name: "statusReceita",
            //     label: "STATUS RECEITA",
            //     options: {
            //         filter: true,
            //         sort: true,
            //         display: true,
            //         filterType: "custom",
            //         filterList: filtersList["statusReceita"],
            //         customBodyRender: (val) => {
            //             return val == '-' ? 'Ativo' : val;
            //         },
            //         customFilterListOptions: {
            //             render: (v) => v,
            //             update: (filterList, filterPos, index) => {
            //                 filterList[index].splice(filterPos, 1);
            //                 return filterList;
            //             },
            //         },
            //         filterOptions: {
            //             logic: (location, filters, row) => {
            //                 if (filters.length) return !filters.includes(location);
            //                 return false;
            //             },
            //             display: (filterList, onChange, index, column) => {
            //                 const optionValues = listSearchedClients.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v));
    
            //                 return (
            //                     <FormControl>
            //                         <InputLabel htmlFor="select-multiple-chip" variant="standard">
            //                             {column.label}
            //                         </InputLabel>
            //                         <Select
            //                             multiple
            //                             variant="standard"
            //                             value={filterList[index]}
            //                             renderValue={(selected) => selected.join(", ")}
            //                             onChange={(event) => {
            //                                 filterList[index] = event.target.value;
            //                                 onChange(filterList[index], index, column);
            //                             }}>
            //                             {optionValues.map((item) => (
            //                                 <MenuItem key={item} value={item}>
            //                                     <Checkbox color="primary" checked={filterList[index].indexOf(item) > -1} />
            //                                     <ListItemText primary={item} />
            //                                 </MenuItem>
            //                             ))}
            //                         </Select>
            //                     </FormControl>
            //                 );
            //             },
            //         },
            //     },
    
            // },
            {
                name: "status",
                label: "STATUS",
                options: {
                    filter: false,
                    sort: false,
                    display: false,
                    viewColumns: false,
                    filterType: "custom",
                    filterList: filtersList.current["status"],
                    customFilterListOptions: {
                        render: (v) => v,
                        update: (filterList, filterPos, index) => {
                            filterList[index].splice(filterPos, 1);
                            return filterList;
                        },
                    },
                    filterOptions: {
                        logic: (location, filters, row) => {
                            if (filters.length) return !filters.includes(location);
                            return false;
                        },
                        display: (filterList, onChange, index, column) => {
                            const optionValues = tabActive.current == 1 ? 
                                listClients?.current.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v)).sort((a, b) => a - b) : 
                                teamClients?.current.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v)).sort((a, b) => a - b)    
                            return (
                                <FormControl>
                                    <InputLabel htmlFor="select-multiple-chip" variant="standard">
                                        {column.label}
                                    </InputLabel>
                                    <Select
                                        multiple
                                        variant="standard"
                                        value={filterList[index]}
                                        renderValue={(selected) => selected.join(", ")}
                                        onChange={(event) => {
                                            filterList[index] = event.target.value;
                                            onChange(filterList[index], index, column);
                                        }}>
                                        {optionValues.map((item) => (
                                            <MenuItem key={item} value={item}>
                                                <Checkbox color="primary" checked={filterList[index].indexOf(item) > -1} />
                                                <ListItemText primary={item} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            );
                        },
                    },
                    setCellProps: () => ({
                        style: {
                            whiteSpace: "nowrap",
                            paddingLeft: "0.5rem",
                            paddingRight: "0.5rem",
                        },
                    }),
                    customBodyRender: (val) => {
                        return val ? val : "-";
                    },
                },
            },
            {
                name: "codigo_lider_broker",
                label: "COD TIME",
                options: {
                    filter: tabActive.current == 1? true : false,
                    sort: true,
                    display: tabActive.current == 1? columnsToDisplay.current?.some((x) => x.column === "codigo_lider_broker" && x.display) ?? true : true,
                    filterType: "custom",
                    filterList: filtersList.current["codigo_lider_broker"],
                        customFilterListOptions: {
                            render: (v) => v,
                            update: (filterList, filterPos, index) => {
                                filterList[index].splice(filterPos, 1);
                                return filterList;
                            },
                        },
                        filterOptions: {
                            logic: (location, filters, row) => {
                                if (filters.length) return !filters.includes(location);
                                return false;
                            },
                            display: (filterList, onChange, index, column) => {
                            const optionValues = tabActive.current == 1 ? 
                                listClients?.current.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v)).sort((a, b) => a - b) : 
                                teamClients?.current.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v)).sort((a, b) => a - b)        
                                return (
                                    <FormControl>
                                        <InputLabel htmlFor="select-multiple-chip" variant="standard">
                                            {column.label}
                                        </InputLabel>
                                        <Select
                                            multiple
                                            variant="standard"
                                            value={filterList[index]}
                                            renderValue={(selected) => selected.join(", ")}
                                            onChange={(event) => {
                                                filterList[index] = event.target.value;
                                                onChange(filterList[index], index, column);
                                            }}>
                                            {optionValues.map((item) => (
                                                <MenuItem key={item} value={item}>
                                                    <Checkbox color="primary" checked={filterList[index].indexOf(item) > -1} />
                                                    <ListItemText primary={item} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                );
                            },
                        },
                        setCellProps: () => ({
                            style: {
                                whiteSpace: "nowrap",
                                paddingLeft: "0.5rem",
                                paddingRight: "0.5rem",
                            },
                        }),
                        customBodyRender: (val) => {
                            return val ? parseInt( val) : "-";
                        },
                },
            },
            {
                name: "time_broker",
                label: "TIME",
                options: {
                    filter: tabActive.current == 1? true : false,
                    sort: true,
                    display: tabActive.current == 1? columnsToDisplay.current?.some((x) => x.column === "time_broker" && x.display) ?? true : true,
                    filterType: "custom",
                    filterList: filtersList.current["time_broker"],
                        customFilterListOptions: {
                            render: (v) => v,
                            update: (filterList, filterPos, index) => {
                                filterList[index].splice(filterPos, 1);
                                return filterList;
                            },
                        },
                        filterOptions: {
                            logic: (location, filters, row) => {
                                if (filters.length) return !filters.includes(location);
                                return false;
                            },
                            display: (filterList, onChange, index, column) => {
                            const optionValues = tabActive.current == 1 ? 
                                listClients?.current.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v)).sort((a, b) => a - b) : 
                                teamClients?.current.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v)).sort((a, b) => a - b)        
                                return (
                                    <FormControl>
                                        <InputLabel htmlFor="select-multiple-chip" variant="standard">
                                            {column.label}
                                        </InputLabel>
                                        <Select
                                            multiple
                                            variant="standard"
                                            value={filterList[index]}
                                            renderValue={(selected) => selected.join(", ")}
                                            onChange={(event) => {
                                                filterList[index] = event.target.value;
                                                onChange(filterList[index], index, column);
                                            }}>
                                            {optionValues.map((item) => (
                                                <MenuItem key={item} value={item}>
                                                    <Checkbox color="primary" checked={filterList[index].indexOf(item) > -1} />
                                                    <ListItemText primary={item} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                );
                            },
                        },
                        setCellProps: () => ({
                            style: {
                                whiteSpace: "nowrap",
                                paddingLeft: "0.5rem",
                                paddingRight: "0.5rem",
                            },
                        }),
                        customBodyRender: (val) => {
                            return val ? val : "-";
                        },
                },
            },
            {
                name: "codigo_broker",
                    label: "COD BROKER",
                options: {
                    filter: tabActive.current == 1? true : false,
                    sort: true,
                    display: tabActive.current == 1? columnsToDisplay.current?.some((x) => x.column === "codigo_broker" && x.display) ?? true : true,
                    filterType: "custom",
                    filterList: filtersList.current["codigo_broker"],
                        customFilterListOptions: {
                            render: (v) => v,
                            update: (filterList, filterPos, index) => {
                                filterList[index].splice(filterPos, 1);
                                return filterList;
                            },
                        },
                        filterOptions: {
                            logic: (location, filters, row) => {
                                if (filters.length) return !filters.includes(location);
                                return false;
                            },
                            display: (filterList, onChange, index, column) => {
                            const optionValues = tabActive.current == 1 ? 
                                listClients?.current.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v)).sort((a, b) => a - b) : 
                                teamClients?.current.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v)).sort((a, b) => a - b)        
                                return (
                                    <FormControl>
                                        <InputLabel htmlFor="select-multiple-chip" variant="standard">
                                            {column.label}
                                        </InputLabel>
                                        <Select
                                            multiple
                                            variant="standard"
                                            value={filterList[index]}
                                            renderValue={(selected) => selected.join(", ")}
                                            onChange={(event) => {
                                                filterList[index] = event.target.value;
                                                onChange(filterList[index], index, column);
                                            }}>
                                            {optionValues.map((item) => (
                                                <MenuItem key={item} value={item}>
                                                    <Checkbox color="primary" checked={filterList[index].indexOf(item) > -1} />
                                                    <ListItemText primary={item} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                );
                            },
                        },
                        setCellProps: () => ({
                            style: {
                                whiteSpace: "nowrap",
                                paddingLeft: "0.5rem",
                                paddingRight: "0.5rem",
                            },
                        }),
                        customBodyRender: (val) => {
                            return val ? parseInt(val) : "-";
                        },
                },
            },
            {
                name: "broker",
                label: "NOME BROKER",
                options: {
                    filter: tabActive.current == 1 ? true : false,
                    sort: true,
                    display: tabActive.current == 1 ? columnsToDisplay.current?.some((x) => x.column === "broker" && x.display) ?? true : true,
                    filterType: "custom",
                    filterList: filtersList.current["broker"],
                        customFilterListOptions: {
                            render: (v) => v,
                            update: (filterList, filterPos, index) => {
                                filterList[index].splice(filterPos, 1);
                                return filterList;
                            },
                        },
                        filterOptions: {
                            logic: (location, filters, row) => {
                                if (filters.length) return !filters.includes(location);
                                return false;
                            },
                            display: (filterList, onChange, index, column) => {
                            const optionValues = tabActive.current == 1 ? 
                                listClients?.current.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v)).sort((a, b) => a - b) : 
                                teamClients?.current.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v)).sort((a, b) => a - b)        
                                return (
                                    <FormControl>
                                        <InputLabel htmlFor="select-multiple-chip" variant="standard">
                                            {column.label}
                                        </InputLabel>
                                        <Select
                                            multiple
                                            variant="standard"
                                            value={filterList[index]}
                                            renderValue={(selected) => selected.join(", ")}
                                            onChange={(event) => {
                                                filterList[index] = event.target.value;
                                                onChange(filterList[index], index, column);
                                            }}>
                                            {optionValues.map((item) => (
                                                <MenuItem key={item} value={item}>
                                                    <Checkbox color="primary" checked={filterList[index].indexOf(item) > -1} />
                                                    <ListItemText primary={item} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                );
                            },
                        },
                        setCellProps: () => ({
                            style: {
                                whiteSpace: "nowrap",
                                paddingLeft: "0.5rem",
                                paddingRight: "0.5rem",
                            },
                        }),
                },
            },
            {
                name: "net_total",
                label: "NET TOTAL",
                options: {
                    filter: tabActive.current == 1? true : false,
                    sort: true,
                    display: tabActive.current == 1? columnsToDisplay.current?.some((x) => x.column === "net_total" && x.display) ?? true : true,
                    filterType: "custom",
                    filterList: filtersList.current["net_total"],
                        customFilterListOptions: {
                            render: (v) => v,
                            update: (filterList, filterPos, index) => {
                                filterList[index].splice(filterPos, 1);
                                return filterList;
                            },
                        },
                        filterOptions: {
                            logic: (location, filters, row) => {
                                if (filters.length) return !filters.includes(location);
                                return false;
                            },
                            display: (filterList, onChange, index, column) => {
                            const optionValues = tabActive.current == 1 ? 
                                listClients?.current.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v)).sort((a, b) => a - b) : 
                                teamClients?.current.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v)).sort((a, b) => a - b)        
                                return (
                                    <FormControl>
                                        <InputLabel htmlFor="select-multiple-chip" variant="standard">
                                            {column.label}
                                        </InputLabel>
                                        <Select
                                            multiple
                                            variant="standard"
                                            value={filterList[index]}
                                            renderValue={(selected) => selected.join(", ")}
                                            onChange={(event) => {
                                                filterList[index] = event.target.value;
                                                onChange(filterList[index], index, column);
                                            }}>
                                            {optionValues.map((item) => (
                                                <MenuItem key={item} value={item}>
                                                    <Checkbox color="primary" checked={filterList[index].indexOf(item) > -1} />
                                                    <ListItemText primary={item} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                );
                            },
                        },
                        setCellProps: () => ({
                            style: {
                                whiteSpace: "nowrap",
                                paddingLeft: "0.5rem",
                                paddingRight: "0.5rem",
                            },
                        }),
                        customBodyRender: (val) => {
                            return val ? toBRL(parseFloat(val)) : "-";
                        },
                        customHeadRender: ({ index, name, ...column }, sortColumn, tableMeta) => (
                            <TableCell
                                className="containerSelectContactTypeHeader tss-gm6zfk-MUIDataTableHeadCell-fixedHeader"
                                onClick={() => {
                                    sortColumn(index);
                                }}>
                                <div className="textSumCell">
                                    <div>
                                        {/* <TableSortLabel active={tableMeta.name === column.name} direction={tableMeta.direction || "asc"} /> */}
                                        {column.label} {tableMeta.name === name ? tableMeta.direction == "asc" ? <AiOutlineArrowUp /> : <AiOutlineArrowDown /> : <></>}
                                    </div>
                                    <p>{toBRL(sumNet.current)}</p>
                                </div>
                            </TableCell>
                        ),
                    },
            },
            {
                name: "net_stock",
                label: "NET AÇÕES",
                options: {
                    filter: tabActive.current == 1? true : false,
                    sort: true,
                    display: tabActive.current == 1? columnsToDisplay.current?.some((x) => x.column === "net_stock" && x.display) ?? true : true,
                    filterType: "custom",
                    filterList: filtersList.current["net_stock"],
                        customFilterListOptions: {
                            render: (v) => v,
                            update: (filterList, filterPos, index) => {
                                filterList[index].splice(filterPos, 1);
                                return filterList;
                            },
                        },
                        filterOptions: {
                            logic: (location, filters, row) => {
                                if (filters.length) return !filters.includes(location);
                                return false;
                            },
                            display: (filterList, onChange, index, column) => {
                            const optionValues = tabActive.current == 1 ? 
                                listClients?.current.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v)).sort((a, b) => a - b) : 
                                teamClients?.current.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v)).sort((a, b) => a - b)        
                                return (
                                    <FormControl>
                                        <InputLabel htmlFor="select-multiple-chip" variant="standard">
                                            {column.label}
                                        </InputLabel>
                                        <Select
                                            multiple
                                            variant="standard"
                                            value={filterList[index]}
                                            renderValue={(selected) => selected.join(", ")}
                                            onChange={(event) => {
                                                filterList[index] = event.target.value;
                                                onChange(filterList[index], index, column);
                                            }}>
                                            {optionValues.map((item) => (
                                                <MenuItem key={item} value={item}>
                                                    <Checkbox color="primary" checked={filterList[index].indexOf(item) > -1} />
                                                    <ListItemText primary={item} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                );
                            },
                        },
                        setCellProps: () => ({
                            style: {
                                whiteSpace: "nowrap",
                                paddingLeft: "0.5rem",
                                paddingRight: "0.5rem",
                            },
                        }),
                        customBodyRender: (val) => {
                            return val ? toBRL(parseFloat(val)) : "-";
                        },
                        customHeadRender: ({ index, name, ...column }, sortColumn, tableMeta) => (
                            <TableCell
                                className="containerSelectContactTypeHeader tss-gm6zfk-MUIDataTableHeadCell-fixedHeader"
                                onClick={() => {
                                    // changeSortGeral();
                                    sortColumn(index);
                                }}>
                                <div className="textSumCell">
                                    <div>
                                        {/* {column.label} {sortGeral == 0 ? "" : sortGeral == 1 ? <AiOutlineArrowUp /> : <AiOutlineArrowDown />} */}
                                        {column.label}
                                        {tableMeta.name === name ? tableMeta.direction == "asc" ? <AiOutlineArrowUp /> : <AiOutlineArrowDown /> : <></>}
                                    </div>
                                    <p>{toBRL(sumNetStock.current)}</p>
                                </div>
                            </TableCell>
                        ),
                },
            },
            {
                name: "percent_net_stock",
                label: "% NET AÇÕES",
                options: {
                    filter: tabActive.current == 1? true : false,
                    sort: true,
                    viewColumns: false,
                    display: tabActive.current == 0? columnsToDisplay.current?.some((x) => x.column === "percent_net_stock" && x.display) ?? true : false,
                    filterType: "custom",
                    filterList: filtersList.current["percent_net_stock"],
                        customFilterListOptions: {
                            render: (v) => v,
                            update: (filterList, filterPos, index) => {
                                filterList[index].splice(filterPos, 1);
                                return filterList;
                            },
                        },
                        filterOptions: {
                            logic: (location, filters, row) => {
                                if (filters.length) return !filters.includes(location);
                                return false;
                            },
                            display: (filterList, onChange, index, column) => {
                            const optionValues = tabActive.current == 1 ? 
                                listClients?.current.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v)).sort((a, b) => a - b) : 
                                teamClients?.current.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v)).sort((a, b) => a - b)        
                                return (
                                    <FormControl>
                                        <InputLabel htmlFor="select-multiple-chip" variant="standard">
                                            {column.label}
                                        </InputLabel>
                                        <Select
                                            multiple
                                            variant="standard"
                                            value={filterList[index]}
                                            renderValue={(selected) => selected.join(", ")}
                                            onChange={(event) => {
                                                filterList[index] = event.target.value;
                                                onChange(filterList[index], index, column);
                                            }}>
                                            {optionValues.map((item) => (
                                                <MenuItem key={item} value={item}>
                                                    <Checkbox color="primary" checked={filterList[index].indexOf(item) > -1} />
                                                    <ListItemText primary={item} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                );
                            },
                        },
                        setCellProps: () => ({
                            style: {
                                whiteSpace: "nowrap",
                                paddingLeft: "0.5rem",
                                paddingRight: "0.5rem",
                            },
                        }),
                        customBodyRender: (val, tableMeta) => {
                            return String(val.toFixed(2)) + "%";
                        },
                },
            },
            {
                name: "net_fii",
                label: "NET FII",
                options: {
                    filter: tabActive.current == 1? true : false,
                    sort: true,
                    display: tabActive.current == 1? columnsToDisplay.current?.some((x) => x.column === "net_fii" && x.display) ?? true : true,
                    filterType: "custom",
                    filterList: filtersList.current["net_fii"],
                        customFilterListOptions: {
                            render: (v) => v,
                            update: (filterList, filterPos, index) => {
                                filterList[index].splice(filterPos, 1);
                                return filterList;
                            },
                        },
                        filterOptions: {
                            logic: (location, filters, row) => {
                                if (filters.length) return !filters.includes(location);
                                return false;
                            },
                            display: (filterList, onChange, index, column) => {
                            const optionValues = tabActive.current == 1 ? 
                                listClients?.current.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v)).sort((a, b) => a - b) : 
                                teamClients?.current.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v)).sort((a, b) => a - b)        
                                return (
                                    <FormControl>
                                        <InputLabel htmlFor="select-multiple-chip" variant="standard">
                                            {column.label}
                                        </InputLabel>
                                        <Select
                                            multiple
                                            variant="standard"
                                            value={filterList[index]}
                                            renderValue={(selected) => selected.join(", ")}
                                            onChange={(event) => {
                                                filterList[index] = event.target.value;
                                                onChange(filterList[index], index, column);
                                            }}>
                                            {optionValues.map((item) => (
                                                <MenuItem key={item} value={item}>
                                                    <Checkbox color="primary" checked={filterList[index].indexOf(item) > -1} />
                                                    <ListItemText primary={item} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                );
                            },
                        },
                        setCellProps: () => ({
                            style: {
                                whiteSpace: "nowrap",
                                paddingLeft: "0.5rem",
                                paddingRight: "0.5rem",
                            },
                        }),
                        customBodyRender: (val) => {
                            return val ? toBRL(parseFloat(val)) : toBRL(parseFloat(0));
                        },
                        customHeadRender: ({ index, name, ...column }, sortColumn, tableMeta) => (
                            <TableCell
                                className="containerSelectContactTypeHeader tss-gm6zfk-MUIDataTableHeadCell-fixedHeader"
                                onClick={() => {
                                    sortColumn(index);
                                }}>
                                <div className="textSumCell">
                                    <div>
                                        {column.label} {tableMeta.name === name ? tableMeta.direction == "asc" ? <AiOutlineArrowUp /> : <AiOutlineArrowDown /> : <></>}
                                    </div>
                                    <p>{toBRL(sumNetFii.current)}</p>
                                </div>
                            </TableCell>
                        ),
                },
            },
            {
                name: "percent_net_fii",
                label: "% NET FII",
                options: {
                    filter: tabActive.current == 1? true : false,
                    sort: true,
                    viewColumns: false,
                    display: tabActive.current == 0? columnsToDisplay.current?.some((x) => x.column === "percent_net_fii" && x.display) ?? true : false,
                    filterType: "custom",
                    filterList: filtersList.current["percent_net_fii"],
                        customFilterListOptions: {
                            render: (v) => v,
                            update: (filterList, filterPos, index) => {
                                filterList[index].splice(filterPos, 1);
                                return filterList;
                            },
                        },
                        filterOptions: {
                            logic: (location, filters, row) => {
                                if (filters.length) return !filters.includes(location);
                                return false;
                            },
                            display: (filterList, onChange, index, column) => {
                            const optionValues = tabActive.current == 1 ? 
                                listClients?.current.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v)).sort((a, b) => a - b) : 
                                teamClients?.current.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v)).sort((a, b) => a - b)        
                                return (
                                    <FormControl>
                                        <InputLabel htmlFor="select-multiple-chip" variant="standard">
                                            {column.label}
                                        </InputLabel>
                                        <Select
                                            multiple
                                            variant="standard"
                                            value={filterList[index]}
                                            renderValue={(selected) => selected.join(", ")}
                                            onChange={(event) => {
                                                filterList[index] = event.target.value;
                                                onChange(filterList[index], index, column);
                                            }}>
                                            {optionValues.map((item) => (
                                                <MenuItem key={item} value={item}>
                                                    <Checkbox color="primary" checked={filterList[index].indexOf(item) > -1} />
                                                    <ListItemText primary={item} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                );
                            },
                        },
                        setCellProps: () => ({
                            style: {
                                whiteSpace: "nowrap",
                                paddingLeft: "0.5rem",
                                paddingRight: "0.5rem",
                            },
                        }),
                        customBodyRender: (val, tableMeta) => {
                            return String(val.toFixed(2)) + "%";
                        },
                },
            },
            {
                name: "pos_rv",
                label: "POS RV",
                options: {
                    filter: tabActive.current == 1? true : false,
                    sort: true,
                    display: tabActive.current == 1? columnsToDisplay.current?.some((x) => x.column === "pos_rv" && x.display) ?? true : true,
                    filterType: "custom",
                    filterList: filtersList.current["pos_rv"],
                        customFilterListOptions: {
                            render: (v) => v,
                            update: (filterList, filterPos, index) => {
                                filterList[index].splice(filterPos, 1);
                                return filterList;
                            },
                        },
                        filterOptions: {
                            logic: (location, filters, row) => {
                                if (filters.length) return !filters.includes(location);
                                return false;
                            },
                            display: (filterList, onChange, index, column) => {
                            const optionValues = tabActive.current == 1 ? 
                                listClients?.current.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v)).sort((a, b) => a - b) : 
                                teamClients?.current.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v)).sort((a, b) => a - b)        
                                return (
                                    <FormControl>
                                        <InputLabel htmlFor="select-multiple-chip" variant="standard">
                                            {column.label}
                                        </InputLabel>
                                        <Select
                                            multiple
                                            variant="standard"
                                            value={filterList[index]}
                                            renderValue={(selected) => selected.join(", ")}
                                            onChange={(event) => {
                                                filterList[index] = event.target.value;
                                                onChange(filterList[index], index, column);
                                            }}>
                                            {optionValues.map((item) => (
                                                <MenuItem key={item} value={item}>
                                                    <Checkbox color="primary" checked={filterList[index].indexOf(item) > -1} />
                                                    <ListItemText primary={item} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                );
                            },
                        },
                        setCellProps: () => ({
                            style: {
                                whiteSpace: "nowrap",
                                paddingLeft: "0.5rem",
                                paddingRight: "0.5rem",
                            },
                        }),
                        customBodyRender: (val, tableMeta) => {
                            return toBRL(val);
                        },
                        customHeadRender: ({ index, name, ...column }, sortColumn, tableMeta) => (
                            <TableCell
                                className="containerSelectContactTypeHeader tss-gm6zfk-MUIDataTableHeadCell-fixedHeader"
                                onClick={() => {
                                    sortColumn(index);
                                }}>
                                <div className="textSumCell">
                                    <div>
                                        {column.label} {tableMeta.name === name ? tableMeta.direction == "asc" ? <AiOutlineArrowUp /> : <AiOutlineArrowDown /> : <></>}
                                    </div>
                                    <p>{toBRL(sumPosRV.current)}</p>
                                </div>
                            </TableCell>
                        ),
                },
            },
            {
                name: "percent_rv",
                label: "% RV",
                options: {
                    filter: tabActive.current == 1? true : false,
                    sort: true,
                    viewColumns: false,
                    display: tabActive.current == 0? columnsToDisplay.current?.some((x) => x.column === "percent_rv" && x.display) ?? true : false,
                    filterType: "custom",
                    filterList: filtersList.current["percent_rv"],
                        customFilterListOptions: {
                            render: (v) => v,
                            update: (filterList, filterPos, index) => {
                                filterList[index].splice(filterPos, 1);
                                return filterList;
                            },
                        },
                        filterOptions: {
                            logic: (location, filters, row) => {
                                if (filters.length) return !filters.includes(location);
                                return false;
                            },
                            display: (filterList, onChange, index, column) => {
                            const optionValues = tabActive.current == 1 ? 
                                listClients?.current.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v)).sort((a, b) => a - b) : 
                                teamClients?.current.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v)).sort((a, b) => a - b)        
                                return (
                                    <FormControl>
                                        <InputLabel htmlFor="select-multiple-chip" variant="standard">
                                            {column.label}
                                        </InputLabel>
                                        <Select
                                            multiple
                                            variant="standard"
                                            value={filterList[index]}
                                            renderValue={(selected) => selected.join(", ")}
                                            onChange={(event) => {
                                                filterList[index] = event.target.value;
                                                onChange(filterList[index], index, column);
                                            }}>
                                            {optionValues.map((item) => (
                                                <MenuItem key={item} value={item}>
                                                    <Checkbox color="primary" checked={filterList[index].indexOf(item) > -1} />
                                                    <ListItemText primary={item} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                );
                            },
                        },
                        setCellProps: () => ({
                            style: {
                                whiteSpace: "nowrap",
                                paddingLeft: "0.5rem",
                                paddingRight: "0.5rem",
                            },
                        }),
                        customBodyRender: (val, tableMeta) => {
                            return String(val.toFixed(2)) + "%";
                        },
                },
            },
            {
                name: "preferred_contact_description",
                label: "CONTATO PREFERENCIAL",
                options: {
                    filter: tabActive == 1 ? true : false,
                    sort: true,
                    display: tabActive.current == 1 ? columnsToDisplay.current?.some((x) => x.column === "preferred_contact_description" && x.display) ?? true : false,
                    filterType: "custom",
                    filterList: filtersList.current["preferred_contact_description"],
                    customBodyRender: (val) => {
                        return val == null ? "Pendente" : val;
                    },
                    customFilterListOptions: {
                        render: (v) => v,
                        update: (filterList, filterPos, index) => {
                            filterList[index].splice(filterPos, 1);
                            return filterList;
                        },
                    },
                    filterOptions: {
                        logic: (location, filters, row) => {
                            if (filters.length) return !filters.includes(location);
                            return false;
                        },
                        display: (filterList, onChange, index, column) => {
                            const optionValues = tabActive.current == 1 ? 
                                listClients?.current.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v)).sort((a, b) => a - b) : 
                                teamClients?.current.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v)).sort((a, b) => a - b)    
                            return (
                                <FormControl>
                                    <InputLabel htmlFor="select-multiple-chip" variant="standard">
                                        {column.label}
                                    </InputLabel>
                                    <Select
                                        multiple
                                        variant="standard"
                                        value={filterList[index]}
                                        renderValue={(selected) => selected.join(", ")}
                                        onChange={(event) => {
                                            filterList[index] = event.target.value;
                                            onChange(filterList[index], index, column);
                                        }}>
                                        {optionValues.map((item) => (
                                            <MenuItem key={item} value={item}>
                                                <Checkbox color="primary" checked={filterList[index].indexOf(item) > -1} />
                                                <ListItemText primary={item} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            );
                        },
                    },
                },
            },

            {
                name: "inclusao_acoes",
                label: "DATA DE INDICAÇÃO",
                options: {
                    filter: tabActive == 1 ? true : false,
                    sort: true,
                    display: tabActive.current == 1 ? columnsToDisplay.current?.some((x) => x.column === "inclusao_acoes" && x.display) ?? true : false,
                    filterType: "custom",
                    filterList: filtersList.current["inclusao_acoes"],
                    customFilterListOptions: {
                        render: (v) => v,
                        update: (filterList, filterPos, index) => {
                            filterList[index].splice(filterPos, 1);
                            return filterList;
                        },
                    },
                    customBodyRender: (val) => {
                        return val ? datetimeToDatePtBR(val) : "-";
                    },
                    filterOptions: {
                        logic: (location, filters, row) => {
                            if (filters.length) return !filters.includes(location);
                            return false;
                        },
                        display: (filterList, onChange, index, column) => {
                            const optionValues = tabActive.current == 1 ? 
                                listClients?.current.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v)).sort((a, b) => a - b) : 
                                teamClients?.current.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v)).sort((a, b) => a - b)    
                            return (
                                <FormControl>
                                    <InputLabel htmlFor="select-multiple-chip" variant="standard">
                                        {column.label}
                                    </InputLabel>
                                    <Select
                                        multiple
                                        variant="standard"
                                        value={filterList[index]}
                                        renderValue={(selected) => selected.join(", ")}
                                        onChange={(event) => {
                                            filterList[index] = event.target.value;
                                            onChange(filterList[index], index, column);
                                        }}>
                                        {optionValues.map((item) => (
                                            <MenuItem key={item} value={item}>
                                                <Checkbox color="primary" checked={filterList[index].indexOf(item) > -1} />
                                                <ListItemText primary={item} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            );
                        },
                    },
                },
            },        
            {
                name: "broker_relationship",
                label: "STATUS RELACIONAMENTO",
                options: {
                    filter: tabActive == 1 ? true : false,
                    sort: true,
                    display: tabActive.current == 1 ? columnsToDisplay.current?.some((x) => x.column === "broker_relationship" && x.display) ?? true : false,
                    filterType: "custom",
                    filterList: filtersList.current["broker_relationship"],
                    customFilterListOptions: {
                        render: (v) => v,
                        update: (filterList, filterPos, index) => {
                            filterList[index].splice(filterPos, 1);
                            return filterList;
                        },
                    },
                    filterOptions: {
                        logic: (location, filters, row) => {
                            if (filters.length) return !filters.includes(location);
                            return false;
                        },
                        display: (filterList, onChange, index, column) => {
                            const optionValues = tabActive.current == 1 ? 
                                listClients?.current.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v)).sort((a, b) => a - b) : 
                                teamClients?.current.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v)).sort((a, b) => a - b)    
                            return (
                                <FormControl>
                                    <InputLabel htmlFor="select-multiple-chip" variant="standard">
                                        {column.label}
                                    </InputLabel>
                                    <Select
                                        multiple
                                        variant="standard"
                                        value={filterList[index]}
                                        renderValue={(selected) => selected.join(", ")}
                                        onChange={(event) => {
                                            filterList[index] = event.target.value;
                                            onChange(filterList[index], index, column);
                                        }}>
                                        {optionValues.map((item) => (
                                            <MenuItem key={item} value={item}>
                                                <Checkbox color="primary" checked={filterList[index].indexOf(item) > -1} />
                                                <ListItemText primary={item} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            );
                        },
                    },
                },
            },
        
            {
                name: "def_broker_id",
                label: "CÓDIGO BROKER REFERÊNCIA",
                options: {
                    filter: tabActive == 1 ? true : false,
                    sort: true,
                    display: tabActive.current == 1 ? columnsToDisplay.current?.some((x) => x.column === "def_broker_id" && x.display) ?? true : false,
                    filterType: "custom",
                    filterList: filtersList["def_broker_id"],
                    customFilterListOptions: {
                        render: (v) => v,
                        update: (filterList, filterPos, index) => {
                            filterList[index].splice(filterPos, 1);
                            return filterList;
                        },
                    },
                    filterOptions: {
                        logic: (location, filters, row) => {
                            if (filters.length) return !filters.includes(location);
                            return false;
                        },
                        display: (filterList, onChange, index, column) => {
                            const optionValues = tabActive.current == 1 ? 
                                listClients?.current.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v)).sort((a, b) => a - b) : 
                                teamClients?.current.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v)).sort((a, b) => a - b)    
                            return (
                                <FormControl>
                                    <InputLabel htmlFor="select-multiple-chip" variant="standard">
                                        {column.label}
                                    </InputLabel>
                                    <Select
                                        multiple
                                        variant="standard"
                                        value={filterList[index]}
                                        renderValue={(selected) => selected.join(", ")}
                                        onChange={(event) => {
                                            filterList[index] = event.target.value;
                                            onChange(filterList[index], index, column);
                                        }}>
                                        {optionValues.map((item) => (
                                            <MenuItem key={item} value={item}>
                                                <Checkbox color="primary" checked={filterList[index].indexOf(item) > -1} />
                                                <ListItemText primary={item} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            );
                        },
                    },
                    setCellProps: () => ({
                        style: {
                            whiteSpace: "nowrap",
                            paddingLeft: "0.5rem",
                            paddingRight: "0.5rem",
                        },
                    }),
                    customBodyRender: (val) => {
                        return val ? val : "-";
                    },
                },
            },
            {
                name: "def_broker",
                label: "BROKER REFERÊNCIA",
                options: {
                    filter: tabActive == 1 ? true : false,
                    sort: true,
                    display: tabActive.current == 1 ? columnsToDisplay.current?.some((x) => x.column === "def_broker" && x.display) ?? true : false,
                    filterType: "custom",
                    filterList: filtersList["def_broker"],
                    customFilterListOptions: {
                        render: (v) => v,
                        update: (filterList, filterPos, index) => {
                            filterList[index].splice(filterPos, 1);
                            return filterList;
                        },
                    },
                    filterOptions: {
                        logic: (location, filters, row) => {
                            if (filters.length) return !filters.includes(location);
                            return false;
                        },
                        display: (filterList, onChange, index, column) => {
                            const optionValues = tabActive.current == 1 ? 
                                listClients?.current.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v)).sort((a, b) => a - b) : 
                                teamClients?.current.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v)).sort((a, b) => a - b)    
                            return (
                                <FormControl>
                                    <InputLabel htmlFor="select-multiple-chip" variant="standard">
                                        {column.label}
                                    </InputLabel>
                                    <Select
                                        multiple
                                        variant="standard"
                                        value={filterList[index]}
                                        renderValue={(selected) => selected.join(", ")}
                                        onChange={(event) => {
                                            filterList[index] = event.target.value;
                                            onChange(filterList[index], index, column);
                                        }}>
                                        {optionValues.map((item) => (
                                            <MenuItem key={item} value={item}>
                                                <Checkbox color="primary" checked={filterList[index].indexOf(item) > -1} />
                                                <ListItemText primary={item} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            );
                        },
                    },
                    setCellProps: () => ({
                        style: {
                            whiteSpace: "nowrap",
                            paddingLeft: "0.5rem",
                            paddingRight: "0.5rem",
                        },
                    }),
                    customBodyRender: (val) => {
                        return val ? val : "-";
                    },
                },
            },
        {
            name: "seg_client",
            label: "SEGMENTO CLIENTE",
            options: {
                filter: true,
                sort: true,
                display: tabActive.current == 1 ? columnsToDisplay.current?.some((x) => x.column === "seg_client" && x.display) ?? true : false,
                filterType: "custom",
                filterList: filtersList["seg_client"],
                customBodyRender: (val) => {
                    return val
                },
                customFilterListOptions: {
                    render: (v) => v,
                    update: (filterList, filterPos, index) => {
                        filterList[index].splice(filterPos, 1);
                        return filterList;
                    },
                },
                filterOptions: {
                    logic: (location, filters, row) => {
                        if (filters.length) return !filters.includes(location);
                        return false;
                    },
                    display: (filterList, onChange, index, column) => {
                            const optionValues = tabActive.current == 1 ? 
                                listClients?.current.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v)).sort((a, b) => a - b) : 
                                teamClients?.current.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v)).sort((a, b) => a - b)                        
                        return (
                            <FormControl>
                                <InputLabel htmlFor="select-multiple-chip" variant="standard">
                                    {column.label}
                                </InputLabel>
                                <Select
                                    multiple
                                    variant="standard"
                                    value={filterList[index]}
                                    renderValue={(selected) => selected.join(", ")}
                                    onChange={(event) => {
                                        filterList[index] = event.target.value;
                                        onChange(filterList[index], index, column);
                                    }}>
                                    {optionValues.map((item) => (
                                        <MenuItem key={item} value={item}>
                                            <Checkbox color="primary" checked={filterList[index].indexOf(item) > -1} />
                                            <ListItemText primary={item} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        );
                    },
                },
            },  

        },
        {
            name: "serviceModel",
            label: "MODELO DE ATENDIMENTO",
            options: {
                filter: true,
                sort: true,
                display: tabActive.current == 1 ? columnsToDisplay.current?.some((x) => x.column === "serviceModel" && x.display) ?? true : false,
                filterType: "custom",
                filterList: filtersList["serviceModel"],
                customBodyRender: (val) => {
                    return val == null ? "-" : val == "1" ? 'Ultra High' : val == "2" ? 'Top Tier' : val == "3" ? 'Private' : val == "4" ? 'Digital' : val == "5" ? 'On Demand' : '-';
                },
                customFilterListOptions: {
                    render: (v) => v,
                    update: (filterList, filterPos, index) => {
                        filterList[index].splice(filterPos, 1);
                        return filterList;
                    },
                },
                filterOptions: {
                    logic: (location, filters, row) => {
                        if (filters.length) return !filters.includes(location);
                        return false;
                    },
                    display: (filterList, onChange, index, column) => {
                            const optionValues = tabActive.current == 1 ? 
                                listClients?.current.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v)).sort((a, b) => a - b) : 
                                teamClients?.current.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v)).sort((a, b) => a - b)
                        return (
                            <FormControl>
                                <InputLabel htmlFor="select-multiple-chip" variant="standard">
                                    {column.label}
                                </InputLabel>
                                <Select
                                    multiple
                                    variant="standard"
                                    value={filterList[index]}
                                    renderValue={(selected) => selected.join(", ")}
                                    onChange={(event) => {
                                        filterList[index] = event.target.value;
                                        onChange(filterList[index], index, column);
                                    }}>
                                    {optionValues.map((item) => (
                                        <MenuItem key={item} value={item}>
                                            <Checkbox color="primary" checked={filterList[index].indexOf(item) > -1} />
                                            <ListItemText primary={item} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        );
                    },
                },
            },

        },
        
        {
            name: "evasionReason",
            label: "EVASÃO",
            options: {
                filter: true,
                sort: true,
                display: tabActive.current == 1 ? columnsToDisplay.current?.some((x) => x.column === "evasionReason" && x.display) ?? true : false,
                filterType: "custom",
                filterList: filtersList["evasionReason"],
                customBodyRender: (val) => {
                    return val == null ? "-" : val == "1" ? 'Assessor solicitou retirada da mesa' : val == '2' ? 'Cliente zerou posição de RV' : val == "3" ? 'Cliente evadiu do escritório' : val == "4" ? 'Cliente solicitou cancelamento do serviço da mesa' : val == "5" ? 'Outros' : 'Outros';  
                },
                customFilterListOptions: {
                    render: (v) => v,
                    update: (filterList, filterPos, index) => {
                        filterList[index].splice(filterPos, 1);
                        return filterList;
                    },
                },
                filterOptions: {
                    logic: (location, filters, row) => {
                        if (filters.length) return !filters.includes(location);
                        return false;
                    },
                    display: (filterList, onChange, index, column) => {
                            const optionValues = tabActive.current == 1 ? 
                                listClients?.current.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v)).sort((a, b) => a - b) : 
                                teamClients?.current.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v)).sort((a, b) => a - b)
                        return (
                            <FormControl>
                                <InputLabel htmlFor="select-multiple-chip" variant="standard">
                                    {column.label}
                                </InputLabel>
                                <Select
                                    multiple
                                    variant="standard"
                                    value={filterList[index]}
                                    renderValue={(selected) => selected.join(", ")}
                                    onChange={(event) => {
                                        filterList[index] = event.target.value;
                                        onChange(filterList[index], index, column);
                                    }}>
                                    {optionValues.map((item) => (
                                        <MenuItem key={item} value={item}>
                                            <Checkbox color="primary" checked={filterList[index].indexOf(item) > -1} />
                                            <ListItemText primary={item} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        );
                    },
                },
            },

        },
        {
            name: "contact",
            label: "CONTATAR?",
            options: {
                filter: true,
                sort: true,
                display: tabActive.current == 1 ? columnsToDisplay.current?.some((x) => x.column === "contact" && x.display) ?? true : false,
                filterType: "custom",
                filterList: filtersList["contact"],
                customBodyRender: (val) => {
                    return val == null ? "-" : val == 0 ? "Não" : "Sim";
                },
                customFilterListOptions: {
                    render: (v) => v,
                    update: (filterList, filterPos, index) => {
                        filterList[index].splice(filterPos, 1);
                        return filterList;
                    },
                },
                filterOptions: {
                    logic: (location, filters, row) => {
                        if (filters.length) return !filters.includes(location);
                        return false;
                    },
                    display: (filterList, onChange, index, column) => {
                            const optionValues = tabActive.current == 1 ? 
                                listClients?.current.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v)).sort((a, b) => a - b) : 
                                teamClients?.current.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v)).sort((a, b) => a - b)
                        return (
                            <FormControl>
                                <InputLabel htmlFor="select-multiple-chip" variant="standard">
                                    {column.label}
                                </InputLabel>
                                <Select
                                    multiple
                                    variant="standard"
                                    value={filterList[index]}
                                    renderValue={(selected) => selected.join(", ")}
                                    onChange={(event) => {
                                        filterList[index] = event.target.value;
                                        onChange(filterList[index], index, column);
                                    }}>
                                    {optionValues.map((item) => (
                                        <MenuItem key={item} value={item}>
                                            <Checkbox color="primary" checked={filterList[index].indexOf(item) > -1} />
                                            <ListItemText primary={item} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        );
                    },
                },
            },

        },
        {
            name: "assetPreference",
            label: "PREFERÊNCIA DE ATIVOS",
            options: {
                filter: true,
                sort: true,
                display: false,
                viewColumns: false,
                filterType: "custom",
                filterList: filtersList["assetPreference"],
                customBodyRender: (val) => {
                    return val == null ? "-" : val ;
                },
                customFilterListOptions: {
                    render: (v) => v,
                    update: (filterList, filterPos, index) => {
                        filterList[index].splice(filterPos, 1);
                        return filterList;
                    },
                },
                filterOptions: {
                    logic: (location, filters, row) => {
                        if (filters.length) return !filters.includes(location);
                        return false;
                    },
                    display: (filterList, onChange, index, column) => {
                            const optionValues = tabActive.current == 1 ? 
                                listClients?.current.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v)).sort((a, b) => a - b) : 
                                teamClients?.current.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v)).sort((a, b) => a - b)
                        return (
                            <FormControl>
                                <InputLabel htmlFor="select-multiple-chip" variant="standard">
                                    {column.label}
                                </InputLabel>
                                <Select
                                    multiple
                                    variant="standard"
                                    value={filterList[index]}
                                    renderValue={(selected) => selected.join(", ")}
                                    onChange={(event) => {
                                        filterList[index] = event.target.value;
                                        onChange(filterList[index], index, column);
                                    }}>
                                    {optionValues.map((item) => (
                                        <MenuItem key={item} value={item}>
                                            <Checkbox color="primary" checked={filterList[index].indexOf(item) > -1} />
                                            <ListItemText primary={item} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        );
                    },
                },
            },

        },
        {
            name: "assetRestrition",
            label: "RESTRIÇÃO DE ATIVOS",
            options: {
                filter: true,
                sort: true,
                display:  false,
                viewColumns: false,
                filterType: "custom",
                filterList: filtersList["assetPreference"],
                customBodyRender: (val) => {
                    return val == null ? "-" : val ;
                },
                customFilterListOptions: {
                    render: (v) => v,
                    update: (filterList, filterPos, index) => {
                        filterList[index].splice(filterPos, 1);
                        return filterList;
                    },
                },
                filterOptions: {
                    logic: (location, filters, row) => {
                        if (filters.length) return !filters.includes(location);
                        return false;
                    },
                    display: (filterList, onChange, index, column) => {
                            const optionValues = tabActive.current == 1 ? 
                                listClients?.current.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v)).sort((a, b) => a - b) : 
                                teamClients?.current.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v)).sort((a, b) => a - b)
                        return (
                            <FormControl>
                                <InputLabel htmlFor="select-multiple-chip" variant="standard">
                                    {column.label}
                                </InputLabel>
                                <Select
                                    multiple
                                    variant="standard"
                                    value={filterList[index]}
                                    renderValue={(selected) => selected.join(", ")}
                                    onChange={(event) => {
                                        filterList[index] = event.target.value;
                                        onChange(filterList[index], index, column);
                                    }}>
                                    {optionValues.map((item) => (
                                        <MenuItem key={item} value={item}>
                                            <Checkbox color="primary" checked={filterList[index].indexOf(item) > -1} />
                                            <ListItemText primary={item} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        );
                    },
                },
            },

        },
        {
            name: "meetLanguage",
            label: "LINGUAGEM DESEJADA",
            options: {
                filter: true,
                sort: true,
                display: tabActive.current == 1 ? columnsToDisplay.current?.some((x) => x.column === "meetLanguage" && x.display) ?? true : false,
                filterType: "custom",
                filterList: filtersList["meetLanguage"],
                customBodyRender: (val) => {
                    return val == null ? "-" : val == "0" ? "Informal" : "Formal";
                },
                customFilterListOptions: {
                    render: (v) => v,
                    update: (filterList, filterPos, index) => {
                        filterList[index].splice(filterPos, 1);
                        return filterList;
                    },
                },
                filterOptions: {
                    logic: (location, filters, row) => {
                        if (filters.length) return !filters.includes(location);
                        return false;
                    },
                    display: (filterList, onChange, index, column) => {
                            const optionValues = tabActive.current == 1 ? 
                                listClients?.current.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v)).sort((a, b) => a - b) : 
                                teamClients?.current.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v)).sort((a, b) => a - b)
                        return (
                            <FormControl>
                                <InputLabel htmlFor="select-multiple-chip" variant="standard">
                                    {column.label}
                                </InputLabel>
                                <Select
                                    multiple
                                    variant="standard"
                                    value={filterList[index]}
                                    renderValue={(selected) => selected.join(", ")}
                                    onChange={(event) => {
                                        filterList[index] = event.target.value;
                                        onChange(filterList[index], index, column);
                                    }}>
                                    {optionValues.map((item) => (
                                        <MenuItem key={item} value={item}>
                                            <Checkbox color="primary" checked={filterList[index].indexOf(item) > -1} />
                                            <ListItemText primary={item} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        );
                    },
                },
            },

        },
        {
            name: "generalObservations",
            label: "NOTAS GERAIS",
            options: {
                filter: true,
                sort: true,
                display: false,
                viewColumns: false,
                filterType: "custom",
                filterList: filtersList["generalObservations"],
                customBodyRender: (val) => {
                    return val == null ? '-' : val;
                },
                customFilterListOptions: {
                    render: (v) => v,
                    update: (filterList, filterPos, index) => {
                        filterList[index].splice(filterPos, 1);
                        return filterList;
                    },
                },
                filterOptions: {
                    logic: (location, filters, row) => {
                        if (filters.length) return !filters.includes(location);
                        return false;
                    },
                    display: (filterList, onChange, index, column) => {
                            const optionValues = tabActive.current == 1 ? 
                                listClients?.current.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v)).sort((a, b) => a - b) : 
                                teamClients?.current.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v)).sort((a, b) => a - b)
                        return (
                            <FormControl>
                                <InputLabel htmlFor="select-multiple-chip" variant="standard">
                                    {column.label}
                                </InputLabel>
                                <Select
                                    multiple
                                    variant="standard"
                                    value={filterList[index]}
                                    renderValue={(selected) => selected.join(", ")}
                                    onChange={(event) => {
                                        filterList[index] = event.target.value;
                                        onChange(filterList[index], index, column);
                                    }}>
                                    {optionValues.map((item) => (
                                        <MenuItem key={item} value={item}>
                                            <Checkbox color="primary" checked={filterList[index].indexOf(item) > -1} />
                                            <ListItemText primary={item} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        );
                    },
                },
            },

        },
        
            {
                name: "frequency_contact",
                label: "id Frequencia",
                options: {
                    filter: false,
                    sort: false,
                    display: false,
                    viewColumns: false,
                },
            },
            {
                name: "advisor_profile",
                label: "perfil assessor",
                options: {
                    filter: false,
                    sort: false,
                    display: false,
                    viewColumns: false,
                },
            },
            {
                name: "last_rv_appointment_date",
                label: "ÚLTIMA REUNIÃO COM O CLIENTE",
                options: {
                    filter: true,
                    sort: true,
                    display: tabActive.current == 1 ? columnsToDisplay.current?.some((x) => x.column === "last_rv_appointment_date" && x.display) ?? true : false,
                    filterType: "custom",
                    filterList: filtersList["last_rv_appointment_date"],
                    customBodyRender: (val) => {
                        return val == null ? "-" : datetimeToDatePtBR(val);
                    },
                    customFilterListOptions: {
                        render: (v) => v,
                        update: (filterList, filterPos, index) => {
                            filterList[index].splice(filterPos, 1);
                            return filterList;
                        },
                    },
                    filterOptions: {
                        logic: (location, filters, row) => {
                            if (filters.length) return !filters.includes(location);
                            return false;
                        },
                        display: (filterList, onChange, index, column) => {
                            const optionValues = tabActive.current == 1 ? 
                                listClients?.current.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v)).sort((a, b) => a - b) : 
                                teamClients?.current.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v)).sort((a, b) => a - b)    
                            return (
                                <FormControl>
                                    <InputLabel htmlFor="select-multiple-chip" variant="standard">
                                        {column.label}
                                    </InputLabel>
                                    <Select
                                        multiple
                                        variant="standard"
                                        value={filterList[index]}
                                        renderValue={(selected) => selected.join(", ")}
                                        onChange={(event) => {
                                            filterList[index] = event.target.value;
                                            onChange(filterList[index], index, column);
                                        }}>
                                        {optionValues.map((item) => (
                                            <MenuItem key={item} value={item}>
                                                <Checkbox color="primary" checked={filterList[index].indexOf(item) > -1} />
                                                <ListItemText primary={item} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            );
                        },
                    },
                },
    
            },
            
        {
            name: "rv_appointment_count",
            label: "QUANTIDADE DE REUNIÕES",
            options: {
                filter: false,
                sort: true,
                viewColumns: false,
                display: tabActive.current == 0 ? columnsToDisplay.current?.some((x) => x.column === "rv_appointment_count" && x.display) ?? true : false,
                filterType: "custom",
                filterList: filtersList["rv_appointment_count"],
                customBodyRender: (val) => {
                    return val == null ? "-" : val;
                },
                customFilterListOptions: {
                    render: (v) => v,
                    update: (filterList, filterPos, index) => {
                        filterList[index].splice(filterPos, 1);
                        return filterList;
                    },
                },
                filterOptions: {
                    logic: (location, filters, row) => {
                        if (filters.length) return !filters.includes(location);
                        return false;
                    },
                    display: (filterList, onChange, index, column) => {
                            const optionValues = tabActive.current == 1 ? 
                                listClients?.current.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v)).sort((a, b) => a - b) : 
                                teamClients?.current.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v)).sort((a, b) => a - b)
                        return (
                            <FormControl>
                                <InputLabel htmlFor="select-multiple-chip" variant="standard">
                                    {column.label}
                                </InputLabel>
                                <Select
                                    multiple
                                    variant="standard"
                                    value={filterList[index]}
                                    renderValue={(selected) => selected.join(", ")}
                                    onChange={(event) => {
                                        filterList[index] = event.target.value;
                                        onChange(filterList[index], index, column);
                                    }}>
                                    {optionValues.map((item) => (
                                        <MenuItem key={item} value={item}>
                                            <Checkbox color="primary" checked={filterList[index].indexOf(item) > -1} />
                                            <ListItemText primary={item} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        );
                    },
                },
            },

        },
        
        
        )

        //cria uma copia de listProducts
        let listProductsCopy = listProducts?.current ? [...listProducts?.current] : listProducts?.current

        //reordena o listProducts de forma que selectedCampaign fique sempre em primeiro
        if (selectedCampaign) {
            // ao selecionar campanha mudar as colunas para display true
            columnsToDisplay.current?.forEach((e) => {
                if(e?.column?.includes(selectedCampaign?.name)){
                    e.display = true
                }
            })

            localStorage.setItem("columnsAbc", JSON.stringify(columnsToDisplay.current))

            listProductsCopy?.sort((a, b) => {
                if (a.id == selectedCampaign?.id) return -1
                if (b.id == selectedCampaign?.id) return 1
                return 0
            })
        }

        const today = new Date();
        today.setHours(0,0,0,0)
        today.setDate(today.getDate() + 1);
        //marca a campanha como visivel
        // sort no listProductsCopy com campanhas de prioridade 3 e pela data final colocar no final das campnhas
        const listProductsCopyAtivas = listProductsCopy?.filter((e) => {
            const dateFinishCampains = new Date(e.finish)
            dateFinishCampains.setHours(0,0,0,0)
            dateFinishCampains.setDate(dateFinishCampains.getDate() + 2);

            return today <= dateFinishCampains
        }
        )
        const listProductsCopyEncerradas = listProductsCopy?.filter((e) => {
            const dateFinishCampains = new Date(e.finish)
            dateFinishCampains.setHours(0,0,0,0)
            dateFinishCampains.setDate(dateFinishCampains.getDate() + 2);

            return today > dateFinishCampains
        }
        )

        // sort no listProductsCopy com campanhas de prioridade 3 e pela data final colocar no final das campnhas, campanhas de prioridade 1 e 2 devem ficar no topo da lista
        listProductsCopyAtivas?.sort((a, b) => {
            if (a.priority == 3 && b.priority != 3) return 1
            if (a.priority != 3 && b.priority == 3) return -1
            if (a.priority == 3 && b.priority == 3) {
                if (a.finish > b.finish) return 1
                if (a.finish < b.finish) return -1
                return 0
            }
            return 0
        })
        //campanhas encerradas sao sempre prioridade 3
        listProductsCopyEncerradas?.sort((a, b) => {
            if (a.finish < b.finish) return 1
            if (a.finish > b.finish) return -1
            return 0
        })
        //concatena as campanhas ativas com as encerradas
        listProductsCopy = listProductsCopyAtivas?.concat(listProductsCopyEncerradas)

        listProductsCopy?.sort((a, b) => {
            // Coloca a campanha selecionada em primeiro lugar
            if (a.id === selectedCampaign?.id) return -1;
            if (b.id === selectedCampaign?.id) return 1;

    
        });

       
        

        

       

        listProductsCopy?.forEach((e) => {
            const produtoX = e.description.toUpperCase()
            const priorityAux = campaignPriority.current?.find((p) => p.id == e.priority)
            const isFirstColumnVisible = columnVisibility["e.description.toUpperCase()"]
            const today = new Date();
            const tomorrow = new Date();
            const dateFinishCampains = new Date(e.finish)
            
            tomorrow.setDate(today.getDate());
            tomorrow.setHours(0,0,0,0)
            dateFinishCampains.setHours(0,0,0,0)
            dateFinishCampains.setDate(dateFinishCampains.getDate() + 1);

            visibleColumnsRef.current.push(
                {
                name: e.name + "__objective",
                group: e.name ,
                label: produtoX ,
                options: {
                    filter: false,
                    sort: false,
                    sortDescFirst: true,
                    display: tabActive.current == 1 ? columnsToDisplay.current?.some(x => x.column === (e.name + "__objective") && x.display) : false,
                    setCellProps: () => ({
                        style: {
                            whiteSpace: "nowrap",
                            padding: "0px 0rem 0px 0rem",
                            borderLeft: "0.5rem solid white",
                            backgroundColor: "#fffcef",
                        },
                    }),
                    customHeadRender: ({ index, name, ...column }, sortColumn, tableMeta) => (
                        <TableCell className="leftTesteAbc">
                            <span
                                className="containerCellNameProductDash"
                                style={{
                                    marginRight: "0px",
                                    height: "2.7rem",
                                    paddingTop: "0.5rem",
                                    backgroundColor: selectedCampaign?.id == e.id ? "#D4AC00" : "#fffcef",
                                    display: "grid",
                                    gridTemplateColumns: "auto auto",
                                    borderTopLeftRadius: "10px",
                                }}
                            >
                                <div className="productNameDash" style={{ paddingLeft: "1rem" }}>
                                    {separarLinhas(e)}
                                </div>
                            </span>
                            <span
                                className="headerProductCellBoxAdvisor"
                                style={{
                                    marginRight: "0px",
                                    backgroundColor: "#fffcef",
                                    cursor: "pointer",
                                }}
                                onClick={() => {
                                    sortColumn(index)
                                }}
                            >
                                <div className="subtitleCellBoxDash">
                                    <div className="subtitleObjectiveAbcDash" style={{ marginLeft: "0.5rem" }}>
                                        Oportunidade
                                    </div>
                                    <div className="subtitleCellBoxDashSum">
                                        <span style={{ color: "green" }}>
                                            {" "}
                                            {toBRLInteger(sum.current[e.name]?.planned_value ? sum.current[e.name]?.planned_value : 0)}{" "}
                                        </span>
                                        /
                                        <span style={{ color: "rgb(212, 172, 0)" }}>
                                            {" "}
                                            {toBRLInteger(sum.current[e.name]?.oportunity ? sum.current[e.name]?.oportunity : 0)}{" "}
                                        </span>
                                    </div>
                                </div>
                                <div className="arrowSortAbc">{tableMeta.name === name ? tableMeta.direction == "asc" ? <AiOutlineArrowUp size={18} /> : <AiOutlineArrowDown size={18} /> : <></>}</div>
                            </span>
                        </TableCell>
                    ),
                    customBodyRender: (val, tableMeta) => {
                        var aux = clientsDash?.current?.find((x) => x?.client_id == tableMeta.rowData[0])

                        return (
                            <div
                                onClick={(w) => {
                                    w.stopPropagation()
                                    var aux = clientsDash?.current?.find((x) => x?.client_id == tableMeta.rowData[0])
                                    setType({
                                        name: "detailsClient",
                                        client: aux,
                                        products: listProducts.current,
                                        product: e,
                                        prioritys: campaignPriority.current,
                                        clientData: tableMeta.rowData,
                                        outros: aux?.evasionReason,
                                        doSum: doSum,
                                    })
                                    setShow(true)
                                }}
                                style={{ width: "100%", height: "100%" }}
                            >
                                <span style={{ color: "green" }}>{aux["c" + e.id + "__planned_value"] ? toBRLInteger(aux["c" + e.id + "__planned_value"]) : '-'}</span>{' '}/{' '}
                                <span style={{ color: "rgb(212, 172, 0)" }}>{aux["c" + e.id + "__objective"] ? toBRLInteger(aux["c" + e.id + "__objective"]) : '-'}</span>
                            </div>
                        )
                    },
                },
                },
                {
                    name: e.name + "__value",
                    group: e.name,
                    label: produtoX + ' :Valor',
                    options: {
                        viewColumns: false,
                        filter: false,
                        sortDescFirst: true,
                        display: tabActive.current == 1 ? columnsToDisplay.current?.some(x => x.column === (e.name + "__objective") && x.display) : false,
                        setCellProps: () => ({
                            style: {
                                whiteSpace: "nowrap",
                                padding: "0px 0.0rem 0px 0.0rem",
                                backgroundColor: "#fffcef",
                            },
                        }),
                        customHeadRender: ({ index, name, ...column }, sortColumn, tableMeta) => (
                            <TableCell className="testeAbc">
                                <span
                                    className="containerCellProductDash"
                                    style={{ marginRight: "0px", height: "2.7rem", paddingTop: "0.5rem", backgroundColor: selectedCampaign?.id == e.id ? "#D4AC00" : "#fffcef" }}
                                >
                                    <span title={priorityAux?.value} style={{ marginTop: "0.5rem" }}>
                                        {e.priority == 1 ? <FaStar color="rgb(255, 195, 0)" size={20} /> : e.priority == 2 ? <BsFire color="red" size={20} /> : null}
                                    </span>
                                </span>

                                <div
                                    className="headerProductCellBoxAdvisor"
                                    style={{
                                        marginRight: "0px",
                                        marginLeft: "0px",
                                        backgroundColor: "#fffcef",
                                        cursor: "pointer",
                                    }}
                                    onClick={() => {
                                        sortColumn(index)
                                    }}
                                >
                                    <div className="subtitleCellBoxDash">
                                        <div className="subtitleObjectiveAbc">Realizado</div>
                                        <div className="subtitleCellBoxDashSum">{toBRLInteger(sum.current[e.name]?.done ? sum.current[e.name].done : 0)}</div>
                                    </div>
                                    {
                                        <div className="arrowSortAbc">
                                            {tableMeta.name === name ? tableMeta.direction == "asc" ? <AiOutlineArrowUp size={18} /> : <AiOutlineArrowDown size={18} /> : <></>}
                                        </div>
                                    }
                                </div>
                            </TableCell>
                        ),
                        customBodyRender: (val, tableMeta) => {
                            // const columnIndex = tableMeta.columnIndex
                            // const realizado = tableMeta.rowData[columnIndex - 1]
                            // const objetivo = tableMeta.rowData[columnIndex - 2]
                            // const status = tableMeta.rowData[columnIndex + 1]
                            // const fee = e.fee

                            return (
                                <div
                                    onClick={(w) => {
                                        w.stopPropagation()
                                        var aux = clientsResult?.current?.table.find((x) => x.client_id == tableMeta.rowData[0])
                                        setType({
                                            name: "detailsClient",
                                            client: aux,
                                            products: listProducts.current,
                                            product: e,
                                            prioritys: campaignPriority.current,
                                            clientData: tableMeta.rowData,
                                            outros: aux?.evasionReason,
                                            doSum: doSum,
                                        })
                                        setShow(true)
                                    }}
                                    style={{ width: "100%", height: "100%" }}
                                >
                                    {val != null ? toBRLInteger(val) : "-"}
                                    {/* {(status !== 'RECUSADO' && status !== 'NÃO ADERENTE' && !val  && status !== 'CANCELADO') ? (
                                        realizado > 0 ? (
                                            <span style={{ color: "green" }}>{toBRLInteger(fee * realizado)}</span>
                                        ) : objetivo > 0 ? (
                                        <span style={{ color: "rgb(212, 172, 0)" }}>{toBRLInteger(fee * objetivo)}</span>
                                        )   :
                                        (
                                            "-"
                                        )
                                    ) : (
                                        "-"
                                    )} */}
                                </div>
                            )
                        },
                    },
                },
                {
                    name: e.name + "__receita_estimada",
                    group: e.name,
                    // label: "Realizado",
                    label: produtoX + ': RECEITA ESTIMADA',
                    options: {
                        viewColumns: false,
                        filter: false,
                        sortDescFirst: true,
                        display: tabActive.current == 1 ? columnsToDisplay.current?.some(x => x.column === (e.name + "__objective") && x.display) : false,
                        setCellProps: () => ({
                            style: {
                                whiteSpace: "nowrap",
                                padding: "0px 0.0rem 0px 0.0rem",
                                backgroundColor: "#fffcef",
                            },
                        }),
                        customHeadRender: ({ index, name, ...column }, sortColumn, tableMeta, val) => (
                            <TableCell className="testeAbc">
                                <span
                                    className="containerCellProductDash"
                                    style={{ marginRight: "0px", height: "2.7rem", paddingTop: "0.5rem", backgroundColor: selectedCampaign?.id == e.id ? "#D4AC00" : "#fffcef", display: "flex", flexDirection:'row', gap:'1rem' }}
                                >
                                    <div
                                        className="statusDivDash"
                                        // className="statusDivDashCopy"
                                        onClick={(j) => {
                                            j.stopPropagation()
                                            // var aux = clientsDash.current.filter((x) => x[e.name + "__operation_id"] != null)
                                            // var filterClients = aux.filter((x) => {
                                            //     listClients?.current.forEach((y) => {
                                            //         y.codigo = x.client_id
                                            //     })  
                                            // })
                                            var aux = clientsDash.current.filter((x) => {
                                                return x["c" + e.id + "__operation_id"] != null && listClients.current.find((y) => y.broker_id == x.codigo_broker);
                                            });

                                            var client = clientsDash.current.filter((x) => {
                                                x["c" + e.id + "__operation_id"] != null && listClients.current.filter((y) => y.codigo_broker == x.broker_id);
                                            });
                                            
                                            console.log('aux', aux, 'listClients', listClients.current, 'clientDash', clientsDash.current, 'client', client)
                                            

                                            setType({
                                                name: "detailsClientProduto",
                                                client: client[0],
                                                product: e,
                                                products: listProducts.current,
                                                clients: client,
                                                prioritys: campaignPriority.current,
                                                clientData: tableMeta.rowData,
                                                doSum: doSum,
                                            })

                                            setShow(true)
                                        
                                         }}
                                        style={{ marginTop: "0.5rem" }}
                                    >
                                        <div className="textStatusAbc">Acessar Detalhes </div>
                                        {/* <div 
                                            className="textStatusAbc detailscampangeDash" 
                                            style={{ 
                                                background: selectedCampaign?.id === null ? '#D4AC00' : selectedCampaign?.id === e.id ? '#414141' : '#D4AC00' , 
                                                color: selectedCampaign?.id === null ? '#414141' : selectedCampaign?.id === e.id ? '#fff' : '#414141',
                                                borderRadius:'10px', 
                                                padding:'6px', 
                                                transition:'0.2s' }}
                                        >
                                                Acessar Detalhes 
                                        </div> */}
                                    </div>
                                    <Link
                                        to={{
                                            pathname: "/baterBase",
                                            state: {
                                                product: e,
                                            },
                                        }}
                                        style={{marginTop:'0.5rem', marginRight:'1rem'}}
                                        >
                                        <span style={{marginTop:'0.5rem', marginRight:'1rem'}}>Bater Base</span>
                                    </Link>
                                </span>

                                <div
                                    className="headerProductCellBoxAdvisor"
                                    style={{
                                        marginRight: "0px",
                                        marginLeft: "0px",
                                        backgroundColor: "#fffcef",
                                        cursor: "pointer",
                                    }}
                                    onClick={() => {
                                        sortColumn(index)
                                    }}
                                >
                                    <div className="subtitleCellBoxDash">
                                        <div className="subtitleObjectiveAbc">Receita Estimada</div>
                                        <div className="subtitleCellBoxDashSum">
                                            <span style={{ color: "green" }}>
                                                {" "}
                                                {toBRLInteger(sum.current[e.name]?.receitaRe /*  && (String(val).toUpperCase) !== "RECUSADO"  */ ? sum.current[e.name].receitaRe : 0)}{" "}
                                            </span>
                                            /
                                            <span style={{ color: "rgb(212, 172, 0)" }}>
                                                {" "}
                                                {toBRLInteger(sum.current[e.name]?.receitaOp /*  && (String(val).toUpperCase) !== "RECUSADO"  */ ? sum.current[e.name].receitaOp : 0)}{" "}
                                            </span>
                                        </div>
                                    </div>
                                    {
                                        <div className="arrowSortAbc">
                                            {tableMeta.name === name ? tableMeta.direction == "asc" ? <AiOutlineArrowUp size={18} /> : <AiOutlineArrowDown size={18} /> : <></>}
                                        </div>
                                    }
                                </div>
                            </TableCell>
                        ),
                        customBodyRender: (val, tableMeta) => {
                            const columnIndex = tableMeta.columnIndex
                            const realizado = tableMeta.rowData[columnIndex - 1]
                            const objetivo = tableMeta.rowData[columnIndex - 2]
                            const status = tableMeta.rowData[columnIndex + 1]
                            const fee = e.fee
                            return (
                                <div
                                    onClick={(w) => {
                                        w.stopPropagation()
                                        var aux = clientsDash.current.find((x) => x.client_id == tableMeta.rowData[0])
                                        setType({
                                            name: "detailsClient",
                                            client: aux,
                                            products: listProducts.current,
                                            product: e,
                                            prioritys: campaignPriority.current,
                                            clientData: tableMeta.rowData,
                                            outros: aux?.evasionReason,
                                            doSum: doSum,
                                        })
                                        setShow(true)
                                    }}
                                    style={{ width: "100%", height: "100%" }}
                                >
                                    {status !== "RECUSADO" && status !== "NÃO ADERENTE" && !val && status !== "CANCELADO" ? (
                                        realizado > 0 ? (
                                            <span style={{ color: "green" }}>{toBRLInteger(fee * realizado)}</span>
                                        ) : objetivo > 0 ? (
                                            <span style={{ color: "rgb(212, 172, 0)" }}>{toBRLInteger(fee * objetivo)}</span>
                                        ) : (
                                            "-"
                                        )
                                    ) : (
                                        "-"
                                    )}
                                </div>
                            )
                        },
                    },
                },
                {
                    name: e.name + "__status",
                    group: e.name,
                    label: produtoX + ': STATUS',
                    options: {
                        viewColumns: false,
                        filter: false,
                        sort: false,
                        display: tabActive.current == 1 ? columnsToDisplay.current?.some(x => x.column === (e.name + "__objective") && x.display) : false,
                        setCellProps: () => ({
                            style: {
                                whiteSpace: "nowrap",
                                padding: "0px 0.0rem 0px 0.0rem",
                                backgroundColor: "#fffcef",
                            },
                        }),
                        customHeadRender: ({ index, name, ...column }, sortColumn, tableMeta) => {
                            const priorityAux = campaignPriority.current?.find((p) => p.id == e.priority)

                            return (
                                <TableCell className="testeAbc">
                                    <span
                                        className="containerCellProductDash"
                                        style={{
                                            marginRight: "0px",
                                            height: "1.7rem",
                                            backgroundColor: selectedCampaign?.id == e.id ? "#D4AC00" : "#fffcef",
                                            borderTopRightRadius: "10px",
                                            height: "2.7rem",
                                            paddingTop: "0.5rem",
                                        }}
                                    >
                                        <span style={{ marginLeft: "auto", marginRight: "auto" }} title={priorityAux?.value}>
                                            <div
                                                className="statusDivDash"
                                                onClick={(j) => {
                                                    j.stopPropagation()
                                                    setType({
                                                        name: "status",
                                                        data: name,
                                                        listFilterStatusProducts: listFilterStatusProducts,
                                                        listSearchedClients: listSearchedClients.current,
                                                        listAllClients: clientsResult.current.table,
                                                        listFilteredClients: listFilteredClients,
                                                        listClients: listClients,
                                                        filtersList: filtersList,
                                                        atualizador: atualizadorModalStatus,
                                                        description: e.description,
                                                        linesCount: linesCount,
                                                        positions: positions,
                                                        doSum: doSum,
                                                    })
                                                    setShow(true)
                                                }}
                                                style={{ marginTop: "0.5rem" }}
                                            >
                                                <div className="containerIconStatusAbc" style={{ height: "1.5rem", marginLeft: "0.3rem" }}>
                                                    <RiEqualizerFill size={12} className="iconStatusAbc" />
                                                </div>
                                            </div>
                                        </span>
                                    </span>
                                    <div
                                        className="headerProductCellBoxAdvisor"
                                        style={{
                                            marginRight: "0px",
                                            marginLeft: "0px",
                                            backgroundColor: "#fffcef",
                                            cursor: "pointer",
                                        }}
                                        onClick={() => {
                                            sortColumn(index)
                                        }}
                                    >
                                        <div className="subtitleCellBoxDash">
                                            <div className="subtitleObjectiveAbc">
                                                <div className="textStatusAbc" style={{ paddingRight: "0.5rem" }}>
                                                    Status
                                                </div>
                                                <div style={{ height: "1.5rem" }}></div>
                                            </div>
                                        </div>
                                        <div className="arrowSortAbc">
                                            {tableMeta.name === name ? tableMeta.direction == "asc" ? <AiOutlineArrowUp size={18} /> : <AiOutlineArrowDown size={18} /> : <></>}
                                        </div>
                                    </div>
                                </TableCell>
                            )
                        },
                        customBodyRender: (val, tableMeta) => {
                            var statusColor =
                                String(val).toUpperCase() == "PENDENTE"
                                    ? "#D4AC00"
                                    : String(val).toUpperCase() == "ABORDADO"
                                    ? "#6B94DE"
                                    : String(val).toUpperCase() == "ACEITO"
                                    ? "#c084fc"
                                    : String(val).toUpperCase() == "BOLETADO"
                                    ? "#58B935"
                                    : String(val).toUpperCase() == "CANCELADO"
                                    ? "#FF83FE"
                                    : String(val).toUpperCase() == "RECUSADO"
                                    ? "#f76a6a"
                                    : String(val).toUpperCase() == "NÃO ADERENTE"
                                    ? "#000000"
                                    : String(val).toUpperCase() == "ANÁLISE DO BACKOFFICE"
                                    ? "#2BC3BB"
                                    : "#8C34BA"

                            return val ? (
                                <div
                                    className="statusColorDivAbc"
                                    title={titleize(String(val))}
                                    onClick={(w) => {
                                        w.stopPropagation()
                                        var aux = clientsDash.current.find((x) => x.client_id == tableMeta.rowData[0])
                                        setType({
                                            name: "detailsClient",
                                            client: aux,
                                            products: listProducts.current,
                                            product: e,
                                            prioritys: campaignPriority.current,
                                            clientData: tableMeta.rowData,
                                            doSum: doSum,
                                        })
                                        setShow(true)
                                    }}
                                    style={{ width: "100%", height: "100%" }}
                                >
                                    <BsFillSquareFill color={statusColor} className="statusColorDivAbc" title={titleize(String(val))} />
                                </div>
                            ) : (
                                "-"
                            )
                        },
                    },
                }
            )


        })
    }
    const AddButton = () => (
        <>
            {user.type < 3 && tabActive.current == 1 ? (
                <Tooltip disableFocusListener title="Gerenciar Campanhas" style={{ marginTop: "0.6rem" }}>
                    <Link
                        to={{
                            pathname: "/abcManagement",
                        }}
                        onClick={() => {
                            localStorage.setItem("lastVisitedPage", "dashAdvisor")
                        }}
                    >
                        {"Gerenciar Campanhas"}
                    </Link>
                </Tooltip>
            ) : (
                <></>
            )}
            {tabActive.current == 1 ? (
                <Tooltip disableFocusListener title="Copiar para a área de transferência">
                    <IconButton onClick={copyToClipboard}>
                        <ContentCopyIcon />
                    </IconButton>
                </Tooltip>
            ) : (
                <></>
            )}
            {tabActive.current <= 3 ? (
                <Tooltip disableFocusListener title="Download Dashboard Antiga">
                    <IconButton onClick={exportDashOld}>
                        <BiDownload />
                    </IconButton>
                </Tooltip>
            ) : (
                <></>
            )}
        </>
    )

    
    function doSum() {
        listProducts?.current?.forEach((p) => {
            sum.current[p.name] = { oportunity: 0, planned_value: 0, done: 0, receita: 0, receitaOp: 0, receitaRe: 0 }
        })
        listProducts?.current?.forEach((p) => {
            clientsResult?.current?.table.forEach((c) => {
                if (c[p.name + "__objective"] !== null) {
                    sum.current[p.name].oportunity += c[p.name + "__objective"]
                }
                
                if (c[p.name + "__planned_value"] !== null) {
                    sum.current[p.name].planned_value += c[p.name + "__planned_value"]
                }
                if (c[p.name + "__status"] !== "RECUSADO" && c[p.name + "__status"] !== "NÃO ADERENTE" && c[p.name + "__status"] !== "CANCELADO") {

                    if (c[p.name + "__value"] !== null) {
                        sum.current[p.name].done += c[p.name + "__value"]
                    }

                    sum.current[p.name].receitaOp = sum.current[p.name].oportunity * p.fee
                    sum.current[p.name].receitaRe = sum.current[p.name].done * p.fee
                }
            })
        })

        //soma os Nets
        sumNet.current = 0
        sumNetStock.current = 0
        sumNetFii.current = 0
        sumPosRV.current = 0
        sumRoa.current = 0
        
        clientsResult?.current?.table.forEach((c) => {
            sumNet.current += c["net_total"]
        })

        clientsResult.current.table.forEach((c) => {
            sumNetStock.current += c["net_stock"]
        })

        clientsResult.current.table.forEach((c) => {
            sumNetFii.current += c["net_fii"]
        })

        clientsResult.current.table.forEach((c) => {
            sumPosRV.current += c.pos_rv
        })


        sumRoa.current = 0
        clientsResult?.current?.table.forEach((c) => {
            sumRoa.current += c["roa"]
        })
        sumRoa.current = sumRoa.current / clientsResult?.current?.table.length

    }


    const options = {
        filterType: "multiselect",
        filter: true,
        download: true,
        print: false,
        responsive: "vertical",
        customSearchRender: debounceSearchRender(500),
        tableBodyHeight: `${alturaTabela}px`,
        selectableRowsHideCheckboxes: true,
        selectToolbarPlacement: "replace",
        selectableRows: "multiple",
        viewColumns: tabActive.current == 0 ? false : true,
        rowsPerPage: rowsPerPage,
        rowsPerPageOptions: [50, 150, 500],
        customToolbar: AddButton,
        search: true,
        textLabels: {
            body: {
                noMatch: loadingTable ? <ArrowLoader /> : "Não foram encontrados dados",
                toolTip: "Ordenar",
                columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
            },
            pagination: {
                next: "Próxima",
                previous: "Anterior",
                rowsPerPage: "Linhas por página:",
                displayRows: "de",
            },
            toolbar: {
                search: "Pesquisar",
                downloadCsv: "Download XLSX",
                print: "Imprimir",
                viewColumns: "Ver colunas",
                filterTable: "Filtrar",
            },
            filter: {
                all: "Todos",
                title: "Filtros",
                reset: "Limpar",
            },
            viewColumns: {
                title: "Mostrar Colunas",
                titleAria: "Mostrar/Ocultar colunas",
            },
            selectedRows: {
                text: "linha(s) selecionadas",
                delete: "Excluir",
                deleteAria: "Excluir linhas Selecionadas",
            },
        },
        downloadOptions: {
            filename: "abc.csv",
            separator: ";",
        },
        // onDownload: (buildHead, buildBody, columns, values) => {
        //     if (tabActive.current == 0) {
        //         configurationExport()
        //         return false
        //     } else {
        //         exportDash()
        //         return false
        //     }
        // },
        onDownload: (buildHead, buildBody, columns, values) => {
            var json = []
            if (tabActive.current == 1) {
                exportDash()
                return false
                const colunas = columns.filter((e) => e.display == "true")

                // pega os dados da lista listSearchedClients.current para as colunas visiveis (colunas)
                json = listSearchedClients.current.map((row) => {
                    const temp = {}
                    colunas.forEach((coluna) => {
                        temp[coluna.label] = row[coluna.name] ? row[coluna.name] : ""
                    })
                    return temp
                })
            }

            const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8"
            const fileExtension = ".xlsx"
            const fileName = tabActive.current == 0 ? `Configurações Clientes` : `Campanhas`
            const ws = utils.json_to_sheet(json)
            const wb = { Sheets: { data: ws }, SheetNames: ["data"] }
            const excelBuffer = write(wb, { bookType: "xlsx", type: "array" })
            const data = new Blob([excelBuffer], { type: fileType })
            saveAs(data, fileName + fileExtension)
            return false
        },
        setTableProps: () => {
            return {
                padding: "none",
                size: "small",
            }
        },
        
        onFilterChange: (column, list, e, i) => {
            if (search !== "") filtersList.current["search"] = search
            if (i !== null) {
                if (!filtersList.current.hasOwnProperty(column)) {
                    filtersList.current[column] = []
                }
                filtersList.current[column] = list[i]
            } else {
                for (const property in filtersList.current) {
                    filtersList.current[property] = []
                }
            }
            listFilterStatusProducts.current[column] = filtersList.current[column]

            visibleColumnsRef.current.map((e) => {
                if (e.name == column) {
                    e.options.filterList = filtersList.current[column]
                }
            })

            props = null

            var currentTable = clientsResult.current.table

            //fazer o search
            var filteredClientsTemp = []
            if (search != "" && search != undefined) {
                const chavesSelecionadas = Array.from(
                    // FAST SEARCH
                    new Set(
                        visibleColumnsRef.current
                            .filter((objeto) => objeto.options.display === true) // filtra somente os objetos com display = true
                            .map((objeto) => objeto.name) // extrai somente os valores da coluna
                    )
                )

                //filtra apenas pelas colunas visiveis no momento
                filteredClientsTemp = currentTable.filter(
                    (row) =>
                        Object.values(Object.fromEntries(Object.entries(row).filter(([chave, valor]) => chavesSelecionadas.includes(chave))))
                            .join("|")
                            .toLowerCase()
                            .indexOf(String(search)) > -1
                )
                listSearchedClients.current = filteredClientsTemp
            } else {
                listSearchedClients.current = currentTable
                filteredClientsTemp = currentTable
            }

            currentTable = filteredClientsTemp

            // // LISTA DE CLIENTE - necesaria para o filtro de status do produto ser apagado ao clicar sobre o filtro setado
            listFilteredClients.current = currentTable.filter((row) => {
                var exists = true
                for (const property in filtersList.current) {
                    if (property !== "search") exists = filtersList.current[property].length === 0 ? exists : filtersList.current[property].includes(row[property]) ? exists : false
                }
                return exists
            })

            listClients.current = clientsResult.current.table.slice(0, rowsPerPage + 501)
            linesCount.current = listFilteredClients.current.length
            doSum()
            positions.current.currentPage = 0
            forceUpdate()
        },
        onViewColumnsChange: (changedColumn, action) => {
            let viewColumns = []

            // Em caso de clicar em "mostrar todas"
            if (changedColumn == null) {
                visibleColumnsRef.current.forEach((e, index) => {
                    if (e.name === "codigo" || e.name === "nome") {
                        viewColumns.push({ column: e.name, display: true })
                    } else {
                        // Apenas a primeira coluna de cada grupo deve ser filtrável
                        const isFilterable = e.name.endsWith(e.name + "__objective")
                        viewColumns.push({ column: e.name, display: isFilterable, index: index })
                    }
                })
                localStorage.setItem("columnsAbc", JSON.stringify(viewColumns))
                setColumnsLocal()
                return
            }
            if (changedColumn != null) {
                // Atualizando a visibilidade da coluna e de seu grupo
                visibleColumnsRef.current.forEach((column) => {
                    if (column.name === changedColumn || (column.group && column.group === changedColumn)) {
                        // Se a coluna alterada for a primeira do grupo, atualize a visibilidade de todo o grupo
                        const isFirstColumnOfGroup = column.name.endsWith("__objective")
                        column.options.display = action === "remove" ? false : true
                        if (isFirstColumnOfGroup) {
                            // Atualize a visibilidade de todas as colunas do grupo
                            visibleColumnsRef.current.forEach((col) => {
                                if (col.group === column.group) {
                                    col.options.display = column.options.display
                                    if (selectedCampaign && col.group.includes(selectedCampaign.id.toString())) {
                                        col.options.display = true
                                    }
                                }
                            })
                        }
                    }
                })
            }

            // Atualizando as preferências no localStorage
            visibleColumnsRef.current.forEach((column) => {
                viewColumns.push({ column: column.name, display: column.options.display })
            })

            localStorage.setItem("columnsAbc", JSON.stringify(viewColumns))
            setColumnsLocal()
            forceUpdate()
        },

        onFilterDialogOpen: (a,b,c) => {
        //     forceUpdate()
        },
        onTableChange: (a, b) => {
            doSum()
            if (tableChanged.current) {
                tableChanged.current = false
                const column = b.sortOrder.name
                const order = b.sortOrder.direction
                lastSortOrder.current.name = column
                lastSortOrder.current.direction = order
                if (clientsResult?.current?.table.length > 1) {
                    var orderedList = []

                    orderedList = clientsResult?.current?.table.sort((a, b) => {
                        let fa = a[column] == null ? "-" : a[column]
                        let fb = b[column] == null ? "-" : b[column]

                        if (typeof fa === "string" || typeof fb === "string") {
                            fa = String(fa).toLowerCase().trim()
                            fb = String(fb).toLowerCase().trim()
                        }

                        return order === "asc" ? (fa < fb ? -1 : fa > fb ? 1 : 0) : fa < fb ? 1 : fa > fb ? -1 : 0
                    })

                    listFilteredClients.current = orderedList
                    listSearchedClients.current = orderedList
                    clientsDash.current.table = orderedList.slice(0, rowsPerPage + 501)
                    setLastPage(0)
                    setCurrentPage(0)
                    positions.current.currentPage = 0
                    forceUpdate()
                }
            }
        },
        onSearchChange: (searchText) => {
            searchText = searchText === null ? "" : searchText
            setSearch(searchText)
            filtersList.current["search"] = searchText

            props = null

            var currentTable = clientsResult.current.table

            //fazer o search
            var filteredClientsTemp = []
            if (searchText != "" && searchText != undefined) {
                const chavesSelecionadas = Array.from(
                    // FAST SEARCH

                    new Set(
                        visibleColumnsRef.current
                            .filter((objeto) => objeto.options.display === true) // filtra somente os objetos com display = true
                            .map((objeto) => objeto.name) // extrai somente os valores da coluna
                    )
                )

                //filtra apenas pelas colunas visiveis no momento
                filteredClientsTemp = currentTable.filter(
                    (row) =>
                        Object.values(Object.fromEntries(Object.entries(row).filter(([chave, valor]) => chavesSelecionadas.includes(chave))))
                            .join("|")
                            .toLowerCase()
                            .indexOf(String(searchText)) > -1
                )
                listSearchedClients.current = filteredClientsTemp
            } else {
                listSearchedClients.current = currentTable
                filteredClientsTemp = currentTable
            }

            currentTable = filteredClientsTemp

            // // LISTA DE CLIENTE - necesaria para o filtro de status do produto ser apagado ao clicar sobre o filtro setado
            listFilteredClients.current = currentTable.filter((row) => {
                var exists = true
                for (const property in filtersList.current) {
                    if (property !== "search") exists = filtersList.current[property].length === 0 ? exists : filtersList.current[property].includes(row[property]) ? exists : false
                }
                return exists
            })

            listClients.current = clientsResult?.current?.table.slice(0, rowsPerPage + 501)

            linesCount.current = clientsResult?.current?.table.length
            forceUpdate()
            doSum()
            positions.current.currentPage = 0
            return false
        },
        onRowClick: (rowData, rowMeta) => {
            const clientData = listClients.current.find((e) => e.codigo == rowData[0])
            const clientsData2 = clientsDash.current.find((e) => e.client_id == rowData[0])


            // usar um tableclientsmodal para pegar os dados da tabela e passar para o modal
            setType({
                name: "detailsClient",
                client: clientsData2,
                products: listProducts.current,
                product: listProducts.current[0],
                outros: clientData.evasionReason,
                prioritys
                : campaignPriority.current,
                doSum: doSum,
                clientData: rowData
            })
            setShow(true)
        },
        customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage, textLabels) => {
            
                return (
                    <CustomFooter
                        {...props}
                        count={linesCount.current}
                        page={positions.current.currentPage}
                        rowsPerPage={rowsPerPage}
                        changeRowsPerPage={changeRowsPerPage}
                        changePage={changePage}
                        textLabels={textLabels}
                    />
                )
        },
        onChangePage: (atualPage) => {
            setCurrentPage(atualPage)
            positions.current.currentPage = atualPage
            if (atualPage > lastPage) {
                listClients.current = listClients.current.concat(listFilteredClients.current.slice(listClients.current.length, listClients.current.length + rowsPerPage))
                setLastPage(atualPage)
            }
        },
        onChangeRowsPerPage: (rows) => {
            listClients.current = listFilteredClients.current.slice(0, rows + 1)
            positions.current.rowsPerPage = rows
            positions.current.currentPage = 0
            localStorage.setItem("positionabc", JSON.stringify(positions.current))
            setRowsPerPage(rows)
            setLastPage(0)
            setCurrentPage(0)
        },
        onColumnSortChange: (column, order) => {
            lastSortOrder.current.name = column
            lastSortOrder.current.direction = order
            if (clientsResult?.current?.table.length > 1) {
                var orderedList = []

                orderedList = clientsResult?.current?.table.sort((a, b) => {
                    let fa = a[column] == null ? "-" : a[column]
                    let fb = b[column] == null ? "-" : b[column]

                    if (typeof fa === "string" || typeof fb === "string") {
                        fa = String(fa).toLowerCase().trim()
                        fb = String(fb).toLowerCase().trim()
                    }

                    return order === "asc" ? (fa < fb ? -1 : fa > fb ? 1 : 0) : fa < fb ? 1 : fa > fb ? -1 : 0
                })

                listFilteredClients.current = orderedList
                listSearchedClients.current = orderedList
                listClients.current = orderedList.slice(0, rowsPerPage + 501)
                setLastPage(0)
                setCurrentPage(0)
                positions.current.currentPage = 0
                forceUpdate()
            }
        },
    }

    const optionsIcons2 = [
        { value: "1", title2: "Indefinido", title: "Indefinido", label: <FaQuestion size={"20"} color="orange" />, icon: FaQuestion, color: "orange" },
        { value: "2", title2: "Possui", title: "Permitido", label: <FaCheck size={"20"} color="green" />, icon: FaCheck, color: "green" },
        { value: "3", title2: "Não possui", title: "Negado", label: <FaTimes size={"20"} color="red" />, icon: FaTimes, color: "red" },
        // { value: "4", title2: "Sem perfil", title: "Sem perfil", label: <FaUserSlash size={"20"} color="black" />, icon: FaUserSlash, color: "black" },
        { value: "5", title2: "Oportunidade", title: "Oportunidade", label: <FaStar size={"20"} color="#FFC300" />, icon: FaStar, color: "#FFC300" },
    ]

    async function setConfigTable() {
        listClients.current = []
        setLoadingTable(true)
        while (!configuration.current.table) {
            await new Promise((resolve) => setTimeout(resolve, 1000))
        }
        listFilteredClients.current = configuration.current.table
        listSearchedClients.current = configuration.current.table
        listClients.current = configuration.current.table.slice(0, rowsPerPage + 501)
        linesCount.current = configuration.current.table.length
        assembleTable()
        forceUpdate()
        setLoadingTable(false)
    }
    const CustomToView = (props) => {
        return <TableViewColDash {...props} ocultColumnsToView={columnsExceptionView} visibleColumnsRef={visibleColumnsRef.current} productsList={listProducts.current}  />;
    };
    
    return (
        user.token !== "" && (
            <>
                <div
                    className="dashboardContainer"
                    style={{
                        zoom: `${
                            window.innerHeight <= 850 && window.devicePixelRatio < 1.2
                                ? (1 / window.devicePixelRatio) * 0.7
                                : window.innerHeight <= 600 && window.devicePixelRatio > 1.2
                                ? (1 / window.devicePixelRatio) * 0.7
                                : 1 / window.devicePixelRatio
                        }`,
                    }}
                >
                    <div className="dashboardRightSide">
                        <div className="dashboardRightSideContentAdvisor">
                        {user.type <= 3 ? 
                        <div className="campaignsContainer" style={{display:retracaoCarrousel ? 'none' : "grid" }}>
                            <Grow in={!retracaoCarrousel}  > 
                                {loading ? (
                                    <ArrowLoader />
                                ) : (
                                    //ENVIA COMO PARÂMETRO PARA O COMPONENTE DE CARROSEL DE CAMPANHAS
                                    //selectedCampaign: campanha selecionada (mande a unica caso só exista uma)
                                    //setSelectedCampaign: função para setar a campanha selecionada
                                    //listProducts: lista de produtos
                                    //campaignPriority: lista de prioridades que existem (para icones)
                                        <div style={{display: "contents",zoom:'0.8' }} >
                                            <CampainsCarrousel
                                                selectedCampaign={selectedCampaign}
                                                setSelectedCampaign={setSelectedCampaign}
                                                listProducts={listProducts}
                                                campaignPriority={campaignPriority}
                                                // searchArea={''}
                                                />
                                            <div className="actionsDash">
                                                <div>
                                                    <button
                                                        style={{
                                                            background: "white",
                                                            color: "white",
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            alignItems: "center",
                                                            border: "2px solid rgb(194, 156, 0)",
                                                        }}
                                                        className="dashButton"
                                                        disabled={loading ? "disabled" : ""}
                                                        // title="Em construção"
                                                        onClick={() => {
                                                            setType({ name: "settlementCalendar", data: listaClientesPorProduto, d: 0 })
                                                            setShow(true)
                                                        }}
                                                    >
                                                        <div style={{ paddingLeft: "5px", textAlign: "center", color: "black", fontWeight: "bold" }}>Calendário de Liquidações</div>
                                                        <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", justifyItems: "flex-start" }}>
                                                            <div style={{ display: "flex", flexDirection: "row" }}>
                                                                <button
                                                                    className="buttond0"
                                                                    disabled={loading ? "disabled" : ""}
                                                                    title={
                                                                        clientsSettlements?.d0 > 0
                                                                            ? clientsSettlements.d0 == 1
                                                                                ? clientsSettlements.d0 + " cliente a ser liquidado em D+0."
                                                                                : clientsSettlements.d0 + " clientes a serem liquidados em D+0."
                                                                            : "Não há clientes a serem liquidados em D+0."
                                                                    }
                                                                    onClick={(e) => {
                                                                        e.stopPropagation()
                                                                        setType({ name: "settlementCalendar", data: listaClientesPorProduto, d: 0 })
                                                                        setShow(true)
                                                                    }}
                                                                >
                                                                    D+0 <br />({clientsSettlements?.d0})
                                                                </button>
                                                                <button
                                                                    className="buttond1"
                                                                    disabled={loading ? "disabled" : ""}
                                                                    title={
                                                                        clientsSettlements?.d1 > 0
                                                                            ? clientsSettlements.d1 == 1
                                                                                ? clientsSettlements.d1 + " cliente a ser liquidado em D+1."
                                                                                : clientsSettlements.d1 + " clientes a serem liquidados em D+1."
                                                                            : "Não há clientes a serem liquidados em D+1."
                                                                    }
                                                                    onClick={(e) => {
                                                                        e.stopPropagation()
                                                                        setType({ name: "settlementCalendar", data: listaClientesPorProduto, d: 1 })
                                                                        setShow(true)
                                                                    }}
                                                                >
                                                                    D+1 <br />({clientsSettlements?.d1})
                                                                </button>
                                                                <button
                                                                    className="buttond2"
                                                                    disabled={loading ? "disabled" : ""}
                                                                    title={
                                                                        clientsSettlements?.d2 > 0
                                                                            ? clientsSettlements.d2 == 1
                                                                                ? clientsSettlements.d2 + " cliente a ser liquidado em D+2."
                                                                                : clientsSettlements.d2 + " clientes a serem liquidados em D+2."
                                                                            : "Não há clientes a serem liquidados em D+2."
                                                                    }
                                                                    onClick={(e) => {
                                                                        e.stopPropagation()
                                                                        setType({ name: "settlementCalendar", data: listaClientesPorProduto, d: 2 })
                                                                        setShow(true)
                                                                    }}
                                                                >
                                                                    D+2 <br />({clientsSettlements?.d2})
                                                                </button>
                                                                <button
                                                                    className="buttond3"
                                                                    disabled={loading ? "disabled" : ""}
                                                                    title={
                                                                        clientsSettlements?.d3 > 0
                                                                            ? clientsSettlements.d3 == 1
                                                                                ? clientsSettlements.d3 + " cliente a ser liquidado em D+3."
                                                                                : clientsSettlements.d3 + " clientes a serem liquidados em D+3."
                                                                            : "Não há clientes a serem liquidados em D+3."
                                                                    }
                                                                    onClick={(e) => {
                                                                        e.stopPropagation()
                                                                        setType({ name: "settlementCalendar", data: listaClientesPorProduto, d: 3 })
                                                                        setShow(true)
                                                                    }}
                                                                >
                                                                    D+3 <br />({clientsSettlements?.d3})
                                                                </button>
                                                                <button
                                                                    className="buttond3Plus"
                                                                    disabled={loading ? "disabled" : ""}
                                                                    title={
                                                                        clientsSettlements?.d3plus > 0
                                                                            ? clientsSettlements.d3plus == 1
                                                                                ? clientsSettlements.d3plus + " cliente a ser liquidado após D+3."
                                                                                : clientsSettlements.d3plus + " clientes a serem liquidados após D+3."
                                                                            : "Não há clientes a serem liquidados após D+3."
                                                                    }
                                                                    onClick={(e) => {
                                                                        e.stopPropagation()
                                                                        setType({ name: "settlementCalendar", data: listaClientesPorProduto, d: 4 })
                                                                        setShow(true)
                                                                    }}
                                                                >
                                                                    &gt; D+3 <br /> ({clientsSettlements?.d3plus})
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </button>
                                                </div>
                                                <button
                                                    className="dashButton"
                                                    style={{ background: "#C29C00", color: "white" }}
                                                    onClick={() => {
                                                        window.open(
                                                            "https://agenteinvest-my.sharepoint.com/:x:/g/personal/enrico_a25245_agenteinvest_com_br/EXhbhBC4Sh9KuHP3KSxHVXMBBHYl0x672e5iBO7q-2RYdA",
                                                            "_blank",
                                                            "noopener,noreferrer"
                                                        )
                                                    }}
                                                >
                                                    <TbCalendarStats size={50} />
                                                    Calendário de produtos{" "}
                                                </button>
                                                {/* <button
                                                style={{ background: "#C29C00", color: "white" }}
                                                className="dashButton"
                                                disabled={eventsDash.length > 0 ? "" : "disabled"}
                                                onClick={() => {
                                                    setType({ name: "calendarDashboard", events: eventsDash }) //! MUDAR PARA ROTA DE EVENTOS
                                                    setShow(true)
                                                }}
                                            >
                                                <BiCalendarStar size={50} />
                                                Calendário de Eventos
                                            </button> */}

                                                <button
                                                    // style={{ background: "#A18300", color: "white", marginRight: "10px" }}
                                                    style={{ background: "#A18300", color: "white" }}
                                                    className="dashButton"
                                                    title="Agende um especialista!"
                                                    onClick={() => {
                                                        window.open("https://outlook.office365.com/owa/calendar/ManchesterTesteBooking@manchesterinvest.com.br/bookings/", "_blank", "noopener,noreferrer")
                                                    }}
                                                >
                                                    {" "}
                                                    <MdPermContactCalendar size={50} />
                                                    Agendar um Especialista
                                                </button>
                                            </div>
                                        </div>
                                )}
                                </Grow>
                            </div> 
                            : 
                            <></>}
                            {loading ? (
                                <span style={{ marginTop: "3rem" }}>
                                    <ArrowLoader />
                                </span>
                            ) : (
                                <div>
                                    <div style={{display:"flex", justifyContent:"center", padding:'0.5rem 0px'}}>
                                        <Tooltip title={ retracaoCarrousel ? "Retrair Carrousel" : "Expandir Carrousel"} arrow>
                                        {
                                            retracaoCarrousel ?  
                                                <>
                                                    <FaArrowAltCircleDown 
                                                        className={'retracaoCarrouselAbc'}
                                                        title={ retracaoCarrousel ? "Retrair Carrousel" : "Expandir Carrousel"} 
                                                        onClick={() => {
                                                            setRetracaoCarrousel(!retracaoCarrousel)
                                                        }} 
                                                        size={24} 
                                                        style={{cursor:"pointer", transitionDelay: '0.1ms'}} />
                                                    
                                                </>
                                            : 
                                                <>
                                                    <FaArrowAltCircleUp 
                                                        className={'retracaoCarrouselAbc'}
                                                        title={ retracaoCarrousel ? "Retrair Carrousel" : "Expandir Carrousel"} 
                                                        onClick={() => {
                                                            setRetracaoCarrousel(!retracaoCarrousel)
                                                        }} 
                                                        size={24} 
                                                        style={{cursor:"pointer", transitionDelay: '0.1ms'}} />
                                                </>
                                                    }
                                        </Tooltip>
                                    </div>
                                    <div className="estructuredsTab" style={{marginTop: '0', display: user.is_leader == 1 || user.type == 1 ? 'flex' : "none" }}>
                                        <div
                                            className={tabActive.current == 1 ? "estructuredsTabOption360" : "estructuredsInactiveTabOption360"}
                                            onClick={() => {
                                                tabActive.current = 1
                                                tableChanged.current = true
                                                linesCount.current = listClients?.current.length
                                                listFilteredClients.current = listClients?.current
                                                listSearchedClients.current = listClients?.current

                                                assembleTable()
                                                forceUpdate()
                                            }}
                                        >
                                            <p>Meus clientes</p>
                                        </div>
                                        <div
                                            className={tabActive?.current == 2 ? "estructuredsTabOption360" : "estructuredsInactiveTabOption360"}
                                            onClick={() => {
                                                tabActive.current = 2
                                                    tableChanged.current = true
                                                    linesCount.current = teamClients?.current?.length
                                                    listFilteredClients.current = teamClients?.current
                                                    listSearchedClients.current = teamClients?.current
                                                    assembleTable()
                                                    forceUpdate()
                                                
                                            }}
                                            style={{cursor: loadClients ? "pointer" : "not-allowed"}}
                                        >
                                            <p>Clientes do meu time</p>
                                        </div>
                                    </div>
                                    {!(!tabActive.current && loadingTable) ? (
                                        
                                        <ThemeProvider theme={getMuiTheme()} className="dashboardBoxContainer">
                                            
                                            <MUIDataTable
                                                title={
                                                    tabActive.current == 1
                                                        ? "Última atualização de bases de clientes: " + positivadorUpdateTime
                                                        : "Última atualização de bases de clientes: " + positivadorUpdateTime
                                                }
                                                data={tabActive?.current === 1 ? listClients?.current : teamClients?.current}
                                                columns={visibleColumnsRef.current}
                                                options={options}
                                                tableRef={refDataTable}
                                                components={{
                                                    TableViewCol: CustomToView,
                                                }}
                                            ></MUIDataTable>
                                        </ThemeProvider>
                                    ) : (
                                        <div style={{ backgroundColor: "white", width: "100%" }}>
                                            <span style={{ marginTop: "4rem" }}>
                                                <ArrowLoader />
                                            </span>
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </>
        )
    )
}
