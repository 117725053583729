/*Tela de configuração de books
/*Descrição: Exibe books criados e efetua cadastro de novos Books para serem utilizados no sistema
/*Desenvolvido por: Equipe Rafael Cezário
*/

import React, { useState } from 'react';
/*Styles*/
import './styles/booksConfiguration.css';
/*Table components*/
import { TableBooksRegistered } from './tableBooksRegistered';
import { TableBooksInactive } from './tableBooksInactive';
/*Contexts*/
import { useModal } from '../../../../contexts/useModal';
import { BooksRegister } from './booksRegister';
/*Modal*/
import Modal from '../../../../components/modal/modal';
/*Util Components*/
import { ConfirmationBox } from '../../../../components/confirmationBox/confirmationBox'
import { BookEdit } from './bookEdit';

export function BooksConfiguration() {

    const { show, setShow, type, setType } = useModal();
    const [tabActive, setTabActive] = useState(1);

    return (

        <div className='booksConfigurationContainer'>
            <Modal>
                {type.name === 'showBooksRegister' ?
                    (<BooksRegister />) :
                    type.name === 'successBookCreation' ? (
                        <ConfirmationBox msgData={type} />
                    ) :
                        type.name === 'bookEditAction' ?
                            (<BookEdit bookData={type} />) :

                            type.name === 'alertMsg' ?
                                (
                                    <ConfirmationBox msgData={type} />
                                ) :
                                (<></>)}
            </Modal>

            <div className='booksTab'>
                <div className={tabActive === 1 ? 'booksTabOption' : 'booksInactiveTabOption'} onClick={() => setTabActive(1)}>
                    <a>Ativos</a>
                    <div></div>
                </div>
                <div className={tabActive === 2 ? 'booksTabOption' : 'booksInactiveTabOption'} onClick={() => setTabActive(2)}>
                    <a>Inativo</a>
                    <div></div>
                </div>
            </div>
            {tabActive === 1 ?
                (
                    <TableBooksRegistered />
                ) :
                (
                    <TableBooksInactive />
                )}


        </div>
    )
}
