import React, { useEffect, useState, useReducer, useRef } from "react";
/*Contexts*/
import { useAuth } from "../../../contexts/useAuth";
import { useModal } from "../../../contexts/useModal";
/*Services*/
import { api } from "../../../services/api";
/*Components*/
// import { ArrowLoader } from "../../../../components/loaders/arrowLoader";
/*Utils*/
import { toBRL } from "../../../utils/formatNumberAndText/toBRLCurrency";
import { titleize } from "../../../utils/formatNumberAndText/formatTextUpperFirstLetter";
import { MdOutlineMonetizationOn, MdOutlineCheckCircleOutline, MdOutbox,MdSearch } from "react-icons/md";
import { FiStar,FiArrowLeft } from 'react-icons/fi';
import { FaStar } from 'react-icons/fa';
import { RiArrowRightDownFill, RiArrowRightUpFill } from 'react-icons/ri';
import { ArrowLoader } from "../../../components/loaders/arrowLoader";
import { styled } from '@mui/material/styles';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import { styleClientsInput } from '../../../components/styles/autocompletField';
import { TextField, Checkbox, FormControlLabel } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { getLongShorts,updateActiveLongShort } from '../../../services/asyncFunctions'
import { datetimeToDatePtBR } from "../../../utils/formatDate/dateTimeToPTBR";

import './styles/longShortMain.css'

export function LongShortMain(props) {
  // console.log('props', props)
  const classes = styleClientsInput()
  const token = localStorage.getItem('@auth:token')
  const { tokenKey, user } = useAuth();
  const { show, setShow, type, setType, edited, setEdited } = useModal();
  const [loading, setLoading] = useState(true);
  const listLongShorts = useRef([])
  const [filteredLongShorts, setFilteredLongShorts] = useState([]);
  const [alertMessage, setAlertMessage] = useState('')
  const [searchText, setSearchText] = useState('')
  const [selectedOrder, setSelectedOrder] = useState()
  const [operation,setOperation] = useState()
  const [inEdit, setInEdit] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [editDate, setEditDate] = useState(null)
  const [editResearch, setEditResearch] = useState('')
  const [editLong, setEditLong] = useState('')
  const [editShort, setEditShort] = useState('')
  const [editRatioLimiteEntrada, setEditRatioLimiteEntrada] = useState(0)
  const [editRatioGain, setEditRatioGain] = useState(0)
  const [editRatioLoss, setEditRatioLoss] = useState(0)
  const [editNetLimit, setEditNetLimit] = useState(0)
  const [longShortIdInEdit, setLongShortIdInEdit] = useState(-1)
  const [isDocument, setIsDocument] = useState(true)
  const [link, setLink] = useState('')
  const [file, setFile] = useState('')
  const [, forceUpdate] = useReducer(x => x + 1, 0);
  const tabActive = useRef(0);

  async function LongShortsGet() {
    const longShortGet = await getLongShorts(tokenKey)
    if (longShortGet?.isError) {
        setType({
            name: 'alertMsg',
            type: 'information',
            value: {
                message: longShortGet?.error
            }
        })
    } else {
        listLongShorts.current = (longShortGet?.data)
    }
  }

  async function getLongEShorts(){
    setLoading(true)
    await LongShortsGet()
    await filterproducts()
    forceUpdate()
    setLoading(false)
  }

  useEffect(() => {
    getLongEShorts()
  }, [])

  useEffect(() => {
    if (selectedOrder){
      var ordereData = filteredLongShorts
      ordereData = ordereData.sort((a, b) => {

        let fa = a[selectedOrder] == null ? "-" : a[selectedOrder];
        let fb = b[selectedOrder] == null ? "-" : b[selectedOrder];

        if (typeof fa === "string" || typeof fb === "string") {
            fa = String(fa).toLowerCase().trim();
            fb = String(fb).toLowerCase().trim();
            return  (fa < fb ? -1 : fa > fb ? 1 : 0)
        }

        return  (fa < fb ? 1 : fa > fb ? -1 : 0) ;
      })
      listLongShorts.current = (ordereData)
    }
  }, [selectedOrder])

  async function openPathLink(pathLink, title, isFile) {

    if(pathLink == undefined || pathLink==''){
        return alert('Não foi atrelado nenhum Link ou Arquivo.')
    }
    if(isFile == 0){
        if(new RegExp("^(https?://)", "i").test(pathLink)){
            return window.open(pathLink)
        }       
        return window.open("http://"+pathLink) 
    }else{
        setLoading(true)
        try{
            const fileExtensio = ['.xls', '.xlsx', '.png', '.jpeg', '.gif', '.pjpeg', '.pdf', 'text/plain', '.zip','.pptx', '.ppt', '.doc', '.docx'] 
            const fileContentTypes = [
                'application/vnd.ms-excel', 
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 
                'image/png',
                'image/jpeg',
                'image/gif',
                'image/pjpeg',
                'application/pdf',
                'text/plain',
                'application/zip',
                'application/vnd.openxmlformats-officedocument.presentationml.presentation',
                'application/vnd.ms-powerpoint',
                'application/msword',
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document'

            ] 
            const responseFile = await api.get('/api/v1/uploads/faq/'+pathLink, {
                headers: {
                    authorization: `Bearer ${token}`
                },
                responseType: 'blob'
            })
            
            const url = URL.createObjectURL(new Blob([responseFile.data]))
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${title}${fileExtensio[fileContentTypes.indexOf(responseFile.data.type)]}`)
            document.body.appendChild(link)
            link.click()
            setLoading(false)
            return;
        }catch(error){
            setLoading(false)
            return alert(error.response.data.message)
        }
        
    }
}

async function handleSubmit(){
  if(isDocument == false){
      if(link == ''){
          return alert(`Você precisa informar o campo Link`)
      }
      setLoading(true)
      try{
          await api.put(`/api/v1/longShort/${String(longShortIdInEdit)}/report`, {
              path_link: link,
          }, {
              headers: {
                  authorization: `Bearer `+ token
              }
          })
          setLoading(false)
      }catch(error){
          setLoading(false)
          return alert(error?.response?.data?.message)
      }
  }else{
      if(file == '' || file ==  undefined){
          return alert(`Você precisa informar o campo Arquivo`)
      }
      setLoading(true)
      try {
        console.log('token',token)
          const formData = new FormData();
          formData.append('longShort', file)
          //lembrar de setar report_is_file pra true
          await api.post(`/api/v1/longShort/${String(longShortIdInEdit)}/upload`, formData, {
              headers:{
                  "Authorization": 'Bearer ' + token,
                  "Content-Type": "multipart/form-data"
              }
          })
          setLoading(false)
      } catch (error) {
          setLoading(false)
          return alert(error.response.data.message)
      }
  }
}

async function handleSubmitEditLS(){
  if (editDate == '' || editDate == undefined || editDate == null){
    return alert('Você precisa informar a data')
  }
  else if (editResearch == '' || editResearch == undefined || editResearch == null){
    return alert('Você precisa informar a research')
  }
  else if (editLong == '' || editLong == undefined || editLong == null){
    return alert('Você precisa informar o Ativo Long')
  }
  else if (editShort == '' || editShort == undefined || editShort == null){
    return alert('Você precisa informar o Ativo Short')
  }
  else if (editRatioLimiteEntrada == '' || editRatioLimiteEntrada == undefined || editRatioLimiteEntrada == null){
    return alert('Você precisa informar o Ratio Limite de Entrada')
  }
  else if (editRatioGain == '' || editRatioGain == undefined || editRatioGain == null){
    return alert('Você precisa informar o Ratio Gain')
  }
  else if (editRatioLoss == '' || editRatioLoss == undefined || editRatioLoss == null){
    return alert('Você precisa informar o Ratio Loss')
  }
  else if (editNetLimit == '' || editNetLimit == undefined || editNetLimit == null){
    return alert('Você precisa informar o Net Limit')
  }
  try{
    await api.put(`/api/v1/longShort/operation/${String(longShortIdInEdit)}`, {
      data: {
        operation_date: editDate,
        research: editResearch,
        asset_long: editLong,
        asset_short: editShort,
        ratio_max_in: editRatioLimiteEntrada,
        ratio_gain: editRatioGain,
        ratio_loss: editRatioLoss,
        net_limit: editNetLimit / 100,
      },
    }, {
      headers: {
          authorization: `Bearer `+ token
      }
    })
    window.location.reload()
    setLoading(false)
  } catch (error) {
    setLoading(false)
    return alert(error.response.data.message)
  }
}

async function updateActive(id, active, interrupt) {
  try {
      const res = await updateActiveLongShort(tokenKey, id, (active == true && interrupt == false) ? 0 : 1)
      if (res.isError) throw res
      getLongEShorts()
  } catch (error) {
      console.log(error)
      alert(error.error)
  }
}

function filterproducts(){
  const aux = listLongShorts.current?.filter((e) => {
    console.log('tabActive', tabActive.current)
    return tabActive.current == 0 ? (e.active == true ? true : false ) : (e.active == true ? false : true )
  })
  setFilteredLongShorts(aux)
}

  return (
    <div className="containerGeralLongShorts">
    <div className="boxTablesAndButtons">
      <div className="estructuredsTab">
              <div className={tabActive.current === 0 ? "estructuredsTabOption" : "estructuredsInactiveTabOption"}
                  onClick={() => {tabActive.current = 0; filterproducts()}}>
                  <p>Em andamento</p>
                  <div></div>
              </div>
              <div className={tabActive.current === 1 ? 'estructuredsTabOption' : 'estructuredsInactiveTabOption'} onClick={() => {tabActive.current = 1; filterproducts()}}>
                  <a>Desativados</a>
                  <div></div>
              </div>
      </div>
      <div className="BoxButtonsLongShort">
      {user?.type  < 3 ? <button className={'buttonActivateLongShort'} onClick={() => {
        setType({ name: 'showImportLongShortOperations' });
        setShow(true);
        console.log('clicou') 
        }}>Importar Operações</button> : <></>}
      </div>
    </div>

    <div className="containerLongShorts">
            {loading ? 
            <div className='clientsCadastrationLoading'>
                <ArrowLoader />
            </div> :
            alertMessage != '' ? 
            <div className='clientsCadastrationLoading'>
              <h1>{alertMessage} </h1>
            </div>
            : 
            <div className="containerLongShort">
                <div className="containerListLongShort">
                  <div className="toolbarBoletarAbc">
                    <div className="toolbarSearchBoletarAbc">
                    <div className={'InputToFormat_two'}>
                      <div>
                          <input onKeyPress={(e) => {if(e.code === 'Backquote') e.preventDefault()}} type="text" value={searchText} onChange={(e) => setSearchText(e.target.value)} placeholder=' '/>
                          <label htmlFor="">Pesquisar</label>
                      </div>
                    </div>
                    </div>
                    
                  <div className="toolbarSelectOrderBoletarAbc">
                  
                    <Autocomplete
                        options={['operation_date','asset_long','asset_short','ratio_max_in','ratio_gain','ratio_loss','net_limit','research']}
                        value={selectedOrder}
                        onChange={(event, newValue) => {
                            setSelectedOrder(newValue);
                        }}
                        getOptionLabel={(x) => 
                            x == 'operation_date' ? 'Data de operação' 
                          : x == 'asset_long' ? 'Ativo long' 
                          : x == 'asset_short' ? 'Ativo short'
                          : x == 'ratio_max_in' ? 'Ratio limite entrada'
                          : x == 'ratio_gain' ? 'Ratio gain'
                          : x == 'ratio_loss' ? 'Ratio loss'
                          : x == 'net_limit' ? 'Limite NET L/S'
                          : x == 'research' ? 'Research':
                          x
                        }
                        getOptionSelected={(option, val) => option === val}
                        id='order'
                        renderInput={(params) => <TextField
                            label='Ordenar por'
                            onKeyPress={(e) => {if(e.code === 'Backquote') e.preventDefault()}}
                            className={classes.autocompleteAbc}
                            {...params} variant="outlined" />}
                    />
                
                  </div>
                  </div> 
                  <div className="listOperationLongShort">
                    {filteredLongShorts?.length != 0 ? 
                      filteredLongShorts?.map((b, indexB) => {
                        const keys = Object.keys(b);
                        b['date'] = datetimeToDatePtBR(b['operation_date']) ;
                        keys.splice(keys.indexOf('id'), 1);
                      //   keys.splice(keys.indexOf('Vencimento'), 1);
                      //   keys.splice(keys.indexOf('Recomendado'), 1);

                        var values = '';
                      //   //insere os valores para filtrar posteriormente
                        keys.map((j) => {
                          values +=b[j] + '-'
                        });
                        b.report_name = '';
                        if(b?.report != null){
                          let index = b?.report.indexOf('-');
                          
                          if (index !== -1) {
                            b.report_name = b.report.substring(index + 1);
                          }
                        }

                        // se algum valor do card está contido no campo de pesquisa, então mostra o card
                        if(values.toLowerCase().includes(searchText.toLowerCase())){
                        
                          return (
                              <div className={
                                  // operation == b ? 'productCardActiveAbc' : 
                                  'productCardLongShort'} onClick={() => setOperation(b)}>
                                  <div className="productCardAbcHeader">
                                      <p></p>
                                      <p ></p>
                                      <p style={{color: '#5E4C0B'}}title='Valor'>{b?.asset_long} X {b?.asset_short}</p>
                                      <div style={{textAlign: 'end'}}>
                                        { user?.type < 3 ?
                                          <>
                                            { b?.interrupt == false ?
                                              <button className={'buttonEditLongShort'} onClick={() => {
                                                setInEdit(true);
                                                setIsEdit(true);
                                                setEditDate(b?.date.split('/').reverse().join('-'));
                                                setEditResearch(b?.research);
                                                setEditLong(b?.asset_long);
                                                setEditShort(b?.asset_short);
                                                setEditRatioLimiteEntrada(b?.ratio_max_in);
                                                setEditRatioGain(b?.ratio_gain);
                                                setEditRatioLoss(b?.ratio_loss);
                                                setEditNetLimit(b?.net_limit * 100);
                                                setLongShortIdInEdit(b?.id);
                                              }}>
                                                  Editar
                                              </button>
                                              :
                                              <></>
                                            }
                                            <button 
                                              className={ b?.active == 1 ? "buttonDeactivateLongShort" : 'buttonActivateLongShort'} 
                                              onClick={() => {
                                                updateActive( b?.id, b?.active, b?.interrupt )
                                                // filteredLongShorts[indexB].active = !b?.active
                                                // listLongShorts[indexB].active = !b?.active
                                              }}>
                                                { b?.active == true && b?.interrupt == false ? 'Desativar' : 'Ativar' }
                                            </button>  
                                          </> :
                                          <></>
                                        }
                                      </div>
                                  </div>
                                  <div className="divisionHeaderCardAbc"></div>
                                  {(!inEdit || longShortIdInEdit != b?.id) ? 
                                    <div className="longShortCardInfos">
                                      <div className="itemlongShortCardInfos"><span style={{fontWeight: '400'}}>DATA OPERAÇÃO: </span><span>{b?.date}</span></div>
                                      <div className="itemlongShortCardInfos"><span style={{fontWeight: '400'}}>RESEARCH: </span><span>{b?.research}</span></div>
                                      <div className="itemlongShortCardInfos"><span style={{fontWeight: '400'}}>ATIVO LONG: </span><span>{b?.asset_long}</span></div>
                                      <div className="itemlongShortCardInfos"><span style={{fontWeight: '400'}}>ATIVO SHORT: </span><span>{b?.asset_short}</span></div>
                                      <div className="itemlongShortCardInfos"><span style={{fontWeight: '400'}}>RATIO ATUAL: </span><span>{b?.ratio_current ? b?.ratio_current : '-'}</span></div>
                                      <div className="itemlongShortCardInfos"><span style={{fontWeight: '400'}}>RATIO LIMITE ENTRADA: </span><span>{b?.ratio_max_in}</span></div>
                                      <div className="itemlongShortCardInfos"><span style={{fontWeight: '400'}}>RATIO GAIN: </span><span>{b?.ratio_gain}</span></div>
                                      <div className="itemlongShortCardInfos"><span style={{fontWeight: '400'}}>RATIO LOSS: </span><span>{b?.ratio_loss}</span></div>
                                      <div className="itemlongShortCardInfos"><span style={{fontWeight: '400'}}>ANEXO: {user?.type  < 3 ? b?.report ? 
                                        <button className="buttonChangeReport" onClick={() => {setInEdit(true); setLongShortIdInEdit(b?.id)}}>Editar</button>
                                        : 
                                        <button className="buttonChangeReport" onClick={() => {setInEdit(true); setLongShortIdInEdit(b?.id)}}>Adicionar</button>
                                        : <></>}
                                      </span><span>
                                        <div>
                                        {b?.report_is_file ?
                                          b?.report ?  <a className="link-like" onClick={() => openPathLink(b?.report, b?.report_name.split('.')[0], b?.report_is_file)}>{b?.report_name?.length > 60 ? b?.report_name?.substr(0,58) + '...'  : b?.report_name}</a> : '-'
                                          : <a className="link-like" onClick={() => openPathLink(b?.report, b?.report_name, b?.report_is_file)}>{b?.report?.length > 60 ? b?.report?.substr(0,58) + '...'  : b?.report}</a>
                                        }
                                        </div> 
                                      </span></div>
                                      <div className="itemlongShortCardInfos"><span style={{fontWeight: '400'}}>LIMITE L/S: </span><span>{b?.net_limit*100}%</span></div>
                                    </div> : 
                                    // EDIT
                                    isEdit ?
                                    (
                                      <div className="editLongShortFields" style={{textAlign: 'center', marginBottom: '5px'}}>
                                        <div className="longShortCardInfos">
                                            <TextField
                                              onKeyPress={(e) => { if (e.code === 'Backquote') e.preventDefault() }}
                                              label={'DATA OPERAÇÃO'}
                                              type={'date'}
                                              variant='outlined'
                                              onChange={(e) => {
                                                  setEditDate(e.target.value)
                                              }}
                                              value={editDate}
                                              InputLabelProps={{
                                                shrink: true,
                                              }}
                                              className={classes.styleForClients}
                                            />
                                            <TextField
                                              onKeyPress={(e) => { if (e.code === 'Backquote') e.preventDefault() }}
                                              label={'RESEARCH'}
                                              type={'text'}
                                              variant='outlined'
                                              onChange={(e) => {
                                                  setEditResearch(e.target.value)
                                              }}
                                              value={editResearch}
                                              InputLabelProps={{
                                                shrink: true,
                                              }}
                                              className={classes.styleForClients}
                                            />
                                            <TextField
                                              onKeyPress={(e) => { if (e.code === 'Backquote') e.preventDefault() }}
                                              label={'ATIVO LONG'}
                                              type={'text'}
                                              variant='outlined'
                                              onChange={(e) => {
                                                  setEditLong(e.target.value)
                                              }}
                                              value={editLong}
                                              InputLabelProps={{
                                                shrink: true,
                                              }}
                                              className={classes.styleForClients}
                                            />
                                            <TextField
                                              onKeyPress={(e) => { if (e.code === 'Backquote') e.preventDefault() }}
                                              label={'ATIVO SHORT'}
                                              type={'text'}
                                              variant='outlined'
                                              onChange={(e) => {
                                                  setEditShort(e.target.value)
                                              }}
                                              value={editShort}
                                              InputLabelProps={{
                                                shrink: true,
                                              }}
                                              className={classes.styleForClients}
                                            />                                            
                                            <TextField
                                              onKeyPress={(e) => { if (e.code === 'Backquote') e.preventDefault() }}
                                              label={'RATIO LIMITE ENTRADA'}
                                              type={'text'}
                                              variant='outlined'
                                              onChange={(e) => {
                                                  setEditRatioLimiteEntrada(e.target.value)
                                              }}
                                              value={editRatioLimiteEntrada}
                                              InputLabelProps={{
                                                shrink: true,
                                              }}
                                              className={classes.styleForClients}
                                            />
                                            <TextField
                                              onKeyPress={(e) => { if (e.code === 'Backquote') e.preventDefault() }}
                                              label={'RATIO GAIN'}
                                              type={'text'}
                                              variant='outlined'
                                              onChange={(e) => {
                                                  setEditRatioGain(e.target.value)
                                              }}
                                              value={editRatioGain}
                                              InputLabelProps={{
                                                shrink: true,
                                              }}
                                              className={classes.styleForClients}
                                            />
                                            <TextField
                                              onKeyPress={(e) => { if (e.code === 'Backquote') e.preventDefault() }}
                                              label={'RATIO LOSS'}
                                              type={'text'}
                                              variant='outlined'
                                              onChange={(e) => {
                                                  setEditRatioLoss(e.target.value)
                                              }}
                                              value={editRatioLoss}
                                              InputLabelProps={{
                                                shrink: true,
                                              }}
                                              className={classes.styleForClients}
                                            />
                                            <TextField
                                              onKeyPress={(e) => { if (e.code === 'Backquote') e.preventDefault() }}
                                              label={'LIMITE L/S'}
                                              type={'text'}
                                              variant='outlined'
                                              onChange={(e) => {
                                                setEditNetLimit(e.target.value)
                                              }}
                                              value={editNetLimit}
                                              InputLabelProps={{
                                                shrink: true,
                                              }}
                                              className={classes.styleForClients}
                                            />
                                        </div>
                                        {/* BOTÕES */}
                                        <div className="buttonEditReportBox" style={{marginTop: '5px'}}>
                                        <div></div>
                                          <button className="buttonChangeReport" onClick={() => {
                                            handleSubmitEditLS()
                                            setInEdit(false)
                                            setIsEdit(false)
                                          }}>
                                            Enviar
                                          </button>

                                          <button className="buttonChangeReportCancel" onClick={() => {
                                            setInEdit(false)
                                            setIsEdit(false)
                                            setLongShortIdInEdit(-1)
                                          }}>
                                            Cancelar
                                          </button>
                                        </div>
                                      </div>
                                    ) : (
                                      <div className="editReportLongShort">
                                        <p>{b?.report ? "Edição de anexo" : "Adição de anexo"}</p>
                                        <div className="editReportLongShortBody">
                                          <div></div>
                                          <div className={'radioSelectContainerColumnLineLongShort'}>
                                            <div>
                                              <input onKeyPress={(e) => {if(e.code === 'Backquote') e.preventDefault()}} type="checkbox" id='archive' checked={isDocument == true ? true : false} onClick={(e) => setIsDocument(true)}/>
                                              <label htmlFor="archive">Arquivo</label>
                                            </div>
                                            <div>
                                              <input onKeyPress={(e) => {if(e.code === 'Backquote') e.preventDefault()}} type="checkbox" id='link' checked={isDocument == true ? false : true} onClick={(e) => setIsDocument(false)}/>
                                              <label htmlFor="link">Link</label>
                                            </div>
                                          </div>
                                          <div>
                                            {!isDocument ? 
                                              <div className={'InputToFormatLS'}>
                                                <div>
                                                  <input className="inputLinkReport" onKeyPress={(e) => {if(e.code === 'Backquote') e.preventDefault()}} type="text" defaultValue={b?.report_is_file == 0 ? b?.report : ''} onChange={(e) => setLink(e.target.value)} placeholder=' '/>
                                                  <label htmlFor="">Link</label>
                                                </div>
                                              </div> :
                                              <div className={'fileInputAdjustLS'}>
                                                <label htmlFor="fileSelect">{file === '' || file === undefined || file === null ? 'Selecione um arquivo' : file.name}</label>
                                                <input onKeyPress={(e) => {if(e.code === 'Backquote') e.preventDefault()}} type="file" id='fileSelect' onChange={(e) => setFile(e.target.files[0])}/>
                                              </div>
                                            }  
                                          </div>
                                        </div>
                                        <div className="buttonEditReportBox">
                                          <button className="buttonChangeReport" onClick={() => {
                                            handleSubmit()
                                            setInEdit(false)
                                          }}>
                                            Enviar
                                          </button>

                                          <button className="buttonChangeReportCancel" onClick={() => {
                                            setInEdit(false)
                                            setLongShortIdInEdit(-1)
                                            setFile()
                                            setLink('')
                                          }}>
                                            Cancelar
                                          </button>
                                        </div>
                                      </div>
                                    )
                                  }
                              </div>
                          ) 
                        }
                      }) : 
                      <div style={{textAlign: 'center'}}>Nenhum resultado</div>
                    }
                  </div>
                </div>
            </div> 
            }
      </div>
    </div>
  );
}
