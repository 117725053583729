//Função recalcula dados do footer
//Criado por: Rafael Cezário
//Efetua o calculo do footer para painel de monitoramento
//trazendo em tela em tempo real o que está sendo filtrado pelo usuário

export function recalculateFooterData(ordersData, sumType) {
    const sumValue = (previousValue, currentValue) => previousValue + currentValue
    if (ordersData.length > 0) {

        const clientsOperated = []
        const thisTotalClientsOperated = []
        const thisTotalValue = []
        const thisTotalComission = []
        const thisTotalOperated = []

        ordersData.forEach((order) => {
            if (clientsOperated?.indexOf(order.clients_external_id) === -1) {
                clientsOperated?.push(order.clients_external_id)
                thisTotalClientsOperated?.push(1)
            }
            sumType === 'sumWithQuantity' ? thisTotalValue?.push(order.value * order.quantity) :
                (order?.price > 0) && thisTotalValue?.push(order.value * order?.price)
            sumType === 'sumWithQuantity' && thisTotalComission?.push(order.comission)
            thisTotalOperated?.push(1)
        })
        return (
            sumType === 'sumWithQuantity' ?
                {
                    totalThisValue: (thisTotalValue.length > 0 ? thisTotalValue.reduce(sumValue) : '0'),
                    totalComissionThisPeriod: (thisTotalComission.reduce(sumValue)),
                    totalOperationsThisPeriod: (thisTotalOperated.reduce(sumValue)),
                    totalClientsOperatedThisPeriod: (thisTotalClientsOperated.reduce(sumValue))
                }
                :
                {
                    totalThisValue: (thisTotalValue.length > 0 ? thisTotalValue.reduce(sumValue) : '0'),
                    totalOperationsThisPeriod: (thisTotalOperated.reduce(sumValue)),
                    totalClientsOperatedThisPeriod: (thisTotalClientsOperated.reduce(sumValue))
                }
        )
    }
}