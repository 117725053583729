import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom'
/*Styles*/
import '../styles/assets.table.css';
/*Contexts*/
import { useModal } from '../../../../../contexts/useModal';
import { useAuth } from '../../../../../contexts/useAuth';
/*Services*/
import { api } from '../../../../../services/api';
import { getAssetsList } from '../../../../../services/asyncFunctions';
/*Components*/
import { ArrowLoader } from '../../../../../components/loaders/arrowLoader';
import { FiTrash2,FiArrowDown, FiCopy } from 'react-icons/fi';
import { AssetsEdit } from '../assets.edit';
/*Utils*/
import { titleize } from '../../../../../utils/formatNumberAndText/formatTextUpperFirstLetter';
import { toBRL } from '../../../../../utils/formatNumberAndText/toBRLCurrency';

export function AssetsTable() {

    const [loading, setLoading] = useState(false);
    const [filtered, setFiltered] = useState('');
    const [selectedFilter, setSelectedFilter] = useState('Ativo');
    const { setShow, setType, type, setEdited, edited } = useModal();
    const { tokenKey } = useAuth();
    const [assetsList, setAssetsList] = useState([]);
    const [scrollTable, setScrollTable] = useState(null);
    const [scrollTableLines, setScrollTableLines] = useState(50);
    const [bottomScroll, setBottomScroll] = useState(false);
    const history = useHistory()


    async function assetsGet(){

        setLoading(true)
        const assets = await getAssetsList(tokenKey)
        if (assets?.isError) {
            setType({
                name: 'alertMsg',
                type: 'information',
                value: {
                    message: assets?.error
                }
            })
            setShow(true);
            setLoading(false)
        } else {
            setAssetsList(assets)
            setLoading(false)
        }
    }
   
    const handleDelete = async (data) => {
        setLoading(true);
        try {
            await api.delete(`/api/v1/assets/${data.asset}`, {
                headers: {
                    authorization: `Bearer ${tokenKey}`
                }
            })

            setType({ name: 'alertMsg', type: 'information', value: { message: 'Ativo excluído com sucesso' } })
            setShow(true);
            setLoading(false);
            setEdited(!edited);

        } catch (error) {
            setType({ name: 'alertMsg', type: 'information', value: { message: error?.response?.data?.message === undefined ? 'Erro ao excluir ativo' : error?.response?.data?.message } })
            setShow(true);
        }
    }

    async function assetsExport() {
        setLoading(true);
        try {
            const exportAssetsResult = await api.get(`/api/v1/assets/export`, {
                headers: {
                    authorization: `Bearer ${tokenKey}`
                },
                responseType: 'blob'
            })
            console.log(exportAssetsResult)

            const responseURL = URL.createObjectURL(new Blob([exportAssetsResult.data]));
            const link = document.createElement('a');
            link.href = responseURL;
            link.setAttribute('download', 'lista_de_ativos.xlsx');
            document.body.appendChild(link);
            link.click();
            setLoading(false)

        } catch (error) {
            setType({ name: 'alertMsg', type: 'information', value: { message: error?.response?.data?.message === undefined ? 'Erro ao buscar dados para exportação' : error?.response?.data?.message } })
            setShow(true);
            setLoading(false);
        }
    }

    async function confirmHandleDelete(assetData) {
        setType({ name: 'alertMsg', value: { message: 'Deseja realmente excluir o ativo selecionado?', func: handleDelete, dataForFunc: assetData } });
        setShow(true);
    }

    function listFilter() {
        if (filtered === null || filtered === undefined || filtered === '') {
            return assetsList;
        } else {
            return assetsList.filter((filterRow) => {

                if (filterRow?.asset?.toLowerCase()?.indexOf(filtered?.toLowerCase()) > -1 && selectedFilter === 'Ativo' ||
                    filterRow?.company_name?.toLowerCase()?.indexOf(filtered?.toLowerCase()) > -1 && selectedFilter === 'Empresa' ||
                    filterRow?.asset_class_description?.toLowerCase()?.indexOf(filtered?.toLowerCase()) > -1 && selectedFilter === 'Classe' ||
                    filterRow?.asset_sector_description?.toLowerCase()?.indexOf(filtered?.toLowerCase()) > -1 && selectedFilter === 'Setor') {
                    return filterRow;
                }
            })
        }
    }

    useEffect(() => {
        if(edited || assetsList?.length === 0){
            assetsGet()
            setEdited(false)
        }
    }, [edited])

    useEffect(() => {
        if (type?.succesEdited) {
            getAssetsList()
        }
    }, [type])

    useEffect(() => {

        if (bottomScroll != false) {

            setScrollTableLines(scrollTableLines + 50)
            setBottomScroll(false);

        } else {
            setBottomScroll(false);
        }

    }, [bottomScroll])

    function editThisAsset(assetData){
        setType({name: 'editAssets', asset: assetData})
        setShow(true);
    }
    return (
        <div className="asstesTableContainer" id='assetsTableContainer' onScrollCapture={() => {
            setScrollTable(document.getElementById('assetsTableContainer'))
            if (scrollTable?.scrollTop + scrollTable?.clientHeight >= scrollTable?.scrollHeight) {
                setBottomScroll(true);
            }
        }}>
            <table className="assetsTable">
                <thead>
                    <tr>
                        <th colSpan='10'>
                            <div className='assetsTableThDiv'>
                                <p>Lista de ativos</p>
                                <div className="assetsTableThDivLeft">
                                    <select id='assetFilter' 
                                    className='selectFilterTable'
                                    onChange={(e)=> setSelectedFilter(e.target.value)}>
                                        <option>Ativo</option>
                                        <option>Empresa</option>
                                        <option>Classe</option>
                                        <option>Setor</option>
                                    </select>
                                    <input 
                                    type='text' 
                                    className='tableAllInputThead'
                                    onChange={(e)=> setFiltered(e.target.value)}
                                    ></input>
                                    <button
                                    className='buttonTableThead'
                                    onClick={() => {
                                        const output = [];
                                        const titles = ['Ativo', 'Valor', 'Empresa', 'Classe', 'Setor']
                                        output.push(titles.join('\t'))
                                        const keys = ['asset', 'value','company_name', 'asset_class_description', 'asset_sector_description'];
                                        assetsList.forEach((x) => {
                                            const tmp = keys.map((y) => {return y==='value' ? String(x[y]).replace('.',',') : x[y]});
                                            output.push(tmp.join('\t'))
                                        })
                                        const table = output.join('\n')
                                        navigator.clipboard.writeText(table)
                                        alert('Conteúdo da tabela copiado para a área de transferência')
                                    }}
                                ><FiCopy />Copiar</button>
                                    <button
                                        className='buttonTableThead'
                                        onClick={() => {
                                            assetsExport();
                                    }}><FiArrowDown />Exportar</button>
                                    <button
                                        className='buttonTableThead'
                                        onClick={() => {
                                            setType({ name: 'assetsCadastrationModal' })
                                            setShow(true)

                                        }}
                                    >+ Novo Ativo</button>
                                    <button
                                        className='buttonTableThead'
                                        onClick={() => {
                                            history.push('/classesAtivos')
                                        }}
                                    >+ Nova Classe</button>
                                    <button
                                        className='buttonTableThead'
                                        onClick={() => {
                                            history.push('/setoresAtivos')
                                        }}
                                    >+ Novo Setor</button>
                                    <button
                                        id='buttonTableThead'
                                        onClick={() => {
                                            setType({ name: 'assetsImportModal' })
                                            setShow(true)
                                        }}
                                    >+ Atualizar valores</button>
                                </div>
                            </div>
                        </th>
                    </tr>
                    <tr className="assetsTableSecondTheadTr">
                        <th>Ativo</th>
                        <th>Valor</th>
                        <th>Empresa</th>
                        <th>Classe</th>
                        <th>Setor</th>
                        <th>Excluir</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        loading ?
                            (
                                <tr>
                                    <td colSpan='10' id='assetsTableLoaderTd'>
                                        <ArrowLoader />
                                    </td>
                                </tr>
                            ) :


                            assetsList?.length > 0 ?
                                (
                                    listFilter().map((asset, index) => {

                                        if (index < scrollTableLines && bottomScroll && index + 1 === scrollTableLines) {
                                            return <tr id={bottomScroll ? 'usersShowLoadingScroll' : 'usersHideLoadingScroll'}><td colspan='11'>
                                                <div className='ldsellipsis'><div></div><div></div><div></div><div></div></div>
                                            </td></tr>
                                        }
                                        else if (index < scrollTableLines) {
                                            return (
                                                <tr>
                                                    <td onClick={()=> editThisAsset(asset)}>{asset?.asset}</td>
                                                    <td onClick={()=> editThisAsset(asset)}>{toBRL(asset?.value)}</td>
                                                    <td onClick={()=> editThisAsset(asset)}>{titleize(asset?.company_name)}</td>
                                                    <td onClick={()=> editThisAsset(asset)}>{titleize(asset?.asset_class_description)}</td>
                                                    <td onClick={()=> editThisAsset(asset)}>{titleize(asset?.asset_sector_description)}</td>
                                                    <td
                                                        title='Clique para remover este ativo'
                                                        onClick={() => confirmHandleDelete(asset)}
                                                    ><FiTrash2 /></td>
                                                </tr>
                                            )
                                        }

                                    })
                                ) :
                                (
                                    <tr>
                                        <td colSpan='10'>--Lista Vazia--</td>
                                    </tr>
                                )
                    }
                </tbody>
            </table>
        </div>
    )
}