/*Componente tabela de clientes
/*Descrição: Exibe todos os clientes cadastrado em sistema
/*Desenvolvido por: Equipe Rafael Cezario
*/

//libraries
import React, { useEffect, useState } from 'react';
import { FiPlus, FiSearch, FiArrowDown,FiCopy } from 'react-icons/fi'
import { ThemeProvider,createTheme } from '@mui/material/styles';
//hooks
import { useModal } from '../../../../contexts/useModal';
import { useAuth } from '../../../../contexts/useAuth';
//services
import { api } from '../../../../services/api';
import { getAllClients } from '../../../../services/asyncFunctions';
//components
import { ArrowLoader } from '../../../../components/loaders/arrowLoader';
import { Tooltip, IconButton } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import { TableCell } from "@material-ui/core";
import CustomToolbarSelectVBATransfer from "./CustomToolbarSelectVBATransfer";
//utils
import { titleize } from '../../../../utils/formatNumberAndText/formatTextUpperFirstLetter'
import { ModalSizeFitToScreen } from '../../../../utils/responsiveFunctions/modalSizeFitToScreen';

//styles
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import './styles/clientsVBATransfer.css'
import { MdTexture } from 'react-icons/md';

export function ClientsEvasion() {

    const { show, setShow, setType, type, edited, setEdited } = useModal();
    const { user, tokenKey } = useAuth();
    const [listClients, setListClients] = useState([]);
    const [listAllClients, setListAllClients] = useState([]);
    const [totalClients, setTotalClients] = useState(0);
    const [profilesList, setProfilesList] = useState([]);
    const [selectedClients, setSelectedClients] = useState([]);
    const [listExceptionClientsIDs, setListExceptionClientsIDs] = useState([]);
    const [viewCheckbox, setViewCheckbox] = useState(false)
    const [loading, setLoading] = useState(true);
    const [isloaded, setIsloaded] = useState(false);
    const token = localStorage.getItem('@auth:token');

    useEffect(() => {
        async function searchClients() {
            try {
                const clientsResult = await api.get('/api/v1/vba/evasion', {
                    headers: {
                        authorization: `Bearer ${token}`,
                        donwload: false
                    }
                })
                // console.log('clientsResult?.data?.data',clientsResult?.data?.data)
                setListAllClients(clientsResult?.data?.data)
                setListClients(clientsResult?.data?.data);
                setTotalClients(clientsResult?.data?.data.length);
                setLoading(false)
            } catch (error) {
                alert(error?.response?.data?.message)
            }
            setLoading(false);
        }

        searchClients()
    }, [show, edited])

    async function clientsExport() {
        setLoading(true);
        try {
            const exportClientsResult = await api.get(`/api/v1/vba/evasion`, {
                headers: {
                    authorization: `Bearer ${tokenKey}`,
                    donwload: true
                },
                responseType: 'blob'
            })

            const responseURL = URL.createObjectURL(new Blob([exportClientsResult.data]));
            const link = document.createElement('a');
            link.href = responseURL;
            link.setAttribute('download', 'clientes evasão.xlsx');
            document.body.appendChild(link);
            link.click();
            setLoading(false)

        } catch (error) {
            setType({ name: 'alertMsg', type: 'information', value: { message: error?.response?.data?.message === undefined ? 'Erro ao buscar dados para exportação' : error?.response?.data?.message } })
            setShow(true);
            setLoading(false);
        }
    }

    function copyToClipboard() {
        const output = [];
        const titles = ["Código cliente", "Cliente", "Código Broker", "Broker", "Código Assessor", "Assessor", "Evasão em:", "Status atual", "Data último status", "Comentário"];
        output.push(titles.join('\t'))
        const keys = ["client_id", "client_name", "broker_id", "broker_name", "advisor_id", "advisor_name", "created_at", "last_status_description", "data_last_active", "comment"]
        listAllClients.forEach((x) => {
            const tmp = keys.map((y) => {return y==='created_at' || y==='data_last_active'  ? x[y].replace(/^(\d{4})-(\d{2})-(\d{2})T(\d{2}:\d{2}:\d{2})\.\d+Z$/, "$3/$2/$1") :
            y === "client_name" || y === "last_status_description"  ? titleize(x[y]) 
            : x[y]});
            output.push(tmp.join('\t'))
        })
        const table = output.join('\n')
        navigator.clipboard.writeText(table)
        alert('Conteúdo da tabela copiado para a área de transferência')
    }


    async function handleAuthorize(clientsIds){
        var idsC = []
        clientsIds.map((e) => {return idsC.push(parseInt(e))})
        try {
            const clientsResult = await api.get(`/api/v1/vba/transfer/${idsC}`,{
                headers: {
                    authorization: `Bearer ${token}`
                }
            })

            const rows = [];
            const ids = [];
            setType({ name: 'successClientEdit', type: 'information', value: { message: `${clientsResult.data.message}` } })
            setShow(true)
            setLoading(false)
        } catch (error) {
            alert(error?.response?.data?.message)
        }
    }

    // Adiciona um botão para chamar a função de copiar a tabela para a área de tranferência
    const AddButton = () => (
        <>
            <Tooltip disableFocusListener title="Copiar para a área de transferência">
                <IconButton onClick={copyToClipboard}>
                    <ContentCopyIcon />
                </IconButton>
            </Tooltip>
            
        </>
    );

    const columns = [
          {
          name: "client_id",
          label: "CÓDIGO CLIENTE",
          options: {
              filter: true,
              sort: true,
              display: true,
              setCellProps: () => ({
                style: {
                    whiteSpace: "nowrap",
                        paddingLeft: '0.5rem',
                        paddingRight: '0.5rem'
                },
            }),
          },
        },
        {
          name: "client_name",
          label: "CLIENTE",
          options: {
              filter: true,
              sort: true,
              display: true,
              setCellProps: () => ({
                style: {
                    whiteSpace: "nowrap",
                        paddingLeft: '0.5rem',
                        paddingRight: '0.5rem'
                },
            }),
            customBodyRender: (val) => {
                return titleize(val);
            }
          },
        },
        {
            name: "broker_id",
            label: "Código Broker",
            options: {
                filter: true,
                sort: true,
                display: false,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                            paddingLeft: '0.5rem',
                            paddingRight: '0.5rem'
                    },
                }),
            },
        },
        {
            name: "broker_name",
            label: "Broker",
            options: {
                filter: true,
                sort: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                            paddingLeft: '0.5rem',
                            paddingRight: '0.5rem'
                    },
                }),
                customBodyRender: (val) => {
                    return titleize(val);
                  },
            },
        },
        {
            name: "advisor_id",
            label: "Código Assessor",
            options: {
                filter: false,
                sort: true,
                display: false,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                            paddingLeft: '0.5rem',
                            paddingRight: '0.5rem'
                    },
                }),
            },
        },
        {
            name: "advisor_name",
            label: "Assessor",
            options: {
                filter: true,
                sort: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                            paddingLeft: '0.5rem',
                            paddingRight: '0.5rem'
                    },
                }),
                customBodyRender: (val) => {
                    return titleize(val);
                  },
            },
        },
        {
            name: "created_at",
            label: "Evasão em:",
            options: {
                filter: false,
                sort: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                            paddingLeft: '0.5rem',
                            paddingRight: '0.5rem'
                    },
                }),
                customBodyRender: (val) => {
                    return val.replace(/^(\d{4})-(\d{2})-(\d{2})T(\d{2}:\d{2}:\d{2})\.\d+Z$/, "$3/$2/$1");
                },
            },
        },
        {
            name: "last_status_description",
            label: "Status atual",
            options: {
                filter: false,
                sort: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                            paddingLeft: '0.5rem',
                            paddingRight: '0.5rem'
                    },
                }),
                customBodyRender: (val) => {
                    return titleize(val);
                }
            },
        },
        {
            name: "data_last_active",
            label: "Data último status",
            options: {
                filter: false,
                sort: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                            paddingLeft: '0.5rem',
                            paddingRight: '0.5rem'
                    },
                }),
                customBodyRender: (val) => {
                    return val.replace(/^(\d{4})-(\d{2})-(\d{2})T(\d{2}:\d{2}:\d{2})\.\d+Z$/, "$3/$2/$1");
                },
            },
        },
        {
            name: "comment",
            label: "Comentário",
            options: {
                filter: false,
                sort: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                            paddingLeft: '0.5rem',
                            paddingRight: '0.5rem'
                    },
                }),
            },
        },
    ];

    const options = {
        filterType: "multiselect",
        download: true,
        print: false,
        responsive: "vertical",
        tableBodyHeight: "71vh",
        selectableRowsHideCheckboxes: true ,
        rowsSelected: user.type === 1 && viewCheckbox === true ? selectedClients : [],
        selectToolbarPlacement: "replace",
        selectableRows: 'multiple',
        viewColumns: false,
        rowsPerPage: 25,
        rowsPerPageOptions: [25, 50, totalClients],
        draggableColumns: {
            enabled: true
          },
        textLabels: {
            body: {
                noMatch: loading ? <ArrowLoader /> : "Não foram encontrados dados",
                toolTip: "Ordenar",
                columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
            },
            pagination: {
                next: "Próxima",
                previous: "Anterior",
                rowsPerPage: "Linhas por página:",
                displayRows: "de",
            },
            toolbar: {
                search: "Pesquisar",
                downloadCsv: "Download XLSX",
                print: "Imprimir",
                viewColumns: "Ver colunas",
                filterTable: "Filtrar",
            },
            filter: {
                all: "Todos",
                title: "Filtros",
                reset: "Limpar",
            },
            viewColumns: {
                title: "Mostrar Colunas",
                titleAria: "Mostrar/Ocultar colunas",
            },
            selectedRows: {
                text: "linha(s) selecionadas",
                delete: "Excluir",
                deleteAria: "Excluir linhas Selecionadas",
            },
        },
        downloadOptions: {
            filename: "vba.csv",
            separator: ";",
        },
        onDownload: () => {
            clientsExport();
            return false;
        },
        customToolbar: AddButton,
        setTableProps: () => {
          return {
              padding: 'none',
              size: 'small'
          };
        }
    };

    const title = 
        <div>
            <div style={{fontSize: "1.6rem", fontWeight: '400'}}>Hístórico evasão</div>
            <div>Total {totalClients}</div>
        </div>


    const getMuiTheme = () =>
        createTheme({
        components: {
            MuiTableCell: {
                styleOverrides: {
                    root:{
                            justifyContent: 'center',
                            whiteSpace: "nowrap",
                            textAlign: 'center',
                    }
                },
            },
            MUIDataTableHeadCell: {
                styleOverrides: {
                    contentWrapper: {
                        justifyContent: 'center',
                        whiteSpace: "nowrap",
                    }
                }
            },
        },
        })

    return (
        loading ? 
        <div className='divLoading'>
            <ArrowLoader /> 
        </div>
        :
        <ThemeProvider theme={getMuiTheme()}>
            <MUIDataTable title={title} data={listClients} columns={columns} options={options}></MUIDataTable>
        </ThemeProvider> 
    );
}
