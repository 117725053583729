/*Componente cadastro de empresas
/*Descrição: Efetua o cadastro de empresas
/*Desenvolvido por: Equipe Rafael Cezário
*/

import React, { useEffect, useState } from 'react';
import { Autocomplete } from '@material-ui/lab'
import { TextField, RadioGroup, FormControl, FormLabel, Radio, FormControlLabel } from '@material-ui/core'
//hooks
import { useModal } from '../../../../contexts/useModal';
//services
import { api } from '../../../../services/api';
//components
import { ArrowLoader } from '../../../../components/loaders/arrowLoader';
//utils
import { cnpjMask } from '../../../../utils/formatNumberAndText/cnpjMask'
import { removeMask } from '../../../../utils/formatNumberAndText/removeCnpjMask'
//styles
import { styleClientsInput } from '../../../../components/styles/autocompletField';
import './styles/branches.cadastration.css'

export function BranchesCadastration() {
    const classes = styleClientsInput();
    const [companyName, setCompanyName] = useState('');
    const [cnpj, setCNPJ] = useState('');
    const [typeOfCompanie, setTypeOfCompanie] = useState('');
    const [UF, setUF] = useState([])
    const [parentCompanyList, setParentCompanyList] = useState([]);
    const [selectedParentCompany, setSelectedParentCompany] = useState('')
    const [selectedUF, setSelectedUF] = useState('')
    const [city, setCity] = useState([]);
    const [selectedCity, setSelectedCity] = useState('');
    const [loading, setLoading] = useState(true);
    const token = localStorage.getItem('@auth:token')
    const { show, setShow, edited, setEdited, setType } = useModal();
    const requiredFieldsParentCompany = [
        { field: 'companyName', value: companyName },
        { field: 'cnpj', value: cnpj },
        { field: 'typeOfCompanie', value: typeOfCompanie },
        { field: 'selectedUF', value: selectedUF },
        { field: 'selectedCity', value: selectedCity },
    ]

    const requiredFieldsBranche = [
        { field: 'companyName', value: companyName },
        { field: 'cnpj', value: cnpj },
        { field: 'typeOfCompanie', value: typeOfCompanie },
        { field: 'selectedUF', value: selectedUF },
        { field: 'selectedCity', value: selectedCity },
        { field: 'selectedParentCompany', value: selectedParentCompany },
    ]
    const [errorFields, setErrorFields] = useState({
        'companyName': false,
        'cnpj': false,
        'typeOfCompanie': false,
        'selectedUF': false,
        'selectedCity': false,
        'selectedParentCompany': false
    });

    useEffect(() => {
        async function searchLocations() {
            setLoading(true);
            try {
                const resultLocation = await fetch('https://servicodados.ibge.gov.br/api/v1/localidades/estados', {
                    headers:
                    {
                        'content-type': 'application/json'
                    }
                }).then(response => response.json())

                setUF(resultLocation.sort(function (a, b) {
                    return a.sigla < b.sigla ? -1 : a.sigla > b.sigla ? 1 : 0
                }))
                setLoading(false);


                if (selectedUF?.length > 0) {
                    const resultCity = await fetch(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${selectedUF}/distritos`, {
                        headers:
                        {
                            'content-type': 'application/json'
                        }
                    }).
                        then(response => response.json())
                    setCity(resultCity?.map((targetCity) => { return targetCity.nome }))
                }
            } catch (error) {
                console.log(error)
            }

        }

        async function searchParentCompanies() {
            const resultCompanies = await api.get('api/v1/branches', {
                headers: {
                    authorization: `Bearer ${token}`
                }
            })

            setParentCompanyList(resultCompanies?.data?.data?.filter((isParentCompany) => {
                if (isParentCompany.parent_company) {
                    return isParentCompany
                }
            }));
        }

        searchLocations();
        searchParentCompanies();

    }, [selectedUF])

    useEffect(() => {
        setSelectedCity('');
        setSelectedParentCompany('');
        setSelectedUF('');
        setCompanyName('');
        setCNPJ('');
        setTypeOfCompanie('');
        setErrorFields({
            'companyName': false,
            'cnpj': false,
            'typeOfCompanie': false,
            'selectedUF': false,
            'selectedCity': false,
            'selectedParentCompany': false
        })

    }, [show])


    function validityFields() {

        let errors = false
        if (parseInt(typeOfCompanie) === 1 || (typeOfCompanie === undefined || typeOfCompanie === '')) {
            requiredFieldsParentCompany.map((fieldRequired) => {

                if (fieldRequired.value === '' || fieldRequired === undefined) {
                    errors = true
                    errorFields[fieldRequired.field] = true
                } else {
                    errorFields[fieldRequired.field] = false
                }
            })
        }
        if (parseInt(typeOfCompanie) === 0) {
            requiredFieldsBranche.map((fieldRequired) => {

                if (fieldRequired.value === '' || fieldRequired === undefined) {
                    errors = true
                    errorFields[fieldRequired.field] = true
                } else {
                    errorFields[fieldRequired.field] = false
                }
            })
        }

        setErrorFields(errorFields)
        return errors
    }

    function setLoadingFalseTimed(time) {

        setTimeout(() => {

            setLoading(false)

        }, [time])

    }
    async function handleSubmit(e) {

        e.preventDefault();
        setLoading(true);
        if (selectedCity === '' || selectedUF === '' || companyName === '' || typeOfCompanie === '') {
            alert('Preencha todos os campos')
            setLoading(false)
            return
        }

        if (validityFields()) {
            alert('Campos obrigatórios não foram preenchidos')
            setLoadingFalseTimed(300)
            return
        } else {
            setLoading(true);
            if (parseInt(typeOfCompanie) === 1) {
                try {
                    const companiesCadastration = await api.post('api/v1/companies', {
                        name: companyName
                    },
                        {
                            headers: {
                                authorization: `Bearer ${token}`
                            }
                        });

                    if (companiesCadastration?.data?.status === 201) {

                        await api.post('api/v1/branches', {
                            companies_id: companiesCadastration.data.data.id,
                            city_state: selectedUF,
                            city: selectedCity,
                            name: companyName,
                            cnpj: removeMask(cnpj),
                            parent_company: parseInt(typeOfCompanie)
                        },
                            {
                                headers: {
                                    authorization: `Bearer ${token}`
                                }
                            })

                        setType({ name: 'alertMsg', type: 'information', value: { message: 'Empresa cadastrada com sucesso!' } });
                        setShow(true);
                    }
                    setEdited(!edited)
                } catch (error) {
                    setType({ name: 'alertMsg', type: 'information', value: { message: error?.response?.data?.message } });
                }
            }
            else {

                try {
                    await api.post('api/v1/branches', {
                        companies_id: selectedParentCompany.companies_id,
                        city_state: selectedUF,
                        city: selectedCity,
                        name: companyName,
                        cnpj: removeMask(cnpj),
                        parent_company: 0
                    }, {
                        headers: {
                            authorization: `Bearer ${token}`
                        }
                    })
                    setType({ name: 'alertMsg', type: 'information', value: { message: 'Filial cadastrada com sucesso!' } });
                    setShow(true);
                    setEdited(!edited)
                } catch (error) {
                    setLoading(false);
                    setType({ name: 'alertMsg', value: { message: error.response.data.message }, type: 'information' })
                }
            }


        }

        setSelectedCity('')
        setCNPJ('')
        setSelectedUF('')
        setCompanyName('')
        setTypeOfCompanie('')

    }

    const handleChange = (value) => {
        setTypeOfCompanie(value.target.value)
    }

    return (
        loading ? (
            <div className='companiesCadastrationLoader'>
                <ArrowLoader />
            </div>
        ) : (

            <div className='companiesCadastrationContainer'>
                <div className='companiesHeader'>
                    <p>Cadastre as empresas</p>
                    <button onClick={() => setShow(false)}>X</button>
                </div>
                <div className='companiesBody'>
                    <form>
                        <label >
                            <TextField
                                label='Empresa'
                                onKeyPress={(e) => { if (e.code === 'Backquote') e.preventDefault() }}
                                variant='outlined'
                                className={errorFields['companyName'] && companyName === '' ? classes.rootWithError : classes.root}
                                value={companyName}
                                onChange={(e) => setCompanyName(e.target.value)}
                            />
                        </label>
                        <label >
                            <TextField
                                label='CNPJ'
                                onKeyPress={(e) => { if (e.code === 'Backquote') e.preventDefault() }}
                                id='cnpjInput'
                                variant='outlined'
                                className={errorFields['cnpj'] && cnpj === '' ? classes.rootWithError : classes.root}
                                value={cnpj}
                                onChange={(e) => {
                                    setCNPJ(cnpjMask(e.target.value))
                                }}

                            />
                        </label>
                        <label>
                            <FormControl component="fieldset">
                                <FormLabel component="legend">Categoria</FormLabel>
                                <RadioGroup row aria-label="Matriz" value={typeOfCompanie} onChange={handleChange}>
                                    <FormControlLabel value='1' control={<Radio />} label="Matriz" />
                                    <FormControlLabel value='0' control={<Radio />} label="Filial" />
                                </RadioGroup>
                            </FormControl>
                        </label>
                        <label className={parseInt(typeOfCompanie) === 0 ? '' : 'companiesCadastrationHide'}>
                            <Autocomplete
                                options={parentCompanyList}
                                value={selectedParentCompany}
                                getOptionLabel={(x) => x.name}
                                onChange={(event, newValue) => {
                                    setSelectedParentCompany(newValue);
                                }}
                                id='company'
                                renderInput={(params) => <TextField
                                    label='Selecione a Matriz'
                                    onKeyPress={(e) => { if (e.code === 'Backquote') e.preventDefault() }}
                                    className={errorFields['selectedParentCompany'] && selectedParentCompany === '' ? classes.rootWithError : classes.root}

                                    {...params} variant="outlined" />}
                            />
                        </label>
                        <label>
                            <Autocomplete
                                options={

                                    UF?.map((loc) => {
                                        return loc.sigla
                                    })
                                }
                                value={selectedUF}
                                getOptionLabel={(x) => x}
                                onChange={(event, newValue) => {

                                    setSelectedUF(newValue);
                                }}
                                id='state'
                                renderInput={(params) => <TextField
                                    label='Estado'
                                    onKeyPress={(e) => { if (e.code === 'Backquote') e.preventDefault() }}
                                    className={errorFields['selectedUF'] && selectedUF === '' ? classes.rootWithError : classes.root}
                                    {...params} variant="outlined" />}
                            />
                        </label>
                        <label>
                            <Autocomplete
                                options={city?.sort()}
                                value={selectedCity}
                                onChange={(event, newValue) => {
                                    setSelectedCity(newValue);
                                }}
                                getOptionLabel={(option) => option}
                                id='city'
                                renderInput={(params) => <TextField
                                    label='Cidade'
                                    onKeyPress={(e) => { if (e.code === 'Backquote') e.preventDefault() }}
                                    className={errorFields['selectedCity'] && selectedCity === '' ? classes.rootWithError : classes.root}

                                    {...params} variant="outlined" />}
                            />
                        </label>
                        <div className='buttonDiv'>
                            <button className='buttonWhiteBg' type='submit' onClick={(e) => handleSubmit(e)}>Cadastrar</button>
                        </div>
                    </form>
                </div>
            </div>
        )
    )
}