import React, { useEffect, useState, useRef, useReducer } from "react"
import { api } from "../../../services/api"

import MUIDataTable from "mui-datatables"
import ContentCopyIcon from "@mui/icons-material/ContentCopy"
import { ThemeProvider, createTheme } from "@mui/material/styles"
import { Tooltip, IconButton } from "@material-ui/core"

import { useModal, setEdited } from "../../../contexts/useModal"
import { toBRL } from "../../../utils/formatNumberAndText/toBRLCurrency"
import { toPercent } from "../../../utils/formatNumberAndText/formatNumberPercent"
import { datetimeToDatePtBR } from "../../../utils/formatDate/dateTimeToPTBR"
import { ArrowLoader } from "../../../components/loaders/arrowLoader"
import { AiOutlineArrowUp, AiOutlineArrowDown, AiFillCloseCircle, AiOutlineCheckSquare } from "react-icons/ai"
import { FaCheck, FaTimes, FaStar, FaQuestion, FaUserSlash } from "react-icons/fa"
import "./styles/scenariosControl.css"
import { useAuth } from "../../../contexts/useAuth"
import { styleClientsInput } from "../../../components/styles/autocompletField"
import CurrencyTextField from "@unicef/material-ui-currency-textfield"
import TextField from "@material-ui/core/TextField"

export function TacticalAlocationsControl(props) {
    // console.log( 'props', props)
    const [dataTableCubastarTactical, setDataTableCubastarTactical] = useState([])
    const [updateValues, setUpdateValues] = useState([])
    const [category, setCategory] = useState([])
    const { type, show, setShow, setType, setEdited } = useModal()
    const [loading, setLoading] = useState(false)
    const permissions = useRef([])
    const { tokenKey, user } = useAuth()
    const classes = styleClientsInput()
    // const campains = useRef([])

    const tableData = props.msgData.data.dataCubastarTactical
    var campains = props.msgData.data.campains
    const [data, setData] = tableData

    useEffect(() => {
        if (show) {
            // campains.current = props.msgData.data.campains
            setLoading(false)
        } else {
        }
    }, [show])

    const optionsList = [
        ["Alocação Tática", 1],
        ["Produtos Secundários", 2],
    ]


    const [editedRows, setEditedRows] = useState([])

    var updateValuesList = []

    async function updateTacticalAlocations(val) {
        // console.log('val', val)
        try {
            const response = await api.post(
                `/api/v1/alocacao/cubastar/alocacaoTatica/update`,
                {
                    data: val,
                },
                {
                    headers: {
                        authorization: `Bearer ${tokenKey}`,
                    },
                }
            )
            alert("Configuração de alocações táticas atualizada com sucesso.")
            setEdited(true)
        } catch (error) {
            console.log(error?.response)
            setType({
                name: "alertMsg",
                type: "information",
                value: { message: error?.response?.data?.message === undefined ? "Erro ao atualizar alocações táticas" : error.response.data.message },
            })
            setShow(true)
        }
    }

    function handleUpdate(updateValuesList) {
        const elementos = updateValuesList.map((item) => item.split(", "))

        let arrayUpdate = []
        elementos.forEach((i) => {
            let arrayItem = {}
            if (i[1] == "abc_campain_id" && i[2] == 0){
                arrayItem = {
                    id: parseInt(i[0]),
                    column: i[1],
                    value: null,
                }
            }
            else{
                arrayItem = {
                    id: parseInt(i[0]),
                    column: i[1],
                    value: i[2],
                }
            }
            arrayUpdate.push(arrayItem)
        })

        updateTacticalAlocations(arrayUpdate)
    }

    const columns = [
        {
            name: "id",
            label: "id",
            options: {
                filter: false,
                sort: false,
                display: false,
                // customHeadRender: ({ index, label, ...column }, sortColumn, tableMeta) => (
                //     <TableCell onClick={() => sortColumn(index)} key={index} className="white_space">
                //         {label}
                //         <TableSortLabel active={tableMeta.name === column.name} direction={tableMeta.direction || "asc"} />
                //     </TableCell>
                // ),
            },
        },
        {
            name: "category",
            label: "Categoria",
            options: {
                filter: false,
                sort: false,
                // customHeadRender: ({ index, label, ...column }, sortColumn, tableMeta) => (
                //     <TableCell onClick={() => sortColumn(index)} key={index} className="white_space">
                //         {label}
                //         <TableSortLabel active={tableMeta.name === column.name} direction={tableMeta.direction || "asc"} />
                //     </TableCell>
                // ),
                customBodyRender: (value, tableMeta, updateValue) => {
                    const options = {
                        1: "Alocação Tática",
                        2: "Produtos Secundários",
                    }

                    return (
                        <div style={{marginLeft: '1rem', marginTop: '0.5rem', marginBottom: '0.5rem', marginRight: '1rem'}}>

                        <select
                            className="selectTacticalAlocationsCategory"
                            value={value}
                            onChange={(e) => {
                                updateValue(Number(e.target.value))
                                updateValuesList.push(`${tableMeta.rowData[0]}, ${tableMeta.columnData.name}, ${e.target.value}`)
                            }}
                            >
                            {Object.entries(options).map(([val, label]) => (
                                <option key={val} value={val}>
                                    {label}
                                </option>
                            ))}
                        </select>
                            </div>
                    )
                },
            },
        },
        {
            name: "abc_campain_id",
            label: "Campanha vinculada",
            options: {
                filter: false,
                sort: false,
                // customHeadRender: ({ index, label, ...column }, sortColumn, tableMeta) => (
                //     <TableCell onClick={() => sortColumn(index)} key={index} className="white_space">
                //         {label}
                //         <TableSortLabel active={tableMeta.name === column.name} direction={tableMeta.direction || "asc"} />
                //     </TableCell>
                // ),
                customBodyRender: (value, tableMeta, updateValue) => {
                    var options = {0: 'Selecione um item'}
                    campains?.forEach((item) => {
                        options[item.id] = item.description
                    })

                    return (
                        <div style={{ marginTop: '0.5rem', marginBottom: '0.5rem', maxWidth: '14rem', marginRight: '1rem'}}> 

                        <select
                            className="selectTacticalAlocationsCategory"
                            style={{maxWidth: '14rem'}}
                            value={value}
                            onChange={(e) => {
                                // console.log('e.target.value', e.target.value)
                                updateValue(Number(e.target.value))
                                updateValuesList.push(`${tableMeta.rowData[0]}, ${tableMeta.columnData.name}, ${e.target.value}`)
                            }}
                            >
                            {Object.entries(options).map(([val, label]) => (
                                <option key={val} value={val} selected={value === val}>
                                    {label}
                                </option>
                            ))}
                        </select>
                            </div>
                    )
                },
            },
        },
        {
            name: "top_pick_rank",
            label: "Top Pick",
            options: {
                filter: false,
                sort: false,
                // customHeadRender: ({ index, label, ...column }, sortColumn, tableMeta) => (
                //     <TableCell onClick={() => sortColumn(index)} key={index} className="white_space">
                //         {label}
                //         <TableSortLabel active={tableMeta.name === column.name} direction={tableMeta.direction || "asc"} />
                //     </TableCell>
                // ),
                customBodyRender: (value, tableMeta) => {
                    return (
                        <TextField
                            type="text"
                            defaultValue={value}
                            InputProps={{ readOnly: false }}
                            inputProps={{ style: { textAlign: "center" }, readOnly: false }}
                            // onChange={event => console.log(event.target.value, tableMeta)}/>
                            // onChange={event => console.log(`modificado ID ${tableMeta.rowData[0]}, coluna ${tableMeta.columnData.name}, valor "${event.target.value}"`)}/>
                            onChange={(event) => updateValuesList.push(`${tableMeta.rowData[0]}, ${tableMeta.columnData.name}, ${event.target.value}`)}
                        />
                    )
                },
            },
        },
        {
            name: "class",
            label: "Classes",
            options: {
                filter: false,
                sort: false,
                // customHeadRender: ({ index, label, ...column }, sortColumn, tableMeta) => (
                //     <TableCell onClick={() => sortColumn(index)} key={index} className="white_space">
                //         {label}
                //         <TableSortLabel active={tableMeta.name === column.name} direction={tableMeta.direction || "asc"} />
                //     </TableCell>
                // ),
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        padding: "0px 0.6rem 0px 0.6rem",
                    },
                }),
                customBodyRender: (value, tableMeta) => {
                    return (
                        <TextField
                            type="text"
                            defaultValue={value}
                            InputProps={{ readOnly: false }}
                            inputProps={{ style: { textAlign: "center" }, readOnly: false }}
                            // onChange={event => console.log(event.target.value, tableMeta)}/>
                            // onChange={event => console.log(`modificado ID ${tableMeta.rowData[0]}, coluna ${tableMeta.columnData.name}, valor "${event.target.value}"`)}/>
                            onChange={(event) => updateValuesList.push(`${tableMeta.rowData[0]}, ${tableMeta.columnData.name}, ${event.target.value}`)}
                        />
                    )
                },
            },
        },
        {
            name: "outlet",
            label: "Veículos",
            options: {
                filter: false,
                sort: false,
                // customHeadRender: ({ index, label, ...column }, sortColumn, tableMeta) => (
                //     <TableCell onClick={() => sortColumn(index)} key={index} className="white_space">
                //         {label}
                //         <TableSortLabel active={tableMeta.name === column.name} direction={tableMeta.direction || "asc"} />
                //     </TableCell>
                // ),
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        padding: "0px 0.6rem 0px 0.6rem",
                    },
                }),
                customBodyRender: (value, tableMeta) => {
                    return (
                        <TextField
                            title={value}
                            type="text"
                            defaultValue={value}
                            inputProps={{ style: { textAlign: "center" }, readOnly: true }}
                            // onChange={event => console.log(event.target.value, tableMeta)}/>
                            // onChange={event => console.log(`modificado ID ${tableMeta.rowData[0]}, coluna ${tableMeta.columnData.name}, valor "${event.target.value}"`)}/>
                            // onChange={(event) => updateValuesList.push(`${tableMeta.rowData[0]}, ${tableMeta.columnData.name}, ${event.target.value}`)}
                        />
                    )
                },
            },
        },
        {
            name: "investor",
            label: "Tipo de Investidor",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta) => {
                    return (
                        <TextField
                            type="text"
                            defaultValue={value}
                            InputProps={{ readOnly: false }}
                            inputProps={{ style: { textAlign: "center" }, readOnly: false }}
                            // onChange={event => console.log(event.target.value, tableMeta)}/>
                            // onChange={event => console.log(`modificado ID ${tableMeta.rowData[0]}, coluna ${tableMeta.columnData.name}, valor "${event.target.value}"`)}/>
                            onChange={(event) => updateValuesList.push(`${tableMeta.rowData[0]}, ${tableMeta.columnData.name}, ${event.target.value}`)}
                        />
                    )
                },
            },
        },
        {
            name: "recommended",
            label: "Recomendado (%)",
            options: {
                filter: false,
                sort: false,
                // customHeadRender: ({ index, label, ...column }, sortColumn, tableMeta) => (
                //     <TableCell onClick={() => sortColumn(index)} key={index} className="white_space">
                //         {label}
                //         <TableSortLabel active={tableMeta.name === column.name} direction={tableMeta.direction || "asc"} />
                //     </TableCell>
                // ),
                customBodyRender: (value, tableMeta) => {
                    return (
                        <TextField
                            type="text"
                            defaultValue={value}
                            InputProps={{ readOnly: false }}
                            inputProps={{ style: { textAlign: "center" }, readOnly: false }}
                            // onChange={event => console.log(event.target.value, tableMeta)}/>
                            // onChange={event => console.log(`modificado ID ${tableMeta.rowData[0]}, coluna ${tableMeta.columnData.name}, valor "${event.target.value}"`)}/>
                            onChange={(event) => updateValuesList.push(`${tableMeta.rowData[0]}, ${tableMeta.columnData.name}, ${event.target.value}`)}
                        />
                    )
                },
            },
        },
        {
            name: "maximum",
            label: "Máximo (%)",
            options: {
                filter: false,
                sort: false,
                // customHeadRender: ({ index, label, ...column }, sortColumn, tableMeta) => (
                //     <TableCell onClick={() => sortColumn(index)} key={index} className="white_space">
                //         {label}
                //         <TableSortLabel active={tableMeta.name === column.name} direction={tableMeta.direction || "asc"} />
                //     </TableCell>
                // ),
                customBodyRender: (value, tableMeta) => {
                    return (
                        <TextField
                            type="text"
                            defaultValue={value}
                            InputProps={{ readOnly: false }}
                            inputProps={{ style: { textAlign: "center" }, readOnly: false }}
                            // onChange={event => console.log(event.target.value, tableMeta)}/>
                            // onChange={event => console.log(`modificado ID ${tableMeta.rowData[0]}, coluna ${tableMeta.columnData.name}, valor "${event.target.value}"`)}/>
                            onChange={(event) => updateValuesList.push(`${tableMeta.rowData[0]}, ${tableMeta.columnData.name}, ${event.target.value}`)}
                        />
                    )
                },
            },
        },
        {
            name: "Editar",
            options: {
                filter: true,
                sort: false,
                empty: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        // padding: "0px 0.6rem 0px 0.6rem",
                        alignItems: 'center'
                    },
                }),
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <div style={{marginLeft: '1rem', marginTop: '0.5rem', marginBottom: '0.5rem', marginRight: '1rem'}}>

                        <button onClick={() => handleUpdate(updateValuesList)} className="tacticalEditCheck">
                            Salvar alterações
                        </button>
                        </div>
                    )
                },
            },
        },
    ]

    const AddButton = () => (
        <Tooltip disableFocusListener title="Copiar para a área de transferência">
            {/* <IconButton onClick={copyToClipboard}> */}
            <IconButton onClick={console.log("copy")}>
                <ContentCopyIcon />
            </IconButton>
        </Tooltip>
    )

    const options = {
        filterType: "multiselect",
        search: false,
        filter: false,
        toolbar: false,
        download: false,
        print: false,
        pagination: false,
        responsive: "vertical",
        tableBodyHeight: "77vh",
        selectableRowsHideCheckboxes: true,
        viewColumns: false,
        toolButton: {
            justifyContent: "center",
        },
        onCellEdit: (updatedCell, rowIndex, columnIndex) => {
            // Atualize a lista inicial com os novos dados
            const updatedData = [...data]
            updatedData[rowIndex][columnIndex] = updatedCell.value
            setData(updatedData)
        },
        textLabels: {
            body: {
                noMatch: loading ? <ArrowLoader /> : "Não há operações não executadas.",
                toolTip: "Ordenar",
                columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
            },
            pagination: {
                next: "Próxima",
                previous: "Anterior",
                rowsPerPage: "Linhas por página:",
                displayRows: "de",
            },
            toolbar: {
                search: "Pesquisar",
                downloadCsv: "Baixar Planilha",
                print: "Imprimir",
                viewColumns: "Ver colunas",
                filterTable: "Filtrar",
            },
            // filter: {
            //     all: "Todos",
            //     title: "Filtros",
            //     reset: "Limpar",
            // },
            viewColumns: {
                title: "Mostrar Colunas",
                titleAria: "Mostrar/Ocultar colunas",
            },
            selectedRows: {
                text: "linha(s) selecionadas",
                delete: "Excluir",
                deleteAria: "Excluir linhas Selecionadas",
            },
        },
        downloadOptions: {
            filename: "operacoes_nao_executadas.csv",
            separator: ";",
        },
        onDownload: () => {
            // assetnotExecutedExport();
            return false
        },
        sortOrder: {
            name: "broker_name",
            direction: "asc",
        },
        // customToolbar: AddButton,
        setTableProps: () => {
            return {
                padding: "none",
                size: "small",
            }
        },
        // rowsPerPage: rowsPerPage,
        // rowsPerPageOptions: [30, 50, 100],
    }

    const getMuiTheme = () =>
        createTheme({
            components: {
                MuiTableCell: {
                    styleOverrides: {
                        root: {
                            justifyContent: "center",
                            whiteSpace: "nowrap",
                            textAlign: "center",
                            color: "#D4AC00",
                            fontSize :'1.2rem',
                        },
                    },
                },
                MUIDataTableHeadCell: {
                    styleOverrides: {
                        contentWrapper: {
                            justifyContent: "center",
                            whiteSpace: "nowrap",
                            color: "#D4AC00",
                        },
                        toolButton: {
                            margin: "0px",
                        },
                        root: {
                            paddingBottom: "1rem",
                        },
                    },
                },
                MUIDataTableBodyRow: {
                    styleOverrides: {
                        root: {
                                backgroundColor: "#F5F5F5",
                                borderBottom: "0.5rem solid white",
                            
                        },
                    },
                },
                MuiPaper: {
                    styleOverrides: {
                        root: {
                            boxShadow: "none",
                            borderRadius: "0",
                        },
                    },
                },
            },
        })

    return (
        <div className="containerModalDashDetails">
            <div className="editBrancheBoxHeaderDash">
                <p style={{ marginLeft: "1rem", color: "#D4AC00" }}>Configuração de Alocações Táticas</p>
                <div style={{ display: "grid", gridTemplateColumns: "auto auto", alignItems: "end" }}>
                    <p style={{ marginRight: "1rem" }}>{user.external_id + " - " + user.full_name} </p>
                    <button
                        onClick={() => {
                            // clear()
                            setShow(false)
                        }}
                        className="modalCloseButtonDashboard"
                        style={{ marginRight: "12px", height: "36px", width: "36px" }}
                    >
                        <AiFillCloseCircle />
                    </button>
                </div>
            </div>
            <div className="tactical-alocations-control">
                <div style={{margin: '1rem'}}>
                    <ThemeProvider theme={getMuiTheme()}>
                        <MUIDataTable data={tableData} columns={columns} options={options}></MUIDataTable>
                    </ThemeProvider>
                </div>
            </div>
        </div>
    )
}
