import React, { useEffect, useState, useRef,useReducer} from "react";
import { Checkbox, FormControlLabel, FormGroup, FormLabel, Radio, RadioGroup } from "@mui/material";
/*Contexts*/
import { useAuth } from "../../../../contexts/useAuth";
import { useModal } from "../../../../contexts/useModal";
/*Services*/
import { api } from "../../../../services/api";
/*Utils*/
import { titleize } from "../../../../utils/formatNumberAndText/formatTextUpperFirstLetter";
import { updateABCStatus } from "../../../../services/asyncFunctions"

import './abcStatusFilter.css'

export function RefusalReason(props) {
  console.log('Recusa', props);
  const { tokenKey, user } = useAuth();
  const { show, setShow, type, setType, edited, setEdited } = useModal();
  const [loading, setLoading] = useState(true);
  const [isTrue, setIsTrue] = useState(false);
  const [selectedReason, setSelectedReason] = useState("");
  const [refusalReasons, setRefusalResasons] = useState([
    {label : 'pendente', color: "#FAF5C7"},
    {label : 'abordado',color: "#7893DB"},
    {label : 'solicitado',color: "#F0B357"},
    {label : 'boletado',color: "#CBF0BE"},
    {label : 'cancelado',color: "#E2BBE3"},
    {label : 'recusado',color: "#E87F7F"},
    {label : 'não aderente',color: "#000000"},
    {label : 'outros',color: "#9013FE" },
  ]);
  const [cont, setCont] = useState(0);

//   const [operationId, setOperationId] = useState(null);
//   const [categoryInfos, setCategoryInfos] = useState(null);
//   const [link, setLink] = useState({
//     boletar: "/",
//     acompanhamento: "/"
//   });

// const props.prop.listFilterStatusProducts.current[props.prop.data] = props.prop.listFilterStatusProducts.current[props.prop.data]


const [, forceUpdate] = useReducer(x => x + 1, 0);

  return (
    <div className="containerStatusAbc">
      <div className="containerStatusConfirm">
        <div className="containerStatusName">
        </div>
        <div className="statusConfirm">
          <p className="h2status">Motivo da Recusa</p>
          <button className="btn btn-primary" style={{width: "50px"}} onClick={()=> {
            // console.log(filtersStatus, filtersStatus.filter((x) => x.visible == true).map((x) => x.label))
            props.prop.handleRefusalReason(selectedReason)     
            setType({ name: "menu", client: props.prop.client, product: props.prop.product });  
            // setType({ prop: props.prop })
            // setShow(false);
            }}>OK</button>
        </div>
      </div>
      <div className="containerStatusList">
          <div className="sliceStatusList">
          </div>
          <div className="containerCheckbox">
            <FormGroup className="statusList">
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="pendente"
                    name="radio-buttons-group"
                  >
                  {refusalReasons.map((e,i)=> {
                    return(
                      <FormControlLabel value={e.label} control={<Radio />} label={e.label} onClick={(e) => {
                        setSelectedReason(e.target.value)
                      }
                      }/>
                    )
                  })}
                  </RadioGroup>
              </FormGroup>
          </div>
      </div>
    </div>
  );
  
//   function getOperationId() {
//     const prod_name = props.prop.product.name;
//     const operation_id = props.prop.client[`${prod_name}_operation_id`]
//     setOperationId(operation_id)
//   }

//   function getCategory() {
//     const prod_name = props.prop.product.name;
//     const cat_id = props.prop.client[`${prod_name}_category_id`]
//     const cat =  props.prop.product.categories.filter((x) => x.id == cat_id)
//     setCategoryInfos(cat[0]);
//   }

//   function getLink() {
//     const prod_type = props.prop.product.type.id;
//     if (prod_type == 1 || prod_type == 2) {
//       // ESTRUTURADAS - 1 COLLAR WISIR - 2 SWING TRADE
//       setLink({
//         boletar: '#/books',
//         acompanhamento: user.type < 3 ? '#/clients' : '#/clientesMesa'
//       })
//     } else if (prod_type == 3) {
//       // OFERTA PÚBLICA
//       setLink({
//         boletar: '#/fiis',
//         acompanhamento: user.type < 3 ? '#/clients' : '#/clientesMesa'
//       })
//     } 
//   }

//   async function updateStatus(status_id) {
//     setLoading(true);  
//       try {
//           const res = await updateABCStatus(tokenKey, operationId, status_id);
//           console.log(res?.data?.data);
//       } catch (error) {
//           setLoading(false);
//           setType({ name: 'alertMsg', type: 'information', value: { message: error?.response?.data?.message == undefined ? 'Erro interno ao carregar os dados' : error?.response?.data?.message } })
//           setShow(true);
//       }
//       setLoading(false);
//   }

//   useEffect(() => {
//     getOperationId()  
//     getCategory()
//     getLink()
//   }, [show]);

  // return (
  //   <div className="containerModalAbcDetails">
  //     <div className="clientControlHeader">
  //       <div className="editBrancheBoxHeader">
  //         <div className="containerProductName">
  //           <p>Ações Disponíveis</p>
  //         </div>
  //         <button
  //           onClick={() => setShow(false)}
  //           className="modalCloseButton"
  //         >
  //           X
  //         </button>
  //       </div>
  //     </div>
  //     <div className="containerClientMenuAbcActions">
  //       <div className="containerClientMenuOptionsAbcActions">
  //         <div className="containerNameAbcActions">
  //           <div className="clientInfoAbcActions">{props?.prop?.client?.nome} - {props?.prop?.client?.codigo}</div>
  //         </div>
  //         <div className="containerGeneralOptionsAbcActions">
  //           <div className="containerOptionsTitleAbcActions">
  //             <div className="optionsTitleAbcActions">{props?.prop?.product?.description}</div>
  //           </div>
  //           <div className="containerOptionsAbcActions">
  //             <button className="buttonAbcActions">Boletar</button>
  //             <button className="buttonAbcActions">Acompanhamento</button>
  //           </div>
  //         </div>
  //         <div></div>
  //         <div className="containerGeneralChangeStatusAbcActions">
  //           <div className="containerChangeStatusTitleAbcActions">
  //             <div className="ChangeStatusTitleAbcActions">Alterar Status</div>
  //           </div>
  //           <div className="containerChangeStatusAbcActions">
  //             <button className="buttonAbordadoAbcActions">Abordado</button>
  //             <button className="buttonRecusadoAbcActions">Recusado</button>
  //           </div>
         
  //         </div>
  //       </div>
  //       <div className="divisionNameProductSubcategory"></div>
  //       <div className="containerClientMenuSubcategoryAbcActions">
  //         <div className="headerSubcategoryAbcActions">
  //           <div className="headerSubcategoryTitleAbcActions">Categoria fictícia para props.prop.listFilterStatusProducts.current[props.prop.data]</div>
  //         </div>
  //         <div className="containerSubcategoryDescriptionAbcActions">

  //         </div>
  //         <div className="headerSubcategoryAbcActions">
  //           <div className="headerSubcategoryTitleAbcActions">Categoria fictícia para props.prop.listFilterStatusProducts.current[props.prop.data]</div>
  //         </div>
  //       </div>
  //     </div>
  //   </div>
  // );
}
