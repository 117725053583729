/**
 * ANIMACAO DE CARREGAMENTO ENTRE TABELAS
 * DESENVOLVIVDO POR: RAFAEL CEZARIO
 * DATA: 25/05/2021
**/

import React from "react"
import './loaderWithoutTextWhite.css'

export function ArrowLoaderWhiteWOText(props) {
  return (
    <div className='containerWhiteWoTxt'>
    <span className={props.gold ? 'loadingWhiteWOTxt' : 'loadingWhiteWOTxt'}></span>
    </div>
  )
}