import React from 'react';
//components
import { MyReservationOrdersTable } from './myReservations.table';
import { MyReservationsMUI } from './myReservationsMUI.table';
import Modal from '../../../../components/modal/modal'
import { ConfirmationBox } from '../../../../components/confirmationBox/confirmationBox'
import { MyReservationDetails } from './myReservations.details';
import { MyReservationResponse } from './myReservations.response';
import { ImportValidation } from './myReservations.imports'
import { MyReservationHistory } from './myReservations.history';
//styles
import './styles/myReservations.css'
/*Contexts*/
import { useModal } from '../../../../contexts/useModal';


export function MyReservations() {

    const { show, setShow, type, setType } = useModal()

    return (
        <div style={{ paddingTop: '2rem', paddingLeft: '2rem', paddingRight: '2rem' }}>
            <Modal>
                {
                    type.name === 'showReservationHistory' ?
                    (
                        <MyReservationHistory prop={type} />
                    ) :
                    type.name === 'alertMsg' ?
                        (
                            <ConfirmationBox msgData={type} />
                        ) :
                        type.name === 'showMyReservationsDetails' ?
                        (
                            <MyReservationDetails data={type.data}/>
                        ) :
                        type.name === 'showMyReservationsResponse' ?
                        (
                            <MyReservationResponse/>
                        ) :
                        type.name === 'showImportConnect' ?
                        (
                            <ImportValidation file={'connect'} value={type.value}/> 
                        ) :
                        (<></>)
                }
            </Modal>
            <MyReservationsMUI />
        </div>
    )
}
