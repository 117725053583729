// Description: Lista de feriados nacionais do Brasil para os anos de 2022 a 2078.
const feriados = [
    "2022-01-01",
    "2022-02-28",
    "2022-03-01",
    "2022-04-15",
    "2022-04-21",
    "2022-05-01",
    "2022-06-16",
    "2022-09-07",
    "2022-10-12",
    "2022-11-02",
    "2022-11-15",
    "2022-12-25",
    "2023-01-01",
    "2023-02-20",
    "2023-02-21",
    "2023-04-07",
    "2023-04-21",
    "2023-05-01",
    "2023-06-08",
    "2023-09-07",
    "2023-10-12",
    "2023-11-02",
    "2023-11-15",
    "2023-12-25",
    "2024-01-01",
    "2024-02-12",
    "2024-02-13",
    "2024-03-29",
    "2024-04-21",
    "2024-05-01",
    "2024-05-30",
    "2024-09-07",
    "2024-10-12",
    "2024-11-02",
    "2024-11-15",
    "2024-12-25",
    "2025-01-01",
    "2025-03-03",
    "2025-03-04",
    "2025-04-18",
    "2025-04-21",
    "2025-05-01",
    "2025-06-19",
    "2025-09-07",
    "2025-10-12",
    "2025-11-02",
    "2025-11-15",
    "2025-12-25",
    "2026-01-01",
    "2026-02-16",
    "2026-02-17",
    "2026-04-03",
    "2026-04-21",
    "2026-05-01",
    "2026-06-04",
    "2026-09-07",
    "2026-10-12",
    "2026-11-02",
    "2026-11-15",
    "2026-12-25",
    "2027-01-01",
    "2027-02-08",
    "2027-02-09",
    "2027-03-26",
    "2027-04-21",
    "2027-05-01",
    "2027-05-27",
    "2027-09-07",
    "2027-10-12",
    "2027-11-02",
    "2027-11-15",
    "2027-12-25",
    "2028-01-01",
    "2028-02-28",
    "2028-02-29",
    "2028-04-14",
    "2028-04-21",
    "2028-05-01",
    "2028-06-15",
    "2028-09-07",
    "2028-10-12",
    "2028-11-02",
    "2028-11-15",
    "2028-12-25",
    "2029-01-01",
    "2029-02-12",
    "2029-02-13",
    "2029-03-30",
    "2029-04-21",
    "2029-05-01",
    "2029-05-31",
    "2029-09-07",
    "2029-10-12",
    "2029-11-02",
    "2029-11-15",
    "2029-12-25",
    "2030-01-01",
    "2030-03-04",
    "2030-03-05",
    "2030-04-19",
    "2030-04-21",
    "2030-05-01",
    "2030-06-20",
    "2030-09-07",
    "2030-10-12",
    "2030-11-02",
    "2030-11-15",
    "2030-12-25",
    "2031-01-01",
    "2031-02-24",
    "2031-02-25",
    "2031-04-11",
    "2031-04-21",
    "2031-05-01",
    "2031-06-12",
    "2031-09-07",
    "2031-10-12",
    "2031-11-02",
    "2031-11-15",
    "2031-12-25",
    "2032-01-01",
    "2032-02-09",
    "2032-02-10",
    "2032-03-26",
    "2032-04-21",
    "2032-05-01",
    "2032-05-27",
    "2032-09-07",
    "2032-10-12",
    "2032-11-02",
    "2032-11-15",
    "2032-12-25",
    "2033-01-01",
    "2033-02-28",
    "2033-03-01",
    "2033-04-15",
    "2033-04-21",
    "2033-05-01",
    "2033-06-16",
    "2033-09-07",
    "2033-10-12",
    "2033-11-02",
    "2033-11-15",
    "2033-12-25",
    "2034-01-01",
    "2034-02-20",
    "2034-02-21",
    "2034-04-07",
    "2034-04-21",
    "2034-05-01",
    "2034-06-08",
    "2034-09-07",
    "2034-10-12",
    "2034-11-02",
    "2034-11-15",
    "2034-12-25",
    "2035-01-01",
    "2035-02-05",
    "2035-02-06",
    "2035-03-23",
    "2035-04-21",
    "2035-05-01",
    "2035-05-24",
    "2035-09-07",
    "2035-10-12",
    "2035-11-02",
    "2035-11-15",
    "2035-12-25",
    "2036-01-01",
    "2036-02-25",
    "2036-02-26",
    "2036-04-11",
    "2036-04-21",
    "2036-05-01",
    "2036-06-12",
    "2036-09-07",
    "2036-10-12",
    "2036-11-02",
    "2036-11-15",
    "2036-12-25",
    "2037-01-01",
    "2037-02-16",
    "2037-02-17",
    "2037-04-03",
    "2037-04-21",
    "2037-05-01",
    "2037-06-04",
    "2037-09-07",
    "2037-10-12",
    "2037-11-02",
    "2037-11-15",
    "2037-12-25",
    "2038-01-01",
    "2038-03-08",
    "2038-03-09",
    "2038-04-21",
    "2038-04-23",
    "2038-05-01",
    "2038-06-24",
    "2038-09-07",
    "2038-10-12",
    "2038-11-02",
    "2038-11-15",
    "2038-12-25",
    "2039-01-01",
    "2039-02-21",
    "2039-02-22",
    "2039-04-08",
    "2039-04-21",
    "2039-05-01",
    "2039-06-09",
    "2039-09-07",
    "2039-10-12",
    "2039-11-02",
    "2039-11-15",
    "2039-12-25",
    "2040-01-01",
    "2040-02-13",
    "2040-02-14",
    "2040-03-30",
    "2040-04-21",
    "2040-05-01",
    "2040-05-31",
    "2040-09-07",
    "2040-10-12",
    "2040-11-02",
    "2040-11-15",
    "2040-12-25",
    "2041-01-01",
    "2041-03-04",
    "2041-03-05",
    "2041-04-19",
    "2041-04-21",
    "2041-05-01",
    "2041-06-20",
    "2041-09-07",
    "2041-10-12",
    "2041-11-02",
    "2041-11-15",
    "2041-12-25",
    "2042-01-01",
    "2042-02-17",
    "2042-02-18",
    "2042-04-04",
    "2042-04-21",
    "2042-05-01",
    "2042-06-05",
    "2042-09-07",
    "2042-10-12",
    "2042-11-02",
    "2042-11-15",
    "2042-12-25",
    "2043-01-01",
    "2043-02-09",
    "2043-02-10",
    "2043-03-27",
    "2043-04-21",
    "2043-05-01",
    "2043-05-28",
    "2043-09-07",
    "2043-10-12",
    "2043-11-02",
    "2043-11-15",
    "2043-12-25",
    "2044-01-01",
    "2044-02-29",
    "2044-03-01",
    "2044-04-15",
    "2044-04-21",
    "2044-05-01",
    "2044-06-16",
    "2044-09-07",
    "2044-10-12",
    "2044-11-02",
    "2044-11-15",
    "2044-12-25",
    "2045-01-01",
    "2045-02-20",
    "2045-02-21",
    "2045-04-07",
    "2045-04-21",
    "2045-05-01",
    "2045-06-08",
    "2045-09-07",
    "2045-10-12",
    "2045-11-02",
    "2045-11-15",
    "2045-12-25",
    "2046-01-01",
    "2046-02-05",
    "2046-02-06",
    "2046-03-23",
    "2046-04-21",
    "2046-05-01",
    "2046-05-24",
    "2046-09-07",
    "2046-10-12",
    "2046-11-02",
    "2046-11-15",
    "2046-12-25",
    "2047-01-01",
    "2047-02-25",
    "2047-02-26",
    "2047-04-12",
    "2047-04-21",
    "2047-05-01",
    "2047-06-13",
    "2047-09-07",
    "2047-10-12",
    "2047-11-02",
    "2047-11-15",
    "2047-12-25",
    "2048-01-01",
    "2048-02-17",
    "2048-02-18",
    "2048-04-03",
    "2048-04-21",
    "2048-05-01",
    "2048-06-04",
    "2048-09-07",
    "2048-10-12",
    "2048-11-02",
    "2048-11-15",
    "2048-12-25",
    "2049-01-01",
    "2049-03-01",
    "2049-03-02",
    "2049-04-16",
    "2049-04-21",
    "2049-05-01",
    "2049-06-17",
    "2049-09-07",
    "2049-10-12",
    "2049-11-02",
    "2049-11-15",
    "2049-12-25",
    "2050-01-01",
    "2050-02-21",
    "2050-02-22",
    "2050-04-08",
    "2050-04-21",
    "2050-05-01",
    "2050-06-09",
    "2050-09-07",
    "2050-10-12",
    "2050-11-02",
    "2050-11-15",
    "2050-12-25",
    "2051-01-01",
    "2051-02-13",
    "2051-02-14",
    "2051-03-31",
    "2051-04-21",
    "2051-05-01",
    "2051-06-01",
    "2051-09-07",
    "2051-10-12",
    "2051-11-02",
    "2051-11-15",
    "2051-12-25",
    "2052-01-01",
    "2052-03-04",
    "2052-03-05",
    "2052-04-19",
    "2052-04-21",
    "2052-05-01",
    "2052-06-20",
    "2052-09-07",
    "2052-10-12",
    "2052-11-02",
    "2052-11-15",
    "2052-12-25",
    "2053-01-01",
    "2053-02-17",
    "2053-02-18",
    "2053-04-04",
    "2053-04-21",
    "2053-05-01",
    "2053-06-05",
    "2053-09-07",
    "2053-10-12",
    "2053-11-02",
    "2053-11-15",
    "2053-12-25",
    "2054-01-01",
    "2054-02-09",
    "2054-02-10",
    "2054-03-27",
    "2054-04-21",
    "2054-05-01",
    "2054-05-28",
    "2054-09-07",
    "2054-10-12",
    "2054-11-02",
    "2054-11-15",
    "2054-12-25",
    "2055-01-01",
    "2055-03-01",
    "2055-03-02",
    "2055-04-16",
    "2055-04-21",
    "2055-05-01",
    "2055-06-17",
    "2055-09-07",
    "2055-10-12",
    "2055-11-02",
    "2055-11-15",
    "2055-12-25",
    "2056-01-01",
    "2056-02-14",
    "2056-02-15",
    "2056-03-31",
    "2056-04-21",
    "2056-05-01",
    "2056-06-01",
    "2056-09-07",
    "2056-10-12",
    "2056-11-02",
    "2056-11-15",
    "2056-12-25",
    "2057-01-01",
    "2057-03-05",
    "2057-03-06",
    "2057-04-20",
    "2057-04-21",
    "2057-05-01",
    "2057-06-21",
    "2057-09-07",
    "2057-10-12",
    "2057-11-02",
    "2057-11-15",
    "2057-12-25",
    "2058-01-01",
    "2058-02-25",
    "2058-02-26",
    "2058-04-12",
    "2058-04-21",
    "2058-05-01",
    "2058-06-13",
    "2058-09-07",
    "2058-10-12",
    "2058-11-02",
    "2058-11-15",
    "2058-12-25",
    "2059-01-01",
    "2059-02-10",
    "2059-02-11",
    "2059-03-28",
    "2059-04-21",
    "2059-05-01",
    "2059-05-29",
    "2059-09-07",
    "2059-10-12",
    "2059-11-02",
    "2059-11-15",
    "2059-12-25",
    "2060-01-01",
    "2060-03-01",
    "2060-03-02",
    "2060-04-16",
    "2060-04-21",
    "2060-05-01",
    "2060-06-17",
    "2060-09-07",
    "2060-10-12",
    "2060-11-02",
    "2060-11-15",
    "2060-12-25",
    "2061-01-01",
    "2061-02-21",
    "2061-02-22",
    "2061-04-08",
    "2061-04-21",
    "2061-05-01",
    "2061-06-09",
    "2061-09-07",
    "2061-10-12",
    "2061-11-02",
    "2061-11-15",
    "2061-12-25",
    "2062-01-01",
    "2062-02-06",
    "2062-02-07",
    "2062-03-24",
    "2062-04-21",
    "2062-05-01",
    "2062-05-25",
    "2062-09-07",
    "2062-10-12",
    "2062-11-02",
    "2062-11-15",
    "2062-12-25",
    "2063-01-01",
    "2063-02-26",
    "2063-02-27",
    "2063-04-13",
    "2063-04-21",
    "2063-05-01",
    "2063-06-14",
    "2063-09-07",
    "2063-10-12",
    "2063-11-02",
    "2063-11-15",
    "2063-12-25",
    "2064-01-01",
    "2064-02-18",
    "2064-02-19",
    "2064-04-04",
    "2064-04-21",
    "2064-05-01",
    "2064-06-05",
    "2064-09-07",
    "2064-10-12",
    "2064-11-02",
    "2064-11-15",
    "2064-12-25",
    "2065-01-01",
    "2065-02-09",
    "2065-02-10",
    "2065-03-27",
    "2065-04-21",
    "2065-05-01",
    "2065-05-28",
    "2065-09-07",
    "2065-10-12",
    "2065-11-02",
    "2065-11-15",
    "2065-12-25",
    "2066-01-01",
    "2066-02-22",
    "2066-02-23",
    "2066-04-09",
    "2066-04-21",
    "2066-05-01",
    "2066-06-10",
    "2066-09-07",
    "2066-10-12",
    "2066-11-02",
    "2066-11-15",
    "2066-12-25",
    "2067-01-01",
    "2067-02-14",
    "2067-02-15",
    "2067-04-01",
    "2067-04-21",
    "2067-05-01",
    "2067-06-02",
    "2067-09-07",
    "2067-10-12",
    "2067-11-02",
    "2067-11-15",
    "2067-12-25",
    "2068-01-01",
    "2068-03-05",
    "2068-03-06",
    "2068-04-20",
    "2068-04-21",
    "2068-05-01",
    "2068-06-21",
    "2068-09-07",
    "2068-10-12",
    "2068-11-02",
    "2068-11-15",
    "2068-12-25",
    "2069-01-01",
    "2069-02-25",
    "2069-02-26",
    "2069-04-12",
    "2069-04-21",
    "2069-05-01",
    "2069-06-13",
    "2069-09-07",
    "2069-10-12",
    "2069-11-02",
    "2069-11-15",
    "2069-12-25",
    "2070-01-01",
    "2070-02-10",
    "2070-02-11",
    "2070-03-28",
    "2070-04-21",
    "2070-05-01",
    "2070-05-29",
    "2070-09-07",
    "2070-10-12",
    "2070-11-02",
    "2070-11-15",
    "2070-12-25",
    "2071-01-01",
    "2071-03-02",
    "2071-03-03",
    "2071-04-17",
    "2071-04-21",
    "2071-05-01",
    "2071-06-18",
    "2071-09-07",
    "2071-10-12",
    "2071-11-02",
    "2071-11-15",
    "2071-12-25",
    "2072-01-01",
    "2072-02-22",
    "2072-02-23",
    "2072-04-08",
    "2072-04-21",
    "2072-05-01",
    "2072-06-09",
    "2072-09-07",
    "2072-10-12",
    "2072-11-02",
    "2072-11-15",
    "2072-12-25",
    "2073-01-01",
    "2073-02-06",
    "2073-02-07",
    "2073-03-24",
    "2073-04-21",
    "2073-05-01",
    "2073-05-25",
    "2073-09-07",
    "2073-10-12",
    "2073-11-02",
    "2073-11-15",
    "2073-12-25",
    "2074-01-01",
    "2074-02-26",
    "2074-02-27",
    "2074-04-13",
    "2074-04-21",
    "2074-05-01",
    "2074-06-14",
    "2074-09-07",
    "2074-10-12",
    "2074-11-02",
    "2074-11-15",
    "2074-12-25",
    "2075-01-01",
    "2075-02-18",
    "2075-02-19",
    "2075-04-05",
    "2075-04-21",
    "2075-05-01",
    "2075-06-06",
    "2075-09-07",
    "2075-10-12",
    "2075-11-02",
    "2075-11-15",
    "2075-12-25",
    "2076-01-01",
    "2076-03-02",
    "2076-03-03",
    "2076-04-17",
    "2076-04-21",
    "2076-05-01",
    "2076-06-18",
    "2076-09-07",
    "2076-10-12",
    "2076-11-02",
    "2076-11-15",
    "2076-12-25",
    "2077-01-01",
    "2077-02-22",
    "2077-02-23",
    "2077-04-09",
    "2077-04-21",
    "2077-05-01",
    "2077-06-10",
    "2077-09-07",
    "2077-10-12",
    "2077-11-02",
    "2077-11-15",
    "2077-12-25",
    "2078-01-01",
    "2078-02-14",
    "2078-02-15",
    "2078-04-01",
    "2078-04-21",
    "2078-05-01",
    "2078-06-02",
    "2078-09-07",
    "2078-10-12",
    "2078-11-02",
    "2078-11-15",
    "2078-12-25",
]

export default feriados;