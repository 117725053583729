import React, { useEffect, useState } from 'react'
import '../styles/modalEditLongShort.css'
import { useAuth } from "../../../../contexts/useAuth";
import { api } from "../../../../services/api";
import { useModal } from '../../../../contexts/useModal'
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import { styleClientsInput } from '../../../../components/styles/autocompletField';
import { toBRL } from "../../../../utils/formatNumberAndText/toBRLCurrency";

export function ModalEditLongShort(props) {
    console.log('id', props.id)
    const [loading, setLoading] = useState(true);
    const { tokenKey, user } = useAuth();
    const { type, show, setShow, setType, edited, setEdited } = useModal()
    const classes = styleClientsInput()

    useEffect(() => {

    }, [show])

    async function handleCostSubmit(){

        // console.log({
        //     "tipo": tipo,
        //     "cost_corr": parseInt(corretagem),
        //     "cost_btc": parseInt(btc),
        //     'id': parseInt(infos[33])
        //   })
        //   return
        try {

            // const postOffersOrders = await api.put(`/api/v1/longShort/updateLongShortOperationCost`, 
            // {
            //   tipo: tipo,
            //   cost_corr: parseFloat(corretagem),
            //   cost_btc: parseFloat(btc),
            //   id: parseInt(infos[33])
            // }, {
            //     headers: {
            //         authorization: `Bearer ${tokenKey}`
            //     }
            // })
            // setType({ name: 'alertMsg', type: 'information', value: {message : 'Custos da operação atualizados com sucesso!'}})
            // setShow(true)
            // setCorretagem(0)
            // setBtc(0)
            // setTipo('')
            // setInfos()
            // setEdited(true)
        } catch (error) {
            // console.log(error?.response)
            // setType({ name: 'alertMsg', type: 'information', value: { message: error?.response?.data?.message === undefined ? 'Erro ao atualizar custos' : error.response.data.message } })
            // setShow(true)
            // setLoading(false)
        }
    }

    return (
       <div className='LSEditContainer'>
            <div className='LSEditHeader'>
                <p>Editar Long Short</p>
                <button onClick={() => setShow(false)} className='modalCloseButton'>x</button>
            </div>
            <div className='LSEditBody'>
                <div className='LSEditLine'>
                    <div className='LSEditLineItem'>
                        <p>Data Operação</p>
                    </div>
                    <div className='LSEditLineItem'>
                        <p>Research</p>
                    </div>
                </div>
                <div className='LSEditLine'>
                    <div className='LSEditLineItem'>
                        <p>Ativo Long</p>
                    </div>
                    <div className='LSEditLineItem'>
                        <p>Ativo Short</p>
                    </div>
                </div>
                <div className='LSEditLine'>
                    <div className='LSEditLineItem'>
                        <p>Ratio Atual</p>
                    </div>
                    <div className='LSEditLineItem'>
                        <p>Ratio Limite Entrada</p>
                    </div>
                </div>
                <div className='LSEditLine'>
                    <div className='LSEditLineItem'>
                        <p>Ratio Gain</p>
                    </div>
                    <div className='LSEditLineItem'>
                        <p>Ratio Loss</p>
                    </div>
                </div>
                <div className='LSEditLine'>
                    <div className='LSEditLineItem'>
                        <p>Limite L/S</p>
                    </div>
                </div>
            </div>
       </div> 
        // <div className='myReservationDetails'>
        //     {
        //         <>
        //             <div className='myReservationsHeader'>
        //                 <p>Editar custos de{props?.prop?.tipo == 'in' ? ' montagem' : ' desmontagem'}</p>
        //                 <button onClick={() => setShow(false)} className='modalCloseButton'>x</button>
        //             </div>
        //             <div className='myReservationEmptyBody'>
        //                 <div className="itemBodyOperateProductAbc fieldsOperateAbc">
        //                     <CurrencyTextField
        //                         label="Corretagem"
        //                         variant="outlined"
        //                         value={corretagem}
        //                         currencySymbol="R$"
        //                         className={classes.styleForClients}
        //                         outputFormat="number"
        //                         decimalCharacter=","
        //                         digitGroupSeparator="."
        //                         onChange={(event, value) => {
        //                             if (value === '' || value === 0) {
        //                                 setCorretagem(0)
        //                             } else {
        //                                 setCorretagem(value);
        //                             }
        //                         }}
        //                     />
        //                 </div>

        //                 <div className="itemBodyOperateProductAbc fieldsOperateAbc">
        //                     <CurrencyTextField
        //                         label="Btc"
        //                         variant="outlined"
        //                         value={btc}
        //                         currencySymbol="R$"
        //                         className={classes.styleForClients}
        //                         outputFormat="number"
        //                         decimalCharacter=","
        //                         digitGroupSeparator="."
        //                         onChange={(event, value) => {
        //                             if (value === '' || value === 0) {
        //                                 setBtc(0)
        //                             } else {
        //                                 setBtc(value);
        //                             }
        //                         }}
        //                     />
        //                 </div>

        //                 <div className="itemBodyOperateProductAbc fieldsOperateAbc">
        //                     Total: {toBRL(corretagem+btc)}
        //                 </div>

        //                 <div className="itemBodyOperateProductAbc fieldsOperateAbc">
        //                     <button className="buttonOperateSubmitABC" onClick={() => handleCostSubmit()}>Enviar</button>
        //                 </div>

                        
        //             </div>
        //         </>
        //     }
        // </div>
    )

}