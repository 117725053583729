import React, { useEffect, useState } from 'react';
import { FaKey } from 'react-icons/fa'
import { FiTrash2, FiSlash, FiPlus, FiArrowDown, FiCopy } from 'react-icons/fi'
//hooks
import { useModal } from '../../../../contexts/useModal';
import { useAuth } from '../../../../contexts/useAuth';
//services
import { api } from '../../../../services/api';
import { getUsers } from '../../../../services/asyncFunctions';
//components
import { ArrowLoader } from '../../../../components/loaders/arrowLoader';
//utils
import { titleize } from '../../../../utils/formatNumberAndText/formatTextUpperFirstLetter'
import { ModalSizeFitToScreen } from '../../../../utils/responsiveFunctions/modalSizeFitToScreen'
//styles
import './styles/users.active.table.css'

export function UsersActiveTable() {

    const [usersList, setUsersList] = useState([]);
    const [totalUsers, setTotalUsers] = useState(0);
    const [filtered, setFiltered] = useState('');
    const [loading, setLoading] = useState(true);
    const [scrollTable, setScrollTable] = useState(null);
    const [scrollTableLines, setScrollTableLines] = useState(50);
    const [bottomScroll, setBottomScroll] = useState(false);
    const { show, setShow, setType, edited, setEdited } = useModal();
    const { user, tokenKey } = useAuth();
    const [selectedFilter, setSelectedFilter] = useState('Código');
    const {screenX, screenY } = ModalSizeFitToScreen()

    useEffect(async () => {
       
        setLoading(true);
        const usersGet = await getUsers(1, tokenKey)
        if (usersGet?.isError) {
            setType({
                name: 'alertMsg',
                type: 'information',
                value: {
                    message: usersGet?.error
                }
            })
            setShow(true);
        } else {
            setUsersList(usersGet);
            setTotalUsers(usersGet?.length);
        }
        setLoading(false);

    }, [edited])

    useEffect(() => {

        if (bottomScroll != false) {

            setScrollTableLines(scrollTableLines + 50)
            setBottomScroll(false);

        } else {
            setBottomScroll(false);
        }

    }, [bottomScroll])

    const handleDelete = async (user) => {

        setLoading(true);

        try {
            await api.delete(`/api/v1/users/${user.external_id}`, {
                headers: {
                    authorization: `Bearer ${tokenKey}`
                }
            })
            setEdited(!edited);
            setShow(false)
        } catch (err) {
            setType({ name: 'alertMsg', type: 'information', value: { message: err?.response?.data?.message } })
            setLoading(false);
            setEdited(!edited);
        }
    }

    async function getExportUsers() {

        try {
            setLoading(true);
            const exportUsers = await api.get(`api/v1/users/export`, {
                headers: {
                    authorization: `Bearer ${tokenKey}`
                },
                responseType: 'blob'
            })

            const responseURL = URL.createObjectURL(new Blob([exportUsers.data]));
            const link = document.createElement('a');
            link.href = responseURL;
            link.setAttribute('download', 'lista_de_usuarios.xlsx');
            document.body.appendChild(link);
            link.click();
            setLoading(false);

        } catch (error) {
            
            setType({name: 'alertMsg', type: 'information', value:{message: error?.response?.data?.message === undefined ? 'Erro ao realizar consulta para exportação' : error?.response?.data?.message}})
            setShow(true);
            setLoading(false);
        }
    }

    async function handleChange(user, action) {

        if (user?.total_clients > 0) {

            let message = ''
            let linkTypeValue = ''
            if (user?.profile_id > 0 && user?.type_access === 3) {
                message = 'Há clientes já vinculados a este broker, para desativar o mesmo, é necessário vincular novo broker, deseja abrir a caixa de transferência?'
                linkTypeValue = 'linkNewBroker'
            } else if (user?.type_access === 4) {
                message = 'Há clientes já vinculados a este assessor, para desativar o mesmo, é necessário transferir todos os clientes deste assesor para outro, deseja abrir a caixa de transferência?'
                linkTypeValue = 'linkNewAdvisor'
            }

            setType({ name: 'alertMsg', value: { message: message, name: 'linkNewUser', value: { full_name: user.full_name, profile_id: user.profile_id, type_access: user.type_access, external_id: user.external_id, active: user.active, linkType: linkTypeValue, action: action } } })
            setShow(true)
            setLoading(false)
        }
        else {
            setLoading(true);
            try {
                await api.put(`/api/v1/users/${user.external_id}/status`,
                    {
                        "active": user.active === true ? 0 : 1
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${tokenKey}`
                        }
                    })
                setEdited(!edited);
            } catch (err) {
                alert(err?.response?.data?.message)
                setLoading(false);
            }
        }
    }

    function permittedUserList() {
        return usersList?.filter((permittedUserShow) => {
            if (user?.type <= permittedUserShow.type_access) {
                return permittedUserShow
            }
        })
    }

    function listFilter() {

        if (filtered == null || filtered == null || filtered == undefined) {
            return permittedUserList()
        }
        return permittedUserList().filter((user) => {

            if (selectedFilter === 'Código' && user?.external_id?.toLowerCase()?.indexOf(filtered?.toLowerCase()) > -1) {
                return user
            }

            if (selectedFilter === 'Nome' && user?.full_name?.toLowerCase()?.indexOf(filtered?.toLowerCase()) > -1) {
                return user
            }

            if (selectedFilter === 'E-mail' && user?.email?.toLowerCase()?.indexOf(filtered?.toLowerCase()) > -1) {
                return user
            }

            if (selectedFilter === 'Filial' && user?.branche?.city?.toLowerCase()?.indexOf(filtered?.toLowerCase()) > -1) {
                return user
            }

            if (selectedFilter === 'Tipo de acesso' &&
                user?.type_access == 1 && 'Administrador'.toLowerCase().indexOf(filtered.toLowerCase()) > -1 ||
                user?.type_access == 2 && 'Backoffice'.toLowerCase().indexOf(filtered.toLowerCase()) > -1 ||
                user?.type_access == 3 && 'Broker'.toLowerCase().indexOf(filtered.toLowerCase()) > -1 ||
                user?.type_access == 4 && 'Assessor'.toLowerCase().indexOf(filtered.toLowerCase()) > -1) {
                return user
            }

            if (selectedFilter === 'Perfil' && user?.profile_description?.toLowerCase()?.indexOf(filtered?.toLowerCase()) > -1) {
                return user
            }
        })
    }


    function checkFilteredValue(value) {
        if (value === '') { setScrollTableLines(50) }
    }

    return (
        <div id='usersScrollableTable' style={{
            maxHeight: screenY -50
        }} className='usersTableContainer' onScrollCapture={() => {
            setScrollTable(document.getElementById('usersScrollableTable'))
            if (scrollTable?.scrollTop + scrollTable?.clientHeight >= scrollTable?.scrollHeight) {
                setBottomScroll(true);
            }
        }}>
            <table>
                <thead id='usersTableTbody'>
                    <tr id='usersTableBody'>
                        <th colSpan='10'>
                            <div className='theadFirst'>
                                <label id='usersTableNameAndTotalUsers'>
                                    <a>Lista de Usuários</a>
                                    <a>Total: {totalUsers}</a>
                                </label>
                                <div className='substitutoLabelDisplay'>
                                    <select className='selectFilterTable' onChange={(e) => setSelectedFilter(e.target.value)}>
                                        <option selected>Código</option>
                                        <option>Nome</option>
                                        <option>E-mail</option>
                                        <option>Filial</option>
                                        <option>Tipo de acesso</option>
                                        <option>Perfil</option>
                                        <option>N° Clientes</option>
                                    </select>
                                    <input placeholder='Filtrar' type="text" onChange={(e) => { setFiltered(e.target.value); checkFilteredValue(e.target.value) }} />
                                    <button
                                    className='buttonTableThead'
                                    onClick={() => {
                                        const output = [];
                                        const titles = ['Código', 'Nome', 'E-mail', 'Filial', 'Tipo de Assesso', 'Perfil', 'Nº Clientes', 'Ativo']
                                        output.push(titles.join('\t'))
                                        const keys = ['external_id', 'full_name','email', 'branche', 'type_description', 'profile-description', 'total_clients', 'active'];
                                        usersList.forEach((x) => {
                                            const tmp = keys.map((y) => {return y==='branche'? x[y].city + ' - ' + x[y].city_state :  y==='active'?  x[y] === true ? 'Ativo' : 'Inativo' : x[y]});
                                            output.push(tmp.join('\t'))
                                        })
                                        const table = output.join('\n')
                                        navigator.clipboard.writeText(table)
                                        alert('Conteúdo da tabela copiado para a área de transferência')
                                    }}
                                ><FiCopy />Copiar</button>
                                    <button
                                        onClick={() => getExportUsers()}><FiArrowDown />Exportar</button>
                                        {
                                            user.type === 1 ? 
                                            <button id='userCadastration'
                                                onClick={() => {
                                                    setType({ name: 'usersCadastration' });
                                                    setShow(!show)
                                                }}>
                                                <FiPlus />
                                                Novo
                                            </button> : <></>
                                        }
                                </div>
                            </div>
                        </th>
                    </tr>
                    <tr>
                        <th>Código</th>
                        <th>Nome</th>
                        <th>E-mail</th>
                        <th>Filial</th>
                        <th>Tipo de Acesso</th>
                        <th>Perfil</th>
                        <th>N° Clientes</th>
                        <th colspan='3'>Ações</th>
                    </tr>
                </thead>
                <tbody id='usersTableTbody'>
                    {loading ? (<tr className='usersActiveTrLoader' ><td colspan='8'><ArrowLoader /></td></tr>) :
                        (
                            listFilter().map((userInList, index) => {

                                if (index < scrollTableLines && bottomScroll && index + 1 === scrollTableLines) {
                                    return <tr id={bottomScroll ? 'usersShowLoadingScroll' : 'usersHideLoadingScroll'}><td colspan='10'>
                                        <div className='ldsellipsis'><div></div><div></div><div></div><div></div></div>
                                    </td></tr>
                                }
                                else if (index < scrollTableLines) {
                                    return (
                                        <tr className={user.external_id === parseInt(userInList.external_id) ? 'usersMyUser' : ''}>
                                            <td id='1' title='Alterar Usuario' onClick={() => {
                                                setType({
                                                    name: 'userEditModal',
                                                    external_id: parseInt(userInList?.external_id),
                                                    full_name: userInList?.full_name,
                                                    type_access: userInList?.type_access,
                                                    total_clients: userInList?.total_clients,
                                                    email: userInList?.email,
                                                    branche: userInList?.branche,
                                                    type_description: userInList?.type_description,
                                                    profile_description: userInList?.profile_description,
                                                    profile_id: userInList?.profile_id
                                                }); setShow(true)
                                            }} ><a>{userInList.external_id}</a></td>
                                            <td id='3' title='Alterar Usuario' onClick={() => {
                                                setType({
                                                    name: 'userEditModal',
                                                    external_id: parseInt(userInList?.external_id),
                                                    full_name: userInList?.full_name,
                                                    type_access: userInList?.type_access,
                                                    total_clients: userInList?.total_clients,
                                                    email: userInList?.email,
                                                    branche: userInList?.branche,
                                                    type_description: userInList?.type_description,
                                                    profile_description: userInList?.profile_description,
                                                    profile_id: userInList?.profile_id
                                                }); setShow(true)
                                            }}>
                                                <div className='editDiv'>
                                                    <div className='ellipsisTd'><span>{titleize(userInList.full_name)}</span></div>
                                                </div>
                                            </td>
                                            <td id='4' title='Alterar Usuario' onClick={() => {
                                                setType({
                                                    name: 'userEditModal',
                                                    external_id: parseInt(userInList?.external_id),
                                                    full_name: userInList?.full_name,
                                                    type_access: userInList?.type_access,
                                                    total_clients: userInList?.total_clients,
                                                    email: userInList?.email,
                                                    branche: userInList?.branche,
                                                    type_description: userInList?.type_description,
                                                    profile_description: userInList?.profile_description,
                                                    profile_id: userInList?.profile_id
                                                }); setShow(true)
                                            }}>
                                                <div className='editDiv'>
                                                    <div className='ellipsisTd'><span>{userInList.email}</span></div>
                                                </div>
                                            </td>
                                            <td id='5' title='Alterar Usuario' onClick={() => {
                                                setType({
                                                    name: 'userEditModal',
                                                    external_id: parseInt(userInList?.external_id),
                                                    full_name: userInList?.full_name,
                                                    type_access: userInList?.type_access,
                                                    total_clients: userInList?.total_clients,
                                                    email: userInList?.email,
                                                    branche: userInList?.branche,
                                                    type_description: userInList?.type_description,
                                                    profile_description: userInList?.profile_description,
                                                    profile_id: userInList?.profile_id
                                                }); setShow(true)
                                            }}>
                                                <div className='editDiv'>
                                                    <div className='ellipsisTd'><span>{userInList.branche?.city} - {userInList.branche?.city_state}</span></div>
                                                </div>
                                            </td>
                                            <td id='6' title='Alterar Usuario' onClick={() => {
                                                setType({
                                                    name: 'userEditModal',
                                                    external_id: parseInt(userInList?.external_id),
                                                    full_name: userInList?.full_name,
                                                    type_access: userInList?.type_access,
                                                    total_clients: userInList?.total_clients,
                                                    email: userInList?.email,
                                                    branche: userInList?.branche,
                                                    type_description: userInList?.type_description,
                                                    profile_description: userInList?.profile_description,
                                                    profile_id: userInList?.profile_id
                                                }); setShow(true)
                                            }}>
                                                <div className='editDiv'>
                                                    <div className='ellipsisTd'><span>{userInList.type_description}</span></div>
                                                </div>
                                            </td>
                                            <td id='7' title='Alterar Usuario' onClick={() => {
                                                setType({
                                                    name: 'userEditModal',
                                                    external_id: parseInt(userInList?.external_id),
                                                    full_name: userInList?.full_name,
                                                    type_access: userInList?.type_access,
                                                    total_clients: userInList?.total_clients,
                                                    email: userInList?.email,
                                                    branche: userInList?.branche,
                                                    type_description: userInList?.type_description,
                                                    profile_description: userInList?.profile_description,
                                                    profile_id: userInList?.profile_id
                                                }); setShow(true)
                                            }}>
                                                <div className='editDiv'>
                                                    <a>{userInList.profile_description ? titleize(userInList.profile_description) : '-'}</a>
                                                </div>
                                            </td>
                                            <td onClick={() => {

                                                setType({
                                                    name: 'userEditModal',
                                                    external_id: parseInt(userInList?.external_id),
                                                    full_name: userInList?.full_name,
                                                    type_access: userInList?.type_access,
                                                    total_clients: userInList?.total_clients,
                                                    email: userInList?.email,
                                                    branche: userInList?.branche,
                                                    type_description: userInList?.type_description,
                                                    profile_description: userInList?.profile_description,
                                                    profile_id: userInList?.profile_id
                                                }); setShow(true)
                                            }} id='8'>
                                                <a>{userInList.total_clients}</a>
                                            </td>
                                            <td id='9'>
                                                {(user.type === 2 && userInList.type_access > 2) || (user.type === 1 && (userInList.external_id !== user.external_id && userInList.type_access > 1) || (user.external_id === 0)) ?
                                                    (<FiTrash2
                                                        title='Excluir usuário'
                                                        cursor='pointer'

                                                        onClick={() => {
                                                            setType({ name: 'alertMsg', value: { message: 'Deseja realmente excluir o usuário selecionado?', func: handleDelete, dataForFunc: userInList } });
                                                            setShow(true);
                                                        }}
                                                    />) :
                                                    (<a className='userInactiveAction'><FiTrash2 /></a>)
                                                }
                                            </td>
                                            <td id='10'>
                                                {(user.type === 2 && userInList.type_access > 2) || (user.type === 1 && (userInList.external_id !== user.external_id && userInList.type_access > 1) || (user.external_id === 0)) ?
                                                    (<FiSlash
                                                        title='Desativar Usuário'
                                                        cursor='pointer'
                                                        onClick={() => handleChange(userInList, 'disableUser')}
                                                    />) :
                                                    (<a className='userInactiveAction'><FiSlash /></a>)}
                                            </td>
                                            <td id='11' className='usersActions'>
                                                {user.external_id == userInList.external_id || (user.type <= 1 && userInList.type_access > 1) || (user.external_id === 0)
                                                    || (user.type <= 2 && userInList.type_access > 2) ?
                                                    (
                                                        <FaKey
                                                            title='Alterar Senha'
                                                            cursor='pointer'
                                                            className='actionIcon'
                                                            onClick={() => {
                                                                sessionStorage.setItem('@userIdForChange', userInList.external_id);
                                                                sessionStorage.setItem('@userName', userInList.full_name);
                                                                setType({ name: 'userChangePass' });
                                                                setShow(true);
                                                            }}
                                                        />
                                                    ) :
                                                    (
                                                        <a className='userInactiveAction'><FaKey /></a>
                                                    )}
                                            </td>
                                        </tr>
                                    )

                                }
                            })
                        )
                    }
                </tbody>
            </table>
        </div >
    )
}