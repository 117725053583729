/*Componente tabela de clientes
/*Descrição: Exibe todos os clientes cadastrado em sistema
/*Desenvolvido por: Equipe Rafael Cezario
*/

//libraries
import React, { useEffect, useState } from "react";
import { FiPlus, FiSearch, FiArrowDown, FiCopy } from "react-icons/fi";
import MUIDataTable from "mui-datatables";
import { FormGroup, FormLabel, FormControl, ListItemText, TextField, Checkbox, FormControlLabel, Grid, Select, InputLabel, MenuItem} from '@mui/material';
import { Tooltip, IconButton } from "@material-ui/core";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableSortLabel from "@mui/material/TableSortLabel";
import { ThemeProvider,createTheme } from '@mui/material/styles';
//hooks
import { useModal } from "../../../../contexts/useModal";
import { useAuth } from "../../../../contexts/useAuth";
//services
import { api } from "../../../../services/api";
import { getB3GuaranteesFollowUp } from "../../../../services/asyncFunctions";
//components
import { ArrowLoader } from "../../../../components/loaders/arrowLoader";
import { toBRL } from "../../../../utils/formatNumberAndText/toBRLCurrency";
//utils
import { titleize } from "../../../../utils/formatNumberAndText/formatTextUpperFirstLetter";
import { ModalSizeFitToScreen } from "../../../../utils/responsiveFunctions/modalSizeFitToScreen";
//styles
import "./styles/brokers.advisor.table.css";

export function B3Guarantees() {
    const { show, setShow, setType, type, edited, setEdited } = useModal();
    const { user,tokenKey } = useAuth();
    const [brokersAdvisor, setBrokersAdvisor] = useState([]);
    const [loading, setLoading] = useState(true);
    const token = localStorage.getItem("@auth:token");
    const [listClients, setListClients] = useState([]);
    const [listAllClients, setListAllClients] = useState([]);
    const [listFilteredClients, setListFilteredClients] = useState([]);
    const [totalClients, setTotalClients] = useState(0);
    const [lastPage, setLastPage] = useState(0);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(30);
    const [filtersList, setFiltersList] = useState({});
    const [search, setSearch] = useState('');

    useEffect(() => {
        async function searchAdvisorBrokers() {
            try {
                const clientsResult = await getB3GuaranteesFollowUp(tokenKey,false);
                // console.log(brokersAdvisorResult);
                // setTotalClients(brokersAdvisorResult?.data?.data?.length + 1);
                setListClients(clientsResult.slice(0, rowsPerPage+101));
                setListAllClients(clientsResult);
                setListFilteredClients(clientsResult)
                setTotalClients(clientsResult?.length);
                setLoading(false)
            } catch (error) {
                alert(error?.response?.data?.message);
            }
        }
        searchAdvisorBrokers();
    }, [show, edited]);

    function copyToClipboard() {
        const output = [];
        const titles = [
            "Código Cliente",
            "Cliente",
            "Estoque Disponível",
            "Limite Máximo (LTD)",
            "Assessor",
            "Broker",
            "Perfil",
            "Estoque de Garantias RV",
            "Estoque de Garantias RF",
            "Estoque de Garantias Ouro",
            "Risco Atual na B3 (RTC)"
        ];
        output.push(titles.join("\t"));
        const keys = [
            "clients_external_id",
            "name",
            "stock_available",
            "max_ltd_limit",
            "advisor_name",
            "broker_name",
            "client_profile",
            "rv_guarantee_stock",
            "rf_guarantee_stock",
            "gold_guarantee_stock",
            "b3_current_risk"
        ];
        listAllClients.forEach((x) => {
            const tmp = keys.map((y) => x[y]);
            output.push(tmp.join("\t"));
        });
        const table = output.join("\n");
        navigator.clipboard.writeText(table);
        alert("Conteúdo da tabela copiado para a área de transferência");
    }

    async function advisorBrokersExport() {
        setLoading(true);
        try {
            const myExport = await getB3GuaranteesFollowUp(tokenKey, true);
            const responseURL = URL.createObjectURL(new Blob([myExport]));
            const link = document.createElement("a");
            link.href = responseURL;
            link.setAttribute("download", "garantias_b3.xlsx");
            document.body.appendChild(link);
            link.click();
            setLoading(false);
        } catch (error) {
            setType({
                name: "alertMsg",
                type: "information",
                value: {
                    message:
                        error?.response?.data?.message === undefined
                            ? "Erro ao buscar dados para exportação"
                            : error?.response?.data?.message,
                },
            });
            setShow(true);
            setLoading(false);
        }
    }

    const columns = [
        {
            name: "clients_external_id",
            label: "Código Cliente",
            options: {
                sort: true,
                filterType: 'custom',
                customFilterListOptions: {
                    render: v => v,
                    update: (filterList, filterPos, index) => {
                    // console.log(filterList, filterPos, index);
                    filterList[index].splice(filterPos, 1);
                    return filterList;
                    }
                },
                filterOptions: {
                    logic: (location, filters, row) => {
                    if (filters.length) return !filters.includes(location);
                    return false;
                    },
                    display: (filterList, onChange, index, column) => {
                    const optionValues = listFilteredClients.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v))

                    return (
                        <FormControl>
                        <InputLabel htmlFor='select-multiple-chip' variant='standard'>
                            {column.label}
                        </InputLabel>
                        <Select
                            multiple
                            variant='standard'
                            value={filterList[index]}
                            renderValue={selected => selected.join(', ')}
                            onChange={event => {
                            filterList[index] = event.target.value;
                            onChange(filterList[index], index, column);
                            }}
                        >
                            {optionValues.map(item => (
                            <MenuItem key={item} value={item}>
                                <Checkbox
                                color='primary'
                                checked={filterList[index].indexOf(item) > -1}
                                />
                                <ListItemText primary={item} />
                            </MenuItem>
                            ))}
                        </Select>
                        </FormControl>
                    );
                    }
                }
            },
        },
        {
            name: "name",
            label: "Cliente",
            options: {
                filter: true,
                sort: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap"
                    },
                }),
                filterType: 'custom',
                customFilterListOptions: {
                    render: v => v,
                    update: (filterList, filterPos, index) => {
                    // console.log(filterList, filterPos, index);
                    filterList[index].splice(filterPos, 1);
                    return filterList;
                    }
                },
                filterOptions: {
                    logic: (location, filters, row) => {
                    if (filters.length) return !filters.includes(location);
                    return false;
                    },
                    display: (filterList, onChange, index, column) => {
                    const optionValues = listFilteredClients.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v))

                    return (
                        <FormControl>
                        <InputLabel htmlFor='select-multiple-chip' variant='standard'>
                            {column.label}
                        </InputLabel>
                        <Select
                            multiple
                            variant='standard'
                            value={filterList[index]}
                            renderValue={selected => selected.join(', ')}
                            onChange={event => {
                            filterList[index] = event.target.value;
                            onChange(filterList[index], index, column);
                            }}
                        >
                            {optionValues.map(item => (
                            <MenuItem key={item} value={item}>
                                <Checkbox
                                color='primary'
                                checked={filterList[index].indexOf(item) > -1}
                                />
                                <ListItemText primary={item} />
                            </MenuItem>
                            ))}
                        </Select>
                        </FormControl>
                    );
                    }
                }
            },
        },
        {
            name: "stock_available",
            label: "Estoque Disponível",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value) => <span>{toBRL(value)}</span>,
                
                filterType: 'custom',
                customFilterListOptions: {
                    render: v => v,
                    update: (filterList, filterPos, index) => {
                    // console.log(filterList, filterPos, index);
                    filterList[index].splice(filterPos, 1);
                    return filterList;
                    }
                },
                filterOptions: {
                    logic: (location, filters, row) => {
                    if (filters.length) return !filters.includes(location);
                    return false;
                    },
                    display: (filterList, onChange, index, column) => {
                    const optionValues = listFilteredClients.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v))

                    return (
                        <FormControl>
                        <InputLabel htmlFor='select-multiple-chip' variant='standard'>
                            {column.label}
                        </InputLabel>
                        <Select
                            multiple
                            variant='standard'
                            value={filterList[index]}
                            renderValue={selected => selected.join(', ')}
                            onChange={event => {
                            filterList[index] = event.target.value;
                            onChange(filterList[index], index, column);
                            }}
                        >
                            {optionValues.map(item => (
                            <MenuItem key={item} value={item}>
                                <Checkbox
                                color='primary'
                                checked={filterList[index].indexOf(item) > -1}
                                />
                                <ListItemText primary={item} />
                            </MenuItem>
                            ))}
                        </Select>
                        </FormControl>
                    );
                    }
                }
            },
        },
        {
            name: "max_ltd_limit",
            label: "Limite Máximo (LTD)",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value) => <span>{toBRL(value)}</span>,
                
                filterType: 'custom',
                customFilterListOptions: {
                    render: v => v,
                    update: (filterList, filterPos, index) => {
                    // console.log(filterList, filterPos, index);
                    filterList[index].splice(filterPos, 1);
                    return filterList;
                    }
                },
                filterOptions: {
                    logic: (location, filters, row) => {
                    if (filters.length) return !filters.includes(location);
                    return false;
                    },
                    display: (filterList, onChange, index, column) => {
                    const optionValues = listFilteredClients.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v))

                    return (
                        <FormControl>
                        <InputLabel htmlFor='select-multiple-chip' variant='standard'>
                            {column.label}
                        </InputLabel>
                        <Select
                            multiple
                            variant='standard'
                            value={filterList[index]}
                            renderValue={selected => selected.join(', ')}
                            onChange={event => {
                            filterList[index] = event.target.value;
                            onChange(filterList[index], index, column);
                            }}
                        >
                            {optionValues.map(item => (
                            <MenuItem key={item} value={item}>
                                <Checkbox
                                color='primary'
                                checked={filterList[index].indexOf(item) > -1}
                                />
                                <ListItemText primary={item} />
                            </MenuItem>
                            ))}
                        </Select>
                        </FormControl>
                    );
                    }
                }
            },
        },
        {
            name: "advisor_name",
            label: "Assessor",
            options: {
                filter: true,
                sort: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap"
                    },
                }),
                filterType: 'custom',
                customFilterListOptions: {
                    render: v => v,
                    update: (filterList, filterPos, index) => {
                    // console.log(filterList, filterPos, index);
                    filterList[index].splice(filterPos, 1);
                    return filterList;
                    }
                },
                filterOptions: {
                    logic: (location, filters, row) => {
                    if (filters.length) return !filters.includes(location);
                    return false;
                    },
                    display: (filterList, onChange, index, column) => {
                    const optionValues = listFilteredClients.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v))

                    return (
                        <FormControl>
                        <InputLabel htmlFor='select-multiple-chip' variant='standard'>
                            {column.label}
                        </InputLabel>
                        <Select
                            multiple
                            variant='standard'
                            value={filterList[index]}
                            renderValue={selected => selected.join(', ')}
                            onChange={event => {
                            filterList[index] = event.target.value;
                            onChange(filterList[index], index, column);
                            }}
                        >
                            {optionValues.map(item => (
                            <MenuItem key={item} value={item}>
                                <Checkbox
                                color='primary'
                                checked={filterList[index].indexOf(item) > -1}
                                />
                                <ListItemText primary={item} />
                            </MenuItem>
                            ))}
                        </Select>
                        </FormControl>
                    );
                    }
                }
            },
        },
        {
            name: "broker_name",
            label: "Broker",
            options: {
                filter: true,
                sort: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap"
                    },
                }),
                filterType: 'custom',
                customFilterListOptions: {
                    render: v => v,
                    update: (filterList, filterPos, index) => {
                    // console.log(filterList, filterPos, index);
                    filterList[index].splice(filterPos, 1);
                    return filterList;
                    }
                },
                filterOptions: {
                    logic: (location, filters, row) => {
                    if (filters.length) return !filters.includes(location);
                    return false;
                    },
                    display: (filterList, onChange, index, column) => {
                    const optionValues = listFilteredClients.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v))

                    return (
                        <FormControl>
                        <InputLabel htmlFor='select-multiple-chip' variant='standard'>
                            {column.label}
                        </InputLabel>
                        <Select
                            multiple
                            variant='standard'
                            value={filterList[index]}
                            renderValue={selected => selected.join(', ')}
                            onChange={event => {
                            filterList[index] = event.target.value;
                            onChange(filterList[index], index, column);
                            }}
                        >
                            {optionValues.map(item => (
                            <MenuItem key={item} value={item}>
                                <Checkbox
                                color='primary'
                                checked={filterList[index].indexOf(item) > -1}
                                />
                                <ListItemText primary={item} />
                            </MenuItem>
                            ))}
                        </Select>
                        </FormControl>
                    );
                    }
                }
            },
        },
        {
            name: "client_profile",
            label: "Perfil",
            options: {
                filter: true,
                sort: true,
                filterType: 'custom',
                customFilterListOptions: {
                    render: v => v,
                    update: (filterList, filterPos, index) => {
                    // console.log(filterList, filterPos, index);
                    filterList[index].splice(filterPos, 1);
                    return filterList;
                    }
                },
                filterOptions: {
                    logic: (location, filters, row) => {
                    if (filters.length) return !filters.includes(location);
                    return false;
                    },
                    display: (filterList, onChange, index, column) => {
                    const optionValues = listFilteredClients.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v))

                    return (
                        <FormControl>
                        <InputLabel htmlFor='select-multiple-chip' variant='standard'>
                            {column.label}
                        </InputLabel>
                        <Select
                            multiple
                            variant='standard'
                            value={filterList[index]}
                            renderValue={selected => selected.join(', ')}
                            onChange={event => {
                            filterList[index] = event.target.value;
                            onChange(filterList[index], index, column);
                            }}
                        >
                            {optionValues.map(item => (
                            <MenuItem key={item} value={item}>
                                <Checkbox
                                color='primary'
                                checked={filterList[index].indexOf(item) > -1}
                                />
                                <ListItemText primary={item} />
                            </MenuItem>
                            ))}
                        </Select>
                        </FormControl>
                    );
                    }
                }
            },
        },
        {
            name: "rv_guarantee_stock",
            label: "Estoque de Garantias RV",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value) => <span>{toBRL(value)}</span>,
                filterType: 'custom',
                customFilterListOptions: {
                    render: v => v,
                    update: (filterList, filterPos, index) => {
                    // console.log(filterList, filterPos, index);
                    filterList[index].splice(filterPos, 1);
                    return filterList;
                    }
                },
                filterOptions: {
                    logic: (location, filters, row) => {
                    if (filters.length) return !filters.includes(location);
                    return false;
                    },
                    display: (filterList, onChange, index, column) => {
                    const optionValues = listFilteredClients.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v))

                    return (
                        <FormControl>
                        <InputLabel htmlFor='select-multiple-chip' variant='standard'>
                            {column.label}
                        </InputLabel>
                        <Select
                            multiple
                            variant='standard'
                            value={filterList[index]}
                            renderValue={selected => selected.join(', ')}
                            onChange={event => {
                            filterList[index] = event.target.value;
                            onChange(filterList[index], index, column);
                            }}
                        >
                            {optionValues.map(item => (
                            <MenuItem key={item} value={item}>
                                <Checkbox
                                color='primary'
                                checked={filterList[index].indexOf(item) > -1}
                                />
                                <ListItemText primary={item} />
                            </MenuItem>
                            ))}
                        </Select>
                        </FormControl>
                    );
                    }
                }
            },
        },
        {
            name: "rf_guarantee_stock",
            label: "Estoque de Garantias RF",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value) => <span>{toBRL(value)}</span>,
                filterType: 'custom',
                customFilterListOptions: {
                    render: v => v,
                    update: (filterList, filterPos, index) => {
                    // console.log(filterList, filterPos, index);
                    filterList[index].splice(filterPos, 1);
                    return filterList;
                    }
                },
                filterOptions: {
                    logic: (location, filters, row) => {
                    if (filters.length) return !filters.includes(location);
                    return false;
                    },
                    display: (filterList, onChange, index, column) => {
                    const optionValues = listFilteredClients.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v))

                    return (
                        <FormControl>
                        <InputLabel htmlFor='select-multiple-chip' variant='standard'>
                            {column.label}
                        </InputLabel>
                        <Select
                            multiple
                            variant='standard'
                            value={filterList[index]}
                            renderValue={selected => selected.join(', ')}
                            onChange={event => {
                            filterList[index] = event.target.value;
                            onChange(filterList[index], index, column);
                            }}
                        >
                            {optionValues.map(item => (
                            <MenuItem key={item} value={item}>
                                <Checkbox
                                color='primary'
                                checked={filterList[index].indexOf(item) > -1}
                                />
                                <ListItemText primary={item} />
                            </MenuItem>
                            ))}
                        </Select>
                        </FormControl>
                    );
                    }
                }
            },
        },
        {
            name: "gold_guarantee_stock",
            label: "Estoque de Garantias Ouro",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value) => <span>{toBRL(value)}</span>,
                filterType: 'custom',
                customFilterListOptions: {
                    render: v => v,
                    update: (filterList, filterPos, index) => {
                    // console.log(filterList, filterPos, index);
                    filterList[index].splice(filterPos, 1);
                    return filterList;
                    }
                },
                filterOptions: {
                    logic: (location, filters, row) => {
                    if (filters.length) return !filters.includes(location);
                    return false;
                    },
                    display: (filterList, onChange, index, column) => {
                    const optionValues = listFilteredClients.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v))

                    return (
                        <FormControl>
                        <InputLabel htmlFor='select-multiple-chip' variant='standard'>
                            {column.label}
                        </InputLabel>
                        <Select
                            multiple
                            variant='standard'
                            value={filterList[index]}
                            renderValue={selected => selected.join(', ')}
                            onChange={event => {
                            filterList[index] = event.target.value;
                            onChange(filterList[index], index, column);
                            }}
                        >
                            {optionValues.map(item => (
                            <MenuItem key={item} value={item}>
                                <Checkbox
                                color='primary'
                                checked={filterList[index].indexOf(item) > -1}
                                />
                                <ListItemText primary={item} />
                            </MenuItem>
                            ))}
                        </Select>
                        </FormControl>
                    );
                    }
                }
            },
        },
        {
            name: "b3_current_risk",
            label: "Risco Atual na B3 (RTC)",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value) => <span>{toBRL(value)}</span>,
                filterType: 'custom',
                customFilterListOptions: {
                    render: v => v,
                    update: (filterList, filterPos, index) => {
                    // console.log(filterList, filterPos, index);
                    filterList[index].splice(filterPos, 1);
                    return filterList;
                    }
                },
                filterOptions: {
                    logic: (location, filters, row) => {
                    if (filters.length) return !filters.includes(location);
                    return false;
                    },
                    display: (filterList, onChange, index, column) => {
                    const optionValues = listFilteredClients.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v))

                    return (
                        <FormControl>
                        <InputLabel htmlFor='select-multiple-chip' variant='standard'>
                            {column.label}
                        </InputLabel>
                        <Select
                            multiple
                            variant='standard'
                            value={filterList[index]}
                            renderValue={selected => selected.join(', ')}
                            onChange={event => {
                            filterList[index] = event.target.value;
                            onChange(filterList[index], index, column);
                            }}
                        >
                            {optionValues.map(item => (
                            <MenuItem key={item} value={item}>
                                <Checkbox
                                color='primary'
                                checked={filterList[index].indexOf(item) > -1}
                                />
                                <ListItemText primary={item} />
                            </MenuItem>
                            ))}
                        </Select>
                        </FormControl>
                    );
                    }
                }
            },
        }
    ];

    // Adiciona um botão para chamar a função de copiar a tabela para a área de tranferência
    const AddButton = () => (
        <Tooltip disableFocusListener title="Copiar para a área de transferência">
            <IconButton onClick={copyToClipboard}>
                <ContentCopyIcon />
            </IconButton>
        </Tooltip>
    );

    const options = {
        filterType: "multiselect",
        download: true,
        print: false,
        responsive: "vertical",
        tableBodyHeight: "72vh",
        selectableRowsHideCheckboxes: true,
        viewColumns: false,
        rowsPerPage: rowsPerPage,
        page: page,
        count: listFilteredClients.length,
        rowsPerPageOptions: [30, 50, 100],
        toolButton: {
            justifyContent: "center"
        },
        textLabels: {
            body: {
                noMatch: loading ? <ArrowLoader /> : "Não foram encontrados dados",
                toolTip: "Ordenar",
                columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
            },
            pagination: {
                next: "Próxima",
                previous: "Anterior",
                rowsPerPage: "Linhas por página:",
                displayRows: "de",
            },
            toolbar: {
                search: "Pesquisar",
                downloadCsv: "Download XLSX",
                print: "Imprimir",
                viewColumns: "Ver colunas",
                filterTable: "Filtrar",
            },
            filter: {
                all: "Todos",
                title: "Filtros",
                reset: "Limpar",
            },
            viewColumns: {
                title: "Mostrar Colunas",
                titleAria: "Mostrar/Ocultar colunas",
            },
            selectedRows: {
                text: "linha(s) selecionadas",
                delete: "Excluir",
                deleteAria: "Excluir linhas Selecionadas",
            },
        },
        downloadOptions: {
            filename: "previa_receita.csv",
            separator: ";",
        },
        onDownload: () => {
            advisorBrokersExport();
            return false;
        },
        customToolbar: AddButton,
        onRowClick: (rowData, rowMeta) => {
            if (user.type === 1){
                setType({ name: "advisorBrokersEdit", data: rowData });
                setShow(true);
            }
        },setTableProps: () => {
            return {
                padding: 'none',
                size: 'small'
            };
          },
          onChangePage: (atualPage) => {
              setPage(atualPage)
              if (atualPage > lastPage){
                  setListClients(listClients.concat(listFilteredClients.slice(listClients.length,(listClients.length + rowsPerPage))))
                  setLastPage(atualPage)
              }
          },
          onChangeRowsPerPage : (rows) => {
              setRowsPerPage(rows)
              setLastPage(0)
              setPage(0)
              setListClients(listFilteredClients.slice(0,rows+1))
          },
          onSearchChange : (searchText) => {
              searchText = searchText === null ? '' : searchText
              setSearch(searchText)
              var filterListTemp = filtersList
              filterListTemp['search'] = searchText
              setFiltersList(filterListTemp)
              var filteredClientsTemp = []
              if (searchText != '' && searchText != undefined) {
                  filteredClientsTemp = listAllClients.filter((row) => Object.values(row).join('|').toLowerCase().indexOf(String(searchText)) > -1)
                  setListFilteredClients(filteredClientsTemp)
                  setLastPage(0)
                  setPage(0)
              } else {
                  setListFilteredClients(listAllClients)
                  setLastPage(0)
                  setPage(0)
                  filteredClientsTemp = listAllClients
              }
              var aux = filteredClientsTemp.filter((row) => {
                  var exists = true;
                  for (const property in filtersList) {
                      if (property !== 'search')
                          exists = filtersList[property].length === 0 ? exists :
                          filtersList[property].includes(row[property]) ? exists : false; 
                      }
                      return exists
                  })
              // console.log('aux', aux)
              setListClients(aux.slice(0,rowsPerPage+101))
              
          },
          onFilterChange : (column, list, e, i) => {
              var filterListTemp = filtersList
              if (search !== '')  filterListTemp['search'] = search
              if (i !== null) {
                  if (!filterListTemp.hasOwnProperty(column)) {
                      filterListTemp[column] = [];
                  }
                  filterListTemp[column] = list[i];
  
              } else {
                  for (const property in filterListTemp) {
                      filterListTemp[property] = []
                  }
              }
              var aux = listFilteredClients.filter((row) => {
                  var exists = true;
                  for (const property in filterListTemp) {
                      exists = filterListTemp[property].length === 0 ? exists :
                      (property === 'search' && filterListTemp['search'] !== '') ? ((Object.values(row).join('|').toLowerCase().indexOf(String(filterListTemp[property])) > -1) ? exists : false ) :
                      filterListTemp[property].includes(row[property]) ? exists : false; 
                  }
                  return exists
              })
              setFiltersList(filterListTemp)
              setLastPage(0)
              setPage(0)
              setListClients(aux.slice(0, rowsPerPage + 101))
          },
          onColumnSortChange: (column, order) => {
              // console.log(column)
              // console.log(order)
              // console.log((listFilteredClients[0]['external_id']))
              // console.log(isNaN(parseFloat(listFilteredClients[0]['external_id'])))
              // console.log((parseFloat(listFilteredClients[0]['external_id'])))
              const orderedList = listFilteredClients.sort(order === 'asc' ? 
              ((a, b) => (a[column] ? (isNaN(parseInt(a[column])) ? a[column].toLowerCase() : parseInt(a[column])) : -2) > (b[column] ? isNaN(parseInt(b[column])) ? b[column].toLowerCase() : parseInt(b[column]) : -1) ? 1 : -1) : 
              ((a, b) => (a[column] ? (isNaN(parseInt(a[column])) ? a[column].toLowerCase() : parseInt(a[column])) : -2) > (b[column] ? isNaN(parseInt(b[column])) ? b[column].toLowerCase() : parseInt(b[column]) : -1) ? -1 : 1))
              setListFilteredClients(orderedList)
              // console.log(orderedList)
              setLastPage(0)
              setPage(0)
              setListClients(orderedList.slice(0,rowsPerPage+101))
          }
    };

    const title = 
        <div>
        <div style={{fontSize: "1.6rem", fontWeight: '400'}}>Monitor Garantias B3</div>
        <div>Total {totalClients}</div>
        </div>

    const getMuiTheme = () =>
    createTheme({
    components: {
        MuiTableCell: {
            styleOverrides: {
                root:{
                        justifyContent: 'center',
                        whiteSpace: "nowrap",
                        textAlign: 'center',
                }
            },
        },
        MUIDataTableHeadCell: {
            styleOverrides: {
                contentWrapper: {
                    justifyContent: 'center',
                    whiteSpace: "nowrap",
                }
            }
        },
    },
    })

    return (
        <div style={{marginTop: '3rem', marginLeft: '2rem', marginRight:'2rem'}}>
            <ThemeProvider theme={getMuiTheme()}>
                <MUIDataTable title={title} data={listClients} columns={columns} options={options}></MUIDataTable>
            </ThemeProvider>
        </div>
    );
}