import React, { useEffect, useState, useRef, useCallback } from "react"

import { api } from "../../../services/api"
import { useAuth } from "../../../contexts/useAuth"
import { useModal } from "../../../contexts/useModal"

import Modal from "../../../components/modal/modal"
import Alert from "../../../components/alert/alert"

import { useMenu } from "../../../contexts/useMenu"
import { ConfirmationBox } from "../../../components/confirmationBox/confirmationBox"

import { FixedIncomeProductsTable } from "./tables/fixedIncome.products.table"
import { FixedIncomeAgiosTable } from "./tables/fixedIncome.agios.table"
import { FixedIncomeDuesTable } from "./tables/fixedIncome.dues.table"
// import { FixedIncomeDuesTable } from "./tables/fixedEmissions.dues.table"

import { InvestmentsEditScenarios } from "./investmentsEditScenarios"

// Components
import CampainsCarrousel from "../dashboard/components/campainsCarrousel"
import { getCampaignsArea } from "../../../services/asyncFunctions"

import { VscGraphLine } from "react-icons/vsc"
import { ArrowLoader } from "../../../components/loaders/arrowLoader"
import { CampainSpeech } from "../dashboard/speech_modal"

export function FixedIncomeInvestments() {
    const [tabActive, setTabActive] = useState(1)
    const [campaignCards, setCampaignCards] = useState([])
    const { show, setShow, type, setType, edited, setEdited } = useModal()
    const [loading, setLoading] = useState(true)
    const { tokenKey, user } = useAuth()

    async function getCards() {
        let listCards = await getCampaignsArea(tokenKey, false, 2)
        if (getCampaignsArea?.isError) {
            setType({
                name: "alertMsg",
                type: "information",
                value: {
                    message: getCampaignsArea?.error,
                },
            })
            setShow(true)
            setCampaignCards([])
        } else {
            listCards = {
                current: listCards,
            }
            setCampaignCards(listCards)
            setLoading(false)
        }
    }

    // async function getData(){
    //     setLoading(true)
    //     const areaCampaigns = await getCampaignsArea(tokenKey, false, 2);
    //     console.log('dados 1:', areaCampaigns);
    //     setCampaignCards(areaCampaigns.data);
    //     setLoading(false)
    // }

    useEffect(() => {
        getCards()
    }, [])

    return (
        <div style={{ height: "-webkit-fill-available", overflow: "auto", overflowX: "hidden" }}>
            <Modal>
                {type.name === "alertMsg" ? (
                    <ConfirmationBox msgData={type} />
                ) : type.name === "editScenarios" ? (
                    <InvestmentsEditScenarios msgData={type} data={{ page: "RENDA FIXA" }} />
                ) : type.name === "speechModal" ? (
                    <CampainSpeech prop={type} />
                ) : (
                    <></>
                )}
            </Modal>
            {loading ? (
                <ArrowLoader />
            ) : (
                <>
                    <div>
                        <div className="campaignsContainer">
                            {/* <span style={{ fontSize: '24px', marginBottom: '1rem'}}>Campanhas em Andamento da Categoria</span> */}
                            {campaignCards?.current?.length === 0 ? (
                                <div style={{ fontSize: "32px", textAlign: "center" }}>Não há campanhas em andamento para esta categoria.</div>
                            ) : (
                              <CampainsCarrousel searchArea={''} selectedCampaign={null} setSelectedCampaign={null} listProducts={campaignCards} campaignPriority={null} productPage={true} />
                            )}
                            <div className="actionsDash">
                                <button
                                    style={{ background: "#C29C00", color: "white", textAlign: "center" }}
                                    className="dashButton"
                                    onClick={() => {
                                        window.open("https://conteudos.xpi.com.br/assessor/rendafixa/rf-comparador-de-troca/", "_blank", "noopener,noreferrer")
                                    }}
                                >
                                    <VscGraphLine size={50} />
                                    Comparador de Renda Fixa
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="testeBrokerDiv">
                        <div className="unsuitedStructuredContainer">
                            <div className="estructuredsBody">
                                <div className="estructuredsTab">
                                    <div className={tabActive === 1 ? "estructuredsTabOption360" : "estructuredsInactiveTabOption360"} onClick={() => setTabActive(1)}>
                                        <p>Produtos</p>
                                    </div>
                                    <div className={tabActive === 2 ? "estructuredsTabOption360" : "estructuredsInactiveTabOption360"} onClick={() => setTabActive(2)}>
                                        <p>Vencimentos</p>
                                    </div>
                                    <div className={tabActive === 3 ? "estructuredsTabOption360" : "estructuredsInactiveTabOption360"} onClick={() => setTabActive(3)}>
                                        <p>Ágios</p>
                                    </div>
                                </div>

                                {user.type === 4 || user.type < 3 ? (
                                    tabActive === 1 ? (
                                        <FixedIncomeProductsTable />
                                    ) : tabActive === 2 ? (
                                        <FixedIncomeDuesTable />
                                    ) : tabActive === 3 ? (
                                        <FixedIncomeAgiosTable />
                                    ) : (
                                        <></>
                                    )
                                ) : (
                                    <></>
                                )}
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    )
}
