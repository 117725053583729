import React, { useEffect, useState, useReducer } from "react";
/*Contexts*/
import { useAuth } from "../../../../contexts/useAuth";
import { useModal } from "../../../../contexts/useModal";
/*Services*/
import { api } from "../../../../services/api";
/*Components*/
// import { ArrowLoader } from "../../../../components/loaders/arrowLoader";
/*Utils*/
import { toBRL } from "../../../../utils/formatNumberAndText/toBRLCurrency";
import { titleize } from "../../../../utils/formatNumberAndText/formatTextUpperFirstLetter";
import { MdOutlineMonetizationOn, MdOutlineCheckCircleOutline, MdOutbox,MdSearch } from "react-icons/md";
import { getABCAvailableOperations } from "../../../../services/asyncFunctions"
import { getAssetsList } from '../../../../services/asyncFunctions';
import { FiStar,FiArrowLeft } from 'react-icons/fi';
import { FaStar } from 'react-icons/fa';
import { RiArrowRightDownFill, RiArrowRightUpFill } from 'react-icons/ri';
import { ArrowLoader } from "../../../../components/loaders/arrowLoader";
import { styled } from '@mui/material/styles';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import { styleClientsInput } from '../../../../components/styles/autocompletField';
import { TextField, Checkbox, FormControlLabel } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { getOffers } from '../../../../services/asyncFunctions'
import { datetimeToDatePtBR } from "../../../../utils/formatDate/dateTimeToPTBR";




import './abcModal.css'

export function AbcClientBoletarOfertaPublica(props) {
  const { tokenKey, user } = useAuth();
  const { show, setShow, type, setType, edited, setEdited } = useModal();
  const [loading, setLoading] = useState(true);
  const [product, setProduct] = useState([]);
  const [client, setClient] = useState();
  const [selectedQtd, setSelectedQtd] = useState(0)
  const [selectedValue, setSelectedValue] = useState('')
  const classes = styleClientsInput()
  const [selectedOrder, setSelectedOrder] = useState()
  const [, forceUpdate] = useReducer(x => x + 1, 0);
  const [offer, setOffer]= useState()
  const [alertMessage, setAlertMessage] = useState('')
  const [emailCliente, setEmailCliente] = useState("")
  const [selectedObservation, setSelectedObservation] = useState('')
  const [vinculated, setVinculated] = useState(false)
  const [justRegister, setJustRegister] = useState(false)
  const requiredFields = [ 
  { field: 'selectedValue', value: selectedValue }, 
  { field: 'emailClient', value: emailCliente }]
  const [errorFields, setErrorFields] = useState({
      selectedClient: false,
      selectedValue: false
  })

  
  useEffect(() => {
    forceUpdate();
  }, [selectedOrder])

  /////////////////////////////////////////////////////////
  ////////Funções para Boletar Ofertas Públicas////////////
  /////////////////////////////////////////////////////////

  async function offersGet() {
    const offersGet = await getOffers(tokenKey)
    if (offersGet?.isError) {
        setType({
            name: 'alertMsg',
            type: 'information',
            value: {
                message: offersGet?.error
            }
        })
    } else {
        const OfferFiltered = offersGet.filter((offer) => {
            if (offer.id === props?.prop?.props?.product?.abc_product_reference_id) {
                return offer
            }
        })
        if(OfferFiltered[0] != undefined){
          setOffer(OfferFiltered[0])
        }
        else{
          setAlertMessage('Produto não encontrado')
        }
    }
}

  async function loadInfosOfertas(){
    setLoading(true)
    offersGet()

    await setProduct(props?.prop?.props?.product)
    await setClient(props?.prop?.props?.client)
    await setEmailCliente(props?.prop?.props?.client?.email)
    setLoading(false)
  }

  function validFields() {

    let errors = false
    requiredFields.map((field) => {
        if (justRegister && field.field == 'emailClient') {

        }
        else if (field.value === '' || field.value === undefined || field.value === null) {
            errorFields[field.field] = true
            errors = true
        }
    })
    return errors
}

  async function handleSubmitOffersOrder(e) {

    if (validFields()) {
        setLoading(true)
        setTimeout(() => {
            setLoading(false)
            setSelectedValue('')
            return alert('Campos obrigatórios não preenchidos, verifique')
        }, 300)
    } else {
        setLoading(true)

        const modalitiesOptions = []
        const options = Object.keys(offer)
        const validyOptions = options.filter((fees) => {


          //alerado os campos conforme solicitado Guilherme Utiama 04/03/2022
          //fee_retail_with_lockup == FEE Sobras
          //fee_retail_without_lockup == FEE Direito de preferência
          //fee_institution_with_lockup == FEE Publica
          //fee_institution_without_lockup == FEE Lote adicional
          if (fees === 'fee_retail_with_lockup') {
              if (offer[fees] !== null && offer[fees] !== undefined) {
                  modalitiesOptions.push('FEE Sobras')
              }
          }

          if (fees === 'fee_retail_without_lockup') {
              if (offer[fees] !== null && offer[fees] !== undefined) {
                  modalitiesOptions.push('FEE Direito de preferência')
              }
          }

          if (fees === 'fee_institution_with_lockup') {
              if (offer[fees] !== null && offer[fees] !== undefined) {
                  modalitiesOptions.push('FEE Pública')
              }
          }

          if (fees === 'fee_institution_without_lockup') {
              if (offer[fees] !== null && offer[fees] !== undefined) {
                  modalitiesOptions.push('FEE Lote adicional')
              }
          }
      })


        try {

            const postOffersOrders = await api.post(`/api/v1/offersOrders`, {
              "offers_id": offer.id,
              "clients_external_id": parseInt(client.client_id),
              "value": parseInt(selectedValue),
              "modality": String(modalitiesOptions[0]),
              "observation": selectedObservation !== '' ? selectedObservation : undefined,
              "vinculated": vinculated,
              "justRegister": justRegister,
              "email": emailCliente !== '' ? emailCliente : undefined
            }, {
                headers: {
                    authorization: `Bearer ${tokenKey}`
                }
            })
            // setType({ name: 'alertMsg', type: 'information', value: { message: "Oferta enviada com sucesso!" } })
            setEdited(!edited)
            alert('Oferta enviada com sucesso!')
            setLoading(false)
            setSelectedValue(0)
            setSelectedQtd(0)
            // clear()
        } catch (error) {
            console.log(error?.response)
            setType({ name: 'alertMsg', type: 'information', value: { message: error?.response?.data?.message === undefined ? 'Erro ao solicitar reserva' : error.response.data.message } })
            setShow(true)
            setLoading(false)
        }
    }
    setLoading(false)
}

  /////////////////////////////////////

  useEffect(() => {
    if (props.prop.props.product.type.id == 2) {
      loadInfosOfertas()
    }
  }, [])

  const handleValueChange = (event) => {
    const { value } = event.target;
    setSelectedQtd(parseInt(value.replaceAll('.', '')));
  };

  const handleValueChangeOffer = (event) => {
    const { value } = event.target;
    if (value != '')
      setSelectedValue(parseInt(value.replaceAll('.', '')));
    else{
      setSelectedValue(0)
    }
  };

  const formatNumberWithThousandsSeparator = (number) => {
    const cleanedValue = String(number).replaceAll('.', ''); // Remove os pontos
    const parts = cleanedValue.toString().split('');
    const formattedParts = [];
    let currentIndex = parts.length - 1;
    while (currentIndex >= 0) {
      const currentPart = parts[currentIndex];
      formattedParts.unshift(currentPart);
      if ((parts.length - currentIndex) % 3 === 0 && currentIndex !== 0) {
        formattedParts.unshift('.');
      }
      currentIndex--;
    }
    return formattedParts.join('');
  };

  return (
    <div className="containerModalAbcDetails">
            <div className="clientControlHeader">
                <div className="editBrancheBoxHeader">
                    <button style={{marginLeft: '1rem'}} title="Voltar" onClick={() => {
                      const pageToreturn = props?.prop?.props?.returnTo
                      setType({ 
                        name: pageToreturn, 
                        product:  props?.prop?.props?.product, 
                        client: props?.prop?.props?.client,
                        products: props?.prop?.props?.products,
                        outros: props?.prop?.props?.outros,
                        prioritys: props?.prop?.props?.prioritys,
                        clientData: props?.prop?.props?.clientData,
                        doSum: props?.prop?.props?.doSum,
                      });
                      }} className="modalCloseButton">
                        <FiArrowLeft/>
                    </button>
                    <div className="containerProductName">
                      {(!loading && alertMessage =='') ?
                        <>
                          <p>{product?.type?.description}: {product?.description} </p>
                          <p style={{marginLeft: '2rem'}}>Cliente: {client?.client} - {client?.client_id}</p>
                        </>
                      : <p>{alertMessage}</p>}
                    </div>
                    <button
                      onClick={() => setShow(false)}
                      className="modalCloseButton">
                        X
                    </button>
                </div>
            </div>
            {loading ? 
            <div className='clientsCadastrationLoading'>
                <ArrowLoader />
            </div> :
            alertMessage != '' ? 
            <div className='clientsCadastrationLoading'>
              <h1>{alertMessage} </h1>
            </div>
            :
              <div className="containerBoletarAbc">
                  <div className="containerListproductsAbc">
                    {alertMessage} 
                    <div className="listOperationProductsAbc">
                        <div className='productCardActiveAbc'>
                          <div className="productCardAbcHeader">
                            <p></p>
                            <p style={{color: '#5E4C0B'}} title="Ativo">{offer?.asset}</p>
                            <p style={{color: '#5E4C0B'}}title='Valor'>
                              <label>
                                <span>{offer?.value ? toBRL(offer?.value) : <></>}</span>
                              </label>
                            </p>
                            <p style={{color: '#5E4C0B'}}title='Vencimento'>{offer?.finish_vnc ? datetimeToDatePtBR(offer?.finish_vnc) : <></>}</p>
                            <p></p>
                          </div>
                          <div className="divisionHeaderCardAbc"></div>
                          <div className="offerCardAbcInfos">
                                <div className="offerItemAbc"><span style={{fontWeight: '400', fontSize: '1.1rem'}}>Recomentação: </span><span style={{textAlign: 'right'}}>{offer?.recommendation == true ? 'Entrar' : 'Não entrar'}</span></div>
                                <div className="offerItemAbc"><span style={{fontWeight: '400', fontSize: '1.1rem'}}>Setor: </span><span style={{textAlign: 'right'}}>{offer?.assets_sector_description}</span></div>
                                <div className="offerItemAbc"><span style={{fontWeight: '400', fontSize: '1.1rem'}}>Inicio de reserva: </span><span style={{textAlign: 'right'}}>{datetimeToDatePtBR(offer?.start_of_reserve)}</span></div>
                                <div className="offerItemAbc"><span style={{fontWeight: '400', fontSize: '1.1rem'}}>Término Vinculados: </span><span style={{textAlign: 'right'}}>{datetimeToDatePtBR(offer?.finish_vnc)}</span></div>
                                <div className="offerItemAbc"><span style={{fontWeight: '400', fontSize: '1.1rem'}}>Término Reservas: </span><span style={{textAlign: 'right'}}>{datetimeToDatePtBR(offer?.finish_of_reserve)}</span></div>
                                <div className="offerItemAbc"><span style={{fontWeight: '400', fontSize: '1.1rem'}}>Bookbuildind: </span><span style={{textAlign: 'right'}}>{datetimeToDatePtBR(offer?.bookbuilding)}</span></div>
                                <div className="offerItemAbc"><span style={{fontWeight: '400', fontSize: '1.1rem'}}>Início negociações: </span><span style={{textAlign: 'right'}}>{datetimeToDatePtBR(offer?.start_negotiation)}</span></div>
                                <div className="offerItemAbc"><span style={{fontWeight: '400', fontSize: '1.1rem'}}>Liquidação: </span><span style={{textAlign: 'right'}}>{datetimeToDatePtBR(offer?.liquidation)}</span></div>
                                <div className="offerItemAbc"><span style={{fontWeight: '400', fontSize: '1.1rem'}}>Quantia mínima: </span><span style={{textAlign: 'right'}}>{offer?.minimum}</span></div>
                                <div className="offerItemAbc"><span style={{fontWeight: '400', fontSize: '1.1rem'}}>Prospecto: </span><a style={{textAlign: 'right'}} href={offer?.prospecto} target="_blank">Acessar</a></div>
                        </div>
                    </div>
                    </div>
                  </div>
                  <div className="divisionNameProductSubcategory"></div>
                    <div className="containerOperateProductAbc">
                      <div className="headerOperateProductAbc">
                          <p style={{textAlign: 'center'}}>{offer?.asset}</p>
                          <p style={{textAlign: 'center', marginTop: '1rem'}}>{offer?.offers_types_description}</p>

                      </div>

                      <div className="contentBodyOperateOfferAbc">

                      <div className="itemBodyOperateOfferAbc fieldsOperateOfferAbc">
                        <TextField
                            onKeyPress={(e) => { if (e.code === 'Backquote') e.preventDefault() }}
                            label={'Insira um email'}
                            variant='outlined'
                            onChange={(e) => {
                                setEmailCliente(e.target.value)
                            }}
                            value={emailCliente}
                            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                            className={classes.styleForClients}
                        />

                        {justRegister ? <div style={{color: "red", height: '1rem'}}></div> :
                          <div style={{color: "red", height: '1rem', fontSize: '0.8rem', textAlign: 'center', marginBottom: '0.5rem'}}>&#x26A0; Utilizar o email de cliente do hub para fins de auditoria &#x26A0;</div>
                        }
                        </div>

                        <div className="itemBodyOperateOfferAbc fieldsOperateOfferAbc">
                              <TextField
                                variant="outlined"
                                label='Quantidade'
                                value={formatNumberWithThousandsSeparator(selectedValue)}
                                className={classes.styleForClients}
                                inputProps={{
                                  inputMode: 'numeric',
                                  pattern: '[0-9]*',
                                }}
                                onChange={(e) => {
                                  handleValueChangeOffer(e);
                                }}

                              />
                        </div>

                        <div className="itemBodyOperateOfferAbc fieldsOperateOfferAbc">
                          <TextField
                              className={classes.textArea}
                              multiline
                              // rows={4} // Definindo 4 linhas
                              rowsMax={4}
                              variant='outlined'
                              onKeyPress={(e) => { if (e.code === 'Backquote') e.preventDefault() }}
                              placeholder='Observações'
                              inputProps={{ maxLength: '200' }}
                              onChange={(e) => setSelectedObservation(e.target.value)}
                          />
                        </div>

                        <div className="itemBodyOperateProductAbc fieldsOperateAbc">
                            <a style={{marginRight: '1rem'}}>Financeiro total estimado:</a>
                            <a>
                                {
                                  toBRL(selectedValue * offer?.value)
                                }
                            </a>
                          </div>

                        <div className="itemBodyOperateOfferAbc fieldsOperateOfferAbc">
                              <FormControlLabel 
                                  control={
                                      <Checkbox
                                          checked={vinculated}
                                          onChange={(e) => setVinculated(!vinculated)}
                                          name="isVinculated"
                                          color='primary'
                                      />
                                  }
                                  label={'Vínculo com a XP?'}
                              />
                        </div>

                        <div className="itemBodyOperateOfferAbc" style={{marginLeft: "20%" , marginRight: "20%"}}>
                              <FormControlLabel 
                                  control={
                                      <Checkbox
                                          checked={justRegister}
                                          onChange={(e) => setJustRegister(!justRegister)}
                                          name="isJustRegister"
                                          color='primary'
                                      />
                                  }
                                  label={'Apenas registro (sem disparo de email)'}
                              />
                        </div>

                      </div>

                      <div className="boxSubmitOperateProductAbc">
                        <button className="buttonOperateSubmitABC" onClick={() => handleSubmitOffersOrder()}>Enviar</button>
                      </div>
                    </div>
              </div>
            }
        </div>
  );
}