/*Componente cadastro de clientes
/*Descrição: Efetua o cadastro de novos clientes no sistema e chama o componente de importação
/*Desenvolvido por: Equipe Rafael Cezário
*/

import React, { useState, useEffect, useRef, useReducer } from "react"
import { TextField, Select, MenuItem } from "@material-ui/core"
import { Autocomplete } from "@material-ui/lab"
//styles
import "./abcModal.css"
import { styleClientsInput } from "../../../../components/styles/autocompletField"
//services
import { api } from "../../../../services/api"
//hooks
import { useModal } from "../../../../contexts/useModal"
//components
import { ArrowLoader } from "../../../../components/loaders/arrowLoader"
//utils
import { ModalSizeFitToScreen } from "../../../../utils/responsiveFunctions/modalSizeFitToScreen"
import { FiMinus, FiPlus } from "react-icons/fi"
import { MdSentimentDissatisfied } from "react-icons/md"
import { ImEyeBlocked, ImEye } from "react-icons/im";

export function AbcManagementUpdateModal(props) {
    const [selectedProductNetAderence, setSelectedProductNetAderence] = useState("")
    const [selectedProductName, setSelectedProductName] = useState("")
    const [selectedProductStart, setSelectedProductStart] = useState("")
    const [selectedProductFinish, setSelectedProductFinish] = useState("")
    const [selectedProductLiquidacao, setSelectedProductLiquidacao] = useState("")
    const [selectedProductAderence, setSelectedProductAderence] = useState("")
    const [selectedProductType, setSelectedProductType] = useState(null)
    const [selectedProduct, setSelectedProduct] = useState(null)
    const [selectedProductArea, setSelectedProductArea] = useState(null)
    const [selectedPriority, setSelectedPriority] = useState(null)
    const [selectedProductDescription, setSelectedProductDescription] = useState("")
    const [selectedProductCardLink, setSelectedProductCardLink] = useState("")
    const [internalSpeech, setInternalSpeech] = useState("")
    const [speech, setSpeech] = useState("")
    const [areas, setAreas] = useState([])
    const [liquidationTypes, setLiquidationTypes] = useState({0: 'Oferta', 1: 'Plataforma'})
    const [selectedLiquidationType, setSelectedLiquidationType] = useState(0)
    const [liquidacaoPlataforma, setLiquidacaoPlataforma] = useState(0)
    const [types, setTypes] = useState([])
    const [estruturadas, setEstruturadas] = useState([])
    const [offers, setOffers] = useState([])
    const classes = styleClientsInput()
    const token = localStorage.getItem("@auth:token")
    const { show, setShow, setType, edited, setEdited } = useModal()
    const [loading, setLoading] = useState(true)
    const { screenY } = ModalSizeFitToScreen()
    const [categories, setCategories] = useState([])
    const [fee, setFee] = useState(0)
    const [fator, setFator] = useState()
    const [priorities, setPriorities] = useState([])
    const [, forceUpdate] = useReducer((x) => x + 1, 0)
    const [selectedTipoInvestidor, setSelectedTipoInvestidor] = useState('')
    const tipoInvestidor = [
        {
            value: 1,
            label: 'Geral'
        },
        {
            value: 2,
            label: 'Qualificado'
        },
    ]

    const requiredFields = [
        { field: "selectedProductName", value: selectedProductName },
        { field: "selectedProductDescription", value: selectedProductDescription },
        { field: "selectedProductArea", value: selectedProductArea },
        { field: "selectedProductType", value: selectedProductType },
        { field: "selectedProductAderence", value: selectedProductAderence },
        { field: "selectedProductNetAderence", value: selectedProductNetAderence },
        { field: "selectedProductStart", value: selectedProductStart },
        { field: "selectedProductFinish", value: selectedProductFinish },
        { field: "selectedProductLiquidacao", value: selectedProductLiquidacao },
    ]
    const [errorFields, setErrorFields] = useState({
        selectedProductName: false,
        selectedProductDescription: false,
        selectedProductArea: false,
        selectedProductType: false,
        selectedProduct: false,
        selectedProductAderence: false,
        selectedProductNetAderence: false,
        selectedProductStart: false,
        selectedProductFinish: false,
        selectedProductLiquidacao: false,
        selectedCategories: false,
    })

    useEffect(async () => {
        if (show) {
            setLoading(true)
            getOperations()
            getArea()
            // getTypes()
            getProduct()
        } else clear()
    }, [show])

    function generateUUID() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
          var r = Math.random() * 16 | 0,
              v = c === 'x' ? r : (r & 0x3 | 0x8);
          return v.toString(16);
        });
      }

    async function alterCategoryLink(id, link) {
        if (link == "") {
            link = null
        }
        categories[id].link = link
    }

    async function addCategory() {
        setCategories([
            ...categories,
            {
                title: null,
                link: null,
                id:  generateUUID()    //id temporario para identificar que é uma categoria nova randomico
            },
        ])
    }

    async function removeCategory(id) {
        //pega a posição onde categories.id == id
        const index = categories.findIndex((x) => x.id == id)
        //se nao der erro, remove da lista
        categories.splice(index, 1)
        setCategories([...categories])
    }

    async function handleSubmit(e) {
        let errors = false
        const aux = errorFields

        if (categories.length == 0) {
            aux["selectedCategories"] = true
            errors = true
        } else {
            aux["selectedCategories"] = false
            categories.map((x) => {
                if (x.title == null || x.title == undefined || x.title == "") {
                    aux["selectedCategories"] = true
                    errors = true
                }
                if (x.link != null && x.link != undefined && !x.link?.trim().length) {
                    aux["selectedCategories"] = true
                    errors = true
                }
            })
        }

        requiredFields.map((required) => {
            if (typeof required.value !== "object" && !required?.value?.toString()?.trim()?.length) {
                aux[required.field] = true
                errors = true
            } else {
                aux[required.field] = false
            }
        })
        setErrorFields(aux)

        if (errors) {
            alert("Campos obrigatórios não preenchidos")
            setLoading(false)
            return
        }

        e.preventDefault()

        if (
            selectedProductName === "" ||
            selectedProductType === "" ||
            selectedProductAderence === "" ||
            selectedProductNetAderence === "" ||
            selectedProductStart === "" ||
            selectedProductFinish === "" ||
            selectedProductLiquidacao === ""
        ) {
            alert("Preencha todos os campos")
            return
        }

        categories.map((x) => {
            if (x.title === "") {
                alert("Preencha todos os campos")
                return
            }
        })

        //verifica se o id da categoria é um id temporario, se for, remove o id
        categories.forEach((x) => {
            //verifica se o id é do tipo int
            if (isNaN(x.id)) {
                x.id = null
            }
        }   )

        selectedLiquidationType === 0 ? setLiquidacaoPlataforma(null) : setSelectedProductLiquidacao(null)
        console.log('fator', parseFloat((parseFloat(fator) / 100).toFixed(6)))
        try {
            setLoading(true)
            await api.put(
                `/api/v1/abc/config/products/${props.prop.product}`,
                {
                    product: {
                        description: selectedProductName,
                        type: selectedProductType.id,
                        reference: selectedProduct?.id ? parseInt(selectedProduct.id) : null,
                        aderence: parseFloat(selectedProductAderence),
                        net_aderence: parseFloat(selectedProductNetAderence),
                        start: selectedProductStart,
                        finish: selectedProductFinish,
                        liquidation: selectedLiquidationType === 1 ? null : selectedProductLiquidacao,
                        categories: categories,
                        card_description: selectedProductDescription,
                        card_link: selectedProductCardLink,
                        area: selectedProductArea?.id ? parseInt(selectedProductArea.id) : null,
                        fee: fee ? parseFloat((parseFloat(fee) / 100).toFixed(6)) : 0,
                        fator: fator ? parseFloat((parseFloat(fator) / 100).toFixed(6)) : 0,
                        tipo_investidor: selectedTipoInvestidor,
                        priority: selectedPriority?.id ? parseInt(selectedPriority.id) : null,
                        speech: speech ? speech : "",
                        internal_speech: internalSpeech ? internalSpeech : "",
                        liquidation_type: selectedLiquidationType,
                        liquidacao_plataforma: selectedLiquidationType === 1 ? parseInt(liquidacaoPlataforma) : null
                    },
                },
                {
                    headers: {
                        authorization: `Bearer ${token}`,
                    },
                }

            )
            console.log('fator', fator)
            setLoading(false)
            setType({ name: "alertMsg", type: "information", value: { message: `Sucesso ao atualizar o produto ${selectedProductName}` } })
            setEdited(true)
        } catch (error) {
            console.log(error.response)
            alert(error?.response?.data?.message)
            setShow(false)
        }
    }

    async function changeStatusCategory(category_id) {
        categories.find((x) => x.id == category_id).active = !categories.find((x) => x.id == category_id).active
        const status = categories.find((x) => x.id == category_id).active
        //se nao der erro, remove da lista
        try {
            //passa o status no body
            await api.put(`/api/v1/abc/config/categories/${category_id}`, {
                new_status: status ? 1 : 0,
            }
            ,{
                headers: {
                    authorization: `Bearer ${token}`,
                },
            })

            alert(`Categoria ${status ? "habilitada" : "desabilitada"} com sucesso!`)
 
        } catch (error) {
            console.log(error.response)
            alert(error?.response?.data?.message)
        }
    }

    async function getOperations(reference, type) {
        const operations = await api.get(`/api/v1/abc/config/references`, {
            headers: {
                authorization: `Bearer ${token}`,
            },
        })
        if (operations?.data?.data?.estruturadas != null) {
            setEstruturadas(operations.data.data.estruturadas)
        }
        if (operations?.data?.data?.ofertas_publicas != null) {
            setOffers(operations.data.data.ofertas_publicas)
        }

        if (type == 1) {
            var old_operation = operations.data.data.estruturadas.find((x) => x.id == reference)
            setSelectedProduct(old_operation ? old_operation : {})
        } else if (type == 2) {
            var old_operation = operations.data.data.ofertas_publicas.find((x) => x.id == reference)
            setSelectedProduct(old_operation ? old_operation : {})
        }

        return operations?.data?.data
    }

    async function getArea() {
        const area = await api.get(`/api/v1/abc/config/areas`, {
            headers: {
                authorization: `Bearer ${token}`,
            },
        })
        setAreas(area?.data?.data)
        return area?.data?.data
    }

    async function getTypes() {
        const t = await api.get(`/api/v1/abc/config/types`, {
            headers: {
                authorization: `Bearer ${token}`,
                // area_id: 1 // !TIP DO GD: AREA 1 REPRESENTA RENDA VARIÁVEL, SE QUISER TRAZER TODOS OS TIPOS DE TODAS AS ÁREAS, BASTA REMOVER ESSA LINHA
            },
        })
        setTypes(t?.data?.data)
        return t?.data?.data
    }

    async function getPriorities() {
        const p = await api.get(`/api/v1/abc/config/priorities`, {
            headers: {
                authorization: `Bearer ${token}`,
            },
        })
        setPriorities(p?.data?.data)
        return p?.data?.data
    }

    async function getProduct() {
        const res = await api.get(`/api/v1/abc/config/products/${props.prop.product}`, {
            headers: {
                authorization: `Bearer ${token}`,
            },
        })

        console.log(res?.data?.data)

        setSelectedProductName(res?.data?.data?.description)
        const old_type = (await getTypes())?.filter((x) => x.id == res?.data?.data?.type)
        if (old_type[0]?.id == 1) {
            getOperations(res?.data?.data?.reference, 1)
            // old_operation =  (await getOperations())?.estruturadas?.filter((x) => x.id == res?.data?.data?.reference)
        } else if (old_type[0]?.id == 2) {
            getOperations(res?.data?.data?.reference, 2)
            // old_operation =  (await getOperations())?.offers?.filter((x) => x.id == res?.data?.data?.reference)
        }
        const old_priority = (await getPriorities())?.filter((x) => x.id == res?.data?.data?.priority)
        setSelectedPriority(old_priority[0])
        setSelectedProductArea({ id: res?.data?.data?.area_id, value: res?.data?.data?.area_description })
        setSelectedProductType(old_type?.length > 0 ? old_type[0] : {})
        // setSelectedProduct(old_operation?.length > 0 ? old_operation[0] : {})
        setSelectedProductAderence(res?.data?.data?.aderence)
        setSelectedProductNetAderence(res?.data?.data?.net_aderence)
        setSelectedProductStart(res?.data?.data?.start)
        setSelectedProductFinish(res?.data?.data?.finish)
        setSelectedProductDescription(res?.data?.data?.card_description)
        setSelectedProductCardLink(res?.data?.data?.card_link)
        setSelectedProductLiquidacao(res?.data?.data?.liquidation)
        setFator(res?.data?.data?.fator ? (res?.data?.data?.fator * 100).toFixed(6) : 0)
        setSelectedTipoInvestidor(res?.data?.data?.tipo_investidor)
        setFee(res?.data?.data?.fee ? (res?.data?.data?.fee * 100).toFixed(6) : 0)
        res?.data?.data?.categories.forEach((x) => {
            x.showMessage = false
        })
        setCategories(res?.data?.data?.categories)
        setSpeech(res?.data?.data?.speech)
        setInternalSpeech(res?.data?.data?.internal_speech)
        setSelectedLiquidationType(res?.data?.data?.liquidation_type ? (res?.data?.data?.liquidation_type === true ? 1 : 0) : 0)
        setLiquidacaoPlataforma(res?.data?.data?.liquidacao_plataforma)
        setLoading(false)
    }

    function clear() {
        setEstruturadas([])
        setOffers([])
        setCategories([])
        setSelectedProductName("")
        setSelectedProduct(null)
        setSelectedProductType(null)
        setSelectedProductAderence("")
        setSelectedProductNetAderence("")
        setSelectedProductStart("")
        setSelectedProductFinish("")
        setSelectedProductDescription("")
        setSelectedProductCardLink("")
        setSelectedProductArea(null)
        setFee(0)
        setSelectedPriority(null)
        setPriorities([])
        setSpeech("")
        setInternalSpeech("")
        setSelectedLiquidationType(0)
        setLiquidacaoPlataforma(0)
        setErrorFields({
            selectedProductName: false,
            selectedProductType: false,
            selectedProductAderence: false,
            selectedProductNetAderence: false,
            selectedProductStart: false,
            selectedProductFinish: false,
            selectedProductLiquidacao: false,
            selectedCategories: false,
            selectedProductDescription: false,
            selectedProductCardLink: false,
            selectedProductArea: false,
        })
    }

    return (
        <div
            className="productCadastrationContainerAbcCopyWithoutTemplate"
            style={{
                maxHeight: screenY,
            }}
        >
            {loading ? (
                <div className="clientsCadastrationLoading">
                    <ArrowLoader />
                </div>
            ) : (
                <>
                    <div className="productCadastrationHeaderAbc">
                        <span>Efetue a atualização do produto abaixo</span>
                        <button onClick={() => setShow(false)}>x</button>
                    </div>
                    <div className="productCadastrationContainerAbcCopyWithoutTemplate">
                        <div className="productCadastrationUpperUpperGridAbc">
                            <label>
                                <TextField
                                    label="Nome"
                                    onKeyPress={(e) => {
                                        if (e.code === "Backquote") e.preventDefault()
                                    }}
                                    value={selectedProductName}
                                    className={errorFields["selectedProductName"] && !selectedProductName?.trim().length ? classes.styleForClientsWithError : classes.styleForClients}
                                    onBlur={(e) => {
                                        setSelectedProductName(e.target.value)
                                    }}
                                    onFocus={(e) => {
                                        setSelectedProductName(null)
                                    }}
                                    variant="outlined"
                                />
                            </label>

                            <label>
                                <TextField
                                    label="Descrição (para o Assessor)"
                                    onKeyPress={(e) => {
                                        if (e.code === "Backquote") e.preventDefault()
                                    }}
                                    value={selectedProductDescription}
                                    className={errorFields["selectedProductDescription"] && !selectedProductDescription?.trim().length ? classes.styleForClientsWithError : classes.styleForClients}
                                    onBlur={(e) => {
                                        setSelectedProductDescription(e.target.value)
                                    }}
                                    onFocus={(e) => {
                                        setSelectedProductDescription(null)
                                    }}
                                    variant="outlined"
                                    style={{ marginTop: "1rem" }}
                                />
                            </label>

                            <label>
                                <TextField
                                    label="Link de material (para o Assessor)"
                                    onKeyPress={(e) => {
                                        if (e.code === "Backquote") e.preventDefault()
                                    }}
                                    value={selectedProductCardLink}
                                    className={errorFields["selectedProductCardLink"] && !selectedProductCardLink?.trim().length ? classes.styleForClientsWithError : classes.styleForClients}
                                    onBlur={(e) => {
                                        setSelectedProductCardLink(e.target.value)
                                    }}
                                    onFocus={(e) => {
                                        setSelectedProductCardLink(null)
                                    }}
                                    variant="outlined"
                                    style={{ marginTop: "1rem" }}
                                />
                            </label>
                        </div>
                        <div className="productCadastrationUpperGridAbc">
                            <label>
                                <Autocomplete
                                    options={areas}
                                    value={selectedProductArea}
                                    onChange={(event, newValue) => {
                                        setSelectedProductArea(newValue)
                                        setSelectedProductType(null)
                                        setSelectedProduct(null)
                                    }}
                                    getOptionLabel={(option) => option?.value}
                                    getOptionSelected={(option) => option?.value}
                                    id="productType"
                                    renderInput={(params) => (
                                        <TextField
                                            label="Área"
                                            onKeyPress={(e) => {
                                                if (e.code === "Backquote") e.preventDefault()
                                            }}
                                            // className="productCadastrationContainerRowItemAbc"
                                            className={errorFields["selectedProductType"] && !selectedProductType?.id ? classes.styleForClientsWithError : classes.styleForClients}
                                            {...params}
                                            variant="outlined"
                                        />
                                    )}
                                />
                            </label>

                            <label>
                                <Autocomplete
                                    options={types}
                                    value={selectedProductType}
                                    onChange={(event, newValue) => {
                                        setSelectedProductType(newValue)
                                        setSelectedProduct(null)
                                    }}
                                    getOptionLabel={(option) => option?.value}
                                    getOptionSelected={(option) => option?.value}
                                    id="productType"
                                    renderInput={(params) => (
                                        <TextField
                                            label="Tipo do Produto"
                                            onKeyPress={(e) => {
                                                if (e.code === "Backquote") e.preventDefault()
                                            }}
                                            // className="productCadastrationContainerRowItemAbc"
                                            className={errorFields["selectedProductType"] && !selectedProductType?.id ? classes.styleForClientsWithError : classes.styleForClients}
                                            {...params}
                                            variant="outlined"
                                        />
                                    )}
                                />
                            </label>
                            <label>
                                <Autocomplete
                                    options={selectedProductType?.id == 1 ? estruturadas : selectedProductType?.id == 2 ? offers : []}
                                    value={selectedProduct}
                                    onChange={(event, newValue) => {
                                        setSelectedProduct(newValue)
                                    }}
                                    getOptionLabel={(option) => option?.value}
                                    getOptionSelected={(option) => option?.value}
                                    id="productOperation"
                                    renderInput={(params) => (
                                        <TextField
                                            label="Produto"
                                            onKeyPress={(e) => {
                                                if (e.code === "Backquote") e.preventDefault()
                                            }}
                                            className={errorFields["selectedProduct"] && !selectedProduct?.id ? classes.styleForClientsWithError : classes.styleForClients}
                                            {...params}
                                            variant="outlined"
                                        />
                                    )}
                                />
                            </label>
                            {selectedProductArea && selectedProductArea?.id == 1 && (
                                <>
                                    <label>
                                        <TextField
                                            label="Aderencia"
                                            // onKeyPress={(e) => { if (e.code === 'Backquote') e.preventDefault() }}
                                            value={selectedProductAderence}
                                            type="number"
                                            className={errorFields["selectedProductAderence"] && selectedProductAderence == "" ? classes.styleForClientsWithError : classes.styleForClients}
                                            onChange={(e) => {
                                                if (e.target.value <= 100) {
                                                    setSelectedProductAderence(e.target.value)
                                                } else {
                                                    setSelectedProductAderence(100)
                                                }
                                            }}
                                            variant="outlined"
                                        />
                                    </label>
                                    <label>
                                        <TextField
                                            label="Aderencia NET"
                                            // onKeyPress={(e) => { if (e.key === 'e' || e.key === 'E' || e.code === 'Backquote') { e.preventDefault() } }}
                                            value={selectedProductNetAderence}
                                            type="number"
                                            className={errorFields["selectedProductNetAderence"] && selectedProductNetAderence == "" ? classes.styleForClientsWithError : classes.styleForClients}
                                            onChange={(e) => {
                                                if (e.target.value <= 100) {
                                                    setSelectedProductNetAderence(e.target.value)
                                                } else {
                                                    setSelectedProductNetAderence(100)
                                                }
                                            }}
                                            // onFocus={(e) => { setSelectedProductNetAderence(null) } }
                                            variant="outlined"
                                        />
                                    </label>
                                </>
                            )}
                            <label>
                                <TextField
                                    label="Início"
                                    value={selectedProductStart}
                                    InputLabelProps={{ shrink: true }}
                                    type="date"
                                    className={errorFields["selectedProductStart"] && !selectedProductStart?.trim()?.length ? classes.styleForClientsWithError : classes.styleForClients}
                                    onChange={(e) => {
                                        setSelectedProductStart(e.target.value)
                                    }}
                                    variant="outlined"
                                />
                            </label>
                            <label>
                                <TextField
                                    label="Fim"
                                    value={selectedProductFinish}
                                    type="date"
                                    InputLabelProps={{ shrink: true }}
                                    className={errorFields["selectedProductFinish"] && !selectedProductFinish?.trim()?.length ? classes.styleForClientsWithError : classes.styleForClients}
                                    onChange={(e) => {
                                        setSelectedProductFinish(e.target.value)
                                    }}
                                    variant="outlined"
                                />
                            </label>
                            <label>
                                <Select
                                    value={selectedLiquidationType}
                                    onChange={(event, newValue) => {
                                        setSelectedLiquidationType(newValue.props.value)
                                    }}
                                    id="liquidation_type"
                                    className={errorFields["selectedLiquidationType"] && !selectedLiquidationType?.id ? classes.styleForClientsWithError : classes.styleForClients}
                                >
                                    <MenuItem value={0}>Oferta</MenuItem>
                                    <MenuItem value={1}>Plataforma</MenuItem>
                                </Select>
                            </label>
                            {
                                selectedLiquidationType == 0 ?
                            <label>
                                <TextField
                                    label="Liquidação"
                                    value={selectedProductLiquidacao}
                                    type="date"
                                    InputLabelProps={{ shrink: true }}
                                    className={errorFields["selectedProductLiquidacao"] && !selectedProductLiquidacao?.trim()?.length ? classes.styleForClientsWithError : classes.styleForClients}
                                    onChange={(e) => {
                                        setSelectedProductLiquidacao(e.target.value)
                                    }}
                                    variant="outlined"
                                />
                            </label> :
                            <label>
                            <TextField
                                label="Liquidação D+x"
                                onChange={(e) => {
                                    setLiquidacaoPlataforma(e.target.value)
                                }}
                                value={liquidacaoPlataforma}
                                type="number"
                                InputLabelProps={{ shrink: true }}
                                className={errorFields["liquidacaoPlataforma"] && !selectedLiquidationType?.trim()?.length ? classes.styleForClientsWithError : classes.styleForClients}
                                variant="outlined"
                            />
                            </label>
                            }
                            <label>
                                <TextField
                                    label="FEE (%)"
                                    value={fee}
                                    type="number"
                                    className={classes.styleForClients}
                                    onChange={(e) => {
                                        setFee(e.target.value)
                                    }}
                                    variant="outlined"
                                />
                            </label>
                            <label>
                                <TextField
                                    label="FATOR (%)"
                                    value={fator}
                                    type="number"
                                    className={classes.styleForClients}
                                    onChange={(e) => {
                                        setFator(e.target.value)
                                        console.log(fator)
                                    }}
                                    variant="outlined"
                                />
                            </label>
                            <label>
                                <TextField
                                    id="outlined-select-currency"
                                    select
                                    onChange={(e) => setSelectedTipoInvestidor(e.target.value)}
                                    value={selectedTipoInvestidor  ? selectedTipoInvestidor : ''} 
                                    label="Tipo Investidor"
                                    // helperText="Tipo de Investidor"
                                    >
                                    {tipoInvestidor.map((option) => (
                                        <MenuItem key={option.value} value={option.label}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </label>

                            {selectedProductArea && selectedProductArea?.id > 0 && (
                                <label>
                                    <Autocomplete
                                        options={priorities}
                                        value={selectedPriority}
                                        onChange={(event, newValue) => {
                                            setSelectedPriority(newValue)
                                        }}
                                        getOptionLabel={(option) => option?.value}
                                        getOptionSelected={(option) => option?.value}
                                        id="priority"
                                        renderInput={(params) => (
                                            <TextField
                                                label="Prioridade da campanha"
                                                onKeyPress={(e) => {
                                                    if (e.code === "Backquote") e.preventDefault()
                                                }}
                                                // className="productCadastrationContainerRowItemAbc"
                                                className={classes.styleForClients}
                                                {...params}
                                                variant="outlined"
                                            />
                                        )}
                                    />
                                </label>
                            )}
                        </div>
                        <div className="productCadastrationUpperUpperGridAbc">
                            <label>
                                <TextField
                                    label="Discurso interno (opcional, aparece quando não há discurso interno para a campanha)"
                                    onKeyPress={(e) => {
                                        if (e.code === "Backquote") e.preventDefault()
                                    }}
                                    value={internalSpeech}
                                    multiline
                                    minRows={2}
                                    maxRows={4}
                                    onBlur={(e) => {
                                        setInternalSpeech(e.target.value)
                                    }}
                                    onFocus={(e) => {
                                        setInternalSpeech(null)
                                    }}
                                    variant="outlined"
                                    style={{ marginTop: "1rem" }}
                                />
                            </label>

                            <label>
                                <TextField
                                    label="Discurso Comercial (opcional, aparece quando não há discurso comercial para o cliente)"
                                    onKeyPress={(e) => {
                                        if (e.code === "Backquote") e.preventDefault()
                                    }}
                                    value={speech}
                                    multiline
                                    minRows={2}
                                    maxRows={4}
                                    onBlur={(e) => {
                                        setSpeech(e.target.value)
                                    }}
                                    onFocus={(e) => {
                                        setSpeech(null)
                                    }}
                                    variant="outlined"
                                    style={{ marginTop: "1rem", marginBottom: "3rem" }}
                                />
                            </label>
                        </div>
                        <div className="productCadastrationCategoriesGridAbc">
                            {categories ? (
                                categories?.map((v, i) => {
                                    return v?.showMessage ? (
                                        //mensagem de confirmação
                                        <div key={v?.id} style={{margin: '1rem 3rem', textAlign: 'center', height: '4rem'}}>
                                            <span style={{display: 'grid'}}>

                                            <label>Tem certeza que deseja desabilitar essa categoria? {v.clients} clientes deixarão de aparecer para o produto.</label>
                                            
                                            <label>Essa ação pode ser desfeita.</label>
                                            </span>
                                            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', marginTop: '0.5rem'}}>

                                            <button
                                                className="buttonExcludeBg"
                                                onClick={() => {
                                                    changeStatusCategory(v.id)
                                                    v.showMessage = false
                                                    forceUpdate()
                                                }}
                                            >
                                                Desabilitar
                                            </button>
                                            <button
                                                className="buttonWhiteBg"
                                                style={{margin: 'auto'}}
                                                onClick={() => {
                                                    v.showMessage = false
                                                    forceUpdate()
                                                }}
                                            >
                                                Cancelar
                                            </button>
                                            </div>
                                        </div>
                                    ) : (
                                        <>
                                            <div key={v?.id} className="productCadastrationCategoryRowAbc" style={{height: '2rem', paddingTop: '1rem', paddingBottom: '1rem',  gridTemplateColumns: '1fr 4fr 4fr auto'}}>
                                                <label>
                                                    <TextField
                                                        key={"key" + v?.id + "title"}
                                                        id={"c" + v?.id + "title"}
                                                        onKeyPress={(e) => {
                                                            if (e.code === "Backquote") e.preventDefault()
                                                        }}
                                                        label={"ID"}
                                                        value={!isNaN(v.id) ? v.id : "-"}
                                                        inputProps={{ style: { textAlign: 'center' }, readOnly: true }}
                                                        style={{ textAlign: 'center' }}
                                                        className={errorFields["selectedCategories"] && !v.title?.trim()?.length ? classes.styleForClientsWithError : classes.styleForClients}
                                                        variant="outlined"
                                                    />
                                                </label>
                                                <label>
                                                    <TextField
                                                        key={"key" + v?.id + "title"}
                                                        id={"c" + v?.id + "title"}
                                                        onKeyPress={(e) => {
                                                            if (e.code === "Backquote") e.preventDefault()
                                                        }}
                                                        label={"Título do discurso " + (i + 1) + " da campanha"}
                                                        defaultValue={v.title}
                                                        onChange={(e) => {
                                                            v.title = e.target.value
                                                        }}
                                                        className={errorFields["selectedCategories"] && !v.title?.trim()?.length ? classes.styleForClientsWithError : classes.styleForClients}
                                                        variant="outlined"
                                                    />
                                                </label>
                                                <label>
                                                    <TextField
                                                        key={"key" + v?.id + "link"}
                                                        id={"c" + v?.id + "link"}
                                                        onKeyPress={(e) => {
                                                            if (e.code === "Backquote") e.preventDefault()
                                                        }}
                                                        label={"Link do discurso " + (i + 1) + " (opcional)"}
                                                        defaultValue={v.link != 'NULL' ? v.link : ""}
                                                        onChange={(e) => {
                                                            alterCategoryLink(i, e.target.value)
                                                        }}
                                                        className={
                                                            errorFields["selectedCategories"] && v.link?.length && !v.link?.trim()?.length ? classes.styleForClientsWithError : classes.styleForClients
                                                        }
                                                        variant="outlined"
                                                    />
                                                </label>
                                                <button
                                                    title={typeof v.active !== 'undefined' ? (v.active ? "Desabilitar" : "Habilitar") : "Remover"}
                                                    className="buttonWhiteBg"
                                                    onClick={() => {
                                                        if (v.id != null && !isNaN(v.id)) {
                                                            if(v.clients > 0 && v.active == true)
                                                                v.showMessage = true

                                                            else{
                                                                changeStatusCategory(v.id)
                                                            }
                                                            forceUpdate()
                                                        } else removeCategory(v.id)
                                                    }}
                                                >
                                                    {(typeof v.active !== 'undefined') ? (v.active == true ? <ImEye /> : <ImEyeBlocked color='gray'/>) : <FiMinus/>} <span style={{marginLeft: '0.5rem', color: `${typeof v.active !== 'undefined' ? v.active ? '' : 'grey' : ''}`}}>Categoria</span>
                                                </button>
                                            </div>
                                        </>
                                    )
                                })
                            ) : (
                                <></>
                            )}
                            <div className="productCadastrationCategoriesGridButtonAbc">
                                <button
                                    className="buttonWhiteBg"
                                    style={{ marginBottom: "4rem" }}
                                    onClick={() => {
                                        addCategory()
                                    }}
                                >
                                    <FiPlus /> Categoria
                                </button>
                            </div>
                        </div>
                        <div className="productCadastrationSubmitAbc">
                            <button className="buttonWhiteBg" onClick={handleSubmit}>
                                Atualizar
                            </button>
                        </div>
                    </div>
                </>
            )}
        </div>
    )
}
