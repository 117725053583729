import React from 'react';
/*Styles*/
import styles from './styles/fiisOfferings.css';
/*Contexts*/
import { useAuth } from '../../../../contexts/useAuth';
import { useModal } from '../../../../contexts/useModal';
/*Components*/
import Modal from '../../../../components/modal/modal';
import { FiisOfferingsTable } from './tables/fiisOfferings.table';
import { ConfirmationBox } from '../../../../components/confirmationBox/confirmationBox';
import { OfferingsRegistration } from '../offeringsRegistration/offeringsRegistration';
import { FiisOfferingsDetails } from './fiisOfferingsDetails';
import { EditOfferingsRegistration } from '../offeringsRegistration/editOfferingsRegistration';

export function FiisOfferings() {

    const { tokenKey } = useAuth()
    const { show, setShow, type, setType } = useModal()

    return (

        <div className='fiisOfferingsContainer' style={{minWidth: show ? '0px' : '1000px'}}>
            <Modal>
                {
                    type.name === 'alertMsg' ?
                        (
                            <ConfirmationBox msgData={type} />
                        ) :
                        type.name === 'offeringsRegistration' ? 
                        
                        (
                            <OfferingsRegistration />
                        ) :
                        type.name === 'editOfferingsRegistration' ? 
                        
                        (
                            <EditOfferingsRegistration editFiisData={type} />
                        ) :
                        type.name === 'fiisOfferingsShowDetails' ?
                        (
                            <FiisOfferingsDetails fiisData={type} />
                        ) :
                        (
                            <></>
                        )
                }
            </Modal>
            <FiisOfferingsTable />
        </div>
    )
}