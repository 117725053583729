/*Componente para editar empresas e filiais
/*Desenvilvido por: Equipe Rafael Cezário
*/

/*Hooks*/
import React, { useState } from 'react'
/*Styles*/
import './styles/brancheEditBox.css';
import { styleClientsInput } from '../../../../components/styles/autocompletField';
/*Contexts*/
import { useModal } from '../../../../contexts/useModal';
import { useAuth } from '../../../../contexts/useAuth';
/*Services*/
import { api } from '../../../../services/api';
/*Components*/
import AntSwitch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { ArrowLoader } from '../../../../components/loaders/arrowLoader';
/*Utils*/
import { cnpjMask } from '../../../../utils/formatNumberAndText/cnpjMask';
import { removeMask } from '../../../../utils/formatNumberAndText/removeCnpjMask';

export function BrancheEditBox(props) {

    const brancheName = props?.msgData?.value?.brancheData?.name;
    const brancheId = props?.msgData?.value?.brancheData?.id;
    const brancheCityState = props?.msgData?.value?.brancheData?.city_state;
    const brancheCity = props?.msgData?.value?.brancheData?.city;
    const brancheCNPJ = props?.msgData?.value?.brancheData?.cnpj;
    const brancheCompaniesId = props?.msgData?.value?.brancheData?.companies_id;
    const [selectedCNPJ, setSelectedCNPJ] = useState('');
    const { show, setShow, setType, edited, setEdited } = useModal();
    const { user, tokenKey } = useAuth();
    const [inEdit, setInEdit] = useState({
        checkedA: false,
        checkedC: false,
    });
    const [loading, setLoading] = useState(false);
    const handleChange = async (event) => {
        setInEdit({ ...inEdit, [event.target.name]: event.target.checked });
    }
    const classes = styleClientsInput();

    async function handleSubmit(e) {

        e.preventDefault();

        if (setSelectedCNPJ === '' || selectedCNPJ === null) {
            return alert('Digite um CNPJ válido');
        }
        if (selectedCNPJ.length < 18) {
            return alert('Digite o CNPJ completo')
        }
        setLoading(true);
        try {

            const brancheChangeResult = await api.put(`/api/v1/branches/${brancheId}`, {
                cnpj: removeMask(selectedCNPJ)
            }, {
                headers: {
                    authorization: `Bearer ${tokenKey}`
                }
            })
            setType({name: 'alertMsg', type: 'information', value: {message: 'Sucesso ao editar empresa'}})
            setShow(true);
            setLoading(false);
            setEdited(!edited);
            console.log(brancheChangeResult?.data?.data);
        } catch (error) {
            console.log(error?.response)
            setType({ name: 'alertMsg', type: 'information', value: { message: error?.response?.data?.message } });
            setShow(true);
            setLoading(false);
        }
    }

    return (
        <div className='editBrancheBoxContainer'>
            {loading ?
                (<div className='editBrancheBoxLoader'>
                    <ArrowLoader />
                </div>) :
                (
                    <>
                        <div className='editBrancheBoxHeader'>
                            <p>Edite os detalhes da empresa</p>
                            <button onClick={() => setShow(false)} className='modalCloseButton'>X</button>
                        </div>
                        <div className='editBrancheBoxBody'>
                            <div className='editBrancheBoxButtonEdit'>
                                <Typography id='editBrancheBoxButtonEditSwitch' component="div">
                                    <Grid component="label" container alignItems="center" spacing={1}>
                                        <Grid item>
                                            <AntSwitch className={classes.switch} checked={inEdit.checkedA} onChange={handleChange} name="checkedA" />
                                        </Grid>
                                        <Grid item>Editar</Grid>
                                    </Grid>
                                </Typography>
                            </div>
                            <form id='editBrancheForm' onSubmit={(e) => handleSubmit(e)}>
                                <div className='editBranchesBoxDualDiv'>
                                    <div className='editBranchesBoxLabelDiv'>
                                        <a>Empresa:</a>{<a>{brancheName}</a>}
                                    </div>
                                    <div className={inEdit.checkedA ? 'editBranchesBoxHideLabel' : 'editBranchesBoxLabelDiv'}>
                                        <a>CNPJ:</a>{<a>{cnpjMask(brancheCNPJ)}</a>}
                                    </div>
                                    <div id='relative' className={inEdit.checkedA ? 'editBrancheBoxShowFields' : 'editBranchesBoxHideLabel'}>
                                        <input id='editBrancheBoxCNPJ'
                                            maxLength='18'
                                            onKeyPress={(e) => {if(e.code === 'Backquote') e.preventDefault()}}
                                            placeholder={`Digite o CNPJ para alterar: ${cnpjMask(brancheCNPJ)}`}
                                            value={selectedCNPJ?.length > 0 ? cnpjMask(selectedCNPJ) : ''}
                                            onChange={(e) => setSelectedCNPJ(e.target.value)}></input>
                                    </div>
                                </div>
                                <div className='editBranchesBoxDualDiv'>
                                    <div className='editBranchesBoxLabelDiv'>
                                        <a>Cidade:</a>{<a>{brancheCity}</a>}
                                    </div>
                                    <div className='editBranchesBoxLabelDiv'>
                                        <a>Estado:</a>{<a>{brancheCityState}</a>}
                                    </div>
                                </div>
                                <button type='submit' className='buttonWhiteBg'>Confirmar</button>
                            </form>
                        </div>
                    </>
                )}
        </div>)
}