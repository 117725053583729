import React, { useState, useEffect, useReducer } from 'react'
import Chart from "react-apexcharts";
import ApexCharts from 'apexcharts';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';


export function GraphCubastarFundsCompare(data){
  const secoes = [];
  let itemsPerPage = 12;
  const [currentPage, setCurrentPage] = useState(0);
  // let currentPage = 0;
  // let startIndex = currentPage * itemsPerPage;
  // let endIndex = startIndex + itemsPerPage;
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(startIndex + itemsPerPage);
  const [copyIndex, setCopyIndex] = useState(0);

  // let currentItems = [];
  // currentItems = secoes.slice(startIndex, endIndex);
  
  // const chartElement = document.querySelector('#cubastarAlocationRates'); 
  // const chart = new ApexCharts(chartElement, currentItems);
  // chart.render();
  
  

  // const forceUpdate = useReducer(() => ({}))[1];
  // const [currentPage, setCurrentPage] = useState(0);
  // const [currentItems, setCurrentItems] = useState(secoes.slice(startIndex, endIndex));
  
  
  data.data.elements.forEach(x => {
    var nomeSecao = x.description;
    var valorSecao = x.rentability_12_months;
    secoes.push({nomeSecao, valorSecao});
  });
  
    const [currentItems, setCurrentItems] = useState(secoes.slice(startIndex, endIndex));

  let secoesGraph = [];
  
  secoes.sort(function(a, b) {
    return b.valorSecao - a.valorSecao;
  });
  
  let forceUpdateKey = 0;
  let pages = Math.ceil(secoes.length / itemsPerPage);
  
  useEffect(() => {
  
    setCurrentPage(0);
    setStartIndex(0);
    setEndIndex(itemsPerPage);
    setCopyIndex(0);
  
    const novosCurrentItems = secoes.slice(startIndex, endIndex).sort((a, b) => b.valorSecao - a.valorSecao);
    setCurrentItems([...novosCurrentItems]); 
    
    let secoesGraph = novosCurrentItems.map(item => item.nomeSecao.split(/\s{2}/));
    secoesGraph = splitCategories(secoesGraph);
    const secoesData = novosCurrentItems.map(item => item.valorSecao.toFixed(2));

    setState(prevState => ({
      ...prevState,
      options: {
        ...prevState.options,
        xaxis: {
          ...prevState.options.xaxis,
          categories: secoesGraph
        }
      },
      series: [{ data: secoesData }]
    }));
  }, [data]);
  

  useEffect(() => {

    const novoStartIndex = copyIndex * itemsPerPage;
    const novoEndIndex = novoStartIndex + itemsPerPage;
    const novosCurrentItems = secoes.slice(novoStartIndex, novoEndIndex).sort((a, b) => b.valorSecao - a.valorSecao);
    
    let novosSecoesGraph = novosCurrentItems.map(item => item.nomeSecao.split(/\s{2}/));
    novosSecoesGraph = splitCategories(novosSecoesGraph);
    const novosSecoesData = novosCurrentItems.map(item => item?.valorSecao?.toFixed(2));

    // Aqui, atualize os estados e o gráfico conforme necessário
    setStartIndex(novoStartIndex);
    setEndIndex(novoEndIndex);
    setCurrentItems(novosCurrentItems);
    setCurrentPage(copyIndex);


    setState(prevState => ({
      ...prevState,
      options: {
          ...prevState.options,
          xaxis: {
              ...prevState.options.xaxis,
              categories: novosSecoesGraph
          }
      },
      series: [{ data: novosSecoesData }]
  }));
  }, [copyIndex]);

  // function updatedCurrentItems(index) {
  //   // setCurrentPage(currentPageClick);
  //   setStartIndex(index * itemsPerPage);
  //   setEndIndex(startIndex + itemsPerPage);

  //   setCurrentItems(secoes.slice(startIndex, endIndex));
  //   currentItems.sort(function(a, b) {
  //     return b.valorSecao - a.valorSecao;
  //   });
  //   console.log('currentItems', currentItems);
  //   console.log('index', copyIndex);
  //   console.log('currentPage', currentPage);
  //   console.log('startIndex', startIndex);
  //   console.log('endIndex', endIndex);

  //   secoesGraph = [];
  //   for (let index = 0; index < currentItems.length; index++) {
  //     secoesGraph.push(currentItems[index].nomeSecao.split(/\s{2}/));
  //   }

  //   secoesGraph = splitCategories(secoesGraph);
  //   secoesData = [];
  //   for (let index = 0; index < currentItems.length; index++) {
  //     secoesData.push(currentItems[index]?.valorSecao?.toFixed(2));
  //   }

  //   state.options.xaxis.categories = secoesGraph;
  //   state.series = [ { data: secoesData } ];
  //   console.log('state', state);
  // }

  // Para evitar transbordo de tela e sobreposição de nome de fundo
  // if(currentItems.length > 12){
  //   currentItems.splice(12, currentItems.length - 12);
  // }

  for (let index = 0; index < currentItems.length; index++) {
    secoesGraph.push(currentItems[index].nomeSecao.split(/\s{2}/));
  }

  let secoesData = [];

  for (let index = 0; index < currentItems.length; index++) {
    secoesData.push(currentItems[index]?.valorSecao?.toFixed(2));
  }

  function splitCategories(categories) {
    const maxWordsPerCategory = 2;
    const result = [];
  
    for (const category of categories) {
      const words = category[0].split(' ');
      if (words.length > maxWordsPerCategory) {
        const brokenCategories = [];
        let currentCategory = [];
        for (const word of words) {
          currentCategory.push(word);
          if (currentCategory.length === maxWordsPerCategory) {
            brokenCategories.push(currentCategory.join(' '));
            currentCategory = [];
          }
        }
        if (currentCategory.length > 0) {
          brokenCategories.push(currentCategory.join(' '));
        }
        result.push(brokenCategories);
      } else {
        result.push([category]);
      }
    }
    return result;
  }

  secoesGraph = splitCategories(secoesGraph);
  let dynamicGraphWidth = 64 * secoesGraph.length;
  if (dynamicGraphWidth >= 1268) {
    dynamicGraphWidth = 1268;
  }

  const [state,setState] = useState({

    options: {
      chart: {
        id: "cubastarAlocationRates",
        type: "bar",
        offsetY: 30,
        toolbar: {
            show: false
        }
    },
    title: {
      text: 'Rentabilidade 12 meses por fundo',
      align: 'center',
      style: {
        fontSize: '14px',
        color: 'gray',
        fontFamily: 'Roboto,Helvetica,Arial,sans-serif'
      }
    },
    plotOptions: {
        bar: {
            dataLabels: {
                position: 'top',
                offsetY: '-100'
            },
        colors: {
            ranges: [{
                from: -1000,
                to: 0,
                color: '#F15B46'
            },
            {
              from: 0,
              to: 1000,
              color: '#09033B'
            }]
            },
        borderRadius: 2,
        horizontal: false,
        columnWidth: '50%',
        endingShape: 'rounded'
        },
    },
    stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
    },
    dataLabels: {
        enabled: true,
        formatter: function(value) {
            return value.toFixed(1) + ('%')
        },
        style: {
            fontSize: '16px',
            colors: ['black']
        },
        offsetY: -30
    },
    grid: {
        show: false
    },
    tooltip: {
        enabled: false
    },
    xaxis: {
        // categories: [[['BV Dólar'], ['Cambial FIC FI']], [['Trend Bitcoin'], [' Dólar FIC FIM']], [['XP Real Estate'], ['Total Return'], ['FIC FIM CP']]],
        categories: secoesGraph,
        labels: {
            rotate: 0
        }
    },
    yaxis: {
        show: false,
        labels: {
            show: false
          },
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          }
    },
    },
    legend: false,
    series: [
      {
        data: secoesData
      }
    ],
  });
  
  return (
    <div className="app" style={{ overflowX: 'auto', overflowY: 'hidden' }} 
      id='cubastarAlocationRates' 
    >
      <div className="row">
        <div style={{display:'flex', flexDirection: 'row', alignItems:'center', justifyContent:'center'}} >
          {currentPage > 0 ? <span className='buttonGraphsCubastar'><FaChevronLeft size={32} style={{cursor:'pointer'}} onClick={() => copyIndex > 0 ? setCopyIndex(copyIndex - 1) : setCopyIndex(0)} /> </span> : <></>  }
          <div className="mixed-chart">
            <Chart
              // key={forceUpdateKey}
              options={state.options}
              series={state.series}
              type="bar"
              width={640 + dynamicGraphWidth}
              height="300"
              />
          </div>
          { currentPage < pages - 1? <span> <FaChevronRight size={32} className='buttonGraphsCubastar' style={{cursor:'pointer'}} onClick={() => copyIndex < pages - 1 ? setCopyIndex(copyIndex + 1) : setCopyIndex(pages - 1)} /> </span> : <></>}
        </div>
        {secoes.length > 0 ? (
            <div style={{marginTop:'1rem', display:'flex', gap:'0.5rem',justifyContent:'center'}}>
              {Array.from(Array(pages), (item,index) => (
                <button 
                        value={index}
                        style={{
                        background: currentPage === index ? '#c49e17' : 'white',
                        color: currentPage === index ? 'white' : 'black',
                        borderRadius: '8px',
                        border: 'none',
                        padding: '10px',
                        cursor: 'pointer',
                        outline: 'outline: 1px solid c49e17',
                        fontSize: '16px',
                        fontFamily: 'Roboto,Helvetica,Arial,sans-serif',
                        transition: 'all 0.2s ease-in-out'
                      }}
                    onClick={(e) => (
                      setCopyIndex(Number(e.target.value)),
                      setCurrentPage(Number(e.target.value))
                      // currentPage = Number(e.target.value),
                      // updatedCurrentItems(index)
                    )} >
                      {index + 1}
                </button>
               ))}
            </div>
          ) : (
            <></>)}
      </div>
    </div>
  );
}