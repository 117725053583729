import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import { Tooltip, IconButton } from "@material-ui/core";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { ThemeProvider,createTheme } from '@mui/material/styles';
/*Styles*/
import "../styles/roaDashboard.table.css";
/*Contexts*/
import { useAuth } from "../../../../contexts/useAuth";
import { useModal } from "../../../../contexts/useModal";
/*Services*/
import { getCustody } from "../../../../services/asyncFunctions";
/*Components*/
import { ArrowLoader } from "../../../../components/loaders/arrowLoader";
import { TableCell } from "@material-ui/core";
/*Utils*/
import { toBRL } from "../../../../utils/formatNumberAndText/toBRLCurrency";
import { toPercent } from "../../../../utils/formatNumberAndText/formatNumberPercent";
import { AiOutlineArrowUp, AiOutlineArrowDown } from "react-icons/ai";

export function MonitorCustodiaTbl(props) {
    const [loading, setLoading] = useState(false);
    const [listCustody, setListCustody] = useState([]);
    const [listRoaDashboardFormatted, setlistRoaDashboardFormatted] = useState([]);
    const [databaseUpdateTime, setDatabaseUpdateTime] = useState([]);
    const { tokenKey } = useAuth();
    const { setType, setShow, edited, setEdited } = useModal();

    const [sumAmount ,setSumAmount] = useState(0)
    const [sumValue ,setSumValue] = useState(0)

    const location = useLocation();
    let orderRec = location.orderRec;

    async function getCustodyindex() {
        const custodys = await getCustody(tokenKey, false);
        if (custodys?.isError) {
            setType({
                name: "alertMsg",
                type: "information",
                value: {
                    message: custodys?.error,
                },
            });
            setShow(true);
        } else {
            setListCustody(custodys?.data);
            
            setLoading(false);
        }
    }

    async function custodyExport() {
        setLoading(true);
        try {
            const myExport = await getCustody(tokenKey, true);
            const responseURL = URL.createObjectURL(new Blob([myExport]));
            const link = document.createElement("a");
            link.href = responseURL;
            link.setAttribute("download", "monitor_custodia.xlsx");
            document.body.appendChild(link);
            link.click();
            setLoading(false);
        } catch (error) {
            setType({
                name: "alertMsg",
                type: "information",
                value: {
                    message:
                        error?.response?.data?.message === undefined
                            ? "Erro ao buscar dados para exportação"
                            : error?.response?.data?.message,
                },
            });
            setShow(true);
            setLoading(false);
        }
    }

    useEffect(() => {
        if (edited || listCustody.length === 0) {
            setEdited(false);
            setLoading(true);
            getCustodyindex();
            
        }
    }, [edited]);

    useEffect(() => {
        calculeValues()
    }, [listCustody]);

    function calculeValues(){
        var qty = 0;
        var value = 0;
        listCustody.forEach((e) => {
            qty += e.qty;
            value += e.value;
        })
        setSumAmount(qty)
        setSumValue(value)
    }

    function copyToClipboard() {
        const output = [];
        const titles = [
            "Código Cliente",
            "Nome do Cliente",
            "Ativo",
            "Tipo de produto",
            "Quantidade",
            "Cotação",
            "Financeiro",
            "Cód Broker",
            "Broker",
            "Cód Assessor",
            "Assessor",
            "Filial"
        ];
        output.push(titles.join("\t"));
        const keys = [
            "client_id",
            "client_name",
            "asset",
            "product_name",
            "qty",
            "value_unit",
            "value",
            "broker_id",
            "broker",
            "advisor_id",
            "advisor",
            "branch"
        ];
        listCustody.forEach((x) => {
            const tmp = keys.map((y) => {
                return y === "value_unit" ||
                    y === "value"
                    ? x[y] == null ? 0 : String(x[y]).replace(".", ",")
                    : x[y];
            });
            output.push(tmp.join("\t"));
        });
        const table = output.join("\n");
        navigator.clipboard.writeText(table);
        alert("Conteúdo da tabela copiado para a área de transferência");
    }

    const columns = [
        {
            name: "client_id",
            label: "Código Cliente",
            options: {
                filter: true,
                sort: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        padding: '0px 0.6rem 0px 0.6rem',
                    },
                }),
            },
        },
        {
            name: "client_name",
            label: "Nome do Cliente",
            options: {
                filter: true,
                sort: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        padding: '0px 0.6rem 0px 0.6rem',
                    },
                }),
            },
        },
        {
            name: "asset",
            label: "Ativo",
            options: {
                filter: true,
                sort: true,

                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        padding: '0px 0.6rem 0px 0.6rem',
                    },
                }),
            },
        },
        {
            name: "product_name",
            label: "Tipo de produto",
            options: {
                filter: true,
                sort: true,

                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        padding: '0px 0.6rem 0px 0.6rem',
                    },
                }),
            },
        },
        {
            name: "qty",
            label: "QUANTIDADE",
            options: {
                filter: false,
                sort: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        padding: '0px 0.6rem 0px 0.6rem',
                    },
                }),

                customBodyRender: (val) => {
                    return (val? val : 0);
                },
                customHeadRender: ({ index, name, ...column }, sortColumn, tableMeta) => {
                    return (
                    
                    <TableCell
                        className="containerSelectContactTypeHeader tss-gm6zfk-MUIDataTableHeadCell-fixedHeader"
                        onClick={() => {
                            sortColumn(index);
                        }}>
                        <div className="textSumCell">
                            <div>
                                {/* <TableSortLabel active={tableMeta.name === column.name} direction={tableMeta.direction || "asc"} /> */}
                                {column.label} {tableMeta.name === name ? tableMeta.direction == "asc" ? <AiOutlineArrowUp /> : <AiOutlineArrowDown /> : <></>}
                            </div>
                            <p>{sumAmount}</p>
                        </div>
                    </TableCell>
                )},
            },
        },
        {
            name: "value_unit",
            label: "Cotação",
            options: {
                filter: true,
                sort: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        padding: '0px 0.6rem 0px 0.6rem',
                    },
                }),
                customBodyRender: (val) => {
                    return toBRL((val != '' && val != null) ? parseFloat(val) : 0);
                },
            },
        },
        {
            name: "value",
            label: "FINANCEIRO",
            options: {
                filter: false,
                sort: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        padding: '0px 0.6rem 0px 0.6rem',
                    },
                }),

                customBodyRender: (val) => {
                    return toBRL((val != '' && val != null) ? parseFloat(val) : 0);
                },
                customHeadRender: ({ index, name, ...column }, sortColumn, tableMeta) => (
                    <TableCell
                        className="containerSelectContactTypeHeader tss-gm6zfk-MUIDataTableHeadCell-fixedHeader"
                        onClick={() => {
                            sortColumn(index);
                        }}>
                        <div className="textSumCell">
                            <div>
                                {/* <TableSortLabel active={tableMeta.name === column.name} direction={tableMeta.direction || "asc"} /> */}
                                {column.label} {tableMeta.name === name ? tableMeta.direction == "asc" ? <AiOutlineArrowUp /> : <AiOutlineArrowDown /> : <></>}
                            </div>
                            <p>{toBRL(sumValue)}</p>
                        </div>
                    </TableCell>
                ),
            },
        },
        {
            name: "broker",
            label: "Broker",
            options: {
                filter: true,
                sort: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        padding: '0px 0.6rem 0px 0.6rem',
                    },
                }),
            },
        },
        {
            name: "advisor",
            label: "Assessor",
            options: {
                filter: true,
                sort: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        padding: '0px 0.6rem 0px 0.6rem',
                    },
                }),
            },
        },
        {
            name: "branch",
            label: "Filial",
            options: {
                filter: true,
                sort: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        padding: '0px 0.6rem 0px 0.6rem',
                    },
                }),
            },
        },
    ];

    // Adiciona um botão para chamar a função de copiar a tabela para a área de tranferência
    const AddButton = () => (
        <Tooltip disableFocusListener title="Copiar para a área de transferência">
            <IconButton onClick={copyToClipboard}>
                <ContentCopyIcon />
            </IconButton>
        </Tooltip>
    );

    const options = {
        filterType: "multiselect",
        filter: true,
        download: true,
        print: false,
        responsive: "standard",
        tableBodyHeight: "72vh",
        draggableColumns: {
            enabled: true,
        },
        pagination: true,
        rowsPerPage: 30,
        rowsPerPageOptions: [30, 300, 3000],
        selectableRowsHideCheckboxes: true,
        viewColumns: false,
        onTableChange: (a,b) => {
            var sumAmountAux = 0
            var sumValueAux = 0
            b.displayData.map((v) => {
                sumAmountAux += (parseFloat((v.data[4] && v.data[4] != null) ? v.data[4] : 0))})
            b.displayData.map((v) => {
                return(sumValueAux += (v.data[6] && v.data[6] != null)  ? (parseFloat(v.data[6].replace('R$', '').replaceAll('.','').replace(',','.').replace(/\s/g, ''))) : 0)   })
            setSumAmount(sumAmountAux)
            setSumValue(sumValueAux)
        },
        sortOrder: {
            name: 'revenue',
            direction: orderRec
        },
        textLabels: {
            body: {
                noMatch: loading ? <ArrowLoader /> : "Não há informações até o momento para projeção de receita.",
                toolTip: "Ordenar",
                columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
            },
            pagination: {
                next: "Próxima",
                previous: "Anterior",
                rowsPerPage: "Linhas por página:",
                displayRows: "de",
            },
            toolbar: {
                search: "Pesquisar",
                downloadCsv: "Download XLSX",
                print: "Imprimir",
                viewColumns: "Ver colunas",
                filterTable: "Filtrar",
            },
            filter: {
                all: "Todos",
                title: "Filtros",
                reset: "Limpar",
            },
            viewColumns: {
                title: "Mostrar Colunas",
                titleAria: "Mostrar/Ocultar colunas",
            },
            selectedRows: {
                text: "linha(s) selecionadas",
                delete: "Excluir",
                deleteAria: "Excluir linhas Selecionadas",
            },
        },
        downloadOptions: {
            filename: "monitor_custodia.csv",
            separator: ";",
        },
        onDownload: () => {
            custodyExport();
            return false;
        },
        customToolbar: AddButton,
        onFilterChange: (columnChanged, filterList,a,b,c) => {
            // console.log(columnChanged, filterList,a,b,c)
            // calculeValues()
        }
    };

    const getMuiTheme = () =>
    createTheme({
      components: {
        MuiTableCell: {
            styleOverrides: {
                root:{
                        justifyContent: 'center',
                        whiteSpace: "nowrap",
                        textAlign: 'center',
                }
            },
          },
        MUIDataTableHeadCell: {
            styleOverrides: {
                contentWrapper: {
                    justifyContent: 'center',
                    whiteSpace: "nowrap",
                }
            }
        },
      },
    })

    return (
        <div style={{ maxWidth: "95%", marginTop: "2rem" }}>
            <ThemeProvider theme={getMuiTheme()}>
                <MUIDataTable title='Monitor Custódia' data={listCustody} columns={columns} options={options}></MUIDataTable>
            </ThemeProvider>
        </div>
    );
}
