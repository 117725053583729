/**
 * Desenvolvido por equipe: Tiago Murilo
 * Descrição: Lista clientes de brokers e assessores e reenderiza component table
 **/

//libraries
import React, { useEffect, useState } from 'react';
//contexts
import { useModal } from '../../../../contexts/useModal'
import { useAuth } from '../../../../contexts/useAuth'
//services
import { api } from '../../../../services/api';
import { getMyClients } from '../../../../services/asyncFunctions';
//components
import { ArrowLoader } from '../../../../components/loaders/arrowLoader';
import Modal from '../../../../components/modal/modal'
import { MyClientsDetail } from './myClientsDetail'
//utils
import { numberBRLFormat } from '../../../../utils/formatNumberAndText/formatNumbertoBRL';
import { titleize } from '../../../../utils/formatNumberAndText/formatTextUpperFirstLetter';
import { ModalSizeFitToScreen } from '../../../../utils/responsiveFunctions/modalSizeFitToScreen'
//styles
import './styles/myClients.table.css'
import { FiArrowDown, FiCopy } from 'react-icons/fi';

export function MyClientsTable() {

    const { setShow, setType, type } = useModal();
    const { screenX, screenY } = ModalSizeFitToScreen();
    const { tokenKey } = useAuth();
    const { user } = useAuth()
    const [filtered, setFiltered] = useState('');
    const [listClients, setListClients] = useState([]);
    const [totalClients, setTotalClients] = useState(0);
    const [scrollTable, setScrollTable] = useState(null);
    const [scrollTableLines, setScrollTableLines] = useState(50);
    const [bottomScroll, setBottomScroll] = useState(false);
    const [loading, setLoading] = useState(true);
    const token = localStorage.getItem('@auth:token');
    const [selectedFilter, setSelectedFilter] = useState('Código');

    useEffect(async () => {
        setLoading(true)

        const clientsGet = await getMyClients(user.external_id, tokenKey)
        if (clientsGet?.isError) {
            setType({
                name: 'alertMsg',
                type: 'information',
                value: {
                    message: clientsGet?.error
                }
            })
            setShow(true);
        } else {
            let listAuthorizedClients = []
            if (clientsGet !== undefined) {
                clientsGet.map((thisClient) => {
                    if (thisClient?.status_client !== 4) {
                        listAuthorizedClients.push(thisClient)
                    }
                })
                setListClients(listAuthorizedClients);
                setTotalClients(listAuthorizedClients?.length);
            }

        }
        setLoading(false);
    }, [])


    // console.log(listClients)

    
    useEffect(() => {

        if (bottomScroll != false) {

            setScrollTableLines(scrollTableLines + 50)
            setBottomScroll(false);

        } else {
            setBottomScroll(false);
        }

    }, [bottomScroll])

    function listFilter() {
        if (filtered === null || filtered === undefined || filtered === '') {
            return listClients;
        } else {
            return listClients.filter((filterRow) => {
                if (filterRow?.client_external_id?.indexOf(filtered) > -1 && selectedFilter === 'Código' ||
                    filterRow?.client_name?.toLowerCase()?.indexOf(filtered?.toLowerCase()) > -1 && selectedFilter === 'Nome') {
                    return filterRow;
                }
            })
        }
    }

    async function myClientsExport() {
        setLoading(true);
        try {
            const myClientsExport = await api.get(`/api/v1/users/${user.external_id}/myClients/export`, {
                headers: {
                    authorization: `Bearer ${tokenKey}`
                },
                responseType: 'blob'
            })
            
            const responseURL = URL.createObjectURL(new Blob([myClientsExport.data]));
            const link = document.createElement('a');
            link.href = responseURL;
            link.setAttribute('download', 'meus_clientes_mesa.xlsx');
            document.body.appendChild(link);
            link.click();
            setLoading(false)
    
        } catch (error) {
            setType({ name: 'alertMsg', type: 'information', value: { message: error?.response?.data?.message === undefined ? 'Erro ao buscar dados para exportação' : error?.response?.data?.message } })
            setShow(true);
            setLoading(false);
        }
    }

    function checkFilteredValue(value) {
        if (value === '') { setScrollTableLines(50) }
    }

    async function showClientDetails(client) {
        setShow(true);
        setType({ name: 'showClientDetails', value: { client: client } });

        try {

            const getClients = await api.get(`/api/v1/clients/${client}`, {
                headers: {
                    authorization: `Bearer ${token}`
                }
            })

            const getBrokers = await api.get(`/api/v1/clients/${client}/brokers?status=1`, {
                headers: {
                    authorization: `Bearer ${token}`
                }
            })
            setType({ name: 'showClientDetails', value: { client: getClients.data.data, broker: getBrokers.data.data } });
            
        } catch (error) {
            alert(error?.response?.data?.message)
        }
    }



    return (
        <>
            <Modal>
                {type?.name === 'showClientDetails' &&
                    <div className='modalMyClients'>
                        <MyClientsDetail />
                    </div>
                }

            </Modal>
            <div className='myClientsBody'>
                <div id='myClientsTableContainer' className='myClientsTableContainer' style={{ maxHeight: screenY }} onScrollCapture={() => {

                    setScrollTable(document.getElementById('myClientsTableContainer'))
                    if (scrollTable?.scrollTop + scrollTable?.clientHeight >= scrollTable?.scrollHeight) {
                        setBottomScroll(true);
                    }
                }}>
                    <table>
                        <thead>
                            <tr>
                                <th colSpan='7'>
                                    <div className='myClientsTheadFirst'>
                                        <label id='myClientsTableNameAndTotalClients'>
                                            <a>Lista de Clientes</a>
                                            <a>Total: {totalClients > 0 ? totalClients : '0'}</a>
                                        </label>
                                        <div className='substitutoLabelDisplay'>
                                        <button
                                            className='buttonTableThead'
                                            onClick={() => {
                                                const output = [];
                                                const titles = ['Código', 'Nome', 'Assessor', 'NET Renda Variavel', 'NET Total', 'Cliente desde:']
                                                output.push(titles.join('\t'))
                                                const keys = ['client_external_id', 'client_name', 'advisor_name', 'net_stock', 'net_total', 'created_at'];
                                                listClients.forEach((x) => {
                                                    const tmp = keys.map((y) => y==='net_stock' || y==='net_total' ? String(x[y]).replace('.',',') : x[y]);
                                                    output.push(tmp.join('\t'))
                                                })
                                                const table = output.join('\n')
                                                navigator.clipboard.writeText(table)
                                                alert('Conteúdo da tabela copiado para a área de transferência')
                                            }}
                                        ><FiCopy />Copiar</button>
                                        <button
                                            className='buttonTableThead'
                                            onClick={() => {
                                                myClientsExport();
                                        }}><FiArrowDown />Exportar</button>
                                            <select className='selectFilterTable' onChange={(e) => setSelectedFilter(e.target.value)}>
                                                <option selected>Código</option>
                                                <option>Nome</option>
                                            </select>
                                            <input placeholder='Filtrar' onChange={(e) => { setFiltered(e.target.value); checkFilteredValue(e.target.value) }} type="text" />
                                        </div>
                                    </div>
                                </th>
                            </tr>
                            <tr>
                                <th>Código</th>
                                <th>Nome</th>
                                <th>{user?.type == 3 ? 'Assessor' : user?.type == 4 ? 'Broker' : ''}</th>
                                <th>Net Renda Variável</th>
                                <th>Net Total</th>
                                <th>Desde</th>
                            </tr>
                        </thead>
                        <tbody>
                            {listClients.length == 0 && !loading ?
                                (
                                    <tr>
                                        <td colSpan='6'>Você não possui nenhum cliente.</td>
                                    </tr>
                                ) : loading ?
                                    (
                                        <tr>
                                            <td colSpan='6'><br /><br /> <ArrowLoader /> <br /></td>
                                        </tr>
                                    ) :
                                    listFilter().map(client => {
                                        return (
                                            <tr onClick={() => showClientDetails(client.client_external_id)}>
                                                <td cursor="pointer">{client.client_external_id}</td>
                                                <td>{titleize(client.client_name)}</td>
                                                <td>{user?.type == 3 ? client.advisor_name : user?.type == 4 ? client.broker_name : ''}</td>
                                                <td>{numberBRLFormat(client.net_stock)}</td>
                                                <td>{numberBRLFormat(client.net_total)}</td>
                                                <td>{client.created_at}</td>
                                            </tr>
                                        )
                                    })}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}