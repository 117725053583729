import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import { debounceSearchRender } from "mui-datatables";
import { Tooltip, IconButton, TableCell } from "@material-ui/core";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { ThemeProvider,createTheme } from '@mui/material/styles';
/*Styles*/
import "../../monitoringPanel/myReservations/styles/myReservation.table.css"
/*Contexts*/
import { useAuth } from "../../../../contexts/useAuth";
import { useModal } from "../../../../contexts/useModal";
/*Services*/
import { getDatabaseReferenceTime, getFundos } from "../../../../services/asyncFunctions";
/*Components*/
import { ArrowLoader } from "../../../../components/loaders/arrowLoader";
import { toBRL } from "../../../../utils/formatNumberAndText/toBRLCurrency";
import { toPercent } from "../../../../utils/formatNumberAndText/formatNumberPercent";
import { BsQuestionCircle } from "react-icons/bs";
import { AiOutlineArrowDown, AiOutlineArrowUp } from "react-icons/ai";

export function FundsValidatedTable() {
    const [loading, setLoading] = useState(false);
    const [listDueStructured, setlistDueStructured] = useState([]);
    const [listDueStructuredFormatted, setlistDueStructuredFormatted] = useState([]);
    const [databaseUpdateTime, setDatabaseUpdateTime] = useState('');
    const { tokenKey } = useAuth();
    const { setType, setShow, edited, setEdited } = useModal();

    async function getDue() {
        const listDueStructuredAux = await getFundos(tokenKey);
        if (listDueStructuredAux?.isError) {
            setType({
                name: "alertMsg",
                type: "information",
                value: {
                    message: listDueStructuredAux?.error,
                },
            });
            setShow(true);
        } else {
            setlistDueStructured(listDueStructuredAux);
            setLoading(false);
        }
    }

    async function getDatabaseUpdateTimeValues() {
        const bases = 'guiaFundos';
        const databaseUpdateTime = await getDatabaseReferenceTime(tokenKey, bases);
        if (getDatabaseReferenceTime?.isError) {
            setType({
                name: "alertMsg",
                type: "information",
                value: {
                    message: getDatabaseReferenceTime?.error,
                },
            });
            setShow(true);
        } else {
            try {
                let dateFormat = databaseUpdateTime[0].file_reference_time;
                setDatabaseUpdateTime(dateFormat);
            } catch (e) {
                let dateFormat = ''
                setDatabaseUpdateTime(dateFormat);
            }
        }
    }

    function formateAssets() {
        let dueStructuredFormatted = [];

        listDueStructured.map((dueStructured) => {
            dueStructuredFormatted.push({
                cnpj: dueStructured.cnpj,
                product: dueStructured.product,
                call: dueStructured.call,
                fundo: dueStructured.fundo,
                categoria: dueStructured.categoria,
                target_investor: dueStructured.target_investor,
                liquidity: dueStructured.liquidity,
                status: dueStructured.status,
                ROA: dueStructured.ROA,
                fundo_destino: dueStructured.fundo_destino,
                roa_destino: dueStructured.roa_destino,
            });
        });

        setlistDueStructuredFormatted(dueStructuredFormatted);
        setLoading(false);
    }

    useEffect(() => {
        if (edited || listDueStructured.length === 0) {
            setEdited(false);
            setLoading(true);
            getDue();
            getDatabaseUpdateTimeValues();
        }
    }, [edited]);

    useEffect(() => {
        if (listDueStructured.length > 0) {
            formateAssets();
        }
    }, [listDueStructured]);

    function copyToClipboard() {
        const output = [];
        const titles = [
            "CNPJ",
            "Produto",
            "Fundo",
            "Categoria",
            "Tipo de Investidor",
            "Liquidez",
            "Status",
            "ROA (%)",
            "Sugestão de Troca",
            "ROA Troca (%)",
        ];
        output.push(titles.join("\t"));
        const keys = [
            "cnpj",
            "product",
            "fundo",
            "categoria",
            "target_investor",
            "liquidity",
            "status",
            "ROA",
            "fundo_destino",
            "roa_destino",
        ];
        listDueStructured.forEach((x) => {
            const tmp = keys.map((y) => {
                return y === "application_curve_value" || y === "sale_gross_value" || y === "agio_outcome" || y === "rentability_mtm"
                    ? String(x[y]).replace(".", ",")
                    : y === "created_at" || y === "due_at"
                    ? x[y].replace(/^(\d{4})-(\d{2})-(\d{2})T(\d{2}:\d{2}:\d{2})\.\d+Z$/, "$3/$2/$1")
                    : x[y];
            });
            output.push(tmp.join("\t"));
        });
        const table = output.join("\n");
        navigator.clipboard.writeText(table);
        alert("Conteúdo da tabela copiado para a área de transferência.");
    }

    // async function dueStructuredExport(open, year) {
    //     setLoading(true);
    //     try {
    //         const myExport = await getFundsView(tokenKey, true);
    //         const responseURL = URL.createObjectURL(new Blob([myExport]));
    //         const link = document.createElement("a");
    //         link.href = responseURL;
    //         link.setAttribute("download", "fundos_validados.xlsx");
    //         document.body.appendChild(link);
    //         link.click();
    //         setLoading(false);
    //     } catch (error) {
    //         setType({
    //             name: "alertMsg",
    //             type: "information",
    //             value: {
    //                 message:
    //                     error?.response?.data?.message === undefined
    //                         ? "Erro ao buscar dados para exportação"
    //                         : error?.response?.data?.message,
    //             },
    //         });
    //         setShow(true);
    //         setLoading(false);
    //     }
    // }

    const columns = [
        {
            name: "cnpj",
            label: "CNPJ",
            options: {
                filter: false,
                sort: true,
                sortDescFirst: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        textAlign: "center"
                    },
                }),
            },
        },
        {
            name: "product",
            label: "Produto",
            options: {
                filter: true,
                sort: true,

                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                    },
                }),
                customBodyRender: (value) =>
                    value != null && value != "null" ? value : "N/C",
            },
        },
        
        {
            name: "fundo",
            label: "Fundo",
            options: {
                filter: false,
                sort: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                    },
                }),
            },
        },
        {
            name: "categoria",
            label: "Categoria",
            options: {
                filter: true,
                sort: true,

                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                    },
                }),
            },
        },
        {
            name:'target_investor',
            label: 'Tipo de Investidor',
            options: {
                filter: true,
                sort: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                    },
                }),
                customBodyRender: (value) => 
                value !== null ? <span>{value}</span> : <span>N/C</span>
            }
        },
        {
            name: "liquidity",
            label: "Liquidez",
            options: {
                filter: true,
                sort: true,

                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                    },
                }),
                customBodyRender: (value) =>
                    value != null && value != "null" ? value : "N/C",
                customHeadRender: ({ index, name, label, ...column }, sortColumn, tableMeta) => {
                    return (
                        <>
                            <TableCell
                                className="TableCell"
                                onClick={() => {
                                    sortColumn(index)
                                }}
                                style={{
                                    paddingRight: "0.5rem",
                                    paddingLeft: "0.5rem",
                                    whiteSpace: "nowrap",
                                    fontWeight: "500",
                                }}
                            >
                                <div
                                    className="containerTableCellButton"
                                    // style={{ margin: "0px 30px" }}
                                >
                                    <div>
                                        {label}
                                        <BsQuestionCircle
                                            color="rgb(117, 117, 117)"
                                            size={16}
                                            style={{
                                                display: "inline-block",
                                                verticalAlign: "middle",
                                                marginTop: "-0.2rem",
                                                cursor: "pointer",
                                                marginLeft: "0.5rem",
                                                marginRight: "0.5rem",
                                            }}
                                            title="Liquidez em dias."
                                        />
                                        <div className="arrowSortAbc">
                                            {tableMeta.name === name ? tableMeta.direction == "asc" ? <AiOutlineArrowUp size={18} /> : <AiOutlineArrowDown size={18} /> : <></>}
                                        </div>
                                    </div>
                                </div>
                            </TableCell>
                        </>
                    )
                },
            },
        },
        {
            name: "status",
            label: "Status",
            options: {
                filter: true,
                sort: true,

                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                    },
                }),
                customBodyRender: (value) => 
                value === 'VALIDADO' || value === 'CALL DE ENTRADA' ? <span style={{color: 'green'}}>{value}</span> : 
                value === 'NÃO VALIDADO' || value === 'CALL DE SAÍDA' ? <span style={{color: 'red'}}>{value}</span> :
                    <span>{value}</span>
            },
        },
        {
            name: "ROA",
            label: "ROA",
            options: {
                filter: false,
                sort: true,
                sortDescFirst: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                    },
                }),
                customBodyRender: (value) => 
                value !== null ? <span>{toPercent(value)}</span> : <span>N/C</span>
            },
        },
        {
            name: "fundo_destino",
            label: "Sugestão de Troca",
            options: {
                filter: true,
                sort: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                    },
                }),
                customBodyRender: (value) => 
                value !== null ? <span>{value}</span> : <span>N/C</span>
            },
        },
        {
            name: "roa_destino",
            label: "ROA Troca",
            options: {
                filter: false,
                sort: true,
                sortDescFirst: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                    },
                }),
                customBodyRender: (value) => 
                value !== null ? <span style={{ color: 'green' }}>{toPercent(value)}</span> : <span>N/C</span>
            },
        }
    ];

    // Adiciona um botão para chamar a função de copiar a tabela para a área de tranferência
    const AddButton = () => (
        <Tooltip disableFocusListener title="Copiar para a área de transferência">
            <IconButton onClick={copyToClipboard}>
                <ContentCopyIcon />
            </IconButton>
        </Tooltip>
    );

    const options = {
        filterType: "multiselect",
        filter: true,
        download: false,
        print: false,
        responsive: "standard",
        customSearchRender: debounceSearchRender(500),
        tableBodyHeight: "70vh",
        selectableRowsHideCheckboxes: true,
        viewColumns: false,
        toolButton: {
            justifyContent: "center"
        },
        textLabels: {
            body: {
                noMatch: loading ? <ArrowLoader /> : "Não há fundos registrados no momento!",
                toolTip: "Ordenar",
                columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
            },
            pagination: {
                next: "Próxima",
                previous: "Anterior",
                rowsPerPage: "Linhas por página:",
                displayRows: "de",
            },
            toolbar: {
                search: "Pesquisar",
                downloadCsv: "Download XLSX",
                print: "Imprimir",
                viewColumns: "Ver colunas",
                filterTable: "Filtrar",
            },
            filter: {
                all: "Todos",
                title: "Filtros",
                reset: "Limpar",
            },
            viewColumns: {
                title: "Mostrar Colunas",
                titleAria: "Mostrar/Ocultar colunas",
            },
            selectedRows: {
                text: "linha(s) selecionadas",
                delete: "Excluir",
                deleteAria: "Excluir linhas Selecionadas",
            },
        },
        downloadOptions: {
            filename: "fundos_validados.csv",
            separator: ";",
        },
        onDownload: () => {
            // dueStructuredExport();
            return false;
        },
        setTableProps: () => {
            return {
                padding: '0px 1rem 0px',
                size: 'small'
            };
        },
        rowsPerPage: 20,
        rowsPerPageOptions: [20, 200, 1000],
        customToolbar: AddButton,
    };

    const getMuiTheme = () =>
    createTheme({
      components: {
        MuiTableCell: {
            styleOverrides: {
                root:{
                        justifyContent: 'center',
                        whiteSpace: "",
                        textAlign: 'center'
                }
            },
          },
          MUIDataTableFilter: {
            styleOverrides: {
                root: {
                    minWidth: "40vw",
                    maxWidth: "50vw",
                },
            },
        },
        MUIDataTableHeadCell: {
            styleOverrides: {
                contentWrapper: {
                    justifyContent: 'center',
                    whiteSpace: "pre-line",
                },
                fixedHeader: {
                    justifyContent: 'center',
                    whiteSpace: "pre-line",
                    maxWidth: '4rem',
                    padding: "0px 0.6rem 0px 0.6rem"
                },
                toolButton: {
                    margin: '0px'
                }
            }
        },
        MUIDataTableBodyRow: {
            styleOverrides: {
            root: {
              '&:nth-child(odd)': { 
                backgroundColor: '#dbdbdb'
              }
            }
        }
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    boxShadow: 'none',
                    borderRadius: '0'
        }
      },
    }
    }
    })

    return (
        <div>
            <ThemeProvider theme={getMuiTheme()}>
                <MUIDataTable
                    title={'Última atualização: ' + databaseUpdateTime}
                    data={listDueStructuredFormatted}
                    columns={columns}
                    options={options}
                    className="tableUnsuitedStructured"
                    ></MUIDataTable>
            </ThemeProvider>
        </div>
    );
}

