import React, { useEffect, useState, useRef,useReducer} from "react";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
/*Contexts*/
import { useAuth } from "../../../../contexts/useAuth";
import { useModal } from "../../../../contexts/useModal";
/*Services*/
import { api } from "../../../../services/api";
/*Utils*/
import { titleize } from "../../../../utils/formatNumberAndText/formatTextUpperFirstLetter";
import { updateABCStatus } from "../../../../services/asyncFunctions"

import './abcStatusFilter.css'

export function AbcStatusFilter(props) {
  // console.log('STATUS', props);
  const { tokenKey, user } = useAuth();
  const { show, setShow, type, setType, edited, setEdited } = useModal();
  const [loading, setLoading] = useState(true);
  const [isTrue, setIsTrue] = useState(false);
  const [filtersStatus, setFiltersStatus] = useState([
    {label : 'pendente', color: "#FAF5C7", visible: false, obs: false},
    {label : 'abordado',color: "#7893DB", visible: false, obs: false},
    {label : 'solicitado',color: "#F0B357", visible: false, obs: true},
    {label : 'boletado',color: "#CBF0BE", visible: false, obs: false},
    {label : 'cancelado',color: "#E2BBE3", visible: false, obs: true},
    {label : 'recusado',color: "#E87F7F", visible: false, obs: false},
    {label : 'não aderente',color: "#000000", visible: false, obs: false},
    {label : 'outros',color: "#9013FE" , visible: false, obs: true},
  ]);
  const [cont, setCont] = useState(0);

//   const [operationId, setOperationId] = useState(null);
//   const [categoryInfos, setCategoryInfos] = useState(null);
//   const [link, setLink] = useState({
//     boletar: "/",
//     acompanhamento: "/"
//   });

// const props.prop.listFilterStatusProducts.current[props.prop.data] = props.prop.listFilterStatusProducts.current[props.prop.data]

 let handleChange = async (el,i) => { 
  var aux = filtersStatus
  aux[i].visible = el.target.checked
  // console.log(el.target.checked);
  await setFiltersStatus(aux)
  forceUpdate()
}; 

const [, forceUpdate] = useReducer(x => x + 1, 0);


let selectAll = async (status) => {
  var aux = filtersStatus
  aux.forEach((e) => {
    e.visible = status
  })
  // console.log('aux',aux)
  await setFiltersStatus(aux)
  forceUpdate()

}

  return (
    <div className="containerStatusAbc">
      <div className="containerStatusConfirm">
        <div className="containerStatusName">
        </div>
        <div className="statusConfirm">
          <p className="h2status">Status</p>
          <button className="btn btn-primary" style={{width: "50px"}} onClick={()=> {
            // console.log(filtersStatus, filtersStatus.filter((x) => x.visible == true).map((x) => x.label))
            props.prop.listFilterStatusProducts.current[props.prop.data] = filtersStatus.filter((x) => x.visible == true).map((x) => x.label.toUpperCase())
            props.prop.filtersList.current[props.prop.data] = filtersStatus.filter((x) => x.visible == true).map((x) => x.label.toUpperCase())
            // console.log('FILTROS UTILIZADOS', props.prop.filtersList.current)
            
            // console.log('COMEÇANDO COM', props.prop.listSearchedClients.current.length)
            props.prop.listFilteredClients.current = props.prop.listSearchedClients.current.filter((row) => {
              var exists = true;
              for (const property in props.prop.filtersList.current) {
                  exists = props.prop.filtersList.current[property].length === 0 ? exists : property === "search" && props.prop.filtersList.current["search"] !== "" ? (Object.values(row).join("|").toLowerCase().indexOf(String(props.prop.filtersList.current[property])) > -1 ? exists : false) : props.prop.filtersList.current[property].includes(row[property]) ? exists : false;
              }
              return exists;
            });
            // console.log('APÓS FILTRO', props.prop.listFilteredClients.current.length)
            props.prop.listClients.current = (props.prop.listFilteredClients.current.slice(0, 201));
            props.prop.atualizador();
            setType({ prop: props.prop })
            setShow(false);
            }}>OK</button>
        </div>
      </div>
      <div className="containerStatusList">
          <h3 style={{ marginLeft: 10, marginTop: 10 }}>{props.prop.description}</h3>
          <div className="sliceStatusList">
          </div>
          <div className="containerCheckbox">
            <FormGroup className="statusList">
            {filtersStatus.map((e,i)=> {
              return(
                <>
                <FormControlLabel 
                  control={
                    <Checkbox style={{ color : e.color}}/> } 
                    value={e.label} 
                    checked={e.visible} 
                    id={i}
                    name={e.label}
                    onChange={el => handleChange(el,i)} 
                    label={titleize(e.label) + (e.obs ? '*' : '')}
                    >
                </FormControlLabel>
                </>
                
              )
            })}
              </FormGroup>
          </div>
          <div className="observationFilterAbc">* Apenas Backoffice Altera</div>
        <div className="containerSelectAll">
          <div></div>
          <button className="btn btn-primary" onClick={() => selectAll(true)}>Marcar Todos</button>
          <div></div>
          <button className="btn btn-primary" onClick={() => selectAll(false)}>Desmarcar Todos</button>
          <div></div>
        </div>
      </div>
    </div>
  );
  
//   function getOperationId() {
//     const prod_name = props.prop.product.name;
//     const operation_id = props.prop.client[`${prod_name}_operation_id`]
//     setOperationId(operation_id)
//   }

//   function getCategory() {
//     const prod_name = props.prop.product.name;
//     const cat_id = props.prop.client[`${prod_name}_category_id`]
//     const cat =  props.prop.product.categories.filter((x) => x.id == cat_id)
//     setCategoryInfos(cat[0]);
//   }

//   function getLink() {
//     const prod_type = props.prop.product.type.id;
//     if (prod_type == 1 || prod_type == 2) {
//       // ESTRUTURADAS - 1 COLLAR WISIR - 2 SWING TRADE
//       setLink({
//         boletar: '#/books',
//         acompanhamento: user.type < 3 ? '#/clients' : '#/clientesMesa'
//       })
//     } else if (prod_type == 3) {
//       // OFERTA PÚBLICA
//       setLink({
//         boletar: '#/fiis',
//         acompanhamento: user.type < 3 ? '#/clients' : '#/clientesMesa'
//       })
//     } 
//   }

//   async function updateStatus(status_id) {
//     setLoading(true);  
//       try {
//           const res = await updateABCStatus(tokenKey, operationId, status_id);
//           console.log(res?.data?.data);
//       } catch (error) {
//           setLoading(false);
//           setType({ name: 'alertMsg', type: 'information', value: { message: error?.response?.data?.message == undefined ? 'Erro interno ao carregar os dados' : error?.response?.data?.message } })
//           setShow(true);
//       }
//       setLoading(false);
//   }

//   useEffect(() => {
//     getOperationId()  
//     getCategory()
//     getLink()
//   }, [show]);

  // return (
  //   <div className="containerModalAbcDetails">
  //     <div className="clientControlHeader">
  //       <div className="editBrancheBoxHeader">
  //         <div className="containerProductName">
  //           <p>Ações Disponíveis</p>
  //         </div>
  //         <button
  //           onClick={() => setShow(false)}
  //           className="modalCloseButton"
  //         >
  //           X
  //         </button>
  //       </div>
  //     </div>
  //     <div className="containerClientMenuAbcActions">
  //       <div className="containerClientMenuOptionsAbcActions">
  //         <div className="containerNameAbcActions">
  //           <div className="clientInfoAbcActions">{props?.prop?.client?.nome} - {props?.prop?.client?.codigo}</div>
  //         </div>
  //         <div className="containerGeneralOptionsAbcActions">
  //           <div className="containerOptionsTitleAbcActions">
  //             <div className="optionsTitleAbcActions">{props?.prop?.product?.description}</div>
  //           </div>
  //           <div className="containerOptionsAbcActions">
  //             <button className="buttonAbcActions">Boletar</button>
  //             <button className="buttonAbcActions">Acompanhamento</button>
  //           </div>
  //         </div>
  //         <div></div>
  //         <div className="containerGeneralChangeStatusAbcActions">
  //           <div className="containerChangeStatusTitleAbcActions">
  //             <div className="ChangeStatusTitleAbcActions">Alterar Status</div>
  //           </div>
  //           <div className="containerChangeStatusAbcActions">
  //             <button className="buttonAbordadoAbcActions">Abordado</button>
  //             <button className="buttonRecusadoAbcActions">Recusado</button>
  //           </div>
         
  //         </div>
  //       </div>
  //       <div className="divisionNameProductSubcategory"></div>
  //       <div className="containerClientMenuSubcategoryAbcActions">
  //         <div className="headerSubcategoryAbcActions">
  //           <div className="headerSubcategoryTitleAbcActions">Categoria fictícia para props.prop.listFilterStatusProducts.current[props.prop.data]</div>
  //         </div>
  //         <div className="containerSubcategoryDescriptionAbcActions">

  //         </div>
  //         <div className="headerSubcategoryAbcActions">
  //           <div className="headerSubcategoryTitleAbcActions">Categoria fictícia para props.prop.listFilterStatusProducts.current[props.prop.data]</div>
  //         </div>
  //       </div>
  //     </div>
  //   </div>
  // );
}
