import React, { useEffect, useState, useRef, useCallback } from 'react';

import { api } from '../../../services/api';
import { useAuth } from '../../../contexts/useAuth';
import { useModal } from '../../../contexts/useModal';

import Modal from '../../../components/modal/modal';
import Alert from '../../../components/alert/alert';

import { useMenu } from '../../../contexts/useMenu'
import { ConfirmationBox } from '../../../components/confirmationBox/confirmationBox';

import { FundsProductsTable } from './tables/funds.products.table';
import { FundsClientsTable } from './tables/funds.clients.table';
import { FundsValidatedTable } from './tables/funds.validated.table';
import { InvestmentsEditScenarios } from './investmentsEditScenarios';

import  CampainsCarrousel  from '../dashboard/components/campainsCarrousel';
import { getCampaignsArea } from '../../../services/asyncFunctions';

import { VscGraphLine } from 'react-icons/vsc';
import { HiDocumentReport } from 'react-icons/hi';
import { ArrowLoader } from '../../../components/loaders/arrowLoader';
import { BsGraphUpArrow } from 'react-icons/bs';
import { CampainSpeech } from '../dashboard/speech_modal';
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

export function FundsInvestments(props) {
    const { codCliente, tab } = useParams();
    console.log('codCliente', codCliente)
    // console.log('FundsInvestments', props.location.state);
    const [tabActive, setTabActive] = useState(codCliente ? 2 : 1 );
    const [campaignCards, setCampaignCards] = useState([]);
    const { show, setShow, type, setType, edited, setEdited } = useModal();
    const [loading, setLoading] = useState(true);
    const { tokenKey, user } = useAuth()

    async function getCards() {
        let listCards = await getCampaignsArea(tokenKey, false, 3)
        if (getCampaignsArea?.isError) {
            setType({
                name: "alertMsg",
                type: "information",
                value: {
                    message: getCampaignsArea?.error,
                },
            })
            setShow(true)
            setCampaignCards([])
        } else {
            listCards = {
                current: listCards
            }
            setCampaignCards(listCards)
            // console.log(listCards);
            setLoading(false)
        }
    }

    useEffect(() => {
        setShow(false)
        setEdited(false)
        getCards()
    }, [])

    // console.log(campaignCards);

    return (

        <div style={{ height: '-webkit-fill-available', overflow: 'auto', overflowX: 'hidden' }}>
            <Modal>
                    {
                        type.name === "alertMsg" ? <ConfirmationBox msgData={type} /> : 
                        type.name === "editScenarios" ? <InvestmentsEditScenarios msgData={type} data={{ page: 'FUNDOS' }} /> :
                        type.name === "speechModal" ? <CampainSpeech prop={type} /> :
                        <></>
                    }                    
            </Modal>
            { loading ? 
                <ArrowLoader/>
                :
                <>
            <div>
                <div className='campaignsContainer'>
                {/* <span style={{ fontSize: '24px', marginBottom: '1rem'}}>Campanhas em Andamento da Categoria</span> */}
                {
                    campaignCards?.current?.length === 0 ? <div style={{ fontSize: '32px', textAlign: 'center'}}>Não há campanhas em andamento para esta categoria.</div> :
                    <CampainsCarrousel selectedCampaign={null} setSelectedCampaign={null} listProducts={campaignCards} campaignPriority={null} productPage={true}/> 
                }
                    <div className='actionsDash'>
                    <button
                        style={{ background: "#D5AF01", color: "white" }}
                        className="dashButton"
                        onClick={() => {
                            window.open('https://maisretorno.com/comparacao-fundos', '_blank', 'noopener,noreferrer')
                        }}
                    >
                        <BsGraphUpArrow size={40} />
                        Comparador de Fundos
                    </button>
                    <button
                        style={{ background: "#C29C00", color: "white" }}
                        className="dashButton"
                        onClick={() => {
                            window.open('https://agenteinvest-my.sharepoint.com/personal/joao_000295618_agenteinvest_com_br/_layouts/15/onedrive.aspx?id=%2Fpersonal%2Fjoao%5F000295618%5Fagenteinvest%5Fcom%5Fbr%2FDocuments%2FArquivos%20de%20Chat%20do%20Microsoft%20Teams%2FRelat%C3%B3rio%20de%20An%C3%A1lise%20de%20Fundos%20%2D%203%C2%B0%20trimestre2023%2Epdf&parent=%2Fpersonal%2Fjoao%5F000295618%5Fagenteinvest%5Fcom%5Fbr%2FDocuments%2FArquivos%20de%20Chat%20do%20Microsoft%20Teams&ga=1', '_blank', 'noopener,noreferrer')
                        }}
                    >
                        <HiDocumentReport size={50} />
                        Relatório Trimestral
                    </button>
                    </div>
                </div>
        </div>

        <div className='testeBrokerDiv'>
            <div className="unsuitedStructuredContainer">
                <div className="estructuredsBody">
                    <div className="estructuredsTab">
                        <div
                            className={tabActive === 1 ? "estructuredsTabOption360" : "estructuredsInactiveTabOption360"}
                            onClick={() => setTabActive(1)}
                        >
                            <p>Produtos</p>
                            
                        </div>
                        <div className={tabActive === 2 ? 'estructuredsTabOption360' : 'estructuredsInactiveTabOption360'} onClick={() => setTabActive(2)}>
                            <p>Clientes</p>
                            
                        </div>
                        <div className={tabActive === 3 ? 'estructuredsTabOption360' : 'estructuredsInactiveTabOption360'} onClick={() => setTabActive(3)}>
                            <p>Fundos</p>
                            
                        </div>
                    </div>

                    {(user.type === 4) || (user.type < 3) ?
                        tabActive === 1 ? 
                        <FundsProductsTable /> : tabActive === 2 ? 
                        <FundsClientsTable props={codCliente != undefined ? codCliente : null} /> : 
                        <FundsValidatedTable /> 
                        // <NotExecutedTbl /> : <IndexProjectedTbl />
                    : <></>}

                </div>
            </div>
        </div>
        </>
        }
        </div>

        // <>
        // {/* <div style={{ marginTop: '1rem' }}>
        //     Aqui entram os cards de campanha e os links da página
        // </div> */}

        // <div className='testeBrokerDiv'>
        //     <div className="unsuitedStructuredContainer">
        //         <Modal>
        //             {
        //             type.name === "alertMsg" ? <ConfirmationBox msgData={type} /> : 
        //             type.name === "editScenarios" ? <ConfirmationBox msgData={type} /> :
        //             <></>
        //             }                    
        //         </Modal>
        //         <div className="estructuredsBody">
        //             <div className="estructuredsTab">
        //                 {/* <div
        //                     className={tabActive === 1 ? "estructuredsTabOption360" : "estructuredsInactiveTabOption360"}
        //                     onClick={() => setTabActive(1)}
        //                 >
        //                     <p>Produtos</p>
        //                     
        //                 </div> */}
        //                 <div className={tabActive === 2 ? 'estructuredsTabOption360' : 'estructuredsInactiveTabOption360'} onClick={() => setTabActive(2)}>
        //                     <p>Clientes</p>
        //                     
        //                 </div>
        //                 <div className={tabActive === 3 ? 'estructuredsTabOption360' : 'estructuredsInactiveTabOption360'} onClick={() => setTabActive(3)}>
        //                     <p>Fundos Validados / Não-Validados</p>
        //                     
        //                 </div>
        //             </div>

        //             {(user.type === 4 && [25245, 20412, 22403, 72269, 21203].includes(user.external_id)) || (user.type < 3) ?
        //                 tabActive === 1 ? 
        //                 <FundsProductsTable /> : tabActive === 2 ? 
        //                 <FundsClientsTable /> : 
        //                 <FundsValidatedTable /> 
        //             : <></> }
        //         </div>
        //     </div>
        // </div>
        // </>
    )
}