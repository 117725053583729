//**Funcão para retornar CNPJ com as pontuações
//Desenvolvido por: Rafael Cezário */

export function cnpjMask(e) {

    return e
        .replace(/\D/g, '') // substitui qualquer caracter que não seja numero por nada
        .replace(/(\d{2})(\d)/, '$1.$2') // captura 2 grupos de numero o primeiro de 2 e o segundo de 3, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d{1,2})/, '$1.$2')
        .replace(/(\d{4})(\d)/, '$1-$2') // captura 1 grupo de numeros com 4 digitos e adiciona um hífen ao final
        .replace(/(-\d{2})\d+?/, '$1')// captura dois numeros seguidos de um hífen e não deixa digitar nada além disto.
}