/*Componente cadastro de clientes
/*Descrição: Efetua o cadastro de novos clientes no sistema e chama o componente de importação
/*Desenvolvido por: Equipe Rafael Cezário
*/

import React, { useState, useEffect, useRef } from "react"
import { TextField, Select, MenuItem } from "@material-ui/core"
import { Autocomplete } from "@material-ui/lab"
//styles
import "./abcModal.css"
import { styleClientsInput } from "../../../../components/styles/autocompletField"
//services
import { api } from "../../../../services/api"
//hooks
import { useModal } from "../../../../contexts/useModal"
//Contexts
import { useAuth } from "../../../../contexts/useAuth"
//components
import { ArrowLoader } from "../../../../components/loaders/arrowLoader"
//utils
import CurrencyTextField from "@unicef/material-ui-currency-textfield"
import { titleize } from "../../../../utils/formatNumberAndText/formatTextUpperFirstLetter"
import { ModalSizeFitToScreen } from "../../../../utils/responsiveFunctions/modalSizeFitToScreen"
import { FiMinus, FiPlus } from "react-icons/fi"

export function AbcManagementCreateModal() {
    const { tokenKey } = useAuth()
    const [selectedProductNetAderence, setSelectedProductNetAderence] = useState("")
    const [selectedProductName, setSelectedProductName] = useState("")
    const [selectedProductStart, setSelectedProductStart] = useState("")
    const [selectedProductFinish, setSelectedProductFinish] = useState("")
    const [selectedProductLiquidacao, setSelectedProductLiquidacao] = useState("")
    const [selectedProductAderence, setSelectedProductAderence] = useState("")
    const [internalSpeech, setInternalSpeech] = useState("")
    const [speech, setSpeech] = useState("")
    const [selectedProductType, setSelectedProductType] = useState(null)
    const [selectedProduct, setSelectedProduct] = useState(null)
    const [selectedCamaignPriority, setSelectedCamaignPriority] = useState(null)
    const [selectedProductArea, setSelectedProductArea] = useState(null)
    const [selectedProductDescription, setSelectedProductDescription] = useState("")
    const [selectedProductCardLink, setSelectedProductCardLink] = useState("")
    const [liquidationTypes, setLiquidationTypes] = useState({0: 'Oferta', 1: 'Plataforma'})
    const [selectedLiquidationType, setSelectedLiquidationType] = useState(0)
    const [liquidacaoPlataforma, setLiquidacaoPlataforma] = useState(0)
    const [areas, setAreas] = useState([])
    const [types, setTypes] = useState([])
    const [estruturadas, setEstruturadas] = useState([])
    const [offers, setOffers] = useState([])
    // const [importadas, setImportadas] = useState([])
    const classes = styleClientsInput()
    const token = localStorage.getItem("@auth:token")
    const { show, setShow, setType, edited, setEdited } = useModal()
    const [loading, setLoading] = useState(true)
    const { screenY } = ModalSizeFitToScreen()
    const [categories, setCategories] = useState([])
    const [fee, setFee] = useState(0)
    const [fator, setFator] = useState()
    const [lastVisitedPage, setLastVisitedPage] = useState("")
    const [selectedTipoInvestidor, setSelectedTipoInvestidor] = useState('')
    var auxCategories = useRef({
        categories: [],
    })
    const [campaignPriority, setCampaignPriority] = useState([])
    const tipoInvestidor = [
        {
            value: 1,
            label: 'Geral'
        },
        {
            value: 2,
            label: 'Qualificado'
        },
    ]

    const requiredFields = [
        { field: "selectedProductName", value: selectedProductName },
        { field: "selectedProductDescription", value: selectedProductDescription },
        { field: "selectedProductArea", value: selectedProductArea },
        { field: "selectedProductType", value: selectedProductType },
        { field: "selectedProductStart", value: selectedProductStart },
        { field: "selectedProductFinish", value: selectedProductFinish }
        
    ]

    const requiredFieldsRV = [
        { field: "selectedProductName", value: selectedProductName },
        // { field: "selectedProductDescription", value: selectedProductDescription },
        // { field: 'selectedProductCardLink', value: selectedProductCardLink },
        { field: "selectedProductArea", value: selectedProductArea },
        { field: "selectedProductType", value: selectedProductType },
        // { field: "selectedProduct", value: selectedProduct },
        { field: "selectedProductAderence", value: selectedProductAderence },
        { field: "selectedProductNetAderence", value: selectedProductNetAderence },
        { field: "selectedProductStart", value: selectedProductStart },
        { field: "selectedProductFinish", value: selectedProductFinish },
        { field: "selectedProductLiquidacao", value: selectedProductLiquidacao }
    ]
    const [errorFields, setErrorFields] = useState({
        selectedProductName: false,
        selectedProductDescription: false,
        // selectedProductCardLink: false,
        selectedProductArea: false,
        selectedProductType: false,
        selectedProduct: false,
        selectedProductAderence: false,
        selectedProductNetAderence: false,
        selectedProductStart: false,
        selectedProductFinish: false,
        selectedProductLiquidacao: false,
        selectedCategories: false
    })

    useEffect(() => {
        getArea()
        getTypes()
        getOperations()
        getPriorities()
        clear()
    }, [show])

    useEffect(() => {
        const lastVisitedPageAux = localStorage.getItem("lastVisitedPage")
        setLastVisitedPage(lastVisitedPageAux)
    }, [])

    async function alterCategoryTitle(id, title) {
        auxCategories.current.categories[id].title = title
    }

    async function alterCategoryLink(id, link) {
        auxCategories.current.categories[id].link = link
    }

    async function addCategory() {
        setLoading(true)
        auxCategories.current.categories.push({
            title: null,
            link: null,
        })
        var aux = categories
        aux.push(aux.length)
        await setCategories(aux)
        setLoading(false)
    }

    async function removeCategory(id) {
        setLoading(true)
        auxCategories.current.categories.splice(id, 1)
        var aux = categories
        aux.splice(id, 1)
        await setCategories(aux)
        setLoading(false)
    }

    async function handleSubmit(e) {
        let errors = false
        const aux = errorFields

        if (categories.length == 0) {
            aux["selectedCategories"] = true
            errors = true
        } else {
            aux["selectedCategories"] = false
            auxCategories.current.categories.map((x) => {
                if (x.title == null || x.title == undefined || x.title == "") {
                    aux["selectedCategories"] = true
                    errors = true
                }
                if (x.link != null && x.link != undefined && !x.link?.trim().length) {
                    aux["selectedCategories"] = true
                    errors = true
                }
            })
        }

        if (selectedProductArea?.id == 1) {
            requiredFieldsRV.map((required) => {
                if (typeof required.value !== "object" && !required?.value?.toString()?.trim()?.length) {
                    aux[required.field] = true
                    errors = true
                } else {
                    aux[required.field] = false
                }
            })
        } else {
            requiredFields.map((required) => {
                if (typeof required.value !== "object" && !required?.value?.toString()?.trim()?.length) {
                    aux[required.field] = true
                    errors = true
                } else {
                    aux[required.field] = false
                }
            })
        }
        setErrorFields(aux)

        if (errors) {
            await new Promise((resolve) => setTimeout(resolve, 1000))
            alert("Campos obrigatórios não preenchidos")
            setLoading(false)
            return
        }

        e.preventDefault()

        if (selectedProductArea?.id == 1) {
            if (
                selectedProductName === "" ||
                selectedProductType === null ||
                (selectedProductType?.id !== 3 && selectedProduct === null) ||
                selectedProductAderence === "" ||
                selectedProductNetAderence === "" ||
                selectedProductStart === "" ||
                selectedProductFinish === "" ||
                selectedProductLiquidacao === ""
            ) {
                alert("Preencha todos os campos")
                return
            }
        } else {
            if (selectedProductName === "" || selectedProductType === null || selectedProductStart === "" || selectedProductFinish === "" ) {
                alert("Preencha todos os campos")
                return
            }
        }

        try {
            setLoading(true)
            await api.post(
                "/api/v1/abc/config/products",
                {
                    products: [
                        {
                            description: selectedProductName,
                            card_description: selectedProductDescription,
                            card_link: selectedProductCardLink,
                            area: selectedProductArea?.id ? parseInt(selectedProductArea.id) : null,
                            aderence: selectedProductAderence ? parseFloat(selectedProductAderence) : null,
                            net_aderence: selectedProductNetAderence ? parseFloat(selectedProductNetAderence) : null,
                            start: selectedProductStart,
                            finish: selectedProductFinish,
                            liquidation: selectedProductLiquidacao,
                            type: selectedProductType?.id ? parseInt(selectedProductType.id) : null,
                            reference: selectedProduct?.id ? parseInt(selectedProduct.id) : null,
                            categories: auxCategories.current.categories,
                            priority: selectedCamaignPriority?.id ? parseInt(selectedCamaignPriority.id) : null,
                            fee: fee && parseFloat(fee) > 0 ? parseFloat(parseFloat(fee/100).toFixed(6)) : null,
                            tipo_investidor: selectedTipoInvestidor,
                            fator: fator && parseFloat(fator) > 0 ? parseFloat(parseFloat(fator/100).toFixed(6)) : null,
                            internal_speech: internalSpeech,
                            speech: speech,
                            liquidation_type: selectedLiquidationType,
                            liquidacao_plataforma: selectedLiquidationType == 1 ? parseInt(liquidacaoPlataforma) : null
                        },
                    ],
                },
                {
                    headers: {
                        authorization: `Bearer ${token}`,
                    },
                }
            )
            setLoading(false)
            setType({ name: "alertMsg", type: "information", value: { message: `Sucesso ao cadastrar o produto ${selectedProductName}` } })
            setEdited(true)
        } catch (error) {
            alert(error?.response?.data?.message)
            setShow(false)
        }
    }

    async function getOperations() {
        const operations = await api.get(`/api/v1/abc/config/references`, {
            headers: {
                authorization: `Bearer ${token}`,
            },
        })
        // console.log('OPERAÇÕES', operations?.data?.data)
        setOffers(operations?.data?.data?.ofertas_publicas != null ? operations?.data?.data?.ofertas_publicas : [])
        setEstruturadas(operations?.data?.data?.estruturadas != null ? operations?.data?.data?.estruturadas : [])
        // setImportadas(operations?.data?.data?.importadas != null ? operations?.data?.data?.importadas : [])
        setLoading(false)
    }

    async function getPriorities() {
        const priorities = await api.get(`/api/v1/abc/config/priorities`, {
            headers: {
                authorization: `Bearer ${token}`,
            },
        })
        setCampaignPriority(priorities?.data?.data)
        setLoading(false)
    }

    async function getArea() {
        const area = await api.get(`/api/v1/abc/config/areas`, {
            headers: {
                authorization: `Bearer ${token}`,
            },
        })
        setAreas(area?.data?.data)
        // console.log('ÁREAS', area?.data?.data)
        setLoading(false)
    }

    async function getTypes() {
        const types = await api.get(`/api/v1/abc/config/types`, {
            headers: {
                authorization: `Bearer ${token}`,
                // area_id: 1 // !TIP DO GD: AREA 1 REPRESENTA RENDA VARIÁVEL, SE QUISER TRAZER TODOS OS TIPOS DE TODAS AS ÁREAS, BASTA REMOVER ESSA LINHA
            },
        })
        setTypes(types?.data?.data)

        setLoading(false)
    }

    function clear() {
        setEstruturadas([])
        setOffers([])
        setCategories([])
        auxCategories.current.categories = []
        setSelectedProductName("")
        setSelectedProduct(null)
        setSelectedProductType(null)
        setSelectedProductAderence("")
        setSelectedProductNetAderence("")
        setSelectedProductStart("")
        setSelectedProductFinish("")
        setSelectedProductDescription("")
        setSelectedProductCardLink("")
        setSelectedProductArea(null)
        setErrorFields({
            selectedProductName: false,
            selectedProductType: false,
            selectedProductAderence: false,
            selectedProductNetAderence: false,
            selectedProductStart: false,
            selectedProductFinish: false,
            selectedCategories: false,
            selectedProductDescription: false,
            selectedProductCardLink: false,
            selectedProductArea: false,
        })
        setCampaignPriority([])
        setSelectedCamaignPriority(null)
        setFee(0)
        setFator(0)
        setSelectedTipoInvestidor('')

    }

    return (
        <div
            className="productCadastrationContainerAbcCopyWithoutTemplate"
            style={{
                maxHeight: screenY,
            }}
        >
            {loading ? (
                <div className="clientsCadastrationLoading">
                    <ArrowLoader />
                </div>
            ) : (
                <>
                    <div className="productCadastrationHeaderAbc">
                        <span>Efetue o cadastro do produto abaixo</span>
                        <button onClick={() => setShow(false)}>x</button>
                    </div>
                    <div className="productCadastrationContainerAbcCopyWithoutTemplate">
                        <div className="productCadastrationUpperUpperGridAbc">
                            <label>
                                <TextField
                                    label="Nome"
                                    onKeyPress={(e) => {
                                        if (e.code === "Backquote") e.preventDefault()
                                    }}
                                    value={selectedProductName}
                                    className={errorFields["selectedProductName"] && !selectedProductName?.trim().length ? classes.styleForClientsWithError : classes.styleForClients}
                                    onBlur={(e) => {
                                        setSelectedProductName(e.target.value)
                                    }}
                                    onFocus={(e) => {
                                        setSelectedProductName(null)
                                    }}
                                    variant="outlined"
                                />
                            </label>

                            <label>
                                <TextField
                                    label="Descrição (para o Assessor)"
                                    onKeyPress={(e) => {
                                        if (e.code === "Backquote") e.preventDefault()
                                    }}
                                    value={selectedProductDescription}
                                    className={errorFields["selectedProductDescription"] && !selectedProductDescription?.trim().length ? classes.styleForClientsWithError : classes.styleForClients}
                                    onBlur={(e) => {
                                        setSelectedProductDescription(e.target.value)
                                    }}
                                    onFocus={(e) => {
                                        setSelectedProductDescription(null)
                                    }}
                                    variant="outlined"
                                    style={{ marginTop: "1rem" }}
                                />
                            </label>

                            <label>
                                <TextField
                                    label="Link de material (para o Assessor)"
                                    onKeyPress={(e) => {
                                        if (e.code === "Backquote") e.preventDefault()
                                    }}
                                    value={selectedProductCardLink}
                                    className={errorFields["selectedProductCardLink"] && !selectedProductCardLink?.trim().length ? classes.styleForClientsWithError : classes.styleForClients}
                                    onBlur={(e) => {
                                        setSelectedProductCardLink(e.target.value)
                                    }}
                                    onFocus={(e) => {
                                        setSelectedProductCardLink(null)
                                    }}
                                    variant="outlined"
                                    style={{ marginTop: "1rem" }}
                                />
                            </label>
                        </div>
                        <div className="productCadastrationUpperGridAbc">
                            <label>
                                <Autocomplete
                                    options={areas}
                                    value={selectedProductArea}
                                    onChange={(event, newValue) => {
                                        setSelectedProductArea(newValue)
                                        setSelectedProductType(null)
                                        setSelectedProduct(null)
                                    }}
                                    getOptionLabel={(option) => option?.value}
                                    getOptionSelected={(option) => option?.value}
                                    id="productType"
                                    renderInput={(params) => (
                                        <TextField
                                            label="Área"
                                            onKeyPress={(e) => {
                                                if (e.code === "Backquote") e.preventDefault()
                                            }}
                                            // className="productCadastrationContainerRowItemAbc"
                                            className={errorFields["selectedProductType"] && !selectedProductType?.id ? classes.styleForClientsWithError : classes.styleForClients}
                                            {...params}
                                            variant="outlined"
                                        />
                                    )}
                                />
                            </label>

                            <label>
                                <Autocomplete
                                    options={types.filter((x) => x.area_id == selectedProductArea?.id)}
                                    value={selectedProductType}
                                    onChange={(event, newValue) => {
                                        setSelectedProductType(newValue)
                                        setSelectedProduct(null)
                                    }}
                                    getOptionLabel={(option) => option?.value}
                                    getOptionSelected={(option) => option?.value}
                                    id="productType"
                                    renderInput={(params) => (
                                        <TextField
                                            label="Tipo do Produto"
                                            onKeyPress={(e) => {
                                                if (e.code === "Backquote") e.preventDefault()
                                            }}
                                            // className="productCadastrationContainerRowItemAbc"
                                            className={errorFields["selectedProductType"] && !selectedProductType?.id ? classes.styleForClientsWithError : classes.styleForClients}
                                            {...params}
                                            variant="outlined"
                                        />
                                    )}
                                />
                            </label>

                            {selectedProductArea && selectedProductArea?.id == 1 ? (
                                <>
                                    <label>
                                        <Autocomplete
                                            options={selectedProductType?.id == 1 ? estruturadas : selectedProductType?.id == 2 ? offers : []}
                                            value={selectedProduct}
                                            onChange={(event, newValue) => {
                                                setSelectedProduct(newValue)
                                            }}
                                            getOptionLabel={(option) => option?.value}
                                            getOptionSelected={(option) => option?.value}
                                            id="productOperation"
                                            renderInput={(params) => (
                                                <TextField
                                                    label="Produto"
                                                    onKeyPress={(e) => {
                                                        if (e.code === "Backquote") e.preventDefault()
                                                    }}
                                                    className={errorFields["selectedProduct"] && !selectedProduct?.id ? classes.styleForClientsWithError : classes.styleForClients}
                                                    {...params}
                                                    variant="outlined"
                                                />
                                            )}
                                        />
                                    </label>
                                    <label>
                                        <TextField
                                            label="Aderencia"
                                            // onKeyPress={(e) => { if (e.code === 'Backquote') e.preventDefault() }}
                                            value={selectedProductAderence}
                                            type="number"
                                            className={errorFields["selectedProductAderence"] && selectedProductAderence == "" ? classes.styleForClientsWithError : classes.styleForClients}
                                            onChange={(e) => {
                                                if (e.target.value <= 100) {
                                                    setSelectedProductAderence(e.target.value)
                                                } else {
                                                    setSelectedProductAderence(100)
                                                }
                                            }}
                                            variant="outlined"
                                        />
                                    </label>
                                    <label>
                                        <TextField
                                            label="Aderencia NET"
                                            // onKeyPress={(e) => { if (e.key === 'e' || e.key === 'E' || e.code === 'Backquote') { e.preventDefault() } }}
                                            value={selectedProductNetAderence}
                                            type="number"
                                            className={errorFields["selectedProductNetAderence"] && selectedProductNetAderence == "" ? classes.styleForClientsWithError : classes.styleForClients}
                                            onChange={(e) => {
                                                if (e.target.value <= 100) {
                                                    setSelectedProductNetAderence(e.target.value)
                                                } else {
                                                    setSelectedProductNetAderence(100)
                                                }
                                            }}
                                            // onFocus={(e) => { setSelectedProductNetAderence(null) } }
                                            variant="outlined"
                                        />
                                    </label>
                                </>
                            ) : selectedProductArea?.id != null ? (
                                <Autocomplete
                                    options={campaignPriority}
                                    value={selectedCamaignPriority}
                                    onChange={(event, newValue) => {
                                        setSelectedCamaignPriority(newValue)
                                    }}
                                    getOptionLabel={(option) => `${option?.id} - ${option?.value}`}
                                    getOptionSelected={(option) => option?.value}
                                    id="campaignPriority"
                                    renderInput={(params) => (
                                        <TextField
                                            label="Prioridade da Campanha"
                                            onKeyPress={(e) => {
                                                if (e.code === "Backquote") e.preventDefault()
                                            }}
                                            className={classes.styleForClients}
                                            {...params}
                                            variant="outlined"
                                        />
                                    )}
                                />
                            ) : <></>}
                            <label>
                                <TextField
                                    label="Início"
                                    value={selectedProductStart}
                                    InputLabelProps={{ shrink: true }}
                                    type="date"
                                    className={errorFields["selectedProductStart"] && !selectedProductStart?.trim()?.length ? classes.styleForClientsWithError : classes.styleForClients}
                                    onChange={(e) => {
                                        setSelectedProductStart(e.target.value)
                                    }}
                                    variant="outlined"
                                />
                            </label>
                            <label>
                                <TextField
                                    label="Fim"
                                    value={selectedProductFinish}
                                    type="date"
                                    InputLabelProps={{ shrink: true }}
                                    className={errorFields["selectedProductFinish"] && !selectedProductFinish?.trim()?.length ? classes.styleForClientsWithError : classes.styleForClients}
                                    onChange={(e) => {
                                        setSelectedProductFinish(e.target.value)
                                    }}
                                    variant="outlined"
                                />
                            </label>
                            <label>
                                <Select
                                    value={selectedLiquidationType}
                                    onChange={(event, newValue) => {
                                        setSelectedLiquidationType(newValue.props.value)
                                    }}
                                    id="liquidation_type"
                                    className={errorFields["selectedLiquidationType"] && !selectedLiquidationType?.id ? classes.styleForClientsWithError : classes.styleForClients}
                                >
                                    <MenuItem value={0}>Oferta</MenuItem>
                                    <MenuItem value={1}>Plataforma</MenuItem>
                                </Select>
                            </label>
                            {
                                selectedLiquidationType == 0 ?
                            <label>
                                <TextField
                                    label="Liquidação"
                                    value={selectedProductLiquidacao}
                                    type="date"
                                    InputLabelProps={{ shrink: true }}
                                    className={errorFields["selectedProductLiquidacao"] && !selectedProductLiquidacao?.trim()?.length ? classes.styleForClientsWithError : classes.styleForClients}
                                    onChange={(e) => {
                                        setSelectedProductLiquidacao(e.target.value)
                                    }}
                                    variant="outlined"
                                />
                            </label> :
                            <label>
                            <TextField
                                label="Liquidação D+x"
                                onChange={(e) => {
                                    setLiquidacaoPlataforma(e.target.value)
                                }}
                                value={liquidacaoPlataforma}
                                type="number"
                                InputLabelProps={{ shrink: true }}
                                className={errorFields["liquidacaoPlataforma"] && !selectedLiquidationType?.trim()?.length ? classes.styleForClientsWithError : classes.styleForClients}
                                variant="outlined"
                            />
                            </label>
                            }
                            <label>
                                <TextField
                                    label="FEE (%)"
                                    value={fee}
                                    type="number"
                                    className={classes.styleForClients}
                                    onChange={(e) => {
                                        setFee(e.target.value)
                                    }}
                                    variant="outlined"
                                />
                            </label>
                            <label>
                                <TextField
                                    label="FATOR (%)"
                                    value={fator}
                                    type="number"
                                    className={classes.styleForClients}
                                    onChange={(e) => {
                                        setFator(e.target.value)
                                    }}
                                    variant="outlined"
                                />
                            </label>
                            <label>
                                
                                <TextField
                                    id="outlined-select-currency"
                                    select
                                    label="Tipo Investidor"
                                    onChange={(e) => {
                                        setSelectedTipoInvestidor(e.target.value)
                                    }}
                                    // helperText="Tipo de Investidor"
                                    >
                                    {tipoInvestidor.map((option) => (
                                        <MenuItem key={option.value} value={option.label}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </label>

                        </div>
                        <div className="productCadastrationUpperUpperGridAbc">
                            <label>
                                <TextField
                                    label="Discurso interno (opcional, aparece quando não há discurso interno para a campanha)"
                                    onKeyPress={(e) => {
                                        if (e.code === "Backquote") e.preventDefault()
                                    }}
                                    value={internalSpeech}
                                    multiline
                                    minRows={2}
                                    maxRows={4}
                                    onBlur={(e) => {
                                        setInternalSpeech(e.target.value)
                                    }}
                                    onFocus={(e) => {
                                        setInternalSpeech(null)
                                    }}
                                    variant="outlined"
                                    style={{ marginTop: "1rem" }}
                                />
                            </label>

                            <label>
                                <TextField
                                    label="Discurso Comercial (opcional, aparece quando não há discurso comercial para o cliente)"
                                    onKeyPress={(e) => {
                                        if (e.code === "Backquote") e.preventDefault()
                                    }}
                                    value={speech}
                                    multiline
                                    minRows={2}
                                    maxRows={4}
                                    onBlur={(e) => {
                                        setSpeech(e.target.value)
                                    }}
                                    onFocus={(e) => {
                                        setSpeech(null)
                                    }}
                                    variant="outlined"
                                    style={{ marginTop: "1rem" }}
                                />
                            </label>
                        </div>
                        <div className="productCadastrationCategoriesGridAbc">
                            {categories ? (
                                categories.map((v, i, a) => {
                                    return (
                                        <>
                                            <div className="productCadastrationCategoryRowAbc" style={{marginTop:'1rem'}}>
                                                <label>
                                                    <TextField
                                                        key={"key" + i + "title"}
                                                        id={"c" + i + "title"}
                                                        onKeyPress={(e) => {
                                                            if (e.code === "Backquote") e.preventDefault()
                                                        }}
                                                        label={"Título do discurso " + (i + 1) + " da campanha"}
                                                        defaultValue={auxCategories.current.categories[i].title}
                                                        onChange={(e) => {
                                                            alterCategoryTitle(i, e.target.value)
                                                        }}
                                                        className={
                                                            errorFields["selectedCategories"] && !auxCategories.current.categories[i].title?.trim()?.length
                                                                ? classes.styleForClientsWithError
                                                                : classes.styleForClients
                                                        }
                                                        variant="outlined"
                                                    />
                                                </label>
                                                <label>
                                                    <TextField
                                                        key={"key" + i + "link"}
                                                        id={"c" + i + "link"}
                                                        onKeyPress={(e) => {
                                                            if (e.code === "Backquote") e.preventDefault()
                                                        }}
                                                        label={"Link do discurso "+ (i + 1) + " (opcional)"}
                                                        defaultValue={auxCategories.current.categories[i].link}
                                                        onChange={(e) => {
                                                            alterCategoryLink(i, e.target.value)
                                                        }}
                                                        className={
                                                            errorFields["selectedCategories"] &&
                                                            auxCategories.current.categories[i].link?.length &&
                                                            !auxCategories.current.categories[i].link?.trim()?.length
                                                                ? classes.styleForClientsWithError
                                                                : classes.styleForClients
                                                        }
                                                        variant="outlined"
                                                    />
                                                </label>
                                                <button
                                                    className="buttonWhiteBg"
                                                    onClick={() => {
                                                        removeCategory(i)
                                                    }}
                                                >
                                                    <FiMinus /> Categoria 
                                                </button>
                                            </div>
                                        </>
                                    )
                                })
                            ) : (
                                <></>
                            )}
                            <div className="productCadastrationCategoriesGridButtonAbc">
                                <button
                                    className="buttonWhiteBg"
                                    onClick={() => {
                                        addCategory()
                                    }}
                                >
                                    <FiPlus /> Categoria
                                </button>
                            </div>
                        </div>
                        <div className="productCadastrationSubmitAbc">
                            <button className="buttonWhiteBg" onClick={handleSubmit}>
                                Cadastrar
                            </button>
                        </div>
                    </div>
                </>
            )}
        </div>
    )
}
