/**
 * ANIMACAO DE CARREGAMENTO ENTRE TABELAS
 * DESENVOLVIVDO POR: RAFAEL CEZARIO
 * DATA: 25/05/2021
**/

import React from "react"
import './loader.css'

export function ArrowLoader(props) {
  return (
    <div className='container'>
    <span className={props.gold ? 'loadingGold' : 'loading'}></span>
    <p className={props.gold ? 'textGold' : 'text'}>Carregando...</p>
    </div>
  )
}