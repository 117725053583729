import React, { useEffect, useState, useReducer } from "react"
/*Styles*/
import "./styles/investmentsEditScenarios.style.css"
/*Contexts*/
import { api } from "../../../services/api"
import { useAuth } from "../../../contexts/useAuth"
import { useModal } from "../../../contexts/useModal"
/*Services*/
import { getFixedIncomeInformation } from "../../../services/asyncFunctions"
import { datetimeToDatePtBR } from "../../../utils/formatDate/dateTimeToPTBR"

/*Components*/
import { ArrowLoader } from "../../../components/loaders/arrowLoader"
import { toBRL } from "../../../utils/formatNumberAndText/toBRLCurrency"
import { toPercent } from "../../../utils/formatNumberAndText/formatNumberPercent"
import { FiPlusCircle } from "react-icons/fi"
import { AiFillCloseCircle } from "react-icons/ai"
import { MdSettingsPhone, MdSystemUpdateAlt } from "react-icons/md"
import { BsTrash } from "react-icons/bs"

export function InvestmentsEditScenarios(props) {
    /* console.log('aquiiiiii', props) */
    const [loading, setLoading] = useState(false)
    const [informationList, setInformationList] = useState([])
    const [newInfo, setNewInfo] = useState([])
    const { user, tokenKey } = useAuth()
    const { setType, setShow, edited, setEdited } = useModal()
    const [visible, setVisible] = useState(true)
    const [, forceUpdate] = useReducer((x) => x + 1, 0)
    const [ currentTitle, setCurrentTitle] = useState("")
    const [currentDescription, setCurrentDescription] = useState("")
    const [onEdit, setOnEdit] = useState(false)
    const [idOnEdit, setIdOnEdit] = useState(null)

    let informativesList = props?.msgData?.data
    let currentPage = props?.data?.page
    // console.log("lista:", informativesList)
    // console.log("mensagem:", currentPage)

    /* TESTE */
        /* const hookUpdateCard = () => {
            useEffect(() => {

            },)
        } */
    /* FIM TESTE */

    async function submitInformative(value) {
        if (currentDescription === "") {
            alert("O conteúdo não pode ser vazio!")
            return
        }

        if (currentTitle === "") {
            alert("O título não pode ser vazio!")
            return
        }

        try {
            const last_id = await api.post(
                `/api/v1/alocacao/products/informatives/${currentPage}`,
                {
                    description: currentDescription,
                    title: currentTitle,
                    destaque: value.destaque,
                    oportunidade: value.oportunidade,
                },
                {
                    headers: {
                        authorization: `Bearer ${tokenKey}`,
                    },
                }
            )
            // setEdited(true)
            alert("Informativo inserido com sucesso!")
            // window.location.reload(true);
            // setShow(false)
            /* window.location.reload(false); */
            
            setCurrentDescription("")
            setCurrentTitle("")
            setOnEdit(false)
            setIdOnEdit(null) 
            setShow(true) 
            /* props.msgData?.getInfo()  */
            
            

        } catch (error) {
            /* console.log(error?.response) */
            setType({
                name: "alertMsg",
                type: "information",
                value: { message: error?.response?.data?.message === undefined ? "Erro ao inserir novo informativo: " : error.response.data.message },
            })
            setShow(true)
        }
    }

    async function updateInformative(value) {
        if (currentDescription === "") {
            alert("O conteúdo não pode ser vazio!")
            return
        }

        if (currentTitle === "") {
            alert("O título não pode ser vazio!")
            return
        }

        try {
            const response = await api.put(
                `/api/v1/alocacao/products/informatives/${idOnEdit}`,
                {
                    description: currentDescription,
                    title: currentTitle,
                    destaque: value.destaque,
                    oportunidade: value.oportunidade,
                },
                {
                    headers: {
                        authorization: `Bearer ${tokenKey}`,
                    },
                }
            )
            alert("Informativo atualizado com sucesso!")
            setEdited(true)
            setCurrentDescription("")
            setCurrentTitle("")
            setOnEdit(false)
            setIdOnEdit(null)
        } catch (error) {
            /* console.log(error?.response)*/
            setType({
                name: "alertMsg",
                type: "information",
                value: { message: error?.response?.data?.message === undefined ? "Erro ao atualizar informativo" : error.response.data.message },
            })
            setShow(true)
        }
    }

    async function deleteInformative(value) {
        if (window.confirm("Você realmente deseja remover este informativo?")) {
            try {
                const response = await api.delete(`/api/v1/alocacao/products/informatives/${value.id}`, {
                    headers: {
                        authorization: `Bearer ${tokenKey}`,
                    },
                })
                // setType({
                //     name: "alertMsg",
                //     type: "information",
                //     value: { message: "Cenario deletado com sucesso" },
                // })
                // setShow(true)
                alert("Informativo removido com sucesso!")
                setEdited(true)
                /* props.msgData?.getInfo()  */
                /* window.location.reload() */
            } catch (error) {
                /* console.log(error?.response) */
                setType({
                    name: "alertMsg",
                    type: "information",
                    value: { message: error?.response?.data?.message === undefined ? "Erro ao deletar informativo" : error.response.data.message },
                })
                setShow(true)
            }
        }
    }

    return (
        <>
            <div className="investmentEditScenariosWindow">
                {
                    <>
                        <div className="titleModalInvestmentEditScenarios">
                            <div style={{ marginLeft: "1rem", color: "#D4AC00", fontWeight: "bold" }}>Gerenciamento de Informativos</div>
                            <button onClick={() => setShow(false)} className="modalCloseButtonDashboard" style={{ marginRight: "12px" }}>
                                <AiFillCloseCircle />
                            </button>
                        </div>
                        <div className="fixedIncomeProductsContainer" style={{ width: "inherit", overflow: "auto", overflowX: "hidden", maxHeight: "50rem" }}>
                                <div className="fixedIncomeProductSubContainer">
                                    <div className="fixedIncomeProductsAddInformation" style={{ position: "sticky" }}>
                                        <div style={{ padding: "30px" }}>
                                            <div style={{ color: "#D4AC00", fontSize: "1.4rem", fontWeight: "500", margin: "0.5rem", marginBottom: '2rem' }}>{onEdit ? 'Editar Conteúdo': 'Adicionar Conteúdo'}</div>
                                            <div style={{ color: "#D4AC00", fontSize: "1.2rem", fontWeight: "500", margin: "0.5rem" }}>Título</div>
                                            <div>
                                                <textarea
                                                    className="investmentsEditBox"
                                                    value={currentTitle}
                                                    style={{ width: "40vw", height: "2rem", resize: "none", borderRadius: "10px", color: "#BAB8B8", borderColor: "#BAB8B8", padddingLeft: "1rem", fontSize: "1rem" }}
                                                    onChange={(e) => {
                                                        setCurrentTitle(e.target.value)
                                                    }}
                                                    
                                                ></textarea>
                                                
                                            </div>

                                            <div style={{ color: "#D4AC00", fontSize: "1.2rem", fontWeight: "500", margin: "0.5rem" }}>Descrição</div>
                                            <div>
                                                <textarea
                                                    className="investmentsEditBox"
                                                    value={currentDescription}
                                                    style={{ width: "40vw", height: "30vh", resize: "none", borderRadius: "10px", color: "#BAB8B8", borderColor: "#BAB8B8", padddingLeft: "1rem", fontSize: "1rem" }}
                                                    onChange={(e) => {
                                                        setCurrentDescription(e.target.value)
                                                    }}
                                                ></textarea>
                                                </div>
                                                
                                                {!onEdit ? <span
                                                    style={{
                                                        display:'inline-block',
                                                        cursor: "pointer",
                                                        backgroundColor: "#dbdbdb",
                                                        padding: "10px 20px",
                                                        background: "#D4AC00",
                                                        border: " 5px solid white",
                                                        boxShadow: " -1px 1px 5px 2px #D6D6D6",
                                                        borderRadius: "10px",
                                                        color: "white",
                                                        fontWeight: "bold",
                                                        
                                                    }}
                                                    onClick={(e) => {
                                                        let textInfo = {
                                                            destaque: false,
                                                            oportunidade: false,
                                                        }
                                                        submitInformative(textInfo)

                                                    }}
                                                >
                                                    Enviar
                                                </span> :
                                                <span
                                                style={{
                                                    display:'inline-block',
                                                    cursor: "pointer",
                                                    backgroundColor: "#dbdbdb",
                                                    padding: "10px 20px",
                                                    background: "#D4AC00",
                                                    border: " 5px solid white",
                                                    boxShadow: " -1px 1px 5px 2px #D6D6D6",
                                                    borderRadius: "10px",
                                                    color: "white",
                                                    fontWeight: "bold",
                                                    
                                                }}
                                                onClick={(e) => {
                                                    let textInfo = {
                                                        destaque: false,
                                                        oportunidade: false,
                                                    }
                                                    updateInformative(textInfo)
                                                }}
                                            >
                                                Salvar Alterações
                                            </span>
                                                }
                                            {onEdit?
                                                <span
                                                style={{
                                                    display:'inline-block',
                                                    cursor: "pointer",
                                                    backgroundColor: "#dbdbdb",
                                                    padding: "10px 20px",
                                                    background: "#D4AC00",
                                                    border: " 5px solid white",
                                                    boxShadow: " -1px 1px 5px 2px #D6D6D6",
                                                    borderRadius: "10px",
                                                    color: "white",
                                                    marginLeft:'5px',
                                                    fontWeight: "bold",
                                                    
                                                }}
                                                onClick={(e) => {
                                                
                                                    setOnEdit(false)
                                                    setCurrentDescription("")
                                                    setCurrentTitle("")
                                                    setIdOnEdit(null)
                                                    
                                                }}
                                                >
                                                    Cancelar
                                                </span>
                                                :<> </>    
                                            }
                                        </div>
                                
                            </div>
                            {/*                             <hr style={{ width: '90%', marginLeft: '5%', marginRight: '5%', marginTop: '1rem', marginBottom: '1rem' }}></hr>
                             */}
                            <div className="fixedIncomeDataTextBoxesEditScenarios">
                                <div style={{ color: "#D4AC00", fontWeight: "500", display: "block" }}>Gerenciar Cards</div>
                                {informativesList.map((item, index) => (
                                    // console.log('dentro do map: ', item, index)
                                    <>
                                        <div className="fixedIncomeDataTextBoxesChild" key={index} style={{ backgroundColor: "#dbdbdb", display: "grid", gridTemplateRows: "auto" }}>
                                            {/* <textarea className="investmentsEditBox" defaultValue={item.description} style={{ resize: 'none', height: '200px' }} onChange={(e) => 
                                            {
                                                item.description = e.target.value
                                            }
                                            }>
                                        </textarea> */}
                                            <table
                                                style={{
                                                    borderCollapse: "separate",
                                                    borderSpacing: " 0 10px",
                                                    paddingLeft: "1rem",
                                                    paddingRight: "1rem",
                                                }}
                                            >
                                                <thead style={{ color: "#D4AC00", fontSize: "1.2rem" }}>
                                                    <th  style={{width:'10vw' , overflow:'hidden',textOverflow:'ellipsis'}}>Titulo</th>
                                                    <th>Atualizado em</th>
                                                    <th>Editar</th>
                                                    <th>Excluir</th>
                                                </thead>
                                                <tbody
                                                    style={{
                                                        textAlign: "center",

                                                    }}
                                                >
                                                    <tr style={{ fontSize: "1.2rem" }}>
                                                        <td  style={{maxWidth:'2vw',minWidth:'2vw', overflow:'hidden',textOverflow:'ellipsis', whiteSpace:'nowrap'}}> {item.title}</td>
                                                        <td>{datetimeToDatePtBR(item.updated_at)}</td>
                                                        <td className="editScenariosHover"onClick={() => {
                                                                setOnEdit(true)
                                                                setIdOnEdit(item.id)
                                                                setCurrentTitle(item.title)
                                                                setCurrentDescription(item.description)
                                                            }}>
                                                            <span >
                                                                    
                                                            <MdSystemUpdateAlt />
                                                            </span>
                                                        </td>
                                                        <td className="editScenariosHover" onClick={(e) => deleteInformative(item)}  >
                                                            <BsTrash />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            {/* <div>
                                            {item.title}
                                            {item.update_at}
                                        </div>
                                        <div style={{ textAlign: 'center', alignSelf: 'flex-end', display: 'inline-flex', justifyContent: 'space-around', flexDirection:'row' }}>
                                            <div onClick={(e) => updateInformative(item)} style={{ cursor: 'pointer' }}>Editar</div>
                                            <div onClick={(e) => deleteInformative(item)} style={{ cursor: 'pointer' }}>Remover</div>
                                        </div> */}
                                        </div>
                                    </>
                                ))}
                            </div></div>
                        </div>
                    </>
                }
            </div>
        </>
    )
}
