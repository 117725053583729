import React, { useEffect, useState, useRef } from 'react';
/*Styles*/
import './styles/assets.cadastration.modal.css';
import { styleClientsInput } from '../../../../components/styles/autocompletField';
import { FiArrowLeft, FiEdit2, FiCheck, FiTrash2 } from 'react-icons/fi';
/*Components*/
import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { ArrowLoader } from '../../../../components/loaders/arrowLoader';
/*Contexts*/
import { useModal } from '../../../../contexts/useModal';
import { useAuth } from '../../../../contexts/useAuth';
/*Utils*/
import { titleize } from '../../../../utils/formatNumberAndText/formatTextUpperFirstLetter';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
/*Services*/
import { api } from '../../../../services/api';
import { getAssetSectors } from '../../../../services/asyncFunctions'

export function AssetsCadastrationModal() {

    const buttonRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const [showEditClass, setShowEditClass] = useState(false);
    const [inEdit, setInEdit] = useState([]);
    const { show, setShow, setType, edited, setEdited } = useModal();
    const { tokenKey } = useAuth();
    const [selectedAsset, setSelectedAsset] = useState('');
    const [selectedAssetClass, setSelectedAssetClass] = useState('');
    const [selectedAssetSector, setSelectedAssetSector] = useState('');
    const [selectedNewClass, setSelectedNewClass] = useState('');
    const [selectedAssetValue, setSelectedAssetValue] = useState('');
    const [selectedCompanyName, setSelectedCompanyName] = useState('');
    const classes = styleClientsInput()
    const [listAssetsClasses, setListAssetsClasses] = useState([]);
    const [assetsSectorsList, setAssetsSectorsList] = useState([])
    const [requiredMissingFields, setRequiredMissingFields] = useState([])

    async function getAssetClassesList() {
        try {
            const assetClasses = await api.get(`/api/v1/assetClass`, {
                headers: {
                    authorization: `Bearer ${tokenKey}`
                }
            })
            let getAssets = []
            assetClasses.data.data.map((thisClass) => {
                getAssets.push(thisClass)
            })
            setListAssetsClasses(getAssets)
            setLoading(false);
        } catch (error) {
            setType({ name: 'alertMsg', type: 'information', value: { message: error?.response?.data?.message === undefined ? 'Erro ao buscar classes de ativos' : error?.response?.data?.message } })
            setShow(true);
        }
    }

    async function getAssetSectorsList() {
        const assetsSectorsGet = await getAssetSectors(tokenKey)
        if (assetsSectorsGet?.isError) {
            setType({
                name: 'alertMsg',
                type: 'information',
                value: {
                    message: assetsSectorsGet?.error
                }
            })
            setShow(true);
            setLoading(false)
        } else {
            setAssetsSectorsList(assetsSectorsGet)
        }
    }

    useEffect(() => {

        setLoading(true)
        setRequiredMissingFields([])
        setSelectedAssetClass('')
        setSelectedAsset('')
        setSelectedAssetValue('')
        setSelectedNewClass('')
        setSelectedCompanyName('')
        setShowEditClass(false)
        setListAssetsClasses([])
        buttonRef.current.childNodes[1].childNodes[1].childNodes[0].childNodes[1].childNodes[0].focus();
        getAssetClassesList();
        getAssetSectorsList()
    }, [show, edited])

    useEffect(() => {

        if (selectedAssetClass?.id === 9999) {
            setTimeout(() => {
                setShowEditClass(true);
            }, 1)

        }
    }, [selectedAssetClass])

    async function handleSubmit(newClass) {

        const requireFields = [
            { field: 'selectedAsset', value: selectedAsset },
            { field: 'selectedCompanyName', value: selectedCompanyName },
            { field: 'selectedAssetClass', value: selectedAssetClass },
            { field: 'selectedAssetSector', value: selectedAssetSector },
            { field: 'selectedAssetValue', value: selectedAssetValue }
        ]

        let requiredFieldsEmpty = []
        requireFields.forEach((required) => {

            if (((typeof(required.value) !== 'object' && typeof(required?.value) !== 'number') && !required?.value?.trim()?.length  ) || required.value === undefined || required.value === '') {
                requiredFieldsEmpty.push(required.field)
            }
        })

        if (requiredFieldsEmpty.length > 0) {
            setRequiredMissingFields(requiredFieldsEmpty)
            setLoading(true)
            setTimeout(() => {
                setLoading(false)
            }, [1000])
            return
        }
        setLoading(true)
        try {
            const newAsset = await api.post('api/v1/assets', {
                asset: selectedAsset,
                company_name: selectedCompanyName,
                asset_class_id: selectedNewClass !== '' ? newClass : selectedAssetClass?.id,
                asset_sector_id: selectedAssetSector?.id,
                value: selectedAssetValue !== '' ? selectedAssetValue : undefined
            }, {
                headers: {
                    authorization: `Bearer ${tokenKey}`
                }
            })
            setType({ name: 'alertMsg', type: 'information', value: { message: 'Cadastro de ativo efetuado com sucesso!' }, succesEdited: true })
            setLoading(false);
            setSelectedNewClass('');
            setEdited(true)
        } catch (error) {
            setType({ name: 'alertMsg', type: 'information', value: { message: error?.response?.data?.message === undefined ? 'Erro ao cadastrar ativo' : error?.response?.data?.message } })
            setShow(true);
            setLoading(false);
        }
    }

    return (
        <div className="assetsCadastrationContainer" ref={buttonRef} id='assetsCadastrationDiv'>
            {
                loading ?
                    (
                        <ArrowLoader />
                    ) :
                    (
                        <>
                            <div className="assetsCadastrationHeader" >
                                <p>Cadastro de Ativos</p>
                                <button onClick={() => setShow(false)} className="modalCloseButton">X</button>
                            </div>
                            <form className="assetsCadastrationBody" id='assetsCadastrationBody'>
                                <span>
                                    <p>Utilize o controle abaixo para cadastrar novos ativos no sistema, ao realizar o cadastro do novo ativo,
                                        o mesmo irá ser listado nas telas de operações, o valor do ativo será usado com base nesse cadastro
                                        ou com a importação efetuada via excel.
                                    </p>
                                </span>
                                <div className="assetCadastrationDualDiv" id='dualDivForClick'>
                                    <TextField
                                        title='Digite o código do ativo'
                                        variant='outlined'
                                        inputProps={{ maxLength: 30 }}
                                        onKeyPress={(e) => { if (e.code === 'Backquote') e.preventDefault() }}
                                        className={requiredMissingFields.indexOf('selectedAsset') > -1 ? classes.styleForClientsWithError : classes.styleForClients}
                                        label='Ativo (*)'
                                        value={selectedAsset}
                                        onChange={(e) => setSelectedAsset(e.target.value.toUpperCase())}
                                    />
                                    <TextField
                                        variant='outlined'
                                        inputProps={{ maxLength: 150 }}
                                        onKeyPress={(e) => { if (e.code === 'Backquote') e.preventDefault() }}
                                        title='Digite o nome da empresa que compõe o ativo'
                                        label='Nome da Empresa (*)'
                                        value={selectedCompanyName !== '' ? titleize(selectedCompanyName) : ''}
                                        className={requiredMissingFields.indexOf('selectedCompanyName') > -1 ? classes.styleForClientsWithError : classes.styleForClients}
                                        onChange={(e) => setSelectedCompanyName(e.target.value)}
                                    />
                                </div>
                                <div className='assetCadastrationTreeDiv'>
                                    <label>
                                        <Autocomplete
                                            title='Escolha a classe do ativo, se não encontrar, cadastre uma nova classe'
                                            options={listAssetsClasses}
                                            value={selectedAssetClass}
                                            onChange={
                                                (event, newValue) => {
                                                    setSelectedAssetClass(newValue);
                                                }
                                            }
                                            getOptionLabel={(x) => titleize(x.description)}
                                            renderInput={
                                                (params) =>
                                                    <TextField
                                                        label='Classe (*)'
                                                        className={requiredMissingFields.indexOf('selectedAssetClass') > -1 ? classes.styleForClientsWithError : classes.styleForClients}
                                                        {...params} variant="outlined" />}
                                        />
                                    </label>
                                    <label>
                                        <Autocomplete
                                            title='Escolha o setor que compõe esse ativo'
                                            options={assetsSectorsList}
                                            value={selectedAssetSector}
                                            onChange={
                                                (event, newValue) => {
                                                    setSelectedAssetSector(newValue);
                                                }
                                            }
                                            getOptionLabel={(x) => titleize(x.description)}
                                            renderInput={
                                                (params) =>
                                                    <TextField
                                                        label='Setor (*)'
                                                        className={requiredMissingFields.indexOf('selectedAssetSector') > -1 ? classes.styleForClientsWithError : classes.styleForClients}
                                                        {...params} variant="outlined" />}
                                        />
                                    </label>
                                    <CurrencyTextField
                                        title='Digite o valor corrente do ativo na data atual'
                                        label="Valor"
                                        variant="outlined"
                                        value={selectedAssetValue}
                                        currencySymbol="R$"
                                        className={requiredMissingFields.indexOf('selectedAssetValue') > -1 ? classes.styleForClientsWithError : classes.styleForClients}
                                        outputFormat="number"
                                        decimalCharacter=","
                                        digitGroupSeparator="."
                                        onChange={(event, value) => setSelectedAssetValue(value)}
                                    />
                                </div>
                                {
                                    requiredMissingFields.length > 0 &&
                                    (
                                        <a id='errorMsgFields'>Verifique os campos em vermelho</a>
                                    )
                                }
                                <button
                                    id='assetCadastrationButton'
                                    className='buttonWhiteBg'
                                    onClick={() => {
                                        handleSubmit()
                                    }}
                                >Cadastrar</button>
                            </form>
                        </>
                    )
            }
        </div >
    )
}