/**
 * Desenvolvido por equipe: Tiago Murilo
 * Descrição: Faz o controle de todos os sockets.
 **/

import React, { createContext, useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';
import { io } from 'socket.io-client'
import { useAuth } from './useAuth';

export const SocketContext = createContext({})

export const SocketProvider = ({ children }) => {

    const [pendings, setPedings] = useState([])
    const [socketState, setSocketState] = useState('')
    const [emitForEveryOne, setEmitForEveryOne] = useState(false)
    const [reloadBookOrders, setReloadBookOrders] = useState(false)
    const [reloadPushOrders, setReloadPushOrders] = useState(false)
    const [reloadOffersOrders, setReloadOffersOrders] = useState(false)
    const { tokenKey, signed, userType, user } = useAuth();
    const history = useHistory()

    useEffect(() => {
        console.log('socket!!')
        if (tokenKey !== '' && signed !== false) {
            const socket = io({
                host: '172.0.0.1',
                port: 8081,
                auth: {
                    token: tokenKey
                }
            })

            socket.on('connect', () => {
                console.log('> Conexão com socket.io estabelecida id:', socket.id)
            })
            
            socket.on('books_orders:refresh', (data) => {
                if (history?.location?.pathname === '/minhasEstruturadas') {
                    if (data?.find(id => parseInt(id?.users_external_id) === 0)) {
                        setEmitForEveryOne(true)
                        setReloadBookOrders(true)
                    }
                    else if ((data?.find(id => parseInt(id?.users_external_id) === user?.external_id)) || userType < 3) {
                        setEmitForEveryOne(false)
                        setReloadBookOrders(true)
                    }
                }
            })

            socket.on('orders_push:refresh', (data) => {
                if (history?.location?.pathname === '/minhasPush') {
                    if (data?.find(id => parseInt(id?.users_external_id) === 0)) {
                        setEmitForEveryOne(true)
                        setReloadPushOrders(true)
                    } else if ((data?.find(id => parseInt(id?.users_external_id) === user?.external_id)) || userType < 3) {
                        setEmitForEveryOne(false)
                        setReloadPushOrders(true)
                    }
                }
            })

            socket.on('offers_orders:refresh', (data) => {
                if (history?.location?.pathname === '/minhasReservas') {
                    if (data?.find(id => parseInt(id?.users_external_id) === 0)) {
                        setEmitForEveryOne(true)
                        setReloadOffersOrders(true)
                    } else if ((data?.find(id => parseInt(id?.users_external_id) === user?.external_id)) || userType <= 99) {
                        setEmitForEveryOne(false)
                        setReloadOffersOrders(true)
                    }
                }
            })

            socket.on('getNotifications', (data) => {
                // console.log('GET NOTIFICAÇÕES', data)
                setPedings(data)
            })

            socket.on('reloadNotifications', (data) => {
                // console.log('RELOAD NOTIFICAÇÕES', data)
                setPedings(data)
            })

            socket.on('moreNotifications', (data) => {
                setPedings(data)
            })

            socket.on('disconnect', () => {
                console.log('> Conexão com socket.io perdida.')
                socket.close()
                socket.connect()
            })

            if (userType == 1 || userType == 2) {
                socket.on('newClientIndication', () => {
                    socket.emit('getNotifications', (data) => {
                        setPedings(data)
                    })
                })
            }
            if (userType == 3 || userType == 4) {
                socket.on('aproveReproveClientIndication', () => {
                    socket.emit('getNotifications', (data) => {
                        setPedings(data)
                    })
                })
            }

            setSocketState(socket)
        }

    }, [tokenKey, signed])

    function socketEmit(command, path='/') {
        if (socketState != '') {
            switch (command) {
                case 'readNotifications':
                    socketState.emit('readNotifications', path)
                    break;
                case 'moreNotifications':
                    socketState.emit('moreNotifications')
                    break;
                case 'disconnect':
                    socketState.disconnect()
                    break;
            }
        }
    }

    return (
        <SocketContext.Provider value={{
            pendings,
            socketState,
            socketEmit,
            emitForEveryOne,
            setEmitForEveryOne,
            reloadBookOrders,
            setReloadBookOrders,
            reloadPushOrders,
            setReloadPushOrders,
            reloadOffersOrders,
            setReloadOffersOrders
        }}>
            {children}
        </SocketContext.Provider>
    )

}

export function useSocket() {
    return useContext(SocketContext)
}