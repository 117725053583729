import React,{useEffect, useState} from "react";
import { api } from "../../../services/api";

import { useModal } from "../../../contexts/useModal"
import { useAuth } from "../../../contexts/useAuth";

export default function ModalCommentBaterBase(props) {

    const [comment, setComment] = useState(props.prop.client[`c${props.prop.selectedCampanha?.id}__comment`] ? props.prop.client[`c${props.prop.selectedCampanha?.id}__comment`] : '');
    const [loading, setLoading] = useState(false);

    const { show, setShow ,setType, edited } = useModal()
    const { user, tokenKey } = useAuth();
    
    useEffect(() => {
        setComment(props.prop.client[`c${props.prop.selectedCampanha?.id}__comment`]);
    }, [props.prop.client[`c${props.prop.selectedCampanha?.id}__comment`], show])

    const handleComment = async () => {
        setLoading(true);
        
        try {
            const data = await api.put(`api/v1/alocacao/baterBase/${props.prop.selectedCampanha.id}/comentario/`, {
                comentario: comment,
                id: props.prop.row[0],
                operation_id: props.prop.row[10]
            }, {
                headers: {
                    authorization: `Bearer ${tokenKey}`
                }
            })
            if (data.status === 200) {
                setType({name: "alertMsg", msg: "Comentário atualizado com sucesso!"});
                setShow(false);
                edited();
            }
        }
        catch (error) {
            console.log(error)
            setType({name: "alertMsg", msg: "Erro ao atualizar comentário!"});
            setShow(true);
        }
        setShow(false);
        setLoading(false);
    }

    const saveComment = async () => {
        props.prop.client[`c${props.prop.selectedCampanha?.id}__comment`] = comment;
        // props.prop.client[`c${props.prop.selectedCampanha?.id}__comment`] = {...props.prop.selectedCampanha, comentario: comment};
        // console.log('props.prop.selectedCampanha', props.prop.selectedCampanha)
        setShow(false);
    }
    
    return (
        <div className={'paperBaterBase'}>
            <h2 >Editar Comentário</h2>
            <textarea
                className="campoTextoAtivoAdm"
                value={comment ? comment : ' '}
                style={{ maxHeight: "9rem", resize: "none", width: "90%", padding: "0.5rem", minHeight: "5rem"}}
                onKeyUp={(e) => {
                    e.target.style.height = "inherit";
                    e.target.style.height = `${e.target.scrollHeight}px`;
                }}
                onChange={(e) => {
                    setComment(e.target.value);
                }}
            />
            <div style={{display: 'flex', flexDirection:'row', justifyContent:'space-between', alignItems:'center'}}>
                <span onClick={() => setShow(false) } style={{background:'#EBEFF3',color:"black", padding:'10px',cursor:"pointer",borderRadius:'0.5rem'}}>Cancelar</span>
                <span 
                    onClick={(e) => {
                            setComment(e.target.value);
                            saveComment();
                        }}
                    style={{background:'#C39D14',color:"white", padding:'10px',cursor:"pointer",borderRadius:'0.5rem'}}>Salvar Comentário</span>
            </div>
        </div>
    );
    }