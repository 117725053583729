import React, { useEffect, useState } from 'react'
import '../../monitoringPanel/myReservations/styles/myReservations.details.css'

import { useModal } from '../../../../contexts/useModal'

export function LongShortResponseModal(props) {
    console.log('props', props)

    const { type, show, setShow } = useModal()
    const [response, setResponse] = useState(false)

    useEffect(() => {
        type.data.order === undefined || type.data.order === 'undefined' ||
            type.data.order === '' || type.data.order === '0' ? setResponse(false) : setResponse(true)
    }, [show])

    return (
        <div className='myReservationDetails'>
            {
                response ?
                    (
                        <>
                            <div className='myReservationsHeader'>
                                <p>Resposta</p>
                                <button onClick={() => setShow(false)} className='modalCloseButton'>x</button>
                            </div>
                            <div className='myReservationsBody'>

                                <p>{type.data.order}</p>
                            </div>
                        </>
                    ) :
                    (
                        <>
                            <div className='myReservationsHeader'>
                                <p>Resposta</p>
                                <button onClick={() => setShow(false)} className='modalCloseButton'>x</button>
                            </div>
                            <div className='myReservationEmptyBody'>
                                <h4>Nenhuma resposta cadastrada</h4>
                            </div>
                        </>

                    )
            }
        </div>
    )

}