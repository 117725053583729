import React, { useEffect, useState, useReducer } from "react";
/*Contexts*/
import { useAuth } from "../../../../contexts/useAuth";
import { useModal } from "../../../../contexts/useModal";
/*Services*/
import { api } from "../../../../services/api";
/*Components*/
// import { ArrowLoader } from "../../../../components/loaders/arrowLoader";
/*Utils*/
import { toBRL } from "../../../../utils/formatNumberAndText/toBRLCurrency";
import { titleize } from "../../../../utils/formatNumberAndText/formatTextUpperFirstLetter";
import { MdOutlineMonetizationOn, MdOutlineCheckCircleOutline, MdOutbox,MdSearch } from "react-icons/md";
import { getABCAvailableOperations } from "../../../../services/asyncFunctions"
import { getAssetsList } from '../../../../services/asyncFunctions';
import { FiStar,FiArrowLeft } from 'react-icons/fi';
import { FaStar } from 'react-icons/fa';
import { RiArrowRightDownFill, RiArrowRightUpFill } from 'react-icons/ri';
import { ArrowLoader } from "../../../../components/loaders/arrowLoader";
import { styled } from '@mui/material/styles';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import { styleClientsInput } from '../../../../components/styles/autocompletField';
import { TextField, Checkbox, FormControlLabel } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { getOffers } from '../../../../services/asyncFunctions'
import { datetimeToDatePtBR } from "../../../../utils/formatDate/dateTimeToPTBR";




import './abcModal.css'

export function AbcClientBoletarEstruturada(props) {
  console.log('propsEstruturadaBoletaEstruturada', props.prop.props)
  const { tokenKey, user } = useAuth();
  const { show, setShow, type, setType, edited, setEdited } = useModal();
  const [loading, setLoading] = useState(true);
  const [operations, setOperations] = useState([]);
  const [product, setProduct] = useState([]);
  const [client, setClient] = useState();
  const [assetsList, setAssetsList] = useState([])
  const [assetsVariationList, setAssetsVariationList] = useState([])
  const [booksList, setBooksList] = useState([])
  const [activeBook, setActiveBook] = useState([])
  const [bookFields, setBookFields] = useState([])
  const [thisBookIsWithAward, setThisBookIsWithAward] = useState(false)
  const [operation, setOperation] = useState({})
  const [typeOperation, setTypeOperation] = useState(2)
  const [selectedQtd, setSelectedQtd] = useState(0)
  const [selectedValue, setSelectedValue] = useState('')
  const classes = styleClientsInput()
  const [qtdIsFilled, setQtdIsFilled] = useState(false)
  const [valueIsFilled, setValueIsFilled] = useState(false)
  const [sendStatus, setSendStatus] = useState({ status: false, type: '' })
  const [showCalculatedValue, setShowCalculatedValue] = useState(false)
  const [showAlert, setShowAlert] = useState('')
  const [searchText, setSearchText] = useState('')
  const [selectedOrder, setSelectedOrder] = useState()
  const [, forceUpdate] = useReducer(x => x + 1, 0);
  const [alertMessage, setAlertMessage] = useState('')

  /////////////////////////////////////////////////////////
  ////////Funções para Boletar Estruturadas////////////////
  /////////////////////////////////////////////////////////
  
  async function availableOperations() {
        try {
            const res = await getABCAvailableOperations(tokenKey, props?.prop?.props?.product?.type?.id);
            setOperations(res?.data?.data);
        } catch (error) {
            setType({ name: 'alertMsg', type: 'information', value: { message: error?.response?.data?.message == undefined ? 'Erro interno ao carregar os dados' : error?.response?.data?.message } })
            setShow(true);
        }
  }

    async function getAssetsVariation() {
      setLoading(true)
      const assetsVariatin = await api.get(`/api/v1/assetsVariation`, {
          headers: {
              authorization: `Bearer ${tokenKey}`
          }
      })
      setAssetsVariationList(assetsVariatin.data.data);
  }

  async function getBooks() {
      const booksResult = await api.get(`/api/v1/books?status=1`, {
          headers: {
              authorization: `Bearer ${tokenKey}`
          }
      })
      setBooksList(booksResult?.data?.data);
      if (booksResult?.data?.data !== undefined && booksResult?.data?.data !== null
          && booksResult?.data?.data !== '') {
          getActiveBook(booksResult?.data?.data[0]?.id)
          setThisBookIsWithAward(booksResult?.data?.data[0]?.award)
      } else {
      }
  }

  function calculateValues(operation, typeCalculate) {
    let lot = assetsList.filter((x) => x.asset == operation.Ativo?.value)[0].lot === 'L';
    //typeCalculate 1: Calcula valor
    //typeCalculate 2: Calcula quantidade
    //typeCalculate 3: Calcula valor

    let assetValue = assetsVariationList[operation.Ativo?.value]?.current_value
    if (typeCalculate === 1) {

        if (selectedValue !== '' && selectedValue > 0) {
            setShowCalculatedValue(true)
            let checkAssetQtd = thisBookIsWithAward ? (selectedValue / (operation.Prêmio?.value / 100))  / assetValue : selectedValue / assetValue;
            checkAssetQtd = Math.ceil(checkAssetQtd);
            let thisAcceptedQtd = 0;
            if (lot) {
                if (checkAssetQtd < 100) {
                    setShowAlert('Lote não pode ser fracionário ou menor que 100');
                    setSendStatus({
                        status: true,
                        type: 'error'
                    });
                    setSelectedQtd(0);
                    setSelectedValue(0);
                    setValueIsFilled(false);
                } else {
                    let restAssetQtd = checkAssetQtd % 100;
                    thisAcceptedQtd = checkAssetQtd - restAssetQtd;

                }
            } else {
                thisAcceptedQtd = checkAssetQtd;
            }
            setSelectedQtd(thisAcceptedQtd);
            setSelectedValue(thisBookIsWithAward ? thisAcceptedQtd * assetValue * (operation.Prêmio?.value / 100) : thisAcceptedQtd * assetValue);
        } else {
            setSelectedQtd(0)
            setSelectedValue(0);
        }
    }

    if (typeCalculate === 3) {
        if (lot && selectedQtd < 100) {
            setSelectedQtd(0)
            setSelectedValue(0)
            setSendStatus({ status: true, type: 'error' })
            setShowAlert('Lote não pode ser fracionário ou menor que 100')
        } else if (lot && selectedQtd >= 100) {
            let thisAcceptedQtd = 0;
            let restAssetQtd = selectedQtd % 100;
            thisAcceptedQtd = selectedQtd - restAssetQtd;
            setSelectedQtd(thisAcceptedQtd);
            setSelectedValue(thisBookIsWithAward ? thisAcceptedQtd * assetValue * (operation.Prêmio?.value / 100) : thisAcceptedQtd * assetValue);
        } else {
            let thisAcceptedQtd = selectedQtd;
            setSelectedQtd(thisAcceptedQtd);
            setSelectedValue(thisBookIsWithAward ? thisAcceptedQtd * assetValue * (operation.Prêmio?.value / 100) : thisAcceptedQtd * assetValue);
        }
    }
  }
  
  async function lookAssets() {

      const getAssets = await getAssetsList(tokenKey);
      if (getAssets?.isError) {
          setType({
              name: 'alertMsg',
              type: 'information',
              value: {
                  message: getAssets?.error
              }
          })
          setShow(true);
      } else {
          setAssetsList(getAssets);
      }
  }

  useEffect(() => {
    forceUpdate();
  }, [selectedOrder])

  useEffect(() => {
    if (selectedOrder){
      var ordereData = activeBook
      // ordereData = ordereData.sort((a, b) => a[selectedOrder].value - b[selectedOrder].value)
      ordereData = ordereData.data.sort((a, b) => {

        let fa = a[selectedOrder].value == null ? "-" : a[selectedOrder].value;
        let fb = b[selectedOrder].value == null ? "-" : b[selectedOrder].value;

        if (typeof fa === "string" || typeof fb === "string") {
            fa = String(fa).toLowerCase().trim();
            fb = String(fb).toLowerCase().trim();
            return  (fa < fb ? -1 : fa > fb ? 1 : 0)
        }

        return  (fa < fb ? 1 : fa > fb ? -1 : 0) ;
      }
      )
      ordereData.data = ordereData
      setActiveBook(ordereData)
    }
  }, [selectedOrder])

  async function getActiveBook(bookData) {
      if (bookData !== undefined) {
          
          try {
              const bookToday = await api.get(`api/v1/booksImports/${bookData}/today`, {
                  headers: {
                      authorization: `Bearer ${tokenKey}`
                  }
              })
              // console.log('bookToday?.data?.data',bookToday?.data?.data)
              setActiveBook(bookToday?.data?.data)
              setBookFields(bookToday?.data?.data?.allFields)
              if (bookToday?.data?.data?.data[0]){
                setOperation(bookToday?.data?.data?.data[0])
              }
              else{
                setAlertMessage('Produto não encontrado')
              }
              
              
          } catch (error) {
              setActiveBook([])
              setType({ name: 'alertMsg', type: 'information', value: { message: error?.response?.data?.message == undefined ? 'Erro interno ao tentar buscar Book' : error?.response?.data?.message } })
              setShow(true);
          }
      }
  }

  async function handleBuySubmit() {

    const selectedCommission = (selectedQtd * assetsVariationList[operation.Ativo?.value]?.current_value) * (operation['FEE']?.value / 100)
    // console.log({"client_id": parseInt(client?.codigo),
    // "asset": operation?.Ativo?.value,
    // "qty": parseInt(selectedQtd),
    // "value": selectedValue / selectedQtd,
    // "comission": selectedCommission,
    // "operation_type_id": typeOperation == 2 ? 1 : 0,
    // "expiration": operation.Vencimento?.value.split('-').reverse().join('/'),
    // "books_id": activeBook?.book_id,
    // "books_import_id": activeBook?.import_id,
    // "user_id" : user?.external_id,
    // "reference": operation?.Ativo?.reference})
    // return


    // 4
    if ((!thisBookIsWithAward && typeOperation === 0) || (!thisBookIsWithAward && typeOperation === 4)) {
        alert('selecione  uma das opções: Compra ou Sob custódia')
        return
    }  

    if (selectedValue === '' || selectedValue === 0) {
        alert('Digite valor ou quantidade')
        return
    }

    if (selectedQtd === '') {
        alert('Digite a quantidade')
        return
    }
    if (selectedValue === '' || selectedValue === 0) {
        alert('Digite o valor')
        return
    }
    // if(justRegister === ''){
    //     return alert('Informe como deve ser operado pelo backoffice')
    // }
    try {
        setLoading(true)
        await api.post(`api/v1/booksOrdersTemp/import`, {
          data : [
            {"clients_external_id": parseInt(client?.client_id),
            "asset": operation?.Ativo?.value,
            "qty": parseInt(selectedQtd),
            "value": selectedValue / selectedQtd,
            "comission": selectedCommission,
            "operation_type_id": typeOperation == 2 ? 1 : 0,
            "expiration": operation.Vencimento?.value.split('-').reverse().join('/'),
            "books_id": activeBook?.book_id,
            "books_import_id": activeBook?.import_id,
            "user_id" : user?.external_id,
            "reference": operation?.Ativo?.reference}
          ]
        }, {
            headers: {
                authorization: `Bearer ${tokenKey}`
            }
        })
        setEdited(!edited)
        setLoading(false)
        setSendStatus({ status: true, type: 'success' })
        alert('Ordem enviada!')
        setSelectedValue(0)
        setSelectedQtd(0)

    } catch (error) {
        console.log('error', error.response)
        setType({ name: 'alertMsg', type: 'information', value: { message: error?.response?.data?.message == undefined ? 'Erro interno ao tentar realizar requisição' : error?.response?.data?.message } })
        setShow(true);
    }
  }

  async function loadInfosEstruturadas(){
    setLoading(true)
    await getAssetsVariation()
    await lookAssets()
    await getBooks()
    await availableOperations()
    await setProduct(props?.prop?.props?.product)
    await setClient(props?.prop?.props?.client)
    setLoading(false)
  }

  /////////////////////////////////////

  useEffect(() => {
    if(props.prop.props.product.type.id == 1){
      loadInfosEstruturadas()
    }
  }, [])

  const handleValueChange = (event) => {
    const { value } = event.target;
    setSelectedQtd(parseInt(value.replaceAll('.', '')));
  };

  const formatNumberWithThousandsSeparator = (number) => {
    const cleanedValue = String(number).replaceAll('.', ''); // Remove os pontos
    const parts = cleanedValue.toString().split('');
    const formattedParts = [];
    let currentIndex = parts.length - 1;
    while (currentIndex >= 0) {
      const currentPart = parts[currentIndex];
      formattedParts.unshift(currentPart);
      if ((parts.length - currentIndex) % 3 === 0 && currentIndex !== 0) {
        formattedParts.unshift('.');
      }
      currentIndex--;
    }
    return formattedParts.join('');
  };

  return (
    <div className="containerModalAbcDetails">
            <div className="clientControlHeader">
                <div className="editBrancheBoxHeader">
                    <button style={{marginLeft: '1rem'}} title="Voltar" onClick={() => {
                      const pageToreturn = props.prop.props.returnTo
                      setType({ name: pageToreturn, product: props.prop.props.product, client: props.prop.props.client, clients: props.prop.props.clients });
                      }} className="modalCloseButton">
                        <FiArrowLeft/>
                    </button>
                    <div className="containerProductName">
                      {(! loading && alertMessage =='') ?
                        <>
                          <p>{product?.type?.description}: {product?.description} </p>
                          <p style={{marginLeft: '2rem'}}>Cliente: {client?.nome} - {client?.client_id}</p>
                        </>
                      : <p>{alertMessage}</p>}
                    </div>
                    <button
                      onClick={() => setShow(false)}
                      className="modalCloseButton">
                        X
                    </button>
                    

                    
                </div>
            </div>
            {loading ? 
            <div className='clientsCadastrationLoading'>
                <ArrowLoader />
            </div> :
            alertMessage != '' ? 
            <div className='clientsCadastrationLoading'>
              <h1>{alertMessage} </h1>
            </div>
            :
              <div className="containerBoletarAbc">
                  <div className="containerListproductsAbc">
                    <div className="toolbarBoletarAbc">
                      <div className="toolbarSearchBoletarAbc">
                      <div className={'InputToFormat_two'}>
                        <div>
                            <input onKeyPress={(e) => {if(e.code === 'Backquote') e.preventDefault()}} type="text" value={searchText} onChange={(e) => setSearchText(e.target.value)} placeholder=' '/>
                            <label htmlFor="">Pesquisar</label>
                        </div>
                      </div>
                      </div>
                      
                    <div className="toolbarSelectOrderBoletarAbc">
                    
                      <Autocomplete
                          options={bookFields}
                          value={selectedOrder}
                          onChange={(event, newValue) => {
                              setSelectedOrder(newValue);
                          }}
                          getOptionLabel={(x) => x.replaceAll('_', ' ')}
                          getOptionSelected={(option, val) => option === val.value}
                          id='order'
                          renderInput={(params) => <TextField
                              label='Ordenar por'
                              onKeyPress={(e) => {if(e.code === 'Backquote') e.preventDefault()}}
                              className={classes.autocompleteAbc}
                              {...params} variant="outlined" />}
                      />
                  
                    </div>
                    </div> 
                    <div className="listOperationProductsAbc">
                      {activeBook?.data?.map((b) => {
                          const keys = Object.keys(b)
                          keys.splice(keys.indexOf('Ativo'), 1);
                          keys.splice(keys.indexOf('Vencimento'), 1);
                          keys.splice(keys.indexOf('Recomendado'), 1);

                          var values = b['Ativo']?.value
                          //insere o valor do ativo
                          values += assetsVariationList[b.Ativo?.value]?.current_value
                          //insere os demais valores para filtrar posteriormente
                          keys.map((j) => {
                            values +=b[j].value
                          })

                          // se algum valor do card está contido no campo de pesquisa, então mostra o card
                          if(values.toLowerCase().includes(searchText.toLowerCase())){
                          
                            return (
                          <div className={operation == b ? 'productCardActiveAbc' : 'productCardAbc'} onClick={() => setOperation(b)}>
                              <div className="productCardAbcHeader">
                                <p></p>
                                <p style={{color: '#5E4C0B'}} title="Ativo">{b['Ativo'].value}</p>
                                <p style={{color: '#5E4C0B'}}title='Valor'>
                                  {assetsVariationList[b.Ativo?.value]?.variation > 1 ?
                                    (
                                        <label>
                                            <span>{toBRL(assetsVariationList[b.Ativo?.value]?.current_value) + ' '}</span>
                                            <i>{assetsVariationList[b.Ativo?.value]?.variation_percentage}%</i>
                                            <RiArrowRightDownFill color='rgb(160, 4, 4)' />
                                        </label>

                                    ) :
                                    (
                                        <label>
                                                <span>{toBRL(assetsVariationList[b.Ativo?.value]?.current_value) + ' '}</span>
                                                <i>{assetsVariationList[b.Ativo?.value]?.variation_percentage}%</i>
                                            <RiArrowRightUpFill color='rgb(66, 146, 66)' />
                                        </label>
                                    )
                                }</p>
                                <p style={{color: '#5E4C0B'}}title='Vencimento'>{b['Vencimento'].value.split('-').reverse().join('/')}</p>
                                {b['Recomendado'].value == true ? <FaStar className='booksOperationStar' title='Recomendado' /> : <p></p>}
                              </div>
                              <div className="divisionHeaderCardAbc"></div>
                              <div className="productCardAbcInfos">
                                {keys.map((k) => {
                                  return(
                                    <div>
                                    <span style={{fontWeight: '400', fontSize: '1.1rem'}}>{k.replaceAll('_', ' ')}: </span><span>{b[k].value}</span>
                                    </div>
                                  )
                                })
                                }
                              </div>
                          </div>
                            ) 
                          }
                        })}
                    </div>
                  </div>
                  <div className="divisionNameProductSubcategory"></div>
                    <div className="containerOperateProductAbc">
                      <div className="headerOperateProductAbc">
                        <p>{operation ? operation['Ativo']?.value : ''}</p>

                      </div>

                        <div className="bodyOperateProductAbc">

                        <div className="itemBodyOperateProductAbc">
                          <div className="switchSelector">
                            <button className={typeOperation == 2 ? "buttonSwitchSelected" : 'buttonSwitchUnselected'} 
                            onClick={() => {
                              typeOperation !== 2 ? setTypeOperation(2) : setTypeOperation(4)
                              setQtdIsFilled(false)
                              setSelectedValue(0)
                              setSelectedQtd(0)
                            }
                            }
                            >Compra</button>
                            <button className={typeOperation == 3 ? "buttonSwitchSelected" : 'buttonSwitchUnselected'} 
                            onClick={() =>{
                              typeOperation !== 3 ? setTypeOperation(3) : setTypeOperation(4)
                              setValueIsFilled(false)
                              setSelectedValue(0)
                              setSelectedQtd(0)
                            }
                              
                            }
                            >Sob Custódia</button>
                          </div>
                        </div>

                        <div className="contentBodyOperateProductAbc">
                          <div className="itemBodyOperateProductAbc fieldsOperateAbc">
                              <CurrencyTextField
                              label="Valor"
                                variant="outlined"
                                value={selectedValue}
                                disabled={typeOperation===3 ? true : false}
                                currencySymbol="R$"
                                className={classes.styleForClients}
                                outputFormat="number"
                                decimalCharacter=","
                                digitGroupSeparator="."
                                onChange={(event, value) => {
                                    if ((!thisBookIsWithAward && typeOperation === 0) || (!thisBookIsWithAward && typeOperation === 4)) {
                                        alert('selecione  uma das opções: Compra ou Sob custódia')
                                        setSelectedValue('')
                                        setSelectedQtd(0)
                                        setValueIsFilled(false)
                                        return
                                    }
                                    
                                    if (value === '' || value === 0) {
                                        setValueIsFilled(false)
                                        setSelectedValue(10)
                                    } else {
                                        setSendStatus({ status: false, type: '' })
                                        setShowCalculatedValue(false);
                                        setValueIsFilled(true)
                                        setSelectedValue(value);
                                    }
                                }}
                                onBlur={() => {
                                    calculateValues(operation, 1)
                                }}
                            />
                          </div>

                          <div className="itemBodyOperateProductAbc fieldsOperateAbc">
                            <TextField
                              variant="outlined"
                              label='Quantidade'
                              value={formatNumberWithThousandsSeparator(selectedQtd)}
                              disabled={valueIsFilled != 0 || typeOperation===2 ? true : false}
                              className={classes.styleForClients}
                              // type="number"
                              inputProps={{
                                inputMode: 'numeric',
                                pattern: '[0-9]*',
                              }}
                              onChange={(e) => {
                                handleValueChange(e);
                                  if ((!thisBookIsWithAward && typeOperation === 0) || (!thisBookIsWithAward && typeOperation === 4)) {
                                      alert('selecione  uma das opções: Compra ou Sob custódia')
                                      setQtdIsFilled(false);
                                      return
                                  }
                                  setSendStatus({ status: false, type: '' })
                                  setShowCalculatedValue(false);
                                  // setSelectedQtd(e.target.value);

                                  if (e.target.value === '' || e.target.value == 0) {
                                      setQtdIsFilled(false);
                                  }
                                  else {
                                      setQtdIsFilled(true);
                                  }
                              }}
                              onBlur={() => calculateValues(operation, 3)}
                            />
                          </div>

                          <div className="itemBodyOperateProductAbc fieldsOperateAbc">
                            <a style={{marginRight: '1rem'}}>Comissão Estimada:</a>
                            <a>
                                {
                                  assetsVariationList[operation.Ativo?.value]?.current_value !== undefined &&
                                  assetsVariationList[operation.Ativo?.value]?.current_value !== 0 ?
                                  toBRL((selectedQtd * assetsVariationList[operation.Ativo?.value]?.current_value) * (operation['FEE']?.value / 100)) : '0'
                                }
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="boxSubmitOperateProductAbc">
                        <button className="buttonOperateSubmitABC" onClick={() => handleBuySubmit()}>Enviar</button>
                        
                      </div>
                    </div>
              </div>
            }
        </div>
  );
}