/**
 * Desenvolvido por equipe: Tiago Murilo
 * Descrição: Lista clientes de brokers e assessores
 **/

import React from 'react'
//components
import { MyClientsTable } from './myClientsTable';
import Modal from '../../../../components/modal/modal';
import { ConfirmationBox } from '../../../../components/confirmationBox/confirmationBox';
//Contexts
import { useModal } from '../../../../contexts/useModal';
//styles
import './styles/myTableClients.css'
import { MyClientsDetail } from './myClientsDetail';

export function MyClients() {

    const { type, setType } = useModal();

    return (
        <div className='myClientsContainer'>
            <Modal>
                {   
                    type.name === 'showClientDetails' ?
                    (
                        <div className='myClientsDetail'>
                            <MyClientsDetail />
                        </div>

                    ) : 
                    type.name === 'alertMsg' && (
                        <ConfirmationBox msgData={type} />
                    )
                }
            </Modal>
            <MyClientsTable />
        </div>
    )
}