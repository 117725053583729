/*Componente de cadastro de usuários
/*Descrição: Efetua o cadastro dos usuários no sistema
/*Desenvolvido por: Equipe Rafael Cezário
*/

import React, { useState, useEffect } from 'react';
import { TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
//services
import { api } from '../../../../services/api';
import { getTypesUsers } from '../../../../services/asyncFunctions';
import { getBranches } from '../../../../services/asyncFunctions';
import { getBrokerProfiles } from '../../../../services/asyncFunctions';
//hooks
import { useModal } from '../../../../contexts/useModal'
//components
import { ArrowLoader } from '../../../../components/loaders/arrowLoader';
//Context
import { useAuth } from '../../../../contexts/useAuth';
//utils
import { validateSchemasAPI } from '../../../../utils/validatorSchemas/validateFieldsToAPI';
import { titleize } from '../../../../utils/formatNumberAndText/formatTextUpperFirstLetter';
//styles
import './styles/users.cadastration.css';
import { styleClientsInput } from '../../../../components/styles/autocompletField';

export function UsersCadastration() {

    const classes = styleClientsInput();
    const [userName, setUserName] = useState('')
    const [selectedUserEmail, setSelectedUserEmail] = useState('');
    const [selectedUserBranch, setSelectedUserBranch] = useState('');
    const [selectedUserCode, setSelectedUserCode] = useState(0)
    const [loading, setLoading] = useState(true);
    const [listTypes, setListTypes] = useState([]);
    const [profilesList, setProfilesList] = useState([])
    const [branchesList, setBranchesList] = useState([]);
    const [selectedUserCPF, setSelectedUserCPF] = useState('')
    const [selectedUserType, setSelectedUserType] = useState('');
    const [selectedProfileBroker, setSelectedProfileBroker] = useState('');
    const [fieldWithError, setFieldWithError] = useState(['']);
    const [userTypeForSend, setUserTypeForSend] = useState('');
    const token = localStorage.getItem('@auth:token');
    const { tokenKey } = useAuth();
    const currentUser = JSON.parse(localStorage.getItem('@auth:user'));
    const { show, setShow, setEdited, edited, setType } = useModal();


    async function exec(){

        const userTypesGet = await getTypesUsers(tokenKey)
        if (userTypesGet?.isError) {
            setType({
                name: 'alertMsg',
                type: 'information',
                value: {
                    message: userTypesGet?.error
                }
            })
            setShow(true);
        } else {
            setListTypes(userTypesGet);
        }

        const branchesGet = await getBranches(tokenKey)
        if (branchesGet?.isError) {
            setType({
                name: 'alertMsg',
                type: 'information',
                value: {
                    message: branchesGet?.error
                }
            })
            setShow(true);
        } else {
            setBranchesList(branchesGet);
        }

        const brokerProfilesGet = await getBrokerProfiles(tokenKey)
        if (brokerProfilesGet?.isError) {
            setType({
                name: 'alertMsg',
                type: 'information',
                value: {
                    message: brokerProfilesGet?.error
                }
            })
            setShow(true);
        } else {
            setProfilesList(brokerProfilesGet);
        }
        setLoading(false);
        clear();
    }

    useEffect(() => {

        if(show){
            exec()
        }

    }, [show])

    function validateEmail(email) {
        let emailForCheck = email.toString().toLowerCase();
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(emailForCheck);
    }

    function clear() {
        setSelectedProfileBroker('')
        setSelectedUserBranch('')
        setSelectedUserCPF('')
        setSelectedUserCode('')
        setSelectedUserEmail('')
        setSelectedUserType('')
        setUserName('')
        setFieldWithError([''])
    }

    async function handleSubmit(e) {

        e.preventDefault();

        if(!userName.trim().length){
            alert('Campo nome é obrigatório')
            return
        }

        if (selectedUserEmail !== '') {
            if (!validateEmail(selectedUserEmail)) {

                setFieldWithError([...fieldWithError, 'emailError'])
                alert('E-mail inválido')
                return
            }
        }

        const validate = validateSchemasAPI([
            { name: "\nNome", value: userName, required: true },
            { name: "\nE-mail", value: selectedUserEmail, required: true },
            { name: "\nCódigo de acesso", value: selectedUserCode, required: true },
            { name: "\nTipo de acesso", value: selectedUserType, required: true },
            { name: "\nPerfil do Broker", value: selectedProfileBroker?.profile_id, required: selectedUserType?.type_access === 3 ? true : false },
        ]);

        if (validate.length > 0) {
            setFieldWithError(validate);
            alert('Os campos abaixo são obrigatórios: \n' + validate.toString())
            return
        }

        setLoading(true);
        try {
            await api.post('/api/v1/users', {
                "external_id": parseInt(selectedUserCode),
                "full_name": userName,
                "cpf": '00000000000',
                "branches_id": selectedUserBranch.id,
                "email": selectedUserEmail,
                "type": selectedUserType.type_access,
                "profile_id": selectedUserType?.type_access === 3 ? selectedProfileBroker.profile_id : undefined
            }, {
                headers: {
                    authorization: `Bearer ${token}`
                }
            })
            setType({ name: 'successUserRegister', type: 'information', value: { message: `Sucesso ao cadastrar o usuario ${titleize(userName)}` } })
            setFieldWithError(['']);
            setEdited(!edited);

        } catch (err) {
            setType({ name: 'alertMsg', type: 'information', value: { message: err?.response?.data?.message } });
            if (err?.response?.data?.message === `Usuário com external_id '${selectedUserCode}' já cadastrado.`) {
                setFieldWithError(['userCodeError']);
            }
            setLoading(false)
        }
    }

    function userTypesToCurrentUser() {

        return listTypes.filter((typePermitted) => {
            if (typePermitted.type_access >= currentUser.type) {
                return typePermitted
            }
        })
    }

    return (
        <div className='usersCadastrationContainer'>
            <div className='userCadastrationHeader'>
                <p>Efetue o cadastro dos usuários abaixo</p>
                <button className='modalCloseButton' onClick={() => setShow(false)}>X</button>
            </div>
            <div className='usersCadastrationBody'>
                {
                    loading ?
                        (<ArrowLoader />)
                        :
                        (
                            <>
                                <form onSubmit={handleSubmit}>
                                    <div id='userCadastrationFormDiv'>
                                        <label id={fieldWithError?.filter((field) => { if (field == '\nNome') return field })?.length > 0 ? 'errorField' : ''}>
                                            <TextField
                                                inputProps={{ maxLength: '50' }}
                                                label='Nome'
                                                onKeyPress={(e) => { if (e.code === 'Backquote') e.preventDefault() }}
                                                value={userName}
                                                className={classes.root}
                                                onChange={(e) => { setUserName(e.target.value) }}
                                                variant="outlined" />
                                        </label>
                                        <label id={fieldWithError?.filter((field) => { if (field == '\nE-mail') return field })?.length > 0 ? 'errorField' : ''}>
                                            <TextField
                                                label='E-mail'
                                                onKeyPress={(e) => { if (e.code === 'Backquote') e.preventDefault() }}
                                                inputProps={{ maxLength: '50' }}
                                                value={selectedUserEmail}
                                                className={classes.root}
                                                onChange={(e) => { setSelectedUserEmail(e.target.value) }}
                                                variant="outlined" />
                                        </label>
                                        <label id={fieldWithError?.filter((field) => { if (field == '\nCódigo de acesso' || field == 'userCodeError') return field })?.length > 0 ? 'errorField' : ''}>
                                            <TextField
                                                type='number'
                                                onKeyDown={(evt) => { if (evt.key === 'e' || evt.key === 'E' || evt.code === 'Backquote') evt.preventDefault() }}
                                                label='Codigo de acesso'
                                                value={selectedUserCode}
                                                className={classes.root}
                                                onChange={(e) => { if (e.target.value.length <= 18) { setSelectedUserCode(e.target.value) } }}
                                                variant="outlined" />
                                        </label>
                                        <label id={fieldWithError?.filter((field) => { if (field == '\nTipo de acesso') return field })?.length > 0 ? 'errorField' : ''}>
                                            <Autocomplete
                                                style={{ width: '100%' }}
                                                options={userTypesToCurrentUser()}
                                                value={selectedUserType}
                                                onChange={(event, newValue) => {
                                                    setSelectedUserType(newValue);
                                                }}
                                                getOptionLabel={(x) => titleize(x.type_description)}
                                                id='userType'
                                                renderInput={(params) => <TextField
                                                    label='Tipo de Acesso'
                                                    onKeyPress={(e) => { if (e.code === 'Backquote') e.preventDefault() }}
                                                    className={classes.root}
                                                    {...params} variant="outlined" />}
                                            />
                                        </label>
                                        <div className={selectedUserType?.type_access === 3 ? 'usersCadastrationBrokerSelectionProfile' : 'usersCadastrationAutocompleteHide'}>
                                            <label >
                                                <Autocomplete
                                                    style={{ width: '100%' }}
                                                    options={profilesList}
                                                    value={selectedProfileBroker}
                                                    defaultValue={profilesList[0]?.profile_description}
                                                    onChange={(event, newValue) => {
                                                        setSelectedProfileBroker(newValue);
                                                    }}
                                                    getOptionLabel={(x) => titleize(x.profile_description)}
                                                    renderInput={(params) =>
                                                        <TextField
                                                            onKeyPress={(e) => { if (e.code === 'Backquote') e.preventDefault() }}
                                                            label='Perfil do Broker'
                                                            className={classes.root}
                                                            {...params} variant="outlined" />}
                                                />
                                            </label>
                                        </div>
                                        <label>
                                            <Autocomplete
                                                style={{ width: '100%' }}
                                                options={branchesList}
                                                value={selectedUserBranch}
                                                onChange={(event, newValue) => {
                                                    setSelectedUserBranch(newValue);
                                                }}
                                                getOptionLabel={(x) => x.name}
                                                id='userType'
                                                renderInput={(params) => <TextField
                                                    label='Filial'
                                                    onKeyPress={(e) => { if (e.code === 'Backquote') e.preventDefault() }}
                                                    className={classes.root}

                                                    {...params} variant="outlined" />}
                                            />
                                        </label>
                                    </div>
                                    <button className='buttonWhiteBg' type='submit' onClick={handleSubmit}>Cadastrar</button>
                                </form>
                            </>
                        )
                }
            </div>
        </div>
    )
}