import React, { useEffect, useState } from 'react'
/*Components*/
import { TextField } from '@material-ui/core'
import { styleClientsInput } from '../../../../components/styles/autocompletField'
/*Styles*/
import './styles/asset.classes.editBox.css'
/*Utils*/
import { ModalSizeFitToScreen } from '../../../../utils/responsiveFunctions/modalSizeFitToScreen'
/*Contexts*/
import { useModal } from '../../../../contexts/useModal'
import { useAuth } from '../../../../contexts/useAuth'
/*Services*/
import { api } from '../../../../services/api'
import { ArrowLoader } from '../../../../components/loaders/arrowLoader'


export function AssetClassesEditBox(props) {

    const [loading, setLoading] = useState(false)
    const classes = styleClientsInput()
    const [assetClassId, setClassId] = useState(0)
    const [selectedNewClass, setSelectedNewClass] = useState('')
    const [classType, setClassType] = useState('')
    const { screenX, screenY } = ModalSizeFitToScreen();
    const { show, setShow, setType, setEdited } = useModal()
    const { tokenKey } = useAuth()

    useEffect(() => {

        setClassId(props?.data?.assetClass?.id)
        setSelectedNewClass(props?.data?.assetClass?.description)
        setClassType(props?.data?.assetClass?.type)
    }, [show])


    async function handleEditClass() {

        setLoading(true)
        try {
            await api.put(`/api/v1/assetClass/${assetClassId}`, {
                description: selectedNewClass
            },
                {
                    headers: {
                        authorization: `Bearer ${tokenKey}`
                    }
                })
            setSelectedNewClass('')
            setType({ name: 'alertMsg', type: 'information', value: { message: 'Edição efetuada com sucesso!' } })
            setEdited(true)
        } catch (error) {
            setType({ name: 'alertMsg', type: 'information', value: { message: error?.response?.data?.message === undefined ? 'Erro ao atualizar classe' : error?.response?.data?.message } })
            setShow(true);
        }
    }

    return (

        <div className="assetClassesEditBoxContainer"
            style={{
                width: screenX > 1300 ? screenX - 750 : screenX - 350,
                height: screenY > 700 ? screenY - 250 : screenY - 100
            }}>
            <div className="assetClassesEditBoxHeader">
                <p>Edição de classe</p>
                <button className='modalCloseButton' onClick={() => setShow(false)}>X</button>
            </div>
            <div className='assetClassesEditBoxBody'>
                {
                    loading ?
                        (
                            <div className='assetClassesEditBoxLoader'>
                                <ArrowLoader />
                            </div>
                        ) :
                        (
                            <>
                                <div className='assetClassesEditBoxEdition'>
                                    <p>Edite o nome da classe abaixo</p>
                                    <TextField
                                        onKeyPress={(e) => {if(e.code === 'Backquote') e.preventDefault()}}
                                        inputProps={{maxLength: '30'}}
                                        onChange={(e) => setSelectedNewClass(e.target.value)}
                                        value={selectedNewClass}
                                        variant='outlined'
                                        className={classes.styleForClients}
                                        label='Classe'
                                    >
                                    </TextField>
                                </div>
                                <button className='buttonWhiteBg' onClick={() => handleEditClass()}>Confirmar</button>
                            </>
                        )
                }

            </div>
        </div>
    )
}