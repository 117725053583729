/*Componente detalhe de clientes
/*Descrição: Exibe os detalhes e permite alteração do cliente
/*Desenvolvido por: Equipe Rafael Cezário
*/

//libraries
import React, { useState, useEffect } from "react";
import AntSwitch from "@material-ui/core/Switch";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { FiEdit, FiTrash2, FiX } from "react-icons/fi";
import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
//utils
import { titleize } from "../../../../utils/formatNumberAndText/formatTextUpperFirstLetter";
import CurrencyTextField from "@unicef/material-ui-currency-textfield";
import { numberBRLFormat } from "../../../../utils/formatNumberAndText/formatNumbertoBRL";
import { ModalSizeFitToScreen } from "../../../../utils/responsiveFunctions/modalSizeFitToScreen";
import { toBRL } from "../../../../utils/formatNumberAndText/toBRLCurrency";
//components
import { ArrowLoader } from "../../../../components/loaders/arrowLoader";
import { useModal } from "../../../../contexts/useModal";
//services
import { api } from "../../../../services/api";
import { myAdvisorsClients } from "../../../../services/asyncFunctions";
//styles
import { styleClientsInput } from "../../../../components/styles/autocompletField";
import "./styles/vbaClients.details.css";
import "./styles/vba.setToBase.css";
//Contexts
import { useAuth } from "../../../../contexts/useAuth";

export function VbaClientSetToBase(props) {
    const { user, tokenKey } = useAuth();
    const { show, setShow, setType, type, edited, setEdited } = useModal();
    const [statusThisClient, setStatusThisClient] = useState("");
    const [statusList, setStatusList] = useState([]);
    const [clientData, setClientData] = useState([]);
    const [listBrokers, setListBrokers] = useState([]);
    const [listAdvisor, setListAdvisor] = useState([]);
    const [advisorList, setAdvisorList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [inEditProfileBroker, setInEditProfileBroker] = useState([]);
    const [successEdit, setSuccessEdit] = useState(false);
    const [statusUpdatedToActive, setStatusUpdatedToActive] = useState(false);
    const [brokerRemoved, setBrokerRemoved] = useState([]);
    const [loadingTable, setLoadingTable] = useState(false);
    const [profileList, setProfileList] = useState([]);
    const [clientIsUpdated, setClientIsUpdated] = useState(false);
    const [clientStatusUpdated, setClientStatusUpdated] = useState(false);
    const [selectedBroker, setSelectedBroker] = useState(null);
    const [brokerList, setBrokerList] = useState([]);
    const [inEdit, setInEdit] = useState({
        checkedA: false,
        checkedC: false,
    });
    const [selectedClientName, setSelectedClientName] = useState("");
    const [selectedClientProfile, setSelectedClientProfile] = useState("");
    const [selectedOperatesAlone, setSelectedClientOperatesAlone] = useState("");
    const [selectedNetStock, setSelectedNetStock] = useState("");
    const [selectedNetTotal, setSelectedNetTotal] = useState("");
    const [selectedBrokers, setSelectedBrokers] = useState([]);
    const [selectedAdvisor, setSelectedAdvisor] = useState([]);
    const [data, setData] = useState([]);
    const [showConfirmButton, setShowConfirmButton] = useState(false);

    const [listClients, setListClients] = useState([]);
    const [selectedClient, setSelectedClient] = useState(null);

    // const handleChange = (event) => {
    //     setInEdit({ ...inEdit, [event.target.name]: event.target.checked });
    //     clear();
    //     setLoadingTable(true);
    //     console.log(event)
    // };

    // async function handleChangeStatusClient() {
    //     try {
    //         const changeStatusResult = await api.put(`api/v1/clients/${clientData[0]?.external_id}/status`, {
    //             headers: {
    //                 authorization: `Bearer ${tokenKey}`
    //             }
    //         })
    //     } catch (error) {
    //         setType({ name: 'alertMsg', type: 'information', value: { message: error?.response?.data?.message } });
    //         setShow(true);
    //     }
    // }

    function clear() {
        setSuccessEdit(false);
        setSelectedClient(null);
        setShowConfirmButton(false);
        setShow(false);
    }

    async function handleSubmit() {
        // console.log(selectedClient.external_id)
        // console.log(user.external_id)

        if (selectedClient) {
            setLoading(true);
            try {
                const clientUpdate = await api.get(
                    `/api/v1/vba/set/${String(user.external_id)}/${String(selectedClient.external_id)}`,
                    {
                        headers: {
                            authorization: `Bearer ${tokenKey}`,
                        },
                    }
                );

                setType({
                    name: "successClientEdit",
                    type: "information",
                    value: { message: `${clientUpdate.data.message}` + " - " + ` ${titleize(selectedClient.name)}` },
                });
                setLoading(false);
            } catch (error) {
                alert(error?.response?.data?.message);
                setLoading(false);
            }
        }
    }

    useEffect(async () => {
        setLoading(true);
        const clientsGet = await myAdvisorsClients(user.external_id, tokenKey, false);

        if (clientsGet?.isError) {
            setType({
                name: "alertMsg",
                type: "information",
                value: {
                    message: clientsGet?.error,
                },
            });
            setShow(true);
        } else {
            console.log("clientes: ", clientsGet.my_clients_advisor[0]);
            setListClients(clientsGet.my_clients_advisor);
        }
        setLoading(false);
    }, [show]);

    return (
        <div className="clientSetToBaseContainer">
            {loading ? (
                <div id="clientDetailsLoader">
                    <ArrowLoader />
                </div>
            ) : successEdit ? (
                <div className="clientDetailsSuccessEdit">
                    <a>Sucesso ao Trazer Cliente</a>
                    <button
                        onClick={() => {
                            setSuccessEdit(false);
                            setType({ name: "successUpdateClient" });
                            setShow(false);
                        }}
                    >
                        Ok
                    </button>
                </div>
            ) : (
                <>
                    <div className="clientsDetailsHeader">
                        <div>
                            <p>Trazer cliente para a minha base</p>
                        </div>
                        <button onClick={() => clear()}>x</button>
                    </div>

                    <span className="subTitleSetToBase">Cliente:</span>
                    <div className="clientDetailsBox2">
                        <div className="titleSelectClient">
                            <p className="titleSetToBase">Selecione um cliente: </p>
                        </div>

                        <Autocomplete
                            options={listClients}
                            onFocus={(e) => e.target.click()}
                            onChange={(event, newValue) => {
                                setSelectedClient(newValue ? newValue : null);
                            }}
                            className={"selectClientSetToBase"}
                            getOptionLabel={(option) => option.external_id + " - " + titleize(option.name)}
                            id="client"
                            renderInput={(params) => (
                                <TextField
                                    label="Selecione:"
                                    size="small"
                                    required={false}
                                    placeholder="Selecione:"
                                    {...params}
                                    onChange={(e) => {
                                        // handleChange(e)
                                    }}
                                />
                            )}
                        />
                    </div>
                    {selectedClient !== null ? (
                        <>
                            <span className="subTitleSetToBase">Dados Cliente:</span>
                            <div className="clientDetailsBox">
                                <span className="itemDetail">
                                    <a id="clientDetailFirstAElementVBA">Código: </a>
                                    <a>{selectedClient.external_id}</a>
                                </span>
                                <span className="itemDetail">
                                    <a id="clientDetailFirstAElementVBA">Nome: </a>
                                    <a>{titleize(selectedClient.name)}</a>
                                </span>
                                <span className="itemDetail">
                                    <a id="clientDetailFirstAElementVBA">Perfil: </a>
                                    <a>{titleize(selectedClient.client_profile)}</a>
                                </span>
                                <span className="itemDetail">
                                    <a id="clientDetailFirstAElementVBA">Net ações: </a>
                                    {toBRL(selectedClient.net_stock ? selectedClient.net_stock : 0)}
                                </span>
                                <span className="itemDetail">
                                    <a id="clientDetailFirstAElementVBA">Net total: </a>
                                    <a>{toBRL(selectedClient.net_total ? selectedClient.net_total : 0)}</a>
                                </span>
                                <span className="itemDetail"></span>
                            </div>

                            <span className="subTitleSetToBase">Assessor:</span>
                            <div className="clientDetailsBox">
                                <span className="itemDetail">
                                    <a id="clientDetailFirstAElementVBA">Código: </a>
                                    <a>{titleize(selectedClient.external_id_a)}</a>
                                </span>

                                <span className="itemDetail">
                                    <a id="clientDetailFirstAElementVBA">Nome: </a>
                                    <a>{titleize(selectedClient.advisor_name)}</a>
                                </span>
                            </div>
                            {showConfirmButton ? (
                                <>
                                    <span className="itemButton">
                                        <button className="buttonSetToBaseConfirm" onClick={() => handleSubmit()}>
                                            Confirmar
                                        </button>
                                        <button className="buttonSetToBaseConfirm" onClick={() => setShowConfirmButton(false)}>
                                            Cancelar
                                        </button>
                                    </span>
                                </>
                            ) : (
                                <span className="itemButton">
                                    <button className="buttonSetToBase" onClick={() => setShowConfirmButton(true)}>
                                        Trazer cliente
                                    </button>
                                </span>
                            )}
                        </>
                    ) : (
                        <></>
                    )}
                </>
            )}
        </div>
    );
}
