import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import { Tooltip, IconButton } from "@material-ui/core";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { ThemeProvider,createTheme } from '@mui/material/styles';
/*Styles*/
import "../styles/roaDashboard.table.css";
/*Contexts*/
import { useAuth } from "../../../../contexts/useAuth";
import { useModal } from "../../../../contexts/useModal";
/*Services*/
import { getClientsROA, getDatabaseUpdateTime } from "../../../../services/asyncFunctions";
/*Components*/
import { ArrowLoader } from "../../../../components/loaders/arrowLoader";
import { TableCell } from "@material-ui/core";
/*Utils*/
import { toBRL } from "../../../../utils/formatNumberAndText/toBRLCurrency";
import { toPercent } from "../../../../utils/formatNumberAndText/formatNumberPercent";
import { AiOutlineArrowUp, AiOutlineArrowDown } from "react-icons/ai";

export function RoaDashboardTbl(props) {
    const [loading, setLoading] = useState(false);
    const [listRoaDashboard, setlistRoaDashboard] = useState([]);
    const [listRoaDashboardFormatted, setlistRoaDashboardFormatted] = useState([]);
    const [databaseUpdateTime, setDatabaseUpdateTime] = useState([]);
    const { tokenKey } = useAuth();
    const { setType, setShow, edited, setEdited } = useModal();

    const [netRvTbl ,setNetRvTbl] = useState(0)
    const [revTbl ,setRevTbl] = useState(0)
    const [roaTbl ,setRoaTbl] = useState(0)
    const [avgRoa ,setAvgRoa] = useState(0)
    const [commTbl ,setCommTbl] = useState(0)
    const [offTbl ,setOffTbl] = useState(0)
    const [strTbl ,setStrTbl] = useState(0)
    const [btcTbl ,setBtcTbl] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(30);

    const location = useLocation();
    let orderRec = location.orderRec;

    async function getClientsROAindex() {
        const listRoaDashboard = await getClientsROA(tokenKey, false);
        if (getClientsROA?.isError) {
            setType({
                name: "alertMsg",
                type: "information",
                value: {
                    message: getClientsROA?.error,
                },
            });
            setShow(true);
        } else {
            setlistRoaDashboard(listRoaDashboard);
            setLoading(false);
        }
    }

    async function getDatabaseUpdateTimeValues() {
        const bases = 'roa_dashboard';
        const databaseUpdateTime = await getDatabaseUpdateTime(tokenKey, bases);
        if (getDatabaseUpdateTime?.isError) {
            setType({
                name: "alertMsg",
                type: "information",
                value: {
                    message: getDatabaseUpdateTime?.error,
                },
            });
            setShow(true);
        } else {
            try {
                let dateFormat = databaseUpdateTime[0].updated_at;
                const dateObj = new Date(dateFormat)
                const dateFormatted = String(` - Atualizado em: ${dateObj.getDate()}/${dateObj.getMonth() + 1}/${dateObj.getFullYear()} ${dateObj.getHours()}:${dateObj.getMinutes()}h`)
                setDatabaseUpdateTime(dateFormatted);
                // console.log(dateFormatted);
            } catch (e) {
                let dateFormat = ''
                setDatabaseUpdateTime(dateFormat);
            }
        }
    }

    function formateAssets() {
        let roaDashboardFormatted = [];

        listRoaDashboard.map((executed) => {
            roaDashboardFormatted.push({
                client_id: executed.client_id,
                client_name: executed.client_name,
                advisor_id: executed.advisor_id,
                advisor_name: executed.advisor_name,
                broker_id: executed.broker_id,
                broker_name: executed.broker_name,
                net_rv: executed.net_rv,
                roa: executed.roa,
                revenue: executed.revenue,
                commission: executed.commission,
                structured : executed.structured,
                offers: executed.offers,
                btc: executed.btc
            });
        });

        setlistRoaDashboardFormatted(roaDashboardFormatted);
        setLoading(false);
    }

    useEffect(() => {
        if (edited || listRoaDashboard.length === 0) {
            setEdited(false);
            setLoading(true);
            getClientsROAindex();
            getDatabaseUpdateTimeValues();
        }
    }, [edited]);

    useEffect(() => {
        if (listRoaDashboard.length > 0) {
            formateAssets();
        }
    }, [listRoaDashboard]);

    useEffect(() => {
        // console.log(listProjected);
        var netRvTbl = 0;
        var revTbl = 0;
        var roaTbl = 0;
        var roaSum = 0;
        var commTbl = 0;
        var offTbl = 0;
        var strTbl = 0;
        var btcTbl = 0;
        listRoaDashboard.forEach((e) => {
            netRvTbl += e.net_rv;
            revTbl += e.revenue;
            roaTbl += e.roa;
            roaSum += 1;
            commTbl += e.commission;
            offTbl += e.offers;
            strTbl += e.structured;
            btcTbl += e.btc;
        })

        var avgRoa = revTbl * 100 / netRvTbl
        
        setNetRvTbl(netRvTbl)
        setRevTbl(revTbl)
        setRoaTbl(roaTbl)
        setAvgRoa(avgRoa)
        setCommTbl(commTbl)
        setOffTbl(offTbl)
        setStrTbl(strTbl)
        setBtcTbl(btcTbl)
    }, [listRoaDashboard]);

    function copyToClipboard() {
        const output = [];
        const titles = [
            "Código Cliente",
            "Nome do Cliente",
            "Código Assessor",
            "Nome Assessor",
            "Código Broker",
            "Nome Broker",
            "NET RV",
            "ROA",
            "Receita",
            "Corretagens",
            "Estruturadas",
            "IPO",
            "BTC"
        ];
        output.push(titles.join("\t"));
        const keys = [
            "client_id",
            "client_name",
            "advisor_id",
            "advisor_name",
            "broker_id",
            "broker_name",
            "net_rv",
            "roa",
            "revenue",
            "commission",
            "structured",
            "offers",
            "btc"
        ];
        listRoaDashboard.forEach((x) => {
            const tmp = keys.map((y) => {
                return y === "net_rv" ||
                    y === "roa" ||
                    y === "revenue" ||
                    y === "commission" ||
                    y === "offers" ||
                    y === "structured" ||
                    y === "btc"
                    ? String(x[y]).replace(".", ",")
                    : x[y];
            });
            output.push(tmp.join("\t"));
        });
        const table = output.join("\n");
        navigator.clipboard.writeText(table);
        alert("Conteúdo da tabela copiado para a área de transferência");
    }

    async function executedStructuredExport(open, year) {
        setLoading(true);
        try {
            const myExport = await getClientsROA(tokenKey, true);
            const responseURL = URL.createObjectURL(new Blob([myExport]));
            const link = document.createElement("a");
            link.href = responseURL;
            link.setAttribute("download", "roaPorCliente.xlsx");
            document.body.appendChild(link);
            link.click();
            setLoading(false);
        } catch (error) {
            setType({
                name: "alertMsg",
                type: "information",
                value: {
                    message:
                        error?.response?.data?.message === undefined
                            ? "Erro ao buscar dados para exportação"
                            : error?.response?.data?.message,
                },
            });
            setShow(true);
            setLoading(false);
        }
    }

    const columns = [
        {
            name: "client_id",
            label: "Código Cliente",
            options: {
                filter: true,
                sort: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        padding: '0px 0.6rem 0px 0.6rem',
                    },
                }),
            },
        },
        {
            name: "client_name",
            label: "Nome do Cliente",
            options: {
                filter: true,
                sort: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        padding: '0px 0.6rem 0px 0.6rem',
                    },
                }),
            },
        },
        {
            name: "advisor_name",
            label: "Nome do Assessor",
            options: {
                filter: true,
                sort: true,

                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        padding: '0px 0.6rem 0px 0.6rem',
                    },
                }),
            },
        },
        {
            name: "broker_name",
            label: "Nome do Broker",
            options: {
                filter: true,
                sort: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        padding: '0px 0.6rem 0px 0.6rem',
                    },
                }),
            },
        },
        {
            name: "net_rv",
            label: "NET RV Total",
            options: {
                filter: false,
                sort: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        padding: '0px 0.6rem 0px 0.6rem',
                    },
                }),

                customBodyRender: (val) => {
                    return toBRL(parseFloat(val));
                },
                customHeadRender: ({ index, name, ...column }, sortColumn, tableMeta) => (
                    <TableCell
                        className="containerSelectContactTypeHeader tss-gm6zfk-MUIDataTableHeadCell-fixedHeader"
                        onClick={() => {
                            sortColumn(index);
                        }}>
                        <div className="textSumCell">
                            <div>
                                {/* <TableSortLabel active={tableMeta.name === column.name} direction={tableMeta.direction || "asc"} /> */}
                                {column.label} {tableMeta.name === name ? tableMeta.direction == "asc" ? <AiOutlineArrowUp /> : <AiOutlineArrowDown /> : <></>}
                            </div>
                            <p>{toBRL(netRvTbl)}</p>
                        </div>
                    </TableCell>
                ),
            },
        },
        {
            name: "commission",
            label: "CORRETAGENS",
            options: {
                filter: false,
                sort: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        padding: '0px 0.6rem 0px 0.6rem',
                    },
                }),

                customBodyRender: (val) => {
                    return toBRL(parseFloat(val));
                },
                customHeadRender: ({ index, name, ...column }, sortColumn, tableMeta) => (
                    <TableCell
                        className="containerSelectContactTypeHeader tss-gm6zfk-MUIDataTableHeadCell-fixedHeader"
                        onClick={() => {
                            sortColumn(index);
                        }}>
                        <div className="textSumCell">
                            <div>
                                {/* <TableSortLabel active={tableMeta.name === column.name} direction={tableMeta.direction || "asc"} /> */}
                                {column.label} {tableMeta.name === name ? tableMeta.direction == "asc" ? <AiOutlineArrowUp /> : <AiOutlineArrowDown /> : <></>}
                            </div>
                            <p>{toBRL(commTbl)}</p>
                        </div>
                    </TableCell>
                ),
            },
        },
        {
            name: "offers",
            label: "OFERTAS",
            options: {
                filter: false,
                sort: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        padding: '0px 0.6rem 0px 0.6rem',
                    },
                }),

                customBodyRender: (val) => {
                    return toBRL(parseFloat(val));
                },
                customHeadRender: ({ index, name, ...column }, sortColumn, tableMeta) => (
                    <TableCell
                        className="containerSelectContactTypeHeader tss-gm6zfk-MUIDataTableHeadCell-fixedHeader"
                        onClick={() => {
                            sortColumn(index);
                        }}>
                        <div className="textSumCell">
                            <div>
                                {/* <TableSortLabel active={tableMeta.name === column.name} direction={tableMeta.direction || "asc"} /> */}
                                {column.label} {tableMeta.name === name ? tableMeta.direction == "asc" ? <AiOutlineArrowUp /> : <AiOutlineArrowDown /> : <></>}
                            </div>
                            <p>{toBRL(offTbl)}</p>
                        </div>
                    </TableCell>
                ),
            },
        },
        {
            name: "structured",
            label: "ESTRUTURADAS",
            options: {
                filter: false,
                sort: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        padding: '0px 0.6rem 0px 0.6rem',
                    },
                }),

                customBodyRender: (val) => {
                    return toBRL(parseFloat(val));
                },
                customHeadRender: ({ index, name, ...column }, sortColumn, tableMeta) => (
                    <TableCell
                        className="containerSelectContactTypeHeader tss-gm6zfk-MUIDataTableHeadCell-fixedHeader"
                        onClick={() => {
                            sortColumn(index);
                        }}>
                        <div className="textSumCell">
                            <div>
                                {/* <TableSortLabel active={tableMeta.name === column.name} direction={tableMeta.direction || "asc"} /> */}
                                {column.label} {tableMeta.name === name ? tableMeta.direction == "asc" ? <AiOutlineArrowUp /> : <AiOutlineArrowDown /> : <></>}
                            </div>
                            <p>{toBRL(strTbl)}</p>
                        </div>
                    </TableCell>
                ),
            },
        },
        {
            name: "btc",
            label: "BTC",
            options: {
                filter: false,
                sort: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        padding: '0px 0.6rem 0px 0.6rem',
                    },
                }),

                customBodyRender: (val) => {
                    return toBRL(parseFloat(val));
                },
                customHeadRender: ({ index, name, ...column }, sortColumn, tableMeta) => (
                    <TableCell
                        className="containerSelectContactTypeHeader tss-gm6zfk-MUIDataTableHeadCell-fixedHeader"
                        onClick={() => {
                            sortColumn(index);
                        }}>
                        <div className="textSumCell">
                            <div>
                                {/* <TableSortLabel active={tableMeta.name === column.name} direction={tableMeta.direction || "asc"} /> */}
                                {column.label} {tableMeta.name === name ? tableMeta.direction == "asc" ? <AiOutlineArrowUp /> : <AiOutlineArrowDown /> : <></>}
                            </div>
                            <p>{toBRL(btcTbl)}</p>
                        </div>
                    </TableCell>
                ),
            },
        },
        {
            name: "revenue",
            label: "RECEITA TOTAL",
            options: {
                filter: false,
                sort: true,

                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        padding: '0px 0.6rem 0px 0.6rem',
                    },
                }),

                customBodyRender: (val) => {
                    return toBRL(parseFloat(val));
                },
                customHeadRender: ({ index, name, ...column }, sortColumn, tableMeta) => (
                    <TableCell
                        className="containerSelectContactTypeHeader tss-gm6zfk-MUIDataTableHeadCell-fixedHeader"
                        onClick={() => {
                            sortColumn(index);
                        }}>
                        <div className="textSumCell">
                            <div>
                                {/* <TableSortLabel active={tableMeta.name === column.name} direction={tableMeta.direction || "asc"} /> */}
                                {column.label} {tableMeta.name === name ? tableMeta.direction == "asc" ? <AiOutlineArrowUp /> : <AiOutlineArrowDown /> : <></>}
                            </div>
                            <p>{toBRL(revTbl)}</p>
                        </div>
                    </TableCell>
                ),
            },
        },
        {
            name: "roa",
            label: "ROA",
            options: {
                filter: false,
                sort: true,

                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        padding: '0px 0.6rem 0px 0.6rem',
                    },
                }),
                
                customBodyRender: (val) => {
                    return toPercent(parseFloat(val));
                },
                customHeadRender: ({ index, name, ...column }, sortColumn, tableMeta) => (
                    <TableCell
                        className="containerSelectContactTypeHeader tss-gm6zfk-MUIDataTableHeadCell-fixedHeader"
                        onClick={() => {
                            sortColumn(index);
                        }}>
                        <div className="textSumCell">
                            <div>
                                {/* <TableSortLabel active={tableMeta.name === column.name} direction={tableMeta.direction || "asc"} /> */}
                                {column.label} {tableMeta.name === name ? tableMeta.direction == "asc" ? <AiOutlineArrowUp /> : <AiOutlineArrowDown /> : <></>}
                            </div>
                            <p>{toPercent(avgRoa)}</p>
                        </div>
                    </TableCell>
                ),
            },
        }
    ];

    // Adiciona um botão para chamar a função de copiar a tabela para a área de tranferência
    const AddButton = () => (
        <Tooltip disableFocusListener title="Copiar para a área de transferência">
            <IconButton onClick={copyToClipboard}>
                <ContentCopyIcon />
            </IconButton>
        </Tooltip>
    );

    const options = {
        filterType: "multiselect",
        filter: true,
        download: true,
        print: false,
        responsive: "standard",
        tableBodyHeight: "72vh",
        draggableColumns: {
            enabled: true,
        },
        pagination: true,
        rowsPerPage: 30,
        rowsPerPageOptions: [30, 300, 3000],
        selectableRowsHideCheckboxes: true,
        viewColumns: false,
        sortOrder: {
            name: 'revenue',
            direction: orderRec
        },
        textLabels: {
            body: {
                noMatch: loading ? <ArrowLoader /> : "Não há informações até o momento para projeção de receita.",
                toolTip: "Ordenar",
                columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
            },
            pagination: {
                next: "Próxima",
                previous: "Anterior",
                rowsPerPage: "Linhas por página:",
                displayRows: "de",
            },
            toolbar: {
                search: "Pesquisar",
                downloadCsv: "Download XLSX",
                print: "Imprimir",
                viewColumns: "Ver colunas",
                filterTable: "Filtrar",
            },
            filter: {
                all: "Todos",
                title: "Filtros",
                reset: "Limpar",
            },
            viewColumns: {
                title: "Mostrar Colunas",
                titleAria: "Mostrar/Ocultar colunas",
            },
            selectedRows: {
                text: "linha(s) selecionadas",
                delete: "Excluir",
                deleteAria: "Excluir linhas Selecionadas",
            },
        },
        downloadOptions: {
            filename: "roa_por_cliente.csv",
            separator: ";",
        },
        onDownload: () => {
            executedStructuredExport();
            return false;
        },
        customToolbar: AddButton
    };

    const getMuiTheme = () =>
    createTheme({
      components: {
        MuiTableCell: {
            styleOverrides: {
                root:{
                        justifyContent: 'center',
                        whiteSpace: "nowrap",
                        textAlign: 'center',
                }
            },
          },
        MUIDataTableHeadCell: {
            styleOverrides: {
                contentWrapper: {
                    justifyContent: 'center',
                    whiteSpace: "nowrap",
                }
            }
        },
      },
    })

    return (
        <div style={{ maxWidth: "95%", marginTop: "2rem" }}>
            <ThemeProvider theme={getMuiTheme()}>
                <MUIDataTable title={`ROA por Cliente ${databaseUpdateTime}`} data={listRoaDashboard} columns={columns} options={options}></MUIDataTable>
            </ThemeProvider>
        </div>
    );
}
