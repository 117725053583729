/*Componente detalhe de clientes
/*Descrição: Exibe os detalhes e permite alteração do cliente
/*Desenvolvido por: Equipe Rafael Cezário
*/

//libraries
import React, { useState, useEffect } from 'react';
import AntSwitch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { FiEdit, FiTrash2, FiX } from 'react-icons/fi';
import { TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab';
//utils
import { titleize } from '../../../../utils/formatNumberAndText/formatTextUpperFirstLetter';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import { numberBRLFormat } from '../../../../utils/formatNumberAndText/formatNumbertoBRL';
import { ModalSizeFitToScreen } from '../../../../utils/responsiveFunctions/modalSizeFitToScreen'
//components
import { ArrowLoader } from '../../../../components/loaders/arrowLoader';
import { useModal } from '../../../../contexts/useModal';
//services
import { api } from '../../../../services/api';
//styles
import { styleClientsInput } from '../../../../components/styles/autocompletField';
import './styles/clients.details.css';
//Contexts
import { useAuth } from '../../../../contexts/useAuth';

export function ClientDetails(props) {

    const { user, tokenKey } = useAuth();
    const [statusThisClient, setStatusThisClient] = useState('');
    const [statusList, setStatusList] = useState([]);
    const [clientData, setClientData] = useState([]);
    const [listBrokers, setListBrokers] = useState([]);
    const [listAdvisor, setListAdvisor] = useState([]);
    const [brokerList, setBrokerList] = useState([]);
    const [advisorList, setAdvisorList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [inEditProfileBroker, setInEditProfileBroker] = useState([]);
    const [successEdit, setSuccessEdit] = useState(false);
    const [statusUpdatedToActive, setStatusUpdatedToActive] = useState(false);
    const [brokerRemoved, setBrokerRemoved] = useState([]);
    const [loadingTable, setLoadingTable] = useState(true);
    const [profileList, setProfileList] = useState([]);
    const [clientIsUpdated, setClientIsUpdated] = useState(false);
    const [clientStatusUpdated, setClientStatusUpdated] = useState(false);
    const [inEdit, setInEdit] = useState({
        checkedA: false,
        checkedC: false,
    });
    const [selectedClientName, setSelectedClientName] = useState('');
    const [selectedClientProfile, setSelectedClientProfile] = useState('');
    const [selectedOperatesAlone, setSelectedClientOperatesAlone] = useState('');
    const [selectedNetStock, setSelectedNetStock] = useState('');
    const [selectedNetTotal, setSelectedNetTotal] = useState('');
    const [selectedBroker, setSelectedBroker] = useState();
    const [selectedAdvisor, setSelectedAdvisor] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState('');
    const classes = styleClientsInput();
    const requiredFields = [
        { field: 'selectedClientName', value: selectedClientName },
        { field: 'selectedClientProfile', value: selectedClientProfile },
    ]
    const [errorFields, setErrorFields] = useState({
        selectedClientName: false,
        selectedClientProfile: false
    })
    const handleChange = async (event) => {
        setInEdit({ ...inEdit, [event.target.name]: event.target.checked });
        clear();
        setLoadingTable(true);
        try {
            const brokersResult = await api.get('/api/v1/users?status=1&type_access=3', {
                headers: {
                    authorization: `Bearer ${tokenKey}`
                }
            });
            setBrokerList(brokersResult.data.data);

            const advisorsResult = await api.get('/api/v1/users?status=1&type_access=4', {
                headers: {
                    authorization: `Bearer ${tokenKey}`
                }
            });

            const brokerProfiles = await api.get(`/api/v1/profiles`, {
                headers: {
                    authorization: `Bearer ${tokenKey}`
                }
            })

            setProfileList(brokerProfiles?.data?.data);
            setAdvisorList(advisorsResult?.data?.data);
            setLoadingTable(false)

        } catch (error) {
            alert(error?.response?.data?.message)
        }

    };
    const { type, setType, setShow, edited, setEdited } = useModal();
    const { screenY } = ModalSizeFitToScreen();


    useEffect(() => {

        async function getStatusClients() {

            try {
                const clientsStatusResult = await api.get(`/api/v1/statusClients`, {
                    headers: {
                        authorization: `Bearer ${tokenKey}`
                    }
                })
                const status = clientsStatusResult?.data?.data?.filter((status) => {
                    if (status?.id === type?.value?.client[0]?.active) {
                        return status
                    }
                })
                setStatusList(clientsStatusResult?.data?.data)
                setStatusThisClient(status[0]);
                setSelectedStatus(status[0]);
                setLoading(false);
            } catch (error) {
                alert(error?.response?.data?.message);
                setLoading(false);
            }
        }

        setLoading(true);
        if (type?.value?.broker !== undefined && type?.value?.client !== undefined) {
            setClientData(type?.value?.client);
            setListBrokers(type?.value?.broker)
            setListAdvisor(type?.value?.client);
            typeof (type?.value?.client[0]?.advisor_external_id) === 'string' ? setSelectedAdvisor({ external_id: type?.value?.client[0].advisor_external_id, full_name: type?.value?.client[0].advisor_name }) :
                setSelectedAdvisor({ external_id: '-', full_name: 'Selecione' })
            setSelectedBroker(type?.value?.broker[0]);
            type?.value?.broker[0]?.broker_id ? setSelectedBroker({ external_id: type?.value?.broker[0].broker_id, full_name: type?.value?.broker[0].full_name }) :
            setSelectedBroker({ external_id: '-', full_name: 'Selecione' })
            setSelectedClientName(type?.value.client[0]?.name);
            setSelectedClientProfile(type?.value.client[0]?.client_profile);
            setSelectedClientOperatesAlone(type?.value.client[0]?.operates_alone);
            setSelectedNetStock(type?.value.client[0]?.net_stock);
            setSelectedNetTotal(type?.value.client[0]?.net_total);
            setClientIsUpdated(false);
            setStatusUpdatedToActive(false);
            setClientStatusUpdated(false);
            setEdited(false);
            setInEdit({ ...inEdit, ['checkedA']: false });
            getStatusClients();
        }
    }, [type])

    async function handleSubmit() {
        setLoading(true);

        let errors = false
        requiredFields.map((required) => {
            if (!required?.value?.trim()?.length) {
                errorFields[required.field] = true
                errors = true
            } else {
                errorFields[required.field] = false
            }
        })
        setErrorFields(errorFields)

        if (errors) {

            await new Promise(resolve => setTimeout(resolve, 1000))
            alert('Campos obrigatórios não preenchidos')
            setLoading(false)
            return
        }
        console.log (selectedBroker)
        
        try {
            const clientUpdate = await api.put(`/api/v1/clients/${clientData[0]?.external_id}`, {
                "name": selectedClientName,
                "cpfcnpj": undefined,
                "client_profile": selectedClientProfile,
                "operate_alone": selectedOperatesAlone.length > 0 ? selectedOperatesAlone : undefined,
                "id_advisor": parseInt(selectedAdvisor?.external_id),
                "id_broker": parseInt(selectedBroker?.external_id),
                "net_total": selectedNetTotal,
                "net_stock": selectedNetStock
            },
                {
                    headers: {
                        authorization: `Bearer ${tokenKey}`
                    }
                })
            const getBrokers = await api.get(`/api/v1/clients/${clientData[0]?.external_id}/brokers?status=1`, {
                headers: {
                    authorization: `Bearer ${tokenKey}`
                }
            })

            let brokerToShowList = [];

            getBrokers?.data?.data.map((brokerToShow) => {
                brokerToShowList.push({
                    broker_id: parseInt(brokerToShow?.users_external_id),
                    full_name: brokerToShow?.user_full_name,
                    profile_id: brokerToShow?.profile_id,
                    profile_description: brokerToShow?.profile_description
                })
            })

            if (statusUpdatedToActive) {
                handleChangeStatusClient()
            }
            setListBrokers(brokerToShowList);
            setInEditProfileBroker([]);
            setType({ name: 'successClientEdit', type: 'information', value: { message: `Sucesso ao editar o cliente ${titleize(selectedClientName)}` } })
            setEdited(true);
            setSuccessEdit(true);
            setLoading(false);
        } catch (error) {
            alert(error?.response?.data?.message)
            setLoading(false);
        }
    }

    async function handleActivateClient() {

        setLoading(true);
        try {
            const result = await api.put(`api/v1/clients/${clientData[0]?.external_id}/status`, {
                'active': 1
            }, {
                headers: {
                    authorization: `Bearer ${tokenKey}`
                }
            })

            const newStatus = statusList.filter((status) => {
                if (status?.id === result?.data?.data?.active) {
                    return status
                }
            })
            setSelectedStatus(newStatus[0]);
            setClientIsUpdated(true);
            setEdited(!edited);
            setLoading(false);
        } catch (error) {
            setType({ name: 'alertMsg', type: 'information', value: { message: error?.response?.data?.message } });
            setShow(true);
            setLoading(false);
        }
    }

    async function handleChangeStatusClient() {
        try {
            const changeStatusResult = await api.put(`api/v1/clients/${clientData[0]?.external_id}/status`, {
                'active': selectedStatus?.id
            }, {
                headers: {
                    authorization: `Bearer ${tokenKey}`
                }
            })
        } catch (error) {
            setType({ name: 'alertMsg', type: 'information', value: { message: error?.response?.data?.message } });
            setShow(true);
        }
    }

    // async function handleDeleteClient() {
    //     if (window.confirm('Deseja realmente excluir esse cliente da base?')) {

    //         setLoading(true)
    //         try {

    //             await api.delete(`/api/v1/clients/${parseInt(props?.clientData?.value.client[0].external_id)}`, {
    //                 headers: {
    //                     authorization: `Bearer ${tokenKey}`
    //                 }
    //             })

    //         } catch (error) {

    //             error?.response?.data?.message ? alert(error?.response?.data?.message) :
    //                 alert('Erro ao excluir cliente')
    //             console.log(error.response)
    //         }

    //         setLoading(false)
    //     }
    // }

    function clear() {
        setInEditProfileBroker([]);
        setBrokerRemoved([]);
        setSuccessEdit(false);
    }

    // function setNewBrokerListToAPI(brokerTochange, indexToChange, profileBroker) {

    //     if (brokerTochange?.external_id === null || brokerTochange?.external_id === undefined) {
    //         //Remover Broker
    //         selectedBrokers.map((brokerAlreadyExist, index) => {
    //             if (brokerAlreadyExist?.broker_id !== brokerTochange?.external_id && profileBroker === brokerAlreadyExist?.profile_id) {
    //                 selectedBrokers.splice(index, 1);
    //                 setSelectedBrokers([{ broker_id: null, profile_id: profileBroker }, ...selectedBrokers]);
    //             }
    //         })
    //     }
    //     else if (indexToChange < selectedBrokers?.length) {
    //         //Alterar broker no perfil selecionado
    //         selectedBrokers.map((brokerAlreadyExist, index) => {

    //             if ((brokerAlreadyExist?.broker_id !== brokerTochange?.external_id && profileBroker === brokerAlreadyExist?.profile_id)) {
    //                 //Broker existente tem id diferente do que está sendo enviado, mas com mesmo perfil, efetua a troca
    //                 selectedBrokers.splice(index, 1);
    //                 setSelectedBrokers([{ broker_id: parseInt(brokerTochange?.external_id), profile_id: brokerTochange?.profile_id }, ...selectedBrokers]);
    //             }
    //             else if (profileBroker !== brokerAlreadyExist?.profile_id) {
    //                 //Broker sendo enviado é vazio na listagem, inclui o broker
    //                 setSelectedBrokers([{ broker_id: parseInt(brokerTochange?.external_id), profile_id: brokerTochange?.profile_id }, ...selectedBrokers]);
    //             }
    //         })
    //     } else {
    //         //Incluir novo broker
    //         if (selectedBrokers?.length < 1) {
    //             //Tratativa para quando não há nenhum broker vinculado
    //             setSelectedBrokers([{ broker_id: parseInt(brokerTochange?.external_id), profile_id: parseInt(brokerTochange?.profile_id) }])
    //         }
    //         //Tratativa para quando já há broker, mas está sendo passado um novo broker para um perfil que ainda não tem vínculo
    //         selectedBrokers.map((brokerAlreadyExist, index) => {
    //             if (brokerAlreadyExist?.broker_id !== brokerTochange?.external_id && profileBroker === brokerAlreadyExist?.profile_id) {
    //                 selectedBrokers.splice(index, 1);
    //                 setSelectedBrokers([{ broker_id: parseInt(brokerTochange?.external_id), profile_id: brokerTochange?.profile_id }, ...selectedBrokers]);
    //             }
    //             else {
    //                 setSelectedBrokers([...selectedBrokers, { broker_id: parseInt(brokerTochange?.external_id), profile_id: brokerTochange?.profile_id }])
    //             }
    //         })
    //     }
    // }

    return (
        <div className='clientDetailsContainer' style={{
            // maxHeight: screenY,
            height: screenY
        }}>
            {loading ?
                (<div id='clientDetailsLoader'>
                    <ArrowLoader />
                </div>
                ) :
                successEdit ?
                    (
                        <div className='clientDetailsSuccessEdit'>
                            <a>Sucesso ao editar o cliente</a>
                            <button onClick={() => { setSuccessEdit(false); setType({ name: 'successUpdateClient' }); setShow(false) }}>Ok</button>
                        </div>
                    ) :
                    (<>
                        <div className='clientsDetailsHeader'>
                            <div>
                                <p>Detalhes do Cliente:</p>
                                <p>{clientData[0]?.external_id} - {titleize(clientData[0]?.name)}</p>
                            </div>
                            <button onClick={() => setShow(false)}>x</button>
                        </div>
                        <div className='clientDetailsBody'>
                            <div id='clientDetailsEditButtonDiv'>
                                {
                                    user.type < 3 ?
                                        (
                                            <>
                                                <Typography id='clientDetailsEditButton' component="div">
                                                    <Grid component="label" container alignItems="center" spacing={1}>
                                                        <Grid item>
                                                            <AntSwitch className={classes.switch} checked={inEdit.checkedA} onChange={handleChange} name="checkedA" />
                                                        </Grid>
                                                        <Grid item>Editar</Grid>
                                                    </Grid>
                                                </Typography>
                                                {/* <div id='clientDetailsDeleteClient' onClick={()=>handleDeleteClient()}>
                                                    <FiTrash2 />
                                                    <a>Excluir cliente</a>
                                                </div> */}
                                            </>
                                        ) :
                                        (<></>)
                                }
                                {
                                    clientIsUpdated ?
                                        (<label className='clientDetailsStatusClient'><a>Status: Ativo</a></label>) :
                                        (
                                            <>
                                                <label className={inEdit.checkedA ? 'clientsBoxHideFields' : 'clientDetailsStatusClient'}>
                                                    <a>Status:</a>
                                                    <a>
                                                        {titleize(statusThisClient?.description)}
                                                    </a>
                                                </label>
                                            </>
                                        )
                                }
                            </div>
                            <div className='clientsDetailDualDetail'>
                                <div id='relative' className={inEdit.checkedA ? 'clientsBoxHideFields' : 'clientsInformationDiv'}>
                                    <a id='clientDetailFirstAElement'>Nome: </a>
                                    <label id='clientsDetailLabelElipsis' title={titleize(clientData[0]?.name)}>
                                        <a>{titleize(clientData[0]?.name)}</a>
                                    </label>
                                </div>

                                <div id='relative' className={inEdit.checkedA ? 'clientsBoxShowFields' : 'clientsBoxHideFields'}>
                                    <input style={{ border: errorFields['selectedClientName'] && !selectedClientName?.trim()?.length ? '1px solid var(--errorFields)' : '' }} maxLength={200} onKeyPress={(e) => { if (e.code === 'Backquote') e.preventDefault() }} className='dinamic' value={selectedClientName?.length > 0 ? titleize(selectedClientName) : ''} onChange={(e) => setSelectedClientName(e.target.value)}></input>
                                    <label id='labelDinamic'>Nome</label>
                                </div>

                                <div id='relative' className={inEdit.checkedA ? 'clientsBoxHideFields' : 'clientsInformationDiv'}>
                                    <a id='clientDetailFirstAElement'>Perfil: </a><a>{titleize(clientData[0]?.client_profile)}</a>
                                </div>

                                <div id='relative' className={inEdit.checkedA ? 'clientsBoxShowFields' : 'clientsBoxHideFields'}>
                                    <input style={{ border: errorFields['selectedClientProfile'] && !selectedClientProfile.trim()?.length ? '1px solid var(--errorFields)' : '' }} maxLength={200} onKeyPress={(e) => { if (e.code === 'Backquote') e.preventDefault() }} className='dinamic' value={selectedClientProfile?.length > 0 ? titleize(selectedClientProfile) : ''} onChange={(e) => setSelectedClientProfile(e.target.value)}></input>
                                    <label id='labelDinamic'>Perfil</label>
                                </div>

                                <div id='relative' className={inEdit.checkedA ? 'clientsBoxHideFields' : 'clientsInformationDiv'}>
                                    <a id='clientDetailFirstAElement'>Opera sozinho: </a><a>{titleize(clientData[0]?.operates_alone)}</a>
                                </div>

                                <div id='relative' className={inEdit.checkedA ? 'clientsBoxShowFields' : 'clientsBoxHideFields'}>
                                    <input onKeyPress={(e) => { if (e.code === 'Backquote') e.preventDefault() }} className='dinamic' value={selectedOperatesAlone?.length > 0 ? titleize(selectedOperatesAlone) : ''} onChange={(e) => setSelectedClientOperatesAlone(e.target.value)}></input>
                                    <label id='labelDinamic'>Opera sozinho</label>
                                </div>
                            </div>
                            <div className='clientsDetailDualDetail'>
                                <div id='relative' className={inEdit.checkedA ? 'clientsBoxHideFields' : 'clientsInformationDiv'}>
                                    <a id='clientDetailFirstAElement'>Net Modalidades: </a><a>{numberBRLFormat(clientData[0]?.net_stock)}</a>
                                </div>
                                <div id='relative' className={inEdit.checkedA ? 'clientsBoxShowFields' : 'clientsBoxHideFields'}>
                                    <CurrencyTextField
                                        label="Net Modalidades"
                                        variant="outlined"
                                        value={selectedNetStock}
                                        currencySymbol="R$"
                                        className={classes.styleForClients}
                                        outputFormat="number"
                                        decimalCharacter=","
                                        digitGroupSeparator="."
                                        onChange={(event, value) => setSelectedNetStock(value)}
                                    />
                                </div>
                                <div id='relative' className={inEdit.checkedA ? 'clientsBoxHideFields' : 'clientsInformationDiv'}>
                                    <a id='clientDetailFirstAElement'>Net Total: </a><a>{numberBRLFormat(clientData[0]?.net_total)}</a>
                                </div>
                                <div id='relative' className={inEdit.checkedA ? 'clientsBoxShowFields' : 'clientsBoxHideFields'}>
                                    <CurrencyTextField
                                        label="Net Total"
                                        variant="outlined"
                                        value={selectedNetTotal}
                                        currencySymbol="R$"
                                        className={classes.styleForClients}
                                        outputFormat="number"
                                        decimalCharacter=","
                                        digitGroupSeparator="."
                                        onChange={(event, value) => setSelectedNetTotal(value)}
                                    />
                                </div>
                                <div id='relative' className={!inEdit.checkedA ? 'clientsBoxHideFields' : 'clientsBoxShowStatusField'}>
                                    <Autocomplete
                                        options={statusList}
                                        value={selectedStatus}
                                        onChange={(event, newValue) => {
                                            setSelectedStatus(newValue);
                                            setStatusUpdatedToActive(true);
                                            setClientStatusUpdated(true);
                                        }}
                                        getOptionLabel={(option) => option?.id + " - " + titleize(option?.description)}
                                        id='advisorLink'
                                        renderInput={(params) => <TextField
                                            label='Status'
                                            onKeyPress={(e) => { if (e.code === 'Backquote') e.preventDefault() }}
                                            className={classes.styleForClients}
                                            {...params} variant="outlined" />}
                                    />
                                </div>
                            </div>
                            <div className={inEdit.checkedA ? 'clientDetailsTableDetailsGray' : 'clientDetailsTableDetails'}>
                                {
                                    inEdit.checkedA ?
                                        loadingTable ?
                                            (
                                                <div className='userDetailsLoadingTable'>
                                                    <ArrowLoader />
                                                </div>
                                            ) :
                                            (<>
                                                <div className='clientDetailsEditLinks'>
                                                    {
                                                        clientData[0]?.active === 1 || clientIsUpdated ?
                                                            (
                                                                <>
                                                                    <div className='clientsDetailsEditFixedBody'>
                                                                        <label>
                                                                            <Autocomplete
                                                                                options={advisorList}
                                                                                value={selectedAdvisor}
                                                                                onChange={(event, newValue) => {
                                                                                    setSelectedAdvisor(newValue);
                                                                                }}
                                                                                getOptionLabel={(option) => option?.external_id + " - " + titleize(option?.full_name)}
                                                                                id='advisorLink'
                                                                                renderInput={(params) => <TextField
                                                                                    label='Assessor'
                                                                                    onKeyPress={(e) => { if (e.code === 'Backquote') e.preventDefault() }}
                                                                                    className={classes.styleForClients}
                                                                                    sx={{ width: 200 }}
                                                                                    {...params} variant="outlined" />}
                                                                            />
                                                                        </label>
                                                                    </div>

                                                                    <div className='clientsDetailsEditFixedBody'>
                                                                        <label>
                                                                            <Autocomplete
                                                                                options={brokerList}
                                                                                value={selectedBroker}
                                                                                onChange={(event, newValue) => {
                                                                                    setSelectedBroker(newValue);
                                                                                }}
                                                                                getOptionLabel={(option) => option?.external_id + " - " + titleize(option?.full_name)}
                                                                                id='advisorLink'
                                                                                renderInput={(params) => <TextField
                                                                                    label='Broker'
                                                                                    onKeyPress={(e) => { if (e.code === 'Backquote') e.preventDefault() }}
                                                                                    className={classes.styleForClients}

                                                                                    {...params} variant="outlined" />}
                                                                            />
                                                                        </label>
                                                                    </div>
                                                                    {/* <div className='usersDetailAddBrokerOption'>
                                                                        {profileList.map((profile, index) => {
                                                                            const brokerOptions = brokerList.filter((permittedBroker) => {
                                                                                if (profile?.profile_id === permittedBroker.profile_id) { return permittedBroker }
                                                                            })
                                                                            selectedBrokers.filter((broker) => {
                                                                                if (profile?.profile_id === broker?.id);
                                                                            })
                                                                            const brokersLinked = listBrokers.filter((linkedBroker) => {
                                                                                if (profile?.profile_id === linkedBroker.profile_id) { return linkedBroker }
                                                                            })

                                                                            return (
                                                                                inEditProfileBroker?.filter((profileToEdit) => { if (profileToEdit === profile?.profile_id) return profileToEdit })[0] === profile?.profile_id ? (
                                                                                    <div id='labelToEditClientBroker'>
                                                                                        <div id='alignLabelForEditBroker'>
                                                                                            <a>Broker {profile.profile_description}</a>
                                                                                            <label>
                                                                                                <Autocomplete
                                                                                                    options={brokerOptions}
                                                                                                    value={selectedBrokers?.filter((brokerValue) => { if (profile?.profile_id === brokerValue?.profile_id) return brokerValue })?.full_name}
                                                                                                    onChange={(event, newValue) => {
                                                                                                        setNewBrokerListToAPI(newValue, index, profile?.profile_id);
                                                                                                    }}
                                                                                                    getOptionLabel={(option) => option?.external_id + " - " + titleize(option?.full_name)}
                                                                                                    getOptionSelected={(option, val) => option?.external_id === val?.external_id}
                                                                                                    id='brokerLink'
                                                                                                    renderInput={(params) => <TextField
                                                                                                        label='Selecione'
                                                                                                        onKeyPress={(e) => { if (e.code === 'Backquote') e.preventDefault() }}
                                                                                                        className={classes.styleForClients}
                                                                                                        {...params} variant="outlined" />}
                                                                                                />
                                                                                            </label>
                                                                                        </div>
                                                                                        <FiX cursor='pointer' color='red' onClick={() => {
                                                                                            inEditProfileBroker.map((profileIDInList, indexForDisable) => {
                                                                                                if (profile?.profile_id === profileIDInList) {
                                                                                                    inEditProfileBroker.splice(indexForDisable, 1);
                                                                                                    if (inEditProfileBroker.length < 1) {
                                                                                                        setInEditProfileBroker([])
                                                                                                    }
                                                                                                    else {
                                                                                                        setInEditProfileBroker([...inEditProfileBroker]);
                                                                                                    }
                                                                                                }
                                                                                            });
                                                                                        }} />
                                                                                    </div>
                                                                                ) :
                                                                                    (
                                                                                        <div className='usersDetailStaticProfileDescription'>
                                                                                            <label for='brokerName'>Broker {profile.profile_description}</label>
                                                                                            <div id='editClientBrokerDetail'>
                                                                                                <a id='brokerName'>{brokerRemoved.indexOf(profile?.profile_id) === 1 ? 'Vazio' : brokersLinked[0]?.full_name !== undefined ? titleize(brokersLinked[0]?.full_name) : 'Vazio'}</a>
                                                                                                <span>
                                                                                                    <FiTrash2 cursor='pointer' onClick={() => {
                                                                                                        setBrokerRemoved([...brokerRemoved, profile?.profile_id]);
                                                                                                        setNewBrokerListToAPI(null, index, profile?.profile_id)
                                                                                                    }
                                                                                                    } />
                                                                                                    <FiEdit cursor='pointer' onClick={() => { setInEditProfileBroker([...inEditProfileBroker, profile?.profile_id]) }} />
                                                                                                </span>
                                                                                            </div>
                                                                                        </div>
                                                                                    )
                                                                            )
                                                                        })}
                                                                    </div> */}
                                                                    <button onClick={() => handleSubmit()} className='buttonWhiteBg'>Confirmar</button>
                                                                </>
                                                            ) :
                                                            (
                                                                <div id='clientDetailsAlterStatus'>
                                                                    <a>Cliente com status diferente de Ativo</a>
                                                                    <button id='clientDetailsReactivate'
                                                                        onClick={() => handleActivateClient()}
                                                                    >Ativar Cliente</button>
                                                                </div>
                                                            )
                                                    }


                                                </div>
                                            </>
                                            ) : (
                                            <>
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th colSpan='2'>Assessor</th>
                                                        </tr>
                                                        <tr>
                                                            <th>Código</th>
                                                            <th>Assessor</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            clientData?.length > 0 ? (
                                                                clientData?.map((advisorData) => {
                                                                    return (
                                                                        <tr>
                                                                            <td>{advisorData?.advisor_external_id}</td>
                                                                            <td>{titleize(advisorData?.advisor_name)}</td>
                                                                        </tr>
                                                                    )
                                                                })
                                                            ) : (
                                                                <tr>
                                                                    <td colSpan='2'>Não há assessores vinculados</td>
                                                                </tr>
                                                            )

                                                        }
                                                    </tbody>
                                                </table>
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th colSpan='4'>Lista de Brokers</th>
                                                        </tr>
                                                        <tr>
                                                            <th>Código</th>
                                                            <th>Broker</th>
                                                            <th>Perfil</th>
                                                            <th>Indicado</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            listBrokers?.length > 0 & !isNaN(type?.value?.broker[0]?.broker_id) ? (
                                                                listBrokers?.map((brokerRow) => {
                                                                    return (
                                                                        <tr>
                                                                            <td>{brokerRow?.broker_id}</td>
                                                                            <td>{titleize(brokerRow?.full_name)}</td>
                                                                            <td>{titleize(brokerRow?.profile_description)}</td>
                                                                            <td>Sim</td>
                                                                        </tr>
                                                                    )
                                                                })
                                                            ) : (
                                                                <tr>
                                                                    <td colSpan='4'> Não há brokers vinculados</td>
                                                                </tr>
                                                            )
                                                        }
                                                    </tbody>
                                                </table>
                                            </>
                                        )
                                }
                            </div>
                        </div>
                    </>)
            }
        </div>
    )
}