import React, { useState, Component, useEffect } from "react";
import { Autocomplete } from "@material-ui/lab";
import Modal from "../../../../components/modal/modal";
import { TextField } from "@material-ui/core";
import { ArrowLoader } from "../../../../components/loaders/arrowLoader";
import CurrencyTextField from "@unicef/material-ui-currency-textfield";
import { makeStyles } from "@material-ui/core/styles";
import { useModal } from "../../../../contexts/useModal";
import { Link, useLocation, useHistory, Route } from "react-router-dom";
import { api } from "../../../../services/api";
import { useAuth } from "../../../../contexts/useAuth";

import { ConfirmationBox } from "../../../../components/confirmationBox/confirmationBox";
import { HiArrowLeft } from "react-icons/hi";
import { FiMinus, FiPlus } from "react-icons/fi";
import { BiSave } from "react-icons/bi";
import { MdDoneOutline } from "react-icons/md";
import "../styles/formToReply.css";

export function FormToReply(props) {
    const [loading, setLoading] = useState(false);
    const [saving, setSaving] = useState(false);
    const { tokenKey, userType, user } = useAuth();
    const { setType, setShow } = useModal();
    const [strutureJson, setStrutureJson] = useState();
    const [refresh, setRefresh] = useState(0);
    const { type } = useModal();

    const [tituloPortfolio, setTituloPortfolio] = useState("");
    const [chooseTitle, setChooseTitle] = useState(true);
    const [idFormReply, setIdFormReply] = useState(null);
    const [idPortfolio, setIdPortfolio] = useState(null);
    const [idReply, setIdReply] = useState(null);
    const [finished, setFinished] = useState(true);
    const history = useHistory();

    const useStyles = makeStyles((theme) => ({
        title: {
            color: strutureJson?.profile === 3 ? "#270000" : strutureJson?.profile === 2 ? "#302700" : strutureJson?.profile === 1 ? "#00323f" : "#3a3a3a",
            fontSize: "1.5rem",
            fontWeight: "400",
            textAlign: "center",
            height: "fit-content",
            width: "fit-content",
            margin: "auto",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            paddingLeft: "2rem",
            paddingRight: "2rem",
            maxWidth: "100%",
        },

        profile: {
            color: strutureJson?.profile === 3 ? "#270000" : strutureJson?.profile === 2 ? "#302700" : strutureJson?.profile === 1 ? "#00323f" : "#3a3a3a",
            marginLeft: "1rem",
        },

        sectionClosed: {
            margin: "1rem 1rem 1rem 1rem",
            backgroundColor: "#E4E4E4",
            fontSize: " 1.5rem",
            fontWeight: "400",
            textAlign: "center",
            border: "5px",
            borderStyle: "none solid none solid",
            // borderColor: strutureJson?.profile === 3 ? '#FF0000' : strutureJson?.profile === 2 ? '#ffae00' : '#11639E',
            borderColor: strutureJson?.profile === 3 ? "#FF0000" : strutureJson?.profile === 2 ? "#ffae00" : strutureJson?.profile === 1 ? "#11639E" : "#3a3a3a",
            height: "3rem",
            display: "grid",
            gridTemplateColumns: "3rem auto 3rem",
            "&:hover": {
                backgroundColor: "#e9e9e9",
                cursor: "pointer",
            },
        },

        boxQuestion: {
            color: strutureJson?.profile === 3 ? "#270000" : strutureJson?.profile === 2 ? "#302700" : strutureJson?.profile === 1 ? "#00323f" : "#3a3a3a",
            borderColor: strutureJson?.profile === 3 ? "#FF0000" : strutureJson?.profile === 2 ? "#ffae00" : strutureJson?.profile === 1 ? "#00323f" : "#ebebeb",
            borderStyle: "solid none none none ",
            fontSize: " 2rem",
            fontWeight: "400",
            backgroundColor: "#E4E4E4",
            margin: "1rem 5rem 1rem 5rem",
        },
        multipleAnswerBox: {
            marginLeft: "1rem",
            fontSize: " 1.5rem",
            fontWeight: "400",
            color: "black",
        },
        separacaoOpcoes: {
            width: "4px",
            height: "80%",
            // backgroundColor: strutureJson?.profile === 3 ? '#FF0000' : strutureJson?.profile === 2 ? '#ffae00' : '#11639E',
            backgroundColor: strutureJson?.profile === 3 ? "#FF0000" : strutureJson?.profile === 2 ? "#ffae00" : strutureJson?.profile === 1 ? "#11639E" : "#3a3a3a",

            margin: "auto",
            marginRight: "0.3rem",
        },
        inputTextAnswer: {
            overflowY: "auto",
            height: "1.8rem",
            width: "80%",
            margin: "auto",
            marginLeft: "0",
            marginRight: "0",
            fontSize: "1.2rem",
            paddingTop: "0.3rem",
            paddingLeft: "0.5rem",
            paddingRight: "0.5rem",
            backgroundColor: "#D9D9D9",
            border: "none",
            borderRadius: "5px",
            borderBottom: "2px solid",
            borderColor: strutureJson?.profile === 3 ? "#FF0000" : strutureJson?.profile === 2 ? "#ffae00" : strutureJson?.profile === 1 ? "#00323f" : "#ebebeb",
            "&::placeholder": {
                color: "#5F5F5F",
                textAlign: "left",
            },
            "&:focus": {
                outline: "none",
                borderBottom: "4px solid",
                borderColor: strutureJson?.profile === 3 ? "#FF0000" : strutureJson?.profile === 2 ? "#ffae00" : strutureJson?.profile === 1 ? "#00323f" : "#ebebeb",
            },
        },
        inputMonetaryAnswer: {
            overflowY: "auto",
            height: "fit-content",
            width: "16rem",
            margin: "auto",
            marginLeft: "0",
            marginRight: "0",
            fontSize: "1.2rem",
            backgroundColor: "#D9D9D9",
            border: "none",
            borderRadius: "5px",
            borderBottom: "2px solid",
            borderColor: strutureJson?.profile === 3 ? "#FF0000" : strutureJson?.profile === 2 ? "#ffae00" : strutureJson?.profile === 1 ? "#00323f" : "#ebebeb",
            "&:focus": {
                outline: "none",
                borderColor: strutureJson?.profile === 3 ? "#FF0000" : strutureJson?.profile === 2 ? "#ffae00" : strutureJson?.profile === 1 ? "#00323f" : "#ebebeb",
            },
        },
        inputPercentageAnswer: {
            overflowY: "auto",
            height: "fit-content",
            width: "16rem",
            margin: "auto",
            marginLeft: "0",
            marginRight: "0",
            fontSize: "1.2rem",
            backgroundColor: "#D9D9D9",
            border: "none",
            borderRadius: "5px",
            borderBottom: "2px solid",
            borderColor: strutureJson?.profile === 3 ? "#FF0000" : strutureJson?.profile === 2 ? "#ffae00" : strutureJson?.profile === 1 ? "#00323f" : "#ebebeb",
            "&:focus": {
                outline: "none",
                borderColor: strutureJson?.profile === 3 ? "#FF0000" : strutureJson?.profile === 2 ? "#ffae00" : strutureJson?.profile === 1 ? "#00323f" : "#ebebeb",
            },
        },
    }));

    const classes = useStyles();

    useEffect(() => {
        if (props?.id?.id !== undefined) sessionStorage.setItem("idPortfolio", props.id.id);
        if (props?.id?.pending_id !== undefined) sessionStorage.setItem("idRespostas", props.id.pending_id);
        const idAtual = sessionStorage.getItem("idPortfolio");
        setIdPortfolio(idAtual);
        const idRespostas = sessionStorage.getItem("idRespostas");
        if (idRespostas !== null) {
            setIdReply(idRespostas);
            setChooseTitle(false);
            getJson(idRespostas, idAtual);
        }
    }, []);

    async function autoSave(isObservation, idQuestion, answer) {
        setSaving(true);

        try {
            await api.post(
                `/api/v1/forms/${idReply}`,
                {
                    question_id: idQuestion,
                    answer: isObservation ? undefined : answer,
                    observation: isObservation ? answer : undefined,
                },
                {
                    headers: {
                        authorization: `Bearer ${tokenKey}`,
                    },
                },
                {}
            );
            setSaving(false);
        } catch (error) {
            setLoading(false);
            setType({ name: "alertMsg", type: "information", value: { message: "Erro ao salvar os dados do formulário" } });
            setShow(true);
        }
    }

    async function getJson(idRespostas, idAtual) {
        setLoading(true);
        try {
            const json = await api.get(`/api/v1/forms/detailed/${idAtual}/${idRespostas}`, {
                headers: {
                    authorization: `Bearer ${tokenKey}`,
                },
            });
            json?.data?.data?.sections.map((section) => {
                section.isClose = true;
            });
            // se finalizado, impede edições nas respostas
            if (json.data.data.session_pending === true) setFinished(false);
            setStrutureJson(json?.data?.data);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            setType({ name: "alertMsg", type: "information", value: { message: "Erro ao buscar os dados do formulário" } });
            setShow(true);
        }
    }

    async function confirmFormReplyTitle() {
        if (tituloPortfolio != null && tituloPortfolio !== "") {
            setLoading(true);
            setChooseTitle(false);
            try {
                const idFormReplyData = await api.post(
                    `/api/v1/forms/use/${idPortfolio}`,
                    {
                        description: tituloPortfolio,
                    },
                    {
                        headers: {
                            authorization: `Bearer ${tokenKey}`,
                        },
                    }
                );
                sessionStorage.setItem("idRespostas", idFormReplyData?.data?.data[0]?.id);
                setIdReply(idFormReplyData?.data?.data[0]?.id);
                getJson(idFormReplyData?.data?.data[0]?.id, idPortfolio);
                setLoading(false);
            } catch (error) {
                setType({ name: "alertMsg", type: "information", value: { message: "Erro ao buscar os detalhes dos portfólios" } });
                setShow(true);
            }
        } else {
            alert("Preencha o título do portfólio antes de confirmar!");
        }
    }

    async function finishAndSend() {
        setLoading(true);
        try {
            // console.log('idReply',idReply)
            await api.post(
                `/api/v1/forms/finish/${idReply}`,
                {},
                {
                    headers: {
                        authorization: `Bearer ${tokenKey}`,
                    },
                }
            );
            setLoading(false);
            history.push("/myForms");
        } catch (error) {
            setType({ name: "alertMsg", type: "information", value: { message: "Erro ao finalizar sessão de respostas" } });
            setShow(true);
        }
    }

    async function handleRemoveHistoryForms() {
        setLoading(true);
        try {
            await api.delete(
                `api/v1/forms/removeHistoryForms/${strutureJson.session_id}`,
                {
                    headers: {
                        authorization: `Bearer ${tokenKey}`,
                    },
                }
            );
            setShow(false);
            setLoading(false);
            history.push("/myForms");
        } catch (error) {
            setType({ name: "alertMsg", type: "information", value: { message: "Erro ao excluir histórico de formulário." } });
            setShow(true);
        }
    }

    return (
        <><Modal>
            {type.name === "alertMsg" ? (
                <ConfirmationBox msgData={type} />
            ) : <></> }
            </Modal>

        <div className="containerGeralCreateFormStructure">
            {chooseTitle ? (
                <div className="containerOverlay">
                    <div className="containerEscolhaTitulo">
                        <div className="tituloEscolhaTitulo">Insira o nome do cliente para identificação</div>
                        <input
                            placeholder="Nome do cliente"
                            onBlur={(e) => setTituloPortfolio(e.target.value)}
                            onFocus={(e) => {
                                setTituloPortfolio(null);
                            }}
                            value={tituloPortfolio}
                            type="text"
                            className="inputReplyTitle"
                        />
                        <button
                            className="confirmReplyTitle"
                            onClick={() => {
                                confirmFormReplyTitle();
                            }}>
                            CONFIRMAR
                        </button>
                    </div>
                </div>
            ) : (
                <>
                    {/* Cabeçalho */}
                    <div className="containerCabecalho">
                        <button onClick={() => history.goBack()} className="backArrow">
                            <HiArrowLeft size="30px" color="#141414" />
                        </button>
                        <div className={classes.title} title={strutureJson?.title + ": " + strutureJson?.description}>
                            {/* <button className="saveButton" title="Pressione para salvar o progresso atual."><BiSave size={30} /> <div className="saveProgressTitle">Guardar progresso</div></button>  */}
                            {strutureJson?.title}: {strutureJson?.description}
                        </div>
                        <div className="boxTituloPerfil">
                            <div className="tituloPerfil">Perfil:</div>
                            <div className={classes.profile}>{strutureJson?.profile === 1 ? "Conservador" : strutureJson?.profile === 2 ? "Moderado" : strutureJson?.profile === 3 ? "Agressivo" : "Geral"}</div>
                        </div>
                        <div></div>
                        <div className={classes.title} title={"Cliente: " + strutureJson?.session_description}>
                            Cliente: {strutureJson?.session_description}
                        </div>
                        <div></div>
                        <div></div>
                        <div className={classes.title} title={"Assessor: " + strutureJson?.session_owner_id + " - " + strutureJson?.session_owner}>
                            Assessor: {strutureJson?.session_owner_id} - {strutureJson?.session_owner}
                        </div>
                        {!strutureJson?.session_pending ? 
                            <div style={{textAlign: 'center'}}>
                                <button className="resetFilter" onClick={() => setFinished(!finished)}>{finished ? 'Editar' : 'Concluir'}</button>
                                <button className="resetFilter" style={{marginLeft: '0.6rem'}} onClick={
                                    () => {
                                        setType({ name: 'alertMsg', value: { message: 'Esta ação é irreversível. Você realmente deseja excluir este formulário?'
                                        , func: handleRemoveHistoryForms
                                        , dataForFunc: strutureJson.session_id
                                    } });
                                        setShow(true)
                                    }
                                    }>Excluir
                                </button>
                            </div> 
                            : 
                            <div style={{textAlign: 'center'}}>
                                <button className="resetFilter" style={{marginLeft: '0.6rem'}} onClick={
                                    () => {
                                        setType({ name: 'alertMsg', value: { message: 'Esta ação é irreversível. Você realmente deseja excluir este formulário?'
                                        , func: handleRemoveHistoryForms
                                        , dataForFunc: strutureJson.session_id
                                    } });
                                        setShow(true)
                                    }
                                    }>Excluir
                                </button>
                            </div>
                            }
                        {/* <button onClick={() => {console.log(strutureJson)}}>Mostrar json</button> */}
                    </div>

                    {/* Seções */}
                    {loading ? (
                        <div className="loaderAllForms">
                            <ArrowLoader style={{ marginBottom: "5rem" }} />
                        </div>
                    ) : (
                        <div className="boxAnswers">
                            {strutureJson?.sections.map((section, index) => {
                                return (
                                    <>
                                        <div
                                            key={index}
                                            className={classes.sectionClosed}
                                            onClick={() => {
                                                strutureJson.sections[index].isClose = !strutureJson.sections[index].isClose;
                                                setRefresh(refresh + 1);
                                            }}>
                                            <div></div>
                                            <div className="sectionTitle">
                                                <p>{parseInt(index + 1) + ' ' + section?.title}</p>
                                            </div>
                                            <div className="buttonOpenCloseSection">{strutureJson.sections[index].isClose ? <FiPlus size="30px" /> : <FiMinus size="30px" />}</div>
                                        </div>
                                        {section?.questions.map((question, indexq) => {
                                            if (!section?.isClose) {
                                                // NÍVEL 1 ////////////////////////////////////////////
                                                
                                                return (
                                                    <>
                                                        <div key={indexq} className={classes.boxQuestion}>
                                                            <div className="questionDescription">{(index+ 1) + '.' + (indexq + 1) + ' ' + question?.description}</div>
                                                            <div className="gridteste">
                                                                <div className={classes.separacaoOpcoes}></div>
                                                                <div className="opcoesDaPergunta">
                                                                    {question?.type === 3 ? (
                                                                        question?.options.map((options, indexO) => {
                                                                            return (
                                                                                <>
                                                                                    <div className={classes.multipleAnswerBox} key={indexO}>
                                                                                        <input
                                                                                            disabled={finished ?? "disabled"}
                                                                                            type="checkbox"
                                                                                            id="check"
                                                                                            name="check"
                                                                                            defaultChecked={question.answer.indexOf(question.options[indexO].id) !== -1 ?? "checked"}
                                                                                            className="checkbox"
                                                                                            onChange={(e) => {
                                                                                                if (question.answer.indexOf(question.options[indexO].id) === -1) question.answer.push(question.options[indexO].id);
                                                                                                else question.answer.splice(question.answer.indexOf(question.options[indexO].id), 1);
                                                                                                question.options[indexO].answer = e.target.checked;
                                                                                                setRefresh(refresh + 1);
                                                                                                autoSave(false, question.id, question.answer);
                                                                                            }}></input>
                                                                                        {options?.description}
                                                                                        {/* Se a opção estiver marcada, abre as possiveis subperguntas */}

                                                                                        {options?.questions?.length > 0 && question.answer.indexOf(question.options[indexO].id) !== -1 ? (
                                                                                            options?.questions?.map((question2, indexq2) => {
                                                                                                // NIVEL 2 //////////////////////////
                                                                                                return (
                                                                                                    <>
                                                                                                        <div key={indexq2} className={classes.boxQuestion}>
                                                                                                            <div className="questionDescription">{question2?.description}</div>
                                                                                                            <div className="gridteste">
                                                                                                                <div className={classes.separacaoOpcoes}></div>
                                                                                                                <div className="opcoesDaPergunta">
                                                                                                                    {question2?.type === 3 ? (
                                                                                                                        question2?.options.map((options2, indexO2) => {
                                                                                                                            return (
                                                                                                                                <>
                                                                                                                                    <div className={classes.multipleAnswerBox} key={indexO2}>
                                                                                                                                        <input
                                                                                                                                            disabled={finished ?? "disabled"}
                                                                                                                                            type="checkbox"
                                                                                                                                            id="check"
                                                                                                                                            name="check"
                                                                                                                                            defaultChecked={question2.answer.indexOf(question2.options[indexO2].id) !== -1 ?? "checked"}
                                                                                                                                            className="checkbox"
                                                                                                                                            onChange={(e) => {
                                                                                                                                                if (question2.answer.indexOf(question2.options[indexO2].id) === -1) question2.answer.push(question2.options[indexO2].id);
                                                                                                                                                else question2.answer.splice(question2.answer.indexOf(question2.options[indexO2].id), 1);
                                                                                                                                                question2.options[indexO2].answer = e.target.checked;
                                                                                                                                                setRefresh(refresh + 1);
                                                                                                                                                autoSave(false, question2.id, question2.answer);
                                                                                                                                            }}></input>
                                                                                                                                        {options2?.description}
                                                                                                                                        {/* Se a opção estiver marcada, abre as possiveis subperguntas */}

                                                                                                                                        {options2?.questions?.length > 0 && question2.answer.indexOf(question2.options[indexO2].id) !== -1 ? (
                                                                                                                                            options2?.questions?.map((question3, indexq3) => {
                                                                                                                                                // NIVEL 3 //////////////////////////
                                                                                                                                                return (
                                                                                                                                                    <>
                                                                                                                                                        <div key={indexq3} className={classes.boxQuestion}>
                                                                                                                                                            <div className="questionDescription">{question3?.description}</div>
                                                                                                                                                            <div className="gridteste">
                                                                                                                                                                <div className={classes.separacaoOpcoes}></div>
                                                                                                                                                                <div className="opcoesDaPergunta">
                                                                                                                                                                    {question3?.type === 3 ? (
                                                                                                                                                                        question3?.options.map((options3, indexO3) => {
                                                                                                                                                                            return (
                                                                                                                                                                                <>
                                                                                                                                                                                    <div className={classes.multipleAnswerBox} key={indexO3}>
                                                                                                                                                                                        <input
                                                                                                                                                                                            disabled={finished ?? "disabled"}
                                                                                                                                                                                            type="checkbox"
                                                                                                                                                                                            id="check"
                                                                                                                                                                                            name="check"
                                                                                                                                                                                            defaultChecked={question3.answer.indexOf(question3.options[indexO3].id) !== -1 ?? "checked"}
                                                                                                                                                                                            className="checkbox"
                                                                                                                                                                                            onChange={(e) => {
                                                                                                                                                                                                if (question3.answer.indexOf(question3.options[indexO3].id) === -1) question3.answer.push(question3.options[indexO3].id);
                                                                                                                                                                                                else question3.answer.splice(question3.answer.indexOf(question3.options[indexO3].id), 1);
                                                                                                                                                                                                question3.options[indexO3].answer = e.target.checked;
                                                                                                                                                                                                setRefresh(refresh + 1);
                                                                                                                                                                                                autoSave(false, question3.id, question3.answer);
                                                                                                                                                                                            }}></input>
                                                                                                                                                                                        {options3?.description}
                                                                                                                                                                                        {/* Se a opção estiver marcada, abre as possiveis subperguntas */}

                                                                                                                                                                                        {options3?.questions?.length > 0 && question3.answer.indexOf(question3.options[indexO3].id) !== -1 ? (
                                                                                                                                                                                            options3?.questions?.map((question4, indexq4) => {
                                                                                                                                                                                                // NIVEL 4 //////////////////////////
                                                                                                                                                                                                return (
                                                                                                                                                                                                    <>
                                                                                                                                                                                                        <div key={indexq4} className={classes.boxQuestion}>
                                                                                                                                                                                                            <div className="questionDescription">{question4?.description}</div>
                                                                                                                                                                                                            <div className="gridteste">
                                                                                                                                                                                                                <div className={classes.separacaoOpcoes}></div>
                                                                                                                                                                                                                <div className="opcoesDaPergunta">
                                                                                                                                                                                                                    {question4?.type === 3 ? (
                                                                                                                                                                                                                        question4?.options.map((options4, indexO4) => {
                                                                                                                                                                                                                            return (
                                                                                                                                                                                                                                <>
                                                                                                                                                                                                                                    <div className={classes.multipleAnswerBox} key={indexO4}>
                                                                                                                                                                                                                                        <input
                                                                                                                                                                                                                                            disabled={finished ?? "disabled"}
                                                                                                                                                                                                                                            type="checkbox"
                                                                                                                                                                                                                                            id="check"
                                                                                                                                                                                                                                            name="check"
                                                                                                                                                                                                                                            defaultChecked={question4.answer.indexOf(question4.options[indexO4].id) !== -1 ?? "checked"}
                                                                                                                                                                                                                                            className="checkbox"
                                                                                                                                                                                                                                            onChange={(e) => {
                                                                                                                                                                                                                                                if (question4.answer.indexOf(question4.options[indexO4].id) === -1) question4.answer.push(question4.options[indexO4].id);
                                                                                                                                                                                                                                                else question4.answer.splice(question4.answer.indexOf(question4.options[indexO4].id), 1);
                                                                                                                                                                                                                                                question4.options[indexO4].answer = e.target.checked;
                                                                                                                                                                                                                                                setRefresh(refresh + 1);
                                                                                                                                                                                                                                                autoSave(false, question4.id, question4.answer);
                                                                                                                                                                                                                                            }}></input>
                                                                                                                                                                                                                                        {options4?.description}
                                                                                                                                                                                                                                    </div>
                                                                                                                                                                                                                                </>
                                                                                                                                                                                                                            );
                                                                                                                                                                                                                        })
                                                                                                                                                                                                                    ) : question4?.type === 1 ? (
                                                                                                                                                                                                                        <textarea
                                                                                                                                                                                                                            type="text"
                                                                                                                                                                                                                            id="answer"
                                                                                                                                                                                                                            name="answer"
                                                                                                                                                                                                                            placeholder="Digite sua resposta aqui"
                                                                                                                                                                                                                            disabled={finished ?? "disabled"}
                                                                                                                                                                                                                            className={classes.inputTextAnswer}
                                                                                                                                                                                                                            onChange={(e) => (question4.answer = e.target.value)}
                                                                                                                                                                                                                            defaultValue={question4.answer ?? question4.answer}
                                                                                                                                                                                                                            onBlur={() => autoSave(false, question4.id, question4.answer)}></textarea>
                                                                                                                                                                                                                    ) : question4?.type === 2 ? (
                                                                                                                                                                                                                        question4?.options.map((options4, indexO4) => {
                                                                                                                                                                                                                            return (
                                                                                                                                                                                                                                <div className={classes.multipleAnswerBox} key={indexO4}>
                                                                                                                                                                                                                                    <input
                                                                                                                                                                                                                                        disabled={finished ?? "disabled"}
                                                                                                                                                                                                                                        type="radio"
                                                                                                                                                                                                                                        id="radio4"
                                                                                                                                                                                                                                        name={question4.id}
                                                                                                                                                                                                                                        defaultChecked={question4.answer === options4.id ?? "checked"}
                                                                                                                                                                                                                                        className="radiobox"
                                                                                                                                                                                                                                        onClick={() => {
                                                                                                                                                                                                                                            question4.answer = options4.id;
                                                                                                                                                                                                                                            setRefresh(refresh + 1);
                                                                                                                                                                                                                                            autoSave(false, question4.id, question4.answer);
                                                                                                                                                                                                                                        }}></input>
                                                                                                                                                                                                                                    {options4?.description}
                                                                                                                                                                                                                                </div>
                                                                                                                                                                                                                            );
                                                                                                                                                                                                                        })
                                                                                                                                                                                                                    ) : question4?.type === 4 ? (
                                                                                                                                                                                                                        <CurrencyTextField
                                                                                                                                                                                                                            disabled={finished ?? "disabled"}
                                                                                                                                                                                                                            variant="outlined"
                                                                                                                                                                                                                            // value={0.00}
                                                                                                                                                                                                                            className={classes.inputMonetaryAnswer}
                                                                                                                                                                                                                            currencySymbol="R$"
                                                                                                                                                                                                                            outputFormat="number"
                                                                                                                                                                                                                            decimalCharacter=","
                                                                                                                                                                                                                            // required={true}
                                                                                                                                                                                                                            digitGroupSeparator="."
                                                                                                                                                                                                                            onChange={(e) => (question4.answer = e.target.value)}
                                                                                                                                                                                                                            defaultValue={question4.answer ?? question4.answer}
                                                                                                                                                                                                                            onBlur={() => autoSave(false, question4.id, question4.answer)}
                                                                                                                                                                                                                        />
                                                                                                                                                                                                                    ) : question4?.type === 5 ? (
                                                                                                                                                                                                                        <div className="containerTipoPerguntaPorcentagem">
                                                                                                                                                                                                                            <TextField
                                                                                                                                                                                                                                type="number"
                                                                                                                                                                                                                                onKeyPress={(e) => {
                                                                                                                                                                                                                                    if (e.code === "Backquote") e.preventDefault();
                                                                                                                                                                                                                                }}
                                                                                                                                                                                                                                step="0.00"
                                                                                                                                                                                                                                onKeyDown={(e) => {
                                                                                                                                                                                                                                    if (e.key === "e" || e.key === "E") {
                                                                                                                                                                                                                                        e.preventDefault();
                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                }}
                                                                                                                                                                                                                                variant="outlined"
                                                                                                                                                                                                                                onBlur={() => autoSave(false, question4.id, question4.answer)}
                                                                                                                                                                                                                                disabled={finished ?? "disabled"}
                                                                                                                                                                                                                                id="answer"
                                                                                                                                                                                                                                name="answer"
                                                                                                                                                                                                                                className={classes.inputPercentageAnswer}
                                                                                                                                                                                                                                onChange={(e) => (question4.answer = e.target.value.replace(",", "."))}
                                                                                                                                                                                                                                defaultValue={question4.answer ?? question4.answer}
                                                                                                                                                                                                                            />
                                                                                                                                                                                                                            <div className="porcentagem">%</div>
                                                                                                                                                                                                                        </div>
                                                                                                                                                                                                                    ) : question4?.type === 6 ? (
                                                                                                                                                                                                                        <div className="containerTipoPerguntaPorcentagem">
                                                                                                                                                                                                                            <TextField
                                                                                                                                                                                                                                type="number"
                                                                                                                                                                                                                                onKeyPress={(e) => {
                                                                                                                                                                                                                                    if (e.key === "." || e.key === ",") e.preventDefault();
                                                                                                                                                                                                                                }}
                                                                                                                                                                                                                                variant="outlined"
                                                                                                                                                                                                                                onBlur={() => autoSave(false, question4.id, question4.answer)}
                                                                                                                                                                                                                                disabled={finished ?? "disabled"}
                                                                                                                                                                                                                                id="answer"
                                                                                                                                                                                                                                name="answer"
                                                                                                                                                                                                                                className={classes.inputPercentageAnswer}
                                                                                                                                                                                                                                onChange={(e) => (question4.answer = e.target.value)}
                                                                                                                                                                                                                                defaultValue={question4.answer ?? question4.answer}
                                                                                                                                                                                                                            />
                                                                                                                                                                                                                        </div>
                                                                                                                                                                                                                    ) : question4?.type === 7 ? (
                                                                                                                                                                                                                        <div className="containerTipoPerguntaPorcentagem">
                                                                                                                                                                                                                            <TextField
                                                                                                                                                                                                                                type="number"
                                                                                                                                                                                                                                variant="outlined"
                                                                                                                                                                                                                                onBlur={() => autoSave(false, question4.id, question4.answer)}
                                                                                                                                                                                                                                disabled={finished ?? "disabled"}
                                                                                                                                                                                                                                id="answer"
                                                                                                                                                                                                                                name="answer"
                                                                                                                                                                                                                                className={classes.inputPercentageAnswer}
                                                                                                                                                                                                                                onChange={(e) => (question4.answer = e.target.value.replace(",", "."))}
                                                                                                                                                                                                                                defaultValue={question4.answer ?? question4.answer}
                                                                                                                                                                                                                            />
                                                                                                                                                                                                                        </div>
                                                                                                                                                                                                                    ) : (
                                                                                                                                                                                                                        <></>
                                                                                                                                                                                                                    )}
                                                                                                                                                                                                                </div>
                                                                                                                                                                                                            </div>
                                                                                                                                                                                                            {question4?.observation === true ? (
                                                                                                                                                                                                                <div className="observation">
                                                                                                                                                                                                                    <p className="observationText">Observações:</p>
                                                                                                                                                                                                                    <textarea
                                                                                                                                                                                                                        disabled={finished ?? "disabled"}
                                                                                                                                                                                                                        type="text"
                                                                                                                                                                                                                        id="obs"
                                                                                                                                                                                                                        name="obs"
                                                                                                                                                                                                                        placeholder="Observações aqui"
                                                                                                                                                                                                                        className={classes.inputTextAnswer}
                                                                                                                                                                                                                        onChange={(e) => (question4.answer_observation = e.target.value)}
                                                                                                                                                                                                                        defaultValue={question4.answer_observation ?? question4.answer_observation}
                                                                                                                                                                                                                        onBlur={() => autoSave(true, question4.id, question4.answer_observation)}></textarea>
                                                                                                                                                                                                                </div>
                                                                                                                                                                                                            ) : (
                                                                                                                                                                                                                <></>
                                                                                                                                                                                                            )}
                                                                                                                                                                                                        </div>
                                                                                                                                                                                                    </>
                                                                                                                                                                                                );
                                                                                                                                                                                            })
                                                                                                                                                                                        ) : (
                                                                                                                                                                                            <></>
                                                                                                                                                                                        )}
                                                                                                                                                                                    </div>
                                                                                                                                                                                </>
                                                                                                                                                                            );
                                                                                                                                                                        })
                                                                                                                                                                    ) : question3?.type === 1 ? (
                                                                                                                                                                        <textarea
                                                                                                                                                                            type="text"
                                                                                                                                                                            id="answer"
                                                                                                                                                                            name="answer"
                                                                                                                                                                            placeholder="Digite sua resposta aqui"
                                                                                                                                                                            disabled={finished ?? "disabled"}
                                                                                                                                                                            className={classes.inputTextAnswer}
                                                                                                                                                                            onChange={(e) => (question3.answer = e.target.value)}
                                                                                                                                                                            defaultValue={question3.answer ?? question3.answer}
                                                                                                                                                                            onBlur={() => autoSave(false, question3.id, question3.answer)}></textarea>
                                                                                                                                                                    ) : question3?.type === 2 ? (
                                                                                                                                                                        question3?.options.map((options3, indexO3) => {
                                                                                                                                                                            return (
                                                                                                                                                                                <div className={classes.multipleAnswerBox} key={indexO3}>
                                                                                                                                                                                    <input
                                                                                                                                                                                        disabled={finished ?? "disabled"}
                                                                                                                                                                                        type="radio"
                                                                                                                                                                                        id="radio3"
                                                                                                                                                                                        defaultChecked={question3.answer === options3.id ?? "checked"}
                                                                                                                                                                                        name={question3.id}
                                                                                                                                                                                        className="radiobox"
                                                                                                                                                                                        onClick={() => {
                                                                                                                                                                                            question3.answer = options3.id;
                                                                                                                                                                                            setRefresh(refresh + 1);
                                                                                                                                                                                            autoSave(false, question3.id, question3.answer);
                                                                                                                                                                                        }}></input>
                                                                                                                                                                                    {options3?.description}
                                                                                                                                                                                    {/* Se a opção estiver marcada, abre as possiveis subperguntas */}
                                                                                                                                                                                    {options3?.questions?.length > 0 && question3?.answer === options3?.id ? (
                                                                                                                                                                                        options3?.questions?.map((question4, indexq4) => {
                                                                                                                                                                                            // NIVEL 4 //////////////////////////
                                                                                                                                                                                            return (
                                                                                                                                                                                                <>
                                                                                                                                                                                                    <div key={indexq4} className={classes.boxQuestion}>
                                                                                                                                                                                                        <div className="questionDescription">{question4?.description}</div>
                                                                                                                                                                                                        <div className="gridteste">
                                                                                                                                                                                                            <div className={classes.separacaoOpcoes}></div>
                                                                                                                                                                                                            <div className="opcoesDaPergunta">
                                                                                                                                                                                                                {question4?.type === 3 ? (
                                                                                                                                                                                                                    question4?.options.map((options4, indexO4) => {
                                                                                                                                                                                                                        return (
                                                                                                                                                                                                                            <>
                                                                                                                                                                                                                                <div className={classes.multipleAnswerBox} key={indexO4}>
                                                                                                                                                                                                                                    <input
                                                                                                                                                                                                                                        disabled={finished ?? "disabled"}
                                                                                                                                                                                                                                        type="checkbox"
                                                                                                                                                                                                                                        id="check"
                                                                                                                                                                                                                                        name="check"
                                                                                                                                                                                                                                        defaultChecked={question4.answer.indexOf(question4.options[indexO4].id) !== -1 ?? "checked"}
                                                                                                                                                                                                                                        className="checkbox"
                                                                                                                                                                                                                                        onChange={(e) => {
                                                                                                                                                                                                                                            if (question4.answer.indexOf(question4.options[indexO4].id) === -1) question4.answer.push(question4.options[indexO4].id);
                                                                                                                                                                                                                                            else question4.answer.splice(question4.answer.indexOf(question4.options[indexO4].id), 1);
                                                                                                                                                                                                                                            question4.options[indexO4].answer = e.target.checked;
                                                                                                                                                                                                                                            setRefresh(refresh + 1);
                                                                                                                                                                                                                                            autoSave(false, question4.id, question4.answer);
                                                                                                                                                                                                                                        }}></input>
                                                                                                                                                                                                                                    {options4?.description}
                                                                                                                                                                                                                                </div>
                                                                                                                                                                                                                            </>
                                                                                                                                                                                                                        );
                                                                                                                                                                                                                    })
                                                                                                                                                                                                                ) : question4?.type === 1 ? (
                                                                                                                                                                                                                    <textarea
                                                                                                                                                                                                                        type="text"
                                                                                                                                                                                                                        id="answer"
                                                                                                                                                                                                                        name="answer"
                                                                                                                                                                                                                        placeholder="Digite sua resposta aqui"
                                                                                                                                                                                                                        disabled={finished ?? "disabled"}
                                                                                                                                                                                                                        className={classes.inputTextAnswer}
                                                                                                                                                                                                                        onChange={(e) => (question4.answer = e.target.value)}
                                                                                                                                                                                                                        defaultValue={question4.answer ?? question4.answer}
                                                                                                                                                                                                                        onBlur={() => autoSave(false, question4.id, question4.answer)}></textarea>
                                                                                                                                                                                                                ) : question4?.type === 2 ? (
                                                                                                                                                                                                                    question4?.options.map((options4, indexO4) => {
                                                                                                                                                                                                                        return (
                                                                                                                                                                                                                            <div className={classes.multipleAnswerBox} key={indexO4}>
                                                                                                                                                                                                                                <input
                                                                                                                                                                                                                                    disabled={finished ?? "disabled"}
                                                                                                                                                                                                                                    type="radio"
                                                                                                                                                                                                                                    id="radio4"
                                                                                                                                                                                                                                    defaultChecked={question4.answer === options4.id ?? "checked"}
                                                                                                                                                                                                                                    name={question4.id}
                                                                                                                                                                                                                                    className="radiobox"
                                                                                                                                                                                                                                    onClick={() => {
                                                                                                                                                                                                                                        question4.answer = options4.id;
                                                                                                                                                                                                                                        setRefresh(refresh + 1);
                                                                                                                                                                                                                                        autoSave(false, question4.id, question4.answer);
                                                                                                                                                                                                                                    }}></input>
                                                                                                                                                                                                                                {options4?.description}
                                                                                                                                                                                                                            </div>
                                                                                                                                                                                                                        );
                                                                                                                                                                                                                    })
                                                                                                                                                                                                                ) : question4?.type === 4 ? (
                                                                                                                                                                                                                    <CurrencyTextField
                                                                                                                                                                                                                        disabled={finished ?? "disabled"}
                                                                                                                                                                                                                        variant="outlined"
                                                                                                                                                                                                                        // value={0.00}
                                                                                                                                                                                                                        className={classes.inputMonetaryAnswer}
                                                                                                                                                                                                                        currencySymbol="R$"
                                                                                                                                                                                                                        outputFormat="number"
                                                                                                                                                                                                                        decimalCharacter=","
                                                                                                                                                                                                                        // required={true}
                                                                                                                                                                                                                        digitGroupSeparator="."
                                                                                                                                                                                                                        onChange={(e) => (question4.answer = e.target.value)}
                                                                                                                                                                                                                        defaultValue={question4.answer ?? question4.answer}
                                                                                                                                                                                                                        onBlur={() => autoSave(false, question4.id, question4.answer)}
                                                                                                                                                                                                                    />
                                                                                                                                                                                                                ) : question4?.type === 5 ? (
                                                                                                                                                                                                                    <div className="containerTipoPerguntaPorcentagem">
                                                                                                                                                                                                                        <TextField
                                                                                                                                                                                                                            type="number"
                                                                                                                                                                                                                            onKeyPress={(e) => {
                                                                                                                                                                                                                                if (e.code === "Backquote") e.preventDefault();
                                                                                                                                                                                                                            }}
                                                                                                                                                                                                                            step="0.00"
                                                                                                                                                                                                                            onKeyDown={(e) => {
                                                                                                                                                                                                                                if (e.key === "e" || e.key === "E") {
                                                                                                                                                                                                                                    e.preventDefault();
                                                                                                                                                                                                                                }
                                                                                                                                                                                                                            }}
                                                                                                                                                                                                                            variant="outlined"
                                                                                                                                                                                                                            onBlur={() => autoSave(false, question4.id, question4.answer)}
                                                                                                                                                                                                                            disabled={finished ?? "disabled"}
                                                                                                                                                                                                                            id="answer"
                                                                                                                                                                                                                            name="answer"
                                                                                                                                                                                                                            className={classes.inputPercentageAnswer}
                                                                                                                                                                                                                            onChange={(e) => (question4.answer = e.target.value.replace(",", "."))}
                                                                                                                                                                                                                            defaultValue={question4.answer ?? question4.answer}
                                                                                                                                                                                                                        />
                                                                                                                                                                                                                        <div className="porcentagem">%</div>
                                                                                                                                                                                                                    </div>
                                                                                                                                                                                                                ) : question4?.type === 6 ? (
                                                                                                                                                                                                                    <div className="containerTipoPerguntaPorcentagem">
                                                                                                                                                                                                                        <TextField
                                                                                                                                                                                                                            type="number"
                                                                                                                                                                                                                            onKeyPress={(e) => {
                                                                                                                                                                                                                                if (e.key === "." || e.key === ",") e.preventDefault();
                                                                                                                                                                                                                            }}
                                                                                                                                                                                                                            variant="outlined"
                                                                                                                                                                                                                            onBlur={() => autoSave(false, question4.id, question4.answer)}
                                                                                                                                                                                                                            disabled={finished ?? "disabled"}
                                                                                                                                                                                                                            id="answer"
                                                                                                                                                                                                                            name="answer"
                                                                                                                                                                                                                            className={classes.inputPercentageAnswer}
                                                                                                                                                                                                                            onChange={(e) => (question4.answer = e.target.value)}
                                                                                                                                                                                                                            defaultValue={question4.answer ?? question4.answer}
                                                                                                                                                                                                                        />
                                                                                                                                                                                                                    </div>
                                                                                                                                                                                                                ) : question4?.type === 7 ? (
                                                                                                                                                                                                                    <div className="containerTipoPerguntaPorcentagem">
                                                                                                                                                                                                                        <TextField
                                                                                                                                                                                                                            type="number"
                                                                                                                                                                                                                            variant="outlined"
                                                                                                                                                                                                                            onBlur={() => autoSave(false, question4.id, question4.answer)}
                                                                                                                                                                                                                            disabled={finished ?? "disabled"}
                                                                                                                                                                                                                            id="answer"
                                                                                                                                                                                                                            name="answer"
                                                                                                                                                                                                                            className={classes.inputPercentageAnswer}
                                                                                                                                                                                                                            onChange={(e) => (question4.answer = e.target.value.replace(",", "."))}
                                                                                                                                                                                                                            defaultValue={question4.answer ?? question4.answer}
                                                                                                                                                                                                                        />
                                                                                                                                                                                                                    </div>
                                                                                                                                                                                                                ) : (
                                                                                                                                                                                                                    <></>
                                                                                                                                                                                                                )}
                                                                                                                                                                                                            </div>
                                                                                                                                                                                                        </div>
                                                                                                                                                                                                        {question4?.observation === true ? (
                                                                                                                                                                                                            <div className="observation">
                                                                                                                                                                                                                <p className="observationText">Observações:</p>
                                                                                                                                                                                                                <textarea
                                                                                                                                                                                                                    disabled={finished ?? "disabled"}
                                                                                                                                                                                                                    type="text"
                                                                                                                                                                                                                    id="obs"
                                                                                                                                                                                                                    name="obs"
                                                                                                                                                                                                                    placeholder="Observações aqui"
                                                                                                                                                                                                                    className={classes.inputTextAnswer}
                                                                                                                                                                                                                    onChange={(e) => (question4.answer_observation = e.target.value)}
                                                                                                                                                                                                                    defaultValue={question4.answer_observation ?? question4.answer_observation}
                                                                                                                                                                                                                    onBlur={() => autoSave(true, question4.id, question4.answer_observation)}></textarea>
                                                                                                                                                                                                            </div>
                                                                                                                                                                                                        ) : (
                                                                                                                                                                                                            <></>
                                                                                                                                                                                                        )}
                                                                                                                                                                                                    </div>
                                                                                                                                                                                                </>
                                                                                                                                                                                            );
                                                                                                                                                                                        })
                                                                                                                                                                                    ) : (
                                                                                                                                                                                        <></>
                                                                                                                                                                                    )}
                                                                                                                                                                                </div>
                                                                                                                                                                            );
                                                                                                                                                                        })
                                                                                                                                                                    ) : question3?.type === 4 ? (
                                                                                                                                                                        <CurrencyTextField
                                                                                                                                                                            disabled={finished ?? "disabled"}
                                                                                                                                                                            variant="outlined"
                                                                                                                                                                            // value={0.00}
                                                                                                                                                                            className={classes.inputMonetaryAnswer}
                                                                                                                                                                            currencySymbol="R$"
                                                                                                                                                                            outputFormat="number"
                                                                                                                                                                            decimalCharacter=","
                                                                                                                                                                            // required={true}
                                                                                                                                                                            digitGroupSeparator="."
                                                                                                                                                                            onChange={(e) => (question3.answer = e.target.value)}
                                                                                                                                                                            defaultValue={question3.answer ?? question3.answer}
                                                                                                                                                                            onBlur={() => autoSave(false, question3.id, question3.answer)}
                                                                                                                                                                        />
                                                                                                                                                                    ) : question3?.type === 5 ? (
                                                                                                                                                                        <div className="containerTipoPerguntaPorcentagem">
                                                                                                                                                                            <TextField
                                                                                                                                                                                type="number"
                                                                                                                                                                                onKeyPress={(e) => {
                                                                                                                                                                                    if (e.code === "Backquote") e.preventDefault();
                                                                                                                                                                                }}
                                                                                                                                                                                step="0.00"
                                                                                                                                                                                onKeyDown={(e) => {
                                                                                                                                                                                    if (e.key === "e" || e.key === "E") {
                                                                                                                                                                                        e.preventDefault();
                                                                                                                                                                                    }
                                                                                                                                                                                }}
                                                                                                                                                                                variant="outlined"
                                                                                                                                                                                onBlur={() => autoSave(false, question3.id, question3.answer)}
                                                                                                                                                                                disabled={finished ?? "disabled"}
                                                                                                                                                                                id="answer"
                                                                                                                                                                                name="answer"
                                                                                                                                                                                className={classes.inputPercentageAnswer}
                                                                                                                                                                                onChange={(e) => (question3.answer = e.target.value.replace(",", "."))}
                                                                                                                                                                                defaultValue={question3.answer ?? question3.answer}
                                                                                                                                                                            />
                                                                                                                                                                            <div className="porcentagem">%</div>
                                                                                                                                                                        </div>
                                                                                                                                                                    ) : question3?.type === 6 ? (
                                                                                                                                                                        <div className="containerTipoPerguntaPorcentagem">
                                                                                                                                                                            <TextField
                                                                                                                                                                                type="number"
                                                                                                                                                                                onKeyPress={(e) => {
                                                                                                                                                                                    if (e.key === "." || e.key === ",") e.preventDefault();
                                                                                                                                                                                }}
                                                                                                                                                                                variant="outlined"
                                                                                                                                                                                onBlur={() => autoSave(false, question3.id, question3.answer)}
                                                                                                                                                                                disabled={finished ?? "disabled"}
                                                                                                                                                                                id="answer"
                                                                                                                                                                                name="answer"
                                                                                                                                                                                className={classes.inputPercentageAnswer}
                                                                                                                                                                                onChange={(e) => (question3.answer = e.target.value)}
                                                                                                                                                                                defaultValue={question3.answer ?? question3.answer}
                                                                                                                                                                            />
                                                                                                                                                                        </div>
                                                                                                                                                                    ) : question3?.type === 7 ? (
                                                                                                                                                                        <div className="containerTipoPerguntaPorcentagem">
                                                                                                                                                                            <TextField
                                                                                                                                                                                type="number"
                                                                                                                                                                                variant="outlined"
                                                                                                                                                                                onBlur={() => autoSave(false, question3.id, question3.answer)}
                                                                                                                                                                                disabled={finished ?? "disabled"}
                                                                                                                                                                                id="answer"
                                                                                                                                                                                name="answer"
                                                                                                                                                                                className={classes.inputPercentageAnswer}
                                                                                                                                                                                onChange={(e) => (question3.answer = e.target.value.replace(",", "."))}
                                                                                                                                                                                defaultValue={question3.answer ?? question3.answer}
                                                                                                                                                                            />
                                                                                                                                                                        </div>
                                                                                                                                                                    ) : (
                                                                                                                                                                        <></>
                                                                                                                                                                    )}
                                                                                                                                                                </div>
                                                                                                                                                            </div>
                                                                                                                                                            {question3?.observation === true ? (
                                                                                                                                                                <div className="observation">
                                                                                                                                                                    <p className="observationText">Observações:</p>
                                                                                                                                                                    <textarea
                                                                                                                                                                        disabled={finished ?? "disabled"}
                                                                                                                                                                        type="text"
                                                                                                                                                                        id="obs"
                                                                                                                                                                        name="obs"
                                                                                                                                                                        placeholder="Observações aqui"
                                                                                                                                                                        className={classes.inputTextAnswer}
                                                                                                                                                                        onChange={(e) => (question3.answer_observation = e.target.value)}
                                                                                                                                                                        defaultValue={question3.answer_observation ?? question3.answer_observation}
                                                                                                                                                                        onBlur={() => autoSave(true, question3.id, question3.answer_observation)}></textarea>
                                                                                                                                                                </div>
                                                                                                                                                            ) : (
                                                                                                                                                                <></>
                                                                                                                                                            )}
                                                                                                                                                        </div>
                                                                                                                                                    </>
                                                                                                                                                );
                                                                                                                                            })
                                                                                                                                        ) : (
                                                                                                                                            <></>
                                                                                                                                        )}
                                                                                                                                    </div>
                                                                                                                                </>
                                                                                                                            );
                                                                                                                        })
                                                                                                                    ) : question2?.type === 1 ? (
                                                                                                                        <textarea type="text" id="answer" name="answer" placeholder="Digite sua resposta aqui" disabled={finished ?? "disabled"} className={classes.inputTextAnswer} onChange={(e) => (question2.answer = e.target.value)} defaultValue={question2.answer ?? question2.answer} onBlur={() => autoSave(false, question2.id, question2.answer)}></textarea>
                                                                                                                    ) : question2?.type === 2 ? (
                                                                                                                        question2?.options.map((options2, indexO2) => {
                                                                                                                            return (
                                                                                                                                <div className={classes.multipleAnswerBox} key={indexO2}>
                                                                                                                                    <input
                                                                                                                                        disabled={finished ?? "disabled"}
                                                                                                                                        type="radio"
                                                                                                                                        id="radio2"
                                                                                                                                        defaultChecked={question2.answer === options2.id ?? "checked"}
                                                                                                                                        name={question2.id}
                                                                                                                                        className="radiobox"
                                                                                                                                        onClick={() => {
                                                                                                                                            question2.answer = options2.id;
                                                                                                                                            setRefresh(refresh + 1);
                                                                                                                                            autoSave(false, question2.id, question2.answer);
                                                                                                                                        }}></input>
                                                                                                                                    {options2?.description}
                                                                                                                                    {/* Se a opção estiver marcada, abre as possiveis subperguntas */}
                                                                                                                                    {options2?.questions?.length > 0 && question2?.answer === options2?.id ? (
                                                                                                                                        options2?.questions?.map((question3, indexq3) => {
                                                                                                                                            // NIVEL 3 //////////////////////////
                                                                                                                                            return (
                                                                                                                                                <>
                                                                                                                                                    <div key={indexq3} className={classes.boxQuestion}>
                                                                                                                                                        <div className="questionDescription">{question3?.description}</div>
                                                                                                                                                        <div className="gridteste">
                                                                                                                                                            <div className={classes.separacaoOpcoes}></div>
                                                                                                                                                            <div className="opcoesDaPergunta">
                                                                                                                                                                {question3?.type === 3 ? (
                                                                                                                                                                    question3?.options.map((options3, indexO3) => {
                                                                                                                                                                        return (
                                                                                                                                                                            <>
                                                                                                                                                                                <div className={classes.multipleAnswerBox} key={indexO3}>
                                                                                                                                                                                    <input
                                                                                                                                                                                        disabled={finished ?? "disabled"}
                                                                                                                                                                                        type="checkbox"
                                                                                                                                                                                        id="check"
                                                                                                                                                                                        name="check"
                                                                                                                                                                                        defaultChecked={question3.answer.indexOf(question3.options[indexO3].id) !== -1 ?? "checked"}
                                                                                                                                                                                        className="checkbox"
                                                                                                                                                                                        onChange={(e) => {
                                                                                                                                                                                            if (question3.answer.indexOf(question3.options[indexO3].id) === -1) question3.answer.push(question3.options[indexO3].id);
                                                                                                                                                                                            else question3.answer.splice(question3.answer.indexOf(question3.options[indexO3].id), 1);
                                                                                                                                                                                            question3.options[indexO3].answer = e.target.checked;
                                                                                                                                                                                            setRefresh(refresh + 1);
                                                                                                                                                                                            autoSave(false, question3.id, question3.answer);
                                                                                                                                                                                        }}></input>
                                                                                                                                                                                    {options3?.description}
                                                                                                                                                                                    {/* Se a opção estiver marcada, abre as possiveis subperguntas */}

                                                                                                                                                                                    {options3?.questions?.length > 0 && question3.answer.indexOf(question3.options[indexO3].id) !== -1 ? (
                                                                                                                                                                                        options3?.questions?.map((question4, indexq4) => {
                                                                                                                                                                                            // NIVEL 4 //////////////////////////
                                                                                                                                                                                            return (
                                                                                                                                                                                                <>
                                                                                                                                                                                                    <div key={indexq4} className={classes.boxQuestion}>
                                                                                                                                                                                                        <div className="questionDescription">{question4?.description}</div>
                                                                                                                                                                                                        <div className="gridteste">
                                                                                                                                                                                                            <div className={classes.separacaoOpcoes}></div>
                                                                                                                                                                                                            <div className="opcoesDaPergunta">
                                                                                                                                                                                                                {question4?.type === 3 ? (
                                                                                                                                                                                                                    question4?.options.map((options4, indexO4) => {
                                                                                                                                                                                                                        return (
                                                                                                                                                                                                                            <>
                                                                                                                                                                                                                                <div className={classes.multipleAnswerBox} key={indexO4}>
                                                                                                                                                                                                                                    <input
                                                                                                                                                                                                                                        disabled={finished ?? "disabled"}
                                                                                                                                                                                                                                        type="checkbox"
                                                                                                                                                                                                                                        id="check"
                                                                                                                                                                                                                                        name="check"
                                                                                                                                                                                                                                        defaultChecked={question4.answer.indexOf(question4.options[indexO4].id) !== -1 ?? "checked"}
                                                                                                                                                                                                                                        className="checkbox"
                                                                                                                                                                                                                                        onChange={(e) => {
                                                                                                                                                                                                                                            if (question4.answer.indexOf(question4.options[indexO4].id) === -1) question4.answer.push(question4.options[indexO4].id);
                                                                                                                                                                                                                                            else question4.answer.splice(question4.answer.indexOf(question4.options[indexO4].id), 1);
                                                                                                                                                                                                                                            question4.options[indexO4].answer = e.target.checked;
                                                                                                                                                                                                                                            setRefresh(refresh + 1);
                                                                                                                                                                                                                                            autoSave(false, question4.id, question4.answer);
                                                                                                                                                                                                                                        }}></input>
                                                                                                                                                                                                                                    {options4?.description}
                                                                                                                                                                                                                                </div>
                                                                                                                                                                                                                            </>
                                                                                                                                                                                                                        );
                                                                                                                                                                                                                    })
                                                                                                                                                                                                                ) : question4?.type === 1 ? (
                                                                                                                                                                                                                    <textarea
                                                                                                                                                                                                                        type="text"
                                                                                                                                                                                                                        id="answer"
                                                                                                                                                                                                                        name="answer"
                                                                                                                                                                                                                        placeholder="Digite sua resposta aqui"
                                                                                                                                                                                                                        disabled={finished ?? "disabled"}
                                                                                                                                                                                                                        className={classes.inputTextAnswer}
                                                                                                                                                                                                                        onChange={(e) => (question4.answer = e.target.value)}
                                                                                                                                                                                                                        defaultValue={question4.answer ?? question4.answer}
                                                                                                                                                                                                                        onBlur={() => autoSave(false, question4.id, question4.answer)}></textarea>
                                                                                                                                                                                                                ) : question4?.type === 2 ? (
                                                                                                                                                                                                                    question4?.options.map((options4, indexO4) => {
                                                                                                                                                                                                                        return (
                                                                                                                                                                                                                            <div className={classes.multipleAnswerBox} key={indexO4}>
                                                                                                                                                                                                                                <input
                                                                                                                                                                                                                                    disabled={finished ?? "disabled"}
                                                                                                                                                                                                                                    type="radio"
                                                                                                                                                                                                                                    id="radio4"
                                                                                                                                                                                                                                    defaultChecked={question4.answer === options4.id ?? "checked"}
                                                                                                                                                                                                                                    name={question4.id}
                                                                                                                                                                                                                                    className="radiobox"
                                                                                                                                                                                                                                    onClick={() => {
                                                                                                                                                                                                                                        question4.answer = options4.id;
                                                                                                                                                                                                                                        setRefresh(refresh + 1);
                                                                                                                                                                                                                                        autoSave(false, question4.id, question4.answer);
                                                                                                                                                                                                                                    }}></input>
                                                                                                                                                                                                                                {options4?.description}
                                                                                                                                                                                                                            </div>
                                                                                                                                                                                                                        );
                                                                                                                                                                                                                    })
                                                                                                                                                                                                                ) : question4?.type === 4 ? (
                                                                                                                                                                                                                    <CurrencyTextField
                                                                                                                                                                                                                        disabled={finished ?? "disabled"}
                                                                                                                                                                                                                        variant="outlined"
                                                                                                                                                                                                                        // value={0.00}
                                                                                                                                                                                                                        className={classes.inputMonetaryAnswer}
                                                                                                                                                                                                                        currencySymbol="R$"
                                                                                                                                                                                                                        outputFormat="number"
                                                                                                                                                                                                                        decimalCharacter=","
                                                                                                                                                                                                                        // required={true}
                                                                                                                                                                                                                        digitGroupSeparator="."
                                                                                                                                                                                                                        onChange={(e) => (question4.answer = e.target.value)}
                                                                                                                                                                                                                        defaultValue={question4.answer ?? question4.answer}
                                                                                                                                                                                                                        onBlur={() => autoSave(false, question4.id, question4.answer)}
                                                                                                                                                                                                                    />
                                                                                                                                                                                                                ) : question4?.type === 5 ? (
                                                                                                                                                                                                                    <div className="containerTipoPerguntaPorcentagem">
                                                                                                                                                                                                                        <TextField
                                                                                                                                                                                                                            type="number"
                                                                                                                                                                                                                            onKeyPress={(e) => {
                                                                                                                                                                                                                                if (e.code === "Backquote") e.preventDefault();
                                                                                                                                                                                                                            }}
                                                                                                                                                                                                                            step="0.00"
                                                                                                                                                                                                                            onKeyDown={(e) => {
                                                                                                                                                                                                                                if (e.key === "e" || e.key === "E") {
                                                                                                                                                                                                                                    e.preventDefault();
                                                                                                                                                                                                                                }
                                                                                                                                                                                                                            }}
                                                                                                                                                                                                                            variant="outlined"
                                                                                                                                                                                                                            onBlur={() => autoSave(false, question4.id, question4.answer)}
                                                                                                                                                                                                                            disabled={finished ?? "disabled"}
                                                                                                                                                                                                                            id="answer"
                                                                                                                                                                                                                            name="answer"
                                                                                                                                                                                                                            className={classes.inputPercentageAnswer}
                                                                                                                                                                                                                            onChange={(e) => (question4.answer = e.target.value.replace(",", "."))}
                                                                                                                                                                                                                            defaultValue={question4.answer ?? question4.answer}
                                                                                                                                                                                                                        />
                                                                                                                                                                                                                        <div className="porcentagem">%</div>
                                                                                                                                                                                                                    </div>
                                                                                                                                                                                                                ) : question4?.type === 6 ? (
                                                                                                                                                                                                                    <div className="containerTipoPerguntaPorcentagem">
                                                                                                                                                                                                                        <TextField
                                                                                                                                                                                                                            type="number"
                                                                                                                                                                                                                            onKeyPress={(e) => {
                                                                                                                                                                                                                                if (e.key === "." || e.key === ",") e.preventDefault();
                                                                                                                                                                                                                            }}
                                                                                                                                                                                                                            variant="outlined"
                                                                                                                                                                                                                            onBlur={() => autoSave(false, question4.id, question4.answer)}
                                                                                                                                                                                                                            disabled={finished ?? "disabled"}
                                                                                                                                                                                                                            id="answer"
                                                                                                                                                                                                                            name="answer"
                                                                                                                                                                                                                            className={classes.inputPercentageAnswer}
                                                                                                                                                                                                                            onChange={(e) => (question4.answer = e.target.value)}
                                                                                                                                                                                                                            defaultValue={question4.answer ?? question4.answer}
                                                                                                                                                                                                                        />
                                                                                                                                                                                                                    </div>
                                                                                                                                                                                                                ) : question4?.type === 7 ? (
                                                                                                                                                                                                                    <div className="containerTipoPerguntaPorcentagem">
                                                                                                                                                                                                                        <TextField
                                                                                                                                                                                                                            type="number"
                                                                                                                                                                                                                            variant="outlined"
                                                                                                                                                                                                                            onBlur={() => autoSave(false, question4.id, question4.answer)}
                                                                                                                                                                                                                            disabled={finished ?? "disabled"}
                                                                                                                                                                                                                            id="answer"
                                                                                                                                                                                                                            name="answer"
                                                                                                                                                                                                                            className={classes.inputPercentageAnswer}
                                                                                                                                                                                                                            onChange={(e) => (question4.answer = e.target.value.replace(",", "."))}
                                                                                                                                                                                                                            defaultValue={question4.answer ?? question4.answer}
                                                                                                                                                                                                                        />
                                                                                                                                                                                                                    </div>
                                                                                                                                                                                                                ) : (
                                                                                                                                                                                                                    <></>
                                                                                                                                                                                                                )}
                                                                                                                                                                                                            </div>
                                                                                                                                                                                                        </div>
                                                                                                                                                                                                        {question4?.observation === true ? (
                                                                                                                                                                                                            <div className="observation">
                                                                                                                                                                                                                <p className="observationText">Observações:</p>
                                                                                                                                                                                                                <textarea
                                                                                                                                                                                                                    disabled={finished ?? "disabled"}
                                                                                                                                                                                                                    type="text"
                                                                                                                                                                                                                    id="obs"
                                                                                                                                                                                                                    name="obs"
                                                                                                                                                                                                                    placeholder="Observações aqui"
                                                                                                                                                                                                                    className={classes.inputTextAnswer}
                                                                                                                                                                                                                    onChange={(e) => (question4.answer_observation = e.target.value)}
                                                                                                                                                                                                                    defaultValue={question4.answer_observation ?? question4.answer_observation}
                                                                                                                                                                                                                    onBlur={() => autoSave(true, question4.id, question4.answer_observation)}></textarea>
                                                                                                                                                                                                            </div>
                                                                                                                                                                                                        ) : (
                                                                                                                                                                                                            <></>
                                                                                                                                                                                                        )}
                                                                                                                                                                                                    </div>
                                                                                                                                                                                                </>
                                                                                                                                                                                            );
                                                                                                                                                                                        })
                                                                                                                                                                                    ) : (
                                                                                                                                                                                        <></>
                                                                                                                                                                                    )}
                                                                                                                                                                                </div>
                                                                                                                                                                            </>
                                                                                                                                                                        );
                                                                                                                                                                    })
                                                                                                                                                                ) : question3?.type === 1 ? (
                                                                                                                                                                    <textarea
                                                                                                                                                                        type="text"
                                                                                                                                                                        id="answer"
                                                                                                                                                                        name="answer"
                                                                                                                                                                        placeholder="Digite sua resposta aqui"
                                                                                                                                                                        disabled={finished ?? "disabled"}
                                                                                                                                                                        className={classes.inputTextAnswer}
                                                                                                                                                                        onChange={(e) => (question3.answer = e.target.value)}
                                                                                                                                                                        defaultValue={question3.answer ?? question3.answer}
                                                                                                                                                                        onBlur={() => autoSave(false, question3.id, question3.answer)}></textarea>
                                                                                                                                                                ) : question3?.type === 2 ? (
                                                                                                                                                                    question3?.options.map((options3, indexO3) => {
                                                                                                                                                                        return (
                                                                                                                                                                            <div className={classes.multipleAnswerBox} key={indexO3}>
                                                                                                                                                                                <input
                                                                                                                                                                                    disabled={finished ?? "disabled"}
                                                                                                                                                                                    type="radio"
                                                                                                                                                                                    id="radio3"
                                                                                                                                                                                    defaultChecked={question3.answer === options3.id ?? "checked"}
                                                                                                                                                                                    name={question3.id}
                                                                                                                                                                                    className="radiobox"
                                                                                                                                                                                    onClick={() => {
                                                                                                                                                                                        question3.answer = options3.id;
                                                                                                                                                                                        setRefresh(refresh + 1);
                                                                                                                                                                                        autoSave(false, question3.id, question3.answer);
                                                                                                                                                                                    }}></input>
                                                                                                                                                                                {options3?.description}
                                                                                                                                                                                {/* Se a opção estiver marcada, abre as possiveis subperguntas */}
                                                                                                                                                                                {options3?.questions?.length > 0 && question3?.answer === options3?.id ? (
                                                                                                                                                                                    options3?.questions?.map((question4, indexq4) => {
                                                                                                                                                                                        // NIVEL 4 //////////////////////////
                                                                                                                                                                                        return (
                                                                                                                                                                                            <>
                                                                                                                                                                                                <div key={indexq4} className={classes.boxQuestion}>
                                                                                                                                                                                                    <div className="questionDescription">{question4?.description}</div>
                                                                                                                                                                                                    <div className="gridteste">
                                                                                                                                                                                                        <div className={classes.separacaoOpcoes}></div>
                                                                                                                                                                                                        <div className="opcoesDaPergunta">
                                                                                                                                                                                                            {question4?.type === 3 ? (
                                                                                                                                                                                                                question4?.options.map((options4, indexO4) => {
                                                                                                                                                                                                                    return (
                                                                                                                                                                                                                        <>
                                                                                                                                                                                                                            <div className={classes.multipleAnswerBox} key={indexO4}>
                                                                                                                                                                                                                                <input
                                                                                                                                                                                                                                    disabled={finished ?? "disabled"}
                                                                                                                                                                                                                                    type="checkbox"
                                                                                                                                                                                                                                    id="check"
                                                                                                                                                                                                                                    name="check"
                                                                                                                                                                                                                                    defaultChecked={question4.answer.indexOf(question4.options[indexO4].id) !== -1 ?? "checked"}
                                                                                                                                                                                                                                    className="checkbox"
                                                                                                                                                                                                                                    onChange={(e) => {
                                                                                                                                                                                                                                        if (question4.answer.indexOf(question4.options[indexO4].id) === -1) question4.answer.push(question4.options[indexO4].id);
                                                                                                                                                                                                                                        else question4.answer.splice(question4.answer.indexOf(question4.options[indexO4].id), 1);
                                                                                                                                                                                                                                        question4.options[indexO4].answer = e.target.checked;
                                                                                                                                                                                                                                        setRefresh(refresh + 1);
                                                                                                                                                                                                                                        autoSave(false, question4.id, question4.answer);
                                                                                                                                                                                                                                    }}></input>
                                                                                                                                                                                                                                {options4?.description}
                                                                                                                                                                                                                            </div>
                                                                                                                                                                                                                        </>
                                                                                                                                                                                                                    );
                                                                                                                                                                                                                })
                                                                                                                                                                                                            ) : question4?.type === 1 ? (
                                                                                                                                                                                                                <textarea
                                                                                                                                                                                                                    type="text"
                                                                                                                                                                                                                    id="answer"
                                                                                                                                                                                                                    name="answer"
                                                                                                                                                                                                                    placeholder="Digite sua resposta aqui"
                                                                                                                                                                                                                    disabled={finished ?? "disabled"}
                                                                                                                                                                                                                    className={classes.inputTextAnswer}
                                                                                                                                                                                                                    onChange={(e) => (question4.answer = e.target.value)}
                                                                                                                                                                                                                    defaultValue={question4.answer ?? question4.answer}
                                                                                                                                                                                                                    onBlur={() => autoSave(false, question4.id, question4.answer)}></textarea>
                                                                                                                                                                                                            ) : question4?.type === 2 ? (
                                                                                                                                                                                                                question4?.options.map((options4, indexO4) => {
                                                                                                                                                                                                                    return (
                                                                                                                                                                                                                        <div className={classes.multipleAnswerBox} key={indexO4}>
                                                                                                                                                                                                                            <input
                                                                                                                                                                                                                                disabled={finished ?? "disabled"}
                                                                                                                                                                                                                                type="radio"
                                                                                                                                                                                                                                id="radio4"
                                                                                                                                                                                                                                defaultChecked={question4.answer === options4.id ?? "checked"}
                                                                                                                                                                                                                                name={question4.id}
                                                                                                                                                                                                                                className="radiobox"
                                                                                                                                                                                                                                onClick={() => {
                                                                                                                                                                                                                                    question4.answer = options4.id;
                                                                                                                                                                                                                                    setRefresh(refresh + 1);
                                                                                                                                                                                                                                    autoSave(false, question4.id, question4.answer);
                                                                                                                                                                                                                                }}></input>
                                                                                                                                                                                                                            {options4?.description}
                                                                                                                                                                                                                        </div>
                                                                                                                                                                                                                    );
                                                                                                                                                                                                                })
                                                                                                                                                                                                            ) : question4?.type === 4 ? (
                                                                                                                                                                                                                <CurrencyTextField
                                                                                                                                                                                                                    disabled={finished ?? "disabled"}
                                                                                                                                                                                                                    variant="outlined"
                                                                                                                                                                                                                    // value={0.00}
                                                                                                                                                                                                                    className={classes.inputMonetaryAnswer}
                                                                                                                                                                                                                    currencySymbol="R$"
                                                                                                                                                                                                                    outputFormat="number"
                                                                                                                                                                                                                    decimalCharacter=","
                                                                                                                                                                                                                    // required={true}
                                                                                                                                                                                                                    digitGroupSeparator="."
                                                                                                                                                                                                                    onChange={(e) => (question4.answer = e.target.value)}
                                                                                                                                                                                                                    defaultValue={question4.answer ?? question4.answer}
                                                                                                                                                                                                                    onBlur={() => autoSave(false, question4.id, question4.answer)}
                                                                                                                                                                                                                />
                                                                                                                                                                                                            ) : question4?.type === 5 ? (
                                                                                                                                                                                                                <div className="containerTipoPerguntaPorcentagem">
                                                                                                                                                                                                                    <TextField
                                                                                                                                                                                                                        type="number"
                                                                                                                                                                                                                        onKeyPress={(e) => {
                                                                                                                                                                                                                            if (e.code === "Backquote") e.preventDefault();
                                                                                                                                                                                                                        }}
                                                                                                                                                                                                                        step="0.00"
                                                                                                                                                                                                                        onKeyDown={(e) => {
                                                                                                                                                                                                                            if (e.key === "e" || e.key === "E") {
                                                                                                                                                                                                                                e.preventDefault();
                                                                                                                                                                                                                            }
                                                                                                                                                                                                                        }}
                                                                                                                                                                                                                        variant="outlined"
                                                                                                                                                                                                                        onBlur={() => autoSave(false, question4.id, question4.answer)}
                                                                                                                                                                                                                        disabled={finished ?? "disabled"}
                                                                                                                                                                                                                        id="answer"
                                                                                                                                                                                                                        name="answer"
                                                                                                                                                                                                                        className={classes.inputPercentageAnswer}
                                                                                                                                                                                                                        onChange={(e) => (question4.answer = e.target.value.replace(",", "."))}
                                                                                                                                                                                                                        defaultValue={question4.answer ?? question4.answer}
                                                                                                                                                                                                                    />
                                                                                                                                                                                                                    <div className="porcentagem">%</div>
                                                                                                                                                                                                                </div>
                                                                                                                                                                                                            ) : question4?.type === 6 ? (
                                                                                                                                                                                                                <div className="containerTipoPerguntaPorcentagem">
                                                                                                                                                                                                                    <TextField
                                                                                                                                                                                                                        type="number"
                                                                                                                                                                                                                        onKeyPress={(e) => {
                                                                                                                                                                                                                            if (e.key === "." || e.key === ",") e.preventDefault();
                                                                                                                                                                                                                        }}
                                                                                                                                                                                                                        variant="outlined"
                                                                                                                                                                                                                        onBlur={() => autoSave(false, question4.id, question4.answer)}
                                                                                                                                                                                                                        disabled={finished ?? "disabled"}
                                                                                                                                                                                                                        id="answer"
                                                                                                                                                                                                                        name="answer"
                                                                                                                                                                                                                        className={classes.inputPercentageAnswer}
                                                                                                                                                                                                                        onChange={(e) => (question4.answer = e.target.value)}
                                                                                                                                                                                                                        defaultValue={question4.answer ?? question4.answer}
                                                                                                                                                                                                                    />
                                                                                                                                                                                                                </div>
                                                                                                                                                                                                            ) : question4?.type === 7 ? (
                                                                                                                                                                                                                <div className="containerTipoPerguntaPorcentagem">
                                                                                                                                                                                                                    <TextField
                                                                                                                                                                                                                        type="number"
                                                                                                                                                                                                                        variant="outlined"
                                                                                                                                                                                                                        onBlur={() => autoSave(false, question4.id, question4.answer)}
                                                                                                                                                                                                                        disabled={finished ?? "disabled"}
                                                                                                                                                                                                                        id="answer"
                                                                                                                                                                                                                        name="answer"
                                                                                                                                                                                                                        className={classes.inputPercentageAnswer}
                                                                                                                                                                                                                        onChange={(e) => (question4.answer = e.target.value.replace(",", "."))}
                                                                                                                                                                                                                        defaultValue={question4.answer ?? question4.answer}
                                                                                                                                                                                                                    />
                                                                                                                                                                                                                </div>
                                                                                                                                                                                                            ) : (
                                                                                                                                                                                                                <></>
                                                                                                                                                                                                            )}
                                                                                                                                                                                                        </div>
                                                                                                                                                                                                    </div>
                                                                                                                                                                                                    {question4?.observation === true ? (
                                                                                                                                                                                                        <div className="observation">
                                                                                                                                                                                                            <p className="observationText">Observações:</p>
                                                                                                                                                                                                            <textarea
                                                                                                                                                                                                                disabled={finished ?? "disabled"}
                                                                                                                                                                                                                type="text"
                                                                                                                                                                                                                id="obs"
                                                                                                                                                                                                                name="obs"
                                                                                                                                                                                                                placeholder="Observações aqui"
                                                                                                                                                                                                                className={classes.inputTextAnswer}
                                                                                                                                                                                                                onChange={(e) => (question4.answer_observation = e.target.value)}
                                                                                                                                                                                                                defaultValue={question4.answer_observation ?? question4.answer_observation}
                                                                                                                                                                                                                onBlur={() => autoSave(true, question4.id, question4.answer_observation)}></textarea>
                                                                                                                                                                                                        </div>
                                                                                                                                                                                                    ) : (
                                                                                                                                                                                                        <></>
                                                                                                                                                                                                    )}
                                                                                                                                                                                                </div>
                                                                                                                                                                                            </>
                                                                                                                                                                                        );
                                                                                                                                                                                    })
                                                                                                                                                                                ) : (
                                                                                                                                                                                    <></>
                                                                                                                                                                                )}
                                                                                                                                                                            </div>
                                                                                                                                                                        );
                                                                                                                                                                    })
                                                                                                                                                                ) : question3?.type === 4 ? (
                                                                                                                                                                    <CurrencyTextField
                                                                                                                                                                        disabled={finished ?? "disabled"}
                                                                                                                                                                        variant="outlined"
                                                                                                                                                                        // value={0.00}
                                                                                                                                                                        className={classes.inputMonetaryAnswer}
                                                                                                                                                                        currencySymbol="R$"
                                                                                                                                                                        outputFormat="number"
                                                                                                                                                                        decimalCharacter=","
                                                                                                                                                                        // required={true}
                                                                                                                                                                        digitGroupSeparator="."
                                                                                                                                                                        onChange={(e) => (question3.answer = e.target.value)}
                                                                                                                                                                        defaultValue={question3.answer ?? question3.answer}
                                                                                                                                                                        onBlur={() => autoSave(false, question3.id, question3.answer)}
                                                                                                                                                                    />
                                                                                                                                                                ) : question3?.type === 5 ? (
                                                                                                                                                                    <div className="containerTipoPerguntaPorcentagem">
                                                                                                                                                                        <TextField
                                                                                                                                                                            type="number"
                                                                                                                                                                            onKeyPress={(e) => {
                                                                                                                                                                                if (e.code === "Backquote") e.preventDefault();
                                                                                                                                                                            }}
                                                                                                                                                                            step="0.00"
                                                                                                                                                                            onKeyDown={(e) => {
                                                                                                                                                                                if (e.key === "e" || e.key === "E") {
                                                                                                                                                                                    e.preventDefault();
                                                                                                                                                                                }
                                                                                                                                                                            }}
                                                                                                                                                                            variant="outlined"
                                                                                                                                                                            onBlur={() => autoSave(false, question3.id, question3.answer)}
                                                                                                                                                                            disabled={finished ?? "disabled"}
                                                                                                                                                                            id="answer"
                                                                                                                                                                            name="answer"
                                                                                                                                                                            className={classes.inputPercentageAnswer}
                                                                                                                                                                            onChange={(e) => (question3.answer = e.target.value.replace(",", "."))}
                                                                                                                                                                            defaultValue={question3.answer ?? question3.answer}
                                                                                                                                                                        />
                                                                                                                                                                        <div className="porcentagem">%</div>
                                                                                                                                                                    </div>
                                                                                                                                                                ) : question3?.type === 6 ? (
                                                                                                                                                                    <div className="containerTipoPerguntaPorcentagem">
                                                                                                                                                                        <TextField
                                                                                                                                                                            type="number"
                                                                                                                                                                            onKeyPress={(e) => {
                                                                                                                                                                                if (e.key === "." || e.key === ",") e.preventDefault();
                                                                                                                                                                            }}
                                                                                                                                                                            variant="outlined"
                                                                                                                                                                            onBlur={() => autoSave(false, question3.id, question3.answer)}
                                                                                                                                                                            disabled={finished ?? "disabled"}
                                                                                                                                                                            id="answer"
                                                                                                                                                                            name="answer"
                                                                                                                                                                            className={classes.inputPercentageAnswer}
                                                                                                                                                                            onChange={(e) => (question3.answer = e.target.value)}
                                                                                                                                                                            defaultValue={question3.answer ?? question3.answer}
                                                                                                                                                                        />
                                                                                                                                                                    </div>
                                                                                                                                                                ) : question3?.type === 7 ? (
                                                                                                                                                                    <div className="containerTipoPerguntaPorcentagem">
                                                                                                                                                                        <TextField
                                                                                                                                                                            type="number"
                                                                                                                                                                            variant="outlined"
                                                                                                                                                                            onBlur={() => autoSave(false, question3.id, question3.answer)}
                                                                                                                                                                            disabled={finished ?? "disabled"}
                                                                                                                                                                            id="answer"
                                                                                                                                                                            name="answer"
                                                                                                                                                                            className={classes.inputPercentageAnswer}
                                                                                                                                                                            onChange={(e) => (question3.answer = e.target.value.replace(",", "."))}
                                                                                                                                                                            defaultValue={question3.answer ?? question3.answer}
                                                                                                                                                                        />
                                                                                                                                                                    </div>
                                                                                                                                                                ) : (
                                                                                                                                                                    <></>
                                                                                                                                                                )}
                                                                                                                                                            </div>
                                                                                                                                                        </div>
                                                                                                                                                        {question3?.observation === true ? (
                                                                                                                                                            <div className="observation">
                                                                                                                                                                <p className="observationText">Observações:</p>
                                                                                                                                                                <textarea
                                                                                                                                                                    disabled={finished ?? "disabled"}
                                                                                                                                                                    type="text"
                                                                                                                                                                    id="obs"
                                                                                                                                                                    name="obs"
                                                                                                                                                                    placeholder="Observações aqui"
                                                                                                                                                                    className={classes.inputTextAnswer}
                                                                                                                                                                    onChange={(e) => (question3.answer_observation = e.target.value)}
                                                                                                                                                                    defaultValue={question3.answer_observation ?? question3.answer_observation}
                                                                                                                                                                    onBlur={() => autoSave(true, question3.id, question3.answer_observation)}></textarea>
                                                                                                                                                            </div>
                                                                                                                                                        ) : (
                                                                                                                                                            <></>
                                                                                                                                                        )}
                                                                                                                                                    </div>
                                                                                                                                                </>
                                                                                                                                            );
                                                                                                                                        })
                                                                                                                                    ) : (
                                                                                                                                        <></>
                                                                                                                                    )}
                                                                                                                                </div>
                                                                                                                            );
                                                                                                                        })
                                                                                                                    ) : question2?.type === 4 ? (
                                                                                                                        <CurrencyTextField
                                                                                                                            disabled={finished ?? "disabled"}
                                                                                                                            variant="outlined"
                                                                                                                            // value={0.00}
                                                                                                                            className={classes.inputMonetaryAnswer}
                                                                                                                            currencySymbol="R$"
                                                                                                                            outputFormat="number"
                                                                                                                            decimalCharacter=","
                                                                                                                            // required={true}
                                                                                                                            digitGroupSeparator="."
                                                                                                                            onChange={(e) => (question2.answer = e.target.value)}
                                                                                                                            defaultValue={question2.answer ?? question2.answer}
                                                                                                                            onBlur={() => autoSave(false, question2.id, question2.answer)}
                                                                                                                        />
                                                                                                                    ) : question2?.type === 5 ? (
                                                                                                                      <div className="containerTipoPerguntaPorcentagem">
                                                                                                                        <TextField
                                                                                                                            type="number"
                                                                                                                            onKeyPress={(e) => {
                                                                                                                                if (e.code === "Backquote") e.preventDefault();
                                                                                                                            }}
                                                                                                                            step="0.00"
                                                                                                                            onKeyDown={(e) => {
                                                                                                                                if (e.key === "e" || e.key === "E") {
                                                                                                                                    e.preventDefault();
                                                                                                                                }
                                                                                                                            }}
                                                                                                                            variant="outlined"
                                                                                                                            onBlur={() => autoSave(false, question2.id, question2.answer)}
                                                                                                                            disabled={finished ?? "disabled"}
                                                                                                                            id="answer"
                                                                                                                            name="answer"
                                                                                                                            className={classes.inputPercentageAnswer}
                                                                                                                            onChange={(e) => (question2.answer = e.target.value.replace(",", "."))}
                                                                                                                            defaultValue={question2.answer ?? question2.answer}
                                                                                                                        />
                                                                                                                        <div className="porcentagem">%</div>
</div>
                                                                                                                    ) : question2?.type === 6 ? (
                                                                                                                        <div className="containerTipoPerguntaPorcentagem">
                                                                                                                            <TextField
                                                                                                                                type="number"
                                                                                                                                onKeyPress={(e) => {
                                                                                                                                    if (e.key === "." || e.key === ",") e.preventDefault();
                                                                                                                                }}
                                                                                                                                variant="outlined"
                                                                                                                                onBlur={() => autoSave(false, question2.id, question2.answer)}
                                                                                                                                disabled={finished ?? "disabled"}
                                                                                                                                id="answer"
                                                                                                                                name="answer"
                                                                                                                                className={classes.inputPercentageAnswer}
                                                                                                                                onChange={(e) => (question2.answer = e.target.value)}
                                                                                                                                defaultValue={question2.answer ?? question2.answer}
                                                                                                                            />
                                                                                                                        </div>
                                                                                                                    ) : question2?.type === 7 ? (
                                                                                                                        <div className="containerTipoPerguntaPorcentagem">
                                                                                                                            <TextField type="number" variant="outlined" onBlur={() => autoSave(false, question2.id, question2.answer)} disabled={finished ?? "disabled"} id="answer" name="answer" className={classes.inputPercentageAnswer} onChange={(e) => (question2.answer = e.target.value.replace(",", "."))} defaultValue={question2.answer ?? question2.answer} />
                                                                                                                        </div>
                                                                                                                    ) : (
                                                                                                                        <></>
                                                                                                                    )}
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            {question2?.observation === true ? (
                                                                                                                <div className="observation">
                                                                                                                    <p className="observationText">Observações:</p>
                                                                                                                    <textarea disabled={finished ?? "disabled"} type="text" id="obs" name="obs" placeholder="Observações aqui" className={classes.inputTextAnswer} onChange={(e) => (question2.answer_observation = e.target.value)} defaultValue={question2.answer_observation ?? question2.answer_observation} onBlur={() => autoSave(true, question2.id, question2.answer_observation)}></textarea>
                                                                                                                </div>
                                                                                                            ) : (
                                                                                                                <></>
                                                                                                            )}
                                                                                                        </div>
                                                                                                    </>
                                                                                                );
                                                                                            })
                                                                                        ) : (
                                                                                            <></>
                                                                                        )}
                                                                                    </div>
                                                                                </>
                                                                            );
                                                                        })
                                                                    ) : question?.type === 1 ? (
                                                                        <textarea disabled={finished ?? "disabled"} type="text" id="answer" name="answer" placeholder="Digite sua resposta aqui" className={classes.inputTextAnswer} onChange={(e) => (question.answer = e.target.value)} defaultValue={question.answer ?? question.answer} onBlur={() => autoSave(false, question.id, question.answer)}></textarea>
                                                                    ) : question?.type === 2 ? (
                                                                        question?.options.map((options, indexO) => {
                                                                            return (
                                                                                <div className={classes.multipleAnswerBox} key={indexO}>
                                                                                    <input
                                                                                        disabled={finished ?? "disabled"}
                                                                                        type="radio"
                                                                                        defaultChecked={question.answer === options.id ?? "checked"}
                                                                                        name={question.id}
                                                                                        className="radiobox"
                                                                                        onClick={() => {
                                                                                            question.answer = options.id;
                                                                                            setRefresh(refresh + 1);
                                                                                            autoSave(false, question.id, question.answer);
                                                                                        }}></input>
                                                                                    {options?.description}
                                                                                    {/* Se a opção estiver marcada, abre as possiveis subperguntas */}
                                                                                    {options?.questions?.length > 0 && question?.answer === options?.id ? (
                                                                                        options?.questions?.map((question2, indexq2) => {
                                                                                            // NIVEL 2 //////////////////////////
                                                                                            return (
                                                                                                <>
                                                                                                    <div key={indexq2} className={classes.boxQuestion}>
                                                                                                        <div className="questionDescription">{question2?.description}</div>
                                                                                                        <div className="gridteste">
                                                                                                            <div className={classes.separacaoOpcoes}></div>
                                                                                                            <div className="opcoesDaPergunta">
                                                                                                                {question2?.type === 3 ? (
                                                                                                                    question2?.options.map((options2, indexO2) => {
                                                                                                                        return (
                                                                                                                            <>
                                                                                                                                <div className={classes.multipleAnswerBox} key={indexO2}>
                                                                                                                                    <input
                                                                                                                                        disabled={finished ?? "disabled"}
                                                                                                                                        type="checkbox"
                                                                                                                                        id="check"
                                                                                                                                        name="check"
                                                                                                                                        defaultChecked={question2.answer.indexOf(question2.options[indexO2].id) !== -1 ?? "checked"}
                                                                                                                                        className="checkbox"
                                                                                                                                        onChange={(e) => {
                                                                                                                                            if (question2.answer.indexOf(question2.options[indexO2].id) === -1) question2.answer.push(question2.options[indexO2].id);
                                                                                                                                            else question2.answer.splice(question2.answer.indexOf(question2.options[indexO2].id), 1);
                                                                                                                                            question2.options[indexO2].answer = e.target.checked;
                                                                                                                                            setRefresh(refresh + 1);
                                                                                                                                            autoSave(false, question2.id, question2.answer);
                                                                                                                                        }}></input>
                                                                                                                                    {options2?.description}
                                                                                                                                    {/* Se a opção estiver marcada, abre as possiveis subperguntas */}

                                                                                                                                    {options2?.questions?.length > 0 && question2.answer.indexOf(question2.options[indexO2].id) !== -1 ? (
                                                                                                                                        options2?.questions?.map((question3, indexq3) => {
                                                                                                                                            // NIVEL 3 //////////////////////////
                                                                                                                                            return (
                                                                                                                                                <>
                                                                                                                                                    <div key={indexq3} className={classes.boxQuestion}>
                                                                                                                                                        <div className="questionDescription">{question3?.description}</div>
                                                                                                                                                        <div className="gridteste">
                                                                                                                                                            <div className={classes.separacaoOpcoes}></div>
                                                                                                                                                            <div className="opcoesDaPergunta">
                                                                                                                                                                {question3?.type === 3 ? (
                                                                                                                                                                    question3?.options.map((options3, indexO3) => {
                                                                                                                                                                        return (
                                                                                                                                                                            <>
                                                                                                                                                                                <div className={classes.multipleAnswerBox} key={indexO3}>
                                                                                                                                                                                    <input
                                                                                                                                                                                        disabled={finished ?? "disabled"}
                                                                                                                                                                                        type="checkbox"
                                                                                                                                                                                        id="check"
                                                                                                                                                                                        name="check"
                                                                                                                                                                                        defaultChecked={question3.answer.indexOf(question3.options[indexO3].id) !== -1 ?? "checked"}
                                                                                                                                                                                        className="checkbox"
                                                                                                                                                                                        onChange={(e) => {
                                                                                                                                                                                            if (question3.answer.indexOf(question3.options[indexO3].id) === -1) question3.answer.push(question3.options[indexO3].id);
                                                                                                                                                                                            else question3.answer.splice(question3.answer.indexOf(question3.options[indexO3].id), 1);
                                                                                                                                                                                            question3.options[indexO3].answer = e.target.checked;
                                                                                                                                                                                            setRefresh(refresh + 1);
                                                                                                                                                                                            autoSave(false, question3.id, question3.answer);
                                                                                                                                                                                        }}></input>
                                                                                                                                                                                    {options3?.description}
                                                                                                                                                                                    {/* Se a opção estiver marcada, abre as possiveis subperguntas */}

                                                                                                                                                                                    {options3?.questions?.length > 0 && question3.answer.indexOf(question3.options[indexO3].id) !== -1 ? (
                                                                                                                                                                                        options3?.questions?.map((question4, indexq4) => {
                                                                                                                                                                                            // NIVEL 4 //////////////////////////
                                                                                                                                                                                            return (
                                                                                                                                                                                                <>
                                                                                                                                                                                                    <div key={indexq4} className={classes.boxQuestion}>
                                                                                                                                                                                                        <div className="questionDescription">{question4?.description}</div>
                                                                                                                                                                                                        <div className="gridteste">
                                                                                                                                                                                                            <div className={classes.separacaoOpcoes}></div>
                                                                                                                                                                                                            <div className="opcoesDaPergunta">
                                                                                                                                                                                                                {question4?.type === 3 ? (
                                                                                                                                                                                                                    question4?.options.map((options4, indexO4) => {
                                                                                                                                                                                                                        return (
                                                                                                                                                                                                                            <>
                                                                                                                                                                                                                                <div className={classes.multipleAnswerBox} key={indexO4}>
                                                                                                                                                                                                                                    <input
                                                                                                                                                                                                                                        disabled={finished ?? "disabled"}
                                                                                                                                                                                                                                        type="checkbox"
                                                                                                                                                                                                                                        id="check"
                                                                                                                                                                                                                                        name="check"
                                                                                                                                                                                                                                        defaultChecked={question4.answer.indexOf(question4.options[indexO4].id) !== -1 ?? "checked"}
                                                                                                                                                                                                                                        className="checkbox"
                                                                                                                                                                                                                                        onChange={(e) => {
                                                                                                                                                                                                                                            if (question4.answer.indexOf(question4.options[indexO4].id) === -1) question4.answer.push(question4.options[indexO4].id);
                                                                                                                                                                                                                                            else question4.answer.splice(question4.answer.indexOf(question4.options[indexO4].id), 1);
                                                                                                                                                                                                                                            question4.options[indexO4].answer = e.target.checked;
                                                                                                                                                                                                                                            setRefresh(refresh + 1);
                                                                                                                                                                                                                                            autoSave(false, question4.id, question4.answer);
                                                                                                                                                                                                                                        }}></input>
                                                                                                                                                                                                                                    {options4?.description}
                                                                                                                                                                                                                                </div>
                                                                                                                                                                                                                            </>
                                                                                                                                                                                                                        );
                                                                                                                                                                                                                    })
                                                                                                                                                                                                                ) : question4?.type === 1 ? (
                                                                                                                                                                                                                    <textarea
                                                                                                                                                                                                                        type="text"
                                                                                                                                                                                                                        id="answer"
                                                                                                                                                                                                                        name="answer"
                                                                                                                                                                                                                        placeholder="Digite sua resposta aqui"
                                                                                                                                                                                                                        disabled={finished ?? "disabled"}
                                                                                                                                                                                                                        className={classes.inputTextAnswer}
                                                                                                                                                                                                                        onChange={(e) => (question4.answer = e.target.value)}
                                                                                                                                                                                                                        defaultValue={question4.answer ?? question4.answer}
                                                                                                                                                                                                                        onBlur={() => autoSave(false, question4.id, question4.answer)}></textarea>
                                                                                                                                                                                                                ) : question4?.type === 2 ? (
                                                                                                                                                                                                                    question4?.options.map((options4, indexO4) => {
                                                                                                                                                                                                                        return (
                                                                                                                                                                                                                            <div className={classes.multipleAnswerBox} key={indexO4}>
                                                                                                                                                                                                                                <input
                                                                                                                                                                                                                                    disabled={finished ?? "disabled"}
                                                                                                                                                                                                                                    type="radio"
                                                                                                                                                                                                                                    id="radio4"
                                                                                                                                                                                                                                    defaultChecked={question4.answer === options4.id ?? "checked"}
                                                                                                                                                                                                                                    name={question4.id}
                                                                                                                                                                                                                                    className="radiobox"
                                                                                                                                                                                                                                    onClick={() => {
                                                                                                                                                                                                                                        question4.answer = options4.id;
                                                                                                                                                                                                                                        setRefresh(refresh + 1);
                                                                                                                                                                                                                                        autoSave(false, question4.id, question4.answer);
                                                                                                                                                                                                                                    }}></input>
                                                                                                                                                                                                                                {options4?.description}
                                                                                                                                                                                                                            </div>
                                                                                                                                                                                                                        );
                                                                                                                                                                                                                    })
                                                                                                                                                                                                                ) : question4?.type === 4 ? (
                                                                                                                                                                                                                    <CurrencyTextField
                                                                                                                                                                                                                        disabled={finished ?? "disabled"}
                                                                                                                                                                                                                        variant="outlined"
                                                                                                                                                                                                                        // value={0.00}
                                                                                                                                                                                                                        className={classes.inputMonetaryAnswer}
                                                                                                                                                                                                                        currencySymbol="R$"
                                                                                                                                                                                                                        outputFormat="number"
                                                                                                                                                                                                                        decimalCharacter=","
                                                                                                                                                                                                                        // required={true}
                                                                                                                                                                                                                        digitGroupSeparator="."
                                                                                                                                                                                                                        onChange={(e) => (question4.answer = e.target.value)}
                                                                                                                                                                                                                        defaultValue={question4.answer ?? question4.answer}
                                                                                                                                                                                                                        onBlur={() => autoSave(false, question4.id, question4.answer)}
                                                                                                                                                                                                                    />
                                                                                                                                                                                                                ) : question4?.type === 5 ? (
                                                                                                                                                                                                                  <div className="containerTipoPerguntaPorcentagem">
                                                                                                                                                                                                                    <TextField
                                                                                                                                                                                                                        type="number"
                                                                                                                                                                                                                        onKeyPress={(e) => {
                                                                                                                                                                                                                            if (e.code === "Backquote") e.preventDefault();
                                                                                                                                                                                                                        }}
                                                                                                                                                                                                                        step="0.00"
                                                                                                                                                                                                                        onKeyDown={(e) => {
                                                                                                                                                                                                                            if (e.key === "e" || e.key === "E") {
                                                                                                                                                                                                                                e.preventDefault();
                                                                                                                                                                                                                            }
                                                                                                                                                                                                                        }}
                                                                                                                                                                                                                        variant="outlined"
                                                                                                                                                                                                                        onBlur={() => autoSave(false, question4.id, question4.answer)}
                                                                                                                                                                                                                        disabled={finished ?? "disabled"}
                                                                                                                                                                                                                        id="answer"
                                                                                                                                                                                                                        name="answer"
                                                                                                                                                                                                                        className={classes.inputPercentageAnswer}
                                                                                                                                                                                                                        onChange={(e) => (question4.answer = e.target.value.replace(",", "."))}
                                                                                                                                                                                                                        defaultValue={question4.answer ?? question4.answer}
                                                                                                                                                                                                                    /><div className="porcentagem">%</div>
                                                                                                                                                                                                                    </div>
                                                                                                                                                                                                                             
                                                                                                                                                                                                                ) : question4?.type === 6 ? (
                                                                                                                                                                                                                    <div className="containerTipoPerguntaPorcentagem">
                                                                                                                                                                                                                        <TextField
                                                                                                                                                                                                                            type="number"
                                                                                                                                                                                                                            onKeyPress={(e) => {
                                                                                                                                                                                                                                if (e.key === "." || e.key === ",") e.preventDefault();
                                                                                                                                                                                                                            }}
                                                                                                                                                                                                                            variant="outlined"
                                                                                                                                                                                                                            onBlur={() => autoSave(false, question4.id, question4.answer)}
                                                                                                                                                                                                                            disabled={finished ?? "disabled"}
                                                                                                                                                                                                                            id="answer"
                                                                                                                                                                                                                            name="answer"
                                                                                                                                                                                                                            className={classes.inputPercentageAnswer}
                                                                                                                                                                                                                            onChange={(e) => (question4.answer = e.target.value)}
                                                                                                                                                                                                                            defaultValue={question4.answer ?? question4.answer}
                                                                                                                                                                                                                        />
                                                                                                                                                                                                                    </div>
                                                                                                                                                                                                                ) : question4?.type === 7 ? (
                                                                                                                                                                                                                    <div className="containerTipoPerguntaPorcentagem">
                                                                                                                                                                                                                        <TextField
                                                                                                                                                                                                                            type="number"
                                                                                                                                                                                                                            variant="outlined"
                                                                                                                                                                                                                            onBlur={() => autoSave(false, question4.id, question4.answer)}
                                                                                                                                                                                                                            disabled={finished ?? "disabled"}
                                                                                                                                                                                                                            id="answer"
                                                                                                                                                                                                                            name="answer"
                                                                                                                                                                                                                            className={classes.inputPercentageAnswer}
                                                                                                                                                                                                                            onChange={(e) => (question4.answer = e.target.value.replace(",", "."))}
                                                                                                                                                                                                                            defaultValue={question4.answer ?? question4.answer}
                                                                                                                                                                                                                        />
                                                                                                                                                                                                                    </div>
                                                                                                                                                                                                                ) : (
                                                                                                                                                                                                                    <></>
                                                                                                                                                                                                                )}
                                                                                                                                                                                                            </div>
                                                                                                                                                                                                        </div>
                                                                                                                                                                                                        {question4?.observation === true ? (
                                                                                                                                                                                                            <div className="observation">
                                                                                                                                                                                                                <p className="observationText">Observações:</p>
                                                                                                                                                                                                                <textarea
                                                                                                                                                                                                                    disabled={finished ?? "disabled"}
                                                                                                                                                                                                                    type="text"
                                                                                                                                                                                                                    id="obs"
                                                                                                                                                                                                                    name="obs"
                                                                                                                                                                                                                    placeholder="Observações aqui"
                                                                                                                                                                                                                    className={classes.inputTextAnswer}
                                                                                                                                                                                                                    onChange={(e) => (question4.answer_observation = e.target.value)}
                                                                                                                                                                                                                    defaultValue={question4.answer_observation ?? question4.answer_observation}
                                                                                                                                                                                                                    onBlur={() => autoSave(true, question4.id, question4.answer_observation)}></textarea>
                                                                                                                                                                                                            </div>
                                                                                                                                                                                                        ) : (
                                                                                                                                                                                                            <></>
                                                                                                                                                                                                        )}
                                                                                                                                                                                                    </div>
                                                                                                                                                                                                </>
                                                                                                                                                                                            );
                                                                                                                                                                                        })
                                                                                                                                                                                    ) : (
                                                                                                                                                                                        <></>
                                                                                                                                                                                    )}
                                                                                                                                                                                </div>
                                                                                                                                                                            </>
                                                                                                                                                                        );
                                                                                                                                                                    })
                                                                                                                                                                ) : question3?.type === 1 ? (
                                                                                                                                                                    <textarea
                                                                                                                                                                        type="text"
                                                                                                                                                                        id="answer"
                                                                                                                                                                        name="answer"
                                                                                                                                                                        placeholder="Digite sua resposta aqui"
                                                                                                                                                                        disabled={finished ?? "disabled"}
                                                                                                                                                                        className={classes.inputTextAnswer}
                                                                                                                                                                        onChange={(e) => (question3.answer = e.target.value)}
                                                                                                                                                                        defaultValue={question3.answer ?? question3.answer}
                                                                                                                                                                        onBlur={() => autoSave(false, question3.id, question3.answer)}></textarea>
                                                                                                                                                                ) : question3?.type === 2 ? (
                                                                                                                                                                    question3?.options.map((options3, indexO3) => {
                                                                                                                                                                        return (
                                                                                                                                                                            <div className={classes.multipleAnswerBox} key={indexO3}>
                                                                                                                                                                                <input
                                                                                                                                                                                    disabled={finished ?? "disabled"}
                                                                                                                                                                                    type="radio"
                                                                                                                                                                                    id="radio3"
                                                                                                                                                                                    defaultChecked={question3.answer === options3.id ?? "checked"}
                                                                                                                                                                                    name={question3.id}
                                                                                                                                                                                    className="radiobox"
                                                                                                                                                                                    onClick={() => {
                                                                                                                                                                                        question3.answer = options3.id;
                                                                                                                                                                                        setRefresh(refresh + 1);
                                                                                                                                                                                        autoSave(false, question3.id, question3.answer);
                                                                                                                                                                                    }}></input>
                                                                                                                                                                                {options3?.description}
                                                                                                                                                                                {/* Se a opção estiver marcada, abre as possiveis subperguntas */}
                                                                                                                                                                                {options3?.questions?.length > 0 && question3?.answer === options3?.id ? (
                                                                                                                                                                                    options3?.questions?.map((question4, indexq4) => {
                                                                                                                                                                                        // NIVEL 4 //////////////////////////
                                                                                                                                                                                        return (
                                                                                                                                                                                            <>
                                                                                                                                                                                                <div key={indexq4} className={classes.boxQuestion}>
                                                                                                                                                                                                    <div className="questionDescription">{question4?.description}</div>
                                                                                                                                                                                                    <div className="gridteste">
                                                                                                                                                                                                        <div className={classes.separacaoOpcoes}></div>
                                                                                                                                                                                                        <div className="opcoesDaPergunta">
                                                                                                                                                                                                            {question4?.type === 3 ? (
                                                                                                                                                                                                                question4?.options.map((options4, indexO4) => {
                                                                                                                                                                                                                    return (
                                                                                                                                                                                                                        <>
                                                                                                                                                                                                                            <div className={classes.multipleAnswerBox} key={indexO4}>
                                                                                                                                                                                                                                <input
                                                                                                                                                                                                                                    disabled={finished ?? "disabled"}
                                                                                                                                                                                                                                    type="checkbox"
                                                                                                                                                                                                                                    id="check"
                                                                                                                                                                                                                                    name="check"
                                                                                                                                                                                                                                    defaultChecked={question4.answer.indexOf(question4.options[indexO4].id) !== -1 ?? "checked"}
                                                                                                                                                                                                                                    className="checkbox"
                                                                                                                                                                                                                                    onChange={(e) => {
                                                                                                                                                                                                                                        if (question4.answer.indexOf(question4.options[indexO4].id) === -1) question4.answer.push(question4.options[indexO4].id);
                                                                                                                                                                                                                                        else question4.answer.splice(question4.answer.indexOf(question4.options[indexO4].id), 1);
                                                                                                                                                                                                                                        question4.options[indexO4].answer = e.target.checked;
                                                                                                                                                                                                                                        setRefresh(refresh + 1);
                                                                                                                                                                                                                                        autoSave(false, question4.id, question4.answer);
                                                                                                                                                                                                                                    }}></input>
                                                                                                                                                                                                                                {options4?.description}
                                                                                                                                                                                                                            </div>
                                                                                                                                                                                                                        </>
                                                                                                                                                                                                                    );
                                                                                                                                                                                                                })
                                                                                                                                                                                                            ) : question4?.type === 1 ? (
                                                                                                                                                                                                                <textarea
                                                                                                                                                                                                                    type="text"
                                                                                                                                                                                                                    id="answer"
                                                                                                                                                                                                                    name="answer"
                                                                                                                                                                                                                    placeholder="Digite sua resposta aqui"
                                                                                                                                                                                                                    disabled={finished ?? "disabled"}
                                                                                                                                                                                                                    className={classes.inputTextAnswer}
                                                                                                                                                                                                                    onChange={(e) => (question4.answer = e.target.value)}
                                                                                                                                                                                                                    defaultValue={question4.answer ?? question4.answer}
                                                                                                                                                                                                                    onBlur={() => autoSave(false, question4.id, question4.answer)}></textarea>
                                                                                                                                                                                                            ) : question4?.type === 2 ? (
                                                                                                                                                                                                                question4?.options.map((options4, indexO4) => {
                                                                                                                                                                                                                    return (
                                                                                                                                                                                                                        <div className={classes.multipleAnswerBox} key={indexO4}>
                                                                                                                                                                                                                            <input
                                                                                                                                                                                                                                disabled={finished ?? "disabled"}
                                                                                                                                                                                                                                type="radio"
                                                                                                                                                                                                                                id="radio4"
                                                                                                                                                                                                                                defaultChecked={question4.answer === options4.id ?? "checked"}
                                                                                                                                                                                                                                name={question4.id}
                                                                                                                                                                                                                                className="radiobox"
                                                                                                                                                                                                                                onClick={() => {
                                                                                                                                                                                                                                    question4.answer = options4.id;
                                                                                                                                                                                                                                    setRefresh(refresh + 1);
                                                                                                                                                                                                                                    autoSave(false, question4.id, question4.answer);
                                                                                                                                                                                                                                }}></input>
                                                                                                                                                                                                                            {options4?.description}
                                                                                                                                                                                                                        </div>
                                                                                                                                                                                                                    );
                                                                                                                                                                                                                })
                                                                                                                                                                                                            ) : question4?.type === 4 ? (
                                                                                                                                                                                                                <CurrencyTextField
                                                                                                                                                                                                                    disabled={finished ?? "disabled"}
                                                                                                                                                                                                                    variant="outlined"
                                                                                                                                                                                                                    // value={0.00}
                                                                                                                                                                                                                    className={classes.inputMonetaryAnswer}
                                                                                                                                                                                                                    currencySymbol="R$"
                                                                                                                                                                                                                    outputFormat="number"
                                                                                                                                                                                                                    decimalCharacter=","
                                                                                                                                                                                                                    // required={true}
                                                                                                                                                                                                                    digitGroupSeparator="."
                                                                                                                                                                                                                    onChange={(e) => (question4.answer = e.target.value)}
                                                                                                                                                                                                                    defaultValue={question4.answer ?? question4.answer}
                                                                                                                                                                                                                    onBlur={() => autoSave(false, question4.id, question4.answer)}
                                                                                                                                                                                                                />
                                                                                                                                                                                                            ) : question4?.type === 5 ? (
                                                                                                                                                                                                              <div className="containerTipoPerguntaPorcentagem">
                                                                                                                                                                                                                <TextField
                                                                                                                                                                                                                    type="number"
                                                                                                                                                                                                                    onKeyPress={(e) => {
                                                                                                                                                                                                                        if (e.code === "Backquote") e.preventDefault();
                                                                                                                                                                                                                    }}
                                                                                                                                                                                                                    step="0.00"
                                                                                                                                                                                                                    onKeyDown={(e) => {
                                                                                                                                                                                                                        if (e.key === "e" || e.key === "E") {
                                                                                                                                                                                                                            e.preventDefault();
                                                                                                                                                                                                                        }
                                                                                                                                                                                                                    }}
                                                                                                                                                                                                                    variant="outlined"
                                                                                                                                                                                                                    onBlur={() => autoSave(false, question4.id, question4.answer)}
                                                                                                                                                                                                                    disabled={finished ?? "disabled"}
                                                                                                                                                                                                                    id="answer"
                                                                                                                                                                                                                    name="answer"
                                                                                                                                                                                                                    className={classes.inputPercentageAnswer}
                                                                                                                                                                                                                    onChange={(e) => (question4.answer = e.target.value.replace(",", "."))}
                                                                                                                                                                                                                    defaultValue={question4.answer ?? question4.answer}
                                                                                                                                                                                                                />
                                                                                                                                                                                                                <div className="porcentagem">%</div>
</div>
           
                                                                                                                                                                                                            ) : question4?.type === 6 ? (
                                                                                                                                                                                                                <div className="containerTipoPerguntaPorcentagem">
                                                                                                                                                                                                                    <TextField
                                                                                                                                                                                                                        type="number"
                                                                                                                                                                                                                        onKeyPress={(e) => {
                                                                                                                                                                                                                            if (e.key === "." || e.key === ",") e.preventDefault();
                                                                                                                                                                                                                        }}
                                                                                                                                                                                                                        variant="outlined"
                                                                                                                                                                                                                        onBlur={() => autoSave(false, question4.id, question4.answer)}
                                                                                                                                                                                                                        disabled={finished ?? "disabled"}
                                                                                                                                                                                                                        id="answer"
                                                                                                                                                                                                                        name="answer"
                                                                                                                                                                                                                        className={classes.inputPercentageAnswer}
                                                                                                                                                                                                                        onChange={(e) => (question4.answer = e.target.value)}
                                                                                                                                                                                                                        defaultValue={question4.answer ?? question4.answer}
                                                                                                                                                                                                                    />
                                                                                                                                                                                                                </div>
                                                                                                                                                                                                            ) : question4?.type === 7 ? (
                                                                                                                                                                                                                <div className="containerTipoPerguntaPorcentagem">
                                                                                                                                                                                                                    <TextField
                                                                                                                                                                                                                        type="number"
                                                                                                                                                                                                                        variant="outlined"
                                                                                                                                                                                                                        onBlur={() => autoSave(false, question4.id, question4.answer)}
                                                                                                                                                                                                                        disabled={finished ?? "disabled"}
                                                                                                                                                                                                                        id="answer"
                                                                                                                                                                                                                        name="answer"
                                                                                                                                                                                                                        className={classes.inputPercentageAnswer}
                                                                                                                                                                                                                        onChange={(e) => (question4.answer = e.target.value.replace(",", "."))}
                                                                                                                                                                                                                        defaultValue={question4.answer ?? question4.answer}
                                                                                                                                                                                                                    />
                                                                                                                                                                                                                </div>
                                                                                                                                                                                                            ) : (
                                                                                                                                                                                                                <></>
                                                                                                                                                                                                            )}
                                                                                                                                                                                                        </div>
                                                                                                                                                                                                    </div>
                                                                                                                                                                                                    {question4?.observation === true ? (
                                                                                                                                                                                                        <div className="observation">
                                                                                                                                                                                                            <p className="observationText">Observações:</p>
                                                                                                                                                                                                            <textarea
                                                                                                                                                                                                                disabled={finished ?? "disabled"}
                                                                                                                                                                                                                type="text"
                                                                                                                                                                                                                id="obs"
                                                                                                                                                                                                                name="obs"
                                                                                                                                                                                                                placeholder="Observações aqui"
                                                                                                                                                                                                                className={classes.inputTextAnswer}
                                                                                                                                                                                                                onChange={(e) => (question4.answer_observation = e.target.value)}
                                                                                                                                                                                                                defaultValue={question4.answer_observation ?? question4.answer_observation}
                                                                                                                                                                                                                onBlur={() => autoSave(true, question4.id, question4.answer_observation)}></textarea>
                                                                                                                                                                                                        </div>
                                                                                                                                                                                                    ) : (
                                                                                                                                                                                                        <></>
                                                                                                                                                                                                    )}
                                                                                                                                                                                                </div>
                                                                                                                                                                                            </>
                                                                                                                                                                                        );
                                                                                                                                                                                    })
                                                                                                                                                                                ) : (
                                                                                                                                                                                    <></>
                                                                                                                                                                                )}
                                                                                                                                                                            </div>
                                                                                                                                                                        );
                                                                                                                                                                    })
                                                                                                                                                                ) : question3?.type === 4 ? (
                                                                                                                                                                    <CurrencyTextField
                                                                                                                                                                        disabled={finished ?? "disabled"}
                                                                                                                                                                        variant="outlined"
                                                                                                                                                                        // value={0.00}
                                                                                                                                                                        className={classes.inputMonetaryAnswer}
                                                                                                                                                                        currencySymbol="R$"
                                                                                                                                                                        outputFormat="number"
                                                                                                                                                                        decimalCharacter=","
                                                                                                                                                                        // required={true}
                                                                                                                                                                        digitGroupSeparator="."
                                                                                                                                                                        onChange={(e) => (question3.answer = e.target.value)}
                                                                                                                                                                        defaultValue={question3.answer ?? question3.answer}
                                                                                                                                                                        onBlur={() => autoSave(false, question3.id, question3.answer)}
                                                                                                                                                                    />
                                                                                                                                                                ) : question3?.type === 5 ? (
                                                                                                                                                                  <div className="containerTipoPerguntaPorcentagem">
<div className="containerTipoPerguntaPorcentagem">
                                                                                                                                                                    <TextField
                                                                                                                                                                        type="number"
                                                                                                                                                                        onKeyPress={(e) => {
                                                                                                                                                                            if (e.code === "Backquote") e.preventDefault();
                                                                                                                                                                        }}
                                                                                                                                                                        step="0.00"
                                                                                                                                                                        onKeyDown={(e) => {
                                                                                                                                                                            if (e.key === "e" || e.key === "E") {
                                                                                                                                                                                e.preventDefault();
                                                                                                                                                                            }
                                                                                                                                                                        }}
                                                                                                                                                                        variant="outlined"
                                                                                                                                                                        onBlur={() => autoSave(false, question3.id, question3.answer)}
                                                                                                                                                                        disabled={finished ?? "disabled"}
                                                                                                                                                                        id="answer"
                                                                                                                                                                        name="answer"
                                                                                                                                                                        className={classes.inputPercentageAnswer}
                                                                                                                                                                        onChange={(e) => (question3.answer = e.target.value.replace(",", "."))}
                                                                                                                                                                        defaultValue={question3.answer ?? question3.answer}
                                                                                                                                                                    />
                                                                                                                                                                    <div className="porcentagem">%</div>
</div>
<div className="porcentagem">%</div>
</div>
                                                                                                                                                                ) : question3?.type === 6 ? (
                                                                                                                                                                    <div className="containerTipoPerguntaPorcentagem">
                                                                                                                                                                        <TextField
                                                                                                                                                                            type="number"
                                                                                                                                                                            onKeyPress={(e) => {
                                                                                                                                                                                if (e.key === "." || e.key === ",") e.preventDefault();
                                                                                                                                                                            }}
                                                                                                                                                                            variant="outlined"
                                                                                                                                                                            onBlur={() => autoSave(false, question3.id, question3.answer)}
                                                                                                                                                                            disabled={finished ?? "disabled"}
                                                                                                                                                                            id="answer"
                                                                                                                                                                            name="answer"
                                                                                                                                                                            className={classes.inputPercentageAnswer}
                                                                                                                                                                            onChange={(e) => (question3.answer = e.target.value)}
                                                                                                                                                                            defaultValue={question3.answer ?? question3.answer}
                                                                                                                                                                        />
                                                                                                                                                                    </div>
                                                                                                                                                                ) : question3?.type === 7 ? (
                                                                                                                                                                    <div className="containerTipoPerguntaPorcentagem">
                                                                                                                                                                        <TextField
                                                                                                                                                                            type="number"
                                                                                                                                                                            variant="outlined"
                                                                                                                                                                            onBlur={() => autoSave(false, question3.id, question3.answer)}
                                                                                                                                                                            disabled={finished ?? "disabled"}
                                                                                                                                                                            id="answer"
                                                                                                                                                                            name="answer"
                                                                                                                                                                            className={classes.inputPercentageAnswer}
                                                                                                                                                                            onChange={(e) => (question3.answer = e.target.value.replace(",", "."))}
                                                                                                                                                                            defaultValue={question3.answer ?? question3.answer}
                                                                                                                                                                        />
                                                                                                                                                                    </div>
                                                                                                                                                                ) : (
                                                                                                                                                                    <></>
                                                                                                                                                                )}
                                                                                                                                                            </div>
                                                                                                                                                        </div>
                                                                                                                                                        {question3?.observation === true ? (
                                                                                                                                                            <div className="observation">
                                                                                                                                                                <p className="observationText">Observações:</p>
                                                                                                                                                                <textarea
                                                                                                                                                                    disabled={finished ?? "disabled"}
                                                                                                                                                                    type="text"
                                                                                                                                                                    id="obs"
                                                                                                                                                                    name="obs"
                                                                                                                                                                    placeholder="Observações aqui"
                                                                                                                                                                    className={classes.inputTextAnswer}
                                                                                                                                                                    onChange={(e) => (question3.answer_observation = e.target.value)}
                                                                                                                                                                    defaultValue={question3.answer_observation ?? question3.answer_observation}
                                                                                                                                                                    onBlur={() => autoSave(true, question3.id, question3.answer_observation)}></textarea>
                                                                                                                                                            </div>
                                                                                                                                                        ) : (
                                                                                                                                                            <></>
                                                                                                                                                        )}
                                                                                                                                                    </div>
                                                                                                                                                </>
                                                                                                                                            );
                                                                                                                                        })
                                                                                                                                    ) : (
                                                                                                                                        <></>
                                                                                                                                    )}
                                                                                                                                </div>
                                                                                                                            </>
                                                                                                                        );
                                                                                                                    })
                                                                                                                ) : question2?.type === 1 ? (
                                                                                                                    <textarea type="text" id="answer" name="answer" placeholder="Digite sua resposta aqui" disabled={finished ?? "disabled"} className={classes.inputTextAnswer} onChange={(e) => (question2.answer = e.target.value)} defaultValue={question2.answer ?? question2.answer} onBlur={() => autoSave(false, question2.id, question2.answer)}></textarea>
                                                                                                                ) : question2?.type === 2 ? (
                                                                                                                    question2?.options.map((options2, indexO2) => {
                                                                                                                        return (
                                                                                                                            <div className={classes.multipleAnswerBox} key={indexO2}>
                                                                                                                                <input
                                                                                                                                    disabled={finished ?? "disabled"}
                                                                                                                                    type="radio"
                                                                                                                                    id="radio2"
                                                                                                                                    defaultChecked={question2.answer === options2.id ?? "checked"}
                                                                                                                                    name={question2.id}
                                                                                                                                    className="radiobox"
                                                                                                                                    onClick={() => {
                                                                                                                                        question2.answer = options2.id;
                                                                                                                                        setRefresh(refresh + 1);
                                                                                                                                        autoSave(false, question2.id, question2.answer);
                                                                                                                                    }}></input>
                                                                                                                                {options2?.description}
                                                                                                                                {/* Se a opção estiver marcada, abre as possiveis subperguntas */}
                                                                                                                                {options2?.questions?.length > 0 && question2?.answer === options2?.id ? (
                                                                                                                                    options2?.questions?.map((question3, indexq3) => {
                                                                                                                                        // NIVEL 3 //////////////////////////
                                                                                                                                        return (
                                                                                                                                            <>
                                                                                                                                                <div key={indexq3} className={classes.boxQuestion}>
                                                                                                                                                    <div className="questionDescription">{question3?.description}</div>
                                                                                                                                                    <div className="gridteste">
                                                                                                                                                        <div className={classes.separacaoOpcoes}></div>
                                                                                                                                                        <div className="opcoesDaPergunta">
                                                                                                                                                            {question3?.type === 3 ? (
                                                                                                                                                                question3?.options.map((options3, indexO3) => {
                                                                                                                                                                    return (
                                                                                                                                                                        <>
                                                                                                                                                                            <div className={classes.multipleAnswerBox} key={indexO3}>
                                                                                                                                                                                <input
                                                                                                                                                                                    disabled={finished ?? "disabled"}
                                                                                                                                                                                    type="checkbox"
                                                                                                                                                                                    id="check"
                                                                                                                                                                                    name="check"
                                                                                                                                                                                    defaultChecked={question3.answer.indexOf(question3.options[indexO3].id) !== -1 ?? "checked"}
                                                                                                                                                                                    className="checkbox"
                                                                                                                                                                                    onChange={(e) => {
                                                                                                                                                                                        if (question3.answer.indexOf(question3.options[indexO3].id) === -1) question3.answer.push(question3.options[indexO3].id);
                                                                                                                                                                                        else question3.answer.splice(question3.answer.indexOf(question3.options[indexO3].id), 1);
                                                                                                                                                                                        question3.options[indexO3].answer = e.target.checked;
                                                                                                                                                                                        setRefresh(refresh + 1);
                                                                                                                                                                                        autoSave(false, question3.id, question3.answer);
                                                                                                                                                                                    }}></input>
                                                                                                                                                                                {options3?.description}
                                                                                                                                                                                {/* Se a opção estiver marcada, abre as possiveis subperguntas */}

                                                                                                                                                                                {options3?.questions?.length > 0 && question3.answer.indexOf(question3.options[indexO3].id) !== -1 ? (
                                                                                                                                                                                    options3?.questions?.map((question4, indexq4) => {
                                                                                                                                                                                        // NIVEL 4 //////////////////////////
                                                                                                                                                                                        return (
                                                                                                                                                                                            <>
                                                                                                                                                                                                <div key={indexq4} className={classes.boxQuestion}>
                                                                                                                                                                                                    <div className="questionDescription">{question4?.description}</div>
                                                                                                                                                                                                    <div className="gridteste">
                                                                                                                                                                                                        <div className={classes.separacaoOpcoes}></div>
                                                                                                                                                                                                        <div className="opcoesDaPergunta">
                                                                                                                                                                                                            {question4?.type === 3 ? (
                                                                                                                                                                                                                question4?.options.map((options4, indexO4) => {
                                                                                                                                                                                                                    return (
                                                                                                                                                                                                                        <>
                                                                                                                                                                                                                            <div className={classes.multipleAnswerBox} key={indexO4}>
                                                                                                                                                                                                                                <input
                                                                                                                                                                                                                                    disabled={finished ?? "disabled"}
                                                                                                                                                                                                                                    type="checkbox"
                                                                                                                                                                                                                                    id="check"
                                                                                                                                                                                                                                    name="check"
                                                                                                                                                                                                                                    defaultChecked={question4.answer.indexOf(question4.options[indexO4].id) !== -1 ?? "checked"}
                                                                                                                                                                                                                                    className="checkbox"
                                                                                                                                                                                                                                    onChange={(e) => {
                                                                                                                                                                                                                                        if (question4.answer.indexOf(question4.options[indexO4].id) === -1) question4.answer.push(question4.options[indexO4].id);
                                                                                                                                                                                                                                        else question4.answer.splice(question4.answer.indexOf(question4.options[indexO4].id), 1);
                                                                                                                                                                                                                                        question4.options[indexO4].answer = e.target.checked;
                                                                                                                                                                                                                                        setRefresh(refresh + 1);
                                                                                                                                                                                                                                        autoSave(false, question4.id, question4.answer);
                                                                                                                                                                                                                                    }}></input>
                                                                                                                                                                                                                                {options4?.description}
                                                                                                                                                                                                                            </div>
                                                                                                                                                                                                                        </>
                                                                                                                                                                                                                    );
                                                                                                                                                                                                                })
                                                                                                                                                                                                            ) : question4?.type === 1 ? (
                                                                                                                                                                                                                <textarea
                                                                                                                                                                                                                    type="text"
                                                                                                                                                                                                                    id="answer"
                                                                                                                                                                                                                    name="answer"
                                                                                                                                                                                                                    placeholder="Digite sua resposta aqui"
                                                                                                                                                                                                                    disabled={finished ?? "disabled"}
                                                                                                                                                                                                                    className={classes.inputTextAnswer}
                                                                                                                                                                                                                    onChange={(e) => (question4.answer = e.target.value)}
                                                                                                                                                                                                                    defaultValue={question4.answer ?? question4.answer}
                                                                                                                                                                                                                    onBlur={() => autoSave(false, question4.id, question4.answer)}></textarea>
                                                                                                                                                                                                            ) : question4?.type === 2 ? (
                                                                                                                                                                                                                question4?.options.map((options4, indexO4) => {
                                                                                                                                                                                                                    return (
                                                                                                                                                                                                                        <div className={classes.multipleAnswerBox} key={indexO4}>
                                                                                                                                                                                                                            <input
                                                                                                                                                                                                                                disabled={finished ?? "disabled"}
                                                                                                                                                                                                                                type="radio"
                                                                                                                                                                                                                                id="radio4"
                                                                                                                                                                                                                                defaultChecked={question4.answer === options4.id ?? "checked"}
                                                                                                                                                                                                                                name={question4.id}
                                                                                                                                                                                                                                className="radiobox"
                                                                                                                                                                                                                                onClick={() => {
                                                                                                                                                                                                                                    question4.answer = options4.id;
                                                                                                                                                                                                                                    setRefresh(refresh + 1);
                                                                                                                                                                                                                                    autoSave(false, question4.id, question4.answer);
                                                                                                                                                                                                                                }}></input>
                                                                                                                                                                                                                            {options4?.description}
                                                                                                                                                                                                                        </div>
                                                                                                                                                                                                                    );
                                                                                                                                                                                                                })
                                                                                                                                                                                                            ) : question4?.type === 4 ? (
                                                                                                                                                                                                                <CurrencyTextField
                                                                                                                                                                                                                    disabled={finished ?? "disabled"}
                                                                                                                                                                                                                    variant="outlined"
                                                                                                                                                                                                                    // value={0.00}
                                                                                                                                                                                                                    className={classes.inputMonetaryAnswer}
                                                                                                                                                                                                                    currencySymbol="R$"
                                                                                                                                                                                                                    outputFormat="number"
                                                                                                                                                                                                                    decimalCharacter=","
                                                                                                                                                                                                                    // required={true}
                                                                                                                                                                                                                    digitGroupSeparator="."
                                                                                                                                                                                                                    onChange={(e) => (question4.answer = e.target.value)}
                                                                                                                                                                                                                    defaultValue={question4.answer ?? question4.answer}
                                                                                                                                                                                                                    onBlur={() => autoSave(false, question4.id, question4.answer)}
                                                                                                                                                                                                                />
                                                                                                                                                                                                            ) : question4?.type === 5 ? (
                                                                                                                                                                                                              <div className="containerTipoPerguntaPorcentagem">
                                                                                                                                                                                                                <TextField
                                                                                                                                                                                                                    type="number"
                                                                                                                                                                                                                    onKeyPress={(e) => {
                                                                                                                                                                                                                        if (e.code === "Backquote") e.preventDefault();
                                                                                                                                                                                                                    }}
                                                                                                                                                                                                                    step="0.00"
                                                                                                                                                                                                                    onKeyDown={(e) => {
                                                                                                                                                                                                                        if (e.key === "e" || e.key === "E") {
                                                                                                                                                                                                                            e.preventDefault();
                                                                                                                                                                                                                        }
                                                                                                                                                                                                                    }}
                                                                                                                                                                                                                    variant="outlined"
                                                                                                                                                                                                                    onBlur={() => autoSave(false, question4.id, question4.answer)}
                                                                                                                                                                                                                    disabled={finished ?? "disabled"}
                                                                                                                                                                                                                    id="answer"
                                                                                                                                                                                                                    name="answer"
                                                                                                                                                                                                                    className={classes.inputPercentageAnswer}
                                                                                                                                                                                                                    onChange={(e) => (question4.answer = e.target.value.replace(",", "."))}
                                                                                                                                                                                                                    defaultValue={question4.answer ?? question4.answer}
                                                                                                                                                                                                                />
                                                                                                                                                                                                                <div className="porcentagem">%</div>
</div>
                                                                                                                                                                                                            ) : question4?.type === 6 ? (
                                                                                                                                                                                                                <div className="containerTipoPerguntaPorcentagem">
                                                                                                                                                                                                                    <TextField
                                                                                                                                                                                                                        type="number"
                                                                                                                                                                                                                        onKeyPress={(e) => {
                                                                                                                                                                                                                            if (e.key === "." || e.key === ",") e.preventDefault();
                                                                                                                                                                                                                        }}
                                                                                                                                                                                                                        variant="outlined"
                                                                                                                                                                                                                        onBlur={() => autoSave(false, question4.id, question4.answer)}
                                                                                                                                                                                                                        disabled={finished ?? "disabled"}
                                                                                                                                                                                                                        id="answer"
                                                                                                                                                                                                                        name="answer"
                                                                                                                                                                                                                        className={classes.inputPercentageAnswer}
                                                                                                                                                                                                                        onChange={(e) => (question4.answer = e.target.value)}
                                                                                                                                                                                                                        defaultValue={question4.answer ?? question4.answer}
                                                                                                                                                                                                                    />
                                                                                                                                                                                                                </div>
                                                                                                                                                                                                            ) : question4?.type === 7 ? (
                                                                                                                                                                                                                <div className="containerTipoPerguntaPorcentagem">
                                                                                                                                                                                                                    <TextField
                                                                                                                                                                                                                        type="number"
                                                                                                                                                                                                                        variant="outlined"
                                                                                                                                                                                                                        onBlur={() => autoSave(false, question4.id, question4.answer)}
                                                                                                                                                                                                                        disabled={finished ?? "disabled"}
                                                                                                                                                                                                                        id="answer"
                                                                                                                                                                                                                        name="answer"
                                                                                                                                                                                                                        className={classes.inputPercentageAnswer}
                                                                                                                                                                                                                        onChange={(e) => (question4.answer = e.target.value.replace(",", "."))}
                                                                                                                                                                                                                        defaultValue={question4.answer ?? question4.answer}
                                                                                                                                                                                                                    />
                                                                                                                                                                                                                </div>
                                                                                                                                                                                                            ) : (
                                                                                                                                                                                                                <></>
                                                                                                                                                                                                            )}
                                                                                                                                                                                                        </div>
                                                                                                                                                                                                    </div>
                                                                                                                                                                                                    {question4?.observation === true ? (
                                                                                                                                                                                                        <div className="observation">
                                                                                                                                                                                                            <p className="observationText">Observações:</p>
                                                                                                                                                                                                            <textarea
                                                                                                                                                                                                                disabled={finished ?? "disabled"}
                                                                                                                                                                                                                type="text"
                                                                                                                                                                                                                id="obs"
                                                                                                                                                                                                                name="obs"
                                                                                                                                                                                                                placeholder="Observações aqui"
                                                                                                                                                                                                                className={classes.inputTextAnswer}
                                                                                                                                                                                                                onChange={(e) => (question4.answer_observation = e.target.value)}
                                                                                                                                                                                                                defaultValue={question4.answer_observation ?? question4.answer_observation}
                                                                                                                                                                                                                onBlur={() => autoSave(true, question4.id, question4.answer_observation)}></textarea>
                                                                                                                                                                                                        </div>
                                                                                                                                                                                                    ) : (
                                                                                                                                                                                                        <></>
                                                                                                                                                                                                    )}
                                                                                                                                                                                                </div>
                                                                                                                                                                                            </>
                                                                                                                                                                                        );
                                                                                                                                                                                    })
                                                                                                                                                                                ) : (
                                                                                                                                                                                    <></>
                                                                                                                                                                                )}
                                                                                                                                                                            </div>
                                                                                                                                                                        </>
                                                                                                                                                                    );
                                                                                                                                                                })
                                                                                                                                                            ) : question3?.type === 1 ? (
                                                                                                                                                                <textarea type="text" id="answer" name="answer" placeholder="Digite sua resposta aqui" disabled={finished ?? "disabled"} className={classes.inputTextAnswer} onChange={(e) => (question3.answer = e.target.value)} defaultValue={question3.answer ?? question3.answer} onBlur={() => autoSave(false, question3.id, question3.answer)}></textarea>
                                                                                                                                                            ) : question3?.type === 2 ? (
                                                                                                                                                                question3?.options.map((options3, indexO3) => {
                                                                                                                                                                    return (
                                                                                                                                                                        <div className={classes.multipleAnswerBox} key={indexO3}>
                                                                                                                                                                            <input
                                                                                                                                                                                disabled={finished ?? "disabled"}
                                                                                                                                                                                type="radio"
                                                                                                                                                                                id="radio3"
                                                                                                                                                                                defaultChecked={question3.answer === options3.id ?? "checked"}
                                                                                                                                                                                name={question3.id}
                                                                                                                                                                                className="radiobox"
                                                                                                                                                                                onClick={() => {
                                                                                                                                                                                    question3.answer = options3.id;
                                                                                                                                                                                    setRefresh(refresh + 1);
                                                                                                                                                                                    autoSave(false, question3.id, question3.answer);
                                                                                                                                                                                }}></input>
                                                                                                                                                                            {options3?.description}
                                                                                                                                                                            {/* Se a opção estiver marcada, abre as possiveis subperguntas */}
                                                                                                                                                                            {options3?.questions?.length > 0 && question3?.answer === options3?.id ? (
                                                                                                                                                                                options3?.questions?.map((question4, indexq4) => {
                                                                                                                                                                                    // NIVEL 4 //////////////////////////
                                                                                                                                                                                    return (
                                                                                                                                                                                        <>
                                                                                                                                                                                            <div key={indexq4} className={classes.boxQuestion}>
                                                                                                                                                                                                <div className="questionDescription">{question4?.description}</div>
                                                                                                                                                                                                <div className="gridteste">
                                                                                                                                                                                                    <div className={classes.separacaoOpcoes}></div>
                                                                                                                                                                                                    <div className="opcoesDaPergunta">
                                                                                                                                                                                                        {question4?.type === 3 ? (
                                                                                                                                                                                                            question4?.options.map((options4, indexO4) => {
                                                                                                                                                                                                                return (
                                                                                                                                                                                                                    <>
                                                                                                                                                                                                                        <div className={classes.multipleAnswerBox} key={indexO4}>
                                                                                                                                                                                                                            <input
                                                                                                                                                                                                                                disabled={finished ?? "disabled"}
                                                                                                                                                                                                                                type="checkbox"
                                                                                                                                                                                                                                id="check"
                                                                                                                                                                                                                                name="check"
                                                                                                                                                                                                                                defaultChecked={question4.answer.indexOf(question4.options[indexO4].id) !== -1 ?? "checked"}
                                                                                                                                                                                                                                className="checkbox"
                                                                                                                                                                                                                                onChange={(e) => {
                                                                                                                                                                                                                                    if (question4.answer.indexOf(question4.options[indexO4].id) === -1) question4.answer.push(question4.options[indexO4].id);
                                                                                                                                                                                                                                    else question4.answer.splice(question4.answer.indexOf(question4.options[indexO4].id), 1);
                                                                                                                                                                                                                                    question4.options[indexO4].answer = e.target.checked;
                                                                                                                                                                                                                                    setRefresh(refresh + 1);
                                                                                                                                                                                                                                    autoSave(false, question4.id, question4.answer);
                                                                                                                                                                                                                                }}></input>
                                                                                                                                                                                                                            {options4?.description}
                                                                                                                                                                                                                        </div>
                                                                                                                                                                                                                    </>
                                                                                                                                                                                                                );
                                                                                                                                                                                                            })
                                                                                                                                                                                                        ) : question4?.type === 1 ? (
                                                                                                                                                                                                            <textarea
                                                                                                                                                                                                                type="text"
                                                                                                                                                                                                                id="answer"
                                                                                                                                                                                                                name="answer"
                                                                                                                                                                                                                placeholder="Digite sua resposta aqui"
                                                                                                                                                                                                                disabled={finished ?? "disabled"}
                                                                                                                                                                                                                className={classes.inputTextAnswer}
                                                                                                                                                                                                                onChange={(e) => (question4.answer = e.target.value)}
                                                                                                                                                                                                                defaultValue={question4.answer ?? question4.answer}
                                                                                                                                                                                                                onBlur={() => autoSave(false, question4.id, question4.answer)}></textarea>
                                                                                                                                                                                                        ) : question4?.type === 2 ? (
                                                                                                                                                                                                            question4?.options.map((options4, indexO4) => {
                                                                                                                                                                                                                return (
                                                                                                                                                                                                                    <div className={classes.multipleAnswerBox} key={indexO4}>
                                                                                                                                                                                                                        <input
                                                                                                                                                                                                                            disabled={finished ?? "disabled"}
                                                                                                                                                                                                                            type="radio"
                                                                                                                                                                                                                            id="radio4"
                                                                                                                                                                                                                            defaultChecked={question4.answer === options4.id ?? "checked"}
                                                                                                                                                                                                                            name={question4.id}
                                                                                                                                                                                                                            className="radiobox"
                                                                                                                                                                                                                            onClick={() => {
                                                                                                                                                                                                                                question4.answer = options4.id;
                                                                                                                                                                                                                                setRefresh(refresh + 1);
                                                                                                                                                                                                                                autoSave(false, question4.id, question4.answer);
                                                                                                                                                                                                                            }}></input>
                                                                                                                                                                                                                        {options4?.description}
                                                                                                                                                                                                                    </div>
                                                                                                                                                                                                                );
                                                                                                                                                                                                            })
                                                                                                                                                                                                        ) : question4?.type === 4 ? (
                                                                                                                                                                                                            <CurrencyTextField
                                                                                                                                                                                                                disabled={finished ?? "disabled"}
                                                                                                                                                                                                                variant="outlined"
                                                                                                                                                                                                                // value={0.00}
                                                                                                                                                                                                                className={classes.inputMonetaryAnswer}
                                                                                                                                                                                                                currencySymbol="R$"
                                                                                                                                                                                                                outputFormat="number"
                                                                                                                                                                                                                decimalCharacter=","
                                                                                                                                                                                                                // required={true}
                                                                                                                                                                                                                digitGroupSeparator="."
                                                                                                                                                                                                                onChange={(e) => (question4.answer = e.target.value)}
                                                                                                                                                                                                                defaultValue={question4.answer ?? question4.answer}
                                                                                                                                                                                                                onBlur={() => autoSave(false, question4.id, question4.answer)}
                                                                                                                                                                                                            />
                                                                                                                                                                                                        ) : question4?.type === 5 ? (
                                                                                                                                                                                                          <div className="containerTipoPerguntaPorcentagem">
                                                                                                                                                                                                            <TextField
                                                                                                                                                                                                                type="number"
                                                                                                                                                                                                                onKeyPress={(e) => {
                                                                                                                                                                                                                    if (e.code === "Backquote") e.preventDefault();
                                                                                                                                                                                                                }}
                                                                                                                                                                                                                step="0.00"
                                                                                                                                                                                                                onKeyDown={(e) => {
                                                                                                                                                                                                                    if (e.key === "e" || e.key === "E") {
                                                                                                                                                                                                                        e.preventDefault();
                                                                                                                                                                                                                    }
                                                                                                                                                                                                                }}
                                                                                                                                                                                                                variant="outlined"
                                                                                                                                                                                                                onBlur={() => autoSave(false, question4.id, question4.answer)}
                                                                                                                                                                                                                disabled={finished ?? "disabled"}
                                                                                                                                                                                                                id="answer"
                                                                                                                                                                                                                name="answer"
                                                                                                                                                                                                                className={classes.inputPercentageAnswer}
                                                                                                                                                                                                                onChange={(e) => (question4.answer = e.target.value.replace(",", "."))}
                                                                                                                                                                                                                defaultValue={question4.answer ?? question4.answer}
                                                                                                                                                                                                            />
                                                                                                                                                                                                            <div className="porcentagem">%</div>
</div>
                                                                                                                                                                                                        ) : question4?.type === 6 ? (
                                                                                                                                                                                                            <div className="containerTipoPerguntaPorcentagem">
                                                                                                                                                                                                                <TextField
                                                                                                                                                                                                                    type="number"
                                                                                                                                                                                                                    onKeyPress={(e) => {
                                                                                                                                                                                                                        if (e.key === "." || e.key === ",") e.preventDefault();
                                                                                                                                                                                                                    }}
                                                                                                                                                                                                                    variant="outlined"
                                                                                                                                                                                                                    onBlur={() => autoSave(false, question4.id, question4.answer)}
                                                                                                                                                                                                                    disabled={finished ?? "disabled"}
                                                                                                                                                                                                                    id="answer"
                                                                                                                                                                                                                    name="answer"
                                                                                                                                                                                                                    className={classes.inputPercentageAnswer}
                                                                                                                                                                                                                    onChange={(e) => (question4.answer = e.target.value)}
                                                                                                                                                                                                                    defaultValue={question4.answer ?? question4.answer}
                                                                                                                                                                                                                />
                                                                                                                                                                                                            </div>
                                                                                                                                                                                                        ) : question4?.type === 7 ? (
                                                                                                                                                                                                            <div className="containerTipoPerguntaPorcentagem">
                                                                                                                                                                                                                <TextField
                                                                                                                                                                                                                    type="number"
                                                                                                                                                                                                                    variant="outlined"
                                                                                                                                                                                                                    onBlur={() => autoSave(false, question4.id, question4.answer)}
                                                                                                                                                                                                                    disabled={finished ?? "disabled"}
                                                                                                                                                                                                                    id="answer"
                                                                                                                                                                                                                    name="answer"
                                                                                                                                                                                                                    className={classes.inputPercentageAnswer}
                                                                                                                                                                                                                    onChange={(e) => (question4.answer = e.target.value.replace(",", "."))}
                                                                                                                                                                                                                    defaultValue={question4.answer ?? question4.answer}
                                                                                                                                                                                                                />
                                                                                                                                                                                                            </div>
                                                                                                                                                                                                        ) : (
                                                                                                                                                                                                            <></>
                                                                                                                                                                                                        )}
                                                                                                                                                                                                    </div>
                                                                                                                                                                                                </div>
                                                                                                                                                                                                {question4?.observation === true ? (
                                                                                                                                                                                                    <div className="observation">
                                                                                                                                                                                                        <p className="observationText">Observações:</p>
                                                                                                                                                                                                        <textarea
                                                                                                                                                                                                            disabled={finished ?? "disabled"}
                                                                                                                                                                                                            type="text"
                                                                                                                                                                                                            id="obs"
                                                                                                                                                                                                            name="obs"
                                                                                                                                                                                                            placeholder="Observações aqui"
                                                                                                                                                                                                            className={classes.inputTextAnswer}
                                                                                                                                                                                                            onChange={(e) => (question4.answer_observation = e.target.value)}
                                                                                                                                                                                                            defaultValue={question4.answer_observation ?? question4.answer_observation}
                                                                                                                                                                                                            onBlur={() => autoSave(true, question4.id, question4.answer_observation)}></textarea>
                                                                                                                                                                                                    </div>
                                                                                                                                                                                                ) : (
                                                                                                                                                                                                    <></>
                                                                                                                                                                                                )}
                                                                                                                                                                                            </div>
                                                                                                                                                                                        </>
                                                                                                                                                                                    );
                                                                                                                                                                                })
                                                                                                                                                                            ) : (
                                                                                                                                                                                <></>
                                                                                                                                                                            )}
                                                                                                                                                                        </div>
                                                                                                                                                                    );
                                                                                                                                                                })
                                                                                                                                                            ) : question3?.type === 4 ? (
                                                                                                                                                                <CurrencyTextField
                                                                                                                                                                    disabled={finished ?? "disabled"}
                                                                                                                                                                    variant="outlined"
                                                                                                                                                                    // value={0.00}
                                                                                                                                                                    className={classes.inputMonetaryAnswer}
                                                                                                                                                                    currencySymbol="R$"
                                                                                                                                                                    outputFormat="number"
                                                                                                                                                                    decimalCharacter=","
                                                                                                                                                                    // required={true}
                                                                                                                                                                    digitGroupSeparator="."
                                                                                                                                                                    onChange={(e) => (question3.answer = e.target.value)}
                                                                                                                                                                    defaultValue={question3.answer ?? question3.answer}
                                                                                                                                                                    onBlur={() => autoSave(false, question3.id, question3.answer)}
                                                                                                                                                                />
                                                                                                                                                            ) : question3?.type === 5 ? (
                                                                                                                                                              <div className="containerTipoPerguntaPorcentagem">
                                                                                                                                                                <TextField
                                                                                                                                                                    type="number"
                                                                                                                                                                    onKeyPress={(e) => {
                                                                                                                                                                        if (e.code === "Backquote") e.preventDefault();
                                                                                                                                                                    }}
                                                                                                                                                                    step="0.00"
                                                                                                                                                                    onKeyDown={(e) => {
                                                                                                                                                                        if (e.key === "e" || e.key === "E") {
                                                                                                                                                                            e.preventDefault();
                                                                                                                                                                        }
                                                                                                                                                                    }}
                                                                                                                                                                    variant="outlined"
                                                                                                                                                                    onBlur={() => autoSave(false, question3.id, question3.answer)}
                                                                                                                                                                    disabled={finished ?? "disabled"}
                                                                                                                                                                    id="answer"
                                                                                                                                                                    name="answer"
                                                                                                                                                                    className={classes.inputPercentageAnswer}
                                                                                                                                                                    onChange={(e) => (question3.answer = e.target.value.replace(",", "."))}
                                                                                                                                                                    defaultValue={question3.answer ?? question3.answer}
                                                                                                                                                                />
                                                                                                                                                                <div className="porcentagem">%</div>
</div>
             
                                                                                                                                                            ) : question3?.type === 6 ? (
                                                                                                                                                                <div className="containerTipoPerguntaPorcentagem">
                                                                                                                                                                    <TextField
                                                                                                                                                                        type="number"
                                                                                                                                                                        onKeyPress={(e) => {
                                                                                                                                                                            if (e.key === "." || e.key === ",") e.preventDefault();
                                                                                                                                                                        }}
                                                                                                                                                                        variant="outlined"
                                                                                                                                                                        onBlur={() => autoSave(false, question3.id, question3.answer)}
                                                                                                                                                                        disabled={finished ?? "disabled"}
                                                                                                                                                                        id="answer"
                                                                                                                                                                        name="answer"
                                                                                                                                                                        className={classes.inputPercentageAnswer}
                                                                                                                                                                        onChange={(e) => (question3.answer = e.target.value)}
                                                                                                                                                                        defaultValue={question3.answer ?? question3.answer}
                                                                                                                                                                    />
                                                                                                                                                                </div>
                                                                                                                                                            ) : question3?.type === 7 ? (
                                                                                                                                                                <div className="containerTipoPerguntaPorcentagem">
                                                                                                                                                                    <TextField type="number" variant="outlined" onBlur={() => autoSave(false, question3.id, question3.answer)} disabled={finished ?? "disabled"} id="answer" name="answer" className={classes.inputPercentageAnswer} onChange={(e) => (question3.answer = e.target.value.replace(",", "."))} defaultValue={question3.answer ?? question3.answer} />
                                                                                                                                                                </div>
                                                                                                                                                            ) : (
                                                                                                                                                                <></>
                                                                                                                                                            )}
                                                                                                                                                        </div>
                                                                                                                                                    </div>
                                                                                                                                                    {question3?.observation === true ? (
                                                                                                                                                        <div className="observation">
                                                                                                                                                            <p className="observationText">Observações:</p>
                                                                                                                                                            <textarea
                                                                                                                                                                disabled={finished ?? "disabled"}
                                                                                                                                                                type="text"
                                                                                                                                                                id="obs"
                                                                                                                                                                name="obs"
                                                                                                                                                                placeholder="Observações aqui"
                                                                                                                                                                className={classes.inputTextAnswer}
                                                                                                                                                                onChange={(e) => (question3.answer_observation = e.target.value)}
                                                                                                                                                                defaultValue={question3.answer_observation ?? question3.answer_observation}
                                                                                                                                                                onBlur={() => autoSave(true, question3.id, question3.answer_observation)}></textarea>
                                                                                                                                                        </div>
                                                                                                                                                    ) : (
                                                                                                                                                        <></>
                                                                                                                                                    )}
                                                                                                                                                </div>
                                                                                                                                            </>
                                                                                                                                        );
                                                                                                                                    })
                                                                                                                                ) : (
                                                                                                                                    <></>
                                                                                                                                )}
                                                                                                                            </div>
                                                                                                                        );
                                                                                                                    })
                                                                                                                ) : question2?.type === 4 ? (
                                                                                                                    <CurrencyTextField
                                                                                                                        disabled={finished ?? "disabled"}
                                                                                                                        variant="outlined"
                                                                                                                        // value={0.00}
                                                                                                                        className={classes.inputMonetaryAnswer}
                                                                                                                        currencySymbol="R$"
                                                                                                                        outputFormat="number"
                                                                                                                        decimalCharacter=","
                                                                                                                        // required={true}
                                                                                                                        digitGroupSeparator="."
                                                                                                                        onChange={(e) => (question2.answer = e.target.value)}
                                                                                                                        defaultValue={question2.answer ?? question2.answer}
                                                                                                                        onBlur={() => autoSave(false, question2.id, question2.answer)}
                                                                                                                    />
                                                                                                                ) : question2?.type === 5 ? (
                                                                                                                  <div className="containerTipoPerguntaPorcentagem">
                                                                                                                    <TextField
                                                                                                                        type="number"
                                                                                                                        onKeyPress={(e) => {
                                                                                                                            if (e.code === "Backquote") e.preventDefault();
                                                                                                                        }}
                                                                                                                        step="0.00"
                                                                                                                        onKeyDown={(e) => {
                                                                                                                            if (e.key === "e" || e.key === "E") {
                                                                                                                                e.preventDefault();
                                                                                                                            }
                                                                                                                        }}
                                                                                                                        variant="outlined"
                                                                                                                        onBlur={() => autoSave(false, question2.id, question2.answer)}
                                                                                                                        disabled={finished ?? "disabled"}
                                                                                                                        id="answer"
                                                                                                                        name="answer"
                                                                                                                        className={classes.inputPercentageAnswer}
                                                                                                                        onChange={(e) => (question2.answer = e.target.value.replace(",", "."))}
                                                                                                                        defaultValue={question2.answer ?? question2.answer}
                                                                                                                    />
                                                                                                                    <div className="porcentagem">%</div>
                                                                                                                    </div>
                                                                                                                ) : question2?.type === 6 ? (
                                                                                                                    <div className="containerTipoPerguntaPorcentagem">
                                                                                                                        <TextField
                                                                                                                            type="number"
                                                                                                                            onKeyPress={(e) => {
                                                                                                                                if (e.key === "." || e.key === ",") e.preventDefault();
                                                                                                                            }}
                                                                                                                            variant="outlined"
                                                                                                                            onBlur={() => autoSave(false, question2.id, question2.answer)}
                                                                                                                            disabled={finished ?? "disabled"}
                                                                                                                            id="answer"
                                                                                                                            name="answer"
                                                                                                                            className={classes.inputPercentageAnswer}
                                                                                                                            onChange={(e) => (question2.answer = e.target.value)}
                                                                                                                            defaultValue={question2.answer ?? question2.answer}
                                                                                                                        />
                                                                                                                    </div>
                                                                                                                ) : question2?.type === 7 ? (
                                                                                                                    <div className="containerTipoPerguntaPorcentagem">
                                                                                                                        <TextField type="number" variant="outlined" onBlur={() => autoSave(false, question2.id, question2.answer)} disabled={finished ?? "disabled"} id="answer" name="answer" className={classes.inputPercentageAnswer} onChange={(e) => (question2.answer = e.target.value.replace(",", "."))} defaultValue={question2.answer ?? question2.answer} />
                                                                                                                    </div>
                                                                                                                ) : (
                                                                                                                    <></>
                                                                                                                )}
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        {question2?.observation === true ? (
                                                                                                            <div className="observation">
                                                                                                                <p className="observationText">Observações:</p>
                                                                                                                <textarea disabled={finished ?? "disabled"} type="text" id="obs" name="obs" placeholder="Observações aqui" className={classes.inputTextAnswer} onChange={(e) => (question2.answer_observation = e.target.value)} defaultValue={question2.answer_observation ?? question2.answer_observation} onBlur={() => autoSave(true, question2.id, question2.answer_observation)}></textarea>
                                                                                                            </div>
                                                                                                        ) : (
                                                                                                            <></>
                                                                                                        )}
                                                                                                    </div>
                                                                                                </>
                                                                                            );
                                                                                        })
                                                                                    ) : (
                                                                                        <></>
                                                                                    )}
                                                                                </div>
                                                                            );
                                                                        })
                                                                    ) : question?.type === 4 ? (
                                                                        <CurrencyTextField
                                                                            disabled={finished ?? "disabled"}
                                                                            variant="outlined"
                                                                            // value={0.00}
                                                                            className={classes.inputMonetaryAnswer}
                                                                            currencySymbol="R$"
                                                                            outputFormat="number"
                                                                            decimalCharacter=","
                                                                            // required={true}
                                                                            digitGroupSeparator="."
                                                                            onChange={(e) => (question.answer = e.target.value)}
                                                                            defaultValue={question.answer ?? question.answer}
                                                                            onBlur={() => autoSave(false, question.id, question.answer)}
                                                                        />
                                                                    ) : question?.type === 5 ? (
                                                                        <div className="containerTipoPerguntaPorcentagem">
                                                                            <TextField
                                                                                type="number"
                                                                                onKeyPress={(e) => {
                                                                                    if (e.code === "Backquote") e.preventDefault();
                                                                                }}
                                                                                step="0.00"
                                                                                onKeyDown={(e) => {
                                                                                    if (e.key === "e" || e.key === "E") {
                                                                                        e.preventDefault();
                                                                                    }
                                                                                }}
                                                                                variant="outlined"
                                                                                onBlur={() => autoSave(false, question.id, question.answer)}
                                                                                disabled={finished ?? "disabled"}
                                                                                id="answer"
                                                                                name="answer"
                                                                                className={classes.inputPercentageAnswer}
                                                                                onChange={(e) => (question.answer = e.target.value.replace(",", "."))}
                                                                                defaultValue={question.answer ?? question.answer}
                                                                            />
                                                                            <div className="porcentagem">%</div>
                                                                        </div>
                                                                    ) : question?.type === 6 ? (
                                                                        <div className="containerTipoPerguntaPorcentagem">
                                                                            <TextField
                                                                                type="number"
                                                                                onKeyPress={(e) => {
                                                                                    if (e.key === "." || e.key === ",") e.preventDefault();
                                                                                }}
                                                                                variant="outlined"
                                                                                onBlur={() => autoSave(false, question.id, question.answer)}
                                                                                disabled={finished ?? "disabled"}
                                                                                id="answer"
                                                                                name="answer"
                                                                                className={classes.inputPercentageAnswer}
                                                                                onChange={(e) => (question.answer = e.target.value)}
                                                                                defaultValue={question.answer ?? question.answer}
                                                                            />
                                                                        </div>
                                                                    ) : question?.type === 7 ? (
                                                                        <div className="containerTipoPerguntaPorcentagem">
                                                                            <TextField type="number" variant="outlined" onBlur={() => autoSave(false, question.id, question.answer)} disabled={finished ?? "disabled"} id="answer" name="answer" className={classes.inputPercentageAnswer} onChange={(e) => (question.answer = e.target.value.replace(",", "."))} defaultValue={question.answer ?? question.answer} />
                                                                        </div>
                                                                    ) : (
                                                                        <></>
                                                                    )}
                                                                </div>
                                                            </div>
                                                            {question?.observation === true ? (
                                                                <div className="observation">
                                                                    <p className="observationText">Observações:</p>
                                                                    <textarea disabled={finished ?? "disabled"} type="text" id="obs" name="obs" placeholder="Observações aqui" className={classes.inputTextAnswer} onChange={(e) => (question.answer_observation = e.target.value)} defaultValue={question.answer_observation ?? question.answer_observation} onBlur={() => autoSave(true, question.id, question.answer_observation)}></textarea>
                                                                </div>
                                                            ) : (
                                                                <></>
                                                            )}
                                                        </div>
                                                    </>
                                                );
                                            }
                                        })}
                                    </>
                                );
                            })}
                        </div>
                    )}
                    {!finished ? (
                        <div className="boxFinish">
                            {saving ? (
                                <div className="containerSaving">
                                    <div className="savingText">Salvando</div>
                                    <div className="containerDotsSaving">
                                        <div className="dotSaving"></div>
                                        <div className="dotSaving"></div>
                                        <div className="dotSaving"></div>
                                    </div>
                                </div>
                            ) : (
                                <div className="savedText">
                                    Salvo <MdDoneOutline size={16} />
                                </div>
                            )}

                            {strutureJson?.session_pending ?
                            <button className="finishButton" onClick={finishAndSend}>
                                Finalizar e enviar
                            </button> : <></>}
                        </div>
                    ) : (
                        <div className="containerSaving"></div>
                    )}
                </>
            )}
        </div>
        </>
    );
}
