import React, { useState, useEffect } from "react";
/*Styles*/
// import "../../styles/recomendationWisir.css";
/*Components*/

import Modal from "../../../components/modal/modal";
import { useAuth } from "../../../contexts/useAuth";
import { useModal } from "../../../contexts/useModal";
import { TextField } from "@material-ui/core";
import CurrencyTextField from "@unicef/material-ui-currency-textfield";
import { getMyClients } from "../../../services/asyncFunctions";
import { api } from "../../../services/api";
import styles from './styles/reports.cadastration.module.css'
import { validyFiles } from '../../../utils/validyTypesOfFiles'

export function ReportsDetail(props) {
  console.log(props)
  const { tokenKey, user } = useAuth();
  const [ fileOrLink, setFileOrLink] = useState(props.msgData.value.props.is_file)
  const [modalityiesForStocks, setModalityiesForStocks] = useState([]);

  const { show, setShow, type, setType, edited, setEdited } = useModal();
  const [loading, setLoading] = useState(true);
  const [clientList, setClientsList] = useState([]);
  const [selectedClient, setSelectedClient] = useState([]);
  const [selectedValue, setSelectedValue] = useState("000");
  const [selectedModality, setSelectedModality] = useState("");
  //   const [selectedTypeStrategie, setSelectedType] = useState("");
  const [selectedObservation, setSelectedObservation] = useState("");
  const [source, setSource] = useState('');
  const [title, setTitle] = useState('');
  const [link, setLink] = useState('');
  const [file, setFile] = useState('');
  const [asset, setAsset] = useState('');
  const [assetCompany, setAssetCompany] = useState('');
  const [isDocument, setIsDocument] = useState(props.msgData.value.props.is_file);
  const [periodic, setPeriodic] = useState(0);
  const [segment, setSegment] = useState('');
  const token = localStorage.getItem('@auth:token');
  const requiredFields = [
    { field: 'source', value: source },
    { field: 'title', value: title },
    { field: 'assetCompany', value: assetCompany },
    { field: 'asset', value: asset },
    { field: 'segment', value: segment },
    { field: 'link', value: isDocument ? 'file' : link },
  ]
  const [errorFields, setErrorFields] = useState({
    selectedClient: false,
    selectedValue: false,
  });

  useEffect(() => {
    if (!show) {
      setSource('')
      setLoading(false)
      setTitle('')
      setLink('')
      setFile('')
      setAsset('')
      setAssetCompany('')
      setSegment('')
      setErrorFields({
        source: false,
        title: false,
        assetCompany: false,
        asset: false,
        segment: false,
        link: false,
        periodic: false
      })
    }
  }, [show])

  async function handleSubmit(e) {
    e.preventDefault()
    
    let isErrors = false

    if(validyFiles(file)){
        return
    }
    setLoading(true)
    try {
        const responseCreate = await api.put(`/api/v1/reports/`+props.msgData.value.props.id_report, {
            source: source ? source : undefined,
            title: title ? title : undefined,
            path_link: isDocument == true ? undefined : link ? link : undefined,
            is_file: isDocument == true ? 1 : 0,
            asset: asset ? asset : undefined,
            asset_company: assetCompany ? assetCompany : undefined,
            segment: segment ? segment : undefined,
            periodic: parseInt(periodic)
        }, {
            headers: { authorization: 'Bearer ' + token }
        })
        if (isDocument && file !='') {

            const formData = new FormData();
            formData.append('report', file)

            await api.post(`/api/v1/reports/${responseCreate.data.data.id}/upload`,
                formData
                , {
                    headers: {
                        "Authorization": 'Bearer ' + token,
                        "Content-Type": "multipart/form-data"
                    }
                })
        }
        setShow(false)
        setEdited(true)
        setLoading(false)
        return
    } catch (error) {
        if (error?.response?.data?.message != undefined) {
            setLoading(false)
            return alert(error?.response?.data?.message)
        }
        setLoading(false)
        return alert('Ocorreu algum erro inesperado ao tentar realizar a criação de relatório.')
    }
  }

  return(
    <div className="modalReportsDetail">
      {console.log(isDocument)}
      <div className="modalReportsHeader">
        <div className="editBrancheBoxHeader">
          <p>Editar relatório</p>
          <button
            onClick={() => setShow(false)}
            className="modalCloseButton"
          >
            X
          </button>
        </div>
      </div>
      <div className="disclaimerEditReport">&#x26A0;&nbsp;&nbsp;A informação relacionada ao campo não será alterada caso não seja inserido um valor&nbsp;&nbsp;&#x26A0;</div>
      <form action="put" onSubmit={handleSubmit} className={styles.formReportsCadastration}>
        <div className={styles.divInputsReportsCadastration}>
            <input maxLength={100} style={{ border: errorFields['source'] ? '1px solid red' : '' }} onKeyPress={(e) => { if (e.code === 'Backquote') e.preventDefault() }} type="text" value={source} onChange={(e) => setSource(e.target.value)} placeholder=' ' />
            <label htmlFor="">Fonte</label>
        </div>
        <div className={styles.divInputsReportsCadastration}>
            <input maxLength={200} style={{ border: errorFields['title'] ? '1px solid red' : '' }} onKeyPress={(e) => { if (e.code === 'Backquote') e.preventDefault() }} type="text" value={title} onChange={(e) => setTitle(e.target.value)} placeholder=' ' />
            <label htmlFor="">Título</label>
        </div>
        <div className={styles.divInputsReportsCadastration}>
            <input maxLength={200} style={{ border: errorFields['assetCompany'] ? '1px solid red' : '' }} onKeyPress={(e) => { if (e.code === 'Backquote') e.preventDefault() }} type="text" value={assetCompany} onChange={(e) => setAssetCompany(e.target.value)} placeholder=' ' />
            <label htmlFor="">Empresa</label>
        </div>
        <div className={styles.divInputsReportsCadastration}>
            <input maxLength={30} style={{ border: errorFields['asset'] ? '1px solid red' : '' }} onKeyPress={(e) => { if (e.code === 'Backquote') e.preventDefault() }} type="text" value={asset} onChange={(e) => setAsset(e.target.value)} placeholder=' ' />
            <label htmlFor="">Ativo</label>
        </div>
        <div className={styles.divInputsReportsCadastration}>
            <input maxLength={100} style={{ border: errorFields['segment'] ? '1px solid red' : '' }} onKeyPress={(e) => { if (e.code === 'Backquote') e.preventDefault() }} type="text" value={segment} onChange={(e) => setSegment(e.target.value)} placeholder=' ' />
            <label htmlFor="">Segmento</label>
        </div>
        <div className={styles.periodicReportLabel}>
            <label for='periodicReport'>
                <a>Periódico ?</a>
                <select id='periodicReport' onChange={(e) => setPeriodic(e.target.value)} placeholder=' '>
                    <option value='1'>Sim</option>
                    <option selected value='0'>Não</option>
                </select>
            </label>
        </div>
        <div className={styles.radioSelectContainerColumn}>
            <div className={styles.radioSelectContainerColumnLine}>
                <div>
                    <input onKeyPress={(e) => { if (e.code === 'Backquote') e.preventDefault() }} type="checkbox" id='archive' checked={fileOrLink == true ? true : false} onClick={(e) => {setIsDocument(true);setFileOrLink(!fileOrLink)}} />
                    <label htmlFor="archive">Arquivo</label>
                </div>
                <div>
                    <input style={{ border: errorFields['link'] ? '1px solid red' : '' }} onKeyPress={(e) => { if (e.code === 'Backquote') e.preventDefault() }} type="checkbox" id='link' checked={fileOrLink == true ? false : true} onClick={(e) => {setIsDocument(false);setFileOrLink(!fileOrLink)}} />
                    <label htmlFor="link">Link</label>
                </div>
            </div>
            {
                !isDocument ?
                    <div className={styles.InputToFormat}>
                        <div>
                            <input maxLength={250} style={{ border: errorFields['link'] ? '1px solid red' : '' }} onKeyPress={(e) => { if (e.code === 'Backquote') e.preventDefault() }} type="text" value={link} onChange={(e) => setLink(e.target.value)} placeholder=' ' />
                            <label htmlFor="">Link</label>
                        </div>
                    </div> :
                    <div className={styles.fileInputAdjust}>
                        <label htmlFor="fileSelect">{file === '' || file === undefined || file === null ? 'Selecione um arquivo' : file.name}</label>
                        <input onKeyPress={(e) => { if (e.code === 'Backquote') e.preventDefault() }} type="file" id='fileSelect' accept=".xls,.xlsx, .pdf, .docx, .png, .jpeg, .jpeg, .txt" onChange={(e) => setFile(e.target.files[0])} />
                    </div>
            }
        </div>
        <button type="submit" className='buttonWhiteBg'>Atualizar</button>
      </form>
    </div>
  )
}
