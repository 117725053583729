/*Componente Tabela de books
/*Descrição: Exibe os Books cadastrados em sistema
/*Desenvolvido por: Equipe Rafael Cezário
*/

import React, { useEffect, useState } from 'react';
import { FiTrash2, FiSlash, FiDownload,FiArrowDown, FiCopy } from 'react-icons/fi';
/*Styles*/
import './styles/tableBooksInactive.css';
/*Contexts*/
import { useModal } from '../../../../contexts/useModal';
import { useAuth } from '../../../../contexts/useAuth';
/*Services*/
import { api } from '../../../../services/api';
import { getBooks } from '../../../../services/asyncFunctions';
/*Util Components*/
import { ArrowLoader } from '../../../../components/loaders/arrowLoader';

export function TableBooksInactive() {

    const { tokenKey } = useAuth();
    const { setShow, setType, edited, setEdited } = useModal();
    const [bookList, setBooklist] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(async () => {
        
        setLoading(true)
        const booksGet = await getBooks(0,tokenKey)
        if(booksGet?.isError){
            setType({
                name: 'alertMsg',
                type: 'information',
                value: {
                    message: booksGet?.error
                }
            })
            setShow(true);
        }else {
            setBooklist(booksGet);
        }

        if (edited || bookList?.length < 1) {
            setLoading(true);
            const newBooksGet = await getBooks(0,tokenKey)
            if(newBooksGet?.isError){
                setType({
                    name: 'alertMsg',
                    type: 'information',
                    value: {
                        message: newBooksGet?.error
                    }
                })
                setShow(true);
            }else {
                setBooklist(newBooksGet);
            }
            setEdited(false);
        }   
        setLoading(false);
    }, [edited])

    async function handleDelete(bookData) {

        setLoading(true);
        try {
            await api.delete(`/api/v1/books/${bookData?.id}`, {
                headers: {
                    authorization: `Bearer ${tokenKey}`
                }
            })
            setEdited(true);
        } catch (err) {
            setType({ name: 'alertMsg', type: 'information', value: { message: err?.response?.data?.message } })
            setShow(true);
        }
    }

    async function booksExport() {
        setLoading(true);
        try {
            const exportAssetsResult = await api.get(`/api/v1/books/export`, {
                headers: {
                    authorization: `Bearer ${tokenKey}`
                },
                responseType: 'blob'
            })
            console.log(exportAssetsResult)

            const responseURL = URL.createObjectURL(new Blob([exportAssetsResult.data]));
            const link = document.createElement('a');
            link.href = responseURL;
            link.setAttribute('download', 'lista_de_books.xlsx');
            document.body.appendChild(link);
            link.click();
            setLoading(false)

        } catch (error) {
            setType({ name: 'alertMsg', type: 'information', value: { message: error?.response?.data?.message === undefined ? 'Erro ao buscar dados para exportação' : error?.response?.data?.message } })
            setShow(true);
            setLoading(false);
        }
    }

    async function handleDisable(bookData) {
        setLoading(true);
        try {

            const disableBook = await api.put(`/api/v1/books/${bookData}/status`, {}, {
                headers: {
                    authorization: `Bearer ${tokenKey}`
                }
            })
            setType({ name: 'alertMsg', type: 'information', value: { message: "Book Reativado com sucesso!" } })
            setShow(true);
            setEdited(true);

        } catch (err) {
            setType({ name: 'alertMsg', type: 'information', value: { message: err?.response?.data?.message } })
            setShow(true);
        }
    }

    async function getBookLayout(bookData) {
        setLoading(true);
        try {
            const bookLayout = await api.get(`/api/v1/books/${bookData}/getWorksheet`, {
                headers: {
                    authorization: `Bearer ${tokenKey}`
                },
                responseType: 'blob'
            })

            const url = URL.createObjectURL(new Blob([bookLayout.data]))
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'layout.xlsx');
            document.body.appendChild(link);
            link.click();
            setLoading(false);
        } catch (err) {
            setType({ name: 'alertMsg', type: 'information', value: { message: err?.response?.data?.message } })
            setLoading(false);
        }
    }

    return (
        <div className='booksConfigurationTableBooksContainer'>
            <table id='tableBooksInactive'>
                <thead>
                    <tr>
                        <th colSpan='7'>
                            <div>
                                <a>Lista de Books Desativados</a>
                                <div className='substitutoLabelDisplay'>
                                <button
                                        style={{marginRight: '1rem'}}
                                        className='buttonTableThead'
                                        onClick={() => {
                                            const output = [];
                                            const titles = ['Código', 'Nome', 'Ordem', 'Criado por', 'Criado em', 'Status Book']
                                            output.push(titles.join('\t'))
                                            const keys = ['id', 'name','position', 'users_external_id', 'created_at', 'active'];
                                            bookList.forEach((x) => {
                                                const tmp = keys.map((y) => {return y==='active'?  x[y] === true ? 'Ativo' : 'Inativo' 
                                                : y==='created_at' ? x[y].split("T")[0] 
                                                : x[y]});
                                                output.push(tmp.join('\t'))
                                            })
                                            const table = output.join('\n')
                                            navigator.clipboard.writeText(table)
                                            alert('Conteúdo da tabela copiado para a área de transferência')
                                        }}
                                    ><FiCopy />Copiar</button>
                                <button
                                        style={{marginRight: '1rem'}}
                                        className='buttonTableThead'
                                        onClick={() => {
                                            booksExport();
                                    }}><FiArrowDown />Exportar</button>
                                    <input className='tableAllInputThead' placeholder='Filtrar'></input>
                                </div>
                            </div>
                        </th>
                    </tr>
                    <tr>
                        <th>Código</th>
                        <th>Nome</th>
                        <th>Criado por</th>
                        <th>Criado em</th>
                        <th>Layout Importação</th>
                        <th>Excluir</th>
                        <th>Reativar</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        loading ?
                            (
                                <td colSpan='7' className='tableBookInactiveLoader'>
                                    <ArrowLoader />
                                </td>
                            ) :
                            bookList?.length > 0 ?
                                (
                                    bookList.map((booksInactive) => {
                                        const date_created = new Date(Date.parse(booksInactive?.created_at)).toLocaleString('pt-BR', { timeZone: 'UTC' });
                                        return (
                                            <tr>
                                                <td >{booksInactive?.id}<a id='bookLegend'>Editar Book</a></td>
                                                <td >{booksInactive?.name}<a id='bookLegend'>Editar Book</a></td>
                                                <td >{booksInactive?.users_external_id}<a id='bookLegend'>Editar Book</a></td>
                                                <td >{date_created}<a id='bookLegend'>Editar Book</a></td>
                                                <td onClick={() => { getBookLayout(booksInactive?.id) }}><FiDownload /></td>
                                                <td onClick={() => { handleDelete(booksInactive) }}><FiTrash2 /></td>
                                                <td onClick={() => { handleDisable(booksInactive?.id) }}><FiSlash />
                                                </td>
                                            </tr>
                                        )
                                    })
                                ) :
                                (
                                    <td colSpan='7'>
                                        <div id='booksEmptyRow'>
                                            <a>-- Lista vazia --</a>
                                        </div>
                                    </td>
                                )
                    }
                </tbody>
            </table>
        </div>
    )
}