import React, { useEffect, useState } from "react"
import "../monitoringPanel/myReservations/styles/myReservations.details.css"

import { api } from "../../../services/api"
import { useModal } from "../../../contexts/useModal"
import { FiCopy } from "react-icons/fi"
import { FaCheck } from "react-icons/fa"
import { AiFillCloseCircle } from "react-icons/ai"
import { BsPencilSquare } from "react-icons/bs"
import { useAuth } from "../../../contexts/useAuth"

export function CubastarTacticalSpeech(props) {
    // console.log("props", props)

    const { type, setType, show, setShow } = useModal()
    const [response, setResponse] = useState(false)
    const [copyingLink, setCopyingLink] = useState(false)
    const [editingSpeech, setEditingSpeech] = useState(false)
    const [editingVendor, setEditingVendor] = useState(false)
    const [text, setText] = useState("")
    const [textVendor, setTextVendor] = useState("")
    const { tokenKey, user } = useAuth()
    const [veiculos, setVeiculos] = useState("")

    async function submitTacticalSpeech() {
        try {
            const updateInfo = await api.post(
                `/api/v1/alocacao/cubastar/alocacaoTatica/updateTacticalSpeech`,
                {
                    id: props?.msgData?.data?.row?.id,
                    speech: text,
                },
                {
                    headers: {
                        authorization: `Bearer ${tokenKey}`,
                    },
                }
            )
            alert("Discurso de alocação tática atualizada com sucesso!")
        } catch (error) {
            console.log(error?.response)
            setType({
                name: "alertMsg",
                type: "information",
                value: { message: error?.response?.data?.message === undefined ? "Erro ao inserir novo informativo: " : error.response.data.message },
            })
            setShow(true)
        }
        props.msgData.data.row.speech = text
        props.msgData.data.cubastarTacticalGet()
    }

    async function submitTacticalVendorSpeech() {
        try {
            const updateInfo = await api.post(
                `/api/v1/alocacao/cubastar/alocacaoTatica/updateTacticalVendorSpeech`,
                {
                    id: props?.msgData?.data?.row?.id,
                    vendor_speech: textVendor,
                },
                {
                    headers: {
                        authorization: `Bearer ${tokenKey}`,
                    },
                }
            )
            alert("Discurso de alocação tática atualizada com sucesso!")
        } catch (error) {
            console.log(error?.response)
            setType({
                name: "alertMsg",
                type: "information",
                value: { message: error?.response?.data?.message === undefined ? "Erro ao inserir novo informativo: " : error.response.data.message },
            })
            setShow(true)
        }
        props.msgData.data.row.vendor_speech = textVendor
        props.msgData.data.cubastarTacticalGet()
    }

    async function submitFundosSpeech(tipo) {
        try {
            const updateInfo = await api.put(
                `/api/v1/alocacao/cubastar/fundos/updateSpeech`,
                {
                    cnpj: props?.msgData?.data?.data?.cnpj,
                    speech_type: tipo,
                    speech: tipo === "speech" ? text : textVendor,
                },
                {
                    headers: {
                        authorization: `Bearer ${tokenKey}`,
                    },
                }
            )
            alert("Discurso atualizado com sucesso!")
        } catch (error) {
            console.log(error?.response)
            setType({
                name: "alertMsg",
                type: "information",
                value: { message: error?.response?.data?.message === undefined ? "Erro ao inserir novo discurso: " : error.response.data.message },
            })
            setShow(true)
        }
        if (tipo === "speech") props.msgData.data.data.speech = text
        else props.msgData.data.data.vendor_speech = textVendor
    }

    async function submitCarteirasSpeech(tipo) {
        try {
            const updateInfo = await api.put(
                `/api/v1/alocacao/cubastar/carteiras/updateSpeech`,
                {
                    carteira_id: props?.msgData?.data?.line?.carteiras_id, 
                    category: props?.msgData?.data?.line?.secao , 
                    description: props?.msgData?.data?.line?.description,
                    speech_type: tipo,
                    speech: tipo === "speech" ? text : textVendor,
                },
                {
                    headers: {
                        authorization: `Bearer ${tokenKey}`,
                    },
                }
            )
            alert("Discurso atualizado com sucesso!")
        } catch (error) {
            console.log(error?.response)
            setType({
                name: "alertMsg",
                type: "information",
                value: { message: error?.response?.data?.message === undefined ? "Erro ao inserir novo discurso: " : error.response.data.message },
            })
            setShow(true)
        }
        if (tipo === "speech") props.msgData.data.line.speech = text
        else props.msgData.data.line.vendor_speech = textVendor
    }


    useEffect(() => {
        type.data.order === undefined || type.data.order === "undefined" || type.data.order === "" || type.data.order === "0" ? setResponse(false) : setResponse(true)

        if (show) {
            if (props.msgData?.data?.type == "fundos") {
                setText(props.msgData?.data?.data?.speech)
                setTextVendor(props.msgData?.data?.data?.vendor_speech)
                setVeiculos(props?.msgData?.data?.data?.description)
            } else if(props.msgData?.data?.type == "carteiras"){
                setText(props.msgData?.data.line?.speech)
                setTextVendor(props.msgData?.data.line?.vendor_speech)
                setVeiculos(props?.msgData?.data?.line?.description)
            }
            else {
                setText(props.msgData.data.row.speech)
                setTextVendor(props.msgData.data.row.vendor_speech)
                setVeiculos(props?.msgData?.data?.row?.veiculos)
            }
        }
    }, [show])

    return (
        <div className="containerModalDashDetails" style={{ height: "33rem", maxHeight: "33rem" }}>
            {
                <>
                    <div className="editBrancheBoxHeaderDash">
                        <p style={{ color: "#D4AC00" }}>{veiculos}</p>
                        <div style={{ display: "grid", gridTemplateColumns: "auto auto", alignItems: "center" }}>
                            <p style={{ marginRight: "1rem" }}>{user.external_id + " - " + user.full_name} </p>
                            <button
                                onClick={() => {
                                    setEditingSpeech(false)
                                    setEditingVendor(false)
                                    setShow(false)
                                }}
                                className="modalCloseButtonDashboard"
                                style={{ marginRight: "12px", height: "36px", width: "36px" }}
                            >
                                <AiFillCloseCircle />
                            </button>
                        </div>
                    </div>
                    <div className="cubastarTacticalDivGrid">
                        <div className="cubastarTacticalContainer">
                            <div className="cubastarTacticalInternal">
                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", backgroundColor: "#BBBBBB", padding: "1rem", borderRadius: "10px" }}>
                                    <div style={{ fontWeight: "bold", fontSize: "20px", textAlign: "center", flex: "50%" }}>USO INTERNO</div>
                                    {user.type < 3 ? (
                                        <div
                                            title={"Editar Conteúdo"}
                                            style={{ cursor: "pointer", width: "1.5rem" }}
                                            onClick={() => {
                                                setEditingSpeech(!editingSpeech)
                                            }}
                                        >
                                            {editingSpeech ? (
                                                <BsPencilSquare
                                                    size={20}
                                                    color={"green"}
                                                    onClick={(e) => {
                                                        if (props.msgData?.data?.type == "fundos") submitFundosSpeech("speech")
                                                        else if (props.msgData?.data?.type == "carteiras") submitCarteirasSpeech("speech")
                                                        else submitTacticalSpeech()
                                                    }}
                                                />
                                            ) : (
                                                <BsPencilSquare size={20} />
                                            )}
                                        </div>
                                    ) : null}
                                </div>
                                <div>
                                    <div style={{ padding: "1rem" }}>
                                        <div>
                                            {editingSpeech ? (
                                                <textarea
                                                    value={text}
                                                    style={{
                                                        width: "-webkit-fill-available",
                                                        height: "20rem",
                                                        fontFamily: "arial",
                                                        fontSize: "initial",
                                                        whiteSpace: "pre-wrap",
                                                        overflowX: "hidden",
                                                    }}
                                                    onChange={(e) => setText(e.target.value)}
                                                ></textarea>
                                            ) : (
                                                <pre style={{ fontFamily: "Roboto, sans-serif", fontSize: "1rem", overflow: "auto", height: "20rem", whiteSpace: "pre-wrap", overflowX: "hidden" }}>
                                                    {text}
                                                </pre>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div></div>
                        <div className="cubastarTacticalContainer">
                            <div className="cubastarTacticalInternal">
                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", backgroundColor: "#BBBBBB", padding: "1rem", borderRadius: "10px" }}>
                                    <div style={{ fontWeight: "bold", fontSize: "20px", textAlign: "center", flex: "50%" }}>DISCURSO COMERCIAL</div>
                                    {user.type < 3 ? (
                                        <div
                                            title={"Editar Conteúdo"}
                                            style={{ cursor: "pointer", width: "1.5rem" }}
                                            onClick={() => {
                                                editingVendor ? setEditingVendor(false) : setEditingVendor(true)
                                            }}
                                        >
                                            {editingVendor ? (
                                                <BsPencilSquare
                                                    size={20}
                                                    color={"green"}
                                                    onClick={(e) => {
                                                        if (props.msgData?.data?.type == "fundos") submitFundosSpeech("vendor_speech")
                                                        else if (props.msgData?.data?.type == "carteiras") submitCarteirasSpeech("vendor_speech")
                                                        else submitTacticalVendorSpeech()
                                                    }}
                                                />
                                            ) : (
                                                <BsPencilSquare size={20} />
                                            )}
                                        </div>
                                    ) : (
                                        <></>
                                    )}

                                    { textVendor !== "" && textVendor !== undefined && textVendor !== null
                                    ?
                                    <div
                                        title={"Copiar Texto"}
                                        style={{ cursor: "pointer", width: "1.5rem" }}
                                        onClick={async () => {
                                            navigator.clipboard.writeText(document.getElementById("speechContent").innerText)
                                            setCopyingLink(true)
                                            setTimeout(() => setCopyingLink(false), 1000)
                                        }}
                                    >
                                        {copyingLink ? <FaCheck size={20} color={"green"} /> : <FiCopy size={20} />}
                                    </div> : <></>}
                                </div>
                                <div style={{ padding: "1rem" }}>
                                    <div>
                                        {editingVendor ? (
                                            <textarea
                                                value={textVendor}
                                                style={{
                                                    width: "-webkit-fill-available",
                                                    height: "20rem",
                                                    fontFamily: "arial",
                                                    fontSize: "initial",
                                                    whiteSpace: "pre-wrap",
                                                    overflowX: "hidden",
                                                }}
                                                onChange={(e) => setTextVendor(e.target.value)}
                                            ></textarea>
                                        ) : (
                                            <pre
                                                id="speechContent"
                                                style={{ fontFamily: "Roboto, sans-serif", fontSize: "1rem", overflow: "auto", height: "20rem", whiteSpace: "pre-wrap", overflowX: "hidden" }}
                                            >
                                                {textVendor}
                                            </pre>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
        </div>
    )
}
