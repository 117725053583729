/**
 * Componente para importação de dados via excel
 * Desenvolvido por: Equipe Rafael Cezário
 */

import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import XLSX from 'xlsx';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import './stepper.css';
import { useModal } from '../../contexts/useModal';
import { api } from '../../services/api'
import { ArrowLoader } from '../loaders/arrowLoader';
import { ModalSizeFitToScreen } from '../../utils/responsiveFunctions/modalSizeFitToScreen'
import { FiArrowDown } from 'react-icons/fi';
import { validyFiles } from '../../utils/validyTypesOfFiles'

const useStyles = makeStyles((theme) => ({
    root: {
        "& .MuiStepLabel-iconContainer .MuiStepLabel-alternativeLabel": {
            background: 'red'
        },
        fontFamily: 'Roboto',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        fontWeight: '400'
    },

    stepperItems: {
        display: 'flex',
        padding: '0.5rem',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
    },

    '& MuiTypography-root .makeStyles-instructions-11 .MuiTypography-body1': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    backButton: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        // marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        fontFamily: 'Roboto',
        fontSize: '0.9rem !important'
    },
}));

function getSteps(externalSteps) {
    return externalSteps;
}

function getStepContent(stepIndex) {
    switch (stepIndex) {
        case 0:
            return 'Selecione a planilha no botão acima';
        case 1:
            return 'Os dados estão corretos? Se sim Clique em importar';
        case 2:
            return 'Clique em importar abaixo'
        default:
            return '';
    }
}

export default function HorizontalLabelPositionBelowStepper(props) {

    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const [fileName, setFilename] = useState('');
    const steps = ['Selecione o arquivo', 'Confira os dados']
    const [rows, setRows] = useState([]);
    const [errorList, setErrorsList] = useState([]);
    const [successImport, setSuccessImport] = useState(false);
    const [hideButtons, setHideButtons] = useState(false);
    const [file, setFile] = useState([]);
    const [loading, setLoading] = useState(false);
    const { show, setShow, setType } = useModal();
    const token = localStorage.getItem('@auth:token');
    const [errorsImport, setErrorsImport] = useState(false);
    const [showErrors, setShowErrors] = useState(false);
    const [importPlainMessage, setImportPlainMessage] = useState('');
    const [importProgress, setImportProgress] = useState(0)
    const { screenX, screenY } = ModalSizeFitToScreen();


    useEffect(() => {

        setActiveStep(0)
        setRows([])
        setShowErrors(false)
        setFilename('')
        setSuccessImport(false)
        setErrorsImport(false)
        setImportProgress(0)

    }, [show])

    async function getLayout() {

        try {
            const getLayouts = await api.get('/api/v1/clients/layoutSpreadsheet', {
                headers: {
                    authorization: `Bearer ${token}`
                },
                responseType: 'blob'
            })

            const url = URL.createObjectURL(new Blob([getLayouts.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', 'layout_importação_clientes.xls')
            document.body.appendChild(link)
            link.click()
        } catch (error) {
            console.log(error?.response)
            alert(error?.response?.data?.message === undefined ? 'Erro ao buscar Layout' : error?.response?.data?.message)
        }
    }

    const handleNext = async (props) => {

        if (props?.parameters?.type === 'import') {


            rows.map((rowInExcel, indexRow) => {

                if (rowInExcel['status_client']?.length > 0); {
                    const newStatusClient = rowInExcel['status_client']?.substr(0, 1);

                    rows[indexRow]['status_client'] = newStatusClient;
                }
            })

            setLoading(true);
            try {
                const importClientAPI = new Promise(async (resolve, reject) => {
                    try {
                        const importResult = await api.post('/api/v1/clients/import', {
                            "data": rows
                        },
                            {
                                headers: {
                                    authorization: `Bearer ${token}`
                                }
                            }
                        )
                        console.log('terminou', importResult)
                        resolve(importResult)
                    } catch (error) {
                        console.log('terminou em erro', error)
                        reject(error)
                    }
                })

                let inProcessLoading = true;
                let timeCount = 0
                let progress = 0

                do {
                    setHideButtons(true);
                    if (timeCount == 5) {
                        setImportPlainMessage(`A planilha está em processo de importação.`)
                    } else if (timeCount < 10 && parseInt((progress / rows.length) * 100) < 50) {
                        setImportPlainMessage(`Este processo pode levar alguns minutos.`)
                    }
                    else if (timeCount < 10 && parseInt((progress / rows.length) * 100) > 50 && parseInt((progress / rows.length) * 100) < 80) {
                        setImportPlainMessage(`Mais da metade dos itens ja importados.`)
                    }
                    else if (timeCount < 15 && parseInt((progress / rows.length) * 100) > 80) {
                        setImportPlainMessage(`Aguarde, o processo está quase acabando.`)
                    }

                    importClientAPI.then((value) => {
                        setImportProgress(0)
                        inProcessLoading = false;
                        setHideButtons(false);
                        setLoading(false);
                        setSuccessImport(true);
                        setType({ name: 'showImportClients', value: 'successImport' })
                        if (value?.data?.errors_details?.length > 0) {
                            setErrorsImport(true);
                            setErrorsList(value?.data?.errors_details);
                        }
                    }).catch((error) => {
                        inProcessLoading = false;

                        if (error?.response?.data?.status === 500) {

                            setErrorsList([{ error_message: error?.response?.data?.message }]);
                            setHideButtons(false);
                            setLoading(false);
                            setErrorsImport(true);
                        } else {
                            setHideButtons(false);
                            setLoading(false);
                            setErrorsList(error?.response?.data?.errors_detail);
                            setErrorsImport(true);
                        }
                    })

                    await new Promise(resolve => {
                        setTimeout(() => {
                            timeCount += 5
                            progress += 230
                            if ((progress / rows.length) * 100 < 100) {
                                setImportProgress(parseInt((progress / rows.length) * 100))
                            } else {
                                setImportProgress(100)
                            }
                            resolve()
                            if (timeCount == 20) timeCount = 0
                        }, 5000)
                    })

                } while (inProcessLoading == true);

            } catch (error) {
                alert('Erro inesperado.')
                setLoading(false);
            }
        }
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    async function readExcelFile(file) {

        setLoading(true);

        const promise = new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsArrayBuffer(file);

            fileReader.onload = (e) => {
                const bufferArray = e.target.result;
                const wb = XLSX.read(bufferArray, { type: 'buffer' });
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];
                const validateData = []
                validateData?.push(ws?.A1?.v)
                validateData?.push(ws?.B1?.v)
                validateData?.push(ws?.C1?.v)
                validateData?.push(ws?.D1?.v)
                validateData?.push(ws?.E1?.v)
                validateData?.push(ws?.F1?.v)
                validateData?.push(ws?.G1?.v)
                // validateData?.push(ws?.H1?.v)
                // validateData?.push(ws?.I1?.v)
                // validateData?.push(ws?.J1?.v)
                // validateData?.push(ws?.K1?.v)
                // validateData?.push(ws?.L1?.v)
                const schemaToValidate = ['Código', 'Nome', 'Perfil do Cliente', 'Net Total', 'Net Ações', 'Net FIIs', 'Cód. Assessor']

                const errors = validateData.filter((fieldToValidate, index) => {

                    if (fieldToValidate !== schemaToValidate[index]) {
                        return fieldToValidate
                    }
                })

                if (errors?.length > 0) {
                    alert('Erro no Layout da planilha, verifique os nomes e a ordem das colunas!')
                    setLoading(false);
                    return
                }
                else {
                    const data = XLSX.utils.sheet_to_json(ws, {
                        header: ['external_id', 'name', 'client_profile', 'net_total', 'net_stock', 'net_fii', 'advisor_id'],
                        blankRows: false
                    });

                    const newData = data.filter((dataToVerify) => {
                        if (dataToVerify.external_id !== '' && dataToVerify.external_id !== undefined
                            && dataToVerify.external_id !== ' ') {
                            return (dataToVerify)
                        }
                    })
                    // console.log(newData)
                    resolve(newData);
                }
            }

            fileReader.onerror = ((error) => {
                reject(error);
            })
        })

        promise.then((data) => {
            data.splice(0, 1)
            setRows(data);
            setLoading(false);
        })
    }

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setSuccessImport(false)
        setShowErrors(false)
        setActiveStep(0)
    };

    function handleFileCheck() {
        if (rows.length > 0) {
            handleNext()
        }
        else {
            alert('Carregue a planilha antes')
        }
    }

    function fileClick() {

        document.getElementById('getFile').value = '';
        document.getElementById('getFile').click()
        setSuccessImport(false);
        setErrorsImport(false);
        setShowErrors(false)
    }

    function tableForImportReccords() {

        return (
            rows?.length > 0 ?
                (<table id='tableForImportReccords'>
                    <thead id='bodyImport'>
                        <tr>
                            <th>Código</th>
                            <th>Nome do Cliente</th>
                            <th>Perfil do Cliente</th>
                            <th>Net Total</th>
                            <th>Net Ações</th>
                            <th>Net FIIs</th>
                            <th>Código do assessor</th>
                        </tr>
                    </thead>
                    <tbody id='bodyImport'>
                        {rows?.map((bodyRows, index) => {

                            return (
                                <tr>
                                    <td>{bodyRows.external_id}</td>
                                    <td>{bodyRows.name}</td>
                                    <td>{bodyRows.client_profile}</td>
                                    <td>{bodyRows.net_total}</td>
                                    <td>{bodyRows.net_stock}</td>
                                    <td>{bodyRows.net_fii}</td>
                                    <td>{bodyRows.advisor_id}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>) :
                (<></>)

        )
    }

    function errorsTable() {

        if (errorList?.length > 0) {
            return (
                <table id='tableErrorsImport'>
                    <thead>
                        <tr>
                            <th>Cod Cliente</th>
                            <th>Nome do Cliente</th>
                            <th>Erro</th>
                        </tr>
                    </thead>
                    <tbody>
                        {errorList?.map((errorDetail) => {
                            return (
                                <tr>
                                    <td>{errorDetail.client_id}</td>
                                    <td>{errorDetail.client_name}</td>
                                    <td>{errorDetail.error_message}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            )
        }
    }

    return (
        <div className='stepMainContainer' style={{
            width: screenX,
            height: screenY
        }}>
            <div className={classes?.root}>
                <div className='stepperDiv'>
                    <button className='modalCloseButton' onClick={() => setShow(false)}>X</button>
                    <Stepper className={classes?.stepperItems} activeStep={activeStep} alternativeLabel>
                        {steps?.map((label) => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                </div>
                <div className='fileInput'>
                    {successImport || hideButtons ?
                        (<></>) :
                        (
                            <div id='clientsImportButtons'>
                                <button className='customButton' onClick={() => { setShowErrors(true); setSuccessImport(false); setErrorsImport(false); fileClick() }}>Selecionar Arquivo</button>
                                <button className='customButton' onClick={() => getLayout()}>Arquivo Layout <FiArrowDown /> </button>
                                <a>{fileName.substring(12, fileName.length)}</a>
                                <input
                                    type='file'
                                    accept='application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                                    id="getFile"
                                    onChange={(e) => {
                                        if (validyFiles(e.target.files[0], 'excel')) {
                                            return
                                        }
                                        const fileToRead = e.target.files[0];
                                        readExcelFile(fileToRead);
                                        setFilename(e.target.value)
                                    }}
                                >
                                </input>
                            </div>
                        )}

                </div>
            </div>
            <div className='fileInput'>
                {loading ? (
                    <div className='stepperLoader'>
                        <ArrowLoader />
                        {importPlainMessage}
                        <div id='importClientsProgressBarContainer'>
                            <span id='progressBarStatus' style={{ width: importProgress + '%', height: '100%' }}>
                                <a>{importProgress + '%'}</a>
                            </span>
                        </div>
                    </div>) : successImport && !errorsImport ?
                    (
                        <div id='stepperRowDiv' className='stepperImportWithoutErrors'>
                            <a>Sucesso ao importar os clientes</a>
                        </div>
                    ) :
                    successImport && errorsImport ? (
                        <div id='stepperRowDiv' className='stepperImportWithErrors'>
                            <a>Sucesso ao importar arquivos!</a>
                            <a>Mas, ocorreram erros na importação</a>
                            <button onClick={() => { setShowErrors(true); setSuccessImport(false); setErrorsImport(false) }}>Verificar erros</button>
                        </div>
                    ) : !successImport && errorsImport ?
                        (
                            <div id='stepperRowDiv' className='stepperImportWithErrors'>
                                <a>ocorreram erros na importação</a>
                                <button onClick={() => { setShowErrors(true); setSuccessImport(false); setErrorsImport(false) }}>Verificar erros</button>
                            </div>
                        ) :
                        showErrors ?
                            (<div id='customScroll' className='tableErrorsImport'> {errorsTable()}</div>)
                            :
                            (<div id='customScroll' className='tableForImportSectionClients'>{tableForImportReccords()} </div>)}
                <div>
                    {activeStep === steps.length && successImport ?
                        (
                            <div id='stepperRowDiv'>
                                <Button className='customButton' onClick={handleReset}>Reimportar</Button>
                                <Button onClick={() => setShow(false)}>Fechar</Button>
                            </div>
                        ) :
                        activeStep === steps.length && !successImport ?
                            (
                                <div id='stepperRowDiv'>
                                    <Button className='customButton' onClick={handleReset}>Reimportar</Button>
                                    <Button onClick={() => setShow(false)}>Fechar</Button>
                                </div>
                            ) :

                            hideButtons ? (<></>) :
                                (
                                    <div >
                                        <Typography className={classes.instructions}>{getStepContent(activeStep)}</Typography>
                                        <div id='stepperRowDiv' >
                                            <Button
                                                disabled={activeStep === 0}
                                                onClick={handleBack}
                                                className={classes.backButton}
                                            >
                                                Voltar
                                            </Button>
                                            <Button
                                                id='buttonImport'
                                                variant="contained"
                                                className='customButton'
                                                onClick={activeStep === steps.length - 1 ? () => handleNext(props) : () => handleFileCheck()}>
                                                {activeStep === steps.length - 1 ? 'Importar' : 'Próximo'}
                                            </Button>
                                        </div>
                                    </div>
                                )}
                </div>
            </div>
        </div>
    );
}