import React, { useEffect, useState, useRef, useReducer } from "react";
import MUIDataTable from "mui-datatables";
import { Tooltip, IconButton, TableCell } from "@material-ui/core";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { ThemeProvider,createTheme } from '@mui/material/styles';
import { AiOutlineArrowUp, AiOutlineArrowDown } from "react-icons/ai";

import { useModal } from "../../../../contexts/useModal";
import { useAuth } from "../../../../contexts/useAuth";
import { useSocket } from "../../../../contexts/useSocket";

import { FiPlus, FiTrash2 } from "react-icons/fi";
import { BiSearch } from "react-icons/bi";
import { BsBasket } from "react-icons/bs";

import { ArrowLoader } from "../../../../components/loaders/arrowLoader";

import { api } from "../../../../services/api";

import { titleize } from "../../../../utils/formatNumberAndText/formatTextUpperFirstLetter";

import { toBRL } from "../../../../utils/formatNumberAndText/toBRLCurrency";
import { toPercent } from "../../../../utils/formatNumberAndText/formatNumberPercent";

import { HiSortAscending, HiSortDescending } from "react-icons/hi";
import { FiArrowDown, FiArrowUp, FiCopy } from "react-icons/fi";
import { Autocomplete } from "@material-ui/lab";
import { TextField, TextareaAutosize } from "@material-ui/core";
import CurrencyTextField from "@unicef/material-ui-currency-textfield";

// import CustomToolbarSelect from "../toolbar/customSelectedToolbarWisirTracking";
// import "../styles/tracking.css";


export function MappingTable() {
    const { user, tokenKey } = useAuth();
    const { show, setShow, edited, setEdited, type, setType } = useModal();

    const [loading, setLoading] = useState(false);

    const [selectedRows, setSelectedRows] = useState([]);
    const [mapping, setMapping] = useState([]);
    const [strategies, setStrategies] = useState([]);
    const [rowsPerPage, setRowsPerPage] = useState(30);


    useEffect(
        () => {
        setLoading(true)
        getMap();
        getStrategies();
        setLoading(false)
        },
        [tokenKey],
        [show],
        [edited]
    );

    const getMap = async () => {
        try {
            const options = await api.get(`/api/v1/wisir2/map`, {
            headers: {
                authorization: `Bearer ${tokenKey}`,
            },
            responseType: "text",
            });
            setMapping(options?.data?.data);
        } catch (error) {
            console.log(error);
        }
    }

    async function getStrategies() {
        try {
            const strats = await api.get(`/api/v1/wisir/strategies`, {
            headers: {
                authorization: `Bearer ${tokenKey}`,
            },
            responseType: "text",
            });
            setStrategies(strats?.data?.data);
        } catch (error) {
            console.log(error);
        }
    }

    var columns = [
        {
            name: "id",
            label: "ID",
            options: {
                display: false,
                sort: false,
                filter: false
            }
        },
        {
            name: "entry",
            label: "CHAVE",
            options: {
                filter: true,
                sort: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        padding: '0px 0.6rem 0px 0.6rem',
                    },
                }),
            },
        },
        {
            name: "strategy",
            label: "ESTRATÉGIA",
            options: {
                filter: true,
                sort: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        padding: '0px 0.6rem 0px 0.6rem',
                    },
                }),
            },
        }
    ];

        // Adiciona um botão para chamar a função de copiar a tabela para a área de tranferência
    const AddButton = () => (
        <>
            {/* <Tooltip disableFocusListener title="Copiar para a área de transferência">
            <IconButton onClick={copyToClipboard}>
                <ContentCopyIcon />
            </IconButton>
            </Tooltip>
            {user.type < 3 ? (
            <>
                <Tooltip title="Atualização via Excel.">
                <IconButton
                    onClick={() => {
                        //   setType({ name: 'showImport', value: activeTableId, strategy: activeTableName, update: getTab  });
                        //   setShow(true);
                    }}>
                    <span style={{ fontSize: "1.4rem" }}><FiArrowUp size={14}/> Importar</span>
                </IconButton>
                </Tooltip>
                <Tooltip title="Atualização via Excel.">
                <IconButton
                    onClick={() => {
                        //   setType({ name: 'showImportCarteira', value: activeTableId, strategy: activeTableName  });
                        //   setShow(true);
                    }}>
                    <span style={{ fontSize: "1.4rem" }}><FiArrowUp size={14}/> Carteira</span>
                </IconButton>
                </Tooltip>
            </>
                    ) : (<></>)} */}
            <Tooltip title="Adicionar mapeamento.">
                <IconButton
                    onClick={() => {
                        mappingEdit(null)
                    }}>
                    <span style={{ fontSize: "1.4rem" }}><FiPlus/></span>
                </IconButton>
            </Tooltip>
            
        </>
    );

    const sendInactivate = async () => {
        const rowsToSend = selectedRows.map((s) => mapping[s].id)
        console.log(rowsToSend, selectedRows.map((s) => mapping[s]))
        setShow(false)
        try{
            const updateWisir = await api.delete(
                `/api/v1/wisir2/map`,
                {
                    data: { ids: rowsToSend },            
                    headers: {
                        authorization: `Bearer ${tokenKey}`,
                    },
                }
            );
            getMap()
            // getTab(activeTableId)
            setType({
                name: "alertMsg",
                type: "information",
                value: { message: 'Mapeamento deletados com sucesso.' },
            });
            setShow(true);
        } catch (error) {
            setLoading(false);
            setType({
            name: "alertMsg",
            type: "information",
            value: { message: error?.response?.data?.message },
            });
            setShow(true);
        }
    }

    const mappingEdit = async (id) => {
        setType({
            name: 'edit',
            id: id,
            info: mapping.filter((x) => x.id == id),
            strats: strategies,
            update: getMap
        });
        setShow(true);
    }

    const options = {
    filterType: "multiselect",
    selectableRows: "multiple",
    selectableRowsOnClick: false,
    filter: true,
    download: false,
    print: false,
    responsive: "vertical",
    tableBodyHeight: "60vh",
    onRowSelectionChange: (rowsSelectedData, allRows, rowsSelected) => {
        setSelectedRows(rowsSelected);
    },
    draggableColumns: {
        enabled: true,
    },
    selectableRowsHideCheckboxes: false,
    viewColumns: false,
    toolButton: {
        justifyContent: "center"
    },
    textLabels: {
        body: {
            noMatch: loading ? <ArrowLoader /> : "Não há operações no histórico.",
            toolTip: "Ordenar",
            columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
        },
        pagination: {
            next: "Próxima",
            previous: "Anterior",
            rowsPerPage: "Linhas por página:",
            displayRows: "de",
        },
        toolbar: {
            search: "Pesquisar",
            downloadCsv: "Download XLSX",
            print: "Imprimir",
            viewColumns: "Ver colunas",
            filterTable: "Filtrar",
        },
        filter: {
            all: "Todos",
            title: "Filtros",
            reset: "Limpar",
        },
        viewColumns: {
            title: "Mostrar Colunas",
            titleAria: "Mostrar/Ocultar colunas",
        },
        selectedRows: {
            text: "linha(s) selecionadas",
            delete: "Excluir",
            deleteAria: "Excluir linhas Selecionadas",
        },
    },
    downloadOptions: {
        filename: "wisir.csv",
        separator: ";",
    },
    onDownload: () => {
        // requestFile();
        // assetStructuredExport();
        return false;
    },
    onRowClick: (rowData, rowMeta) => {
            mappingEdit(rowData[0])
        // showClientDetails({id: rowData[0], name: rowData[1], strategy: activeTableId, client_status: rowData[2] });
        },
    sortOrder: {
        name: 'current_value',
        direction: 'desc'
    },
    customToolbar: AddButton,
    setTableProps: () => {
        return {
            padding: 'none',
            size: 'small'
        };
    },
    onRowsDelete: sendInactivate,
    rowsPerPage: rowsPerPage,
    rowsPerPageOptions: [30, 100, 1000],
    };

    const getMuiTheme = () =>
    createTheme({
    components: {
    MuiTableCell: {
        styleOverrides: {
            root:{
                    justifyContent: 'center',
                    whiteSpace: "nowrap",
                    textAlign: 'center',
            }
        },
        },
    MUIDataTableHeadCell: {
        styleOverrides: {
            contentWrapper: {
                justifyContent: 'center',
                whiteSpace: "nowrap",
                // textAlign: 'center'
            }
        }
    },
    },
    })

    return (
        <div key="performanceTabsTable" className="performanceTabsTable">
            <>
            { loading ? (
                <div className="divLoading">
                <ArrowLoader />
            </div>
            ) : (

                <ThemeProvider theme={getMuiTheme()}>
                    <MUIDataTable
                        title={`Mapeamento Wisir`}
                        data={mapping}
                        columns={columns}
                        options={options}
                        ></MUIDataTable>
                </ThemeProvider>

            )}
            </>
        </div>
    );
}
