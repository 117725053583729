import React from 'react';
/*Styles*/
import './styles/assets.css';
/*Contexts*/
import { useModal } from '../../../../contexts/useModal';
import { useAuth } from '../../../../contexts/useAuth';
/*Components*/
import Modal from '../../../../components/modal/modal';
import { ConfirmationBox } from '../../../../components/confirmationBox/confirmationBox';
import { AssetsTable } from './tables/assets.table';
import { AssetsCadastrationModal } from './assets.cadastration.modal';
import { AssetsImportModal } from './assetsImportModal';
import { AssetsEdit } from './assets.edit';

export function Assets() {

    const { show, setShow, type } = useModal();
    const { user } = useAuth();

    return (
        <div className="assetsContainer" style={{minWidth: !show ? '1000px' : '0'}}>
            <Modal>
                {
                    type.name === 'alertMsg' ?
                        (
                            <ConfirmationBox msgData={type} />
                        ) :

                        type.name === 'assetsCadastrationModal' ?
                            (
                                <AssetsCadastrationModal />
                            ) :
                            type.name === 'assetsImportModal' ?
                                (
                                    <AssetsImportModal />
                                ) :

                                type.name === 'editAssets' ?
                                    (<AssetsEdit data={type.asset} />) :
                                    (<></>)
                }
            </Modal>
            <AssetsTable />
        </div>
    )
}