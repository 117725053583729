/*Componente tabela de Analise da base de clientes
/*Desenvolvido por: Equipe Tech RV
*/

//libraries
import React, { useEffect, useState, useRef,useReducer } from "react";
import { FiPlus, FiSearch, FiArrowDown, FiCopy } from "react-icons/fi";
import { AiOutlineArrowUp, AiOutlineArrowDown } from "react-icons/ai";
import { MdKeyboardArrowRight,MdKeyboardArrowDown } from "react-icons/md";
import { FormGroup, FormLabel, FormControl, ListItemText, TextField, Checkbox, FormControlLabel, Grid, Select, InputLabel, MenuItem } from "@mui/material";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import { ThemeProvider,createTheme } from '@mui/material/styles';
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
//hooks
import { useModal } from "../../../contexts/useModal";
import Modal from "../../../components/modal/modal";
import { useAuth } from "../../../contexts/useAuth";
//services
import { api } from "../../../services/api";
//components
import { ArrowLoader } from "../../../components/loaders/arrowLoader";
import { Alert } from "../../../components/alert/alert";
import { Tooltip, IconButton } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
//utils
import { titleize } from "../../../utils/formatNumberAndText/formatTextUpperFirstLetter";
import { ModalSizeFitToScreen } from "../../../utils/responsiveFunctions/modalSizeFitToScreen";
import TableViewCol from "../../../utils/muiDatatables/TableViewCol";
import { getABCDashboard } from "../../../services/asyncFunctions"

//styles
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { MdOutlineMonetizationOn, MdOutlineCheckCircleOutline, MdOutbox } from "react-icons/md";

import { HiOutlineSquares2X2 } from "react-icons/hi2";
import { FaRegAddressCard } from "react-icons/fa";

import { toBRL } from "../../../utils/formatNumberAndText/toBRLCurrency";
import "./mainStyle.css";
import "./dashboardAbc.css";

export function DashboardTable() {
    const { show, setShow, setType, type, edited, setEdited } = useModal();
    const { user, tokenKey } = useAuth();
    const [filtered, setFiltered] = useState("");
    const [selectedFilter, setSelectedFilter] = useState("Código");
    const [listClients, setListClients] = useState([]);
    const [listAllClients, setListAllClients] = useState([]);
    const [listFilteredClients, setListFilteredClients] = useState([]);
    const [listSearchedClients, setListSearchedClients] = useState([]);
    const [listFilteredProducts, setListFilteredProducts] = useState([]);
    const [listSearchedProducts, setListSearchedProducts] = useState([]);
    const [totalClients, setTotalClients] = useState(0);
    const [lastPage, setLastPage] = useState(0);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(30);
    const [filtersList, setFiltersList] = useState({});
    const [search, setSearch] = useState("");
    const [profilesList, setProfilesList] = useState([]);
    const [listExceptionClients, setListExceptionClients] = useState([]);
    const [listExceptionClientsIDs, setListExceptionClientsIDs] = useState([]);
    const [scrollTable, setScrollTable] = useState(null);
    const [scrollTableLines, setScrollTableLines] = useState(50);
    const [bottomScroll, setBottomScroll] = useState(false);
    const [viewCheckbox, setViewCheckbox] = useState(false);
    const [loading, setLoading] = useState(true);
    const [isloaded, setIsloaded] = useState(false);
    const token = localStorage.getItem("@auth:token");
    const currentUser = JSON.parse(localStorage.getItem("@auth:user"));
    const [columnsToDisplay, setColumnsToDisplay] = useState();
    const [okay, setOkay] = useState(false);
    const [visibleInteractionDaysType, setVisibleInteractionDaysType] = useState(0);
    const [showAlert, setShowAlert] = useState(true);
    const [sumNetTotal, setSumNetTotal] = useState(0);
    const [sumNetStock, setSumNetStock] = useState(0);
    const [sumNetFii, setSumNetFii] = useState(0);
    const [sumPosRV, setSumPosRV] = useState(0);
    const [dataAbc, setDataAbc] = useState();
    const visibleColumnsRef = useRef([]);
    const listTeams = useRef([]);
    const rowsToView = useRef();
    const [products, setProducts] = useState([])
    const [brokers, setBrokers] = useState([])

    const [, forceUpdate] = useReducer(x => x + 1, 0);

    async function getProducts(response) {
        setLoading(true);
        const res = await getABCDashboard(tokenKey, false)
        setProducts(res.data?.data?.products);
        setBrokers(res.data?.data?.dashboard)
        
        if(response?.length == 0){
            response = null
        }

        visibleColumnsRef.current = [
            {
                name: "product_id",
                label: "PRODUTO",
                options: {
                    filter: false,
                    sort: false,
                    display: false,
                },
            },
            {
                name: "product_title",
                label: "PRODUTO",
                options: {
                    filter: true,
                    sort: true,
                    display: true,
                    setCellProps: () => ({
                        style: {
                            whiteSpace: "nowrap",
                            paddingLeft: "0.5rem",
                            paddingRight: "0.5rem",
                            minWidth: '20rem'
                        },
                    }),
                },
            },
            {
                name: "amount_value",
                label: "Net Alocado",
                options: {
                    filter: true,
                    sort: true,
                    display: true,
                    setCellProps: () => ({
                        style: {
                            whiteSpace: "nowrap",
                            paddingLeft: "0.5rem",
                            paddingRight: "0.5rem",
                            minWidth: '10rem'
                        },
                    }),
                    customBodyRender: (val) => {
                        return (val != null && val != undefined)  ? toBRL((val)) : "-";
                    },
                },
            },
            {
                name: "amount_objective",
                label: "Objetivo",
                options: {
                    filter: true,
                    sort: true,
                    display: true,
                    setCellProps: () => ({
                        style: {
                            whiteSpace: "nowrap",
                            paddingLeft: "0.5rem",
                            paddingRight: "0.5rem",
                            minWidth: '10rem'
                        },
                    }),
                    customBodyRender: (val) => {
                        return (val != null && val != undefined)  ? toBRL((val)) : "-";
                    },
                },
            },
            {
                name: "qty_clients",
                label: "Qtd. Clientes",
                options: {
                    filter: true,
                    sort: true,
                    display: true,
                    setCellProps: () => ({
                        style: {
                            whiteSpace: "nowrap",
                            paddingLeft: "0.5rem",
                            paddingRight: "0.5rem",
                        },
                    }),
                },
            },
            {
                name: "pendente",
                label: "Pendentes",
                options: {
                    filter: true,
                    sort: true,
                    display: true,
                    setCellProps: () => ({
                        style: {
                            whiteSpace: "nowrap",
                            paddingLeft: "0.5rem",
                            paddingRight: "0.5rem",
                        },
                    }),
                },
            },
            {
                name: "abordado",
                label: "Abordados",
                options: {
                    filter: true,
                    sort: true,
                    display: true,
                    setCellProps: () => ({
                        style: {
                            whiteSpace: "nowrap",
                            paddingLeft: "0.5rem",
                            paddingRight: "0.5rem",
                        },
                    }),
                },
            },
            {
                name: "solicitado",
                label: "Solicitados",
                options: {
                    filter: true,
                    sort: true,
                    display: true,
                    setCellProps: () => ({
                        style: {
                            whiteSpace: "nowrap",
                            paddingLeft: "0.5rem",
                            paddingRight: "0.5rem",
                        },
                    }),
                },
            },
            {
                name: "boletado",
                label: "Boletados",
                options: {
                    filter: true,
                    sort: true,
                    display: true,
                    setCellProps: () => ({
                        style: {
                            whiteSpace: "nowrap",
                            paddingLeft: "0.5rem",
                            paddingRight: "0.5rem",
                        },
                    }),
                },
            },
            {
                name: "cancelado",
                label: "Cancelados",
                options: {
                    filter: true,
                    sort: true,
                    display: true,
                    setCellProps: () => ({
                        style: {
                            whiteSpace: "nowrap",
                            paddingLeft: "0.5rem",
                            paddingRight: "0.5rem",
                        },
                    }),
                },
            },
            {
                name: "recusado",
                label: "Recusados",
                options: {
                    filter: true,
                    sort: true,
                    display: true,
                    setCellProps: () => ({
                        style: {
                            whiteSpace: "nowrap",
                            paddingLeft: "0.5rem",
                            paddingRight: "0.5rem",
                        },
                    }),
                },
            },
            {
                name: "nao_aderente",
                label: "Não Aderentes",
                options: {
                    filter: true,
                    sort: true,
                    display: true,
                    setCellProps: () => ({
                        style: {
                            whiteSpace: "nowrap",
                            paddingLeft: "0.5rem",
                            paddingRight: "0.5rem",
                        },
                    }),
                },
            },
            {
                name: "outros",
                label: "Outros",
                options: {
                    filter: true,
                    sort: true,
                    display: true,
                    setCellProps: () => ({
                        style: {
                            whiteSpace: "nowrap",
                            paddingLeft: "0.5rem",
                            paddingRight: "0.5rem",
                        },
                    }),
                },
            },
        ]
        setLoading(false);
    }

    useEffect(() => {
        getProducts()
    }, []);

    async function copyToClipboard() {
        const output = [];

        var titles = [
            "PRODUTO",
            "CÓDIGO LÍDER",
            "LÍDER",
            "CÓDIGO BROKER",
            "BROKER",
            "NET ALOCADO",
            "OBJETIVO",
            "QTD. CLIENTES",
            "PENDENTES",
            "ABORDADOS",
            "SOLICITADOS",
            "BOLETADOS",
            "CANCELADOS",
            "RECUSADOS",
            "NÃO ADERENTES",
            "OUTROS",
        ];

        var keys = [
            "product_title",
            "leader_id",
            "leader_name",
            "broker_id",
            "broker_name",
            "amount_value",
            "amount_objective",
            "qty_clients",
            "pendente",
            "abordado",
            "solicitado",
            "boletado",
            "cancelado",
            "recusado",
            "nao_aderente",
            "outros",
        ];

        output.push(titles.join("\t"));

        brokers.forEach((x) => {
            const tmp = keys.map(y => x[y]);
            output.push(tmp.join("\t"));
        });
        const table = output.join("\n");
        navigator.clipboard.writeText(table);
        alert("Conteúdo da tabela copiado para a área de transferência");
    }

    async function clientsExport() {
        setLoading(true);
        try {
            const res = await getABCDashboard(tokenKey, true)
            const responseURL = URL.createObjectURL(new Blob([res]));
            const link = document.createElement("a");
            link.href = responseURL;
            link.setAttribute("download", "dashboard_abc.xlsx");
            document.body.appendChild(link);
            link.click();
            setLoading(false);
        } catch (error) {
            console.log(error)
            setType({
                name: "alertMsg",
                type: "information",
                value: {
                    message: error?.response?.data?.message === undefined ? "Erro ao buscar dados para exportação" : error?.response?.data?.message,
                },
            });
            setShow(true);
            setLoading(false);
        }
    }

    const AddButton = () => (
        <>
            <Tooltip disableFocusListener title="Copiar para a área de transferência">
                <IconButton onClick={copyToClipboard}>
                    <ContentCopyIcon />
                </IconButton>
            </Tooltip>
        </>
    );

    var columnsAux = [];

    const columnsExceptionView = ['product_description', 'product_type_description', 'product_id'];
    const columnsOrder = [0, 1, 2, 3, 4, 5, 6];


    const CustomToView = (props) => {
        return <TableViewCol {...props} ocultColumnsToView={columnsExceptionView} orderColumns={columnsOrder} />;
    };

    const options = {
        filterType: "multiselect",
        download: true,
        print: false,
        responsive: "vertical",
        tableBodyHeight: "71vh",
        selectableRowsHideCheckboxes: true,
        rowsSelected: user.type === 3 && viewCheckbox === true ? listExceptionClients : [],
        selectToolbarPlacement: "replace",
        selectableRows: "multiple",
        viewColumns: true,
        rowsPerPage: rowsPerPage,
        page: page,
        count: listFilteredClients.length,
        rowsPerPageOptions: [30, 50, 100],
        textLabels: {
            body: {
                noMatch: loading ? <ArrowLoader /> : "Não foram encontrados dados",
                toolTip: "Ordenar",
                columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
            },
            pagination: {
                next: "Próxima",
                previous: "Anterior",
                rowsPerPage: "Linhas por página:",
                displayRows: "de",
            },
            toolbar: {
                search: "Pesquisar",
                downloadCsv: "Download XLSX",
                print: "Imprimir",
                viewColumns: "Ver colunas",
                filterTable: "Filtrar",
            },
            filter: {
                all: "Todos",
                title: "Filtros",
                reset: "Limpar",
            },
            viewColumns: {
                title: "Mostrar Colunas",
                titleAria: "Mostrar/Ocultar colunas",
            },
            selectedRows: {
                text: "linha(s) selecionadas",
                delete: "Excluir",
                deleteAria: "Excluir linhas Selecionadas",
            },
        },
        downloadOptions: {
            filename: "abcManagement.csv",
            separator: ";",
        },
          onDownload: () => {
              clientsExport();
              return false;
          },
        customToolbar: AddButton,
        setTableProps: () => {
            return {
                padding: "none",
                size: "small",
            };
        },
        expandableRows: true,
        renderExpandableRow: (rowData, rowMeta) => {
            // console.log('expandleRow',rowData, rowMeta);
            // apenas broker que tem operações no produto
            const colSpan = rowData.length + 1;
            var brokersOnProduct = brokers
            brokersOnProduct = brokersOnProduct.filter(row => row.product_id == rowData[0])

            // Usando o método reduce para calcular o somatório de informaç~eos do time
            const resultado = brokersOnProduct.reduce((acumulador, broker) => {
                if (!acumulador[broker.leader_id]) {
                    acumulador[broker.leader_id] = {
                        broker_name: broker.leader_name,
                        amount_value: 0,
                        amount_objective: 0,
                        qty_clients: 0,
                        pendente: 0,
                        abordado: 0,
                        solicitado: 0,
                        boletado: 0,
                        cancelado: 0,
                        recusado: 0,
                        nao_aderente: 0,
                        outros: 0,
                    };
                }
                acumulador[broker.leader_id].clientes += broker.clientes;
                acumulador[broker.leader_id].amount_value += broker.amount_value 
                acumulador[broker.leader_id].amount_objective += broker.amount_objective 
                acumulador[broker.leader_id].qty_clients += broker.qty_clients 
                acumulador[broker.leader_id].pendente += broker.pendente 
                acumulador[broker.leader_id].abordado += broker.abordado 
                acumulador[broker.leader_id].solicitado += broker.solicitado 
                acumulador[broker.leader_id].boletado += broker.boletado 
                acumulador[broker.leader_id].cancelado += broker.cancelado 
                acumulador[broker.leader_id].recusado += broker.recusado 
                acumulador[broker.leader_id].nao_aderente += broker.nao_aderente 
                acumulador[broker.leader_id].outros += broker.outros 
                return acumulador;
            }, {});
            
            const listTeamsAux = Object.keys(resultado).map((broker_id,index) => {
                // console.log('EOQ', listTeams.current, index)
                return {
                    broker_id,
                    broker_name: resultado[broker_id].broker_name,
                    amount_value: resultado[broker_id].amount_value,
                    amount_objective: resultado[broker_id].amount_objective,
                    qty_clients: resultado[broker_id].qty_clients,
                    pendente: resultado[broker_id].pendente,
                    abordado: resultado[broker_id].abordado,
                    solicitado: resultado[broker_id].solicitado,
                    boletado: resultado[broker_id].boletado,
                    cancelado: resultado[broker_id].cancelado,
                    recusado: resultado[broker_id].recusado,
                    nao_aderente: resultado[broker_id].nao_aderente,
                    outros: resultado[broker_id].outros,
                    isTeam: true,
                    isOpen: (listTeams?.current.length != 0) ? listTeams.current[index]?.isOpen : false,
                    brokers: brokersOnProduct.filter((b) => b.leader_id == broker_id)
                };
            });
            // console.log('TIMES', listTeamsAux)
            // rowsToView.current = []
            listTeams.current = listTeamsAux
            // listTeamsAux.forEach((e) => {
            //     rowsToView.current.push(e)
            //     brokersOnProduct.map((b) => {
            //         if(b.leader_id == e.broker_id){
            //             rowsToView.current.push(b)
            //         }
            //     })
            // })
            // console.log('rowsToView.current',rowsToView.current)
            return (
              <React.Fragment>
                <tr>
                  <td colSpan={13}>
                    <TableContainer component={Paper}>
                      <Table style={{ minWidth: "650"}} aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell></TableCell>
                            <TableCell className={"noWrapAbc"} style={{minWidth: '17rem'}} align="center">TIME</TableCell>
                            <TableCell className={"noWrapAbc"} style={{minWidth: '8.5rem'}} align="center">NET ALOCADO</TableCell>
                            <TableCell className={"noWrapAbc"} style={{minWidth: '8rem'}} align="center">OBJETIVO</TableCell>
                            <TableCell className={"noWrapAbc"} align="center">QTD. CLIENTES</TableCell>
                            <TableCell className={"noWrapAbc"} align="center">PENDENTES</TableCell>
                            <TableCell className={"noWrapAbc"} align="center">ABORDADOS</TableCell>
                            <TableCell className={"noWrapAbc"} align="center">SOLICITADOS</TableCell>
                            <TableCell className={"noWrapAbc"} align="center">BOLETADOS</TableCell>
                            <TableCell className={"noWrapAbc"} align="center">CANCELADOS</TableCell>
                            <TableCell className={"noWrapAbc"} align="center">RECUSADOS</TableCell>
                            <TableCell className={"noWrapAbc"} align="center">NÃO ADERENTES</TableCell>
                            <TableCell className={"noWrapAbc"} align="center">OUTROS</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {listTeams.current?.map((row, index) => {
                                return (
                                <>
                                <TableRow key={index} className={'expandableRowDashAbc'}>
                                    <TableCell className={"expandableIconDashAbc"}>
                                        <button className="iconToExpandleRowsAbc" onClick={() => {   
                                            listTeams.current[index].isOpen = !(listTeams.current[index]?.isOpen)
                                            forceUpdate()
                                        }}>
                                            {listTeams.current[index].isOpen == true ? <MdKeyboardArrowDown size="24" color="rgba(0, 0, 0, 0.54)"/> : <MdKeyboardArrowRight size="24" color="rgba(0, 0, 0, 0.54)"/>}
                                        </button>
                                    </TableCell>
                                    <TableCell className={"noWrapAbcTeam"} align="center">{row.broker_name}</TableCell>
                                    <TableCell className={"noWrapAbcTeam"} align="center">{toBRL(row.amount_value)}</TableCell>
                                    <TableCell className={"noWrapAbcTeam"} align="center">{toBRL(row.amount_objective)}</TableCell>
                                    <TableCell className={"noWrapAbcTeam"} align="center">{row.qty_clients}</TableCell>
                                    <TableCell className={"noWrapAbcTeam"} align="center">{row.pendente}</TableCell>
                                    <TableCell className={"noWrapAbcTeam"} align="center">{row.abordado}</TableCell>
                                    <TableCell className={"noWrapAbcTeam"} align="center">{row.solicitado}</TableCell>
                                    <TableCell className={"noWrapAbcTeam"} align="center">{row.boletado}</TableCell>
                                    <TableCell className={"noWrapAbcTeam"} align="center">{row.cancelado}</TableCell>
                                    <TableCell className={"noWrapAbcTeam"} align="center">{row.recusado}</TableCell>
                                    <TableCell className={"noWrapAbcTeam"} align="center">{row.nao_aderente}</TableCell>
                                    <TableCell className={"noWrapAbcTeam"} align="center">{row.outros}</TableCell>
                                </TableRow>
                                {(listTeams.current[index]?.isOpen && listTeams.current[index]?.brokers) ? listTeams.current[index].brokers.map((b,i) => {
                                    return (<TableRow key={i} className="expandablesRowsDashAbc">
                                        <TableCell></TableCell>
                                        <TableCell className={"noWrapAbc"} align="center">{b.broker_name}</TableCell>
                                        <TableCell className={"noWrapAbc"} align="center">{toBRL(b.amount_value)}</TableCell>
                                        <TableCell className={"noWrapAbc"} align="center">{toBRL(b.amount_objective)}</TableCell>
                                        <TableCell className={"noWrapAbc"} align="center">{b.qty_clients}</TableCell>
                                        <TableCell className={"noWrapAbc"} align="center">{b.pendente}</TableCell>
                                        <TableCell className={"noWrapAbc"} align="center">{b.abordado}</TableCell>
                                        <TableCell className={"noWrapAbc"} align="center">{b.solicitado}</TableCell>
                                        <TableCell className={"noWrapAbc"} align="center">{b.boletado}</TableCell>
                                        <TableCell className={"noWrapAbc"} align="center">{b.cancelado}</TableCell>
                                        <TableCell className={"noWrapAbc"} align="center">{b.recusado}</TableCell>
                                        <TableCell className={"noWrapAbc"} align="center">{b.nao_aderente}</TableCell>
                                        <TableCell className={"noWrapAbc"} align="center">{b.outros}</TableCell>
                                    </TableRow>)
                                }) : <></>}
                                </>
                                )
                                
                            }
                            )
                            }
                            
                            </TableBody>
                            </Table>
                    </TableContainer>
                  </td>
                </tr>
              </React.Fragment>
            );
          },
    };

    const title = (
        <div>
            <div style={{ fontSize: "1.6rem", fontWeight: "400" }}>ABC - Dashboard</div>
            {/* <div>Total {totalClients}</div> */}
        </div>
    );

    const getMuiTheme = () =>
    createTheme({
      components: {
        MuiTableCell: {
            styleOverrides: {
                root:{
                        justifyContent: 'center',
                        whiteSpace: "nowrap",
                        textAlign: 'center',
                        // border: '5px solid',
                }
            },
          },
        // MuiTableCell: {
        //     styleOverrides: {
        //         body:{
        //                 justifyContent: 'center',
        //                 whiteSpace: "nowrap",
        //                 textAlign: 'center',
        //                 // border: '5px solid',
        //         }
        //     },
        //   },
        MUIDataTableHeadCell: {
            styleOverrides: {
                contentWrapper: {
                    justifyContent: 'center',
                    whiteSpace: "nowrap",
                }
            }
        },
      },
    })

    return loading ? (
        <div className="divLoading">
            <ArrowLoader />
        </div>
    ) : (
        <div className="abcManagementMainTableBoxAbc">
        <ThemeProvider theme={getMuiTheme()}>
            <MUIDataTable
                title={title}
                data={products}
                columns={visibleColumnsRef.current}
                options={options}
                components={{
                    TableViewCol: CustomToView,
                }}
                ></MUIDataTable>
        </ThemeProvider>
        </div>
    )
}

