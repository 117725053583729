import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import { Tooltip, IconButton } from "@material-ui/core";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { ThemeProvider,createTheme } from '@mui/material/styles';
/*Styles*/
import "../styles/cubastar.funds.table.css"
/*Contexts*/
import { useAuth } from "../../../../contexts/useAuth";
import { useModal } from "../../../../contexts/useModal";
/*Services*/
import { getNotExecuted, getDatabaseUpdateTime } from "../../../../services/asyncFunctions";
/*Components*/
import { ArrowLoader } from "../../../../components/loaders/arrowLoader";
import { toBRL } from "../../../../utils/formatNumberAndText/toBRLCurrency";
import { toPercent } from "../../../../utils/formatNumberAndText/formatNumberPercent";
import { AiOutlineCheck } from "react-icons/ai";

export function CubastarExamplesTable(data) {
    const [loading, setLoading] = useState(false);
    const { tokenKey } = useAuth();
    const { setType, setShow, edited, setEdited } = useModal();
    
    const tableData = data?.data.elements;
    // console.log(tableData); 
    var tableRows = [];

    

    // tableData((x) => {
    //     x.elements.map((y) => {
    //         var nomeProduto = y.description;
    //         var secao = y.secao;
    //         var liquidez = y.liquidity;
    //         var alocacao = y.allocation;
    //         tableRows.push({nomeProduto, secao, alocacao, liquidez});
    //     });
    // });

    tableData.forEach(x => {
        
            var nomeProduto = x.description;
            var secao = x.secao;
            var liquidez = x.liquidity;
            var alocacao = x.allocation;
            tableRows.push({nomeProduto, secao, alocacao, liquidez});
        
    });
    

    useEffect(() => {
        if (edited || tableData === 0 ) {
            setEdited(false);
        }
    }, [edited]);

    function copyToClipboard() {
        const output = [];
        const titles = [
            "Categoria",
            "Produto",
            "Liquidez",
            "Alocação"
        ];
        output.push(titles.join("\t"));
        const keys = [
            "nomeProduto",
            "secao",
            "liquidez",
            "alocacao"
        ];

        const table = output.join("\n");
        navigator.clipboard.writeText(table);
        alert("Conteúdo da tabela copiado para a área de transferência");
    }

    async function assetnotExecutedExport() {
        // setLoading(true);
        try {
            const myExport = await getNotExecuted(tokenKey, true);
            const responseURL = URL.createObjectURL(new Blob([myExport]));
            const link = document.createElement("a");
            link.href = responseURL;
            link.setAttribute("download", `Carteira Recomendada - ${data.data.description}.xlsx`);
            document.body.appendChild(link);
            link.click();
            setLoading(false);
        } catch (error) {
            setType({
                name: "alertMsg",
                type: "information",
                value: {
                    message:
                        error?.response?.data?.message === undefined
                            ? "Erro ao buscar dados para exportação"
                            : error?.response?.data?.message,
                },
            });
            setShow(true);
            setLoading(false);
        }
    }

    const columns = [
        {
            name: "secao",
            label: "Categoria",
            options: {
                filter: false,
                sort: true,

                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        padding: '0px 0.2rem 0px 0.2rem',
                    },
                }),
                // customHeadRender: ({ index, label, ...column }, sortColumn, tableMeta) => (
                //     <TableCell onClick={() => sortColumn(index)} key={index} className="white_space">
                //         {label}
                //         <TableSortLabel active={tableMeta.name === column.name} direction={tableMeta.direction || "asc"} />
                //     </TableCell>
                // ),
            },
        },
        {
            name: "nomeProduto",
            label: "Produto",
            options: {
                filter: false,
                sort: true,

                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        padding: '0px 0.2rem 0px 0.2rem',
                    },
                }),
            }
        },
        {
            name: "liquidez",
            label: "Liquidez",
            options: {
                filter: false,
                sort: true,

                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        padding: '0px 0.2rem 0px 0.2rem',
                    },
                }),
            }
        },
        {
            name: "alocacao",
            label: "Alocação",
            options: {
                filter: false,
                sort: true,

                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        padding: '0px 0.2rem 0px 0.2rem',
                    },
                }),
                customBodyRender: (value) => <span>{
                    value !== null ? value.toFixed(2) + '%' : "-"
                    }</span>
            },
        }
    ];

    // Adiciona um botão para chamar a função de copiar a tabela para a área de tranferência
    const AddButton = () => (
        <Tooltip disableFocusListener title="Copiar para a área de transferência">
            <IconButton onClick={copyToClipboard}>
                <ContentCopyIcon />
            </IconButton>
        </Tooltip>
    );

    const options = {
        filterType: "multiselect",
        search: false,
        filter: false,
        toolbar: false,
        download: false,
        print: false,
        pagination: false,
        responsive: "vertical",
        // tableBodyHeight: '30rem',
        draggableColumns: {
            enabled: true,
        },
        selectableRowsHideCheckboxes: true,
        viewColumns: false,
        toolButton: {
            justifyContent: "center"
        },
        textLabels: {
            body: {
                noMatch: loading ? <ArrowLoader /> : "Não há exemplo de carteira.",
                toolTip: "Ordenar",
                columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
            },
            pagination: {
                next: "Próxima",
                previous: "Anterior",
                rowsPerPage: "Linhas por página:",
                displayRows: "de",
            },
            toolbar: {
                search: "Pesquisar",
                downloadCsv: "Baixar Planilha",
                print: "Imprimir",
                viewColumns: "Ver colunas",
                filterTable: "Filtrar",
            },
            // filter: {
            //     all: "Todos",
            //     title: "Filtros",
            //     reset: "Limpar",
            // },
            viewColumns: {
                title: "Mostrar Colunas",
                titleAria: "Mostrar/Ocultar colunas",
            },
            selectedRows: {
                text: "linha(s) selecionadas",
                delete: "Excluir",
                deleteAria: "Excluir linhas Selecionadas",
            },
        },
        downloadOptions: {
            filename: "operacoes_nao_executadas.csv",
            separator: ";",
        },
        onDownload: () => {
            assetnotExecutedExport();
            return false;
        },
        // sortOrder: {
        //     name: 'alocacao',
        //     direction: 'desc'
        // },
        onRowClick: (rowData, rowMeta) => {
            const data = tableData.find((x) => x.secao === rowData[0]);
            const line = tableData.find((x) => x.description === rowData[1]);
            setType({ name: "tacticalSpeech", data: { line, type: "carteiras" } })
            setShow(true)
            
            
        },
        // customToolbar: AddButton,
        setTableProps: () => {
            return {
                padding: 'none',
                size: 'small'
            };
          },
        // rowsPerPage: rowsPerPage,
        // rowsPerPageOptions: [30, 50, 100],
    };

    const getMuiTheme = () =>
    createTheme({
      components: {
        MuiTableCell: {
            styleOverrides: {
                root:{
                        justifyContent: 'center',
                        whiteSpace: "nowrap",
                        textAlign: 'center'
                }
            },
          },
        MUIDataTableHeadCell: {
            styleOverrides: {
                contentWrapper: {
                    justifyContent: 'center',
                    whiteSpace: "nowrap",
                },
                toolButton: {
                    margin: '0px'
                }
            }
        },
        MUIDataTableBodyRow: {
            styleOverrides: {
            root: {
              '&:nth-child(odd)': { 
                backgroundColor: '#dbdbdb'
              }
            }
        }
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    boxShadow: 'none',
                    borderRadius: '0',
                    height: 'inherit',
                    overflow: 'auto'
        }
      },
    }
    }
    })

    return (
        <div className='cubastar-table'>
            <div className='cubastar-table-label'>Exemplo de Carteira</div>
            <ThemeProvider theme={getMuiTheme()}>
                <MUIDataTable 
                    data={tableRows}
                    columns={columns}
                    options={options}
                    ></MUIDataTable>
            </ThemeProvider>
        </div>
    );
}

