import React, { useEffect, useState, useRef, useReducer } from "react"

import { useModal } from "../../../contexts/useModal"
import { AiFillCloseCircle, AiOutlineConsoleSql } from "react-icons/ai"
import { toBRL } from "../../../utils/formatNumberAndText/toBRLCurrency"
import { toPercent } from "../../../utils/formatNumberAndText/formatNumberPercent"
import { datetimeToDatePtBR } from "../../../utils/formatDate/dateTimeToPTBR"
import { AiFillDollarCircle, AiFillMessage, AiOutlineWhatsApp, AiOutlineCheckSquare, AiOutlineWarning } from "react-icons/ai"
import { ArrowLoader } from "../../../components/loaders/arrowLoader"
import { AiOutlineArrowUp, AiOutlineArrowDown } from "react-icons/ai"
import { FaCheck, FaTimes, FaStar, FaQuestion, FaUserSlash } from "react-icons/fa"
import Select from "react-select"
import "./styles/config_edit_modal.css"
import { useAuth } from "../../../contexts/useAuth"
import {  BsQuestionCircle } from "react-icons/bs"
import { styleClientsInput } from "../../../components/styles/autocompletField"
import { getPermissionsAlocacao, getCampainBasesAlocacao, getPrazosAlocacao, getReturnObjectivesAlocacao, getProfilesAlocacao, getDefaultProfiles } from "../../../services/asyncFunctions"
import CurrencyTextField from "@unicef/material-ui-currency-textfield"
import TextField from "@material-ui/core/TextField"
import { FiSave, FiList } from "react-icons/fi"
import { api } from "../../../services/api"
import { TbBrandNytimes } from "react-icons/tb"

export function ConfigEditModal(props) {
    const { type, show, setShow, setType } = useModal()
    const { user, userAvatar, tokenKey } = useAuth()
    const [response, setResponse] = useState(false)
    const [loading, setLoading] = useState(false)
    const [successEdit, setSuccessEdit] = useState(false)
    const infosClient = useRef()
    const intermediary = useRef()
    const newInfosClient = useRef({})
    const permissions = useRef([])
    const campain_bases = useRef([])
    const prazos = useRef([])
    const return_objectives = useRef([])
    const profiles = useRef([])
    const knowledge_levels = useRef([])
    const defaultProfiles = useRef([])
    const classes = styleClientsInput()
    const [valuePLE, setValuePLE] = useState("")
    const productsMacro = useRef([])
    const productsMicro = useRef([])
    const campaigns = useRef([])
    const configuration = useRef({})
    const [, forceUpdate] = useReducer((x) => x + 1, 0)
    const permissionsColumnsId = useRef([])
    const [questionUpdatePermissions, setQuestionUpdatePermissions] = useState(false)
    const [changedProfile, setChangedProfile] = useState(false)
    const [needsReview, setNeedsReview] = useState(false)
    // const [totalCampos, setTotalCampos] = useState(0)
    // const [totalCamposPreenchidos, setTotalCamposPreenchidos] = useState(0)
    // const [camposAlterados, setCamposAlterados] = useState({}); // Estado para rastrear campos alterados


    // const handleFieldChange = (fieldName, value) => {
    // if (value.trim() !== "" && !camposAlterados[fieldName]) {
    //     setTotalCamposPreenchidos(prev => prev + 1);
    //     setCamposAlterados(prev => ({ ...prev, [fieldName]: true }));
    // }
    // };



    async function getData() {
        setLoading(true)
        // const permissionsAux = await getPermissionsAlocacao(tokenKey)
        // const campain_basesAux = await getCampainBasesAlocacao(tokenKey)
        // const prazosAux = await getPrazosAlocacao(tokenKey)
        // const return_objectivesAux = await getReturnObjectivesAlocacao(tokenKey)
        // const profilesAux = await getProfilesAlocacao(tokenKey)
        permissions.current = configuration.current.permissions
        campain_bases.current = configuration.current.campain_bases
        prazos.current = configuration.current.prazos
        return_objectives.current = configuration.current.return_objectives
        profiles.current = configuration.current.profiles
        knowledge_levels.current = configuration.current.knowledge_levels
        const defaultProfilesAux = await getDefaultProfiles(tokenKey)
        defaultProfiles.current = defaultProfilesAux?.data
        // teste()
        setLoading(false)
    }

    function checkIfNeedReview() {
        //pega data de 6 meses atras
        const date = new Date()
        date.setMonth(date.getMonth() - 6)
        const date6MonthsAgo = date.getTime()
        const lastUpdate = infosClient?.current?.last_update ? new Date(infosClient?.current?.last_update).getTime() : null
        //se a data de atualização do cliente for menor que 6 meses atras, seta a variavel needsReview como true
        if (lastUpdate && lastUpdate < date6MonthsAgo) {
            setNeedsReview(true)
        } else {
            setNeedsReview(false)
        }
    
    }

    useEffect(() => {
        if (show) {
            infosClient.current = props.prop.data
            //copia infosClient para intermediary infos
            const intermediaryInfosAux = JSON.parse(JSON.stringify(infosClient.current))
            intermediary.current = intermediaryInfosAux

            productsMacro.current = props.prop.listProductsPermissionsMacro.current
            productsMicro.current = props.prop.listProductsPermissionsMicro.current
            campaigns.current = props.prop.listCampaignsPermissions.current
            permissionsColumnsId.current = configuration.current.permissions
            configuration.current = props.prop.configuration
            checkIfNeedReview()
            getData()
        } else {
            infosClient.current = []
            intermediary.current = []
            productsMacro.current = []
            productsMicro.current = []
            campaigns.current = []
            permissionsColumnsId.current = []
            newInfosClient.current = {}
            infosClient.current = null
            setShow(false)
        }
        setChangedProfile(false)
        setQuestionUpdatePermissions(false)
        
    }, [show])

    // useEffect(() => {
    //     teste()
    // }, [intermediary.current])

    function clear() {
        newInfosClient.current = []
        intermediary.current = []

        setShow(false)
    }

    const optionsIcons = [
        { value: "2", title: "Sim", label: <FaCheck size={"20"} color="green" />, icon: FaCheck, color: "green" },
        { value: "5", title: "Não", label: <FaTimes size={"20"} color="red" />, icon: FaTimes, color: "red" },
    ]

    const camposGeral = [
        // { name: "fixed_fee", label: "FEE Fixo", options: optionsIcons, type: "icon" },
        { name: "profile_id", label: "Perfil", options: profiles.current, type: "normal", column: "profiles"  },
        { name: "objective_id", label: "Objetivo de Retorno", options: return_objectives.current, type: "normal", column: "return_objectives" },
        { name: "max_term_id", label: "Prazo Máximo", options: prazos.current, type: "normal", column: "prazos" },
        { name: "necessary_liq", label: "Liquidez Necessária", options: prazos.current, type: "currencyTextArea" },
        { name: "campain_base_id", label: "Base Campanhas", options: campain_bases.current, type: "normal", column: "campain_bases" },
        { name: "propensity_to_accept_campains", label: "Propensão a Aceitar Campanhas", options: [{value: 'Alta', label:"Alta"},{value: "Baixa", label: "Baixa"}], type: "text" },
        { name: "knowledge_id", label: "Nível de Conhecimento", options: knowledge_levels.current , type: "normal",  column: "knowledge_levels" },
    ]

    const optionsIcons2 = [
        { value: "1", title: "Indefinido", label: <FaQuestion size={"20"} color="orange" />, icon: FaQuestion, color: "orange" },
        { value: "2", title: "Permitido", label: <FaCheck size={"20"} color="green" />, icon: FaCheck, color: "green" },
        { value: "3", title: "Negado", label: <FaTimes size={"20"} color="red" />, icon: FaTimes, color: "red" },
        // { value: "4", title: "Sem perfil", label: <FaUserSlash size={"20"} color="black" />, icon: FaUserSlash, color: "black" },
        // { value: "5", title: "Oportunidade", label: <FaStar size={"20"} color="#FFC300" />, icon: FaStar, color: "#FFC300" },
    ]

    const customStyles = {
        option: (provided) => ({
            ...provided,
            display: "flex",
            alignItems: "center",
            color:'#AEAEAE',
        }),
        singleValue: (provided) => ({
            ...provided,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        }),
        dropdownIndicator: (provided) => ({
            ...provided,
            paddingRight: 0,
        }),
        control: (provided) => ({
            ...provided,
            height: 30, // Definir a altura desejada
            minWidth: 70,
        }),
        listbox: (provided) => ({
            ...provided,
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
        }),
    }

    const customStylesUndefined = {
        option: (provided) => ({
            ...provided,
            display: "flex",
            alignItems: "center",
            color:'#AEAEAE',
        }),
        singleValue: (provided) => ({
            ...provided,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color:'red',
        }),
        dropdownIndicator: (provided) => ({
            ...provided,
            paddingRight: 0,
        }),
        control: (provided) => ({
            ...provided,
            height: 30, // Definir a altura desejada
            minWidth: 70,
        }),
        listbox: (provided) => ({
            ...provided,
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
        }),
    }

    const customOption = ({ innerProps, label, data }) => (
        <div {...innerProps} title={data.title} style={{ textAlign: "center", paddingRight: "1.5rem" }}>
            {React.createElement(data.icon, { size: 20, color: data.color })}
        </div>
    )

    async function submitNewValues() {
        const columns = Object.keys(newInfosClient.current)
        // return

        if (columns.length > 0) {
            columns.forEach((n) => {
                // if (n.startsWith("product__") || n.startsWith("campaign__")) {
                if (n.endsWith("__permissions_id")) {
                    const columnId = n.split("__")[0].replace("p", "")
                    var can = false
                    updatePermission(intermediary.current.client_id, newInfosClient.current[n], String(columnId))
                    infosClient.current[n] = newInfosClient.current[n]
                    
                    // identificar de qual macr oArea é a permissão
                    //vai passar por todos os produtos dessa macro area e verificar se algum deles pode
                    //se algum puder, marca a area como pode
                    //caso contrario, marcar como nao pode 
                    productsMacro.current.map((p, i) => {
                        
                        
                        if(p.idsMicro.includes(parseInt(columnId))){
                            // console.log('macro',p)
                            
                            // intermediary.current["p" + p.id + "__permissions_id"] = newInfosClient.current[n]
                            productsMicro.current.map((m, i) => {
                                if(p.idsMicro.includes(parseInt(m.id))){
                                    // console.log('micro',m)
                                    if(intermediary.current["p" + m.id + "__permissions_id"] == 2){
                                        can = true
                                    }
                                }
                            
                            })
                        
                            if(can){
                                intermediary.current["p" + p.id + "__permissions_id"] = 2
                                infosClient.current["p" + p.id + "__permissions_id"] = 2
                            } else {
                                intermediary.current["p" + p.id + "__permissions_id"] = 3
                                infosClient.current["p" + p.id + "__permissions_id"] = 3
                            }
                        }
                    })
                }
                else {
                    if (n == "fixed_fee") {
                        updateFixedFee(intermediary.current.client_id, String(newInfosClient.current[n]))
                        // intermediary.current['fixed_fee'] = fixed_fee.current.find((x) => x.value == e.value).label
                    }
                    if (n == "profile_id") {
                        updateProfile(intermediary.current.client_id, String(newInfosClient.current[n]))
                        infosClient.current["profile"] = profiles.current.find((x) => x.value == newInfosClient.current[n]).label
                        infosClient.current[n] = newInfosClient.current[n]
                    }
                    if (n == "objective_id") {
                        updateObjective(intermediary.current.client_id, String(newInfosClient.current[n]))
                        infosClient.current["objective"] = return_objectives.current.find((x) => x.value == newInfosClient.current[n]).label
                        infosClient.current[n] = newInfosClient.current[n]
                    }

                    if (n == "max_term_id") {
                        updatePrazo(intermediary.current.client_id, String(newInfosClient.current[n]))
                        infosClient.current["max_term"] = prazos.current.find((x) => x.value == newInfosClient.current[n]).label
                        infosClient.current[n] = newInfosClient.current[n]
                    }
                    if (n == "campain_base_id" || n == "pl_custom") {
                        updateCampainBase(intermediary.current.client_id, String(newInfosClient.current["campain_base_id"]))
                        infosClient.current["campain_base"] = campain_bases.current.find((x) => x.value == intermediary.current["campain_base_id"]).label
                        infosClient.current["pl_custom"] = (intermediary.current["pl_custom"] && intermediary.current["campain_base_id"] == 4) ? intermediary.current["pl_custom"] : null
                        infosClient.current[n] = newInfosClient.current[n]
                    }
                    // if (n == "pl_custom") {
                    //     updateCampainBase(intermediary.current.client_id, String(intermediary.current["campain_base_id"]))
                    //     infosClient.current["pl_custom"] = intermediary.current["pl_custom"] ? intermediary.current["pl_custom"] : null
                    // }
                    if (n == "ple") {
                        updatePle(intermediary.current.client_id, String(newInfosClient.current[n]))
                        infosClient.current["ple"] = newInfosClient.current[n]
                    }
                    if (n == "necessary_liq") {
                        updateLiq(intermediary.current.client_id, String(newInfosClient.current[n]))
                        infosClient.current["necessary_liq"] = newInfosClient.current[n]
                    }

                    if (n == "propensity_to_accept_campains") {
                        updatePropensity(intermediary.current.client_id, String(newInfosClient.current[n]))
                        infosClient.current["propensity_to_accept_campains"] = newInfosClient.current[n]
                    }

                    if (n == 'knowledge_id'){
                        updateKnowledge(intermediary.current.client_id, String(newInfosClient.current[n]))
                        infosClient.current["knowledge"] = knowledge_levels.current.find((x) => x.value == newInfosClient.current[n]).label
                        infosClient.current["knowledge_id"] = newInfosClient.current[n]
                    }
                }
            })
            setType({ name: "alertMsg", type: "information", value: { message: "Sucesso ao alterar dados do cliente" } })
            // atualiza a data de atualização do cliente
            infosClient.current["last_update"] = new Date()
            forceUpdate()
            clear()
        } else {
            setShow(false)
            forceUpdate()
            clear()
        }
    }

    // async function changeValueClient(newValue, client, column) {
    //     infosClient.current.filter((c) => c.client_id == client.client_id)[0][column] = newValue // atuliza o json atual
    //     //se cliente for perfil conservador, desabilita todas as permissões rv do cliente
    //     if (column == "profile_id" && newValue == 1) {
    //         infosClient.current.map((c) => {
    //             if (c.client_id == client.client_id) {
    //                 c["wisir_fii_permission_id"] = 1
    //                 c["wisir_acoes_permission_id"] = 1
    //                 c["long_short_permission_id"] = 1
    //                 c["estruturadas_permission_id"] = 1
    //             }
    //         })
    //     }
    //     if (column == "fixed_fee") updateFixedFee(client.client_id, newValue)
    //     if (column == "profile_id") updateProfile(client.client_id, newValue)
    //     if (column == "objective_id") updateObjective(client.client_id, newValue)
    //     if (column == "max_term_id") updatePrazo(client.client_id, newValue)
    //     if (column == "campain_base_id") updateCampainBase(client.client_id, newValue)
    //     if (column == "ple") updatePle(client.client_id, newValue)
    //     forceUpdate()
    // }

    async function updateFixedFee(client_id, value) {
        try {
            await api.put(`/api/v1/alocacao/feeFixo/${client_id}/${value}`, {}, { headers: { authorization: `Bearer ${tokenKey}` } })
        } catch (error) {
            console.log(error?.response)
            setType({
                name: "alertMsg",
                type: "information",
                value: { message: error?.response?.data?.message === undefined ? "Erro ao atualizar" : error.response.data.message },
            })
            setShow(true)
        }
    }

    async function updateProfile(client_id, value) {
        try {
            await api.put(`/api/v1/alocacao/profile/${client_id}/${value}`, {}, { headers: { authorization: `Bearer ${tokenKey}` } })
        } catch (error) {
            console.log(error?.response)
            setType({
                name: "alertMsg",
                type: "information",
                value: { message: error?.response?.data?.message === undefined ? "Erro ao atualizar" : error.response.data.message },
            })
            setShow(true)
        }
    }

    async function updateObjective(client_id, value) {
        // console.log('value', value, client_id)
        // return
        try {
            await api.put(`/api/v1/alocacao/return_objective/${client_id}/${value}`, {}, { headers: { authorization: `Bearer ${tokenKey}` } })
        } catch (error) {
            console.log(error?.response)
            setType({
                name: "alertMsg",
                type: "information",
                value: { message: error?.response?.data?.message === undefined ? "Erro ao atualizar" : error.response.data.message },
            })
            setShow(true)
        }
    }

    async function updatePrazo(client_id, value) {
        try {
            await api.put(`/api/v1/alocacao/prazo/${client_id}/${value}`, {}, { headers: { authorization: `Bearer ${tokenKey}` } })
        } catch (error) {
            console.log(error?.response)
            setType({
                name: "alertMsg",
                type: "information",
                value: { message: error?.response?.data?.message === undefined ? "Erro ao atualizar" : error.response.data.message },
            })
            setShow(true)
        }
    }

    async function updateCampainBase(client_id, value) {
        try {
            await api.put(`/api/v1/alocacao/campain_base/${client_id}/${value}`, {
                pl_custom: (intermediary.current["pl_custom"] && value == 4) ? intermediary.current["pl_custom"] : null,
            }, { headers: { authorization: `Bearer ${tokenKey}` } })
        } catch (error) {
            console.log(error?.response)
            setType({
                name: "alertMsg",
                type: "information",
                value: { message: error?.response?.data?.message === undefined ? "Erro ao atualizar" : error.response.data.message },
            })
            setShow(true)
        }
    }

    async function updatePle(client_id, value) {
        try {
            await api.put(
                `/api/v1/alocacao/ple/${client_id}`,
                {
                    ple: value,
                },
                { headers: { authorization: `Bearer ${tokenKey}` } }
            )
        } catch (error) {
            console.log(error?.response)
            setType({
                name: "alertMsg",
                type: "information",
                value: { message: error?.response?.data?.message === undefined ? "Erro ao atualizar" : error.response.data.message },
            })
            setShow(true)
        }
    }

    async function updatePropensity(client_id, value) {
        try {
            await api.put(
                `/api/v1/alocacao/propensity/${client_id}`,
                {
                    propensity_to_accept_campains: value,
                },
                { headers: { authorization: `Bearer ${tokenKey}` } }
            )
        } catch (error) {
            console.log(error?.response)
            setType({
                name: "alertMsg",
                type: "information",
                value: { message: error?.response?.data?.message === undefined ? "Erro ao atualizar" : error.response.data.message },
            })
            setShow(true)
        }
    }

    async function updateKnowledge(client_id, value) {
        try {
            await api.put(
                `/api/v1/alocacao/knowledge_level/${client_id}`,
                {
                    knowledge_level_id: value,
                },
                { headers: { authorization: `Bearer ${tokenKey}` } }
            )
        } catch (error) {
            console.log(error?.response)
            setType({
                name: "alertMsg",
                type: "information",
                value: { message: error?.response?.data?.message === undefined ? "Erro ao atualizar" : error.response.data.message },
            })
            setShow(true)
        }
    }

    async function updateLiq(client_id, value) {
        try {
            await api.put(
                `/api/v1/alocacao/liq/${client_id}`,
                {
                    liq_necessaria: value,
                },
                { headers: { authorization: `Bearer ${tokenKey}` } }
            )
        } catch (error) {
            console.log(error?.response)
            setType({
                name: "alertMsg",
                type: "information",
                value: { message: error?.response?.data?.message === undefined ? "Erro ao atualizar" : error.response.data.message },
            })
            setShow(true)
        }
    }

    async function updatePermission(client_id, status_id, column_id) {
        try {
            await api.put(`/api/v1/alocacao/permission/${client_id}/${column_id}/${status_id}`, {}, { headers: { authorization: `Bearer ${tokenKey}` } })
        } catch (error) {
            console.log(error?.response)
            setType({
                name: "alertMsg",
                type: "information",
                value: { message: error?.response?.data?.message === undefined ? "Erro ao atualizar" : error.response.data.message },
            })
            setShow(true)
        }
    }

    //seta as permissões padrões para o perfil selecionado no json do cliente
    async function setDefaultPermissions() {
        const defaultProfilesAux = defaultProfiles.current.filter((p) => p.profile_id == intermediary.current.profile_id)
        defaultProfilesAux?.map((p) => {
            newInfosClient.current["p" + p.column_id + "__permissions_id"] = p.permission_id
            intermediary.current["p" + p.column_id + "__permissions_id"] = p.permission_id
        })
        setQuestionUpdatePermissions(false)
    }

    // function teste (){
    //     var totalCamposPreenchidos = 0
    //     var totalPermissoes = 0
    //     var total = 0

    //     camposGeral.map((c) => {
    //         total += 1 
    //         if (intermediary.current) {
    //             const selectedValue = c?.options.find((option) => option.value == String(intermediary.current[c?.name]))
                
    //             var newValue = intermediary.current[c?.name] ? intermediary.current[c?.name] : ""
    //             var pl_custom = intermediary.current["pl_custom"] ? intermediary.current["pl_custom"] : 0
                
    //             if (c.name == "objective_id") {
    //                 c.options = c.options.filter((o) => o.alocacao_profile_id == intermediary.current["profile_id"])
    //             }
    //             if (c.type == 'normal' && selectedValue?.label !== 'Indefinido'){
    //                 totalCamposPreenchidos+= 1;
    //                 console.log('camposGeral', totalCamposPreenchidos, intermediary.current[c?.name])
    //             }
                
    //             if ((c.type == 'text' || c.type == 'currencyTextArea') && intermediary.current[c?.name] !== '' && intermediary.current[c?.name] !== null && intermediary.current[c?.name] !== undefined){
    //                 totalCamposPreenchidos+= 1;
    //                 console.log('camposGeral', totalCamposPreenchidos, intermediary.current[c?.name])
    //             }
    //         }})

    //         //fazer para as permissões
    //         productsMacro?.current?.map((p, i) => {
    //             total += 1
    //             const selectedValue = optionsIcons2.find((option) => option.value == String(intermediary.current["p" + p.id + "__permissions_id"]))
    //             // console.log('selectedValue', selectedValue)
    //             if (selectedValue?.title !== 'Indefinido', intermediary.current["p" + p.id + "__permissions_id"] !== null && intermediary.current["p" + p.id + "__permissions_id"] !== undefined , intermediary.current["p" + p.id + "__permissions_id"] !==  '1'){
    //                 totalPermissoes+= 1;
    //                 console.log('productsMacro', totalPermissoes, intermediary.current["p" + p.id + "__permissions_id"])
    //             } 
    //         })


    //         setTotalCamposPreenchidos(totalCamposPreenchidos + totalPermissoes)
    //         setTotalCampos(total)
    // }

    return (
        <div className="containerModalDashDetails"  style={{ zoom: `${window.innerHeight < 575 ? 0.95 :  1}`}}>
            {loading ? (
                <div id="clientDetailsLoader">
                    <ArrowLoader />
                </div>
            ) : (
                <>
                    {questionUpdatePermissions ? (
                        <>
                            <div className="modalChoice">
                                <a>Deseja alterar as permissões de produto para os padrões do perfil {profiles.current.find((x) => x.value == newInfosClient.current.profile_id).label}?</a>
                                <div>
                                    <button
                                        onClick={() => {
                                            setDefaultPermissions()
                                        }}
                                    >
                                        Sim, quero alterar as permissões padrões para o perfil {profiles.current.find((x) => x.value == newInfosClient.current.profile_id).label}
                                    </button>

                                    <button
                                        onClick={() => {
                                            setQuestionUpdatePermissions(false)
                                        }}
                                    >
                                        Não, quero manter as configurações de permissões atuais
                                    </button>
                                </div>
                            </div>
                            <div className="modalChoiceBackgroundShow"></div>
                        </>
                    ) : null}
                    <div className="editBrancheBoxHeaderDash" style={{ zoom: `${window.innerHeight > 760 ?  1  : window.innerHeight > 530 ? 0.9 :  0.8}`}}>
                        <div>
                            <p style={{fontWeight:'500'}}>Configurações do Cliente {infosClient?.current?.last_update ? 
                            needsReview ?
                            <>
                                <span style={{color: 'red'}}> -- Atualização Necessária! --</span>
                                <span> Atualizado em {datetimeToDatePtBR(infosClient?.current?.last_update)  }</span>
                            </>
                            :
                            <span>- Atualizado em {datetimeToDatePtBR(infosClient?.current?.last_update)  }</span>
                                : ''}</p>
                            <p style={{color:'#A7A7A7'}}>{infosClient?.current?.client_id + ': ' + infosClient?.current?.client}</p>
                        </div>
                        
                        {/* <button
                            onClick={() => {
                                clear()
                                setShow(false)
                            }}
                        >
                            x
                        </button> */}
                        <div style={{display: 'grid', gridTemplateColumns: 'auto auto auto', alignItems: 'end'}}>
                        <p style={{marginRight: '1rem'}}>{user.external_id + ' - ' + user.full_name } </p>
                        {user.type != 3 ?  
                            <div
                            className="saveAndExitButton"
                            onClick={() => {
                                submitNewValues()
                                clear()
                                // setShow(false)
                            }}
                        >
                            <FiSave size={"22"} /> Salvar
                        </div>
                        :  
                        <></>
                        }
                        
                        <button
                            onClick={() => {clear(); setShow(false)}}
                            className="modalCloseButtonDashboard" style={{marginRight: '12px', height: '36px', width: '36px'}}>
                            <AiFillCloseCircle />
                        </button>
                    </div>
                    </div>
                    <div className="clientConfigEditBody" style={{ zoom: `${window.innerHeight > 760 ?  1  : window.innerHeight > 530 ? 0.9 :  0.8}`}}>
                        <div className="bodyConfigBloc">
                            
                            <div className="bodyConfigBlocBody">

                                <div className="bodyConfigBlocHeader" >Geral {/* {totalCamposPreenchidos} */}</div> 
                                <div className="bodyConfigLeftTable">
                                    {camposGeral.map((c) => {
                                        if (intermediary.current) {
                                            const selectedValue = c?.options.find((option) => option.value == String(intermediary.current[c?.name]))
                                            
                                            var newValue = intermediary.current[c?.name] ? intermediary.current[c?.name] : ""
                                            var pl_custom = intermediary.current["pl_custom"] ? intermediary.current["pl_custom"] : 0
                                            


                                            return (
                                                <>
                                                    <div className="configBlocBodyItem">
                                                        <div className="configBlocBodyHeader">{c?.label} </div>
                                                        <div className="configBlocBodyContent">
                                                            {c.type === "currencyTextArea" ? (
                                                                <TextField
                                                                    style={{ height: "2.4rem" }}
                                                                    variant="outlined"
                                                                    value={intermediary.current[c.name]}
                                                                    className={classes.styleForClients}
                                                                    onChange={(e) => {
                                                                        const valorAtualizado = e.target.value.replace(/[^0-9,.]/g, "");
                                                                        setValuePLE(valorAtualizado);

                                                                        newInfosClient.current[c.name] = valorAtualizado
                                                                        intermediary.current[c.name] = valorAtualizado

                                                                        // if (valorAtualizado.trim() !== "" && !camposAlterados[c.name]) {
                                                                        //     setTotalCamposPreenchidos(prev => prev + 1);
                                                                        //     setCamposAlterados(prev => ({ ...prev, [c.name]: true }));
                                                                        //   }

                                                                    }}
                                                                    
                                                                />
                                                            ) : c.type == "textArea" ? (
                                                                <CurrencyTextField
                                                                    style={{ height: "2.4rem" }}
                                                                    variant="outlined"
                                                                    value={newValue}
                                                                    currencySymbol="R$"
                                                                    className={classes.styleForClients}
                                                                    outputFormat="number"
                                                                    decimalCharacter=","
                                                                    digitGroupSeparator="."
                                                                    onChange={(event, value) => {
                                                                        newValue = value
                                                                    }}
                                                                    onBlur={(e) => {
                                                                        newInfosClient.current[c.name] = newValue
                                                                        intermediary.current[c.name] = newValue
                                                                    }}
                                                                />
                                                            ) : c.type == 'normal' ? (
                                                                <Select
                                                                    options={(c.name == "profile_id" || c.name == "knowledge_id") ? c?.options.filter((o) => o.value != 1) : 
                                                                    c.name == "objective_id" ? c?.options.filter((o) => o.alocacao_profile_id == intermediary.current["profile_id"]) :
                                                                    c?.options}
                                                                    styles={(c.name == "profile_id" || c.name == "knowledge_id") && selectedValue?.value == '1' ? customStylesUndefined : customStyles}
                                                                    components={c.type == "icon" ? { Option: customOption } : ""}
                                                                    isSearchable={false}
                                                                    value={selectedValue}
                                                                    onChange={(e) => {
                                                                        newInfosClient.current[c.name] = e.value
                                                                        intermediary.current[c.name] = e.value
                                                                        if (c.name == "profile_id" && infosClient.current[c.name] == 1 && changedProfile == false) {
                                                                            setDefaultPermissions()
                                                                            setChangedProfile(true)
                                                                        } else{
                                                                            if (c.name == "profile_id"){
                                                                                setQuestionUpdatePermissions(true)
                                                                                setChangedProfile(true)
                                                                            }
                                                                        }
                                                                        
                                                                        forceUpdate()
                                                                    }}
                                                                />
                                                            ) : c.type == 'text' ? (
                                                                <Select
                                                                    options={c?.options}
                                                                    styles={customStyles}
                                                                    isSearchable={false}
                                                                    value={selectedValue}
                                                                    onChange={(e) => {
                                                                        newInfosClient.current[c.name] = e.value
                                                                        intermediary.current[c.name] = e.value
                                                                        forceUpdate()
                                                                    }}
                                                                />
                                                            ) : null
                                                                    
                                                            }
                                                        </div>
                                                    </div>

                                                    {c.name == "campain_base_id" && intermediary.current[c.name] == "4" ? (
                                                        <div className="configBlocBodyItem">
                                                            <div className="configBlocBodyHeader">Valor PL Assessor</div>
                                                            <div className="configBlocBodyContent">
                                                                <CurrencyTextField
                                                                    style={{ height: "2.4rem" }}
                                                                    variant="outlined"
                                                                    value={pl_custom}
                                                                    currencySymbol="R$"
                                                                    className={classes.styleForClients}
                                                                    outputFormat="number"
                                                                    decimalCharacter=","
                                                                    digitGroupSeparator="."
                                                                    onChange={(event, value) => {
                                                                        pl_custom = value
                                                                        // console.log("pl_custom Onchange", pl_custom)
                                                                    }}
                                                                    onBlur={(e) => {
                                                                        newInfosClient.current['pl_custom'] = pl_custom
                                                                        intermediary.current['pl_custom'] = pl_custom
                                                                        console.log("pl_custom Onblur", pl_custom)
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <></>
                                                    )}
                                                </>
                                            )
                                        }
                                    })}
                                </div>
                            </div>
                        </div>

                        {/* <div className="bodyConfigBloc">
                            <div className="bodyConfigBlocHeader">Permissões de Negócios</div>
                            <div className="bodyConfigBlocBody">
                                {campaigns.current.map((c) => {
                                    if (infosClient?.current) {
                                        const selectedValue = optionsIcons2.find((option) => option.value == String(infosClient?.current[c]))
                                        return (
                                            <div className="configBlocBodyItem">
                                                <div className="configBlocBodyHeader">{c.split("__")[1].toUpperCase().replaceAll("_", " ")}</div>
                                                <div className="configBlocBodyContent">
                                                    <Select
                                                        options={optionsIcons}
                                                        styles={customStyles}
                                                        components={{ Option: customOption }}
                                                        isSearchable={false}
                                                        defaultValue={selectedValue}
                                                        menuPlacement="auto" // Centralizar o dropdown
                                                        menuPosition="absolute" // Centralizar o dropdown
                                                        onChange={(e) => {
                                                            newInfosClient.current[c] = e.value
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        )
                                    }
                                })}
                            </div>
                        </div> */}

                        <div className="bodyConfigBloc">
                            {/* <div className="bodyConfigBlocHeader" style={{ display: "flex" }}>
                                <span style={{marginTop: 'auto'}}>Permissões de Produtos</span>
                                <div
                                    className="saveAndExitButton"
                                    style={{ width: "auto", marginLeft: "auto", marginRight: "1rem" }}
                                    title="Restaura as permissões de produtos para o padrão do perfil atual do cliente"
                                    onClick={() => {
                                    }}
                                >
                                    <FiList size={"22"} /> Restaurar permissões padrão
                                </div>
                            </div> */}
                            
                            <div className="bodyConfigBlocBody ">
                                <div className="bodyConfigBlocHeader">Permissões de Produtos
                                <BsQuestionCircle
                                    color="rgb(117, 117, 117)"
                                    size={16}
                                    style={{
                                        display: "inline-block",
                                        verticalAlign: "middle",
                                        marginTop: "-0.2rem",
                                        cursor: "pointer",
                                        marginLeft: "0.5rem",
                                        marginRight: "0.5rem",
                                        }}
                                    title="O cliente fará parte das campanhas de produtos marcados como indefinido."
                                />
                                </div>
                                <div className="bodyConfigRightTable">
                                    {productsMicro?.current?.map((p, i) => {
                                        // const microLenght = productsMicro?.current?.filter((m) => {
                                        //     return m.includes("product__" + p.split("__")[1])
                                        // })
                                        const selectedValue = optionsIcons2.find((option) => option.value == String(intermediary.current["p" + p.id + "__permissions_id"]))
                                        // var labelAux = p.description.replace(':'," : ")
                                        var descriptionParts = p.description.split(':');
                                        var labelAux;
                                        if (descriptionParts.length == 2 && descriptionParts[0].trim() === descriptionParts[1].trim()) {
                                            labelAux = descriptionParts[0];
                                        } else {
                                            labelAux = p.description;
                                        }

                                        //caso seja subproduto de uma area: ex: wisir fii em RV
                                        // if (p.split("__").length > 3) {
                                        //     labelAux += ": " + p.split("__")[2].toUpperCase().replaceAll("_", " ")
                                        // }

                                        return (
                                            <div className="configBlocBodyItemProduct">
                                                <div className="configBlocBodyHeader" 
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        minHeight: '40px',
                                                      }}
                                                      >
                                                        {labelAux}
                                                </div>
                                                <div className={"configBlocBodyContent"}>
                                                    <Select
                                                        options={optionsIcons2.filter((o) => o.value != 1)}
                                                        styles={customStyles}
                                                        components={{ Option: customOption }}
                                                        isSearchable={false}
                                                        value={selectedValue ? selectedValue : optionsIcons2[0]}
                                                        onChange={(e) => {
                                                            newInfosClient.current["p" + p.id + "__permissions_id"] = e.value
                                                            intermediary.current["p" + p.id + "__permissions_id"] = e.value
                                                            forceUpdate()
                                                        }}
                                                    />
                                                </div>

                                                {/* {infosClient.current[p] == "2" || infosClient.current[p] == "5"
                                                    ? microLenght?.map((mi) => {
                                                        const selectedValue2 = optionsIcons2.find((option) => option.value == String(infosClient?.current[mi]))
                                                        return (
                                                            <>
                                                                <div className="configBlocBodyHeaderSub">{mi.split("__")[2].toUpperCase().replaceAll("_", " ")}</div>
                                                                <div className="configBlocBodyContent">
                                                                    <Select
                                                                        options={optionsIcons2}
                                                                        styles={customStyles}
                                                                        components={{ Option: customOption }}
                                                                        isSearchable={false}
                                                                        defaultValue={selectedValue2 ? selectedValue2 : optionsIcons2[0]}
                                                                        onChange={(e) => {
                                                                            newInfosClient.current[mi] = e.value
                                                                        }}
                                                                    />
                                                                </div>
                                                            </>
                                                        )
                                                    })
                                                    : null} */}
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    )
}
