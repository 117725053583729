import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import { Tooltip, IconButton } from "@material-ui/core";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { ThemeProvider,createTheme } from '@mui/material/styles';
/*Styles*/
import "../../monitoringPanel/myReservations/styles/myReservation.table.css"
/*Contexts*/
import { useAuth } from "../../../../contexts/useAuth";
import { useModal } from "../../../../contexts/useModal";
/*Services*/
import { getNotExecuted, getDatabaseUpdateTime } from "../../../../services/asyncFunctions";
/*Components*/
import { ArrowLoader } from "../../../../components/loaders/arrowLoader";
import { toBRL } from "../../../../utils/formatNumberAndText/toBRLCurrency";

export function NotExecutedTbl() {
    const [loading, setLoading] = useState(false);
    const [listNotExecuted, setListNotExecuted] = useState([]);
    const [databaseUpdateTime, setDatabaseUpdateTime] = useState([]);
    const [listNotExecutedFormatted, setListNotExecutedFormatted] = useState([]);
    const { tokenKey } = useAuth();
    const { setType, setShow, edited, setEdited } = useModal();
    const [rowsPerPage, setRowsPerPage] = useState(30);

    async function getNot() {
        const listNotExecuted = await getNotExecuted(tokenKey, false);
        // console.log(listNotExecuted);
        if (getNotExecuted?.isError) {
            setType({
                name: "alertMsg",
                type: "information",
                value: {
                    message: getNotExecuted?.error,
                },
            });
            setShow(true);
        } else {
            setListNotExecuted(listNotExecuted);
            setLoading(false);
        }
    }

    async function getDatabaseUpdateTimeValues() {
        const bases = 'notExecuted';
        const databaseUpdateTime = await getDatabaseUpdateTime(tokenKey, bases);
        if (getDatabaseUpdateTime?.isError) {
            setType({
                name: "alertMsg",
                type: "information",
                value: {
                    message: getDatabaseUpdateTime?.error,
                },
            });
            setShow(true);
        } else {
            try {
                let dateFormat = databaseUpdateTime[0].updated_at;
                const dateObj = new Date(dateFormat)
                const dateFormatted = String(` - Atualizado em: ${dateObj.getDate()}/${dateObj.getMonth() + 1}/${dateObj.getFullYear()} ${dateObj.getHours()}:${dateObj.getMinutes()}h`)
                setDatabaseUpdateTime(dateFormatted);
                // console.log(dateFormatted);
                setLoading(false);
            } catch (e) {
                let dateFormat = ''
                setDatabaseUpdateTime(dateFormat);
                setLoading(false);
            }
        }
    }

    function formateAssets() {
        let notExecutedFormatted = [];

        listNotExecuted.map((notExecuted) => {
            notExecutedFormatted.push({
                client_id: notExecuted.client_id,
                client_name: notExecuted.client_name,
                advisor_id: notExecuted.advisor_id,
                advisor_name: notExecuted.advisor_name,
                broker_id: notExecuted.broker_id,
                broker_name: notExecuted.broker_name,
                asset: notExecuted.asset,
                op_type: notExecuted.op_type,
                asset_qty: notExecuted.asset_qty,
                op_status: notExecuted.op_status,
                ctrl_num: notExecuted.ctrl_num
            });
        });

        setListNotExecutedFormatted(notExecutedFormatted);
        setLoading(false);
    }

    useEffect(() => {
        if (edited || listNotExecuted.length === 0) {
            setEdited(false);
            setLoading(true);
            getNot();
            getDatabaseUpdateTimeValues();
        }
    }, [edited]);

    useEffect(() => {
        if (listNotExecuted.length > 0) {
            formateAssets();
        }
    }, [listNotExecuted]);

    function copyToClipboard() {
        const output = [];
        const titles = [
            "Código Cliente",
            "Cliente",
            "Assessor",
            "Broker",
            "Ativo",
            "Quantidade",
            "Operação",
            "Status da Operação",
            "Código XP da Operação"
        ];
        output.push(titles.join("\t"));
        const keys = [
            "client_id",
            "client_name",
            "advisor_name",
            "broker_name",
            "asset",
            "asset_qty",
            "op_type",
            "op_status",
            "ctrl_num"
        ];
        listNotExecuted.forEach((x) => {
            const tmp = keys.map((y) => {
                return y === "net"
                    ? String(x[y]).replace(".", ",") : y === "due_date"
                    ? x[y].replace(/^(\d{4})-(\d{2})-(\d{2})T(\d{2}:\d{2}:\d{2})\.\d+Z$/, "$3/$2/$1") : x[y]
            });
            output.push(tmp.join("\t"));

        });
        const table = output.join("\n");
        navigator.clipboard.writeText(table);
        alert("Conteúdo da tabela copiado para a área de transferência");
    }

    async function assetnotExecutedExport() {
        setLoading(true);
        try {
            const myExport = await getNotExecuted(tokenKey, true);
            const responseURL = URL.createObjectURL(new Blob([myExport]));
            const link = document.createElement("a");
            link.href = responseURL;
            link.setAttribute("download", "Operações não Executadas.xlsx");
            document.body.appendChild(link);
            link.click();
            setLoading(false);
        } catch (error) {
            setType({
                name: "alertMsg",
                type: "information",
                value: {
                    message:
                        error?.response?.data?.message === undefined
                            ? "Erro ao buscar dados para exportação"
                            : error?.response?.data?.message,
                },
            });
            setShow(true);
            setLoading(false);
        }
    }

    const columns = [
        {
            name: "client_id",
            label: "Código Cliente",
            options: {
                filter: false,
                sort: true,

                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        padding: '0px 0.6rem 0px 0.6rem',
                    },
                }),
                // customHeadRender: ({ index, label, ...column }, sortColumn, tableMeta) => (
                //     <TableCell onClick={() => sortColumn(index)} key={index} className="white_space">
                //         {label}
                //         <TableSortLabel active={tableMeta.name === column.name} direction={tableMeta.direction || "asc"} />
                //     </TableCell>
                // ),
            },
        },
        {
            name: "client_name",
            label: "Cliente",
            options: {
                filter: false,
                sort: true,

                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        padding: '0px 0.6rem 0px 0.6rem',
                    },
                }),
                // customHeadRender: ({ index, label, ...column }, sortColumn, tableMeta) => (
                //     <TableCell onClick={() => sortColumn(index)} key={index} className="white_space">
                //         {label}
                //         <TableSortLabel active={tableMeta.name === column.name} direction={tableMeta.direction || "asc"} />
                //     </TableCell>
                // ),
            },
        },
        {
            name: "advisor_name",
            label: "Assessor",
            options: {
                filter: true,
                sort: true,
                // caso não seja para mostrar a coluna baseado no tipo de acesso, usar a linha display
                // display: tokenKey.type > 3 ? true : false,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        padding: '0px 0.6rem 0px 0.6rem',
                    },
                }),
                // customHeadRender: ({ index, label, ...column }, sortColumn, tableMeta) => (
                //     <TableCell onClick={() => sortColumn(index)} key={index} className="white_space">
                //         {label}
                //         <TableSortLabel active={tableMeta.name === column.name} direction={tableMeta.direction || "asc"} />
                //     </TableCell>
                // ),
            },
        },
        {
            name: "broker_name",
            label: "Broker",
            options: {
                filter: true,
                sort: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        padding: '0px 0.6rem 0px 0.6rem',
                    },
                }),
                // customHeadRender: ({ index, label, ...column }, sortColumn, tableMeta) => (
                //     <TableCell onClick={() => sortColumn(index)} key={index} className="white_space">
                //         {label}
                //         <TableSortLabel active={tableMeta.name === column.name} direction={tableMeta.direction || "asc"} />
                //     </TableCell>
                // ),
            },
        },
        {
            name: "asset",
            label: "Ativo",
            options: {
                filter: false,
                sort: true,

                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        padding: '0px 0.6rem 0px 0.6rem',
                    },
                }),
            },
        },
        {
            name: "asset_qty",
            label: "Quantidade",
            options: {
                filter: false,
                sort: true,

                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        padding: '0px 0.6rem 0px 0.6rem',
                    },
                }),
            },
        },
        {
            name: "op_type",
            label: "Operação",
            options: {
                filter: false,
                sort: true,

                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        padding: '0px 0.6rem 0px 0.6rem',
                    },
                }),
            },
        },
        {
            name: "op_status",
            label: "Status da Operação",
            options: {
                filter: false,
                sort: true,

                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        padding: '0px 0.6rem 0px 0.6rem',
                    },
                }),
            },
        },
        {
            name: "ctrl_num",
            label: "Código XP da Operação",
            options: {
                filter: false,
                sort: true,

                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        padding: '0px 0.6rem 0px 0.6rem',
                    },
                }),
            },
        },
    ];

    // Adiciona um botão para chamar a função de copiar a tabela para a área de tranferência
    const AddButton = () => (
        <Tooltip disableFocusListener title="Copiar para a área de transferência">
            <IconButton onClick={copyToClipboard}>
                <ContentCopyIcon />
            </IconButton>
        </Tooltip>
    );

    const options = {
        filterType: "multiselect",
        filter: true,
        download: true,
        print: false,
        responsive: "vertical",
        tableBodyHeight: "60vh",
        draggableColumns: {
            enabled: true,
        },
        selectableRowsHideCheckboxes: true,
        viewColumns: false,
        toolButton: {
            justifyContent: "center"
        },
        textLabels: {
            body: {
                noMatch: loading ? <ArrowLoader /> : "Não há operações não executadas.",
                toolTip: "Ordenar",
                columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
            },
            pagination: {
                next: "Próxima",
                previous: "Anterior",
                rowsPerPage: "Linhas por página:",
                displayRows: "de",
            },
            toolbar: {
                search: "Pesquisar",
                downloadCsv: "Download XLSX",
                print: "Imprimir",
                viewColumns: "Ver colunas",
                filterTable: "Filtrar",
            },
            filter: {
                all: "Todos",
                title: "Filtros",
                reset: "Limpar",
            },
            viewColumns: {
                title: "Mostrar Colunas",
                titleAria: "Mostrar/Ocultar colunas",
            },
            selectedRows: {
                text: "linha(s) selecionadas",
                delete: "Excluir",
                deleteAria: "Excluir linhas Selecionadas",
            },
        },
        downloadOptions: {
            filename: "operacoes_nao_executadas.csv",
            separator: ";",
        },
        onDownload: () => {
            assetnotExecutedExport();
            return false;
        },
        sortOrder: {
            name: 'broker_name',
            direction: 'asc'
        },
        customToolbar: AddButton,
        setTableProps: () => {
            return {
                padding: 'none',
                size: 'small'
            };
          },
        rowsPerPage: rowsPerPage,
        rowsPerPageOptions: [30, 50, 100],
    };

    const getMuiTheme = () =>
    createTheme({
      components: {
        MuiTableCell: {
            styleOverrides: {
                root:{
                        justifyContent: 'center',
                        whiteSpace: "nowrap",
                        textAlign: 'center',
                }
            },
          },
        MUIDataTableHeadCell: {
            styleOverrides: {
                contentWrapper: {
                    justifyContent: 'center',
                    whiteSpace: "nowrap",
                }
            }
        },
      },
    })

    return (
        <div>
            <ThemeProvider theme={getMuiTheme()}>
                <MUIDataTable
                    title={`Operações não Executadas ${databaseUpdateTime}`}
                    data={listNotExecuted}
                    columns={columns}
                    options={options}
                    ></MUIDataTable>
            </ThemeProvider>
        </div>
    );
}

