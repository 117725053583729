import React, { useEffect, useState, useRef, useReducer } from "react"
import MUIDataTable from "mui-datatables"
import { debounceSearchRender } from "mui-datatables"
import { Tooltip, IconButton } from "@material-ui/core"
import ContentCopyIcon from "@mui/icons-material/ContentCopy"
import { ThemeProvider, createTheme } from "@mui/material/styles"
import TableCell from "@mui/material/TableCell"
/*Styles*/
import "../../monitoringPanel/myReservations/styles/myReservation.table.css"
import { AiOutlineArrowUp, AiOutlineArrowDown } from "react-icons/ai"
import { TextField, Select, MenuItem } from "@material-ui/core"
import { datetimeToDatePtBR } from "../../../../utils/formatDate/dateTimeToPTBR"
import { AiFillCloseCircle } from "react-icons/ai"

/*Contexts*/
import { useAuth } from "../../../../contexts/useAuth"
import { useModal } from "../../../../contexts/useModal"
/*Services*/
import { getDatabaseReferenceTime, getFixedIncomeView, getFixedIncomeDuesView } from "../../../../services/asyncFunctions"
/*Components*/

import { titleize } from "../../../../utils/formatNumberAndText/formatTextUpperFirstLetter"
import { ArrowLoader } from "../../../../components/loaders/arrowLoader"
import { toBRL } from "../../../../utils/formatNumberAndText/toBRLCurrency"
import { toPercent } from "../../../../utils/formatNumberAndText/formatNumberPercent"
import { styleClientsInput } from "../../../../components/styles/autocompletField"
export function FixedIncomeDuesTable() {
    const [loading, setLoading] = useState(false)
    const [listTable, setlistTable] = useState([])
    const [listTableFormatted, setlistTableFormatted] = useState([])
    const { user, tokenKey } = useAuth()
    const [, forceUpdate] = useReducer((x) => x + 1, 0)
    const { setType, setShow, edited, setEdited } = useModal()
    const [databaseUpdateTime, setDatabaseUpdateTime] = useState("")
    const teste = useRef(0)
    const teste2 = useRef(0)
    const [date, setDate] = useState(new Date())
    const [filterByVencimento, setFilterByVencimento] = useState(false)
    const [dateStart, setDateStart] = useState("")
    const [dateFinish, setDateFinish] = useState("")
    const classes = styleClientsInput()
    const [filterClients, setFilterClients] = useState(false)
    const [errorFields, setErrorFields] = useState({
        dateFinish: false,
    })

    async function getTable() {
        const listTable = await getFixedIncomeDuesView(tokenKey, false)
        console.log('listTable', listTable)
        // console.log("user", user)
        if (getFixedIncomeView?.isError) {
            setType({
                name: "alertMsg",
                type: "information",
                value: {
                    message: getFixedIncomeView?.error,
                },
            })
            setShow(true)
        } else {
            setlistTable(listTable)
            setLoading(false)
        }
    }

    async function getDatabaseUpdateTimeValues() {
        const bases = "diversificacao"
        const databaseUpdateTime = await getDatabaseReferenceTime(tokenKey, bases)
        if (getDatabaseReferenceTime?.isError) {
            setType({
                name: "alertMsg",
                type: "information",
                value: {
                    message: getDatabaseReferenceTime?.error,
                },
            })
            setShow(true)
        } else {
            try {
                let dateFormat = databaseUpdateTime[0].file_reference_time
                setDatabaseUpdateTime(dateFormat)
            } catch (e) {
                let dateFormat = ""
                setDatabaseUpdateTime(dateFormat)
            }
        }
    }

    function formateAssets(dateStart, dateFinish) {
        let tableResponseFormatted = []
        setLoading(true)



        listTable.map((tableResponse) => {
            tableResponseFormatted.push({
                client_id: parseInt(tableResponse.client_id),
                client_name: tableResponse.client_name,
                advisor: tableResponse.advisor_name,
                advisor_id: tableResponse.advisor_id,
                team: tableResponse.leader_name,
                branch_city: tableResponse.branch_city,
                branch_state: tableResponse.branch_state,
                subproduct: tableResponse.sub_produto,
                issuer: tableResponse.emissor,
                active_name: tableResponse.ativo,
                due_at: tableResponse.data_de_vencimento,
                application_curve_value: tableResponse.net,
            })
        })

        if (dateStart && dateFinish) {
            
            // var cloneTableResponseFormatted = tableResponseFormatted.filter((item) => {
            //     const itemDate = new Date(item.due_at);
            //     const startDate = new Date(dateStart);
            //     const finishDate = new Date(dateFinish);

            //     return itemDate >= startDate && itemDate <= finishDate;
            // });
            var cloneTableResponseFormatted = []
            const finishDatePlusOneDay = new Date(dateFinish);
            finishDatePlusOneDay.setDate(finishDatePlusOneDay.getDate() + 1);

            listTable.map((tableResponse) => {
                if (tableResponse.data_de_vencimento >= dateStart && tableResponse.data_de_vencimento <= dateFinish) {
                    cloneTableResponseFormatted.push({
                        client_id: parseInt(tableResponse.client_id),
                        client_name: tableResponse.client_name,
                        advisor: tableResponse.advisor_name,
                        advisor_id: tableResponse.advisor_id,
                        team: tableResponse.leader_name,
                        branch_city: tableResponse.branch_city,
                        branch_state: tableResponse.branch_state,
                        subproduct: tableResponse.sub_produto,
                        issuer: tableResponse.emissor,
                        active_name: tableResponse.ativo,
                        due_at: tableResponse.data_de_vencimento,
                        application_curve_value: tableResponse.net,
                    })
                }
            });
        }

        setlistTableFormatted(dateStart && dateFinish ? cloneTableResponseFormatted : tableResponseFormatted)
        forceUpdate()
        setLoading(false)
    }

    useEffect(() => {
        if (edited || listTable.length === 0) {
            setEdited(false)
            setLoading(true)
            getTable()
            getDatabaseUpdateTimeValues()
        }
    }, [edited])

    useEffect(() => {
        if (listTable.length > 0) {
            formateAssets()
        }
    }, [listTable])

    function copyToClipboard() {
        const output = []
        const titles = ["Código Cliente", "Cliente", "Assessor", "Código Assessor", 
        "Time", "Filial", 
        "Ativo", "Emissor",  "Vencimento", "Financeiro"]
        output.push(titles.join("\t"))
        const keys = ["client_id", "client_name", "advisor", "advisor_id",
        "team", "branch_city", 
        "active_name", "issuer", "due_at", "application_curve_value"]
        listTableFormatted.forEach((x) => {
            const tmp = keys.map((y) => {
                return  y === "due_at"
                    ? x[y].replace(/^(\d{4})-(\d{2})-(\d{2})T(\d{2}:\d{2}:\d{2})\.\d+Z$/, "$3/$2/$1")
                    : 
                     x[y]
            })
            output.push(tmp.join("\t"))
        })
        const table = output.join("\n")
        navigator.clipboard.writeText(table)
        alert("Conteúdo da tabela copiado para a área de transferência.")
    }

    async function tableResponseExport(open, year) {
        setLoading(true)
        try {
            const myExport = await getFixedIncomeDuesView(tokenKey, true)
            const responseURL = URL.createObjectURL(new Blob([myExport]))
            const link = document.createElement("a")
            link.href = responseURL
            link.setAttribute("download", "vencimentos_renda_fixa_emissões.xlsx")
            document.body.appendChild(link)
            link.click()
            setLoading(false)
        } catch (error) {
            setType({
                name: "alertMsg",
                type: "information",
                value: {
                    message: error?.response?.data?.message === undefined ? "Erro ao buscar dados para exportação" : error?.response?.data?.message,
                },
            })
            setShow(true)
            setLoading(false)
        }
    }

    const columns = [
        {
            name: "client_id",
            label: "Código Cliente",
            options: {
                filter: true,
                sort: true,
                sortDescFirst: true,
                customHeadRender: ({ index, name, label, ...column }, sortColumn, tableMeta) => {
                    return (
                        <>
                            <TableCell
                                className="TableCell"
                                onClick={() => {
                                    sortColumn(index)
                                }}
                                style={{
                                    minWidth: "9rem",
                                    whiteSpace: "nowrap",
                                    position: "sticky",
                                    left: 0,
                                    zIndex: 121,
                                    backgroundColor: "white",
                                }}
                            >
                                <div
                                    className="containerTableCellButton"
                                    // style={{ margin: "0px 30px" }}
                                >
                                    <div>
                                        {label}

                                        <div className="arrowSortAbc">
                                            {tableMeta.name === name ? tableMeta.direction == "asc" ? <AiOutlineArrowUp size={18} /> : <AiOutlineArrowDown size={18} /> : <></>}
                                        </div>
                                    </div>
                                </div>
                            </TableCell>
                        </>
                    )
                },
                setCellProps: () => {
                    teste.current += 1
                    //linha par cinza e linha impar branca
                    const color = teste.current % 2 != 0 ? "#dbdbdb" : "white"
                    return {
                        style: {
                            minWidth: "10rem",
                            whiteSpace: "nowrap",
                            textAlign: "center",
                            position: "sticky",
                            left: "0",
                            backgroundColor: color,
                            zIndex: 120,
                        },
                    }
                },
            },
        },
        {
            name: "client_name",
            label: "Cliente",
            options: {
                filter: true,
                sort: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                    },
                }),
                customBodyRender: (val) => {
                    return titleize(val)
                },
                customHeadRender: ({ index, name, label, ...column }, sortColumn, tableMeta) => {
                    return (
                        <>
                            <TableCell
                                className="TableCell"
                                onClick={() => {
                                    sortColumn(index)
                                }}
                                style={{
                                    minWidth: "9rem",
                                    position: "sticky",
                                    left: "12rem",
                                    zIndex: 121,
                                    backgroundColor: "white",
                                }}
                            >
                                <div
                                    className="containerTableCellButton"
                                    // style={{ margin: "0px 30px" }}
                                >
                                    <div>
                                        {label}

                                        <div className="arrowSortAbc">
                                            {tableMeta.name === name ? tableMeta.direction == "asc" ? <AiOutlineArrowUp size={18} /> : <AiOutlineArrowDown size={18} /> : <></>}
                                        </div>
                                    </div>
                                </div>
                            </TableCell>
                        </>
                    )
                },
                setCellProps: (e, i) => {
                    teste2.current += 1
                    //linha par cinza e linha impar branca
                    const color = teste2.current % 2 != 0 ? "#dbdbdb" : "white"
                    return {
                        style: {
                            textAlign: "center",
                            position: "sticky",
                            whiteSpace: "nowrap",
                            left: "12rem",
                            backgroundColor: color,
                            zIndex: 120,
                        },
                    }
                },
            },
        },
    ]

    //se o usuario for lider, entao insere uma coluna de assessor na tabela para saber de qual dos liderados é o cliente
    if (user?.is_leader > 0|| user.type < 3) {
        columns.push(
            {
                name: "advisor",
                label: "ASSESSOR",
                options: {
                    filter: true,
                    sort: true,
                    display: true,
                    setCellProps: () => ({
                        style: {
                            whiteSpace: "nowrap",
                        },
                    }),
                },
            },
            {
                name: "advisor_id",
                label: "Código do Assessor",
                options: {
                    filter: true,
                    sort: false,
                    sortDescFirst: true,
                    display: false,
                    setCellProps: () => ({
                        style: {
                            whiteSpace: "nowrap",
                        },
                    }),
                },
            })}
            if (user.type < 3) {
                columns.push(
            {
                name: "team",
                label: "Time",
                options: {
                    filter: true,
                    sort: true,
                    display: true,
                    setCellProps: () => ({
                        style: {
                            whiteSpace: "nowrap",
                        },
                    }),
                },
            },
            {
                name: "branch_city",
                label: "Filial",
                options: {
                    filter: true,
                    sort: true,
                    display: true,
                    setCellProps: () => ({
                        style: {
                            whiteSpace: "nowrap",
                        },
                    }),
                },
            },
            // {
            //     name: "branch_state",
            //     label: "Filial UF",
            //     options: {
            //         filter: true,
            //         sort: true,
            //         display: true,
            //         setCellProps: () => ({
            //             style: {
            //                 whiteSpace: "nowrap",
            //             },
            //         }),
            //     },
            // }
        )
    }

    columns.push(
        {
            name: "subproduct",
            label: "Subproduto",
            options: {
                filter: true,
                sort: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                    },
                }),
            },
        },
        {
            name: "active_name",
            label: "Ativo",
            options: {
                filter: true,
                sort: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                    },
                }),
            },
        },
        {
            name: "issuer",
            label: "Emissor",
            options: {
                filter: true,
                sort: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                    },
                }),
            },
        },
        {
            name: "due_at",
            label: "Vencimento",
            options: {
                filter: true,
                sort: true,
                sortDescFirst: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                    },
                }),
                customBodyRender: (val) => {
                    return val?.replace(/^(\d{4})-(\d{2})-(\d{2})T(\d{2}:\d{2}:\d{2})\.\d+Z$/, "$3/$2/$1")
                },
            },
        },
        {
            name: "application_curve_value",
            label: "Financeiro",
            options: {
                filter: false,
                sort: true,
                sortDescFirst: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                    },
                }),
                customBodyRender: (value) => <span>{toBRL(value)}</span>,
            },
        }
    )

    useEffect(() => {
        if (listTable.length > 0) {
            formateAssets()
        }
    }, [listTable])

    const AddButton = () => (
        <>
            <Tooltip disableFocusListener title="Copiar para a área de transferência">
                <IconButton onClick={copyToClipboard}>
                    <ContentCopyIcon />
                </IconButton>
            </Tooltip>
            {filterClients == false ? 
            <span style={{cursor:"pointer",  marginTop:'0.75rem'}} 
            onClick={() => {
                setFilterClients(false) 
                setFilterByVencimento(true)
            }} > Filtrar por vencimento</span> : 
            <div style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
                <span style={{cursor:"pointer", margin:'auto'}} onClick={() => setFilterByVencimento(true)} > Vencimento filtrado por {datetimeToDatePtBR(dateStart)} - {datetimeToDatePtBR(dateFinish)}</span>
                <AiFillCloseCircle size={24} color="#C39D16" cursor={'pointer'} onClick={() => { setDateStart(""); setDateFinish(""); formateAssets(); setFilterClients(false) }} />
            </div>
            }
            {filterByVencimento ?
                <div style={{padding:'1rem', position: 'absolute', zIndex: 1000, background: 'white',display:'flex', flexDirection:'column', border: '1px solid black', borderRadius:'0.5rem', boxShadow: '2px 2px 5px 0px', transition:'0.5s' }}>
                    <div style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
                        <span style={{ color:'#C39D16', fontWeight:"bold"}}>Filtrar por vencimento</span>
                        <AiFillCloseCircle size={24} color="#C39D16" cursor={'pointer'} onClick={() => setFilterByVencimento(false)} />
                    </div>
                    <span style={{}} onClick={() => setFilterByVencimento(false)} >
                    </span>
                    <div style={{display:'flex', justifyContent: filterClients ? 'space-between' : 'flex-end', marginTop:'1rem'}}>
                        { filterClients ?
                            <span 
                                style={{background:'#803535', padding:'0.5rem', borderRadius:'0.5rem', color:"white", height:'fit-content', textAlign:'center', cursor:"pointer"}} 
                                onClick={() => {
                                    setDateStart("")
                                    setDateFinish("")
                                    formateAssets()
                                    setFilterByVencimento(false)
                                    setFilterClients(false)
                                }}
                                >Limpar Filtros
                            </span>
                            : <></>
                        }
                        <span 
                            style={{background:'#C39D16', padding:'0.5rem', borderRadius:'0.5rem', color:"white", height:'fit-content', textAlign:'center', cursor:"pointer"}} 
                            onClick={() => {
                                setFilterClients(true)
                                formateAssets(dateStart, dateFinish)
                                setFilterByVencimento(false)
                            }}
                            >Filtrar
                        </span>
                    </div>
                    <div style={{display:'flex', flexDirection:'column', gap:'1rem', marginTop:'1rem'}}>
                        <div style={{display:"flex", flexDirection:"column"}}>
                            <TextField
                                label="Desde"
                                value={dateStart}
                                type="date"
                                InputLabelProps={{ shrink: true }}
                                className={errorFields["dateStart"] && !dateStart?.trim()?.length ? classes.styleForClientsWithError : classes.styleForClients}
                                onChange={(e) => {
                                    setDateStart(e.target.value)
                                }}
                                variant="outlined"
                            />
                        </div>
                        <div style={{display:"flex", flexDirection:"column"}}>
                            <TextField
                                label="Até"
                                value={dateFinish}
                                type="date"
                                InputLabelProps={{ shrink: true }}
                                className={errorFields["dateFinish"] && !dateFinish?.trim()?.length ? classes.styleForClientsWithError : classes.styleForClients}
                                onChange={(e) => {
                                    setDateFinish(e.target.value)
                                }}
                                variant="outlined"
                            />
                        </div>
                    </div>
                    
                </div>

                : <></>
            }
        </>
    )

    const options = {
        filterType: "multiselect",
        filter: true,
        download: true,
        print: false,
        responsive: "standard",
        customSearchRender: debounceSearchRender(500),
        tableBodyHeight: "70vh",
        selectableRowsHideCheckboxes: true,
        viewColumns: false,
        toolButton: {
            justifyContent: "center",
        },
        textLabels: {
            body: {
                noMatch: loading ? <ArrowLoader /> : "Não há informações a apresentar no momento!",
                toolTip: "Ordenar",
                columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
            },
            pagination: {
                next: "Próxima",
                previous: "Anterior",
                rowsPerPage: "Linhas por página:",
                displayRows: "de",
            },
            toolbar: {
                search: "Pesquisar",
                downloadCsv: "Download XLSX",
                print: "Imprimir",
                viewColumns: "Ver colunas",
                filterTable: "Filtrar",
            },
            filter: {
                all: "Todos",
                title: "Filtros",
                reset: "Limpar",
            },
            viewColumns: {
                title: "Mostrar Colunas",
                titleAria: "Mostrar/Ocultar colunas",
            },
            selectedRows: {
                text: "linha(s) selecionadas",
                delete: "Excluir",
                deleteAria: "Excluir linhas Selecionadas",
            },
        },
        downloadOptions: {
            filename: "previa_receita.csv",
            separator: ";",
        },
        onDownload: () => {
            tableResponseExport()
            return false
        },
        setTableProps: () => {
            return {
                paddingTop: "0px",
                paddingBottom: "0px",
                paddingLeft: "1rem",
                paddingRight: "1rem",
                // padding: '0px 1rem 0px',
                size: "small",
            }
        },
        rowsPerPage: 20,
        rowsPerPageOptions: [20, 200, 1000],
        customToolbar: AddButton,
    }

    const getMuiTheme = () =>
        createTheme({
            components: {
                MuiTableCell: {
                    styleOverrides: {
                        root: {
                            justifyContent: "center",
                            whiteSpace: "",
                            textAlign: "center",
                        },
                    },
                },
                MUIDataTableHeadCell: {
                    styleOverrides: {
                        contentWrapper: {
                            justifyContent: "center",
                            whiteSpace: "pre-line",
                        },
                        fixedHeader: {
                            justifyContent: "center",
                            whiteSpace: "pre-line",
                            maxWidth: "4rem",
                            padding: "0px 0.6rem 0px 0.6rem",
                        },
                        toolButton: {
                            margin: "0px",
                        },
                    },
                },
                MUIDataTableFilter: {
                    styleOverrides: {
                        root: {
                            minWidth: "40vw",
                            maxWidth: "50vw",
                        },
                    },
                },
                MUIDataTableBodyRow: {
                    styleOverrides: {
                        root: {
                            "&:nth-child(odd)": {
                                backgroundColor: "#dbdbdb",
                            },
                            // "&:hover ": {
                            //     backgroundColor: "#e7e7e7 !important",
                            // },
                            // "&: hover td": {
                            //     backgroundColor: "#e7e7e7 !important",
                            // },
                        },
                    },
                },
                MuiPaper: {
                    styleOverrides: {
                        root: {
                            boxShadow: "none",
                            borderRadius: "0",
                        },
                    },
                },
            },
        })

    return (
        <div>
            <ThemeProvider theme={getMuiTheme()}>
                <MUIDataTable title={"Última atualização: " + databaseUpdateTime} data={listTableFormatted} columns={columns} options={options} className="tableUnsuitedStructured"></MUIDataTable>
            </ThemeProvider>
        </div>
    )
}
