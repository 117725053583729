import React, { useState, useEffect } from 'react'
import { useAuth } from "../../../contexts/useAuth"
import Chart from "react-apexcharts";
import { getCubastarRentabilityCurve } from '../../../services/asyncFunctions';
import { datetimeToDatePtBR } from '../../../utils/formatDate/dateTimeToPTBR';

export function GraphCubastarRentabilityCurve(data){
    const { tokenKey, user } = useAuth()
    const {dataRentabilityCurve, setDataRentabilityCurve} = useState([]);
    const curveDate = [];
    const dataCarteira = [];
    const dataCDI = [];

    const nomeIndicador = data?.data?.portfolio?.includes("IPCA") ? "IPCA + 4%" : "CDI"
    const curveProps = data?.data;

    curveProps.forEach(x => {
        var date = new Date(x.date).getTime();
        var carteira = x.carteira_acc;
        var cdi = x.cdi_acc;
        dataCarteira.push({x: date, y: carteira});
        dataCDI.push({x: date, y: cdi});
    });

    var newDataCarteira = {};
    var newDataCDI = {};

  const state = {

    series: [
        {
            name: 'Carteira',
            data: dataCarteira,
        },
        {
            name: nomeIndicador,
            data: dataCDI
        }
    ],
      options: {
        chart: {
          type: 'area',
          stacked: false,
          height: 350,
          background: '#fff',
          zoom: {
            enabled: false,
            type: 'x',
            autoScaleYaxis: true
          },
          toolbar: {
            autoSelected: 'zoom',
            show: false
          },
          events: {
            zoomed: function (event, chartContext, config) {

                // console.log(event, chartContext, config);

                var minChart = chartContext.xaxis.min;
                var maxChart = chartContext.xaxis.max;

                newDataCarteira = dataCarteira.filter(function (data) {
                    return data.x >= minChart && data.x <= maxChart;
                });
                newDataCDI = dataCDI.filter(function (data) {
                    return data.x >= minChart && data.x <= maxChart;
                });

                // console.log('novos dados: ', newDataCarteira, newDataCDI);
                // TODO: Atualizar o gráfico com a nova faixa de dados.
            }
        },
        locales: [{
          name: 'en',
          options: {
            months: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
            shortMonths: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
            days: ['Domingo', 'Segunda-feira', 'Terça-feira', 'Quarta-feira', 'Quinta-feira', 'Sexta-feira', 'Sábado'],
            shortDays: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'],
            toolbar: {
              download: 'Download',
              selection: 'Seleção',
              selectionZoom: 'Seleção com Zoom',
              zoomIn: 'Aumentar Zoom',
              zoomOut: 'Reduzir Zoom',
              pan: 'Mover Gráfico',
              reset: 'Redefinir Zoom',
            }
          }
        }]
        },
        colors: ['#09033B', '#66DA26'],
        dataLabels: {
          enabled: false
        },
        markers: {
          size: 0,
        },
        // title: {
        //   text: 'Gráfico de Rentabilidade',
        //   align: 'left'
        // },
        fill: {
          type: 'gradient',
          gradient: {
            shadeIntensity: 1,
            inverseColors: false,
            opacityFrom: 0.5,
            opacityTo: 0,
            stops: [0, 90, 100]
          },
        },
        xaxis: {
          type: 'datetime',
        },
        yaxis: {
          labels: {
            formatter: function (value) {
              return value.toFixed(2);
            }
          },
        },        
        tooltip: {
          shared: false
        }
      },
    };
    
  return (
    <>
    <div className="app">
      <div className="row">
        <div className="mixed-chart-this">
          <div className='cubastar-table-label'>Curva de Rentabilidade</div>
          {
            curveProps.length > 0 ? 
            <Chart
            options={state.options}
            series={state.series}
            type="area"
            width="100%"
          /> : <div>
            <p style={{textAlign: 'center'}}>Não há dados para apresentar no momento.</p>
          </div>
          }
          
        </div>
      </div>
    </div>
    </>
  );
}