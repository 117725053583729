import React, { useEffect, useState } from 'react'
/*Styles*/
import './styles/fiisOfferingsDetails.css'
import { styledForOfferings } from '../../../../components/styles/autocompletField'
import { Autocomplete } from '@material-ui/lab'
import { TextField, TextareaAutosize } from '@material-ui/core'
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
/*Contexts*/
import { useAuth } from '../../../../contexts/useAuth'
import { useModal } from '../../../../contexts/useModal'
/*Utils*/
import { ModalSizeFitToScreen } from '../../../../utils/responsiveFunctions/modalSizeFitToScreen'
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import draftToHtml from 'draftjs-to-html'
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { titleize } from '../../../../utils/formatNumberAndText/formatTextUpperFirstLetter'
/*Services*/
import { postOffersDetails } from '../../../../services/asyncFunctions'
import { getOffersDetails } from '../../../../services/asyncFunctions'
import { api } from '../../../../services/api'
/*Components*/
import { ArrowLoader } from '../../../../components/loaders/arrowLoader'

export function FiisOfferingsDetails(props) {
    const { screenX, screenY } = ModalSizeFitToScreen()
    const [loading, setLoading] = useState(true)
    const clientList = props.fiisData.clientList
    const fiisData = props.fiisData.fiisData
    const [modalityForStock, setModalityiesForStocks] = useState([])
    const [offersDetails, setOffersDetails] = useState('')
    const [showReservation, setShowReservation] = useState(false)
    const [selectedClient, setSelectedClient] = useState([])
    const [selectedValue, setSelectedValue] = useState(0)
    const [selectedModality, setSelectedModality] = useState('')
    const [selectedObservation, setSelectedObservation] = useState('')
    const classes = styledForOfferings()
    const { show, setShow, type, setType } = useModal()
    const { user, tokenKey } = useAuth()
    const [inEdit, setInEdit] = useState(false)
    const [editorState, setEditorState] = useState(() =>
        EditorState.createEmpty()
    );
    const [textContent, setTextContent] = useState('')
    const [previewText, setPreviewText] = useState('')
    const [previewTextShow, setPreviewTextShow] = useState(false)
    const nowDate = new Date()
    console.log('props', props)
    async function handleSubmitOffersDetails() {

        setLoading(true)
        let newText = previewText

        while (newText.indexOf(`"`) > -1) {

            newText = newText.replace(/"/, '!!!!')

        }

        const insertDetaisl = await postOffersDetails(tokenKey, fiisData.asset, newText)
        if (insertDetaisl?.isError) {
            setType({
                name: 'alertMsg',
                type: 'information',
                value: {
                    message: insertDetaisl?.error
                }
            })
            setShow(true);
        } else {
            setType({ name: 'alertMsg', type: 'information', value: { message: 'Cadastro realizado com sucesso!' } })
            setShow(true)
            setLoading(false)
        }
    }

    function generatePDF(id) {

        const htmlToPDfData = document.getElementById(id).innerHTML
        const pdfWindow = window.open('', '', 'width=1920, height= 1080')
        pdfWindow.document.write('<html><head>')
        pdfWindow.document.write(`<tile><h4>Oferta: ${fiisData.asset} | Data: ${nowDate.toLocaleDateString()}</h4></title></head>`)
        pdfWindow.document.write('<body>')
        pdfWindow.document.write(htmlToPDfData)
        pdfWindow.document.write('</body></html>')
        pdfWindow.document.close()
        pdfWindow.print()
            ;
    }

    async function getOffersDetailsByAPI() {

        const offersDetailsGet = await getOffersDetails(tokenKey, fiisData.id)
        if (offersDetailsGet?.isError && !offersDetailsGet?.notExist) {
            setType({
                name: 'alertMsg',
                type: 'information',
                value: {
                    message: offersDetailsGet?.error
                }
            })
            setShow(true);
        } else {

            if (!offersDetailsGet?.notExist) {

                let newDetails = offersDetailsGet

                while (newDetails.indexOf(`!!!!`) > -1) {
                    newDetails = newDetails.replace(`!!!!`, `"`)
                }

                while (newDetails.indexOf(`<p></p>`) > -1) {
                    newDetails = newDetails.replace(`<p></p>`, `<br>`)
                }
                setOffersDetails(newDetails)
            } else {
                setOffersDetails('')
            }
            setLoading(false)
        }
    }

    async function handleSubmitOffersOrder() {

        setLoading(true)
        try {
            console.log(selectedClient)
            const postOffersOrders = await api.post(`/api/v1/offersOrders`, {
                "offers_id": props.fiisData.fiisData.id,
                "clients_external_id": parseInt(selectedClient.external_id),
                "value": parseInt(selectedValue),
                "modality": selectedModality !== '' ? selectedModality : undefined,
                "observation": selectedObservation !== '' ? selectedObservation : undefined
            }, {
                headers: {
                    authorization: `Bearer ${tokenKey}`
                }
            })

            setType({ name: 'alertMsg', type: 'information', value: { message: "Oferta enviada com sucesso!" } })
            setShow(true)
            setLoading(false)
        } catch (error) {
            console.log(error?.response)
            setType({ name: 'alertMsg', type: 'information', value: { message: error?.response?.data?.message === undefined ? 'Erro ao solicitar reserva' : error.response.data.message } })
            setShow(true)
        }
    }

    useEffect(() => {

        if (show) {
            setLoading(true)
            getOffersDetailsByAPI()
            const modalitiesOptions = []
            const options = Object.keys(props.fiisData.fiisData)
            const validyOptions = options.filter((fees) => {

                if (fees === 'fee_retail_with_lockup') {
                    if (props.fiisData.fiisData[fees] !== null && props.fiisData.fiisData[fees] !== undefined) {
                        modalitiesOptions.push('FEE Sobras')
                    }
                }

                if (fees === 'fee_retail_without_lockup') {
                    if (props.fiisData.fiisData[fees] !== null && props.fiisData.fiisData[fees] !== undefined) {
                        modalitiesOptions.push('FEE Direito de preferência')
                    }
                }

                if (fees === 'fee_institution_with_lockup') {
                    if (props.fiisData.fiisData[fees] !== null && props.fiisData.fiisData[fees] !== undefined) {
                        modalitiesOptions.push('FEE Pública')
                    }
                }

                if (fees === 'fee_institution_without_lockup') {
                    if (props.fiisData.fiisData[fees] !== null && props.fiisData.fiisData[fees] !== undefined) {
                        modalitiesOptions.push('FEE Lote adicional')
                    }
                }
            })
            console.log(modalitiesOptions)
            modalitiesOptions.length > 0 ? setModalityiesForStocks(modalitiesOptions) : setModalityiesForStocks([])
        }
    }, [show])

    useEffect(() => {
        setPreviewText(draftToHtml(convertToRaw(editorState.getCurrentContent())))
        setShowReservation(false)
    }, [editorState])

    return (
        <div className="fiisOfferingsDetailsContainer" style={{ width: screenX, height: screenY }}>
            <div className="fiisOfferingsDetailsHeader">
                <div className='fiisOfferingsDetailsHeaderCompanyName'>
                    <p>Detalhes: </p>
                    <p>{fiisData?.company_name}</p>
                </div>
                <button className='modalCloseButton' onClick={() => setShow(false)}>X</button>
            </div>
            {
                loading ?
                    <div className='fiisOfferingsDetailsLoader' style={{ width: screenX - 100, height: screenY - 200, maxHeight: screenY - 200 }}>
                        <ArrowLoader />
                    </div>
                    :
                    !showReservation ?
                        (
                            <div className="fiisOfferingsDetailsBody" style={{ width: screenX - 100, height: screenY - 200, maxHeight: screenY - 200 }}>
                                {
                                    inEdit && !previewTextShow ?
                                        (
                                            <div id='fiisOfferingsEditor'>
                                                <Editor
                                                    editorState={editorState}
                                                    toolbarClassName="toolbarClassName"
                                                    wrapperClassName="wrapperClassName"
                                                    editorClassName="editorClassName"
                                                    onEditorStateChange={setEditorState}
                                                    toolbar={{
                                                        options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'colorPicker', 'link', 'emoji', 'list', 'textAlign',
                                                            'remove', 'history'],
                                                    }}
                                                />
                                            </div>
                                        ) :
                                        previewTextShow ?
                                            (
                                                <div className='fiisOfferingsDetailsPreviewTextContent' dangerouslySetInnerHTML={{ __html: previewText }} >

                                                </div>
                                            ) :
                                            offersDetails.length > 0 ?
                                                (
                                                    <div className='fiisOfferingsDetailsTextContent' id='offeringsPdfModel' dangerouslySetInnerHTML={{ __html: offersDetails }}></div>
                                                ) :
                                                (
                                                    <div className='fiisOfferingsDetailsTextContent'>Sem dados cadastrados</div>
                                                )
                                }
                            </div>
                        ) :
                        (
                            <div className="fiisOfferingsDetailsReservation" style={{ width: screenX - 100, height: screenY - 200, maxHeight: screenY - 200 }}>
                                <div className="fiisOfferingsReservationCard">
                                    <div className="fiisOfferingsReservationCardHeader" style={{ gridTemplateColumns: modalityForStock.length > 0 ? 'repeat(3,1fr)' : 'repeat(2,1fr)' }}>
                                        <Autocomplete
                                            options={clientList}
                                            onChange={(event, newValue) => {
                                                setSelectedClient(newValue);
                                            }}
                                            getOptionLabel={(option) => option?.external_id + ' - ' + titleize(option.name)}
                                            id='operatesAlone'
                                            renderInput={(params) =>
                                                <TextField
                                                    label='Cliente'
                                                    className={classes.root}
                                                    {...params} variant="outlined"
                                                />
                                            }
                                        />
                                        <label>
                                            <TextField
                                                onKeyPress={(e) => { console.log(e); if (e.code === 'Backquote' || e.key === 'e') e.preventDefault() }}
                                                label='Quantidade'
                                                variant='outlined'
                                                type="number"
                                                onChange={(e) => {
                                                    if (e.target.value.length <= 255) {

                                                        setSelectedValue(e.target.value)
                                                    }
                                                }}
                                                value={selectedValue}
                                                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                                className={classes.root}
                                            />
                                        </label>
                                        {
                                            modalityForStock.length > 0
                                            &&
                                            <Autocomplete
                                                options={modalityForStock}
                                                onChange={(event, newValue) => {
                                                    setSelectedModality(newValue);
                                                }}
                                                getOptionLabel={(option) => option}
                                                id='operatesAlone'
                                                renderInput={(params) =>
                                                    <TextField
                                                        label='Modalidade'
                                                        className={classes.root}
                                                        {...params} variant="outlined"
                                                    />
                                                }
                                            />
                                        }
                                    </div>
                                    <label id='offeringsDetailsObservation'>
                                        <TextField
                                            onChange={(e) => setSelectedObservation(e.target.value)}
                                            onKeyPress={(e) => { if (e.code === 'Backquote') e.preventDefault() }}
                                            placeholder='Observações'
                                            inputProps={{ maxLength: '200' }}
                                            className={classes.textArea}
                                            multiline
                                            variant='outlined'
                                            maxRows='4'
                                        />
                                    </label>

                                </div>
                            </div>
                        )}

            <div className="fiisOfferingsDetailsFooter" style={{ width: screenX - 100, maxWidth: screenX - 100 }}>
                {
                    inEdit ?
                        (
                            <>
                                <button onClick={() => { setInEdit(false); setPreviewTextShow(false); setPreviewText('') }}>Cancelar Edição</button>
                                {
                                    !previewTextShow ?
                                        <button className='blackButton' onClick={() => setPreviewTextShow(true)}>Prévia</button>
                                        :
                                        <button className='blackButton' onClick={() => setPreviewTextShow(false)}>Voltar a edição</button>
                                }
                                <button className='blackButton' onClick={() => handleSubmitOffersDetails()}>Confirmar</button>
                            </>
                        ) : (
                            <>
                                {
                                    showReservation ?
                                        <button onClick={() => setShowReservation(!showReservation)}>Cancelar reserva</button>
                                        :
                                        <button onClick={() => setShowReservation(!showReservation)} title='Clique para indicar reserva a um cliente'>Indicar reserva</button>
                                }
                                {
                                    user.type < 3 && !showReservation ?
                                        <button className='blackButton' onClick={() => { setInEdit(true) }}>Novo texto</button>
                                        :
                                        showReservation &&
                                        <button className='blackButton' onClick={() => { handleSubmitOffersOrder() }}>Confirmar reserva</button>
                                }
                                {
                                    offersDetails.length > 0 &&
                                    <button className='blackButton' onClick={() => generatePDF('offeringsPdfModel')}>Modelo auditoria</button>
                                }
                            </>
                        )
                }
            </div>
        </div>
    )
}