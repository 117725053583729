import React, { useEffect, useState } from 'react';

import { useModal } from '../../../../contexts/useModal';
import { useAuth } from '../../../../contexts/useAuth';

import { FiTrash2 } from 'react-icons/fi'

import { ArrowLoader } from '../../../../components/loaders/arrowLoader';
import { ArrowLoaderWOText } from '../../../../components/loaders/loaderWithoutText';
import { ArrowLoaderWhiteWOText } from '../../../../components/loaders/loaderWhitoutTextWhite';

import { api } from '../../../../services/api';

import { titleize } from '../../../../utils/formatNumberAndText/formatTextUpperFirstLetter';

import { toBRL } from "../../../../utils/formatNumberAndText/toBRLCurrency";

import { HiSortAscending, HiSortDescending} from 'react-icons/hi'
import { FiArrowUp, FiCopy } from 'react-icons/fi';
import { MdAutorenew } from 'react-icons/md';
import { BiMailSend } from 'react-icons/bi';
import { Autocomplete } from '@material-ui/lab';
import { TextField, TextareaAutosize } from '@material-ui/core'
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import { styledForOfferings } from '../../../../components/styles/autocompletField'

export function WisirControleTable(){
  const { user, tokenKey } = useAuth()
  const classes = styledForOfferings()

  const { show, setShow, edited, setEdited, type, setType } = useModal()

  const [loadingTable, setLoadingTable] = useState(false)
  const [loading, setLoading] = useState(false)

  const [activeControllBackofficeTable, setActiveControllBackofficeTable] = useState([])
  const [activeBackofficeControlTab, setActiveBackofficeControlTab] = useState(1)
  const [activeBackofficeControlName, setActiveBackofficeControlTabName] = useState("AÇÕES")

  const [filter, setFilter] = useState('')
  const [placeHolderPerformanceTable, setPlaceHolderPerformanceTable] = useState(0)

  const [orderingColumnName, setOrderingColumnName] = useState("")
  const [orderDescending, setOrderDescending] = useState(false)

  const [tabs, setTabs] = useState()
  const [assets, setAssets] = useState()

  const [ativo, setAtivo] = useState(null)
  const [nome, setNome] = useState(null)
  const [setor, setSetor] = useState(null)
  const [peso, setPeso] = useState(null)
  const [precoAtual, setPrecoAtual] = useState(null)

  const [requiredFields, setRequiredFields] = useState([
    {field: "ativo", value: ativo},
    {field: "nome", value: nome},
    {field: "setor", value: setor},
    {field: "peso", value: peso},
    {field: "precoAtual", value: precoAtual},
  ])
  
  useEffect(() => {
    getTabs()
    getAssets()
    getTab(activeBackofficeControlTab)
  }, [tokenKey], [show])

  function reloadTable(){
    getTab(activeBackofficeControlTab)
  }
  // REQUISIÇÃO DE ATIVOS DISPONÍVEIS ++++++++++++++++++++++++++++++++++++++++++
  async function getAssets(){
    setLoading(true);
    try {
      const assets = await api.get(`/api/v1/assets`, {
        headers: {
          authorization: `Bearer ${tokenKey}`
        }
      })
      let distinctAssets = assets?.data?.data?.filter((v, i, a) => a.indexOf(v) === i)
      setAssets(distinctAssets)
    } catch (error) {
      setLoading(false);
      setType({ name: 'alertMsg', type: 'information', value: { message: error?.response?.data?.message == undefined ? 'Erro interno ao carregar as abas' : error?.response?.data?.message } })
      setShow(true);
    }
    setLoading(false);
    setLoadingTable(false);
  }
  // ---------------------------------------------------------------------------
  // REQUISIÇÕES DE ABA DA TABELA ++++++++++++++++++++++++++++++++++++++++++++++
  async function getTabs(){
    setLoading(true);
    try {
      const tabs = await api.get(`/api/v1/wisir/strategies`, {
        headers: {
          authorization: `Bearer ${tokenKey}`
        }
      })
      let distinctTabs = tabs?.data?.data?.filter((v, i, a) => a.indexOf(v) === i)
      setTabs(distinctTabs)
    } catch (error) {
      setLoading(false);
      setType({ name: 'alertMsg', type: 'information', value: { message: error?.response?.data?.message == undefined ? 'Erro interno ao carregar as abas' : error?.response?.data?.message } })
      setShow(true);
    }
    setLoading(false);
    setLoadingTable(false);
  }

  async function performanceExport() {
    setLoading(true);
    try {
        const exportAssetsResult = await api.get(`/api/v1/BI`, {
            headers: {
                authorization: `Bearer ${tokenKey}`
            },
            responseType: 'blob'
        })

        const responseURL = URL.createObjectURL(new Blob([exportAssetsResult.data]));
        const link = document.createElement('a');
        link.href = responseURL;
        link.setAttribute('download', 'relatorios_de_performance.xlsx');
        document.body.appendChild(link);
        link.click();
        setLoading(false)

    } catch (error) {
        setType({ name: 'alertMsg', type: 'information', value: { message: error?.response?.data?.message === undefined ? 'Erro ao buscar dados para exportação' : error?.response?.data?.message } })
        setShow(true);
        setLoading(false);
    }
  }

  async function deleteRow(row){
    try {
      const removeRow = await api.delete(`/api/v1/wisir/controle/${row.id}/remove`, 
      {
        headers: {
          authorization: `Bearer ${tokenKey}`
        }
      })
      getTab(activeBackofficeControlTab)
   } catch (error) {
    console.log(error)
   }
    setShow(false)
  }

  async function addNewAsset(){
    try {
      const newAssetRow = await api.post(`/api/v1/wisir/controle/new`, {
        'asset': ativo.asset,
        'weight': peso,
        'value': precoAtual,
        'wisir_strategy_id': activeBackofficeControlTab,
      },
      {
        headers: {
          authorization: `Bearer ${tokenKey}`
        }
      })
      getTab(activeBackofficeControlTab)
   } catch (error) {
    console.log(error)
   }
   setAtivo(null)
  }

  function editAsset(row){
    assets.map((item, index) => {
      if(item.asset == row.asset){
        row.index = index
      }
    })
    row.assets = assets
    setShow(true); 
    setType({ name: 'editRow', value: row, func: reloadTable})
  }

  async function getTab(id){
    setLoading(true);
    try {
      const tabInfo = await api.get(`api/v1/wisir/controle/${id}`, {
        headers: {
          authorization: `Bearer ${tokenKey}`
        }
      })
      setActiveControllBackofficeTable(tabInfo?.data?.data)
    } catch (error) {
      setActiveControllBackofficeTable([])
      setLoading(false);
      setType({ name: 'alertMsg', type: 'information', value: { message: error?.response?.data?.message == undefined ? 'Erro interno ao carregar a tabela' : error?.response?.data?.message } })
      setShow(true);
    }
    setLoading(false);
    setLoadingTable(false);
  }

  function confirmRealizarGiro(){
    setType({
      name: "alertMsg",
      value: {
        message: "Deseja realizar o giro de carteira?",
        func: realizarGiro,
      },
    });
    setShow(true)
  }

  async function realizarGiro(){
    try {
      const giroMsg = await api.put(`api/v1/wisir/controle/giro/${activeBackofficeControlTab}`, {},
      {
        headers: {
          authorization: `Bearer ${tokenKey}`
        }
      })
      console.log(giroMsg)
      setType({ name: 'alertMsg', type: 'information', value: { message: 'Giro realizado com sucesso!' } })
      setShow(true)
    } catch (error) {
      setType({ name: 'alertMsg', type: 'information', value: { message: error?.response?.data?.message == undefined ? 'Erro interno ao carregar a tabela' : error?.response?.data?.message } })
      setShow(true)
    }
  }

  async function generateEmail() {
    setLoading(true);
    try {
        const emailGenerated = await api.get(`/api/v1/wisir/operational/${activeBackofficeControlTab}/emails`, {
            headers: {
                authorization: `Bearer ${tokenKey}`
            },
            responseType: 'blob'
        })
        const responseURL = URL.createObjectURL(new Blob([emailGenerated.data]));
        const link = document.createElement('a');
        link.href = responseURL;

        let date = new Date();
        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();
        let fileName = 'email_'+activeBackofficeControlName+'_'+day+'/'+month+'/'+year+'.json'
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        setLoading(false)
    } catch (error) {
        setType({ name: 'alertMsg', type: 'information', value: { message: error?.response?.data?.message === undefined ? 'Erro ao buscar dados para exportação' : error?.response?.data?.message } })
        setShow(true);
        setLoading(false);
    }
  }

  async function changeIgnorable(id){
    try {
      const ignorableAsset = await api.put(`/api/v1/wisir/controle/${id}/ignorable`, {},
      {
        headers: {
          authorization: `Bearer ${tokenKey}`
        }
      })
      getTab(activeBackofficeControlTab)
    } catch (error) {
      console.log(error)
    }
  }
  // REQUISIÇÕES DE ABA DA TABELA ----------------------------------------------
  // FILTRO ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  function filteredTable() {
    if (filter != undefined && filter != '' && filter != null) {
        return activeControllBackofficeTable.filter(row => {
          const rowKeys = Object.keys(row);
          if(rowKeys.filter(key => {
            return row?.[key]?.toLowerCase()?.indexOf(filter?.toLowerCase()) > -1
          }).length > 0){
            return row
          }
        })
    } else {
        return activeControllBackofficeTable
    }
  }

  // FILTRO --------------------------------------------------------------------
  return(
    <div key='performanceTabsTable' className='performanceTabsTable'>
      {loadingTable ? 
        (
          <div key='performanceTabsTableLoader' id='performanceTabsTableLoader'>
            <ArrowLoader />
          </div>
        )
        :
        <>
          {/* ABAS +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */}
          <div key='performanceTabsMenuHeader' id='performanceTabsMenuHeader'>
            <div key='performanceTabs' className='performanceTabs' >
              <> 
                {tabs?.map((tab) => {
                  return (
                    <label key={'tabLabel_'+tab.id}
                      style={{ cursor: 'pointer' }}
                      title='Clique para vizualizar essa tabela'
                      id={'tabId_'+tab.id}
                      className={activeBackofficeControlTab == tab.id ? 'performanceTabsLabelActive' : 'performanceTabsLabel'}
                      onClick={
                        () => {
                          setActiveBackofficeControlTab(tab.id)
                          setActiveBackofficeControlTabName(tab.description)
                          getTab(tab.id)
                        }
                      }>
                      <a>{tab.description}</a>
                    </label>
                  )
                })}
              </>  
            </div>
          </div>
          {/* ABAS ----------------------------------------------------------- */}
          <div className='performanceTableContainer'>
            <div className='performanceTableContent'>
                <table>
                    <thead id='performanceTableTbody'>
                        <tr id='performanceTableBody'>
                            <th colSpan='7'>
                                <div className='theadFirst'>
                                    <a>Controle backoffice</a>
                                    <div className='substitutoLabelDisplay'>
                                    <button
                                            className='buttonTableThead'
                                            onClick={() => {
                                              if (activeControllBackofficeTable.length > 0){

                                                const output = [];
                                                const keys = ['asset', 'name', 'sector', 'weight', 'value']
                                                // const keys = Object.keys(activeControllBackofficeTable[0]).slice(2)
                                                const titles = ['Ativo', 'Nome', 'Setor', 'Peso', 'Preço Atual']
                                                // 'id', 'strategy', 'asset', 'name', 'sector', 'weight', 'value'
                                                output.push(titles.join('\t'))
                                                activeControllBackofficeTable.forEach((x) => {
                                                  const tmp = keys.map((y) => {
                                                    return y==='weight'? 
                                                    parseFloat(x[y]).toFixed(2).replaceAll(".",",") + "%" 
                                                    : y==='value' ? 
                                                    toBRL(x[y])
                                                    : x[y]
                                                  });
                                                  output.push(tmp.join('\t'))
                                                })
                                                const table = output.join('\n')
                                                navigator.clipboard.writeText(table)
                                                alert('Conteúdo da tabela copiado para a área de transferência')
                                              }
                                              
                                            else {
                                              alert('Tabela vazia')
                                            }
                                          }
                                        }
                                        ><FiCopy />Copiar</button>
                                      <button
                                          className='buttonTableThead'
                                          onClick={() => {
                                              performanceExport();
                                      }}><FiArrowUp />Exportar</button>
                                      <button
                                          className='buttonTableThead'
                                          onClick={() => {
                                            confirmRealizarGiro();
                                      }}><MdAutorenew />Realizar giro</button>
                                      {/* <button
                                          className='buttonTableThead'
                                          onClick={() => {
                                            generateEmail();
                                      }}><BiMailSend />Gerar email</button> */}
                                      <input placeholder='Filtrar' onChange={(e) => setFilter(e.target.value)} type="text" />
                                    </div>
                                </div>
                            </th>
                        </tr>
                        <tr>
                          {activeControllBackofficeTable.length > 0 ?
                            <>
                              <th>Ativo</th>
                              <th>Nome</th>
                              <th>Setor</th>
                              <th>Peso</th>
                              <th>Preço atual</th>
                              <th>Status</th>
                              <th>Excluir</th>
                            </> 
                            : 
                            <th>Sem informações</th>
                          }
                        </tr>
                    </thead>
                    <tbody>
                      {loading ?
                        <tr>
                          <td id='reportLoadingTd' colSpan='7'><ArrowLoader /></td>
                        </tr>
                        :
                        filteredTable()?.map(row => {
                          return (
                            <tr>
                              <td onClick={(e) => {editAsset(row)}}>{row.asset}</td>
                              <td onClick={(e) => {editAsset(row)}}>{row.name}</td>
                              <td onClick={(e) => {editAsset(row)}}>{row.sector}</td>
                              <td onClick={(e) => {editAsset(row)}}>{row.weight}%</td>
                              <td onClick={(e) => {editAsset(row)}}>{toBRL(row.value)}</td>
                              <td title="Clique para trocar o status de um ativo." style={{cursor: "pointer"}} onClick={(e) => {
                                changeIgnorable(row.id)
                              }}>{row.ignorable ? "Inativo" : "Ativo"}</td>
                              <td title="Clique para excluir um ativo da lista." style={{cursor: "pointer"}} onClick={(e) => {
                                setShow(true);
                                setType({
                                  name: "alertMsg",
                                  value: {
                                    message: "Deseja realmente excluir o ativo da lista?",
                                    func: deleteRow,
                                    dataForFunc: row,
                                  },
                                })}}><FiTrash2 /></td>
                                
                            </tr>
                          )
                          
                        })
                      }
                    </tbody>
                </table>
            </div>
        </div>
        <div className='containerAddNewAsset'>
          <div className='inputTextTitle'>Ativo</div>
          {/* <div className='inputTextTitle'>Nome</div>
          <div className='inputTextTitle'>Setor</div> */}
          <div className='inputTextTitle'>Peso (%)</div>
          <div className='inputTextTitle'>Preço atual</div>
          <button className='createRow' onClick={(e) => addNewAsset()}>Inserir ativo</button>
          <Autocomplete
            options={assets}
            onChange={(event, newValue) => {
              setAtivo(newValue);
            }}
            value={ativo}
            defaultValue={ativo}
            getOptionLabel={(option) => option.asset}
            id='assetOptions'
            className='selectAsset'
            renderInput={(params) => <TextField
                label=''
                onKeyPress={(e) => { if (e.code === 'Backquote') e.preventDefault() }}
                {...params} variant="outlined" size="small" />}
          />
          <TextField type='number' inputProps={{ min: 0, max: 100 }} onKeyPress={(e) => { if (e.code === 'Backquote') e.preventDefault() }} step='0.00' onKeyDown={(e) => { if (e.key === 'e' || e.key === 'E') { e.preventDefault() } }} value={peso} className="currencyTextFieldCustom" variant='outlined' onChange={(e) => {
                if (parseInt(e.target.value) <= 100 || e.target.value == 0) {
                    setPeso(e.target.value)
                }
            }} />
          <label style={{width: "100%", textAlign: "center"}}>
            <CurrencyTextField
                variant="outlined"
                value={precoAtual}
                className='currencyTextFieldCustom'
                currencySymbol="R$"
                outputFormat="number"
                decimalCharacter=","
                required={true}
                digitGroupSeparator="."
                onChange={(event, value) => {
                    if (value === '' || value === 0) {
                        setPrecoAtual(0)
                    } else {
                        setPrecoAtual(value);
                    }
                }}
            />
          </label>
          <div></div>
        </div>
        </>
      }
    </div>
  );
}