/*Tela de usuários
/*Descrição: Tela onde é centralizado todos os usuários do sistema
/*Desenvilvido por: Equipe Rafael Cezário
*/

import React, { useState, useEffect } from "react";
import { TextField } from "@material-ui/core";
//hook
import { useModal } from "../../../../contexts/useModal";
import { useAuth } from "../../../../contexts/useAuth";
//services
import { api } from "../../../../services/api";
//utils
import { validPassword } from "../../../../utils/validatorSchemas/passwordComplexity";
import { titleize } from "../../../../utils/formatNumberAndText/formatTextUpperFirstLetter";
//components
import Modal from "../../../../components/modal/modal";
import { UsersCadastration } from "./users.cadastration";
import { UsersInactiveTable } from "./users.inactive.table";
import { UsersActiveTable } from "./users.active.table";
import { BrokersAdvisor } from "./brokersAdvisor.table";
import { BrokerLeader } from "./brokerLeader.table";
import { ToForClients } from "./users.toForClients";
import { UserEditBox } from "./users.editBox";
import { BrokersAdvisorEditBox } from "./brokersAdvisors.editBox";
import { BrokerLeaderEditBox } from "./brokerLeader.editBox";
import { BrokerLeaderMirroringsEdit } from "./brokerLeaderMirrorings";

import { ArrowLoader } from "../../../../components/loaders/arrowLoader";
import { ConfirmationBox } from "../../../../components/confirmationBox/confirmationBox";
import { styleClientsInput } from "../../../../components/styles/autocompletField";
//styles
import "./styles/users.css";

export function Users() {
    const { show, setShow, type } = useModal();
    const { user, tokenKey } = useAuth();
    const [tabActive, setTabActive] = useState(1);
    const [newPass, setNewPass] = useState("");
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [oldPass, setOldPass] = useState("0");
    const [newConfirmPass, setNewConfirmPass] = useState("");
    const classes = styleClientsInput();
    const userIdForPassChange = sessionStorage.getItem("@userIdForChange");
    const userNameForPassChange = sessionStorage.getItem("@userName");
    const token = localStorage.getItem("@auth:token");

    useEffect(() => {
        setNewPass("");
        setOldPass("");
        setNewConfirmPass("");
        setSuccess(false);
    }, [show]);

    async function handleNewPassSubmit(e, typeOldPass) {
        e.preventDefault();

        if (user.external_id === userIdForPassChange) {
            if (oldPass === "0") {
                alert("Digite a senha antiga");
                return;
            }
        }
        if (newPass !== newConfirmPass) {
            alert("A confirmação da senha não confere");
            return;
        }

        if (!validPassword(newPass)) {
            return;
        }

        setLoading(true);
        try {
            const passResult = await api.put(
                `/api/v1/users/${userIdForPassChange}/resetPassword`,
                {
                    old_password: typeOldPass === "0" ? btoa("0") : btoa(oldPass),
                    new_password: btoa(newPass),
                },
                {
                    headers: {
                        authorization: `Bearer ${token}`,
                    },
                }
            );

            setSuccess(true);
            setLoading(false);
        } catch (error) {
            if (
                error.response.data.message ==
                `Erro tentar alterar senha do usuário com external_id '${userIdForPassChange}', a senha pode estar errada.`
            ) {
                alert("A senha antiga não coincide com a senha digitada!");
                setLoading(false);
                return;
            }
        }
    }
    return (
        <div className="usersContainer" style={{ minWidth: !show ? "1000px" : "0" }}>
            <Modal>
                {type.name === "usersCadastration" ? (
                    <UsersCadastration />
                ) : type.name === "linkNewUser" ? (
                    <ToForClients userData={type} />
                ) : type.name === "alertMsg" ? (
                    <ConfirmationBox msgData={type} />
                ) : type.name === "userEditModal" ? (
                    <UserEditBox
                        userData={{
                            external_id: type?.external_id,
                            full_name: type?.full_name,
                            type_access: type?.type_access,
                            total_clients: type?.total_clients,
                            email: type?.email === null ? "" : type?.email,
                            branche: type?.branche,
                            type_description: type?.type_description,
                            profile_description: type?.profile_description,
                            profile_id: type?.profile_id,
                        }}
                    />
                ) : type.name === "userChangePass" && loading ? (
                    <div className="usersChangePassContainer">
                        <div className="usersLoading"></div>
                        <div className="usersLoading">
                            <ArrowLoader />
                        </div>
                    </div>
                ) : type.name === "userChangePass" && success ? (
                    <div className="usersChangePassContainer">
                        <div className="usersChangePassHeader">
                            <a></a>
                            <button className="modalCloseButton" onClick={() => setShow(false)}>
                                X
                            </button>
                        </div>
                        <div className="usersSuccessPassword">
                            <h2 style={{ margin: "0" }}>Senha alterada com sucesso!</h2>
                            <button className="buttonWhiteBg" onClick={() => setShow(false)}>
                                Fechar
                            </button>
                        </div>
                    </div>
                ) : type.name === "userChangePass" && parseInt(user.external_id) === parseInt(userIdForPassChange) ? (
                    <div className="usersChangePassContainer">
                        <div className="usersChangePassHeader">
                            <p>Alteração de senha</p>
                            <button onClick={() => setShow(false)}>X</button>
                        </div>
                        <form action="submit">
                            <label>
                                <a>
                                    Requisitos mínimos: Conter 8 letras ou mais, conter 1 ocorrência de cada: Letra maiúscula,
                                    letra minúscula, número e caracter especial.
                                </a>
                            </label>
                            <a>Usuário: {titleize(userNameForPassChange)}</a>
                            <label>
                                <TextField
                                    type="password"
                                    label="Senha antiga"
                                    variant="outlined"
                                    value={oldPass}
                                    className={classes.root}
                                    onChange={(e) => setOldPass(e.target.value)}
                                />
                            </label>
                            <label>
                                <TextField
                                    type="password"
                                    label="Nova senha"
                                    variant="outlined"
                                    value={newPass}
                                    className={classes.root}
                                    onChange={(e) => setNewPass(e.target.value)}
                                />
                            </label>
                            <label>
                                <TextField
                                    type="password"
                                    label="Confirmação de senha"
                                    variant="outlined"
                                    className={classes.root}
                                    onChange={(e) => setNewConfirmPass(e.target.value)}
                                />
                            </label>
                            <button className="buttonWhiteBg" type="submit" onClick={(e) => handleNewPassSubmit(e)}>
                                Alterar
                            </button>
                        </form>
                    </div>
                ) : type.name === "userChangePass" ? (
                    <div className="usersChangePassContainer">
                        <div className="usersChangePassHeader">
                            <p>Alteração de senha</p>
                            <button onClick={() => setShow(false)}>X</button>
                        </div>
                        <form action="submit">
                            <label>
                                <a>
                                    Requisitos mínimos: conter 8 letras ou mais, conter 1 ocorrência de cada: letra maiúscula,
                                    letra minúscula, número e caracter especial.
                                </a>
                            </label>
                            <a>Usuário: {titleize(userNameForPassChange)}</a>
                            <label>
                                <TextField
                                    type="password"
                                    label="Nova senha"
                                    variant="outlined"
                                    className={classes.root}
                                    onChange={(e) => setNewPass(e.target.value)}
                                />
                            </label>
                            <label>
                                <TextField
                                    type="password"
                                    label="Confirmação de senha"
                                    variant="outlined"
                                    className={classes.root} // value={companyName}
                                    onChange={(e) => setNewConfirmPass(e.target.value)}
                                />
                            </label>
                            <button
                                className="buttonWhiteBg"
                                type="submit"
                                onClick={(e) => {
                                    handleNewPassSubmit(e, "0");
                                }}
                            >
                                Alterar
                            </button>
                        </form>
                    </div>
                ) : type.name === "successUserRegister" ? (
                    <ConfirmationBox msgData={type} />
                ) : type.name === "advisorBrokersEdit" ? (
                    <BrokersAdvisorEditBox
                        userData={{
                            data: type
                        }}
                    />
                ) : type.name === "brokerLeaderEdit" ? (
                    <BrokerLeaderEditBox
                        userData={{
                            data: type
                        }}
                    />
                ) : type.name === "brokerLeaderMirroringsEdit" ? (
                    <BrokerLeaderMirroringsEdit
                        userData={{
                            data: type
                        }}
                    />
                ) : (
                    <></>
                )}
            </Modal>
            <div className="usersBody">
                <div className="usersTab">
                    <div
                        className={tabActive === 1 ? "usersTabOption" : "usersInactiveTabOption"}
                        onClick={() => setTabActive(1)}
                    >
                        <a>Ativos</a>
                        <div></div>
                    </div>
                    <div
                        className={tabActive === 2 ? "usersTabOption" : "usersInactiveTabOption"}
                        onClick={() => setTabActive(2)}
                    >
                        <a>Inativo</a>
                        <div></div>
                    </div>

                    <div
                        className={tabActive === 3 ? "usersTabOption" : "usersInactiveTabOption"}
                        onClick={() => setTabActive(3)}
                    >
                        <a>Brokers por Assessor</a>
                        <div></div>
                    </div>

                    <div
                        className={tabActive === 4 ? "usersTabOption" : "usersInactiveTabOption"}
                        onClick={() => setTabActive(4)}
                    >
                        <a>Lideranças</a>
                        <div></div>
                    </div>
                </div>
                <div className="usersTable">
                    {tabActive === 1 ? <UsersActiveTable /> : tabActive === 2 ? <UsersInactiveTable /> : tabActive === 3 ? <BrokersAdvisor /> : <BrokerLeader/>}
                </div>
            </div>
        </div>
    );
}
