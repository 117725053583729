import React from "react";
/*Styles*/
import "./mainStyle.css";
/*Components*/
import Modal from "../../../components/modal/modal";
import { ConfirmationBox } from "../../../components/confirmationBox/confirmationBox";
// import { WisirTrackingTable } from "./tables/wisirTracking.table";
// import { ClientRecord } from "./modalWisir/componentsModalWisir/clientRecord";
/*Contexts*/
import { useModal } from "../../../contexts/useModal";
import { ManagementTable } from "./management.table";
import { AbcManagementImportModal } from "./modalAbc/abcManagement.import";
import { AbcManagementCreateModal } from "./modalAbc/abcManagement.create";
import { AbcManagementUpdateModal } from "./modalAbc/abcManagement.update";
// import {ImportMovimentation} from './modalWisir/componentsModalWisir/importMovimentation';

export function Management() {
    const { type } = useModal();
  
    return (
      <div className="abcMainTableAbc">
        <Modal>
          {
            type.name === "create" ? (
              <AbcManagementCreateModal prop={type} />
            ) : 
            type.name === "update" ? (
              <AbcManagementUpdateModal prop={type} />
            ) : 
            type.name === "import" ? (
              <AbcManagementImportModal prop={type} />
            ) : 
            type.name === 'alertMsg' ? (
                <ConfirmationBox msgData={type} />
            ) : (
              <></>
            )
          }
          <></>
        </Modal>
        <ManagementTable prop={type} />
      </div>
    );
  }