import React, { useEffect, useState } from "react"
/*Contexts*/
import { useAuth } from "../../../contexts/useAuth"
import { useModal } from "../../../contexts/useModal"
/*Services*/
import { FiArrowRight } from "react-icons/fi"

import { api } from "../../../services/api"
/*Components*/
// import { ArrowLoader } from "../../../../components/loaders/arrowLoader";
/*Utils*/
import { toBRL } from "../../../utils/formatNumberAndText/toBRLCurrency"
import { titleize } from "../../../utils/formatNumberAndText/formatTextUpperFirstLetter"
import { MdOutlineMonetizationOn, MdOutlineCheckCircleOutline, MdOutbox, MdContentCopy } from "react-icons/md"
import { BsWhatsapp, BsTelephone, BsPeople, BsChatSquareText, BsCpu, BsChatDots, BsCardList, BsBoxArrowUpLeft, BsPersonPlus } from "react-icons/bs"
import { AiOutlineMail, AiOutlineSend } from "react-icons/ai"
import { BsSend } from "react-icons/bs"
import { FaCheck } from "react-icons/fa"
import { updateABCStatus } from "../../../services/asyncFunctions"
import { makeStyles } from "@material-ui/core/styles"
import { ArrowLoader } from "../../../components/loaders/arrowLoader"
import { AiFillCloseCircle } from "react-icons/ai"
import CurrencyTextField from "@unicef/material-ui-currency-textfield"

import "./styles/dashboardAdvisorClientMenuModal.css"
import { styleClientsInput } from "../../../components/styles/autocompletField"
import { FormControlLabel, FormGroup, Menu, Radio, RadioGroup } from "@mui/material"

export function DashboardAdvisorClientMenu(props) {
    // console.log("MENU", props)
    const { tokenKey, user } = useAuth()
    const { show, setShow, type, setType, edited, setEdited } = useModal()
    const [operationId, setOperationId] = useState(null)
    const [categoryInfos, setCategoryInfos] = useState(null)
    const product = React.useRef(null)
    const [filters, setFilters] = useState([])
    const [items, setItems] = useState([])
    const [allItems, setAllItems] = useState([])
    const [filteredItems, setFilteredItems] = useState([])
    const [itemQty, setItemQty] = useState(30)
    const [loading, setLoading] = useState(true)
    const [loadingTimeline, setLoadingTimeline] = useState(false)
    const [copyingDescription, setCopyingDescription] = useState(false)
    const [copyingLink, setCopyingLink] = useState([])
    const [client_speech, setClientSpeech] = useState("")
    const [newStatus, setNewStatus] = useState(-1)
    const [refuseReason, setRefusalReason] = useState("")
    const [currentStatus, setCurretnStatus] = useState()

    /* TESTE */
    /* const [selectedNaoAderente, setSelectedNaoAderente] = useState(false)
    const [selectedRecuse, setSelectedRecuse] = useState(false)
    const [selectedAbordado, setSelectedAbordado] = useState(false)

    function handleSelectedNaoAderente() {
        setSelectedNaoAderente(!selectedNaoAderente);
        setSelectedRecuse(false);
        setSelectedAbordado(false);
    } */

    /* FIM TESTE */
    const [link, setLink] = useState({
        boletar: "#/",
        acompanhamento: "#/",
    })
    const [contactType, setContactType] = useState(5)
    const filterProduct = React.useRef(true)
    const [typeAbc, setTypeAbc] = useState(0) //tipo de produto pode ser RV ou de asssessor (por hora)
    const [comentTimeline, setComentTimeline] = useState("")
    const [defaultComments, setDefaultComments] = useState({
        2: { comment: `Feito contato com o cliente sobre o produto ` + props?.prop?.product?.description + `. Cliente ficou de avaliar a sugestão.` },
        6: { comment: `Feito contato com o cliente sobre o produto ` + props?.prop?.product?.description + `. Cliente não teve interesse na oferta e recusou o produto.` },
        7: { comment: `Cliente não possui perfil para o produto ` + props?.prop?.product?.description + `.` },
    })
    const [activeRefusalReasonBox, setActiveRefusalReasonBox] = useState(false)
    const [selectedReason, setSelectedReason] = useState("")
    const [refusalReasons, setRefusalResasons] = useState([
        {
            id: "recurso",
            label: "Falta de recurso p/ alocar",
            comment: `Abordado o investidor sobre a oportunidade ${props?.prop?.product?.description}, porém o cliente está sem recurso disponível.`,
        },
        { id: "prazo", label: "Prazo do produto", comment: `Abordado o investidor sobre a oportunidade ${props?.prop?.product?.description}, porém o cliente não aceitou o prazo do produto.` },
        {
            id: "cenario",
            label: "Cenário Adverso",
            comment: `Abordado o investidor sobre a oportunidade ${props?.prop?.product?.description}, porém o cliente não aceitou o produto por receio com o cenário macro atual.`,
        },
        {
            id: "preferencia",
            label: "Prefere outro produto",
            comment: `Abordado o investidor sobre a oportunidade ${props?.prop?.product?.description}, porém o cliente não aceitou o produto por preferir outras classes de ativos no momento.`,
        },
        {
            id: "taxa",
            label: "Taxa não atrativa",
            comment: `Abordado o investidor sobre a oportunidade ${props?.prop?.product?.description}, porém o cliente não aceitou o produto por não achar atrativa a taxa do ativo.`,
        },
        { id: "outros", label: "Outros", comment: `Escreva o motivo!` },
    ])
    const [boletar, setBoletar] = useState(false)
    const [boletarValue, setBoletarValue] = useState(0)
    const classes = styleClientsInput()

    const useStyles = makeStyles((theme) => ({
        timelineNewCommentContactTypeActive: {
            width: "2rem",
            height: "2rem",
            backgroundColor: "#c49e17",
            // backgroundColor: contactType == 5 ? "#EADDCA" : contactType == 1 ? "#A4F097" : contactType == 2 ? "#CBE6FF" : contactType == 3 ? "#FFC8C8" : "#FEFFBC",
            color: "white",
            // color: contactType == 5 ? "#412500" : contactType == 1 ? "#165102" : contactType == 2 ? "#001D82" : contactType == 3 ? "#A90000" : "#BA9C00",
            display: "grid",
            padding: "0.2rem",
            paddingTop: "0.15rem",
            borderRadius: "8px",
            transition: "all ease-in-out 0.2s",
            marginLeft: "0.4rem",
            marginRight: "0.4rem",
            cursor: "pointer",
        },
        timelineNewCommentContactTypeInactive: {
            width: "2rem",
            height: "2rem",
            backgroundColor: "#d3d3d3",
            display: "grid",
            padding: "0.2rem",
            paddingTop: "0.15rem",
            borderRadius: "8px",
            transition: "all ease-in-out 0.2s",
            cursor: "pointer",
            marginLeft: "0.4rem",
            marginRight: "0.4rem",
            color: "white",
            "&:hover": {
                backgroundColor: "#b8b8b8",
            },
        },
        timelineFilterType1Active: {
            width: "1.5rem",
            height: "1.5rem",
            backgroundColor: "#c49e17",
            color: "white",
            display: "grid",
            padding: "0.2rem",
            paddingTop: "0.15rem",
            borderRadius: "8px",
            transition: "all ease-in-out 0.2s",
            marginLeft: "0.2rem",
            marginRight: "0.2rem",
            cursor: "pointer",
        },
        timelineFilterType2Active: {
            width: "1.5rem",
            height: "1.5rem",
            backgroundColor: "#c49e17",
            color: "white",
            display: "grid",
            padding: "0.2rem",
            paddingTop: "0.15rem",
            borderRadius: "8px",
            transition: "all ease-in-out 0.2s",
            marginLeft: "0.2rem",
            marginRight: "0.2rem",
            cursor: "pointer",
        },
        timelineFilterType3Active: {
            width: "1.5rem",
            height: "1.5rem",
            backgroundColor: "#c49e17",
            color: "white",
            display: "grid",
            padding: "0.2rem",
            paddingTop: "0.15rem",
            borderRadius: "8px",
            transition: "all ease-in-out 0.2s",
            marginLeft: "0.2rem",
            marginRight: "0.2rem",
            cursor: "pointer",
        },
        timelineFilterType4Active: {
            width: "1.5rem",
            height: "1.5rem",
            backgroundColor: "#c49e17",
            color: "white",
            display: "grid",
            padding: "0.2rem",
            paddingTop: "0.15rem",
            borderRadius: "8px",
            transition: "all ease-in-out 0.2s",
            marginLeft: "0.2rem",
            marginRight: "0.2rem",
            cursor: "pointer",
        },
        timelineFilterType5Active: {
            width: "1.5rem",
            height: "1.5rem",
            backgroundColor: "#c49e17",
            color: "white",
            display: "grid",
            padding: "0.2rem",
            paddingTop: "0.15rem",
            borderRadius: "8px",
            transition: "all ease-in-out 0.2s",
            marginLeft: "0.2rem",
            marginRight: "0.2rem",
            cursor: "pointer",
        },
        timelineFilterType0Active: {
            width: "1.5rem",
            height: "1.5rem",
            backgroundColor: "#c49e17",
            color: "white",
            display: "grid",
            padding: "0.2rem",
            paddingTop: "0.15rem",
            borderRadius: "8px",
            transition: "all ease-in-out 0.2s",
            marginLeft: "0.2rem",
            marginRight: "0.2rem",
            cursor: "pointer",
        },
        timelineFilterType6Active: {
            width: "1.5rem",
            height: "1.5rem",
            backgroundColor: "#c49e17",
            color: "white",
            display: "grid",
            padding: "0.2rem",
            paddingTop: "0.15rem",
            borderRadius: "8px",
            transition: "all ease-in-out 0.2s",
            marginLeft: "0.2rem",
            marginRight: "0.2rem",
            cursor: "pointer",
        },
        timelineFilterTypeInactive: {
            width: "1.5rem",
            height: "1.5rem",
            backgroundColor: "#d3d3d3",
            color: "white",
            display: "grid",
            padding: "0.2rem",
            paddingTop: "0.15rem",
            borderRadius: "8px",
            transition: "all ease-in-out 0.2s",
            cursor: "pointer",
            marginLeft: "0.2rem",
            marginRight: "0.2rem",
            "&:hover": {
                backgroundColor: "#b8b8b8",
            },
        },
        timelineFilterTypeProduct: {
            width: "fit-content",
            height: "1.5rem",
            backgroundColor: "#c49e17",
            color: "white",
            display: "grid",
            padding: "0.2rem",
            paddingLeft: "1rem",
            paddingRight: "1rem",
            borderRadius: "8px",
            transition: "all ease-in-out 0.2s",
            marginLeft: "0.2rem",
            marginRight: "0.2rem",
            cursor: "pointer",
        },
        timelineFilterTypeProductInactive: {
            color: "white",
            width: "fit-content",
            height: "1.5rem",
            backgroundColor: "#d3d3d3",
            display: "grid",
            padding: "0.2rem",
            paddingLeft: "1rem",
            paddingRight: "1rem",
            borderRadius: "8px",
            transition: "all ease-in-out 0.2s",
            cursor: "pointer",
            marginLeft: "0.2rem",
            marginRight: "0.2rem",
            "&:hover": {
                backgroundColor: "#b8b8b8",
            },
        },
        timelineNewComentWriteArea: {
            paddingRight: "3rem",
            resize: "none",
            borderRadius: "10px",
            border: "1px solid #dadada",
            backgroundColor: "#f8f8f8",
            padding: "0.5rem",
            fontSize: "1rem",
            // width: "calc(100% - 2rem)",
            "&:focus": {
                outline: "none",
                border: "#c49e17 2px solid",
                // contactType == 5
                //     ? "2px solid #412500"
                //     : contactType == 1
                //     ? "2px solid #165102"
                //     : contactType == 2
                //     ? "2px solid #001D82"
                //     : contactType == 3
                //     ? "2px solid #A90000"
                //     : "2px solid #BA9C00",
            },
        },
        timelineNewCommentSendActive: {
            width: "3rem",
            height: "3rem",
            // backgroundColor: contactType == 5 ? "#d3d3d3" : contactType == 1 ? "#A4F097" : contactType == 2 ? "#CBE6FF" : contactType == 3 ? "#FFC8C8" : "#FEFFBC",
            // color: contactType == 5 ? "#412500" : contactType == 1 ? "#165102" : contactType == 2 ? "#001D82" : contactType == 3 ? "#A90000" : "#BA9C00",
            backgroundColor: "transparent",
            color: "black",
            display: "grid",
            padding: "0.55rem",
            paddingLeft: "0.65rem",
            borderRadius: "8px",
            transition: "all ease-in-out 0.2s",
            marginTop: "auto",
            // transform: 'rotate(-55deg)',
            marginLeft: "-3.4rem",
            marginBottom: "0.15rem",
            border: "none",
            "&:hover": {
                backgroundColor: contactType == 5 ? "#b8b8b8" : contactType == 1 ? "#c5ffbb" : contactType == 2 ? "#e9f4ff" : contactType == 3 ? "#ffdbdb" : "#feffd3",
                boxShadow:
                    contactType == 5
                        ? "0px 0px 4px #b8b8b8"
                        : contactType == 1
                        ? "0px 0px 4px #165102"
                        : contactType == 2
                        ? "0px 0px 4px #001D82"
                        : contactType == 3
                        ? "0px 0px 4px #A90000"
                        : "0px 0px 4px #BA9C00",
            },
        },
        timelineNewCommentSendInative: {
            width: "3rem",
            height: "3rem",
            backgroundColor: "transparent",
            color: "#b8b8b8",
            display: "grid",
            padding: "0.55rem",
            paddingLeft: "0.65rem",
            // transform: 'rotate(-55deg)',
            borderRadius: "8px",
            transition: "all ease-in-out 0.2s",
            marginTop: "auto",
            marginLeft: "-3.4rem",
            marginBottom: "0.15rem",
            border: "none",
            cursor: "default",
        },
        timelineComentBoxUserComentName: {
            width: "3rem",
            height: "3rem",
            backgroundColor: "#d3d3d3",
            color: "#b8b8b8",
            display: "grid",
            padding: "0.55rem",
            paddingLeft: "0.65rem",
            borderRadius: "8px",
            transition: "all ease-in-out 0.2s",
            marginTop: "auto",
            marginRight: "0.4rem",
            marginBottom: "0.15rem",
            border: "none",
            cursor: "default",
        },
    }))

    const classesContactType = useStyles()

    function getOperationId() {
        const prod_name = props.prop.product.id //antes era .name
        const operation_id = props.prop.client[`c${prod_name}__operation_id`]
        setOperationId(operation_id)
    }

    function getCategory() {
        const prod_name = props.prop.product.id
        const cat_id = props.prop.client[`c${prod_name}__category_id`]
        const cat = props.prop.product.categories.filter((x) => x.id == cat_id)
        // const cat = [{link: 'www.google.com', title: 'teste', description: 'teste', icon: 'teste'}]
        setCategoryInfos(cat[0])

        var aux = []
        let linkSplited = cat[0]?.link?.split(" ")
        linkSplited?.forEach(() => {
            aux.push(false)
        })
        setCopyingLink(aux)
    }

    function getSpeech() {
        const prod_name = props.prop.product.id
        const client_speech = props.prop.client[`c${prod_name}__speech`]
        setClientSpeech(client_speech)
    }

    // function getLink() {
    //     const prod_type = props.prop.product.type.id;
    //     if (prod_type == 1 || prod_type == 3) {
    //         // ESTRUTURADAS E IMPORTADAS
    //         setLink({
    //             boletar: "#/books",
    //             acompanhamento: user.type < 3 ? "#/clients" : "#/clientesMesa",
    //         });
    //     } else if (prod_type == 2) {
    //         // OFERTA PÚBLICA
    //         setLink({
    //             boletar: "#/fiis",
    //             acompanhamento: user.type < 3 ? "#/clients" : "#/clientesMesa",
    //         });
    //     }
    // }

    async function updateStatus(status_id) {
        setLoading(true)
        setNewStatus(status_id)
        setComentTimeline(defaultComments[status_id].comment)

        // try {
        //     const res = await updateABCStatus(tokenKey, operationId, status_id);
        //     // console.log(res?.data?.data);
        //     setType({ name: 'alertMsg', type: 'information', value: { message: `Status alterado com sucesso.` } })
        //     setEdited(true)
        // } catch (error) {
        //     setLoading(false);
        //     setType({ name: "alertMsg", type: "information", value: { message: error?.response?.data?.message == undefined ? "Erro interno ao carregar os dados" : error?.response?.data?.message } });
        //     setShow(true);
        // }
        // setLoading(false);
    }

    async function sendNewStatus() {
        if (newStatus < 0) {
            return
        }
        try {
            const res = await updateABCStatus(tokenKey, operationId, newStatus)
            // console.log(res?.data?.data);
            // setType({ name: 'alertMsg', type: 'information', value: { message: `Status alterado com sucesso.` } })
            // setEdited(true)
            alert("Status alterado com sucesso!")
            if (newStatus == 6) {
                setCurretnStatus("Recusado")
            } else if (newStatus == 7) {
                setCurretnStatus("Não aderente")
            } else if (newStatus == 2) {
                setCurretnStatus("Abordado")
            }

            //atualiza o status do cliente no objeto
            props.prop.client[`c${props.prop.product.id}__status`] = newStatus == 6 ? "Recusado" : newStatus == 7 ? "Não aderente" : newStatus == 2 ? "Abordado" : "Aceito"


            setNewStatus(-1)
        } catch (error) {
            setLoading(false)
            setType({
                name: "alertMsg",
                type: "information",
                value: { message: error?.response?.data?.message == undefined ? "Erro interno ao atualizar o status do cliente" : error?.response?.data?.message },
            })
            setShow(true)
        }
        setLoading(false)
    }

    async function submitBoleta() {
        setLoading(true)
        try {
            const res = await api.put(
                `/api/v1/alocacao/value/${props.prop.client.client_id}`,
                {
                    operation_id: operationId,
                    boletarValue: boletarValue,
                    abc_products_id: props.prop.product.id,
                },
                {
                    headers: {
                        authorization: `Bearer ${tokenKey}`,
                    },
                }
            )
            // console.log(res?.data?.data);
            // setType({ name: "alertMsg", type: "information", value: { message: `Boleta enviada com sucesso.` } })
            // setEdited(true)
            alert("Status alterado com sucesso! Lembre-se que a boleta deve ser enviada via HUB")
            setCurretnStatus('Aceito')

            setBoletar(false)
            setBoletarValue(0)
            props.prop.client[`c${props.prop.product.id}__status`] = "Aceito"
            props.prop.client[`c${props.prop.product.id}__value`] = boletarValue

        } catch (error) {
            setLoading(false)
            setType({
                name: "alertMsg",
                type: "information",
                value: { message: error?.response?.data?.message == undefined ? "Erro interno ao enviar a boleta" : error?.response?.data?.message },
            })
            setShow(true)
        }
        setLoading(false)
    }

    useEffect(() => {
        if (show) {
            setTypeAbc(props.prop.product.type.id)
            product.current = props.prop.product
            setItems([])
            setFilteredItems([])
            getOperationId()
            getCategory()
            getSpeech()

            const productId = props.prop.product.id
            const status = props.prop.client[`c${productId}__status`]
            setCurretnStatus(status)
            // getLink();
            getTimeline()
            setDefaultComments({
                2: { comment: `Feito contato com o cliente sobre o produto ` + props?.prop?.product?.description + `. Cliente ficou de avaliar a sugestão.` },
                3: { comment: `Feito o envio de ordem do produto ` + props?.prop?.product?.description + ` para o cliente.` },
                6: { comment: `Feito contato com o cliente sobre o produto ` + props?.prop?.product?.description + `. Cliente não teve interesse na oferta e recusou o produto.` },
                7: { comment: `Cliente não possui perfil para o produto ` + props?.prop?.product?.description + `.` },
            })
            setRefusalResasons([
                {
                    id: "recurso",
                    label: "Falta de recurso p/ alocar",
                    comment: `Abordado o investidor sobre a oportunidade ${props?.prop?.product?.description}, porém o cliente está sem recurso disponível.`,
                },
                { id: "prazo", label: "Prazo do produto", comment: `Abordado o investidor sobre a oportunidade ${props?.prop?.product?.description}, porém o cliente não aceitou o prazo do produto.` },
                {
                    id: "cenario",
                    label: "Cenário Adverso",
                    comment: `Abordado o investidor sobre a oportunidade ${props?.prop?.product?.description}, porém o cliente não aceitou o produto por receio com o cenário macro atual.`,
                },
                {
                    id: "preferencia",
                    label: "Prefere outro produto",
                    comment: `Abordado o investidor sobre a oportunidade ${props?.prop?.product?.description}, porém o cliente não aceitou o produto por preferir outras classes de ativos no momento.`,
                },
                {
                    id: "taxa",
                    label: "Taxa não atrativa",
                    comment: `Abordado o investidor sobre a oportunidade ${props?.prop?.product?.description}, porém o cliente não aceitou o produto por não achar atrativa a taxa do ativo.`,
                },
                { id: "outros", label: "Outros", comment: `Escreva o motivo!` },
            ])
        }
    }, [show])

    function disableCopying() {
        setCopyingDescription(false)
        var aux = copyingLink.map((l) => false)
        setCopyingLink(aux)
    }

    function clear() {
        setLink({
            boletar: "#/",
            acompanhamento: "#/",
        })
        setComentTimeline("")
        setContactType(5)
        setFilters([])
        setItems([])
        setFilteredItems([])
        setClientSpeech("")
        setNewStatus(-1)
        setActiveRefusalReasonBox(false)
        setSelectedReason("")
        product.current = null
    }

    async function handleSubmitComment() {
        if (comentTimeline !== null && comentTimeline != "") {
            //se status recusado add o motivo da recusa antes do texto
            var newMessage = ""
            if (newStatus == 6) {
                const reason = refusalReasons.filter((r) => r.id == selectedReason)
                newMessage = `${comentTimeline}`
            }
            try {
                const clientUpdate = await api.post(
                    `/api/v1/vba/sendComment/${props.prop.client.client_id}?abc_products_id=${props.prop.product.id}`,
                    {
                        comment: newStatus == 6 ? newMessage.replaceAll("'", "''") : comentTimeline.replaceAll("'", "''"),
                        commentType: contactType,
                    },
                    {
                        headers: {
                            authorization: `Bearer ${tokenKey}`,
                            // abc_products_id: props.prop.product.id,
                        },
                    }
                )
                setComentTimeline("")
                setContactType(5)
                getTimeline()
                if (newStatus >= 0) {
                    sendNewStatus()
                }
                // setType({ name: 'alertMsg', type: 'information', value: { message: `Comentário cadastrado.` } })
            } catch (error) {
                alert(error?.response?.data?.message)
            }
        } else {
            alert("O comentário não pode ser vazio")
        }
    }

    async function filterTimeline(all) {
        var todosItems = all ? all : structuredClone(allItems)
        var filteredItemsAux = []
        if (filters.length > 0) {
            filteredItemsAux = todosItems.filter((e) => filters.indexOf(parseInt(e.type)) >= 0)
        } else {
            filteredItemsAux = todosItems
        }
        if (filterProduct.current) {
            filteredItemsAux = filteredItemsAux.filter((e) => e.abc_products_id == product.current.id)
        }

        await setFilteredItems(filteredItemsAux)
        await setItems(filteredItemsAux.length - 30 < 0 ? filteredItemsAux : filteredItemsAux.slice(filteredItemsAux.length - 30))
        scrollBottom()
    }

    async function getTimeline() {
        await setLoadingTimeline(true)
        try {
            const timeline = await api.get(`/api/v1/vba/clientTimeline/${props?.prop?.client?.client_id}`, {
                headers: {
                    authorization: `Bearer ${tokenKey}`,
                    abc_products_id: props?.prop?.product?.id,
                },
            })

            await setAllItems(timeline?.data?.data)
            // await setFilteredItems(timeline?.data?.data);
            await filterTimeline(timeline?.data?.data)
            // await setItems(timeline?.data?.data.length - itemQty < 0 ? timeline?.data?.data : timeline?.data?.data.slice(timeline?.data?.data.length - itemQty));

            await setLoadingTimeline(false)
            scrollBottom()
        } catch (error) {
            alert(error?.response?.data?.message)
        }
    }

    async function updateItemsList() {
        var auxAllItems = structuredClone(filteredItems)
        var objDiv = document.getElementById("timelineCommentBoxAbc")
        var scrollNext = objDiv?.scrollHeight
        if (itemQty != auxAllItems.length) {
            if (auxAllItems.length - (itemQty + 30) <= 0) {
                await setItems(auxAllItems)
                await setItemQty(auxAllItems.length)
            } else {
                await setItems(auxAllItems.slice(auxAllItems.length - (itemQty + 30)))
                await setItemQty(itemQty + 30)
            }
            if (objDiv) objDiv.scrollTop = objDiv?.scrollHeight - scrollNext
        }
    }

    async function scrollBottom() {
        if (props?.clientData?.value?.client[15] != "Prospect" && props?.clientData?.value?.client[15] != "Transferido") {
            var objDiv = document.getElementById("timelineCommentBoxAbc")
            if (objDiv) {
                objDiv.scrollTop = objDiv?.scrollHeight
            }
        }
    }

    async function showClientActivation(type) {
        // type - função
        // 1 - ativação inicial do cliente
        // 2 - vizualização do formulário de ativação já preenchido
        // 3 - vizualização e edição de formulário para transferência
        // 4 - cliente transferido, o broker precisa visualizar para ativá-lo
        setShow(true)
        setType({ name: "showVbaClientActivation", value: props?.clientData?.value?.client, type: type })
    }

    function handleRefusalReason() {
        updateStatus(6)
    }

    return (
        <div className="containerModalDashDetails" style={{ zoom: `${window.innerHeight < 575 ? 0.95 :  1}` }}>
            <div className="clientControlHeader" style={{ zoom: `${window.innerHeight > 760 ?  1  : window.innerHeight > 530 ? 0.9 :  0.8}` }}>
                <div className="editBrancheBoxHeaderDash">
                    <div className="containerProductNameDash">
                        <p>{props?.prop?.product?.description}: </p>
                        <p>
                            {props?.prop?.client?.client_id} - {props?.prop?.client?.client}
                        </p>
                    </div>
                    <div style={{ display: "grid", gridTemplateColumns: "auto auto", alignItems: "end" }}>
                        <p style={{ marginRight: "1rem" }}>{user.external_id + " - " + user.full_name} </p>
                        <button
                            onClick={() => {
                                clear()
                                setShow(false)
                            }}
                            className="modalCloseButtonDashboard"
                            style={{ marginRight: "12px", height: "36px", width: "36px" }}
                        >
                            <AiFillCloseCircle />
                        </button>
                    </div>
                </div>
            </div>
            <div className="containerClientMenuDashActionsAbrir" style={{ zoom: `${window.innerHeight > 760 ?  1  : window.innerHeight > 530 ? 0.9 :  0.8}` }}>
                <div className="containerClientMenuSubcategoryAbcActionsDash" style={{ margin: "1rem", height: `${window.innerHeight > 760 ? 'calc(100vh - 12rem)' : window.innerHeight > 530 ? 'calc(100vh - 6rem)' : 'calc(100vh - 2.3rem)'}`}}>
                    <div className="headerSubcategoryAbcActions">
                        <div className="headerSubcategoryTitleDashActions" style={{ paddingTop: "1rem" }}>
                            <div
                                style={{
                                    color: "#D4AC00",
                                    fontWeight: "bold",
                                    paddingLeft: "1rem",
                                }}
                                
                            >
                                Discurso 
                            </div>
                        </div>
                        <div></div>
                        <span
                            style={{
                                fontWeight: "bold",
                                paddingLeft: "2rem",
                                paddingTop: "1rem",
                                // marginTop: '1rem'
                                color: "#BCBCBC",
                            }}
                        >
                            {client_speech != null && client_speech != "" ? categoryInfos?.title : 'Discurso comercial do produto'}
                        </span>
                    </div>
                    <div
                        className="containerSubcategoryDescriptionAbcActionsDash"
                        style={{
                            display: "grid",
                            gridTemplateColumns: "auto 2.5rem",
                            color: "black",
                        }}
                    >
                        {client_speech != null && client_speech != "" ? <pre className="textAreaSpeechAbcDash">{client_speech}</pre> : <pre className="textAreaSpeechAbcDash">{props?.prop?.product?.vendor_speech}</pre> }
                        {(client_speech != null && client_speech != "") || (props?.prop?.product?.vendor_speech != null && props?.prop?.product?.vendor_speech != '') ? (
                            <div
                                className="containerIconCopyContentAbcActions"
                                onClick={(e) => {
                                    setCopyingDescription(true)
                                    navigator.clipboard.writeText(client_speech != null && client_speech != "" ? client_speech : props?.prop?.product?.vendor_speech)
                                    setTimeout(disableCopying, 1000)
                                    
                                }}
                            >
                                {!copyingDescription ? <MdContentCopy size={20} /> : <FaCheck size={20} className="iconCopyContentAbcActionsAtive" />}
                            </div>
                        ) : (
                            <div >
                                
                            </div>
                        )}
                        {/* <p className="descriptionTextAbc">{client_speech}</p> */}
                    </div>
                    <div className="linksBoxAbcDash">
                        {categoryInfos?.link ? (
                            categoryInfos?.link.split(" ").map((l, index) => {
                                return (
                                    <>
                                        <div key={index} className="headerSubcategoryTitleAbcActions">
                                            {l}
                                        </div>
                                        <div
                                            className="containerIconCopyContentAbcActions"
                                            onClick={async () => {
                                                let aux = copyingLink.map((v, i, a) => (i == index ? true : v))
                                                await setCopyingLink(aux)
                                                navigator.clipboard.writeText(l)
                                                setTimeout(disableCopying, 1000)
                                            }}
                                        >
                                            <div className="linkMenuModalBoxAbc" style={{ color: "black" }}>
                                                {!copyingLink[index] ? <MdContentCopy size={20} /> : <FaCheck size={20} className="iconCopyContentAbcActionsAtive" />}
                                            </div>
                                        </div>
                                    </>
                                )
                            })
                        ) : (
                            <div className="headerSubcategoryTitleAbcActions">Link Indisponível</div>
                        )}
                    </div>
                </div>

                {/* Botões só aparacem se o produto nao for re RV */}
                {typeAbc != 1 ? (
                    <>
                        {/* <div className="divisionNameProductSubcategory"></div> */}
                        <div></div>
                        <div className="containerClientMenuOptionsAbcActionsDash" style={{ minWidth: "16rem", maxWidth: "18rem" }}>
                            <div className="containerNameAbcActionsDash">
                                <div className="clientInfoAbcActionsDash">
                                    <div
                                        style={{
                                            color: "#D4AC00",
                                            fontWeight: "bold",
                                            paddingLeft: "1rem",
                                            fontSize: "1.2rem",
                                        }}
                                    >
                                        Alterar Status{" "}
                                    </div>
                                </div>
                            </div>
                            <div>
                                <span
                                    style={{
                                        fontWeight: "bold",
                                        paddingLeft: "2rem",
                                        paddingTop: "1rem",
                                        // marginTop: '1rem'
                                        color: "#BCBCBC",
                                    }}
                                >
                                    Status Atual: {titleize(currentStatus ? currentStatus : "Indefinido")}
                                </span>
                            </div>
                            <div className="containerGeneralOptionsAbcActions">
                                {/* <div className="containerOptionsTitleAbcActions">
                                <div className="optionsTitleAbcActions">{props?.prop?.product?.description}</div>
                            </div>
                            <div className="containerOptionsAbcActions">
                                {props.prop.product.type.id != 3 ? <button
                                    className="buttonAbcActions"
                                    
                                    onClick={() => {
                                    //   const win = window.open(link.boletar, "_blank");
                                    //   win.focus();
                                    setType({ name: props.prop.product.type.id == 1 ? "boletarEstruturada" : 'boletarOfertaPublica', props: {product : props.prop.product, client: props.prop.client, clients: props.prop.clients, returnTo: 'menu'} });
                                    
                                    }}>
                                    Boletar
                                </button> : 
                                <button
                                className="buttonAbcActionsSolicit"
                                onClick={() => {
                                    updateStatus(3)
                                }}>
                                Solicitar
                            </button>}
                            </div> */}
                            </div>

                            <div className="containerGeneralChangeStatusAbcActions">
                                {/* <div className="containerChangeStatusTitleAbcActions">
                                    <div className="ChangeStatusTitleAbcActions">Alterar Status</div>
                                </div> */}
                                <div className="containerChangeStatusAbcActionsDash">
                                    <button
                                        style={{ background: newStatus === 2 ? "#C49E18" : "#868686" }}
                                        className="buttomGray360"
                                        onClick={() => {
                                            setActiveRefusalReasonBox(false)
                                            setBoletar(false)
                                            if (newStatus === 2) {
                                                setNewStatus(-1)
                                            } else updateStatus(2)
                                        }}
                                    >
                                        {setNewStatus ? <p>Abordado</p> : <></>}
                                    </button>
                                    <button
                                        style={{ background: activeRefusalReasonBox ? "#C49E18" : "#868686" }}
                                        className="buttomGray360"
                                        onClick={() => {
                                            setActiveRefusalReasonBox(!activeRefusalReasonBox)
                                            setBoletar(false)
                                            setNewStatus(-1)
                                        }}
                                    >
                                        {setActiveRefusalReasonBox ? <p>Recusado</p> : <p style={{ background: "#868686" }}>Recusado</p>}
                                    </button>
                                    <button
                                        className="buttomGray360"
                                        style={{ background: newStatus === 7 ? "#C49E18" : "#868686" }}
                                        onClick={() => {
                                            setActiveRefusalReasonBox(false)
                                            setBoletar(false)
                                            if (newStatus === 7) {
                                                setNewStatus(-1)
                                            } else updateStatus(7)
                                        }}
                                    >
                                        Não Aderente
                                    </button>

                                    <button
                                        className="buttomGray360"
                                        style={{ background: boletar ? "#C49E18" : "#868686"}}
                                        onClick={() => {
                                            setActiveRefusalReasonBox(false)
                                            setBoletar(!boletar)
                                            setNewStatus(-1)
                                        }}
                                    >
                                        Aceito
                                    </button>

                                    {boletar ? (
                                        <>
                                            <CurrencyTextField
                                                style={{ height: "2.4rem", marginTop: "1rem" }}
                                                variant="outlined"
                                                value={boletarValue}
                                                currencySymbol="R$"
                                                className={classes.styleForClients}
                                                outputFormat="number"
                                                decimalCharacter=","
                                                digitGroupSeparator="."
                                                onChange={(event, value) => {
                                                    setBoletarValue(value)
                                                }}
                                            />

                                            <button
                                                className="buttomGray360"
                                                style={{ background: boletar ? "#C49E18" : "#868686" }}
                                                onClick={() => {
                                                    setActiveRefusalReasonBox(false)
                                                    setBoletar(!boletar)
                                                    submitBoleta()
                                                }}
                                            >
                                                Enviar
                                            </button>
                                            <span style={{color: 'red', fontSize: '0.9rem'}}>* A boleta deve ser enviada via HUB e será registarda aqui automaticamente no próximo dia útil</span>
                                        </>
                                    ) : (
                                        <></>
                                    )}
                                </div>
                            </div>
                            {newStatus >= 0 ? (
                                <div className="suggestionMessageDash alertMessageAbc">
                                    <div className="suggestionMessageHeader">A mensagem ao lado será registrada.</div>

                                    <div
                                        className="suggestionMessageBody"
                                        style={{
                                            display: "grid",
                                            gridTemplateColumns: "auto 2rem",
                                            bottom: "0px",
                                        }}
                                    >
                                        <div>
                                            Adicione uma descrição mais completa se necessário.
                                        </div>
                                        <div>
                                            <FiArrowRight color size="40" />
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div></div>
                            )}
                        </div>
                        {/* <div className="divisionNameProductSubcategory"></div> */}
                        <div></div>
                    </>
                ) : (
                    <>
                        <div></div>
                        <div></div>
                        <div></div>

                        {/* <div className="divisionNameProductSubcategory"></div> */}
                    </>
                )}
                {/* timeline do cliente */}
                <div>
                    {activeRefusalReasonBox ? (
                        <div className={"refusalReasonBox"}>
                            <>
                                <div className="refuseReasonHeaderAbc">
                                    <div></div>
                                    <div className="refuseReasontitleAbc">Motivo da recusa</div>
                                    <button onClick={() => setActiveRefusalReasonBox(false)} className="modalCloseButton">
                                        X
                                    </button>
                                </div>
                                <FormGroup className="reasonsRefusalList">
                                    <RadioGroup aria-labelledby="demo-radio-buttons-group-label" name="radio-buttons-group">
                                        {refusalReasons.map((e, i) => {
                                            return (
                                                <FormControlLabel
                                                    value={e.id}
                                                    control={<Radio />}
                                                    label={e.label}
                                                    onClick={(e) => {
                                                        setSelectedReason(e.target.value)
                                                        const refusalReasonAux = refusalReasons.filter((r) => r.id == e.target.value)
                                                        setComentTimeline(refusalReasonAux[0]?.comment)
                                                    }}
                                                />
                                            )
                                        })}
                                    </RadioGroup>
                                </FormGroup>
                                <button
                                    className="buttomGray360"
                                    onClick={() => {
                                        setActiveRefusalReasonBox(false)
                                        setNewStatus(6)
                                    }}
                                >
                                    Ok
                                </button>
                            </>
                        </div>
                    ) : (
                        <></>
                    )}
                    <div className="containerTimelineAbcDash" style={{height: `${window.innerHeight > 760 ? 'calc(100vh - 12rem)' : window.innerHeight > 530 ? 'calc(100vh - 6rem)' : 'calc(100vh - 2.3rem)'}`}}>
                        <>
                            <div className="timelineTitleAbcAbrirDash">
                                <div style={{ display: "grid", gridTemplateColumns: "auto auto" }}>
                                    <div style={{ marginTop: "0.2rem", color: "rgb(212, 172, 0)", fontWeight: "bold", fontSize: "1.2rem" }}>Comentários</div>
                                    <div style={{ display: "grid", gridTemplateColumns: "auto auto" }}>
                                        <div
                                            style={{ display: "grid", gridTemplateColumns: "auto auto", marginLeft: "auto" }}
                                            className={filterProduct.current ? classesContactType.timelineFilterTypeProduct : classesContactType.timelineFilterTypeProductInactive}
                                            onClick={() => {
                                                filterProduct.current = !filterProduct.current
                                                filterTimeline()
                                            }}
                                            title="Apenas da Campanha"
                                        >
                                            Apenas da Campanha
                                        </div>
                                        <div
                                            style={{ display: "grid", gridTemplateColumns: "auto auto", marginLeft: "auto" }}
                                            className={!filterProduct.current ? classesContactType.timelineFilterTypeProduct : classesContactType.timelineFilterTypeProductInactive}
                                            onClick={() => {
                                                filterProduct.current = !filterProduct.current
                                                filterTimeline()
                                            }}
                                            title="Geral"
                                        >
                                            Geral
                                        </div>
                                    </div>
                                </div>
                                <div className="timelineFiltersDash">
                                    Filtrar:
                                    <div
                                        className={filters.indexOf(1) >= 0 ? classesContactType.timelineFilterType1Active : classesContactType.timelineFilterTypeInactive}
                                        onClick={() => {
                                            if (filters.indexOf(1) >= 0) {
                                                filters.splice(filters.indexOf(1), 1)
                                            } else {
                                                filters.push(1)
                                            }
                                            filterTimeline()
                                        }}
                                        title="Whatsapp"
                                    >
                                        <BsWhatsapp style={{ margin: "auto" }} size={18} />
                                    </div>
                                    <div
                                        className={filters.indexOf(2) >= 0 ? classesContactType.timelineFilterType2Active : classesContactType.timelineFilterTypeInactive}
                                        onClick={() => {
                                            if (filters.indexOf(2) >= 0) {
                                                filters.splice(filters.indexOf(2), 1)
                                            } else {
                                                filters.push(2)
                                            }
                                            filterTimeline()
                                        }}
                                        title="Ligação"
                                    >
                                        <BsTelephone style={{ margin: "auto" }} size={18} />
                                    </div>
                                    <div
                                        className={filters.indexOf(3) >= 0 ? classesContactType.timelineFilterType3Active : classesContactType.timelineFilterTypeInactive}
                                        onClick={() => {
                                            if (filters.indexOf(3) >= 0) {
                                                filters.splice(filters.indexOf(3), 1)
                                            } else {
                                                filters.push(3)
                                            }
                                            filterTimeline()
                                        }}
                                        title="E-mail"
                                    >
                                        <AiOutlineMail style={{ margin: "auto" }} size={18} />
                                    </div>
                                    <div
                                        className={filters.indexOf(4) >= 0 ? classesContactType.timelineFilterType4Active : classesContactType.timelineFilterTypeInactive}
                                        onClick={() => {
                                            if (filters.indexOf(4) >= 0) {
                                                filters.splice(filters.indexOf(4), 1)
                                            } else {
                                                filters.push(4)
                                            }
                                            filterTimeline()
                                        }}
                                        title="Reunião"
                                    >
                                        <BsPeople style={{ margin: "auto" }} size={18} />
                                    </div>
                                    <div
                                        className={filters.indexOf(5) >= 0 ? classesContactType.timelineFilterType5Active : classesContactType.timelineFilterTypeInactive}
                                        onClick={() => {
                                            if (filters.indexOf(5) >= 0) {
                                                filters.splice(filters.indexOf(5), 1)
                                            } else {
                                                filters.push(5)
                                            }
                                            filterTimeline()
                                        }}
                                        title="Apenas Comentário"
                                    >
                                        <BsChatDots style={{ margin: "auto" }} size={18} />
                                    </div>
                                    <div
                                        className={filters.indexOf(0) >= 0 ? classesContactType.timelineFilterType0Active : classesContactType.timelineFilterTypeInactive}
                                        onClick={() => {
                                            if (filters.indexOf(0) >= 0) {
                                                filters.splice(filters.indexOf(0), 1)
                                            } else {
                                                filters.push(0)
                                            }
                                            filterTimeline()
                                        }}
                                        title="Sistema"
                                    >
                                        <BsCpu style={{ margin: "auto" }} size={18} />
                                    </div>
                                    <div
                                        className={filters.indexOf(6) >= 0 ? classesContactType.timelineFilterType6Active : classesContactType.timelineFilterTypeInactive}
                                        onClick={() => {
                                            if (filters.indexOf(6) >= 0) {
                                                filters.splice(filters.indexOf(6), 1)
                                            } else {
                                                filters.push(6)
                                            }
                                            filterTimeline()
                                        }}
                                        title="Formulário Ativação"
                                    >
                                        <BsCardList style={{ margin: "auto" }} size={18} />
                                    </div>
                                </div>
                            </div>

                            <div
                                className="timelineCommentBoxAbcDash"
                                id="timelineCommentBoxAbc"
                                onScroll={(e) => {
                                    if (e.target.scrollTop < 10) updateItemsList()
                                }}
                            >
                                {items.length > 0 ? (
                                    items.map((item) => {
                                        return item.user.id == user.external_id ? (
                                            item.type == "6" ? (
                                                <div className="timelineComentBoxUserComentRight">
                                                    <div className={"timelineComentBoxUserComentHeaderRight formHeaderColor"}>
                                                        <div className="timelineComentBoxUserComentIconRight">
                                                            <BsCardList title="Formulário respondido antes da ativação" />
                                                        </div>
                                                        <div className="timelineComentBoxUserComentNameRight">{item.user.name}</div>
                                                    </div>
                                                    <div className={"timelineComentBoxUserComentMessageRight formMessageColor"}>
                                                        <button
                                                            className="buttonShowFinishedForm"
                                                            onClick={() => {
                                                                showClientActivation(2)
                                                            }}
                                                        >
                                                            Formulário de primeira reunião
                                                            <BsBoxArrowUpLeft style={{ marginLeft: "0.4rem" }} size={14} />
                                                        </button>
                                                    </div>
                                                    <div className={"timelineComentBoxUserComentDateRight formDateColor"}>
                                                        {item.created_at.split("T")[0].split("-")[2] +
                                                            "/" +
                                                            item.created_at.split("T")[0].split("-")[1] +
                                                            "/" +
                                                            item.created_at.split("T")[0].split("-")[0] +
                                                            " - " +
                                                            item.created_at.split("T")[1].split(".")[0]}
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className="timelineComentBoxUserComentRight">
                                                    <div
                                                        className={
                                                            item.type == 1
                                                                ? "timelineComentBoxUserComentHeaderRight commentMessageDash"
                                                                : item.type == 2
                                                                ? "timelineComentBoxUserComentHeaderRight commentMessageDash"
                                                                : item.type == 3
                                                                ? "timelineComentBoxUserComentHeaderRight commentMessageDash"
                                                                : item.type == 4
                                                                ? "timelineComentBoxUserComentHeaderRight commentMessageDash"
                                                                : item.type == 5
                                                                ? "timelineComentBoxUserComentHeaderRight commentMessageDash"
                                                                : item.type == 0
                                                                ? "timelineComentBoxUserComentHeaderRight commentMessageDash"
                                                                : "timelineComentBoxUserComentHeaderRight commentMessageDash"
                                                        }
                                                    >
                                                        <div className="timelineComentBoxUserComentIconRight">
                                                            {item.type == 1 ? (
                                                                <BsWhatsapp title="Contato por WhatsApp" />
                                                            ) : item.type == 2 ? (
                                                                <BsTelephone title="Contato por ligação" />
                                                            ) : item.type == 3 ? (
                                                                <AiOutlineMail title="Contato por e-mail" />
                                                            ) : item.type == 4 ? (
                                                                <BsPeople title="Contato por reunião" />
                                                            ) : item.type == 5 ? (
                                                                <BsChatDots title="Mensagem sem classificação" />
                                                            ) : item.type == 0 ? (
                                                                <BsCpu title="Mensagem do sistema" />
                                                            ) : (
                                                                <BsPersonPlus title="Indicação de cliente" />
                                                            )}
                                                        </div>
                                                        <div className="timelineComentBoxUserComentNameRight">{item.user.name}</div>
                                                    </div>
                                                    <div
                                                        className={
                                                            item.type == 1
                                                                ? "timelineComentBoxUserComentMessageRight commentMessageDash"
                                                                : item.type == 2
                                                                ? "timelineComentBoxUserComentMessageRight commentMessageDash"
                                                                : item.type == 3
                                                                ? "timelineComentBoxUserComentMessageRight commentMessageDash"
                                                                : item.type == 4
                                                                ? "timelineComentBoxUserComentMessageRight commentMessageDash"
                                                                : item.type == 5
                                                                ? "timelineComentBoxUserComentMessageRight commentMessageDash"
                                                                : item.type == 0
                                                                ? "timelineComentBoxUserComentMessageRight commentMessageDash"
                                                                : "timelineComentBoxUserComentMessageRight commentMessageDash"
                                                        }
                                                    >
                                                        {item.text}
                                                        <>
                                                            {item?.informations?.map((line) => {
                                                                return (
                                                                    <div>
                                                                        {line.key} <b>: </b> {line.value}
                                                                    </div>
                                                                )
                                                            })}
                                                        </>
                                                    </div>
                                                    <div
                                                        className={
                                                            item.type == 1
                                                                ? "timelineComentBoxUserComentDateRight commentMessageDash"
                                                                : item.type == 2
                                                                ? "timelineComentBoxUserComentDateRight commentMessageDash"
                                                                : item.type == 3
                                                                ? "timelineComentBoxUserComentDateRight commentMessageDash"
                                                                : item.type == 4
                                                                ? "timelineComentBoxUserComentDateRight commentMessageDash"
                                                                : item.type == 5
                                                                ? "timelineComentBoxUserComentDateRight commentMessageDash"
                                                                : item.type == 0
                                                                ? "timelineComentBoxUserComentDateRight commentMessageDash"
                                                                : "timelineComentBoxUserComentDateRight commentMessageDash"
                                                        }
                                                    >
                                                        {item.created_at.split("T")[0].split("-")[2] +
                                                            "/" +
                                                            item.created_at.split("T")[0].split("-")[1] +
                                                            "/" +
                                                            item.created_at.split("T")[0].split("-")[0] +
                                                            " - " +
                                                            item.created_at.split("T")[1].split(".")[0]}
                                                    </div>
                                                </div>
                                            )
                                        ) : item.type == "6" ? (
                                            <div className="timelineComentBoxUserComentLeft">
                                                <div className={"timelineComentBoxUserComentHeaderLeft formHeaderColor"}>
                                                    <div className="timelineComentBoxUserComentNameLeft">{item.user.name}</div>
                                                    <div className="timelineComentBoxUserComentIconLeft">
                                                        <BsCardList title="Formulário respondido antes da ativação" />
                                                    </div>
                                                </div>
                                                <div className={"timelineComentBoxUserComentMessageLeft formMessageColor"}>
                                                    <button
                                                        className="buttonShowFinishedForm"
                                                        onClick={() => {
                                                            showClientActivation(2)
                                                        }}
                                                    >
                                                        Formulário de primeira reunião
                                                        <BsBoxArrowUpLeft style={{ marginLeft: "0.4rem" }} size={14} />
                                                    </button>
                                                </div>
                                                <div className={"timelineComentBoxUserComentDateLeft formDateColor"}>
                                                    {item.created_at.split("T")[0].split("-")[2] +
                                                        "/" +
                                                        item.created_at.split("T")[0].split("-")[1] +
                                                        "/" +
                                                        item.created_at.split("T")[0].split("-")[0] +
                                                        " - " +
                                                        item.created_at.split("T")[1].split(".")[0]}
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="timelineComentBoxUserComentLeft">
                                                <div
                                                    className={
                                                        item.type == 1
                                                            ? "timelineComentBoxUserComentHeaderLeft commentMessageDash"
                                                            : item.type == 2
                                                            ? "timelineComentBoxUserComentHeaderLeft commentMessageDash"
                                                            : item.type == 3
                                                            ? "timelineComentBoxUserComentHeaderLeft commentMessageDash"
                                                            : item.type == 4
                                                            ? "timelineComentBoxUserComentHeaderLeft commentMessageDash"
                                                            : item.type == 5
                                                            ? "timelineComentBoxUserComentHeaderLeft commentMessageDash"
                                                            : item.type == 0
                                                            ? "timelineComentBoxUserComentHeaderLeft commentMessageDash"
                                                            : "timelineComentBoxUserComentHeaderLeft commentMessageDash"
                                                    }
                                                >
                                                    <div className="timelineComentBoxUserComentNameLeft">{item.user.name}</div>
                                                    <div className="timelineComentBoxUserComentIconLeft">
                                                        {item.type == 1 ? (
                                                            <BsWhatsapp title="Contato por WhatsApp" />
                                                        ) : item.type == 2 ? (
                                                            <BsTelephone title="Contato por ligação" />
                                                        ) : item.type == 3 ? (
                                                            <AiOutlineMail title="Contato por e-mail" />
                                                        ) : item.type == 4 ? (
                                                            <BsPeople title="Contato por reunião" />
                                                        ) : item.type == 5 ? (
                                                            <BsChatDots title="Mensagem sem classificação" />
                                                        ) : item.type == 0 ? (
                                                            <BsCpu title="Mensagem do sistema" />
                                                        ) : (
                                                            <BsPersonPlus title="Indicação de cliente" />
                                                        )}
                                                    </div>
                                                </div>
                                                <div
                                                    className={
                                                        item.type == 1
                                                            ? "timelineComentBoxUserComentMessageLeft commentMessageDash"
                                                            : item.type == 2
                                                            ? "timelineComentBoxUserComentMessageLeft commentMessageDash"
                                                            : item.type == 3
                                                            ? "timelineComentBoxUserComentMessageLeft commentMessageDash"
                                                            : item.type == 4
                                                            ? "timelineComentBoxUserComentMessageLeft commentMessageDash"
                                                            : item.type == 5
                                                            ? "timelineComentBoxUserComentMessageLeft commentMessageDash"
                                                            : item.type == 0
                                                            ? "timelineComentBoxUserComentMessageLeft commentMessageDash"
                                                            : "timelineComentBoxUserComentMessageLeft commentMessageDash"
                                                    }
                                                >
                                                    {item.text}
                                                    <>
                                                        {item?.informations?.map((line) => {
                                                            return (
                                                                <div>
                                                                    {line.key} <b>: </b> {line.value}
                                                                </div>
                                                            )
                                                        })}
                                                    </>
                                                </div>
                                                <div
                                                    className={
                                                        item.type == 1
                                                            ? "timelineComentBoxUserComentDateLeft commentMessageDash"
                                                            : item.type == 2
                                                            ? "timelineComentBoxUserComentDateLeft commentMessageDash"
                                                            : item.type == 3
                                                            ? "timelineComentBoxUserComentDateLeft commentMessageDash"
                                                            : item.type == 4
                                                            ? "timelineComentBoxUserComentDateLeft commentMessageDash"
                                                            : item.type == 5
                                                            ? "timelineComentBoxUserComentDateLeft commentMessageDash"
                                                            : item.type == 0
                                                            ? "timelineComentBoxUserComentDateLeft commentMessageDash"
                                                            : "timelineComentBoxUserComentDateLeft commentMessageDash"
                                                    }
                                                >
                                                    {item.created_at.split("T")[0].split("-")[2] +
                                                        "/" +
                                                        item.created_at.split("T")[0].split("-")[1] +
                                                        "/" +
                                                        item.created_at.split("T")[0].split("-")[0] +
                                                        " - " +
                                                        item.created_at.split("T")[1].split(".")[0]}
                                                </div>
                                            </div>
                                        )
                                    })
                                ) : loadingTimeline ? (
                                    <ArrowLoader />
                                ) : (
                                    <div className="emptyTimelineMessage">Não há mensagens...</div>
                                )}
                            </div>
                            <div className="timelineNewCommentBox">
                                <div className="timelineNewCommentContactType">
                                    <div className="timelineNewCommentContactTypeTitle">Tipo de contato</div>
                                    <div
                                        className={contactType == 1 ? classesContactType.timelineNewCommentContactTypeActive : classesContactType.timelineNewCommentContactTypeInactive}
                                        onClick={() => {
                                            setContactType(1)
                                            if (contactType == 1) {
                                                setContactType(5)
                                            }
                                        }}
                                        title="Whatsapp"
                                    >
                                        <BsWhatsapp style={{ margin: "auto" }} size={24} />
                                    </div>
                                    <div
                                        className={contactType == 2 ? classesContactType.timelineNewCommentContactTypeActive : classesContactType.timelineNewCommentContactTypeInactive}
                                        onClick={() => {
                                            setContactType(2)
                                            if (contactType == 2) {
                                                setContactType(5)
                                            }
                                        }}
                                        title="Ligação"
                                    >
                                        <BsTelephone style={{ margin: "auto" }} size={24} />
                                    </div>
                                    <div
                                        className={contactType == 3 ? classesContactType.timelineNewCommentContactTypeActive : classesContactType.timelineNewCommentContactTypeInactive}
                                        onClick={() => {
                                            setContactType(3)
                                            if (contactType == 3) {
                                                setContactType(5)
                                            }
                                        }}
                                        title="E-mail"
                                    >
                                        <AiOutlineMail style={{ margin: "auto" }} size={24} />
                                    </div>
                                    <div
                                        className={contactType == 4 ? classesContactType.timelineNewCommentContactTypeActive : classesContactType.timelineNewCommentContactTypeInactive}
                                        onClick={() => {
                                            setContactType(4)
                                            if (contactType == 4) {
                                                setContactType(5)
                                            }
                                        }}
                                        title="Reunião"
                                    >
                                        <BsPeople style={{ margin: "auto" }} size={24} />
                                    </div>
                                </div>
                                <div className="timelineNewCommentWriteSendDash">
                                    <textarea
                                        className={classesContactType.timelineNewComentWriteArea}
                                        value={comentTimeline}
                                        label="Novo comentário"
                                        onChange={(e) => {
                                            setComentTimeline(e.target.value)
                                        }}
                                    ></textarea>
                                    <button
                                        className={
                                            comentTimeline !== "" && comentTimeline !== null ? classesContactType.timelineNewCommentSendActive : classesContactType.timelineNewCommentSendInative
                                        }
                                        onClick={() => {
                                            if (comentTimeline !== "" && comentTimeline !== null) {
                                                handleSubmitComment()
                                            }
                                        }}
                                    >
                                        <BsSend size={26} />
                                    </button>
                                </div>
                            </div>
                        </>
                    </div>
                </div>
            </div>
        </div>
    )
}
