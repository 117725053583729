import React, { useEffect, useState, useRef} from "react";
import MUIDataTable from "mui-datatables";
import { debounceSearchRender } from "mui-datatables";
import { Tooltip, IconButton } from "@material-ui/core";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { ThemeProvider,createTheme } from '@mui/material/styles';
import TableCell from "@mui/material/TableCell"
/*Styles*/
import "../../monitoringPanel/myReservations/styles/myReservation.table.css"
import { AiOutlineArrowUp, AiOutlineArrowDown } from "react-icons/ai"
/*Contexts*/
import { useAuth } from "../../../../contexts/useAuth";
import { useModal } from "../../../../contexts/useModal";
/*Services*/
import { getDatabaseReferenceTime, getFundosClients } from "../../../../services/asyncFunctions";
/*Components*/
import { ArrowLoader } from "../../../../components/loaders/arrowLoader";
import { toBRL } from "../../../../utils/formatNumberAndText/toBRLCurrency";
import { toPercent } from "../../../../utils/formatNumberAndText/formatNumberPercent";
import { titleize } from "../../../../utils/formatNumberAndText/formatTextUpperFirstLetter";
import { BsQuestionCircle } from "react-icons/bs";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

export function FundsClientsTable(props) {
    console.log('FundsClientsTable', props ? props : 'no props');
    const { codCliente } = useParams();
    const [loading, setLoading] = useState(false);
    const [listDueStructured, setlistDueStructured] = useState([]);
    const [listDueStructuredFormatted, setlistDueStructuredFormatted] = useState([]);
    const { user, tokenKey } = useAuth();
    const { setType, setShow, edited, setEdited } = useModal();
    const [databaseUpdateTime, setDatabaseUpdateTime] = useState("");
    const teste = useRef(0)
    const teste2 = useRef(0)
    const [category, setCategory] = useState(codCliente ? 'Liquidez / Alocação Tática' : '')

    console.log('teste', codCliente)
    async function getDue() {
        const listDueStructuredAux = await getFundosClients(tokenKey, false);
        if (listDueStructuredAux?.isError) {
            setType({
                name: "alertMsg",
                type: "information",
                value: {
                    message: listDueStructuredAux?.error,
                },
            });
            setShow(true);
        } else {
            setlistDueStructured(listDueStructuredAux);
            listDueStructuredFormatted.find((item) => {
            
                item.category.includes('Liquidez')
                // setCategory(item.category)
            });
            // console.log(listDueStructuredAux)
            setLoading(false);
        }
    }



    async function getDatabaseUpdateTimeValues() {
        const bases = 'diversificacao';
        const databaseUpdateTime = await getDatabaseReferenceTime(tokenKey, bases);
        if (getDatabaseReferenceTime?.isError) {
            setType({
                name: "alertMsg",
                type: "information",
                value: {
                    message: getDatabaseReferenceTime?.error,
                },
            });
            setShow(true);
        } else {
            try {
                let dateFormat = databaseUpdateTime[0].file_reference_time;
                setDatabaseUpdateTime(dateFormat);
            } catch (e) {
                let dateFormat = ''
                setDatabaseUpdateTime(dateFormat);
            }
        }
    }

    function formateAssets() {
        let dueStructuredFormatted = [];

        listDueStructured.map((dueStructured) => {
            
            dueStructuredFormatted.push({
                client_id: parseInt(dueStructured.client_id),
                client: dueStructured.client,
                product: dueStructured.product,
                asset: dueStructured.asset,
                category: dueStructured.category,
                liquidity: dueStructured.liquidity,
                roa: dueStructured.roa,
                status: dueStructured.status,
                net: dueStructured.net,
                suggestion_asset: dueStructured.suggestion_asset,
                suggestion_roa: dueStructured.suggestion_roa,
                roa_increase: dueStructured.suggestion_roa !== null ? ((dueStructured.suggestion_roa / dueStructured.roa) - 1) * 100 : null,
                net_increase: dueStructured.suggestion_roa !== null ? (dueStructured.net * (dueStructured.suggestion_roa/100 - dueStructured.roa/100))  : null,
                advisor_id: dueStructured.advisor_id,
                advisor_name: dueStructured.advisor_name,
                team: dueStructured.leader_name,
                branch_city: dueStructured.branch_city,
                target_investor: dueStructured.target_investor,
            });
        });

        setlistDueStructuredFormatted(dueStructuredFormatted);
        setLoading(false);
    }

    // let category 
    useEffect(() => {
        listDueStructuredFormatted.find((item) => {
            
            item.category.includes('Liquidez')
            // setCategory(item.category)
        });
    }, [listDueStructuredFormatted])

    useEffect(() => {
        if (edited || listDueStructured.length === 0) {
            setEdited(false);
            setLoading(true);
            getDue();
            getDatabaseUpdateTimeValues();
        }
    }, [edited]);

    useEffect(() => {
        if (listDueStructured.length > 0) {
            formateAssets();
        }
    }, [listDueStructured]);

    function copyToClipboard() {
        const output = [];
        const titles = [
            "Código Cliente",
            "Cliente",
            "Produto",
            "Nome Assessor",
            "Código Assessor",
            "Time",
            "Filial",
            "Fundo",
            "Categoria",
            "Liquidez",
            "ROA (%)",
            "Status",
            "NET",
            "Sugestão de Troca",
            "ROA da Sugestão (%)",
            "Δ da Troca (%)",
            "Diferença Receita A.A. (R$)",
            
        ];
        output.push(titles.join("\t"));
        const keys = [
            "client_id",
            "client",
            "product",
            "advisor_name",
            "advisor_id",
            "team",
            "branch_city",
            "asset",
            "category",
            "liquidity",
            "roa",
            "status",
            "net",
            "suggestion_asset",
            "suggestion_roa",
            "roa_increase",
            "net_increase",            
        ];
        listDueStructuredFormatted.forEach((x) => {
            const tmp = keys.map((y) => {
                return  x[y] !== null && x[y] !== undefined ?
                y === "net" || y === "roa" || y === "roa_increase" || y === "suggestion_roa" || y === "net_increase"
                    ? String(x[y]).replace(".", ",")
                    : y === "created_at" || y === "due_at"
                    ? x[y].replace(/^(\d{4})-(\d{2})-(\d{2})T(\d{2}:\d{2}:\d{2})\.\d+Z$/, "$3/$2/$1")
                    : x[y] : '-' ;
            });
            output.push(tmp.join("\t"));
        });
        const table = output.join("\n");
        navigator.clipboard.writeText(table);
        alert("Conteúdo da tabela copiado para a área de transferência.");
    }

    // async function dueStructuredExport(open, year) {
    //     setLoading(true);
    //     try {
    //         const myExport = await getFundsView(tokenKey, true);
    //         const responseURL = URL.createObjectURL(new Blob([myExport]));
    //         const link = document.createElement("a");
    //         link.href = responseURL;
    //         link.setAttribute("download", "fundos_por_cliente.xlsx");
    //         document.body.appendChild(link);
    //         link.click();
    //         setLoading(false);
    //     } catch (error) {
    //         setType({
    //             name: "alertMsg",
    //             type: "information",
    //             value: {
    //                 message:
    //                     error?.response?.data?.message === undefined
    //                         ? "Erro ao buscar dados para exportação"
    //                         : error?.response?.data?.message,
    //             },
    //         });
    //         setShow(true);
    //         setLoading(false);
    //     }
    // }

    const columns = [
        {
            name: "client_id",
            label: "Código Cliente",
            options: {
                filter: true,
                sort: true,
                filterList: codCliente ? [Number(codCliente)] : [],
                customHeadRender: ({ index, name, label, ...column }, sortColumn, tableMeta) => {
                    return (
                        <>
                            <TableCell
                                className="TableCell"
                                onClick={() => {
                                    sortColumn(index)
                                }}
                                style={{
                                    minWidth: "9rem",
                                    whiteSpace: "nowrap",
                                    position: "sticky",
                                    left: 0,
                                    zIndex: 121,
                                    backgroundColor: "white",
                                }}
                            >
                                <div
                                    className="containerTableCellButton"
                                    // style={{ margin: "0px 30px" }}
                                >
                                    <div>
                                        {label}

                                        <div className="arrowSortAbc">
                                            {tableMeta.name === name ? tableMeta.direction == "asc" ? <AiOutlineArrowUp size={18} /> : <AiOutlineArrowDown size={18} /> : <></>}
                                        </div>
                                    </div>
                                </div>
                            </TableCell>
                        </>
                    )
                },
                setCellProps: (e, i) => {
                    teste.current += 1
                    //linha par cinza e linha impar branca
                    const color = teste.current % 2 != 0 ? "#dbdbdb" : "white"
                    return {
                        style: {
                            minWidth: "10rem",
                            whiteSpace: "nowrap",
                            textAlign: "center",
                            position: "sticky",
                            left: "0",
                            backgroundColor: color,
                            zIndex: 120,
                        },
                    }
                },
            },
        },
        {
            name: "client",
            label: "Cliente",
            options: {
                filter: true,
                sort: true,
                customHeadRender: ({ index, name, label, ...column }, sortColumn, tableMeta) => {
                    return (
                        <>
                            <TableCell
                                className="TableCell"
                                onClick={() => {
                                    sortColumn(index)
                                }}
                                style={{
                                    minWidth: "9rem",
                                    position: "sticky",
                                    left: "12rem",
                                    zIndex: 121,
                                    backgroundColor: "white",
                                }}
                            >
                                <div
                                    className="containerTableCellButton"
                                    // style={{ margin: "0px 30px" }}
                                >
                                    <div>
                                        {label}

                                        <div className="arrowSortAbc">
                                            {tableMeta.name === name ? tableMeta.direction == "asc" ? <AiOutlineArrowUp size={18} /> : <AiOutlineArrowDown size={18} /> : <></>}
                                        </div>
                                    </div>
                                </div>
                            </TableCell>
                        </>
                    )
                },
                setCellProps: (e, i) => {
                    teste2.current += 1
                    //linha par cinza e linha impar branca
                    const color = teste2.current % 2 != 0 ? "#dbdbdb" : "white"
                    return {
                        style: {
                            textAlign: "center",
                            position: "sticky",
                            whiteSpace: "nowrap",
                            left: "12rem",
                            backgroundColor: color,
                            zIndex: 120,
                        },
                    }
                },  
            },
        }]

        //se o usuario for lider, entao insere uma coluna de assessor na tabela para saber de qual dos liderados é o cliente
        if (user?.is_leader > 0|| user.type < 3) {
        columns.push(
        {
            name: "advisor_id",
            label: "Código do Assessor",
            options: {
                filter: true,
                sort: false,
                display: false,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                    },
                }),
            },
        },
        {
            name: "advisor_name",
            label: "Assessor",
            options: {
                display: (user.type < 3 ? true : false),
                filter: true,
                sort: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                    },
                }),
            },
        })}
        if (user.type < 3) {
            columns.push(
        {
            name: "team",
            label: "Time",
            options: {
                filter: true,
                sort: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                    },
                }),
            },
        },
        {
            name: "branch_city",
            label: "Filial",
            options: {
                filter: true,
                sort: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                    },
                }),
            },
        },
        // {
        //     name: "branch_state",
        //     label: "Filial UF",
        //     options: {
        //         filter: true,
        //         sort: true,
        //         setCellProps: () => ({
        //             style: {
        //                 whiteSpace: "nowrap",
        //             },
        //         }),
        //     },
        // }
        )
    }

        
        columns.push(
            {
                name: "product",
                label: "Produto",
                options: {
                    filter: true,
                    sort: true,
    
                    setCellProps: () => ({
                        style: {
                            whiteSpace: "nowrap",
                        },
                    }),
                    customBodyRender: (value) =>
                        value != null && value != "null" ? value : "N/C",
                },
            },
            {
            name: "asset",
            label: "Fundo",
            options: {
                filter: true,
                sort: true,

                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                    },
                }),
            },
        },
        {
            name: "category",
            label: "Categoria",
            options: {
                filter: true,
                sort: true,
                filterList: category ? [category] : [],
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                    },
                }),
                customBodyRender: (value) =>
                    value != null && value != "null" ? value : "N/C"
            },
        },
        {
            name: "target_investor",
            label: "Tipo de Investidor",
            options: {
                filter: true,
                sort: true,

                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                    },
                }),
                customBodyRender: (value) =>
                    value != null && value != "null" ? value : "N/C"
            },
        },
        {
            name: "liquidity",
            label: "LIQUIDEZ",
            options: {
                filter: true,
                sort: true,

                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                    },
                }),
                customBodyRender: (value) =>
                    value != null && value != "null" ? value : "N/C",
                customHeadRender: ({ index, name, label, ...column }, sortColumn, tableMeta) => {
                    return (
                        <>
                            <TableCell
                                className="TableCell"
                                onClick={() => {
                                    sortColumn(index)
                                }}
                                style={{
                                    paddingRight: "0.5rem",
                                    paddingLeft: "0.5rem",
                                    whiteSpace: "nowrap",
                                }}
                            >
                                <div
                                    className="containerTableCellButton"
                                    // style={{ margin: "0px 30px" }}
                                >
                                    <div>
                                        {label}
                                        <BsQuestionCircle
                                            color="rgb(117, 117, 117)"
                                            size={16}
                                            style={{
                                                display: "inline-block",
                                                verticalAlign: "middle",
                                                marginTop: "-0.2rem",
                                                cursor: "pointer",
                                                marginLeft: "0.5rem",
                                                marginRight: "0.5rem",
                                            }}
                                            title="Liquidez em dias."
                                        />
                                        <div className="arrowSortAbc">
                                            {tableMeta.name === name ? tableMeta.direction == "asc" ? <AiOutlineArrowUp size={18} /> : <AiOutlineArrowDown size={18} /> : <></>}
                                        </div>
                                    </div>
                                </div>
                            </TableCell>
                        </>
                    )
                },
            },
        },
        {
            name: "roa",
            label: "ROA",
            options: {
                filter: false,
                sort: true,
                sortDescFirst: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                    },
                }),
                customBodyRender: (value) =>
                value !== null ?
                <span>{parseFloat((value)).toFixed(2) + '%'}</span> : <span>N/C</span>
            },
        },
        {
            name: "status",
            label: "Status",
            options: {
                filter: true,
                sort: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                    },
                }),
                customBodyRender: (value) => 
                value === 'VALIDADO' || value === 'CALL DE ENTRADA' ? <span style={{color: 'green'}}>{value}</span> : 
                value === 'NÃO VALIDADO' || value === 'CALL DE SAÍDA' ? <span style={{color: 'red'}}>{value}</span> :
                    <span>{value}</span>
            },
        },
        {
            name: "net",
            label: "NET",
            options: {
                filter: false,
                sort: true,
                sortDescFirst: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                    },
                }),
                customBodyRender: (value) => <span>{toBRL(value)}</span>
            },
        },
        {
            name: "suggestion_asset",
            label: "Sugestão de Troca",
            options: {
                filter: false,
                sort: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                    },
                }),
                customBodyRender: (value) => 
                value !== null ? <span>{value}</span> : <span>N/C</span>
            },
        },
        {
            name: "suggestion_roa",
            label: "ROA da Sugestão",
            options: {
                filter: false,
                sort: true,
                sortDescFirst: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                    },
                }),
                customBodyRender: (value) => 
                value !== null ? <span style={{color: 'green'}}>{toPercent(value)}</span> : <span>N/C</span>
            },
        },
        {
            name: "roa_increase",
            label: "Δ da Troca",
            options: {
                filter: false,
                sort: true,
                sortDescFirst: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                    },
                }),
                customBodyRender: (value) => 
                value !== null ? <span style={{color: value >= 0 ? 'green' : 'red'}}>{toPercent(value)}</span> : <span>N/C</span>
            },
        },
        {
            name: "net_increase",
            label: "Diferença Receita A.A.",
            options: {
                filter: false,
                sort: true,
                sortDescFirst: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                    },
                }),
                customBodyRender: (value) => 
                value !== null ? <span style={{color: value >= 0 ? 'green' : 'red'}}>{value >=0 ? toBRL(value) : '-'}</span> : <span>N/C</span>
            },
        })
        
    

    // Adiciona um botão para chamar a função de copiar a tabela para a área de tranferência
    const AddButton = () => (
        <Tooltip disableFocusListener title="Copiar para a área de transferência">
            <IconButton onClick={copyToClipboard}>
                <ContentCopyIcon />
            </IconButton>
        </Tooltip>
    );

    const options = {
        filterType: "multiselect",
        filter: true,
        download: false,
        print: false,
        responsive: "standard",
        customSearchRender: debounceSearchRender(500),
        tableBodyHeight: "70vh",
        selectableRowsHideCheckboxes: true,
        viewColumns: false,
        toolButton: {
            justifyContent: "center"
        },
        textLabels: {
            body: {
                noMatch: loading ? <ArrowLoader /> : "Não há clientes com fundos registrados no momento!",
                toolTip: "Ordenar",
                columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
            },
            pagination: {
                next: "Próxima",
                previous: "Anterior",
                rowsPerPage: "Linhas por página:",
                displayRows: "de",
            },
            toolbar: {
                search: "Pesquisar",
                downloadCsv: "Download XLSX",
                print: "Imprimir",
                viewColumns: "Ver colunas",
                filterTable: "Filtrar",
            },
            filter: {
                all: "Todos",
                title: "Filtros",
                reset: "Limpar",
            },
            viewColumns: {
                title: "Mostrar Colunas",
                titleAria: "Mostrar/Ocultar colunas",
            },
            selectedRows: {
                text: "linha(s) selecionadas",
                delete: "Excluir",
                deleteAria: "Excluir linhas Selecionadas",
            },
        },
        downloadOptions: {
            filename: "fundos_validados.csv",
            separator: ";",
        },
        onDownload: () => {
            // dueStructuredExport();
            return false;
        },
        setTableProps: () => {
            return {
                padding: '0px 1rem 0px',
                size: 'small'
            };
        },
        rowsPerPage: 20,
        rowsPerPageOptions: [20, 200, 1000],
        customToolbar: AddButton,
    };

    const getMuiTheme = () =>
    createTheme({
      components: {
        MuiTableCell: {
            styleOverrides: {
                root:{
                        justifyContent: 'center',
                        whiteSpace: "",
                        textAlign: 'center'
                }
            },
          },
          MUIDataTableFilter: {
            styleOverrides: {
                root: {
                    minWidth: "40vw",
                    maxWidth: "50vw",
                },
            },
        },
        MUIDataTableHeadCell: {
            styleOverrides: {
                contentWrapper: {
                    justifyContent: 'center',
                    whiteSpace: "pre-line",
                },
                fixedHeader: {
                    justifyContent: 'center',
                    whiteSpace: "pre-line",
                    maxWidth: '4rem',
                    padding: "0px 0.6rem 0px 0.6rem"
                },
                toolButton: {
                    margin: '0px'
                }
            }
        },
        MUIDataTableBodyRow: {
            styleOverrides: {
            root: {
              '&:nth-child(odd)': { 
                backgroundColor: '#dbdbdb'
              },
              "&:hover ": {
                  backgroundColor: "#e7e7e7 !important",
              },
              "&: hover td": {
                  backgroundColor: "#e7e7e7 !important",
              },
            }
        }
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    boxShadow: 'none',
                    borderRadius: '0'
        }
      },
    }
    }
    })

    return (
        <div>
            <ThemeProvider theme={getMuiTheme()}>
                <MUIDataTable
                    title={'Última atualização: ' + databaseUpdateTime}
                    data={listDueStructuredFormatted}
                    columns={columns}
                    options={options}
                    className="tableUnsuitedStructured"
                    ></MUIDataTable>
            </ThemeProvider>
        </div>
    );
}

