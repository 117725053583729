import React from 'react'
/*Styles*/
import './styles/assetSector.css'
/*Components*/
import Modal from '../../../../components/modal/modal'
import { ArrowLoader } from '../../../../components/loaders/arrowLoader'
import { ConfirmationBox } from '../../../../components/confirmationBox/confirmationBox'
/*Contexts*/
import { useModal } from '../../../../contexts/useModal'
import { AssetSectorTable } from './table/assetSector.table'
import { AssetSectorCadastration } from './assetsSectorCadastrion'
/*Utils*/
import { ModalSizeFitToScreen } from '../../../../utils/responsiveFunctions/modalSizeFitToScreen'

export default function AssetSector(){

    const { type, show, setShow } = useModal()
    const { screenY } = ModalSizeFitToScreen()

    return (
        <div className="assetSectorMainContainer" style={{
            maxHeight: screenY
        }}>
            <Modal>
                {
                    type.name === 'alertMsg' && <ConfirmationBox msgData={type}/>
                }
                {
                    type.name === 'assetSectorCadastration' && <AssetSectorCadastration data={type}/>
                }
                {
                    type.name === 'assetSectorEdit' && <ConfirmationBox msgData={type}/>
                }
            </Modal>
            <div className="assetSectorTableDiv">
                <AssetSectorTable />
            </div>
        </div>
    )
}