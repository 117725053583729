import React, { useEffect, useState, useReducer } from "react"
/*Styles*/
import "../styles/fixedIncome.products.style.css"
/*Contexts*/
import { useAuth } from "../../../../contexts/useAuth"
import { useModal } from "../../../../contexts/useModal"
/*Services*/
import { getFixedIncomeInformation } from "../../../../services/asyncFunctions"
/*Components*/
import { ArrowLoader } from "../../../../components/loaders/arrowLoader"
import { toBRL } from "../../../../utils/formatNumberAndText/toBRLCurrency"
import { toPercent } from "../../../../utils/formatNumberAndText/formatNumberPercent"
import { datetimeToDatePtBR } from "../../../../utils/formatDate/dateTimeToPTBR"
import { FiPlusCircle, FiSettings } from "react-icons/fi"

export function FundsProductsTable() {
    const [loading, setLoading] = useState(false)
    const [informationList, setInformationList] = useState([])
    const { user, tokenKey } = useAuth()
    const { setType, setShow, edited, setEdited, show } = useModal()
    const [, forceUpdate] = useReducer(x => x + 1, 0);

    async function getInfo() {
        setLoading(true)
        const results = await getFixedIncomeInformation(tokenKey, false, "FUNDOS")
        console.log(results)
        // console.log(listTable);
        if (getFixedIncomeInformation?.isError) {
            setType({
                name: "alertMsg",
                type: "information",
                value: {
                    message: getFixedIncomeInformation?.error,
                },
            })
            setShow(true)
        } else {
            setInformationList(results)
            setLoading(false)
            console.log('foi editado?', edited)
            if (edited){
                setType({
                    name: "editScenarios",
                    product_type: "RENDA FIXA",
                    data: results,
                    getInfo: getInfo
                })
                setShow(false)
                setShow(true)
            }
        }
    }

    useEffect(() => {
        setLoading(true)
        getInfo()
    }, [])

    return (
        <>
            {loading ? (
                <ArrowLoader style={{ marginTop: "1rem" }} />
            ) : (
                <>
                    
                    <div className="fixedIncomeProductsContainer" style={{ overflow: "auto", display: "block" }}>
                        <div className="fixedIncomeDataTextBoxes">
                            {informationList.map((item, index) => {

                                return (
                                    <pre className="fixedIncomeDataTextBoxesChild" style={{ fontSize: "1rem", fontFamily: "Arial", padding: "1rem", whiteSpace: "pre-line" }}>
                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                            <h2>{item.title ? item.title : ""}</h2>
                                            <span style={{ color: "#969494", fontWeight: "500" }}>Atualizado em: {item.updated_at ? datetimeToDatePtBR(item.updated_at) : "-"}</span>
                                        </div>
                                        <p style={{ marginTop: "1rem" }}>{item.description}</p>
                                    </pre>
                                )
                            })}
                        </div>
                    </div>
                    {user.type < 3 ? (
                        <>
                            <div style={{ marginTop: "1rem", display: "flex", float: "right", placeContent: "end", alignItems: "center" }}>
                                <div
                                    style={{
                                        alignSelf: "center",
                                        cursor: "pointer",
                                        // background: "#BBBBBB",
                                        alignItems: "center",
                                        display: "flex",
                                        padding: "5px 10px 5px 10px",
                                        color: "#FDFDFD",
                                        fontWeight: "bold",
                                        borderRadius: "10px",
                                    }}
                                    className="investimentcards"
                                    onClick={() => {
                                        setType({
                                            name: "editScenarios",
                                            product_type: "RENDA FIXA",
                                            data: informationList,
                                            getInfo: getInfo
                                        })
                                        setShow(true)
                                    }}
                                >
                                    Gerenciar Cards &nbsp;
                                    <FiSettings size={24} />
                                </div>
                                <br />
                            </div>
                        </>
                    ) : (
                        <></>
                    )}
                </>
            )}
        </>
    )
}
