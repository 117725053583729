import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { FiArrowRight, FiPlus, FiTrash2 } from 'react-icons/fi'
import { BsArrowLeftRight, BsArrowRight } from 'react-icons/bs'
/*Styles*/
import './styles/brokerPanel.css'
import { api } from '../../../services/api'
/*Contexts*/
import { useAuth } from '../../../contexts/useAuth'
import { useModal } from '../../../contexts/useModal'
/*Components*/
import Modal from '../../../components/modal/modal'
import { TradeIdeasText } from './tradeIadeasText'
import { ArrowLoader } from '../../../components/loaders/arrowLoader'
import { TileLoader } from '../../../components/loaders/tileLoader';
import ObjectivesImport from './objectivesImport'
import { ConfirmationBox } from '../../../components/confirmationBox/confirmationBox'
import { BrokerPanelLinkRegister } from './brokerPanelLinkRegister'
/*Services*/
import { getTradeIdeas, getBrokerPanelLeftLinks, getBrokerPanelGoals, getBrokerPanelResults } from '../../../services/asyncFunctions';
/*Utils*/
import { ModalSizeFitToScreen } from '../../../utils/responsiveFunctions/modalSizeFitToScreen'
import { toBRL } from '../../../utils/formatNumberAndText/toBRLCurrency'
import GoalsImport from './goalsImport'

export function BrokerPanel() {

    const token = localStorage.getItem('@auth:token')
    const [loading, setLoading] = useState(false)
    const [loadingTradeIdeas, setLoadingTradeIdeas] = useState(true)
    const [loadingBrokerLinks, setLoadingBrokerLinks] = useState(true)
    const [loadingBrokerResults, setLoadingBrokerResults] = useState(true)
    const [reports, setReports] = useState([])
    const { type, setType, show, setShow, edited, setEdited } = useModal()
    const { user, tokenKey } = useAuth()
    const history = useHistory()
    const [tradeIdeasList, setTradeIdeasList] = useState([])
    const { screenX } = ModalSizeFitToScreen()
    const [brokerPanelLeftLinks, setBrokerPanelLeftLinks] = useState([])
    const [brokerPanelCategoryLinks, setBrokerPanelCategoryLinks] = useState([])
    const [brokerPanelResults, setBrokerPanelResults] = useState([])
    const [restMonth, setRestMonth] = useState(0)
    const now = new Date

    async function getTradeIdeasText() {

        setLoadingTradeIdeas(true)
        const ideas = await getTradeIdeas(tokenKey)

        if (ideas.notExist) {
            setTradeIdeasList([])
            setLoadingTradeIdeas(false)
        }
        else if (ideas?.isError) {
            setType({
                name: 'alertMsg',
                type: 'information',
                value: {
                    message: ideas?.error
                }
            })
            setShow(true);
            setLoadingTradeIdeas(false)
        } else {

            const newIdeas = ideas.map((details) => {

                let newDetails = details.details

                while (newDetails.indexOf(`!!!!`) > -1) {
                    newDetails = newDetails.replace(`!!!!`, `"`)
                }

                while (newDetails.indexOf(`<p></p>`) > -1) {
                    newDetails = newDetails.replace(`<p></p>`, `<br>`)
                }

                return {
                    detaisl: newDetails,
                    id: details?.id,
                    title: details?.title
                }
            })

            setTradeIdeasList(newIdeas)
            setLoadingTradeIdeas(false)
        }
    }

    async function getBrokerPanelListLinksAPI() {

        setLoadingBrokerLinks(true)
        const panelLinks = await getBrokerPanelLeftLinks(tokenKey)
        if (panelLinks?.isError) {
            setType({
                name: 'alertMsg',
                type: 'information',
                value: {
                    message: panelLinks?.error
                }
            })
            setShow(true);
        } else {

            let categorys = []
            if (panelLinks.length > 0) {
                panelLinks.map((link) => {
                    if (categorys.indexOf(link.category) === -1) {
                        categorys.push(link.category)
                    }
                })
                setBrokerPanelCategoryLinks(categorys)
                setBrokerPanelLeftLinks(panelLinks)
            } else {
                setBrokerPanelCategoryLinks([])
                setBrokerPanelLeftLinks([])
            }
            setLoadingBrokerLinks(false)
        }
    }

    async function getBrokerPanelResultsAPI() {

        setLoadingBrokerResults(true)
        const panelResults = await getBrokerPanelResults(tokenKey, user.external_id, now.getMonth() + 1, now.getFullYear())
        if (panelResults?.isError) {
            setType({
                name: 'alertMsg',
                type: 'information',
                value: {
                    message: panelResults?.error
                }
            })
            setShow(true);
            setLoadingBrokerResults(false)
        } else {
            setBrokerPanelResults(panelResults)
            setLoadingBrokerResults(false)
        }
    }

    function openLink(link) {

        if (link.indexOf('http') === -1) {
            link = `http://${link}`
        }
        window.open(link);
    }

    const handleDeleteIdea = async (idIdea) => {

        setShow(false)
        setLoadingTradeIdeas(true)
        try {

            await api.delete(`/api/v1/tradeIdeas/${idIdea.id}`, {
                headers: {
                    authorization: `Bearer ${tokenKey}`
                }
            })

            setType({ name: 'alertMsg', type: 'information', value: { message: 'Sucesso ao remover o texto' } })
            setShow(true)
            getTradeIdeasText()
        } catch (error) {
            console.log(error?.response)
            setType({ name: 'alertMsg', type: 'information', value: { message: error?.response?.data?.message === undefined ? 'Erro ao remover texto' : error?.response?.data?.message } })
            setShow(true)
            setLoadingTradeIdeas(false)
        }

    }

    useEffect(() => {

        if (edited || tradeIdeasList.length === 0 || reports.length === 0 || brokerPanelLeftLinks.length === 0) {
            setLoading(true)
            getData()
            getTradeIdeasText()
            getBrokerPanelListLinksAPI()
            user.type === 3 && getBrokerPanelResultsAPI()
            setEdited(false)
            setRestMonth(22 - now.getDate())
        }
    }, [edited])

    async function getData() {

        try {
            const getReports = await api.get('/api/v1/reports', {
                headers: {
                    authorization: `Bearer ${token}`
                }
            })

            let periodicReports = []
            getReports.data.data.filter((isPeriodicReport) => {
                if (isPeriodicReport.periodic) {
                    periodicReports.push(isPeriodicReport)
                }
            })
            setReports(periodicReports)
            setLoading(false)
        } catch (error) {
            setLoading(false)
            console.log(error?.response)
            setType({ name: 'alertMsg', type: 'information', value: { message: error?.response?.data?.message === undefined ? 'Erro ao localizar texto' : error?.response?.data?.message } })
            setShow(true)
        }
    }

    async function getReport(pathLink, title, isFile) {

        if (pathLink == undefined || pathLink == '') {
            return alert('Não foi atrelado nenhum Link ou Arquivo a este FAQ.')
        }
        if (isFile == 0) {
            if (new RegExp("^(https?://)", "i").test(pathLink)) {
                return window.open(pathLink)
            }
            return window.open("http://" + pathLink)
        } else {
            setLoading(true)
            try {
                const fileExtensio = ['.xls', '.xlsx', '.png', '.jpeg', '.gif', '.pjpeg', '.pdf', 'text/plain', '.zip']
                const fileContentTypes = [
                    'application/vnd.ms-excel',
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                    'image/png',
                    'image/jpeg',
                    'image/gif',
                    'image/pjpeg',
                    'application/pdf',
                    'text/plain',
                    'application/zip'
                ]
                const responseFile = await api.get('/api/v1/uploads/reports/' + pathLink, {
                    headers: {
                        authorization: `Bearer ${token}`
                    },
                    responseType: 'blob'
                })

                const url = URL.createObjectURL(new Blob([responseFile.data]))
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${title}${fileExtensio[fileContentTypes.indexOf(responseFile.data.type)]}`)
                document.body.appendChild(link)
                link.click()
                setLoading(false)
                return;
            } catch (error) {
                setLoading(false)
                if (error.response?.data?.message == undefined) {
                    return alert('Erro ao tentar buscar relatório. Se o problema persistir entre em contato com algum Backoffice ou Administrador do sistema.')
                }
                return alert(error.response?.data?.message)
            }
        }
    }

    async function handleDeleteBrokerLink(link) {

        setLoadingBrokerLinks(true)
        try {
            await api.delete(`/api/v1/brokerPanel/${link}`, {
                headers: {
                    authorization: `Bearer ${tokenKey}`
                }
            })
            setType({ name: 'alertMsg', type: 'information', value: { message: 'Link removido com sucesso' } })
            setShow(true)
            getBrokerPanelListLinksAPI()
        } catch (error) {
            console?.log(error?.response)
            setType({ name: 'alertMsg', type: 'information', value: { message: error?.response?.data?.message === undefined ? 'Erro ao remover link' : error?.response?.data?.message } })
            setShow(true)
            setLoading(false)
        }
    }

    return (
        <>
            <Modal>
                {
                    type.name === 'alertMsg' ?
                        <ConfirmationBox msgData={type} />
                        :
                        type.name === 'showEditTradeIdeasText' ?
                            (
                                <TradeIdeasText />
                            ) :
                            type.name === 'showUpdateObjectives' ?
                                (
                                    <ObjectivesImport />
                                ) :

                                type.name === 'brokerPanelLinkRegister' ?
                                    (<BrokerPanelLinkRegister />) :
                                    type.name === 'showUpdateResults' ?
                                    (<GoalsImport />) 
                                    :
                                    (<></>)
                }
            </Modal>
            <div className='brokerPanelMainContainer' style={{ minWidth: '1300px' }}>
                <div id='brokerPanelLeftSide' style={{ overflowY: 'auto' }}>
                    <div className='brokerPanelLeftMenu'>
                        <div id='brokerPanelNewLinkButtonDiv'>
                            {user?.type < 3 && <button onClick={() => { if (!loading) { setType({ name: 'brokerPanelLinkRegister', categorys: brokerPanelCategoryLinks }); setShow(true) } }} >Novo link <FiPlus /></button>}
                        </div>
                    </div>
                    {
                        loadingBrokerLinks ?
                            <div className='brokerPanelLeftMenuLoading'>
                                <label className='tileLoaderSm'><TileLoader /></label>
                                <label><TileLoader /></label>
                            </div>
                            :
                            brokerPanelLeftLinks.length > 0 &&
                            brokerPanelCategoryLinks.map((linkPanel) => {
                                return (
                                    <div className='brokerPanelLeftMenu'>
                                        <a className='brokerPanelLeftTitle'>{linkPanel}</a>
                                        {
                                            brokerPanelLeftLinks.map((link) => {
                                                if (link.category === linkPanel) {
                                                    return (
                                                        <label><a onClick={() => {
                                                            openLink(link.url_link)
                                                        }}>{link?.title}</a>
                                                            {user.type < 3 && <FiTrash2 onClick={() => handleDeleteBrokerLink(link?.id)} title='Clique para excluir esse link' />}
                                                        </label>
                                                    )
                                                }
                                            })
                                        }
                                    </div>
                                )
                            })
                    }

                    <div className='brokerPanelLeftMenu'>
                        <a className='brokerPanelLeftTitle'>Links</a>
                        <label><a>Vba Cs</a><BsArrowRight size='20' /></label>
                        <label title='Clique para ir a boletera' onClick={() => history.push('/ordensPush')}><a>Boletera</a><BsArrowRight size='20' /></label>
                        <label><a>Monitor</a><BsArrowRight size='20' /></label>
                        <label><a>Custódia</a><BsArrowRight size='20' /></label>
                    </div>
                </div>
                <div id='brokerPanelRightSide'>
                    <div id='brokerPanelUpper'>
                        <div id='brokerPanelUpperLeftSide'>
                            <div id='brokerPanelObjectives'>
                                {
                                    user.type < 3 ?
                                        (
                                            <>
                                                <div className='brokerPanelUpdateViaImport'>
                                                    <p>Atualizar valores de objetivos</p>
                                                    <button onClick={() => { setType({ name: 'showUpdateObjectives' }); setShow(true) }} id='brokerPanelUpdateButton'>Atualizar</button>
                                                </div>
                                                <div className='brokerPanelUpdateViaImport'>
                                                    <p>Atualizar valores de receitas</p>
                                                    <button onClick={() => { setType({ name: 'showUpdateResults' }); setShow(true) }} id='brokerPanelUpdateButton'>Atualizar</button>
                                                </div>
                                            </>
                                        ) :
                                        loadingBrokerResults ?
                                            (
                                                <>
                                                    <div className='tileLoaderSm'>
                                                        <TileLoader />
                                                    </div>
                                                    <div className='tileLoaderFullW'>
                                                        <TileLoader />
                                                    </div>
                                                </>

                                            )
                                            :
                                            (
                                                <>
                                                    <label>
                                                        <a>Receita do Mês</a>
                                                        <a>{brokerPanelResults.length > 0 ? toBRL(brokerPanelResults[0]?.month_result) : toBRL(0)}</a>
                                                    </label>
                                                    <label>
                                                        <a>Objetivo pessoal</a>
                                                        <a>{brokerPanelResults?.length > 0 ? toBRL(brokerPanelResults[0]?.month_goal) : toBRL(0)}</a>
                                                    </label>
                                                    <label>
                                                        <a>%</a>
                                                        <a>{typeof(((brokerPanelResults[0]?.month_result / brokerPanelResults[0]?.month_goal) * 100).toFixed(2)) === 'string' ? 0 : ((brokerPanelResults[0]?.month_result / brokerPanelResults[0]?.month_goal) * 100).toFixed(2)}%</a>
                                                    </label>
                                                    <label>
                                                        <a>Projetado</a>
                                                        <a>{brokerPanelResults.length > 0 ? toBRL(brokerPanelResults[0]?.month_result / now.getDate()) : toBRL(0)}</a>
                                                    </label>
                                                    <label>
                                                        <a>Meta diária para objetivo</a>
                                                        <a>{brokerPanelResults.length > 0 ? toBRL(((brokerPanelResults[0]?.month_goal - brokerPanelResults[0]?.month_result) / restMonth)) : toBRL(0)}</a>
                                                    </label>
                                                </>
                                            )
                                }
                            </div>
                        </div>
                        <div id='brokerPanelUpperRightSide'>
                            <div id='brokerPanelReports'>
                                <label>
                                    <h3>Relatórios periódicos</h3>
                                    <FiArrowRight />
                                </label>
                                <div id='brokerPanelReportsCotent'>
                                    {
                                        loading ?
                                            <div className='tileLoaderGroup'>
                                                <div className='tileLoaderFullW'>
                                                    <TileLoader />
                                                </div>
                                                <div className='tileLoaderFullW'>
                                                    <TileLoader />
                                                </div>
                                            </div>

                                            :
                                            <table>
                                                <tbody>
                                                    {reports.map((report) => (
                                                        <tr>
                                                            <td onClick={() => getReport(report.path_link, report.title, report.is_file)}>{report.title}</td>
                                                        </tr>

                                                    ))}
                                                </tbody>
                                            </table>
                                    }
                                </div>
                            </div>
                            <div id='brokerPanelRecommendedWallet'>
                                <h3>Carteira recomendada mesa</h3>
                                <a title='Desativado'>Acessar</a>
                            </div>
                        </div>
                    </div>
                    <div id='brokerPanelbottom'>
                        <div id='brokerPanelbottonTradeIdeaHeader'>
                            <h2>Trade Ideias</h2>
                            {user.type < 3 && <button onClick={() => { if (user.type < 3) { setType({ name: 'showEditTradeIdeasText', value: 'cashOperations' }); setShow(true) } }}>Novo Texto</button>}
                        </div>
                        <div id='brokerPanelBottonOvergflownedPanel' style={{ height: loadingTradeIdeas && '80%', justifyContent: loadingTradeIdeas && 'center' }}>
                            {
                                loadingTradeIdeas ?
                                    (
                                        <div id='tradeIdeasLoaderGroup'>
                                            <div className='tileLoaderGroup'>
                                                <div className='tileLoaderSm'>
                                                    <TileLoader />
                                                </div>
                                                <div className='tileLoaderLg'>
                                                    <TileLoader />
                                                </div>
                                            </div>
                                            <div className='tileLoaderGroup'>
                                                <div className='tileLoaderLg'>
                                                    <TileLoader />
                                                </div>
                                                <div className='tileLoaderFullW'>
                                                    <TileLoader />
                                                </div>
                                                <div className='tileLoaderFullW'>
                                                    <TileLoader />
                                                </div>
                                            </div>
                                        </div>

                                    ) :
                                    <div id='brokerPanelTextGroupDiv' style={{ gridTemplateColumns: tradeIdeasList.length > 0 && screenX > 1366 ? 'repeat(2,1fr)' : '1fr' }}>
                                        {
                                            tradeIdeasList.length > 0 ?
                                                tradeIdeasList.map((thisIdea) => {
                                                    return <div className='brokerPanelTradeIdeaContainerDiv'>
                                                        <FiTrash2 onClick={() => {
                                                            setType({ name: 'alertMsg', value: { message: 'Deseja realmente excluir este texto?', func: handleDeleteIdea, dataForFunc: thisIdea } });
                                                            setShow(true)
                                                        }}
                                                            style={{
                                                                fontSize: '1.2rem', opacity: user.type < 3 ? '1' : '0',
                                                                cursor: user.type < 3 ? 'pointer' : 'default'
                                                            }}
                                                            title={user.type < 3 ? 'Clique para remover esse card' : ''}
                                                        />
                                                        <label style={{ cursor: user.type < 3 ? 'pointer' : 'default' }} title={user.type < 3 ? 'Clique para criar um novo texto' : ''} onClick={() => { if (user.type < 3) { setType({ name: 'showEditTradeIdeasText', value: 'cashOperations', textName: thisIdea.title, tradeIdeaId: thisIdea.id, textMessage: thisIdea.detaisl }); setShow(true) } }}>
                                                            <a>{thisIdea?.title}</a>
                                                            <div className='brokerPanelTextDiv' dangerouslySetInnerHTML={{ __html: thisIdea.detaisl }}></div>
                                                        </label>
                                                    </div>

                                                }) :
                                                <div className=''>
                                                    <label >
                                                        <a></a>
                                                        <div className='brokerPanelTextDiv'><a>Lista Vazia</a></div>
                                                    </label>
                                                </div>
                                        }
                                    </div>
                            }
                        </div>
                    </div>
                </div>

            </div >
        </>

    )
}