import { api } from '../../../services/api';
import { useEffect, useState, useRef, useReducer } from 'react';
import MUIDataTable from "mui-datatables"

import { useAuth } from "../../../contexts/useAuth";
import { useModal } from "../../../contexts/useModal"
import Modal from "../../../components/modal/modal"

import { ArrowLoader } from "../../../components/loaders/arrowLoader"
import { toBRL, toBRLInteger } from "../../../utils/formatNumberAndText/toBRLCurrency"
import { toPercent } from "../../../utils/formatNumberAndText/formatNumberPercent"
import { TableCell } from "@mui/material"
import { BsQuestionCircle } from "react-icons/bs"
import { AiOutlineLeftSquare, AiOutlineRightSquare } from "react-icons/ai"
import { AiOutlineArrowUp, AiOutlineArrowDown } from "react-icons/ai"
import { FaExclamationTriangle } from "react-icons/fa"
import { BsFillSquareFill } from "react-icons/bs"
import { debounceSearchRender } from "mui-datatables"
import { utils, write } from "xlsx"
import { saveAs } from "file-saver"
import { ThemeProvider, createTheme } from "@mui/material/styles"
import { FaPencilAlt, FaLink } from 'react-icons/fa';

/*Utils*/
import './dashboard.css'
// import { log } from 'logrocket';
import ModalCommentBaterBase from './ModalCommentBaterBase';
import { TableRow } from '@mui/material';
import ListCampainsBaterBaseModal from './listCampainsBaterBaseModal';
import CurrencyTextField from "@unicef/material-ui-currency-textfield"
import CustomFooter from "./CustomFooterTableDashAdvisor"
import CustomFooterBaterBase from './CustomFooterTableDashBaterBase';
import "./styles/stylesPortal360.css"


export function DashBaterBaseCampanhas() {
    const { user, tokenKey } = useAuth();
    const { show, setShow, setType, edited, type } = useModal()
    const [loading, setLoading] = useState(true);
    const selectedCampanha = useRef()
    const [loadingTable, setLoadingTable] = useState(false)
    const [alturaTabela, setAlturaTabela] = useState(null)
    const [lastPage, setLastPage] = useState(0)
    const [currentPage, setCurrentPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(150)
    const [, forceUpdate] = useReducer((x) => x + 1, 0)
    const [lenghtClients, setLenghtClients] = useState(0)
    const campanhas = useRef([])
    const listFilteredClients = useRef([])
    const sum = useRef({})
    const sumNet = useRef(0)
    const sumRoa = useRef(0)
    const visibleColumnsRef = useRef([])
    const listSearchedClients = useRef([])
    const filtersList = useRef({})
    const listClients = useRef([])
    const linesCount = useRef(0)
    const positions = useRef({ currentPage: 0, rowsPerPage: 50 })
    const [search, setSearch] = useState("")
    const tabActive = useRef()
    const clientsResult = useRef()
    const lastSortOrder = useRef({
        name: "nome",
        direction: "asc",
    })
    const refDataTable = useRef(null)
    const tableChanged = useRef(false)
    const [anythingChanged, setAnythingChanged] = useState(false)
    const cloneListFilteredClients = useRef([])
    const [isChecked, setIsChecked] = useState(true);

    function handleSubmit(...props) {

    }

    useEffect(() => {
        const updateAlturaTabela = () => {
            const altura =
                window.innerHeight <= 850 && window.devicePixelRatio < 1.26
                    ? window.innerHeight * window.devicePixelRatio * 0.92
                    : window.innerHeight <= 650 && window.devicePixelRatio > 1.2
                        ? window.innerHeight * window.devicePixelRatio * 0.90
                        : window.innerHeight * window.devicePixelRatio
            setAlturaTabela(altura - 417 * window.devicePixelRatio)
        }

        updateAlturaTabela()

        window.addEventListener("resize", updateAlturaTabela)

        return () => {
            window.removeEventListener("resize", updateAlturaTabela)
        }
    }, [window.innerHeight, window.devicePixelRatio])

    // carregar os dados da tabela quando a pagina é mudada ou a campainha é mudada
    useEffect(() => {
        cloneListFilteredClients.current = lenghtClients
        assembleTable()
        forceUpdate()

    }, [currentPage, selectedCampanha.current])

    useEffect(() => {
        cloneListFilteredClients.current = lenghtClients
        // linesCount.current = listcl.current.length
        assembleTable()
        forceUpdate()
    }, [rowsPerPage])

    useEffect(() => {
        listClients.current = lenghtClients
        linesCount.current = lenghtClients.length
        assembleTable()
        forceUpdate()
    }, [selectedCampanha.current])

    useEffect(() => {
        listClients.current = listFilteredClients.current.slice(0, rowsPerPage + 501)
        cloneListFilteredClients.current = lenghtClients.current
        assembleTable()
        forceUpdate()
    }
        , [listFilteredClients.current])

    const getMuiTheme = () =>
        createTheme({
            components: {
                MuiTableCell: {
                    styleOverrides: {
                        root: {
                            textAlign: "center",
                            justifyContent: "center",
                            whiteSpace: "nowrap",
                            backgrounColor: "white",
                            fontSize: "0.8rem",
                            padding: tabActive.curret == 1 ? '0.25rem ' : '0rem',
                        },
                    },
                },
                MuiToolbar: {
                    styleOverrides: {
                        root: {
                            minHeight: "20px !important",
                        },
                    },
                },
                MuiCheckbox: {
                    styleOverrides: {
                        root: {
                            marginLeft: "10px", // Aumenta o padding dos checkboxes para 10px
                        },
                    },
                },

                MUIDataTableHeadCell: {
                    styleOverrides: {
                        contentWrapper: {
                            justifyContent: "center",
                            whiteSpace: "pre-wrap",
                        },
                    },
                },
                MUIDataTable: {
                    styleOverrides: {
                        root: {
                            width: "75vw",
                        },
                    },
                },
                MUIDataTableFilter: {
                    styleOverrides: {
                        root: {
                            minWidth: "40vw",
                            maxWidth: "50vw",
                        },
                    },
                },
                MuiTableRow: {
                    styleOverrides: {
                        root: {
                            "&:hover ": {
                                backgroundColor: "#e7e7e7 !important",
                            },
                            "&: hover td": {
                                backgroundColor: "#e7e7e7 !important",
                            },
                        },
                    },
                },
            },
            root: {
                "&::-webkit-scrollbar": {
                    width: 7,
                },
                "&::-webkit-scrollbar-track": {
                    boxShadow: `inset 0 0 6px #bea032`,
                },
                "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "#bea032",
                    outline: `1px solid slategrey`,
                },
            },
        })


    const handleCheckboxChange = () => {
        setIsChecked(!isChecked); // Inverte o valor atual do estado
    };

    const AddButton = () => (
        <>
            <span
                onClick={() => {
                    updateBaterBase()

                    getCampainsBaterBase()

                    assembleTable()
                    forceUpdate()
                    setAnythingChanged(false)
                }
                }
                style={{
                    background: '#C3980D',
                    marginTop: '0.4rem',
                    display: 'flex',
                    alignItems: 'center',
                    padding: '5px 10px',
                    color: 'white',
                    fontWeight: '400',
                    borderRadius: '10px',
                    cursor: 'pointer',
                }} >
                Salvar Modificações
            </span>
        </>
    )

    function doSum() {
        campanhas?.current?.forEach((p) => {
            sum.current["c" + p.id] = { oportunity: 0, done: 0, receita: 0, receitaOp: 0, receitaRe: 0 }
        })
        campanhas?.current?.forEach((p) => {
            listFilteredClients?.current?.forEach((c) => {
                if (c["c" + p.id + "__status"] !== "RECUSADO" && c["c" + p.id + "__status"] !== "NÃO ADERENTE" && c["c" + p.id + "__status"] !== "CANCELADO") {
                    if (c["c" + p.id + "__objective"] !== null) {
                        sum.current["c" + p.id].oportunity += c["c" + p.id + "__objective"]
                    }

                    if (c["c" + p.id + "__value"] !== null) {
                        sum.current["c" + p.id].done += c["c" + p.id + "__value"]
                    }

                    sum.current["c" + p.id].receitaOp = sum.current["c" + p.id].oportunity * p.fee
                    sum.current["c" + p.id].receitaRe = sum.current["c" + p.id].done * p.fee
                }
            })
        })

        //soma os Nets
        sumNet.current = 0
        listFilteredClients?.current.forEach((c) => {
            sumNet.current += c["net_total"]
        })

        sumRoa.current = 0
        listFilteredClients?.current.forEach((c) => {
            sumRoa.current += c["roa"]
        })
        sumRoa.current = sumRoa.current / listFilteredClients?.current.length

    }

    async function exportDash() {
        var titles = []
        visibleColumnsRef.current.forEach((e) => {
            if (e.options.display) {
                titles.push(e.label)
            }
        })
        var data = []
        listSearchedClients.current.forEach((row) => {
            var temp = []
            visibleColumnsRef.current.forEach((e) => {
                if (e.options.display) {
                    temp.push(row[e.name])
                }
            })
            data.push(temp)
        })
        var csv = titles.join(";") + "\n"
        data.forEach((row) => {
            csv += row.join(";") + "\n"
        })
        var hiddenElement = document.createElement("a")
        hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(csv)
        hiddenElement.target = "_blank"
        hiddenElement.download = "abc.csv"
        hiddenElement.click()
    }

    useEffect(() => {
        assembleTable()
        forceUpdate()
    }, [])

    async function updateBaterBase() {
        // return
        setLoadingTable(true)
        setAnythingChanged(false)
        try {
            const data = await api.put(`api/v1/alocacao/baterBase/campains/${selectedCampanha.current.id}`, {
                clientsEdited: cloneListFilteredClients.current.filter((c) => c.changed === true)
            }, {
                headers: {
                    authorization: `Bearer ${tokenKey}`
                }
            })
            return
            assembleTable()
            setLoadingTable(false)
            forceUpdate()
            return data?.data?.data
        }
        catch (error) {
            console.log(error?.response)
            alert('Erro ao salvar as modificações')
            setLoadingTable(false)
        }
        getCampainsBaterBase()
    }

    function formatValue(value) {
        if (value === null) {
            return ''
        }
        return value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
    }


    // async function getCampainsBaterBase(response) {    
    //     setLoading(true)
    //     try {
    //         const data = await api.get(`api/v1/alocacao/baterBase/campains`, {
    //             headers: {
    //                 authorization: `Bearer ${tokenKey}`
    //             }
    //         })


    //         // setCampanhas(data?.data?.data)
    //         campanhas.current = data?.data?.data?.products
    //         clientsResult.current = data?.data?.data
    //         console.log('clientsResult', clientsResult.current) 
    //         listSearchedClients.current = clientsResult?.current?.table
    //         listClients.current = clientsResult?.current?.table.slice(0, rowsPerPage + 501)
    //         listFilteredClients.current = data?.data?.data?.table
    //         isChecked ? listFilteredClients.current = listFilteredClients.current.filter((c) => c["c" + selectedCampanha?.id + "__operation_id"] != null) : listFilteredClients.current = listFilteredClients.current
    //         cloneListFilteredClients.current = listFilteredClients.current
    //         setSelectedCampanha(data?.data?.data?.products[0])
    //         doSum()
    //         assembleTable()
    //         console.log('campanhas', campanhas.current)
    //         console.log('dataBaterBase', data?.data?.data)

    //         setLoading(false)

    //         return data?.data?.data

    //     } catch (error) {
    //         console.log(error?.response)
    //         return ({
    //             isError: true,
    //             error: error?.response?.data?.message === undefined ? error?.response?.status === 404 ?
    //                 'Informações do bater base não localizada.' :
    //                 'Erro ao realizar a busca das informações do bater base.' :
    //                 error?.response?.data?.message
    //         })
    //     }
    // }

    //atualiza a tabela quando o filtro é alterado
    useEffect(() => {
        forceUpdate()
        assembleTable()
    }, [listClients, listFilteredClients, listSearchedClients, listFilteredClients, selectedCampanha.current, isChecked, cloneListFilteredClients])

    useEffect(() => {
        forceUpdate()
        assembleTable()
    }, [show])

    let data;
    let products;
    let table;
    async function getCampainsBaterBase() {
        setLoadingTable(true);
        try {
            const response = await api.get(`api/v1/alocacao/baterBase/campains/${selectedCampanha.current?.id}`, {
                headers: {
                    authorization: `Bearer ${tokenKey}`
                }
            });

            data = response?.data?.data;
            tabActive.current = 0;
            
            products = data?.products;
            table = data?.table
            setLenghtClients(user.type === 4  && user.is_leader ? table.filter(c => c.advisor_id === user.external_id) : user.type === 3 && user.is_leader ? table.filter(c => c.broker_id === user.external_id) : table);
            linesCount.current = table.length;
            selectedCampanha.current = products[0];

            campanhas.current = products;
            clientsResult.current = data;
            listFilteredClients.current = /* isChecked ? table.filter(c => c["c" + selectedCampanha?.id + "__operation_id"] != null) : */ table;
            listSearchedClients.current = listFilteredClients.current;
            listClients.current = lenghtClients
            cloneListFilteredClients.current = [...listFilteredClients.current];
            
            doSum();
            forceUpdate();
            assembleTable();

            setLoadingTable(false);
            setLoading(false);

            return data;
        } catch (error) {
            console.log(error?.response);

            const errorMessage = error?.response?.data?.message || (error?.response?.status === 404 ?
                'Informações do bater base não localizadas.' :
                'Erro ao realizar a busca das informações do bater base.');

            // setLoadingTable(false);

            return { isError: true, error: errorMessage };
        }
        setLoadingTable(false);
    }

    // async function getData(){
    //     // campanhas.current = products;
    //     // clientsResult.current = data;
    //     // // listFilteredClients.current = isChecked ? table.filter(c => c["c" + selectedCampanha?.id + "__objective"] != 0) : table;
    //     // listFilteredClients.current = isChecked ? table?.filter(c => c["c" + selectedCampanha?.id + "__operation_id"] != null) : table;
    //     // console.log('listFilteredClients', listFilteredClients.current)
    //     // listSearchedClients.current = listFilteredClients.current;
    //     // listClients.current = listFilteredClients.current?.slice(0, rowsPerPage + 501);
    //     // cloneListFilteredClients.current = [...listFilteredClients.current];
    // }

    // useEffect(async () => {
    //     await getData();
    //     assembleTable();
    // }, [isChecked]);


    useEffect(() => {
        // getIdsCampains()
        getCampainsBaterBase()

    }, [])

    // useEffect(() =>{
    //     if(selectedCampanha.current != []){
    //         getCampainsBaterBase()
    //     }

    // }, [selectedCampanha.current])


    // useEffect(() => {
    //     listClients.current = cloneListFilteredClients.current?.slice(0, rowsPerPage + 501)
    //     assembleTable()
    // }, [anythingChanged, listClients, listFilteredClients, selectedCampanha.current, isChecked])

    async function assembleTable() {
        visibleColumnsRef.current = [
            {
                name: "client_id",
                label: <span>Código <br /> Cliente</span>,
                options: {
                    filter: true,
                    sort: true,
                    sortDescFirst: true,
                    display: true,
                    customHeadRender: ({ index, name, label, ...column }, sortColumn, tableMeta) => {
                        return (
                            <>
                                <TableCell
                                    className="TableCell"
                                    onClick={() => {
                                        sortColumn(index)
                                    }}
                                    style={{
                                        textAlign: "center",
                                        minWidth: "13rem",
                                        whiteSpace: "nowrap",
                                        position: "sticky",
                                        left: 0,
                                        zIndex: 123,
                                        backgroundColor: "white",
                                    }}
                                >
                                    <div className="containerTableCellButtonBaterBase">
                                        <div>
                                            {label}
                                            <div className="arrowSortAbc">
                                                {tableMeta.name === name ? tableMeta.direction == "asc" ? <AiOutlineArrowUp size={15} /> : <AiOutlineArrowDown size={15} /> : <></>}
                                            </div>
                                        </div>
                                    </div>
                                </TableCell>
                            </>
                        )
                    },
                    setCellProps: () => ({
                        style: {
                            paddingLeft: "0.5rem",
                            paddingRight: "0.5rem",
                            position: "sticky",
                            left: "0rem",
                            backgroundColor: "white",
                            zIndex: 122,
                        },
                    }),
                },
            },
            {
                name: "client",
                label: "Cliente",
                options: {
                    filter: true,
                    sort: true,
                    sortDescFirst: true,
                    display: true,
                    customHeadRender: ({ index, name, label, ...column }, sortColumn, tableMeta) => {
                        return (
                            <>
                                <TableCell
                                    className="TableCell"
                                    onClick={() => {
                                        sortColumn(index)
                                    }}
                                    style={{
                                        textAlign: "center",
                                        minWidth: "13rem",
                                        whiteSpace: "nowrap",
                                        position: "sticky",
                                        left: '13rem',
                                        zIndex: 123,
                                        backgroundColor: "white",
                                    }}
                                >
                                    <div className="containerTableCellButtonBaterBase">
                                        <div>
                                            {label}
                                            <div className="arrowSortAbc">
                                                {tableMeta.name === name ? tableMeta.direction == "asc" ? <AiOutlineArrowUp size={15} /> : <AiOutlineArrowDown size={15} /> : <></>}
                                            </div>
                                        </div>
                                    </div>
                                </TableCell>
                            </>
                        )
                    },
                    setCellProps: () => ({
                        style: {
                            paddingLeft: "0.5rem",
                            paddingRight: "0.5rem",
                            position: "sticky",
                            left: "13rem",
                            backgroundColor: "white",
                            zIndex: 122,
                        },
                    }),
                },
            },
            {
                name: "advisor",
                label: "Assessor",
                options: {
                    filter: true,
                    sort: true,
                    sortDescFirst: true,
                    display: true,
                    customHeadRender: ({ index, name, label, ...column }, sortColumn, tableMeta) => {
                        return (
                            <>
                                <TableCell
                                    className="TableCell"
                                    onClick={() => {
                                        sortColumn(index)
                                    }}
                                    style={{
                                        textAlign: "center",
                                        minWidth: "13rem",
                                        whiteSpace: "nowrap",
                                        backgroundColor: "white",
                                    }}
                                >
                                    <div className="containerTableCellButtonBaterBase">
                                        <div>
                                            {label}
                                            <div className="arrowSortAbc">
                                                {tableMeta.name === name ? tableMeta.direction == "asc" ? <AiOutlineArrowUp size={15} /> : <AiOutlineArrowDown size={15} /> : <></>}
                                            </div>
                                        </div>
                                    </div>
                                </TableCell>
                            </>
                        )
                    },
                    setCellProps: () => ({
                        style: {
                            paddingLeft: "0.5rem",
                            paddingRight: "0.5rem",
                        },
                    }),
                },
            },
            {
                name: "broker",
                label: "Broker",
                options: {
                    filter: true,
                    sort: true,
                    sortDescFirst: true,
                    display: user.type === 3 ? false : true,
                    customHeadRender: ({ index, name, label, ...column }, sortColumn, tableMeta) => {
                        return (
                            <>
                                <TableCell
                                    className="TableCell"
                                    onClick={() => {
                                        sortColumn(index)
                                    }}
                                    style={{
                                        textAlign: "center",
                                        minWidth: "13rem",
                                        whiteSpace: "nowrap",
                                        backgroundColor: "white",
                                    }}
                                >
                                    <div className="containerTableCellButtonBaterBase">
                                        <div>
                                            {label}
                                            <div className="arrowSortAbc">
                                                {tableMeta.name === name ? tableMeta.direction == "asc" ? <AiOutlineArrowUp size={15} /> : <AiOutlineArrowDown size={15} /> : <></>}
                                            </div>
                                        </div>
                                    </div>
                                </TableCell>
                            </>
                        )
                    },
                    setCellProps: () => ({
                        style: {
                            paddingLeft: "0.5rem",
                            paddingRight: "0.5rem",
                        },
                    }),
                },
            },
            // criar um multi select para o status
            {
                name: `c${selectedCampanha.current?.id}__aderente`,
                label: "Aderente",
                options: {
                    filter: true,
                    sort: true,
                    sortDescFirst: true,
                    display: true,
                    customHeadRender: ({ index, name, label, ...column }, sortColumn, tableMeta) => {
                        return (
                            <>
                                <TableCell
                                    className="TableCell"
                                    onClick={() => {
                                        sortColumn(index)
                                    }}
                                    style={{
                                        textAlign: "center",
                                        whiteSpace: "nowrap",
                                        backgroundColor: "white",
                                    }}
                                >
                                    <div className="containerTableCellButtonBaterBase">
                                        <div>
                                            {label}
                                            <div className="arrowSortAbc">
                                                {tableMeta.name === name ? tableMeta.direction == "asc" ? <AiOutlineArrowUp size={15} /> : <AiOutlineArrowDown size={15} /> : <></>}
                                            </div>
                                        </div>
                                    </div>
                                </TableCell>
                            </>
                        )
                    },
                    setCellProps: () => ({
                        style: {
                            paddingLeft: "0.5rem",
                            paddingRight: "0.5rem",
                        },
                    }),
                    customBodyRender: (val, tableMeta, updateValue) => {
                        const optAderente = {
                            0: "Não",
                            1: "Sim",
                        }


                        return (
                            tabActive.current === 0 ? (
                            <div style={{ marginLeft: '1rem', marginRight: '1rem' }}>
                                <select
                                    // disabled={tableMeta.rowData[4] == 0 ? true : false}
                                    className="selectTacticalAlocationsCategory"
                                    style={{
                                        // border: cloneListFilteredClients.current[tableMeta.tableMeta.rowData[0]][0].changed && cloneListFilteredClients.current[tableMeta.tableMeta.rowData[0]][0]["c" + selectedCampanha.current?.id + "__advisors_responsibility"] !== listFilteredClients.current.find((e) => e.client_id === tableMeta.rowData[0] ? e['c' + selectedCampanha.current?.id + '__advisors_responsibility'] : '' )? '2px solid green' : '1px solid #a0a0a0',
                                        width: '7rem',
                                        height: '25px',
                                        textAlign: 'center',
                                        borderRadius: '10px'
                                    }}
                                    value={val === undefined ||  val == null ? 'Indefinido' : val == 1 ? 1 : 0}
                                    onChange={(e) => {
                                        const client = lenghtClients.find(c => c.client_id === tableMeta.rowData[0]);

                                        lenghtClients.find((c) => c.client_id === tableMeta.rowData[0])['c' + selectedCampanha.current?.id + '__aderente'] = Number(e.target.value)
                                        lenghtClients.find((c) => c.client_id === tableMeta.rowData[0]).changed = true
                                        cloneListFilteredClients.current = (lenghtClients.filter(c => c.changed === true))

                                        setAnythingChanged(true)
                                        updateValue(Number(e.target.value))
                                        return
                                        // if (client) {
                                        //     e.target.value ? (cloneListFilteredClients.current.find((c) => c.client_id === tableMeta.rowData[0]))['c' + selectedCampanha.current?.id + '__aderente'] = Number(e.target.value) : null
                                        //     (cloneListFilteredClients.current.find((c) => c.client_id === tableMeta.rowData[0])).changed = true;
                                        // } else {
                                        // }
                                        
                                        // setAnythingChanged(true)
                                        // updateValue(Number(e.target.value))
                                        // updateValuesList.push(`${tableMeta.rowData[0]}, ${tableMeta.columnData.name}, ${e.target.value}`)
                                    }}
                                >
                                    {val === undefined || val === null && (
                                        <option value="Indefinido">Indefinido</option>
                                    )}
                                    {Object.entries(optAderente).map(([val, label]) => (
                                        <option key={val} value={val}>
                                            {label}
                                        </option>
                                    ))}
                                </select>
                            </div> ) :
                            val == null || val == undefined  ? 'Indefinido' : val === 1 ? 'Sim' : 'Não'
                        )
                    },
                },
            },

            {
                name: "qtd_products",
                label: <span>Oportunidades<br />em Aberto</span>,
                options: {
                    filter: true,
                    sort: true,
                    sortDescFirst: true,
                    display: true,
                    customHeadRender: ({ index, name, label, ...column }, sortColumn, tableMeta) => {
                        return (
                            <>
                                <TableCell
                                    className="TableCell"
                                    onClick={() => {
                                        sortColumn(index)
                                    }}
                                    style={{
                                        textAlign: "center",
                                        whiteSpace: "nowrap",
                                        backgroundColor: "white",
                                    }}
                                >
                                    <div className="containerTableCellButtonBaterBase">
                                        <div>
                                            {label}
                                            <div className="arrowSortAbc">
                                                {tableMeta.name === name ? tableMeta.direction == "asc" ? <AiOutlineArrowUp size={15} /> : <AiOutlineArrowDown size={15} /> : <></>}
                                            </div>
                                        </div>
                                    </div>
                                </TableCell>
                            </>
                        )
                    },
                    setCellProps: () => ({
                        style: {
                            paddingLeft: "0.5rem",
                            paddingRight: "0.5rem",
                        },
                    }),
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <div style={{ marginLeft: '1rem', marginRight: '1rem', minWidth: '2.2rem', display: 'flex', gap: '1rem', justifyContent: 'center' }}>
                                <span > {value ? value : ' - '}</span>
                                {value > 0 && (
                                    <FaLink size={20} onClick={() => {
                                        setType({
                                            name: "listCampains",
                                            campanhas: listFilteredClients.current.find((c) => c.client_id === tableMeta.rowData[0]),
                                            products: campanhas.current,
                                            row: tableMeta.rowData,
                                        })
                                        setShow(true)
                                    }} />
                                )}
                            </div>
                        )
                    }
                },
            },

            {
                name: "c" + selectedCampanha.current?.id + "__objective",
                label: <span>Volume <br />Planejado</span>,
                options: {
                    filter: true,
                    sort: true,
                    sortDescFirst: true,
                    display: true,
                    customHeadRender: ({ index, name, label, ...column }, sortColumn, tableMeta) => {
                        return (
                            <>
                                <TableCell
                                    className="TableCell"
                                    onClick={() => {
                                        sortColumn(index)
                                    }}
                                    style={{
                                        textAlign: "center",
                                        minWidth: "13rem",
                                        whiteSpace: "nowrap",
                                        backgroundColor: "white",
                                    }}
                                >
                                    <div className="containerTableCellButtonBaterBase">
                                        <div>
                                            {label}
                                            <div className="arrowSortAbc">
                                                {tableMeta.name === name ? tableMeta.direction == "asc" ? <AiOutlineArrowUp size={15} /> : <AiOutlineArrowDown size={15} /> : <></>}
                                            </div>
                                        </div>
                                    </div>
                                </TableCell>
                            </>
                        )
                    },
                    setCellProps: () => ({
                        style: {
                            paddingLeft: "0.5rem",
                            paddingRight: "0.5rem",
                        },
                    }),
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <>{toBRL(value)}</>
                        )
                    }
                },
            },
            {
                name: "c" + selectedCampanha.current?.id + "__planned_value",
                label: <span>Volume <br />Reserva</span>,
                options: {
                    filter: true,
                    sort: true,
                    sortDescFirst: true,
                    display: true,
                    customHeadRender: ({ index, name, label, ...column }, sortColumn, tableMeta) => {
                        return (
                            <>
                                <TableCell
                                    className="TableCell"
                                    onClick={() => {
                                        sortColumn(index)
                                    }}
                                    style={{
                                        textAlign: "center",
                                        minWidth: "13rem",
                                        whiteSpace: "nowrap",
                                        backgroundColor: "white",
                                    }}
                                >
                                    <div className="containerTableCellButtonBaterBase">
                                        <div>
                                            {label}
                                            <div className="arrowSortAbc">
                                                {tableMeta.name === name ? tableMeta.direction == "asc" ? <AiOutlineArrowUp size={15} /> : <AiOutlineArrowDown size={15} /> : <></>}
                                            </div>
                                        </div>
                                    </div>
                                </TableCell>
                            </>
                        )
                    },
                    setCellProps: () => ({
                        style: {
                            paddingLeft: "0.5rem",
                            paddingRight: "0.5rem",
                            fontSize: '0.8rem'
                        },
                    }),
                    customBodyRender: (value, tableMeta, updateValue) => {
                        var clientPlanned = listFilteredClients.current.find(c => c.client_id === tableMeta.rowData[0]);
                        // var clientbool = clientPlanned['c' + selectedCampanha.current?.id + '__planned_value']
                        return (
                            tabActive.current === 0 ? (
                            <CurrencyTextField
                                style={{ minWidth: '150px', fontSize: '0.8rem' }}
                                variant="outlined"
                                value={value ? value : ''}
                                disabled={tableMeta.rowData[4] == 0 ? true : false}
                                size="large"
                                currencySymbol="R$"
                                outputFormat="number"
                                decimalCharacter=","
                                digitGroupSeparator="."
                                onBlur={(event, value) => {
                                    // console.log('clientbool', clientbool)
                                        // console.log(event?.target?.value)
                                        const parsedValueString = event?.target?.value.replaceAll('.', '').replace(',', '.');
                                        const parsedValue = Number(parsedValueString);
                                        const finalValue = isNaN(parsedValue) ? null : parsedValueString;

                                        lenghtClients.find((c) => c.client_id === tableMeta.rowData[0])['c' + selectedCampanha.current?.id + '__planned_value'] = finalValue
                                        lenghtClients.find((c) => c.client_id === tableMeta.rowData[0]).changed = true

                                        cloneListFilteredClients.current = (lenghtClients.filter(c => c.changed === true))


                                    setAnythingChanged(true)
                                    updateValue(event.target.value)
                                }}
                            />
                            ) : toBRL(value)
                        )
                    }
                },
            },
            {
                name: 'c' + selectedCampanha.current?.id + '__advisors_responsibility',
                label: <span>Responsável <br /> Liquidação</span>,
                options: {
                    filter: true,
                    sort: true,
                    sortDescFirst: true,
                    display: true,
                    customHeadRender: ({ index, name, label, ...column }, sortColumn, tableMeta) => {
                        return (
                            <>
                                <TableCell
                                    className="TableCell"
                                    onClick={() => {
                                        sortColumn(index)
                                    }}
                                    style={{
                                        textAlign: "center",
                                        minWidth: "13rem",
                                        whiteSpace: "nowrap",
                                        backgroundColor: "white",
                                    }}
                                >
                                    <div className="containerTableCellButtonBaterBase">
                                        <div>
                                            {label}
                                            <div className="arrowSortAbc">
                                                {tableMeta.name === name ? tableMeta.direction == "asc" ? <AiOutlineArrowUp size={18} /> : <AiOutlineArrowDown size={18} /> : <></>}
                                            </div>
                                        </div>
                                    </div>
                                </TableCell>
                            </>
                        )
                    },
                    setCellProps: () => ({
                        style: {
                            paddingLeft: "0.5rem",
                            paddingRight: "0.5rem",
                        },
                    }),
                    customBodyRender: (value, tableMeta, updateValue) => {
                        const optResponsável = {
                            1: "Assessor",
                            0: "Broker",
                        }


                        return (
                            tabActive.current === 0 ? (
                                <div style={{ marginLeft: '1rem', marginRight: '1rem' }}>
                                    <select
                                        disabled={tableMeta.rowData[4] == 0 ? true : false}
                                        className="selectTacticalAlocationsCategory"
                                        style={{
                                            // border: cloneListFilteredClients.current[tableMeta.tableMeta.rowData[0]][0].changed && cloneListFilteredClients.current[tableMeta.tableMeta.rowData[0]][0]["c" + selectedCampanha.current?.id + "__advisors_responsibility"] !== listFilteredClients.current.find((e) => e.client_id === tableMeta.rowData[0] ? e['c' + selectedCampanha.current?.id + '__advisors_responsibility'] : '' )? '2px solid green' : '1px solid #a0a0a0',
                                            width: '7rem',
                                            height: '25px',
                                            textAlign: 'center',
                                            borderRadius: '10px'
                                        }}
                                        value={value === undefined || value == null ? 'Indefinido' : value ? 1 : 0}
                                        onChange={(e) => {
                                            const client = lenghtClients.find(c => c.client_id === tableMeta.rowData[0]);

                                            lenghtClients.find((c) => c.client_id === tableMeta.rowData[0])['c' + selectedCampanha.current?.id + '__advisors_responsibility'] = Number(e.target.value)
                                            lenghtClients.find((c) => c.client_id === tableMeta.rowData[0]).changed = true

                                            cloneListFilteredClients.current = (lenghtClients.filter(c => c.changed === true))

                                            setAnythingChanged(true)
                                            updateValue(Number(e.target.value))
                                            // updateValuesList.push(`${tableMeta.rowData[0]}, ${tableMeta.columnData.name}, ${e.target.value}`)
                                        }}
                                    >
                                        {value === undefined || value == null && (
                                            <option value="Indefinido">Indefinido</option>
                                        )}
                                        {Object.entries(optResponsável).map(([val, label]) => (
                                            <option key={val} value={val}>
                                                {label}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            ) : value == null || value == undefined ? 'indefinido' : value ? 'Assessor' : 'Broker'
                        )
                    },
                }
            },
            {
                name: "c" + selectedCampanha.current?.id + "__comment",
                label: "Comentário",
                options: {
                    filter: true,
                    sort: true,
                    sortDescFirst: true,
                    display: true,
                    customHeadRender: ({ index, name, label, ...column }, sortColumn, tableMeta) => {
                        return (
                            <>
                                <TableCell
                                    className="TableCell"
                                    onClick={() => {
                                        sortColumn(index)
                                    }}
                                    style={{
                                        textAlign: "center",
                                        minWidth: "13rem",
                                        whiteSpace: "nowrap",
                                        backgroundColor: "white",
                                    }}
                                >
                                    <div className="containerTableCellButtonBaterBase">
                                        <div>
                                            {label}
                                            <div className="arrowSortAbc">
                                                {tableMeta.name === name ? tableMeta.direction == "asc" ? <AiOutlineArrowUp size={15} /> : <AiOutlineArrowDown size={15} /> : <></>}
                                            </div>
                                        </div>
                                    </div>
                                </TableCell>
                            </>
                        )
                    },
                    setCellProps: () => ({
                        style: {
                            paddingLeft: "0.5rem",
                            paddingRight: "0.5rem",
                            maxWidth: '21rem',
                            textOverflow: 'ellipsis',
                        },
                    }),
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <div style={{ marginLeft: '1rem', marginRight: '1rem', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                                <p style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', maxWidth: '16rem' }}> {value ? value : ' - '}</p>
                                <FaPencilAlt size={15} style={{ marginLeft: '0.5rem', cursor: 'pointer' , display: tabActive.current === 0 ? 'block' : 'none'}}
                                    onClick={() => {
                                        setType({
                                            name: "comment",
                                            selectedCampanha: selectedCampanha.current,
                                            row: tableMeta.rowData,
                                            client: listFilteredClients.current.find((c) => c.client_id === tableMeta.rowData[0])
                                        })
                                        cloneListFilteredClients.current.find((c) => c.client_id === tableMeta.rowData[0]).changed = true
                                        setShow(true)
                                        setAnythingChanged(true)
                                    }
                                    } />
                            </div>
                        )
                    }

                },
            },

            {
                name: "c" + selectedCampanha.current?.id + "__operation_id",
                label: "operacao",
                options: {
                    filter: true,
                    sort: true,
                    sortDescFirst: true,
                    display: false,
                    viewColumns: false,
                    customHeadRender: ({ index, name, label, ...column }, sortColumn, tableMeta) => {
                        return (
                            <>
                                <TableCell
                                    className="TableCell"
                                    onClick={() => {
                                        sortColumn(index)
                                    }}
                                    style={{
                                        textAlign: "center",
                                        minWidth: "13rem",
                                        whiteSpace: "nowrap",
                                        backgroundColor: "white",
                                    }}
                                >
                                    <div className="containerTableCellButtonBaterBase">
                                        <div>
                                            {label}
                                            <div className="arrowSortAbc">
                                                {tableMeta.name === name ? tableMeta.direction == "asc" ? <AiOutlineArrowUp size={15} /> : <AiOutlineArrowDown size={15} /> : <></>}
                                            </div>
                                        </div>
                                    </div>
                                </TableCell>
                            </>
                        )
                    },
                    setCellProps: () => ({
                        style: {
                            paddingLeft: "0.5rem",
                            paddingRight: "0.5rem",
                        },
                    }),
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <div style={{ marginLeft: '1rem', marginRight: '1rem' }}>
                                {value ? value : ' - '}
                                <FaPencilAlt size={15} style={{ marginLeft: '0.5rem', cursor: 'pointer' }}
                                    onClick={() => {
                                        setType({
                                            name: "comment",
                                            selectedCampanha: selectedCampanha.current,
                                            row: tableMeta.rowData,
                                        })
                                        setShow(true)
                                    }
                                    } />
                            </div>
                        )
                    }
                },
            },
        ]
    }

    const options = {
        filterType: "multiselect",
        filter: ((user?.is_leader > 0 || user.type <= 3) || user.type == 4) ? true : false,
        download: true,
        print: false,
        // responsive: "vertical",
        customSearchRender: debounceSearchRender(500),
        tableBodyHeight: `${alturaTabela}px`,
        // tableBodyHeight: '80vh',
        tableBodyWidth: '80vw',
        selectableRowsHideCheckboxes: true,
        selectToolbarPlacement: "replace",
        selectableRows: "multiple",
        viewColumns: tabActive.current == 0 ? false : true,
        rowsPerPage: rowsPerPage,
        customToolbar: anythingChanged ? AddButton : null,
        rowsPerPageOptions: [50, 150, 500],
        search: true,
        textLabels: {
            body: {
                noMatch: loadingTable ? <ArrowLoader /> : "Não foram encontrados dados",
                toolTip: "Ordenar",
                columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
            },
            pagination: {
                next: "Próxima",
                previous: "Anterior",
                rowsPerPage: "Linhas por página:",
                displayRows: "de",
            },
            toolbar: {
                search: "Pesquisar",
                downloadCsv: "Download XLSX",
                print: "Imprimir",
                viewColumns: "Ver colunas",
                filterTable: "Filtrar",
            },
            filter: {
                all: "Todos",
                title: "Filtros",
                reset: "Limpar",
            },
            viewColumns: {
                title: "Mostrar Colunas",
                titleAria: "Mostrar/Ocultar colunas",
            },
            selectedRows: {
                text: "linha(s) selecionadas",
                delete: "Excluir",
                deleteAria: "Excluir linhas Selecionadas",
            },
        },
        downloadOptions: {
            filename: "abc.csv",
            separator: ";",
        },
        // onDownload: (buildHead, buildBody, columns, values) => {
        //     if (tabActive.current == 0) {
        //         configurationExport()
        //         return false
        //     } else {
        //         exportDash()
        //         return false
        //     }
        // },
        onDownload: (buildHead, buildBody, columns, values) => {
            var json = []
            if (tabActive.current == 1) {
                exportDash()
                return false
                const colunas = columns.filter((e) => e.display == "true")

                // pega os dados da lista listSearchedClients.current para as colunas visiveis (colunas)
                json = listSearchedClients.current.map((row) => {
                    const temp = {}
                    colunas.forEach((coluna) => {
                        // console.log('coluna', coluna, row[coluna.name])
                        temp[coluna.label] = row[coluna.name] ? row[coluna.name] : ""
                    })
                    return temp
                })
            } else if (tabActive.current == 0) {
                // configurationExport()
                const colunas = columns.filter((e) => e.display == "true" || e.name.indexOf("__permission") > -1)

            }

            const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8"
            const fileExtension = ".xlsx"
            const fileName = tabActive.current == 0 ? `Configurações Clientes` : `Bater Base`
            const ws = utils.json_to_sheet(json)
            const wb = { Sheets: { data: ws }, SheetNames: ["data"] }
            const excelBuffer = write(wb, { bookType: "xlsx", type: "array" })
            const data = new Blob([excelBuffer], { type: fileType })
            saveAs(data, fileName + fileExtension)
            return false
        },
        setTableProps: () => {
            return {
                padding: "none",
                size: "small",
            }
        },
        // onFilterChange: (column, list, e, i) => {
        //     if (search !== "") filtersList.current["search"] = search
        //     if (i !== null) {
        //         if (!filtersList.current.hasOwnProperty(column)) {
        //             filtersList.current[column] = []
        //         }
        //         filtersList.current[column] = list[i]
        //     } else {
        //         for (const property in filtersList.current) {
        //             filtersList.current[property] = []
        //         }
        //     }
        //     listFilterStatusProducts.current[column] = filtersList.current[column]

        //     visibleColumnsRef.current.map((e) => {
        //         if (e.name == column) {
        //             e.options.filterList = filtersList.current[column]
        //         }
        //     })

        //     props = null

        //     var currentTable = tabActive.current == 0 ? configuration.current.table : clientsResult.current.table

        //     //fazer o search
        //     var filteredClientsTemp = []
        //     if (search != "" && search != undefined) {
        //         const chavesSelecionadas = Array.from(
        //             // FAST SEARCH
        //             new Set(
        //                 visibleColumnsRef.current
        //                     .filter((objeto) => objeto.options.display === true) // filtra somente os objetos com display = true
        //                     .map((objeto) => objeto.name) // extrai somente os valores da coluna
        //             )
        //         )

        //         //filtra apenas pelas colunas visiveis no momento
        //         filteredClientsTemp = currentTable.filter(
        //             (row) =>
        //                 Object.values(Object.fromEntries(Object.entries(row).filter(([chave, valor]) => chavesSelecionadas.includes(chave))))
        //                     .join("|")
        //                     .toLowerCase()
        //                     .indexOf(String(search)) > -1
        //         )
        //         listSearchedClients.current = filteredClientsTemp
        //     } else {
        //         listSearchedClients.current = currentTable
        //         filteredClientsTemp = currentTable
        //     }

        //     currentTable = filteredClientsTemp

        //     // // LISTA DE CLIENTE - necesaria para o filtro de status do produto ser apagado ao clicar sobre o filtro setado
        //     listFilteredClients.current = currentTable.filter((row) => {
        //         var exists = true
        //         for (const property in filtersList.current) {
        //             if (property !== "search") exists = filtersList.current[property].length === 0 ? exists : filtersList.current[property].includes(row[property]) ? exists : false
        //         }
        //         return exists
        //     })

        //     listFilteredClients.current = listFilteredClients.current.slice(0, rowsPerPage + 501)
        //     linesCount.current = listFilteredClients.current.length
        //     doSum()
        //     positions.current.currentPage = 0
        //     forceUpdate()
        // },

        onFIlterChange: (column, list, e, i) => {
            linesCount.current = listFilteredClients.current.length
            assembleTable()
            forceUpdate()
            doSum()
            positions.current.currentPage = 0
            return false
        },
        onFilterDialogOpen: () => {
            forceUpdate()
        },
        onTableChange: (a, b) => {
            doSum()
            if (tableChanged.current) {
                tableChanged.current = false
                const column = b.sortOrder.name
                const order = b.sortOrder.direction
                lastSortOrder.current.name = column
                lastSortOrder.current.direction = order
                if (listFilteredClients.current.length > 1) {
                    var orderedList = []

                    orderedList = listFilteredClients.current.sort((a, b) => {
                        let fa = a[column] == null ? "-" : a[column]
                        let fb = b[column] == null ? "-" : b[column]

                        if (typeof fa === "string" || typeof fb === "string") {
                            fa = String(fa).toLowerCase().trim()
                            fb = String(fb).toLowerCase().trim()
                        }

                        return order === "asc" ? (fa < fb ? -1 : fa > fb ? 1 : 0) : fa < fb ? 1 : fa > fb ? -1 : 0
                    })

                    listFilteredClients.current = orderedList
                    listSearchedClients.current = orderedList
                    listFilteredClients.current = orderedList.slice(0, rowsPerPage + 501)
                    setLastPage(0)
                    setCurrentPage(0)
                    positions.current.currentPage = 0
                    assembleTable()
                    forceUpdate()
                }
            }
        },
        onSearchChange: (searchText) => {
            searchText = searchText === null ? "" : searchText
            setSearch(searchText)
            filtersList.current["search"] = searchText


            var currentTable = clientsResult.current.table

            //fazer o search
            var filteredClientsTemp = []
            if (searchText != "" && searchText != undefined) {
                const chavesSelecionadas = Array.from(
                    // FAST SEARCH

                    new Set(
                        visibleColumnsRef.current
                            .filter((objeto) => objeto.options.display === true) // filtra somente os objetos com display = true
                            .map((objeto) => objeto.name) // extrai somente os valores da coluna
                    )
                )

                //filtra apenas pelas colunas visiveis no momento
                filteredClientsTemp = currentTable.filter(
                    (row) =>
                        Object.values(Object.fromEntries(Object.entries(row).filter(([chave, valor]) => chavesSelecionadas.includes(chave))))
                            .join("|")
                            .toLowerCase()
                            .indexOf(String(searchText)) > -1
                )
                listSearchedClients.current = filteredClientsTemp
            } else {
                listSearchedClients.current = currentTable
                filteredClientsTemp = currentTable
            }

            currentTable = filteredClientsTemp

            // // LISTA DE CLIENTE - necesaria para o filtro de status do produto ser apagado ao clicar sobre o filtro setado
            listFilteredClients.current = currentTable.filter((row) => {
                var exists = true
                for (const property in filtersList.current) {
                    if (property !== "search") exists = filtersList.current[property].length === 0 ? exists : filtersList.current[property].includes(row[property]) ? exists : false
                }
                return exists
            })

            linesCount.current = listFilteredClients.current.length
            listFilteredClients.current = listFilteredClients.current.slice(0, rowsPerPage + 101)

            forceUpdate()
            doSum()
            positions.current.currentPage = 0
            return false
        },
        customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage, textLabels) => {
                return (
                    <CustomFooterBaterBase
                        // {...props}
                        count={lenghtClients.length}
                        page={positions.current.currentPage}
                        rowsPerPage={rowsPerPage}
                        changeRowsPerPage={changeRowsPerPage}
                        changePage={changePage}
                        textLabels={textLabels}
                    />
                )
            
        },
        onChangePage: (atualPage) => {
            setCurrentPage(atualPage)
            positions.current.currentPage = atualPage
            if (atualPage > lastPage) {
                listFilteredClients.current = listFilteredClients.current.concat(listFilteredClients.current.slice(listFilteredClients.current.length, listFilteredClients.current.length + rowsPerPage))
                setLastPage(atualPage)
            }
            assembleTable()
        },
        onChangeRowsPerPage: (rows) => {
            listFilteredClients.current = listFilteredClients.current.slice(0, rows + 1)
            positions.current.rowsPerPage = rows
            positions.current.currentPage = 0
            localStorage.setItem("positionabc", JSON.stringify(positions.current))
            linesCount.current = listFilteredClients.current.length
            setRowsPerPage(rows)
            setLastPage(0)
            setCurrentPage(0)
            assembleTable()
        },
        onColumnSortChange: (column, order) => {
            lastSortOrder.current.name = column
            lastSortOrder.current.direction = order
            if (listFilteredClients.current.length > 1) {
                var orderedList = []

                orderedList = listFilteredClients.current.sort((a, b) => {
                    let fa = a[column] == null ? "-" : a[column]
                    let fb = b[column] == null ? "-" : b[column]

                    if (typeof fa === "string" || typeof fb === "string") {
                        fa = String(fa).toLowerCase().trim()
                        fb = String(fb).toLowerCase().trim()
                    }

                    return order === "asc" ? (fa < fb ? -1 : fa > fb ? 1 : 0) : fa < fb ? 1 : fa > fb ? -1 : 0
                })

                listFilteredClients.current = orderedList
                listSearchedClients.current = orderedList
                listFilteredClients.current = orderedList.slice(0, rowsPerPage + 501)
                setLastPage(0)
                setCurrentPage(0)
                positions.current.currentPage = 0
                assembleTable()
                forceUpdate()
            }
        },
    }


    return (
        loading ? (
            <ArrowLoader />
        ) : (
            <>
                <Modal show={show} onHide={() => setShow(false)}>
                    {type.name === 'comment' ? (
                        <ModalCommentBaterBase prop={type} />
                    ) :
                        type.name === 'listCampains' ? (
                            <ListCampainsBaterBaseModal prop={type} />
                        ) : (
                            <></>
                        )}
                </Modal>

                <div>
                    <div className='containerBaterBase'>
                        <div className='containerBaterBaseLeftSide'>
                            <span>
                                Campanhas
                            </span>
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%', alignItems: 'center', overflowY: 'auto', overflowX: 'hidden' }}>
                                <span>
                                    {campanhas.current.map((campanha, index) => (
                                        <div key={index} style={{ width: '95%', }} >
                                            <div
                                                onClick={() => { selectedCampanha.current = campanha; forceUpdate() }}
                                                style={{
                                                    padding: '10px',
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    alignItems: 'center',
                                                    gap: '0.5rem',
                                                    background: selectedCampanha.current?.id == campanha?.id ? '#C39D16' : 'inherit',
                                                    color: selectedCampanha.current?.id == campanha?.id ? 'white' : 'inherit',
                                                    fontWeight: '500',
                                                    marginBottom: '0.5rem',
                                                }} className='buttonBaterBaseSideLeft'>
                                                <span style={{ background: '#C39D16', height: '30px', width: '10px', borderRadius: '0.25rem', display: 'flex' }} ></span>
                                                <span> {campanha.description}</span>
                                            </div>
                                        </div>
                                    ))}
                                </span>
                                <span
                                    onClick={
                                        () => {
                                            setAnythingChanged(false)
                                            window.history.back()
                                        }}
                                    style={{
                                        padding: '0.8rem 1.2rem',
                                        marginLeft: '1rem',
                                        border: '1px solid #C39D16 ',
                                        background: '#C39D16',
                                        color: 'white',
                                        borderRadius: '0.5rem',
                                        width: 'fit-content',
                                        marginTop: '2rem',
                                        cursor: 'pointer'
                                    }} >Voltar</span>
                            </div>
                        </div>
                        <div className='containerBaterBaseRightSide'>
                            <div className='containerInfosAboutProduct' style={{ padding: '20px' }}>
                                <span style={{ display: 'block', padding: '10px 0', fontWeight: 'bold', fontSize: '1.2rem' }}>{selectedCampanha.current.description}</span>
                                <div className='infosProductBaterBase'>
                                    <span><strong>Oportunidade </strong><p>{toBRL(selectedCampanha.current?.sumObjective)}</p></span>
                                    <span><strong>Realizado </strong><p>{toBRL(selectedCampanha.current?.sumValue)}</p></span>
                                    <span><strong>Receita Estimada </strong><p>{toBRL(sum.current['c' + selectedCampanha.current?.id].receitaRe)} </p></span>
                                    <span><strong>FEE </strong><p>{selectedCampanha.current?.fee ? toPercent(selectedCampanha.current?.fee * 100) : '-'}</p></span>
                                    <span><strong>Descrição</strong> <p>{selectedCampanha.current?.card_description != null ? selectedCampanha.current?.card_description : '-'}</p></span>
                                    <span><strong>Prioridade </strong><p>{selectedCampanha.current?.priority == 1 ? 'CRM' : selectedCampanha.current?.priority == 2 ? 'Movimentação tática' : 'Normal'}</p></span>
                                    <span><strong>Produto </strong><p>{(selectedCampanha.current?.type?.description ? selectedCampanha.current?.type?.description : '-')}</p></span>
                                </div>
                            </div>
                            <div>
                                <nav style={{display: !loadingTable && (user.is_leader == false || user.is_leader == 0 || user.type != 2 || user.type != 1) ? 'none' :'flex'}}>
                                    <span className={ tabActive.current == 0 ? "estructuredsTabOption360" : "estructuredsInactiveTabOption360"}
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => {
                                            tabActive.current = 0
                                            setLenghtClients(clientsResult.current.table.filter(e => e.advisor_id == user.external_id))
                                            linesCount.current = clientsResult.current.table.filter(e => e.advisor_id == user.external_id).length
                                            assembleTable()
                                            forceUpdate()
                                        }}
                                    >
                                        Meus clientes
                                    </span>
                                    <span className={ tabActive.current == 1 ? "estructuredsTabOption360" : "estructuredsInactiveTabOption360"}
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => {
                                            tabActive.current = 1
                                            setLenghtClients(clientsResult.current.table.filter(e => e.advisor_id != user.external_id))
                                            linesCount.current = clientsResult.current.table.filter(e => e.advisor_id != user.external_id).length
                                            assembleTable()
                                            forceUpdate()
                                        }}
                                    >
                                        Clientes do meu time
                                    </span>
                                </nav>
                                <div className='containerInfosAboutProductSecond'>
                                    {loadingTable ?
                                        <ArrowLoader /> :

                                        (<ThemeProvider theme={getMuiTheme()} className="dashboardBoxContainerBaterBase"  >
                                            <MUIDataTable
                                                title={"Clientes"}
                                                data={lenghtClients}
                                                columns={visibleColumnsRef.current}
                                                options={options}
                                            ></MUIDataTable>
                                        </ThemeProvider>)
                                    }
                            </div>
                    </div>
                </div>
            </div >
        </div >
        </>
    ));
}