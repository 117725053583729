import { DashboardAdvisor } from "./dashboard_advisor"
import { Dashboard } from "./dashboard"
import { useAuth } from "../../../contexts/useAuth"
import { useModal } from "../../../contexts/useModal"
import Modal from "../../../components/modal/modal"
import { ConfirmationBox } from "../../../components/confirmationBox/confirmationBox"
import { ConfigEditModal } from "./config_edit_modal"
import { CalendarResponseModal } from "./dashboard_advisor_calendar"
import { CalendarProductsResponseModal } from "./dashboardAdvisorProductsCalendar"
import { DashboardTextEditor } from "./dashboardTextEditor"
import { DashboardAdvisorClientMenu } from "./dashboardAdvisorClientMenuModal"
import { DashboardAdvisorDetailsModal } from "./dashboardAdvisorDetailsModal"
import { DashboardAdvisoClientProductsModal } from "./dashboardAdvisorClientProdutcsModal"
import { DashboardAdvisorStatusFilter } from "./dashboardAdvisorStatusFilter"
import { CampainSpeech } from "./speech_modal"
import { SettlementCalendarModal } from "./dashboardSettlementCalendar"

export function Dash2() {
    const { type } = useModal()
    const { user } = useAuth()

    return (
        <>
            <Modal>
                {type.name === "alertMsg" ? (
                    <ConfirmationBox msgData={type} />
                ) : type.name === "modalConfigEdit" ? (
                    <ConfigEditModal prop={type} />
                ) : type.name === "productsCalendarDashboard" ? (
                    <CalendarProductsResponseModal />
                ) : type.name === "calendarDashboard" ? (
                    <CalendarResponseModal prop={type}/>
                ) : type.name === "settlementCalendar" ? (
                    <SettlementCalendarModal prop={type}/>
                ) : type.name === "showDashboardTextEditor" ? (
                    <DashboardTextEditor />
                ) : type.name === "menu" ? (
                    <DashboardAdvisorClientMenu prop={type} />
                ) : type.name === "details" ? (
                    <DashboardAdvisorDetailsModal prop={type} />
                ) : type.name === "detailsClient" ? (
                    <DashboardAdvisoClientProductsModal prop={type} />
                ) : type.name === "status" ? (
                    <DashboardAdvisorStatusFilter prop={type}/>
                ) : type.name === "speechModal" ? (
                    <CampainSpeech prop={type} />
                ) :
                
                 (
                    <></>
                )}
            </Modal>
            <DashboardAdvisor prop={type} />
        </>
    )
}
