import React, { useEffect, useState } from 'react'
/*Styles*/
import './styles/brokerPanelLeftLinkRegister.css'
import { styleClientsInput } from '../../../components/styles/autocompletField'
/*Contexts*/
import { useModal } from '../../../contexts/useModal'
import { useAuth } from '../../../contexts/useAuth'
/*Utils*/
import { ModalSizeFitToScreen } from '../../../utils/responsiveFunctions/modalSizeFitToScreen'
import { TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { titleize } from '../../../utils/formatNumberAndText/formatTextUpperFirstLetter'
import { api } from '../../../services/api'
import { ArrowLoader } from '../../../components/loaders/arrowLoader'


export function BrokerPanelLinkRegister() {

    const [loading, setLoading] = useState(false)
    const classes = styleClientsInput()
    const { show, setShow, type, setType, edited, setEdited } = useModal()
    const { user, tokenKey } = useAuth()
    const { screenX, screenY } = ModalSizeFitToScreen()
    const [brokerPanelCategorys, setBrokerPanelCategorys] = useState([])
    const [selectedNewCategory, setSelectedNewCategory] = useState(false)
    const [selectedCategory, setSelectedCategory] = useState('')
    const [selectedTitle, setSelectedTitle] = useState('')
    const [selectedLink, setSelectedLink] = useState('')
    const requiredFields = [
        {field: 'selectedTitle', value: selectedTitle},
        {field: 'selectedCategory', value: selectedCategory},
        {field: 'selectedLink', value: selectedLink},
    ]
    const [errorFields, setErrorFields] = useState({
        selectedTitle: false,
        selectedCategory: false,
        selectedLink: false,
    })

    useEffect(() => {

        if (show) {
            setBrokerPanelCategorys(type?.categorys)
            setSelectedNewCategory(false)
        }
    }, [show])

    async function handleSubmit() {

        setLoading(true)
        try {
            const createLink = await api.post(`/api/v1/brokerPanel`, {
                title: selectedTitle,
                url_link: selectedLink,
                category: selectedCategory
            }, {
                headers: {
                    authorization: `Bearer ${tokenKey}`
                }
            })

            setType({ name: 'alertMsg', type: 'information', value: { message: 'Link registrado com sucesso!' } })
            setShow(true)
            setLoading(false)
            setEdited(true)
        } catch (error) {
            console.log(error?.response)
            setType({ name: 'alertMsg', type: 'information', value: { message: error?.response?.data?.message === undefined ? 'Erro ao cadastar o link, tente novamente' : error?.response?.data?.message } })
            setShow(true)
            setLoading(false)
        }
    }

    return (
        <div id='borkerPanelLinkRegisterContainer' style={{ minWidth: 800, maxWidth: 900, width: screenX, height: screenY, maxHeight: 600, minHeight: 400 }}>
            <div id='brokerPanelLinkRegisterHeader'>
                <p>Cadastro de links para painel broker</p>
                <button className='modalCloseButton' onClick={() => setShow(false)}>x</button>
            </div>
            <div id='brokerPanelLinkRegisterBody'>
                {
                    loading ?

                        <ArrowLoader />
                        :
                        <>
                            <form>
                                <div id='brokerPanelLinkRegistrerFormDiv'>
                                    <TextField type='text' inputProps={{ maxLength: 100 }} onKeyPress={(e) => { if (e.code === 'Backquote') { e.preventDefault() } }} variant='outlined' className={classes.styleForClients} onChange={(e) => setSelectedTitle(e.target.value)} label='Título'></TextField>
                                    {
                                        selectedNewCategory ?
                                            (
                                                <TextField type='text' onKeyPress={(e) => { if (e.code === 'Backquote') { e.preventDefault() } }} inputProps={{ maxLength: 100 }} onChange={(e) => setSelectedCategory(e.target.value)} className={classes.styleForClients} variant="outlined" label='Digite a categoria' />
                                            ) :
                                            <>
                                                <Autocomplete
                                                    options={brokerPanelCategorys}
                                                    onChange={(event, newValue) => {
                                                        setSelectedCategory(newValue);
                                                    }}
                                                    value={selectedCategory}
                                                    getOptionLabel={(option) => option}
                                                    id='category'
                                                    renderInput={(params) => <TextField
                                                        label='Categoria'
                                                        onKeyPress={(e) => { if (e.code === 'Backquote') e.preventDefault() }}
                                                        className={classes.styleForClients}
                                                        {...params} variant="outlined" />}
                                                />
                                                <button id='brokerPanelCategoryButton' onClick={(e) => { e.preventDefault(); setSelectedNewCategory(true) }}>Nova categoria</button>
                                            </>
                                    }
                                    <TextField type='text' onKeyPress={(e) => { if (e.code === 'Backquote') { e.preventDefault() } }} onChange={(e) => setSelectedLink(e.target.value)} inputProps={{ maxLength: 100 }} variant="outlined" className={classes.styleForClients} label='Link'></TextField>
                                </div>
                            </form>
                            <button onClick={() => handleSubmit()} className='buttonWhiteBg'>Confirmar</button>
                        </>
                }
            </div >
        </div >
    )
}