/*Componente tabela de Analise da base de clientes
/*Desenvolvido por: Equipe Tech RV
*/

//libraries
import React, { useEffect, useState, useRef,useReducer } from "react";
import { FiPlus, FiSearch, FiArrowDown, FiCopy, FiPenTool } from "react-icons/fi";
import { AiOutlineArrowUp, AiOutlineArrowDown, AiOutlineUsergroupAdd } from "react-icons/ai";
import { FormGroup, FormLabel, FormControl, ListItemText, TextField, Checkbox, FormControlLabel, Grid, Select, InputLabel, MenuItem } from "@mui/material";
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import { ThemeProvider,createTheme } from '@mui/material/styles';
//hooks
import { useModal } from "../../../contexts/useModal";
import Modal from "../../../components/modal/modal";
import { useAuth } from "../../../contexts/useAuth";
//services
import { api } from "../../../services/api";
//components
import { ArrowLoader } from "../../../components/loaders/arrowLoader";
import { Alert } from "../../../components/alert/alert";
import { Tooltip, IconButton } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import { TableCell } from "@material-ui/core";
//utils
import { titleize } from "../../../utils/formatNumberAndText/formatTextUpperFirstLetter";
import { ModalSizeFitToScreen } from "../../../utils/responsiveFunctions/modalSizeFitToScreen";
import TableViewCol from "../../../utils/muiDatatables/TableViewCol";
import { getABCProducts, getABCClients, updateListOnly,updateActive,deleteProduct } from "../../../services/asyncFunctions"

//styles
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { MdOutlineMonetizationOn, MdOutlineCheckCircleOutline, MdOutbox,MdDeleteForever } from "react-icons/md";

import { HiOutlineSquares2X2 } from "react-icons/hi2";
import { FaRegAddressCard } from "react-icons/fa";

import { toBRL } from "../../../utils/formatNumberAndText/toBRLCurrency";
import "./mainStyle.css";
import { RiContactsBookLine } from "react-icons/ri";

export function ManagementTable() {
    const { show, setShow, setType, type, edited, setEdited } = useModal();
    const [tabActive, setTabActive] = useState(0);
    const { user, tokenKey } = useAuth();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(30);
    const [listExceptionClients, setListExceptionClients] = useState([]);
    const [viewCheckbox, setViewCheckbox] = useState(false);
    const [loading, setLoading] = useState(true);
    const visibleColumnsRef = useRef([]);
    const products = useRef([])
    const listFilteredProducts = useRef([]);
    const [columnsToDisplay, setColumnsToDisplay] = useState();
    const [lastVisitedPage, setLastVisitedPage] = useState('');

    const [, forceUpdate] = useReducer(x => x + 1, 0);
    
    useEffect(() => {
        const lastVisitedPageAux = localStorage.getItem('lastVisitedPage');
        setLastVisitedPage(lastVisitedPageAux);
      }, []);

    const Android12Switch = styled(Switch)(({ theme }) => ({
        padding: 8,
        '& .MuiSwitch-track': {
          borderRadius: 22 / 2,
          '&:before, &:after': {
            content: '""',
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            width: 16,
            height: 16,
          },
          '&:before': {
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
              theme.palette.getContrastText(theme.palette.primary.main),
            )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
            left: 12,
          },
          '&:after': {
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
              theme.palette.getContrastText(theme.palette.primary.main),
            )}" d="M19,13H5V11H19V13Z" /></svg>')`,
            right: 12,
          },
        },
        '& .MuiSwitch-thumb': {
          boxShadow: 'none',
          width: 16,
          height: 16,
          margin: 2,
        },
      }));

    function loadColumns() {
        const res = setColumnsLocal()
        return res
    }

    async function getXLSXClients(product_id) {
        try {
            const product_name = products.current.filter((x) => x.product_id == product_id)[0]?.product_description
            const res = await getABCClients(tokenKey, product_id)
            const responseURL = URL.createObjectURL(new Blob([res]));
            const link = document.createElement("a");
            link.href = responseURL;
            link.setAttribute("download", `clientes_produto_${product_name ? product_name : 'export'}.xlsx`);
            document.body.appendChild(link);
            link.click();
        } catch (error) {
            console.log(error)
        }
    }
    
    async function getProducts(response) {
        setLoading(true);
        // const area = lastVisitedPage == 'dashAdvisor' ? undefined : 1
        const res = await getABCProducts(tokenKey, undefined)
        products.current = res.data?.data;

        listFilteredProducts.current = res.data?.data.filter((e) => {
            // console.log(e)
            var strData = e.finish ? e.finish : e.liquidacao
            var partesData = strData.split("/");
            var data = new Date(partesData[2], partesData[1] - 1, partesData[0]);
            // console.log('porra da data', data.toISOString().split('T')[0], new Date().toISOString().split('T')[0])
            return data.toISOString().split('T')[0] >= new Date().toISOString().split('T')[0] ? true : false         
            })
        
        if(response?.length == 0){
            response = null
        }
        columnsAux = [
            {
                name: "area_description",
                label: "Área",
                options: {
                    filter: true,
                    sort: true,
                    display: true,
                    setCellProps: () => ({
                        style: {
                            whiteSpace: "nowrap",
                            paddingLeft: "0.5rem",
                            paddingRight: "0.5rem",
                        },
                    }),
                    
                },
            },
            {
                name: "product_id",
                label: "ATUALIZAR",
                options: {
                    filter: false,
                    sort: false,
                    setCellProps: () => ({
                        style: {
                            whiteSpace: "nowrap",
                            paddingLeft: "1rem",
                            paddingRight: "1rem",
                        },
                    }),
                    customBodyRender: (val) => {
                        return (
                            <div className="btnsBoxAbc">
                                <div title="Atualizar" className="mgnBtnsAbc"
                                    onClick={(e) => {
                                        const product = products.current.find((x) => x.product_id == val)
                                        console.log('product', product)
                                        e.stopPropagation();
                                        setType({ name: "import", product: val, isPlataforma: product.liquidation_type == true ? true : false });
                                        setShow(true);
                                    }}>
                                    <div className="btnMngAbc"><AiOutlineUsergroupAdd /></div>
                                </div>
                            </div>
                        );
                    }
                },
            },
            {
                name: "product_id",
                label: "id",
                options: {
                    filter: false,
                    sort: false,
                    display:false,
                }
            },
            {
                name: "product_description",
                label: "PRODUTO",
                options: {
                    filter: true,
                    sort: true,
                    display: true,
                    setCellProps: () => ({
                        style: {
                            whiteSpace: "nowrap",
                            paddingLeft: "0.5rem",
                            paddingRight: "0.5rem",
                        },
                    }),
                    
                },
            },
            {
                name: "operation_reference",
                label: "OPERAÇÃO",
                options: {
                    filter: true,
                    sort: true,
                    display: true,
                    customBodyRender: (val) => {
                        return val ? `${val}` : '-';
                    },
                    setCellProps: () => ({
                        style: {
                            whiteSpace: "nowrap",
                            paddingLeft: "0.5rem",
                            paddingRight: "0.5rem",
                        },
                    }),
                },
            },
            {
                name: "product_type_description",
                label: "CATEGORIA PRODUTO",
                options: {
                    filter: true,
                    sort: true,
                    display: true,
                    setCellProps: () => ({
                        style: {
                            whiteSpace: "nowrap",
                            paddingLeft: "0.5rem",
                            paddingRight: "0.5rem",
                        },
                    }),
                },
            },
            {
                name: "aderence",
                label: "ADERENCIA",
                options: {
                    filter: response ? response[2]?.display : true,
                    sort: true,
                    display: response ? response[2]?.display : true,
                    setCellProps: () => ({
                        style: {
                            whiteSpace: "nowrap",
                            paddingLeft: "0.5rem",
                            paddingRight: "0.5rem",
                        },
                    }),
                    customBodyRender: (val) => {
                        return val ? `${(val)} %` : '-';
                    },
                },
            },
            {
                name: "net_aderence",
                label: "ADERENCIA NET",
                options: {
                    filter: response ? response[3]?.display : true,
                    sort: true,
                    display: response ? response[3]?.display : true,
                    setCellProps: () => ({
                        style: {
                            whiteSpace: "nowrap",
                            paddingLeft: "0.5rem",
                            paddingRight: "0.5rem",
                        },
                    }),
                    customBodyRender: (val) => {
                        return val ? `${(val)} %` : '-';
                    },
                },
            },
            {
                name: "start",
                label: "INÍCIO",
                options: {
                    filter: false,
                    sort: true,
                    display: response ? response[4]?.display : true,
                    setCellProps: () => ({
                        style: {
                            whiteSpace: "nowrap",
                            paddingLeft: "0.5rem",
                            paddingRight: "0.5rem",
                        },
                    }),
                    customBodyRender: (val) => {
                        return val ? val : "-";
                    },
                },
            },
            {
                name: "finish",
                label: "TÉRMINO",
                options: {
                    filter: false,
                    sort: true,
                    display: response ? response[5]?.display : true,
                    setCellProps: () => ({
                        style: {
                            whiteSpace: "nowrap",
                            paddingLeft: "0.5rem",
                            paddingRight: "0.5rem",
                        },
                    }),
                    customBodyRender: (val) => {
                        return val ? val : "-";
                    },
                },
            },
            {
                name: "data_liquidacao",
                label: "Data de Liquidação",
                options: {
                    filter: false,
                    sort: true,
                    display: response ? response[5]?.display : true,
                    setCellProps: () => ({
                        style: {
                            whiteSpace: "nowrap",
                            paddingLeft: "0.5rem",
                            paddingRight: "0.5rem",
                        },
                    }),
                    customBodyRender: (val) => {
                        return val ? val : "-";
                    },
                },
            },
            {
                name: "product_id",
                label: "ATUALIZAR",
                options: {
                    filter: false,
                    sort: false,
                    display:false,
                    setCellProps: () => ({
                        style: {
                            whiteSpace: "nowrap",
                            paddingLeft: "1rem",
                            paddingRight: "1rem",
                        },
                    }),
                    customBodyRender: (val) => {
                        return (
                            <div className="btnsBoxAbc">
                                <div title="Atualizar" className="mgnBtnsAbc"
                                    onClick={() => {
                                        setType({ name: "update", product: val });
                                        setShow(true);
                                    }}>
                                    <div className="btnMngAbc"><FiPenTool /></div>
                                </div>
                            </div>
                        );
                    }
                },
            },
            {
                name: "list_only",
                label: "SOMENTE LISTA?",
                options: {
                    filter: false,
                    sort: false,
                    setCellProps: () => ({
                        style: {
                            whiteSpace: "nowrap",
                            paddingLeft: "2rem",
                            paddingRight: "2rem",
                        },
                    }),
                    customBodyRender: (val, tableMeta) => {
                        return (
                            <FormGroup>
                                <FormControlLabel
                                    control={<Android12Switch checked={val} 
                                    onClick={(e) => 
                                        {
                                            e.stopPropagation()
                                        }
                                    }
                                    onChange={(e) => {
                                        listOnlyUpdate(tableMeta.rowData[2], e.target.checked ? 1 : 0)                                     
                                    }} />
                                    
                                    }
                                    // label="Android 12"
                                />
                            </FormGroup>
                        );
                    }
                },
            },
            {
                name: "geral",
                label: "VISÍVEL PARA ASSESSORES?",
                options: {
                    filter: false,
                    sort: false,
                    setCellProps: () => ({
                        style: {
                            whiteSpace: "nowrap",
                            paddingLeft: "4.5rem",
                            paddingRight: "4.5rem",
                        },
                    }),
                    customBodyRender: (val, tableMeta) => {
                        return (
                            <FormGroup>
                                <FormControlLabel
                                    onClick={(e) => e.stopPropagation()}
                                    control={<Android12Switch checked={val} onChange={(e) => {
                                        geralUpdate(tableMeta.rowData[2], e.target.checked ? 1 : 0)} } 
                                        
                                        />}
                                    // label="Android 12"
                                />
                            </FormGroup>
                        );
                    }
                },
            },
            {
                name: "active",
                label: "ATIVAR/DESATIVAR",
                options: {
                    filter: false,
                    sort: false,
                    setCellProps: () => ({
                        style: {
                            whiteSpace: "nowrap",
                            paddingLeft: "1.5rem",
                            paddingRight: "1.5rem",
                        },
                    }),
                    customBodyRender: (val, tableMeta) => {
                        return (
                            <FormGroup>
                                <FormControlLabel
                                    control={<Android12Switch checked={val} 
                                    onClick={(e) => e.stopPropagation()}
                                    onChange={(e) => {
                                        activeUpdate(tableMeta.rowData[2], e.target.checked ? 1 : 0) 
                                    }
                                    } />
                                }
                                    // label="Android 12"
                                />
                            </FormGroup>
                        );
                    }
                },
            },
            {
                name: "product_id",
                label: "EXCLUIR",
                options: {
                    filter: false,
                    sort: false,
                    setCellProps: () => ({
                        style: {
                            whiteSpace: "nowrap",
                            paddingLeft: "1rem",
                            paddingRight: "1rem",
                        },
                    }),
                    customBodyRender: (val) => {
                        return (
                            <div className="btnsBoxAbc">
                                <div title="Excluir permanentemente" className="mgnBtnsAbc"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        deleteproduct(val)
                                    }}>
                                    <div className="btnMngAbc"><MdDeleteForever size={'20'}/></div>
                                </div>
                            </div>
                        );
                    }
                },
            },
        ]

        visibleColumnsRef.current = columnsAux;
        setLoading(false);
    }

    async function listOnlyUpdate(product_id, state) {
        try {
            listFilteredProducts.current.map((p,i) => {
                if (p.product_id == product_id){
                    const states = state == 1 ? true : false
                    listFilteredProducts.current[i].list_only = states
                    forceUpdate()
                }
            })
            const res = await updateListOnly(tokenKey, product_id, state)
            if (res.isError) throw res
        } catch (error) {
            console.log(error)
            alert(error.error)
        }
    }

    async function activeUpdate(product_id, state) {
        try {
            listFilteredProducts.current.map((p,i) => {
                if (p.product_id == product_id){
                    const states = state == 1 ? true : false
                    listFilteredProducts.current[i].active = states
                    forceUpdate()
                }
            })
            const res = await updateActive(tokenKey, product_id, state)
            if (res.isError) throw res
        } catch (error) {
            console.log(error)
            alert(error.error)
        }
    }

    async function geralUpdate(product_id, state) {
        try {
            listFilteredProducts.current.map((p,i) => {
                if (p.product_id == product_id){
                    const states = state == 1 ? true : false
                    listFilteredProducts.current[i].geral = states
                    forceUpdate()
                }
            })

            await api.put(`api/v1/abc/config/products/${product_id}/geral`, {
                state: state
            }, {
                headers: {
                    authorization: `Bearer ${tokenKey}`
                }
            })
            
        } catch (error) {
            console.log(error)
            alert(error.error)
        }
    }

    async function deleteproduct (product_id){
        try {
            const res = await deleteProduct(tokenKey, product_id)
            if (res.isError) throw res
            setType({ name: 'alertMsg', type: 'information', value: { message: 'Sucesso ao excluir produto' } })
      setShow(true)
        } catch (error) {
            console.log(error)
            alert(error.error)
        }
    }

    useEffect(() => {
        const response = loadColumns() ;
        getProducts(response);
    }, [edited]);

    useEffect(() => {  
        getProducts()
    }, []);

    // Adiciona um botão para chamar a função de copiar a tabela para a área de tranferência
    const AddButton = () => (
        <>
            {user.type < 3 ? (
                <Tooltip disableFocusListener title="Importar">
                    <IconButton
                        onClick={() => {
                            setType({
                                name: "create"
                            });
                            setShow(true);
                            // showModalImport();
                        }}>
                        <FiPlus />
                        <span style={{ fontSize: "1.4rem" }}>Produto</span>
                    </IconButton>
                </Tooltip>
            ) : (
                <></>
            )}
        </>
    );

    var columnsAux = [];

    function setColumnsLocal() {
        if (localStorage.getItem("columnsabcManagement")) {
            const columnsToDisplayAux = JSON.parse(localStorage.getItem("columnsabcManagement"));
            setColumnsToDisplay(columnsToDisplayAux);
            return columnsToDisplayAux
        } else {
            let viewColumns = [];
            columnsAux.map((e) => {
                viewColumns.push({ column: e.name, display: e.options.display });
            });
            localStorage.setItem("columnsabcManagement", JSON.stringify(viewColumns));
            setColumnsLocal();
        }
    }

    const columnsExceptionView = ['product_description', 'product_type_description', 'product_id'];
    const columnsOrder = [0, 1, 2, 3, 4, 5, 6];


    const CustomToView = (props) => {
        return <TableViewCol {...props} ocultColumnsToView={columnsExceptionView} orderColumns={columnsOrder} />;
    };

    const options = {
        filterType: "multiselect",
        download: true,
        print: false,
        responsive: "vertical",
        tableBodyHeight: "71vh",
        selectableRowsHideCheckboxes: true,
        rowsSelected: user.type === 3 && viewCheckbox === true ? listExceptionClients : [],
        selectToolbarPlacement: "replace",
        selectableRows: "multiple",
        viewColumns: true,
        rowsPerPage: rowsPerPage,
        page: page,
        count: listFilteredProducts.length,
        rowsPerPageOptions: [30, 50, 100],
        textLabels: {
            body: {
                noMatch: loading ? <ArrowLoader /> : "Não foram encontrados dados",
                toolTip: "Ordenar",
                columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
            },
            pagination: {
                next: "Próxima",
                previous: "Anterior",
                rowsPerPage: "Linhas por página:",
                displayRows: "de",
            },
            toolbar: {
                search: "Pesquisar",
                downloadCsv: "Download XLSX",
                print: "Imprimir",
                viewColumns: "Ver colunas",
                filterTable: "Filtrar",
            },
            filter: {
                all: "Todos",
                title: "Filtros",
                reset: "Limpar",
            },
            viewColumns: {
                title: "Mostrar Colunas",
                titleAria: "Mostrar/Ocultar colunas",
            },
            selectedRows: {
                text: "linha(s) selecionadas",
                delete: "Excluir",
                deleteAria: "Excluir linhas Selecionadas",
            },
        },
        downloadOptions: {
            filename: "abcManagement.csv",
            separator: ";",
        },
        //   onDownload: () => {
        //       clientsExport();
        //       return false;
        //   },
        customToolbar: AddButton,
        onRowClick: (rowData, rowMeta) => {
            setType({ name: "update", product: rowData[2]  });
            setShow(true);
        },
        
        
        onViewColumnsChange: (changedColumn, action) => {
            let viewColumns = [];

            //em caso de clicar em mostrar todas
            if (changedColumn == null && action == "update") {
                visibleColumnsRef.current.map((e) => {
                    if (columnsExceptionView.indexOf(e.name) != -1) {
                        viewColumns.push({ column: e.name, display: false });
                    } else {
                        viewColumns.push({ column: e.name, display: true });
                    }
                });
                
                localStorage.setItem("columnsabcManagement", JSON.stringify(viewColumns));
                setColumnsLocal();
                return;
            }
            //codigo e nome, nao fazer nada
            if (changedColumn == "codigo" || changedColumn == "nome") return;

            visibleColumnsRef.current.forEach((e) => {
                if (e.name == changedColumn) {
                    e.options.display = action == "remove" ? false : true;
                }
            });
            visibleColumnsRef.current.map((e) => {
                viewColumns.push({ column: e.name, display: e.options.display });
            });
            localStorage.setItem("columnsabcManagement", JSON.stringify(viewColumns));
            setColumnsLocal();
        },
        setTableProps: () => {
            return {
                padding: "none",
                size: "small",  
            };
        },
    };

    useEffect(() => {
        if (!show) {
            setTabActive(0);
        }
    }, [show]);

    const title = (
        <div>
            <div style={{ fontSize: "1.6rem", fontWeight: "400" }}>{lastVisitedPage != 'dashAdvisor' ? 'Gerenciamento de Produtos' : 'Gerenciamento de Campanhas'} {tabActive == 0 ? " em Andamento": lastVisitedPage !=  'dashAdvisor' ? ' Encerrados' :" Encerradas"}</div>
            {/* <div>Total {totalClients}</div> */}
        </div>
    );

    const getMuiTheme = () =>
    createTheme({
      components: {
        MuiTableCell: {
            styleOverrides: {
                root:{
                        justifyContent: 'center',
                        whiteSpace: "nowrap",
                        textAlign: 'center',
                }
            },
          },
        MUIDataTableHeadCell: {
            styleOverrides: {
                contentWrapper: {
                    justifyContent: 'center',
                    whiteSpace: "nowrap",
                }
            }
        },
      },
    })
    
    function filterproducts(){
        listFilteredProducts.current = products.current.filter((e) => {
            // var strData = e.data_liquidacao ? e.data_liquidacao : e.finish
            // var partesData = strData.split("/");
            // var data = new Date(partesData[2], partesData[1] - 1, partesData[0]);
            // return tabActive == 0 ? (data.toISOString().split('T')[0] <= new Date().toISOString().split('T')[0] ? true : false) : (data.toISOString().split('T')[0] <= new Date().toISOString().split('T')[0] ? true : false)
        
            //se a data final ja passou, entao o produto esta encerrado e ficara no tabactive == 1 senao ficara no tabactive == 0
            return tabActive == 0 ? (e.finish ? (e.finish.split('/').reverse().join('-') >= new Date().toISOString().split('T')[0] ? true : false) : true) : (e.finish ? (e.finish.split('/').reverse().join('-') < new Date().toISOString().split('T')[0] ? true : false) : false)
        
        })
            
        forceUpdate()
    }

    useEffect(() => {
        filterproducts()
        forceUpdate()
        
    }, [tabActive])
    

    return loading ? (
        <div className="divLoading">
            <ArrowLoader />
        </div>
    ) : (
        <div className="clientsBody">
                <div className="estructuredsTab">
                    <div className={tabActive == 0 ? "estructuredsTabOption" : "estructuredsInactiveTabOption"}
                        onClick={(e) => {
                            setTabActive(0);
                            filterproducts()                             
                        }}>
                        <p>Em andamento</p>
                        <div></div>
                    </div>
                    <div className={tabActive == 1 ? 'estructuredsTabOption' : 'estructuredsInactiveTabOption'} 
                        onClick={() => {
                            setTabActive(1); 
                            filterproducts()
                            
                        }}>

                        <a>Encerrados</a>
                        <div></div>
                    </div>
                </div>
                    <ThemeProvider theme={getMuiTheme()}>
                        <MUIDataTable
                            title={title}
                            data={listFilteredProducts.current}
                            columns={visibleColumnsRef.current}
                            options={options}
                            components={{
                                TableViewCol: CustomToView,
                            }}
                            ></MUIDataTable>
                    </ThemeProvider>
                </div>
    )
}

