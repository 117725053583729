/*Tela que centraliza todos os componentes de clientes
/*Desenvolvido por: Equipe Rafael Cezário
*/

import React, { useEffect, useState } from "react";
//hooks
import { useModal } from '../../../../contexts/useModal'
//components
import { ClientsCadastration } from './clients.cadastration'
import { ClientsTable } from './clients.table';
import { ClientsVBATable } from './clientsVBA.table';
import { MyClientsVBATable } from './myClientsVBA.table';
import { TransferenciasVBA } from './transferVBA.table';
import { VbaExportCRM } from './vbaExportCRM';
import { ReguaPadrao } from './reguaPadrao';
import { MyTransfersVBA } from './myTransferVBA.table';
import { ClientDetails } from './clients.details';
import { ClientsEvasion } from './clientsEvasion.table';
import { VbaClientSetToBase } from './vbaClients.setToBase';
import { VbaClientDetails } from './vbaClients.details';
import { VbaClientActivation } from './vbaClients.activation';
import { VbaClientImport } from './vbaClients.import';
import { VbaClientImportStandBy } from './vbaClients.importStandBy';

import { VbaColumnsToView } from './vbaClients.columnsToView';
import { BrokersTable } from './clients.brokers.table';
import { ConfirmationBox } from '../../../../components/confirmationBox/confirmationBox';
import HorizontalLabelPositionBelowStepper from '../../../../components/stepper/stepper';
import Modal from '../../../../components/modal/modal';
import { useAuth } from '../../../../contexts/useAuth';
import { api } from '../../../../services/api';
//styles
import './styles/clients.css';

export function Clients() {

    const { show, setShow, type } = useModal();
    const { user, tokenKey } = useAuth();
    const [tabActive, setTabActive] = useState(0);
    const [isLeader, setIsLeader] = useState(0);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        async function searchClients() {
            setLoading(true)
            try {
                const result = await api.get('/api/v1/vba/isLeader', {
                    headers: {
                        authorization: `Bearer ${tokenKey}`
                    }
                })
                setIsLeader(result?.data?.data.length > 0 ? result?.data?.data[0][''] : 0)
            } catch (error) {
                alert(error?.response?.data?.message)
            }
            setTabActive(user.type < 3 ? 3 : 2)
            setLoading(false)
        }
        searchClients()
    },[])

    return (
        <div className='clientsContainer' style={{minWidth: !show ? '1000px' : '0'}}>
            <Modal >
                {type === 'registration' ?
                    (<ClientsCadastration />) :

                    type.name === 'alertMsg' ? 
                    (
                        <ConfirmationBox msgData={type} />
                    ) :

                    type.name === 'showBrokers' ? (<BrokersTable brokerData={type} />) :

                        type.name === 'showClientDetails' ? (<ClientDetails clientData={type} />) :
                        type.name === 'showVbaClientDetails' ? (<VbaClientDetails clientData={type} />) :
                        type.name === 'showVbaClientActivation' ? (<VbaClientActivation clientData={type} />) :
                        type.name === 'showVbaClientSetToBase' ? (<VbaClientSetToBase clientData={type} />) :
                        type.name === 'showVbaClientsImport' ? (<VbaClientImport clientData={type} />) :
                        type.name === 'showVbaClientsImportStandBy' ? (<VbaClientImportStandBy clientData={type} />) :
                        type.name === 'showSetColumnsToView' ? (<VbaColumnsToView data={type} />) :
                        type.name === 'exportCRM' ? (<VbaExportCRM func={type}/>) :
                        type.name === 'reguaPadrao' ? (<ReguaPadrao/>) :


                        type.name === 'showImportClients' ?
                            (<HorizontalLabelPositionBelowStepper parameters={
                                {
                                    type: 'import',
                                    steps: ['Selecione o arquivo para ser importado', 'Confira as informações'],
                                    requiredFields:
                                        ['codigo',
                                            'nome',
                                            'codigo_assessor',
                                            'nome_assessor',
                                            'codigo_broker',
                                            'nome_broker',
                                            'perfil_broker',
                                            'perfil_cliente',
                                            'net_total',
                                            'net_modalidade',
                                            'opera_sozinho'],
                                    typeofFields: ['number', 'string', 'number', 'string',
                                        'number', 'string', 'number', 'string', 'float', 'float',
                                        'string'
                                    ],
                                    requiredFieldsForAPI: [
                                        'client_external_id',
                                        'name',
                                        'advisor_external_id',
                                        'advisor_name',
                                        'broker_external_id',
                                        'broker_name',
                                        'profile_id',
                                        'client_profile',
                                        'net_total',
                                        'net_stocks',
                                        'operates_alone'],
                                }
                            } />) : 
                            type.name === 'successClientCadastration' ?
                            (<ConfirmationBox msgData={type}/>) :
                            type.name === 'successClientEdit' ? 
                            (<ConfirmationBox msgData={type}/>) :
                            (<></>) 
                }
            </Modal>
            <div className="clientsBody">
                <div className="estructuredsTab">
                    {user.type < 3 ? <div
                        className={tabActive === 1 ? "estructuredsTabOption" : "estructuredsInactiveTabOption"}
                        onClick={() => setTabActive(1)}
                    >
                        <p>Clientes</p>
                        <div></div>
                    </div> : <></>}
                    {user.type == 3 ? <div className={tabActive === 2 ? 'estructuredsTabOption' : 'estructuredsInactiveTabOption'} onClick={() => setTabActive(2)}>
                        <a>Clientes mesa</a>
                        <div></div>
                    </div> : <></>}
                    {(isLeader == 1 || user.type < 3 ) ? <div className={tabActive === 3 ? 'estructuredsTabOption' : 'estructuredsInactiveTabOption'} onClick={() => setTabActive(3)}>
                        <a>{user.type !== 3 ? 'VBA' : "Clientes mesa do time"}</a>
                        <div></div>
                    </div> : <></>}
                    {user.type === 3 ? <div className={tabActive === 4 ? 'estructuredsTabOption' : 'estructuredsInactiveTabOption'} onClick={() => setTabActive(4)}>
                        <a>Pedidos de Transferência</a>
                        <div></div>
                    </div> : <></>}
                    {(isLeader == 1 || user.type < 3) ? <div className={tabActive === 5 ? 'estructuredsTabOption' : 'estructuredsInactiveTabOption'} onClick={() => setTabActive(5)}>
                        <a>Aprovar Transferências</a>
                        <div></div>
                    </div> : <></>}
                    <div className={tabActive === 6 ? 'estructuredsTabOption' : 'estructuredsInactiveTabOption'} onClick={() => setTabActive(6)}>
                        <a>Clientes evasão</a>
                        <div></div>
                    </div>
                </div>
                {tabActive === 1 ? <ClientsTable /> : tabActive === 2 ? <MyClientsVBATable /> : tabActive === 3 ? <ClientsVBATable /> : tabActive === 4 ? <MyTransfersVBA />: tabActive === 5 ? <TransferenciasVBA /> : <ClientsEvasion />}
            </div>
            
        </div>
    )
}