//libraries
import React, { useEffect, useState, useRef } from "react"
import { useAuth } from "../../../contexts/useAuth"
import MUIDataTable from "mui-datatables";

/*Components*/
import Modal from "../../../components/modal/modal"
import { ConfirmationBox } from "../../../components/confirmationBox/confirmationBox"
import { TextField } from "@mui/material"
import Select from "react-select"
import { FaCheck, FaTimes, FaStar, FaQuestion, FaUserSlash } from "react-icons/fa"
import { ArrowLoader } from "../../../components/loaders/arrowLoader"
import { Tooltip, IconButton } from "@material-ui/core";
import ContentCopyIcon from "@mui/icons-material/ContentCopy"
import {ThemeProvider, createTheme } from '@mui/material/styles';

/*Services*/
import { getDefaultProfiles, newDefaultPermissionsProfile, editDefaultPermissionsProfile, removeDefaultPermissionsProfile } from "../../../services/asyncFunctions"

export function DashboardAdvisorConfig() {
    const [loading, setLoading] = React.useState(false)
    const [defaultColumns, setDefaultColumns] = useState([]);
    const [updatedColumns, setUpdatedColumns] = useState([]);
    const { user, tokenKey } = useAuth()
    const columnsLoader = useRef()

    async function getData(){
        setLoading(true)
        const defaultPermissions = await getDefaultProfiles(tokenKey);
        setDefaultColumns(defaultPermissions.data);
        setLoading(false)
    }

    useEffect(() => {
        getData()
    }, [])

    
    let defaultProfiles = []; // Mapeamento das colunas e informações únicas
    let defaultRows = []; // Mapeamento das linhas e informações únicas

    defaultColumns.forEach(x => {
        defaultProfiles.push({profile_id: x.profile_id, profile_description: x.profile_description})
    });

    defaultColumns.forEach(x => {
        defaultRows.push({column_id: x.column_id, column_description: x.column_description})
    });

    const setColumns = new Set(defaultProfiles.map(item => JSON.stringify(item)));
    let removeDups = [...setColumns].map(item => JSON.parse(item));
    removeDups = removeDups.sort((a, b) => a.profile_id - b.profile_id);

    const rowsTable = new Set(defaultRows.map(item => JSON.stringify(item)));
    let removeDups2 = [...rowsTable].map(item => JSON.parse(item));
    removeDups2 = removeDups2.sort((a, b) => a.profile_id - b.profile_id);
    
    // const columnsDatatable = removeDups;

    // function groupByKey(array, key) {
    //     return array
    //       .reduce((hash, obj) => {
    //         if(obj[key] === undefined) return hash; 
    //         return Object.assign(hash, { [obj[key]]:( hash[obj[key]] || [] ).concat(obj)})
    //       }, {})
    //  }

    // console.log(defaultColumns);
    // const dataView = groupByKey(defaultColumns, 'profile_id')

    // const selectedValue = optionsIcons2.find((option) => option.value == String(infosClient?.current[p]))
    // console.log(dataTable);

    const optionsIcons = [
        { value: "1", title: "Indefinido", label: <FaQuestion size={"20"} color="orange" />, icon: FaQuestion, color: "orange" },
        { value: "2", title: "Permitido", label: <FaCheck size={"20"} color="green" />, icon: FaCheck, color: "green" },
        { value: "3", title: "Negado", label: <FaTimes size={"20"} color="red" />, icon: FaTimes, color: "red" },
        { value: "4", title: "Sem perfil", label: <FaUserSlash size={"20"} color="black" />, icon: FaUserSlash, color: "black" },
        { value: "5", title: "Oportunidade", label: <FaStar size={"20"} color="#FFC300" />, icon: FaStar, color: "#FFC300" },
    ]

    const customStyles = {
        option: (provided) => ({
            ...provided,
            display: "flex",
            alignItems: "center",
            color:'#AEAEAE',
        }),
        singleValue: (provided) => ({
            ...provided,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        }),
        dropdownIndicator: (provided) => ({
            ...provided,
            paddingRight: 0,
        }),
        control: (provided) => ({
            ...provided,
            height: 30, // Definir a altura desejada
            minWidth: 70,
        }),
        listbox: (provided) => ({
            ...provided,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        }),
    }

    const customOption = ({ innerProps, label, data }) => (
        <div {...innerProps} title={data.title} style={{ textAlign: "center", paddingRight: "1.5rem" }}>
            {React.createElement(data.icon, { size: 20, color: data.color })}
        </div>
    )

    const getMuiTheme = () =>
    createTheme({
      components: {
        MuiTableCell: {
            styleOverrides: {
                root:{
                        justifyContent: 'center',
                        whiteSpace: "nowrap",
                        textAlign: 'center',
                }
            },
          },
        MUIDataTableHeadCell: {
            styleOverrides: {
                contentWrapper: {
                    justifyContent: 'center',
                    whiteSpace: "nowrap",
                }
            }
        },
      },
    })

    return (
        <div style={{ height:'-webkit-fill-available' , overflow: 'auto' }}>
            <div>
                {loading ?
                <div id="clientDetailsLoader">
                    <ArrowLoader />
                </div>
                :
                <div style={{ 
                    paddingLeft: '1rem',
                    paddingTop: '1rem'
                    }}>
                <div>
                <span style={{ fontSize: '24px', marginBottom: '1rem'}}>Adicionar novo produto</span>
                <form style={{ marginTop: '1rem', marginLeft: '1rem', height: '32px'}}>
                    <input type="text" name="name" placeholder="Nome do Produto" style={{ height: '-webkit-fill-available' }} />
                    <input type="submit" value="Adicionar" style={{ height: '-webkit-fill-available', marginLeft: '2rem' }}/>
                </form>

                </div>
                <hr style={{margin: '1rem 1rem 1rem 1rem'}}></hr>
                <div className="profileConfigContainer">
                    <span style={{ fontSize: '24px', marginBottom: '1rem'}}>Editar configurações de enquadramento de perfil</span><br/>
                    <span style={{ marginLeft: '1rem'}}>*As modificações serão automaticamente salvas.</span>
                    <div className="profileConfigSelector">
                        <div>
                            <div style={{ 
                                display: 'flex',
                                padding: '1rem',
                                placeContent: 'center'}}>
                                    <div style={{ display: 'flex', gridGap: '20px' }}>
                                    {/* <div>PERFIL
                                    {
                                        removeDups2.map((item, index) => {
                                            return (
                                                <div style={{ textAlign: 'center'}}>{item.column_description}</div>
                                            )
                                        })
                                    }
                                    </div> */}
                                {
                                    removeDups.map((item, index) => {
                                        return (
                                            <div style={{
                                                borderRadius: '10px',
                                                backgroundColor: '#F4F4F4',
                                                textAlign: 'center'
                                            }}>
                                            <div style={{ fontWeight: '800' }}>{item.profile_description}</div>
                                                {defaultColumns.map((x, i) => {
                                                    const selectedValue = optionsIcons.find((option) => option.value == String(x.permission_id))
                                                    return (
                                                        x.profile_id == item.profile_id ? 
                                                            <div style={{
                                                                textAlign: 'center',
                                                                paddingTop: '1rem',
                                                                paddingRight: '1rem',
                                                                paddingLeft: '1rem'
                                                            }}>
                                                                {x.column_description}
                                                            <div className="configBlocBodyContent">
                                                                <Select
                                                                    options={optionsIcons}
                                                                    styles={customStyles}
                                                                    components={{ Option: customOption }}
                                                                    isSearchable={false}
                                                                    value={selectedValue ? selectedValue : optionsIcons[0]}
                                                                    onChange={(e) => {
                                                                        updatedColumns.push({id: x.id, column_id: x.column_id, permission_id: e.value})
                                                                    }}
                                                                />
                                                                </div>
                                                            </div>
                                                        : null
                                                    )
                                                })}
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                    <div className="profileConfigOptions">
                    </div>
                </div> 
                </div>
                </div>}

            </div>
        </div>
    )
}
