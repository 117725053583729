import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import { Tooltip, IconButton } from "@material-ui/core";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { ThemeProvider,createTheme } from '@mui/material/styles';
/*Styles*/
import "../../monitoringPanel/myReservations/styles/myReservation.table.css"
/*Contexts*/
import { useAuth } from "../../../../contexts/useAuth";
import { useModal } from "../../../../contexts/useModal";
/*Services*/
import { getFixedIncomeView } from "../../../../services/asyncFunctions";
/*Components*/
import { ArrowLoader } from "../../../../components/loaders/arrowLoader";
import { toBRL } from "../../../../utils/formatNumberAndText/toBRLCurrency";
import { toPercent } from "../../../../utils/formatNumberAndText/formatNumberPercent";

export function OffshoreEducacional() {
    const [loading, setLoading] = useState(false);
    const [listDueStructured, setlistDueStructured] = useState([]);
    const [listDueStructuredFormatted, setlistDueStructuredFormatted] = useState([]);
    const { tokenKey } = useAuth();
    const { setType, setShow, edited, setEdited } = useModal();



    let dataMock = [
		{
			"source": "XP",
			"description": "Pitch de Vendas conta global: mostra principais argumentos para vender a conta global XP ",
			"link": "https://conteudos.xpi.com.br/wp-content/uploads/2022/07/PITCH-Clientes.pdf"
		},
		{
			"source": "XP",
			"description": "Pitch de Vendas Bonds: mostra principais argumentos para venda de bonds",
			"link": "https://conteudos.xpi.com.br/wp-content/uploads/2022/04/Bonds-Speech-de-Venda.pdf"
		},
		{
			"source": "XP",
			"description": "FAQ XP",
			"link": "https://hub.xpi.com.br/mercado-internacional/#/duvidas"
		},
		{
			"source": "XP",
			"description": "Link Expert Internacional: página com diversos materiais sobre investimento internacional na conta global XP",
			"link": "https://conteudos.xpi.com.br/assessor/internacional/plataforma-internacional/"
		},
		{
			"source": "XP",
			"description": "Link Expert Bonds: página com principais informações sobre bonds na conta global XP, incluindo lista de taxas do dia",
			"link": "https://conteudos.xpi.com.br/wp-content/uploads/2023/07/Taxas-Indicativas-Bonds-28-07-23.pdf"
		},
		{
			"source": "XP",
			"description": "Link Detalhes cartão de débito",
			"link": "https://materiais.avenue.us/features-economize-no-iof"
		},
		{
			"source": "XP",
			"description": "Guia de IR Velotax (ok para clientes)",
			"link": "https://conteudos.xpi.com.br/wp-content/uploads/2022/12/Guia-de-IR-de-Ativos-no-Exterior-Velotax-XP-vFF-15.12.2022.pdf"
		},
		{
			"source": "XP",
			"description": "Guia Abertura de conta (ok para clientes)",
			"link": "https://conteudos.xpi.com.br/wp-content/uploads/2022/12/Abrir-Conta.mp4"
		},
		{
			"source": "XP",
			"description": "Guia Remessa de Câmbio (ok para clientes)",
			"link": "https://conteudos.xpi.com.br/wp-content/uploads/2022/12/Funding.mp4"
		},
		{
			"source": "XP",
			"description": "Guia Negociação ações (ok para clientes)",
			"link": "https://conteudos.xpi.com.br/wp-content/uploads/2022/12/Busca.mp4"
		},
		{
			"source": "XP",
			"description": "Guia Negociação bonds (ok para clientes)",
			"link": "https://conteudos.xpi.com.br/wp-content/uploads/2022/12/Bonds.mp4"
		},
		{
			"source": "XP",
			"description": "Guia Consulta pós negociação (ok para clientes)",
			"link": "https://conteudos.xpi.com.br/wp-content/uploads/2022/12/Pos-Trading.mp4"
		},
		{
			"source": "XP",
			"description": "Guia Contratação Velotax (ok para clientes)",
			"link": "https://www.youtube.com/watch?v=suGUoDhHlgE"
		},
		{
			"source": "AVENUE",
			"description": "Página de conteúdos Avenue",
			"link": "https://b2b.avenue.us/contents/"
		},
		{
			"source": "AVENUE",
			"description": "Seleção Avenue",
			"link": "https://avenue.us/assets/pdf/selecao-teses-investimento.pdf"
		},
		{
			"source": "AVENUE",
			"description": "Seleção de Fundos",
			"link": "https://avenue.us/assets/pdf/selecao-carteiras-recomendadas-de-fundos.pdf"
		},
		{
			"source": "AVENUE",
			"description": "Seleção de ETFs",
			"link": "https://avenue.us/assets/pdf/selecao-carteiras-recomendadas-de-etf.pdf"
		},
		{
			"source": "AVENUE",
			"description": "Seleção de Bonds",
			"link": "https://avenue.us/assets/pdf/selecao-bonds-atualizada.pdf"
		},
		{
			"source": "AVENUE",
			"description": "Bond List Brazil",
			"link": "https://avenue.us/assets/pdf/bonds_list_br.pdf"
		},
		{
			"source": "AVENUE",
			"description": "Bond List US",
			"link": "https://avenue.us/assets/pdf/bonds_list_us.pdf"
		}
]

    async function getDue() {
        // const listDueStructured = await getFixedIncomeView(tokenKey, false);
        // // console.log(listDueStructured);
        // if (getFixedIncomeView?.isError) {
        //     setType({
        //         name: "alertMsg",
        //         type: "information",
        //         value: {
        //             message: getFixedIncomeView?.error,
        //         },
        //     });
        //     setShow(true);
        // } else {
            setlistDueStructured(dataMock);
            setLoading(false);
        }
    


    function formateAssets() {
        let dueStructuredFormatted = [];

        listDueStructured.map((dueStructured) => {
            dueStructuredFormatted.push({
                source: dueStructured.source,
                description: dueStructured.description,
                hlink: dueStructured.link
            });
        });

        setlistDueStructuredFormatted(dueStructuredFormatted);
        setLoading(false);
    }

    useEffect(() => {
        if (edited || listDueStructured.length === 0) {
            setEdited(false);
            setLoading(true);
            getDue();
        }
    }, [edited]);

    useEffect(() => {
        if (listDueStructured.length > 0) {
            formateAssets();
        }
    }, [listDueStructured]);

    function copyToClipboard() {
        const output = [];
        const titles = [
            "Canal de Origem",
            "Material",
            "Link"
        ];
        output.push(titles.join("\t"));
        const keys = [
            "source",
            "description",
            "link"
        ];
        listDueStructured.forEach((x) => {
            const tmp = keys.map((y) => {
                return y === "application_curve_value" || y === "sale_gross_value" || y === "agio_outcome" || y === "rentability_mtm"
                    ? String(x[y]).replace(".", ",")
                    : y === "created_at" || y === "due_at"
                    ? x[y].replace(/^(\d{4})-(\d{2})-(\d{2})T(\d{2}:\d{2}:\d{2})\.\d+Z$/, "$3/$2/$1")
                    : x[y];
            });
            output.push(tmp.join("\t"));
        });
        const table = output.join("\n");
        navigator.clipboard.writeText(table);
        alert("Conteúdo da tabela copiado para a área de transferência.");
    }

    async function dueStructuredExport(open, year) {
        setLoading(true);
        try {
            const myExport = await getFixedIncomeView(tokenKey, true);
            const responseURL = URL.createObjectURL(new Blob([myExport]));
            const link = document.createElement("a");
            link.href = responseURL;
            link.setAttribute("download", "materiais_educacionais_offshore.xlsx");
            document.body.appendChild(link);
            link.click();
            setLoading(false);
        } catch (error) {
            setType({
                name: "alertMsg",
                type: "information",
                value: {
                    message:
                        error?.response?.data?.message === undefined
                            ? "Erro ao buscar dados para exportação"
                            : error?.response?.data?.message,
                },
            });
            setShow(true);
            setLoading(false);
        }
    }

    const columns = [
        {
            name: "source",
            label: "Canal de Origem",
            options: {
                filter: true,
                sort: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        textAlign: "center"
                    },
                }),
            },
        },
        {
            name: "description",
            label: "Material",
            options: {
                filter: false,
                sort: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                    },
                }),
            },
        },
        {
            name: "hlink",
            label: "Link",
            options: {
                filter: false,
                sort: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                    },
                }),
                customBodyRender: (value) => <a target="_blank" rel="noreferrer" href={value}>Acessar</a>
            },
        }
    ];

    // Adiciona um botão para chamar a função de copiar a tabela para a área de tranferência
    const AddButton = () => (
        <Tooltip disableFocusListener title="Copiar para a área de transferência">
            <IconButton onClick={copyToClipboard}>
                <ContentCopyIcon />
            </IconButton>
        </Tooltip>
    );

    const options = {
        filterType: "multiselect",
        filter: true,
        download: false,
        print: false,
        responsive: "standard",
        tableBodyHeight: "70vh",
        selectableRowsHideCheckboxes: true,
        viewColumns: false,
        toolButton: {
            justifyContent: "center"
        },
        textLabels: {
            body: {
                noMatch: loading ? <ArrowLoader /> : "Não há links de materiais educacionais no momento!",
                toolTip: "Ordenar",
                columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
            },
            pagination: {
                next: "Próxima",
                previous: "Anterior",
                rowsPerPage: "Linhas por página:",
                displayRows: "de",
            },
            toolbar: {
                search: "Pesquisar",
                downloadCsv: "Download XLSX",
                print: "Imprimir",
                viewColumns: "Ver colunas",
                filterTable: "Filtrar",
            },
            filter: {
                all: "Todos",
                title: "Filtros",
                reset: "Limpar",
            },
            viewColumns: {
                title: "Mostrar Colunas",
                titleAria: "Mostrar/Ocultar colunas",
            },
            selectedRows: {
                text: "linha(s) selecionadas",
                delete: "Excluir",
                deleteAria: "Excluir linhas Selecionadas",
            },
        },
        downloadOptions: {
            filename: "file.csv",
            separator: ";",
        },
        onDownload: () => {
            dueStructuredExport();
            return false;
        },
        setTableProps: () => {
            return {
                padding: '0px 1rem 0px',
                size: 'small'
            };
        },
        rowsPerPage: 20,
        rowsPerPageOptions: [20, 200, 1000],
        customToolbar: AddButton,
    };

    const getMuiTheme = () =>
    createTheme({
      components: {
        MuiTableCell: {
            styleOverrides: {
                root:{
                        justifyContent: 'center',
                        whiteSpace: "",
                        textAlign: 'center'
                }
            },
          },
        MUIDataTableHeadCell: {
            styleOverrides: {
                contentWrapper: {
                    justifyContent: 'center',
                    whiteSpace: "pre-line",
                },
                fixedHeader: {
                    justifyContent: 'center',
                    whiteSpace: "pre-line",
                    maxWidth: '4rem',
                    padding: "0px 0.6rem 0px 0.6rem"
                },
                toolButton: {
                    margin: '0px'
                }
            }
        },
        MUIDataTableFilter: {
            styleOverrides: {
                root: {
                    minWidth: "40vw",
                    maxWidth: "50vw",
                },
            },
        },
        MUIDataTableBodyRow: {
            styleOverrides: {
            root: {
              '&:nth-child(odd)': { 
                backgroundColor: '#dbdbdb'
              }
            }
        }
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    boxShadow: 'none',
                    borderRadius: '0'
        }
      },
    }
    }
    })
    

    return (
        <div>
            <ThemeProvider theme={getMuiTheme()}>
                <MUIDataTable
                    title={""}
                    data={listDueStructuredFormatted}
                    columns={columns}
                    options={options}
                    className="tableUnsuitedStructured"
                    ></MUIDataTable>
            </ThemeProvider>
        </div>
    );
}

