import React from 'react';
//components
import { MyStructuredTable } from './myStructured.table';
import Modal from '../../../../components/modal/modal';
import { ConfirmationBox } from '../../../../components/confirmationBox/confirmationBox';
//styles
import './styles/myStructured.css';
//Contexts
import { useModal } from '../../../../contexts/useModal';
/*Components*/
import { MystructuredOrderDetail } from './myStructured.details';
import { AssetClassCadastration } from '../../configuration/assetClasses/asset.class.cadastration'
import { MyStructuredOrderStatusUpdate } from './myStructuredOrderStatusUpdate'
import { MyStructuredTableMUI } from './myStructuredMUI.table';
import { MyStructuredHistory } from './myStructured.history';

export function MyStructured() {

    const { show, setShow, type, setType } = useModal();

    return (
        <div style={{ paddingTop: '2rem', paddingLeft: '2rem', paddingRight: '2rem' }}>
            <Modal>
                {
                    type.name === 'showStructuredHistory' ? 
                    (
                        <MyStructuredHistory />
                    ) : 
                        type.name === 'alertMsg' ?
                        (
                            <ConfirmationBox msgData={type} />
                        ) : type.name === 'updateOrdersStatus' ? 
                            (
                                <MyStructuredOrderStatusUpdate />
                            ) :
                                (<></>)
                    }
            </Modal>
            <MyStructuredTableMUI />
        </div>
    )
}
