import React, { useEffect, useState } from 'react';

import { MdExpandLess, MdTipsAndUpdates, MdSpeakerNotes, MdOutlineAttachMoney } from "react-icons/md";
import { BsChatSquareDots } from "react-icons/bs";
import { FiActivity } from "react-icons/fi";

import { makeStyles } from '@material-ui/core/styles';
import { margin } from '@mui/system';

export function ChangeContentComponent(props){
  // console.log(props)
  const [closedContentComponent, setClosedContentComponent] = useState(true)
  const [widthContentComponent, setWidthContentComponent] = useState('14rem')
  const [heightContentComponent, setHeightContentComponent] = useState('3rem')
  const [borderRadiusContentComponent, setBorderRadiusContentComponentContentComponent] = useState('30px')
  const [borderBottomRightRadiusContentComponent, setBorderBottomRightRadiusContentComponentContentComponent] = useState('30px')

  const [selectedPage, setSelectedPage] = useState("Saldos & Posições")

  const classes = makeStyles((theme) => ({
    globalContainerChangeContentComponent: {
      width: widthContentComponent,
      minWidth: '12rem',
      height: heightContentComponent,
      position: 'absolute',
      bottom: '1rem',
      right: '2rem',
      backgroundColor: '#141414',
      borderRadius: borderRadiusContentComponent,
      borderBottomRightRadius: borderBottomRightRadiusContentComponent,
      transition: 'all ease 0.2s',
      zIndex: '999',
      boxShadow: '0px 0px 4px black'
    },
    containerOpenContentComponent: {
      position: 'absolute',
      bottom: '0px',
      display: 'grid',
      gridTemplateColumns: '1fr auto',
      width: '100%',
      height: "3rem",
      backgroundColor: '#141414',
      borderRadius: borderRadiusContentComponent,
      borderBottomRightRadius: borderBottomRightRadiusContentComponent,
    },
    openContentComponentButton: {
      width: '2.8rem',
      height: '2.8rem',
      border: 'none',
      margin: 'auto',
      borderRadius: '20px',
      background: 'transparent',
      color: '#f9f9f9',
      cursor: 'default', // overwrite button property
      // '&:hover': {
      //   color: '#cdcdcd',
      // }
    },
    openContentComponentButtonIcon: {
      transition: 'color ease 0.1s, transform ease 0.2s',
      transform: closedContentComponent ? 'rotate(0deg)' : 'rotate(180deg)',
    },
    selectedPageText: {
      color: '#f9f9f9',
      margin: 'auto',
      marginRight: '0px',
      width: '100%',
      paddingLeft: '1rem',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    globalContentOptionsContainer: {
      display: 'grid',
      marginTop: '1rem',
    },
    containerContentOption: {
      display: 'grid',
      width: '100%',
      height: '2.4rem',
      color: '#f9f9f9',
      transition: 'all ease 0.1s',
      '&:hover': {
        color: '#141414',
        backgroundColor: '#f9f9f9',
        cursor: 'pointer',
      },
    },
    contentOption: {
      margin: 'auto',
      marginLeft: '0px',
      paddingLeft: '1rem',
      paddingRight: '1rem',
      fontSize: '1.2rem',
      whiteSpace: 'nowrap',
      display: 'grid',
      gridTemplateColumns: '1fr auto'
    },
    contentOptionIcon: {
      paddingRight: '0.6rem',
      margin: 'auto'
    },
    selectedContentOptionIcon: {
      paddingRight: '0.6rem',
      margin: 'auto',
      height: 'fit-content',
      // paddingTop: '1rem'
    },
  }))
  const changeContentComponentStyle = classes();

  function changeClosedContentComponent(){
    setClosedContentComponent(!closedContentComponent)
    if(closedContentComponent){
      setWidthContentComponent('14rem')
      setHeightContentComponent('11.4rem') // muda de acordo com o número de subpáginas (1.2rem + 3rem + 2.4remXelementos)
      setBorderRadiusContentComponentContentComponent('10px')
      setBorderBottomRightRadiusContentComponentContentComponent('0px')
    }
    else{
      setWidthContentComponent('14rem')
      setHeightContentComponent('3rem')
      setBorderRadiusContentComponentContentComponent('30px')
      setBorderBottomRightRadiusContentComponentContentComponent('30px')
    }
  }

  return (
    <div className={changeContentComponentStyle.globalContainerChangeContentComponent}
      onMouseEnter={() => {changeClosedContentComponent()}} 
      onMouseLeave={() => {changeClosedContentComponent()}}
      // onClick={() => {changeClosedContentComponent()}}
    >
      {closedContentComponent? 
        <></>
        :
        <div className={changeContentComponentStyle.globalContentOptionsContainer}>
          <div className={changeContentComponentStyle.containerContentOption} onClick={() => {
              props?.props?.func(1)
              setSelectedPage("Saldos & Posições")
            }}>
            <div className={changeContentComponentStyle.contentOption}><FiActivity className={changeContentComponentStyle.contentOptionIcon} size={20}/>Saldos & Posições</div>
          </div>
          <div className={changeContentComponentStyle.containerContentOption} onClick={() => {
              props?.props?.func(3)
              setSelectedPage("Recados")
            }}>
            <div className={changeContentComponentStyle.contentOption}><BsChatSquareDots className={changeContentComponentStyle.contentOptionIcon} size={20}/>Recados</div>
          </div>
          <div className={changeContentComponentStyle.containerContentOption} onClick={() => {
              props?.props?.func(2)
              setSelectedPage("Receita")
            }}>
            <div className={changeContentComponentStyle.contentOption}><MdOutlineAttachMoney className={changeContentComponentStyle.contentOptionIcon} size={20}/>Receita</div>
          </div>
        </div>
      }
      <div className={changeContentComponentStyle.containerOpenContentComponent}><div className={changeContentComponentStyle.selectedPageText}>
          {selectedPage == "Saldos & Posições" ? 
            <FiActivity className={changeContentComponentStyle.selectedContentOptionIcon} size={13}/>
            :
            selectedPage == "Receita" ?
              <MdOutlineAttachMoney className={changeContentComponentStyle.selectedContentOptionIcon} size={13}/>
              :
              <BsChatSquareDots className={changeContentComponentStyle.selectedContentOptionIcon} size={13}/>
          }
          {selectedPage}
        </div><button className={changeContentComponentStyle.openContentComponentButton} ><MdExpandLess className={changeContentComponentStyle.openContentComponentButtonIcon} size={34}/></button></div>
    </div>
  )
}