import React, { useEffect, useState } from "react";
/*Contexts*/
import { useAuth } from "../../../../contexts/useAuth";
import { useModal } from "../../../../contexts/useModal";
/*Services*/
import { api } from "../../../../services/api";
/*Components*/
// import { ArrowLoader } from "../../../../components/loaders/arrowLoader";
/*Utils*/
import { toBRL } from "../../../../utils/formatNumberAndText/toBRLCurrency";
import { titleize } from "../../../../utils/formatNumberAndText/formatTextUpperFirstLetter";
import { MdOutlineMonetizationOn, MdOutlineCheckCircleOutline, MdOutbox } from "react-icons/md";
import { getABCReferenceOperation } from "../../../../services/asyncFunctions"

import './abcModal.css'

export function AbcClientAcompanhar(props) {
  const { tokenKey, user } = useAuth();
  const { show, setShow, type, setType, edited, setEdited } = useModal();
  const [loading, setLoading] = useState(true);
  const [operation, setOperation] = useState([]);

//   useEffect(() => {
    
//   }, [show]);

    async function availableOperation() {
        setLoading(true);  
        try {
            const res = await getABCReferenceOperation(tokenKey, props.prop.operation_id);
            console.log(res?.data?.data);
            setOperation(res?.data?.data);
        } catch (error) {
            setLoading(false);
            setType({ name: 'alertMsg', type: 'information', value: { message: error?.response?.data?.message == undefined ? 'Erro interno ao carregar os dados' : error?.response?.data?.message } })
            setShow(true);
        }
        setLoading(false);
    }

    useEffect(() => {
        availableOperation()
    }, [show])

  return (
    <div className="containerModalAbcDetails">
      <div className="clientControlHeader">
        <div className="editBrancheBoxHeader">
          <div className="containerProductName">
            <p>Ações Disponíveis</p>
          </div>
          <button
            onClick={() => setShow(false)}
            className="modalCloseButton"
          >
            X
          </button>
        </div>
      </div>
      <div className="containerClientMenu">
        CHEQUE O TERMINAL
      </div>
    </div>
  );
}