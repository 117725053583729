import React from "react";
/*Styles*/
import "./styles/brokerPayroll.css";
/*Components*/
import { MonitorCustodiaTbl } from "./tables/monitorCustodia.table";
import Modal from "../../../components/modal/modal";
import { ConfirmationBox } from "../../../components/confirmationBox/confirmationBox";
/*Contexts*/
import { useModal } from "../../../contexts/useModal";

export function MonitorCustodia() {
    const { type } = useModal();

    return (
        <div className="brokerPayrollContainer">
            <Modal>{type.name === "alertMsg" ? <ConfirmationBox msgData={type} /> : <></>}</Modal>
            <MonitorCustodiaTbl />
        </div>
    );
}