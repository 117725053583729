//libraries
import React, { useEffect, useState, useRef, useReducer } from "react";
import { FiPlus, FiSearch, FiArrowDown, FiCopy } from "react-icons/fi";
import { AiOutlineArrowUp, AiOutlineArrowDown } from "react-icons/ai";
import { FormGroup, FormLabel, FormControl, ListItemText, TextField, Checkbox, FormControlLabel, Grid, Select, InputLabel, MenuItem } from "@mui/material";
/*Styles*/
import "./mainStyle.css";
import { MdOutlineMonetizationOn, MdOutlineCheckCircleOutline, MdOutbox } from "react-icons/md";
//services
import { api } from "../../../services/api";
/*Components*/
import Modal from "../../../components/modal/modal";
import { ConfirmationBox } from "../../../components/confirmationBox/confirmationBox";
import {RefusalReason} from "./modalAbc/abcRefusal"
/*Contexts*/
import { useModal } from "../../../contexts/useModal";
import { useAuth } from "../../../contexts/useAuth";
import { ClientsAbcTable } from "./abcMainPage"
import { AbcDetailsModal } from "./modalAbc/abcDetailsModal"
import { AbcClientMenu } from "./modalAbc/abcClientMenuModal";
import { AbcClientBoletarEstruturada } from "./modalAbc/abcClientBoletarEstruturada";
import { AbcClientBoletarOfertaPublica } from "./modalAbc/abcClientBoletarOfertaPublica";
import { AbcClientAcompanhar } from "./modalAbc/abcClientAcompanhar";
import { AbcStatusFilter } from "./modalAbc/abcStatusFilter";
import { toBRL } from "../../../utils/formatNumberAndText/toBRLCurrency";
import MUIDataTable from "mui-datatables";
import { TableCell } from "@material-ui/core";
import { DashboardAdvisoClientProductsModal } from "../dashboard/dashboardAdvisorClientProdutcsModal"
import { DashboardAdvisorStatusFilter } from "../dashboard/dashboardAdvisorStatusFilter"
import { DashboardAdvisorDetailsModal } from "../dashboard/dashboardAdvisorDetailsModal"
import { CampainSpeech } from "../dashboard/speech_modal"
import { SettlementCalendarModal } from "../dashboard/dashboardSettlementCalendar"
import { AbcClientProductsModal } from "./modalAbc/abcClientProductsModal";
import { VbaClientActivation } from "../registrations/clients/vbaClients.activation";

export function Abc() {
    const { type, setType } = useModal();    
  
    return (
      <>
        <Modal>
          {type.name === "details" ? (
            <AbcDetailsModal prop={type} />
          ) : type.name === "detailsClientProduto" ? (
              <DashboardAdvisorDetailsModal prop={type} />
          ) : type.name === "menu" ? (
            <AbcClientMenu prop={type}/>
          ) : type.name === "boletarEstruturada" ? (
            <AbcClientBoletarEstruturada prop={type} />
          ) : type.name === "boletarOfertaPublica" ? (
            <AbcClientBoletarOfertaPublica prop={type} />
          ) : type.name === "acompanhamento" ? (
            <AbcClientAcompanhar prop={type} />
          ) : type.name === "settlementCalendar" ? (
            <SettlementCalendarModal prop={type}/>
          ) :
           type.name === "status" ? (
            <AbcStatusFilter prop={type} />
          ) : type.name === "alertMsg" ? (
            <ConfirmationBox msgData={type} />
          ) : type.name === "recusa" ? (
            <RefusalReason prop={type} />
          ) : type.name === "detailsClient" ? (
            <AbcClientProductsModal prop={type} />
        ) : type.name === "status" ? (
            <DashboardAdvisorStatusFilter prop={type} />
        ) : type.name === "speechModal" ? (
            <CampainSpeech prop={type} />
        ) : type.name === 'showVbaClientActivation' ? (<VbaClientActivation clientData={type} />) 
          : (
            <></>
        )}
          
        </Modal>
        <div className="abcMainTableAbc">
          <ClientsAbcTable prop={type} />
        </div>
      </>
    );
  }