import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import { Tooltip, IconButton } from "@material-ui/core";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { ThemeProvider,createTheme } from '@mui/material/styles';
/*Styles*/
import "../../monitoringPanel/myReservations/styles/myReservation.table.css"
/*Contexts*/
import { useAuth } from "../../../../contexts/useAuth";
import { useModal } from "../../../../contexts/useModal";
/*Services*/
import { getNegativeBalance, getDatabaseUpdateTime } from "../../../../services/asyncFunctions";
/*Components*/
import { ArrowLoader } from "../../../../components/loaders/arrowLoader";
import { toBRL } from "../../../../utils/formatNumberAndText/toBRLCurrency";
import { pickHex } from '../../../../utils/hexPicker/hexPicker';

export function NegativeBalanceTbl() {
    const [loading, setLoading] = useState(false);
    const [listNegative, setListNegative] = useState([]);
    const [databaseUpdateTime, setDatabaseUpdateTime] = useState([]);
    const [listBalanceFormatted, setListBalanceFormatted] = useState([]);
    const { tokenKey } = useAuth();
    // const { user, tokenKey } = useAuth();
    const { setType, setShow, edited, setEdited } = useModal();
    const [rowsPerPage, setRowsPerPage] = useState(30);

    async function getBalance() {
        const listNegative = await getNegativeBalance(tokenKey, false);
        // console.log(listNegative);
        if (getNegativeBalance?.isError) {
            setType({
                name: "alertMsg",
                type: "information",
                value: {
                    message: getNegativeBalance?.error,
                },
            });
            setShow(true);
        } else {
            const data = listNegative.slice();
            const color = [120, 0, 0];
            const white = [0, 0, 0];
            
            const mapping = data.map((item, index) => {
            let backgroundColor = [];   
            const ratio = (index / (data.length / 2 )); 
            backgroundColor = `rgba(${pickHex(white, color, ratio-1).join(",")})`;
            
            Object.assign(item, {backgroundColor: backgroundColor});
            })
            setListNegative(listNegative);
            setLoading(false);
        }
    }

    async function getDatabaseUpdateTimeValues() {
        const bases = 'balance';
        const databaseUpdateTime = await getDatabaseUpdateTime(tokenKey, bases);
        if (getDatabaseUpdateTime?.isError) {
            setType({
                name: "alertMsg",
                type: "information",
                value: {
                    message: getDatabaseUpdateTime?.error,
                },
            });
            setShow(true);
        } else {
            try {
                let dateFormat = databaseUpdateTime[0].updated_at;
                const dateObj = new Date(dateFormat)
                const dateFormatted = String(` - Atualizado em: ${dateObj.getDate()}/${dateObj.getMonth() + 1}/${dateObj.getFullYear()} ${dateObj.getHours()}:${dateObj.getMinutes()}h`)
                setDatabaseUpdateTime(dateFormatted);
                // console.log(dateFormatted);
                setLoading(false);
            } catch (e) {
                let dateFormat = ''
                setDatabaseUpdateTime(dateFormat);
                setLoading(false);
            }
        }
    }

    function formateAssets() {
        let balanceFormatted = [];

        listNegative.map((balance) => {
            balanceFormatted.push({
                client_id: balance.client_id,
                client_name: balance.client_name,
                advisor_id: balance.advisor_id,
                advisor_name: balance.advisor_name,
                broker_id: balance.broker_id,
                broker_name: balance.broker_name,
                d0: balance.d0,
                d1: balance.d1,
                d2: balance.d2,
                d3: balance.d3,
                total: balance.total
            });
        });

        setListBalanceFormatted(balanceFormatted);
        setLoading(false);
    }

    useEffect(() => {
        if (edited || listNegative.length === 0) {
            setEdited(false);
            setLoading(true);
            getBalance();
            getDatabaseUpdateTimeValues();
        }
    }, [edited]);

    useEffect(() => {
        if (listNegative.length > 0) {
            formateAssets();
        }
    }, [listNegative]);

    function copyToClipboard() {
        const output = [];
        const titles = [
            "Código Cliente",
            "Cliente",
            "Assessor",
            "Broker",
            "Saldo D0",
            "Saldo Projetado"
        ];
        output.push(titles.join("\t"));
        const keys = [
            "client_id",
            "client_name",
            "advisor_name",
            "broker_name",
            "d0",
            "total"
        ];
        listNegative.forEach((x) => {
            const tmp = keys.map((y) => {
                return y === "d0" || "total"
                    ? String(x[y]).replace(".", ",")
                    : x[y];
            });
            output.push(tmp.join("\t"));
        });
        const table = output.join("\n");
        navigator.clipboard.writeText(table);
        alert("Conteúdo da tabela copiado para a área de transferência");
    }

    async function negativeBalanceExport() {
        setLoading(true);
        try {
            const myExport = await getNegativeBalance(tokenKey, true);
            const responseURL = URL.createObjectURL(new Blob([myExport]));
            const link = document.createElement("a");
            link.href = responseURL;
            link.setAttribute("download", "Saldo Negativo.xlsx");
            document.body.appendChild(link);
            link.click();
            setLoading(false);
        } catch (error) {
            setType({
                name: "alertMsg",
                type: "information",
                value: {
                    message:
                        error?.response?.data?.message === undefined
                            ? "Erro ao buscar dados para exportação"
                            : error?.response?.data?.message,
                },
            });
            setShow(true);
            setLoading(false);
        }
    }

    const columns = [
        {
            name: "client_id",
            label: "Código Cliente",
            options: {
                filter: false,
                sort: true,

                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        padding: '0px 0.6rem 0px 0.6rem',
                    },
                }),
                // customHeadRender: ({ index, label, ...column }, sortColumn, tableMeta) => (
                //     <TableCell onClick={() => sortColumn(index)} key={index} className="white_space">
                //         {label}
                //         <TableSortLabel active={tableMeta.name === column.name} direction={tableMeta.direction || "asc"} />
                //     </TableCell>
                // ),
            },
        },
        {
            name: "client_name",
            label: "Cliente",
            options: {
                filter: false,
                sort: true,

                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        padding: '0px 0.6rem 0px 0.6rem',
                    },
                }),
                // customHeadRender: ({ index, label, ...column }, sortColumn, tableMeta) => (
                //     <TableCell onClick={() => sortColumn(index)} key={index} className="white_space">
                //         {label}
                //         <TableSortLabel active={tableMeta.name === column.name} direction={tableMeta.direction || "asc"} />
                //     </TableCell>
                // ),
            },
        },
        {
            name: "advisor_name",
            label: "Assessor",
            options: {
                filter: true,
                sort: true,
                // caso não seja para mostrar a coluna baseado no tipo de acesso, usar a linha display
                // display: tokenKey.type > 3 ? true : false,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap"
                    },
                }),
                // customHeadRender: ({ index, label, ...column }, sortColumn, tableMeta) => (
                //     <TableCell onClick={() => sortColumn(index)} key={index} className="white_space">
                //         {label}
                //         <TableSortLabel active={tableMeta.name === column.name} direction={tableMeta.direction || "asc"} />
                //     </TableCell>
                // ),
            },
        },
        {
            name: "broker_name",
            label: "Broker",
            options: {
                filter: true,
                sort: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        padding: '0px 0.6rem 0px 0.6rem',
                    },
                }),
                // customHeadRender: ({ index, label, ...column }, sortColumn, tableMeta) => (
                //     <TableCell onClick={() => sortColumn(index)} key={index} className="white_space">
                //         {label}
                //         <TableSortLabel active={tableMeta.name === column.name} direction={tableMeta.direction || "asc"} />
                //     </TableCell>
                // ),
            },
        },
        {
            name: "backgroundColor",
            label: "Background Color",
            options : {
                display: false
            }
        },
        {
            name: "d0",
            label: "Saldo D0",
            options: {
                filter: false,
                sort: true,

                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        padding: '0px 0.6rem 0px 0.6rem',
                    },
                }),
                customBodyRender: (value, tableMeta, index) => {
                    return (
                      <span style={{ color : String(tableMeta.currentTableData[tableMeta.rowIndex].data[4]), fontWeight : "bold" }}>
                        {toBRL(value)}
                        {/* {tableMeta} */}
                      </span>
                    );
                }
            }
        },
        {
            name: "d1",
            label: "Saldo D1",
            options: {
                filter: false,
                sort: true,

                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        padding: '0px 0.6rem 0px 0.6rem',
                    },
                }),
                customBodyRender: (value, tableMeta, index) => {
                    return (
                      <span style={{ color : String(tableMeta.currentTableData[tableMeta.rowIndex].data[4]), fontWeight : "bold" }}>
                        {toBRL(value)}
                        {/* {tableMeta} */}
                      </span>
                    );
                }
            }
        },
        {
            name: "d2",
            label: "Saldo D2",
            options: {
                filter: false,
                sort: true,

                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        padding: '0px 0.6rem 0px 0.6rem',
                    },
                }),
                customBodyRender: (value, tableMeta, index) => {
                    return (
                      <span style={{ color : String(tableMeta.currentTableData[tableMeta.rowIndex].data[4]), fontWeight : "bold" }}>
                        {toBRL(value)}
                        {/* {tableMeta} */}
                      </span>
                    );
                }
            }
        },
        {
            name: "total",
            label: "Saldo Projetado",
            options: {
                filter: false,
                sort: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        padding: '0px 0.6rem 0px 0.6rem',
                    },
                }),
                customBodyRender: (value, tableMeta, index) => {
                    return (
                      <span style={{ color : String(tableMeta.currentTableData[tableMeta.rowIndex].data[4]), fontWeight : "bold" }}>
                        {toBRL(value)}
                        {/* {tableMeta} */}
                      </span>
                    );
                }
            }
        }
    ];

    // Adiciona um botão para chamar a função de copiar a tabela para a área de tranferência
    const AddButton = () => (
        <Tooltip disableFocusListener title="Copiar para a área de transferência">
            <IconButton onClick={copyToClipboard}>
                <ContentCopyIcon />
            </IconButton>
        </Tooltip>
    );

    const options = {
        filterType: "multiselect",
        filter: true,
        download: true,
        print: false,
        responsive: "vertical",
        tableBodyHeight: "60vh",
        draggableColumns: {
            enabled: true,
        },
        selectableRowsHideCheckboxes: true,
        viewColumns: false,
        toolButton: {
            justifyContent: "center"
        },
        textLabels: {
            body: {
                noMatch: loading ? <ArrowLoader /> : "A busca não trouxe retornos para o filtro aplicado.",
                toolTip: "Ordenar",
                columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
            },
            pagination: {
                next: "Próxima",
                previous: "Anterior",
                rowsPerPage: "Linhas por página:",
                displayRows: "de",
            },
            toolbar: {
                search: "Pesquisar",
                downloadCsv: "Download XLSX",
                print: "Imprimir",
                viewColumns: "Ver colunas",
                filterTable: "Filtrar",
            },
            filter: {
                all: "Todos",
                title: "Filtros",
                reset: "Limpar",
            },
            viewColumns: {
                title: "Mostrar Colunas",
                titleAria: "Mostrar/Ocultar colunas",
            },
            selectedRows: {
                text: "linha(s) selecionadas",
                delete: "Excluir",
                deleteAria: "Excluir linhas Selecionadas",
            },
        },
        downloadOptions: {
            filename: "saldo_d0_e_projetado.csv",
            separator: ";",
        },
        onDownload: () => {
            negativeBalanceExport();
            return false;
        },
        customToolbar: AddButton,
        sortOrder: {
            name: 'broker_name',
            direction: 'asc'
        },
        setTableProps: () => {
            return {
                padding: 'none',
                size: 'small'
            };
        },
        rowsPerPage: rowsPerPage,
        rowsPerPageOptions: [30, 50, 100],
    };

    const getMuiTheme = () =>
    createTheme({
      components: {
        MuiTableCell: {
            styleOverrides: {
                root:{
                        justifyContent: 'center',
                        whiteSpace: "nowrap",
                        textAlign: 'center',
                }
            },
          },
        MUIDataTableHeadCell: {
            styleOverrides: {
                contentWrapper: {
                    justifyContent: 'center',
                    whiteSpace: "nowrap",
                }
            }
        },
      },
    })

    return (
        <div>
            <ThemeProvider theme={getMuiTheme()}>
            <MUIDataTable
                title={`Saldo Negativo ${databaseUpdateTime}`}
                data={listNegative}
                columns={columns}
                options={options}
                ></MUIDataTable>
            </ThemeProvider>
        </div>
    );
}

