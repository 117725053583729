import React, { useEffect, useState } from 'react'
/*Styles*/
import './styles/myPushOrders.errors.table.css'
/*Utils*/
import { ModalSizeFitToScreen } from '../../../../utils/responsiveFunctions/modalSizeFitToScreen'
import { useModal } from '../../../../contexts/useModal'

export function MyPushOrderErrorsTable(props) {

    const [errorsList, setErrorsList] = useState([])
    const { screenY } = ModalSizeFitToScreen()
    const {show, setShow} = useModal()

    useEffect(() => {
        setErrorsList(props?.data)
    }, [])


    return (
        <div className='myPushOrderErrorsTable' style={{
            maxHeight: screenY
        }}>
            <table>
                <thead>
                    <tr>
                        <th>
                            <div>
                                <p>Descrição</p>
                                <button className='modalCloseButton' onClick={()=> setShow(false)}>x</button>
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {
                        errorsList.map((error) => (
                            <tr>
                                <td>{error}</td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        </div>
    )
}