import React, { useState, useEffect } from 'react'
import ReactApexChart from 'react-apexcharts';

export function GraphPie(){
  const valores = [10,20,30,40]
  const nomes = ["primeiro","segundo","terceiro","quarto"]
  const state = {      
    options: {
      chart: {
        type: 'donut',
      },
      series: valores,
      labels: nomes,
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              total: {
                show: true,
                // showAlways: true,
                label: '',
                fontWeight: 600,
                formatter: function () {return "ASDASD<br>ASDASD"}
              }
            }
          }
        }
      },
      tooltip: {
        y: {
          formatter: function(val) {
            return ''
          },
          title: {
            formatter: function (seriesName) {
              return seriesName
            }
          }
        }
      }
    },
  };

  return (
    <ReactApexChart options={state.options} series={state.options.series} type="donut" />
  );
}