/*Componente detalhe de usuários
/*Descrição: Exibe os detalhes do usuário permitindo a edição
/*Desenvolvido por: Equipe Rafael Cezário
*/

//libraries
import React, { useState, useEffect } from 'react';
import AntSwitch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab';
//services
import { api } from '../../../../services/api';
import { getBranches } from '../../../../services/asyncFunctions';
import { getTypesUsers } from '../../../../services/asyncFunctions';
import { getBrokerProfiles } from '../../../../services/asyncFunctions';
//components
import { ArrowLoader } from '../../../../components/loaders/arrowLoader';
//hooks
import { useModal } from '../../../../contexts/useModal';
import { useAuth } from '../../../../contexts/useAuth';
//utils
import { titleize } from '../../../../utils/formatNumberAndText/formatTextUpperFirstLetter'
import { ModalSizeFitToScreen } from '../../../../utils/responsiveFunctions/modalSizeFitToScreen'
//styles
import './styles/users.editBox.css';
import { styleClientsInput } from '../../../../components/styles/autocompletField';


export function UserEditBox(props) {

    const userExternal_id = props.userData.external_id;
    const userDataFullName = props.userData.full_name;
    const userDataTypeAccess = props.userData.type_access;
    const userDataTotalClients = props.userData.total_clients;
    const userDataEmail = props.userData.email;
    const userDataBranche = props.userData.branche;
    const userDataTypeDescription = props.userData.type_description;
    const userDataProfileDescription = props.userData.profile_description;
    const userDataProfileId = props.userData.profile_id;
    const [inEdit, setInEdit] = useState({
        checkedA: false,
        checkedC: false,
    });
    const classes = styleClientsInput();
    const [branchesList, setBranchesList] = useState([]);
    const [profilesList, setProfilesList] = useState([]);
    const [selectedUserFullName, setSelectedUserFullName] = useState('');
    const [selectedUserEmail, setSelectedUserEmail] = useState('');
    const [selectedBranch, setSelectedBranch] = useState('');
    const [selectedUserType, setSelectedUserType] = useState('');
    const [selectedProfileUser, setSelectedProfileUser] = useState('1');
    const [listTypes, setListTypes] = useState([]);
    const [loading, setLoading] = useState(true);
    const { show, setShow, setType, edited, setEdited } = useModal();
    const { user, tokenKey, setTokenKey } = useAuth();
    const { screenX, screenY } = ModalSizeFitToScreen()
    const requiredFields = [
        {field: 'selectedUserFullName', value: selectedUserFullName },
        {field: 'selectedUserEmail', value: selectedUserEmail },
    ]
    const [errorFields, setErrorFields] = useState({
        selectedUserFullName: false,
        selectedUserEmail: false
    })

    useEffect(() => {

        async function getData() {

            const branchesGet = await getBranches(tokenKey)
            if (branchesGet?.isError) {
                setType({
                    name: 'alertMsg',
                    type: 'information',
                    value: {
                        message: branchesGet?.error
                    }
                })
                setShow(true);
            } else {
                setBranchesList(branchesGet);
            }
            const typesUsersGet = await getTypesUsers(tokenKey)
            if (typesUsersGet?.isError) {
                setType({
                    name: 'alertMsg',
                    type: 'information',
                    value: {
                        message: typesUsersGet?.error
                    }
                })
                setShow(true);
            } else {
                setListTypes(typesUsersGet);
            }

            const brokerProfilesGet = await getBrokerProfiles(tokenKey)
            if (brokerProfilesGet?.isError) {
                setType({
                    name: 'alertMsg',
                    type: 'information',
                    value: {
                        message: brokerProfilesGet?.error
                    }
                })
                setShow(true);
            } else {
                setProfilesList(brokerProfilesGet);
            }
        }

        setSelectedUserType({ type_description: userDataTypeDescription });
        setSelectedUserFullName(userDataFullName);
        setSelectedUserEmail(userDataEmail);
        setSelectedProfileUser({ profile_description: userDataProfileDescription });
        setSelectedBranch(userDataBranche);
        setInEdit({
            checkedA: false,
            checkedC: false,
        })
        getData();
        setLoading(false)

    }, [show])

    const handleChange = (event) => {
        setInEdit({ ...inEdit, [event.target.name]: event.target.checked });
    };

    function clear() {
        setSelectedBranch('');
        setSelectedUserEmail('');
        setSelectedUserFullName('');
        setSelectedUserType(0);
        setSelectedProfileUser('')
    }

    function validateEmail(email) {
        let emailForCheck = email?.toString().toLowerCase();
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(emailForCheck);
    }

    async function handleSubmit() {

        setLoading(true)
        let errors = false
        requiredFields.map((field)=> {
            if(!field?.value?.trim()?.length){
                errorFields[field.field] = true
                errors = true
            }else {
                errorFields[field.field] = false
            }
            setErrorFields(errorFields)
        })

        if(errors){
            await new Promise ( resolve => setTimeout(resolve, 1000))
            alert('Campos obrigatórios não preenchidos')
            setLoading(false)
            return
        }

        if (selectedUserEmail !== '') {
            if (!validateEmail(selectedUserEmail)) return alert('E-mail inválido')
        }
        setLoading(true)
        try {
            const userChangeResult = await api.put(`/api/v1/users/${userExternal_id}`,
                {
                    "full_name": selectedUserFullName === '' ? userDataFullName : selectedUserFullName,
                    "branches_id": selectedBranch.id,
                    "email": selectedUserEmail === '' ? userDataEmail === '' ? undefined : userDataEmail : selectedUserEmail,
                    "type": selectedUserType.type_access === '' ? userDataTypeAccess : selectedUserType.type_access,
                    "profile_id": selectedProfileUser.profile_id > 0 ? selectedProfileUser.profile_id : userDataTypeAccess === 3 ? userDataProfileId : undefined
                },
                {
                    headers: {
                        authorization: `Bearer ${tokenKey}`
                    }
                })
            if (parseInt(userExternal_id) === parseInt(user.external_id)) {

                if (userChangeResult.data.new_access_token !== '' || userChangeResult.data.new_access_token !== undefined) {
                    setTokenKey(userChangeResult.data.new_access_token);
                    localStorage.setItem('@auth:token', userChangeResult.data.new_access_token);
                }
            }

            setType({ name: 'alertMsg', type: 'information', value: { message: 'Sucesso ao alterar usuário' } })
            setEdited(!edited)
            setLoading(false);

        } catch (error) {

            if (error?.response?.data?.has_linked_customers) {
                let message = ''
                let linkTypeValue = ''
                if (selectedProfileUser.profile_id > 0 && userDataTypeAccess === 3) {
                    message = 'Há clientes já vinculados a este broker com este perfil, para alterar o perfil, é necessário vincular novo broker, deseja abrir a caixa de transferência?'
                    linkTypeValue = 'linkNewBroker'
                } else if (selectedUserType.type_access !== '3' && userDataTypeAccess === 3) {
                    message = 'Há clientes já vinculados a este broker, para alterar o tipo de acesso do mesmo, é necessário transferir todos os clientes deste broker para outro, deseja abrir a caixa de transferência?'
                    linkTypeValue = 'linkNewBroker'
                } else if (selectedUserType.type_access !== '4') {
                    message = 'Há clientes já vinculados a este assessor, para alterar o tipo de acesso do mesmo, é necessário transferir todos os clientes deste assesor para outro, deseja abrir a caixa de transferência?'
                    linkTypeValue = 'linkNewAdvisor'
                }

                setType({ name: 'alertMsg', value: { message: message, name: 'linkNewUser', value: { full_name: userDataFullName, profile_id: userDataProfileId, type_access: userDataTypeAccess, external_id: userExternal_id, linkType: linkTypeValue } } })
                setShow(true)
                setLoading(false)
            } else {
                setType({ name: 'alertMsg', type: 'information', value: { message: error?.response?.data.message === undefined ? 'Erro ao relaizar requisição' : error?.response?.data.message } })
                setShow(true)
            }
        }
        clear();
    }

    return (
        <div className='userEditBox' style={{
            width: screenX - 200,
            height: screenY
        }}>
            {loading ? (<div className='userBoxArrow'>
                <ArrowLoader />
            </div>) :
                (<>
                    <div className='headerBoxUser'>
                        <div>
                            <h1>Usuário: </h1>
                            <h1>{titleize(userDataFullName)}</h1>
                            {(parseInt(user.external_id) === parseInt(userExternal_id)) ||
                                (parseInt(user.type) === 1 && userExternal_id !== parseInt(user.external_id) && userDataTypeAccess > 1) ?
                                (<Typography component="div">
                                    <Grid component="label" container alignItems="center" spacing={1}>
                                        <Grid item>
                                            <AntSwitch className={classes.switch} checked={inEdit.checkedA} onChange={handleChange} name="checkedA" />
                                        </Grid>
                                        <Grid item>Editar</Grid>
                                    </Grid>
                                </Typography>) : (<></>)
                            }
                        </div>
                        <div>
                            <a>Total de clientes: </a><a> {userDataTotalClients}</a>
                        </div>

                    </div>
                    <div className='formBoxUser'>
                        <div id='relative' className={inEdit.checkedA ? 'userBoxHideFields' : 'usersInformationDiv'}>
                            <a>Nome: </a><a>{titleize(userDataFullName)}</a>
                        </div>

                        <div id='relative' className={inEdit.checkedA ? 'userBoxShowFields' : 'userBoxHideFields'}>
                            <input style={{border: errorFields['selectedUserFullName'] && !selectedUserFullName.trim().length ? '1px solid var(--errorFields)' : ''}} onKeyPress={(e) => {if(e.code === 'Backquote') e.preventDefault()}}  maxLength='50' id='dinamic' value={selectedUserFullName !== '' ? titleize(selectedUserFullName) : ''} onChange={(e) => setSelectedUserFullName(e.target.value)}></input>
                            <label id='labelDinamic'>Nome</label>
                        </div>

                        <div id='relative' className={inEdit.checkedA ? 'userBoxHideFields' : 'usersInformationDiv'}>
                            <a>E-mail: </a><a>{userDataEmail?.toLowerCase()}</a>
                        </div>

                        <div id='relative' className={inEdit.checkedA ? 'userBoxShowFields' : 'userBoxHideFields'}>
                            <input style={{border: errorFields['selectedUserEmail'] && !selectedUserEmail.trim().length ? '1px solid var(--errorFields)' : ''}} onKeyPress={(e) => {if(e.code === 'Backquote') e.preventDefault()}} maxLength='50' value={selectedUserEmail} id='dinamic' placeholder={userDataEmail} onChange={(e) => setSelectedUserEmail(e.target.value)}></input>
                            <label id='labelDinamic' htmlFor="email">E-mail</label>
                        </div>

                        <div className={inEdit.checkedA ? 'userBoxHideFields' : 'usersInformationDiv'}>
                            <a>Filial: </a><a>{userDataBranche?.name ? titleize(userDataBranche?.name) + ' - ' + titleize(userDataBranche?.city) + ' - ' + userDataBranche?.city_state.toUpperCase() : 'Vazio'}</a>
                        </div>

                        <div className={inEdit.checkedA ? 'userBoxShowFields' : 'userBoxHideFields'}>
                            <Autocomplete
                                options={branchesList}
                                value={selectedBranch}
                                getOptionLabel={(x) => x.name != null ? titleize(x.name) : '' + ' - ' + x?.city ? titleize(x.city) : '' + ' - ' + x?.city_state ? x.city_state.toUpperCase() : ''}
                                getOptionSelected={(option, val) => option.value == val.value}
                                onChange={(event, newValue) => {
                                    setSelectedBranch(newValue);
                                }}
                                id='company'
                                renderInput={(params) =>
                                    <TextField
                                        onKeyPress={(e) => {if(e.code === 'Backquote') e.preventDefault()}}
                                        label='Selecione a Filial'
                                        className={classes.autocompleteEditUser}
                                        {...params} variant="outlined" />}
                            />
                        </div>

                        <div className={inEdit.checkedA ? 'userBoxHideFields' : 'usersInformationDiv'}>
                            <a>Tipo de acesso: </a><a>{titleize(userDataTypeDescription)}</a>
                        </div>

                        <div className={inEdit.checkedA ? 'userBoxShowFields' : 'userBoxHideFields'}>
                            <Autocomplete
                                options={listTypes}
                                value={selectedUserType}
                                getOptionLabel={(x) => x.type_description}
                                getOptionSelected={(option, val) => option.value == val.value}
                                onChange={(event, newValue) => {
                                    setSelectedUserType(newValue);
                                }}

                                id='company'
                                renderInput={(params) => <TextField
                                    label='Tipo de acesso'
                                    onKeyPress={(e) => {if(e.code === 'Backquote') e.preventDefault()}}
                                    className={classes.autocompleteEditUser}

                                    {...params} variant="outlined" />}
                            />
                        </div>
                        {
                            userDataTypeAccess === 3 ?
                                (
                                    <div className={inEdit.checkedA ? 'userBoxHideFields' : 'usersInformationDiv'}>
                                        <a>Perfil: </a><a>{titleize(userDataProfileDescription)}</a>
                                    </div>
                                ) :
                                (
                                    <></>
                                )
                        }
                        <div className={((inEdit.checkedA && userDataProfileId === 3) || (inEdit.checkedA && selectedUserType.type_access === 3)) ? 'userBoxShowFields' : 'userBoxHideFields'}>
                            <Autocomplete
                                options={profilesList}
                                value={selectedProfileUser}
                                getOptionLabel={(x) => titleize(x.profile_description)}
                                getOptionSelected={(option, val) => option.value == val.value}
                                onChange={(event, newValue) => {
                                    setSelectedProfileUser(newValue);
                                }}
                                id='profile'
                                renderInput={(params) => <TextField
                                    label='Perfil'
                                    className={classes.autocompleteEditUser}
                                    onKeyPress={(e) => {if(e.code === 'Backquote') e.preventDefault()}}
                                    {...params} variant="outlined" />}
                            />
                        </div>
                        <button onClick={() => handleSubmit()} id={inEdit.checkedA ? 'userBoxButtonShow' : 'userBoxButtonHide'}>Confirmar</button>
                        <button onClick={() => setShow(false)} id={inEdit.checkedA ? 'userBoxButtonHide' : 'userBoxButtonShow'}>Fechar</button>
                    </div>
                </>)}
        </div>
    )
}