/*Componente detalhe de clientes
/*Descrição: Exibe os detalhes e permite alteração do cliente
/*Desenvolvido por: Equipe Rafael Cezário
*/

//libraries
import React, { useState, useEffect } from 'react';
import AntSwitch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { FiEdit, FiTrash2, FiX } from 'react-icons/fi';
import { TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab';
//utils
import { titleize } from '../../../../utils/formatNumberAndText/formatTextUpperFirstLetter';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import { numberBRLFormat } from '../../../../utils/formatNumberAndText/formatNumbertoBRL';
import { ModalSizeFitToScreen } from '../../../../utils/responsiveFunctions/modalSizeFitToScreen'
import { toBRL } from "../../../../utils/formatNumberAndText/toBRLCurrency";
//components
import { ArrowLoader } from '../../../../components/loaders/arrowLoader';
import { useModal } from '../../../../contexts/useModal';
//services
import { api } from '../../../../services/api';
import { myAdvisorsClients } from '../../../../services/asyncFunctions';
//styles
import { styleClientsInput } from '../../../../components/styles/autocompletField';
import './styles/clientsVBA.exportCRM.css';
import './styles/clients.columnsToView.css';
//Contexts
import { useAuth } from '../../../../contexts/useAuth';

export function VbaExportCRM(props) {

    const { user, tokenKey } = useAuth();
    const { show, setShow, setType, type, edited, setEdited } = useModal();

    const [initialDateCRMExport, setInitialDateCRMExport] = useState();
    const [finalDateCRMExport, setFinalDateCRMExport] = useState();
    
    function clear() {
        
    }

    async function handleChange() {
        props.data.alterViewColumns(props.data.columns)
    }

    function checkDateExportCRM(){
      if(initialDateCRMExport != undefined){
        if(finalDateCRMExport != undefined){
          var initialDateExportFormatted = initialDateCRMExport.split("-")[1] + "-" + initialDateCRMExport.split("-")[2] + "-" + initialDateCRMExport.split("-")[0]
          var finalDateExportFormatted = finalDateCRMExport.split("-")[1] + "-" + finalDateCRMExport.split("-")[2] + "-" + finalDateCRMExport.split("-")[0]
          props?.func.func(initialDateExportFormatted,finalDateExportFormatted)
        }
        else{
          alert("Preencha a data final da exportação!")
        }
      }
      else{
        if(finalDateCRMExport != undefined){
          alert("Preencha a data inicial da exportação!")
        }
        else{
          alert("Preencha as datas da exportação")
        }
      }
    }

    return (
        <>
          {/* <button onClick={() => {props?.func.func()}}>ESCREVER</button> */}
          <div className='containerExportCRM'>
            <div className='clientsCadastrationHeader'>
              <p>CRM</p>
              <button onClick={() => setShow(false)}>x</button>
            </div>
            <button className='buttonExportCRM' type='submit' onClick={() => {props?.func.func(undefined,undefined)}}>Exportar CRM da data atual</button>
            <div className='exportCRMdivline'></div>
            <div className='containerExportDate'>
              <div className='exportDateTitle'>Data inicial de exportação: </div>
              <input type="date" className='inputDateExportCRM' onChange={(e) => {setInitialDateCRMExport(e.target.value)}}/>
            </div>
            <div className='containerExportDate'>
              <div className='exportDateTitle'>Data final de exportação: </div>
              <input type="date" className='inputDateExportCRM' onChange={(e) => {setFinalDateCRMExport(e.target.value)}}/>
            </div>
            <button className='buttonExportCRM' type='submit' onClick={() => {console.log(initialDateCRMExport,finalDateCRMExport);checkDateExportCRM()}}>Exportar CRM do período selecionado</button>
          </div>
        </>
    )
}