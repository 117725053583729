/*Componente detalhe de clientes
/*Descrição: Exibe os detalhes e permite alteração do cliente
/*Desenvolvido por: Equipe Rafael Cezário
*/

//libraries
import React, { useState, useEffect } from 'react';
import AntSwitch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { FiEdit, FiTrash2, FiX } from 'react-icons/fi';
import { TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab';
//utils
import { titleize } from '../../../../utils/formatNumberAndText/formatTextUpperFirstLetter';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import { numberBRLFormat } from '../../../../utils/formatNumberAndText/formatNumbertoBRL';
import { ModalSizeFitToScreen } from '../../../../utils/responsiveFunctions/modalSizeFitToScreen'
import { toBRL } from "../../../../utils/formatNumberAndText/toBRLCurrency";
//components
import { ArrowLoader } from '../../../../components/loaders/arrowLoader';
import { useModal } from '../../../../contexts/useModal';
//services
import { api } from '../../../../services/api';
import { myAdvisorsClients } from '../../../../services/asyncFunctions';
//styles
import { styleClientsInput } from '../../../../components/styles/autocompletField';
import './styles/clientsVBA.exportCRM.css';
import './styles/clients.columnsToView.css';
//Contexts
import { useAuth } from '../../../../contexts/useAuth';

export function ReguaPadrao() {

    const { user, tokenKey } = useAuth();
    const { show, setShow, setType, type, edited, setEdited } = useModal();
    const [reguaPadrao, setReguaPadrao] = useState({});
    const [reguasPadrao, setReguasPadrao] = useState([]);

    const [loading, setLoading] = useState(true);
    
    useEffect(() => {
      async function getReguaPadrao() {
          setLoading(true)
          try {
              const result = await api.get('/api/v1/vba/reguaPadrao', {
                  headers: {
                      authorization: `Bearer ${tokenKey}`
                  }
              })
              setReguasPadrao(result?.data.data[0] ? result?.data.data : [{relacionamentos: 0}])
              setReguaPadrao(result?.data.data[0] ? result?.data.data[0] : {relacionamentos: 0})
          } catch (error) {
              alert(error?.response?.data?.message)
          }
          setLoading(false)
      }
      getReguaPadrao()
  },[])

  async function updateReguaPadrao() {
    setLoading(true)
    try {
        await api.put('/api/v1/vba/reguaPadrao', {relacionamentos: reguaPadrao.relacionamentos, id : reguaPadrao.id }, {
            headers: {
                authorization: `Bearer ${tokenKey}`
            }
        })
        alert('Regua Padrão atualizada com sucesso!')
      } catch (error) {
          alert(error?.response?.data?.message)
      }
      setLoading(false)
    }

    return (
        <>
          <div className='containerExportCRM'>
            <div className='clientsCadastrationHeader'>
              <p>Regua Padrão</p>
              <button onClick={() => {setShow(false); setReguaPadrao(null)}}>x</button>
            </div>
            
            <div className='containerExportDate'>
              <div className='exportDateTitle'>Relacionamentos por ano: </div>

              <input type='number' value={reguaPadrao?.relacionamentos} onChange={(e) => setReguaPadrao({...reguaPadrao, relacionamentos: e.target.value})} 
              style={{
                width: '100px',
                height: '30px',
                fontSize: '16px',
                padding: '5px',
                margin: '10px 0'
              
              }}/>
            </div>
            <button className='buttonExportCRM' type='submit' onClick={() => {updateReguaPadrao()}}>Enviar</button>
          </div>
        </>
    )
}