import { useModal } from "../../../contexts/useModal";
import Modal from '../../../components/modal/modal';
import { CreateFormStructure } from "./structures/createFormStructure";
import { ConfirmationBox } from "../../../components/confirmationBox/confirmationBox";
import "./styles/global.css";

export function CreateForm(props){
  const {type} = useModal()

  return (
    <div className="containerCreateForm">
      <Modal>{type.name === "alertMsg" ? (<ConfirmationBox msgData={type} />) : <></>}</Modal>
      <CreateFormStructure props={props}/>
    </div>
  )
}