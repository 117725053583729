import React, { useEffect, useState } from 'react'
/*Styles*/
import './styles/clientsIndication.css';
/*Contexts*/
import { useModal } from '../../../../contexts/useModal'
import { useAuth } from '../../../../contexts/useAuth'
/*Utils Components*/
import Modal from '../../../../components/modal/modal';
import { ClientsIndicationBox } from './clientsIndicationBox';
import { ClientAcceptedBox } from './clientAcceptedBox';
import { ClientIndicationSuccess } from './clientIndicationSuccess';
import { ConfirmationBox } from '../../../../components/confirmationBox/confirmationBox';
import { ClientsIndicationToApprovalBox } from './clientsIndicationToApprovalBox';
import { ArrowLoader } from '../../../../components/loaders/arrowLoader';
import { ToIndicate } from './clientsIndication.table.indicate';
// import { ClientsIndicationPendings } from './clientsIndication.table.pendings';
// import { ClientsIndicationTableFinished } from './clientsIndication.table.fineshed';
/*Services*/
import { api } from '../../../../services/api';
/*Utils Functions*/
import { titleize } from '../../../../utils/formatNumberAndText/formatTextUpperFirstLetter';

export function ClientsIndication() {
    const { tokenKey, user } = useAuth();
    const { show, setShow, type } = useModal();

    function profilesIndicated(clientData) {

        return (
            <div className='indicatedProfileList'>
                <table>
                    <thead>
                        <tr>
                            <th colSpan='4' id='theadProfileList'>
                                <label>
                                    <a>Descrição</a>
                                    <button id='closeProfileList' onClick={() => setShow(false)}>X</button>
                                </label>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {clientData.map((profileIndicated) => {
                            return (
                                <tr>
                                    <td>{titleize(profileIndicated?.profile_description)}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        )
    }

    return (
        <div className='clientsIndicationMainContainer' style={{minWidth: !show ? '1000px' : '0'}}>
            <Modal>
                {type.name === 'clientIndicationBox' ?
                    (
                        <div className='clientsIndicationBoxModal'>
                            <ClientsIndicationBox id={type.client_external_id} name={type.client_name} />
                        </div>
                    ) : type.name === 'clientIndicationSuccess' ?
                        (<ClientIndicationSuccess id={type.value.idCliente} name={type.value.nomeCliente} />) :
                        type.name === 'alertMsg' ?
                            (
                                <ConfirmationBox msgData={type} />
                            ) : type.name === 'showProfilesIndicated' ?
                                (
                                    profilesIndicated(type.clientData)
                                ) :

                                type.name === 'showIndicationDetails' ?
                                    (<ClientsIndicationToApprovalBox data={type} />) :
                                    (type.name === 'clientAcceptedBox' ?
                                        <ClientAcceptedBox />:<></>
                                    )}
            </Modal>
            <div className='clientsIndicationTableIndicated'>
                <ToIndicate />
            </div>
        </div>
    )
}