import React, { useState, Component, useEffect } from "react"
import { api } from '../../../../services/api';
import { useAuth } from '../../../../contexts/useAuth'
import { useModal } from '../../../../contexts/useModal';
import { ArrowLoader } from '../../../../components/loaders/arrowLoader';
import { Link, useLocation, useHistory } from "react-router-dom";

import { AiFillCloseCircle } from 'react-icons/ai';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { GrFormClose } from 'react-icons/gr';
import { MdExpandLess, MdExpandMore, MdPublishedWithChanges } from 'react-icons/md';
import { BsFillPersonFill,BsCheckCircleFill,BsSlashCircleFill } from 'react-icons/bs';


export function MyFormsStructure(){
  const [filtroTipoPortfolio, setFiltroTipoPortfolio] = useState("0")
  const [loading, setLoading] = useState(false)
  const {setType, setShow} = useModal()
  const { tokenKey, userType, user } = useAuth()

  const [jsonMyForms, setJsonMyForms] = useState()
  const [jsonFormsReplyDetails, setJsonFormsReplyDetails] = useState()

  const [formTypeFilter, setFormTypeFilter] = useState("0")
  const [formProgressFilter, setFormProgressFilter] = useState("0")
  const [formNameFilter, setFormNameFilter] = useState("")

  const [atualizacao, setAtualizacao] = useState(0)
  
  useEffect(() => {
    getForms()
    sessionStorage.removeItem('idPortfolio')
    sessionStorage.removeItem('idRespostas')
  }, [tokenKey])

  async function getForms() {
    setLoading(true)
    try {
      const formsTemp = await api.get(`/api/v1/forms/myForms`, {
          headers: {
              authorization: `Bearer ${tokenKey}`
          }
      })
      setJsonMyForms(formsTemp?.data?.data)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      setType({ name: 'alertMsg', type: 'information', value: { message: 'Erro ao buscar os portfólios' } })
      setShow(true)
    }
  }

  function jsonFiltrado(){
    let jsonFiltrado = jsonMyForms
    if(formTypeFilter != "0"){
      jsonFiltrado = jsonFiltrado.filter((portfolio) => portfolio.form_profile == formTypeFilter)
    }
    if(formProgressFilter != "0"){
      jsonFiltrado = jsonFiltrado.filter((portfolio) => String(portfolio.is_open) == formProgressFilter)
    }
    if(formNameFilter != null && formNameFilter != ""){
      jsonFiltrado = jsonFiltrado.filter((portfolio) => portfolio.form_title.toUpperCase().includes(formNameFilter.toUpperCase()) || portfolio.description.toUpperCase().includes(formNameFilter.toUpperCase()) || portfolio.owner_name.toUpperCase().includes(formNameFilter.toUpperCase()) || portfolio.owner.toUpperCase().includes(formNameFilter.toUpperCase()))
    }
    return jsonFiltrado
  }

  return (
    <div>
      <div style={{backgroundColor: '#dbe0e7', paddingBottom: '2rem'}}>
        <div className="containerFiltroSelectPortfolio">
          <select className="selectTipoPortfolio" onChange={(e) => {setFormTypeFilter(e.target.value)}} value={formTypeFilter}>
            <option value="0" selected>Filtrar categoria</option>
            <option value="Moderado">Moderado</option>
            <option value="Conservador">Conservador</option>
            <option value="Agressivo">Agressivo</option>
            <option value="Geral">Geral</option>
          </select>
          <select className="selectTipoPortfolio" onChange={(e) => {setFormProgressFilter(e.target.value)}} value={formProgressFilter}>
            <option value="0" selected>Filtrar progresso</option>
            <option value="true">Em andamento</option>
            <option value="false">Finalizado</option>
          </select>
          <input placeholder="Filtro geral" onChange={(e) => setFormNameFilter(e.target.value)} value={formNameFilter} type="text" className="inputNameFilter"/>
          <button className="resetFilter" onClick={() => {setFormTypeFilter("0");setFormNameFilter("")}}>Limpar</button>
        </div>
      </div>
      {loading ? 
        <div className="loaderAllForms">
          <ArrowLoader style={{marginBottom: "5rem"}} />
        </div>
        :
        <div className="containerMeusPortfoliosExistentes">
          {jsonFiltrado()?.map((form) => {
            if(form.form_profile == "Conservador"){
              return (
                <Link className="MeuItemPortfolioConservador" to={{pathname: "/formReply", state: {id: form.form_id, pending_id: form.id}}}>
                  <div className="dataPortfolioGeral">{form.updated_at.replace(/^(\d{4})-(\d{2})-(\d{2})T(\d{2}:\d{2}):(\d{2})\.\d+Z$/, "$3/$2/$1 - $4h")}</div>
                  <div className="tituloPortfolio">{form.form_title}</div>
                  <div className="separacaoItemPortfolioConservador"></div>
                  <div className="detalheMeuItemPortfolio"><BsFillPersonFill size={12}/> {form.description}</div>
                  <div className="separacaoItemPortfolioConservador"></div>
                  <div className="detalheMeuItemPortfolio">{form.is_open ? <><BsSlashCircleFill size={12}/> Em andamento</> : <><BsCheckCircleFill size={12}/> Finalizado</>}</div>
                  <div className="separacaoItemPortfolioConservador"></div>
                  <div className="tipoPerfilPortfolioConservador">Perfil conservador</div>
                  <div className="nomeAssessorPortfolioConservador" title={form.owner_name}>{"A" + form.owner} - {form.owner_name.split(" ").length > 1 ? form.owner_name.split(" ")[0] + " " + form.owner_name.split(" ")[form.owner_name.split(" ").length-1] : form.owner_name}</div>
                </Link>
              )
            }
            if(form.form_profile == "Moderado"){
              return (
                <Link className="MeuItemPortfolioModerado" to={{pathname: "/formReply", state: {id: form.form_id, pending_id: form.id}}}>
                  <div className="dataPortfolioGeral">{form.updated_at.replace(/^(\d{4})-(\d{2})-(\d{2})T(\d{2}:\d{2}):(\d{2})\.\d+Z$/, "$3/$2/$1 - $4h")}</div>
                  <div className="tituloPortfolio">{form.form_title}</div>
                  <div className="separacaoItemPortfolioModerado"></div>
                  <div className="detalheMeuItemPortfolio"><BsFillPersonFill size={12}/> {form.description}</div>
                  <div className="separacaoItemPortfolioModerado"></div>
                  <div className="detalheMeuItemPortfolio">{form.is_open ? <><BsSlashCircleFill size={12}/> Em andamento</> : <><BsCheckCircleFill size={12}/> Finalizado</>}</div>
                  <div className="separacaoItemPortfolioModerado"></div>
                  <div className="tipoPerfilPortfolioModerado">Perfil moderado</div>
                  <div className="nomeAssessorPortfolioModerado" title={form.owner_name}>{"A" + form.owner} - {form.owner_name.split(" ").length > 1 ? form.owner_name.split(" ")[0] + " " + form.owner_name.split(" ")[form.owner_name.split(" ").length-1] : form.owner_name}</div>
                </Link>
              )
            }
            if(form.form_profile == "Agressivo"){
              return (
                <Link className="MeuItemPortfolioAgressivo" to={{pathname: "/formReply", state: {id: form.form_id, pending_id: form.id}}}>
                  <div className="dataPortfolioGeral">{form.updated_at.replace(/^(\d{4})-(\d{2})-(\d{2})T(\d{2}:\d{2}):(\d{2})\.\d+Z$/, "$3/$2/$1 - $4h")}</div>
                  <div className="tituloPortfolio">{form.form_title}</div>
                  <div className="separacaoItemPortfolioAgressivo"></div>
                  <div className="detalheMeuItemPortfolio"><BsFillPersonFill size={12}/> {form.description}</div>
                  <div className="separacaoItemPortfolioAgressivo"></div>
                  <div className="detalheMeuItemPortfolio">{form.is_open ? <><BsSlashCircleFill size={12}/> Em andamento</> : <><BsCheckCircleFill size={12}/> Finalizado</>}</div>
                  <div className="separacaoItemPortfolioAgressivo"></div>
                  <div className="tipoPerfilPortfolioAgressivo">Perfil agressivo</div>
                  <div className="nomeAssessorPortfolioAgressivo" title={form.owner_name}>{"A" + form.owner} - {form.owner_name.split(" ").length > 1 ? form.owner_name.split(" ")[0] + " " + form.owner_name.split(" ")[form.owner_name.split(" ").length-1] : form.owner_name}</div>
                </Link>
              )
            }
            if(form.form_profile == "Geral"){
              return (
                <Link className="MeuItemPortfolioGeral" to={{pathname: "/formReply", state: {id: form.form_id, pending_id: form.id}}}>
                  <div className="dataPortfolioGeral">{form.updated_at.replace(/^(\d{4})-(\d{2})-(\d{2})T(\d{2}:\d{2}):(\d{2})\.\d+Z$/, "$3/$2/$1 - $4h")}</div>
                  <div className="tituloPortfolio">{form.form_title}</div>
                  <div className="separacaoItemPortfolioGeral"></div>
                  <div className="detalheMeuItemPortfolio"><BsFillPersonFill size={12}/> {form.description}</div>
                  <div className="separacaoItemPortfolioGeral"></div>
                  <div className="detalheMeuItemPortfolio">{form.is_open ? <><BsSlashCircleFill size={12}/> Em andamento</> : <><BsCheckCircleFill size={12}/> Finalizado</>}</div>
                  <div className="separacaoItemPortfolioGeral"></div>
                  <div className="tipoPerfilPortfolioGeral">Perfil geral</div>
                  <div className="nomeAssessorPortfolioGeral" title={form.owner_name}>{"A" + form.owner} - {form.owner_name.split(" ").length > 1 ? form.owner_name.split(" ")[0] + " " + form.owner_name.split(" ")[form.owner_name.split(" ").length-1] : form.owner_name}</div>
                </Link>
              )
            }
          })}
        </div>
      }
    </div> 
  )
}