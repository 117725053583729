//libraries
import React from "react"
/*Components*/
import Modal from "../../../components/modal/modal"
import { ConfirmationBox } from "../../../components/confirmationBox/confirmationBox"
/*Contexts*/
import { useModal } from "../../../contexts/useModal"
import { useAuth } from "../../../contexts/useAuth"

import { CubastarMain } from "./cubastarMain"
import { CubastarTacticalSpeech } from "./cubastar_tactical_speech"
import { ScenariosControl } from "./scenariosControl"
import { TacticalAlocationsControl } from "./tacticalAlocationsControl"

export function Cubastar() {
    const { type } = useModal()
    const { user } = useAuth()

    return (
        <>
            <Modal>
                {
                type.name === "alertMsg" ? 
                    <ConfirmationBox msgData={type} /> : 
                type.name === "tacticalSpeech" ?
                    <CubastarTacticalSpeech msgData={type} /> : 
                type.name === "scenariosControl" ?
                    <ScenariosControl msgData={type} /> :
                type.name === "tacticalAlocationsControl" ?
                    <TacticalAlocationsControl msgData={type} /> :
                <></>}
            </Modal>
            <div style={{ paddingTop: "2rem", paddingLeft: "2rem", paddingRight: "2rem" }}>
                <CubastarMain prop={type} />  
            </div>
        </>
    )
}
