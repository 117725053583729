/*Descrição: Tela componente de notificações onde serão exibidas as notificações do sistema
/*Desenvolvido por: Equipe Rafael Cezário
*/

import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom'
import { useHistory } from 'react-router';
/*Styles*/
import './notificationCenter.css';
import { FiCircle } from 'react-icons/fi';
/*Utils*/
import { useSocket } from '../../../contexts/useSocket';
import { titleize } from '../../../utils/formatNumberAndText/formatTextUpperFirstLetter';
/*Contexts*/
import { useMenu } from '../../../contexts/useMenu';
import { useModal } from '../../../contexts/useModal'

export function NotificationCenter() {

    const { pendings, socketEmit } = useSocket()
    const [listPendings, setListPendings] = useState([])
    const { showNotificationCenter } = useMenu()
    const history = useHistory()
    const location = useLocation()
    const { type, setType } = useModal()

    useEffect(() => {
        setListPendings(pendings?.details)
    }, [pendings])

    return (
        showNotificationCenter ?
            (<div className='notificationCenterMainContainer' >
                <span id='notificationHeader'>
                    <a>Suas Notificações</a >
                </span >
                <div className='notificationAlerts'>
                    {
                        listPendings?.length > 0 ?
                            (
                                <>
                                    {
                                        listPendings?.map((detailsNotification, index) => {
                                            const date_notification = new Date(Date.parse(detailsNotification.created_at)).toLocaleString('pt-BR', { timeZone: 'UTC' });
                                            return (
                                                <div id='notificationDiv' onClick={() => {
                                                    if (location.pathname === '/indicarClientes') {
                                                        setType({ name: 'reloadIndicationTable' })
                                                    }
                                                    socketEmit("readNotifications", detailsNotification?.frontend_path)
                                                    history.push(detailsNotification?.frontend_path)

                                                }}>
                                                    {detailsNotification.path_last_viewer < detailsNotification.created_at || detailsNotification.path_last_viewer == null ?
                                                        (<FiCircle fill='#c39e16' color='#c39e16' size='15' />) :
                                                        (<FiCircle fill='gray' color='gray' size='15' />)}
                                                    <div id='textNotification'>
                                                        <label id='textElipisisLabel' className='notificationTitleText'>
                                                            <a>{detailsNotification?.title}</a>
                                                        </label>
                                                        <a>
                                                            {
                                                                detailsNotification?.ref_table == 'clients_indications' ?
                                                                    `Cliente: detailsNotification.subtitle` : detailsNotification.subtitle
                                                            }
                                                        </a>
                                                        <a>Enviado por {titleize(detailsNotification?.created_by_users_name)}</a>
                                                        <a>{" às " + date_notification}</a>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                    {listPendings.length >= 25 ?
                                        (
                                            <div onClick={() => socketEmit('moreNotifications')}>
                                                <label id='textElipisisLabel'>
                                                    <a>Mais notificações</a>
                                                </label>
                                            </div>
                                        ) : <></>
                                    }
                                </>

                            ) :
                            (
                                <a>Sem notificações</a>
                            )
                    }
                </div>
            </div >) :
            (<></>)

    )
}