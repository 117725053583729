/*Tela Dashboard Assessor*/
/*Desenvolvido por: Equipe Tech RV*/

import React, { useEffect, useState, useRef, useReducer, useMemo } from "react"
import { Link } from "react-router-dom"
import MUIDataTable from "mui-datatables"
import { debounceSearchRender } from "mui-datatables"
import { ThemeProvider, createTheme } from "@mui/material/styles"
import { Fade, TableCell } from "@mui/material"
import { Tooltip, IconButton } from "@material-ui/core"
import { datetimeToDatePtBR } from "../../../utils/formatDate/dateTimeToPTBR"
import debounce from 'lodash/debounce';
/*Styles*/
import "./dashboard.css"
import "./styles/stylesPortal360.css"
import ContentCopyIcon from "@mui/icons-material/ContentCopy"

import { ArrowLoader } from "../../../components/loaders/arrowLoader"
import { FormControl, ListItemText, Checkbox, Select, InputLabel, MenuItem } from "@mui/material"
import { BsQuestionCircle } from "react-icons/bs"
import { AiFillCloseCircle, AiOutlineLeftSquare, AiOutlineRightSquare } from "react-icons/ai"
import { AiOutlineArrowUp, AiOutlineArrowDown } from "react-icons/ai"
import { FaCheck, FaTimes, FaStar, FaQuestion, FaFilter, FaSearch, FaCaretRight } from "react-icons/fa"
import { FiAlertTriangle } from "react-icons/fi"
import { BsFillSquareFill } from "react-icons/bs"
/*Utils*/
import { titleize } from "../../../utils/formatNumberAndText/formatTextUpperFirstLetter"
import CustomFooter from "./CustomFooterTableDashAdvisor"
import CustomFooterConfig from "./CustomFooterTableDashAdvisorConfig"
import { saveAs } from "file-saver"
import { utils, write } from "xlsx"
import { TextField } from "@mui/material"

import { useAuth } from "../../../contexts/useAuth"
import { useModal } from "../../../contexts/useModal"
/*Services*/
import { api } from "../../../services/api"
import { getDashAlocacaoTableCampains, getDashAlocacaoTableConfiguration, getDashAlocacaoTableOptions, getDashAlocacaoEvents, getDatabaseReferenceTime } from "../../../services/asyncFunctions"
/*components*/
import { toBRL, toBRLInteger } from "../../../utils/formatNumberAndText/toBRLCurrency"

import { BsFire } from "react-icons/bs"
import { BiCalendar, BiCalendarStar } from "react-icons/bi"
import { TbCalendarStats } from "react-icons/tb"
import { MdCrisisAlert, MdPermContactCalendar } from "react-icons/md"

import { RiEqualizerFill } from "react-icons/ri"
//importar carrousel
import CampainsCarrousel from "./components/campainsCarrousel"
import { toPercent } from "../../../utils/formatNumberAndText/formatNumberPercent"
import TableViewColDash from "../../../utils/muiDatatables/TableviewColDash"
import { useFilter } from "../../../utils/useFilter"


import feriados from "../../../utils/feriados/feriados"
import Button from "@mui/material/Button";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

export function DashboardAdvisor(props) {
    const { user, tokenKey, userType } = useAuth()
    const token = localStorage.getItem("@auth:token")
    const { show, setShow, setType, edited } = useModal()
    const [filterAreaOpen, setFilterAreaOpen] = useState(false)
    const [filterSearchOpen ,setFilterSearchOpen] = useState(false)
    const [debouncedInputValue, setDebouncedInputValue] = React.useState("")
    const [loading, setLoading] = useState(false)
    const [loadingTable, setLoadingTable] = useState(false)
    const variavel = useRef({ grupo1: false })
    const [, forceUpdate] = useReducer((x) => x + 1, 0)
    const columnsToDisplay = useRef() // Inicializa como um array vazio
    const [okay, setOkay] = useState(false)
    const changedDay = useRef(false) // em caso de mudança de dia, os produtos ficarão todos visiveis novamente
    const [columnVisibility, setColumnVisibility] = useState({})
    const [areasFiltered, setAreasFiltered] = useState([])
    const [cloneListProducts, setCloneListProducts] = useState([])
    const [filterCampains, setFilterCampains] = useState([])
    const [areasAlocacaoCampanhas, setAreasAlocacaoCampanhas] = useState([])
    const columnsExceptionView = ['profile', 'campain_base', 'pl_custom', 'ple', 'knowledge', 'products', 'objective', 'propensity_to_accept_campains', 'max_term', 'necessary_liq']
    const columnsOrder = [1, 2]
    const filterAreaRef = useRef(null);
    const getMuiTheme = () => createTheme({
            components: {
                MuiTableCell: {
                    styleOverrides: {
                        root: {
                            textAlign: "center",
                            justifyContent: "center",
                            whiteSpace: "nowrap",
                            backgrounColor: "white",
                        },
                    },
                },
                MuiToolbar: {
                    styleOverrides: {
                        root: {
                            minHeight: "30px !important",
                        },
                    },
                },
                MuiCheckbox: {
                    styleOverrides: {
                        root: {
                            marginLeft: "10px", // Aumenta o padding dos checkboxes para 10px
                        },
                    },
                },

                MUIDataTableHeadCell: {
                    styleOverrides: {
                        contentWrapper: {
                            justifyContent: "center",
                            whiteSpace: "pre-wrap",
                        },
                    },
                },

                MUIDataTableFilter: {
                    styleOverrides: {
                        root: {
                            minWidth: "40vw",
                            maxWidth: "50vw",
                        },
                    },
                },
                MuiTableRow: {
                    styleOverrides: {
                        root: {
                            "&:hover ": {
                                backgroundColor: "#e7e7e7 !important",
                            },
                            "&: hover td": {
                                backgroundColor: "#e7e7e7 !important",
                            },
                        },
                    },
                },
            },
            root: {
                "&::-webkit-scrollbar": {
                    width: 7,
                },
                "&::-webkit-scrollbar-track": {
                    boxShadow: `inset 0 0 6px #bea032`,
                },
                "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "#bea032",
                    outline: `1px solid slategrey`,
                },
            },
    })

    function changeVisibleColumns(grupo) {
        variavel.current[grupo] = !variavel.current[grupo]
        assembleTable()
        forceUpdate()
    }

    const updateColumnVisibility = (columnName, isVisible) => {
        setColumnVisibility((prev) => ({ ...prev, [columnName]: isVisible }))
    }
    const CheckboxComponent = Checkbox;
    const clientsResult = useRef()
    const listClients = useRef()
    const listProducts = useRef()
    const listProductsPermissionsAll = useRef()
    const listProductsPermissionsMacro = useRef()
    const listProductsPermissionsMicro = useRef()
    const [searchTerm, setSearchTerm] = useState('');
    const listCampaignsPermissions = useRef()
    const visibleColumnsRef = useRef([])
    const sum = useRef({})
    const sumNet = useRef(0)
    const sumRoa = useRef(0)
    const sumSaldo = useRef(0)
    const permissions = useRef([])
    const [lastPage, setLastPage] = useState(0)
    const [currentPage, setCurrentPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(150)
    const [search, setSearch] = useState("")
    const [searchArea, setSearchArea] = useState("")
    const [databaseUpdateTime, setDatabaseUpdateTime] = useState("")
    const [crmUpdateTime, setCrmUpdateTime] = useState("")
    const [positivadorUpdateTime, setPositivadorUpdateTime] = useState("")
    const [open, setOpen] = React.useState(false);

    const tabActive = useRef(user.type === 3 ? 0 : 1)
    // tabActive === 0 ? tabActive.current = 1 : tabActive.current = 0
    const filteredCampains = useRef([])
    const [selectedCampaign, setSelectedCampaign] = useState(null)
    const listFilterStatusProducts = useRef({})
    const listSearchedClients = useRef([])
    const listFilteredClients = useRef([])
    const filtersList = useRef({})
    const configuration = useRef({})
    const optionsConfiguration = useRef({})
    const [clientsSettlements, setClientsSettlements] = useState({})
    const [eventsDash, setEventsDash] = useState([])
    const [currentFilters, setCurrentFilters] = useState({})
    const [juntarColunas, setJuntarColunas] = useState(false)
    const tableChanged = useRef(false)
    const [messageTransfer, setMessageTransfer] = useState("")
    const lastSortOrder = useRef({
        name: "nome",
        direction: "asc",
    })
    const refDataTable = React.useRef(null)

    const positions = useRef({
        rowsPerPage: 50,
        currentPage: 0,
    })
    // const feeFixo = useRef({})
    const campaignPriority = useRef([])
    const linesCount = useRef(0)
    const [alturaTabela, setAlturaTabela] = useState(null)
    const [listaClientesPorProduto, setListaClientesPorProduto] = useState([])

    async function getDataOptions() {
        const optionsAux = await getDashAlocacaoTableOptions(tokenKey)
        optionsConfiguration.current = optionsAux
    }
    async function getDataEvents() {
        const eventsAux = await getDashAlocacaoEvents(tokenKey)
        setEventsDash(eventsAux)
    }

    async function getAreasAlocacaoCampanhas() {
        try {
            const response = await api.get(`api/v1/alocacao/areas`, {
                headers: {
                    authorization: `Bearer ${tokenKey}`,
                },
            })

            setAreasAlocacaoCampanhas(response.data.data)
            return response.data.data
        }
        catch (error) {
            return []
        }
    }

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setDebouncedInputValue(searchArea);
        }, 500);
        return () => clearTimeout(timeoutId);
      }, [searchArea, 500]);

    async function getDataPermissions() {
        const configurationAux = await getDashAlocacaoTableConfiguration(tokenKey)
        configuration.current = configurationAux

        //todas as keys de uma linha da tabela
        const keys = Object.keys(configurationAux.table[0])

        //apenas as colunas de campanhas
        const listCampaignsPermissionsAux = keys.filter((item) => item.startsWith("c") && item.endsWith("__permissions_id"))
        listCampaignsPermissions.current = listCampaignsPermissionsAux

        permissions.current = configuration.current.permissions

        // apenas as grandes areas dos produtos : ex: RV,cambio,crédito...
        const listProductsPermissionsAux2 = permissions.current.filter((item) => item.name.split("__").length < 3)

        //apenas as subareas dos produtos : ex: mesa_rv, wisir_fii, wisir_acoes...
        const listProductsPermissionsAux3 = permissions.current.filter((item) => item.name.split("__").length > 2)

        listProductsPermissionsMacro.current = listProductsPermissionsAux2
        listProductsPermissionsAll.current = permissions.current

        permissions.current.forEach((p, pi) => {
            variavel.current["grupo" + (pi + 2)] = false
        })

        listProductsPermissionsMicro.current = listProductsPermissionsAux3
    }

    // async function setColumnsLocal(  ) {
    //     let viewColumns;

    //     if (localStorage.getItem("columnsDash")) {
    //         // Carrega do localStorage
    //         viewColumns = JSON.parse(localStorage.getItem("columnsDash"));
    //         const updatedColumns = visibleColumnsRef.current.map(col => {
    //             const localStorageColumn = viewColumns.find(vc => vc.column === col.name);
    //             return {
    //                 ...col,
    //                 options: {
    //                     ...col.options,
    //                     display: localStorageColumn ? localStorageColumn.display : true
    //                 }
    //             };
    //         });
    //         setColumnsLocal()
    //         setOkay(true); // Garante que 'setOkay' esteja definido e utilizado corretamente
    //         return viewColumns
    //     } else {
    //         viewColumns = visibleColumnsRef.current.map(e => ({
    //             column: e.name,
    //             display: true // Aqui você pode definir um valor padrão para 'display'
    //         }));
    //         const updatedColumns = visibleColumnsRef.current.map(col => {
    //             const localStorageColumn = viewColumns.find(vc => vc.column === col.name);
    //             return {
    //                 ...col,
    //                 options: {
    //                     ...col.options,
    //                     display: localStorageColumn ? localStorageColumn.display : true
    //                 }
    //             };
    //         });
    //         localStorage.setItem("columnsDash", JSON.stringify(viewColumns));
    //         return setColumnsLocal()
    //     }

    //     // Atualizando as colunas para exibir com base nos dados carregados
    //     const updatedColumns = visibleColumnsRef.current.map(col => {
    //         const localStorageColumn = viewColumns.find(vc => vc.column === col.name);
    //         return {
    //             ...col,
    //             options: {
    //                 ...col.options,
    //                 display: localStorageColumn ? localStorageColumn.display : true
    //             }
    //         };
    //     });

    //     setColumnsToDisplay(updatedColumns);
    //     setOkay(true); // Garante que 'setOkay' esteja definido e utilizado corretamente
    // }

    const delayedSearch = (value) => {
        // Sua lógica de busca aqui
        setSearchArea(value);
      };
    
      
      const handleChange = (e) => {
        const { value } = e.target;
        setSearchArea(value);
        // Executa a busca após um atraso de 500 milissegundos
        setTimeout(() => {
          delayedSearch(value);
        }, 500); // Tempo de atraso em milissegundos (ajuste conforme necessário)
      };
    

    async function setColumnsLocal() {
        let viewColumns = []
        const today = new Date()
        const lastResetDayString = localStorage.getItem("lastResetDate")
        const lastResetDay = new Date(lastResetDayString)

        today.setHours(0, 0, 0, 0)
        lastResetDay.setHours(0, 0, 0, 0)

        if (localStorage.getItem("columnsDash")) {
            viewColumns = JSON.parse(localStorage.getItem("columnsDash"))
        } else {
            viewColumns = visibleColumnsRef.current.map(e => ({
                column: e.name,
                display: true,
            }));
            viewColumns = viewColumns.concat(listProducts.current.map(p => {
                const finishDate = new Date(p.finish)
                finishDate.setHours(0, 0, 0, 0)
                finishDate.setDate(finishDate.getDate() + 1)

                const display = finishDate >= today ? true : false
                
                return {
                    column: `c${p.id}__objective`,
                    display: display,
                }
            }));

            viewColumns = viewColumns.concat(listProducts.current.map(p => {
                const finishDate = new Date(p.finish)
                finishDate.setHours(0, 0, 0, 0)
                finishDate.setDate(finishDate.getDate() + 1)

                const display = finishDate >= today ? true : false
                
                return {
                    column: `c${p.id}__value`,
                    display: display,
                }
            }));

            viewColumns = viewColumns.concat(listProducts.current.map(p => {
                const finishDate = new Date(p.finish)
                finishDate.setHours(0, 0, 0, 0)
                finishDate.setDate(finishDate.getDate() + 1)

                const display = finishDate >= today ? true : false

                return {
                    column: `c${p.id}__receita_estimada`,
                    display: display,
                }
            }));
            
            viewColumns = viewColumns.concat(listProducts.current.map(p => {
                const finishDate = new Date(p.finish)
                finishDate.setHours(0, 0, 0, 0)
                finishDate.setDate(finishDate.getDate() + 1)

                const display = finishDate >= today ? true : false
                
                return {
                    column: `c${p.id}__status`,
                    display: display,
                }
            }));

            localStorage.setItem("columnsDash", JSON.stringify(viewColumns));
        }
        

        // Nomes das colunas da campanha selecionada
        listProducts?.current?.forEach((product) => {
            ;["objective", "value", "receita_estimada", "status"].forEach((suffix) => {
                const columnName = `c${product.id}__${suffix}`
                if (!viewColumns.some((vc) => vc.column === columnName)) {
                    viewColumns.push({ column: columnName, display: true })
                }
            })
        })

        // Compara as datas  colocando displat como true, mas apenas se a data de hoje for maior que a data do ultimo reset quero fazer um verificação também  no visibleColumnsRef.current, se o e.finish for maior que a data de hoje, então eu coloco como true, se não eu coloco como false se nao tiver e.finish eu coloco como true
        if (today > lastResetDay || !localStorage.getItem("lastResetDate")) {
            viewColumns = visibleColumnsRef.current.map(e => ({
                column: e.name,
                display: true,
            }));
        
            // Concatena os produtos ao array de colunas
            viewColumns = viewColumns.concat(listProducts.current.map(p => {
                const finishDate = new Date(p.finish)
                finishDate.setHours(0, 0, 0, 0)
                finishDate.setDate(finishDate.getDate() + 1)

                const display = finishDate >= today ? true : false

                return {
                    column: `c${p.id}__objective`,
                    display: display,
                }
            }));

            viewColumns = viewColumns.concat(listProducts.current.map(p => {
                const finishDate = new Date(p.finish)
                finishDate.setHours(0, 0, 0, 0)
                finishDate.setDate(finishDate.getDate() + 1)

                const display = finishDate >= today ? true : false
                
                return {
                    column: `c${p.id}__value`,
                    display: display,
                }
            }));

            viewColumns = viewColumns.concat(listProducts.current.map(p => {
                const finishDate = new Date(p.finish)
                finishDate.setHours(0, 0, 0, 0)
                finishDate.setDate(finishDate.getDate() + 1)

                const display = finishDate >= today ? true : false

                return {
                    column: `c${p.id}__receita_estimada`,
                    display: display,
                }
            }));

            viewColumns = viewColumns.concat(listProducts.current.map(p => {
                const finishDate = new Date(p.finish)
                finishDate.setHours(0, 0, 0, 0)
                finishDate.setDate(finishDate.getDate() + 1)

                const display = finishDate >= today ? true : false
                
                return {
                    column: `c${p.id}__status`,
                    display: display,
                }
            }));
        
            localStorage.setItem("columnsDash", JSON.stringify(viewColumns));
            localStorage.setItem("lastResetDate", today);
        } else {
            viewColumns = JSON.parse(localStorage.getItem("columnsDash"))
        }

        // // Atualiza columnsToDisplay e localStorage
        // columnsToDisplay.current = viewColumns;
        // localStorage.setItem('columnsDash', JSON.stringify(viewColumns));
        // const updatedColumns = visibleColumnsRef.current.map(col => {
        //     const localStorageColumn = columnsToDisplay.current?.find(vc => vc.column === col.name);
        //     return {
        //         ...col,
        //         options: {
        //             ...col.options,
        //             display: localStorageColumn ? localStorageColumn.display : true
        //         }
        //     };

        // });

        columnsToDisplay.current = viewColumns
        setOkay(true)
    }


    // useEffect(() => {
    //     let viewColumns = [];
    //     if (localStorage.getItem("columnsDash")) {
    //       // Carrega do localStorage
    //       viewColumns = JSON.parse(localStorage.getItem("columnsDash"));
    //     } else {
    //       // Define as colunas padrões
    //       viewColumns = visibleColumnsRef.current.map(e => ({
    //         column: e.name,
    //         display: true
    //       }));
    //     }

    //     // Nomes das colunas da campanha selecionada
    //     listProducts?.current?.forEach(product => {
    //         ["objective", "value", "receita_estimada", "status"].forEach(suffix => {
    //           const columnName = `c${product.id}__${suffix}`;
    //           if (!viewColumns.some(vc => vc.column === columnName)) {
    //             viewColumns.push({ column: columnName, display: true });
    //           }
    //         });
    //       });

    //     // Atualiza columnsToDisplay e localStorage
    //     columnsToDisplay.current = viewColumns;
    //     localStorage.setItem('columnsDash', JSON.stringify(viewColumns));
    //   }, []);

    async function getData() {
        setLoading(true)
        try {
            if (user.type === 4 || user.type < 3) {
                const essentials = await getDashAlocacaoTableCampains(tokenKey)

                listProducts.current = essentials.products
                linesCount.current = essentials.table.length

                clientsResult.current = essentials
                listSearchedClients.current = clientsResult?.current?.table
                listFilteredClients.current = clientsResult?.current?.table
                listClients.current = essentials.table.slice(0, rowsPerPage + 501)

                essentials.products.forEach((p) => {
                    if (p.campain == true) {
                        sum.current["c" + p.id] = { oportunity: 0, planned_value: 0, done: 0, receita: 0, receitaOp: 0, receitaRe: 0 }
                    }
                })
                if (listProducts.current) {
                    listProducts?.current.forEach((p) => {
                        // Adicione em cada objeto do array um elemento display e seta como true
                        p["display"] = true
                    })
                }
                setCloneListProducts(listProducts.current)
                filteredCampains.current = listProducts.current

                setColumnsLocal()
                doSum()
                loadSettlementsCalendar()
                // getBalance()
                assembleTable()
            }
            setLoading(false)
            // getDataPermissions()
            getDataOptions()
            getDataEvents()
            // getFeeFixo()
        } catch (error) {
            setLoading(false)
            return alert(`Erro ao realizar busca de relatórios.`)
        }
    }

    // const passandoPorColumntoDisplay = columnsToDisplay?.map((e) => {
    //     visibleColumnsRef.current.push(e.options.display)


    // });



    function loadSettlementsCalendar() {
        //para o calendário de liquidações
        var listaClientesPorProdutoAux = listProducts.current.map((p) => {
            if (p.data_liquidacao && p.data_liquidacao !== null) {
                const listFilteredClients = clientsResult.current.table.filter((c) => c["c" + p.id + "__status"] == "BOLETADO")
                return {
                    id: p.id,
                    description: p.description,
                    name: p.name,
                    date: p.data_liquidacao,
                    sum: sum.current["c" + p.id],
                    clients: listFilteredClients,
                    area: p.type.description?.split(" - ")[0],
                }
            }
            if (p.liquidation_type) {
                const listFilteredClients = clientsResult.current.table?.filter((c) => c["c" + p.id + "__status"] == "BOLETADO" || c["c" + p.id + "__status"] == "ACEITO")
                //agrupa os clientes de 'p' por data de liquidação sem levar em consideração o fuso horário
                const listFilteredClientsGrouped = listFilteredClients?.reduce((acc, curr) => {
                    const date = curr["c" + p.id + "__liquidation_date"].split("T")[0]
                    if (!acc[date]) {
                        acc[date] = []
                    }
                    acc[date].push(curr)
                    return acc
                }, {})
                //cria produtos ficticios com as infos do produto original e os clientes agrupados por data de liquidação
                const listFilteredClientsGroupedArray = Object.entries(listFilteredClientsGrouped)?.map(([date, clients]) => {
                    var dias = p.liquidacao_plataforma
                    var data = new Date(date)

                    var weekDay = data.getUTCDay()
                    while (dias > 0) {
                        data.setDate(data.getDate() + 1)
                        weekDay = data.getUTCDay()
                        while (weekDay == 0 || weekDay == 6 || feriados.includes(data.toISOString().split("T")[0])) {
                            data.setDate(data.getDate() + 1)
                            weekDay = data.getUTCDay()
                        }
                        dias--
                    }
                    return {
                        id: p.id,
                        description: p.description,
                        name: p.name,
                        date: data.toISOString(),
                        sum: sum.current["c" + p.id],
                        clients: clients,
                        area: p.type.description?.split(" - ")[0],
                        liquidacao_plataforma: p.liquidacao_plataforma,
                    }
                })
                return listFilteredClientsGroupedArray
            }
                
                
        })
        listaClientesPorProdutoAux = listaClientesPorProdutoAux?.flat()
        setListaClientesPorProduto(listaClientesPorProdutoAux)

        //pega a data de hoje
        let today = new Date()

        var d0 = today
        var d1 = today
        var d2 = today
        var d3 = today
        var d3plus = today

        //pega o dia da semana
        var day = today.getUTCDay()

        var lastDayVerified = today

        //verifica se hoje é feriado ou final de semana. se for, pega o primeiro dia util seguinte
        while (day == 0 || day == 6 || feriados.includes(lastDayVerified.toISOString().split("T")[0])) {
            lastDayVerified.setDate(lastDayVerified.getDate() + 1)
            day = lastDayVerified.getUTCDay()
        }
        d0 = lastDayVerified.toISOString().split("T")[0]

        lastDayVerified.setDate(lastDayVerified.getDate() + 1)
        day = lastDayVerified.getUTCDay()

        //verifica se hoje é feriado ou final de semana. se for, pega o primeiro dia util seguinte
        while (day == 0 || day == 6 || feriados.includes(lastDayVerified.toISOString().split("T")[0])) {
            lastDayVerified.setDate(lastDayVerified.getDate() + 1)
            day = lastDayVerified.getUTCDay()
        }
        d1 = lastDayVerified.toISOString().split("T")[0]
        lastDayVerified.setDate(lastDayVerified.getDate() + 1)
        day = lastDayVerified.getUTCDay()

        //verifica se hoje é feriado ou final de semana. se for, pega o primeiro dia util seguinte
        while (day == 0 || day == 6 || feriados.includes(lastDayVerified.toISOString().split("T")[0])) {
            lastDayVerified.setDate(lastDayVerified.getDate() + 1)
            day = lastDayVerified.getUTCDay()
        }
        d2 = lastDayVerified.toISOString().split("T")[0]
        lastDayVerified.setDate(lastDayVerified.getDate() + 1)
        day = lastDayVerified.getUTCDay()

        //verifica se hoje é feriado ou final de semana. se for, pega o primeiro dia util seguinte
        while (day == 0 || day == 6 || feriados.includes(lastDayVerified.toISOString().split("T")[0])) {
            lastDayVerified.setDate(lastDayVerified.getDate() + 1)
            day = lastDayVerified.getUTCDay()
        }
        d3 = lastDayVerified.toISOString().split("T")[0]
        lastDayVerified.setDate(lastDayVerified.getDate() + 1)
        day = lastDayVerified.getUTCDay()

        //verifica se hoje é feriado ou final de semana. se for, pega o primeiro dia util seguinte
        while (day == 0 || day == 6 || feriados.includes(lastDayVerified.toISOString().split("T")[0])) {
            lastDayVerified.setDate(lastDayVerified.getDate() + 1)
            day = lastDayVerified.getUTCDay()
        }
        d3plus = lastDayVerified.toISOString().split("T")[0]

        //qtd clientes em dx
        var qtdD0 = 0
        var qtdD1 = 0
        var qtdD2 = 0
        var qtdD3 = 0
        var qtdD3plus = 0

        listaClientesPorProdutoAux.map((p) => {
            if (p?.clients) {
                if (p.date.split("T")[0] == d0) qtdD0 += p.clients.length
                if (p.date.split("T")[0] == d1 || (p.date.split("T")[0] < d1 && p.date.split("T")[0] > d0)) qtdD1 += p.clients.length
                if (p.date.split("T")[0] == d2 || (p.date.split("T")[0] < d2 && p.date.split("T")[0] > d1)) qtdD2 += p.clients.length
                if (p.date.split("T")[0] == d3 || (p.date.split("T")[0] < d3 && p.date.split("T")[0] > d2)) qtdD3 += p.clients.length
                if (p.date.split("T")[0] >= d3plus || (p.date.split("T")[0] < d3plus && p.date.split("T")[0] > d3)) qtdD3plus += p.clients.length
            }
        })

        setClientsSettlements({
            d0: qtdD0,
            d1: qtdD1,
            d2: qtdD2,
            d3: qtdD3,
            d3plus: qtdD3plus,
        })
    }

    async function configurationExport() {
        try {
            const exportClientsConfigurationResult = await api.get(`api/v1/alocacao/table/configuration/export`, {
                headers: {
                    authorization: `Bearer ${tokenKey}`,
                },
                responseType: "blob",
            })

            const responseURL = URL.createObjectURL(new Blob([exportClientsConfigurationResult.data]))
            const link = document.createElement("a")
            link.href = responseURL
            link.setAttribute("download", "configurações_de_clientes.xlsx")
            document.body.appendChild(link)
            link.click()
        } catch (error) {
            setType({
                name: "alertMsg",
                type: "information",
                value: {
                    message: error?.response?.data?.message === undefined ? "Erro ao buscar dados para exportação" : error?.response?.data?.message,
                },
            })
            setShow(true)
        }
    }

      // Função de busca que será chamada após um atraso
  const searchCampains = (value) => {
    // Sua lógica de busca aqui
    setSearchArea(value);
  };

  // Função para lidar com a mudança no campo de entrada
  const handleChangeSeach = (e) => {
    setSearchTerm(e.target.value);
  };

  // Função que executa a busca após um atraso
  const delayedSearchCampains = debounce(searchCampains, 500);

    async function copyToClipboard() {
        const output = []
        var titles = ["Código do cliente", "Nome do cliente"]
        if ((userType === 1 || userType === 2 || user?.is_leader > 0 || (userType == 4 && clientsResult?.current?.is_leader)) && columnsToDisplay.current?.some((x) => x.column === "advisor" && x.display == true)) titles.push("Assessor")
        if ((userType === 1 || userType === 2 || user?.is_leader > 0 || (userType == 4 && clientsResult?.current?.is_leader)) && columnsToDisplay.current?.some((x) => x.column === "advisor_id" && x.display == true)) titles.push("Código do Assessor")
        if (((userType === 1 || userType === 2 || user?.is_leader > 1 || (userType == 4 && clientsResult?.current?.is_leader)) && columnsToDisplay.current?.some((x) => x.column === "leader_id" && x.display == true))) titles.push("Código do Líder")
        if (((userType === 1 || userType === 2 || user?.is_leader > 1 || (userType == 4 && clientsResult?.current?.is_leader)) && columnsToDisplay.current?.some((x) => x.column === "leader" && x.display == true))) titles.push("Líder")
        if (((userType === 1 || userType === 2 || user?.is_leader > 1 || (userType == 4 && clientsResult?.current?.is_leader)) && columnsToDisplay.current?.some((x) => x.column === "branch_city" && x.display == true))) titles.push("Filial")
        if (columnsToDisplay.current?.some((x) => x.column === "net_total" && x.display == true)) titles.push("NET")
        if (columnsToDisplay.current?.some((x) => x.column === "balance_d0" && x.display == true)) titles.push("SALDO EM CONTA")
        if (columnsToDisplay.current?.some((x) => x.column === "roa" && x.display == true)) titles.push("ROA")
        if (columnsToDisplay.current?.some((x) => x.column === "fee_fixo_comission" && x.display == true)) titles.push("FEE FIXO")
        // titles.push("NET", "ROA", "FEE FIXO")
        listProducts.current.forEach((p) => {
            // Verifica cada título relacionado ao produto
            if (columnsToDisplay.current?.some(x => x.column === `c${p.id}__objective` && x.display == true)) {
                titles.push(`${p.description}: Objetivo`);
            }
            if (columnsToDisplay.current?.some(x => x.column === `c${p.id}__value` && x.display == true)) {
                titles.push(`${p.description}: Valor`);
            }
            if (columnsToDisplay.current?.some(x => x.column === `c${p.id}__receita_estimada` && x.display == true)) {
                titles.push(`${p.description}: Receita Estimada`);
            }
            if (columnsToDisplay.current?.some(x => x.column === `c${p.id}__status` && x.display == true)) {
                titles.push(`${p.description}: Status`);
            }
        
            
            // Calcula a receita para cada cliente
            listSearchedClients.current.forEach((c) => {
                if (columnsToDisplay.current?.some(x => x.column === `c${p.id}__receita` && x.display == true)) {
                    c["c" + p.id + "__receita"] =
                        c["c" + p.id + "__value"] > 0 && c["c" + p.id + "__value"] != null
                            ? c["c" + p.id + "__value"] * p.fee
                            : c["c" + p.id + "__objective"] > 0 && c["c" + p.id + "__objective"] != null
                                ? c["c" + p.id + "__objective"] * p.fee
                                : 0;
                }
            });
        });
        

        output.push(titles.join("\t"))

        var keys = ["client_id", "client"]
        // if (userType === 1 || userType === 2 || user?.is_leader > 0 || ( userType == 4 && clientsResult?.current?.is_leader) ) keys.push("advisor", "advisor_id")
        // if (userType === 1 || userType === 2 || user?.is_leader > 1 || ( userType == 4 && clientsResult?.current?.is_leader)) keys.push("leader_id", "leader", "branch_city")
        if ((userType === 1 || userType === 2 || user?.is_leader > 0 || (userType == 4 && clientsResult?.current?.is_leader)) && columnsToDisplay.current?.some((x) => x.column === "advisor" && x.display == true)) keys.push("advisor");
        if ((userType === 1 || userType === 2 || user?.is_leader > 0 || (userType == 4 && clientsResult?.current?.is_leader)) && columnsToDisplay.current?.some((x) => x.column === "advisor_id" && x.display == true)) keys.push("advisor_id");
        if (((userType === 1 || userType === 2 || user?.is_leader > 1 || (userType == 4 && clientsResult?.current?.is_leader)) && columnsToDisplay.current?.some((x) => x.column === "leader_id" && x.display == true))) keys.push("leader_id");
        if (((userType === 1 || userType === 2 || user?.is_leader > 1 || (userType == 4 && clientsResult?.current?.is_leader)) && columnsToDisplay.current?.some((x) => x.column === "leader" && x.display == true))) keys.push("leader");
        if (((userType === 1 || userType === 2 || user?.is_leader > 1 || (userType == 4 && clientsResult?.current?.is_leader)) && columnsToDisplay.current?.some((x) => x.column === "branch_city" && x.display == true))) keys.push("branch_city");
        // keys.push("net_total", "roa" , "fee_fixo_comission")
        if (columnsToDisplay.current?.some((x) => x.column === "net_total" && x.display == true)) keys.push("net_total")
        if (columnsToDisplay.current?.some((x) => x.column === "balance_d0" && x.display == true)) keys.push("balance_d0")
        if (columnsToDisplay.current?.some((x) => x.column === "roa" && x.display == true)) keys.push("roa")
        if (columnsToDisplay.current?.some((x) => x.column === "fee_fixo_comission" && x.display == true)) keys.push("fee_fixo_comission")



        listProducts.current.forEach((p) => {
            // Verifica e adiciona cada coluna e título relacionado ao produto
            if (columnsToDisplay.current?.some(x => x.column === `c${p.id}__objective` && x.display == true)) {
                titles.push(`${p.description}: Objetivo`);
                keys.push(`c${p.id}__objective`);
            }
            if (columnsToDisplay.current?.some(x => x.column === `c${p.id}__value` && x.display == true)) {
                titles.push(`${p.description}: Valor`);
                keys.push(`c${p.id}__value`);
            }
            if (columnsToDisplay.current?.some(x => x.column === `c${p.id}__receita_estimada` && x.display == true)) {
                titles.push(`${p.description}: Receita Estimada`);
                keys.push(`c${p.id}__receita_estimada`);
            }
            if (columnsToDisplay.current?.some(x => x.column === `c${p.id}__status` && x.display == true)) {
                titles.push(`${p.description}: Status`);
                keys.push(`c${p.id}__status`);
            }
        });
        
        // listSearchedClients.current.forEach((c) => {
        //     const tmp = keys.map((k) => {
        //         if (k === "fee_fixo_comission" && c[k]) {
        //             // Multiplicar por 100 se existir e for um valor numérico
        //             return String(c[k] * 100).replace(".", ",");
        //         } 
        //     output.push(tmp.join("\t"));
        // })});

        // listProducts.current.forEach((p) => {
        //     // Verifica cada coluna relacionada ao produto
        //     if (columnsToDisplay.current?.some(x => x.column === `c${p.id}__objective` && x.display == true)) {
        //         keys.push(`c${p.id}__objective`);
        //     }
        //     if (columnsToDisplay.current?.some(x => x.column === `c${p.id}__value` && x.display == true)) {
        //         keys.push(`c${p.id}__value`);
        //     }
        //     if (columnsToDisplay.current?.some(x => x.column === `c${p.id}__receita` && x.display == true)) {
        //         keys.push(`c${p.id}__receita`);
        //     }
        //     if (columnsToDisplay.current?.some(x => x.column === `c${p.id}__status` && x.display == true)) {
        //         keys.push(`c${p.id}__status`);
        //     }
        // });
        

        listSearchedClients.current.forEach((c) => {
            const tmp = keys.map((k) => {
                if (k === "fee_fixo_comission" && c[k]) {
                    // Multiplicar por 100 se existir e for um valor numérico
                    return String(c[k] * 100).replace(".", ",");
                } else {
                    return c[k] ? (k === "net_total" || k === "roa" /* || k === "fee_fixo_comission" */ || k.endsWith("_objective") || k.endsWith("_value") || k.endsWith("_receita") ? String(c[k]).replace(".", ",") : c[k]) : "-"
                }
            })
            output.push(tmp.join("\t"))
        })
        const table = output.join("\n")
        navigator.clipboard.writeText(table)
        alert("Conteúdo da tabela copiado para a área de transferência.")
    }

    async function exportDash() {
        var titles = ["Código do cliente", "Nome do cliente"]
        if (userType === 1 || userType === 2 || clientsResult.current?.is_leader > 0 || (userType == 4 && clientsResult?.current?.is_leader)) titles.push("Assessor", "Código do Assessor")
        if (userType === 1 || userType === 2 || user?.is_leader > 1 || (userType == 4 && clientsResult?.current?.is_leader)) titles.push("Código do Líder", "Líder", "Filial")
        titles.push("NET", "SALDO EM CONTA", "ROA", "FEE FIXO")
        listProducts.current.forEach((p) => {
            titles.push(`${p.description}: Objetivo`, `${p.description}: Valor`, `${p.description}: Receita Estimada`, `${p.description}: Status`)

            listSearchedClients.current?.forEach((c) => {
                c["c" + p.id + "__receita"] =
                    c["c" + p.id + "__value"] > 0 && c["c" + p.id + "__value"] != null
                        ? c["c" + p.id + "__value"] * p.fee
                        : c["c" + p.id + "__objective"] > 0 && c["c" + p.id + "__objective"] != null
                            ? c["c" + p.id + "__objective"] * p.fee
                            : 0
            })
        })

        var keys = ["client_id", "client"]
        if (userType === 1 || userType === 2 || user?.is_leader > 0 || (userType == 4 && clientsResult?.current?.is_leader)) keys.push("advisor", "advisor_id")
        if (userType === 1 || userType === 2 || user?.is_leader > 1 || (userType == 4 && clientsResult?.current?.is_leader)) keys.push("leader_id", "leader", "branch_city")
        keys.push("net_total", "balance_d0", "roa", "fee_fixo_comission")
        listProducts.current.forEach((p) => {
            keys.push(`c${p.id}__objective`, `c${p.id}__value`, `c${p.id}__receita`, `c${p.id}__status`)
        })

        const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8"
        const fileExtension = ".xlsx"

        const json = listSearchedClients.current?.reduce((result, val) => {
            const temp = {}
            keys.forEach((v, idx) => {
                v == "fee_fixo_comission" ? (temp[titles[idx]] = val[v] ? val[v] * 100 : "-") : (temp[titles[idx]] = val[v] ? val[v] : "-")
            })

            result.push(temp)
            return result
        }, [])

        const fileName = `Dashboard - Campanhas`
        const ws = utils.json_to_sheet(json)
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] }
        const excelBuffer = write(wb, { bookType: "xlsx", type: "array" })
        const data = new Blob([excelBuffer], { type: fileType })
        saveAs(data, fileName + fileExtension)
        return false
    }

    useEffect(() => {
        getPriorities()
        getData()
        getAreasAlocacaoCampanhas()
        getDatabaseUpdateTimeValues()
    }, [edited])

    async function getPriorities() {
        const priorities = await api.get(`/api/v1/abc/config/priorities`, {
            headers: {
                authorization: `Bearer ${token}`,
            },
        })
        campaignPriority.current = priorities?.data?.data
    }

    // async function getFeeFixo() {
    //     const feeFixo = await api.get(`/api/v1/abc/config/fee`, {
    //         headers: {
    //             authorization: `Bearer ${token}`,
    //         },
    //     })

    //     feeFixo.current = feeFixo?.data?.data
    // }

    async function getDatabaseUpdateTimeValues() {
        const bases = "campanhas,positivador"
        const databaseUpdateTime = await getDatabaseReferenceTime(tokenKey, bases)
        if (getDatabaseReferenceTime?.isError) {
            setType({
                name: "alertMsg",
                type: "information",
                value: {
                    message: getDatabaseReferenceTime?.error,
                },
            })
            setShow(true)
        } else {
            try {
                setCrmUpdateTime(databaseUpdateTime[0].file_reference_time)
                setPositivadorUpdateTime(databaseUpdateTime[1].file_reference_time)
            } catch (e) {
                let dateFormat = ""
                setDatabaseUpdateTime(dateFormat)
            }
        }
    }

    useEffect(
        () => {
            forceUpdate()
        },
        [listClients.current],
        [listFilteredClients.current]
    )

    // useEffect(() => {
    //     const currentDay = new Date().getDate(); // Obtém o dia atual
    //     const storedDay = localStorage.getItem('dayLastAccessDash'); // Obtém o dia armazenado

    //     if (storedDay && currentDay.toString() === storedDay) {
    //       // Se o dia armazenado é igual ao dia atual, carregue os filtros do local storage
    //       const storedFilters = JSON.parse(localStorage.getItem('filtersDash'));
    //       if (storedFilters) {
    //         setFilters(storedFilters); // Sua função para definir os filtros na UI
    //       }
    //     } else {
    //       // Se o dia mudou, resete os filtros
    //       localStorage.setItem('dayLastAccessDash', currentDay.toString()); // Atualize o dia armazenado
    //     }
    //   }, []); // Este useEffect roda apenas quando o componente é montado

    // Função para atualizar os filtros

    useEffect(() => {
        const updateAlturaTabela = () => {
            const altura =
                window.innerHeight <= 850 && window.devicePixelRatio < 1.2
                    ? window.innerHeight * window.devicePixelRatio * 1.4
                    : window.innerHeight <= 600 && window.devicePixelRatio > 1.2
                        ? window.innerHeight * window.devicePixelRatio * 1.4
                        : window.innerHeight * window.devicePixelRatio
            setAlturaTabela(altura - 492)
        }

        updateAlturaTabela()

        window.addEventListener("resize", updateAlturaTabela)

        return () => {
            window.removeEventListener("resize", updateAlturaTabela)
        }
    }, [])

    const atualizadorModalStatus = () => {
        setCurrentPage(0)
        setLastPage(0)
    }
    useEffect(() => {
        if (tabActive.current == 1) {
            forceUpdate()
            assembleTable()
        }
    }, [selectedCampaign])

    useEffect(() => {
        if (tabActive.current == 0) {
            tableChanged.current = true
            setConfigTable()
            forceUpdate()
            assembleTable()
        }
    }, [])

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    const handleInputChange = (event) => {
        setSearchArea(event.target.value);
     }

    const separarLinhas = (e) => {
        let tamanhoFrase = e.description.length
        let palavras = e.description.split(" ")
        let primeiraLinha = ""

        for (let i = 0; i < palavras.length; i++) {
            primeiraLinha += palavras[i] + " "
            if (primeiraLinha.length > tamanhoFrase / 2) {
                break
            }
        }
        let segundaLinha = palavras.slice(primeiraLinha.split(" ").length).join(" ")

        return (
            <>
                {tamanhoFrase >= 40 ? (
                    <div style={{ textAlign: "left", paddingRight: "1rem" }}>
                        <span>{primeiraLinha}</span>
                        <br />
                        <span>{segundaLinha}</span>
                    </div>
                ) : (
                    e?.description
                )}
            </>
        )
    }

    useEffect(() => {
        // Atualiza o localStorage com as novas colunas sempre que houver uma alteração
        const updatedColumnsToDisplay = columnsToDisplay.current?.map((column) => ({
            ...column,
            // Define o display como true para colunas de novas campanhas
            display: column.column.includes(`c${selectedCampaign?.id}__`) ? true : column.display,
        }))

        // Salva as novas colunas no estado e no localStorage
        columnsToDisplay.current = updatedColumnsToDisplay
        localStorage.setItem("columnsToDisplay", JSON.stringify(updatedColumnsToDisplay))
    }, [selectedCampaign]) // Dependência: selectedCampaign

    // useEffect(() => {
    //     setFilters([])
    //     visibleColumnsRef.current.map((e) => {
    //         if(e.name == props?.prop?.prop?.data){
    //             e.options.filterList = (props.prop.prop.listFilterStatusProducts.current[props?.prop?.prop?.data])
    //             filtersList.current[props?.prop?.prop?.data] = (props.prop.prop.listFilterStatusProducts.current[props?.prop?.prop?.data]);
    //         }
    //     })
    //     localStorage.setItem("filterDash", JSON.stringify(filtersList.current))

    //     const day = (new Date()).getDate()
    //     if (localStorage.getItem("dayLastAccessDash")) {
    //         const dayLastAccess = localStorage.getItem('dayLastAccessDash')
    //         if(dayLastAccess != day){
    //             localStorage.setItem("dayLastAccessDash", day)
    //             changedDay.current = true
    //         }
    //     }
    //     else{
    //         localStorage.setItem("dayLastAccessDash", day)
    //     }

    //     forceUpdate()

    // }, [show]);

    // setFilters(clientsResult?.data?.data?.products.map((x) => `${x.name}`))

    useEffect(() => {
        if (selectedCampaign) {
            const columns = columnsToDisplay.current
            const newColumns = columns.map((column) => {
                if (column.column.includes(`c${selectedCampaign.id}__`)) {
                    return {
                        ...column,
                        display: true,
                    }
                }
                return column
            }
            )
            columnsToDisplay.current = newColumns
            localStorage.setItem("columnsDash", JSON.stringify(newColumns))
        }
        assembleTable()
    }, [selectedCampaign])

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (filterAreaRef.current && !filterAreaRef.current.contains(event.target)) {
                setFilterAreaOpen(false);
            }
        };

        document.addEventListener('mousedown', handleOutsideClick);

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);

    async function assembleTable() {
        visibleColumnsRef.current = [
            {
                name: "client_id",
                label: "CÓDIGO DO CLIENTE",
                options: {
                    filter: tabActive.current == 0 ? true : false,
                    sort: true,
                    sortDescFirst: true,
                    display: true,
                    filterType: "custom",
                    filterList: filtersList.current["client_id"],
                    customFilterListOptions: {
                        render: (v) => v,
                        update: (filterList, filterPos, index) => {
                            filterList[index].splice(filterPos, 1)
                            return filterList
                        },
                    },
                    filterOptions: {
                        logic: (location, filters, row) => {
                            if (filters.length) return !filters.includes(location)
                            return false
                        },
                        display: (filterList, onChange, index, column) => {
                            const optionValues = listSearchedClients.current.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v))
                            return (
                                <FormControl>
                                    <InputLabel htmlFor="select-multiple-chip" variant="standard">
                                        {column.label}
                                    </InputLabel>
                                    <Select
                                        multiple
                                        variant="standard"
                                        value={filterList[index]}
                                        renderValue={(selected) => selected.join(", ")}
                                        onChange={(event) => {
                                            filterList[index] = event.target.value
                                            onChange(filterList[index], index, column)
                                        }}
                                    >
                                        {optionValues.map((item) => (
                                            <MenuItem key={item} value={item}>
                                                <Checkbox color="primary" checked={filterList[index].indexOf(item) > -1} />
                                                <ListItemText primary={item} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            )
                        },
                    },
                    customHeadRender: ({ index, name, label, ...column }, sortColumn, tableMeta) => {
                        return (
                            <>
                                <TableCell
                                    className="TableCell"
                                    onClick={() => {
                                        sortColumn(index)
                                    }}
                                    style={{
                                        textAlign: "center",
                                        minWidth: "13rem",
                                        whiteSpace: "nowrap",
                                        position: "sticky",
                                        left: 0,
                                        zIndex: 121,
                                        backgroundColor: "white",
                                    }}
                                >
                                    <div className="containerTableCellButton">
                                        <div>
                                            {label}
                                            <BsQuestionCircle
                                                color="rgb(117, 117, 117)"
                                                size={16}
                                                style={{
                                                    display: "inline-block",
                                                    verticalAlign: "middle",
                                                    marginTop: "-0.2rem",
                                                    cursor: "pointer",
                                                    marginLeft: "0.5rem",
                                                    marginRight: "0.5rem",
                                                }}
                                                title="Informa o código do cliente."
                                            />
                                            <div className="arrowSortAbc">
                                                {tableMeta.name === name ? tableMeta.direction == "asc" ? <AiOutlineArrowUp size={18} /> : <AiOutlineArrowDown size={18} /> : <></>}
                                            </div>
                                        </div>
                                    </div>
                                </TableCell>
                            </>
                        )
                    },
                    setCellProps: () => ({
                        style: {
                            position: "sticky",
                            left: "0",
                            backgroundColor: "white",
                            zIndex: 120,
                        },
                    }),
                },
            },
            {
                name: "client",
                label: "CLIENTE",
                options: {
                    filter: tabActive.current == 0 ? true : false,
                    sort: true,
                    display: true,
                    filterType: "custom",
                    filterList: filtersList.current["client"],
                    customFilterListOptions: {
                        render: (v) => v,
                        update: (filterList, filterPos, index) => {
                            filterList[index].splice(filterPos, 1)
                            return filterList
                        },
                    },
                    filterOptions: {
                        logic: (location, filters, row) => {
                            if (filters.length) return !filters.includes(location)
                            return false
                        },
                        display: (filterList, onChange, index, column) => {
                            const optionValues = listSearchedClients.current.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v))
                            return (
                                <FormControl>
                                    <InputLabel htmlFor="select-multiple-chip" variant="standard">
                                        {column.label}
                                    </InputLabel>
                                    <Select
                                        multiple
                                        variant="standard"
                                        value={filterList[index]}
                                        renderValue={(selected) => selected.join(", ")}
                                        onChange={(event) => {
                                            filterList[index] = event.target.value
                                            onChange(filterList[index], index, column)
                                        }}
                                    >
                                        {optionValues.map((item) => (
                                            <MenuItem key={item} value={item}>
                                                <Checkbox color="primary" checked={filterList[index].indexOf(item) > -1} />
                                                <ListItemText primary={item} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            )
                        },
                    },
                    customHeadRender: ({ index, name, label, ...column }, sortColumn, tableMeta) => {
                        return (
                            <>
                                <TableCell
                                    className="TableCell"
                                    onClick={() => {
                                        sortColumn(index)
                                    }}
                                    style={{
                                        paddingRight: "0.5rem",
                                        whiteSpace: "nowrap",
                                        position: "sticky",
                                        top: "0px",
                                        left: "13rem",
                                        zIndex: 121,
                                        backgroundColor: "white",
                                    }}
                                >
                                    <div className="containerTableCellButton">
                                        <div>
                                            {label}
                                            <BsQuestionCircle
                                                color="rgb(117, 117, 117)"
                                                size={16}
                                                style={{
                                                    display: "inline-block",
                                                    verticalAlign: "middle",
                                                    marginTop: "-0.2rem",
                                                    cursor: "pointer",
                                                    marginLeft: "0.5rem",
                                                    marginRight: "0.5rem",
                                                }}
                                                title="Informa o nome do cliente."
                                            />
                                            <div className="arrowSortAbc">
                                                {tableMeta.name === name ? tableMeta.direction == "asc" ? <AiOutlineArrowUp size={18} /> : <AiOutlineArrowDown size={18} /> : <></>}
                                            </div>
                                        </div>
                                    </div>
                                </TableCell>
                            </>
                        )
                    },
                    setCellProps: () => ({
                        style: {
                            paddingLeft: "0.5rem",
                            paddingRight: "0.5rem",
                            position: "sticky",
                            left: "13rem",
                            backgroundColor: "white",
                            zIndex: 120,
                        },
                    }),
                },
            },
        ]
        //se o usuario for lider, entao insere uma coluna de assessor na tabela para saber de qual dos liderados é o cliente
        if (userType === 1 || userType === 2 || user?.is_leader > 0 || (userType == 4 && clientsResult?.current?.is_leader)) {
            visibleColumnsRef.current.push(
                {
                    name: "advisor",
                    label: "ASSESSOR",
                    options: {
                        filter: true,
                        sort: true,
                        display: tabActive.current == 1 ? columnsToDisplay.current?.some((x) => x.column === "advisor" && x.display) ?? true : true,
                        filterType: "custom",
                        filterList: filtersList.current["advisor"],
                        sortDescFirst: true,
                        customFilterListOptions: {
                            render: (v) => v,
                            update: (filterList, filterPos, index) => {
                                filterList[index].splice(filterPos, 1)
                                return filterList
                            },
                        },
                        filterOptions: {
                            logic: (location, filters, row) => {
                                if (filters.length) return !filters.includes(location)
                                return false
                            },
                            display: (filterList, onChange, index, column) => {
                                const optionValues = listSearchedClients.current.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v))
                                optionValues.sort((a, b) => {
                                    if (a < b) {
                                        return -1
                                    }
                                    if (a > b) {
                                        return 1
                                    }
                                    return 0
                                })
                                return (
                                    <FormControl>
                                        <InputLabel htmlFor="select-multiple-chip" variant="standard">
                                            {column.label}
                                        </InputLabel>
                                        <Select
                                            multiple
                                            variant="standard"
                                            value={filterList[index]}
                                            renderValue={(selected) => selected.join(", ")}
                                            onChange={(event) => {
                                                filterList[index] = event.target.value
                                                onChange(filterList[index], index, column)
                                            }}
                                        >
                                            {optionValues.map((item) => (
                                                <MenuItem key={item} value={item}>
                                                    <Checkbox color="primary" checked={filterList[index].indexOf(item) > -1} />
                                                    <ListItemText primary={item} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                )
                            },
                        },
                        customHeadRender: ({ index, name, label, ...column }, sortColumn, tableMeta) => {
                            return (
                                <>
                                    <TableCell
                                        className="TableCell"
                                        onClick={() => {
                                            sortColumn(index)
                                        }}
                                        style={{
                                            paddingRight: "0.5rem",
                                            paddingLeft: "0.5rem",
                                            whiteSpace: "nowrap",
                                        }}
                                    >
                                        <div className="containerTableCellButton">
                                            <div>
                                                {label}
                                                <BsQuestionCircle
                                                    color="rgb(117, 117, 117)"
                                                    size={16}
                                                    style={{
                                                        display: "inline-block",
                                                        verticalAlign: "middle",
                                                        marginTop: "-0.2rem",
                                                        cursor: "pointer",
                                                        marginLeft: "0.5rem",
                                                        marginRight: "0.5rem",
                                                    }}
                                                    title="Informa o nome do Assessor responsável pelo cliente."
                                                />
                                                <div className="arrowSortAbc">
                                                    {tableMeta.name === name ? tableMeta.direction == "asc" ? <AiOutlineArrowUp size={18} /> : <AiOutlineArrowDown size={18} /> : <></>}
                                                </div>
                                            </div>
                                        </div>
                                    </TableCell>
                                </>
                            )
                        },
                        setCellProps: () => ({
                            style: {
                                paddingLeft: "0.5rem",
                                paddingRight: "0.5rem",
                            },
                        }),
                    },
                },
                {
                    name: "advisor_id",
                    label: "Código do Assessor",
                    options: {
                        filter: true,
                        sort: false,
                        display: tabActive.current == 1 ? columnsToDisplay.current?.some((x) => x.column === "advusir_id" && x.display) ?? true : true,
                    },
                }
            )
        }

        if (userType === 1 || userType === 2 || user?.is_leader > 1 || (userType == 4 && clientsResult?.current?.is_leader)) {
            visibleColumnsRef.current.push(
                {
                    name: "leader_id",
                    label: "CÓDIGO DO LÍDER",
                    options: {
                        filter: true,
                        sort: true,
                        display: tabActive.current == 1 ? columnsToDisplay.current?.some((x) => x.column === "leader_id" && x.display) ?? true : true,
                        filterType: "custom",
                        filterList: filtersList.current["leader_id"],
                        sortDescFirst: true,
                        customFilterListOptions: {
                            render: (v) => v,
                            update: (filterList, filterPos, index) => {
                                filterList[index].splice(filterPos, 1)
                                return filterList
                            },
                        },
                        filterOptions: {
                            logic: (location, filters, row) => {
                                if (filters.length) return !filters.includes(location)
                                return false
                            },
                            display: (filterList, onChange, index, column) => {
                                const optionValues = listSearchedClients.current
                                    .map((x) => x[column.name])
                                    .filter((v, i, a) => i === a.indexOf(v))
                                    .sort((a, b) => a - b)

                                return (
                                    <FormControl>
                                        <InputLabel htmlFor="select-multiple-chip" variant="standard">
                                            {column.label}
                                        </InputLabel>
                                        <Select
                                            multiple
                                            variant="standard"
                                            value={filterList[index]}
                                            renderValue={(selected) => selected.join(", ")}
                                            onChange={(event) => {
                                                filterList[index] = event.target.value
                                                onChange(filterList[index], index, column)
                                            }}
                                        >
                                            {optionValues.map((item) => (
                                                <MenuItem key={item} value={item}>
                                                    <Checkbox color="primary" checked={filterList[index].indexOf(item) > -1} />
                                                    <ListItemText primary={item} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                )
                            },
                        },
                        customHeadRender: ({ index, name, label, ...column }, sortColumn, tableMeta) => {
                            return (
                                <>
                                    <TableCell
                                        className="TableCell"
                                        onClick={() => {
                                            sortColumn(index)
                                        }}
                                        style={{
                                            paddingRight: "0.5rem",
                                            paddingLeft: "0.5rem",
                                            whiteSpace: "nowrap",
                                        }}
                                    >
                                        <div className="containerTableCellButton">
                                            <div>
                                                {label}
                                                <BsQuestionCircle
                                                    color="rgb(117, 117, 117)"
                                                    size={16}
                                                    style={{
                                                        display: "inline-block",
                                                        verticalAlign: "middle",
                                                        marginTop: "-0.2rem",
                                                        cursor: "pointer",
                                                        marginLeft: "0.5rem",
                                                        marginRight: "0.5rem",
                                                    }}
                                                    title="Informa o nome do Assessor responsável pelo cliente."
                                                />
                                                <div className="arrowSortAbc">
                                                    {tableMeta.name === name ? tableMeta.direction == "asc" ? <AiOutlineArrowUp size={18} /> : <AiOutlineArrowDown size={18} /> : <></>}
                                                </div>
                                            </div>
                                        </div>
                                    </TableCell>
                                </>
                            )
                        },
                        setCellProps: () => ({
                            style: {
                                paddingLeft: "0.5rem",
                                paddingRight: "0.5rem",
                            },
                        }),
                    },
                },
                {
                    name: "leader",
                    label: "TIME",
                    options: {
                        filter: true,
                        sort: true,
                        display: tabActive.current == 1 ? columnsToDisplay.current?.some((x) => x.column === "leader" && x.display) ?? true : true,
                        filterType: "custom",
                        filterList: filtersList.current["leader"],
                        sortDescFirst: true,
                        customFilterListOptions: {
                            render: (v) => v,
                            update: (filterList, filterPos, index) => {
                                filterList[index].splice(filterPos, 1)
                                return filterList
                            },
                        },
                        filterOptions: {
                            logic: (location, filters, row) => {
                                if (filters.length) return !filters.includes(location)
                                return false
                            },
                            display: (filterList, onChange, index, column) => {
                                const optionValues = listSearchedClients.current.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v))
                                optionValues.sort((a, b) => {
                                    if (a < b) {
                                        return -1
                                    }
                                    if (a > b) {
                                        return 1
                                    }
                                    return 0
                                })
                                return (
                                    <FormControl>
                                        <InputLabel htmlFor="select-multiple-chip" variant="standard">
                                            {column.label}
                                        </InputLabel>
                                        <Select
                                            multiple
                                            variant="standard"
                                            value={filterList[index]}
                                            renderValue={(selected) => selected.join(", ")}
                                            onChange={(event) => {
                                                filterList[index] = event.target.value
                                                onChange(filterList[index], index, column)
                                            }}
                                        >
                                            {optionValues.map((item) => (
                                                <MenuItem key={item} value={item}>
                                                    <Checkbox color="primary" checked={filterList[index].indexOf(item) > -1} />
                                                    <ListItemText primary={item} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                )
                            },
                        },
                        customHeadRender: ({ index, name, label, ...column }, sortColumn, tableMeta) => {
                            return (
                                <>
                                    <TableCell
                                        className="TableCell"
                                        onClick={() => {
                                            sortColumn(index)
                                        }}
                                        style={{
                                            paddingRight: "0.5rem",
                                            paddingLeft: "0.5rem",
                                            whiteSpace: "nowrap",
                                        }}
                                    >
                                        <div className="containerTableCellButton">
                                            <div>
                                                {label}
                                                <BsQuestionCircle
                                                    color="rgb(117, 117, 117)"
                                                    size={16}
                                                    style={{
                                                        display: "inline-block",
                                                        verticalAlign: "middle",
                                                        marginTop: "-0.2rem",
                                                        cursor: "pointer",
                                                        marginLeft: "0.5rem",
                                                        marginRight: "0.5rem",
                                                    }}
                                                    title="Informa o Líder responsável."
                                                />
                                                <div className="arrowSortAbc">
                                                    {tableMeta.name === name ? tableMeta.direction == "asc" ? <AiOutlineArrowUp size={18} /> : <AiOutlineArrowDown size={18} /> : <></>}
                                                </div>
                                            </div>
                                        </div>
                                    </TableCell>
                                </>
                            )
                        },
                        setCellProps: () => ({
                            style: {
                                paddingLeft: "0.5rem",
                                paddingRight: "0.5rem",
                            },
                        }),
                    },
                },
                {
                    name: "branch_city",
                    label: "FILIAL",
                    options: {
                        filter: true,
                        sort: true,
                        display: tabActive.current == 1 ? columnsToDisplay.current?.some((x) => x.column === "branch_city" && x.display) ?? true : true,
                        filterType: "custom",
                        filterList: filtersList.current["branch_city"],
                        sortDescFirst: true,
                        customFilterListOptions: {
                            render: (v) => v,
                            update: (filterList, filterPos, index) => {
                                filterList[index].splice(filterPos, 1)
                                return filterList
                            },
                        },
                        filterOptions: {
                            logic: (location, filters, row) => {
                                if (filters.length) return !filters.includes(location)
                                return false
                            },
                            display: (filterList, onChange, index, column) => {
                                const optionValues = listSearchedClients.current.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v))
                                optionValues.sort((a, b) => {
                                    if (a < b) {
                                        return -1
                                    }
                                    if (a > b) {
                                        return 1
                                    }
                                    return 0
                                })
                                return (
                                    <FormControl>
                                        <InputLabel htmlFor="select-multiple-chip" variant="standard">
                                            {column.label}
                                        </InputLabel>
                                        <Select
                                            multiple
                                            variant="standard"
                                            value={filterList[index]}
                                            renderValue={(selected) => selected.join(", ")}
                                            onChange={(event) => {
                                                filterList[index] = event.target.value
                                                onChange(filterList[index], index, column)
                                            }}
                                        >
                                            {optionValues.map((item) => (
                                                <MenuItem key={item} value={item}>
                                                    <Checkbox color="primary" checked={filterList[index].indexOf(item) > -1} />
                                                    <ListItemText primary={item} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                )
                            },
                        },
                        customHeadRender: ({ index, name, label, ...column }, sortColumn, tableMeta) => {
                            return (
                                <>
                                    <TableCell
                                        className="TableCell"
                                        onClick={() => {
                                            sortColumn(index)
                                        }}
                                        style={{
                                            paddingRight: "0.5rem",
                                            paddingLeft: "0.5rem",
                                            whiteSpace: "nowrap",
                                        }}
                                    >
                                        <div className="containerTableCellButton">
                                            <div>
                                                {label}
                                                <BsQuestionCircle
                                                    color="rgb(117, 117, 117)"
                                                    size={16}
                                                    style={{
                                                        display: "inline-block",
                                                        verticalAlign: "middle",
                                                        marginTop: "-0.2rem",
                                                        cursor: "pointer",
                                                        marginLeft: "0.5rem",
                                                        marginRight: "0.5rem",
                                                    }}
                                                    title="Informa qual o time responsável."
                                                />
                                                <div className="arrowSortAbc">
                                                    {tableMeta.name === name ? tableMeta.direction == "asc" ? <AiOutlineArrowUp size={18} /> : <AiOutlineArrowDown size={18} /> : <></>}
                                                </div>
                                            </div>
                                        </div>
                                    </TableCell>
                                </>
                            )
                        },
                        setCellProps: () => ({
                            style: {
                                marginLeft: "0.5rem",
                                marginRight: "0.5rem",
                            },
                        }),
                    },
                }
            )
        }
        {
            visibleColumnsRef.current.push(
                {
                    name: "net_total",
                    label: "NET",
                    options: {
                        filter: false,
                        sort: true,
                        display: tabActive.current == 1 ? columnsToDisplay.current?.some((x) => x.column === "net_total" && x.display) ?? true : true,
                        sortDescFirst: true,
                        sortDirection: "desc",
                        customHeadRender: ({ index, name, label, ...column }, sortColumn, tableMeta) => {
                            return (
                                <>
                                    <TableCell
                                        className="TableCell"
                                        onClick={() => {
                                            sortColumn(index)
                                        }}
                                        style={{
                                            padding: "0",
                                            whiteSpace: "nowrap",
                                        }}
                                    >
                                        <div className="containerTableCellButton">
                                            <div style={{ display: "grid", marginBottom: "-2.2rem" }}>
                                                <div>
                                                    {label}
                                                    <BsQuestionCircle
                                                        color="rgb(117, 117, 117)"
                                                        size={16}
                                                        style={{
                                                            display: "inline-block",
                                                            verticalAlign: "middle",
                                                            marginTop: "-0.2rem",
                                                            cursor: "pointer",
                                                            marginLeft: "0.5rem",
                                                            marginRight: "0.5rem",
                                                        }}
                                                        title="Informa o NET total do cliente na Manchester."
                                                    />
                                                    <div className="arrowSortAbc">
                                                        {tableMeta.name === name ? tableMeta.direction == "asc" ? <AiOutlineArrowUp size={18} /> : <AiOutlineArrowDown size={18} /> : <></>}
                                                    </div>
                                                </div>
                                                <div className="subtitleCellBoxDashSum" style={{ marginTop: "0.5rem" }}>
                                                    {toBRL(sumNet.current)}
                                                </div>
                                            </div>
                                        </div>
                                    </TableCell>
                                </>
                            )
                        },
                        setCellProps: () => ({
                            style: {
                                paddingLeft: "0.5rem",
                                paddingRight: "0.5rem",
                            },
                        }),
                        customBodyRender: (val, tableMeta) => {
                            return val ? val.toLocaleString("pt-br", { style: "currency", currency: "BRL" }) : "-"
                        },
                    },
                },
                {
                    name: "balance_d0",
                    label: "SALDO EM CONTA",
                    options: {
                        filter: false,
                        sort: true,
                        display: tabActive.current == 1 ? columnsToDisplay.current?.some((x) => x.column === "balance_d0" && x.display) ?? true : true,
                        sortDescFirst: true,
                        sortDirection: "desc",
                        customHeadRender: ({ index, name, label, ...column }, sortColumn, tableMeta) => {
                            return (
                                <>
                                    <TableCell
                                        className="TableCell"
                                        onClick={() => {
                                            sortColumn(index)
                                        }}
                                        style={{
                                            padding: "0",
                                            whiteSpace: "nowrap",
                                        }}
                                    >
                                        <div className="containerTableCellButton">
                                            <div style={{ display: "grid", marginBottom: "-2.2rem" }}>
                                                <div>
                                                    {label}
                                                    <BsQuestionCircle
                                                        color="rgb(117, 117, 117)"
                                                        size={16}
                                                        style={{
                                                            display: "inline-block",
                                                            verticalAlign: "middle",
                                                            marginTop: "-0.2rem",
                                                            cursor: "pointer",
                                                            marginLeft: "0.5rem",
                                                            marginRight: "0.5rem",
                                                        }}
                                                        title="Saldo em D0 de acordo com o horário de atualização não contempla projeções de futuras liquidações. Verificar no HUB XP para maiores detalhes."
                                                    />
                                                    <div className="arrowSortAbc">
                                                        {tableMeta.name === name ? tableMeta.direction == "asc" ? <AiOutlineArrowUp size={18} /> : <AiOutlineArrowDown size={18} /> : <></>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </TableCell>
                                </>
                            )
                        },
                        setCellProps: () => ({
                            style: {
                                paddingLeft: "0.5rem",
                                paddingRight: "0.5rem",
                            },
                        }),
                        customBodyRender: (val, tableMeta) => {
                            return val ? val.toLocaleString("pt-br", { style: "currency", currency: "BRL" }) : "-"
                        },
                    }
                },
                {
                    name: "roa",
                    label: "ROA",
                    options: {
                        filter: false,
                        sort: true,
                        display: tabActive.current == 1 ? columnsToDisplay.current?.some((x) => x.column === "roa" && x.display) ?? true : true,
                        sortDescFirst: true,
                        customHeadRender: ({ index, name, label, ...column }, sortColumn, tableMeta) => {
                            return (
                                <>
                                    <TableCell
                                        className="TableCell"
                                        onClick={() => {
                                            sortColumn(index)
                                        }}
                                        style={{
                                            whiteSpace: "nowrap",
                                        }}
                                    >
                                        <div className="containerTableCellButton">
                                            <div style={{ display: "grid", marginBottom: "-2.2rem" }}>
                                                <div>
                                                    {label}
                                                    <BsQuestionCircle
                                                        color="rgb(117, 117, 117)"
                                                        size={16}
                                                        style={{
                                                            display: "inline-block",
                                                            verticalAlign: "middle",
                                                            marginTop: "-0.2rem",
                                                            cursor: "pointer",
                                                            marginLeft: "0.5rem",
                                                            marginRight: "0.5rem",
                                                        }}
                                                        title="Informa o ROA médio do cliente no ano corrente."
                                                    />
                                                    <div className="arrowSortAbc">
                                                        {tableMeta.name === name ? tableMeta.direction == "asc" ? <AiOutlineArrowUp size={18} /> : <AiOutlineArrowDown size={18} /> : <></>}
                                                    </div>
                                                </div>
                                                {/* <div>
                                                    <div className="subtitleCellBoxDashSum" style={{marginTop:'0.5rem', display:'flex', justifyContent:'center' }} >{toPercent(sumRoa.current)}</div>
                                                </div> */}
                                            </div>
                                        </div>
                                    </TableCell>
                                </>
                            )
                        },
                        setCellProps: () => ({
                            style: {
                                paddingLeft: "0.5rem",
                                paddingRight: "0.5rem",
                            },
                        }),
                        customBodyRender: (val, tableMeta) => {
                            // se o val for null, retorna um traço e tambem se o topercente for menor ou igual a 0,30 mostrar como vermelho e se for maior que 0,30 mostrar como verde
                            return val != null ? (
                                <span style={{ color: val <= 0.3 ? "red" : "green" }}>{toPercent(val)}</span>
                            ) : (
                                "-"
                            )
                        },
                    },
                }
            )
        }
        visibleColumnsRef.current.push(
            {
                name: "fee_fixo_comission",
                label: "FEE FIXO",
                options: {
                    filter: true, //true,
                    sort: true,
                    display: tabActive.current == 1 ? columnsToDisplay.current?.some((x) => x.column === "fee_fixo_comission" && x.display) ?? true : true,
                    sortDescFirst: true,
                    customBodyRender: (val, tableMeta) => {
                        return val != null ? <span>{toPercent(val * 100)}</span> : "-"
                    },
                    setCellProps: () => ({
                        style: {
                            paddingLeft: "0.5rem",
                            paddingRight: "0.5rem",
                        },
                    }),
                    customHeadRender: ({ index, name, label, ...column }, sortColumn, tableMeta) => {
                        return (
                            <>
                                <TableCell
                                    className="TableCell"
                                    onClick={() => {
                                        sortColumn(index)
                                    }}
                                    style={{
                                        whiteSpace: "nowrap",
                                    }}
                                >
                                    <div className="containerTableCellButton">
                                        <div>
                                            {label}
                                            <BsQuestionCircle
                                                color="rgb(117, 117, 117)"
                                                size={16}
                                                style={{
                                                    display: "inline-block",
                                                    verticalAlign: "middle",
                                                    marginTop: "-0.2rem",
                                                    cursor: "pointer",
                                                    marginLeft: "0.5rem",
                                                    marginRight: "0.5rem",
                                                }}
                                                title="Informa se o cliente tem ou não FEE FIXO."
                                            />
                                            <div className="arrowSortAbc">
                                                {tableMeta.name === name ? tableMeta.direction == "asc" ? <AiOutlineArrowUp size={18} /> : <AiOutlineArrowDown size={18} /> : <></>}
                                            </div>
                                        </div>
                                    </div>
                                </TableCell>
                            </>
                        )
                    },
                },
            },
            {
                name: "profile",
                label: "Perfil",
                options: {
                    filter: tabActive.current == 0 ? true : false,
                    viewColumns: false,
                    sort: true,
                    display: tabActive.current == 0 ? true : false,
                    filterType: "custom",
                    filterList: filtersList.current["profile"],
                    customFilterListOptions: {
                        render: (v) => v,
                        update: (filterList, filterPos, index) => {
                            filterList[index].splice(filterPos, 1)
                            return filterList
                        },
                    },
                    filterOptions: {
                        logic: (location, filters, row) => {
                            if (filters.length) return !filters.includes(location)
                            return false
                        },
                        display: (filterList, onChange, index, column) => {
                            const optionValues = listSearchedClients.current.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v))
                            return (
                                <FormControl>
                                    <InputLabel htmlFor="select-multiple-chip" variant="standard">
                                        {column.label}
                                    </InputLabel>
                                    <Select
                                        multiple
                                        variant="standard"
                                        value={filterList[index]}
                                        renderValue={(selected) => selected.join(", ")}
                                        onChange={(event) => {
                                            filterList[index] = event.target.value
                                            onChange(filterList[index], index, column)
                                        }}
                                    >
                                        {optionValues.map((item) => (
                                            <MenuItem key={item} value={item}>
                                                <Checkbox color="primary" checked={filterList[index].indexOf(item) > -1} />
                                                <ListItemText primary={item} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            )
                        },
                    },
                    customBodyRender: (val, tableMeta) => {
                        return val ? val == "Indefinido" ? <span style={{ color: "red" }}>{val}</span> : val : "-"
                    },
                    customHeadRender: ({ index, name, label, ...column }, sortColumn, tableMeta) => {
                        return (
                            <>
                                <TableCell
                                    className="TableCell"
                                    onClick={() => {
                                        sortColumn(index)
                                    }}
                                    style={{
                                        whiteSpace: "nowrap",
                                    }}
                                >
                                    <div className="containerTableCellButton">
                                        <div>
                                            {label}
                                            <BsQuestionCircle
                                                color="rgb(117, 117, 117)"
                                                size={16}
                                                style={{
                                                    display: "inline-block",
                                                    verticalAlign: "middle",
                                                    marginTop: "-0.2rem",
                                                    cursor: "pointer",
                                                    marginLeft: "0.5rem",
                                                    marginRight: "0.5rem",
                                                }}
                                                title="Informa o perfil mais adequado para seu cliente"
                                            />
                                            <div className="arrowSortAbc">
                                                {tableMeta.name === name ? tableMeta.direction == "asc" ? <AiOutlineArrowUp size={18} /> : <AiOutlineArrowDown size={18} /> : <></>}
                                            </div>
                                        </div>
                                    </div>
                                </TableCell>
                            </>
                        )
                    },
                    setCellProps: () => ({
                        style: {
                            paddingLeft: "0.5rem",
                            paddingRight: "0.5rem",
                        },
                    }),
                },
            },
            {
                name: "objective",
                label: "Objetivo de Retorno",
                options: {
                    filter: tabActive.current == 0 ? true : false,
                    sort: true,
                    viewColumns: false,
                    display: tabActive.current == 0 ? true : false,
                    filterType: "custom",
                    filterList: filtersList.current["objective"],
                    customFilterListOptions: {
                        render: (v) => v,
                        update: (filterList, filterPos, index) => {
                            filterList[index].splice(filterPos, 1)
                            return filterList
                        },
                    },
                    filterOptions: {
                        logic: (location, filters, row) => {
                            if (filters.length) return !filters.includes(location)
                            return false
                        },
                        display: (filterList, onChange, index, column) => {
                            const optionValues = listSearchedClients.current.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v))
                            return (
                                <FormControl>
                                    <InputLabel htmlFor="select-multiple-chip" variant="standard">
                                        {column.label}
                                    </InputLabel>
                                    <Select
                                        multiple
                                        variant="standard"
                                        value={filterList[index]}
                                        renderValue={(selected) => selected.join(", ")}
                                        onChange={(event) => {
                                            filterList[index] = event.target.value
                                            onChange(filterList[index], index, column)
                                        }}
                                    >
                                        {optionValues.map((item) => (
                                            <MenuItem key={item} value={item}>
                                                <Checkbox color="primary" checked={filterList[index].indexOf(item) > -1} />
                                                <ListItemText primary={item} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            )
                        },
                    },
                    customBodyRender: (val, tableMeta) => {
                        return val ? val : "-"
                    },
                    customHeadRender: ({ index, name, label, ...column }, sortColumn, tableMeta) => {
                        return (
                            <>
                                <TableCell
                                    className="TableCell"
                                    onClick={() => {
                                        sortColumn(index)
                                    }}
                                    style={{
                                        whiteSpace: "nowrap",
                                    }}
                                >
                                    <div className="containerTableCellButton">
                                        <div>
                                            {label}
                                            <BsQuestionCircle
                                                color="rgb(117, 117, 117)"
                                                size={16}
                                                style={{
                                                    display: "inline-block",
                                                    verticalAlign: "middle",
                                                    marginTop: "-0.2rem",
                                                    cursor: "pointer",
                                                    marginLeft: "0.5rem",
                                                    marginRight: "0.5rem",
                                                }}
                                                title="Informa o objetivo de retorno mais adequado para seu cliente."
                                            />
                                            <div className="arrowSortAbc">
                                                {tableMeta.name === name ? tableMeta.direction == "asc" ? <AiOutlineArrowUp size={18} /> : <AiOutlineArrowDown size={18} /> : <></>}
                                            </div>
                                        </div>
                                    </div>
                                </TableCell>
                            </>
                        )
                    },
                    setCellProps: () => ({
                        style: {
                            paddingLeft: "0.5rem",
                            paddingRight: "0.5rem",
                        },
                    }),
                },
            },
            {
                name: "propensity_to_accept_campains",
                label: "Propensão a aceite de sugestões",
                options: {
                    filter: tabActive.current == 0 ? true : false,
                    sort: true,
                    display: tabActive.current == 0 ? true : false,
                    filterType: "custom",
                    viewColumns: false,
                    filterList: filtersList.current["propensity_to_accept_campains"],
                    customFilterListOptions: {
                        render: (v) => v,
                        update: (filterList, filterPos, index) => {
                            filterList[index].splice(filterPos, 1)
                            return filterList
                        },
                    },
                    filterOptions: {
                        logic: (location, filters, row) => {
                            if (filters.length) return !filters.includes(location)
                            return false
                        },
                        display: (filterList, onChange, index, column) => {
                            const optionValues = listSearchedClients.current.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v))
                            return (
                                <FormControl>
                                    <InputLabel htmlFor="select-multiple-chip" variant="standard">
                                        {column.label}
                                    </InputLabel>
                                    <Select
                                        multiple
                                        variant="standard"
                                        value={filterList[index]}
                                        renderValue={(selected) => selected.join(", ")}
                                        onChange={(event) => {
                                            filterList[index] = event.target.value
                                            onChange(filterList[index], index, column)
                                        }}
                                    >
                                        {optionValues.map((item) => (
                                            <MenuItem key={item} value={item}>
                                                <Checkbox color="primary" checked={filterList[index].indexOf(item) > -1} />
                                                <ListItemText primary={item} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            )
                        },
                    },
                    customBodyRender: (val, tableMeta) => {
                        return val ? val : "-"
                    },
                    customHeadRender: ({ index, name, label, ...column }, sortColumn, tableMeta) => {
                        return (
                            <>
                                <TableCell
                                    className="TableCell"
                                    onClick={() => {
                                        sortColumn(index)
                                    }}
                                    style={{
                                        whiteSpace: "nowrap",
                                    }}
                                >
                                    <div className="containerTableCellButton">
                                        <div>
                                            {label}
                                            <BsQuestionCircle
                                                color="rgb(117, 117, 117)"
                                                size={16}
                                                style={{
                                                    display: "inline-block",
                                                    verticalAlign: "middle",
                                                    marginTop: "-0.2rem",
                                                    cursor: "pointer",
                                                    marginLeft: "0.5rem",
                                                    marginRight: "0.5rem",
                                                }}
                                                title="Informa a propensão do cliente em aceitar a sugestão de oportunidade."
                                            />
                                            <div className="arrowSortAbc">
                                                {tableMeta.name === name ? tableMeta.direction == "asc" ? <AiOutlineArrowUp size={18} /> : <AiOutlineArrowDown size={18} /> : <></>}
                                            </div>
                                        </div>
                                    </div>
                                </TableCell>
                            </>
                        )
                    },
                    setCellProps: () => ({
                        style: {
                            paddingLeft: "0.5rem",
                            paddingRight: "0.5rem",
                        },
                    }),
                },
            },
            {
                name: "max_term",
                label: "Prazo Máximo",
                options: {
                    filter: tabActive.current == 0 ? true : false,
                    sort: true,
                    viewColumns: false,
                    display: tabActive.current == 0 ? true : false,
                    filterType: "custom",
                    filterList: filtersList.current["max_term"],
                    customFilterListOptions: {
                        render: (v) => v,
                        update: (filterList, filterPos, index) => {
                            filterList[index].splice(filterPos, 1)
                            return filterList
                        },
                    },
                    filterOptions: {
                        logic: (location, filters, row) => {
                            if (filters.length) return !filters.includes(location)
                            return false
                        },
                        display: (filterList, onChange, index, column) => {
                            const optionValues = listSearchedClients.current.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v))
                            return (
                                <FormControl>
                                    <InputLabel htmlFor="select-multiple-chip" variant="standard">
                                        {column.label}
                                    </InputLabel>
                                    <Select
                                        multiple
                                        variant="standard"
                                        value={filterList[index]}
                                        renderValue={(selected) => selected.join(", ")}
                                        onChange={(event) => {
                                            filterList[index] = event.target.value
                                            onChange(filterList[index], index, column)
                                        }}
                                    >
                                        {optionValues.map((item) => (
                                            <MenuItem key={item} value={item}>
                                                <Checkbox color="primary" checked={filterList[index].indexOf(item) > -1} />
                                                <ListItemText primary={item} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            )
                        },
                    },
                    customBodyRender: (val, tableMeta) => {
                        return val ? val : "-"
                    },
                    customHeadRender: ({ index, name, label, ...column }, sortColumn, tableMeta) => {
                        return (
                            <>
                                <TableCell
                                    className="TableCell"
                                    onClick={() => {
                                        sortColumn(index)
                                    }}
                                    style={{
                                        whiteSpace: "nowrap",
                                    }}
                                >
                                    <div className="containerTableCellButton">
                                        <div>
                                            {label}
                                            <BsQuestionCircle
                                                color="rgb(117, 117, 117)"
                                                size={16}
                                                style={{
                                                    display: "inline-block",
                                                    verticalAlign: "middle",
                                                    marginTop: "-0.2rem",
                                                    cursor: "pointer",
                                                    marginLeft: "0.5rem",
                                                    marginRight: "0.5rem",
                                                }}
                                                title="Informa o prazo máximo que o cliente aceita para ofertas de produtos."
                                            />
                                            <div className="arrowSortAbc">
                                                {tableMeta.name === name ? tableMeta.direction == "asc" ? <AiOutlineArrowUp size={18} /> : <AiOutlineArrowDown size={18} /> : <></>}
                                            </div>
                                        </div>
                                    </div>
                                </TableCell>
                            </>
                        )
                    },
                    setCellProps: () => ({
                        style: {
                            paddingLeft: "0.5rem",
                            paddingRight: "0.5rem",
                        },
                    }),
                },
            },
            {
                name: "necessary_liq",
                label: "Liquidez Necessária",
                options: {
                    filter: tabActive.current == 0 ? true : false,
                    sort: true,
                    sortDescFirst: true,
                    viewColumns: false,
                    display: tabActive.current == 0 ? true : false,
                    filterType: "custom",
                    filterList: filtersList.current["necessary_liq"],
                    customFilterListOptions: {
                        render: (v) => {
                            const selecteds = v.map((s) => {
                                return s + "%"
                            })
                            return selecteds
                        },
                        update: (filterList, filterPos, index) => {
                            filterList[index].splice(filterPos, 1)
                            return filterList
                        },
                    },
                    filterOptions: {
                        logic: (location, filters, row) => {
                            if (filters.length) return !filters.includes(location)
                            return false
                        },
                        display: (filterList, onChange, index, column) => {
                            const optionValues = listSearchedClients.current.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v))
                            return (
                                <FormControl>
                                    <InputLabel htmlFor="select-multiple-chip" variant="standard">
                                        {column.label}
                                    </InputLabel>
                                    <Select
                                        multiple
                                        variant="standard"
                                        value={filterList[index]}
                                        renderValue={(selected) => {
                                            const selecteds = selected.map((s) => {
                                                return s + "%"
                                            })
                                            return selecteds.join(", ")
                                        }}
                                        onChange={(event) => {
                                            filterList[index] = event.target.value
                                            onChange(filterList[index], index, column)
                                        }}
                                    >
                                        {optionValues.map((item) => (
                                            <MenuItem key={item} value={item}>
                                                <Checkbox color="primary" checked={filterList[index].indexOf(item) > -1} />
                                                <ListItemText primary={item + "%"} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            )
                        },
                    },
                    customHeadRender: ({ index, name, label, ...column }, sortColumn, tableMeta) => {
                        return (
                            <>
                                <TableCell
                                    className="TableCell"
                                    onClick={() => {
                                        sortColumn(index)
                                    }}
                                    style={{
                                        whiteSpace: "nowrap",
                                    }}
                                >
                                    <div className="containerTableCellButton">
                                        <div>
                                            {label}
                                            <BsQuestionCircle
                                                color="rgb(117, 117, 117)"
                                                size={16}
                                                style={{
                                                    display: "inline-block",
                                                    verticalAlign: "middle",
                                                    marginTop: "-0.2rem",
                                                    cursor: "pointer",
                                                    marginLeft: "0.5rem",
                                                    marginRight: "0.5rem",
                                                }}
                                                title="Informa qual a porcentagem do NET do cliente na Manchester o cliente precisa para liquidez imediata."
                                            />
                                            <div className="arrowSortAbc">
                                                {tableMeta.name === name ? tableMeta.direction == "asc" ? <AiOutlineArrowUp size={18} /> : <AiOutlineArrowDown size={18} /> : <></>}
                                            </div>
                                        </div>
                                    </div>
                                </TableCell>
                            </>
                        )
                    },
                    setCellProps: () => ({
                        style: {
                            paddingLeft: "0.5rem",
                            paddingRight: "0.5rem",
                        },
                    }),
                    customBodyRender: (val, tableMeta) => {
                        return val && parseFloat(val) ? parseFloat(val)?.toFixed(2) + "%" : "-"
                    },
                },
            },
            {
                name: "campain_base",
                label: "Base Campanhas",
                options: {
                    filter: tabActive.current == 0 ? true : false,
                    sort: true,
                    viewColumns: false,
                    display: tabActive.current == 0 ? true : false,
                    filterType: "custom",
                    filterList: filtersList.current["campain_base"],
                    customFilterListOptions: {
                        render: (v) => v,
                        update: (filterList, filterPos, index) => {
                            filterList[index].splice(filterPos, 1)
                            return filterList
                        },
                    },
                    filterOptions: {
                        logic: (location, filters, row) => {
                            if (filters.length) return !filters.includes(location)
                            return false
                        },
                        display: (filterList, onChange, index, column) => {
                            const optionValues = listSearchedClients.current.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v))
                            return (
                                <FormControl>
                                    <InputLabel htmlFor="select-multiple-chip" variant="standard">
                                        {column.label}
                                    </InputLabel>
                                    <Select
                                        multiple
                                        variant="standard"
                                        value={filterList[index]}
                                        renderValue={(selected) => selected.join(", ")}
                                        onChange={(event) => {
                                            filterList[index] = event.target.value
                                            onChange(filterList[index], index, column)
                                        }}
                                    >
                                        {optionValues.map((item) => (
                                            <MenuItem key={item} value={item}>
                                                <Checkbox color="primary" checked={filterList[index].indexOf(item) > -1} />
                                                <ListItemText primary={item} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            )
                        },
                    },
                    customBodyRender: (val, tableMeta) => {
                        const indexColumn = tableMeta.columnIndex
                        const pl_custom = tableMeta.rowData[indexColumn + 1]
                        return val ? (pl_custom ? val + " (" + toBRL(pl_custom) + ")" : val) : "-"
                    },
                    customHeadRender: ({ index, name, label, ...column }, sortColumn, tableMeta) => {
                        return (
                            <>
                                <TableCell
                                    className="TableCell"
                                    onClick={() => {
                                        sortColumn(index)
                                    }}
                                    style={{
                                        whiteSpace: "nowrap",
                                    }}
                                >
                                    <div className="containerTableCellButton">
                                        <div>
                                            {label}
                                            <BsQuestionCircle
                                                color="rgb(117, 117, 117)"
                                                size={16}
                                                style={{
                                                    display: "inline-block",
                                                    verticalAlign: "middle",
                                                    marginTop: "-0.2rem",
                                                    cursor: "pointer",
                                                    marginLeft: "0.5rem",
                                                    marginRight: "0.5rem",
                                                }}
                                                title="Informa qual a base de cálculo mais adequada para calcularmos o tamanho das oportunidades nas campanhas."
                                            />
                                            <div className="arrowSortAbc">
                                                {tableMeta.name === name ? tableMeta.direction == "asc" ? <AiOutlineArrowUp size={18} /> : <AiOutlineArrowDown size={18} /> : <></>}
                                            </div>
                                        </div>
                                    </div>
                                </TableCell>
                            </>
                        )
                    },
                    setCellProps: () => ({
                        style: {
                            paddingLeft: "0.5rem",
                            paddingRight: "0.5rem",
                        },
                    }),
                },
            },
            {
                name: "pl_custom",
                label: "PL Assessor",
                options: {
                    filter: false,
                    sort: false,
                    viewColumns: false,
                    display: false,
                },
            },
            {
                name: "ple",
                label: "PLE",
                options: {
                    filter: false,
                    viewColumns: false,
                    sort: true,
                    sortDescFirst: true,
                    display: tabActive.current == 0 ? true : false,
                    customHeadRender: ({ index, name, label, ...column }, sortColumn, tableMeta) => {
                        return (
                            <>
                                <TableCell
                                    className="TableCell"
                                    onClick={() => {
                                        sortColumn(index)
                                    }}
                                    style={{
                                        whiteSpace: "nowrap",
                                    }}
                                >
                                    <div className="containerTableCellButton">
                                        <div>
                                            {label}
                                            <BsQuestionCircle
                                                color="rgb(117, 117, 117)"
                                                size={16}
                                                style={{
                                                    display: "inline-block",
                                                    verticalAlign: "middle",
                                                    marginTop: "-0.2rem",
                                                    cursor: "pointer",
                                                    marginLeft: "0.5rem",
                                                    marginRight: "0.5rem",
                                                }}
                                                title="Informa o Patrimônio Estimado do cliente segundo base XP."
                                            />
                                            <div className="arrowSortAbc">
                                                {tableMeta.name === name ? tableMeta.direction == "asc" ? <AiOutlineArrowUp size={18} /> : <AiOutlineArrowDown size={18} /> : <></>}
                                            </div>
                                        </div>
                                    </div>
                                </TableCell>
                            </>
                        )
                    },
                    setCellProps: () => ({
                        style: {
                            paddingLeft: "0.5rem",
                            paddingRight: "0.5rem",
                        },
                    }),
                    customBodyRender: (val, tableMeta) => {
                        return val ? toBRL(val) : "-"
                    },
                },
            },
            {
                name: "knowledge",
                label: "Nível de Conhecimento",
                options: {
                    filter: tabActive.current == 0 ? true : false,
                    sort: true,
                    viewColumns: false,
                    display: tabActive.current == 0 ? true : false,
                    filterType: "custom",
                    filterList: filtersList.current["knowledge"],
                    customFilterListOptions: {
                        render: (v) => v,
                        update: (filterList, filterPos, index) => {
                            filterList[index].splice(filterPos, 1)
                            return filterList
                        },
                    },
                    filterOptions: {
                        logic: (location, filters, row) => {
                            if (filters.length) return !filters.includes(location)
                            return false
                        },
                        display: (filterList, onChange, index, column) => {
                            const optionValues = listSearchedClients.current.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v))
                            return (
                                <FormControl>
                                    <InputLabel htmlFor="select-multiple-chip" variant="standard">
                                        {column.label}
                                    </InputLabel>
                                    <Select
                                        multiple
                                        variant="standard"
                                        value={filterList[index]}
                                        renderValue={(selected) => selected.join(", ")}
                                        onChange={(event) => {
                                            filterList[index] = event.target.value
                                            onChange(filterList[index], index, column)
                                        }}
                                    >
                                        {optionValues.map((item) => (
                                            <MenuItem key={item} value={item}>
                                                <Checkbox color="primary" checked={filterList[index].indexOf(item) > -1} />
                                                <ListItemText primary={item} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            )
                        },
                    },
                    customBodyRender: (val, tableMeta) => {
                        return val ? val : "-"
                    },
                    customHeadRender: ({ index, name, label, ...column }, sortColumn, tableMeta) => {
                        return (
                            <>
                                <TableCell
                                    className="TableCell"
                                    onClick={() => {
                                        sortColumn(index)
                                    }}
                                    style={{
                                        whiteSpace: "nowrap",
                                    }}
                                >
                                    <div className="containerTableCellButton">
                                        <div>
                                            {label}
                                            <BsQuestionCircle
                                                color="rgb(117, 117, 117)"
                                                size={16}
                                                style={{
                                                    display: "inline-block",
                                                    verticalAlign: "middle",
                                                    marginTop: "-0.2rem",
                                                    cursor: "pointer",
                                                    marginLeft: "0.5rem",
                                                    marginRight: "0.5rem",
                                                }}
                                                title="Informa a o nível de conhecimento técnico do cliente."
                                            />
                                            <div className="arrowSortAbc">
                                                {tableMeta.name === name ? tableMeta.direction == "asc" ? <AiOutlineArrowUp size={18} /> : <AiOutlineArrowDown size={18} /> : <></>}
                                            </div>
                                        </div>
                                    </div>
                                </TableCell>
                            </>
                        )
                    },
                    setCellProps: () => ({
                        style: {
                            paddingLeft: "0.5rem",
                            paddingRight: "0.5rem",
                        },
                    }),
                },
            },
            {
                name: "products",
                label: "Produtos",
                options: {
                    filter: false,
                    sort: false,
                    viewColumns: false,
                    display: tabActive.current == 0 ? true : false,
                    customBodyRender: () => {
                        return ""
                    },
                    customHeadRender: ({ index, label, ...column }, sortColumn, tableMeta) => (
                        <TableCell
                            className={"expandedColumnDashAdvisor"}
                            style={{
                                position: "sticky",
                                top: "0",
                                zIndex: "100",
                                borderLeft: index === 0 ? "#494947 20px solid" : "white 5px solid",

                                backgroundColor: "white",
                            }}
                        >
                            <div
                                style={{
                                    textAlign: "center",
                                    fontWeight: "450",
                                    fontSize: "0.8rem",
                                    paddingLeft: "1rem",
                                    paddingRight: "1rem",
                                    background: "#494947",
                                    color: "white",
                                    borderTopLeftRadius: "10px",
                                    borderTopRightRadius: "10px",
                                    height: "2.7rem",
                                    marginTop: "1.5rem",
                                }}
                            >
                                <span style={{ lineHeight: "3rem" }}>{label.toUpperCase()}</span>
                                <span
                                    key={index}
                                    style={{ paddingTop: "1.5rem" }}
                                    className="expandleLsIcon"
                                    onClick={() => {
                                        changeVisibleColumns("grupo1")
                                    }}
                                >
                                    <span style={{ marginLeft: "1rem", verticalAlign: "middle" }} title={variavel.current.grupo1 == true ? "Enconder Colunas" : "Expandir Colunas"}>
                                        {variavel.current.grupo1 == true ? <AiOutlineLeftSquare size={24} color="white" /> : <AiOutlineRightSquare size={24} color="white" />}
                                    </span>
                                </span>
                            </div>
                            <div
                                className="subtitleStatusClientOnProductDash"
                                style={{
                                    backgroundColor: "#F4F4F4",
                                    marginTop: "auto",
                                }}
                            >
                                <span style={{ marginLeft: "10px", fontSize: "0.7rem" }}></span>
                                <BsQuestionCircle
                                    color="rgb(117, 117, 117)"
                                    size={16}
                                    style={{
                                        display: "inline-block",
                                        verticalAlign: "middle",
                                        marginTop: "-0.2rem",
                                        cursor: "pointer",
                                        marginLeft: "0.5rem",
                                        marginRight: "0.5rem",
                                        display: "none",
                                    }}
                                    title="Informa se o cliente pode negociar o produto."
                                />
                                <span style={{ fontSize: "0.7rem" }}></span>
                                <BsQuestionCircle
                                    color="rgb(117, 117, 117)"
                                    size={16}
                                    style={{
                                        display: "inline-block",
                                        verticalAlign: "middle",
                                        marginTop: "-0.2rem",
                                        cursor: "pointer",
                                        marginLeft: "0.5rem",
                                        marginRight: "0.5rem",
                                        display: "none",
                                    }}
                                    title="Informa se o cliente possui o produto em sua carteira."
                                />
                            </div>
                        </TableCell>
                    ),
                    setCellProps: ({ index }) => ({
                        style: {
                            whiteSpace: "nowrap",
                            padding: "0px 0rem 0px 0rem",
                            backgroundColor: "#F4F4F4",
                            positions: "relative",
                            left: "50px",
                            borderLeft: index === 0 ? "none" : "5px solid white",
                        },
                    }),
                },
            }
        )

        const listProductsPermissionsMacroLenght = listProductsPermissionsMacro.current?.length

        listProductsPermissionsMacro.current?.forEach((e, Eindex) => {

            const microLenght = listProductsPermissionsMicro?.current?.filter((m) => {
                return m.name.includes("product__" + e.name.split("__")[1])
            })

            const lenght = microLenght?.length

            visibleColumnsRef.current.push({
                name: "p" + e.id + "__permissions_id",
                label: e.description,
                options: {
                    filter: tabActive.current == 0 ? true : false,
                    sort: true,
                    viewColumns: false,
                    display: tabActive.current == 0 ? variavel.current.grupo1 : false,
                    filterType: "custom",
                    filterList: filtersList.current["objective"],
                    customFilterListOptions: {
                        render: (v) => {
                            const selecteds = v.map((s) => {
                                const selectedOption = optionsIcons2.find((option) => option.value == `${s}`)
                                return e.description + ": " + selectedOption?.title
                            })
                            return selecteds
                        },
                        update: (filterList, filterPos, index) => {
                            filterList[index].splice(filterPos, 1)
                            return filterList
                        },
                    },
                    filterOptions: {
                        logic: (location, filters, row) => {
                            if (filters.length) return !filters.includes(location)
                            return false
                        },
                        display: (filterList, onChange, index, column) => {
                            const optionValues = listSearchedClients.current.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v))
                            return (
                                <FormControl>
                                    <InputLabel htmlFor="select-multiple-chip" variant="standard">
                                        {column.label}
                                    </InputLabel>
                                    <Select
                                        multiple
                                        variant="standard"
                                        value={filterList[index]}
                                        renderValue={(selected) => {
                                            const selecteds = selected.map((s) => {
                                                const selectedOption = optionsIcons2.find((option) => option.value == `${s}`)
                                                return selectedOption?.title ? selectedOption.title : "-"
                                            })
                                            return selecteds.join(", ")
                                        }}
                                        onChange={(event) => {
                                            filterList[index] = event.target.value
                                            onChange(filterList[index], index, column)
                                        }}
                                    >
                                        {optionValues.map((item) => {
                                            const description = optionsIcons2.find((option) => option.value == `${item}`)?.title
                                            return (
                                                <MenuItem key={item} value={item}>
                                                    <Checkbox color="primary" checked={filterList[index].indexOf(item) > -1} />
                                                    <ListItemText primary={description} />
                                                </MenuItem>
                                            )
                                        })}
                                    </Select>
                                </FormControl>
                            )
                        },
                    },
                    customBodyRender: (val, tableMeta) => {
                        const currentColumn = tableMeta.columnIndex

                        //a coluna que mostra se o cliente tem ou nao tem o produto fica logo após a coluna atual
                        var nextColumn = tableMeta.rowData[currentColumn + 1]
                        var selectedOption = optionsIcons2.find((option) => option.value == `${val}`)
                        var nextColumnSelectedOption = optionsIcons2.find((option) => option.value == (val == "2" ? (nextColumn ? "2" : "5") : nextColumn ? "2" : "3"))

                        return (
                            <>
                                <span className=" permissionsIconDashAdvisor" title={selectedOption?.label ? selectedOption.title : optionsIcons2[0].title}>
                                    {selectedOption?.label ? selectedOption.label : optionsIcons2[0].label}
                                </span>
                                <span
                                    className=" permissionsIconDashAdvisor"
                                    title={nextColumn != null ? (nextColumnSelectedOption?.label ? nextColumnSelectedOption.title2 : optionsIcons2[0].title2) : "Não Consta"}
                                >
                                    {nextColumn != null ? (nextColumnSelectedOption?.label ? nextColumnSelectedOption?.label : optionsIcons2[0].label) : "N/C"}
                                </span>
                            </>
                        )
                    },
                    setCellProps: ({ index }) => ({
                        style: {
                            whiteSpace: "nowrap",
                            padding: "0px 0rem 0px 0rem",
                            backgroundColor: "#F4F4F4",
                            positions: "relative",
                            left: "50px",
                            borderLeft: index === 0 ? "none" : "5px solid white",
                        },
                    }),
                    customHeadRender: ({ index, label, name, ...column }, sortColumn, tableMeta) => (
                        <TableCell
                            key={index}
                            className={
                                Eindex == 0 ? "expandedColumnDashAdvisorLeft" : listProductsPermissionsMacroLenght - 1 == Eindex ? "expandedColumnDashAdvisorRight" : "expandedColumnDashAdvisor"
                            }
                            style={{
                                position: "sticky",
                                zIndex: "100",
                                top: "0px",
                                borderLeft: index === 0 ? "#494947 20px solid" : "white 5px solid",

                                backgroundColor: "white",
                            }}
                        >
                            <div
                                onClick={() => sortColumn(index)}
                                style={{
                                    textAlign: "center",
                                    fontWeight: "450",
                                    fontSize: "0.8rem",
                                    paddingLeft: "1rem",
                                    paddingRight: "1rem",
                                    background: "#494947",
                                    color: "white",
                                    borderTopLeftRadius: "10px",
                                    borderTopRightRadius: "10px",
                                    height: "2.7rem",
                                    marginTop: "1.5rem",
                                }}
                            >
                                <span key={index} style={{ paddingTop: "1.5rem" }} className="expandleLsIcon">
                                    <span style={{ lineHeight: "3rem" }}>{label.toUpperCase()}</span>
                                    {lenght > 0 ? (
                                        <span
                                            onClick={(e) => {
                                                changeVisibleColumns(`grupo${Eindex + 2}`)
                                                e.stopPropagation()
                                            }}
                                            style={{ marginLeft: "1rem", verticalAlign: "middle" }}
                                            title={variavel.current[`grupo${Eindex + 2}`] == true ? "Enconder Colunas" : "Expandir Colunas"}
                                        >
                                            {variavel.current[`grupo${Eindex + 2}`] == true ? <AiOutlineLeftSquare size={24} color="white" /> : <AiOutlineRightSquare size={24} color="white" />}
                                        </span>
                                    ) : null}
                                </span>
                            </div>
                            <div
                                className="subtitleStatusClientOnProductDash"
                                style={{
                                    backgroundColor: "#F4F4F4",
                                    marginTop: "auto",
                                }}
                            >
                                <span style={{ marginLeft: "10px", fontSize: "0.7rem" }}>PODE</span>
                                <BsQuestionCircle
                                    color="rgb(117, 117, 117)"
                                    size={16}
                                    style={{
                                        display: "inline-block",
                                        verticalAlign: "middle",
                                        marginTop: "-0.2rem",
                                        cursor: "pointer",
                                        marginLeft: "0.5rem",
                                        marginRight: "0.5rem",
                                    }}
                                    title="Informa se o cliente pode negociar o produto."
                                />
                                <span style={{ fontSize: "0.7rem" }}>POSSUI</span>
                                <BsQuestionCircle
                                    color="rgb(117, 117, 117)"
                                    size={16}
                                    style={{
                                        display: "inline-block",
                                        verticalAlign: "middle",
                                        marginTop: "-0.2rem",
                                        cursor: "pointer",
                                        marginLeft: "0.5rem",
                                        marginRight: "0.5rem",
                                    }}
                                    title="Informa se o cliente possui o produto em sua carteira."
                                />
                            </div>
                        </TableCell>
                    ),
                },
            })

            visibleColumnsRef.current.push({
                name: "p" + e.id + "__custody",
                label: "Custodia",
                options: {
                    filter: false,
                    viewColumns: false,
                    sort: false,
                    display: false,
                },
            })

            microLenght?.forEach((m, mIndex) => {
                visibleColumnsRef.current.push({
                    name: "p" + m.id + "__permissions_id",
                    label: m.description.toUpperCase(),
                    options: {
                        filter: tabActive.current == 0 ? true : false,
                        viewColumns: false,
                        sort: true,
                        display: tabActive.current == 0 ? variavel.current.grupo1 && variavel.current[`grupo${Eindex + 2}`] : false,
                        filterType: "custom",
                        filterList: filtersList.current["objective"],
                        customFilterListOptions: {
                            render: (v) => {
                                const selecteds = v.map((s) => {
                                    const selectedOption = optionsIcons2.find((option) => option.value == `${s}`)
                                    return m.description + ": " + selectedOption?.title
                                })
                                return selecteds
                            },
                            update: (filterList, filterPos, index) => {
                                filterList[index].splice(filterPos, 1)
                                return filterList
                            },
                        },
                        filterOptions: {
                            logic: (location, filters, row) => {
                                if (filters.length) return !filters.includes(location)
                                return false
                            },
                            display: (filterList, onChange, index, column) => {
                                const optionValues = listSearchedClients.current.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v))
                                return (
                                    <FormControl>
                                        <InputLabel htmlFor="select-multiple-chip" variant="standard">
                                            {column.label}
                                        </InputLabel>
                                        <Select
                                            multiple
                                            variant="standard"
                                            value={filterList[index]}
                                            renderValue={(selected) => {
                                                const selecteds = selected.map((s) => {
                                                    const selectedOption = optionsIcons2.find((option) => option.value == `${s}`)
                                                    return selectedOption?.title ? selectedOption.title : "-"
                                                })
                                                return selecteds.join(", ")
                                            }}
                                            onChange={(event) => {
                                                filterList[index] = event.target.value
                                                onChange(filterList[index], index, column)
                                            }}
                                        >
                                            {optionValues.map((item) => {
                                                const description = optionsIcons2.find((option) => option.value == `${item}`)?.title
                                                return (
                                                    <MenuItem key={item} value={item}>
                                                        <Checkbox color="primary" checked={filterList[index].indexOf(item) > -1} />
                                                        <ListItemText primary={description} />
                                                    </MenuItem>
                                                )
                                            })}
                                        </Select>
                                    </FormControl>
                                )
                            },
                        },
                        customBodyRender: (val, tableMeta) => {
                            const currentColumn = tableMeta.columnIndex
                            const selectedOption = optionsIcons2.find((option) => option.value == `${val}`)
                            //a coluna que mostra se o cliente tem ou nao tem o produto fica logo após a coluna atual
                            var nextColumn = tableMeta.rowData[currentColumn + 1]
                            var nextColumnSelectedOption = optionsIcons2.find((option) => option.value == (val == "2" ? (nextColumn ? "2" : "5") : nextColumn ? "2" : "3"))

                            return (
                                <>
                                    <span className=" permissionsIconDashAdvisor" title={selectedOption?.label ? selectedOption.title : optionsIcons2[0].title}>
                                        {selectedOption?.label ? selectedOption.label : optionsIcons2[0].label}
                                    </span>
                                    <span
                                        className=" permissionsIconDashAdvisor"
                                        title={nextColumn != null ? (nextColumnSelectedOption?.label ? nextColumnSelectedOption.title2 : optionsIcons2[0].title2) : "Não Consta"}
                                    >
                                        {nextColumn != null ? (nextColumnSelectedOption?.label ? nextColumnSelectedOption?.label : optionsIcons2[0].label) : "N/C"}
                                    </span>
                                </>
                            )
                        },
                        setCellProps: ({ index }) => ({
                            style: {
                                whiteSpace: "nowrap",
                                padding: "0px 0rem 0px 0rem",
                                backgroundColor: "#F4F4F4",
                                positions: "relative",
                                left: "50px",
                                borderLeft: index === 0 ? "none" : "5px solid white",
                            },
                        }),

                        customHeadRender: ({ index, label, ...column }, sortColumn, tableMeta) => (
                            <TableCell
                                key={index}
                                className={
                                    Eindex == 0 ? "expandedColumnDashAdvisorLeft" : listProductsPermissionsMacroLenght - 1 == Eindex ? "expandedColumnDashAdvisorRight" : "expandedColumnDashAdvisor"
                                }
                                style={{ position: "sticky", zIndex: "100", top: "0px", borderLeft: index === 0 ? "#494947 20px solid" : "white 5px solid", backgroundColor: "white" }}
                            >
                                <div
                                    onClick={() => sortColumn(index)}
                                    style={{
                                        textAlign: "center",
                                        fontWeight: "450",
                                        fontSize: "0.8rem",
                                        paddingLeft: "1rem",
                                        paddingRight: "1rem",
                                        background: "#6b6b67",
                                        color: "white",
                                        borderTopLeftRadius: "10px",
                                        borderTopRightRadius: "10px",
                                        height: "2.7rem",
                                        marginTop: "1.5rem",
                                    }}
                                >
                                    <span style={{ lineHeight: "3rem" }}>{label.split(":")[1].toUpperCase()}</span>
                                </div>
                                <div
                                    className="subtitleStatusClientOnProductDash"
                                    style={{
                                        backgroundColor: "#F4F4F4",
                                        marginTop: "auto",
                                    }}
                                >
                                    <span style={{ marginLeft: "10px", fontSize: "0.7rem" }}>PODE</span>
                                    <BsQuestionCircle
                                        color="rgb(117, 117, 117)"
                                        size={16}
                                        style={{
                                            display: "inline-block",
                                            verticalAlign: "middle",
                                            marginTop: "-0.2rem",
                                            cursor: "pointer",
                                            marginLeft: "0.5rem",
                                            marginRight: "0.5rem",
                                        }}
                                        title="Informa se o cliente pode negociar o produto."
                                    />
                                    <span style={{ fontSize: "0.7rem" }}>POSSUI</span>
                                    <BsQuestionCircle
                                        color="rgb(117, 117, 117)"
                                        size={16}
                                        style={{
                                            display: "inline-block",
                                            verticalAlign: "middle",
                                            marginTop: "-0.2rem",
                                            cursor: "pointer",
                                            marginLeft: "0.5rem",
                                            marginRight: "0.5rem",
                                        }}
                                        title="Informa se o cliente possui o produto em sua carteira."
                                    />
                                </div>
                            </TableCell>
                        ),
                    },
                })

                visibleColumnsRef.current.push({
                    name: "p" + m.id + "__custody",
                    label: "Custodia",
                    options: {
                        filter: false,
                        viewColumns: false,
                        sort: false,
                        display: false,
                    },
                })
            })
        })

        //cria uma copia de listProducts
        let listProductsCopy = listProducts?.current ? [...listProducts?.current] : listProducts?.current

        //reordena o listProducts de forma que selectedCampaign fique sempre em primeiro
        if (selectedCampaign) {
            // columnsToDisplay?.current.find((x) => x.column === "c" + selectedCampaign?.id + "__objective").display = true
            // columnsToDisplay?.current.find((x) => x.column === "c" + selectedCampaign?.id + "__value").display = true
            // columnsToDisplay?.current.find((x) => x.column === "c" + selectedCampaign?.id + "__receita_estimada").display = true
            // columnsToDisplay?.current.find((x) => x.column === "c" + selectedCampaign?.id + "__status").display = true

            // localStorage.setItem("columnsDash", JSON.stringify(columnsToDisplay?.current))

            listProductsCopy?.sort((a, b) => {
                if (a.id == selectedCampaign?.id) return -1
                if (b.id == selectedCampaign?.id) return 1
                return 0
            })
        }

        const today = new Date();
        today.setHours(0, 0, 0, 0)
        today.setDate(today.getDate() + 1);
        //marca a campanha como visivel
        // sort no listProductsCopy com campanhas de prioridade 3 e pela data final colocar no final das campnhas
        const listProductsCopyAtivas = listProductsCopy?.filter((e) => {
            const dateFinishCampains = new Date(e.finish)
            dateFinishCampains.setHours(0, 0, 0, 0)
            dateFinishCampains.setDate(dateFinishCampains.getDate() + 2);

            return today <= dateFinishCampains
        }
        )
        const listProductsCopyEncerradas = listProductsCopy?.filter((e) => {
            const dateFinishCampains = new Date(e.finish)
            dateFinishCampains.setHours(0, 0, 0, 0)
            dateFinishCampains.setDate(dateFinishCampains.getDate() + 2);

            return today > dateFinishCampains
        }
        )

        // sort no listProductsCopy com campanhas de prioridade 3 e pela data final colocar no final das campnhas, campanhas de prioridade 1 e 2 devem ficar no topo da lista
        listProductsCopyAtivas?.sort((a, b) => {
            if (a.priority == 3 && b.priority != 3) return 1
            if (a.priority != 3 && b.priority == 3) return -1
            if (a.priority == 3 && b.priority == 3) {
                if (a.finish > b.finish) return 1
                if (a.finish < b.finish) return -1
                return 0
            }
            return 0
        })
        //campanhas encerradas sao sempre prioridade 3
        listProductsCopyEncerradas?.sort((a, b) => {
            if (a.finish < b.finish) return 1
            if (a.finish > b.finish) return -1
            return 0
        })
        //concatena as campanhas ativas com as encerradas
        listProductsCopy = listProductsCopyAtivas?.concat(listProductsCopyEncerradas)

        listProductsCopy?.sort((a, b) => {
            // Coloca a campanha selecionada em primeiro lugar
            if (a.id === selectedCampaign?.id) return -1;
            if (b.id === selectedCampaign?.id) return 1;

    
        });


        

       

        listProductsCopy?.forEach((e) => {
            const produtoX = e.description.toUpperCase()
            const priorityAux = campaignPriority.current?.find((p) => p.id == e.priority)
            const isFirstColumnVisible = columnVisibility["e.description.toUpperCase()"]
            const today = new Date();
            const tomorrow = new Date();
            const dateFinishCampains = new Date(e.finish)
            
            tomorrow.setDate(today.getDate());
            tomorrow.setHours(0, 0, 0, 0)
            dateFinishCampains.setHours(0, 0, 0, 0)
            dateFinishCampains.setDate(dateFinishCampains.getDate() + 1);

            visibleColumnsRef.current.push(
                {
                    name: "c" + e.id + "__objective",
                    group: "c" + e.id,
                    label: produtoX,
                    options: {
                        filter: false,
                        sort: false,
                        sortDescFirst: true,
                        display: tabActive.current == 1 ? columnsToDisplay.current?.some(x => x.column === ("c" + e.id + "__objective") && x.display) : false,
                        setCellProps: () => ({
                            style: {
                                whiteSpace: "nowrap",
                                padding: "0px 0rem 0px 0rem",
                                borderLeft: "0.5rem solid white",
                                backgroundColor: "#fffcef",
                            },
                        }),
                        customHeadRender: ({ index, name, ...column }, sortColumn, tableMeta) => (
                            <TableCell className="leftTesteAbc">
                                <span
                                    className="containerCellNameProductDash"
                                    style={{
                                        marginRight: "0px",
                                        height: "2.7rem",
                                        paddingTop: "0.5rem",
                                        backgroundColor: selectedCampaign?.id == e.id ? "#D4AC00" : "#fffcef",
                                        display: "grid",
                                        gridTemplateColumns: "auto auto",
                                        borderTopLeftRadius: "10px",
                                    }}
                                >
                                    <div className="productNameDash" style={{ paddingLeft: "1rem" }}>
                                        {separarLinhas(e)}
                                    </div>
                                </span>
                                <span
                                    className="headerProductCellBoxAdvisor"
                                    style={{
                                        marginRight: "0px",
                                        backgroundColor: "#fffcef",
                                        cursor: "pointer",
                                    }}
                                    onClick={() => {
                                        sortColumn(index)
                                    }}
                                >
                                    <div className="subtitleCellBoxDash">
                                        <div className="subtitleObjectiveAbcDash" style={{ marginLeft: "0.5rem" }}>
                                            Oportunidade
                                        </div>
                                        <div className="subtitleCellBoxDashSum">
                                            <span style={{ color: "green" }}>
                                                {" "}
                                                {toBRLInteger(sum.current["c" + e.id]?.planned_value ? sum.current["c" + e.id]?.planned_value : 0)}{" "}
                                            </span>
                                            /
                                            <span style={{ color: "rgb(212, 172, 0)" }}>
                                                {" "}
                                                {toBRLInteger(sum.current["c" + e.id]?.oportunity ? sum.current["c" + e.id]?.oportunity : 0)}{" "}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="arrowSortAbc">{tableMeta.name === name ? tableMeta.direction == "asc" ? <AiOutlineArrowUp size={18} /> : <AiOutlineArrowDown size={18} /> : <></>}</div>
                                </span>
                            </TableCell>
                        ),
                        customBodyRender: (val, tableMeta) => {
                            var aux = listClients.current.find((x) => x.client_id == tableMeta.rowData[0])
                            return (
                                <div
                                    onClick={(w) => {
                                        w.stopPropagation()
                                        setType({
                                            name: "detailsClient",
                                            client: aux,
                                            products: listProducts.current,
                                            product: e,
                                            prioritys: campaignPriority.current,
                                            doSum: doSum,
                                        })
                                        setShow(true)
                                    }}
                                    style={{ width: "100%", height: "100%" }}
                                >
                                    <span style={{ color: "green" }}>{aux["c" + e.id + "__planned_value"] ? toBRLInteger(aux["c" + e.id + "__planned_value"]) : '-'}</span>{' '}/{' '}
                                    <span style={{ color: "rgb(212, 172, 0)" }}>{aux["c" + e.id + "__objective"] ? toBRLInteger(aux["c" + e.id + "__objective"]) : '-'}</span>
                                </div>
                            )
                        },
                    },
                },
                {
                    name: "c" + e.id + "__value",
                    group: "c" + e.id,
                    label: produtoX,
                    options: {
                        viewColumns: false,
                        filter: false,
                        sortDescFirst: true,
                        display: tabActive.current == 1 ? columnsToDisplay.current?.some(x => x.column === ("c" + e.id + "__value") && x.display) : false,
                        setCellProps: () => ({
                            style: {
                                whiteSpace: "nowrap",
                                padding: "0px 0.0rem 0px 0.0rem",
                                backgroundColor: "#fffcef",
                            },
                        }),
                        customHeadRender: ({ index, name, ...column }, sortColumn, tableMeta) => (
                            <TableCell className="testeAbc">
                                <span
                                    className="containerCellProductDash"
                                    style={{ marginRight: "0px", height: "2.7rem", paddingTop: "0.5rem", backgroundColor: selectedCampaign?.id == e.id ? "#D4AC00" : "#fffcef" }}
                                >
                                    <span title={priorityAux?.value} style={{ marginTop: "0.5rem" }}>
                                        {e.priority == 1 ? <FaStar color="rgb(255, 195, 0)" size={20} /> : e.priority == 2 ? <BsFire color="red" size={20} /> : null}
                                    </span>
                                </span>

                                <div
                                    className="headerProductCellBoxAdvisor"
                                    style={{
                                        marginRight: "0px",
                                        marginLeft: "0px",
                                        backgroundColor: "#fffcef",
                                        cursor: "pointer",
                                    }}
                                    onClick={() => {
                                        sortColumn(index)
                                    }}
                                >
                                    <div className="subtitleCellBoxDash">
                                        <div className="subtitleObjectiveAbc">Realizado</div>
                                        <div className="subtitleCellBoxDashSum">{toBRLInteger(sum.current["c" + e.id]?.done ? sum.current["c" + e.id].done : 0)}</div>
                                    </div>
                                    {
                                        <div className="arrowSortAbc">
                                            {tableMeta.name === name ? tableMeta.direction == "asc" ? <AiOutlineArrowUp size={18} /> : <AiOutlineArrowDown size={18} /> : <></>}
                                        </div>
                                    }
                                </div>
                            </TableCell>
                        ),
                        customBodyRender: (val, tableMeta) => {
                            // const columnIndex = tableMeta.columnIndex
                            // const realizado = tableMeta.rowData[columnIndex - 1]
                            // const objetivo = tableMeta.rowData[columnIndex - 2]
                            // const status = tableMeta.rowData[columnIndex + 1]
                            // const fee = e.fee

                            return (
                                <div
                                    onClick={(w) => {
                                        w.stopPropagation()
                                        var aux = listClients.current.find((x) => x.client_id == tableMeta.rowData[0])
                                        setType({
                                            name: "detailsClient",
                                            client: aux,
                                            products: listProducts.current,
                                            product: e,
                                            prioritys: campaignPriority.current,
                                            doSum: doSum,
                                        })
                                        setShow(true)
                                    }}
                                    style={{ width: "100%", height: "100%" }}
                                >
                                    {val != null ? toBRLInteger(val) : "-"}
                                    {/* {(status !== 'RECUSADO' && status !== 'NÃO ADERENTE' && !val  && status !== 'CANCELADO') ? (
                                        realizado > 0 ? (
                                            <span style={{ color: "green" }}>{toBRLInteger(fee * realizado)}</span>
                                        ) : objetivo > 0 ? (
                                        <span style={{ color: "rgb(212, 172, 0)" }}>{toBRLInteger(fee * objetivo)}</span>
                                        )   :
                                        (
                                            "-"
                                        )
                                    ) : (
                                        "-"
                                    )} */}
                                </div>
                            )
                        },
                    },
                },
                {
                    name: "c" + e.id + "__receita_estimada",
                    group: "c" + e.id,
                    // label: "Realizado",
                    label: produtoX,
                    options: {
                        viewColumns: false,
                        filter: false,
                        sortDescFirst: true,
                        display: tabActive.current == 1 ? columnsToDisplay.current?.some(x => x.column === ("c" + e.id + "__receita_estimada") && x.display) : false,
                        setCellProps: () => ({
                            style: {
                                whiteSpace: "nowrap",
                                padding: "0px 0.0rem 0px 0.0rem",
                                backgroundColor: "#fffcef",
                            },
                        }),
                        customHeadRender: ({ index, name, ...column }, sortColumn, tableMeta, val) => (
                            <TableCell className="testeAbc">
                                <span
                                    className="containerCellProductDash"
                                    style={{ marginRight: "0px", height: "2.7rem", paddingTop: "0.5rem", backgroundColor: selectedCampaign?.id == e.id ? "#D4AC00" : "#fffcef", display: "flex", flexDirection: 'row', gap: '1rem' }}
                                >
                                    <div
                                        className="statusDivDash"
                                        // className="statusDivDashCopy"
                                        onClick={(j) => {
                                            j.stopPropagation()
                                            var aux = listClients.current.filter((x) => x["c" + e.id + "__operation_id"] != null)
                                            setType({
                                                name: "details",
                                                client: aux[0],
                                                product: e,
                                                clients: listClients.current,
                                                prioritys: campaignPriority.current,
                                                doSum: doSum,
                                            })

                                            setShow(true)
                                        }}
                                        style={{ marginTop: "0.5rem" }}
                                    >

                                        <div className="textStatusAbc">Acessar Detalhes </div>
                                        {/* <div 
                                            className="textStatusAbc detailscampangeDash" 
                                            style={{ 
                                                background: selectedCampaign?.id === null ? '#D4AC00' : selectedCampaign?.id === e.id ? '#414141' : '#D4AC00' , 
                                                color: selectedCampaign?.id === null ? '#414141' : selectedCampaign?.id === e.id ? '#fff' : '#414141',
                                                borderRadius:'10px', 
                                                padding:'6px', 
                                                transition:'0.2s' }}
                                        >
                                                Acessar Detalhes 
                                        </div> */}
                                    </div>
                                    <Link
                                        to={{
                                            pathname: "/baterBase",
                                            state: {
                                                product: e,
                                            },
                                        }}
                                        style={{ marginTop: '0.5rem', marginRight: '1rem', color: 'green' }}
                                    >
                                        <span style={{ marginTop: '0.5rem', marginRight: '1rem' }}>Bater Base</span>
                                    </Link>
                                </span>
                                <div
                                    className="headerProductCellBoxAdvisor"
                                    style={{
                                        marginRight: "0px",
                                        marginLeft: "0px",
                                        backgroundColor: "#fffcef",
                                        cursor: "pointer",
                                    }}
                                    onClick={() => {
                                        sortColumn(index)
                                    }}
                                >
                                    <div className="subtitleCellBoxDash">
                                        <div className="subtitleObjectiveAbc">Receita Estimada</div>
                                        <div className="subtitleCellBoxDashSum">
                                            <span style={{ color: "green" }}>
                                                {" "}
                                                {toBRLInteger(sum.current["c" + e.id]?.receitaRe /*  && (String(val).toUpperCase) !== "RECUSADO"  */ ? sum.current["c" + e.id].receitaRe : 0)}{" "}
                                            </span>
                                            /
                                            <span style={{ color: "rgb(212, 172, 0)" }}>
                                                {" "}
                                                {toBRLInteger(sum.current["c" + e.id]?.receitaOp /*  && (String(val).toUpperCase) !== "RECUSADO"  */ ? sum.current["c" + e.id].receitaOp : 0)}{" "}
                                            </span>
                                        </div>
                                    </div>
                                    {
                                        <div className="arrowSortAbc">
                                            {tableMeta.name === name ? tableMeta.direction == "asc" ? <AiOutlineArrowUp size={18} /> : <AiOutlineArrowDown size={18} /> : <></>}
                                        </div>
                                    }
                                </div>
                            </TableCell>
                        ),
                        customBodyRender: (val, tableMeta) => {
                            const columnIndex = tableMeta.columnIndex
                            const realizado = tableMeta.rowData[columnIndex - 1]
                            const objetivo = tableMeta.rowData[columnIndex - 2]
                            const status = tableMeta.rowData[columnIndex + 1]
                            const fee = e.fee
                            return (
                                <div
                                    onClick={(w) => {
                                        w.stopPropagation()
                                        var aux = listClients.current.find((x) => x.client_id == tableMeta.rowData[0])
                                        setType({
                                            name: "detailsClient",
                                            client: aux,
                                            products: listProducts.current,
                                            product: e,
                                            prioritys: campaignPriority.current,
                                            doSum: doSum,
                                        })
                                        setShow(true)
                                    }}
                                    style={{ width: "100%", height: "100%" }}
                                >
                                    {status !== "RECUSADO" && status !== "NÃO ADERENTE" && !val && status !== "CANCELADO" ? (
                                        realizado > 0 ? (
                                            <span style={{ color: "green" }}>{toBRLInteger(fee * realizado)}</span>
                                        ) : objetivo > 0 ? (
                                            <span style={{ color: "rgb(212, 172, 0)" }}>{toBRLInteger(fee * objetivo)}</span>
                                        ) : (
                                            "-"
                                        )
                                    ) : (
                                        "-"
                                    )}
                                </div>
                            )
                        },
                    },
                },
                {
                    name: "c" + e.id + "__status",
                    group: "c" + e.id,
                    label: produtoX,
                    options: {
                        viewColumns: false,
                        filter: false,
                        sort: false,
                        display: tabActive.current == 1 ? columnsToDisplay.current?.some(x => x.column === ("c" + e.id + "__status") && x.display) : false,
                        setCellProps: () => ({
                            style: {
                                whiteSpace: "nowrap",
                                padding: "0px 0.0rem 0px 0.0rem",
                                backgroundColor: "#fffcef",
                            },
                        }),
                        customHeadRender: ({ index, name, ...column }, sortColumn, tableMeta) => {
                            const priorityAux = campaignPriority.current?.find((p) => p.id == e.priority)

                            return (
                                <TableCell className="testeAbc">
                                    <span
                                        className="containerCellProductDash"
                                        style={{
                                            marginRight: "0px",
                                            height: "1.7rem",
                                            backgroundColor: selectedCampaign?.id == e.id ? "#D4AC00" : "#fffcef",
                                            borderTopRightRadius: "10px",
                                            height: "2.7rem",
                                            paddingTop: "0.5rem",
                                        }}
                                    >
                                        <span style={{ marginLeft: "auto", marginRight: "auto" }} title={priorityAux?.value}>
                                            <div
                                                className="statusDivDash"
                                                onClick={(j) => {
                                                    j.stopPropagation()
                                                    setType({
                                                        name: "status",
                                                        data: name,
                                                        listFilterStatusProducts: listFilterStatusProducts,
                                                        listSearchedClients: listSearchedClients,
                                                        listAllClients: clientsResult.current.table,
                                                        listFilteredClients: listFilteredClients,
                                                        listClients: listClients,
                                                        filtersList: filtersList,
                                                        atualizador: atualizadorModalStatus,
                                                        description: e.description,
                                                        linesCount: linesCount,
                                                        positions: positions,
                                                        doSum: doSum,
                                                    })
                                                    setShow(true)
                                                }}
                                                style={{ marginTop: "0.5rem" }}
                                            >
                                                <div className="containerIconStatusAbc" style={{ height: "1.5rem", marginLeft: "0.3rem" }}>
                                                    <RiEqualizerFill size={12} className="iconStatusAbc" />
                                                </div>
                                            </div>
                                        </span>
                                    </span>
                                    <div
                                        className="headerProductCellBoxAdvisor"
                                        style={{
                                            marginRight: "0px",
                                            marginLeft: "0px",
                                            backgroundColor: "#fffcef",
                                            cursor: "pointer",
                                        }}
                                        onClick={() => {
                                            sortColumn(index)
                                        }}
                                    >
                                        <div className="subtitleCellBoxDash">
                                            <div className="subtitleObjectiveAbc">
                                                <div className="textStatusAbc" style={{ paddingRight: "0.5rem" }}>
                                                    Status
                                                </div>
                                                <div style={{ height: "1.5rem" }}></div>
                                            </div>
                                        </div>
                                        <div className="arrowSortAbc">
                                            {tableMeta.name === name ? tableMeta.direction == "asc" ? <AiOutlineArrowUp size={18} /> : <AiOutlineArrowDown size={18} /> : <></>}
                                        </div>
                                    </div>
                                </TableCell>
                            )
                        },
                        customBodyRender: (val, tableMeta) => {
                            var statusColor =
                                String(val).toUpperCase() == "PENDENTE"
                                    ? "#D4AC00"
                                    : String(val).toUpperCase() == "ABORDADO"
                                        ? "#6B94DE"
                                        : String(val).toUpperCase() == "ACEITO"
                                            ? "#c084fc"
                                            : String(val).toUpperCase() == "BOLETADO"
                                                ? "#58B935"
                                                : String(val).toUpperCase() == "CANCELADO"
                                                    ? "#FF83FE"
                                                    : String(val).toUpperCase() == "RECUSADO"
                                                        ? "#f76a6a"
                                                        : String(val).toUpperCase() == "NÃO ADERENTE"
                                                            ? "#000000"
                                                            : String(val).toUpperCase() == "ANÁLISE DO BACKOFFICE"
                                                                ? "#2BC3BB"
                                                                : "#8C34BA"

                            return val ? (
                                <div
                                    className="statusColorDivAbc"
                                    title={titleize(String(val))}
                                    onClick={(w) => {
                                        w.stopPropagation()
                                        var aux = listClients.current.find((x) => x.client_id == tableMeta.rowData[0])
                                        setType({
                                            name: "detailsClient",
                                            client: aux,
                                            products: listProducts.current,
                                            product: e,
                                            prioritys: campaignPriority.current,
                                            doSum: doSum,
                                        })
                                        setShow(true)
                                    }}
                                    style={{ width: "100%", height: "100%" }}
                                >
                                    <BsFillSquareFill color={statusColor} className="statusColorDivAbc" title={titleize(String(val))} />
                                </div>
                            ) : (
                                "-"
                            )
                        },
                    },
                }
            )

            // visibleColumnsRef.current.push(
            //     {
            //     name: "c" + e.id + "__value",
            //     group: "c" + e.id ,
            //     label: produtoX,
            //     options: {
            //         filter: false,
            //         sortDescFirst: true,
            //         display: tabActive.current != 0 ? true : false,
            //         setCellProps: () => ({
            //             style: {
            //                 whiteSpace: "nowrap",
            //                 padding: "0px 0.0rem 0px 0.0rem",
            //                 backgroundColor: "#fffcef",
            //             },
            //         }),
            //         customHeadRender: ({ index, name, ...column }, sortColumn, tableMeta) => (
            //             <TableCell className="testeAbc">
            //                 <span
            //                     className="containerCellProductDash"
            //                     style={{ marginRight: "0px", height: "2.7rem", paddingTop: "0.5rem", backgroundColor: selectedCampaign?.id == e.id ? "#D4AC00" : "#fffcef" }}
            //                 >
            //                     <span title={priorityAux?.value} style={{ marginTop: "0.5rem" }}>
            //                         {e.priority == 1 ? <FaStar color="rgb(255, 195, 0)" size={20} /> : e.priority == 2 ? <BsFire color="red" size={20} /> : null}
            //                     </span>
            //                 </span>

            //                 <div
            //                     className="headerProductCellBoxAdvisor"
            //                     style={{
            //                         marginRight: "0px",
            //                         marginLeft: "0px",
            //                         backgroundColor: "#fffcef",
            //                         cursor: "pointer",
            //                     }}
            //                     onClick={() => {
            //                         sortColumn(index)
            //                     }}
            //                 >
            //                     <div className="subtitleCellBoxDash">
            //                         <div className="subtitleObjectiveAbc">Realizado</div>
            //                         <div className="subtitleCellBoxDashSum">{toBRLInteger(sum.current["c" + e.id]?.done ? sum.current["c" + e.id].done : 0)}</div>
            //                     </div>
            //                     {
            //                         <div className="arrowSortAbc">
            //                             {tableMeta.name === name ? tableMeta.direction == "asc" ? <AiOutlineArrowUp size={18} /> : <AiOutlineArrowDown size={18} /> : <></>}
            //                         </div>
            //                     }
            //                 </div>
            //             </TableCell>
            //         ),
            //         customBodyRender: (val, tableMeta) => {
            //             // const columnIndex = tableMeta.columnIndex
            //             // const realizado = tableMeta.rowData[columnIndex - 1]
            //             // const objetivo = tableMeta.rowData[columnIndex - 2]
            //             // const status = tableMeta.rowData[columnIndex + 1]
            //             // const fee = e.fee

            //             return (
            //                 <div
            //                     onClick={(w) => {
            //                         w.stopPropagation()
            //                         var aux = listClients.current.find((x) => x.client_id == tableMeta.rowData[0])
            //                         setType({
            //                             name: "detailsClient",
            //                             client: aux,
            //                             products: listProducts.current,
            //                             product: e,
            //                             prioritys: campaignPriority.current,
            //                             doSum: doSum,
            //                         })
            //                         setShow(true)
            //                     }}
            //                     style={{ width: "100%", height: "100%" }}
            //                 >
            //                     {val != null ? toBRLInteger(val) : "-"}
            //                     {/* {(status !== 'RECUSADO' && status !== 'NÃO ADERENTE' && !val  && status !== 'CANCELADO') ? (
            //                         realizado > 0 ? (
            //                             <span style={{ color: "green" }}>{toBRLInteger(fee * realizado)}</span>
            //                         ) : objetivo > 0 ? (
            //                         <span style={{ color: "rgb(212, 172, 0)" }}>{toBRLInteger(fee * objetivo)}</span>
            //                         )   :
            //                         (
            //                             "-"
            //                         )
            //                     ) : (
            //                         "-"
            //                     )} */}
            //                 </div>
            //             )
            //         },
            //     },
            // })

            // visibleColumnsRef.current.push({
            //     name: "c" + e.id + "__receita_estimada",
            //     group: "c" + e.id ,
            //     // label: "Realizado",
            //     label: produtoX,
            //     options: {
            //         filter: false,
            //         sortDescFirst: true,
            //         display: tabActive.current != 0 ? true : false,
            //         setCellProps: () => ({
            //             style: {
            //                 whiteSpace: "nowrap",
            //                 padding: "0px 0.0rem 0px 0.0rem",
            //                 backgroundColor: "#fffcef",
            //             },
            //         }),
            //         customHeadRender: ({ index, name, ...column }, sortColumn, tableMeta, val) => (
            //             <TableCell className="testeAbc">
            //                 <span
            //                     className="containerCellProductDash"
            //                     style={{ marginRight: "0px", height: "2.7rem", paddingTop: "0.5rem", backgroundColor: selectedCampaign?.id == e.id ? "#D4AC00" : "#fffcef" }}
            //                 >
            //                     <div
            //                         className="statusDivDash"
            //                         // className="statusDivDashCopy"
            //                         onClick={(j) => {
            //                             j.stopPropagation()
            //                             var aux = listClients.current.filter((x) => x["c" + e.id + "__operation_id"] != null)
            //                             setType({
            //                                 name: "details",
            //                                 client: aux[0],
            //                                 product: e,
            //                                 clients: listClients.current,
            //                                 prioritys: campaignPriority.current,
            //                                 doSum: doSum,
            //                             })

            //                             setShow(true)
            //                         }}
            //                         style={{ marginTop: "0.5rem" }}
            //                     >
            //                         <div className="textStatusAbc">Acessar Detalhes </div>
            //                         {/* <div
            //                             className="textStatusAbc detailscampangeDash"
            //                             style={{
            //                                 background: selectedCampaign?.id === null ? '#D4AC00' : selectedCampaign?.id === e.id ? '#414141' : '#D4AC00' ,
            //                                 color: selectedCampaign?.id === null ? '#414141' : selectedCampaign?.id === e.id ? '#fff' : '#414141',
            //                                 borderRadius:'10px',
            //                                 padding:'6px',
            //                                 transition:'0.2s' }}
            //                         >
            //                                 Acessar Detalhes
            //                         </div> */}
            //                     </div>
            //                 </span>

            //                 <div
            //                     className="headerProductCellBoxAdvisor"
            //                     style={{
            //                         marginRight: "0px",
            //                         marginLeft: "0px",
            //                         backgroundColor: "#fffcef",
            //                         cursor: "pointer",
            //                     }}
            //                     onClick={() => {
            //                         sortColumn(index)
            //                     }}
            //                 >
            //                     <div className="subtitleCellBoxDash">
            //                         <div className="subtitleObjectiveAbc">Receita Estimada</div>
            //                         <div className="subtitleCellBoxDashSum">
            //                          <span style={{ color: "green" }}> {toBRLInteger(sum.current["c" + e.id]?.receitaRe/*  && (String(val).toUpperCase) !== "RECUSADO"  */? sum.current["c" + e.id].receitaRe : 0)} </span>
            //                           /
            //                             <span style={{ color: "rgb(212, 172, 0)" }}> {toBRLInteger(sum.current["c" + e.id]?.receitaOp /*  && (String(val).toUpperCase) !== "RECUSADO"  */ ? sum.current["c" + e.id].receitaOp : 0)} </span>
            //                          </div>
            //                     </div>
            //                     {
            //                         <div className="arrowSortAbc">
            //                             {tableMeta.name === name ? tableMeta.direction == "asc" ? <AiOutlineArrowUp size={18} /> : <AiOutlineArrowDown size={18} /> : <></>}
            //                         </div>
            //                     }
            //                 </div>
            //             </TableCell>
            //         ),
            //         customBodyRender: (val, tableMeta) => {
            //             const columnIndex = tableMeta.columnIndex
            //             const realizado = tableMeta.rowData[columnIndex - 1]
            //             const objetivo = tableMeta.rowData[columnIndex - 2]
            //             const status = tableMeta.rowData[columnIndex + 1]
            //             const fee = e.fee
            //             return (
            //                 <div
            //                     onClick={(w) => {
            //                         w.stopPropagation()
            //                         var aux = listClients.current.find((x) => x.client_id == tableMeta.rowData[0])
            //                         setType({
            //                             name: "detailsClient",
            //                             client: aux,
            //                             products: listProducts.current,
            //                             product: e,
            //                             prioritys: campaignPriority.current,
            //                             doSum: doSum,
            //                         })
            //                         setShow(true)
            //                     }}
            //                     style={{ width: "100%", height: "100%" }}
            //                 >
            //                     {(status !== 'RECUSADO' && status !== 'NÃO ADERENTE' && !val  && status !== 'CANCELADO') ? (
            //                         realizado > 0 ? (
            //                             <span style={{ color: "green" }}>{toBRLInteger(fee * realizado)}</span>
            //                         ) : objetivo > 0 ? (
            //                         <span style={{ color: "rgb(212, 172, 0)" }}>{toBRLInteger(fee * objetivo)}</span>
            //                         )   :
            //                         (
            //                             "-"
            //                         )
            //                     ) : (
            //                         "-"
            //                     )}

            //                 </div>
            //             )
            //         },
            //     },
            // })

            // visibleColumnsRef.current.push({
            //     name: "c" + e.id + "__status",
            //     group: "c" + e.id ,
            //     label: produtoX,
            //     options: {
            //         filter: false,
            //         sort: false,
            //         display: tabActive.current != 0 ? true : false,
            //         setCellProps: () => ({
            //             style: {
            //                 whiteSpace: "nowrap",
            //                 padding: "0px 0.0rem 0px 0.0rem",
            //                 backgroundColor: "#fffcef",
            //             },
            //         }),
            //         customHeadRender: ({ index, name, ...column }, sortColumn, tableMeta) => {
            //             const priorityAux = campaignPriority.current?.find((p) => p.id == e.priority)

            //             return (
            //                 <TableCell className="testeAbc">
            //                     <span
            //                         className="containerCellProductDash"
            //                         style={{
            //                             marginRight: "0px",
            //                             height: "1.7rem",
            //                             backgroundColor: selectedCampaign?.id == e.id ? "#D4AC00" : "#fffcef",
            //                             borderTopRightRadius: "10px",
            //                             height: "2.7rem",
            //                             paddingTop: "0.5rem",
            //                         }}
            //                     >
            //                         <span style={{ marginLeft: "auto", marginRight: "auto" }} title={priorityAux?.value}>
            //                             <div
            //                                 className="statusDivDash"
            //                                 onClick={(j) => {
            //                                     j.stopPropagation()
            //                                     setType({
            //                                         name: "status",
            //                                         data: name,
            //                                         listFilterStatusProducts: listFilterStatusProducts,
            //                                         listSearchedClients: listSearchedClients,
            //                                         listAllClients: clientsResult.current.table,
            //                                         listFilteredClients: listFilteredClients,
            //                                         listClients: listClients,
            //                                         filtersList: filtersList,
            //                                         atualizador: atualizadorModalStatus,
            //                                         description: e.description,
            //                                         linesCount: linesCount,
            //                                         positions: positions,
            //                                         doSum: doSum,
            //                                     })
            //                                     setShow(true)
            //                                 }}
            //                                 style={{ marginTop: "0.5rem" }}
            //                             >
            //                                 <div className="containerIconStatusAbc" style={{ height: "1.5rem", marginLeft: "0.3rem" }}>
            //                                     <RiEqualizerFill size={12} className="iconStatusAbc" />
            //                                 </div>
            //                             </div>
            //                         </span>
            //                     </span>
            //                     <div
            //                         className="headerProductCellBoxAdvisor"
            //                         style={{
            //                             marginRight: "0px",
            //                             marginLeft: "0px",
            //                             backgroundColor: "#fffcef",
            //                             cursor: "pointer",
            //                         }}
            //                         onClick={() => {
            //                             sortColumn(index)
            //                         }}
            //                     >
            //                         <div className="subtitleCellBoxDash">
            //                             <div className="subtitleObjectiveAbc">
            //                                 <div className="textStatusAbc" style={{ paddingRight: "0.5rem" }}>
            //                                     Status
            //                                 </div>
            //                                 <div style={{ height: "1.5rem" }}></div>
            //                             </div>
            //                         </div>
            //                         <div className="arrowSortAbc">
            //                             {tableMeta.name === name ? tableMeta.direction == "asc" ? <AiOutlineArrowUp size={18} /> : <AiOutlineArrowDown size={18} /> : <></>}
            //                         </div>
            //                     </div>
            //                 </TableCell>
            //             )
            //         },
            //         customBodyRender: (val, tableMeta) => {
            //             var statusColor =
            //                 String(val).toUpperCase() == "PENDENTE"
            //                     ? "#D4AC00"
            //                     : String(val).toUpperCase() == "ABORDADO"
            //                     ? "#6B94DE"
            //                     : String(val).toUpperCase() == "ACEITO"
            //                     ? "#c084fc"
            //                     : String(val).toUpperCase() == "BOLETADO"
            //                     ? "#58B935"
            //                     : String(val).toUpperCase() == "CANCELADO"
            //                     ? "#FF83FE"
            //                     : String(val).toUpperCase() == "RECUSADO"
            //                     ? "#f76a6a"
            //                     : String(val).toUpperCase() == "NÃO ADERENTE"
            //                     ? "#000000"
            //                     : String(val).toUpperCase() == "ANÁLISE DO BACKOFFICE"
            //                     ? "#2BC3BB"

            //                     : "#8C34BA"

            //             return val ? (
            //                 <div
            //                     className="statusColorDivAbc"
            //                     title={titleize(String(val))}
            //                     onClick={(w) => {
            //                         w.stopPropagation()
            //                         var aux = listClients.current.find((x) => x.client_id == tableMeta.rowData[0])
            //                         setType({
            //                             name: "detailsClient",
            //                             client: aux,
            //                             products: listProducts.current,
            //                             product: e,
            //                             prioritys: campaignPriority.current,
            //                             doSum: doSum,
            //                         })
            //                         setShow(true)
            //                     }}
            //                     style={{ width: "100%", height: "100%" }}
            //                 >
            //                     <BsFillSquareFill color={statusColor} className="statusColorDivAbc" title={titleize(String(val))} />
            //                 </div>
            //             ) : (
            //                 "-"
            //             )
            //         },
            //     },
            // })
        })
    }
    const AddButton = () => (
        <>
            {user.type < 3 && tabActive.current == 1 ? (
                <Tooltip disableFocusListener title="Gerenciar Campanhas" style={{ marginTop: "0.6rem" }}>
                    <Link
                        to={{
                            pathname: "/abcManagement",
                        }}
                        onClick={() => {
                            localStorage.setItem("lastVisitedPage", "dashAdvisor")
                        }}
                    >
                        {"Gerenciar Campanhas"}
                    </Link>
                </Tooltip>
            ) : (
                <></>
            )}
            {tabActive.current == 1 ? (
                <Tooltip disableFocusListener title="Copiar para a área de transferência">
                    <IconButton onClick={copyToClipboard}>
                        <ContentCopyIcon />
                    </IconButton>
                </Tooltip>
            ) : (
                <></>
            )}
        </>
    )

    function doSum() {
        listProducts?.current?.forEach((p) => {
            sum.current["c" + p.id] = { oportunity: 0, planned_value: 0, done: 0, receita: 0, receitaOp: 0, receitaRe: 0 }
        })
        listProducts?.current?.forEach((p) => {
            listFilteredClients?.current?.forEach((c) => {
                if (c["c" + p.id + "__objective"] !== null) {
                    sum.current["c" + p.id].oportunity += c["c" + p.id + "__objective"]
                }

                if (c["c" + p.id + "__planned_value"] !== null) {
                    sum.current["c" + p.id].planned_value += c["c" + p.id + "__planned_value"]
                }
                if (c["c" + p.id + "__status"] !== "RECUSADO" && c["c" + p.id + "__status"] !== "NÃO ADERENTE" && c["c" + p.id + "__status"] !== "CANCELADO") {


                    if (c["c" + p.id + "__value"] !== null) {
                        sum.current["c" + p.id].done += c["c" + p.id + "__value"]
                    }

                    sum.current["c" + p.id].receitaOp = sum.current["c" + p.id].oportunity * p.fee
                    sum.current["c" + p.id].receitaRe = sum.current["c" + p.id].done * p.fee
                }
            })
        })

        //soma os Nets
        sumNet.current = 0
        listFilteredClients?.current.forEach((c) => {
            sumNet.current += c["net_total"]
        })

        sumRoa.current = 0
        listFilteredClients?.current.forEach((c) => {
            sumRoa.current += c["roa"]
        })
        sumRoa.current = sumRoa.current / listFilteredClients?.current.length

    }


    const options = {
        filterType: "multiselect",
        filter: tabActive.current == 0 || ((user?.is_leader > 0 || user.type < 3) || user.type == 4 && tabActive.current == 1) ? true : false,
        download: true,
        print: false,
        responsive: "vertical",
        customSearchRender: debounceSearchRender(500),
        tableBodyHeight: `${alturaTabela}px`,
        selectableRowsHideCheckboxes: true,
        selectToolbarPlacement: "replace",
        selectableRows: "multiple",
        viewColumns: tabActive.current == 0 ? false : true,
        rowsPerPage: rowsPerPage,
        rowsPerPageOptions: [50, 150, 500],
        customToolbar: AddButton,
        search: true,
        textLabels: {
            body: {
                noMatch: loadingTable ? <ArrowLoader /> : "Não foram encontrados dados",
                toolTip: "Ordenar",
                columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
            },
            pagination: {
                next: "Próxima",
                previous: "Anterior",
                rowsPerPage: "Linhas por página:",
                displayRows: "de",
            },
            toolbar: {
                search: "Pesquisar",
                downloadCsv: "Download XLSX",
                print: "Imprimir",
                viewColumns: "Ver colunas",
                filterTable: "Filtrar",
            },
            filter: {
                all: "Todos",
                title: "Filtros",
                reset: "Limpar",
            },
            viewColumns: {
                title: "Mostrar Colunas",
                titleAria: "Mostrar/Ocultar colunas",
            },
            selectedRows: {
                text: "linha(s) selecionadas",
                delete: "Excluir",
                deleteAria: "Excluir linhas Selecionadas",
            },
        },
        downloadOptions: {
            filename: "abc.csv",
            separator: ";",
        },
        // onDownload: (buildHead, buildBody, columns, values) => {
        //     if (tabActive.current == 0) {
        //         configurationExport()
        //         return false
        //     } else {
        //         exportDash()
        //         return false
        //     }
        // },
        onDownload: (buildHead, buildBody, columns, values) => {
            var json = []
            if (tabActive.current == 1) {
                exportDash()
                return false
                const colunas = columns.filter((e) => e.display == "true")

                // pega os dados da lista listSearchedClients.current para as colunas visiveis (colunas)
                json = listSearchedClients.current.map((row) => {
                    const temp = {}
                    colunas.forEach((coluna) => {
                        temp[coluna.label] = row[coluna.name] ? row[coluna.name] : ""
                    })
                    return temp
                })
            } else if (tabActive.current == 0) {
                // configurationExport()
                const colunas = columns.filter((e) => e.display == "true" || e.name.indexOf("__permission") > -1)

                json = listSearchedClients.current.map((row) => {
                    const temp = {}
                    colunas.forEach((coluna) => {
                        if (coluna.name.indexOf("__permission") > -1) {
                            const custody = coluna.name.replace("__permissions_id", "__custody")
                            var selectedOption = optionsIcons2.find((option) => option.value == `${row[coluna.name]}`)?.title
                            var possui = row[custody] ? (row[custody] > 0 ? "Sim" : "Não") : "N/C"
                            temp[coluna.label + " - PODE"] = row[coluna.name] ? selectedOption : "N/C"
                            temp[coluna.label + " - POSSUI"] = possui
                        } else {
                            temp[coluna.label] = row[coluna.name] ? row[coluna.name] : ""
                            if (coluna.name == "fee_fixo_comission") temp[coluna.label] = row[coluna.name] ? row[coluna.name] * 100 : "-"
                        }
                    })
                    return temp
                })
            }

            const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8"
            const fileExtension = ".xlsx"
            const fileName = tabActive.current == 0 ? `Configurações Clientes` : `Campanhas`
            const ws = utils.json_to_sheet(json)
            const wb = { Sheets: { data: ws }, SheetNames: ["data"] }
            const excelBuffer = write(wb, { bookType: "xlsx", type: "array" })
            const data = new Blob([excelBuffer], { type: fileType })
            saveAs(data, fileName + fileExtension)
            return false
        },
        setTableProps: () => {
            return {
                padding: "none",
                size: "small",
            }
        },
        onFilterChange: (column, list, e, i) => {
            if (search !== "") filtersList.current["search"] = search
            if (i !== null) {
                if (!filtersList.current.hasOwnProperty(column)) {
                    filtersList.current[column] = []
                }
                filtersList.current[column] = list[i]
            } else {
                for (const property in filtersList.current) {
                    filtersList.current[property] = []
                }
            }
            listFilterStatusProducts.current[column] = filtersList.current[column]

            visibleColumnsRef.current.map((e) => {
                if (e.name == column) {
                    e.options.filterList = filtersList.current[column]
                }
            })

            props = null

            var currentTable = tabActive.current == 0 ? configuration.current.table : clientsResult.current.table

            //fazer o search
            var filteredClientsTemp = []
            if (search != "" && search != undefined) {
                const chavesSelecionadas = Array.from(
                    // FAST SEARCH
                    new Set(
                        visibleColumnsRef.current
                            .filter((objeto) => objeto.options.display === true) // filtra somente os objetos com display = true
                            .map((objeto) => objeto.name) // extrai somente os valores da coluna
                    )
                )

                //filtra apenas pelas colunas visiveis no momento
                filteredClientsTemp = currentTable.filter(
                    (row) =>
                        Object.values(Object.fromEntries(Object.entries(row).filter(([chave, valor]) => chavesSelecionadas.includes(chave))))
                            .join("|")
                            .toLowerCase()
                            .indexOf(String(search)) > -1
                )
                listSearchedClients.current = filteredClientsTemp
            } else {
                listSearchedClients.current = currentTable
                filteredClientsTemp = currentTable
            }

            currentTable = filteredClientsTemp

            // // LISTA DE CLIENTE - necesaria para o filtro de status do produto ser apagado ao clicar sobre o filtro setado
            listFilteredClients.current = currentTable.filter((row) => {
                var exists = true
                for (const property in filtersList.current) {
                    if (property !== "search") exists = filtersList.current[property].length === 0 ? exists : filtersList.current[property].includes(row[property]) ? exists : false
                }
                return exists
            })

            listClients.current = listFilteredClients.current.slice(0, rowsPerPage + 501)
            linesCount.current = listFilteredClients.current.length
            doSum()
            positions.current.currentPage = 0
            forceUpdate()
        },
        onViewColumnsChange: (changedColumn, action) => {
            let viewColumns = []

            // Em caso de clicar em "mostrar todas"
            if (changedColumn == null) {
                visibleColumnsRef.current.forEach((e, index) => {
                    if (e.name === "codigo" || e.name === "nome") {
                        viewColumns.push({ column: e.name, display: true })
                    } else {
                        // Apenas a primeira coluna de cada grupo deve ser filtrável
                        const isFilterable = e.name.endsWith("c" + e.id + "__objective")
                        viewColumns.push({ column: e.name, display: isFilterable, index: index })
                    }
                })
                localStorage.setItem("columnsDash", JSON.stringify(viewColumns))
                setColumnsLocal()
                return
            }
            if (changedColumn != null) {
                // Atualizando a visibilidade da coluna e de seu grupo
                visibleColumnsRef.current.forEach((column) => {
                    if (column.name === changedColumn || (column.group && column.group === changedColumn)) {
                        // Se a coluna alterada for a primeira do grupo, atualize a visibilidade de todo o grupo
                        const isFirstColumnOfGroup = column.name.endsWith("__objective")
                        column.options.display = action === "remove" ? false : true
                        if (isFirstColumnOfGroup) {
                            // Atualize a visibilidade de todas as colunas do grupo
                            visibleColumnsRef.current.forEach((col) => {
                                if (col.group === column.group) {
                                    col.options.display = column.options.display
                                    if (selectedCampaign && col.group.includes(selectedCampaign.id.toString())) {
                                        col.options.display = true
                                    }
                                }
                            })
                        }
                    }
                })
            }

            // Atualizando as preferências no localStorage
            visibleColumnsRef.current.forEach((column) => {
                viewColumns.push({ column: column.name, display: column.options.display })
            })

            localStorage.setItem("columnsDash", JSON.stringify(viewColumns))
            setColumnsLocal()
            forceUpdate()
        },

        onFilterDialogOpen: () => {
            forceUpdate()
        },
        onTableChange: (a, b) => {
            doSum()
            if (tableChanged.current) {
                tableChanged.current = false
                const column = b.sortOrder.name
                const order = b.sortOrder.direction
                lastSortOrder.current.name = column
                lastSortOrder.current.direction = order
                if (listFilteredClients.current.length > 1) {
                    var orderedList = []

                    orderedList = listFilteredClients.current.sort((a, b) => {
                        let fa = a[column] == null ? "-" : a[column]
                        let fb = b[column] == null ? "-" : b[column]

                        if (typeof fa === "string" || typeof fb === "string") {
                            fa = String(fa).toLowerCase().trim()
                            fb = String(fb).toLowerCase().trim()
                        }

                        return order === "asc" ? (fa < fb ? -1 : fa > fb ? 1 : 0) : fa < fb ? 1 : fa > fb ? -1 : 0
                    })

                    listFilteredClients.current = orderedList
                    listSearchedClients.current = orderedList
                    listClients.current = orderedList.slice(0, rowsPerPage + 501)
                    setLastPage(0)
                    setCurrentPage(0)
                    positions.current.currentPage = 0
                    forceUpdate()
                }
            }
        },
        onSearchChange: (searchText) => {
            searchText = searchText === null ? "" : searchText
            setSearch(searchText)
            filtersList.current["search"] = searchText

            props = null

            var currentTable = tabActive.current == 0 ? configuration.current.table : clientsResult.current.table

            //fazer o search
            var filteredClientsTemp = []
            if (searchText != "" && searchText != undefined) {
                const chavesSelecionadas = Array.from(
                    // FAST SEARCH

                    new Set(
                        visibleColumnsRef.current
                            .filter((objeto) => objeto.options.display === true) // filtra somente os objetos com display = true
                            .map((objeto) => objeto.name) // extrai somente os valores da coluna
                    )
                )

                //filtra apenas pelas colunas visiveis no momento
                filteredClientsTemp = currentTable.filter(
                    (row) =>
                        Object.values(Object.fromEntries(Object.entries(row).filter(([chave, valor]) => chavesSelecionadas.includes(chave))))
                            .join("|")
                            .toLowerCase()
                            .indexOf(String(searchText)) > -1
                )
                listSearchedClients.current = filteredClientsTemp
            } else {
                listSearchedClients.current = currentTable
                filteredClientsTemp = currentTable
            }

            currentTable = filteredClientsTemp

            // // LISTA DE CLIENTE - necesaria para o filtro de status do produto ser apagado ao clicar sobre o filtro setado
            listFilteredClients.current = currentTable.filter((row) => {
                var exists = true
                for (const property in filtersList.current) {
                    if (property !== "search") exists = filtersList.current[property].length === 0 ? exists : filtersList.current[property].includes(row[property]) ? exists : false
                }
                return exists
            })

            listClients.current = listFilteredClients.current.slice(0, rowsPerPage + 501)

            linesCount.current = listFilteredClients.current.length
            forceUpdate()
            doSum()
            positions.current.currentPage = 0
            return false
        },
        onRowClick: (rowData, rowMeta) => {
            const clientData = listClients.current.find((e) => e.client_id == rowData[0])
            if (tabActive.current == 0) {
                setType({
                    name: "modalConfigEdit",
                    data: clientData,
                    listProductsPermissionsMacro: listProductsPermissionsMacro,
                    listProductsPermissionsMicro: listProductsPermissionsMicro,
                    listCampaignsPermissions: listCampaignsPermissions,
                    configuration: optionsConfiguration.current,
                })
                setShow(true)
            } else {
                setType({
                    name: "detailsClient",
                    client: clientData,
                    products: listProducts.current,
                    product: listProducts.current[0],
                    prioritys: campaignPriority.current,
                    doSum: doSum,
                })
                setShow(true)
            }
        },
        customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage, textLabels) => {
            if (tabActive.current == 1) {
                return (
                    <CustomFooter
                        {...props}
                        count={linesCount.current}
                        page={positions.current.currentPage}
                        rowsPerPage={rowsPerPage}
                        changeRowsPerPage={changeRowsPerPage}
                        changePage={changePage}
                        textLabels={textLabels}
                    />
                )
            } else {
                return (
                    <CustomFooterConfig
                        {...props}
                        count={linesCount.current}
                        page={page}
                        rowsPerPage={rowsPerPage}
                        changeRowsPerPage={changeRowsPerPage}
                        changePage={changePage}
                        textLabels={textLabels}
                        optionsIcons={optionsIcons2}
                    />
                )
            }
        },
        onChangePage: (atualPage) => {
            setCurrentPage(atualPage)
            positions.current.currentPage = atualPage
            if (atualPage > lastPage) {
                listClients.current = listClients.current.concat(listFilteredClients.current.slice(listClients.current.length, listClients.current.length + rowsPerPage))
                setLastPage(atualPage)
            }
        },
        onChangeRowsPerPage: (rows) => {
            listClients.current = listFilteredClients.current.slice(0, rows + 1)
            positions.current.rowsPerPage = rows
            positions.current.currentPage = 0
            localStorage.setItem("positionabc", JSON.stringify(positions.current))
            setRowsPerPage(rows)
            setLastPage(0)
            setCurrentPage(0)
        },
        onColumnSortChange: (column, order) => {
            lastSortOrder.current.name = column
            lastSortOrder.current.direction = order
            if (listFilteredClients.current.length > 1) {
                var orderedList = []

                orderedList = listFilteredClients.current.sort((a, b) => {
                    let fa = a[column] == null ? "-" : a[column]
                    let fb = b[column] == null ? "-" : b[column]

                    if (typeof fa === "string" || typeof fb === "string") {
                        fa = String(fa).toLowerCase().trim()
                        fb = String(fb).toLowerCase().trim()
                    }

                    return order === "asc" ? (fa < fb ? -1 : fa > fb ? 1 : 0) : fa < fb ? 1 : fa > fb ? -1 : 0
                })

                listFilteredClients.current = orderedList
                listSearchedClients.current = orderedList
                listClients.current = orderedList.slice(0, rowsPerPage + 501)
                setLastPage(0)
                setCurrentPage(0)
                positions.current.currentPage = 0
                forceUpdate()
            }
        },
    }

    const optionsIcons2 = [
        { value: "1", title2: "Indefinido", title: "Indefinido", label: <FaQuestion size={"20"} color="orange" />, icon: FaQuestion, color: "orange" },
        { value: "2", title2: "Possui", title: "Permitido", label: <FaCheck size={"20"} color="green" />, icon: FaCheck, color: "green" },
        { value: "3", title2: "Não possui", title: "Negado", label: <FaTimes size={"20"} color="red" />, icon: FaTimes, color: "red" },
        // { value: "4", title2: "Sem perfil", title: "Sem perfil", label: <FaUserSlash size={"20"} color="black" />, icon: FaUserSlash, color: "black" },
        { value: "5", title2: "Oportunidade", title: "Oportunidade", label: <FaStar size={"20"} color="#FFC300" />, icon: FaStar, color: "#FFC300" },
    ]

    async function setConfigTable() {
        listClients.current = []
        setLoadingTable(true)
        while (!configuration.current.table) {
            await new Promise((resolve) => setTimeout(resolve, 1000))
        }
        listFilteredClients.current = configuration.current.table
        listSearchedClients.current = configuration.current.table
        listClients.current = configuration.current.table.slice(0, rowsPerPage + 501)
        linesCount.current = configuration.current.table.length
        assembleTable()
        forceUpdate()
        setLoadingTable(false)
    }
    const CustomToView = (props) => {
        return <TableViewColDash {...props} ocultColumnsToView={columnsExceptionView} visibleColumnsRef={visibleColumnsRef.current} productsList={listProducts.current} />;
    };
    // const [columnsExceptionViewCampains, setColumnsExceptionViewCampains] = useState([]);
    // const CustomToViewCampains = (props) => {
    //     return <TableViewColDash {...props} ocultColumnsToView={columnsExceptionViewCampains} visibleColumnsRef={visibleColumnsRef.current} productsList={listProducts.current} />;
    // }


    useEffect(() => {
        if(areasAlocacaoCampanhas?.length > 0) {
            areasAlocacaoCampanhas?.forEach((area) => {
                listProducts?.current?.forEach((product) => {
                    if(product?.area?.includes(area?.description) && !areasFiltered?.includes(area)) {
                        areasFiltered?.push(area)
                    }
                })
            })
        }
    }, [areasAlocacaoCampanhas, listProducts.current])

    useEffect(() => {
        const delayInputTimeoutId = setTimeout(() => {
          setDebouncedInputValue(searchArea);
        }, 500);
        return () => clearTimeout(delayInputTimeoutId);
      }, [searchArea, 500]);

    return (
        user.token !== "" && (
            <>
                <div
                    className="dashboardContainer"
                    style={{
                        zoom: `${window.innerHeight <= 850 && window.devicePixelRatio < 1.2
                                ? (1 / window.devicePixelRatio) * 0.7
                                : window.innerHeight <= 600 && window.devicePixelRatio > 1.2
                                    ? (1 / window.devicePixelRatio) * 0.7
                                    : 1 / window.devicePixelRatio
                            }`,
                    }}
                >
                    <div className="dashboardRightSide">
                        <div className="dashboardRightSideContentAdvisor">
                            {user.type != 3 ?
                                <div className="campaignsContainer">
                                    {loading ? (
                                        <ArrowLoader />
                                    ) : (
                                        //ENVIA COMO PARÂMETRO PARA O COMPONENTE DE CARROSEL DE CAMPANHAS
                                        //selectedCampaign: campanha selecionada (mande a unica caso só exista uma)
                                        //setSelectedCampaign: função para setar a campanha selecionada
                                        //listProducts: lista de produtos
                                        //campaignPriority: lista de prioridades que existem (para icones)
                                        <>
                                            <CampainsCarrousel
                                                selectedCampaign={selectedCampaign}
                                                setSelectedCampaign={setSelectedCampaign}
                                                listProducts={filteredCampains}
                                                campaignPriority={campaignPriority}
                                                searchArea={debouncedInputValue}
                                            />
                                            <div style={{ display: 'grid', gridTemplateColumns: '1fr 4fr', justifyItems:'center' }}>
                                                <div style={{display: "flex", flexDirection:"column", gap:'0.5rem', justifyContent: 'center'}}>
                                                    <Tooltip title='Filtrar por Area' arrow>
                                                        <div>

                                                                <FaFilter
                                                                    onClick={() => {
                                                                        setFilterAreaOpen(!filterAreaOpen)
                                                                        setFilterSearchOpen(false)
                                                                    }} 
                                                                    style={{ 
                                                                        cursor: 'pointer',
                                                                        padding: '0.75rem',
                                                                        borderRadius: '0.5rem',
                                                                        boxShadow: '0px 0px 5px',
                                                                        background: '#f1f5f9', 
                                                                        
                                                                    }} />
                                                            </div>
                                                        </Tooltip>
                                                    {filterAreaOpen ?
                                                        <Fade in={filterAreaOpen == true} style={{ }} >
                                                            <form
                                                                ref={filterAreaRef}
                                                                style={{
                                                                    position: 'absolute',
                                                                    zIndex: '1000',
                                                                    background: 'white',
                                                                    padding: '0.5rem 0.5rem 0.5rem 1rem',
                                                                    borderRadius: '0.5rem',
                                                                    boxShadow:' -1px 2px 5px',
                                                                    top:'190px',
                                                                    transition: 'top 0.5s',
                                                                }}
                                                            >
                                                                
                                                                <FormGroup
                                                                >

                                                                    <div style={{display:'flex', justifyContent:'space-between', flexDirection: 'row', marginBottom:'1rem', alignItems:"center"}}>
                                                                        <span>Filtrar por Area:</span>
                                                                        <AiFillCloseCircle style={{cursor:'pointer',  right: '10px',}} onClick={() => setFilterAreaOpen(false)} color="#C29C00" size={24} />
                                                                    </div> 
                                                                    {
                                                                        areasFiltered.map((area, index) => (

                                                                            <FormControlLabel
                                                                                key={index}
                                                                                label={area.description}
                                                                                control={
                                                                                    <CheckboxComponent
                                                                                        key={index}
                                                                                        value={area.description}
                                                                                        label={area.description}
                                                                                        fieldName='areas'
                                                                                        checked={listProducts.current.some((product) => {
                                                                                            if(product.area.includes(area.description)) {
                                                                                                return product.display
                                                                                            }
                                                                                        })}
                                                                                        defaultChecked={
                                                                                            listProducts.current.some((product) => {
                                                                                                if(product.area.includes(area.description)) {
                                                                                                    return product.display
                                                                                                }
                                                                                            })
                                                                                        }
                                                                                    />
                                                                                }
                                                                                onChange={(e) => {
                                                                                    listProducts.current.forEach((product) => {
                                                                                        if (product.area.includes(e.target.value)) {
                                                                                            product.display = !product.display
                                                                                            forceUpdate()
                                                                                        }
                                                                                    })
                                                                                }}
                                                                            />

                                                                        ))
                                                                    }
                                                                </FormGroup>
                                                                {/* </FormControl>   */}
                                                                <div style={{display:"flex", justifyContent:'center'}}>

                                                                <Button
                                                                    styleType={'success'}
                                                                    type='submit'
                                                                    onClick={(e) => {
                                                                        filteredCampains.current = cloneListProducts.filter((product) => product.display)
                                                                        setFilterAreaOpen(false)
                                                                        
                                                                    }}
                                                                    >
                                                                    Filtrar
                                                                </Button>
                                                                </div>
                                                            </form>
                                                        </Fade>
                                                        : <></>

                                                    }
                                                    {/* <Tooltip title='Pesquisar Campanhas' arrow>
                                                        <div>
                                                            <FaSearch 
                                                                disabled={"disabled" }
                                                                onClick={() => {
                                                                    // setFilterSearchOpen(!filterSearchOpen)
                                                                    setFilterAreaOpen(false)
                                                                }} 
                                                                style={{ 
                                                                    cursor: 'no-drop',
                                                                    padding: '0.75rem',
                                                                    borderRadius: '0.5rem',
                                                                    boxShadow: '0px 0px 5px',
                                                                    background: '#f1f5f9', 
                                                                    
                                                                }} />
                                                        </div>
                                                    </Tooltip>
                                                    {
                                                        filterSearchOpen ? 
                                                        <form
                                                            style={{
                                                                position: 'absolute',
                                                                zIndex: '1000',
                                                                background: 'white',
                                                                padding: '0.5rem 0.5rem',
                                                                borderRadius: '0.5rem',
                                                                boxShadow:' -1px 2px 5px',
                                                                top:'250px'
                                                                
                                                            }}
                                                        >
                                                            <div style={{display:'flex', justifyContent:'space-between', alignItems:"center" }}>
                                                                     
                                                                    <div>
                                                                        Pesquisar Campanhas
                                                                    </div>
                                                                    <AiFillCloseCircle style={{cursor:'pointer'}} onClick={() => setFilterSearchOpen(false)} color="#C29C00" size={24} />
                                                                </div> 
                                                            <TextField
                                                                style={{width:'100%'}}
                                                                variant="outlined"
                                                                value={searchArea}
                                                                onChange={handleInputChange}
                                                            />

                                                        </form>
                                                        : <></>
                                                    } */}
                                                </div>
                                                <div className="actionsDash" style={{border:'none'}}>
                                                    <div></div>
                                                    <div>
                                                        <button
                                                            style={{
                                                                background: "white",
                                                                color: "white",
                                                                display: "flex",
                                                                flexDirection: "column",
                                                                alignItems: "center",
                                                                border: "2px solid rgb(194, 156, 0)",
                                                            }}
                                                            className="dashButton"
                                                            disabled={loading ? "disabled" : ""}
                                                        // title="Em construção"
                                                        // onClick={() => {
                                                        //     setType({ name: "settlementCalendar", data: listaClientesPorProduto, d: 0 })
                                                        //     setShow(true)
                                                        // }}
                                                        >
                                                            <div style={{ paddingLeft: "5px", textAlign: "center", color: "black", fontWeight: "bold" }}>Calendário de Liquidações</div>
                                                            <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", justifyItems: "flex-start" }}>
                                                                <div style={{ display: "flex", flexDirection: "row" }}>
                                                                    <div style={{ color: "black" }}><FiAlertTriangle />  Em manutenção!  <FiAlertTriangle /></div>
                                                                    {/* <button
                                                                    className="buttond0"
                                                                    disabled={loading ? "disabled" : ""}
                                                                    title={
                                                                        clientsSettlements?.d0 > 0
                                                                            ? clientsSettlements.d0 == 1
                                                                                ? clientsSettlements.d0 + " cliente a ser liquidado em D+0."
                                                                                : clientsSettlements.d0 + " clientes a serem liquidados em D+0."
                                                                            : "Não há clientes a serem liquidados em D+0."
                                                                    }
                                                                    onClick={(e) => {
                                                                        e.stopPropagation()
                                                                        setType({ name: "settlementCalendar", data: listaClientesPorProduto, d: 0 })
                                                                        setShow(true)
                                                                    }}
                                                                >
                                                                    D+0 <br />({clientsSettlements?.d0})
                                                                </button>
                                                                <button
                                                                    className="buttond1"
                                                                    disabled={loading ? "disabled" : ""}
                                                                    title={
                                                                        clientsSettlements?.d1 > 0
                                                                            ? clientsSettlements.d1 == 1
                                                                                ? clientsSettlements.d1 + " cliente a ser liquidado em D+1."
                                                                                : clientsSettlements.d1 + " clientes a serem liquidados em D+1."
                                                                            : "Não há clientes a serem liquidados em D+1."
                                                                    }
                                                                    onClick={(e) => {
                                                                        e.stopPropagation()
                                                                        setType({ name: "settlementCalendar", data: listaClientesPorProduto, d: 1 })
                                                                        setShow(true)
                                                                    }}
                                                                >
                                                                    D+1 <br />({clientsSettlements?.d1})
                                                                </button>
                                                                <button
                                                                    className="buttond2"
                                                                    disabled={loading ? "disabled" : ""}
                                                                    title={
                                                                        clientsSettlements?.d2 > 0
                                                                            ? clientsSettlements.d2 == 1
                                                                                ? clientsSettlements.d2 + " cliente a ser liquidado em D+2."
                                                                                : clientsSettlements.d2 + " clientes a serem liquidados em D+2."
                                                                            : "Não há clientes a serem liquidados em D+2."
                                                                    }
                                                                    onClick={(e) => {
                                                                        e.stopPropagation()
                                                                        setType({ name: "settlementCalendar", data: listaClientesPorProduto, d: 2 })
                                                                        setShow(true)
                                                                    }}
                                                                >
                                                                    D+2 <br />({clientsSettlements?.d2})
                                                                </button>
                                                                <button
                                                                    className="buttond3"
                                                                    disabled={loading ? "disabled" : ""}
                                                                    title={
                                                                        clientsSettlements?.d3 > 0
                                                                            ? clientsSettlements.d3 == 1
                                                                                ? clientsSettlements.d3 + " cliente a ser liquidado em D+3."
                                                                                : clientsSettlements.d3 + " clientes a serem liquidados em D+3."
                                                                            : "Não há clientes a serem liquidados em D+3."
                                                                    }
                                                                    onClick={(e) => {
                                                                        e.stopPropagation()
                                                                        setType({ name: "settlementCalendar", data: listaClientesPorProduto, d: 3 })
                                                                        setShow(true)
                                                                    }}
                                                                >
                                                                    D+3 <br />({clientsSettlements?.d3})
                                                                </button>
                                                                <button
                                                                    className="buttond3Plus"
                                                                    disabled={loading ? "disabled" : ""}
                                                                    title={
                                                                        clientsSettlements?.d3plus > 0
                                                                            ? clientsSettlements.d3plus == 1
                                                                                ? clientsSettlements.d3plus + " cliente a ser liquidado após D+3."
                                                                                : clientsSettlements.d3plus + " clientes a serem liquidados após D+3."
                                                                            : "Não há clientes a serem liquidados após D+3."
                                                                    }
                                                                    onClick={(e) => {
                                                                        e.stopPropagation()
                                                                        setType({ name: "settlementCalendar", data: listaClientesPorProduto, d: 4 })
                                                                        setShow(true)
                                                                    }}
                                                                >
                                                                    &gt; D+3 <br /> ({clientsSettlements?.d3plus})
                                                                </button> */}
                                                                </div>
                                                            </div>
                                                        </button>
                                                    </div>
                                                    <button
                                                        className="dashButton"
                                                        style={{ background: "#C29C00", color: "white" }}
                                                        onClick={() => {
                                                            window.open(
                                                                "https://agenteinvest-my.sharepoint.com/:x:/g/personal/enrico_a25245_agenteinvest_com_br/EXhbhBC4Sh9KuHP3KSxHVXMBBHYl0x672e5iBO7q-2RYdA",
                                                                "_blank",
                                                                "noopener,noreferrer"
                                                            )
                                                        }}
                                                    >
                                                        <TbCalendarStats size={50} />
                                                        Calendário de produtos{" "}
                                                    </button>
                                                    {/* <button
                                                style={{ background: "#C29C00", color: "white" }}
                                                className="dashButton"
                                                disabled={eventsDash.length > 0 ? "" : "disabled"}
                                                onClick={() => {
                                                    setType({ name: "calendarDashboard", events: eventsDash }) //! MUDAR PARA ROTA DE EVENTOS
                                                    setShow(true)
                                                }}
                                            >
                                                <BiCalendarStar size={50} />
                                                Calendário de Eventos
                                            </button> */}

                                                    <button
                                                        // style={{ background: "#A18300", color: "white", marginRight: "10px" }}
                                                        style={{ background: "#A18300", color: "white" }}
                                                        className="dashButton"
                                                        title="Agende um especialista!"
                                                        onClick={() => {
                                                            window.open("https://outlook.office365.com/owa/calendar/ManchesterTesteBooking@manchesterinvest.com.br/bookings/", "_blank", "noopener,noreferrer")
                                                        }}
                                                    >
                                                        {" "}
                                                        <MdPermContactCalendar size={50} />
                                                        Agendar um Especialista
                                                    </button>
                                                </div>
                                            </div>

                                        </>
                                    )}
                                </div>
                                :
                                <></>}
                            {loading ? (
                                <span

                                    style={{ marginTop: "3rem" }}>
                                    <ArrowLoader />
                                </span>
                            ) : (
                                <div>
                                    {/* {showMessageTransfer && (
                                        <div
                                            style={{
                                                position: 'absolute', 
                                                    width: '100vw', 
                                                    top:'0', 
                                                    padding:'1rem', 
                                                    left:'0', 
                                                    right:'0', 
                                                    margin:'auto', 
                                                    background:'#7f1d1d',                                                    
                                                    transition: 'all 0.5s ease',
                                                    boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)', // Adicionando sombra à borda
                                                    zIndex: '9999',
                                            }}
                                        >
                                            <div 
                                                style={{
                                                    
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    alignItems: 'center',
                                                    
                                                }}
                                            >
                                                <p style={{fontWeight:'bold',color:'white', fontSize:'1.2rem'}}>Prezado assessor, em breve esta página estará disponível em https://portal.manchesterinvest.com.br</p>
                                                <div style={{display:'flex', alignItems: "center", gap:'1rem', paddingRight:'2rem'}}>
                                                    <p 
                                                        onClick={() => {
                                                            localStorage.setItem('showMessageTransfer', 'false')
                                                            setShowMessageTransfer(false)
                                                        }}
                                                        style={{cursor:"pointer",color:'#fff', fontWeight:'bold', fontSize:'1.2rem', textDecoration:'underline'}}
                                                    >
                                                        Não Mostrar Novamente
                                                    </p>
                                                    <AiFillCloseCircle onClick={() => {setShowMessageTransfer(false)}} size={'24'} color="black" fill="white" style={{cursor:'pointer'}} /> 
                                                </div>
                                            </div>
                                        </div>
                                    )} */}
                                    {user.type != 3 ?
                                        <div className="estructuredsTab">
                                            <div
                                                className={tabActive.current == 1 ? "estructuredsTabOption360" : "estructuredsInactiveTabOption360"}
                                                onClick={() => {
                                                    tableChanged.current = true
                                                    tabActive.current = 1
                                                    listFilteredClients.current = clientsResult.current.table
                                                    listSearchedClients.current = clientsResult.current.table
                                                    listClients.current = clientsResult.current.table.slice(0, rowsPerPage + 501)
                                                    linesCount.current = clientsResult.current.table.length
                                                    assembleTable()
                                                    forceUpdate()
                                                }}
                                            >
                                                <p>Campanhas</p>
                                            </div>
                                            {/*<div
                                                className={tabActive.current == 0 ? "estructuredsTabOption360" : "estructuredsInactiveTabOption360"}
                                                onClick={() => {
                                                    tabActive.current = 0
                                                    setConfigTable()
                                                    tableChanged.current = true
                                                }}
                                            >
                                                <p>Configurações</p>
                                            </div>*/}
                                        </div> :
                                        <></>
                                    }


                                    {!(tabActive.current == 0 && loadingTable) ? (
                                        <ThemeProvider theme={getMuiTheme()} className="dashboardBoxContainer">
                                            <MUIDataTable
                                                title={
                                                    tabActive.current == 0
                                                        ? "Última atualização de bases de clientes: " + positivadorUpdateTime
                                                        : "Última atualização de bases de clientes: " + positivadorUpdateTime
                                                }
                                                data={listClients.current}
                                                columns={visibleColumnsRef.current}
                                                options={options}
                                                tableRef={refDataTable}
                                                components={{
                                                    TableViewCol: CustomToView,
                                                }}
                                            ></MUIDataTable>
                                        </ThemeProvider>
                                    ) : (
                                        <div style={{ backgroundColor: "white", width: "100%" }}>
                                            <span style={{ marginTop: "4rem" }}>
                                                <ArrowLoader />
                                            </span>
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </>
        )
    )
}