import React, { useState, Component, useEffect } from "react";
import { Autocomplete } from "@material-ui/lab";
import { TextField } from "@material-ui/core";
import CurrencyTextField from "@unicef/material-ui-currency-textfield";
import { api } from "../../../../services/api";
import { useAuth } from "../../../../contexts/useAuth";
import { useModal } from "../../../../contexts/useModal";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { FiTrash2 } from "react-icons/fi";
import { AiOutlineInfoCircle } from "react-icons/ai";

export function CreateFormStructure(props) {
  const { show, setShow, type, setType } = useModal();
  const { tokenKey, user } = useAuth();

  const [idEstrutura, setIdEstrutura] = useState(0);
  const [jsonConstrutor, setJsonConstrutor] = useState({
    title: null,
    description: null,
    profile: "4",
    sections: [],
  });

  const [tituloPortfolio, setTituloPortfolio] = useState();

  const [idCriacao, setIdCriacao] = useState(0);

  const [atualizacao, setAtualizacao] = useState(0);

  const [percentTemp, setPercentTemp] = useState(0);
  const [precoTemp, setPrecoTemp] = useState(null);
  const [inteiroTemp, setInteiroTemp] = useState(0);
  const [decimalTemp, setDecimalTemp] = useState(0);

  const [edicao, setEdicao] = useState(props?.props?.location?.state != undefined ? true : false);

  const history = useHistory();

  const useStyles = makeStyles((theme) => ({
    sectionClosed: {
      margin: "1rem 1rem 1rem 1rem",
      backgroundColor: "#E4E4E4",
      fontSize: " 1.5rem",
      fontWeight: "400",
      textAlign: "center",
      border: "5px",
      borderStyle: "none solid none solid",
      // borderColor: strutureJson?.profile === '3' ? '#FF0000' : strutureJson?.profile === '2' ? '#ffae00' : '#11639E',
      height: "3rem",
      display: "grid",
      gridTemplateColumns: "3rem auto 3rem",
    },
    tituloTitulo: {
      fontSize: "1.8rem",
      fontWeight: "400",
      color: String(jsonConstrutor?.profile) === "3" ? "#270000" : String(jsonConstrutor?.profile) === "2" ? "#302700" : String(jsonConstrutor?.profile) === "1" ? "#00323f" : "#3a3a3a",
      textAlign: "center",
    },
    descricaoPortfolio: {
      width: "90%",
      margin: "auto",
      marginTop: "0.4rem",
      height: "2.2rem",
      paddingLeft: "0.4rem",
      paddingRight: "0.4rem",
      fontSize: "1.2rem",
      borderBottom: "2px solid #11639E",
      borderBottomColor: String(jsonConstrutor?.profile) === "3" ? "#FF0000" : String(jsonConstrutor?.profile) === "2" ? "#ffae00" : String(jsonConstrutor?.profile) === "1" ? "#11639E" : "#3a3a3a",
      borderLeft: "2px solid #fff",
      borderRight: "2px solid #fff",
      borderTop: "2px solid #fff",
      boxShadow: "-1px 3px 6px gray",
      "&:focus": {
        outline: "none",
        border: "2px solid",
        borderColor: String(jsonConstrutor?.profile) === "3" ? "#FF0000" : String(jsonConstrutor?.profile) === "2" ? "#ffae00" : String(jsonConstrutor?.profile) === "1" ? "#11639E" : "#3a3a3a",
      },
    },
    selectTipoPortfolio: {
      border: "none",
      borderBottom: "2px solid",
      borderBottomColor: String(jsonConstrutor?.profile) === "3" ? "#FF0000" : String(jsonConstrutor?.profile) === "2" ? "#ffae00" : String(jsonConstrutor?.profile) === "1" ? "#11639E" : "#3a3a3a",
      borderStyle: "none none solid none",
      fontSize: "1.2rem",
      width: "fit-content",
      padding: "0.1rem",
      paddingLeft: "0.4rem",
      paddingRight: "0.4rem",
      margin: "auto",
      borderRadius: "0.1rem",
      boxShadow: "-1px 3px 6px gray",
      marginTop: "0.4rem",
      height: "2.4rem",
    },
    inputTituloTitulo: {
      border: "none",
      borderLeft: "2px solid",
      borderRight: "2px solid",
      borderTop: "2px solid #fff",
      borderBottom: "2px solid #fff",
      borderLeftColor: String(jsonConstrutor?.profile) === "3" ? "#FF0000" : String(jsonConstrutor?.profile) === "2" ? "#ffae00" : String(jsonConstrutor?.profile) === "1" ? "#11639E" : "#3a3a3a",
      borderRightColor: String(jsonConstrutor?.profile) === "3" ? "#FF0000" : String(jsonConstrutor?.profile) === "2" ? "#ffae00" : String(jsonConstrutor?.profile) === "1" ? "#11639E" : "#3a3a3a",
      fontSize: "1.6rem",
      width: "14rem",
      padding: "0.2rem",
      textAlign: "center",
      margin: "auto",
      marginTop: "0.4rem",
      borderRadius: "0.1rem",
      boxShadow: "-1px 3px 6px gray",
      "&:focus": {
        outline: "none",
        borderColor: String(jsonConstrutor?.profile) === "3" ? "#FF0000" : String(jsonConstrutor?.profile) === "2" ? "#ffae00" : String(jsonConstrutor?.profile) === "1" ? "#11639E" : "#3a3a3a",
      },
    },
    botaoNovaSecao: {
      width: "20%",
      marginTop: "0.6rem",
      marginBottom: "2rem",
      height: "fit-content",
      fontSize: "1rem",
      backgroundColor: String(jsonConstrutor?.profile) === "3" ? "#FF0000" : String(jsonConstrutor?.profile) === "2" ? "#ffae00" : String(jsonConstrutor?.profile) === "1" ? "#11639E" : "#3a3a3a",
      border: "none",
      color: "white",
      padding: "0.4rem",
      borderRadius: "0.2rem",
      transition: "all ease-in-out 0.1s",
      "&:hover": {
        backgroundColor: String(jsonConstrutor?.profile) === "3" ? "#e50000" : String(jsonConstrutor?.profile) === "2" ? "#e59c00" : String(jsonConstrutor?.profile) === "1" ? "#0f598e" : "#2e2e2e",
      },
    },
    tituloSecao: {
      fontSize: "1.4rem",
      fontWeight: "400",
      color: String(jsonConstrutor?.profile) === "3" ? "#270000" : String(jsonConstrutor?.profile) === "2" ? "#302700" : String(jsonConstrutor?.profile) === "1" ? "#00323f" : "#3a3a3a",
      marginLeft: " 0.4rem",
    },
    inputTituloSecao: {
      border: "none",
      borderLeft: "2px solid",
      borderRight: "2px solid",
      borderTop: "2px solid #fff",
      borderBottom: "2px solid #fff",
      borderLeftColor: String(jsonConstrutor?.profile) === "3" ? "#FF0000" : String(jsonConstrutor?.profile) === "2" ? "#ffae00" : String(jsonConstrutor?.profile) === "1" ? "#11639E" : "#3a3a3a",
      borderRightColor: String(jsonConstrutor?.profile) === "3" ? "#FF0000" : String(jsonConstrutor?.profile) === "2" ? "#ffae00" : String(jsonConstrutor?.profile) === "1" ? "#11639E" : "#3a3a3a",
      fontSize: "1.2rem",
      width: "14rem",
      padding: "0.1rem",
      paddingLeft: "0.4rem",
      paddingRight: "0.4rem",
      margin: "auto",
      marginTop: "0.2rem",
      borderRadius: "0.1rem",
      boxShadow: "-1px 3px 6px gray",
      "&:focus": {
        outline: "none",
        borderTop: "2px solid #11639E",
        borderBottom: "2px solid #11639E",
      },
    },
    botaoNovaPergunta: {
      width: "30%",
      marginTop: "0.8rem",
      height: "fit-content",
      fontSize: "1.2rem",
      backgroundColor: String(jsonConstrutor?.profile) === "3" ? "#FF0000" : String(jsonConstrutor?.profile) === "2" ? "#ffae00" : String(jsonConstrutor?.profile) === "1" ? "#11639E" : "#3a3a3a",
      border: "none",
      color: "white",
      padding: "0.4rem",
      borderRadius: "0.2rem",
      transition: "all ease-in-out 0.1s",
      "&:hover": {
        backgroundColor: String(jsonConstrutor?.profile) === "3" ? "#e50000" : String(jsonConstrutor?.profile) === "2" ? "#e59c00" : String(jsonConstrutor?.profile) === "1" ? "#0f598e" : "#2e2e2e",
      },
    },
    pergunta: {
      display: "grid",
      gridTemplateColumns: "auto auto 1fr auto",
      width: "92%",
      borderColor: String(jsonConstrutor?.profile) === "3" ? "#FF0000" : String(jsonConstrutor?.profile) === "2" ? "#ffae00" : String(jsonConstrutor?.profile) === "1" ? "#11639E" : "#3a3a3a",
      borderTop: "2px solid",
      margin: "auto",
      marginTop: "0.6rem",
      padding: "0.6rem",
      background: "white",
      boxShadow: "-1px 3px 6px gray",
    },
    barraLateral: {
      width: "0.2rem",
      height: "100%",
      backgroundColor: String(jsonConstrutor?.profile) === "3" ? "#e50000" : String(jsonConstrutor?.profile) === "2" ? "#e59c00" : String(jsonConstrutor?.profile) === "1" ? "#0f598e" : "#2e2e2e",
      margin: "auto",
      marginRight: "0.6rem",
    },
    tituloOpcaoPergunta: {
      color: String(jsonConstrutor?.profile) === "3" ? "#270000" : String(jsonConstrutor?.profile) === "2" ? "#302700" : String(jsonConstrutor?.profile) === "1" ? "#00323f" : "#3a3a3a",
      marginBottom: "0.1rem",
    },
    inputTituloPergunta: {
      border: "none",
      borderBottomColor: String(jsonConstrutor?.profile) === "3" ? "#FF0000" : String(jsonConstrutor?.profile) === "2" ? "#ffae00" : String(jsonConstrutor?.profile) === "1" ? "#11639E" : "#3a3a3a",
      borderBottom: "2px solid",
      borderLeft: "2px solid #fff",
      borderRight: "2px solid #fff",
      borderTop: "2px solid #fff",
      fontSize: "1.2rem",
      width: "28%",
      padding: "0.1rem",
      paddingLeft: "0.4rem",
      paddingRight: "0.4rem",
      margin: "auto",
      borderRadius: "0.1rem",
      boxShadow: "-1px 3px 6px gray",
      "&:focus": {
        outline: "none",
        borderColor: String(jsonConstrutor?.profile) === "3" ? "#FF0000" : String(jsonConstrutor?.profile) === "2" ? "#ffae00" : String(jsonConstrutor?.profile) === "1" ? "#11639E" : "#3a3a3a",
        border: "2px solid",
      },
    },
    inputOrdem: {
      border: "none",
      borderBottomColor: String(jsonConstrutor?.profile) === "3" ? "#FF0000" : String(jsonConstrutor?.profile) === "2" ? "#ffae00" : String(jsonConstrutor?.profile) === "1" ? "#11639E" : "#3a3a3a",
      borderBottom: "2px solid",
      borderLeft: "2px solid #fff",
      borderRight: "2px solid #fff",
      borderTop: "2px solid #fff",
      fontSize: "1.2rem",
      width: "15%",
      marginLeft: '0.4rem',
      padding: "0.1rem",
      right: '0px',
      paddingLeft: "0.4rem",
      paddingRight: "0.4rem",
      borderRadius: "0.1rem",
      boxShadow: "-1px 3px 6px gray",
      "&:focus": {
        outline: "none",
        borderColor: String(jsonConstrutor?.profile) === "3" ? "#FF0000" : String(jsonConstrutor?.profile) === "2" ? "#ffae00" : String(jsonConstrutor?.profile) === "1" ? "#11639E" : "#3a3a3a",
        border: "2px solid",
      },
    },
    selectTipoPergunta: {
      border: "none",
      borderBottomColor: String(jsonConstrutor?.profile) === "3" ? "#FF0000" : String(jsonConstrutor?.profile) === "2" ? "#ffae00" : String(jsonConstrutor?.profile) === "1" ? "#11639E" : "#3a3a3a",
      borderBottom: "2px solid",
      borderLeft: "2px solid #fff",
      borderRight: "2px solid #fff",
      borderTop: "2px solid #fff",
      fontSize: "1.2rem",
      width: "fit-content",
      padding: "0.1rem",
      paddingLeft: "0.4rem",
      paddingRight: "0.4rem",
      margin: "auto",
      borderRadius: "0.1rem",
      boxShadow: "-1px 3px 6px gray",
      "&:focus": {
        outline: "none",
        borderColor: String(jsonConstrutor?.profile) === "3" ? "#FF0000" : String(jsonConstrutor?.profile) === "2" ? "#ffae00" : String(jsonConstrutor?.profile) === "1" ? "#11639E" : "#3a3a3a",
        border: "2px solid",
      },
    },
    containerTags: {
      display: "flex",
      width: "fit-content",
      borderLeftColor: String(jsonConstrutor?.profile) === "3" ? "#FF0000" : String(jsonConstrutor?.profile) === "2" ? "#ffae00" : String(jsonConstrutor?.profile) === "1" ? "#11639E" : "#3a3a3a",
      borderLeft: "2px solid",
      paddingLeft: "0.6rem",
    },
    inputNameTag: {
      border: "none",
      borderBottomColor: String(jsonConstrutor?.profile) === "3" ? "#FF0000" : String(jsonConstrutor?.profile) === "2" ? "#ffae00" : String(jsonConstrutor?.profile) === "1" ? "#11639E" : "#3a3a3a",
      borderBottom: "2px solid",
      borderLeft: "2px solid #fff",
      borderRight: "2px solid #fff",
      borderTop: "2px solid #fff",
      fontSize: "1.2rem",
      padding: "0.1rem",
      paddingLeft: "0.4rem",
      paddingRight: "0.4rem",
      margin: "auto",
      marginLeft: "0.6rem",
      borderRadius: "0.1rem",
      boxShadow: "-1px 3px 6px gray",
      "&:focus": {
        outline: "none",
        borderColor: String(jsonConstrutor?.profile) === "3" ? "#FF0000" : String(jsonConstrutor?.profile) === "2" ? "#ffae00" : String(jsonConstrutor?.profile) === "1" ? "#11639E" : "#3a3a3a",
        border: "2px solid",
      },
    },
    tipoPerguntaTexto: {
      border: "none",
      borderBottomColor: String(jsonConstrutor?.profile) === "3" ? "#FF0000" : String(jsonConstrutor?.profile) === "2" ? "#ffae00" : String(jsonConstrutor?.profile) === "1" ? "#11639E" : "#3a3a3a",
      borderBottom: "2px solid",
      borderLeft: "2px solid #fff",
      borderRight: "2px solid #fff",
      borderTop: "2px solid #fff",
      fontSize: "1.2rem",
      width: "30%",
      padding: "0.1rem",
      paddingLeft: "0.4rem",
      paddingRight: "0.4rem",
      marginTop: "1rem",
      borderRadius: "0.1rem",
      boxShadow: "-1px 3px 6px gray",
      "&:focus": {
        outline: "none",
        borderColor: String(jsonConstrutor?.profile) === "3" ? "#FF0000" : String(jsonConstrutor?.profile) === "2" ? "#ffae00" : String(jsonConstrutor?.profile) === "1" ? "#11639E" : "#3a3a3a",
        border: "2px solid",
      },
    },
    notchedOutline: {
      borderColor: String(jsonConstrutor?.profile) === "3" ? "#FF0000 !important" : String(jsonConstrutor?.profile) === "2" ? "#ffae00 !important" : String(jsonConstrutor?.profile) === "1" ? "#11639E !important" : "#3a3a3a !important",
    },
    inputOptionName: {
      border: "none",
      border: "1px solid #fff",
      fontSize: "1.2rem",
      padding: "0.1rem",
      paddingLeft: "0.4rem",
      paddingRight: "0.4rem",
      margin: "auto",
      marginLeft: "0.4rem",
      borderRadius: "0.1rem",
      boxShadow: "-1px 3px 6px gray",
      minWidth: "4rem",
      width: "min-content",
      height: "fit-content",
      "&:focus": {
        outline: "none",
        borderColor: String(jsonConstrutor?.profile) === "3" ? "#FF0000" : String(jsonConstrutor?.profile) === "2" ? "#ffae00" : String(jsonConstrutor?.profile) === "1" ? "#11639E" : "#3a3a3a",
        border: "1px solid",
      },
    },
  }));

  const classes = useStyles();

  useEffect(() => {
    if (props?.props?.location?.state != undefined) {
      setEdicao(true);
      setEditForm();
    } else {
      setEdicao(false);
    }
  }, []);

  async function setEditForm() {
    try {
      const jsonEditTemp = await api.get(`/api/v1/forms/detailed/${props?.props?.location?.state?.id}`, {
        headers: {
          authorization: `Bearer ${tokenKey}`,
        },
      });
      let jsonTemp = jsonEditTemp?.data?.data;
      let idTemp = 100000;
      let maxId = jsonTemp.id;
      jsonTemp.sections.forEach((secao) => {
        secao.questions.forEach((pergunta, indexP) => {
          pergunta.ordem = parseInt(indexP) + 1;
          pergunta.options.forEach((opcao1) => {
            if (opcao1.tag == null) {
              opcao1.tag = [];
            } else {
              let tempTagArray = opcao1.tag.split("|");
              opcao1.tag = [];
              tempTagArray.forEach((tag) => {
                opcao1.tag.push({
                  description: tag,
                  id: idTemp,
                });
                idTemp++;
              });
            }
            opcao1.questions.forEach((subPergunta1) => {
              subPergunta1.options.forEach((opcao2, indexOpcao2) => {
                if (opcao2.tag == null) {
                  opcao2.tag = [];
                } else {
                  let tempTagArray = opcao2.tag.split("|");
                  opcao2.tag = [];
                  tempTagArray.forEach((tag) => {
                    opcao2.tag.push({
                      description: tag,
                      id: idTemp,
                    });
                    idTemp++;
                  });
                }
                opcao2.questions.forEach((subPergunta2) => {
                  subPergunta2.options.forEach((opcao3, indexOpcao3) => {
                    if (opcao3.tag == null) {
                      opcao3.tag = [];
                    } else {
                      let tempTagArray = opcao3.tag.split("|");
                      opcao3.tag = [];
                      tempTagArray.forEach((tag) => {
                        opcao3.tag.push({
                          description: tag,
                          id: idTemp,
                        });
                        idTemp++;
                      });
                    }
                    opcao3.questions.forEach((subPergunta3) => {
                      subPergunta3.options.forEach((opcao4, indexOpcao4) => {
                        if (opcao4.tag == null) {
                          opcao4.tag = [];
                        } else {
                          let tempTagArray = opcao4.tag.split("|");
                          opcao4.tag = [];
                          tempTagArray.forEach((tag) => {
                            opcao4.tag.push({
                              description: tag,
                              id: idTemp,
                            });
                            idTemp++;
                          });
                        }
                      });
                    });
                  });
                });
              });
            });
          });
        });
      });
      jsonTemp.sections.forEach((secao) => {
        if (secao.id > maxId) maxId = secao.id;
        secao.questions.forEach((pergunta) => {
          if (pergunta.id > maxId) maxId = pergunta.id;
          pergunta.options.forEach((opcao1) => {
            if (opcao1.id > maxId) maxId = opcao1.id;
            opcao1.tag.forEach((tag) => {
              if (tag.id > maxId) maxId = tag.id;
            });
            opcao1.questions.forEach((subPergunta1) => {
              if (subPergunta1.id > maxId) maxId = subPergunta1.id;
              subPergunta1.options.forEach((opcao2, indexOpcao2) => {
                if (opcao2.id > maxId) maxId = opcao2.id;
                opcao2.tag.forEach((tag1) => {
                  if (tag1.id > maxId) maxId = tag1.id;
                });
                opcao2.questions.forEach((subPergunta2) => {
                  if (subPergunta2.id > maxId) maxId = subPergunta2.id;
                  subPergunta2.options.forEach((opcao3, indexOpcao3) => {
                    if (opcao3.id > maxId) maxId = opcao3.id;
                    opcao3.tag.forEach((tag2) => {
                      if (tag2.id > maxId) maxId = tag2.id;
                    });
                    opcao3.questions.forEach((subPergunta3) => {
                      if (subPergunta3.id > maxId) maxId = subPergunta3.id;
                      subPergunta3.options.forEach((opcao4, indexOpcao4) => {
                        if (opcao4.id > maxId) maxId = opcao4.id;
                        opcao4.tag.forEach((tag3) => {
                          if (tag3.id > maxId) maxId = tag3.id;
                        });
                      });
                    });
                  });
                });
              });
            });
          });
        });
      });
      setIdCriacao(maxId + 1);
      setJsonConstrutor(jsonTemp);
      setAtualizacao(atualizacao + 1);
    } catch (error) {
      setType({ name: "alertMsg", type: "information", value: { message: "Erro ao buscar os dados do formulário" } });
      setShow(true);
    }
  }

  function deletePergunta(idPergunta) {
    var jsonTemp = jsonConstrutor;
    jsonTemp.sections.forEach((secao) => {
      secao.questions.forEach((pergunta, indexPergunta) => {
        if (pergunta.id == idPergunta) {
          secao.questions.splice(indexPergunta, 1);
        } else {
          pergunta.options.forEach((opcao) => {
            opcao.questions.forEach((pergunta1, indexPergunta1) => {
              if (pergunta1.id == idPergunta) {
                pergunta1.options.push(opcao.questions.splice(indexPergunta1, 1));
              } else {
                pergunta1.options.forEach((opcao1) => {
                  opcao1.questions.forEach((pergunta2, indexPergunta2) => {
                    if (pergunta2.id == idPergunta) {
                      opcao1.questions.splice(indexPergunta2, 1);
                    } else {
                      pergunta2.options.forEach((opcao2) => {
                        opcao2.questions.forEach((pergunta3, indexPergunta3) => {
                          if (pergunta3.id == idPergunta) {
                            opcao2.questions.splice(indexPergunta3, 1);
                          }
                        });
                      });
                    }
                  });
                });
              }
            });
          });
        }
      });
    });
    setJsonConstrutor(jsonTemp);
    setAtualizacao(atualizacao + 1);
  }

  function deleteOpcao(idOpcao) {
    var jsonTemp = jsonConstrutor;
    jsonTemp.sections.forEach((secao) => {
      secao.questions.forEach((pergunta) => {
        pergunta.options.forEach((opcao1, indexOpcao1) => {
          if (opcao1.id == idOpcao) {
            pergunta.options.splice(indexOpcao1, 1);
          } else {
            opcao1.questions.forEach((subPergunta1) => {
              subPergunta1.options.forEach((opcao2, indexOpcao2) => {
                if (opcao2.id == idOpcao) {
                  subPergunta1.options.splice(indexOpcao2, 1);
                } else {
                  opcao2.questions.forEach((subPergunta2) => {
                    subPergunta2.options.forEach((opcao3, indexOpcao3) => {
                      if (opcao3.id == idOpcao) {
                        subPergunta2.options.splice(indexOpcao3, 1);
                      } else {
                        opcao3.questions.forEach((subPergunta3) => {
                          subPergunta3.options.forEach((opcao4, indexOpcao4) => {
                            if (opcao4.id == idOpcao) {
                              subPergunta3.options.splice(indexOpcao4, 1);
                            } else {
                            }
                          });
                        });
                      }
                    });
                  });
                }
              });
            });
          }
        });
      });
    });
    setJsonConstrutor(jsonTemp);
    setAtualizacao(atualizacao + 1);
  }

  function deleteSecao(idSecao) {
    var jsonTemp = jsonConstrutor;
    jsonTemp.sections.forEach((secao, index) => {
      if (secao.id == idSecao) {
        jsonTemp.sections.splice(index, 1);
      }
    });
    setJsonConstrutor(jsonTemp);
    setAtualizacao(atualizacao + 1);
  }

  function deleteTag(idTag) {
    // console.log(idTag)
    var jsonTemp = jsonConstrutor;
    jsonTemp.sections.forEach((secao) => {
      secao.questions.forEach((pergunta) => {
        pergunta.options.forEach((opcao1) => {
          if (opcao1.tag.length > 0) {
            opcao1.tag.forEach((tag, indexTag) => {
              if (tag.id == idTag) {
                opcao1.tag.splice(indexTag, 1);
              }
            });
          }
          opcao1.questions.forEach((subPergunta1) => {
            subPergunta1.options.forEach((opcao2, indexOpcao2) => {
              if (opcao2.tag.length > 0) {
                opcao2.tag.forEach((tag1, indexTag1) => {
                  if (tag1.id == idTag) {
                    opcao2.tag.splice(indexTag1, 1);
                  }
                });
              }
              opcao2.questions.forEach((subPergunta2) => {
                subPergunta2.options.forEach((opcao3, indexOpcao3) => {
                  if (opcao3.tag.length > 0) {
                    opcao3.tag.forEach((tag2, indexTag2) => {
                      if (tag2.id == idTag) {
                        opcao3.tag.splice(indexTag2, 1);
                      }
                    });
                  }
                  opcao3.questions.forEach((subPergunta3) => {
                    subPergunta3.options.forEach((opcao4, indexOpcao4) => {
                      opcao4.tag.forEach((tag3, indexTag3) => {
                        if (tag3.id == idTag) {
                          opcao4.tag.splice(indexTag3, 1);
                        }
                      });
                    });
                  });
                });
              });
            });
          });
        });
      });
    });
    setJsonConstrutor(jsonTemp);
    setAtualizacao(atualizacao + 1);
  }

  function addPergunta(idPai) {
    var jsonTemp = jsonConstrutor;
    jsonTemp.sections.forEach((secao, indexPergunta) => {
      if (secao.id == idPai) {
        secao.questions.push({
          description: null,
          type: "1",
          id: idCriacao,
          options: [],
          observation: false,
          ordem: parseInt(secao.questions.length + 1),
        });
      } else {
        secao.questions.forEach((pergunta) => {
          pergunta.options.forEach((opcao1) => {
            if (opcao1.id == idPai) {
              opcao1.questions.push({
                description: null,
                type: "1",
                id: idCriacao,
                options: [],
                observation: false,
                ordem: parseInt(opcao1.questions.length) + 1,
              });
            } else {
              opcao1.questions.forEach((subPergunta1) => {
                subPergunta1.options.forEach((opcao2) => {
                  if (opcao2.id == idPai) {
                    opcao2.questions.push({
                      description: null,
                      type: "1",
                      id: idCriacao,
                      options: [],
                      observation: false,
                    });
                  } else {
                    opcao2.questions.forEach((subPergunta2) => {
                      subPergunta2.options.forEach((opcao3) => {
                        if (opcao3.id == idPai) {
                          opcao3.questions.push({
                            description: null,
                            type: "1",
                            id: idCriacao,
                            options: [],
                            observation: false,
                          });
                        }
                      });
                    });
                  }
                });
              });
            }
          });
        });
      }
    });
    setIdCriacao(idCriacao + 1);
    setJsonConstrutor(jsonTemp);
    setAtualizacao(atualizacao + 1);
  }

  function addSecao() {
    var jsonTemp = jsonConstrutor;
    jsonTemp.sections.push({
      title: null,
      id: idCriacao,
      questions: [
        {
          description: null,
          type: "1",
          id: idCriacao + 1,
          options: [],
          ordem: 1,
          observation: false,
        },
      ],
    });
    setIdCriacao(idCriacao + 2);
    setJsonConstrutor(jsonTemp);
    setAtualizacao(atualizacao + 1);
  }

  function addOpcao(idPai) {
    var jsonTemp = jsonConstrutor;
    jsonTemp.sections.forEach((secao) => {
      secao.questions.forEach((pergunta) => {
        if (pergunta.id == idPai) {
          pergunta.options.push({
            description: null,
            id: idCriacao,
            questions: [],
            tag: [],
          });
        } else {
          pergunta.options.forEach((opcao) => {
            opcao.questions.forEach((pergunta1) => {
              if (pergunta1.id == idPai) {
                pergunta1.options.push({
                  description: null,
                  id: idCriacao,
                  questions: [],
                  tag: [],
                });
              } else {
                pergunta1.options.forEach((opcao1) => {
                  opcao1.questions.forEach((pergunta2) => {
                    if (pergunta2.id == idPai) {
                      pergunta2.options.push({
                        description: null,
                        id: idCriacao,
                        questions: [],
                        tag: [],
                      });
                    } else {
                      pergunta2.options.forEach((opcao2) => {
                        opcao2.questions.forEach((pergunta3) => {
                          if (pergunta3.id == idPai) {
                            pergunta3.options.push({
                              description: null,
                              id: idCriacao,
                              questions: [],
                              tag: [],
                            });
                          }
                        });
                      });
                    }
                  });
                });
              }
            });
          });
        }
      });
    });
    setIdCriacao(idCriacao + 1);
    setJsonConstrutor(jsonTemp);
    setAtualizacao(atualizacao + 1);
  }

  function addOptionTag(idOpcao, nomeTag) {
    if (nomeTag != "" && nomeTag != null) {
      var jsonTemp = jsonConstrutor;
      jsonTemp.sections.forEach((secao) => {
        secao.questions.forEach((pergunta) => {
          pergunta.options.forEach((opcao1, indexOpcao1) => {
            if (opcao1.id == idOpcao) {
              opcao1.tag.push({
                description: nomeTag,
                id: idCriacao,
              });
            } else {
              opcao1.questions.forEach((subPergunta1) => {
                subPergunta1.options.forEach((opcao2, indexOpcao2) => {
                  if (opcao2.id == idOpcao) {
                    opcao2.tag.push({
                      description: nomeTag,
                      id: idCriacao,
                    });
                  } else {
                    opcao2.questions.forEach((subPergunta2) => {
                      subPergunta2.options.forEach((opcao3, indexOpcao3) => {
                        if (opcao3.id == idOpcao) {
                          opcao3.tag.push({
                            description: nomeTag,
                            id: idCriacao,
                          });
                        } else {
                          opcao3.questions.forEach((subPergunta3) => {
                            subPergunta3.options.forEach((opcao4, indexOpcao4) => {
                              if (opcao4.id == idOpcao) {
                                opcao4.tag.push({
                                  description: nomeTag,
                                  id: idCriacao,
                                });
                              }
                            });
                          });
                        }
                      });
                    });
                  }
                });
              });
            }
          });
        });
      });
      setIdCriacao(idCriacao + 1);
      setJsonConstrutor(jsonTemp);
      setAtualizacao(atualizacao + 1);
    } else {
      alert("Não é possível adicionar uma tag sem nome");
    }
  }

  function changeNamePortfolio(name) {
    var jsonTemp = jsonConstrutor;
    jsonConstrutor.title = name;
    setJsonConstrutor(jsonTemp);
    setAtualizacao(atualizacao + 1);
  }

  function changeDescriptionPortfolio(description) {
    var jsonTemp = jsonConstrutor;
    jsonConstrutor.description = description;
    setJsonConstrutor(jsonTemp);
    setAtualizacao(atualizacao + 1);
  }

  function changeNameSecao(idSecao, name) {
    var jsonTemp = jsonConstrutor;
    jsonTemp.sections.forEach((secao, index) => {
      if (secao.id == idSecao) {
        secao.title = name;
      }
    });
    setJsonConstrutor(jsonTemp);
    setAtualizacao(atualizacao + 1);
  }

  function changeNamePergunta(idPergunta, title) {
    var jsonTemp = jsonConstrutor;
    jsonTemp.sections.forEach((secao) => {
      secao.questions.forEach((pergunta) => {
        if (pergunta.id == idPergunta) {
          pergunta.description = title;
        } else {
          pergunta.options.forEach((opcao) => {
            opcao.questions.forEach((pergunta1) => {
              if (pergunta1.id == idPergunta) {
                pergunta1.description = title;
              } else {
                pergunta1.options.forEach((opcao1) => {
                  opcao1.questions.forEach((pergunta2) => {
                    if (pergunta2.id == idPergunta) {
                      pergunta2.description = title;
                    } else {
                      pergunta2.options.forEach((opcao2) => {
                        opcao2.questions.forEach((pergunta3) => {
                          if (pergunta3.id == idPergunta) {
                            pergunta3.description = title;
                          }
                        });
                      });
                    }
                  });
                });
              }
            });
          });
        }
      });
    });
    setIdCriacao(idCriacao + 1);
    setJsonConstrutor(jsonTemp);
    setAtualizacao(atualizacao + 1);
  }

  function changeNameOption(idOpcao, title) {
    var jsonTemp = jsonConstrutor;
    jsonTemp.sections.forEach((secao) => {
      secao.questions.forEach((pergunta) => {
        pergunta.options.forEach((opcao1, indexOpcao1) => {
          if (opcao1.id == idOpcao) {
            opcao1.description = title;
          } else {
            opcao1.questions.forEach((subPergunta1) => {
              subPergunta1.options.forEach((opcao2, indexOpcao2) => {
                if (opcao2.id == idOpcao) {
                  opcao2.description = title;
                } else {
                  opcao2.questions.forEach((subPergunta2) => {
                    subPergunta2.options.forEach((opcao3, indexOpcao3) => {
                      if (opcao3.id == idOpcao) {
                        opcao3.description = title;
                      } else {
                        opcao3.questions.forEach((subPergunta3) => {
                          subPergunta3.options.forEach((opcao4, indexOpcao4) => {
                            if (opcao4.id == idOpcao) {
                              opcao4.description = title;
                            }
                          });
                        });
                      }
                    });
                  });
                }
              });
            });
          }
        });
      });
    });
    setIdCriacao(idCriacao + 1);
    setJsonConstrutor(jsonTemp);
    setAtualizacao(atualizacao + 1);
  }

  function changeObservation(idPergunta) {
    var jsonTemp = jsonConstrutor;
    jsonTemp.sections.forEach((secao) => {
      secao.questions.forEach((pergunta) => {
        if (pergunta.id == idPergunta) {
          pergunta.observation = !pergunta.observation;
        } else {
          pergunta.options.forEach((opcao) => {
            opcao.questions.forEach((pergunta1) => {
              if (pergunta1.id == idPergunta) {
                pergunta1.observation = !pergunta1.observation;
              } else {
                pergunta1.options.forEach((opcao1) => {
                  opcao1.questions.forEach((pergunta2) => {
                    if (pergunta2.id == idPergunta) {
                      pergunta2.observation = !pergunta2.observation;
                    } else {
                      pergunta2.options.forEach((opcao2) => {
                        opcao2.questions.forEach((pergunta3) => {
                          if (pergunta3.id == idPergunta) {
                            pergunta3.observation = !pergunta3.observation;
                          }
                        });
                      });
                    }
                  });
                });
              }
            });
          });
        }
      });
    });
    setIdCriacao(idCriacao + 1);
    setJsonConstrutor(jsonTemp);
    setAtualizacao(atualizacao + 1);
  }

  function formatJsonTags() {
    var jsonTemp = structuredClone(jsonConstrutor);
    jsonTemp.sections.forEach((secao) => {
      secao.questions.forEach((pergunta) => {
        pergunta.options.forEach((opcao1) => {
          if (opcao1.tag.length > 0) {
            let tagNames = [];
            opcao1.tag.forEach((tag) => {
              tagNames.push(tag.description);
            });
            opcao1.tag = tagNames.join("|");
          } else {
            opcao1.tag = undefined;
          }
          opcao1.questions.forEach((subPergunta1) => {
            subPergunta1.options.forEach((opcao2, indexOpcao2) => {
              if (opcao2.tag.length > 0) {
                let tagNames = [];
                opcao2.tag.forEach((tag) => {
                  tagNames.push(tag.description);
                });
                opcao2.tag = tagNames.join("|");
              } else {
                opcao2.tag = undefined;
              }
              opcao2.questions.forEach((subPergunta2) => {
                subPergunta2.options.forEach((opcao3, indexOpcao3) => {
                  if (opcao3.tag.length > 0) {
                    let tagNames = [];
                    opcao3.tag.forEach((tag) => {
                      tagNames.push(tag.description);
                    });
                    opcao3.tag = tagNames.join("|");
                  } else {
                    opcao3.tag = undefined;
                  }
                  opcao3.questions.forEach((subPergunta3) => {
                    subPergunta3.options.forEach((opcao4, indexOpcao4) => {
                      if (opcao4.tag.length > 0) {
                        let tagNames = [];
                        opcao4.tag.forEach((tag) => {
                          tagNames.push(tag.description);
                        });
                        opcao4.tag = tagNames.join("|");
                      } else {
                        opcao4.tag = undefined;
                      }
                    });
                  });
                });
              });
            });
          });
        });
      });
    });
    return jsonTemp;
  }

  async function sendUpdate() {
    var jsonTemp = formatJsonTags();
    if (jsonTemp.sections.length < 1) {
      alert("O formulário deve conter ao menos uma sessão");
      return;
    }

    try {
      const postForm = await api.post(
        `/api/v1/forms/update/${props?.props?.location?.state?.id}`,
        {
          title: jsonTemp.title,
          description: jsonTemp.description,
          profile: parseInt(jsonConstrutor?.profile),
          sections: jsonTemp.sections,
        },
        {
          headers: {
            authorization: `Bearer ${tokenKey}`,
          },
        }
      );
      setType({ name: "alertMsg", type: "information", value: { message: "Portfólio atualizado com sucesso!" }, func: history.push("/selectForm") });
      setShow(true);
    } catch (error) {
      console.log(error?.response);
      setType({
        name: "alertMsg",
        type: "information",
        value: { message: error?.response?.data?.message === undefined ? "Erro ao criar o portfólio." : error.response.data.message },
      });
      setShow(true);
    }
  }

  async function sendForm() {
    var jsonTemp = formatJsonTags();
    if (jsonTemp.sections.length < 1) {
      alert("O formulário deve conter ao menos uma sessão");
      return;
    }

    try {
      const postForm = await api.post(
        `/api/v1/forms`,
        {
          title: jsonTemp.title,
          description: jsonTemp.description,
          profile: parseInt(jsonConstrutor?.profile),
          sections: jsonTemp.sections,
        },
        {
          headers: {
            authorization: `Bearer ${tokenKey}`,
          },
        }
      );
      setType({ name: "alertMsg", type: "information", value: { message: "Portfólio criado com sucesso!" }, func: history.push("/selectForm") });
      setShow(true);
    } catch (error) {
      console.log(error?.response);
      setType({
        name: "alertMsg",
        type: "information",
        value: { message: error?.response?.data?.message === undefined ? "Erro ao criar o portfólio." : error.response.data.message },
      });
      setShow(true);
    }
  }

  
  function reordenarPerguntas(idSecao, idPergunta,oldVal, newVal) {
    var jsonTemp = jsonConstrutor;
    //existem dois casos: 1) a pergunta foi movida para cima, 2) a pergunta foi movida para baixo
    //1) a pergunta foi movida para cima
    if (oldVal > newVal) {
      jsonTemp.sections.forEach((secao) => {
        if (secao.id == idSecao) {
          secao.questions.forEach((pergunta) => {
            if (pergunta.ordem >= newVal && pergunta.ordem < oldVal) {
              pergunta.ordem = parseInt(pergunta.ordem) + 1;
            }
          });
        }
      });
    }
    //2) a pergunta foi movida para baixo
    else {
      jsonTemp.sections.forEach((secao) => {
        if (secao.id == idSecao) {
          secao.questions.forEach((pergunta) => {
            if (pergunta.ordem <= newVal && pergunta.ordem > oldVal) {
              pergunta.ordem = parseInt(pergunta.ordem) - 1;
            }
          });
        }
      });
    }
  
    //altera a ordem da pergunta que foi movida
    jsonTemp.sections.forEach((secao) => {
      if (secao.id == idSecao) {
        secao.questions.forEach((pergunta) => {
          if (pergunta.id == idPergunta) {
            pergunta.ordem = parseInt(newVal);
          }
        });
      }
    });

    //ordena as perguntas de acordo com a ordem
    jsonTemp.sections.forEach((secao) => {
      if (secao.id == idSecao) {
        secao.questions.sort((a, b) => {
          return a.ordem - b.ordem;
        });
      }
    });

    setJsonConstrutor(jsonTemp);
    setAtualizacao(atualizacao + 1);
  }
    

  return (
    <div className="containerGeralCreateFormStructure">
      {/* <button onClick={(e) => {console.log((jsonConstrutor))}}>CONSOLE JSONCONSTRUTOR</button> */}
      {/* <button onClick={(e) => {console.log(edicao)}}>EDICAO</button> */}
      <div className="containerCabecalhoPortfolio">
        <div className="containerTituloForm">
          <div className={classes.tituloTitulo}>Nome portfólio</div>
          <input
            placeholder="Clique para editar"
            onBlur={(e) => changeNamePortfolio(e.target.value)}
            onFocus={(e) => {
              changeNamePortfolio(null);
            }}
            value={jsonConstrutor.title}
            type="text"
            className={classes.inputTituloTitulo}
          />
        </div>
        <div className="containerSelectTipoPortfolio">
          <div className={classes.tituloTitulo}>Suitability</div>
          <select
            className={classes.selectTipoPortfolio}
            onChange={(e) => {
              jsonConstrutor.profile = e.target.value;
              setAtualizacao(atualizacao + 1);
            }}>
            <option value="4" selected={String(jsonConstrutor.profile) == "4" ? true : false}>
              Geral
            </option>
            <option value="1" selected={String(jsonConstrutor.profile) == "1" ? true : false}>
              Conservador
            </option>
            <option value="2" selected={String(jsonConstrutor.profile) == "2" ? true : false}>
              Moderado
            </option>
            <option value="3" selected={String(jsonConstrutor.profile) == "3" ? true : false}>
              Agressivo
            </option>
          </select>
        </div>
        <div className="containerDescricaoPortfolio">
          <div className={classes.tituloTitulo}>Descrição</div>
          <input
            type="text"
            className={classes.descricaoPortfolio}
            onBlur={(e) => {
              changeDescriptionPortfolio(e.target.value);
            }}
            onFocus={(e) => {
              changeDescriptionPortfolio(null);
            }}
            value={jsonConstrutor.description}
          />
        </div>
      </div>
      <div className="containerSecoesPerguntas">
        {jsonConstrutor?.sections?.map((secao, indexSecao) => {
          return (
            <div className="secao" key={secao.id}>
              <div className={classes.tituloSecao}>Nome seção</div>
              <input
                placeholder="Clique para editar"
                onBlur={(e) => changeNameSecao(secao.id, e.target.value)}
                onFocus={(e) => {
                  changeNameSecao(secao.id, null);
                }}
                value={secao.title}
                type="text"
                className={classes.inputTituloSecao}
              />
              <button
                className="deleteRadioOption"
                onClick={() => {
                  deleteSecao(secao.id);
                }}>
                <FiTrash2 />
              </button>
              {secao?.questions?.map((pergunta) => {
                return (
                  <div className={classes.pergunta} key={pergunta.id}>
                    <button
                      className="deleteRadioOption"
                      onClick={() => {
                        deletePergunta(pergunta.id);
                      }}>
                      <FiTrash2 />
                    </button>
                    <div className={classes.barraLateral}></div>
                    <div className="configuracaoPergunta">
                      <div className="opcaoPergunta">
                        <div className={classes.tituloOpcaoPergunta} 
                          style={{display:"flex", width:'30%',justifyContent:"space-between",alignItems:"center" }}>
                            <span> Titulo </span>
                            <div style={{display:"flex", alignItems:"center", justifyContent:"flex-end"}}> <span> Ordem: </span>
                              <input value={pergunta.ordem} className={classes.inputOrdem}
                                onChange={(e) => {
                                  if (e.target.value != "" && e.target.value != null && e.target.value != undefined && e.target.value > 0 && e.target.value <= secao.questions.length) {
                                    if (pergunta.oldOrdem && pergunta.oldOrdem != ''){
                                      reordenarPerguntas(secao.id, pergunta.id,pergunta.oldOrdem , parseInt(e.target.value));
                                      pergunta.oldOrdem = ''
                                      
                                    } else {
                                      reordenarPerguntas(secao.id, pergunta.id, pergunta.ordem, parseInt(e.target.value));
                                    }
                                  }
                                  else {
                                    if (e.target.value !== 0){
                                      pergunta.oldOrdem = pergunta.ordem;
                                      pergunta.ordem = ''
                                    }
                                  }
                                  setAtualizacao(atualizacao + 1);
                                  }
                                }
                                  
                              />
                            </div>
                        </div>
                        <span>
                          {indexSecao+1 + "." + pergunta.ordem + " "}
                          <input
                            placeholder="Pressione para editar"
                            onBlur={(e) => changeNamePergunta(pergunta.id, e.target.value)}
                            onFocus={(e) => {
                              changeNamePergunta(pergunta.id, null);
                            }}
                            type="text"
                            value={pergunta.description}
                            className={classes.inputTituloPergunta}
                            />
                        </span>
                      </div>
                      <div className="opcaoPergunta">
                        <div className={classes.tituloOpcaoPergunta}>Tipo de pergunta</div>
                        <select
                          className={classes.selectTipoPergunta}
                          onChange={(e) => {
                            pergunta.type = e.target.value;
                            pergunta.options = [];
                            setAtualizacao(atualizacao + 1);
                          }}
                          value={pergunta.type}>
                          <option value="1">Texto</option>
                          <option value="2">Seleção Única</option>
                          <option value="3">Checkbox</option>
                          <option value="4">Valor monetário</option>
                          <option value="6">Inteiro</option>
                          <option value="7">Decimal</option>
                          <option value="5">Porcentagem</option>
                        </select>
                        {pergunta.type == "1" ? (
                          <div
                            className={classes.tipoPerguntaTexto}
                            contentEditable
                            suppressContentEditableWarning={true}
                            onBlur={(e) => {
                              if (e.target.innerHTML == "") {
                                e.target.innerHTML = "Insira a resposta";
                              }
                            }}
                            onMouseLeave={(e) => {}}
                            onClick={(e) => {
                              if (e.target.innerHTML == "Insira a resposta") e.target.innerHTML = "";
                            }}>
                            Insira a resposta
                          </div>
                        ) : pergunta.type == "2" ? (
                          <div className="containerTipoPerguntaOpcaoMultipla">
                            {pergunta?.options?.length > 0 ? (
                              pergunta?.options?.map((opcao) => {
                                return (
                                  <>
                                    <div className="containerRadioOption" key={opcao.id}>
                                      <button
                                        className="deleteRadioOption"
                                        onClick={() => {
                                          deleteOpcao(opcao.id);
                                        }}>
                                        <FiTrash2 />
                                      </button>
                                      <input type="radio" id="html" name="fav_language" value="HTML" /> {" "}
                                      <input
                                        placeholder="Nome opção"
                                        onBlur={(e) => changeNameOption(opcao.id, e.target.value)}
                                        onFocus={(e) => {
                                          changeNameOption(opcao.id, null);
                                        }}
                                        type="text"
                                        value={opcao.description}
                                        className={classes.inputOptionName}
                                      />
                                       {" "}
                                      <button
                                        className="addSubSection"
                                        onClick={() => {
                                          addPergunta(opcao.id);
                                          setAtualizacao(atualizacao + 1);
                                        }}>
                                        + Subpergunta
                                      </button>
                                      <div className={classes.containerTags}>
                                        <div className="containerInfoTags">
                                          <AiOutlineInfoCircle title="Se essa opção possuir alguma tag adicionada, esta será enviada para o banco no momento que ela for selecionada por quem está preenchendo o portfolio." size={15} />
                                        </div>
                                        <form
                                          className="formTags"
                                          onSubmit={(e) => {
                                            e.preventDefault();
                                            addOptionTag(opcao.id, e.target.firstChild.value);
                                            e.target.firstChild.value = "";
                                          }}>
                                          <input placeholder="Insira o nome da tag" type="text" className={classes.inputNameTag} />
                                          <input type="submit" className="addTagButton" value="+" />
                                        </form>
                                        <div className="containerDisplayTags">
                                          {opcao?.tag?.map((tag) => {
                                            return (
                                              <div className="containerTag" key={tag.id}>
                                                <button
                                                  className="deleteTagButton"
                                                  onClick={(e) => {
                                                    deleteTag(tag.id);
                                                    setAtualizacao(atualizacao + 1);
                                                  }}>
                                                  X
                                                </button>
                                                <div className="nomeTag">{tag.description}</div>
                                              </div>
                                            );
                                          })}
                                        </div>
                                      </div>
                                    </div>
                                    {opcao?.questions?.length > 0 ? (
                                      opcao?.questions?.map((subPergunta) => {
                                        //SUBPERGUNTA 1
                                        return (
                                          <div className={classes.pergunta} key={subPergunta.id}>
                                            <button
                                              className="deleteRadioOption"
                                              onClick={() => {
                                                deletePergunta(subPergunta.id);
                                              }}>
                                              <FiTrash2 />
                                            </button>
                                            <div className={classes.barraLateral}></div>
                                            <div className="configuracaoPergunta">
                                              <div className="opcaoPergunta">
                                                <div className={classes.tituloOpcaoPergunta}>Titulo</div>
                                                <input
                                                  placeholder="Pressione para editar"
                                                  onBlur={(e) => changeNamePergunta(subPergunta.id, e.target.value)}
                                                  onFocus={(e) => {
                                                    changeNamePergunta(subPergunta.id, null);
                                                  }}
                                                  type="text"
                                                  value={subPergunta.description}
                                                  className={classes.inputTituloPergunta}
                                                />
                                              </div>
                                              <div className="opcaoPergunta">
                                                <div className={classes.tituloOpcaoPergunta}>Tipo de pergunta</div>
                                                <select
                                                  className={classes.selectTipoPergunta}
                                                  onChange={(e) => {
                                                    subPergunta.type = e.target.value;
                                                    subPergunta.options = [];
                                                    setAtualizacao(atualizacao + 1);
                                                  }}
                                                  value={subPergunta.type}>
                                                  <option value="1">Texto</option>
                                                  <option value="2">Seleção Única</option>
                                                  <option value="3">Checkbox</option>
                                                  <option value="4">Valor monetário</option>
                                                  <option value="6">Inteiro</option>
                                                  <option value="7">Decimal</option>
                                                  <option value="5">Porcentagem</option>
                                                </select>
                                                {subPergunta.type == "1" ? (
                                                  <div
                                                    className={classes.tipoPerguntaTexto}
                                                    contentEditable
                                                    suppressContentEditableWarning={true}
                                                    onBlur={(e) => {
                                                      if (e.target.innerHTML == "") {
                                                        e.target.innerHTML = "Insira a resposta";
                                                      }
                                                    }}
                                                    onMouseLeave={(e) => {}}
                                                    onClick={(e) => {
                                                      if (e.target.innerHTML == "Insira a resposta") e.target.innerHTML = "";
                                                    }}>
                                                    Insira a resposta
                                                  </div>
                                                ) : subPergunta.type == "2" ? (
                                                  <div className="containerTipoPerguntaOpcaoMultipla">
                                                    {subPergunta?.options?.length > 0 ? (
                                                      subPergunta?.options?.map((opcao1) => {
                                                        return (
                                                          <>
                                                            <div className="containerRadioOption" key={opcao1.id}>
                                                              <button
                                                                className="deleteRadioOption"
                                                                onClick={() => {
                                                                  deleteOpcao(opcao1.id);
                                                                }}>
                                                                <FiTrash2 />
                                                              </button>
                                                              <input type="radio" id="html" name="fav_language" value="HTML" />
                                                              <input
                                                                placeholder="Nome opção"
                                                                onBlur={(e) => changeNameOption(opcao1.id, e.target.value)}
                                                                type="text"
                                                                value={opcao1.description}
                                                                onFocus={(e) => {
                                                                  changeNameOption(opcao1.id, null);
                                                                }}
                                                                className={classes.inputOptionName}
                                                              />
                                                               {" "}
                                                              <button
                                                                className="addSubSection"
                                                                onClick={() => {
                                                                  addPergunta(opcao1.id);
                                                                  setAtualizacao(atualizacao + 1);
                                                                }}>
                                                                + Subpergunta
                                                              </button>
                                                              <div className={classes.containerTags}>
                                                                <div className="containerInfoTags">
                                                                  <AiOutlineInfoCircle title="Se essa opção possuir alguma tag adicionada, esta será enviada para o banco no momento que ela for selecionada por quem está preenchendo o portfolio." size={15} />
                                                                </div>
                                                                <form
                                                                  className="formTags"
                                                                  onSubmit={(e) => {
                                                                    e.preventDefault();
                                                                    addOptionTag(opcao1.id, e.target.firstChild.value);
                                                                    e.target.firstChild.value = "";
                                                                  }}>
                                                                  <input placeholder="Insira o nome da tag" type="text" className={classes.inputNameTag} />
                                                                  <input type="submit" className="addTagButton" value="+" />
                                                                </form>
                                                                <div className="containerDisplayTags">
                                                                  {opcao1?.tag?.map((tag) => {
                                                                    return (
                                                                      <div className="containerTag" key={tag.id}>
                                                                        <button
                                                                          className="deleteTagButton"
                                                                          onClick={(e) => {
                                                                            deleteTag(tag.id);
                                                                            setAtualizacao(atualizacao + 1);
                                                                          }}>
                                                                          X
                                                                        </button>
                                                                        <div className="nomeTag">{tag.description}</div>
                                                                      </div>
                                                                    );
                                                                  })}
                                                                </div>
                                                              </div>
                                                            </div>
                                                            {opcao1?.questions?.length > 0 ? (
                                                              opcao1?.questions?.map((subPergunta1) => {
                                                                //SUBPERGUNTA 2
                                                                return (
                                                                  <div className={classes.pergunta} key={subPergunta1.id}>
                                                                    <button
                                                                      className="deleteRadioOption"
                                                                      onClick={() => {
                                                                        deletePergunta(subPergunta1.id);
                                                                      }}>
                                                                      <FiTrash2 />
                                                                    </button>
                                                                    <div className={classes.barraLateral}></div>
                                                                    <div className="configuracaoPergunta">
                                                                      <div className="opcaoPergunta">
                                                                        <div className={classes.tituloOpcaoPergunta}>Titulo</div>
                                                                        <input
                                                                          placeholder="Pressione para editar"
                                                                          onBlur={(e) => changeNamePergunta(subPergunta1.id, e.target.value)}
                                                                          onFocus={(e) => {
                                                                            changeNamePergunta(subPergunta1.id, null);
                                                                          }}
                                                                          type="text"
                                                                          value={subPergunta1.description}
                                                                          className={classes.inputTituloPergunta}
                                                                        />
                                                                      </div>
                                                                      <div className="opcaoPergunta">
                                                                        <div className={classes.tituloOpcaoPergunta}>Tipo de pergunta</div>
                                                                        <select
                                                                          className={classes.selectTipoPergunta}
                                                                          onChange={(e) => {
                                                                            subPergunta1.type = e.target.value;
                                                                            subPergunta1.options = [];
                                                                            setAtualizacao(atualizacao + 1);
                                                                          }}
                                                                          value={subPergunta1.type}>
                                                                          <option value="1">Texto</option>
                                                                          <option value="2">Seleção Única</option>
                                                                          <option value="3">Checkbox</option>
                                                                          <option value="4">Valor monetário</option>
                                                                          <option value="6">Inteiro</option>
                                                                          <option value="7">Decimal</option>
                                                                          <option value="5">Porcentagem</option>
                                                                        </select>
                                                                        {subPergunta1.type == "1" ? (
                                                                          <div
                                                                            className={classes.tipoPerguntaTexto}
                                                                            contentEditable
                                                                            suppressContentEditableWarning={true}
                                                                            onBlur={(e) => {
                                                                              if (e.target.innerHTML == "") {
                                                                                e.target.innerHTML = "Insira a resposta";
                                                                              }
                                                                            }}
                                                                            onMouseLeave={(e) => {}}
                                                                            onClick={(e) => {
                                                                              if (e.target.innerHTML == "Insira a resposta") e.target.innerHTML = "";
                                                                            }}>
                                                                            Insira a resposta
                                                                          </div>
                                                                        ) : subPergunta1.type == "2" ? (
                                                                          <div className="containerTipoPerguntaOpcaoMultipla">
                                                                            {subPergunta1?.options?.length > 0 ? (
                                                                              subPergunta1?.options?.map((opcao2) => {
                                                                                return (
                                                                                  <>
                                                                                    <div className="containerRadioOption" key={opcao2.id}>
                                                                                      <button
                                                                                        className="deleteRadioOption"
                                                                                        onClick={() => {
                                                                                          deleteOpcao(opcao2.id);
                                                                                        }}>
                                                                                        <FiTrash2 />
                                                                                      </button>
                                                                                      <input type="radio" id="html" name="fav_language" value="HTML" />
                                                                                      <input
                                                                                        placeholder="Nome opção"
                                                                                        onBlur={(e) => changeNameOption(opcao2.id, e.target.value)}
                                                                                        onFocus={(e) => {
                                                                                          changeNameOption(opcao2.id, null);
                                                                                        }}
                                                                                        type="text"
                                                                                        value={opcao2.description}
                                                                                        className={classes.inputOptionName}
                                                                                      />
                                                                                       {" "}
                                                                                      <button
                                                                                        className="addSubSection"
                                                                                        onClick={() => {
                                                                                          addPergunta(opcao2.id);
                                                                                          setAtualizacao(atualizacao + 1);
                                                                                        }}>
                                                                                        + Subpergunta
                                                                                      </button>
                                                                                      <div className={classes.containerTags}>
                                                                                        <div className="containerInfoTags">
                                                                                          <AiOutlineInfoCircle title="Se essa opção possuir alguma tag adicionada, esta será enviada para o banco no momento que ela for selecionada por quem está preenchendo o portfolio." size={15} />
                                                                                        </div>
                                                                                        <form
                                                                                          className="formTags"
                                                                                          onSubmit={(e) => {
                                                                                            e.preventDefault();
                                                                                            addOptionTag(opcao2.id, e.target.firstChild.value);
                                                                                            e.target.firstChild.value = "";
                                                                                          }}>
                                                                                          <input placeholder="Insira o nome da tag" type="text" className={classes.inputNameTag} />

                                                                                          <input type="submit" className="addTagButton" value="+" />
                                                                                        </form>
                                                                                        <div className="containerDisplayTags">
                                                                                          {opcao2?.tag?.map((tag) => {
                                                                                            return (
                                                                                              <div className="containerTag" key={tag.id}>
                                                                                                <button
                                                                                                  className="deleteTagButton"
                                                                                                  onClick={(e) => {
                                                                                                    deleteTag(tag.id);
                                                                                                    setAtualizacao(atualizacao + 1);
                                                                                                  }}>
                                                                                                  X
                                                                                                </button>
                                                                                                <div className="nomeTag">{tag.description}</div>
                                                                                              </div>
                                                                                            );
                                                                                          })}
                                                                                        </div>
                                                                                      </div>
                                                                                    </div>
                                                                                    {opcao2?.questions?.length > 0 ? (
                                                                                      opcao2?.questions?.map((subPergunta2) => {
                                                                                        //SUBPERGUNTA 3
                                                                                        return (
                                                                                          <div className={classes.pergunta} key={subPergunta2.id}>
                                                                                            <button
                                                                                              className="deleteRadioOption"
                                                                                              onClick={() => {
                                                                                                deletePergunta(subPergunta2.id);
                                                                                              }}>
                                                                                              <FiTrash2 />
                                                                                            </button>
                                                                                            <div className={classes.barraLateral}></div>
                                                                                            <div className="configuracaoPergunta">
                                                                                              <div className="opcaoPergunta">
                                                                                                <div className={classes.tituloOpcaoPergunta}>Titulo</div>
                                                                                                <input
                                                                                                  placeholder="Pressione para editar"
                                                                                                  onBlur={(e) => changeNamePergunta(subPergunta2.id, e.target.value)}
                                                                                                  onFocus={(e) => {
                                                                                                    changeNamePergunta(subPergunta2.id, null);
                                                                                                  }}
                                                                                                  type="text"
                                                                                                  value={subPergunta2.description}
                                                                                                  className={classes.inputTituloPergunta}
                                                                                                />
                                                                                              </div>
                                                                                              <div className="opcaoPergunta">
                                                                                                <div className={classes.tituloOpcaoPergunta}>Tipo de pergunta</div>
                                                                                                <select
                                                                                                  className={classes.selectTipoPergunta}
                                                                                                  onChange={(e) => {
                                                                                                    subPergunta2.type = e.target.value;
                                                                                                    subPergunta2.options = [];
                                                                                                    setAtualizacao(atualizacao + 1);
                                                                                                  }}
                                                                                                  value={subPergunta2.type}>
                                                                                                  <option value="1">Texto</option>
                                                                                                  <option value="2">Seleção Única</option>
                                                                                                  <option value="3">Checkbox</option>
                                                                                                  <option value="4">Valor monetário</option>
                                                                                                  <option value="6">Inteiro</option>
                                                                                                  <option value="7">Decimal</option>
                                                                                                  <option value="5">Porcentagem</option>
                                                                                                </select>
                                                                                                {subPergunta2.type == "1" ? (
                                                                                                  <div
                                                                                                    className={classes.tipoPerguntaTexto}
                                                                                                    contentEditable
                                                                                                    suppressContentEditableWarning={true}
                                                                                                    onBlur={(e) => {
                                                                                                      if (e.target.innerHTML == "") {
                                                                                                        e.target.innerHTML = "Insira a resposta";
                                                                                                      }
                                                                                                    }}
                                                                                                    onMouseLeave={(e) => {}}
                                                                                                    onClick={(e) => {
                                                                                                      if (e.target.innerHTML == "Insira a resposta") e.target.innerHTML = "";
                                                                                                    }}>
                                                                                                    Insira a resposta
                                                                                                  </div>
                                                                                                ) : subPergunta2.type == "2" ? (
                                                                                                  <div className="containerTipoPerguntaOpcaoMultipla">
                                                                                                    {subPergunta2?.options?.length > 0 ? (
                                                                                                      subPergunta2?.options?.map((opcao3) => {
                                                                                                        return (
                                                                                                          <>
                                                                                                            <div className="containerRadioOption" key={opcao3.id}>
                                                                                                              <button
                                                                                                                className="deleteRadioOption"
                                                                                                                onClick={() => {
                                                                                                                  deleteOpcao(opcao3.id);
                                                                                                                }}>
                                                                                                                <FiTrash2 />
                                                                                                              </button>
                                                                                                              <input type="radio" id="html" name="fav_language" value="HTML" />
                                                                                                              <input
                                                                                                                placeholder="Nome opção"
                                                                                                                onBlur={(e) => changeNameOption(opcao3.id, e.target.value)}
                                                                                                                onFocus={(e) => {
                                                                                                                  changeNameOption(opcao3.id, null);
                                                                                                                }}
                                                                                                                type="text"
                                                                                                                value={opcao3.description}
                                                                                                                className={classes.inputOptionName}
                                                                                                              />
                                                                                                              <div className={classes.containerTags}>
                                                                                                                <div className="containerInfoTags">
                                                                                                                  <AiOutlineInfoCircle title="Se essa opção possuir alguma tag adicionada, esta será enviada para o banco no momento que ela for selecionada por quem está preenchendo o portfolio." size={15} />
                                                                                                                </div>

                                                                                                                <form
                                                                                                                  className="formTags"
                                                                                                                  onSubmit={(e) => {
                                                                                                                    e.preventDefault();
                                                                                                                    addOptionTag(opcao3.id, e.target.firstChild.value);
                                                                                                                    e.target.firstChild.value = "";
                                                                                                                  }}>
                                                                                                                  <input placeholder="Insira o nome da tag" type="text" className={classes.inputNameTag} />

                                                                                                                  <input type="submit" className="addTagButton" value="+" />
                                                                                                                </form>
                                                                                                                <div className="containerDisplayTags">
                                                                                                                  {opcao3?.tag?.map((tag) => {
                                                                                                                    return (
                                                                                                                      <div className="containerTag" key={tag.id}>
                                                                                                                        <button
                                                                                                                          className="deleteTagButton"
                                                                                                                          onClick={(e) => {
                                                                                                                            deleteTag(tag.id);
                                                                                                                            setAtualizacao(atualizacao + 1);
                                                                                                                          }}>
                                                                                                                          X
                                                                                                                        </button>
                                                                                                                        <div className="nomeTag">{tag.description}</div>
                                                                                                                      </div>
                                                                                                                    );
                                                                                                                  })}
                                                                                                                </div>
                                                                                                              </div>
                                                                                                            </div>
                                                                                                          </>
                                                                                                        );
                                                                                                      })
                                                                                                    ) : (
                                                                                                      <></>
                                                                                                    )}
                                                                                                    <button
                                                                                                      className="addRadioOption"
                                                                                                      onClick={() => {
                                                                                                        addOpcao(subPergunta2.id);
                                                                                                      }}>
                                                                                                      + Adicionar opção
                                                                                                    </button>
                                                                                                  </div>
                                                                                                ) : subPergunta2.type == "3" ? (
                                                                                                  <div className="containerTipoPerguntaCaixaSelecao">
                                                                                                    {subPergunta2?.options?.length > 0 ? (
                                                                                                      subPergunta2?.options?.map((opcao3) => {
                                                                                                        return (
                                                                                                          <>
                                                                                                            <div className="containerCheckBoxOption" key={opcao3.id}>
                                                                                                              <button
                                                                                                                className="deleteCheckBoxOption"
                                                                                                                onClick={() => {
                                                                                                                  deleteOpcao(opcao3.id);
                                                                                                                }}>
                                                                                                                <FiTrash2 />
                                                                                                              </button>
                                                                                                              <input type="Checkbox" id="3" name="3" />
                                                                                                              <input
                                                                                                                placeholder="Nome opção"
                                                                                                                onBlur={(e) => changeNameOption(opcao3.id, e.target.value)}
                                                                                                                onFocus={(e) => {
                                                                                                                  changeNameOption(opcao3.id, null);
                                                                                                                }}
                                                                                                                type="text"
                                                                                                                value={opcao3.description}
                                                                                                                className={classes.inputOptionName}
                                                                                                              />
                                                                                                              <div className={classes.containerTags}>
                                                                                                                <div className="containerInfoTags">
                                                                                                                  <AiOutlineInfoCircle title="Se essa opção possuir alguma tag adicionada, esta será enviada para o banco no momento que ela for selecionada por quem está preenchendo o portfolio." size={15} />
                                                                                                                </div>
                                                                                                                <form
                                                                                                                  className="formTags"
                                                                                                                  onSubmit={(e) => {
                                                                                                                    e.preventDefault();
                                                                                                                    addOptionTag(opcao3.id, e.target.firstChild.value);
                                                                                                                    e.target.firstChild.value = "";
                                                                                                                  }}>
                                                                                                                  <input placeholder="Insira o nome da tag" type="text" className={classes.inputNameTag} />

                                                                                                                  <input type="submit" className="addTagButton" value="+" />
                                                                                                                </form>
                                                                                                                <div className="containerDisplayTags">
                                                                                                                  {opcao3?.tag?.map((tag) => {
                                                                                                                    return (
                                                                                                                      <div className="containerTag" key={tag.id}>
                                                                                                                        <button
                                                                                                                          className="deleteTagButton"
                                                                                                                          onClick={(e) => {
                                                                                                                            deleteTag(tag.id);
                                                                                                                            setAtualizacao(atualizacao + 1);
                                                                                                                          }}>
                                                                                                                          X
                                                                                                                        </button>
                                                                                                                        <div className="nomeTag">{tag.description}</div>
                                                                                                                      </div>
                                                                                                                    );
                                                                                                                  })}
                                                                                                                </div>
                                                                                                              </div>
                                                                                                            </div>
                                                                                                          </>
                                                                                                        );
                                                                                                      })
                                                                                                    ) : (
                                                                                                      <></>
                                                                                                    )}
                                                                                                    <button
                                                                                                      className="addCheckBoxOption"
                                                                                                      onClick={() => {
                                                                                                        addOpcao(subPergunta2.id);
                                                                                                      }}>
                                                                                                      + Adicionar opção
                                                                                                    </button>
                                                                                                  </div>
                                                                                                ) : subPergunta2.type == "4" ? (
                                                                                                  <div>
                                                                                                    <CurrencyTextField
                                                                                                      InputProps={{
                                                                                                        classes: {
                                                                                                          notchedOutline: classes.notchedOutline,
                                                                                                        },
                                                                                                      }}
                                                                                                      variant="outlined"
                                                                                                      value={precoTemp}
                                                                                                      className="tipoPerguntaMonetario"
                                                                                                      currencySymbol="R$"
                                                                                                      outputFormat="number"
                                                                                                      decimalCharacter=","
                                                                                                      required={true}
                                                                                                      digitGroupSeparator="."
                                                                                                      onChange={(event, value) => {
                                                                                                        if (value === "" || value === 0) {
                                                                                                          setPrecoTemp(0);
                                                                                                        } else {
                                                                                                          setPrecoTemp(value);
                                                                                                        }
                                                                                                      }}
                                                                                                    />
                                                                                                  </div>
                                                                                                ) : subPergunta2.type == "5" ? (
                                                                                                  <div className="containerTipoPerguntaPorcentagem">
                                                                                                    <TextField
                                                                                                      InputProps={{
                                                                                                        classes: {
                                                                                                          notchedOutline: classes.notchedOutline,
                                                                                                        },
                                                                                                      }}
                                                                                                      type="number"
                                                                                                      onKeyPress={(e) => {
                                                                                                        if (e.code === "Backquote") e.preventDefault();
                                                                                                      }}
                                                                                                      step="0.00"
                                                                                                      onKeyDown={(e) => {
                                                                                                        if (e.key === "e" || e.key === "E") {
                                                                                                          e.preventDefault();
                                                                                                        }
                                                                                                      }}
                                                                                                      value={percentTemp}
                                                                                                      className="tipoPerguntaPorcentagem"
                                                                                                      variant="outlined"
                                                                                                      onChange={(e) => {
                                                                                                        setPercentTemp(e.target.value);
                                                                                                      }}
                                                                                                    />
                                                                                                    <div className="porcentagem">%</div>
                                                                                                  </div>
                                                                                                ) : subPergunta2.type == "6" ? (
                                                                                                  <div className="containerTipoPerguntaPorcentagem">
                                                                                                    <TextField
                                                                                                      
                                                                                                      InputProps={{ classes: { notchedOutline: classes.notchedOutline } }}
                                                                                                      type="number"
                                                                                                      onKeyPress={(e) => {
                                                                                                        if (e.key === "." || e.key === ",") e.preventDefault();
                                                                                                      }}
                                                                                                      value={inteiroTemp}
                                                                                                      className="tipoPerguntaPorcentagem"
                                                                                                      variant="outlined"
                                                                                                      onChange={(e) => {
                                                                                                        setInteiroTemp(e.target.value);
                                                                                                      }}
                                                                                                    />
                                                                                                  </div>
                                                                                                ) : subPergunta2.type == "7" ? (
                                                                                                  <div className="containerTipoPerguntaPorcentagem">
                                                                                                    <TextField
                                                                                                      
                                                                                                      InputProps={{ classes: { notchedOutline: classes.notchedOutline } }}
                                                                                                      type="number"
                                                                                                      value={decimalTemp}
                                                                                                      className="tipoPerguntaPorcentagem"
                                                                                                      variant="outlined"
                                                                                                      onChange={(e) => {
                                                                                                        setDecimalTemp(e.target.value);
                                                                                                      }}
                                                                                                    />
                                                                                                  </div>
                                                                                                ) : (
                                                                                                  <></>
                                                                                                )}
                                                                                              </div>
                                                                                              <div className="habilitarObservacao">
                                                                                                <input
                                                                                                  checked={subPergunta2.observation}
                                                                                                  onChange={(e) => {
                                                                                                    changeObservation(subPergunta2.id);
                                                                                                  }}
                                                                                                  type="Checkbox"
                                                                                                  id={"Checkbox" + subPergunta2.id}
                                                                                                  name="Checkbox"
                                                                                                />
                                                                                                <label for={"Checkbox" + subPergunta2.id}> Adicionar campo de observação?</label>
                                                                                              </div>
                                                                                            </div>
                                                                                          </div>
                                                                                        );
                                                                                        //SUBPERGUNTA 3
                                                                                      })
                                                                                    ) : (
                                                                                      <></>
                                                                                    )}
                                                                                  </>
                                                                                );
                                                                              })
                                                                            ) : (
                                                                              <></>
                                                                            )}
                                                                            <button
                                                                              className="addRadioOption"
                                                                              onClick={() => {
                                                                                addOpcao(subPergunta1.id);
                                                                              }}>
                                                                              + Adicionar opção
                                                                            </button>
                                                                          </div>
                                                                        ) : subPergunta1.type == "3" ? (
                                                                          <div className="containerTipoPerguntaOpcaoMultipla">
                                                                            {subPergunta1?.options?.length > 0 ? (
                                                                              subPergunta1?.options?.map((opcao2) => {
                                                                                return (
                                                                                  <>
                                                                                    <div className="containerRadioOption" key={opcao2.id}>
                                                                                      <button
                                                                                        className="deleteRadioOption"
                                                                                        onClick={() => {
                                                                                          deleteOpcao(opcao2.id);
                                                                                        }}>
                                                                                        <FiTrash2 />
                                                                                      </button>
                                                                                      <input type="Checkbox" id="3" name="3" />
                                                                                      <input
                                                                                        placeholder="Nome opção"
                                                                                        onBlur={(e) => changeNameOption(opcao2.id, e.target.value)}
                                                                                        onFocus={(e) => {
                                                                                          changeNameOption(opcao2.id, null);
                                                                                        }}
                                                                                        type="text"
                                                                                        value={opcao2.description}
                                                                                        className={classes.inputOptionName}
                                                                                      />
                                                                                       {" "}
                                                                                      <button
                                                                                        className="addSubSection"
                                                                                        onClick={() => {
                                                                                          addPergunta(opcao2.id);
                                                                                          setAtualizacao(atualizacao + 1);
                                                                                        }}>
                                                                                        + Subpergunta
                                                                                      </button>
                                                                                      <div className={classes.containerTags}>
                                                                                        <div className="containerInfoTags">
                                                                                          <AiOutlineInfoCircle title="Se essa opção possuir alguma tag adicionada, esta será enviada para o banco no momento que ela for selecionada por quem está preenchendo o portfolio." size={15} />
                                                                                        </div>

                                                                                        <form
                                                                                          className="formTags"
                                                                                          onSubmit={(e) => {
                                                                                            e.preventDefault();
                                                                                            addOptionTag(opcao2.id, e.target.firstChild.value);
                                                                                            e.target.firstChild.value = "";
                                                                                          }}>
                                                                                          <input placeholder="Insira o nome da tag" type="text" className={classes.inputNameTag} />

                                                                                          <input type="submit" className="addTagButton" value="+" />
                                                                                        </form>
                                                                                        <div className="containerDisplayTags">
                                                                                          {opcao2?.tag?.map((tag) => {
                                                                                            return (
                                                                                              <div className="containerTag" key={tag.id}>
                                                                                                <button
                                                                                                  className="deleteTagButton"
                                                                                                  onClick={(e) => {
                                                                                                    deleteTag(tag.id);
                                                                                                    setAtualizacao(atualizacao + 1);
                                                                                                  }}>
                                                                                                  X
                                                                                                </button>
                                                                                                <div className="nomeTag">{tag.description}</div>
                                                                                              </div>
                                                                                            );
                                                                                          })}
                                                                                        </div>
                                                                                      </div>
                                                                                    </div>
                                                                                    {opcao2?.questions?.length > 0 ? (
                                                                                      opcao2?.questions?.map((subPergunta2) => {
                                                                                        //SUBPERGUNTA 3
                                                                                        return (
                                                                                          <div className={classes.pergunta} key={subPergunta2.id}>
                                                                                            <button
                                                                                              className="deleteRadioOption"
                                                                                              onClick={() => {
                                                                                                deletePergunta(subPergunta2.id);
                                                                                              }}>
                                                                                              <FiTrash2 />
                                                                                            </button>
                                                                                            <div className={classes.barraLateral}></div>
                                                                                            <div className="configuracaoPergunta">
                                                                                              <div className="opcaoPergunta">
                                                                                                <div className={classes.tituloOpcaoPergunta}>Titulo</div>
                                                                                                <input
                                                                                                  placeholder="Pressione para editar"
                                                                                                  onBlur={(e) => changeNamePergunta(subPergunta2.id, e.target.value)}
                                                                                                  onFocus={(e) => {
                                                                                                    changeNamePergunta(subPergunta2.id, null);
                                                                                                  }}
                                                                                                  type="text"
                                                                                                  value={subPergunta2.description}
                                                                                                  className={classes.inputTituloPergunta}
                                                                                                />
                                                                                              </div>
                                                                                              <div className="opcaoPergunta">
                                                                                                <div className={classes.tituloOpcaoPergunta}>Tipo de pergunta</div>
                                                                                                <select
                                                                                                  className={classes.selectTipoPergunta}
                                                                                                  onChange={(e) => {
                                                                                                    subPergunta2.type = e.target.value;
                                                                                                    subPergunta2.options = [];
                                                                                                    setAtualizacao(atualizacao + 1);
                                                                                                  }}
                                                                                                  value={subPergunta2.type}>
                                                                                                  <option value="1">Texto</option>
                                                                                                  <option value="2">Seleção Única</option>
                                                                                                  <option value="3">Checkbox</option>
                                                                                                  <option value="4">Valor monetário</option>
                                                                                                  <option value="6">Inteiro</option>
                                                                                                  <option value="7">Decimal</option>
                                                                                                  <option value="5">Porcentagem</option>
                                                                                                </select>
                                                                                                {subPergunta2.type == "1" ? (
                                                                                                  <div
                                                                                                    className={classes.tipoPerguntaTexto}
                                                                                                    contentEditable
                                                                                                    suppressContentEditableWarning={true}
                                                                                                    onBlur={(e) => {
                                                                                                      if (e.target.innerHTML == "") {
                                                                                                        e.target.innerHTML = "Insira a resposta";
                                                                                                      }
                                                                                                    }}
                                                                                                    onMouseLeave={(e) => {}}
                                                                                                    onClick={(e) => {
                                                                                                      if (e.target.innerHTML == "Insira a resposta") e.target.innerHTML = "";
                                                                                                    }}>
                                                                                                    Insira a resposta
                                                                                                  </div>
                                                                                                ) : subPergunta2.type == "2" ? (
                                                                                                  <div className="containerTipoPerguntaOpcaoMultipla">
                                                                                                    {subPergunta2?.options?.length > 0 ? (
                                                                                                      subPergunta2?.options?.map((opcao3) => {
                                                                                                        return (
                                                                                                          <>
                                                                                                            <div className="containerRadioOption" key={opcao3.id}>
                                                                                                              <button
                                                                                                                className="deleteRadioOption"
                                                                                                                onClick={() => {
                                                                                                                  deleteOpcao(opcao3.id);
                                                                                                                }}>
                                                                                                                <FiTrash2 />
                                                                                                              </button>
                                                                                                              <input type="radio" id="html" name="fav_language" value="HTML" />
                                                                                                              <input
                                                                                                                placeholder="Nome opção"
                                                                                                                onBlur={(e) => changeNameOption(opcao3.id, e.target.value)}
                                                                                                                onFocus={(e) => {
                                                                                                                  changeNameOption(opcao3.id, null);
                                                                                                                }}
                                                                                                                type="text"
                                                                                                                value={opcao3.description}
                                                                                                                className={classes.inputOptionName}
                                                                                                              />
                                                                                                              <div className={classes.containerTags}>
                                                                                                                <div className="containerInfoTags">
                                                                                                                  <AiOutlineInfoCircle title="Se essa opção possuir alguma tag adicionada, esta será enviada para o banco no momento que ela for selecionada por quem está preenchendo o portfolio." size={15} />
                                                                                                                </div>

                                                                                                                <form
                                                                                                                  className="formTags"
                                                                                                                  onSubmit={(e) => {
                                                                                                                    e.preventDefault();
                                                                                                                    addOptionTag(opcao3.id, e.target.firstChild.value);
                                                                                                                    e.target.firstChild.value = "";
                                                                                                                  }}>
                                                                                                                  <input placeholder="Insira o nome da tag" type="text" className={classes.inputNameTag} />

                                                                                                                  <input type="submit" className="addTagButton" value="+" />
                                                                                                                </form>
                                                                                                                <div className="containerDisplayTags">
                                                                                                                  {opcao3?.tag?.map((tag) => {
                                                                                                                    return (
                                                                                                                      <div className="containerTag" key={tag.id}>
                                                                                                                        <button
                                                                                                                          className="deleteTagButton"
                                                                                                                          onClick={(e) => {
                                                                                                                            deleteTag(tag.id);
                                                                                                                            setAtualizacao(atualizacao + 1);
                                                                                                                          }}>
                                                                                                                          X
                                                                                                                        </button>
                                                                                                                        <div className="nomeTag">{tag.description}</div>
                                                                                                                      </div>
                                                                                                                    );
                                                                                                                  })}
                                                                                                                </div>
                                                                                                              </div>
                                                                                                            </div>
                                                                                                          </>
                                                                                                        );
                                                                                                      })
                                                                                                    ) : (
                                                                                                      <></>
                                                                                                    )}
                                                                                                    <button
                                                                                                      className="addRadioOption"
                                                                                                      onClick={() => {
                                                                                                        addOpcao(subPergunta2.id);
                                                                                                      }}>
                                                                                                      + Adicionar opção
                                                                                                    </button>
                                                                                                  </div>
                                                                                                ) : subPergunta2.type == "3" ? (
                                                                                                  <div className="containerTipoPerguntaCaixaSelecao">
                                                                                                    {subPergunta2?.options?.length > 0 ? (
                                                                                                      subPergunta2?.options?.map((opcao3) => {
                                                                                                        return (
                                                                                                          <>
                                                                                                            <div className="containerCheckBoxOption" key={opcao3.id}>
                                                                                                              <button
                                                                                                                className="deleteCheckBoxOption"
                                                                                                                onClick={() => {
                                                                                                                  deleteOpcao(opcao3.id);
                                                                                                                }}>
                                                                                                                <FiTrash2 />
                                                                                                              </button>
                                                                                                              <input type="Checkbox" id="3" name="3" />
                                                                                                              <input
                                                                                                                placeholder="Nome opção"
                                                                                                                onBlur={(e) => changeNameOption(opcao3.id, e.target.value)}
                                                                                                                onFocus={(e) => {
                                                                                                                  changeNameOption(opcao3.id, null);
                                                                                                                }}
                                                                                                                type="text"
                                                                                                                value={opcao3.description}
                                                                                                                className={classes.inputOptionName}
                                                                                                              />
                                                                                                              <div className={classes.containerTags}>
                                                                                                                <div className="containerInfoTags">
                                                                                                                  <AiOutlineInfoCircle title="Se essa opção possuir alguma tag adicionada, esta será enviada para o banco no momento que ela for selecionada por quem está preenchendo o portfolio." size={15} />
                                                                                                                </div>

                                                                                                                <form
                                                                                                                  className="formTags"
                                                                                                                  onSubmit={(e) => {
                                                                                                                    e.preventDefault();
                                                                                                                    addOptionTag(opcao3.id, e.target.firstChild.value);
                                                                                                                    e.target.firstChild.value = "";
                                                                                                                  }}>
                                                                                                                  <input placeholder="Insira o nome da tag" type="text" className={classes.inputNameTag} />

                                                                                                                  <input type="submit" className="addTagButton" value="+" />
                                                                                                                </form>
                                                                                                                <div className="containerDisplayTags">
                                                                                                                  {opcao3?.tag?.map((tag) => {
                                                                                                                    return (
                                                                                                                      <div className="containerTag" key={tag.id}>
                                                                                                                        <button
                                                                                                                          className="deleteTagButton"
                                                                                                                          onClick={(e) => {
                                                                                                                            deleteTag(tag.id);
                                                                                                                            setAtualizacao(atualizacao + 1);
                                                                                                                          }}>
                                                                                                                          X
                                                                                                                        </button>
                                                                                                                        <div className="nomeTag">{tag.description}</div>
                                                                                                                      </div>
                                                                                                                    );
                                                                                                                  })}
                                                                                                                </div>
                                                                                                              </div>
                                                                                                            </div>
                                                                                                          </>
                                                                                                        );
                                                                                                      })
                                                                                                    ) : (
                                                                                                      <></>
                                                                                                    )}
                                                                                                    <button
                                                                                                      className="addCheckBoxOption"
                                                                                                      onClick={() => {
                                                                                                        addOpcao(subPergunta2.id);
                                                                                                      }}>
                                                                                                      + Adicionar opção
                                                                                                    </button>
                                                                                                  </div>
                                                                                                ) : subPergunta2.type == "4" ? (
                                                                                                  <div>
                                                                                                    <CurrencyTextField
                                                                                                      InputProps={{
                                                                                                        classes: {
                                                                                                          notchedOutline: classes.notchedOutline,
                                                                                                        },
                                                                                                      }}
                                                                                                      variant="outlined"
                                                                                                      value={precoTemp}
                                                                                                      className="tipoPerguntaMonetario"
                                                                                                      currencySymbol="R$"
                                                                                                      outputFormat="number"
                                                                                                      decimalCharacter=","
                                                                                                      required={true}
                                                                                                      digitGroupSeparator="."
                                                                                                      onChange={(event, value) => {
                                                                                                        if (value === "" || value === 0) {
                                                                                                          setPrecoTemp(0);
                                                                                                        } else {
                                                                                                          setPrecoTemp(value);
                                                                                                        }
                                                                                                      }}
                                                                                                    />
                                                                                                  </div>
                                                                                                ) : subPergunta2.type == "5" ? (
                                                                                                  <div className="containerTipoPerguntaPorcentagem">
                                                                                                    <TextField
                                                                                                      InputProps={{
                                                                                                        classes: {
                                                                                                          notchedOutline: classes.notchedOutline,
                                                                                                        },
                                                                                                      }}
                                                                                                      type="number"
                                                                                                      onKeyPress={(e) => {
                                                                                                        if (e.code === "Backquote") e.preventDefault();
                                                                                                      }}
                                                                                                      step="0.00"
                                                                                                      onKeyDown={(e) => {
                                                                                                        if (e.key === "e" || e.key === "E") {
                                                                                                          e.preventDefault();
                                                                                                        }
                                                                                                      }}
                                                                                                      value={percentTemp}
                                                                                                      className="tipoPerguntaPorcentagem"
                                                                                                      variant="outlined"
                                                                                                      onChange={(e) => {
                                                                                                        setPercentTemp(e.target.value);
                                                                                                      }}
                                                                                                    />
                                                                                                    <div className="porcentagem">%</div>
                                                                                                  </div>
                                                                                                ) : subPergunta2.type == "6" ? (
                                                                                                  <div className="containerTipoPerguntaPorcentagem">
                                                                                                    <TextField
                                                                                                      
                                                                                                      InputProps={{ classes: { notchedOutline: classes.notchedOutline } }}
                                                                                                      type="number"
                                                                                                      onKeyPress={(e) => {
                                                                                                        if (e.key === "." || e.key === ",") e.preventDefault();
                                                                                                      }}
                                                                                                      value={inteiroTemp}
                                                                                                      className="tipoPerguntaPorcentagem"
                                                                                                      variant="outlined"
                                                                                                      onChange={(e) => {
                                                                                                        setInteiroTemp(e.target.value);
                                                                                                      }}
                                                                                                    />
                                                                                                  </div>
                                                                                                ) : subPergunta2.type == "7" ? (
                                                                                                  <div className="containerTipoPerguntaPorcentagem">
                                                                                                    <TextField
                                                                                                      
                                                                                                      InputProps={{ classes: { notchedOutline: classes.notchedOutline } }}
                                                                                                      type="number"
                                                                                                      value={decimalTemp}
                                                                                                      className="tipoPerguntaPorcentagem"
                                                                                                      variant="outlined"
                                                                                                      onChange={(e) => {
                                                                                                        setDecimalTemp(e.target.value);
                                                                                                      }}
                                                                                                    />
                                                                                                  </div>
                                                                                                ) : (
                                                                                                  <></>
                                                                                                )}
                                                                                              </div>
                                                                                              <div className="habilitarObservacao">
                                                                                                <input
                                                                                                  checked={subPergunta2.observation}
                                                                                                  onChange={(e) => {
                                                                                                    changeObservation(subPergunta2.id);
                                                                                                  }}
                                                                                                  type="Checkbox"
                                                                                                  id={"Checkbox" + subPergunta2.id}
                                                                                                  name="Checkbox"
                                                                                                />
                                                                                                <label for={"Checkbox" + subPergunta2.id}> Adicionar campo de observação?</label>
                                                                                              </div>
                                                                                            </div>
                                                                                          </div>
                                                                                        );
                                                                                        //SUBPERGUNTA 3
                                                                                      })
                                                                                    ) : (
                                                                                      <></>
                                                                                    )}
                                                                                  </>
                                                                                );
                                                                              })
                                                                            ) : (
                                                                              <></>
                                                                            )}
                                                                            <button
                                                                              className="addRadioOption"
                                                                              onClick={() => {
                                                                                addOpcao(subPergunta1.id);
                                                                              }}>
                                                                              + Adicionar opção
                                                                            </button>
                                                                          </div>
                                                                        ) : subPergunta1.type == "4" ? (
                                                                          <div>
                                                                            <CurrencyTextField
                                                                              InputProps={{
                                                                                classes: {
                                                                                  notchedOutline: classes.notchedOutline,
                                                                                },
                                                                              }}
                                                                              variant="outlined"
                                                                              value={precoTemp}
                                                                              className="tipoPerguntaMonetario"
                                                                              currencySymbol="R$"
                                                                              outputFormat="number"
                                                                              decimalCharacter=","
                                                                              required={true}
                                                                              digitGroupSeparator="."
                                                                              onChange={(event, value) => {
                                                                                if (value === "" || value === 0) {
                                                                                  setPrecoTemp(0);
                                                                                } else {
                                                                                  setPrecoTemp(value);
                                                                                }
                                                                              }}
                                                                            />
                                                                          </div>
                                                                        ) : subPergunta1.type == "5" ? (
                                                                          <div className="containerTipoPerguntaPorcentagem">
                                                                            <TextField
                                                                              InputProps={{
                                                                                classes: {
                                                                                  notchedOutline: classes.notchedOutline,
                                                                                },
                                                                              }}
                                                                              type="number"
                                                                              onKeyPress={(e) => {
                                                                                if (e.code === "Backquote") e.preventDefault();
                                                                              }}
                                                                              step="0.00"
                                                                              onKeyDown={(e) => {
                                                                                if (e.key === "e" || e.key === "E") {
                                                                                  e.preventDefault();
                                                                                }
                                                                              }}
                                                                              value={percentTemp}
                                                                              className="tipoPerguntaPorcentagem"
                                                                              variant="outlined"
                                                                              onChange={(e) => {
                                                                                setPercentTemp(e.target.value);
                                                                              }}
                                                                            />
                                                                            <div className="porcentagem">%</div>
                                                                          </div>
                                                                        ) : subPergunta1.type == "6" ? (
                                                                          <div className="containerTipoPerguntaPorcentagem">
                                                                            <TextField
                                                                              
                                                                              InputProps={{ classes: { notchedOutline: classes.notchedOutline } }}
                                                                              type="number"
                                                                              onKeyPress={(e) => {
                                                                                if (e.key === "." || e.key === ",") e.preventDefault();
                                                                              }}
                                                                              value={inteiroTemp}
                                                                              className="tipoPerguntaPorcentagem"
                                                                              variant="outlined"
                                                                              onChange={(e) => {
                                                                                setInteiroTemp(e.target.value);
                                                                              }}
                                                                            />
                                                                          </div>
                                                                        ) : subPergunta1.type == "7" ? (
                                                                          <div className="containerTipoPerguntaPorcentagem">
                                                                            <TextField
                                                                              
                                                                              InputProps={{ classes: { notchedOutline: classes.notchedOutline } }}
                                                                              type="number"
                                                                              value={decimalTemp}
                                                                              className="tipoPerguntaPorcentagem"
                                                                              variant="outlined"
                                                                              onChange={(e) => {
                                                                                setDecimalTemp(e.target.value);
                                                                              }}
                                                                            />
                                                                          </div>
                                                                        ) : (
                                                                          <></>
                                                                        )}
                                                                      </div>
                                                                      <div className="habilitarObservacao">
                                                                        <input
                                                                          checked={subPergunta1.observation}
                                                                          onChange={(e) => {
                                                                            changeObservation(subPergunta1.id);
                                                                          }}
                                                                          type="Checkbox"
                                                                          id={"Checkbox" + subPergunta1.id}
                                                                          name="Checkbox"
                                                                        />
                                                                        <label for={"Checkbox" + subPergunta1.id}> Adicionar campo de observação?</label>
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                );
                                                                //SUBPERGUNTA 2
                                                              })
                                                            ) : (
                                                              <></>
                                                            )}
                                                          </>
                                                        );
                                                      })
                                                    ) : (
                                                      <></>
                                                    )}
                                                    <button
                                                      className="addRadioOption"
                                                      onClick={() => {
                                                        addOpcao(subPergunta.id);
                                                      }}>
                                                      + Adicionar opção
                                                    </button>
                                                  </div>
                                                ) : subPergunta.type == "3" ? (
                                                  <div className="containerTipoPerguntaOpcaoMultipla">
                                                    {subPergunta?.options?.length > 0 ? (
                                                      subPergunta?.options?.map((opcao1) => {
                                                        return (
                                                          <>
                                                            <div className="containerRadioOption" key={opcao1.id}>
                                                              <button
                                                                className="deleteRadioOption"
                                                                onClick={() => {
                                                                  deleteOpcao(opcao1.id);
                                                                }}>
                                                                <FiTrash2 />
                                                              </button>
                                                              <input type="Checkbox" id="3" name="3" />
                                                              <input
                                                                placeholder="Nome opção"
                                                                onBlur={(e) => changeNameOption(opcao1.id, e.target.value)}
                                                                type="text"
                                                                value={opcao1.description}
                                                                onFocus={(e) => {
                                                                  changeNameOption(opcao1.id, null);
                                                                }}
                                                                className={classes.inputOptionName}
                                                              />
                                                               {" "}
                                                              <button
                                                                className="addSubSection"
                                                                onClick={() => {
                                                                  addPergunta(opcao1.id);
                                                                  setAtualizacao(atualizacao + 1);
                                                                }}>
                                                                + Subpergunta
                                                              </button>
                                                              <div className={classes.containerTags}>
                                                                <div className="containerInfoTags">
                                                                  <AiOutlineInfoCircle title="Se essa opção possuir alguma tag adicionada, esta será enviada para o banco no momento que ela for selecionada por quem está preenchendo o portfolio." size={15} />
                                                                </div>

                                                                <form
                                                                  className="formTags"
                                                                  onSubmit={(e) => {
                                                                    e.preventDefault();
                                                                    addOptionTag(opcao1.id, e.target.firstChild.value);
                                                                    e.target.firstChild.value = "";
                                                                  }}>
                                                                  <input placeholder="Insira o nome da tag" type="text" className={classes.inputNameTag} />

                                                                  <input type="submit" className="addTagButton" value="+" />
                                                                </form>
                                                                <div className="containerDisplayTags">
                                                                  {opcao1?.tag?.map((tag) => {
                                                                    return (
                                                                      <div className="containerTag" key={tag.id}>
                                                                        <button
                                                                          className="deleteTagButton"
                                                                          onClick={(e) => {
                                                                            deleteTag(tag.id);
                                                                            setAtualizacao(atualizacao + 1);
                                                                          }}>
                                                                          X
                                                                        </button>
                                                                        <div className="nomeTag">{tag.description}</div>
                                                                      </div>
                                                                    );
                                                                  })}
                                                                </div>
                                                              </div>
                                                            </div>
                                                            {opcao1?.questions?.length > 0 ? (
                                                              opcao1?.questions?.map((subPergunta1) => {
                                                                //SUBPERGUNTA 2
                                                                return (
                                                                  <div className={classes.pergunta} key={subPergunta1.id}>
                                                                    <button
                                                                      className="deleteRadioOption"
                                                                      onClick={() => {
                                                                        deletePergunta(subPergunta1.id);
                                                                      }}>
                                                                      <FiTrash2 />
                                                                    </button>
                                                                    <div className={classes.barraLateral}></div>
                                                                    <div className="configuracaoPergunta">
                                                                      <div className="opcaoPergunta">
                                                                        <div className={classes.tituloOpcaoPergunta}>Titulo</div>
                                                                        <input
                                                                          placeholder="Pressione para editar"
                                                                          onBlur={(e) => changeNamePergunta(subPergunta1.id, e.target.value)}
                                                                          onFocus={(e) => {
                                                                            changeNamePergunta(subPergunta1.id, null);
                                                                          }}
                                                                          type="text"
                                                                          value={subPergunta1.description}
                                                                          className={classes.inputTituloPergunta}
                                                                        />
                                                                      </div>
                                                                      <div className="opcaoPergunta">
                                                                        <div className={classes.tituloOpcaoPergunta}>Tipo de pergunta</div>
                                                                        <select
                                                                          className={classes.selectTipoPergunta}
                                                                          onChange={(e) => {
                                                                            subPergunta1.type = e.target.value;
                                                                            subPergunta1.options = [];
                                                                            setAtualizacao(atualizacao + 1);
                                                                          }}
                                                                          value={subPergunta1.type}>
                                                                          <option value="1">Texto</option>
                                                                          <option value="2">Seleção Única</option>
                                                                          <option value="3">Checkbox</option>
                                                                          <option value="4">Valor monetário</option>
                                                                          <option value="6">Inteiro</option>
                                                                          <option value="7">Decimal</option>
                                                                          <option value="5">Porcentagem</option>
                                                                        </select>
                                                                        {subPergunta1.type == "1" ? (
                                                                          <div
                                                                            className={classes.tipoPerguntaTexto}
                                                                            contentEditable
                                                                            suppressContentEditableWarning={true}
                                                                            onBlur={(e) => {
                                                                              if (e.target.innerHTML == "") {
                                                                                e.target.innerHTML = "Insira a resposta";
                                                                              }
                                                                            }}
                                                                            onMouseLeave={(e) => {}}
                                                                            onClick={(e) => {
                                                                              if (e.target.innerHTML == "Insira a resposta") e.target.innerHTML = "";
                                                                            }}>
                                                                            Insira a resposta
                                                                          </div>
                                                                        ) : subPergunta1.type == "2" ? (
                                                                          <div className="containerTipoPerguntaOpcaoMultipla">
                                                                            {subPergunta1?.options?.length > 0 ? (
                                                                              subPergunta1?.options?.map((opcao2) => {
                                                                                return (
                                                                                  <>
                                                                                    <div className="containerRadioOption" key={opcao2.id}>
                                                                                      <button
                                                                                        className="deleteRadioOption"
                                                                                        onClick={() => {
                                                                                          deleteOpcao(opcao2.id);
                                                                                        }}>
                                                                                        <FiTrash2 />
                                                                                      </button>
                                                                                      <input type="radio" id="html" name="fav_language" value="HTML" />
                                                                                      <input
                                                                                        placeholder="Nome opção"
                                                                                        onBlur={(e) => changeNameOption(opcao2.id, e.target.value)}
                                                                                        onFocus={(e) => {
                                                                                          changeNameOption(opcao2.id, null);
                                                                                        }}
                                                                                        type="text"
                                                                                        value={opcao2.description}
                                                                                        className={classes.inputOptionName}
                                                                                      />
                                                                                       {" "}
                                                                                      <button
                                                                                        className="addSubSection"
                                                                                        onClick={() => {
                                                                                          addPergunta(opcao2.id);
                                                                                          setAtualizacao(atualizacao + 1);
                                                                                        }}>
                                                                                        + Subpergunta
                                                                                      </button>
                                                                                      <div className={classes.containerTags}>
                                                                                        <div className="containerInfoTags">
                                                                                          <AiOutlineInfoCircle title="Se essa opção possuir alguma tag adicionada, esta será enviada para o banco no momento que ela for selecionada por quem está preenchendo o portfolio." size={15} />
                                                                                        </div>

                                                                                        <form
                                                                                          className="formTags"
                                                                                          onSubmit={(e) => {
                                                                                            e.preventDefault();
                                                                                            addOptionTag(opcao2.id, e.target.firstChild.value);
                                                                                            e.target.firstChild.value = "";
                                                                                          }}>
                                                                                          <input placeholder="Insira o nome da tag" type="text" className={classes.inputNameTag} />

                                                                                          <input type="submit" className="addTagButton" value="+" />
                                                                                        </form>
                                                                                        <div className="containerDisplayTags">
                                                                                          {opcao2?.tag?.map((tag) => {
                                                                                            return (
                                                                                              <div className="containerTag" key={tag.id}>
                                                                                                <button
                                                                                                  className="deleteTagButton"
                                                                                                  onClick={(e) => {
                                                                                                    deleteTag(tag.id);
                                                                                                    setAtualizacao(atualizacao + 1);
                                                                                                  }}>
                                                                                                  X
                                                                                                </button>
                                                                                                <div className="nomeTag">{tag.description}</div>
                                                                                              </div>
                                                                                            );
                                                                                          })}
                                                                                        </div>
                                                                                      </div>
                                                                                    </div>
                                                                                    {opcao2?.questions?.length > 0 ? (
                                                                                      opcao2?.questions?.map((subPergunta2) => {
                                                                                        //SUBPERGUNTA 3
                                                                                        return (
                                                                                          <div className={classes.pergunta} key={subPergunta2.id}>
                                                                                            <button
                                                                                              className="deleteRadioOption"
                                                                                              onClick={() => {
                                                                                                deletePergunta(subPergunta2.id);
                                                                                              }}>
                                                                                              <FiTrash2 />
                                                                                            </button>
                                                                                            <div className={classes.barraLateral}></div>
                                                                                            <div className="configuracaoPergunta">
                                                                                              <div className="opcaoPergunta">
                                                                                                <div className={classes.tituloOpcaoPergunta}>Titulo</div>
                                                                                                <input
                                                                                                  placeholder="Pressione para editar"
                                                                                                  onBlur={(e) => changeNamePergunta(subPergunta2.id, e.target.value)}
                                                                                                  onFocus={(e) => {
                                                                                                    changeNamePergunta(subPergunta2.id, null);
                                                                                                  }}
                                                                                                  type="text"
                                                                                                  value={subPergunta2.description}
                                                                                                  className={classes.inputTituloPergunta}
                                                                                                />
                                                                                              </div>
                                                                                              <div className="opcaoPergunta">
                                                                                                <div className={classes.tituloOpcaoPergunta}>Tipo de pergunta</div>
                                                                                                <select
                                                                                                  className={classes.selectTipoPergunta}
                                                                                                  onChange={(e) => {
                                                                                                    subPergunta2.type = e.target.value;
                                                                                                    subPergunta2.options = [];
                                                                                                    setAtualizacao(atualizacao + 1);
                                                                                                  }}
                                                                                                  value={subPergunta2.type}>
                                                                                                  <option value="1">Texto</option>
                                                                                                  <option value="2">Seleção Única</option>
                                                                                                  <option value="3">Checkbox</option>
                                                                                                  <option value="4">Valor monetário</option>
                                                                                                  <option value="6">Inteiro</option>
                                                                                                  <option value="7">Decimal</option>
                                                                                                  <option value="5">Porcentagem</option>
                                                                                                </select>
                                                                                                {subPergunta2.type == "1" ? (
                                                                                                  <div
                                                                                                    className={classes.tipoPerguntaTexto}
                                                                                                    contentEditable
                                                                                                    suppressContentEditableWarning={true}
                                                                                                    onBlur={(e) => {
                                                                                                      if (e.target.innerHTML == "") {
                                                                                                        e.target.innerHTML = "Insira a resposta";
                                                                                                      }
                                                                                                    }}
                                                                                                    onMouseLeave={(e) => {}}
                                                                                                    onClick={(e) => {
                                                                                                      if (e.target.innerHTML == "Insira a resposta") e.target.innerHTML = "";
                                                                                                    }}>
                                                                                                    Insira a resposta
                                                                                                  </div>
                                                                                                ) : subPergunta2.type == "2" ? (
                                                                                                  <div className="containerTipoPerguntaOpcaoMultipla">
                                                                                                    {subPergunta2?.options?.length > 0 ? (
                                                                                                      subPergunta2?.options?.map((opcao3) => {
                                                                                                        return (
                                                                                                          <>
                                                                                                            <div className="containerRadioOption" key={opcao3.id}>
                                                                                                              <button
                                                                                                                className="deleteRadioOption"
                                                                                                                onClick={() => {
                                                                                                                  deleteOpcao(opcao3.id);
                                                                                                                }}>
                                                                                                                <FiTrash2 />
                                                                                                              </button>
                                                                                                              <input type="radio" id="html" name="fav_language" value="HTML" />
                                                                                                              <input
                                                                                                                placeholder="Nome opção"
                                                                                                                onBlur={(e) => changeNameOption(opcao3.id, e.target.value)}
                                                                                                                onFocus={(e) => {
                                                                                                                  changeNameOption(opcao3.id, null);
                                                                                                                }}
                                                                                                                type="text"
                                                                                                                value={opcao3.description}
                                                                                                                className={classes.inputOptionName}
                                                                                                              />
                                                                                                              <div className={classes.containerTags}>
                                                                                                                <div className="containerInfoTags">
                                                                                                                  <AiOutlineInfoCircle title="Se essa opção possuir alguma tag adicionada, esta será enviada para o banco no momento que ela for selecionada por quem está preenchendo o portfolio." size={15} />
                                                                                                                </div>

                                                                                                                <form
                                                                                                                  className="formTags"
                                                                                                                  onSubmit={(e) => {
                                                                                                                    e.preventDefault();
                                                                                                                    addOptionTag(opcao3.id, e.target.firstChild.value);
                                                                                                                    e.target.firstChild.value = "";
                                                                                                                  }}>
                                                                                                                  <input placeholder="Insira o nome da tag" type="text" className={classes.inputNameTag} />

                                                                                                                  <input type="submit" className="addTagButton" value="+" />
                                                                                                                </form>
                                                                                                                <div className="containerDisplayTags">
                                                                                                                  {opcao3?.tag?.map((tag) => {
                                                                                                                    return (
                                                                                                                      <div className="containerTag" key={tag.id}>
                                                                                                                        <button
                                                                                                                          className="deleteTagButton"
                                                                                                                          onClick={(e) => {
                                                                                                                            deleteTag(tag.id);
                                                                                                                            setAtualizacao(atualizacao + 1);
                                                                                                                          }}>
                                                                                                                          X
                                                                                                                        </button>
                                                                                                                        <div className="nomeTag">{tag.description}</div>
                                                                                                                      </div>
                                                                                                                    );
                                                                                                                  })}
                                                                                                                </div>
                                                                                                              </div>
                                                                                                            </div>
                                                                                                          </>
                                                                                                        );
                                                                                                      })
                                                                                                    ) : (
                                                                                                      <></>
                                                                                                    )}
                                                                                                    <button
                                                                                                      className="addRadioOption"
                                                                                                      onClick={() => {
                                                                                                        addOpcao(subPergunta2.id);
                                                                                                      }}>
                                                                                                      + Adicionar opção
                                                                                                    </button>
                                                                                                  </div>
                                                                                                ) : subPergunta2.type == "3" ? (
                                                                                                  <div className="containerTipoPerguntaCaixaSelecao">
                                                                                                    {subPergunta2?.options?.length > 0 ? (
                                                                                                      subPergunta2?.options?.map((opcao3) => {
                                                                                                        return (
                                                                                                          <>
                                                                                                            <div className="containerCheckBoxOption" key={opcao3.id}>
                                                                                                              <button
                                                                                                                className="deleteCheckBoxOption"
                                                                                                                onClick={() => {
                                                                                                                  deleteOpcao(opcao3.id);
                                                                                                                }}>
                                                                                                                <FiTrash2 />
                                                                                                              </button>
                                                                                                              <input type="Checkbox" id="3" name="3" />
                                                                                                              <input
                                                                                                                placeholder="Nome opção"
                                                                                                                onBlur={(e) => changeNameOption(opcao3.id, e.target.value)}
                                                                                                                onFocus={(e) => {
                                                                                                                  changeNameOption(opcao3.id, null);
                                                                                                                }}
                                                                                                                type="text"
                                                                                                                value={opcao3.description}
                                                                                                                className={classes.inputOptionName}
                                                                                                              />
                                                                                                              <div className={classes.containerTags}>
                                                                                                                <div className="containerInfoTags">
                                                                                                                  <AiOutlineInfoCircle title="Se essa opção possuir alguma tag adicionada, esta será enviada para o banco no momento que ela for selecionada por quem está preenchendo o portfolio." size={15} />
                                                                                                                </div>

                                                                                                                <form
                                                                                                                  className="formTags"
                                                                                                                  onSubmit={(e) => {
                                                                                                                    e.preventDefault();
                                                                                                                    addOptionTag(opcao3.id, e.target.firstChild.value);
                                                                                                                    e.target.firstChild.value = "";
                                                                                                                  }}>
                                                                                                                  <input placeholder="Insira o nome da tag" type="text" className={classes.inputNameTag} />

                                                                                                                  <input type="submit" className="addTagButton" value="+" />
                                                                                                                </form>
                                                                                                                <div className="containerDisplayTags">
                                                                                                                  {opcao3?.tag?.map((tag) => {
                                                                                                                    return (
                                                                                                                      <div className="containerTag" key={tag.id}>
                                                                                                                        <button
                                                                                                                          className="deleteTagButton"
                                                                                                                          onClick={(e) => {
                                                                                                                            deleteTag(tag.id);
                                                                                                                            setAtualizacao(atualizacao + 1);
                                                                                                                          }}>
                                                                                                                          X
                                                                                                                        </button>
                                                                                                                        <div className="nomeTag">{tag.description}</div>
                                                                                                                      </div>
                                                                                                                    );
                                                                                                                  })}
                                                                                                                </div>
                                                                                                              </div>
                                                                                                            </div>
                                                                                                          </>
                                                                                                        );
                                                                                                      })
                                                                                                    ) : (
                                                                                                      <></>
                                                                                                    )}
                                                                                                    <button
                                                                                                      className="addCheckBoxOption"
                                                                                                      onClick={() => {
                                                                                                        addOpcao(subPergunta2.id);
                                                                                                      }}>
                                                                                                      + Adicionar opção
                                                                                                    </button>
                                                                                                  </div>
                                                                                                ) : subPergunta2.type == "4" ? (
                                                                                                  <div>
                                                                                                    <CurrencyTextField
                                                                                                      InputProps={{
                                                                                                        classes: {
                                                                                                          notchedOutline: classes.notchedOutline,
                                                                                                        },
                                                                                                      }}
                                                                                                      variant="outlined"
                                                                                                      value={precoTemp}
                                                                                                      className="tipoPerguntaMonetario"
                                                                                                      currencySymbol="R$"
                                                                                                      outputFormat="number"
                                                                                                      decimalCharacter=","
                                                                                                      required={true}
                                                                                                      digitGroupSeparator="."
                                                                                                      onChange={(event, value) => {
                                                                                                        if (value === "" || value === 0) {
                                                                                                          setPrecoTemp(0);
                                                                                                        } else {
                                                                                                          setPrecoTemp(value);
                                                                                                        }
                                                                                                      }}
                                                                                                    />
                                                                                                  </div>
                                                                                                ) : subPergunta2.type == "5" ? (
                                                                                                  <div className="containerTipoPerguntaPorcentagem">
                                                                                                    <TextField
                                                                                                      InputProps={{
                                                                                                        classes: {
                                                                                                          notchedOutline: classes.notchedOutline,
                                                                                                        },
                                                                                                      }}
                                                                                                      type="number"
                                                                                                      onKeyPress={(e) => {
                                                                                                        if (e.code === "Backquote") e.preventDefault();
                                                                                                      }}
                                                                                                      step="0.00"
                                                                                                      onKeyDown={(e) => {
                                                                                                        if (e.key === "e" || e.key === "E") {
                                                                                                          e.preventDefault();
                                                                                                        }
                                                                                                      }}
                                                                                                      value={percentTemp}
                                                                                                      className="tipoPerguntaPorcentagem"
                                                                                                      variant="outlined"
                                                                                                      onChange={(e) => {
                                                                                                        setPercentTemp(e.target.value);
                                                                                                      }}
                                                                                                    />
                                                                                                    <div className="porcentagem">%</div>
                                                                                                  </div>
                                                                                                ) : subPergunta2.type == "6" ? (
                                                                                                  <div className="containerTipoPerguntaPorcentagem">
                                                                                                    <TextField
                                                                                                      
                                                                                                      InputProps={{ classes: { notchedOutline: classes.notchedOutline } }}
                                                                                                      type="number"
                                                                                                      onKeyPress={(e) => {
                                                                                                        if (e.key === "." || e.key === ",") e.preventDefault();
                                                                                                      }}
                                                                                                      value={inteiroTemp}
                                                                                                      className="tipoPerguntaPorcentagem"
                                                                                                      variant="outlined"
                                                                                                      onChange={(e) => {
                                                                                                        setInteiroTemp(e.target.value);
                                                                                                      }}
                                                                                                    />
                                                                                                  </div>
                                                                                                ) : subPergunta2.type == "7" ? (
                                                                                                  <div className="containerTipoPerguntaPorcentagem">
                                                                                                    <TextField
                                                                                                      
                                                                                                      InputProps={{ classes: { notchedOutline: classes.notchedOutline } }}
                                                                                                      type="number"
                                                                                                      value={decimalTemp}
                                                                                                      className="tipoPerguntaPorcentagem"
                                                                                                      variant="outlined"
                                                                                                      onChange={(e) => {
                                                                                                        setDecimalTemp(e.target.value);
                                                                                                      }}
                                                                                                    />
                                                                                                  </div>
                                                                                                ) : (
                                                                                                  <></>
                                                                                                )}
                                                                                              </div>
                                                                                              <div className="habilitarObservacao">
                                                                                                <input
                                                                                                  checked={subPergunta2.observation}
                                                                                                  onChange={(e) => {
                                                                                                    changeObservation(subPergunta2.id);
                                                                                                  }}
                                                                                                  type="Checkbox"
                                                                                                  id={"Checkbox" + subPergunta2.id}
                                                                                                  name="Checkbox"
                                                                                                />
                                                                                                <label for={"Checkbox" + subPergunta2.id}> Adicionar campo de observação?</label>
                                                                                              </div>
                                                                                            </div>
                                                                                          </div>
                                                                                        );
                                                                                        //SUBPERGUNTA 3
                                                                                      })
                                                                                    ) : (
                                                                                      <></>
                                                                                    )}
                                                                                  </>
                                                                                );
                                                                              })
                                                                            ) : (
                                                                              <></>
                                                                            )}
                                                                            <button
                                                                              className="addRadioOption"
                                                                              onClick={() => {
                                                                                addOpcao(subPergunta1.id);
                                                                              }}>
                                                                              + Adicionar opção
                                                                            </button>
                                                                          </div>
                                                                        ) : subPergunta1.type == "3" ? (
                                                                          <div className="containerTipoPerguntaOpcaoMultipla">
                                                                            {subPergunta1?.options?.length > 0 ? (
                                                                              subPergunta1?.options?.map((opcao2) => {
                                                                                return (
                                                                                  <>
                                                                                    <div className="containerRadioOption" key={opcao2.id}>
                                                                                      <button
                                                                                        className="deleteRadioOption"
                                                                                        onClick={() => {
                                                                                          deleteOpcao(opcao2.id);
                                                                                        }}>
                                                                                        <FiTrash2 />
                                                                                      </button>
                                                                                      <input type="Checkbox" id="3" name="3" />
                                                                                      <input
                                                                                        placeholder="Nome opção"
                                                                                        onBlur={(e) => changeNameOption(opcao2.id, e.target.value)}
                                                                                        onFocus={(e) => {
                                                                                          changeNameOption(opcao2.id, null);
                                                                                        }}
                                                                                        type="text"
                                                                                        value={opcao2.description}
                                                                                        className={classes.inputOptionName}
                                                                                      />
                                                                                       {" "}
                                                                                      <button
                                                                                        className="addSubSection"
                                                                                        onClick={() => {
                                                                                          addPergunta(opcao2.id);
                                                                                          setAtualizacao(atualizacao + 1);
                                                                                        }}>
                                                                                        + Subpergunta
                                                                                      </button>
                                                                                      <div className={classes.containerTags}>
                                                                                        <div className="containerInfoTags">
                                                                                          <AiOutlineInfoCircle title="Se essa opção possuir alguma tag adicionada, esta será enviada para o banco no momento que ela for selecionada por quem está preenchendo o portfolio." size={15} />
                                                                                        </div>

                                                                                        <form
                                                                                          className="formTags"
                                                                                          onSubmit={(e) => {
                                                                                            e.preventDefault();
                                                                                            addOptionTag(opcao2.id, e.target.firstChild.value);
                                                                                            e.target.firstChild.value = "";
                                                                                          }}>
                                                                                          <input placeholder="Insira o nome da tag" type="text" className={classes.inputNameTag} />

                                                                                          <input type="submit" className="addTagButton" value="+" />
                                                                                        </form>
                                                                                        <div className="containerDisplayTags">
                                                                                          {opcao2?.tag?.map((tag) => {
                                                                                            return (
                                                                                              <div className="containerTag" key={tag.id}>
                                                                                                <button
                                                                                                  className="deleteTagButton"
                                                                                                  onClick={(e) => {
                                                                                                    deleteTag(tag.id);
                                                                                                    setAtualizacao(atualizacao + 1);
                                                                                                  }}>
                                                                                                  X
                                                                                                </button>
                                                                                                <div className="nomeTag">{tag.description}</div>
                                                                                              </div>
                                                                                            );
                                                                                          })}
                                                                                        </div>
                                                                                      </div>
                                                                                    </div>
                                                                                    {opcao2?.questions?.length > 0 ? (
                                                                                      opcao2?.questions?.map((subPergunta2) => {
                                                                                        //SUBPERGUNTA 3
                                                                                        return (
                                                                                          <div className={classes.pergunta} key={subPergunta2.id}>
                                                                                            <button
                                                                                              className="deleteRadioOption"
                                                                                              onClick={() => {
                                                                                                deletePergunta(subPergunta2.id);
                                                                                              }}>
                                                                                              <FiTrash2 />
                                                                                            </button>
                                                                                            <div className={classes.barraLateral}></div>
                                                                                            <div className="configuracaoPergunta">
                                                                                              <div className="opcaoPergunta">
                                                                                                <div className={classes.tituloOpcaoPergunta}>Titulo</div>
                                                                                                <input
                                                                                                  placeholder="Pressione para editar"
                                                                                                  onBlur={(e) => changeNamePergunta(subPergunta2.id, e.target.value)}
                                                                                                  onFocus={(e) => {
                                                                                                    changeNamePergunta(subPergunta2.id, null);
                                                                                                  }}
                                                                                                  type="text"
                                                                                                  value={subPergunta2.description}
                                                                                                  className={classes.inputTituloPergunta}
                                                                                                />
                                                                                              </div>
                                                                                              <div className="opcaoPergunta">
                                                                                                <div className={classes.tituloOpcaoPergunta}>Tipo de pergunta</div>
                                                                                                <select
                                                                                                  className={classes.selectTipoPergunta}
                                                                                                  onChange={(e) => {
                                                                                                    subPergunta2.type = e.target.value;
                                                                                                    subPergunta2.options = [];
                                                                                                    setAtualizacao(atualizacao + 1);
                                                                                                  }}
                                                                                                  value={subPergunta2.type}>
                                                                                                  <option value="1">Texto</option>
                                                                                                  <option value="2">Seleção Única</option>
                                                                                                  <option value="3">Checkbox</option>
                                                                                                  <option value="4">Valor monetário</option>
                                                                                                  <option value="6">Inteiro</option>
                                                                                                  <option value="7">Decimal</option>
                                                                                                  <option value="5">Porcentagem</option>
                                                                                                </select>
                                                                                                {subPergunta2.type == "1" ? (
                                                                                                  <div
                                                                                                    className={classes.tipoPerguntaTexto}
                                                                                                    contentEditable
                                                                                                    suppressContentEditableWarning={true}
                                                                                                    onBlur={(e) => {
                                                                                                      if (e.target.innerHTML == "") {
                                                                                                        e.target.innerHTML = "Insira a resposta";
                                                                                                      }
                                                                                                    }}
                                                                                                    onMouseLeave={(e) => {}}
                                                                                                    onClick={(e) => {
                                                                                                      if (e.target.innerHTML == "Insira a resposta") e.target.innerHTML = "";
                                                                                                    }}>
                                                                                                    Insira a resposta
                                                                                                  </div>
                                                                                                ) : subPergunta2.type == "2" ? (
                                                                                                  <div className="containerTipoPerguntaOpcaoMultipla">
                                                                                                    {subPergunta2?.options?.length > 0 ? (
                                                                                                      subPergunta2?.options?.map((opcao3) => {
                                                                                                        return (
                                                                                                          <>
                                                                                                            <div className="containerRadioOption" key={opcao3.id}>
                                                                                                              <button
                                                                                                                className="deleteRadioOption"
                                                                                                                onClick={() => {
                                                                                                                  deleteOpcao(opcao3.id);
                                                                                                                }}>
                                                                                                                <FiTrash2 />
                                                                                                              </button>
                                                                                                              <input type="radio" id="html" name="fav_language" value="HTML" />
                                                                                                              <input
                                                                                                                placeholder="Nome opção"
                                                                                                                onBlur={(e) => changeNameOption(opcao3.id, e.target.value)}
                                                                                                                onFocus={(e) => {
                                                                                                                  changeNameOption(opcao3.id, null);
                                                                                                                }}
                                                                                                                type="text"
                                                                                                                value={opcao3.description}
                                                                                                                className={classes.inputOptionName}
                                                                                                              />
                                                                                                              <div className={classes.containerTags}>
                                                                                                                <div className="containerInfoTags">
                                                                                                                  <AiOutlineInfoCircle title="Se essa opção possuir alguma tag adicionada, esta será enviada para o banco no momento que ela for selecionada por quem está preenchendo o portfolio." size={15} />
                                                                                                                </div>

                                                                                                                <form
                                                                                                                  className="formTags"
                                                                                                                  onSubmit={(e) => {
                                                                                                                    e.preventDefault();
                                                                                                                    addOptionTag(opcao3.id, e.target.firstChild.value);
                                                                                                                    e.target.firstChild.value = "";
                                                                                                                  }}>
                                                                                                                  <input placeholder="Insira o nome da tag" type="text" className={classes.inputNameTag} />

                                                                                                                  <input type="submit" className="addTagButton" value="+" />
                                                                                                                </form>
                                                                                                                <div className="containerDisplayTags">
                                                                                                                  {opcao3?.tag?.map((tag) => {
                                                                                                                    return (
                                                                                                                      <div className="containerTag" key={tag.id}>
                                                                                                                        <button
                                                                                                                          className="deleteTagButton"
                                                                                                                          onClick={(e) => {
                                                                                                                            deleteTag(tag.id);
                                                                                                                            setAtualizacao(atualizacao + 1);
                                                                                                                          }}>
                                                                                                                          X
                                                                                                                        </button>
                                                                                                                        <div className="nomeTag">{tag.description}</div>
                                                                                                                      </div>
                                                                                                                    );
                                                                                                                  })}
                                                                                                                </div>
                                                                                                              </div>
                                                                                                            </div>
                                                                                                          </>
                                                                                                        );
                                                                                                      })
                                                                                                    ) : (
                                                                                                      <></>
                                                                                                    )}
                                                                                                    <button
                                                                                                      className="addRadioOption"
                                                                                                      onClick={() => {
                                                                                                        addOpcao(subPergunta2.id);
                                                                                                      }}>
                                                                                                      + Adicionar opção
                                                                                                    </button>
                                                                                                  </div>
                                                                                                ) : subPergunta2.type == "3" ? (
                                                                                                  <div className="containerTipoPerguntaCaixaSelecao">
                                                                                                    {subPergunta2?.options?.length > 0 ? (
                                                                                                      subPergunta2?.options?.map((opcao3) => {
                                                                                                        return (
                                                                                                          <>
                                                                                                            <div className="containerCheckBoxOption" key={opcao3.id}>
                                                                                                              <button
                                                                                                                className="deleteCheckBoxOption"
                                                                                                                onClick={() => {
                                                                                                                  deleteOpcao(opcao3.id);
                                                                                                                }}>
                                                                                                                <FiTrash2 />
                                                                                                              </button>
                                                                                                              <input type="Checkbox" id="3" name="3" />
                                                                                                              <input
                                                                                                                placeholder="Nome opção"
                                                                                                                onBlur={(e) => changeNameOption(opcao3.id, e.target.value)}
                                                                                                                onFocus={(e) => {
                                                                                                                  changeNameOption(opcao3.id, null);
                                                                                                                }}
                                                                                                                type="text"
                                                                                                                value={opcao3.description}
                                                                                                                className={classes.inputOptionName}
                                                                                                              />
                                                                                                              <div className={classes.containerTags}>
                                                                                                                <div className="containerInfoTags">
                                                                                                                  <AiOutlineInfoCircle title="Se essa opção possuir alguma tag adicionada, esta será enviada para o banco no momento que ela for selecionada por quem está preenchendo o portfolio." size={15} />
                                                                                                                </div>

                                                                                                                <form
                                                                                                                  className="formTags"
                                                                                                                  onSubmit={(e) => {
                                                                                                                    e.preventDefault();
                                                                                                                    addOptionTag(opcao3.id, e.target.firstChild.value);
                                                                                                                    e.target.firstChild.value = "";
                                                                                                                  }}>
                                                                                                                  <input placeholder="Insira o nome da tag" type="text" className={classes.inputNameTag} />

                                                                                                                  <input type="submit" className="addTagButton" value="+" />
                                                                                                                </form>
                                                                                                                <div className="containerDisplayTags">
                                                                                                                  {opcao3?.tag?.map((tag) => {
                                                                                                                    return (
                                                                                                                      <div className="containerTag" key={tag.id}>
                                                                                                                        <button
                                                                                                                          className="deleteTagButton"
                                                                                                                          onClick={(e) => {
                                                                                                                            deleteTag(tag.id);
                                                                                                                            setAtualizacao(atualizacao + 1);
                                                                                                                          }}>
                                                                                                                          X
                                                                                                                        </button>
                                                                                                                        <div className="nomeTag">{tag.description}</div>
                                                                                                                      </div>
                                                                                                                    );
                                                                                                                  })}
                                                                                                                </div>
                                                                                                              </div>
                                                                                                            </div>
                                                                                                          </>
                                                                                                        );
                                                                                                      })
                                                                                                    ) : (
                                                                                                      <></>
                                                                                                    )}
                                                                                                    <button
                                                                                                      className="addCheckBoxOption"
                                                                                                      onClick={() => {
                                                                                                        addOpcao(subPergunta2.id);
                                                                                                      }}>
                                                                                                      + Adicionar opção
                                                                                                    </button>
                                                                                                  </div>
                                                                                                ) : subPergunta2.type == "4" ? (
                                                                                                  <div>
                                                                                                    <CurrencyTextField
                                                                                                      InputProps={{
                                                                                                        classes: {
                                                                                                          notchedOutline: classes.notchedOutline,
                                                                                                        },
                                                                                                      }}
                                                                                                      variant="outlined"
                                                                                                      value={precoTemp}
                                                                                                      className="tipoPerguntaMonetario"
                                                                                                      currencySymbol="R$"
                                                                                                      outputFormat="number"
                                                                                                      decimalCharacter=","
                                                                                                      required={true}
                                                                                                      digitGroupSeparator="."
                                                                                                      onChange={(event, value) => {
                                                                                                        if (value === "" || value === 0) {
                                                                                                          setPrecoTemp(0);
                                                                                                        } else {
                                                                                                          setPrecoTemp(value);
                                                                                                        }
                                                                                                      }}
                                                                                                    />
                                                                                                  </div>
                                                                                                ) : subPergunta2.type == "5" ? (
                                                                                                  <div className="containerTipoPerguntaPorcentagem">
                                                                                                    <TextField
                                                                                                      InputProps={{
                                                                                                        classes: {
                                                                                                          notchedOutline: classes.notchedOutline,
                                                                                                        },
                                                                                                      }}
                                                                                                      type="number"
                                                                                                      onKeyPress={(e) => {
                                                                                                        if (e.code === "Backquote") e.preventDefault();
                                                                                                      }}
                                                                                                      step="0.00"
                                                                                                      onKeyDown={(e) => {
                                                                                                        if (e.key === "e" || e.key === "E") {
                                                                                                          e.preventDefault();
                                                                                                        }
                                                                                                      }}
                                                                                                      value={percentTemp}
                                                                                                      className="tipoPerguntaPorcentagem"
                                                                                                      variant="outlined"
                                                                                                      onChange={(e) => {
                                                                                                        setPercentTemp(e.target.value);
                                                                                                      }}
                                                                                                    />
                                                                                                    <div className="porcentagem">%</div>
                                                                                                  </div>
                                                                                                ) : subPergunta2.type == "6" ? (
                                                                                                  <div className="containerTipoPerguntaPorcentagem">
                                                                                                    <TextField
                                                                                                      
                                                                                                      InputProps={{ classes: { notchedOutline: classes.notchedOutline } }}
                                                                                                      type="number"
                                                                                                      onKeyPress={(e) => {
                                                                                                        if (e.key === "." || e.key === ",") e.preventDefault();
                                                                                                      }}
                                                                                                      value={inteiroTemp}
                                                                                                      className="tipoPerguntaPorcentagem"
                                                                                                      variant="outlined"
                                                                                                      onChange={(e) => {
                                                                                                        setInteiroTemp(e.target.value);
                                                                                                      }}
                                                                                                    />
                                                                                                  </div>
                                                                                                ) : subPergunta2.type == "7" ? (
                                                                                                  <div className="containerTipoPerguntaPorcentagem">
                                                                                                    <TextField
                                                                                                      
                                                                                                      InputProps={{ classes: { notchedOutline: classes.notchedOutline } }}
                                                                                                      type="number"
                                                                                                      value={decimalTemp}
                                                                                                      className="tipoPerguntaPorcentagem"
                                                                                                      variant="outlined"
                                                                                                      onChange={(e) => {
                                                                                                        setDecimalTemp(e.target.value);
                                                                                                      }}
                                                                                                    />
                                                                                                  </div>
                                                                                                ) : (
                                                                                                  <></>
                                                                                                )}
                                                                                              </div>
                                                                                              <div className="habilitarObservacao">
                                                                                                <input
                                                                                                  checked={subPergunta2.observation}
                                                                                                  onChange={(e) => {
                                                                                                    changeObservation(subPergunta2.id);
                                                                                                  }}
                                                                                                  type="Checkbox"
                                                                                                  id={"Checkbox" + subPergunta2.id}
                                                                                                  name="Checkbox"
                                                                                                />
                                                                                                <label for={"Checkbox" + subPergunta2.id}> Adicionar campo de observação?</label>
                                                                                              </div>
                                                                                            </div>
                                                                                          </div>
                                                                                        );
                                                                                        //SUBPERGUNTA 3
                                                                                      })
                                                                                    ) : (
                                                                                      <></>
                                                                                    )}
                                                                                  </>
                                                                                );
                                                                              })
                                                                            ) : (
                                                                              <></>
                                                                            )}
                                                                            <button
                                                                              className="addRadioOption"
                                                                              onClick={() => {
                                                                                addOpcao(subPergunta1.id);
                                                                              }}>
                                                                              + Adicionar opção
                                                                            </button>
                                                                          </div>
                                                                        ) : subPergunta1.type == "4" ? (
                                                                          <div>
                                                                            <CurrencyTextField
                                                                              InputProps={{
                                                                                classes: {
                                                                                  notchedOutline: classes.notchedOutline,
                                                                                },
                                                                              }}
                                                                              variant="outlined"
                                                                              value={precoTemp}
                                                                              className="tipoPerguntaMonetario"
                                                                              currencySymbol="R$"
                                                                              outputFormat="number"
                                                                              decimalCharacter=","
                                                                              required={true}
                                                                              digitGroupSeparator="."
                                                                              onChange={(event, value) => {
                                                                                if (value === "" || value === 0) {
                                                                                  setPrecoTemp(0);
                                                                                } else {
                                                                                  setPrecoTemp(value);
                                                                                }
                                                                              }}
                                                                            />
                                                                          </div>
                                                                        ) : subPergunta1.type == "5" ? (
                                                                          <div className="containerTipoPerguntaPorcentagem">
                                                                            <TextField
                                                                              InputProps={{
                                                                                classes: {
                                                                                  notchedOutline: classes.notchedOutline,
                                                                                },
                                                                              }}
                                                                              type="number"
                                                                              onKeyPress={(e) => {
                                                                                if (e.code === "Backquote") e.preventDefault();
                                                                              }}
                                                                              step="0.00"
                                                                              onKeyDown={(e) => {
                                                                                if (e.key === "e" || e.key === "E") {
                                                                                  e.preventDefault();
                                                                                }
                                                                              }}
                                                                              value={percentTemp}
                                                                              className="tipoPerguntaPorcentagem"
                                                                              variant="outlined"
                                                                              onChange={(e) => {
                                                                                setPercentTemp(e.target.value);
                                                                              }}
                                                                            />
                                                                            <div className="porcentagem">%</div>
                                                                          </div>
                                                                        ) : subPergunta1.type == "6" ? (
                                                                          <div className="containerTipoPerguntaPorcentagem">
                                                                            <TextField
                                                                              
                                                                              InputProps={{ classes: { notchedOutline: classes.notchedOutline } }}
                                                                              type="number"
                                                                              onKeyPress={(e) => {
                                                                                if (e.key === "." || e.key === ",") e.preventDefault();
                                                                              }}
                                                                              value={inteiroTemp}
                                                                              className="tipoPerguntaPorcentagem"
                                                                              variant="outlined"
                                                                              onChange={(e) => {
                                                                                setInteiroTemp(e.target.value);
                                                                              }}
                                                                            />
                                                                          </div>
                                                                        ) : subPergunta1.type == "7" ? (
                                                                          <div className="containerTipoPerguntaPorcentagem">
                                                                            <TextField
                                                                              
                                                                              InputProps={{ classes: { notchedOutline: classes.notchedOutline } }}
                                                                              type="number"
                                                                              value={decimalTemp}
                                                                              className="tipoPerguntaPorcentagem"
                                                                              variant="outlined"
                                                                              onChange={(e) => {
                                                                                setDecimalTemp(e.target.value);
                                                                              }}
                                                                            />
                                                                          </div>
                                                                        ) : (
                                                                          <></>
                                                                        )}
                                                                      </div>
                                                                      <div className="habilitarObservacao">
                                                                        <input
                                                                          checked={subPergunta1.observation}
                                                                          onChange={(e) => {
                                                                            changeObservation(subPergunta1.id);
                                                                          }}
                                                                          type="Checkbox"
                                                                          id={"Checkbox" + subPergunta1.id}
                                                                          name="Checkbox"
                                                                        />
                                                                        <label for={"Checkbox" + subPergunta1.id}> Adicionar campo de observação?</label>
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                );
                                                                //SUBPERGUNTA 2
                                                              })
                                                            ) : (
                                                              <></>
                                                            )}
                                                          </>
                                                        );
                                                      })
                                                    ) : (
                                                      <></>
                                                    )}
                                                    <button
                                                      className="addRadioOption"
                                                      onClick={() => {
                                                        addOpcao(subPergunta.id);
                                                      }}>
                                                      + Adicionar opção
                                                    </button>
                                                  </div>
                                                ) : subPergunta.type == "4" ? (
                                                  <div>
                                                    <CurrencyTextField
                                                      InputProps={{ classes: { notchedOutline: classes.notchedOutline } }}
                                                      variant="outlined"
                                                      value={precoTemp}
                                                      className="tipoPerguntaMonetario"
                                                      currencySymbol="R$"
                                                      outputFormat="number"
                                                      decimalCharacter=","
                                                      required={true}
                                                      digitGroupSeparator="."
                                                      onChange={(event, value) => {
                                                        if (value === "" || value === 0) {
                                                          setPrecoTemp(0);
                                                        } else {
                                                          setPrecoTemp(value);
                                                        }
                                                      }}
                                                    />
                                                  </div>
                                                ) : subPergunta.type == "5" ? (
                                                  <div className="containerTipoPerguntaPorcentagem">
                                                    <TextField
                                                      InputProps={{ classes: { notchedOutline: classes.notchedOutline } }}
                                                      type="number"
                                                      onKeyPress={(e) => {
                                                        if (e.code === "Backquote") e.preventDefault();
                                                      }}
                                                      step="0.00"
                                                      onKeyDown={(e) => {
                                                        if (e.key === "e" || e.key === "E") {
                                                          e.preventDefault();
                                                        }
                                                      }}
                                                      value={percentTemp}
                                                      className="tipoPerguntaPorcentagem"
                                                      variant="outlined"
                                                      onChange={(e) => {
                                                        setPercentTemp(e.target.value);
                                                      }}
                                                    />
                                                    <div className="porcentagem">%</div>
                                                  </div>
                                                ) : subPergunta.type == "6" ? (
                                                  <div className="containerTipoPerguntaPorcentagem">
                                                    <TextField
                                                      
                                                      InputProps={{ classes: { notchedOutline: classes.notchedOutline } }}
                                                      type="number"
                                                      onKeyPress={(e) => {
                                                        if (e.key === "." || e.key === ",") e.preventDefault();
                                                      }}
                                                      value={inteiroTemp}
                                                      className="tipoPerguntaPorcentagem"
                                                      variant="outlined"
                                                      onChange={(e) => {
                                                        setInteiroTemp(e.target.value);
                                                      }}
                                                    />
                                                  </div>
                                                ) : subPergunta.type == "7" ? (
                                                  <div className="containerTipoPerguntaPorcentagem">
                                                    <TextField
                                                      
                                                      InputProps={{ classes: { notchedOutline: classes.notchedOutline } }}
                                                      type="number"
                                                      value={decimalTemp}
                                                      className="tipoPerguntaPorcentagem"
                                                      variant="outlined"
                                                      onChange={(e) => {
                                                        setDecimalTemp(e.target.value);
                                                      }}
                                                    />
                                                  </div>
                                                ) : (
                                                  <></>
                                                )}
                                              </div>
                                              <div className="habilitarObservacao">
                                                <input
                                                  checked={subPergunta.observation}
                                                  onChange={(e) => {
                                                    changeObservation(subPergunta.id);
                                                  }}
                                                  type="Checkbox"
                                                  id={"Checkbox" + subPergunta.id}
                                                  name="Checkbox"
                                                />
                                                <label for={"Checkbox" + subPergunta.id}> Adicionar campo de observação?</label>
                                              </div>
                                            </div>
                                          </div>
                                        );
                                        //SUBPERGUNTA 1
                                      })
                                    ) : (
                                      <></>
                                    )}
                                  </>
                                );
                              })
                            ) : (
                              <></>
                            )}
                            <button
                              className="addRadioOption"
                              onClick={() => {
                                addOpcao(pergunta.id);
                              }}>
                              + Adicionar opção
                            </button>
                          </div>
                        ) : pergunta.type == "3" ? (
                          <div className="containerTipoPerguntaCaixaSelecao">
                            {pergunta?.options?.length > 0 ? (
                              pergunta?.options?.map((opcao) => {
                                return (
                                  <>
                                    <div className="containerCheckBoxOption" key={opcao.id}>
                                      <button
                                        className="deleteCheckBoxOption"
                                        onClick={() => {
                                          deleteOpcao(opcao.id);
                                        }}>
                                        <FiTrash2 />
                                      </button>
                                      <input type="Checkbox" id="3" name="3" />
                                      <input
                                        placeholder="Nome opção"
                                        onBlur={(e) => changeNameOption(opcao.id, e.target.value)}
                                        onFocus={(e) => {
                                          changeNameOption(opcao.id, null);
                                        }}
                                        type="text"
                                        value={opcao.description}
                                        className={classes.inputOptionName}
                                      />
                                       {" "}
                                      <button
                                        className="addSubSection"
                                        onClick={() => {
                                          addPergunta(opcao.id);
                                          setAtualizacao(atualizacao + 1);
                                        }}>
                                        + Subpergunta
                                      </button>
                                      <div className={classes.containerTags}>
                                        <div className="containerInfoTags">
                                          <AiOutlineInfoCircle title="Se essa opção possuir alguma tag adicionada, esta será enviada para o banco no momento que ela for selecionada por quem está preenchendo o portfolio." size={15} />
                                        </div>

                                        <form
                                          className="formTags"
                                          onSubmit={(e) => {
                                            e.preventDefault();
                                            addOptionTag(opcao.id, e.target.firstChild.value);
                                            e.target.firstChild.value = "";
                                          }}>
                                          <input placeholder="Insira o nome da tag" type="text" className={classes.inputNameTag} />

                                          <input type="submit" className="addTagButton" value="+" />
                                        </form>
                                        <div className="containerDisplayTags">
                                          {opcao?.tag?.map((tag) => {
                                            return (
                                              <div className="containerTag" key={tag.id}>
                                                <button
                                                  className="deleteTagButton"
                                                  onClick={(e) => {
                                                    deleteTag(tag.id);
                                                    setAtualizacao(atualizacao + 1);
                                                  }}>
                                                  X
                                                </button>
                                                <div className="nomeTag">{tag.description}</div>
                                              </div>
                                            );
                                          })}
                                        </div>
                                      </div>
                                    </div>
                                    {opcao?.questions?.length > 0 ? (
                                      opcao?.questions?.map((subPergunta) => {
                                        //SUBPERGUNTA 1
                                        return (
                                          <div className={classes.pergunta} key={subPergunta.id}>
                                            <button
                                              className="deleteCheckBoxOption"
                                              onClick={() => {
                                                deletePergunta(subPergunta.id);
                                              }}>
                                              <FiTrash2 />
                                            </button>
                                            <div className={classes.barraLateral}></div>
                                            <div className="configuracaoPergunta">
                                              <div className="opcaoPergunta">
                                                <div className={classes.tituloOpcaoPergunta}>Titulo</div>
                                                <input
                                                  placeholder="Pressione para editar"
                                                  onBlur={(e) => changeNamePergunta(subPergunta.id, e.target.value)}
                                                  onFocus={(e) => {
                                                    changeNamePergunta(subPergunta.id, null);
                                                  }}
                                                  type="text"
                                                  value={subPergunta.description}
                                                  className={classes.inputTituloPergunta}
                                                />
                                              </div>
                                              <div className="opcaoPergunta">
                                                <div className={classes.tituloOpcaoPergunta}>Tipo de pergunta</div>
                                                <select
                                                  className={classes.selectTipoPergunta}
                                                  onChange={(e) => {
                                                    subPergunta.type = e.target.value;
                                                    subPergunta.options = [];
                                                    setAtualizacao(atualizacao + 1);
                                                  }}
                                                  value={subPergunta.type}>
                                                  <option value="1">Texto</option>
                                                  <option value="2">Seleção Única</option>
                                                  <option value="3">Checkbox</option>
                                                  <option value="4">Valor monetário</option>
                                                  <option value="6">Inteiro</option>
                                                  <option value="7">Decimal</option>
                                                  <option value="5">Porcentagem</option>
                                                </select>
                                                {subPergunta.type == "1" ? (
                                                  <div
                                                    className={classes.tipoPerguntaTexto}
                                                    contentEditable
                                                    suppressContentEditableWarning={true}
                                                    onBlur={(e) => {
                                                      if (e.target.innerHTML == "") {
                                                        e.target.innerHTML = "Insira a resposta";
                                                      }
                                                    }}
                                                    onMouseLeave={(e) => {}}
                                                    onClick={(e) => {
                                                      if (e.target.innerHTML == "Insira a resposta") e.target.innerHTML = "";
                                                    }}>
                                                    Insira a resposta
                                                  </div>
                                                ) : subPergunta.type == "2" ? (
                                                  <div className="containerTipoPerguntaOpcaoMultipla">
                                                    {subPergunta?.options?.length > 0 ? (
                                                      subPergunta?.options?.map((opcao1) => {
                                                        return (
                                                          <>
                                                            <div className="containerRadioOption" key={opcao1.id}>
                                                              <button
                                                                className="deleteRadioOption"
                                                                onClick={() => {
                                                                  deleteOpcao(opcao1.id);
                                                                }}>
                                                                <FiTrash2 />
                                                              </button>
                                                              <input type="radio" id="html" name="fav_language" value="HTML" />
                                                              <input
                                                                placeholder="Nome opção"
                                                                onBlur={(e) => changeNameOption(opcao1.id, e.target.value)}
                                                                type="text"
                                                                value={opcao1.description}
                                                                onFocus={(e) => {
                                                                  changeNameOption(opcao1.id, null);
                                                                }}
                                                                className={classes.inputOptionName}
                                                              />
                                                               {" "}
                                                              <button
                                                                className="addSubSection"
                                                                onClick={() => {
                                                                  addPergunta(opcao1.id);
                                                                  setAtualizacao(atualizacao + 1);
                                                                }}>
                                                                + Subpergunta
                                                              </button>
                                                              <div className={classes.containerTags}>
                                                                <div className="containerInfoTags">
                                                                  <AiOutlineInfoCircle title="Se essa opção possuir alguma tag adicionada, esta será enviada para o banco no momento que ela for selecionada por quem está preenchendo o portfolio." size={15} />
                                                                </div>

                                                                <form
                                                                  className="formTags"
                                                                  onSubmit={(e) => {
                                                                    e.preventDefault();
                                                                    addOptionTag(opcao1.id, e.target.firstChild.value);
                                                                    e.target.firstChild.value = "";
                                                                  }}>
                                                                  <input placeholder="Insira o nome da tag" type="text" className={classes.inputNameTag} />

                                                                  <input type="submit" className="addTagButton" value="+" />
                                                                </form>
                                                                <div className="containerDisplayTags">
                                                                  {opcao1?.tag?.map((tag) => {
                                                                    return (
                                                                      <div className="containerTag" key={tag.id}>
                                                                        <button
                                                                          className="deleteTagButton"
                                                                          onClick={(e) => {
                                                                            deleteTag(tag.id);
                                                                            setAtualizacao(atualizacao + 1);
                                                                          }}>
                                                                          X
                                                                        </button>
                                                                        <div className="nomeTag">{tag.description}</div>
                                                                      </div>
                                                                    );
                                                                  })}
                                                                </div>
                                                              </div>
                                                            </div>
                                                            {opcao1?.questions?.length > 0 ? (
                                                              opcao1?.questions?.map((subPergunta1) => {
                                                                //SUBPERGUNTA 2
                                                                return (
                                                                  <div className={classes.pergunta} key={subPergunta1.id}>
                                                                    <button
                                                                      className="deleteRadioOption"
                                                                      onClick={() => {
                                                                        deletePergunta(subPergunta1.id);
                                                                      }}>
                                                                      <FiTrash2 />
                                                                    </button>
                                                                    <div className={classes.barraLateral}></div>
                                                                    <div className="configuracaoPergunta">
                                                                      <div className="opcaoPergunta">
                                                                        <div className={classes.tituloOpcaoPergunta}>Titulo</div>
                                                                        <input
                                                                          placeholder="Pressione para editar"
                                                                          onBlur={(e) => changeNamePergunta(subPergunta1.id, e.target.value)}
                                                                          onFocus={(e) => {
                                                                            changeNamePergunta(subPergunta1.id, null);
                                                                          }}
                                                                          type="text"
                                                                          value={subPergunta1.description}
                                                                          className={classes.inputTituloPergunta}
                                                                        />
                                                                      </div>
                                                                      <div className="opcaoPergunta">
                                                                        <div className={classes.tituloOpcaoPergunta}>Tipo de pergunta</div>
                                                                        <select
                                                                          className={classes.selectTipoPergunta}
                                                                          onChange={(e) => {
                                                                            subPergunta1.type = e.target.value;
                                                                            subPergunta1.options = [];
                                                                            setAtualizacao(atualizacao + 1);
                                                                          }}
                                                                          value={subPergunta1.type}>
                                                                          <option value="1">Texto</option>
                                                                          <option value="2">Seleção Única</option>
                                                                          <option value="3">Checkbox</option>
                                                                          <option value="4">Valor monetário</option>
                                                                          <option value="6">Inteiro</option>
                                                                          <option value="7">Decimal</option>
                                                                          <option value="5">Porcentagem</option>
                                                                        </select>
                                                                        {subPergunta1.type == "1" ? (
                                                                          <div
                                                                            className={classes.tipoPerguntaTexto}
                                                                            contentEditable
                                                                            suppressContentEditableWarning={true}
                                                                            onBlur={(e) => {
                                                                              if (e.target.innerHTML == "") {
                                                                                e.target.innerHTML = "Insira a resposta";
                                                                              }
                                                                            }}
                                                                            onMouseLeave={(e) => {}}
                                                                            onClick={(e) => {
                                                                              if (e.target.innerHTML == "Insira a resposta") e.target.innerHTML = "";
                                                                            }}>
                                                                            Insira a resposta
                                                                          </div>
                                                                        ) : subPergunta1.type == "2" ? (
                                                                          <div className="containerTipoPerguntaOpcaoMultipla">
                                                                            {subPergunta1?.options?.length > 0 ? (
                                                                              subPergunta1?.options?.map((opcao2) => {
                                                                                return (
                                                                                  <>
                                                                                    <div className="containerRadioOption" key={opcao2.id}>
                                                                                      <button
                                                                                        className="deleteRadioOption"
                                                                                        onClick={() => {
                                                                                          deleteOpcao(opcao2.id);
                                                                                        }}>
                                                                                        <FiTrash2 />
                                                                                      </button>
                                                                                      <input type="radio" id="html" name="fav_language" value="HTML" />
                                                                                      <input
                                                                                        placeholder="Nome opção"
                                                                                        onBlur={(e) => changeNameOption(opcao2.id, e.target.value)}
                                                                                        onFocus={(e) => {
                                                                                          changeNameOption(opcao2.id, null);
                                                                                        }}
                                                                                        type="text"
                                                                                        value={opcao2.description}
                                                                                        className={classes.inputOptionName}
                                                                                      />
                                                                                       {" "}
                                                                                      <button
                                                                                        className="addSubSection"
                                                                                        onClick={() => {
                                                                                          addPergunta(opcao2.id);
                                                                                          setAtualizacao(atualizacao + 1);
                                                                                        }}>
                                                                                        + Subpergunta
                                                                                      </button>
                                                                                      <div className={classes.containerTags}>
                                                                                        <div className="containerInfoTags">
                                                                                          <AiOutlineInfoCircle title="Se essa opção possuir alguma tag adicionada, esta será enviada para o banco no momento que ela for selecionada por quem está preenchendo o portfolio." size={15} />
                                                                                        </div>

                                                                                        <form
                                                                                          className="formTags"
                                                                                          onSubmit={(e) => {
                                                                                            e.preventDefault();
                                                                                            addOptionTag(opcao2.id, e.target.firstChild.value);
                                                                                            e.target.firstChild.value = "";
                                                                                          }}>
                                                                                          <input placeholder="Insira o nome da tag" type="text" className={classes.inputNameTag} />

                                                                                          <input type="submit" className="addTagButton" value="+" />
                                                                                        </form>
                                                                                        <div className="containerDisplayTags">
                                                                                          {opcao2?.tag?.map((tag) => {
                                                                                            return (
                                                                                              <div className="containerTag" key={tag.id}>
                                                                                                <button
                                                                                                  className="deleteTagButton"
                                                                                                  onClick={(e) => {
                                                                                                    deleteTag(tag.id);
                                                                                                    setAtualizacao(atualizacao + 1);
                                                                                                  }}>
                                                                                                  X
                                                                                                </button>
                                                                                                <div className="nomeTag">{tag.description}</div>
                                                                                              </div>
                                                                                            );
                                                                                          })}
                                                                                        </div>
                                                                                      </div>
                                                                                    </div>
                                                                                    {opcao2?.questions?.length > 0 ? (
                                                                                      opcao2?.questions?.map((subPergunta2) => {
                                                                                        //SUBPERGUNTA 3
                                                                                        return (
                                                                                          <div className={classes.pergunta} key={subPergunta2.id}>
                                                                                            <button
                                                                                              className="deleteRadioOption"
                                                                                              onClick={() => {
                                                                                                deletePergunta(subPergunta2.id);
                                                                                              }}>
                                                                                              <FiTrash2 />
                                                                                            </button>
                                                                                            <div className={classes.barraLateral}></div>
                                                                                            <div className="configuracaoPergunta">
                                                                                              <div className="opcaoPergunta">
                                                                                                <div className={classes.tituloOpcaoPergunta}>Titulo</div>
                                                                                                <input
                                                                                                  placeholder="Pressione para editar"
                                                                                                  onBlur={(e) => changeNamePergunta(subPergunta2.id, e.target.value)}
                                                                                                  onFocus={(e) => {
                                                                                                    changeNamePergunta(subPergunta2.id, null);
                                                                                                  }}
                                                                                                  type="text"
                                                                                                  value={subPergunta2.description}
                                                                                                  className={classes.inputTituloPergunta}
                                                                                                />
                                                                                              </div>
                                                                                              <div className="opcaoPergunta">
                                                                                                <div className={classes.tituloOpcaoPergunta}>Tipo de pergunta</div>
                                                                                                <select
                                                                                                  className={classes.selectTipoPergunta}
                                                                                                  onChange={(e) => {
                                                                                                    subPergunta2.type = e.target.value;
                                                                                                    subPergunta2.options = [];
                                                                                                    setAtualizacao(atualizacao + 1);
                                                                                                  }}
                                                                                                  value={subPergunta2.type}>
                                                                                                  <option value="1">Texto</option>
                                                                                                  <option value="2">Seleção Única</option>
                                                                                                  <option value="3">Checkbox</option>
                                                                                                  <option value="4">Valor monetário</option>
                                                                                                  <option value="6">Inteiro</option>
                                                                                                  <option value="7">Decimal</option>
                                                                                                  <option value="5">Porcentagem</option>
                                                                                                </select>
                                                                                                {subPergunta2.type == "1" ? (
                                                                                                  <div
                                                                                                    className={classes.tipoPerguntaTexto}
                                                                                                    contentEditable
                                                                                                    suppressContentEditableWarning={true}
                                                                                                    onBlur={(e) => {
                                                                                                      if (e.target.innerHTML == "") {
                                                                                                        e.target.innerHTML = "Insira a resposta";
                                                                                                      }
                                                                                                    }}
                                                                                                    onMouseLeave={(e) => {}}
                                                                                                    onClick={(e) => {
                                                                                                      if (e.target.innerHTML == "Insira a resposta") e.target.innerHTML = "";
                                                                                                    }}>
                                                                                                    Insira a resposta
                                                                                                  </div>
                                                                                                ) : subPergunta2.type == "2" ? (
                                                                                                  <div className="containerTipoPerguntaOpcaoMultipla">
                                                                                                    {subPergunta2?.options?.length > 0 ? (
                                                                                                      subPergunta2?.options?.map((opcao3) => {
                                                                                                        return (
                                                                                                          <>
                                                                                                            <div className="containerRadioOption" key={opcao3.id}>
                                                                                                              <button
                                                                                                                className="deleteRadioOption"
                                                                                                                onClick={() => {
                                                                                                                  deleteOpcao(opcao3.id);
                                                                                                                }}>
                                                                                                                <FiTrash2 />
                                                                                                              </button>
                                                                                                              <input type="radio" id="html" name="fav_language" value="HTML" />
                                                                                                              <input
                                                                                                                placeholder="Nome opção"
                                                                                                                onBlur={(e) => changeNameOption(opcao3.id, e.target.value)}
                                                                                                                onFocus={(e) => {
                                                                                                                  changeNameOption(opcao3.id, null);
                                                                                                                }}
                                                                                                                type="text"
                                                                                                                value={opcao3.description}
                                                                                                                className={classes.inputOptionName}
                                                                                                              />
                                                                                                              <div className={classes.containerTags}>
                                                                                                                <div className="containerInfoTags">
                                                                                                                  <AiOutlineInfoCircle title="Se essa opção possuir alguma tag adicionada, esta será enviada para o banco no momento que ela for selecionada por quem está preenchendo o portfolio." size={15} />
                                                                                                                </div>

                                                                                                                <form
                                                                                                                  className="formTags"
                                                                                                                  onSubmit={(e) => {
                                                                                                                    e.preventDefault();
                                                                                                                    addOptionTag(opcao3.id, e.target.firstChild.value);
                                                                                                                    e.target.firstChild.value = "";
                                                                                                                  }}>
                                                                                                                  <input placeholder="Insira o nome da tag" type="text" className={classes.inputNameTag} />

                                                                                                                  <input type="submit" className="addTagButton" value="+" />
                                                                                                                </form>
                                                                                                                <div className="containerDisplayTags">
                                                                                                                  {opcao3?.tag?.map((tag) => {
                                                                                                                    return (
                                                                                                                      <div className="containerTag" key={tag.id}>
                                                                                                                        <button
                                                                                                                          className="deleteTagButton"
                                                                                                                          onClick={(e) => {
                                                                                                                            deleteTag(tag.id);
                                                                                                                            setAtualizacao(atualizacao + 1);
                                                                                                                          }}>
                                                                                                                          X
                                                                                                                        </button>
                                                                                                                        <div className="nomeTag">{tag.description}</div>
                                                                                                                      </div>
                                                                                                                    );
                                                                                                                  })}
                                                                                                                </div>
                                                                                                              </div>
                                                                                                            </div>
                                                                                                          </>
                                                                                                        );
                                                                                                      })
                                                                                                    ) : (
                                                                                                      <></>
                                                                                                    )}
                                                                                                    <button
                                                                                                      className="addRadioOption"
                                                                                                      onClick={() => {
                                                                                                        addOpcao(subPergunta2.id);
                                                                                                      }}>
                                                                                                      + Adicionar opção
                                                                                                    </button>
                                                                                                  </div>
                                                                                                ) : subPergunta2.type == "3" ? (
                                                                                                  <div className="containerTipoPerguntaCaixaSelecao">
                                                                                                    {subPergunta2?.options?.length > 0 ? (
                                                                                                      subPergunta2?.options?.map((opcao3) => {
                                                                                                        return (
                                                                                                          <>
                                                                                                            <div className="containerCheckBoxOption" key={opcao3.id}>
                                                                                                              <button
                                                                                                                className="deleteCheckBoxOption"
                                                                                                                onClick={() => {
                                                                                                                  deleteOpcao(opcao3.id);
                                                                                                                }}>
                                                                                                                <FiTrash2 />
                                                                                                              </button>
                                                                                                              <input type="Checkbox" id="3" name="3" />
                                                                                                              <input
                                                                                                                placeholder="Nome opção"
                                                                                                                onBlur={(e) => changeNameOption(opcao3.id, e.target.value)}
                                                                                                                onFocus={(e) => {
                                                                                                                  changeNameOption(opcao3.id, null);
                                                                                                                }}
                                                                                                                type="text"
                                                                                                                value={opcao3.description}
                                                                                                                className={classes.inputOptionName}
                                                                                                              />
                                                                                                              <div className={classes.containerTags}>
                                                                                                                <div className="containerInfoTags">
                                                                                                                  <AiOutlineInfoCircle title="Se essa opção possuir alguma tag adicionada, esta será enviada para o banco no momento que ela for selecionada por quem está preenchendo o portfolio." size={15} />
                                                                                                                </div>

                                                                                                                <form
                                                                                                                  className="formTags"
                                                                                                                  onSubmit={(e) => {
                                                                                                                    e.preventDefault();
                                                                                                                    addOptionTag(opcao3.id, e.target.firstChild.value);
                                                                                                                    e.target.firstChild.value = "";
                                                                                                                  }}>
                                                                                                                  <input placeholder="Insira o nome da tag" type="text" className={classes.inputNameTag} />

                                                                                                                  <input type="submit" className="addTagButton" value="+" />
                                                                                                                </form>
                                                                                                                <div className="containerDisplayTags">
                                                                                                                  {opcao3?.tag?.map((tag) => {
                                                                                                                    return (
                                                                                                                      <div className="containerTag" key={tag.id}>
                                                                                                                        <button
                                                                                                                          className="deleteTagButton"
                                                                                                                          onClick={(e) => {
                                                                                                                            deleteTag(tag.id);
                                                                                                                            setAtualizacao(atualizacao + 1);
                                                                                                                          }}>
                                                                                                                          X
                                                                                                                        </button>
                                                                                                                        <div className="nomeTag">{tag.description}</div>
                                                                                                                      </div>
                                                                                                                    );
                                                                                                                  })}
                                                                                                                </div>
                                                                                                              </div>
                                                                                                            </div>
                                                                                                          </>
                                                                                                        );
                                                                                                      })
                                                                                                    ) : (
                                                                                                      <></>
                                                                                                    )}
                                                                                                    <button
                                                                                                      className="addCheckBoxOption"
                                                                                                      onClick={() => {
                                                                                                        addOpcao(subPergunta2.id);
                                                                                                      }}>
                                                                                                      + Adicionar opção
                                                                                                    </button>
                                                                                                  </div>
                                                                                                ) : subPergunta2.type == "4" ? (
                                                                                                  <div>
                                                                                                    <CurrencyTextField
                                                                                                      InputProps={{
                                                                                                        classes: {
                                                                                                          notchedOutline: classes.notchedOutline,
                                                                                                        },
                                                                                                      }}
                                                                                                      variant="outlined"
                                                                                                      value={precoTemp}
                                                                                                      className="tipoPerguntaMonetario"
                                                                                                      currencySymbol="R$"
                                                                                                      outputFormat="number"
                                                                                                      decimalCharacter=","
                                                                                                      required={true}
                                                                                                      digitGroupSeparator="."
                                                                                                      onChange={(event, value) => {
                                                                                                        if (value === "" || value === 0) {
                                                                                                          setPrecoTemp(0);
                                                                                                        } else {
                                                                                                          setPrecoTemp(value);
                                                                                                        }
                                                                                                      }}
                                                                                                    />
                                                                                                  </div>
                                                                                                ) : subPergunta2.type == "5" ? (
                                                                                                  <div className="containerTipoPerguntaPorcentagem">
                                                                                                    <TextField
                                                                                                      InputProps={{
                                                                                                        classes: {
                                                                                                          notchedOutline: classes.notchedOutline,
                                                                                                        },
                                                                                                      }}
                                                                                                      type="number"
                                                                                                      onKeyPress={(e) => {
                                                                                                        if (e.code === "Backquote") e.preventDefault();
                                                                                                      }}
                                                                                                      step="0.00"
                                                                                                      onKeyDown={(e) => {
                                                                                                        if (e.key === "e" || e.key === "E") {
                                                                                                          e.preventDefault();
                                                                                                        }
                                                                                                      }}
                                                                                                      value={percentTemp}
                                                                                                      className="tipoPerguntaPorcentagem"
                                                                                                      variant="outlined"
                                                                                                      onChange={(e) => {
                                                                                                        setPercentTemp(e.target.value);
                                                                                                      }}
                                                                                                    />
                                                                                                    <div className="porcentagem">%</div>
                                                                                                  </div>
                                                                                                ) : subPergunta2.type == "6" ? (
                                                                                                  <div className="containerTipoPerguntaPorcentagem">
                                                                                                    <TextField
                                                                                                      
                                                                                                      InputProps={{ classes: { notchedOutline: classes.notchedOutline } }}
                                                                                                      type="number"
                                                                                                      onKeyPress={(e) => {
                                                                                                        if (e.key === "." || e.key === ",") e.preventDefault();
                                                                                                      }}
                                                                                                      value={inteiroTemp}
                                                                                                      className="tipoPerguntaPorcentagem"
                                                                                                      variant="outlined"
                                                                                                      onChange={(e) => {
                                                                                                        setInteiroTemp(e.target.value);
                                                                                                      }}
                                                                                                    />
                                                                                                  </div>
                                                                                                ) : subPergunta2.type == "7" ? (
                                                                                                  <div className="containerTipoPerguntaPorcentagem">
                                                                                                    <TextField
                                                                                                      
                                                                                                      InputProps={{ classes: { notchedOutline: classes.notchedOutline } }}
                                                                                                      type="number"
                                                                                                      value={decimalTemp}
                                                                                                      className="tipoPerguntaPorcentagem"
                                                                                                      variant="outlined"
                                                                                                      onChange={(e) => {
                                                                                                        setDecimalTemp(e.target.value);
                                                                                                      }}
                                                                                                    />
                                                                                                  </div>
                                                                                                ) : (
                                                                                                  <></>
                                                                                                )}
                                                                                              </div>
                                                                                              <div className="habilitarObservacao">
                                                                                                <input
                                                                                                  checked={subPergunta2.observation}
                                                                                                  onChange={(e) => {
                                                                                                    changeObservation(subPergunta2.id);
                                                                                                  }}
                                                                                                  type="Checkbox"
                                                                                                  id={"Checkbox" + subPergunta2.id}
                                                                                                  name="Checkbox"
                                                                                                />
                                                                                                <label for={"Checkbox" + subPergunta2.id}> Adicionar campo de observação?</label>
                                                                                              </div>
                                                                                            </div>
                                                                                          </div>
                                                                                        );
                                                                                        //SUBPERGUNTA 3
                                                                                      })
                                                                                    ) : (
                                                                                      <></>
                                                                                    )}
                                                                                  </>
                                                                                );
                                                                              })
                                                                            ) : (
                                                                              <></>
                                                                            )}
                                                                            <button
                                                                              className="addRadioOption"
                                                                              onClick={() => {
                                                                                addOpcao(subPergunta1.id);
                                                                              }}>
                                                                              + Adicionar opção
                                                                            </button>
                                                                          </div>
                                                                        ) : subPergunta1.type == "3" ? (
                                                                          <div className="containerTipoPerguntaOpcaoMultipla">
                                                                            {subPergunta1?.options?.length > 0 ? (
                                                                              subPergunta1?.options?.map((opcao2) => {
                                                                                return (
                                                                                  <>
                                                                                    <div className="containerCheckBoxOption" key={opcao2.id}>
                                                                                      <button
                                                                                        className="deleteCheckBoxOption"
                                                                                        onClick={() => {
                                                                                          deleteOpcao(opcao2.id);
                                                                                        }}>
                                                                                        <FiTrash2 />
                                                                                      </button>
                                                                                      <input type="Checkbox" id="3" name="3" />
                                                                                      <input
                                                                                        placeholder="Nome opção"
                                                                                        onBlur={(e) => changeNameOption(opcao2.id, e.target.value)}
                                                                                        onFocus={(e) => {
                                                                                          changeNameOption(opcao2.id, null);
                                                                                        }}
                                                                                        type="text"
                                                                                        value={opcao2.description}
                                                                                        className={classes.inputOptionName}
                                                                                      />
                                                                                       {" "}
                                                                                      <button
                                                                                        className="addSubSection"
                                                                                        onClick={() => {
                                                                                          addPergunta(opcao2.id);
                                                                                          setAtualizacao(atualizacao + 1);
                                                                                        }}>
                                                                                        + Subpergunta
                                                                                      </button>
                                                                                      <div className={classes.containerTags}>
                                                                                        <div className="containerInfoTags">
                                                                                          <AiOutlineInfoCircle title="Se essa opção possuir alguma tag adicionada, esta será enviada para o banco no momento que ela for selecionada por quem está preenchendo o portfolio." size={15} />
                                                                                        </div>

                                                                                        <form
                                                                                          className="formTags"
                                                                                          onSubmit={(e) => {
                                                                                            e.preventDefault();
                                                                                            addOptionTag(opcao2.id, e.target.firstChild.value);
                                                                                            e.target.firstChild.value = "";
                                                                                          }}>
                                                                                          <input placeholder="Insira o nome da tag" type="text" className={classes.inputNameTag} />

                                                                                          <input type="submit" className="addTagButton" value="+" />
                                                                                        </form>
                                                                                        <div className="containerDisplayTags">
                                                                                          {opcao2?.tag?.map((tag) => {
                                                                                            return (
                                                                                              <div className="containerTag" key={tag.id}>
                                                                                                <button
                                                                                                  className="deleteTagButton"
                                                                                                  onClick={(e) => {
                                                                                                    deleteTag(tag.id);
                                                                                                    setAtualizacao(atualizacao + 1);
                                                                                                  }}>
                                                                                                  X
                                                                                                </button>
                                                                                                <div className="nomeTag">{tag.description}</div>
                                                                                              </div>
                                                                                            );
                                                                                          })}
                                                                                        </div>
                                                                                      </div>
                                                                                    </div>
                                                                                    {opcao2?.questions?.length > 0 ? (
                                                                                      opcao2?.questions?.map((subPergunta2) => {
                                                                                        //SUBPERGUNTA 3
                                                                                        return (
                                                                                          <div className={classes.pergunta} key={subPergunta2.id}>
                                                                                            <button
                                                                                              className="deleteCheckBoxOption"
                                                                                              onClick={() => {
                                                                                                deletePergunta(subPergunta2.id);
                                                                                              }}>
                                                                                              <FiTrash2 />
                                                                                            </button>
                                                                                            <div className={classes.barraLateral}></div>
                                                                                            <div className="configuracaoPergunta">
                                                                                              <div className="opcaoPergunta">
                                                                                                <div className={classes.tituloOpcaoPergunta}>Titulo</div>
                                                                                                <input
                                                                                                  placeholder="Pressione para editar"
                                                                                                  onBlur={(e) => changeNamePergunta(subPergunta2.id, e.target.value)}
                                                                                                  onFocus={(e) => {
                                                                                                    changeNamePergunta(subPergunta2.id, null);
                                                                                                  }}
                                                                                                  type="text"
                                                                                                  value={subPergunta2.description}
                                                                                                  className={classes.inputTituloPergunta}
                                                                                                />
                                                                                              </div>
                                                                                              <div className="opcaoPergunta">
                                                                                                <div className={classes.tituloOpcaoPergunta}>Tipo de pergunta</div>
                                                                                                <select
                                                                                                  className={classes.selectTipoPergunta}
                                                                                                  onChange={(e) => {
                                                                                                    subPergunta2.type = e.target.value;
                                                                                                    subPergunta2.options = [];
                                                                                                    setAtualizacao(atualizacao + 1);
                                                                                                  }}
                                                                                                  value={subPergunta2.type}>
                                                                                                  <option value="1">Texto</option>
                                                                                                  <option value="2">Seleção Única</option>
                                                                                                  <option value="3">Checkbox</option>
                                                                                                  <option value="4">Valor monetário</option>
                                                                                                  <option value="6">Inteiro</option>
                                                                                                  <option value="7">Decimal</option>
                                                                                                  <option value="5">Porcentagem</option>
                                                                                                </select>
                                                                                                {subPergunta2.type == "1" ? (
                                                                                                  <div
                                                                                                    className={classes.tipoPerguntaTexto}
                                                                                                    contentEditable
                                                                                                    suppressContentEditableWarning={true}
                                                                                                    onBlur={(e) => {
                                                                                                      if (e.target.innerHTML == "") {
                                                                                                        e.target.innerHTML = "Insira a resposta";
                                                                                                      }
                                                                                                    }}
                                                                                                    onMouseLeave={(e) => {}}
                                                                                                    onClick={(e) => {
                                                                                                      if (e.target.innerHTML == "Insira a resposta") e.target.innerHTML = "";
                                                                                                    }}>
                                                                                                    Insira a resposta
                                                                                                  </div>
                                                                                                ) : subPergunta2.type == "2" ? (
                                                                                                  <div className="containerTipoPerguntaOpcaoMultipla">
                                                                                                    {subPergunta2?.options?.length > 0 ? (
                                                                                                      subPergunta2?.options?.map((opcao3) => {
                                                                                                        return (
                                                                                                          <>
                                                                                                            <div className="containerRadioOption" key={opcao3.id}>
                                                                                                              <button
                                                                                                                className="deleteRadioOption"
                                                                                                                onClick={() => {
                                                                                                                  deleteOpcao(opcao3.id);
                                                                                                                }}>
                                                                                                                <FiTrash2 />
                                                                                                              </button>
                                                                                                              <input type="radio" id="html" name="fav_language" value="HTML" />
                                                                                                              <input
                                                                                                                placeholder="Nome opção"
                                                                                                                onBlur={(e) => changeNameOption(opcao3.id, e.target.value)}
                                                                                                                onFocus={(e) => {
                                                                                                                  changeNameOption(opcao3.id, null);
                                                                                                                }}
                                                                                                                type="text"
                                                                                                                value={opcao3.description}
                                                                                                                className={classes.inputOptionName}
                                                                                                              />
                                                                                                              <div className={classes.containerTags}>
                                                                                                                <div className="containerInfoTags">
                                                                                                                  <AiOutlineInfoCircle title="Se essa opção possuir alguma tag adicionada, esta será enviada para o banco no momento que ela for selecionada por quem está preenchendo o portfolio." size={15} />
                                                                                                                </div>

                                                                                                                <form
                                                                                                                  className="formTags"
                                                                                                                  onSubmit={(e) => {
                                                                                                                    e.preventDefault();
                                                                                                                    addOptionTag(opcao3.id, e.target.firstChild.value);
                                                                                                                    e.target.firstChild.value = "";
                                                                                                                  }}>
                                                                                                                  <input placeholder="Insira o nome da tag" type="text" className={classes.inputNameTag} />

                                                                                                                  <input type="submit" className="addTagButton" value="+" />
                                                                                                                </form>
                                                                                                                <div className="containerDisplayTags">
                                                                                                                  {opcao3?.tag?.map((tag) => {
                                                                                                                    return (
                                                                                                                      <div className="containerTag" key={tag.id}>
                                                                                                                        <button
                                                                                                                          className="deleteTagButton"
                                                                                                                          onClick={(e) => {
                                                                                                                            deleteTag(tag.id);
                                                                                                                            setAtualizacao(atualizacao + 1);
                                                                                                                          }}>
                                                                                                                          X
                                                                                                                        </button>
                                                                                                                        <div className="nomeTag">{tag.description}</div>
                                                                                                                      </div>
                                                                                                                    );
                                                                                                                  })}
                                                                                                                </div>
                                                                                                              </div>
                                                                                                            </div>
                                                                                                          </>
                                                                                                        );
                                                                                                      })
                                                                                                    ) : (
                                                                                                      <></>
                                                                                                    )}
                                                                                                    <button
                                                                                                      className="addRadioOption"
                                                                                                      onClick={() => {
                                                                                                        addOpcao(subPergunta2.id);
                                                                                                      }}>
                                                                                                      + Adicionar opção
                                                                                                    </button>
                                                                                                  </div>
                                                                                                ) : subPergunta2.type == "3" ? (
                                                                                                  <div className="containerTipoPerguntaCaixaSelecao">
                                                                                                    {subPergunta2?.options?.length > 0 ? (
                                                                                                      subPergunta2?.options?.map((opcao3) => {
                                                                                                        return (
                                                                                                          <>
                                                                                                            <div className="containerCheckBoxOption" key={opcao3.id}>
                                                                                                              <button
                                                                                                                className="deleteCheckBoxOption"
                                                                                                                onClick={() => {
                                                                                                                  deleteOpcao(opcao3.id);
                                                                                                                }}>
                                                                                                                <FiTrash2 />
                                                                                                              </button>
                                                                                                              <input type="Checkbox" id="3" name="3" />
                                                                                                              <input
                                                                                                                placeholder="Nome opção"
                                                                                                                onBlur={(e) => changeNameOption(opcao3.id, e.target.value)}
                                                                                                                onFocus={(e) => {
                                                                                                                  changeNameOption(opcao3.id, null);
                                                                                                                }}
                                                                                                                type="text"
                                                                                                                value={opcao3.description}
                                                                                                                className={classes.inputOptionName}
                                                                                                              />
                                                                                                              <div className={classes.containerTags}>
                                                                                                                <div className="containerInfoTags">
                                                                                                                  <AiOutlineInfoCircle title="Se essa opção possuir alguma tag adicionada, esta será enviada para o banco no momento que ela for selecionada por quem está preenchendo o portfolio." size={15} />
                                                                                                                </div>

                                                                                                                <form
                                                                                                                  className="formTags"
                                                                                                                  onSubmit={(e) => {
                                                                                                                    e.preventDefault();
                                                                                                                    addOptionTag(opcao3.id, e.target.firstChild.value);
                                                                                                                    e.target.firstChild.value = "";
                                                                                                                  }}>
                                                                                                                  <input placeholder="Insira o nome da tag" type="text" className={classes.inputNameTag} />

                                                                                                                  <input type="submit" className="addTagButton" value="+" />
                                                                                                                </form>
                                                                                                                <div className="containerDisplayTags">
                                                                                                                  {opcao3?.tag?.map((tag) => {
                                                                                                                    return (
                                                                                                                      <div className="containerTag" key={tag.id}>
                                                                                                                        <button
                                                                                                                          className="deleteTagButton"
                                                                                                                          onClick={(e) => {
                                                                                                                            deleteTag(tag.id);
                                                                                                                            setAtualizacao(atualizacao + 1);
                                                                                                                          }}>
                                                                                                                          X
                                                                                                                        </button>
                                                                                                                        <div className="nomeTag">{tag.description}</div>
                                                                                                                      </div>
                                                                                                                    );
                                                                                                                  })}
                                                                                                                </div>
                                                                                                              </div>
                                                                                                            </div>
                                                                                                          </>
                                                                                                        );
                                                                                                      })
                                                                                                    ) : (
                                                                                                      <></>
                                                                                                    )}
                                                                                                    <button
                                                                                                      className="addCheckBoxOption"
                                                                                                      onClick={() => {
                                                                                                        addOpcao(subPergunta2.id);
                                                                                                      }}>
                                                                                                      + Adicionar opção
                                                                                                    </button>
                                                                                                  </div>
                                                                                                ) : subPergunta2.type == "4" ? (
                                                                                                  <div>
                                                                                                    <CurrencyTextField
                                                                                                      InputProps={{
                                                                                                        classes: {
                                                                                                          notchedOutline: classes.notchedOutline,
                                                                                                        },
                                                                                                      }}
                                                                                                      variant="outlined"
                                                                                                      value={precoTemp}
                                                                                                      className="tipoPerguntaMonetario"
                                                                                                      currencySymbol="R$"
                                                                                                      outputFormat="number"
                                                                                                      decimalCharacter=","
                                                                                                      required={true}
                                                                                                      digitGroupSeparator="."
                                                                                                      onChange={(event, value) => {
                                                                                                        if (value === "" || value === 0) {
                                                                                                          setPrecoTemp(0);
                                                                                                        } else {
                                                                                                          setPrecoTemp(value);
                                                                                                        }
                                                                                                      }}
                                                                                                    />
                                                                                                  </div>
                                                                                                ) : subPergunta2.type == "5" ? (
                                                                                                  <div className="containerTipoPerguntaPorcentagem">
                                                                                                    <TextField
                                                                                                      InputProps={{
                                                                                                        classes: {
                                                                                                          notchedOutline: classes.notchedOutline,
                                                                                                        },
                                                                                                      }}
                                                                                                      type="number"
                                                                                                      onKeyPress={(e) => {
                                                                                                        if (e.code === "Backquote") e.preventDefault();
                                                                                                      }}
                                                                                                      step="0.00"
                                                                                                      onKeyDown={(e) => {
                                                                                                        if (e.key === "e" || e.key === "E") {
                                                                                                          e.preventDefault();
                                                                                                        }
                                                                                                      }}
                                                                                                      value={percentTemp}
                                                                                                      className="tipoPerguntaPorcentagem"
                                                                                                      variant="outlined"
                                                                                                      onChange={(e) => {
                                                                                                        setPercentTemp(e.target.value);
                                                                                                      }}
                                                                                                    />
                                                                                                    <div className="porcentagem">%</div>
                                                                                                  </div>
                                                                                                ) : subPergunta2.type == "6" ? (
                                                                                                  <div className="containerTipoPerguntaPorcentagem">
                                                                                                    <TextField
                                                                                                      
                                                                                                      InputProps={{ classes: { notchedOutline: classes.notchedOutline } }}
                                                                                                      type="number"
                                                                                                      onKeyPress={(e) => {
                                                                                                        if (e.key === "." || e.key === ",") e.preventDefault();
                                                                                                      }}
                                                                                                      value={inteiroTemp}
                                                                                                      className="tipoPerguntaPorcentagem"
                                                                                                      variant="outlined"
                                                                                                      onChange={(e) => {
                                                                                                        setInteiroTemp(e.target.value);
                                                                                                      }}
                                                                                                    />
                                                                                                  </div>
                                                                                                ) : subPergunta2.type == "7" ? (
                                                                                                  <div className="containerTipoPerguntaPorcentagem">
                                                                                                    <TextField
                                                                                                      
                                                                                                      InputProps={{ classes: { notchedOutline: classes.notchedOutline } }}
                                                                                                      type="number"
                                                                                                      value={decimalTemp}
                                                                                                      className="tipoPerguntaPorcentagem"
                                                                                                      variant="outlined"
                                                                                                      onChange={(e) => {
                                                                                                        setDecimalTemp(e.target.value);
                                                                                                      }}
                                                                                                    />
                                                                                                  </div>
                                                                                                ) : (
                                                                                                  <></>
                                                                                                )}
                                                                                              </div>
                                                                                              <div className="habilitarObservacao">
                                                                                                <input
                                                                                                  checked={subPergunta2.observation}
                                                                                                  onChange={(e) => {
                                                                                                    changeObservation(subPergunta2.id);
                                                                                                  }}
                                                                                                  type="Checkbox"
                                                                                                  id={"Checkbox" + subPergunta2.id}
                                                                                                  name="Checkbox"
                                                                                                />
                                                                                                <label for={"Checkbox" + subPergunta2.id}> Adicionar campo de observação?</label>
                                                                                              </div>
                                                                                            </div>
                                                                                          </div>
                                                                                        );
                                                                                        //SUBPERGUNTA 3
                                                                                      })
                                                                                    ) : (
                                                                                      <></>
                                                                                    )}
                                                                                  </>
                                                                                );
                                                                              })
                                                                            ) : (
                                                                              <></>
                                                                            )}
                                                                            <button
                                                                              className="addRadioOption"
                                                                              onClick={() => {
                                                                                addOpcao(subPergunta1.id);
                                                                              }}>
                                                                              + Adicionar opção
                                                                            </button>
                                                                          </div>
                                                                        ) : subPergunta1.type == "4" ? (
                                                                          <div>
                                                                            <CurrencyTextField
                                                                              InputProps={{
                                                                                classes: {
                                                                                  notchedOutline: classes.notchedOutline,
                                                                                },
                                                                              }}
                                                                              variant="outlined"
                                                                              value={precoTemp}
                                                                              className="tipoPerguntaMonetario"
                                                                              currencySymbol="R$"
                                                                              outputFormat="number"
                                                                              decimalCharacter=","
                                                                              required={true}
                                                                              digitGroupSeparator="."
                                                                              onChange={(event, value) => {
                                                                                if (value === "" || value === 0) {
                                                                                  setPrecoTemp(0);
                                                                                } else {
                                                                                  setPrecoTemp(value);
                                                                                }
                                                                              }}
                                                                            />
                                                                          </div>
                                                                        ) : subPergunta1.type == "5" ? (
                                                                          <div className="containerTipoPerguntaPorcentagem">
                                                                            <TextField
                                                                              InputProps={{
                                                                                classes: {
                                                                                  notchedOutline: classes.notchedOutline,
                                                                                },
                                                                              }}
                                                                              type="number"
                                                                              onKeyPress={(e) => {
                                                                                if (e.code === "Backquote") e.preventDefault();
                                                                              }}
                                                                              step="0.00"
                                                                              onKeyDown={(e) => {
                                                                                if (e.key === "e" || e.key === "E") {
                                                                                  e.preventDefault();
                                                                                }
                                                                              }}
                                                                              value={percentTemp}
                                                                              className="tipoPerguntaPorcentagem"
                                                                              variant="outlined"
                                                                              onChange={(e) => {
                                                                                setPercentTemp(e.target.value);
                                                                              }}
                                                                            />
                                                                            <div className="porcentagem">%</div>
                                                                          </div>
                                                                        ) : subPergunta1.type == "6" ? (
                                                                          <div className="containerTipoPerguntaPorcentagem">
                                                                            <TextField
                                                                              
                                                                              InputProps={{ classes: { notchedOutline: classes.notchedOutline } }}
                                                                              type="number"
                                                                              onKeyPress={(e) => {
                                                                                if (e.key === "." || e.key === ",") e.preventDefault();
                                                                              }}
                                                                              value={inteiroTemp}
                                                                              className="tipoPerguntaPorcentagem"
                                                                              variant="outlined"
                                                                              onChange={(e) => {
                                                                                setInteiroTemp(e.target.value);
                                                                              }}
                                                                            />
                                                                          </div>
                                                                        ) : subPergunta1.type == "7" ? (
                                                                          <div className="containerTipoPerguntaPorcentagem">
                                                                            <TextField
                                                                              
                                                                              InputProps={{ classes: { notchedOutline: classes.notchedOutline } }}
                                                                              type="number"
                                                                              value={decimalTemp}
                                                                              className="tipoPerguntaPorcentagem"
                                                                              variant="outlined"
                                                                              onChange={(e) => {
                                                                                setDecimalTemp(e.target.value);
                                                                              }}
                                                                            />
                                                                          </div>
                                                                        ) : (
                                                                          <></>
                                                                        )}
                                                                      </div>
                                                                      <div className="habilitarObservacao">
                                                                        <input
                                                                          checked={subPergunta1.observation}
                                                                          onChange={(e) => {
                                                                            changeObservation(subPergunta1.id);
                                                                          }}
                                                                          type="Checkbox"
                                                                          id={"Checkbox" + subPergunta1.id}
                                                                          name="Checkbox"
                                                                        />
                                                                        <label for={"Checkbox" + subPergunta1.id}> Adicionar campo de observação?</label>
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                );
                                                                //SUBPERGUNTA 2
                                                              })
                                                            ) : (
                                                              <></>
                                                            )}
                                                          </>
                                                        );
                                                      })
                                                    ) : (
                                                      <></>
                                                    )}
                                                    <button
                                                      className="addRadioOption"
                                                      onClick={() => {
                                                        addOpcao(subPergunta.id);
                                                      }}>
                                                      + Adicionar opção
                                                    </button>
                                                  </div>
                                                ) : subPergunta.type == "3" ? (
                                                  <div className="containerTipoPerguntaOpcaoMultipla">
                                                    {subPergunta?.options?.length > 0 ? (
                                                      subPergunta?.options?.map((opcao1) => {
                                                        return (
                                                          <>
                                                            <div className="containerRadioOption" key={opcao1.id}>
                                                              <button
                                                                className="deleteRadioOption"
                                                                onClick={() => {
                                                                  deleteOpcao(opcao1.id);
                                                                }}>
                                                                <FiTrash2 />
                                                              </button>
                                                              <input type="Checkbox" id="3" name="3" />
                                                              <input
                                                                placeholder="Nome opção"
                                                                onBlur={(e) => changeNameOption(opcao1.id, e.target.value)}
                                                                onFocus={(e) => {
                                                                  changeNameOption(opcao1.id, null);
                                                                }}
                                                                type="text"
                                                                value={opcao1.description}
                                                                className={classes.inputOptionName}
                                                              />
                                                               {" "}
                                                              <button
                                                                className="addSubSection"
                                                                onClick={() => {
                                                                  addPergunta(opcao1.id);
                                                                  setAtualizacao(atualizacao + 1);
                                                                }}>
                                                                + Subpergunta
                                                              </button>
                                                              <div className={classes.containerTags}>
                                                                <div className="containerInfoTags">
                                                                  <AiOutlineInfoCircle title="Se essa opção possuir alguma tag adicionada, esta será enviada para o banco no momento que ela for selecionada por quem está preenchendo o portfolio." size={15} />
                                                                </div>

                                                                <form
                                                                  className="formTags"
                                                                  onSubmit={(e) => {
                                                                    e.preventDefault();
                                                                    addOptionTag(opcao1.id, e.target.firstChild.value);
                                                                    e.target.firstChild.value = "";
                                                                  }}>
                                                                  <input placeholder="Insira o nome da tag" type="text" className={classes.inputNameTag} />

                                                                  <input type="submit" className="addTagButton" value="+" />
                                                                </form>
                                                                <div className="containerDisplayTags">
                                                                  {opcao1?.tag?.map((tag) => {
                                                                    return (
                                                                      <div className="containerTag" key={tag.id}>
                                                                        <button
                                                                          className="deleteTagButton"
                                                                          onClick={(e) => {
                                                                            deleteTag(tag.id);
                                                                            setAtualizacao(atualizacao + 1);
                                                                          }}>
                                                                          X
                                                                        </button>
                                                                        <div className="nomeTag">{tag.description}</div>
                                                                      </div>
                                                                    );
                                                                  })}
                                                                </div>
                                                              </div>
                                                            </div>
                                                            {opcao1?.questions?.length > 0 ? (
                                                              opcao1?.questions?.map((subPergunta1) => {
                                                                //SUBPERGUNTA 2
                                                                return (
                                                                  <div className={classes.pergunta} key={subPergunta1.id}>
                                                                    <button
                                                                      className="deleteRadioOption"
                                                                      onClick={() => {
                                                                        deletePergunta(subPergunta1.id);
                                                                      }}>
                                                                      <FiTrash2 />
                                                                    </button>
                                                                    <div className={classes.barraLateral}></div>
                                                                    <div className="configuracaoPergunta">
                                                                      <div className="opcaoPergunta">
                                                                        <div className={classes.tituloOpcaoPergunta}>Titulo</div>
                                                                        <input
                                                                          placeholder="Pressione para editar"
                                                                          onBlur={(e) => changeNamePergunta(subPergunta1.id, e.target.value)}
                                                                          onFocus={(e) => {
                                                                            changeNamePergunta(subPergunta1.id, null);
                                                                          }}
                                                                          type="text"
                                                                          value={subPergunta1.description}
                                                                          className={classes.inputTituloPergunta}
                                                                        />
                                                                      </div>
                                                                      <div className="opcaoPergunta">
                                                                        <div className={classes.tituloOpcaoPergunta}>Tipo de pergunta</div>
                                                                        <select
                                                                          className={classes.selectTipoPergunta}
                                                                          onChange={(e) => {
                                                                            subPergunta1.type = e.target.value;
                                                                            subPergunta1.options = [];
                                                                            setAtualizacao(atualizacao + 1);
                                                                          }}
                                                                          value={subPergunta1.type}>
                                                                          <option value="1">Texto</option>
                                                                          <option value="2">Seleção Única</option>
                                                                          <option value="3">Checkbox</option>
                                                                          <option value="4">Valor monetário</option>
                                                                          <option value="6">Inteiro</option>
                                                                          <option value="7">Decimal</option>
                                                                          <option value="5">Porcentagem</option>
                                                                        </select>
                                                                        {subPergunta1.type == "1" ? (
                                                                          <div
                                                                            className={classes.tipoPerguntaTexto}
                                                                            contentEditable
                                                                            suppressContentEditableWarning={true}
                                                                            onBlur={(e) => {
                                                                              if (e.target.innerHTML == "") {
                                                                                e.target.innerHTML = "Insira a resposta";
                                                                              }
                                                                            }}
                                                                            onMouseLeave={(e) => {}}
                                                                            onClick={(e) => {
                                                                              if (e.target.innerHTML == "Insira a resposta") e.target.innerHTML = "";
                                                                            }}>
                                                                            Insira a resposta
                                                                          </div>
                                                                        ) : subPergunta1.type == "2" ? (
                                                                          <div className="containerTipoPerguntaOpcaoMultipla">
                                                                            {subPergunta1?.options?.length > 0 ? (
                                                                              subPergunta1?.options?.map((opcao2) => {
                                                                                return (
                                                                                  <>
                                                                                    <div className="containerRadioOption" key={opcao2.id}>
                                                                                      <button
                                                                                        className="deleteRadioOption"
                                                                                        onClick={() => {
                                                                                          deleteOpcao(opcao2.id);
                                                                                        }}>
                                                                                        <FiTrash2 />
                                                                                      </button>
                                                                                      <input type="radio" id="html" name="fav_language" value="HTML" />
                                                                                      <input
                                                                                        placeholder="Nome opção"
                                                                                        onBlur={(e) => changeNameOption(opcao2.id, e.target.value)}
                                                                                        onFocus={(e) => {
                                                                                          changeNameOption(opcao2.id, null);
                                                                                        }}
                                                                                        type="text"
                                                                                        value={opcao2.description}
                                                                                        className={classes.inputOptionName}
                                                                                      />
                                                                                       {" "}
                                                                                      <button
                                                                                        className="addSubSection"
                                                                                        onClick={() => {
                                                                                          addPergunta(opcao2.id);
                                                                                          setAtualizacao(atualizacao + 1);
                                                                                        }}>
                                                                                        + Subpergunta
                                                                                      </button>
                                                                                      <div className={classes.containerTags}>
                                                                                        <div className="containerInfoTags">
                                                                                          <AiOutlineInfoCircle title="Se essa opção possuir alguma tag adicionada, esta será enviada para o banco no momento que ela for selecionada por quem está preenchendo o portfolio." size={15} />
                                                                                        </div>

                                                                                        <form
                                                                                          className="formTags"
                                                                                          onSubmit={(e) => {
                                                                                            e.preventDefault();
                                                                                            addOptionTag(opcao2.id, e.target.firstChild.value);
                                                                                            e.target.firstChild.value = "";
                                                                                          }}>
                                                                                          <input placeholder="Insira o nome da tag" type="text" className={classes.inputNameTag} />

                                                                                          <input type="submit" className="addTagButton" value="+" />
                                                                                        </form>
                                                                                        <div className="containerDisplayTags">
                                                                                          {opcao2?.tag?.map((tag) => {
                                                                                            return (
                                                                                              <div className="containerTag" key={tag.id}>
                                                                                                <button
                                                                                                  className="deleteTagButton"
                                                                                                  onClick={(e) => {
                                                                                                    deleteTag(tag.id);
                                                                                                    setAtualizacao(atualizacao + 1);
                                                                                                  }}>
                                                                                                  X
                                                                                                </button>
                                                                                                <div className="nomeTag">{tag.description}</div>
                                                                                              </div>
                                                                                            );
                                                                                          })}
                                                                                        </div>
                                                                                      </div>
                                                                                    </div>
                                                                                    {opcao2?.questions?.length > 0 ? (
                                                                                      opcao2?.questions?.map((subPergunta2) => {
                                                                                        //SUBPERGUNTA 3
                                                                                        return (
                                                                                          <div className={classes.pergunta} key={subPergunta2.id}>
                                                                                            <button
                                                                                              className="deleteRadioOption"
                                                                                              onClick={() => {
                                                                                                deletePergunta(subPergunta2.id);
                                                                                              }}>
                                                                                              <FiTrash2 />
                                                                                            </button>
                                                                                            <div className={classes.barraLateral}></div>
                                                                                            <div className="configuracaoPergunta">
                                                                                              <div className="opcaoPergunta">
                                                                                                <div className={classes.tituloOpcaoPergunta}>Titulo</div>
                                                                                                <input
                                                                                                  placeholder="Pressione para editar"
                                                                                                  onBlur={(e) => changeNamePergunta(subPergunta2.id, e.target.value)}
                                                                                                  onFocus={(e) => {
                                                                                                    changeNamePergunta(subPergunta2.id, null);
                                                                                                  }}
                                                                                                  type="text"
                                                                                                  value={subPergunta2.description}
                                                                                                  className={classes.inputTituloPergunta}
                                                                                                />
                                                                                              </div>
                                                                                              <div className="opcaoPergunta">
                                                                                                <div className={classes.tituloOpcaoPergunta}>Tipo de pergunta</div>
                                                                                                <select
                                                                                                  className={classes.selectTipoPergunta}
                                                                                                  onChange={(e) => {
                                                                                                    subPergunta2.type = e.target.value;
                                                                                                    subPergunta2.options = [];
                                                                                                    setAtualizacao(atualizacao + 1);
                                                                                                  }}
                                                                                                  value={subPergunta2.type}>
                                                                                                  <option value="1">Texto</option>
                                                                                                  <option value="2">Seleção Única</option>
                                                                                                  <option value="3">Checkbox</option>
                                                                                                  <option value="4">Valor monetário</option>
                                                                                                  <option value="6">Inteiro</option>
                                                                                                  <option value="7">Decimal</option>
                                                                                                  <option value="5">Porcentagem</option>
                                                                                                </select>
                                                                                                {subPergunta2.type == "1" ? (
                                                                                                  <div
                                                                                                    className={classes.tipoPerguntaTexto}
                                                                                                    contentEditable
                                                                                                    suppressContentEditableWarning={true}
                                                                                                    onBlur={(e) => {
                                                                                                      if (e.target.innerHTML == "") {
                                                                                                        e.target.innerHTML = "Insira a resposta";
                                                                                                      }
                                                                                                    }}
                                                                                                    onMouseLeave={(e) => {}}
                                                                                                    onClick={(e) => {
                                                                                                      if (e.target.innerHTML == "Insira a resposta") e.target.innerHTML = "";
                                                                                                    }}>
                                                                                                    Insira a resposta
                                                                                                  </div>
                                                                                                ) : subPergunta2.type == "2" ? (
                                                                                                  <div className="containerTipoPerguntaOpcaoMultipla">
                                                                                                    {subPergunta2?.options?.length > 0 ? (
                                                                                                      subPergunta2?.options?.map((opcao3) => {
                                                                                                        return (
                                                                                                          <>
                                                                                                            <div className="containerRadioOption" key={opcao3.id}>
                                                                                                              <button
                                                                                                                className="deleteRadioOption"
                                                                                                                onClick={() => {
                                                                                                                  deleteOpcao(opcao3.id);
                                                                                                                }}>
                                                                                                                <FiTrash2 />
                                                                                                              </button>
                                                                                                              <input type="radio" id="html" name="fav_language" value="HTML" />
                                                                                                              <input
                                                                                                                placeholder="Nome opção"
                                                                                                                onBlur={(e) => changeNameOption(opcao3.id, e.target.value)}
                                                                                                                onFocus={(e) => {
                                                                                                                  changeNameOption(opcao3.id, null);
                                                                                                                }}
                                                                                                                type="text"
                                                                                                                value={opcao3.description}
                                                                                                                className={classes.inputOptionName}
                                                                                                              />
                                                                                                              <div className={classes.containerTags}>
                                                                                                                <div className="containerInfoTags">
                                                                                                                  <AiOutlineInfoCircle title="Se essa opção possuir alguma tag adicionada, esta será enviada para o banco no momento que ela for selecionada por quem está preenchendo o portfolio." size={15} />
                                                                                                                </div>

                                                                                                                <form
                                                                                                                  className="formTags"
                                                                                                                  onSubmit={(e) => {
                                                                                                                    e.preventDefault();
                                                                                                                    addOptionTag(opcao3.id, e.target.firstChild.value);
                                                                                                                    e.target.firstChild.value = "";
                                                                                                                  }}>
                                                                                                                  <input placeholder="Insira o nome da tag" type="text" className={classes.inputNameTag} />

                                                                                                                  <input type="submit" className="addTagButton" value="+" />
                                                                                                                </form>
                                                                                                                <div className="containerDisplayTags">
                                                                                                                  {opcao3?.tag?.map((tag) => {
                                                                                                                    return (
                                                                                                                      <div className="containerTag" key={tag.id}>
                                                                                                                        <button
                                                                                                                          className="deleteTagButton"
                                                                                                                          onClick={(e) => {
                                                                                                                            deleteTag(tag.id);
                                                                                                                            setAtualizacao(atualizacao + 1);
                                                                                                                          }}>
                                                                                                                          X
                                                                                                                        </button>
                                                                                                                        <div className="nomeTag">{tag.description}</div>
                                                                                                                      </div>
                                                                                                                    );
                                                                                                                  })}
                                                                                                                </div>
                                                                                                              </div>
                                                                                                            </div>
                                                                                                          </>
                                                                                                        );
                                                                                                      })
                                                                                                    ) : (
                                                                                                      <></>
                                                                                                    )}
                                                                                                    <button
                                                                                                      className="addRadioOption"
                                                                                                      onClick={() => {
                                                                                                        addOpcao(subPergunta2.id);
                                                                                                      }}>
                                                                                                      + Adicionar opção
                                                                                                    </button>
                                                                                                  </div>
                                                                                                ) : subPergunta2.type == "3" ? (
                                                                                                  <div className="containerTipoPerguntaCaixaSelecao">
                                                                                                    {subPergunta2?.options?.length > 0 ? (
                                                                                                      subPergunta2?.options?.map((opcao3) => {
                                                                                                        return (
                                                                                                          <>
                                                                                                            <div className="containerCheckBoxOption" key={opcao3.id}>
                                                                                                              <button
                                                                                                                className="deleteCheckBoxOption"
                                                                                                                onClick={() => {
                                                                                                                  deleteOpcao(opcao3.id);
                                                                                                                }}>
                                                                                                                <FiTrash2 />
                                                                                                              </button>
                                                                                                              <input type="Checkbox" id="3" name="3" />
                                                                                                              <input
                                                                                                                placeholder="Nome opção"
                                                                                                                onBlur={(e) => changeNameOption(opcao3.id, e.target.value)}
                                                                                                                onFocus={(e) => {
                                                                                                                  changeNameOption(opcao3.id, null);
                                                                                                                }}
                                                                                                                type="text"
                                                                                                                value={opcao3.description}
                                                                                                                className={classes.inputOptionName}
                                                                                                              />
                                                                                                              <div className={classes.containerTags}>
                                                                                                                <div className="containerInfoTags">
                                                                                                                  <AiOutlineInfoCircle title="Se essa opção possuir alguma tag adicionada, esta será enviada para o banco no momento que ela for selecionada por quem está preenchendo o portfolio." size={15} />
                                                                                                                </div>

                                                                                                                <form
                                                                                                                  className="formTags"
                                                                                                                  onSubmit={(e) => {
                                                                                                                    e.preventDefault();
                                                                                                                    addOptionTag(opcao3.id, e.target.firstChild.value);
                                                                                                                    e.target.firstChild.value = "";
                                                                                                                  }}>
                                                                                                                  <input placeholder="Insira o nome da tag" type="text" className={classes.inputNameTag} />

                                                                                                                  <input type="submit" className="addTagButton" value="+" />
                                                                                                                </form>
                                                                                                                <div className="containerDisplayTags">
                                                                                                                  {opcao3?.tag?.map((tag) => {
                                                                                                                    return (
                                                                                                                      <div className="containerTag" key={tag.id}>
                                                                                                                        <button
                                                                                                                          className="deleteTagButton"
                                                                                                                          onClick={(e) => {
                                                                                                                            deleteTag(tag.id);
                                                                                                                            setAtualizacao(atualizacao + 1);
                                                                                                                          }}>
                                                                                                                          X
                                                                                                                        </button>
                                                                                                                        <div className="nomeTag">{tag.description}</div>
                                                                                                                      </div>
                                                                                                                    );
                                                                                                                  })}
                                                                                                                </div>
                                                                                                              </div>
                                                                                                            </div>
                                                                                                          </>
                                                                                                        );
                                                                                                      })
                                                                                                    ) : (
                                                                                                      <></>
                                                                                                    )}
                                                                                                    <button
                                                                                                      className="addCheckBoxOption"
                                                                                                      onClick={() => {
                                                                                                        addOpcao(subPergunta2.id);
                                                                                                      }}>
                                                                                                      + Adicionar opção
                                                                                                    </button>
                                                                                                  </div>
                                                                                                ) : subPergunta2.type == "4" ? (
                                                                                                  <div>
                                                                                                    <CurrencyTextField
                                                                                                      InputProps={{
                                                                                                        classes: {
                                                                                                          notchedOutline: classes.notchedOutline,
                                                                                                        },
                                                                                                      }}
                                                                                                      variant="outlined"
                                                                                                      value={precoTemp}
                                                                                                      className="tipoPerguntaMonetario"
                                                                                                      currencySymbol="R$"
                                                                                                      outputFormat="number"
                                                                                                      decimalCharacter=","
                                                                                                      required={true}
                                                                                                      digitGroupSeparator="."
                                                                                                      onChange={(event, value) => {
                                                                                                        if (value === "" || value === 0) {
                                                                                                          setPrecoTemp(0);
                                                                                                        } else {
                                                                                                          setPrecoTemp(value);
                                                                                                        }
                                                                                                      }}
                                                                                                    />
                                                                                                  </div>
                                                                                                ) : subPergunta2.type == "5" ? (
                                                                                                  <div className="containerTipoPerguntaPorcentagem">
                                                                                                    <TextField
                                                                                                      InputProps={{
                                                                                                        classes: {
                                                                                                          notchedOutline: classes.notchedOutline,
                                                                                                        },
                                                                                                      }}
                                                                                                      type="number"
                                                                                                      onKeyPress={(e) => {
                                                                                                        if (e.code === "Backquote") e.preventDefault();
                                                                                                      }}
                                                                                                      step="0.00"
                                                                                                      onKeyDown={(e) => {
                                                                                                        if (e.key === "e" || e.key === "E") {
                                                                                                          e.preventDefault();
                                                                                                        }
                                                                                                      }}
                                                                                                      value={percentTemp}
                                                                                                      className="tipoPerguntaPorcentagem"
                                                                                                      variant="outlined"
                                                                                                      onChange={(e) => {
                                                                                                        setPercentTemp(e.target.value);
                                                                                                      }}
                                                                                                    />
                                                                                                    <div className="porcentagem">%</div>
                                                                                                  </div>
                                                                                                ) : subPergunta2.type == "6" ? (
                                                                                                  <div className="containerTipoPerguntaPorcentagem">
                                                                                                    <TextField
                                                                                                      
                                                                                                      InputProps={{ classes: { notchedOutline: classes.notchedOutline } }}
                                                                                                      type="number"
                                                                                                      onKeyPress={(e) => {
                                                                                                        if (e.key === "." || e.key === ",") e.preventDefault();
                                                                                                      }}
                                                                                                      value={inteiroTemp}
                                                                                                      className="tipoPerguntaPorcentagem"
                                                                                                      variant="outlined"
                                                                                                      onChange={(e) => {
                                                                                                        setInteiroTemp(e.target.value);
                                                                                                      }}
                                                                                                    />
                                                                                                  </div>
                                                                                                ) : subPergunta2.type == "7" ? (
                                                                                                  <div className="containerTipoPerguntaPorcentagem">
                                                                                                    <TextField
                                                                                                      
                                                                                                      InputProps={{ classes: { notchedOutline: classes.notchedOutline } }}
                                                                                                      type="number"
                                                                                                      value={decimalTemp}
                                                                                                      className="tipoPerguntaPorcentagem"
                                                                                                      variant="outlined"
                                                                                                      onChange={(e) => {
                                                                                                        setDecimalTemp(e.target.value);
                                                                                                      }}
                                                                                                    />
                                                                                                  </div>
                                                                                                ) : (
                                                                                                  <></>
                                                                                                )}
                                                                                              </div>
                                                                                              <div className="habilitarObservacao">
                                                                                                <input
                                                                                                  checked={subPergunta2.observation}
                                                                                                  onChange={(e) => {
                                                                                                    changeObservation(subPergunta2.id);
                                                                                                  }}
                                                                                                  type="Checkbox"
                                                                                                  id={"Checkbox" + subPergunta2.id}
                                                                                                  name="Checkbox"
                                                                                                />
                                                                                                <label for={"Checkbox" + subPergunta2.id}> Adicionar campo de observação?</label>
                                                                                              </div>
                                                                                            </div>
                                                                                          </div>
                                                                                        );
                                                                                        //SUBPERGUNTA 3
                                                                                      })
                                                                                    ) : (
                                                                                      <></>
                                                                                    )}
                                                                                  </>
                                                                                );
                                                                              })
                                                                            ) : (
                                                                              <></>
                                                                            )}
                                                                            <button
                                                                              className="addRadioOption"
                                                                              onClick={() => {
                                                                                addOpcao(subPergunta1.id);
                                                                              }}>
                                                                              + Adicionar opção
                                                                            </button>
                                                                          </div>
                                                                        ) : subPergunta1.type == "3" ? (
                                                                          <div className="containerTipoPerguntaOpcaoMultipla">
                                                                            {subPergunta1?.options?.length > 0 ? (
                                                                              subPergunta1?.options?.map((opcao2) => {
                                                                                return (
                                                                                  <>
                                                                                    <div className="containerCheckBoxOption" key={opcao2.id}>
                                                                                      <button
                                                                                        className="deleteCheckBoxOption"
                                                                                        onClick={() => {
                                                                                          deleteOpcao(opcao2.id);
                                                                                        }}>
                                                                                        <FiTrash2 />
                                                                                      </button>
                                                                                      <input type="Checkbox" id="3" name="3" />
                                                                                      <input
                                                                                        placeholder="Nome opção"
                                                                                        onBlur={(e) => changeNameOption(opcao2.id, e.target.value)}
                                                                                        onFocus={(e) => {
                                                                                          changeNameOption(opcao2.id, null);
                                                                                        }}
                                                                                        type="text"
                                                                                        value={opcao2.description}
                                                                                        className={classes.inputOptionName}
                                                                                      />
                                                                                       {" "}
                                                                                      <button
                                                                                        className="addSubSection"
                                                                                        onClick={() => {
                                                                                          addPergunta(opcao2.id);
                                                                                          setAtualizacao(atualizacao + 1);
                                                                                        }}>
                                                                                        + Subpergunta
                                                                                      </button>
                                                                                      <div className={classes.containerTags}>
                                                                                        <div className="containerInfoTags">
                                                                                          <AiOutlineInfoCircle title="Se essa opção possuir alguma tag adicionada, esta será enviada para o banco no momento que ela for selecionada por quem está preenchendo o portfolio." size={15} />
                                                                                        </div>

                                                                                        <form
                                                                                          className="formTags"
                                                                                          onSubmit={(e) => {
                                                                                            e.preventDefault();
                                                                                            addOptionTag(opcao2.id, e.target.firstChild.value);
                                                                                            e.target.firstChild.value = "";
                                                                                          }}>
                                                                                          <input placeholder="Insira o nome da tag" type="text" className={classes.inputNameTag} />

                                                                                          <input type="submit" className="addTagButton" value="+" />
                                                                                        </form>
                                                                                        <div className="containerDisplayTags">
                                                                                          {opcao2?.tag?.map((tag) => {
                                                                                            return (
                                                                                              <div className="containerTag" key={tag.id}>
                                                                                                <button
                                                                                                  className="deleteTagButton"
                                                                                                  onClick={(e) => {
                                                                                                    deleteTag(tag.id);
                                                                                                    setAtualizacao(atualizacao + 1);
                                                                                                  }}>
                                                                                                  X
                                                                                                </button>
                                                                                                <div className="nomeTag">{tag.description}</div>
                                                                                              </div>
                                                                                            );
                                                                                          })}
                                                                                        </div>
                                                                                      </div>
                                                                                    </div>
                                                                                    {opcao2?.questions?.length > 0 ? (
                                                                                      opcao2?.questions?.map((subPergunta2) => {
                                                                                        //SUBPERGUNTA 3
                                                                                        return (
                                                                                          <div className={classes.pergunta} key={subPergunta2.id}>
                                                                                            <button
                                                                                              className="deleteCheckBoxOption"
                                                                                              onClick={() => {
                                                                                                deletePergunta(subPergunta2.id);
                                                                                              }}>
                                                                                              <FiTrash2 />
                                                                                            </button>
                                                                                            <div className={classes.barraLateral}></div>
                                                                                            <div className="configuracaoPergunta">
                                                                                              <div className="opcaoPergunta">
                                                                                                <div className={classes.tituloOpcaoPergunta}>Titulo</div>
                                                                                                <input
                                                                                                  placeholder="Pressione para editar"
                                                                                                  onBlur={(e) => changeNamePergunta(subPergunta2.id, e.target.value)}
                                                                                                  onFocus={(e) => {
                                                                                                    changeNamePergunta(subPergunta2.id, null);
                                                                                                  }}
                                                                                                  type="text"
                                                                                                  value={subPergunta2.description}
                                                                                                  className={classes.inputTituloPergunta}
                                                                                                />
                                                                                              </div>
                                                                                              <div className="opcaoPergunta">
                                                                                                <div className={classes.tituloOpcaoPergunta}>Tipo de pergunta</div>
                                                                                                <select
                                                                                                  className={classes.selectTipoPergunta}
                                                                                                  onChange={(e) => {
                                                                                                    subPergunta2.type = e.target.value;
                                                                                                    subPergunta2.options = [];
                                                                                                    setAtualizacao(atualizacao + 1);
                                                                                                  }}
                                                                                                  value={subPergunta2.type}>
                                                                                                  <option value="1">Texto</option>
                                                                                                  <option value="2">Seleção Única</option>
                                                                                                  <option value="3">Checkbox</option>
                                                                                                  <option value="4">Valor monetário</option>
                                                                                                  <option value="6">Inteiro</option>
                                                                                                  <option value="7">Decimal</option>
                                                                                                  <option value="5">Porcentagem</option>
                                                                                                </select>
                                                                                                {subPergunta2.type == "1" ? (
                                                                                                  <div
                                                                                                    className={classes.tipoPerguntaTexto}
                                                                                                    contentEditable
                                                                                                    suppressContentEditableWarning={true}
                                                                                                    onMouseLeave={(e) => {}}
                                                                                                    onClick={(e) => {
                                                                                                      if (e.target.innerHTML == "Insira a resposta") e.target.innerHTML = "";
                                                                                                    }}>
                                                                                                    Insira a resposta
                                                                                                  </div>
                                                                                                ) : subPergunta2.type == "2" ? (
                                                                                                  <div className="containerTipoPerguntaOpcaoMultipla">
                                                                                                    {subPergunta2?.options?.length > 0 ? (
                                                                                                      subPergunta2?.options?.map((opcao3) => {
                                                                                                        return (
                                                                                                          <>
                                                                                                            <div className="containerRadioOption" key={opcao3.id}>
                                                                                                              <button
                                                                                                                className="deleteRadioOption"
                                                                                                                onClick={() => {
                                                                                                                  deleteOpcao(opcao3.id);
                                                                                                                }}>
                                                                                                                <FiTrash2 />
                                                                                                              </button>
                                                                                                              <input type="radio" id="html" name="fav_language" value="HTML" />
                                                                                                              <input
                                                                                                                placeholder="Nome opção"
                                                                                                                onBlur={(e) => changeNameOption(opcao3.id, e.target.value)}
                                                                                                                onFocus={(e) => {
                                                                                                                  changeNameOption(opcao3.id, null);
                                                                                                                }}
                                                                                                                type="text"
                                                                                                                value={opcao3.description}
                                                                                                                className={classes.inputOptionName}
                                                                                                              />
                                                                                                              <div className={classes.containerTags}>
                                                                                                                <div className="containerInfoTags">
                                                                                                                  <AiOutlineInfoCircle title="Se essa opção possuir alguma tag adicionada, esta será enviada para o banco no momento que ela for selecionada por quem está preenchendo o portfolio." size={15} />
                                                                                                                </div>

                                                                                                                <form
                                                                                                                  className="formTags"
                                                                                                                  onSubmit={(e) => {
                                                                                                                    e.preventDefault();
                                                                                                                    addOptionTag(opcao3.id, e.target.firstChild.value);
                                                                                                                    e.target.firstChild.value = "";
                                                                                                                  }}>
                                                                                                                  <input placeholder="Insira o nome da tag" type="text" className={classes.inputNameTag} />

                                                                                                                  <input type="submit" className="addTagButton" value="+" />
                                                                                                                </form>
                                                                                                                <div className="containerDisplayTags">
                                                                                                                  {opcao3?.tag?.map((tag) => {
                                                                                                                    return (
                                                                                                                      <div className="containerTag" key={tag.id}>
                                                                                                                        <button
                                                                                                                          className="deleteTagButton"
                                                                                                                          onClick={(e) => {
                                                                                                                            deleteTag(tag.id);
                                                                                                                            setAtualizacao(atualizacao + 1);
                                                                                                                          }}>
                                                                                                                          X
                                                                                                                        </button>
                                                                                                                        <div className="nomeTag">{tag.description}</div>
                                                                                                                      </div>
                                                                                                                    );
                                                                                                                  })}
                                                                                                                </div>
                                                                                                              </div>
                                                                                                            </div>
                                                                                                          </>
                                                                                                        );
                                                                                                      })
                                                                                                    ) : (
                                                                                                      <></>
                                                                                                    )}
                                                                                                    <button
                                                                                                      className="addRadioOption"
                                                                                                      onClick={() => {
                                                                                                        addOpcao(subPergunta2.id);
                                                                                                      }}>
                                                                                                      + Adicionar opção
                                                                                                    </button>
                                                                                                  </div>
                                                                                                ) : subPergunta2.type == "3" ? (
                                                                                                  <div className="containerTipoPerguntaCaixaSelecao">
                                                                                                    {subPergunta2?.options?.length > 0 ? (
                                                                                                      subPergunta2?.options?.map((opcao3) => {
                                                                                                        return (
                                                                                                          <>
                                                                                                            <div className="containerCheckBoxOption" key={opcao3.id}>
                                                                                                              <button
                                                                                                                className="deleteCheckBoxOption"
                                                                                                                onClick={() => {
                                                                                                                  deleteOpcao(opcao3.id);
                                                                                                                }}>
                                                                                                                <FiTrash2 />
                                                                                                              </button>
                                                                                                              <input type="Checkbox" id="3" name="3" />
                                                                                                              <input
                                                                                                                placeholder="Nome opção"
                                                                                                                onBlur={(e) => changeNameOption(opcao3.id, e.target.value)}
                                                                                                                onFocus={(e) => {
                                                                                                                  changeNameOption(opcao3.id, null);
                                                                                                                }}
                                                                                                                type="text"
                                                                                                                value={opcao3.description}
                                                                                                                className={classes.inputOptionName}
                                                                                                              />
                                                                                                              <div className={classes.containerTags}>
                                                                                                                <div className="containerInfoTags">
                                                                                                                  <AiOutlineInfoCircle title="Se essa opção possuir alguma tag adicionada, esta será enviada para o banco no momento que ela for selecionada por quem está preenchendo o portfolio." size={15} />
                                                                                                                </div>

                                                                                                                <form
                                                                                                                  className="formTags"
                                                                                                                  onSubmit={(e) => {
                                                                                                                    e.preventDefault();
                                                                                                                    addOptionTag(opcao3.id, e.target.firstChild.value);
                                                                                                                    e.target.firstChild.value = "";
                                                                                                                  }}>
                                                                                                                  <input placeholder="Insira o nome da tag" type="text" className={classes.inputNameTag} />

                                                                                                                  <input type="submit" className="addTagButton" value="+" />
                                                                                                                </form>
                                                                                                                <div className="containerDisplayTags">
                                                                                                                  {opcao3?.tag?.map((tag) => {
                                                                                                                    return (
                                                                                                                      <div className="containerTag" key={tag.id}>
                                                                                                                        <button
                                                                                                                          className="deleteTagButton"
                                                                                                                          onClick={(e) => {
                                                                                                                            deleteTag(tag.id);
                                                                                                                            setAtualizacao(atualizacao + 1);
                                                                                                                          }}>
                                                                                                                          X
                                                                                                                        </button>
                                                                                                                        <div className="nomeTag">{tag.description}</div>
                                                                                                                      </div>
                                                                                                                    );
                                                                                                                  })}
                                                                                                                </div>
                                                                                                              </div>
                                                                                                            </div>
                                                                                                          </>
                                                                                                        );
                                                                                                      })
                                                                                                    ) : (
                                                                                                      <></>
                                                                                                    )}
                                                                                                    <button
                                                                                                      className="addCheckBoxOption"
                                                                                                      onClick={() => {
                                                                                                        addOpcao(subPergunta2.id);
                                                                                                      }}>
                                                                                                      + Adicionar opção
                                                                                                    </button>
                                                                                                  </div>
                                                                                                ) : subPergunta2.type == "4" ? (
                                                                                                  <div>
                                                                                                    <CurrencyTextField
                                                                                                      InputProps={{
                                                                                                        classes: {
                                                                                                          notchedOutline: classes.notchedOutline,
                                                                                                        },
                                                                                                      }}
                                                                                                      variant="outlined"
                                                                                                      value={precoTemp}
                                                                                                      className="tipoPerguntaMonetario"
                                                                                                      currencySymbol="R$"
                                                                                                      outputFormat="number"
                                                                                                      decimalCharacter=","
                                                                                                      required={true}
                                                                                                      digitGroupSeparator="."
                                                                                                      onChange={(event, value) => {
                                                                                                        if (value === "" || value === 0) {
                                                                                                          setPrecoTemp(0);
                                                                                                        } else {
                                                                                                          setPrecoTemp(value);
                                                                                                        }
                                                                                                      }}
                                                                                                    />
                                                                                                  </div>
                                                                                                ) : subPergunta2.type == "5" ? (
                                                                                                  <div className="containerTipoPerguntaPorcentagem">
                                                                                                    <TextField
                                                                                                      InputProps={{
                                                                                                        classes: {
                                                                                                          notchedOutline: classes.notchedOutline,
                                                                                                        },
                                                                                                      }}
                                                                                                      type="number"
                                                                                                      onKeyPress={(e) => {
                                                                                                        if (e.code === "Backquote") e.preventDefault();
                                                                                                      }}
                                                                                                      step="0.00"
                                                                                                      onKeyDown={(e) => {
                                                                                                        if (e.key === "e" || e.key === "E") {
                                                                                                          e.preventDefault();
                                                                                                        }
                                                                                                      }}
                                                                                                      value={percentTemp}
                                                                                                      className="tipoPerguntaPorcentagem"
                                                                                                      variant="outlined"
                                                                                                      onChange={(e) => {
                                                                                                        setPercentTemp(e.target.value);
                                                                                                      }}
                                                                                                    />
                                                                                                    <div className="porcentagem">%</div>
                                                                                                  </div>
                                                                                                ) : subPergunta2.type == "6" ? (
                                                                                                  <div className="containerTipoPerguntaPorcentagem">
                                                                                                    <TextField
                                                                                                      
                                                                                                      InputProps={{ classes: { notchedOutline: classes.notchedOutline } }}
                                                                                                      type="number"
                                                                                                      onKeyPress={(e) => {
                                                                                                        if (e.key === "." || e.key === ",") e.preventDefault();
                                                                                                      }}
                                                                                                      value={inteiroTemp}
                                                                                                      className="tipoPerguntaPorcentagem"
                                                                                                      variant="outlined"
                                                                                                      onChange={(e) => {
                                                                                                        setInteiroTemp(e.target.value);
                                                                                                      }}
                                                                                                    />
                                                                                                  </div>
                                                                                                ) : subPergunta2.type == "7" ? (
                                                                                                  <div className="containerTipoPerguntaPorcentagem">
                                                                                                    <TextField
                                                                                                      
                                                                                                      InputProps={{ classes: { notchedOutline: classes.notchedOutline } }}
                                                                                                      type="number"
                                                                                                      value={decimalTemp}
                                                                                                      className="tipoPerguntaPorcentagem"
                                                                                                      variant="outlined"
                                                                                                      onChange={(e) => {
                                                                                                        setDecimalTemp(e.target.value);
                                                                                                      }}
                                                                                                    />
                                                                                                  </div>
                                                                                                ) : (
                                                                                                  <></>
                                                                                                )}
                                                                                              </div>
                                                                                              <div className="habilitarObservacao">
                                                                                                <input
                                                                                                  checked={subPergunta2.observation}
                                                                                                  onChange={(e) => {
                                                                                                    changeObservation(subPergunta2.id);
                                                                                                  }}
                                                                                                  type="Checkbox"
                                                                                                  id={"Checkbox" + subPergunta2.id}
                                                                                                  name="Checkbox"
                                                                                                />
                                                                                                <label for={"Checkbox" + subPergunta2.id}> Adicionar campo de observação?</label>
                                                                                              </div>
                                                                                            </div>
                                                                                          </div>
                                                                                        );
                                                                                        //SUBPERGUNTA 3
                                                                                      })
                                                                                    ) : (
                                                                                      <></>
                                                                                    )}
                                                                                  </>
                                                                                );
                                                                              })
                                                                            ) : (
                                                                              <></>
                                                                            )}
                                                                            <button
                                                                              className="addRadioOption"
                                                                              onClick={() => {
                                                                                addOpcao(subPergunta1.id);
                                                                              }}>
                                                                              + Adicionar opção
                                                                            </button>
                                                                          </div>
                                                                        ) : subPergunta1.type == "4" ? (
                                                                          <div>
                                                                            <CurrencyTextField
                                                                              InputProps={{
                                                                                classes: {
                                                                                  notchedOutline: classes.notchedOutline,
                                                                                },
                                                                              }}
                                                                              variant="outlined"
                                                                              value={precoTemp}
                                                                              className="tipoPerguntaMonetario"
                                                                              currencySymbol="R$"
                                                                              outputFormat="number"
                                                                              decimalCharacter=","
                                                                              required={true}
                                                                              digitGroupSeparator="."
                                                                              onChange={(event, value) => {
                                                                                if (value === "" || value === 0) {
                                                                                  setPrecoTemp(0);
                                                                                } else {
                                                                                  setPrecoTemp(value);
                                                                                }
                                                                              }}
                                                                            />
                                                                          </div>
                                                                        ) : subPergunta1.type == "5" ? (
                                                                          <div className="containerTipoPerguntaPorcentagem">
                                                                            <TextField
                                                                              InputProps={{
                                                                                classes: {
                                                                                  notchedOutline: classes.notchedOutline,
                                                                                },
                                                                              }}
                                                                              type="number"
                                                                              onKeyPress={(e) => {
                                                                                if (e.code === "Backquote") e.preventDefault();
                                                                              }}
                                                                              step="0.00"
                                                                              onKeyDown={(e) => {
                                                                                if (e.key === "e" || e.key === "E") {
                                                                                  e.preventDefault();
                                                                                }
                                                                              }}
                                                                              value={percentTemp}
                                                                              className="tipoPerguntaPorcentagem"
                                                                              variant="outlined"
                                                                              onChange={(e) => {
                                                                                setPercentTemp(e.target.value);
                                                                              }}
                                                                            />
                                                                            <div className="porcentagem">%</div>
                                                                          </div>
                                                                        ) : subPergunta1.type == "6" ? (
                                                                          <div className="containerTipoPerguntaPorcentagem">
                                                                            <TextField
                                                                              
                                                                              InputProps={{ classes: { notchedOutline: classes.notchedOutline } }}
                                                                              type="number"
                                                                              onKeyPress={(e) => {
                                                                                if (e.key === "." || e.key === ",") e.preventDefault();
                                                                              }}
                                                                              value={inteiroTemp}
                                                                              className="tipoPerguntaPorcentagem"
                                                                              variant="outlined"
                                                                              onChange={(e) => {
                                                                                setInteiroTemp(e.target.value);
                                                                              }}
                                                                            />
                                                                          </div>
                                                                        ) : subPergunta1.type == "7" ? (
                                                                          <div className="containerTipoPerguntaPorcentagem">
                                                                            <TextField
                                                                              
                                                                              InputProps={{ classes: { notchedOutline: classes.notchedOutline } }}
                                                                              type="number"
                                                                              value={decimalTemp}
                                                                              className="tipoPerguntaPorcentagem"
                                                                              variant="outlined"
                                                                              onChange={(e) => {
                                                                                setDecimalTemp(e.target.value);
                                                                              }}
                                                                            />
                                                                          </div>
                                                                        ) : (
                                                                          <></>
                                                                        )}
                                                                      </div>
                                                                      <div className="habilitarObservacao">
                                                                        <input
                                                                          checked={subPergunta1.observation}
                                                                          onChange={(e) => {
                                                                            changeObservation(subPergunta1.id);
                                                                          }}
                                                                          type="Checkbox"
                                                                          id={"Checkbox" + subPergunta1.id}
                                                                          name="Checkbox"
                                                                        />
                                                                        <label for={"Checkbox" + subPergunta1.id}> Adicionar campo de observação?</label>
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                );
                                                                //SUBPERGUNTA 2
                                                              })
                                                            ) : (
                                                              <></>
                                                            )}
                                                          </>
                                                        );
                                                      })
                                                    ) : (
                                                      <></>
                                                    )}
                                                    <button
                                                      className="addRadioOption"
                                                      onClick={() => {
                                                        addOpcao(subPergunta.id);
                                                      }}>
                                                      + Adicionar opção
                                                    </button>
                                                  </div>
                                                ) : subPergunta.type == "4" ? (
                                                  <div>
                                                    <CurrencyTextField
                                                      InputProps={{ classes: { notchedOutline: classes.notchedOutline } }}
                                                      variant="outlined"
                                                      value={precoTemp}
                                                      className="tipoPerguntaMonetario"
                                                      currencySymbol="R$"
                                                      outputFormat="number"
                                                      decimalCharacter=","
                                                      required={true}
                                                      digitGroupSeparator="."
                                                      onChange={(event, value) => {
                                                        if (value === "" || value === 0) {
                                                          setPrecoTemp(0);
                                                        } else {
                                                          setPrecoTemp(value);
                                                        }
                                                      }}
                                                    />
                                                  </div>
                                                ) : subPergunta.type == "5" ? (
                                                  <div className="containerTipoPerguntaPorcentagem">
                                                    <TextField
                                                      InputProps={{ classes: { notchedOutline: classes.notchedOutline } }}
                                                      type="number"
                                                      onKeyPress={(e) => {
                                                        if (e.code === "Backquote") e.preventDefault();
                                                      }}
                                                      step="0.00"
                                                      onKeyDown={(e) => {
                                                        if (e.key === "e" || e.key === "E") {
                                                          e.preventDefault();
                                                        }
                                                      }}
                                                      value={percentTemp}
                                                      className="tipoPerguntaPorcentagem"
                                                      variant="outlined"
                                                      onChange={(e) => {
                                                        setPercentTemp(e.target.value);
                                                      }}
                                                    />
                                                    <div className="porcentagem">%</div>
                                                  </div>
                                                ) : subPergunta.type == "6" ? (
                                                  <div className="containerTipoPerguntaPorcentagem">
                                                    <TextField
                                                      
                                                      InputProps={{ classes: { notchedOutline: classes.notchedOutline } }}
                                                      type="number"
                                                      onKeyPress={(e) => {
                                                        if (e.key === "." || e.key === ",") e.preventDefault();
                                                      }}
                                                      value={inteiroTemp}
                                                      className="tipoPerguntaPorcentagem"
                                                      variant="outlined"
                                                      onChange={(e) => {
                                                        setInteiroTemp(e.target.value);
                                                      }}
                                                    />
                                                  </div>
                                                ) : subPergunta.type == "7" ? (
                                                  <div className="containerTipoPerguntaPorcentagem">
                                                    <TextField
                                                      
                                                      InputProps={{ classes: { notchedOutline: classes.notchedOutline } }}
                                                      type="number"
                                                      value={decimalTemp}
                                                      className="tipoPerguntaPorcentagem"
                                                      variant="outlined"
                                                      onChange={(e) => {
                                                        setDecimalTemp(e.target.value);
                                                      }}
                                                    />
                                                  </div>
                                                ) : (
                                                  <></>
                                                )}
                                              </div>
                                              <div className="habilitarObservacao">
                                                <input
                                                  checked={subPergunta.observation}
                                                  onChange={(e) => {
                                                    changeObservation(subPergunta.id);
                                                  }}
                                                  type="Checkbox"
                                                  id={"Checkbox" + subPergunta.id}
                                                  name="Checkbox"
                                                />
                                                <label for={"Checkbox" + subPergunta.id}> Adicionar campo de observação?</label>
                                              </div>
                                            </div>
                                          </div>
                                        );
                                        //SUBPERGUNTA 1
                                      })
                                    ) : (
                                      <></>
                                    )}
                                  </>
                                );
                              })
                            ) : (
                              <></>
                            )}
                            <button
                              className="addRadioOption"
                              onClick={() => {
                                addOpcao(pergunta.id);
                              }}>
                              + Adicionar opção
                            </button>
                          </div>
                        ) : pergunta.type == "4" ? (
                          <div>
                            <CurrencyTextField
                              InputProps={{ classes: { notchedOutline: classes.notchedOutline } }}
                              variant="outlined"
                              value={precoTemp}
                              className="tipoPerguntaMonetario"
                              currencySymbol="R$"
                              outputFormat="number"
                              decimalCharacter=","
                              required={true}
                              digitGroupSeparator="."
                              onChange={(event, value) => {
                                if (value === "" || value === 0) {
                                  setPrecoTemp(0);
                                } else {
                                  setPrecoTemp(value);
                                }
                              }}
                            />
                          </div>
                        ) : pergunta.type == "5" ? (
                          <div className="containerTipoPerguntaPorcentagem">
                            <TextField
                              InputProps={{ classes: { notchedOutline: classes.notchedOutline } }}
                              type="number"
                              onKeyPress={(e) => {
                                if (e.code === "Backquote") e.preventDefault();
                              }}
                              step="0.00"
                              onKeyDown={(e) => {
                                if (e.key === "e" || e.key === "E") {
                                  e.preventDefault();
                                }
                              }}
                              value={percentTemp}
                              className="tipoPerguntaPorcentagem"
                              variant="outlined"
                              onChange={(e) => {
                                setPercentTemp(e.target.value);
                              }}
                            />
                            <div className="porcentagem">%</div>
                          </div>
                        ) : pergunta.type == "6" ? (
                          <div className="containerTipoPerguntaPorcentagem">
                            <TextField
                              
                              InputProps={{ classes: { notchedOutline: classes.notchedOutline } }}
                              type="number"
                              onKeyPress={(e) => {
                                if (e.key === "." || e.key === ",") e.preventDefault();
                              }}
                              value={inteiroTemp}
                              className="tipoPerguntaPorcentagem"
                              variant="outlined"
                              onChange={(e) => {
                                setInteiroTemp(e.target.value);
                              }}
                            />
                          </div>
                        ) : pergunta.type == "7" ? (
                          <div className="containerTipoPerguntaPorcentagem">
                            <TextField
                              
                              InputProps={{ classes: { notchedOutline: classes.notchedOutline } }}
                              type="number"
                              value={decimalTemp}
                              className="tipoPerguntaPorcentagem"
                              variant="outlined"
                              onChange={(e) => {
                                setDecimalTemp(e.target.value);
                              }}
                            />
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div className="habilitarObservacao">
                        <input
                          checked={pergunta.observation}
                          onChange={(e) => {
                            changeObservation(pergunta.id);
                          }}
                          type="Checkbox"
                          id={"Checkbox" + pergunta.id}
                          name="Checkbox"
                        />
                        <label for={"Checkbox" + pergunta.id}> Adicionar campo de observação?</label>
                      </div>
                    </div>
                  </div>
                );
              })}
              <div>
                <button
                  className={classes.botaoNovaPergunta}
                  onClick={() => {
                    addPergunta(secao.id);
                  }}>
                  Adicionar pergunta
                </button>
              </div>
            </div>
          );
        })}
        <div className="containerNovaSecao">
          <button
            className={classes.botaoNovaSecao}
            onClick={() => {
              addSecao();
            }}>
            Adicionar seção
          </button>
        </div>
      </div>
      <div className="containerButtonCreateForm">
        {edicao ? <div className="modoEdicaoText">Modo edição</div> : <div className="modoEdicaoText"></div>}

        <button
          className="createFormButton"
          onClick={() => {
            if (edicao) {
              sendUpdate();
            } else {
              sendForm();
            }
          }}>
          {edicao ? "Atualizar portfólio" : "Criar portfólio"}
        </button>
      </div>
    </div>
  );
}
