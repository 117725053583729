/*Tela de operações Books
/*Descrição: Centraliza todos os books do sistema e boleta, permitindo as operações
/*Desenvolvido por: Equipe Rafael Cezário
*/

import React, { useEffect, useState } from 'react';
/*Styles*/
import './books.css';
import { FiDollarSign } from 'react-icons/fi'
/*Components*/
import OperationTable from './tables/booksOperation';
import OrderTable from './tables/booksOrder';
import { BooksImport } from './booksImport';
import { BooksOrdersImport } from './booksOrders.import';
import Modal from '../../../../components/modal/modal';
import { ConfirmationBox } from '../../../../components/confirmationBox/confirmationBox';
import { ShowAssetCard } from './showAssetCard'
/*Contexts*/
import { BooksProvider, useBooks } from '../../../../contexts/useBooks';
import { useModal } from '../../../../contexts/useModal';
import { useAuth } from '../../../../contexts/useAuth';
/*Utils*/
import { validTime, endData } from '../../../../utils/dateLimit';

export function Books() {

    const { type, setType, show, setShow } = useModal();
    const { user } = useAuth();
    const [isToCleanCheckBox, setIsToCleanCheckBox] = useState(false);

    useEffect(() => {

        if (type.action === 'updateTotalValues') {
        
            const handleKeyDown = (event) => {
                if (event.key === 'Escape') {
                    document.getElementById('showBookOrdersButton').click()
                }
            }
            //adiciona listener para esc na boleta
            window.addEventListener('keydown', handleKeyDown);

            //remove o listener
            return () => {
                window.removeEventListener('keydown', handleKeyDown);
            };
        }


    }, [type])

    if (validTime()) {
        return (
            <div id='bookMainContainer' className='bookRubiMainContainer' style={{ minWidth: !show && type.name !== 'showBookOrders' ? '1000px' : '0' }}>
                <BooksProvider>
                    <Modal>
                        {type.name === 'booksImportShow' ?
                            (
                                <BooksImport book={type?.value?.activeBook} />
                            ) :
                            type.name === 'alertMsg' ?
                                (<ConfirmationBox msgData={type} />) :

                                type.name === 'showAssetCard' ?
                                    (
                                        <ShowAssetCard />
                                    ) : type.name === 'booksOrdersImport' ?
                                    (
                                        <BooksOrdersImport prop={type} />
                                    ) :
                                    (<></>)}
                    </Modal>
                    <div className={type.name === 'showBookOrders' ? 'bookRubiMainContainerHide' : 'bookRubiMainContainerShow'}></div>
                    <div className={user?.type > 2 ? 'bookRubiOperationContainer' : 'bookRubiOperationContainerAdm'}>
                        <OperationTable title='Operações estruturadas' />
                    </div>
                    <div className={type.name === 'showBookOrders' ? 'bookRubiOrderShow' : 'bookRubiOrderHide'}>
                        <button id='showBookOrdersButton' onClick={() => {
                            setIsToCleanCheckBox(!isToCleanCheckBox);
                            setType({ name: 'HideBookOrder' });
                            setShow(false)
                        }}>X</button>
                        <div className='bookRubiOrdersContainer'>
                            <OrderTable title='Boleta' cleanCheckBoxes={isToCleanCheckBox} />
                        </div>
                    </div>
                </BooksProvider>
            </div >
        )
    } else {
        return (
            <>
                <div className='bookRubiMainContainerHourHide'></div>
                <div className='bookRubiWarning'>
                    <h1>Mercado Fechado após as {endData.endHour}</h1>
                </div>
            </>

        )
    }
}