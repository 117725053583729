/* Rotina: Detalhes Meu usuários
/* Objetivo: Exibir detalhes da conta Ativa, e editar os mesmos
/* Desenvolvido por: Equipe Rafael Cezário
*/

import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router';

//Material UI
import AntSwitch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab';

//Estilos
import './myProfile.css'
import { styleClientsInput } from '../../styles/autocompletField';
import { FiEdit } from 'react-icons/fi'
import defaultAvatar from '../../../assets/user_avatar.png'

//Components
import { ArrowLoader } from '../../../components/loaders/arrowLoader'
import Tooltip from '@material-ui/core/Tooltip'

//Utils
import { useAuth } from '../../../contexts/useAuth';
import { useMenu } from '../../../contexts/useMenu';
import { useModal } from '../../../contexts/useModal';
import { useSocket } from '../../../contexts/useSocket';
import { api } from '../../../services/api'
import { titleize } from '../../../utils/formatNumberAndText/formatTextUpperFirstLetter';
import { validPassword } from '../../../utils/validatorSchemas/passwordComplexity';

export function MyProfile(props) {

    const classes = styleClientsInput();
    const [branchesList, setBranchesList] = useState([]);
    const { showUser, setShowUser, setMenuStatus, showNotificationCenter, setShowNotificationCenter, } = useMenu();
    const { socketEmit } = useSocket()
    const { setUser, setUserType, user, userAvatar, tokenKey, setTokenKey } = useAuth();
    const [userTotalClients, setUserTotalClients] = useState([]);
    const { setType, edited, setEdited } = useModal();
    const { showProfileConfig, setShowProfileConfig } = props.isOpen;
    const [loading, setLoading] = useState(true);
    const [dataChanged, setDataChanged] = useState(false);
    const [userAvatarProfile, setUserAvatarProfile] = useState('');
    const [typeAccess, setTypeAccess] = useState([]);
    const history = useHistory();
    const [inEdit, setInEdit] = useState({
        checkedA: false,
        checkedC: false,
    });
    const handleChange = async (event) => {

        setInEdit({ ...inEdit, [event.target.name]: event.target.checked });
    }

    const [selectedUserName, setSelectedUserName] = useState('');
    const [newPass, setNewPass] = useState('00000');
    const [oldPass, setOldPass] = useState('');
    const [userName, setUserName] = useState('');
    const [selectedUserEmail, setSelectedUserEmail] = useState('');
    const [selectedUserBranch, setSelectedUserBranch] = useState('');
    const [picturePreview, setPicturePreview] = useState('');
    const [imageSrc, setImageSrc] = useState(null)

    function readFile(file) {
        return new Promise((resolve) => {
            const reader = new FileReader()
            reader.addEventListener('load', () => resolve(reader.result), false)
            reader.readAsDataURL(file)
        })
    }

    function getUserName() {

        let cuttNamePosition = (user?.full_name?.indexOf(' '))

        if (cuttNamePosition > 0) {

            setUserName(user?.full_name?.slice(0, cuttNamePosition))
        } else {
            setUserName(user?.full_name)
        }
        setSelectedUserName(user?.full_name)
    }

    useEffect(() => {

        if (edited || userName === '') {

            getUserName()
            setEdited(false)
        }

    }, [edited])

    useEffect(() => {

        async function getAvatar() {

            try {
                const avatarUser = await api.get(`/api/v1/uploads/avatar/${userAvatar}`, {
                    headers: {
                        authorization: `Bearer ${tokenKey}`,
                    },
                    responseType: 'blob'
                })
                setUserAvatarProfile(URL.createObjectURL(avatarUser.data))
            } catch (error) {
                setUserAvatarProfile('erro')
            }
        }

        async function getBranches() {

            try {
                const branchesResult = await api.get('/api/v1/branches', {
                    headers: {
                        authorization: `Bearer ${tokenKey}`
                    }
                })
                setBranchesList(branchesResult.data.data)
                const branche = (branchesResult.data.data.filter((branchInList) => {
                    if (branchInList?.id === user?.branches_id) {
                        return (branchInList)
                    }
                }))
                setSelectedUserBranch(branche[0])
                setLoading(false);
            } catch (error) {
                alert(error?.response?.data?.message)
            }
        }

        async function searchUsers() {

            try {
                const usersResult = await api.get(`/api/v1/users/${user?.external_id}`, {
                    headers: {
                        authorization: `Bearer ${tokenKey}`
                    }
                })
                if (!usersResult.data.data === undefined) {

                    setUserTotalClients(usersResult?.data?.data[0]?.total_clients)
                }

            } catch (error) {
                alert(error.response.data.message)
            }
        }

        async function getTypes() {
            const typeResult = await api.get('/api/v1/types', {
                headers: {
                    authorization: `Bearer ${tokenKey}`
                }
            })

            setTypeAccess(typeResult?.data?.data?.filter((typeUser) => {
                if (typeUser.type_access === user?.type) {
                    return typeUser
                }
            }))
        }

        if (tokenKey !== undefined && !!tokenKey) {
            searchUsers()
            getAvatar();
            getBranches();
            getTypes();
            setSelectedUserName(user?.full_name);
            setSelectedUserEmail(user?.email);
            setNewPass('');
        }


    }, [tokenKey, userAvatar])

    async function handleLogout() {
        if (window.confirm(`Deseja sair do sistema?`)) {
            try {
                await api.post(`/auth/logout`)
                localStorage.removeItem('@auth:token')
                localStorage.removeItem('@auth:user')
                setUser(null)
                setUserType('')
                setTokenKey('');
                socketEmit('disconnect')
                history.push('/')
                return;
            } catch (error) {
                alert('Erro ao realizar Logout, entre em contato com o Suporte.')
            }
        }

    }

    function handleGetImageUser() {
        document.getElementById('fileUserInput').click();
    }

    const handleNewUserAvatar = async (e) => {
        const file = e.target.files[0];
        let imageDataUrl = await readFile(file)
        console.log(imageDataUrl)
        setImageSrc(imageDataUrl);
        setPicturePreview(URL.createObjectURL(file))
        setType({ name: 'userAvatar', user: user, value: { file: e, imgSrc: imageDataUrl, picPreview: URL.createObjectURL(file) } })
        history.push(`/editarAvatar`);
        setShowUser(!showUser);
        setShowProfileConfig(false);
    }

    function validateEmail(email) {
        let emailForCheck = email?.toString().toLowerCase();
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(emailForCheck);
    }

    function handleCloseProfileModal() {
        document.getElementById('checkMenu').checked = false
        setMenuStatus(0);
        setShowProfileConfig(false);
        setShowNotificationCenter(false);
        setShowUser(!showUser);
    }

    async function handleSubmit(e) {

        e.preventDefault();

        if (selectedUserEmail !== '') {
            if (!validateEmail(selectedUserEmail)) return alert('E-mail inválido')
        }
        if (newPass !== '' || oldPass !== '') {

            if (newPass === oldPass) {
                return alert('Senha não pode ser igual a última')
            }

            setLoading(true)
            if (!validPassword(newPass)) {
                setLoading(false)
                return alert('Requisitos mínimos: Conter no mínimo 8 letras e máximo 20, conter 1 ocorrência de cada: Letra maiúscula, letra minúscula, número e caracter especial.')
            } else {
                try {
                    await api.put(`/api/v1/users/${user?.external_id}/resetPassword`, {
                        "old_password": Buffer.from(oldPass).toString('base64'),
                        "new_password": Buffer.from(newPass).toString('base64')
                    },
                        {
                            headers: {
                                authorization: `Bearer ${tokenKey}`
                            }
                        })

                    setDataChanged(true);
                } catch (error) {

                    if (error?.response?.data?.message === `Erro tentar alterar senha do usuário com external_id '${user?.external_id}', a senha pode estar errada.`) {
                        alert(`Erro ao alterar a senha, senha antiga não está correta, tente novamente`);
                    } else {
                        alert(error?.response?.data?.message)
                    }
                    setNewPass('')
                    setOldPass('')
                    setLoading(false)
                    return
                }
            }
        }

        if (selectedUserEmail !== user?.email || selectedUserName !== userName) {
            setLoading(true)
            try {
                const userChangeResult = await api.put(`/api/v1/users/${user.external_id}`,
                    {
                        "full_name": selectedUserName === '' ? user?.full_name : selectedUserName,
                        "branches_id": selectedUserBranch?.id,
                        "email": selectedUserEmail === '' ? user?.email : selectedUserEmail,
                        "type": user?.type,
                        "profile_id": parseInt(user?.type) === 3 ? user?.profile_id : undefined
                    },
                    {
                        headers: {
                            authorization: `Bearer ${tokenKey}`
                        }
                    })
                if (userChangeResult.data.new_access_token !== '' || userChangeResult.data.new_access_token !== undefined) {
                    setTokenKey(userChangeResult.data.new_access_token)
                    localStorage.setItem('@auth:token', userChangeResult.data.new_access_token)
                }

                setUser({
                    avatar: user?.avatar,
                    branches_id: user?.branches_id,
                    email: selectedUserEmail,
                    external_id: user?.external_id,
                    full_name: selectedUserName,
                    profile_id: user?.profile_id,
                    type: user?.type
                })
                setNewPass('');
                setOldPass('');
                setInEdit({ ...inEdit, checkedA: false });
                setSelectedUserEmail(selectedUserEmail)
                setLoading(false);
                setDataChanged(true);
                setEdited(true)

            } catch (error) {
                console.log(error.response)
                setLoading(false)
            }
        }
    }

    return (

        <div className={setShowUser && showProfileConfig ? 'userActionsWide' : showUser ? 'userActions' : 'userActionsHide'}>

            {

                loading ? (
                    <div className='usersActionsWideLoader'>
                        < ArrowLoader />
                    </div>
                ) : dataChanged ?
                    (<div className='userChangePassSuccess'>
                        <a>Dados alterados com sucesso!</a>
                        <button onClick={() => setDataChanged(false)}>ok</button>
                    </div>) :
                    (
                        <>
                            <div className={showUser && showProfileConfig ? 'actionMinHide' : 'actionMin'}>

                                {userAvatarProfile == '' ? (
                                    <div className='LoaderMyProfil'>
                                        <ArrowLoader />
                                    </div>
                                ) : userAvatarProfile == 'erro' ?
                                    (
                                        <img srcSet={defaultAvatar} />
                                    ) : (
                                        <img srcSet={userAvatarProfile} />
                                    )}
                                <a>{titleize(userName !== '' ? userName : '')}</a>
                                <a onClick={() => { setShowProfileConfig(!showProfileConfig) }}>Minha conta</a>
                                <a onClick={() => handleLogout()}>Sair</a>
                            </div>
                            <div className={showProfileConfig ? 'usersActionWideShowOptions' : 'actionMinHide'}>
                                <div className='profileUserAvatar'>
                                    <button title='Fechar perfil' id='profileCloseButton' onClick={() => handleCloseProfileModal()}>X</button>
                                    <div>
                                        <span>
                                            <a>Editar</a>
                                            <Typography id='spanEdit' component="div">
                                                <Grid component="label" container alignItems="center" spacing={1}>
                                                    <Grid item>
                                                        <AntSwitch className={classes.switch} checked={inEdit.checkedA} onChange={handleChange} name="checkedA" />
                                                    </Grid>
                                                </Grid>
                                            </Typography>
                                        </span>
                                        <span>
                                            {userAvatarProfile == '' ? (
                                                <div className='LoaderMyProfil'>
                                                    <ArrowLoader />
                                                </div>
                                            ) : userAvatarProfile == 'erro' ?
                                                (
                                                    <img onClick={() => handleGetImageUser()} srcSet={defaultAvatar} />
                                                )
                                                : (
                                                    <div className='userAvatar'>

                                                        <img onClick={() => handleGetImageUser()} srcSet={userAvatarProfile} />
                                                    </div>
                                                )}
                                            <a>{titleize(user?.full_name)}</a>
                                            <a onClick={() => handleGetImageUser()}>Alterar foto de perfil</a>
                                            <input
                                                id='fileUserInput'
                                                accept='image/png, image/jpg, image/jpeg'
                                                type='file'
                                                onChange={handleNewUserAvatar}
                                            >
                                            </input>
                                        </span>
                                        <span>
                                            <></>
                                        </span>

                                    </div>
                                </div>

                                <div className='formDiv'>
                                    <form onSubmit={() => handleSubmit()}>
                                        <div className='bodyDataUser'>
                                            <div className='formLeftDiv'>
                                                {inEdit.checkedA ?
                                                    (
                                                        <label id='leftLabel'>
                                                            <div className='profileAlterProperty'>
                                                                <label>
                                                                    <TextField
                                                                        value={titleize(selectedUserName)}
                                                                        label='Nome'
                                                                        id='userName'
                                                                        inputProps={{ maxLength: '50' }}
                                                                        className={classes.textField}
                                                                        onChange={(e) => { setSelectedUserName(e.target.value) }}
                                                                        variant="outlined" />
                                                                </label>
                                                            </div>
                                                            <div className='profileAlterProperty'>
                                                                <label id='passwordLabel'>
                                                                    <TextField
                                                                        label='Senha antiga'
                                                                        type='password'
                                                                        id='oldPass'
                                                                        inputProps={{ maxLength: '20' }}
                                                                        className={classes.textField}
                                                                        onChange={(e) => { setOldPass(e.target.value) }}
                                                                        variant="outlined" />
                                                                    <TextField
                                                                        label='Nova senha'
                                                                        type='password'
                                                                        id='newPass'
                                                                        inputProps={{ maxLength: '20' }}
                                                                        className={classes.textField}
                                                                        onChange={(e) => { setNewPass(e.target.value) }}
                                                                        variant="outlined" />
                                                                </label>
                                                            </div>
                                                            <div className='profileAlterProperty'>
                                                                <TextField
                                                                    value={selectedUserEmail}
                                                                    id='e-mail'
                                                                    label='E-mail'
                                                                    className={classes.textField}
                                                                    onChange={(e) => { setSelectedUserEmail(e.target.value) }}
                                                                    variant="outlined" />
                                                            </div>
                                                        </label>
                                                    ) :
                                                    (
                                                        <label>
                                                            <div className='profileAlterProperty'>
                                                                <a>Nome:</a>
                                                                <a>{titleize(user?.full_name)}</a>
                                                            </div>
                                                            <div className='profileAlterProperty'>
                                                                <a>Senha:</a>
                                                                <a>******</a>
                                                            </div>
                                                            <div className='profileAlterProperty'>
                                                                <a>E-mail:</a>
                                                                <a>{user?.email?.toLowerCase()}</a>
                                                            </div>
                                                        </label>
                                                    )}
                                            </div>
                                            <div className='profileSeparator'></div>
                                            <div className='formRightDiv'>
                                                <label>
                                                    <div className='profileAlterProperty'>
                                                        <a>Filial:</a>
                                                        <a>{selectedUserBranch?.name}</a>
                                                    </div>
                                                    <div className='profileAlterProperty'>
                                                        <a>Permissão:</a>
                                                        <a>{titleize(typeAccess[0]?.type_description)}</a>
                                                    </div>
                                                    {user?.type > 2 ?
                                                        (<div className='profileAlterProperty'>
                                                            <a>Numero de Clientes:</a>
                                                            <a>{userTotalClients !== null ? userTotalClients : 'Sem clientes'}</a>
                                                        </div>) : (<></>)}
                                                </label>
                                            </div>
                                        </div>
                                        <button id={inEdit.checkedA ? 'myProfileSubmit' : 'myProfileButtonHide'} onClick={handleSubmit}>Confirmar</button>
                                    </form>
                                </div>
                            </div >
                        </>
                    )
            }
        </div >
    )
}