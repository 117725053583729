import React, { useEffect, useState } from 'react';
/*Styles*/
import './styles/assets.cadastration.css'
/*Contexts*/
import { ModalSizeFitToScreen } from '../../../../utils/responsiveFunctions/modalSizeFitToScreen'
import { useModal } from '../../../../contexts/useModal'
import { useAuth } from '../../../../contexts/useAuth'
/*Components*/
import { TextField } from '@material-ui/core';
import { styleClientsInput } from '../../../../components/styles/autocompletField'
/*Utils*/
import { api } from '../../../../services/api';
import { ArrowLoader } from '../../../../components/loaders/arrowLoader';

export function AssetClassCadastration() {

    const [loading, setLoading] = useState(false);
    const { screenX, screenY } = ModalSizeFitToScreen()
    const { show, setShow, setType, setEdited } = useModal()
    const { tokenKey } = useAuth()
    const classes = styleClientsInput()
    const [classType, setClassType] = useState(false)
    const [selectedAssetClass, setSelectedAssetClass] = useState('');

    useEffect(() => {

        setClassType(false)

    }, [show])

    async function handleSubmitNewClass(e) {

        e.preventDefault()
        setLoading(true)
        if(!selectedAssetClass.trim().length){
            alert('Descrição está vazio')
            setLoading(false)
            return
        }

        try {

            await api.post(`/api/v1/assetClass`, {
                description: selectedAssetClass,
                type: classType ? 'F' : 'L'
            }, {
                headers: {
                    authorization: `Bearer ${tokenKey}`
                }
            })
            setLoading(false)
            setType({ name: 'alertMsg', type: 'information', value: { message: 'Cadastro de classe realizada com sucesso!' } })
            setShow(true)
            setEdited(true)
        } catch (error) {
            setType({ name: 'alertMsg', type: 'information', value: { message: error?.response?.data?.message === undefined ? 'Erro ao cadastrar classe do ativo' : error?.response?.data?.message } })
            setShow(true);
        }
    }

    return (
        <div className="assetClassCadastrationContainer" style={{
            width: screenX > 1300 ? screenX - 550 : screenX - 350,
            height: screenY > 700 ? screenY - 250 : screenY - 100,
            minHeight: 350,
            minWidth: 600
        }}>
            <div className="assetClassCadastrationHeader">
                <p>Cadastro de classe de ativos</p>
                <button
                    className='modalCloseButton' onClick={() => setShow(false)}>X</button>
            </div>
            {
                loading ?
                    (
                        <div className="assetClassCadastrationLoader">
                            <ArrowLoader />
                        </div>
                    )
                    :
                    (
                        <div className="assetCadastrationBody">
                            <div className='assetCadastrationBodyDescription'>
                                <p>Efetue o cadastro da <strong>Classe</strong>, 
                                    essa será utilizada quando efetuar o cadastro do ativo, 
                                    o campo <strong>(Permitir Fração)</strong> ao ser marcado, irá permitir operar de forma <strong>Fracionada</strong> os ativos dentro da classe cadastrada, 
                                    caso não seja marcado, as operações da classe poderão ocorrer apenas em lotes.
                                </p>
                            </div>
                            <form onSubmit={(e)=> handleSubmitNewClass(e)} className='assetCadastrationSelectClass'>
                                <label>
                                    <TextField
                                        onKeyPress={(e) => {if(e.code === 'Backquote') e.preventDefault()}}
                                        inputProps={{maxLength: '30'}}
                                        className={classes.styleForClients}
                                        variant='outlined'
                                        label='Descrição'
                                        onChange={(e) => setSelectedAssetClass(e.target.value)}
                                    >
                                    </TextField>
                                </label>
                                <button
                                    type='button'
                                    title='Marque para permitir operar de forma fracionada para esta classe'
                                    className='assetClassCadastrationTypeButton'
                                    onClick={() => setClassType(!classType)}
                                    style={{
                                        background: classType ? 'var(--goldColor)' : 'white'
                                    }}
                                ><a
                                    style={{
                                        opacity: classType ? '1' : '0'
                                    }}
                                >x
                                    </a>
                                </button>
                                <a title='Marque para permitir operar de forma fracionada para esta classe'>Permite fração?</a>
                            </form>
                            <button type='submit' className='buttonWhiteBg' onClick={(e) => handleSubmitNewClass(e)}>Cadastrar</button>
                        </div>
                    )
            }
        </div>
    )
}