import React from 'react';
/*Styles*/
import './styles/recomendations.css';
/*Components*/
import { RecomendationsTable } from './tables/recomendations.table';
import Modal from '../../../components/modal/modal'
import { ConfirmationBox } from '../../../components/confirmationBox/confirmationBox'
import { ReccomendationsImport } from './recommendationsImport';
/*Contexts*/
import { useModal } from '../../../contexts/useModal';

export function Recomendations() {

    const { type } = useModal()

    return (
        <div className="recomendationsContainer">

            <Modal >
                {
                    type.name === 'alertMsg' ?
                        (
                            <ConfirmationBox msgData={type} />
                        ) :
                        type.name === 'importRecommendations' ?
                        (
                         <ReccomendationsImport />
                        ) : 
                        (<></>)
                }
            </ Modal>
            <RecomendationsTable />
        </div >
    )
}