import React, { useState, useEffect } from 'react'
/*Styles*/
import '../styles/assetSector.table.css'
import { FiTrash2 } from 'react-icons/fi'
/*Services*/
import { getAssetSectors } from '../../../../../services/asyncFunctions.js'
/*Contexts*/
import { useAuth } from '../../../../../contexts/useAuth'
import { useModal } from '../../../../../contexts/useModal'
/*Components*/
import { ArrowLoader } from '../../../../../components/loaders/arrowLoader'
import { api } from '../../../../../services/api'

export function AssetSectorTable() {

    const { show, setShow, type, setType, edited, setEdited } = useModal()
    const { tokenKey } = useAuth()
    const [assetSectorsList, setAssetSectorsList] = useState([])
    const [loading, setLoading] = useState(true)

    async function getSectors() {

        const sectorsGet = await getAssetSectors(tokenKey)
        if (sectorsGet?.isError) {
            setType({
                name: 'alertMsg',
                type: 'information',
                value: {
                    message: sectorsGet?.error
                }
            })
            setShow(true);
            setLoading(false)
        } else {
            setAssetSectorsList(sectorsGet)
            setLoading(false)
        }
    }

    async function handleDelete(assetSector){
        setLoading(true)
        try{

            const removeSector = await api.delete(`/api/v1/assetSector/${assetSector.id}`, {
                headers: {
                    authorization: `Bearer ${tokenKey}`
                }
            })

            setType({name: 'alertMsg', type:'information', value: {message: 'Registro removido com sucesso!'}})
            setShow(true)
            setLoading(false)
            setEdited(true)
        }catch(error){
            console.log(error?.response)
            setType({name: 'alertMsg', type: 'information', value: {message: error?.response?.data?.message === undefined ? 'Erro ao remover setor' : error?.response?.data?.message}})
            setShow(true)
            setLoading(false)
        }
    }

    useEffect(() => {
        setLoading(true)
        if (edited || assetSectorsList.lenght === undefined) {
            getSectors()
            setEdited(false)
        }

    }, [edited])

    return (
        <table id='assetSectorTable'>
            <thead>
                <tr>
                    <th colSpan='2'>
                        <div className='assetSectorTableFirstDivTH'>
                            <p>Lista de setores</p>
                            <div className='assetSectorTableFirstDivButtons'>
                                <input className='tableAllInputThead' placeholder='Filtrar'></input>
                                <button
                                    className='buttonTableThead'
                                    onClick={() => {
                                        setType({ name: 'assetSectorCadastration' })
                                        setShow(true)
                                    }}
                                >Cadastrar</button>
                            </div>
                        </div>
                    </th>
                </tr>
                <tr>
                    <th>Setor</th>
                    <th>Excluir</th>
                </tr>
            </thead>
            <tbody>
                {
                    loading ?
                        (
                            <tr id='assetSectorsLoading'>
                                <td colSpan='2'><ArrowLoader /></td>
                            </tr>
                        ) :
                        assetSectorsList?.length > 0 ?
                            assetSectorsList.map((assetSector) => {
                                return (
                                    <tr>
                                        <td>{assetSector.description}</td>
                                        <td><FiTrash2 onClick={()=> handleDelete(assetSector)} /></td>
                                    </tr>
                                )
                            }) :
                            (
                                <tr>
                                    <td colSpan='2'>--Lista Vazia--</td>
                                </tr>
                            )
                }
            </tbody>
        </table>
    )
}
