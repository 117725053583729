/**
 * Desenvolvido por equipe: Tiago Murilo
 * Descrição: Faz o controle de todos os sockets.
 **/
import React, { createContext, useState, useEffect, useContext } from 'react';
import { api } from '../services/api';

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {

    const [user, setUser] = useState(null);
    const [userType, setUserType] = useState('');
    const [loading, setLoading] = useState(true);
    const [userAvatar, setUserAvatar] = useState(true);
    const [tokenKey, setTokenKey ] = useState('');

    useEffect(() => {
        
        async function localStorageData() {

            const storagedToken = localStorage.getItem('@auth:token');

            try {

                const isValid = await api.get('/auth/logged', {
                    headers: {
                        authorization: `Bearer ${storagedToken}`
                    }
                })

                if (isValid.data.token_valid === true) {
                    setUser(isValid.data.user);
                    setUserAvatar(isValid?.data?.user?.avatar)
                    setUserType(isValid.data.user.type);
                    setTokenKey(storagedToken);
                }
                setLoading(false)
            } catch (err) {
                setLoading(false)
            }
        }
        localStorageData();


    }, [])

    async function getInfos(token) {

        try {
            const response = await api.get('/api/v1/users/infos', {
                headers: {
                    authorization: `Bearer ${token}`
                }
            })
            setUser(response.data.user)
            setUserAvatar(response?.data?.user?.avatar)
            setUserType(response.data.user.type)
            localStorage.setItem('@auth:user', JSON.stringify(response.data.user));
            return true
        } catch (error) {
            console.log(error.response)
            if (error?.response?.status >= 400 && error.response.status < 500) {
                alert(error.response.data.message)
                return false;
            }
            alert("Erro interno. Entre em contato com o suporte")
            return false;
        }
    }

    async function signIn(login, password) {

        try {
            const response = await api.post('/auth/logon', {
                login: login,
                password: Buffer.from(password).toString('base64')
            })
            // setUser(response.data.user)
            // setUserAvatar(response?.data?.user?.avatar)
            // setUserType(response.data.user.type)
            localStorage.setItem('@auth:token', response.data.access_token);
            setTokenKey(response.data.access_token);
            // localStorage.setItem('@auth:user', JSON.stringify(response.data.user));
            await getInfos(response.data.access_token);
            return true;

        } catch (error) {
		    console.log(error.response)
            if (error?.response?.status >= 400 && error.response.status < 500) {
                alert(error.response.data.message)
                return false;
            }
            alert("Erro interno. Entre em contato com o suporte")
            return false;
        }
    }

    return (
        <AuthContext.Provider value={{
            signed: Boolean(user),
            loading: loading,
            user: user,
            signIn,
            userType,
            setUser,
            setUserType,
            userAvatar,
            setUserAvatar,
            setTokenKey,
            tokenKey
        }}>
            {children}
        </AuthContext.Provider>
    )
}

export function useAuth() {
    return useContext(AuthContext);
}
