import React, { useEffect, useState } from "react"
import "../monitoringPanel/myReservations/styles/myReservations.details.css"

import { useModal } from "../../../contexts/useModal"
import { FiCopy } from "react-icons/fi"
import { FaCheck } from "react-icons/fa"
import { AiFillCloseCircle } from "react-icons/ai"
import { useAuth } from "../../../contexts/useAuth"

export function CampainSpeech(props) {
    console.log("MENU", props);
    const { type, show, setShow } = useModal()
    const [response, setResponse] = useState(false)
    const [copyingLink, setCopyingLink] = useState(false)
    const { tokenKey, user } = useAuth()

    useEffect(() => {
        type.data.order === undefined || type.data.order === "undefined" || type.data.order === "" || type.data.order === "0" ? setResponse(false) : setResponse(true)
    }, [show])

    return (
        <div className="containerModalDashDetails" style={{ height: "33rem", maxHeight: "33rem", maxWidth: "80vw" }}>
            {
                <>
                    <div className="editBrancheBoxHeaderDash">
                        <p style={{ color: "#D4AC00" }}>Discurso de Venda - {props.prop.data.c?.description}</p>
                        <div style={{ display: "grid", gridTemplateColumns: "auto auto", alignItems: "end" }}>
                            <p style={{ marginRight: "1rem" }}>{user.external_id + " - " + user.full_name} </p>
                            <button
                                onClick={() => {
                                    // clear()
                                    setShow(false)
                                }}
                                className="modalCloseButtonDashboard"
                                style={{ marginRight: "12px", height: "36px", width: "36px" }}
                            >
                                <AiFillCloseCircle />
                            </button>
                        </div>
                    </div>
                    <div className="cubastarTacticalDivGrid">
                        <div className="cubastarTacticalContainer">
                            <div className="cubastarTacticalInternal">
                                <p className="cubastarTacticalInternalUse" style={{color: '#454545'}}>USO INTERNO</p>
                                <br></br>
                                <div style={{ padding: "1rem" }}>
                                    <pre style={{ fontFamily: "Roboto, sans-serif", fontSize: "1rem", overflow: "auto", height: "20rem", color: '#454545',whiteSpace: 'pre-wrap', overflowX: 'hidden' }}>{props.prop.data.c?.speech ? props.prop.data.c?.speech : props.prop.data.c?.general_internal_speech}</pre>
                                </div>
                            </div>
                        </div>
                        <div></div>
                        <div className="cubastarTacticalContainer">
                            <div className="cubastarTacticalInternal"
                            style={{
                                boxShadow: "-1px 1px 9px 1px #D4AC00",
                                borderRadius: "10px"
                            }}>
                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", backgroundColor: "#BBBBBB", padding: "1rem", borderRadius: "10px" }}>
                                    <div style={{ fontWeight: "bold", fontSize: "20px", textAlign: "center", flex: "50%" }}>DISCURSO COMERCIAL</div>
                                    
                                    {(props.prop.data.c?.vendor_speech != null && props.prop.data.c?.vendor_speech != '') ||
                                     (props.prop.data.c?.general_speech != null && props.prop.data.c?.general_speech != '')
                                    ? <div
                                        title={"Copiar Texto"}
                                        style={{ 
                                            cursor: "pointer", 
                                            width: "1.5rem" , }}
                                        onClick={async () => {
                                            navigator.clipboard.writeText(props.prop.data.c?.vendor_speech ? props.prop.data.c?.vendor_speech : props.prop.data.c?.general_speech)
                                            setCopyingLink(true)
                                            setTimeout(() => setCopyingLink(false), 1000)
                                            
                                        }}

                                    >
                                        {copyingLink ? <FaCheck size={20} color={"green"} /> : <FiCopy />}
                                    </div> : <></>}  
                                    
                                </div>
                                <br></br>
                                <div style={{ padding: "1rem" }}>
                                    <pre style={{ fontFamily: "Roboto, sans-serif", fontSize: "1rem", overflow: "auto", height: "20rem",whiteSpace: 'pre-wrap', overflowX: 'hidden' }}>{props.prop.data.c?.vendor_speech ? props.prop.data.c?.vendor_speech : props.prop.data.c?.general_speech}</pre>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
        </div>
    )
}
