/*Componente detalhe de clientes
/*Descrição: Exibe os detalhes e permite alteração do cliente
/*Desenvolvido por: Equipe Rafael Cezário
*/

//libraries
import React, { useState, useEffect, useRef } from "react";
import AntSwitch from "@material-ui/core/Switch";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { FiEdit, FiTrash2, FiX } from "react-icons/fi";
import { BsWhatsapp, BsTelephone, BsPeople, BsChatSquareText, BsCpu, BsChatDots, BsCardList, BsBoxArrowUpLeft, BsPersonPlus } from "react-icons/bs";
import { AiOutlineMail, AiOutlineSend } from "react-icons/ai";
import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
//utils
import { titleize } from "../../../../utils/formatNumberAndText/formatTextUpperFirstLetter";
import CurrencyTextField from "@unicef/material-ui-currency-textfield";
import { numberBRLFormat } from "../../../../utils/formatNumberAndText/formatNumbertoBRL";
import { ModalSizeFitToScreen } from "../../../../utils/responsiveFunctions/modalSizeFitToScreen";
import { toBRL } from "../../../../utils/formatNumberAndText/toBRLCurrency";
//components
import { ArrowLoader } from "../../../../components/loaders/arrowLoader";
import { useModal } from "../../../../contexts/useModal";
//services
import { api } from "../../../../services/api";
//styles
import { styleClientsInput } from "../../../../components/styles/autocompletField";
import { makeStyles } from "@material-ui/core/styles";
import "./styles/vbaClients.details.css";
//Contexts
import { useAuth } from "../../../../contexts/useAuth";

export function VbaClientDetails(props) {
    console.log('propsVba', props)
    const { user, tokenKey } = useAuth();
    const { show, setShow, setType, type, edited, setEdited } = useModal();
    const [statusThisClient, setStatusThisClient] = useState("");
    const [statusList, setStatusList] = useState([]);
    const [clientData, setClientData] = useState([]);
    const [listBrokers, setListBrokers] = useState([]);
    const [listAdvisor, setListAdvisor] = useState([]);
    const [advisorList, setAdvisorList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [inEditProfileBroker, setInEditProfileBroker] = useState([]);
    const [successEdit, setSuccessEdit] = useState(false);
    const [statusUpdatedToActive, setStatusUpdatedToActive] = useState(false);
    const [brokerRemoved, setBrokerRemoved] = useState([]);
    const [loadingTable, setLoadingTable] = useState(false);
    const [loadingTimeline, setLoadingTimeline] = useState(false);
    const [profileList, setProfileList] = useState([]);
    const [clientIsUpdated, setClientIsUpdated] = useState(false);
    const [clientStatusUpdated, setClientStatusUpdated] = useState(false);
    const [selectedBroker, setSelectedBroker] = useState(null);
    const [defaultBroker, setDefaultBroker] = useState(null);
    const [brokerListAll, setBrokerListAll] = useState([]);
    const [brokerListDigital, setBrokerListDigital] = useState([]);
    const [brokerListPrivate, setBrokerListPrivate] = useState([]);
    const [inEdit, setInEdit] = useState({
        checkedA: false,
        checkedC: false,
    });
    const [selectedClientName, setSelectedClientName] = useState("");
    const [selectedClientProfile, setSelectedClientProfile] = useState("");
    const [selectedNetStock, setSelectedNetStock] = useState("");
    const [selectedNetTotal, setSelectedNetTotal] = useState("");
    const [selectedBrokers, setSelectedBrokers] = useState([]);
    const [selectedAdvisor, setSelectedAdvisor] = useState([]);
    const [data, setData] = useState([]);
    const [edit, setEdit] = useState(false);
    const [commentOn, setCommentOn] = useState(false);
    const [comment, setComment] = useState("");
    const [comentTimeline, setComentTimeline] = useState("");
    const [contactType, setContactType] = useState(5);
    const [filters, setFilters] = useState([]);
    const [items, setItems] = useState([]);
    const [allItems, setAllItems] = useState([]);
    const [filteredItems, setFilteredItems] = useState([]);
    const [itemQty, setItemQty] = useState(30);
    const [selectedStatus, setSelectedStatus] = useState("");

    const [clientExcecao, setClientExcecao] = useState(props.clientData.value.client[29] == "Sim" ? true : false);
    const [clientExcecaoEdited, setClientExcecaoEdited] = useState(false);
    const [segClientDigitalExcecao, setSegClientDigitalExcecao] = useState(props.clientData.value.client[38] == "Digital" ? true : false);
    const [segClientDigitalExcecaoEdited, setSegClientDigitalExcecaoEdited] = useState(false);
    const [segClientPrivateExcecao, setSegClientPrivateExcecao] = useState(props.clientData.value.client[38] == "Private" ? true : false);
    const [segClientPrivateExcecaoEdited, setSegClientPrivateExcecaoEdited] = useState(false);

    const [servicoHB, setServicoHB] = useState();
    const [servicoHBEdited, setServicoHBEdited] = useState(false);
    const [rootervicoHB, setRootervicoHB] = useState(props.clientData.value.client[39]);

    const [rootTipoPreferencialContato, setRootTipoPreferencialContato] = useState(0);
    const [rootPeriodicidadeContato, setRootPeriodicidadeContato] = useState(0);
    const [rootServiceModel, setRootServiceModel] = useState(props.clientData.value.client[38]);

    const [rootOperatesAlone, setRootOperatesAlone] = useState(props.clientData.value.client[16]);
    const [rootBrokerRelationship, setRootBrokerRelationship] = useState(props.clientData.value.client[42]);
    const [rootEvasion, setRootEvasion] = useState(props.clientData.value.client[46]);

    const [selectedTipoPreferencialContato, setSelectedTipoPreferencialContato] = useState();
    const [selectedPeriodicidadeContato, setSelectedPeriodicidadeContato] = useState();
    const [selectedServiceModel, setSelectedServiceModel] = useState();
    const [selectedEvasion, setSelectedEvasion] = useState();
    const [selectedOperatesAlone, setSelectedOperatesAlone] = useState();
    const [selectedBrokerRelationship, setSelectedBrokerRelationship] = useState();
    const [admAcount, setAdmAcount] = useState(props.clientData.value.client[6]);

    const [toggleTipoPreferencialContato, setToggleTipoPreferencialContato] = useState(false);
    const [togglePeriodicidadeContato, setTogglePeriodicidadeContato] = useState(false);
    const [toggleServiceModel, setToggleServiceModel] = useState(false);
    const [toggleOperatesAlone, setToggleOperatesAlone] = useState(false);
    const [toggleBrokerRelationship, setToggleBrokerRelationship] = useState(false);
    const [toggleAdmAcount, setToggleAdmAcount] = useState(false);
    const [toggleEvasion, setToggleEvasion] = useState(false)

    const [modalStandyByConfirm, setModalStandyByConfirm] = useState(false)
    const serviceModel = [
        { value: 1, description: 'Ultra High' },
        { value: 2, description: 'Top Tier'},
        { value: 3, description: 'Private'},
        { value: 4, description: 'Digital'},
        { value: 5, description: 'On Demand'},
    ]

    const evasao = [
        {value:0, description: 'Selecione na lista para marcar evasão...'},
        {value:1, description: 'Assessor solicitou retirada da mesa'},
        {value:2, description: 'Cliente zerou posição de RV'},
        {value:3, description: 'Cliente evadiu do escritório'},
        {value:4, description: 'Cliente solicitou cancelamento do serviço da mesa'},
        {value:5, description: 'Outros'},
    ]   

    const [languageFormalClientExcecao, setLanguageFormalClientExcecao] = useState(props.clientData.value.client[44] == 'Formal' ? true : false);
    const [languageFormalClientExcecaoEdited, setLanguageFormalClientExcecaoEdited] = useState(false);
    const [languageInformalClientExcecao, setLanguageInformalClientExcecao] = useState(props.clientData.value.client[44] == 'Informal' ? true : false);
    const [languageInformalClientExcecaoEdited, setLanguageInformalClientExcecaoEdited] = useState(false);
    const [contactClientExcecao, setContactClientExcecao] = useState(props.clientData.value.client[41] == 'Sim' ? true : false);
    const [contactClientExcecaoEdited, setContactClientExcecaoEdited] = useState(false);
    const [nonContactClientExcecaoEdited, setNonContactClientExcecaoEdited] = useState(false);
    const [nonContactClientExcecao, setNonContactClientExcecao] = useState(props.clientData.value.client[41] == 'Não' ? true : false);
    const [togglePreferrendAtivos, setTogglePreferrendAtivos] = useState(false);
    const [toggleRestricoesAtivos, setToggleRestricoesAtivos] = useState(false);
    const [preferrendAtivos, setPreferrendAtivos] = useState(props.clientData.value.client[42]);
    const [restricoesAtivos, setRestricoesAtivos] = useState(props.clientData.value.client[43]);
    const [toggleNotasGerais, setToggleNotasGerais] = useState(false);
    const [notasGerais, setNotasGerais] = useState(props.clientData.value.client[45]);

    const frequency = [
        { value: 1, description: "Semanal" },
        { value: 2, description: "Quinzenal" },
        { value: 3, description: "Mensal" },
        { value: 4, description: "Bimestral" },
    ];

    const preferency = [
        { value: 1, description: "Whatsapp" },
        { value: 2, description: "Ligação" },
        { value: 4, description: "Reunião presencial" },
        { value: 5, description: "Reunião online" },
    ];

    const brokerRelationship = [{ value: "Ativo" }, { value: "Passivo" }, { value: "Pendente" }, { value: "Evasão" }];

    const operatesAlone = [{ value: "Sim" }, { value: "Parcial" }, { value: "Não" }, { value: "Pendente" }];
    const [textInner, setTextInner] = useState(props.outros);

    // console.log('data', data)

    const useStyles = makeStyles((theme) => ({
        timelineNewCommentContactTypeActive: {
            width: "2rem",
            height: "2rem",
            backgroundColor: contactType == 5 ? "#EADDCA" : contactType == 1 ? "#A4F097" : contactType == 2 ? "#CBE6FF" : contactType == 3 ? "#FFC8C8" : "#FEFFBC",
            color: contactType == 5 ? "#412500" : contactType == 1 ? "#165102" : contactType == 2 ? "#001D82" : contactType == 3 ? "#A90000" : "#BA9C00",
            display: "grid",
            padding: "0.2rem",
            paddingTop: "0.15rem",
            borderRadius: "8px",
            transition: "all ease-in-out 0.2s",
            marginLeft: "0.4rem",
            marginRight: "0.4rem",
            cursor: "pointer",
        },
        timelineNewCommentContactTypeInactive: {
            width: "2rem",
            height: "2rem",
            backgroundColor: "#d3d3d3",
            display: "grid",
            padding: "0.2rem",
            paddingTop: "0.15rem",
            borderRadius: "8px",
            transition: "all ease-in-out 0.2s",
            cursor: "pointer",
            marginLeft: "0.4rem",
            marginRight: "0.4rem",
            "&:hover": {
                backgroundColor: "#b8b8b8",
            },
        },
        timelineFilterType1Active: {
            width: "1.5rem",
            height: "1.5rem",
            backgroundColor: "#A4F097",
            color: "#165102",
            display: "grid",
            padding: "0.2rem",
            paddingTop: "0.15rem",
            borderRadius: "8px",
            transition: "all ease-in-out 0.2s",
            marginLeft: "0.4rem",
            marginRight: "0.4rem",
            cursor: "pointer",
        },
        timelineFilterType2Active: {
            width: "1.5rem",
            height: "1.5rem",
            backgroundColor: "#CBE6FF",
            color: "#001D82",
            display: "grid",
            padding: "0.2rem",
            paddingTop: "0.15rem",
            borderRadius: "8px",
            transition: "all ease-in-out 0.2s",
            marginLeft: "0.4rem",
            marginRight: "0.4rem",
            cursor: "pointer",
        },
        timelineFilterType3Active: {
            width: "1.5rem",
            height: "1.5rem",
            backgroundColor: "#FFC8C8",
            color: "#A90000",
            display: "grid",
            padding: "0.2rem",
            paddingTop: "0.15rem",
            borderRadius: "8px",
            transition: "all ease-in-out 0.2s",
            marginLeft: "0.4rem",
            marginRight: "0.4rem",
            cursor: "pointer",
        },
        timelineFilterType4Active: {
            width: "1.5rem",
            height: "1.5rem",
            backgroundColor: "#FEFFBC",
            color: "#BA9C00",
            display: "grid",
            padding: "0.2rem",
            paddingTop: "0.15rem",
            borderRadius: "8px",
            transition: "all ease-in-out 0.2s",
            marginLeft: "0.4rem",
            marginRight: "0.4rem",
            cursor: "pointer",
        },
        timelineFilterType5Active: {
            width: "1.5rem",
            height: "1.5rem",
            backgroundColor: "#f3ece1",
            color: "#2b1800",
            display: "grid",
            padding: "0.2rem",
            paddingTop: "0.15rem",
            borderRadius: "8px",
            transition: "all ease-in-out 0.2s",
            marginLeft: "0.4rem",
            marginRight: "0.4rem",
            cursor: "pointer",
        },
        timelineFilterType0Active: {
            width: "1.5rem",
            height: "1.5rem",
            backgroundColor: "#a8a8a8",
            color: "#0e0e0e",
            display: "grid",
            padding: "0.2rem",
            paddingTop: "0.15rem",
            borderRadius: "8px",
            transition: "all ease-in-out 0.2s",
            marginLeft: "0.4rem",
            marginRight: "0.4rem",
            cursor: "pointer",
        },
        timelineFilterType6Active: {
            width: "1.5rem",
            height: "1.5rem",
            backgroundColor: "#cee4e0",
            color: "#003a2f",
            display: "grid",
            padding: "0.2rem",
            paddingTop: "0.15rem",
            borderRadius: "8px",
            transition: "all ease-in-out 0.2s",
            marginLeft: "0.4rem",
            marginRight: "0.4rem",
            cursor: "pointer",
        },
        timelineFilterTypeInactive: {
            width: "1.5rem",
            height: "1.5rem",
            backgroundColor: "#d3d3d3",
            display: "grid",
            padding: "0.2rem",
            paddingTop: "0.15rem",
            borderRadius: "8px",
            transition: "all ease-in-out 0.2s",
            cursor: "pointer",
            marginLeft: "0.4rem",
            marginRight: "0.4rem",
            "&:hover": {
                backgroundColor: "#b8b8b8",
            },
        },
        timelineNewComentWriteArea: {
            resize: "none",
            borderRadius: "10px",
            border: "1px solid #dadada",
            backgroundColor: "#f8f8f8",
            padding: "0.5rem",
            fontSize: "1rem",
            width: "calc(100% - 2rem)",
            "&:focus": {
                outline: "none",
                border: contactType == 5 ? "2px solid #412500" : contactType == 1 ? "2px solid #165102" : contactType == 2 ? "2px solid #001D82" : contactType == 3 ? "2px solid #A90000" : "2px solid #BA9C00",
            },
        },
        timelineNewCommentSendActive: {
            width: "3rem",
            height: "3rem",
            backgroundColor: contactType == 5 ? "#d3d3d3" : contactType == 1 ? "#A4F097" : contactType == 2 ? "#CBE6FF" : contactType == 3 ? "#FFC8C8" : "#FEFFBC",
            color: contactType == 5 ? "#412500" : contactType == 1 ? "#165102" : contactType == 2 ? "#001D82" : contactType == 3 ? "#A90000" : "#BA9C00",
            display: "grid",
            padding: "0.55rem",
            paddingLeft: "0.65rem",
            borderRadius: "8px",
            transition: "all ease-in-out 0.2s",
            marginTop: "auto",
            marginRight: "0.4rem",
            marginBottom: "0.15rem",
            border: "none",
            "&:hover": {
                backgroundColor: contactType == 5 ? "#b8b8b8" : contactType == 1 ? "#c5ffbb" : contactType == 2 ? "#e9f4ff" : contactType == 3 ? "#ffdbdb" : "#feffd3",
                boxShadow: contactType == 5 ? "0px 0px 4px #b8b8b8" : contactType == 1 ? "0px 0px 4px #165102" : contactType == 2 ? "0px 0px 4px #001D82" : contactType == 3 ? "0px 0px 4px #A90000" : "0px 0px 4px #BA9C00",
            },
        },
        timelineNewCommentSendInative: {
            width: "3rem",
            height: "3rem",
            backgroundColor: "#d3d3d3",
            color: "#b8b8b8",
            display: "grid",
            padding: "0.55rem",
            paddingLeft: "0.65rem",
            borderRadius: "8px",
            transition: "all ease-in-out 0.2s",
            marginTop: "auto",
            marginRight: "0.4rem",
            marginBottom: "0.15rem",
            border: "none",
            cursor: "default",
        },
        timelineComentBoxUserComentName: {
            width: "3rem",
            height: "3rem",
            backgroundColor: "#d3d3d3",
            color: "#b8b8b8",
            display: "grid",
            padding: "0.55rem",
            paddingLeft: "0.65rem",
            borderRadius: "8px",
            transition: "all ease-in-out 0.2s",
            marginTop: "auto",
            marginRight: "0.4rem",
            marginBottom: "0.15rem",
            border: "none",
            cursor: "default",
        },
    }));

    const classesContactType = useStyles();

    useEffect(() => {
        if(show){
            setClientData(props.clientData.value.client);
            setSegClientDigitalExcecao(props.clientData.value.client[38] == "Digital" ? true : false);
            setSegClientPrivateExcecao(props.clientData.value.client[38] == "Private" ? true : false);
            setPreferrendAtivos(props.clientData.value.client[42]);
            setRestricoesAtivos(props.clientData.value.client[43]);
            setNotasGerais(props.clientData.value.client[45]);
            setLanguageFormalClientExcecao(props.clientData.value.client[44] == 'Formal' ? true : false);
            setLanguageInformalClientExcecao(props.clientData.value.client[44] == 'Informal' ? true : false);
            setContactClientExcecao(props.clientData.value.client[41] == 'Sim' ? true : false);
            setNonContactClientExcecao(props.clientData.value.client[41] == 'Não' ? true : false);
            setSelectedTipoPreferencialContato(props.clientData.value.client[35] ? props.clientData.value.client[35] : 0);
            setSelectedPeriodicidadeContato(props.clientData.value.client[37] ? props.clientData.value.client[37] : 0);
            setSelectedServiceModel(props.clientData.value.client[38]);
            setSelectedEvasion(props.clientData.value.client[46]);
        }
    }, [show]);

    useEffect(() => {
        const props_data = props.clientData.value.client;
        clear();
        setData(props_data);
        setClientData({ external_id: data[0], name: data[1] });
        setSelectedClientName(type?.value.client[0]?.name);
        setSelectedClientProfile(type?.value.client[0]?.client_profile);
        setSelectedNetStock(type?.value.client[0]?.net_stock);
        setSelectedNetTotal(type?.value.client[0]?.net_total);
        setSelectedOperatesAlone(type?.value.client[0]?.opera_sozinho);
        setSelectedTipoPreferencialContato(props.clientData.value.client[35] ? props.clientData.value.client[35] : 0);
        setSelectedPeriodicidadeContato(props.clientData.value.client[37] ? props.clientData.value.client[37] : 0);

        setLoading(false);
        if (type?.value?.broker !== undefined && type?.value?.client !== undefined) {
            setListBrokers(type?.value?.broker);
            setListAdvisor(type?.value?.client);
            typeof type?.value?.client[0]?.advisor_external_id === "string" ? setSelectedAdvisor({ external_id: type?.value?.client[0].advisor_external_id, full_name: type?.value?.client[0].advisor_name }) : setSelectedAdvisor({ external_id: "-", full_name: "Selecione" });
            setSelectedBrokers(type?.value?.broker);
            setClientIsUpdated(false);
            setStatusUpdatedToActive(false);
            setClientStatusUpdated(false);
            setEdited(false);
            setInEdit({ ...inEdit, ["checkedA"]: false });
        }
    }, [type]);

    // useEffect(() => {
    //     if (!show){
    //         clear()
    //     }
    // }, [show]);

    function clear() {
        setInEditProfileBroker([]);
        setBrokerRemoved([]);
        setSuccessEdit(false);
        setSelectedBroker(null);
        setClientExcecao(null);
        setServicoHB(null);
        setClientExcecaoEdited(false);
        setServicoHBEdited(false);
        setSelectedTipoPreferencialContato(null);
        setSelectedPeriodicidadeContato(null);
        setFilters([])
        setFilteredItems([])
        setComentTimeline('')
        setContactType(5)
        setToggleAdmAcount(false);
        setModalStandyByConfirm(false)
    }

    function handleClickTarget(event) {
        event.target.click();
    }

    async function handleSubmit(clientStatus) {
        setLoading(true);
        if (selectedBroker == null) {
            return;
        }
        if (selectedBroker == user.external_id && data[20] == null) {
            try {
                const clientUpdate = await api.get(`/api/v1/vba/set/${String(selectedBroker)}/${String(data[0])}`, {
                    headers: {
                        authorization: `Bearer ${tokenKey}`,
                    },
                });

                setType({ name: "successClientEdit", type: "information", value: { message: `${clientUpdate.data.message}` + " - " + ` ${titleize(data[1])}` } });
                setLoading(false);
                setEdited(true);
            } catch (error) {
                alert(error?.response?.data?.message);
                setLoading(false);
            }
        } else {
            try {
                const clientUpdate = await api.get(`/api/v1/vba/transfer/${String(selectedBroker)}/${String(data[0])}/${clientStatus}`, {
                    headers: {
                        authorization: `Bearer ${tokenKey}`,
                    },
                });

                setType({ name: "successClientEdit", type: "information", value: { message: `${clientUpdate.data.message}` + " - " + ` ${titleize(data[1])}` } });
                setLoading(false);
                setEdited(true);
            } catch (error) {
                alert(error?.response?.data?.message);
                setLoading(false);
            }
        }
    }
    const autocompleteRef = useRef(null);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if(segClientDigitalExcecaoEdited || segClientPrivateExcecaoEdited){
            autocompleteRef.current.click()
            autocompleteRef.current.click()
            autocompleteRef.current.inputMode = ' '
            // autocompleteRef.current.setOpen(true)
            console.log('autocompleteRef', autocompleteRef)

        }
    }, [segClientDigitalExcecaoEdited, segClientPrivateExcecaoEdited])


    async function submitHandleSubmitException() {
        setLoading(true);
        // return
        try {
            const client = await api.put(
                `/api/v1/vba/performanceException`,
                {
                    client_id: parseInt(data[0]),
                },
                {
                    headers: {
                        authorization: `Bearer ${tokenKey}`,
                    },
                }
            );
            setType({ name: "successClientEdit", type: "information", value: { message: `${client.data.message}` + " - " + ` ${titleize(data[1])}` } });
            setLoading(false);
        } catch (error) {
            alert(error?.response?.data?.message);
            setLoading(false);
        }
    }
    async function updatePreferrendAtivos() {
        if (preferrendAtivos) {
            try {
                const clientUpdate = await api.put(
                    `api/v1/vba/update/${String(data[0])}/preferrend_ativos/${preferrendAtivos}`,
                    {},
                    {
                        headers: {
                            authorization: `Bearer ${tokenKey}`,
                        },
                    }
                );
                alert("Cliente " + titleize(data[1]) + " atualizado com sucesso!");
                setLoading(false);
                setEdited(true);
                setTogglePreferrendAtivos(false)
                clear();
            } catch (error) {
                alert(error?.response?.data?.message);
                setLoading(false);
            }
        }
    }

    async function updateNotasGerais() {
        // console.log('notasGerais', notasGerais)
        if (notasGerais) {
            try {
                const clientUpdate = await api.put(
                    `api/v1/vba/update/${String(data[0])}/notas_gerais/${notasGerais}`,
                    {},
                    {
                        headers: {
                            authorization: `Bearer ${tokenKey}`,    
                        },
                    }
                );
                alert("Cliente " + titleize(data[1]) + " atualizado com sucesso!");
                setLoading(false);
                setEdited(true);
                setToggleNotasGerais(false)
                clear();
            } catch (error) {
                alert(error?.response?.data?.message);
                setLoading(false);
            }
        }
    }


    async function updateRestricoesAtivos() {
        if (restricoesAtivos) {
            try {
                const clientUpdate = await api.put(
                    `api/v1/vba/update/${String(data[0])}/restricoes_ativos/${restricoesAtivos}`,
                    {},
                    {
                        headers: {
                            authorization: `Bearer ${tokenKey}`,
                        },
                    }
                );
                alert("Cliente " + titleize(data[1]) + " atualizado com sucesso!");
                setLoading(false);
                setEdited(true);
                setToggleRestricoesAtivos(false)
                clear();
            } catch (error) {
                alert(error?.response?.data?.message);
                setLoading(false);
            }
        }
    }
    async function submitHandleSubmitLanguageFormalClientException() {
        setLoading(true);
        try {
            const client = await api.put(
                `/api/v1/vba/update/${String(data[0])}/language_formal_client_exception/${languageFormalClientExcecao ? "1" : "0"}`,
                {
                    client_id: parseInt(data[0]),
                },
                {
                    headers: {
                        authorization: `Bearer ${tokenKey}`,
                    },
                }
            );
            alert("Cliente " + titleize(data[1]) + " atualizado com sucesso!");
            setLanguageFormalClientExcecaoEdited(false);
            setLoading(false);
            setEdited(true);
        } catch (error) {
            alert(error?.response?.data?.message);
            setLoading(false);
        }
    }

    async function updateEvasion() {
        if (selectedEvasion) {
            try {
                const clientUpdate = await api.put(
                    `api/v1/vba/update/${String(data[0])}/evasion/${selectedEvasion == 5 ? textInner : selectedEvasion}`,
                    {},
                    {
                        headers: {
                            authorization: `Bearer ${tokenKey}`,
                        },
                    }
                );
                alert("Cliente " + titleize(data[1]) + " atualizado com sucesso!");
                setLoading(false);
                setEdited(true);
                setToggleEvasion(false)
                clear();
            } catch (error) {
                alert(error?.response?.data?.message);
                setLoading(false);
            }
        }
    }

    async function submitHandleSubmitLanguageInformalClientException() {
        setLoading(true);
        try {
            const client = await api.put(
                `/api/v1/vba/update/${String(data[0])}/language_informal_client_exception/${languageInformalClientExcecao ? "0" : "1"}`,
                {
                    client_id: parseInt(data[0]),
                },
                {
                    headers: {
                        authorization: `Bearer ${tokenKey}`,
                    },
                }
            );
            alert("Cliente " + titleize(data[1]) + " atualizado com sucesso!");
            setLanguageInformalClientExcecaoEdited(false);
            setLoading(false);
            setEdited(true);
        } catch (error) {
            alert(error?.response?.data?.message);
            setLoading(false);
        }
    }


    async function submitHandleSubmitSegClientPrivateException() {
        setLoading(true);
        // console.log('segClientPrivateExcecao', segClientPrivateExcecao ? 1 : 0)
        try {
            const client = await api.put(
                `/api/v1/vba/update/${String(data[0])}/seg_client_private_exception/${segClientPrivateExcecao ? 1 : 0}`,
                {
                    client_id: parseInt(data[0]),
                },
                {
                    headers: {
                        authorization: `Bearer ${tokenKey}`,
                    },
                }
            );
            // console.log('client', client)
            alert("Cliente " + titleize(data[1]) + " atualizado com sucesso!");
            setSegClientPrivateExcecaoEdited(false);
            setLoading(false);
            setEdited(true);
        } catch (error) {
            alert(error?.response?.data?.message);
            setLoading(false);
        }
    }
    function languageFormalClientExceptionEdited(newValue) {
        const exception = props.clientData.value.client[44] === 0 ? true : false;
        if (exception != newValue) {
            setLanguageFormalClientExcecaoEdited(true);
        } else {
            setLanguageFormalClientExcecaoEdited(false);
        }
    }

    function languageInformalClientExceptionEdited(newValue) {
        const exception = props.clientData.value.client[44] === 1 ? true : false;
        if (exception != newValue) {
            setLanguageInformalClientExcecaoEdited(true);
        } else {
            setLanguageInformalClientExcecaoEdited(false);
        }
    }

    function contactClientExceptionEdited(newValue) {
        const exception = props.clientData.value.client[28] === "Sim" ? true : false;
        if (exception != newValue) {
            setContactClientExcecaoEdited(true);
        } else {
            setContactClientExcecaoEdited(false);
        }
    }

    function nonContactClientExceptionEdited(newValue) {
        const exception = props.clientData.value.client[29] === "Sim" ? true : false;
        if (exception != newValue) {
            setNonContactClientExcecaoEdited(true);
        } else {
            setNonContactClientExcecaoEdited(false);
        }
    }


    async function submitHandleSubmitSegClientDigitalException() {
        setLoading(true);
        try {
            const client = await api.put(
                `/api/v1/vba/update/${String(data[0])}/seg_client_digital_exception/${segClientDigitalExcecao ? 0 : 1}`,
                {
                    client_id: parseInt(data[0]),
                },
                {
                    headers: {
                        authorization: `Bearer ${tokenKey}`,
                    },
                }
            );
            setSegClientDigitalExcecaoEdited(false);
            setLoading(false);
            setEdited(true);
        } catch (error) {
            alert(error?.response?.data?.message);
            setLoading(false);
        }
    }

    async function submitHandleSubmitSegClientPrivateException() {
        setLoading(true);
        // console.log('segClientPrivateExcecao', segClientPrivateExcecao ? 1 : 0)
        try {
            const client = await api.put(
                `/api/v1/vba/update/${String(data[0])}/seg_client_private_exception/${segClientPrivateExcecao ? 1 : 0}`,
                {
                    client_id: parseInt(data[0]),
                },
                {
                    headers: {
                        authorization: `Bearer ${tokenKey}`,
                    },
                }
            );
            // console.log('client', client)
            setSegClientPrivateExcecaoEdited(false);
            setLoading(false);
            setEdited(true);
        } catch (error) {
            alert(error?.response?.data?.message);
            setLoading(false);
        }
    }

    async function submitHandleSubmitContactClientException() {
        setLoading(true);
        // console.log('contactClientExcecao', contactClientExcecao)
        try {
            const client = await api.put(
                `api/v1/vba/update/${String(data[0])}/contact_client_exception/${contactClientExcecao ? true : false}`,
                {
                    client_id: parseInt(data[0]),
                },
                {
                    headers: {
                        authorization: `Bearer ${tokenKey}`,
                    },
                }
            );
            // console.log('client', client)
            alert("Cliente " + titleize(data[1]) + " atualizado com sucesso!");
            setContactClientExcecaoEdited(false);
            setLoading(false);
        } catch (error) {
            alert(error?.response?.data?.message);
            setLoading(false);
        }
    }

    async function submitHandleSubmitNonContactClientException() {
        setLoading(true);
        // console.log('nonContactClientExcecao', nonContactClientExcecao)
        try {
            const client = await api.put(
                `api/v1/vba/update/${String(data[0])}/non_contact_client_exception/${nonContactClientExcecao ? false : true}`,
                {
                    client_id: parseInt(data[0]),
                },
                {
                    headers: {
                        authorization: `Bearer ${tokenKey}`,
                    },
                }
            );
            alert("Cliente " + titleize(data[1]) + " atualizado com sucesso!");
            setNonContactClientExcecaoEdited(false);
            setLoading(false);
        } catch (error) {
            alert(error?.response?.data?.message);
            setLoading(false);
        }
    }

    async function submitHandleSubmitHBService() {
        // setLoading(true);
        try {
            const client = await api.put(
                `/api/v1/vba/updateClient/${String(data[0])}/hb_service/${servicoHB}`,
                {
                    client_id: parseInt(data[0]),
                },
                {
                    headers: {
                        authorization: `Bearer ${tokenKey}`,
                    },
                }
            );
            alert("Cliente " + titleize(data[1]) + " atualizado com sucesso!");
            setServicoHBEdited(false);
            setRootervicoHB(servicoHB);
            setLoading(false);
        } catch (error) {
            alert(error?.response?.data?.message);
            setLoading(false);
        }
    }

    async function submitEvasion() {
        setLoading(false);
        if (comment !== null && comment != "") {
            try {
                const clientUpdate = await api.post(
                    `/api/v1/vba/setEvasion/${String(data[0])}`,
                    {
                        comment: comment,
                    },
                    {
                        headers: {
                            authorization: `Bearer ${tokenKey}`,
                        },
                    }
                );

                setType({ name: "successClientEdit", type: "information", value: { message: `${clientUpdate.data.message}` + " - " + ` ${titleize(data[1])}` } });
                setLoading(false);
                setEdited(true);
            } catch (error) {
                alert(error?.response?.data?.message);
                setLoading(false);
            }
        } else {
            alert("Você precisa deixar um comentário do motivo de marcar o cliente como evasão");
        }
        setLoading(false);
    }

    async function updateFrequency() {
        if (selectedPeriodicidadeContato) {
            try {
                const clientUpdate = await api.put(
                    `/api/v1/vba/update/${String(data[0])}/frequency/${selectedPeriodicidadeContato}`,
                    {},
                    {
                        headers: {
                            authorization: `Bearer ${tokenKey}`,
                        },
                    }
                );
                alert("Periodicidade preferencial de contato do cliente " + titleize(data[1]) + " atualizado com sucesso!");
                setLoading(false);
                setEdited(true);
                clear();
            } catch (error) {
                alert(error?.response?.data?.message);
                setLoading(false);
            }
        }
    }

    async function updateServiceModel() {
        // console.log('selectedServiceModel', selectedServiceModel)
        if (selectedServiceModel) {
            try {
                const clientUpdate = await api.put(
                    `/api/v1/vba/update/${String(data[0])}/serviceModel/${selectedServiceModel}`,
                    {},
                    {
                        headers: {
                            authorization: `Bearer ${tokenKey}`,
                        },
                    }
                );
                // console.log('clientUpdate', clientUpdate)
                alert("Modelo de serviço do cliente " + titleize(data[1]) + " atualizado com sucesso!");
                setLoading(false);
                setEdited(true);
                clear();
            } catch (error) {
                alert(error?.response?.data?.message);
                setLoading(false);
            }
        }
    }

    async function updatePreferredContact() {
        if (selectedTipoPreferencialContato) {
            try {
                const clientUpdate = await api.put(
                    `/api/v1/vba/update/${String(data[0])}/preference/${selectedTipoPreferencialContato}`,
                    {},
                    {
                        headers: {
                            authorization: `Bearer ${tokenKey}`,
                        },
                    }
                );
                alert("Tipo de contato preferencial do cliente " + titleize(data[1]) + " atualizado com sucesso!");
                setLoading(false);
                setEdited(true);
                clear();
            } catch (error) {
                alert(error?.response?.data?.message);
                setLoading(false);
            }
        }
    }

    async function updateOperatesAlone() {
        if (selectedOperatesAlone) {
            try {
                const clientUpdate = await api.put(
                    `/api/v1/vba/updateClient/${String(data[0])}/operates_alone/${selectedOperatesAlone}`,
                    {},
                    {
                        headers: {
                            authorization: `Bearer ${tokenKey}`,
                        },
                    }
                );
                alert("Cliente " + titleize(data[1]) + " atualizado com sucesso!");
                setLoading(false);
                setEdited(true);
                clear();
            } catch (error) {
                alert(error?.response?.data?.message);
                setLoading(false);
            }
        }
    }

    async function updateBrokerRelationship() {
        if (selectedBrokerRelationship) {
            try {
                const clientUpdate = await api.put(
                    `/api/v1/vba/updateClient/${String(data[0])}/broker_relationship/${selectedBrokerRelationship}`,
                    {},
                    {
                        headers: {
                            authorization: `Bearer ${tokenKey}`,
                        },
                    }
                );
                alert("Cliente " + titleize(data[1]) + " atualizado com sucesso!");
                setLoading(false);
                setEdited(true);
                clear();
            } catch (error) {
                alert(error?.response?.data?.message);
                setLoading(false);
            }
        }
    }

    async function updateAdmAcount() {
        if (admAcount) {
            try {
                const clientUpdate = await api.put(
                    `/api/v1/vba/updateClient/${String(data[0])}/account_adm/${admAcount}`,
                    {},
                    {
                        headers: {
                            authorization: `Bearer ${tokenKey}`,
                        },
                    }
                );
                alert("Cliente " + titleize(data[1]) + " atualizado com sucesso!");
                setLoading(false);
                setEdited(true);
                setToggleAdmAcount(false)
                clear();
            } catch (error) {
                alert(error?.response?.data?.message);
                setLoading(false);
            }
        }
    }

    async function resetData() {
        await setAllItems([]);
        await setItems([]);
        await setItemQty(30);
    }

    async function filterTimeline(all) {
        var todosItems = all ? all : structuredClone(allItems)
        var filteredItemsAux = []
        if(filters.length > 0){
            filteredItemsAux = todosItems.filter((e) => filters.indexOf(parseInt(e.type)) >= 0)
        }
        else{
            filteredItemsAux = todosItems
        }
        await setFilteredItems(filteredItemsAux)
        await setItems(filteredItemsAux.length - 30 < 0 ? filteredItemsAux : filteredItemsAux.slice(filteredItemsAux.length - 30))
    
    }

    async function getTimeline() {
        await setLoadingTimeline(true);
        try {
            const timeline = await api.get(`/api/v1/vba/clientTimeline/${String(props.clientData.value.client[0])}`, {
                headers: {
                    authorization: `Bearer ${tokenKey}`,
                },
            });
            
            await setAllItems(timeline?.data?.data);
            // await setFilteredItems(timeline?.data?.data);
            await filterTimeline(timeline?.data?.data)
            // await setItems(timeline?.data?.data.length - itemQty < 0 ? timeline?.data?.data : timeline?.data?.data.slice(timeline?.data?.data.length - itemQty));
            
            await setLoadingTimeline(false);
            
        } catch (error) {
            alert(error?.response?.data?.message);
        }
    }
    useEffect(() => {
        async function getBrokers() {
            try {
                const brokersResult = await api.get(`/api/v1/vba/availableBrokers/${props.clientData.value.client[15]}`, {
                    headers: {
                        authorization: `Bearer ${tokenKey}`,
                    },
                });
                // console.log('brokersResult', brokersResult)
                let defaultBruki = brokersResult.data.data.default[0]?.broker_id ? brokersResult.data.data.default[0]?.broker_id : "";
                setDefaultBroker(defaultBruki);
                const aux = defaultBruki == props.clientData.value.client[20] ? brokersResult.data.data.brokers.filter((x) => x.external_id != defaultBruki) : brokersResult.data.data.brokers.sort((x, y) => (x.external_id == defaultBruki ? -1 : y.external_id == defaultBruki ? 1 : 0));
                setBrokerListDigital(aux.filter((x) => x.profile_id == 2));
                setBrokerListPrivate(aux.filter((x) => x.profile_id == 3));
                setBrokerListAll(aux);
                setLoading(false);
            } catch (error) {
                console.log(`/api/v1/vba/availableBrokers/${props.clientData.value.client[12]}`);
                alert("brokers não encontrados", error?.response?.data?.message);
            }
        }

        if (!show) {
            clear();
            return;
        }
        getBrokers();
        setAdmAcount(props.clientData.value.client[6] ? props.clientData.value.client[6] : ' ')
        setClientExcecao(props.clientData.value.client[29] == "Sim" ? true : false);
        setSegClientDigitalExcecao(props.clientData.value.client[38] == "Digital" ? true : false);
        setSegClientPrivateExcecao(props.clientData.value.client[38] == "Private" ? true : false);
        setServicoHB(props.clientData.value.client[39] == "Sim" ? true : false);
        setSelectedTipoPreferencialContato(props.clientData.value.client[34] ? props.clientData.value.client[34] : 0);
        setSelectedPeriodicidadeContato(props.clientData.value.client[37] ? props.clientData.value.client[37] : 0);
        setRootervicoHB(props.clientData.value.client[39] == "Sim" ? true : false);
        setClientExcecaoEdited(false);
        setSegClientDigitalExcecaoEdited(false);
        setSegClientPrivateExcecaoEdited(false);
        setServicoHBEdited(false);
        setContactClientExcecao(props.clientData.value.client[28] == "Sim" ? true : false);
        setNonContactClientExcecao(props.clientData.value.client[29] == "Sim" ? true : false);
        setLanguageFormalClientExcecao(props.clientData.value.client[44] == 'Formal' ? true : false);
        setLanguageInformalClientExcecao(props.clientData.value.client[44] == 'Informal' ? true : false);
        setComment('');
        setFilters([]);

        getTimeline();
    }, [show]);

    async function handleSubmitComment() {
        setLoadingTimeline(true);
        if (comentTimeline !== null && comentTimeline != "") {
            try {
                const clientUpdate = await api.post(
                    `/api/v1/vba/sendComment/${String(data[0])}`,
                    {
                        comment: comentTimeline.replaceAll("'", "''"),
                        commentType: contactType,
                    },
                    {
                        headers: {
                            authorization: `Bearer ${tokenKey}`,
                        },
                    }
                );
                setComentTimeline("");
                setContactType(5);
                getTimeline();
            } catch (error) {
                alert(error?.response?.data?.message);
            }
        } else {
            alert("O comentário nao pode ser vazio");
        }
        setLoadingTimeline(false);
    }

    async function updateItemsList() {
        var auxAllItems = structuredClone(filteredItems);
        var objDiv = document.getElementById("timelineComentBox");
        var scrollNext = objDiv?.scrollHeight;
        if (itemQty != auxAllItems.length) {
            if (auxAllItems.length - (itemQty + 30) <= 0) {
                await setItems(auxAllItems);
                await setItemQty(auxAllItems.length);
            } else {
                await setItems(auxAllItems.slice(auxAllItems.length - (itemQty + 30)));
                await setItemQty(itemQty + 30);
            }
            objDiv.scrollTop = objDiv?.scrollHeight - scrollNext;
        }
    }

    

    async function showClientActivation(type) {
        // type - função
        // 1 - ativação inicial do cliente
        // 2 - vizualização do formulário de ativação já preenchido
        // 3 - vizualização e edição de formulário para transferência
        // 4 - cliente transferido, o broker precisa visualizar para ativá-lo
        // 5 - edição de formulário atualmente vigente
        setShow(true);
        setType({ name: "showVbaClientActivation", value: props?.clientData?.value?.client, type: type, handleSubmit: handleSubmit });
    }

    function clientExceptionEdited(newValue) {
        const exception = props.clientData.value.client[29] === "Sim" ? true : false;
        if (exception != newValue) {
            setClientExcecaoEdited(true);
        } else {
            setClientExcecaoEdited(false);
        }
    }
    function segClientDigitalExceptionEdited(newValue) {
        const exception = props.clientData.value.client[34] === 0 ? true : false;
        if (exception != newValue) {
            setSegClientDigitalExcecaoEdited(true);
        } else {
            setSegClientDigitalExcecaoEdited(false);
        }
    }
    function segClientPrivateExceptionEdited(newValue) {
        const exception = props.clientData.value.client[34] === 1 ? true : false;
        if (exception != newValue) {
            setSegClientPrivateExcecaoEdited(true);
        } else {
            setSegClientPrivateExcecaoEdited(false);
        }
    }

    function clientServiceHBEdited(newValue) {
        if (rootervicoHB != newValue) {
            setServicoHBEdited(true);
        } else {
            setServicoHBEdited(false);
        }
    }

    // Verifica se o cliente possui mais de 50k em rv e assessor b2c (profile_id = 4).
    // Caso se enquadre, solicita preenchimento de formulário de primeira reunião.
    // Caso contrario, transfere o cliente diretamente
    function verifyClientSegment() {
        // // props.clientData.value.client[22] // pos_rv
        // // props.clientData.value.client[27] // perfil do assessor
        var pos_rv = parseFloat(props.clientData.value.client[22].replace("R$", "").replaceAll(".", "").replace(",", "."));
        if (props.clientData.value.client[27] == 4 && pos_rv < 50000) {
            handleSubmit(0);
        } else {
            showClientActivation(3);
        }
    }

    async function handleChangeStatusClient(status) {
        try {
            const changeStatusResult = await api.put(`api/v1/clients/${data[0]}/status`, {
                'active': status
            }, {
                headers: {
                    authorization: `Bearer ${tokenKey}`
                }
            })
            alert('Status do cliente alterado para Stand By');
            setModalStandyByConfirm(false)
            setEdited(true)
            setShow(false)

        } catch (error) {
            setType({ name: 'alertMsg', type: 'information', value: { message: error?.response?.data?.message } });
            setShow(true);
        }
    }

    return (
        <div className="clientDetailsContainer">
            {loading ? (
                <div id="clientDetailsLoader">
                    <ArrowLoader />
                </div>
            ) : successEdit ? (
                <div className="clientDetailsSuccessEdit">
                    <a>Sucesso ao editar o broker</a>
                    <button
                        onClick={() => {
                            setSuccessEdit(false);
                            setType({ name: "successUpdateClient" });
                            clear();
                            setShow(false);
                        }}>
                        Ok
                    </button>
                </div>
            ) : (
                <>  
                {modalStandyByConfirm ?
                <>
                        <div className='clientStandByContainer'>
                            <div className='clientsDetailsHeader'>
                            <div>
                                <p>{data[0]} - {titleize(data[1])}</p>
                            </div>
                            </div>
                            <div className='standByStatusBody'>
                                <div className='messageAlertVba'>Afirmo que o assessor solicitou para não realizar contato com o cliente.</div>
                                <div className='buttonsStandByAlert'>
                                    <button 
                                        className="activateClientButton"
                                        onClick={() => {
                                            handleChangeStatusClient(7)
                                        }}>
                                        Não contatar
                                    </button>
                                    <button 
                                        className="activateClientButton"
                                        onClick={() => {
                                            setModalStandyByConfirm(false);
                                        }}>
                                        Voltar
                                    </button>
                                </div>
                            </div>
                    </div>
                        <div className="modalBackgroundShow2"></div>
                </>
                        : <></>}
                    <div className="clientsDetailsHeader">
                        <div>
                            <p>Detalhes do Cliente:</p>
                            <p>
                                {data[0]} - {titleize(data[1])}
                            </p>
                        </div>
                        <button
                            onClick={() => {
                                clear();
                                setShow(false);
                                resetData();
                            }}>
                            x
                        </button>
                    </div>
                    {/* <Typography id='clientDetailsEditButton' component="div">
                                <Grid component="label" container alignItems="center" spacing={1}>
                                    <Grid item>
                                        <AntSwitch className={classes.switch} checked={edit} onChange={() => setEdit(!edit)} name="checkedA" />
                                    </Grid>
                                    <Grid item>Editar</Grid>
                                </Grid>
                            </Typography> */}
                    {/* <div id='clientDetailsDeleteClient' onClick={()=>handleDeleteClient()}>
                                <FiTrash2 />
                                <a>Excluir cliente</a>
                            </div> */}
                    <div className="clientDetailsBodycontent">
                        <div className="containerDetalhesEspecificos">
                            <span className="subTitle">Cliente:</span>
                            <div className="clientDetails">
                                <span className="itemDetail">
                                    <a id="clientDetailFirstAElementVBA">Nome: </a>
                                    <a>{titleize(data[1])}</a>
                                </span>
                                <span className="itemDetail">
                                    <a id="clientDetailFirstAElementVBA">Email: </a>
                                    <a>{data[2]}</a>
                                </span>
                                <span className="itemDetail">
                                    <a id="clientDetailFirstAElementVBA">Net RV: </a>
                                    <a>{data[23]}</a>
                                </span>
                                <span className="itemDetail">
                                    <a id="clientDetailFirstAElementVBA">Perfil: </a>
                                    <a>{titleize(data[3])}</a>
                                </span>
                                <span className="itemDetail">
                                    <a id="clientDetailFirstAElementVBA">Status Receita: </a>
                                    <a>{titleize(data[12])}</a>
                                </span>
                                <span className="itemDetail">
                                    <button
                                        className="botaoEvasaoVBA"
                                        onClick={() => {
                                            showClientActivation(5);
                                        }}>
                                        Editar formulário de primeira reunião
                                        <BsBoxArrowUpLeft style={{ marginLeft: "0.4rem" }} size={14} />
                                    </button>
                                </span>
                                <span className="itemDetail">
                                    <a id="clientDetailFirstAElementVBA">Tipo de contato preferencial: </a>
                                    <select
                                        className="selectClientDetails"
                                        onChange={(e) => {
                                            setToggleTipoPreferencialContato(true);
                                            setSelectedTipoPreferencialContato(e.target.value);
                                        }}>
                                        {props?.clientData?.value?.client[34] == null ? (
                                            <option selected value={0}>
                                                Pendente
                                            </option>
                                        ) : (
                                            <></>
                                        )}
                                        {preferency.map((x) => {
                                            if (props?.clientData?.value?.client[34] == x.value) {
                                                return (
                                                    <option selected value={x.value}>
                                                        {x.description}
                                                    </option>
                                                );
                                                // <option selected value={props?.clientData?.value?.client[37] ? props?.clientData?.value?.client[37] : 0} >{props?.clientData?.value?.client[38] ? props?.clientData?.value?.client[38] : 'Pendente'}</option>
                                            } else {
                                                return <option value={x.value}>{x.description}</option>;
                                            }
                                        })}
                                    </select>
                                    {toggleTipoPreferencialContato ? (
                                        <>
                                            <button
                                                className={toggleTipoPreferencialContato ? "selectClientDetailsButton" : "selectClientDetailsButtonDISABLED"}
                                                disabled={!toggleTipoPreferencialContato}
                                                onClick={() => {
                                                    setToggleTipoPreferencialContato(false);
                                                    setSelectedTipoPreferencialContato(props.clientData.value.client[34] ? props.clientData.value.client[34] : 0);
                                                }}>
                                                Cancelar
                                            </button>
                                            <button
                                                className={toggleTipoPreferencialContato ? "selectClientDetailsButton" : "selectClientDetailsButtonDISABLED"}
                                                disabled={!toggleTipoPreferencialContato}
                                                onClick={() => {
                                                    setToggleTipoPreferencialContato(false);
                                                    setRootTipoPreferencialContato(selectedTipoPreferencialContato);
                                                    updatePreferredContact();
                                                }}>
                                                Ok
                                            </button>
                                        </>
                                    ) : (
                                        <></>
                                    )}
                                </span>
                                {/* <span className="itemDetail">
                                    <a id="clientDetailFirstAElementVBA">Periodicidade de contato: </a>
                                    <select
                                        className="selectClientDetails"
                                        onChange={(e) => {
                                            setTogglePeriodicidadeContato(true);
                                            setSelectedPeriodicidadeContato(e.target.value);
                                        }}>
                                        {props?.clientData?.value?.client[37] == null ? (
                                            <option selected value={0}>
                                                Pendente
                                            </option>
                                        ) : (
                                            <></>
                                        )}
                                        {frequency.map((x) => {
                                            if (props?.clientData?.value?.client[37] == x.value) {
                                                return (
                                                    <option selected value={x.value}>
                                                        {x.description}
                                                    </option>
                                                );
                                            } else {
                                                return <option value={x.value}>{x.description}</option>;
                                            }
                                        })}
                                    </select>
                                    {togglePeriodicidadeContato ? (
                                        <>
                                            <button
                                                className={togglePeriodicidadeContato ? "selectClientDetailsButton" : "selectClientDetailsButtonDISABLED"}
                                                disabled={!togglePeriodicidadeContato}
                                                onClick={() => {
                                                    setTogglePeriodicidadeContato(false);
                                                    setSelectedPeriodicidadeContato(rootPeriodicidadeContato);
                                                }}>
                                                Cancelar
                                            </button>
                                            <button
                                                className={togglePeriodicidadeContato ? "selectClientDetailsButton" : "selectClientDetailsButtonDISABLED"}
                                                disabled={!togglePeriodicidadeContato}
                                                onClick={() => {
                                                    setTogglePeriodicidadeContato(false);
                                                    setRootPeriodicidadeContato(selectedPeriodicidadeContato);
                                                    updateFrequency();
                                                }}>
                                                Ok
                                            </button>
                                        </>
                                    ) : (
                                        <></>
                                    )}
                                </span> */}
                                <span className="itemDetail">
                                    <a id="clientDetailFirstAElementVBA">Modelo de Atendimento: </a>
                                    <select
                                        className="selectClientDetails"
                                        onChange={(e) => {
                                            // console.log('e.target.value', e)
                                            setToggleServiceModel(true);
                                            setSelectedServiceModel(e.target.value);
                                        }}>
                                        {props?.clientData?.value?.client[39] == null ? (
                                            <option selected >
                                                Pendente
                                            </option>
                                        ) : (
                                            <></>
                                        )}
                                        {serviceModel.map((x) => {
                                            if (props?.clientData?.value?.client[39] == x.description) {
                                                return (
                                                    <option selected value={x.value}>
                                                        {x.description}
                                                    </option>
                                                );
                                            } else {
                                                return <option value={x.value }>{x.description}</option>;
                                            }
                                        })}
                                    </select>
                                    {toggleServiceModel ? (
                                        <>
                                            <button
                                                className={toggleServiceModel ? "selectClientDetailsButton" : "selectClientDetailsButtonDISABLED"}
                                                disabled={!toggleServiceModel}
                                                onClick={() => {
                                                    setToggleServiceModel(false);
                                                    setSelectedServiceModel(props.clientData.value.client[39] ? props.clientData.value.client[39] : 0);
                                                }}>
                                                Cancelar
                                            </button>
                                            <button
                                                className={toggleServiceModel ? "selectClientDetailsButton" : "selectClientDetailsButtonDISABLED"}
                                                disabled={!toggleServiceModel}
                                                onClick={() => {
                                                    // console.log('selectedServiceModel', selectedServiceModel)
                                                    // console.log('rootServiceModel', rootServiceModel)
                                                    setToggleServiceModel(false);
                                                    setSelectedServiceModel(rootServiceModel);
                                                    updateServiceModel();
                                                }}>
                                                Ok
                                            </button>
                                        </>
                                    ) : (
                                        <></>
                                    )}
                                </span>

                                <span className="itemDetail">
                                    <a id="clientDetailFirstAElementVBA">Status Relacionamento: </a>
                                    
                                    <a>{titleize(data[34])}</a>
                                </span>
                            </div>

                            <span className="subTitle">Assessor:</span>
                            <div className="clientDetails">
                                <div className="clientDetailDualDetail">
                                    <span className="itemDetail">
                                        <a id="clientDetailFirstAElementVBA">Nome: </a>
                                        <a>{titleize(data[10])}</a>
                                    </span>

                                    <span className="itemDetail">
                                        <a id="clientDetailFirstAElementVBA">Filial: </a>
                                        <a>{titleize(data[11])}</a>
                                    </span>

                                    <span className="itemDetail" style={{ gridColumnStart: 1, gridColumnEnd: 3 }}>
                                        <a id="clientDetailFirstAElementVBA">Time: </a>
                                        <a>{titleize(data[14])}</a>
                                    </span>
                                </div>
                            </div>

                            <span className="subTitle">Broker:</span>
                            <div className="clientDetails2">
                                <div className="clientDetailDualDetail2">
                                <span className="itemDetail">
                                    <a id="clientDetailFirstAElementVBA">Segmento Cliente: </a> <br />

                                    <a id="clientDetailSecondtAElementVBA">Digital: </a>
                                    <input
                                        defaultChecked={props.clientData.value.client[38] == 'Digital' ? "checked" : ""}
                                        checked={segClientDigitalExcecao ? "checked" : ""}
                                        type="checkbox"
                                        id="check"
                                        name="check"
                                        className="checkboxVBAExceptionClient"
                                        onChange={(e) => {
                                            setSegClientDigitalExcecao(true);
                                            segClientDigitalExceptionEdited(true);
                                            if (e.target.checked) {
                                                setSegClientPrivateExcecao(false);
                                                setSegClientPrivateExcecaoEdited(false);
                                            }
                                            alert('Você está mudando o segmento de atendimento deste cliente. Para concluir, selecione um broker na lista. Dê preferência ao broker referência.');
                                        }}></input>
                                    <a id="clientDetailSecondtAElementVBA">Private: </a>
                                    <input
                                        defaultChecked={props.clientData.value.client[38] == 'Private' ? "checked" : ""}
                                        checked={segClientPrivateExcecao ? "checked" : ""}
                                        type="checkbox"
                                        id="check"
                                        name="check"
                                        className="checkboxVBAExceptionClient"
                                        onChange={(e) => {
                                            setSegClientPrivateExcecao(true);
                                            segClientPrivateExceptionEdited(true);
                                            if (e.target.checked) {
                                                setSegClientDigitalExcecao(false);
                                                setSegClientDigitalExcecaoEdited(false);
                                            }
                                            alert('Você está mudando o segmento de atendimento deste cliente. Para concluir, selecione um broker na lista. Dê preferência ao broker referência.');
                                        }}></input>

                                </span> 
                                    <span className="itemDetail" style={{ marginTop: "0.8rem", marginRight: "1rem" }}>
                                        {data[20] !== null ? (
                                            <>
                                                <a id="clientDetailFirstAElementVBA">Nome: </a>
                                                <a>{titleize(data[16])}</a>
                                            </>
                                        ) : (
                                            <a className="itemNull">{titleize("sem broker associado")}</a>
                                        )}
                                    </span>

                                    <span className="itemDetail">
                                        {data[25] !== null || user.type < 3 ? (
                                            <Autocomplete
                                                ref={autocompleteRef}
                                                options={segClientDigitalExcecao ? brokerListDigital.filter((x) => x.profile_id == 2) : segClientPrivateExcecao ? brokerListPrivate.filter((x) => x.profile_id == 3) : brokerListDigital}
                                                // onFocus={(e) => e.target.click()}
                                                hasPopupIcon={false}
                                                onChange={(event, newValue) => {
                                                    setSelectedBroker(newValue ? newValue.external_id : null);
                                                }}
                                                className={"selectBroker"}
                                                getOptionLabel={(option) => option.external_id + " - " + titleize(option.full_name) + (option.external_id == defaultBroker ? " (referência)" : "")}
                                                id="client"
                                                renderInput={(params) => (
                                                    <TextField
                                                        // value = {searchClientValue}
                                                        label="Transferir para:"
                                                        required={false}
                                                        placeholder="Selecione:"
                                                        {...params}
                                                        onChange={(e) => {
                                                            // handleChange(e)
                                                        }}
                                                    />
                                                )}
                                                renderOption={(props) => {
                                                    return (
                                                        <span {...props} className={props.external_id == defaultBroker ? "defaultBroker" : ""} title="Este é o broker referência do assessor deste cliente.">
                                                            {console.log('props', props)}
                                                            {props.external_id + " - " + titleize(props.full_name) + (props.external_id == defaultBroker ? " (referência)" : "")}
                                                        </span>
                                                    );
                                                }}
                                            />
                                        ) : user.type === 3 && data[20] === null ? (
                                            <>
                                                <input
                                                    type="Checkbox"
                                                    className="checkBoxVBA"
                                                    name="solicitar"
                                                    onChange={(e) => {
                                                        setSelectedBroker(e.target.checked ? user.external_id : null);
                                                    }}
                                                />
                                                <a className="getClient">Gostaria de aderir esse cliente à minha base </a>
                                            </>
                                        ) : (
                                            <></>
                                        )}
                                    </span>
                                    <span className="itemDetail" style={{ gridColumnStart: 1, gridColumnEnd: 3, marginLeft: "auto", marginTop: "0.6rem", marginBottom: "0.6rem" }}>
                                        {/* {(selectedBroker !== null) ? <button className='botaoSolicitarVBA' onClick={() => {handleSubmit()}}>Solicitar</button> : <></>} */}
                                        {selectedBroker !== null ? (
                                            <button
                                                className="botaoSolicitarVBA"
                                                onClick={() => {
                                                    verifyClientSegment();
                                                    segClientDigitalExcecao ? submitHandleSubmitSegClientDigitalException() : segClientPrivateExcecao ? submitHandleSubmitSegClientPrivateException() : <></>;
                                                }}>
                                                Solicitar
                                            </button>
                                        ) : (
                                            <></>
                                        )}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="verticalBarVbaClients"></div>
                        <div className="containerDetalhesEspecificos" style={{marginTop:'2rem'}}>
                            <div className="clientDetails" style={{marginTop:'1rem'}}>
                            <span className="itemDetail">
                        <a id="clientDetailFirstAElementVBA">Apelido Administrador da conta: </a>
                        <input
                            type="text"
                            className="campoTextoAtivoAdm"
                            value={admAcount}
                            onChange={(e) => {
                                if (e.target.value === props.prop?.clientData[6]) {
                                    setToggleAdmAcount(false);
                                    setAdmAcount(e.target.value);
                                } else {
                                    setToggleAdmAcount(true);
                                    setAdmAcount(e.target.value);
                                }
                            }}
                        />
                        {toggleAdmAcount ? (
                            <>
                                <button
                                    className={toggleAdmAcount ? "selectClientDetailsButton" : "selectClientDetailsButtonDISABLED"}
                                    disabled={!toggleAdmAcount}
                                    onClick={() => {
                                        setToggleAdmAcount(false);
                                        setAdmAcount(props.prop?.clientData[9] ? props.prop?.clientData[9] : '-');
                                    }}>
                                    Cancelar
                                </button>
                                <button
                                    className={toggleAdmAcount ? "selectClientDetailsButton" : "selectClientDetailsButtonDISABLED"}
                                    disabled={!toggleAdmAcount}
                                    onClick={() => {
                                        setToggleAdmAcount(false);
                                        updateAdmAcount();
                                    }}>
                                    Ok
                                </button>
                            </>
                        ) : (
                            <></>
                        )}
                    </span>
                                <span className="itemDetail">
                                    <a id="clientDetailFirstAElementVBA">Contatar?: </a>
                                    <a id="clientDetailSecondtAElementVBA">Sim: </a>
                                    <input
                                        defaultChecked={props.clientData.value.client[41] == 'Sim' ? "checked" : ""}
                                        checked={contactClientExcecao ? "checked" : ""}
                                        type="checkbox"
                                        id="check"
                                        name="check"
                                        className="checkboxVBAExceptionClient"
                                        onChange={(e) => {
                                            setContactClientExcecao(e.target.checked);
                                            contactClientExceptionEdited(e.target.checked);
                                            if (e.target.checked) {
                                                setNonContactClientExcecao(false);
                                                setNonContactClientExcecaoEdited(false);
                                            }
                                        }}></input>
                                    <a id="clientDetailSecondtAElementVBA">Não: </a>
                                    <input
                                        defaultChecked={props.clientData.value.client[41] == 'Não' ? "checked" : ""}
                                        checked={nonContactClientExcecao ? "checked" : ""}
                                        type="checkbox"
                                        id="check"
                                        name="check"
                                        className="checkboxVBAExceptionClient"
                                        onChange={(e) => {
                                            setNonContactClientExcecao(e.target.checked);
                                            nonContactClientExceptionEdited(e.target.checked);
                                            if (e.target.checked) {
                                                setContactClientExcecao(false);
                                                setContactClientExcecaoEdited(false);
                                            }
                                        }}></input>

                                    {/* {contactClientExcecaoEdited ? (
                                        <button
                                            className="botaoSubmitExcecaoVBA"
                                            onClick={() => {
                                                submitHandleSubmitContactClientException();
                                            }}>
                                            Confirmar
                                        </button>
                                    ) : (
                                        nonContactClientExcecaoEdited ? (
                                            <button
                                                className="botaoSubmitExcecaoVBA"
                                                onClick={() => {
                                                    submitHandleSubmitNonContactClientException();
                                                }}>
                                                Confirmar
                                            </button>
                                        ) : (
                                            <></>
                                        )
                                    )} */}
                                </span>
                                <span className="itemDetail">
                                    <a id="clientDetailFirstAElementVBA">Evasão: </a>
                                    <select style={{marginBottom: '0.5rem'}}
                                        className="selectClientDetails"
                                        onChange={(e) => {
                                            // console.log('e.target.value', e)
                                            setToggleEvasion(true);
                                            setSelectedEvasion(e.target.value);
                                        }}>
                                        {props?.clientData?.value?.client[40] == null ? (
                                            <option selected >
                                                Pendente
                                            </option>
                                        ) : (
                                            <></>
                                        )}
                                        {evasao.map((x) => {
                                            if (props?.clientData?.value?.client[40] == x.description) {
                                                return (
                                                    <>
                                                    <option selected value={x.value}>
                                                        {x.description}
                                                    </option>
                                                    
                                                    </>

                                                );
                                            } else {
                                                return <option value={x.value }>{x.description}</option>;
                                            }
                                        })}
                                    </select>
                                    {selectedEvasion == 5 || props?.clientData[40]?.value?.client[40] == 'Outros' ? (
                                        <textarea
                                            className="campoTextoAtivoAdm"
                                            value={textInner}
                                            style={{ maxHeight: "9rem", resize: "none", width: "90%", padding: "0.5rem", marginTop: '0.5rem'}}
                                            onKeyUp={(e) => {
                                                e.target.style.height = "inherit";
                                                e.target.style.height = `${e.target.scrollHeight}px`;
                                            }}
                                            onChange={(e) => {
                                                if (e.target.value === props?.clientData?.value?.client[40]) {
                                                    setToggleEvasion(false);
                                                    setTextInner(e.target.value);
                                                } else {
                                                    setToggleEvasion(true);
                                                    setTextInner(e.target.value);
                                                }
                                            }}
                                        />
                                    ) : (
                                        <></>
                                    )}
                                    {toggleEvasion ? (
                                        <div style={{display: 'flex'}}>
                                            <button
                                                className={toggleEvasion ? "selectClientDetailsButton" : "selectClientDetailsButtonDISABLED"}
                                                disabled={!toggleEvasion}
                                                onClick={() => {
                                                    setToggleEvasion(false);
                                                    setSelectedEvasion(rootEvasion);
                                                }}>
                                                Cancelar
                                            </button>
                                            <button
                                                className={toggleEvasion ? "selectClientDetailsButton" : "selectClientDetailsButtonDISABLED"}
                                                disabled={!toggleEvasion}
                                                onClick={() => {
                                                    // console.log('selectedEvasion', selectedEvasion)
                                                    // console.log('rootEvasion', rootEvasion)
                                                    setToggleEvasion(false);
                                                    setSelectedEvasion(rootEvasion);
                                                    updateEvasion();

                                                    if(selectedEvasion == 3){

                                                        setRootBrokerRelationship('Evasão')
                                                        setSelectedBrokerRelationship('Evasão')
                                                        updateBrokerRelationship();
                                                    }
                                                }}>
                                                Marcar como evasão
                                            </button>
                                        </div>
                                    ) : (
                                        <></>
                                    )}
                                </span>
                                <span className="itemDetail">
                                    <a id="clientDetailFirstAElementVBA">Preferências de ativos: </a> <br />
                                    <textarea
                                        className="campoTextoAtivoAdm"
                                        value={preferrendAtivos}
                                        style={{ maxHeight: "9rem", resize: "none", width: "90%", padding: "0.5rem"}}
                                        onKeyUp={(e) => {
                                            e.target.style.height = "inherit";
                                            e.target.style.height = `${e.target.scrollHeight}px`;
                                        }}
                                        onChange={(e) => {
                                            if (e.target.value === props.clientData.value.client[42]) {
                                                setTogglePreferrendAtivos(false);
                                                setPreferrendAtivos(e.target.value);
                                            } else {
                                                setTogglePreferrendAtivos(true);
                                                setPreferrendAtivos(e.target.value);
                                            }
                                        }}
                                    />
                                    <br/>
                                    {togglePreferrendAtivos ? (
                                        <>
                                            <button
                                                className={togglePreferrendAtivos ? "selectClientDetailsButton" : "selectClientDetailsButtonDISABLED"}
                                                disabled={!togglePreferrendAtivos}
                                                onClick={() => {
                                                    setTogglePreferrendAtivos(false);
                                                    setPreferrendAtivos(props.clientData.value.client[42] ? props.clientData.value.client[42] : ' ');
                                                }}>
                                                Cancelar
                                            </button>
                                            <button
                                                className={togglePreferrendAtivos ? "selectClientDetailsButton" : "selectClientDetailsButtonDISABLED"}
                                                disabled={!togglePreferrendAtivos}
                                                onClick={() => {
                                                    setTogglePreferrendAtivos(false);
                                                    updatePreferrendAtivos();
                                                }}>
                                                Ok
                                            </button>
                                        </>
                                    ) : (
                                        <></>
                                    )}
                                </span>
                                <span className="itemDetail">
                                    <a id="clientDetailFirstAElementVBA">Restrições de ativos: </a> <br />
                                    <textarea
                                        type="text"
                                        className="campoTextoAtivoAdm"
                                        value={restricoesAtivos}
                                        style={{ maxHeight: "9rem", resize: "none", width: "90%", padding: "0.5rem"}}
                                        onKeyUp={(e) => {
                                            e.target.style.height = "inherit";
                                            e.target.style.height = `${e.target.scrollHeight}px`;
                                        }}
                                        onChange={(e) => {
                                            if (e.target.value === props.clientData.value.client[43]) {
                                                setToggleRestricoesAtivos(false);
                                                setRestricoesAtivos(e.target.value);
                                            } else {
                                                setToggleRestricoesAtivos(true);
                                                setRestricoesAtivos(e.target.value);
                                            }
                                        }}
                                    />
                                    <br/>
                                    {toggleRestricoesAtivos ? (
                                        <>
                                            <button
                                                className={toggleRestricoesAtivos ? "selectClientDetailsButton" : "selectClientDetailsButtonDISABLED"}
                                                disabled={!toggleRestricoesAtivos}
                                                onClick={() => {
                                                    setToggleRestricoesAtivos(false);
                                                    setRestricoesAtivos(props.clientData.value.client[43] ? props.clientData.value.client[43] : '-');
                                                }}>
                                                Cancelar
                                            </button>
                                            <button
                                                className={toggleRestricoesAtivos ? "selectClientDetailsButton" : "selectClientDetailsButtonDISABLED"}
                                                disabled={!toggleRestricoesAtivos}
                                                onClick={() => {
                                                    setToggleRestricoesAtivos(false);
                                                    updateRestricoesAtivos();
                                                }}>
                                                Ok
                                            </button>
                                        </>
                                    ) : (
                                        <></>
                                    )}
                                </span>
                                <span className="itemDetail">
                                    <a id="clientDetailFirstAElementVBA">Notas Gerais: </a> <br />
                                    <textarea
                                        type="text"
                                        className="campoTextoAtivoAdm"
                                        value={notasGerais}
                                        style={{ maxHeight: "9rem", resize: "none", width: "90%", padding: "0.5rem"}}
                                        onKeyUp={(e) => {
                                            e.target.style.height = "inherit";
                                            e.target.style.height = `${e.target.scrollHeight}px`;
                                        }}
                                        onChange={(e) => {
                                            if (e.target.value === props.clientData.value.client[45]) {
                                                setToggleNotasGerais(false);
                                                setNotasGerais(e.target.value);
                                            } else {
                                                setToggleNotasGerais(true);
                                                setNotasGerais(e.target.value);
                                            }
                                        }}
                                    />
                                    <br/>
                                    {toggleNotasGerais ? (
                                        <>
                                            <button
                                                className={toggleNotasGerais ? "selectClientDetailsButton" : "selectClientDetailsButtonDISABLED"}
                                                disabled={!toggleNotasGerais}
                                                onClick={() => {
                                                    setToggleNotasGerais(false);
                                                    setNotasGerais(props.clientData.value.client[45] ? props.clientData.value.client[45] : ' ');
                                                }}>
                                                Cancelar
                                            </button>
                                            <button
                                                className={toggleNotasGerais ? "selectClientDetailsButton" : "selectClientDetailsButtonDISABLED"}
                                                disabled={!toggleNotasGerais}
                                                onClick={() => {
                                                    setToggleNotasGerais(false);
                                                    updateNotasGerais();
                                                }}>
                                                Ok
                                            </button>
                                        </>
                                    ) : (
                                        <></>
                                    )}
                                </span>
                                <span className="itemDetail">
                                    <a id="clientDetailFirstAElementVBA">Linguagem Desejada: </a> <br />

                                    <a id="clientDetailSecondtAElementVBA">Formal: </a>
                                    <input
                                        defaultChecked={props.clientData.value.client[44] == 'Formal' ? "checked" : ""}
                                        checked={languageFormalClientExcecao ? "checked" : ""}
                                        type="checkbox"
                                        id="check"
                                        name="check"
                                        className="checkboxVBAExceptionClient"
                                        onChange={(e) => {
                                            setLanguageFormalClientExcecao(e.target.checked);
                                            languageFormalClientExceptionEdited(e.target.checked);
                                            if (e.target.checked) {
                                                setLanguageInformalClientExcecao(false);
                                                setLanguageInformalClientExcecaoEdited(false);
                                            }

                                        }}></input>
                                    <a id="clientDetailSecondtAElementVBA">Informal: </a>
                                    <input
                                        defaultChecked={props.clientData.value.client[44] == 'Informal' ? "checked" : ""}
                                        checked={languageInformalClientExcecao ? "checked" : ""}
                                        type="checkbox"
                                        id="check"
                                        name="check"
                                        className="checkboxVBAExceptionClient"
                                        onChange={(e) => {
                                            setLanguageInformalClientExcecao(e.target.checked);
                                            languageInformalClientExceptionEdited(e.target.checked);
                                            if (e.target.checked) {
                                                setLanguageFormalClientExcecao(false);
                                                setLanguageFormalClientExcecaoEdited(false);
                                            }
                                        }}></input>

                                    {languageFormalClientExcecaoEdited ? (
                                        <button
                                            className="botaoSubmitExcecaoVBA"
                                            onClick={() => {
                                                submitHandleSubmitLanguageFormalClientException();
                                            }}>
                                            Confirmar
                                        </button>
                                    ) : (
                                        languageInformalClientExcecaoEdited ? (
                                            <button
                                                className="botaoSubmitExcecaoVBA"
                                                onClick={() => {
                                                    submitHandleSubmitLanguageInformalClientException();
                                                }}>
                                                Confirmar
                                            </button>
                                        ) : (
                                            <></>
                                        )
                                    )}
                                </span>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}
