import React, { useState } from "react";
/*Styles*/
import "./styles/unsuitedStructured.table.css";
/*Tables*/
import { UnsuitedStructuredTbl } from "./tables/unsuitedStructured.table";
import { ExecutedStructuredTbl } from "./tables/executedStructured.table";
import { BrokenStructuredTbl } from "./tables/brokenStructured.table";
import { DueStructuredTbl } from "./tables/dueStructured.table";
import { WisirExecutedStructuredTbl } from "./tables/wisirExecutedStructured.table";
/*Components*/
import Modal from "../../../../components/modal/modal";
import { ConfirmationBox } from "../../../../components/confirmationBox/confirmationBox";
/*Contexts*/
import { useModal } from "../../../../contexts/useModal";

export function UnsuitedStructured() {
    const [tabActive, setTabActive] = useState(1);
    const { show, setShow, type } = useModal();

    return (
        <div className="unsuitedStructuredContainer">
            <Modal>{type.name === "alertMsg" ? <ConfirmationBox msgData={type} /> : <></>}</Modal>
            <div className="estructuredsBody">
                <div className="estructuredsTab">
                    <div
                        className={tabActive === 1 ? "estructuredsTabOption" : "estructuredsInactiveTabOption"}
                        onClick={() => setTabActive(1)}
                    >
                        <p>Operações Desenquadradas</p>
                        <div></div>
                    </div>
                    <div className={tabActive === 2 ? 'estructuredsTabOption' : 'estructuredsInactiveTabOption'} onClick={() => setTabActive(2)}>
                        <a>Operações Executadas</a>
                        <div></div>
                    </div>
                    <div className={tabActive === 3 ? 'estructuredsTabOption' : 'estructuredsInactiveTabOption'} onClick={() => setTabActive(3)}>
                        <a>Barreiras Rompidas</a>
                        <div></div>
                    </div>
                    <div className={tabActive === 4 ? 'estructuredsTabOption' : 'estructuredsInactiveTabOption'} onClick={() => setTabActive(4)}>
                        <a>Vencimentos</a>
                        <div></div>
                    </div>
                    <div className={tabActive === 5 ? 'estructuredsTabOption' : 'estructuredsInactiveTabOption'} onClick={() => setTabActive(5)}>
                        <a>Estruturadas Wisir</a>
                        <div></div>
                    </div>
                </div>
                {tabActive === 1 ? <UnsuitedStructuredTbl /> : tabActive === 2 ? <ExecutedStructuredTbl /> : tabActive === 3 ? <BrokenStructuredTbl /> : tabActive === 4 ? <DueStructuredTbl /> : <WisirExecutedStructuredTbl /> }
            </div>
        </div>
    );
}
