import axios from "axios"

export const api = axios.create({
	baseURL: 'https://portalrv.manchesterinvest.com.br',
    withCredentials: true
})

// export const api = axios.create({
//     baseURL: "http://localhost:3000",
// })
