import React, { useEffect, useState } from "react";
import { useSocket } from "../../../../contexts/useSocket";
import MUIDataTable from "mui-datatables";
import { Tooltip, IconButton } from "@material-ui/core";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { ThemeProvider,createTheme } from '@mui/material/styles';
/*Styles*/
import "../../monitoringPanel/myReservations/styles/myReservation.table.css"
/*Contexts*/
import { useAuth } from "../../../../contexts/useAuth";
import { useModal } from "../../../../contexts/useModal";
/*Services*/
import { api } from '../../../../services/api';
import { getEstructuredOperationsAll } from '../../../../services/asyncFunctions';
import { getReservationOrdersOperationsAll } from '../../../../services/asyncFunctions';
import { getReservationOrdersOperationsAllExport } from "../../../../services/asyncFunctions";
import { getOperationsStatus } from '../../../../services/asyncFunctions';
import { getOffers,updateMyReservationStatus } from '../../../../services/asyncFunctions'
/*Components*/
import { ArrowLoader } from "../../../../components/loaders/arrowLoader";
import { toBRL } from "../../../../utils/formatNumberAndText/toBRLCurrency";
import { TableCell } from "@material-ui/core";
import { AiOutlineArrowUp, AiOutlineArrowDown, AiFillMessage, AiFillBulb } from "react-icons/ai";
import CustomToolbarSelect from "./toolbar/customSelectedToolbarMyReservation";

export function MyReservationsMUI() {
    const { reloadBookOrders, setReloadBookOrders, emitForEveryOne, setEmitForEveryOne } = useSocket()
    const now = new Date()
    const [loading, setLoading] = useState(true);
    const { user, tokenKey, userType } = useAuth()
    const [showMessage, setShowMessage] = useState(false)
    const [hideMessage, setHideMessage] = useState(false)
    const [listOffers, setListOffers] = useState([]);
    const [listDeletion, setListDeletion] = useState([]);
    const [listOffersFormatted, setlistOffersFormatted] = useState([]);
    const [reservationOrdersOperationPeriodsList, setReservationOrdersOperationPeriodList] = useState([])
    const [reservationOrdersList, setReservationOrdersList] = useState([])
    const [selectedOpen, setSelectedOpen] = useState(1);
    const [selectedYear, setSelectedYear] = useState(0);
    const [selectedMonth, setSelectedMonth] = useState(0);


    const { setType, type, setShow, edited, setEdited } = useModal();
    const [rowsPerPage, setRowsPerPage] = useState(30);
    
    const [showOptions, setShowOptions] = useState(false)
    const [selectedOperations, setSelectedOperations] = useState([])
    const [operations, setOperations] = useState(0);
    const [netSum, setNetSum] = useState(0);
    const [revenue, setRevenue] = useState(0);
    const [operationsStatus, setOperationsStatus] = useState([]);
    const [statusOrderSelected,setStatusOrderSelected] = useState(-1)

    async function getStatusoperations() {
        const operationsStatusAux = await getOperationsStatus(tokenKey)
        setOperationsStatus(operationsStatusAux?.data)
        setLoading(false);
    }

    async function getOrdersReccords(open, year, month, reload = true) {
        
        if (reload) { setLoading(true) }
        
        const reservationOrdersOperations = await getReservationOrdersOperationsAll(user?.type > 2 ? user.external_id : undefined, tokenKey, open, year, month, false)
        if (reservationOrdersOperations?.isError) {
            setListOffers([])
        } else {
            setListOffers(reservationOrdersOperations);
        }
        setLoading(false);
    }

    async function getOrdersReccordsNow(open, year, reload = true) {
        if (reload) { setLoading(true) }
        
        const reservationOrdersOperations = await getReservationOrdersOperationsAllExport(user?.type > 2 ? user.external_id : undefined, tokenKey, open, year, false)
        if (reservationOrdersOperations?.isError) {
            setListOffers([])
        } else {
            setListOffers(reservationOrdersOperations);
        }
        
        setLoading(false);
    }

    function formateAssets() {
        let offersFormatted = [];
        listOffers.map((offers) => {
            offersFormatted.push({
                response: offers.response,
                users_full_name: offers.users_full_name,
                clients_external_id: offers.clients_external_id,
                clients_name: offers.clients_name,
                vinculated: offers.vinculated === true ? 'Sim' : 'Não',
                broker_name: offers.broker_name !== null ? offers.broker_name : '-',
                advisor_name: offers.advisor_name,
                offers_asset: offers.offers_asset,
                modality: offers.modality.slice(3),
                net: offers.price * offers.value,
                qtd: parseInt(offers.value),
                done: offers.done,
                qtdDiff: offers.value - offers.done,
                balance_update: (offers.price * offers.value) - offers.balance,
                operations_status_description: offers.operations_status_description,
                observation: offers.observation,
                id: offers.id,
                anotations: offers.anotations,
                advisor_id: offers.advisor_id,
                broker_id: offers.broker_id,
                created_at: offers.created_at
            });
        });

        setlistOffersFormatted(offersFormatted);
        // setLoading(false);
    }

    function verifyIsFiltered(field, filterRow, column) {

        return field.length > 0 ?
            field.filter((filter) => {

                if (typeof (filterRow[column]) !== 'boolean' && filterRow[column] !== null  ? filterRow[column].toLowerCase() === filter.toLowerCase() :
                    filterRow[column] === filter) {
                    return filterRow;
                }
            }).length > 0
            :
            field.filter((filter) => {
                if (typeof (filterRow[column]) !== 'boolean' && filterRow[column] !== null ? filterRow[column].toLowerCase() === filter.toLowerCase() :
                    filterRow[column] === filter) {
                    return filterRow;
                }
            }).length === 0
    }

    const HandleDeleteRow = (e) => {
        const listDeletion = [];
        e.data.forEach(x => {
            listDeletion.push(parseInt(listOffersFormatted[x.dataIndex].id))
        });
        setListDeletion(listDeletion);
        setType({ name: 'alertMsg', value: { message: 'Deseja realmente excluir todas as ordens selecionadas?', func: handleDelete, dataForFunc: listDeletion } })
        setShow(true);
}

    const updateSumHeader = (e) => {
        let newSumNet = 0;
        let newSumComission = 0;
        e?.forEach(x => {
            newSumNet += parseFloat(listOffersFormatted[x.dataIndex].net)
            newSumComission += parseFloat(listOffersFormatted[x.dataIndex].comission)
        });
        setNetSum(newSumNet);
        setRevenue(newSumComission);
    }

    const handleDelete = async (data) => {

        setLoading(true)
        try {
            const deleteOrders = await api.delete(`/api/v1/offersOrders`, {

                headers: {
                    authorization: `Bearer ${tokenKey}`
                },
                data: {
                    "orders": data
                }
            })
            setType({ name: 'alertMsg', type: 'information', value: { message: 'Ordens excluídas com sucesso!' } })
            setShow(true)
            setEdited(true)
            setSelectedOperations([])
        } catch (error) {

            if (error?.response?.data?.details?.length > 0) {
                setType({
                    name: 'alertMsg', type: 'information', value: {
                        message: error?.response?.data?.details[0]
                    }
                })
                setShow(true)
                setSelectedOperations([])
                setLoading(false)

            } else {
                setType({
                    name: 'alertMsg', type: 'information', value: {
                        message: error?.response?.data?.message === undefined ? 'Erro ao remover Ordens' : error?.response?.data?.message
                    }
                })
                setShow(true)
                setSelectedOperations([])
                setLoading(false)

            }
        }
    }

    useEffect(() => {
        if (edited || listOffers?.length === 0) {
            setEdited(false);
            setLoading(false);
            getOrdersReccordsNow(1, now.getFullYear());
            getStatusoperations()
            // getOrdersReccords(selectedOpen, selectedYear !== 0 ? selectedYear : now.getFullYear(), selectedMonth !== 0 ? selectedMonth : now.getMonth());
        }
    }, [edited]);

    useEffect(() => {
        formateAssets();
    }, [listOffers]);

    useEffect(() => {
        var netSumHead = 0;
        var revHead = 0;
        listOffersFormatted.forEach((e) => {
            netSumHead += parseFloat(e.net);
            revHead += parseFloat(e.comission);
        })

        setNetSum(netSumHead.toFixed(2));
        setRevenue(revHead.toFixed(2));
    }, [listOffersFormatted]);

    function copyToClipboard() {
        const output = [];
        const titles = [
            "Data de Solicitação",
            "Operado Por",
            "Código Cliente",
            "Cliente",
            "Vinculado à XP",
            "Broker",
            "Assessor",
            "Ativo",
            "Modalidade",
            "Valor",
            "QTD",
            "QTD Reservada",
            "Diferença",
            "Saldo Descontado",
            "Status"
        ];
        output.push(titles.join("\t"));
        const keys = [
            "created_at",
            "users_full_name",
            "clients_external_id",
            "clients_name",
            "vinculated",
            "broker_name",
            "advisor_name",
            "offers_asset",
            "modality",
            "net",
            "qtd",
            "done",
            "qtdDiff",
            "balance_update",
            "operations_status_description"
        ];
        listOffersFormatted.forEach((x) => {
            const tmp = keys.map((y) => {
                return y === "net" | y === "comission"
                    ? String(x[y]).replace(".", ",") : y === "created_at" | y === "expiration"
                    ? x[y].replace(/^(\d{4})-(\d{2})-(\d{2})T(\d{2}:\d{2}:\d{2})\.\d+Z$/, "$3/$2/$1") : x[y]
            });
            output.push(tmp.join("\t"));

        });
        const table = output.join("\n");
        navigator.clipboard.writeText(table);
        alert("Conteúdo da tabela copiado para a área de transferência");
    }

    async function offersExport(open, year) {
        setLoading(true);
        try {
            const myExport = await getReservationOrdersOperationsAllExport(user?.type > 2 ? user.external_id : undefined, tokenKey, open, year, true)
            const responseURL = URL.createObjectURL(new Blob([myExport]));
            const link = document.createElement("a");
            link.href = responseURL;
            link.setAttribute("download", "Ofertas Públicas.xlsx");
            document.body.appendChild(link);
            link.click();
            setLoading(false);
        } catch (error) {
            setType({
                name: "alertMsg",
                type: "information",
                value: {
                    message:
                        error?.response?.data?.message === undefined
                            ? "Erro ao buscar dados para exportação"
                            : error?.response?.data?.message,
                },
            });
            setShow(true);
            setLoading(false);
        }
    }

    async function notifyUser(all) {
        setShowMessage(all ? { notify: true, allUsers: true } : { notify: true, allUsers: false })
        await new Promise(resolve => setTimeout(resolve, 300))
        setHideMessage(false)
    }

    async function hideNotification() {

        setHideMessage(true)
        await new Promise(resolve => setTimeout(resolve, 3000))
        setShowMessage(false)
    }

    async function handleOrderStatusUpdate(e) {
        if(statusOrderSelected != -1){

            const listUpdateStatus = [];
            e.data.forEach(x => {
                listUpdateStatus.push(parseInt(listOffersFormatted[x.dataIndex].id))
            });

            try {
                const res = await updateMyReservationStatus(tokenKey, listUpdateStatus, statusOrderSelected);

                setType({ name: 'alertMsg', type: 'information', value: { message: 'Status atualizado(s) com sucesso' }})
                setShow(true)
                setEdited(true)
            } catch (error) {
                setLoading(false);
                setType({ name: "alertMsg", type: "information", value: { message: error?.response?.data?.message == undefined ? "Erro interno ao atualizar o status da ordem" : error?.response?.data?.message } });
                setShow(true);
            }
            

            
            
        }
        setStatusOrderSelected(-1)
        setSelectedOperations([])
    }

    // async function updateOperationStatus (status, idOrder){
    //     const newStatus = operationsStatus.filter(o => o.description == status)[0].id

    //     try {
    //         const res = await updateMyReservationStatus(tokenKey, idOrder, newStatus);
    //         alert('Status alterado com sucesso!')
    //     } catch (error) {
    //         setLoading(false);
    //         setType({ name: "alertMsg", type: "information", value: { message: error?.response?.data?.message == undefined ? "Erro interno ao atualizar o status da ordem" : error?.response?.data?.message } });
    //         setShow(true);
    //     }
    //     setLoading(false);
    // }

    useEffect(() => {

        const month = now.getMonth()
        const year = now.getFullYear()
        if (emitForEveryOne && reloadBookOrders) {
            notifyUser(true)
            setReloadBookOrders(false)
            setEmitForEveryOne(false)
        }
        else if (!emitForEveryOne && reloadBookOrders) {
            notifyUser(false)
            getReservationOrdersOperationsAll(new Date().getMonth() + 1, new Date().getFullYear(), 1)
            setReloadBookOrders(false)
        }
    }, [reloadBookOrders])

    const columns = [
        {
            name: "response",
            label: "Resposta",
            options: {
                filter: false,
                sort: true,

                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        padding: '0px 0.6rem 0px 0.6rem',
                    },
                }),
                customBodyRender: (val, tableMeta) => {
                    if(tableMeta.rowData[0] !== null){
                        return (
                            <div>
                                <div title="E-mail de resposta do cliente" style={{ placeContent: 'center'}}
                                    onClick={() => {
                                        const order = tableMeta.rowData[0] != null ? tableMeta.rowData[0] : '';
                                        setType({ name: 'showMyReservationsResponse', data: { order }});
                                        setShow(true);
                                    }}>
                                    <div className="btnMngAbc" style={{ backgroundColor: '#c49e17'}}><AiFillMessage /></div>
                            </div>
                        </div>
                        )
                    } else {
                        return (
                            <div>
                                <div title="E-mail de resposta do cliente" style={{ placeContent: 'center'}}
                                    onClick={() => {
                                        const order = tableMeta.rowData[0] != null ? tableMeta.rowData[0] : '';
                                        setType({ name: 'showMyReservationsResponse', data: { order }});
                                        setShow(true);
                                    }}>
                                    <div className="btnMngAbc"><AiFillMessage /></div>
                            </div>
                        </div>
                        )
                    }
                }
            },
        },
        {
            name: "created_at",
            label: "Data de Solicitação",
            options: {
                filter: true,
                sort: true,

                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        padding: '0px 0.6rem 0px 0.6rem',
                    },
                }),
                // customHeadRender: ({ index, label, ...column }, sortColumn, tableMeta) => (
                //     <TableCell onClick={() => sortColumn(index)} key={index} className="white_space">
                //         {label}
                //         <TableSortLabel active={tableMeta.name === column.name} direction={tableMeta.direction || "asc"} />
                //     </TableCell>
                // ),
                customBodyRender: (val) => {
                    return val.replace(/^(\d{4})-(\d{2})-(\d{2})T(\d{2}:\d{2}:\d{2})\.\d+Z$/, "$3/$2/$1");
                },
            },
        },
        {
            name: "users_full_name",
            label: "Operado Por",
            options: {
                filter: true,
                sort: true,

                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        padding: '0px 0.6rem 0px 0.6rem',
                    },
                }),
                // customHeadRender: ({ index, label, ...column }, sortColumn, tableMeta) => (
                //     <TableCell onClick={() => sortColumn(index)} key={index} className="white_space">
                //         {label}
                //         <TableSortLabel active={tableMeta.name === column.name} direction={tableMeta.direction || "asc"} />
                //     </TableCell>
                // ),
            },
        },
        {
            name: "clients_external_id",
            label: "Código Cliente",
            options: {
                filter: false,
                sort: true,

                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        padding: '0px 0.6rem 0px 0.6rem',
                    },
                }),
                // customHeadRender: ({ index, label, ...column }, sortColumn, tableMeta) => (
                //     <TableCell onClick={() => sortColumn(index)} key={index} className="white_space">
                //         {label}
                //         <TableSortLabel active={tableMeta.name === column.name} direction={tableMeta.direction || "asc"} />
                //     </TableCell>
                // ),
            },
        },
        {
            name: "clients_name",
            label: "Cliente",
            options: {
                filter: false,
                sort: true,
                // caso não seja para mostrar a coluna baseado no tipo de acesso, usar a linha display
                // display: tokenKey.type > 3 ? true : false,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        padding: '0px 0.6rem 0px 0.6rem',
                    },
                }),
                // customHeadRender: ({ index, label, ...column }, sortColumn, tableMeta) => (
                //     <TableCell onClick={() => sortColumn(index)} key={index} className="white_space">
                //         {label}
                //         <TableSortLabel active={tableMeta.name === column.name} direction={tableMeta.direction || "asc"} />
                //     </TableCell>
                // ),
            },
        },
        {
            name: "vinculated",
            label: "Vinculado à XP",
            options: {
                filter: true,
                sort: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        padding: '0px 0.6rem 0px 0.6rem',
                    },
                }),
                // customHeadRender: ({ index, label, ...column }, sortColumn, tableMeta) => (
                //     <TableCell onClick={() => sortColumn(index)} key={index} className="white_space">
                //         {label}
                //         <TableSortLabel active={tableMeta.name === column.name} direction={tableMeta.direction || "asc"} />
                //     </TableCell>
                // ),
            },
        },
        {
            name: "broker_name",
            label: "Broker",
            options: {
                filter: true,
                sort: true,

                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        padding: '0px 0.6rem 0px 0.6rem',
                    },
                }),
            },
        },
        {
            name: "advisor_name",
            label: "Assessor",
            options: {
                filter: true,
                sort: true,

                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        padding: '0px 0.6rem 0px 0.6rem',
                    },
                }),
            },
        },
        {
            name: "offers_asset",
            label: "Ativo",
            options: {
                filter: true,
                sort: true,

                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        padding: '0px 0.6rem 0px 0.6rem',
                    },
                }),
            },
        },
        {
            name: "modality",
            label: "Modalidade",
            options: {
                filter: true,
                sort: true,

                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        padding: '0px 0.6rem 0px 0.6rem',
                    },
                }),
            },
        },
        {
            name: "net",
            label: "VALOR",
            options: {
                filter: false,
                sort: true,

                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        padding: '0px 0.6rem 0px 0.6rem',
                    },
                }),
                customBodyRender: (value) => {
                    return (
                      <span>
                        {toBRL(value)}
                        {/* {tableMeta} */}
                      </span>
                    );
                },
            customHeadRender: ({ index, name, ...column }, sortColumn, tableMeta) => (
                <TableCell
                    className="containerSelectContactTypeHeader tss-gm6zfk-MUIDataTableHeadCell-fixedHeader"
                    onClick={() => {
                        sortColumn(index);
                    }}>
                    <div className="textSumCell" style={{ marginTop : 0 }}>
                        <div>
                            {/* <TableSortLabel active={tableMeta.name === column.name} direction={tableMeta.direction || "asc"} /> */}
                            {column.label} {tableMeta.name === name ? tableMeta.direction == "asc" ? <AiOutlineArrowUp /> : <AiOutlineArrowDown /> : <></>}
                        </div>
                        <p>{toBRL(parseFloat(netSum))}</p>
                    </div>
                </TableCell>
            ),
        }
        },
        {
            name: "qtd",
            label: "Qtd",
            options: {
                filter: false,
                sort: true,

                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        padding: '0px 0.6rem 0px 0.6rem',
                    },
                }),
            },
        },
        {
            name: "done",
            label: "Qtd Reservada",
            options: {
                filter: false,
                sort: true,

                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        padding: '0px 0.6rem 0px 0.6rem',
                    },
                }),
            },
        },
        {
            name: "qtdDiff",
            label: "Diferença",
            options: {
                filter: false,
                sort: true,

                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        padding: '0px 0.6rem 0px 0.6rem',
                    },
                }),
            },
        },
        {
            name: "balance_update",
            label: "Saldo Descontado",
            options: {
                filter: false,
                sort: true,

                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        padding: '0px 0.6rem 0px 0.6rem',
                    },
                }),
                customBodyRender: (value) => {
                    return (
                      <p>
                        {toBRL(value)}
                        {/* {tableMeta} */}
                      </p>
                    );
                },
            }
        },
        {
            name: "operations_status_description",
            label: "Status",
            options: {
                filter: true,
                sort: true,

                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        padding: '0px 0.6rem 0px 0.6rem',
                    },
                }),
                // customBodyRender: (val, tableMeta) => {
                //     return (
                //         <div>
                //             <select value={val} onChange={(e) => updateOperationStatus(e.target.value, tableMeta.rowData[16])}>
                //                 {operationsStatus?.map((option) => {
                //                     return (
                //                 <option selected={option.description == val ? 'selected' : ''}value={option.description}>{option.description}</option>
                //                 )}
                //                 )}
                //             </select>
                //         </div>
                //     )
                // },
            },
        },
        {
            name: "observation",
            label: "Obs.",
            options: {
                filter: false,
                sort: true,

                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        padding: '0px 0.6rem 0px 0.6rem',
                    },
                }),
                customBodyRender: (val, tableMeta) => {
                    if(tableMeta.rowData[17] !== null){
                        return (
                            <div>
                                <div title="E-mail de resposta do cliente" style={{ placeContent: 'center'}}
                                    onClick={() => {
                                        const order = tableMeta.rowData[17] != null ? tableMeta.rowData[17] : '';
                                        setType({ name: 'showMyReservationsDetails', data: { order }});
                                        setShow(true);
                                    }}>
                                    <div className="btnMngAbc" style={{ backgroundColor: '#c49e17'}}><AiFillMessage /></div>
                            </div>
                        </div>
                        )
                    } else {
                        return (
                            <div>
                                <div title="E-mail de resposta do cliente" style={{ placeContent: 'center'}}
                                    onClick={() => {
                                        const order = tableMeta.rowData[17] != null ? tableMeta.rowData[17] : '';
                                        setType({ name: 'showMyReservationsDetails', data: { order }});
                                        setShow(true);
                                    }}>
                                    <div className="btnMngAbc"><AiFillMessage /></div>
                            </div>
                        </div>
                        )
                    }
                }
            },
        },
        {
            name: "id",
            label: "id",
            options: {
                filter: false,
                sort: false,
                display: false
            }
        }
    ];

    async function showModalOffersHistory() {
        setShow(true);
        setType({ 
        name: "showReservationHistory",
        setSelectedYear : setSelectedYear,
        setSelectedMonth : setSelectedMonth,
        setSelectedOpen : setSelectedOpen,
        value: {
            data: getOrdersReccords }
        }
        
        );
    }

    // Adiciona um botão para chamar a função de copiar a tabela para a área de tranferência
    const AddButton = () => (
        <>
            {/* <Tooltip disableFocusListener title="Mostrar Colunas">
                <IconButton onClick={() => { showModalShowColumns()}}>
                    <span style={{fontSize: "1.4rem"}}>Personalizar Colunas</span>
                </IconButton>
            </Tooltip> */}

            <Tooltip disableFocusListener title="Copiar para a área de transferência">
                <IconButton onClick={copyToClipboard}>
                    <ContentCopyIcon />
                </IconButton>
            </Tooltip>
            <Tooltip disableFocusListener title="Selecione o período de operações desejado.">
                <IconButton
                    onClick={() => {
                        showModalOffersHistory();
                    }}>
                    <span style={{ fontSize: "1.4rem" }}>Buscar Histórico</span>
                </IconButton>
            </Tooltip>
            <Tooltip disableFocusListener title="Visualizar as ordens de ofertas ativas.">
                <IconButton
                    onClick={() => {
                        setSelectedOpen(1);
                        getOrdersReccordsNow(1, now.getFullYear());
                    }}>
                    <span style={{ fontSize: "1.4rem" }}>Ofertas em Andamento</span>
                </IconButton>
            </Tooltip>
            { user.type < 3 ?
            <Tooltip disableFocusListener title="Atualize em massa os status das operações.">
                <IconButton
                    onClick={() => {
                        setType({ name: 'showImportConnect', value: listOffers}); 
                        setShow(true);
                    }}>
                    <span style={{ fontSize: "1.4rem" }}>Atualizar via Excel</span>
                </IconButton>
            </Tooltip> : null
            }
        </>
    );

    const options = {
        filterType: "multiselect",
        selectableRows: "multiple",
        // selectableRowsOnClick: true,
        filter: true,
        download: true,
        print: false,
        responsive: "vertical",
        tableBodyHeight: "60vh",
        onRowSelectionChange: (rowsSelectedData, allRows, rowsSelected) => {
            setSelectedOperations(rowsSelected);
        },
        draggableColumns: {
            enabled: false,
        },
        // selectableRowsHideCheckboxes: true,
        viewColumns: false,
        toolButton: {
            justifyContent: "center"
        },
        textLabels: {
            body: {
                noMatch: loading ? <ArrowLoader /> : "Não há operações no histórico.",
                toolTip: "Ordenar",
                columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
            },
            pagination: {
                next: "Próxima",
                previous: "Anterior",
                rowsPerPage: "Linhas por página:",
                displayRows: "de",
            },
            toolbar: {
                search: "Pesquisar",
                downloadCsv: "Download XLSX",
                print: "Imprimir",
                viewColumns: "Ver colunas",
                filterTable: "Filtrar",
            },
            filter: {
                all: "Todos",
                title: "Filtros",
                reset: "Limpar",
            },
            viewColumns: {
                title: "Mostrar Colunas",
                titleAria: "Mostrar/Ocultar colunas",
            },
            selectedRows: {
                text: "linha(s) selecionadas",
                delete: "Excluir",
                deleteAria: "Excluir linhas Selecionadas",
            },
        },
        downloadOptions: {
            filename: "ofertas_publicas.csv",
            separator: ";",
        },
        onDownload: () => {
            offersExport(selectedOpen, selectedYear === 0 ? now.getFullYear() : selectedYear);
            return false;
        },
        onFilterChange: (a,b,c,d,e) => {
        updateSumHeader(e);
        },

        sortOrder: {
            name: 'net',
            direction: 'desc'
        },
        customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
            <CustomToolbarSelect
                selectedRows={selectedRows}
                optionsStatusOrder={operationsStatus}
                setStatusOrderSelected={setStatusOrderSelected}
                sendUpdate={handleOrderStatusUpdate}
                delete={HandleDeleteRow}
            />
          ),
        customToolbar: AddButton,
        setTableProps: () => {
            return {
                padding: 'none',
                size: 'small'
            };
          },
        onRowsDelete: (e) => HandleDeleteRow(e),
        rowsPerPage: rowsPerPage,
        rowsPerPageOptions: [30, 100, 1000],
    };

    const getMuiTheme = () =>
    createTheme({
      components: {
        MuiTableCell: {
            styleOverrides: {
                root:{
                        justifyContent: 'center',
                        whiteSpace: "nowrap",
                        textAlign: 'center',
                }
            },
          },
        MUIDataTableHeadCell: {
            styleOverrides: {
                contentWrapper: {
                    justifyContent: 'center',
                    whiteSpace: "nowrap",
                    // textAlign: 'center'
                }
            }
        },
      },
    })

    return loading ? (
        <div style={{ width : '100%' }}>
            <div className="divLoading">
                <ArrowLoader />
            </div>
        </div>
    ) : (
        <div>
            {
                    showMessage?.allUsers && showMessage?.notify ?
                        <div id='myStructuredNotifyBox' style={{ opacity: hideMessage ? '0' : '1' }}>
                            <p>Ocorreram atualizações em massa efetuadas pelo backoffice</p>
                            <button onClick={() => hideNotification()}>X</button>
                        </div>
                        :
                        !showMessage?.allUsers && showMessage?.notify &&
                        <div id='myStructuredNotifyBox' style={{ opacity: hideMessage ? '0' : '1' }}>
                            <p>Alterações realizadas</p>
                            <button onClick={() => hideNotification()}>X</button>
                        </div>

                }
            <ThemeProvider theme={getMuiTheme()}>
                <MUIDataTable
                    title={`Minhas Ordens`}
                    data={listOffersFormatted}
                    columns={columns}
                    options={options}
                    ></MUIDataTable>
            </ThemeProvider>
        </div>
    );
}

