import React from "react";
/*Styles*/
import "./styles/brokerPayroll.css";
/*Components*/
import { BrokerPayrollTbl } from "./tables/brokerPayroll.table";
import Modal from "../../../components/modal/modal";
import { ConfirmationBox } from "../../../components/confirmationBox/confirmationBox";
// import { ReccomendationsImport } from './recommendationsImport';
/*Contexts*/
import { useModal } from "../../../contexts/useModal";

export function BrokerPayroll() {
    const { type } = useModal();

    return (
        <div className="brokerPayrollContainer">
            <Modal>{type.name === "alertMsg" ? <ConfirmationBox msgData={type} /> : <></>}</Modal>
            <BrokerPayrollTbl />
        </div>
    );
}
