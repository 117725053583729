/**
 * Desenvolvido por equipe: Tiago Murilo
 * Descrição: Modal container que traz formulário de criação de relatórios
 **/

import React, { useEffect, useState } from 'react'
//services
import { api } from '../../../services/api';
//hooks
import { useModal } from '../../../contexts/useModal';
//styles
import styles from './styles/reports.cadastration.module.css'
import { ArrowLoader } from '../../../components/loaders/arrowLoader';
import { validyFiles } from '../../../utils/validyTypesOfFiles'

export function ReportCadastrations() {

    const [loading, setLoading] = useState(false)
    const { show, setShow, setEdited } = useModal()
    const [source, setSource] = useState('')
    const [title, setTitle] = useState('')
    const [link, setLink] = useState('')
    const [file, setFile] = useState('')
    const [asset, setAsset] = useState('')
    const [assetCompany, setAssetCompany] = useState('')
    const [isDocument, setIsDocument] = useState(true)
    const [periodic, setPeriodic] = useState(0)
    const [segment, setSegment] = useState('')
    const token = localStorage.getItem('@auth:token')
    const [errorFields, setErrorFields] = useState({

        source: false,
        title: false,
        assetCompany: false,
        asset: false,
        segment: false,
        link: false,
    })

    const requiredFields = [

        { field: 'source', value: source },
        { field: 'title', value: title },
        { field: 'assetCompany', value: assetCompany },
        { field: 'asset', value: asset },
        { field: 'segment', value: segment },
        { field: 'link', value: isDocument ? 'file' : link },
    ]

    useEffect(() => {
        if (!show) {
            setSource('')
            setLoading(false)
            setTitle('')
            setLink('')
            setFile('')
            setAsset('')
            setAssetCompany('')
            setIsDocument('')
            setSegment('')
            setErrorFields({
                source: false,
                title: false,
                assetCompany: false,
                asset: false,
                segment: false,
                link: false,
                periodic: false
            })
        }
    }, [show])

    async function handleSubmit(e) {

        e.preventDefault()
        
        let isErrors = false

        requiredFields.map((required) => {
            if ((typeof(required.value) !== 'number' && !required?.value?.trim()?.length) || required.value === 0) {
                errorFields[required.field] = true
                isErrors = true
            } else {
                errorFields[required.field] = false
            }
            setErrorFields(errorFields)
        })

        if (isErrors) {
            setLoading(true)
            setTimeout(() => {
                setLoading(false)
                alert('Verifique os campos em vermelho obrigatórios')
            }, [100])
            return
        }

        if(isDocument && file ===''){
            alert('Selecione um arquivo para enviar')
            return
        }

        if(validyFiles(file)){
            return
        }
        setLoading(true)
        try {

            const responseCreate = await api.post(`/api/v1/reports`, {
                source: source,
                title: title,
                path_link: isDocument == true ? undefined : link,
                is_file: isDocument == true ? 1 : 0,
                asset: asset,
                asset_company: assetCompany,
                segment: segment,
                periodic: parseInt(periodic)
            }, {
                headers: { authorization: 'Bearer ' + token }
            })
            if (isDocument) {

                const formData = new FormData();
                formData.append('report', file)

                await api.post(`/api/v1/reports/${responseCreate.data.data.id}/upload`,
                    formData
                    , {
                        headers: {
                            "Authorization": 'Bearer ' + token,
                            "Content-Type": "multipart/form-data"
                        }
                    })
            }
            setShow(false)
            setEdited(true)
            setLoading(false)
            return
        } catch (error) {
            if (error?.response?.data?.message != undefined) {
                setLoading(false)
                return alert(error?.response?.data?.message)
            }
            setLoading(false)
            return alert('Ocorreu algum erro inesperado ao tentar realizar a criação de relatório.')

        }

    }

    return (
        loading ?
            <div style={{ display: 'flex', height: '25rem', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                <ArrowLoader />
            </div>
            :
            <form action="post" onSubmit={handleSubmit} className={styles.formReportsCadastration}>
                <div className={styles.divInputsReportsCadastration}>
                    <input maxLength={100} style={{ border: errorFields['source'] ? '1px solid red' : '' }} onKeyPress={(e) => { if (e.code === 'Backquote') e.preventDefault() }} type="text" value={source} onChange={(e) => setSource(e.target.value)} placeholder=' ' />
                    <label htmlFor="">Fonte</label>
                </div>
                <div className={styles.divInputsReportsCadastration}>
                    <input maxLength={200} style={{ border: errorFields['title'] ? '1px solid red' : '' }} onKeyPress={(e) => { if (e.code === 'Backquote') e.preventDefault() }} type="text" value={title} onChange={(e) => setTitle(e.target.value)} placeholder=' ' />
                    <label htmlFor="">Título</label>
                </div>
                <div className={styles.divInputsReportsCadastration}>
                    <input maxLength={200} style={{ border: errorFields['assetCompany'] ? '1px solid red' : '' }} onKeyPress={(e) => { if (e.code === 'Backquote') e.preventDefault() }} type="text" value={assetCompany} onChange={(e) => setAssetCompany(e.target.value)} placeholder=' ' />
                    <label htmlFor="">Empresa</label>
                </div>
                <div className={styles.divInputsReportsCadastration}>
                    <input maxLength={30} style={{ border: errorFields['asset'] ? '1px solid red' : '' }} onKeyPress={(e) => { if (e.code === 'Backquote') e.preventDefault() }} type="text" value={asset} onChange={(e) => setAsset(e.target.value)} placeholder=' ' />
                    <label htmlFor="">Ativo</label>
                </div>
                <div className={styles.divInputsReportsCadastration}>
                    <input maxLength={100} style={{ border: errorFields['segment'] ? '1px solid red' : '' }} onKeyPress={(e) => { if (e.code === 'Backquote') e.preventDefault() }} type="text" value={segment} onChange={(e) => setSegment(e.target.value)} placeholder=' ' />
                    <label htmlFor="">Segmento</label>
                </div>
                <div className={styles.periodicReportLabel}>
                    <label for='periodicReport'>
                        <a>Periódico ?</a>
                        <select id='periodicReport' onChange={(e) => setPeriodic(e.target.value)} placeholder=' '>
                            <option value='1'>Sim</option>
                            <option selected value='0'>Não</option>
                        </select>
                    </label>

                </div>
                <div className={styles.radioSelectContainerColumn}>
                    <div className={styles.radioSelectContainerColumnLine}>
                        <div>
                            <input onKeyPress={(e) => { if (e.code === 'Backquote') e.preventDefault() }} type="checkbox" id='archive' checked={isDocument == true ? true : false} onClick={(e) => setIsDocument(true)} />
                            <label htmlFor="archive">Arquivo</label>
                        </div>
                        <div>
                            <input style={{ border: errorFields['link'] ? '1px solid red' : '' }} onKeyPress={(e) => { if (e.code === 'Backquote') e.preventDefault() }} type="checkbox" id='link' checked={isDocument == true ? false : true} onClick={(e) => setIsDocument(false)} />
                            <label htmlFor="link">Link</label>
                        </div>
                    </div>
                    {
                        !isDocument ?
                            <div className={styles.InputToFormat}>
                                <div>
                                    <input maxLength={250} style={{ border: errorFields['link'] ? '1px solid red' : '' }} onKeyPress={(e) => { if (e.code === 'Backquote') e.preventDefault() }} type="text" value={link} onChange={(e) => setLink(e.target.value)} placeholder=' ' />
                                    <label htmlFor="">Link</label>
                                </div>
                            </div> :
                            <div className={styles.fileInputAdjust}>
                                <label htmlFor="fileSelect">{file === '' || file === undefined || file === null ? 'Selecione um arquivo' : file.name}</label>
                                <input onKeyPress={(e) => { if (e.code === 'Backquote') e.preventDefault() }} type="file" id='fileSelect' accept=".xls,.xlsx, .pdf, .docx, .png, .jpeg, .jpeg, .txt" onChange={(e) => setFile(e.target.files[0])} />
                            </div>
                    }
                </div>
                <button type="submit" className='buttonWhiteBg'>Cadastrar</button>
            </form>
    )
}