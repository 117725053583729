/**
 * Desenvolvido por equipe: Tiago Murilo
 * Descrição: Faz o controle de todos os sockets.
 **/
import React, { createContext, useContext, useEffect, useState } from 'react'
import { useLocation} from 'react-router-dom';
import menuOptions from '../components/menu/config/directories.menu';

const menuContext = createContext({});

export const MenuProvider = ({ children }) => {

    const [menuStatus, setMenuStatus] = useState(1);
    const [currentScreen, setCurrentScreen] = useState('');
    const [menuSelected, setMenuSelected] = useState('');
    const [menuTitle, setMenuTitle] = useState('');
    const [orders, setOrders] = useState([]);
    const [showUser, setShowUser] = useState(false);
    const [ showNotificationCenter, setShowNotificationCenter] = useState(false);
    const [ showHelpCenter, setShowHelpCenter] = useState(false);
    const location = useLocation()

    function verifyPath(){

        const loggedScreen = []

        menuOptions.filter((menuOption)=> {
            
            if(menuOption.to === location.pathname){
                loggedScreen.push(menuOption)
            }

            return menuOption?.list?.filter((menuOptionList) => {
               if(menuOptionList?.to === location.pathname){
                   loggedScreen.push(menuOptionList)
               }
            })
        })

        setCurrentScreen(loggedScreen[0]?.name)
    }

    useEffect(()=> {
        verifyPath()
    })

    return (
        <menuContext.Provider value={{
            menuStatus, setMenuStatus,
            menuSelected, setMenuSelected,
            menuTitle, setMenuTitle,
            orders, setOrders,
            showUser, setShowUser,
            showNotificationCenter, setShowNotificationCenter,
            showHelpCenter, setShowHelpCenter,
            currentScreen
        }}>
            {children}
        </menuContext.Provider>
    )
}

// Cria Hook para mandar context do menu
export function useMenu() {
    return useContext(menuContext);
}