import React, { useState, useEffect } from 'react';
/*Utils Functions*/
import { titleize } from '../../../../utils/formatNumberAndText/formatTextUpperFirstLetter';
/*Styles*/
import './styles/clientsIndicationToApprovalBox.css';
import { styleClientsInput } from '../../../../components/styles/autocompletField';
import { FiCheck } from 'react-icons/fi';
/*Contexts*/
import { useModal } from '../../../../contexts/useModal';
import { useAuth } from '../../../../contexts/useAuth';
/*Utils Components*/
import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { ArrowLoader } from '../../../../components/loaders/arrowLoader';
/*Services*/
import { api } from '../../../../services/api';
import { getBrokers } from '../../../../services/asyncFunctions';

export function ClientsIndicationToApprovalBox(props) {

    const classes = styleClientsInput();
    const clientData = props?.data?.value;
    const profiles = props?.data?.value?.profiles;
    const [listBrokers, setListBrokers] = useState([]);
    const [selectedProfiles, setSelectedProfile] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState('');
    const { setShow, show, setType, edited, setEdited } = useModal();
    const { tokenKey } = useAuth();
    const [loading, setLoading] = useState(true);
    const [SendToApiSuccess, setSendToApiSuccess] = useState(false);
    const [inApproval, setInApproval] = useState(false);


    useEffect(async() => {

        setInApproval(false);
        const brokersGet = await getBrokers(1,tokenKey)
        if(brokersGet?.isError){
            setType({
                name: 'alertMsg',
                type: 'information',
                value: {
                    message: brokersGet?.error
                }
            })
            setShow(true);
        }else {
            setListBrokers(brokersGet);
        }
        setLoading(false);

    }, [show])

    async function handleReject() {
        if (window.confirm('Deseja realmente rejeitar esta indicação?')) {
            setLoading(true);
            try {
               await api.put(`/api/v1/clientsIndications/${clientData?.id}/clientsExternalId/${clientData?.clients_external_id}`, {
                    "status": 2
                },
                    {
                        headers:
                        {
                            authorization: `Bearer ${tokenKey}`
                        }
                    })
                setType({ name: 'alertMsg', type: 'information', value: { message: 'Indicação rejeitada com sucesso!' } })

            } catch (error) {
                setType({ name: 'alertMsg', type: 'information', value: { message: error?.response?.data?.message } });
                setLoading(false);
            }
        }
    }

    async function handleSubmit() {

        if (selectedProfiles?.length !== profiles?.length) {
            return alert('Selecione todos os brokers!')

        }

        try {
            setLoading(true);
            await api.put(`/api/v1/clientsIndications/${clientData?.id}/clientsExternalId/${clientData?.clients_external_id}`,
                {
                    "status": 1,
                    "brokers": selectedProfiles,
                    "operate_alone": "Sim",
                    "net_total": 0,
                    "net_stock": 0
                },
                {
                    headers: {
                        authorization: `Bearer ${tokenKey}`
                    }
                }

            )
            setType({ name: 'alertMsg', type: 'information', value: { message: 'Successo ao aprovar indicação' } })
            setEdited(!edited);
        } catch (error) {
            setType({ name: 'alertMsg', type: 'information', value: { message: error?.response?.data?.message } })
            setLoading(false);
        }
    }

    return (
        <div className='clientsIndicationToApprovalContainer'>
            {loading ? (
                <div className='clientsIndicationLoader'>
                    <ArrowLoader />
                </div>
            ) :
                (
                    <>
                        <div className='clientsIndicationToApprovalHeader'>
                            <p>Detalhes da indicação</p>
                            <button className='modalCloseButton' onClick={() => setShow(false)}>X</button>
                        </div>
                        <div className='clientsToApprovalBodyContainer'>
                            <div className='clientsIndicationToAprrovalUppper'>
                                <div id='relative' className='clientsIndicationLabelInfo'>
                                    <a>Código: </a><a>{titleize(clientData?.clients_external_id)}</a>
                                </div>
                                <div id='relative' className='clientsIndicationLabelInfo'>
                                    <a>Nome: </a><a>{titleize(clientData?.client_name)}</a>
                                </div>
                                <div id='relative' className='clientsIndicationLabelInfo'>
                                    <a>Indicado por: </a><a>{clientData?.users_external_id + ' - ' + titleize(clientData?.users_full_name)}</a>
                                </div>
                                <div id='relative' className='clientsIndicationLabelInfo'>
                                    <a>Em:</a><a>{clientData?.created_at}</a>
                                </div>
                            </div>
                            {inApproval ?
                                (<div className='clientsIndicationChooseTheProfilesDiv'>
                                    <label>
                                        <a>Selecione os Brokers</a>
                                    </label>
                                    <div className='clientsIndicationProfilesToChooseDiv'>
                                        {
                                            profiles?.map((profileToSelect, index) => {

                                                const listBrokersInThisProfile = listBrokers.filter((brokerInThisProfile) => {
                                                    if (profileToSelect?.profile_id === brokerInThisProfile?.profile_id) {
                                                        return brokerInThisProfile
                                                    }
                                                })
                                                return (
                                                    <Autocomplete
                                                        options={listBrokersInThisProfile}
                                                        value={selectedProfiles[profileToSelect]}
                                                        getOptionLabel={(x) => x.external_id + ' - ' + titleize(x?.full_name)}
                                                        getOptionSelected={(option, val) => option.value == val.value}
                                                        onChange={(event, newValue) => {

                                                            setSelectedProfile([...selectedProfiles, { broker_id: parseInt(newValue?.external_id), profile_id: parseInt(newValue?.profile_id) }]);
                                                            /*Verifica se o perfil escolhido ja existe no Array*/
                                                            const profileToSplice = selectedProfiles.findIndex(profileToRemove =>
                                                                profileToRemove?.profile_id === profileToSelect?.profile_id
                                                            );
                                                            if (profileToSplice > -1) {
                                                                /*Caso exista, irá remover do Array o que ja existia*/
                                                                selectedProfiles.splice(profileToSplice, 1);
                                                                if (selectedProfiles?.length > 0) {
                                                                    /*Verifica o tamanho do array selecionado*
                                                                    /*E Verifica se o valor passado no value é preenchido ou vazio*/
                                                                    newValue?.lenght > 0 ? setSelectedProfile([...selectedProfiles, { broker_id: parseInt(newValue?.external_id), profile_id: parseInt(newValue?.profile_id) }]) : setSelectedProfile([...selectedProfiles])
                                                                } else {
                                                                    /*Se a lista de perfis selecionados é vazia
                                                                    irá verificar se foi passado valor no value, e setar o novo valor, ou
                                                                    setar vazio*/
                                                                    newValue?.lenght > 0 ? setSelectedProfile({ broker_id: parseInt(newValue?.external_id), profile_id: parseInt(newValue?.profile_id) }) : setSelectedProfile([]);
                                                                }
                                                            }
                                                        }}
                                                        id={profileToSelect?.profile_description + index}
                                                        renderInput={(params) => <TextField
                                                            label={`Selecione o broker de ${profiles[index]?.profile_description}`}
                                                            className={classes.root}
                                                            {...params}
                                                            variant='outlined' />}
                                                    />
                                                )
                                            })
                                        }
                                    </div>
                                </div>) :
                                (
                                    <>
                                        <label>
                                            <a>Categorias</a>
                                        </label>
                                        <div className='clientsIndicationToAprrovalProfilesIndicated'>
                                            {profiles.map((profile) => {
                                                return (
                                                    <div className='clientsIndicationToApprovalProfileListIndicated'>
                                                        <FiCheck color='white' size='15' />
                                                        <a>{titleize(profile?.profile_description)}</a>
                                                    </div>
                                                )

                                            })}
                                        </div>
                                        <label className={inApproval ? 'clientsIndicationHide' : ''}>
                                            <a>Observações:</a>
                                        </label>
                                        <div className={inApproval ? 'clientsIndicationHide' : 'clientsIndicationToApprovalLower'} >
                                            <a>{clientData?.observation}</a>
                                        </div>
                                    </>
                                )
                            }
                        </div>
                        {inApproval ?
                            (
                                <div className='clientIndicationsApprovalRejectButtons'>
                                    <button className='buttonWhiteBg' onClick={() => handleSubmit()}>Confirmar</button>
                                </div>
                            ) :
                            (
                                <div className='clientIndicationsApprovalRejectButtons'>
                                    <button className='buttonWhiteBg' onClick={() => setInApproval(true)}>Aprovar</button>
                                    <button className='buttonWhiteBg' onClick={() => handleReject()}>Rejeitar</button>
                                </div >
                            )
                        }
                    </>
                )}

        </div >
    )
}