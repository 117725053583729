import React, { useState,useEffect } from 'react';
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import MdDone from "@mui/icons-material/Done";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import Block from "@mui/icons-material/Block";
import { withStyles } from "tss-react/mui";
import { Autocomplete } from '@material-ui/lab'
import { titleize } from '../../../../utils/formatNumberAndText/formatTextUpperFirstLetter'
import { TextField, TextareaAutosize, Checkbox, FormControlLabel } from '@material-ui/core'
import { height } from '@mui/system';

const defaultToolbarSelectStyles = {
  iconContainer: {
    display: "flex",
    marginRight: "3rem"
  },
  statusArea : {
    width: "15rem",
    marginRight: "3rem",
    height: "1.5rem"
  }
};

class CustomToolbarSelect extends React.Component {

handleSelectedStatus = () => {
  const selectedsIds = [];
  const selectedRows = this.props.selectedRows.data

  selectedRows.forEach((val) => {
    selectedsIds.push(this.props.displayData[val.index].data[0])
  })
  this.props.setSelectedRows([])
  this.props.setSelectedClients(selectedsIds)
  this.props.handleAuthorize(selectedsIds)
};

handleSelectedStatusReprove = () => {
  const selectedsIds = [];
  const selectedRows = this.props.selectedRows.data

  selectedRows.forEach((val) => {
    selectedsIds.push(this.props.displayData[val.index].data[0])
  })
  this.props.setSelectedRows([])
  this.props.setSelectedClients(selectedsIds)
  this.props.handleReprove(selectedsIds)
}

render() {
    const { classes } = this.props;
    return (
      <>
        <div className={classes.iconContainer}>
          <Tooltip>
            <span className='itemDetail'>
                <button className='botaoSolicitarExcecaoVBA' onClick={() => {this.handleSelectedStatus()}}>Autorizar transferência</button> 
            </span>
          </Tooltip>
          <Tooltip>
            <span className='itemDetail'>
                <button className='botaoSolicitarExcecaoVBA' onClick={() => {this.handleSelectedStatusReprove()}}>Negar transferência</button> 
            </span>
          </Tooltip>
        </div>
      </>

    );
  }
}

export default withStyles(CustomToolbarSelect, defaultToolbarSelectStyles, { name: "CustomToolbarSelect" });
