import React, { useEffect, useState, useRef } from "react"
/*Contexts*/
import { useAuth } from "../../../../contexts/useAuth"
import { useModal } from "../../../../contexts/useModal"
/*Services*/
import { FiArrowRight } from "react-icons/fi"

import { api } from "../../../../services/api"
import { Link } from "react-router-dom"
/*Components*/
// import { ArrowLoader } from "../../../../components/loaders/arrowLoader";
/*Utils*/
import { toBRL } from "../../../../utils/formatNumberAndText/toBRLCurrency"
import { titleize } from "../../../../utils/formatNumberAndText/formatTextUpperFirstLetter"
import { MdOutlineMonetizationOn, MdOutlineCheckCircleOutline, MdOutbox, MdContentCopy, MdSearch, MdOutlineArrowBackIosNew, MdOutlineArrowForwardIos } from "react-icons/md"
import { BsWhatsapp, BsTelephone, BsPeople, BsChatSquareText, BsCpu, BsChatDots, BsCardList, BsBoxArrowUpLeft, BsPersonPlus } from "react-icons/bs"
import { AiOutlineMail, AiOutlineSend } from "react-icons/ai"
import { FaCheck, } from "react-icons/fa"
import { BsSend } from "react-icons/bs"
import { updateABCStatus } from "../../../../services/asyncFunctions"
import { makeStyles } from "@material-ui/core/styles"
import { ArrowLoader } from "../../../../components/loaders/arrowLoader"
import { BsFillSquareFill } from "react-icons/bs"
import { Tooltip, Button } from "@material-ui/core"
import Select from "react-select"
import { TextField, Checkbox } from "@material-ui/core"
import { Autocomplete } from "@material-ui/lab"
import { AiFillCloseCircle } from "react-icons/ai"

import { FormControlLabel, FormGroup, Radio, RadioGroup } from "@mui/material"
import { styleClientsInput } from "../../../../components/styles/autocompletField"
import { datetimeToDatePtBR } from "../../../../utils/formatDate/dateTimeToPTBR"
import CurrencyTextField from "@unicef/material-ui-currency-textfield"

/* Styles */
import "./abcModal.css"

export function AbcClientProductsModal(props) {

    const classes = styleClientsInput()
    const { tokenKey, user } = useAuth()
    const { show, setShow, type, setType, edited, setEdited } = useModal()
    const [operationId, setOperationId] = useState(null)
    const [categoryInfos, setCategoryInfos] = useState(null)
    const [filters, setFilters] = useState([])
    const [items, setItems] = useState([])
    const [allItems, setAllItems] = useState([])
    const [filteredItems, setFilteredItems] = useState([])
    const [itemQty, setItemQty] = useState(30)
    const [loading, setLoading] = useState(true)
    const [loadingTimeline, setLoadingTimeline] = useState(false)
    const [copyingDescription, setCopyingDescription] = useState(false)
    const [copyingLink, setCopyingLink] = useState([])
    const [client_speech, setClientSpeech] = useState("")
    const [newStatus, setNewStatus] = useState(-1)
    const selectedProduct = useRef()
    const [refuseReason, setRefusalReason] = useState("")
    const [currentStatus, setCurretnStatus] = useState("")
    const [tabActive, setTabActive] = useState(0)
    const [toggleBrokerRelationship, setToggleBrokerRelationship] = useState(false);
    const [rootBrokerRelationship, setRootBrokerRelationship] = useState(props?.prop?.clientData[26]);
    const [clientExcecao, setClientExcecao] = useState(props?.prop?.clientData[29] == "Sim" ? true : false);
    const [clientExcecaoEdited, setClientExcecaoEdited] = useState(false);
    const [data, setData] = useState(props?.prop?.clientData);
    const [closeMessage, setCloseMessage] = useState(true)    
    const [selectedOtherEvasion, setSelectedOtherEvasion] = useState(' ');
    const [textInner, setTextInner] = useState(props?.prop?.outros);
    const [link, setLink] = useState({
        boletar: "#/",
        acompanhamento: "#/",
    })
    const [typeAbc, setTypeAbc] = useState(0) //tipo de produto pode ser RV ou de asssessor (por hora)
    const [contactType, setContactType] = useState(5)
    const [comentTimeline, setComentTimeline] = useState("")
    const [defaultComments, setDefaultComments] = useState({
        2: { comment: `Feito contato com o cliente sobre o produto ${selectedProduct?.current?.description}. Cliente ficou de avaliar a sugestão.` },
        6: { comment: `Feito contato com o cliente sobre o produto ${selectedProduct?.current?.description}. Cliente não teve interesse na oferta e recusou o produto.` },
        7: { comment: `Cliente não possui perfil para o produto ${selectedProduct?.current?.description}.` },
    });
    const [assuntoSelecionado, setAssuntoSelecionado] = useState()
    const [cloneAssuntoSelecionado, setCloneAssuntoSelecionado] = useState()
    const [activeRefusalReasonBox, setActiveRefusalReasonBox] = useState(false)
    const [selectedReason, setSelectedReason] = useState("")
    const filterProduct = React.useRef(true)
    const product = React.useRef(null)
    const [refusalReasons, setRefusalResasons] = useState([
        {
            id: "recurso",
            label: "Falta de recurso p/ alocar",
            comment: `Abordado o investidor sobre a oportunidade ${selectedProduct?.current?.description}, porém o cliente está sem recurso disponível.`,
        },
        { id: "prazo", label: "Prazo do produto", comment: `Abordado o investidor sobre a oportunidade ${selectedProduct?.current?.description}, porém o cliente não aceitou o prazo do produto.` },
        {
            id: "cenario",
            label: "Cenário Adverso",
            comment: `Abordado o investidor sobre a oportunidade ${selectedProduct?.current?.description}, porém o cliente não aceitou o produto por receio com o cenário macro atual.`,
        },
        {
            id: "preferencia",
            label: "Prefere outro produto",
            comment: `Abordado o investidor sobre a oportunidade ${selectedProduct?.current?.description}, porém o cliente não aceitou o produto por preferir outras classes de ativos no momento.`,
        },
        {
            id: "taxa",
            label: "Taxa não atrativa",
            comment: `Abordado o investidor sobre a oportunidade ${selectedProduct?.current?.description}, porém o cliente não aceitou o produto por não achar atrativa a taxa do ativo.`,
        },
        { id: "outros", label: "Outros", comment: `Escreva o motivo!` },
    ])

    const [searchActive, setSearchActive] = useState(false)
    const [toggleEvasion, setToggleEvasion] = useState(false)
    const [searchText, setSearchText] = useState("")
    const [clients, setClients] = useState([])
    const [filterCategory, setFilterCategory] = useState(0)
    const [selectedCategory, setSelectedCategory] = useState(null)
    const [geralCategory, setGeralCategory] = useState(null)
    const [categories, setCategories] = useState({})
    const [description, setDescription] = useState("")
    const [currentPosition, setCurrentPosition] = useState(0)
    const menuItemsRef = React.createRef()
    const [commentOn, setCommentOn] = useState(false);
    const [comment, setComment] = useState("");
    const [toggleTipoPreferencialContato, setToggleTipoPreferencialContato] = useState(false);
    const [togglePeriodicidadeContato, setTogglePeriodicidadeContato] = useState(false);
    const [selectedTipoPreferencialContato, setSelectedTipoPreferencialContato] = useState();
    const [boletar, setBoletar] = useState(false)
    const [boletarValue, setBoletarValue] = useState(0)
    const [rootTipoPreferencialContato, setRootTipoPreferencialContato] = useState(0);
    const [selectedPeriodicidadeContato, setSelectedPeriodicidadeContato] = useState();
    const [selectedBroker, setSelectedBroker] = useState(null);
    const [rootPeriodicidadeContato, setRootPeriodicidadeContato] = useState(0);
    const [servicoHB, setServicoHB] = useState();
    const [servicoHBEdited, setServicoHBEdited] = useState(false);
    const [selectedOperatesAlone, setSelectedOperatesAlone] = useState();
    const [selectedBrokerRelationship, setSelectedBrokerRelationship] = useState();
    const [admAcount, setAdmAcount] = useState(props.prop?.clientData[6]);
    const [preferrendAtivos, setPreferrendAtivos] = useState(props.prop?.clientData[33]);
    const [restricoesAtivos, setRestricoesAtivos] = useState(props.prop?.clientData[34]);
    const [toggleOperatesAlone, setToggleOperatesAlone] = useState(false);
    const [toggleAdmAcount, setToggleAdmAcount] = useState(false);
    const [togglePreferrendAtivos, setTogglePreferrendAtivos] = useState(false);
    const [toggleRestricoesAtivos, setToggleRestricoesAtivos] = useState(false);
    const [toggleSelectedOtherEvasion, setToggleSelectedOtherEvasion] = useState(false);
    const [rootervicoHB, setRootervicoHB] = useState(props.prop?.clientData[39]);
    const [rootOperatesAlone, setRootOperatesAlone] = useState(props.prop?.clientData[16]);
    const [brokerListAll, setBrokerListAll] = useState([]);
    const [brokerListDigital, setBrokerListDigital] = useState([]);
    const [brokerListPrivate, setBrokerListPrivate] = useState([]);
    const [defaultBroker, setDefaultBroker] = useState(null);

    const serviceModel = [
        { value: 1, description: 'Ultra High' },
        { value: 2, description: 'Top Tier'},
        { value: 3, description: 'Private'},
        { value: 4, description: 'Digital'},
        { value: 5, description: 'On Demand'},
    ]
    const [toggleServiceModel, setToggleServiceModel] = useState(false);
    const [selectedServiceModel, setSelectedServiceModel] = useState();
    const [segClientDigitalExcecao, setSegClientDigitalExcecao] = useState(props.prop.clientData[29] == 'Digital' ? true : false);
    const [segClientDigitalExcecaoEdited, setSegClientDigitalExcecaoEdited] = useState(false);
    const [reuniao_realizada, setReuniao_realizada] = useState(false);
    const [segClientPrivateExcecao, setSegClientPrivateExcecao] = useState(props.prop.clientData[29] == 'Private' ? true : false);
    const [segClientPrivateExcecaoEdited, setSegClientPrivateExcecaoEdited] = useState(false);
    const [languageFormalClientExcecao, setLanguageFormalClientExcecao] = useState(props.prop.clientData[35] == 'Formal' ? true : false);
    const [languageFormalClientExcecaoEdited, setLanguageFormalClientExcecaoEdited] = useState(false);
    const [languageInformalClientExcecao, setLanguageInformalClientExcecao] = useState(props.prop.clientData[35] == 'Informal' ? true : false);
    const [languageInformalClientExcecaoEdited, setLanguageInformalClientExcecaoEdited] = useState(false);
    const [contactClientExcecao, setContactClientExcecao] = useState(props.prop.clientData[32] == 'Sim' ? true : false);
    const [contactClientExcecaoEdited, setContactClientExcecaoEdited] = useState(false);
    const [nonContactClientExcecaoEdited, setNonContactClientExcecaoEdited] = useState(false);
    const [nonContactClientExcecao, setNonContactClientExcecao] = useState(props.prop.clientData[32] == 'Não' ? true : false);
    const [toggleNotasGerais, setToggleNotasGerais] = useState(false);
    const [notasGerais, setNotasGerais] = useState(props.prop.clientData[36]);
    const [selectedEvasion, setSelectedEvasion] = useState(props.prop.clientData[31]);
    const [rootEvasion, setRootEvasion] = useState(props.prop.clientData[31]);
    const [brokerID, setBrokerID] = useState(props.prop.clientData[15] );
    const [tabActiveInfos, setTabActiveInfos] = useState(0)
    const [loadingLiquidez, setLoadingLiquidez] = useState(false)
    const [liquidez, setLiquidez] = useState()
    const [netFundos, setNetFundos] = useState()

    const evasao = [
        {value:1, description: 'Assessor solicitou retirada da mesa'},
        {value:2, description: 'Cliente zerou posição de RV'},
        {value:3, description: 'Cliente evadiu do escritório'},
        {value:4, description: 'Cliente solicitou cancelamento do serviço da mesa'},
        {value:5, description: 'Outros'},
    ]
    const [marcarEvasao, setMarcarEvasao] = useState(props.prop.clientData[31] != '-' ? true : false);
    const [reguaPadrao, setReguaPadrao] = useState();



    const optUltraHigh = [
        { value: 1, description: 'Relacionamentos' },
        { value: 2, description: 'Revisão de Carteira' },
        { value: 3, description: 'Revisão Anual' },
        { value: 4, description: 'Revisão Ultra High' },
    ]
    const optTopTier = [
        { value: 1, description: 'Relacionamentos' },
        { value: 2, description: 'Revisão de Carteira' },
        { value: 3, description: 'Revisão Anual' },
    ]

    useEffect(() => {
        async function getReguaPadrao() {
            setLoading(true)
            try {
                const result = await api.get('/api/v1/vba/reguaPadrao', {
                    headers: {
                        authorization: `Bearer ${tokenKey}`
                    }
                })
                setReguaPadrao(result?.data.data[0] ? result?.data.data[0] : {relacionamentos: 0})
            } catch (error) {
                alert(error?.response?.data?.message)
            }
            setLoading(false)
        }
        getReguaPadrao()
    },[])

    async function getLiquidez(){
        setLoadingLiquidez(true)
        var data_liquidacao = selectedProduct?.current?.data_liquidacao == null ?  (selectedProduct?.current?.finish) : (selectedProduct?.current?.data_liquidacao)
        
        // return
        try {
            const data = await api.get(`/api/v1/alocacao/liquidez/${props?.prop?.client?.client_id}/${data_liquidacao}`, {
                headers: {
                    authorization: `Bearer ${tokenKey}`,
                },
                body: {
                    client_id: props?.prop?.client?.client_id,
                    dateLiquidation: data_liquidacao ,
                },
            })
            setLiquidez(data?.data?.data[0]?.net_vencimento)
            setNetFundos(data?.data?.data[0]?.net_fundos)
            setLoadingLiquidez(false)
        } catch (error) {
            alert(error?.response?.data?.message)
            console.log(error)
            // await setLoadingLiquidez(false)
        }
    }

    const useStyles = makeStyles((theme) => ({
        timelineNewCommentContactTypeActive: {
            width: "2rem",
            height: "2rem",
            backgroundColor: "#c49e17",
            // backgroundColor: contactType == 5 ? "#EADDCA" : contactType == 1 ? "#A4F097" : contactType == 2 ? "#CBE6FF" : contactType == 3 ? "#FFC8C8" : "#FEFFBC",
            color: "white",
            // color: contactType == 5 ? "#412500" : contactType == 1 ? "#165102" : contactType == 2 ? "#001D82" : contactType == 3 ? "#A90000" : "#BA9C00",
            display: "grid",
            padding: "0.2rem",
            paddingTop: "0.15rem",
            borderRadius: "8px",
            transition: "all ease-in-out 0.2s",
            marginLeft: "0.4rem",
            marginRight: "0.4rem",
            cursor: "pointer",
        },
        timelineNewCommentContactTypeInactive: {
            width: "2rem",
            height: "2rem",
            backgroundColor: "#d3d3d3",
            display: "grid",
            padding: "0.2rem",
            paddingTop: "0.15rem",
            borderRadius: "8px",
            transition: "all ease-in-out 0.2s",
            cursor: "pointer",
            marginLeft: "0.4rem",
            marginRight: "0.4rem",
            color: "white",
            "&:hover": {
                backgroundColor: "#b8b8b8",
            },
        },
        timelineFilterType1Active: {
            width: "1.5rem",
            height: "1.5rem",
            backgroundColor: "#c49e17",
            color: "white",
            display: "grid",
            padding: "0.2rem",
            paddingTop: "0.15rem",
            borderRadius: "8px",
            transition: "all ease-in-out 0.2s",
            marginLeft: "0.2rem",
            marginRight: "0.2rem",
            cursor: "pointer",
        },
        timelineFilterType2Active: {
            width: "1.5rem",
            height: "1.5rem",
            backgroundColor: "#c49e17",
            color: "white",
            display: "grid",
            padding: "0.2rem",
            paddingTop: "0.15rem",
            borderRadius: "8px",
            transition: "all ease-in-out 0.2s",
            marginLeft: "0.2rem",
            marginRight: "0.2rem",
            cursor: "pointer",
        },
        timelineFilterType3Active: {
            width: "1.5rem",
            height: "1.5rem",
            backgroundColor: "#c49e17",
            color: "white",
            display: "grid",
            padding: "0.2rem",
            paddingTop: "0.15rem",
            borderRadius: "8px",
            transition: "all ease-in-out 0.2s",
            marginLeft: "0.2rem",
            marginRight: "0.2rem",
            cursor: "pointer",
        },
        timelineFilterType4Active: {
            width: "1.5rem",
            height: "1.5rem",
            backgroundColor: "#c49e17",
            color: "white",
            display: "grid",
            padding: "0.2rem",
            paddingTop: "0.15rem",
            borderRadius: "8px",
            transition: "all ease-in-out 0.2s",
            marginLeft: "0.2rem",
            marginRight: "0.2rem",
            cursor: "pointer",
        },
        timelineFilterType5Active: {
            width: "1.5rem",
            height: "1.5rem",
            backgroundColor: "#c49e17",
            color: "white",
            display: "grid",
            padding: "0.2rem",
            paddingTop: "0.15rem",
            borderRadius: "8px",
            transition: "all ease-in-out 0.2s",
            marginLeft: "0.2rem",
            marginRight: "0.2rem",
            cursor: "pointer",
        },
        timelineFilterType0Active: {
            width: "1.5rem",
            height: "1.5rem",
            backgroundColor: "#c49e17",
            color: "white",
            display: "grid",
            padding: "0.2rem",
            paddingTop: "0.15rem",
            borderRadius: "8px",
            transition: "all ease-in-out 0.2s",
            marginLeft: "0.2rem",
            marginRight: "0.2rem",
            cursor: "pointer",
        },
        timelineFilterType6Active: {
            width: "1.5rem",
            height: "1.5rem",
            backgroundColor: "#c49e17",
            color: "white",
            display: "grid",
            padding: "0.2rem",
            paddingTop: "0.15rem",
            borderRadius: "8px",
            transition: "all ease-in-out 0.2s",
            marginLeft: "0.2rem",
            marginRight: "0.2rem",
            cursor: "pointer",
        },
        timelineFilterTypeInactive: {
            width: "1.5rem",
            height: "1.5rem",
            backgroundColor: "#d3d3d3",
            color: "white",
            display: "grid",
            padding: "0.2rem",
            paddingTop: "0.15rem",
            borderRadius: "8px",
            transition: "all ease-in-out 0.2s",
            cursor: "pointer",
            marginLeft: "0.2rem",
            marginRight: "0.2rem",
            "&:hover": {
                backgroundColor: "#b8b8b8",
            },
        },
        timelineFilterTypeProduct: {
            width: "fit-content",
            height: "1.5rem",
            backgroundColor: "#c49e17",
            color: "white",
            display: "grid",
            padding: "0.2rem",
            paddingLeft: "1rem",
            paddingRight: "1rem",
            borderRadius: "8px",
            transition: "all ease-in-out 0.2s",
            marginLeft: "0.2rem",
            marginRight: "0.2rem",
            cursor: "pointer",
        },
        timelineFilterTypeProductInactive: {
            color: "white",
            width: "fit-content",
            height: "1.5rem",
            backgroundColor: "#d3d3d3",
            display: "grid",
            padding: "0.2rem",
            paddingLeft: "1rem",
            paddingRight: "1rem",
            borderRadius: "8px",
            transition: "all ease-in-out 0.2s",
            cursor: "pointer",
            marginLeft: "0.2rem",
            marginRight: "0.2rem",
            "&:hover": {
                backgroundColor: "#b8b8b8",
            },
        },
        timelineNewComentWriteArea: {
            paddingRight: "3rem",
            resize: "none",
            borderRadius: "10px",
            border: "1px solid #dadada",
            backgroundColor: "#f8f8f8",
            padding: "0.5rem",
            fontSize: "1rem",
            // width: "calc(100% - 2rem)",
            "&:focus": {
                outline: "none",
                border: "#c49e17 2px solid",
                // contactType == 5
                //     ? "2px solid #412500"
                //     : contactType == 1
                //     ? "2px solid #165102"
                //     : contactType == 2
                //     ? "2px solid #001D82"
                //     : contactType == 3
                //     ? "2px solid #A90000"
                //     : "2px solid #BA9C00",
            },
        },
        timelineNewCommentSendActive: {
            width: "3rem",
            height: "3rem",
            // backgroundColor: contactType == 5 ? "#d3d3d3" : contactType == 1 ? "#A4F097" : contactType == 2 ? "#CBE6FF" : contactType == 3 ? "#FFC8C8" : "#FEFFBC",
            // color: contactType == 5 ? "#412500" : contactType == 1 ? "#165102" : contactType == 2 ? "#001D82" : contactType == 3 ? "#A90000" : "#BA9C00",
            backgroundColor: "transparent",
            color: "black",
            display: "grid",
            padding: "0.55rem",
            paddingLeft: "0.65rem",
            borderRadius: "8px",
            transition: "all ease-in-out 0.2s",
            marginTop: "auto",
            // transform: 'rotate(-55deg)',
            marginLeft: "-3.4rem",
            marginBottom: "0.15rem",
            border: "none",
            "&:hover": {
                backgroundColor: contactType == 5 ? "#b8b8b8" : contactType == 1 ? "#c5ffbb" : contactType == 2 ? "#e9f4ff" : contactType == 3 ? "#ffdbdb" : "#feffd3",
                boxShadow:
                    contactType == 5
                        ? "0px 0px 4px #b8b8b8"
                        : contactType == 1
                        ? "0px 0px 4px #165102"
                        : contactType == 2
                        ? "0px 0px 4px #001D82"
                        : contactType == 3
                        ? "0px 0px 4px #A90000"
                        : "0px 0px 4px #BA9C00",
            },
        },
        timelineNewCommentSendInative: {
            width: "3rem",
            height: "3rem",
            backgroundColor: "transparent",
            color: "#b8b8b8",
            display: "grid",
            padding: "0.55rem",
            paddingLeft: "0.65rem",
            // transform: 'rotate(-55deg)',
            borderRadius: "8px",
            transition: "all ease-in-out 0.2s",
            marginTop: "auto",
            marginLeft: "-3.4rem",
            marginBottom: "0.15rem",
            border: "none",
            cursor: "default",
        },
        timelineComentBoxUserComentName: {
            width: "3rem",
            height: "3rem",
            backgroundColor: "#d3d3d3",
            color: "#b8b8b8",
            display: "grid",
            padding: "0.55rem",
            paddingLeft: "0.65rem",
            borderRadius: "8px",
            transition: "all ease-in-out 0.2s",
            marginTop: "auto",
            marginRight: "0.4rem",
            marginBottom: "0.15rem",
            border: "none",
            cursor: "default",
        },
    }))


    const classesContactType = useStyles()
    const operatesAlone = [{ value: "Sim" }, { value: "Parcial" }, { value: "Não" }, { value: "Pendente" }];
    const brokerRelationship = [{ value: "Ativo" }, { value: "Passivo" }, { value: "Pendente" }];

    function verifyClientSegment() {
        // // props.prop?.clientData[27] // pos_rv
        // // props.prop?.clientData[41] // perfil do assessor
        var pos_rv = parseFloat(props.prop?.clientData[27].replace("R$", "").replaceAll(".", "").replace(",", "."));
        if (props.prop?.clientData[41] == 4 && pos_rv < 50000) {
            handleSubmit(0);
        } else {
            showClientActivation(3);
        }
    }
    async function updateBrokerRelationship() {
        if (selectedBrokerRelationship) {
            try {
                const clientUpdate = await api.put(
                    `api/v1/vba/update/${String(data[0])}/broker_relationship/${selectedBrokerRelationship}`,
                    {},
                    {
                        headers: {
                            authorization: `Bearer ${tokenKey}`,
                        },
                    }
                );
                alert("Cliente " + titleize(data[1]) + " atualizado com sucesso!");
                setLoading(false);
                setEdited(true);
                clear();
            } catch (error) {
                alert(error?.response?.data?.message);
                setLoading(false);
            }
        }
    }

    async function updateAdmAcount() {
        if (admAcount) {
            try {
                const clientUpdate = await api.put(
                    `api/v1/vba/update/${String(data[0])}/account_adm/${admAcount}`,
                    {},
                    {
                        headers: {
                            authorization: `Bearer ${tokenKey}`,
                        },
                    }
                );
                alert("Cliente " + titleize(data[1]) + " atualizado com sucesso!");
                setLoading(false);
                setEdited(true);
                setToggleAdmAcount(false)
                clear();
            } catch (error) {
                alert(error?.response?.data?.message);
                setLoading(false);
            }
        }
    }

    async function updatePreferrendAtivos() {
        if (preferrendAtivos) {
            try {
                const clientUpdate = await api.put(
                    `api/v1/vba/update/${String(data[0])}/preferrend_ativos/${preferrendAtivos}`,
                    {},
                    {
                        headers: {
                            authorization: `Bearer ${tokenKey}`,
                        },
                    }
                );
                alert("Cliente " + titleize(data[1]) + " atualizado com sucesso!");
                setLoading(false);
                setEdited(true);
                setTogglePreferrendAtivos(false)
                clear();
            } catch (error) {
                alert(error?.response?.data?.message);
                setLoading(false);
            }
        }
    }

    async function updateRestricoesAtivos() {
        if (restricoesAtivos) {
            try {
                const clientUpdate = await api.put(
                    `api/v1/vba/update/${String(data[0])}/restricoes_ativos/${restricoesAtivos}`,
                    {},
                    {
                        headers: {
                            authorization: `Bearer ${tokenKey}`,
                        },
                    }
                );
                alert("Cliente " + titleize(data[1]) + " atualizado com sucesso!");
                setLoading(false);
                setEdited(true);
                setToggleRestricoesAtivos(false)
                clear();
            } catch (error) {
                alert(error?.response?.data?.message);
                setLoading(false);
            }
        }
    }
    async function updateOperatesAlone() {
        if (selectedOperatesAlone) {
            try {
                const clientUpdate = await api.put(
                    `api/v1/vba/update/${String(data[0])}/operates_alone/${selectedOperatesAlone}`,
                    {},
                    {
                        headers: {
                            authorization: `Bearer ${tokenKey}`,
                        },
                    }
                );
                alert("Cliente " + titleize(data[1]) + " atualizado com sucesso!");
                setLoading(false);
                setEdited(true);
                // getBrokers();
                clear();
            } catch (error) {
                alert(error?.response?.data?.message);
                setLoading(false);
            }
        }
    }

    async function submitHandleSubmitHBService() {
        // setLoading(true);
        try {
            const client = await api.put(
                `api/v1/vba/update/${String(data[0])}/hb_service/${servicoHB}`,
                {
                    client_id: parseInt(data[0]),
                },
                {
                    headers: {
                        authorization: `Bearer ${tokenKey}`,
                    },
                }
            );
            alert("Cliente " + titleize(data[1]) + " atualizado com sucesso!");
            setServicoHBEdited(false);
            setRootervicoHB(servicoHB);
            setLoading(false);
        } catch (error) {
            alert(error?.response?.data?.message);
            setLoading(false);
        }
    }

    async function submitHandleSubmitContactClientException() {
        setLoading(true);
        try {
            const client = await api.put(
                `api/v1/vba/update/${String(data[0])}/contact_client_exception/${contactClientExcecao}`,
                {
                    client_id: parseInt(data[0]),
                },
                {
                    headers: {
                        authorization: `Bearer ${tokenKey}`,
                    },
                }
            );
            alert("Cliente " + titleize(data[1]) + " atualizado com sucesso!");
            setContactClientExcecaoEdited(false);
            setLoading(false);
        } catch (error) {
            alert(error?.response?.data?.message);
            setLoading(false);
        }
    }

    async function submitHandleSubmitNonContactClientException() {
        setLoading(true);
        try {
            const client = await api.put(
                `api/v1/vba/update/${String(data[0])}/non_contact_client_exception/${nonContactClientExcecao}`,
                {
                    client_id: parseInt(data[0]),
                },
                {
                    headers: {
                        authorization: `Bearer ${tokenKey}`,
                    },
                }
            );
            alert("Cliente " + titleize(data[1]) + " atualizado com sucesso!");
            setNonContactClientExcecaoEdited(false);
            setLoading(false);
        } catch (error) {
            alert(error?.response?.data?.message);
            setLoading(false);
        }
    }

    const [proximaReuniao, setProximaReuniao] = useState('Carregando');

    useEffect(() => {
        if(reguaPadrao?.relacionamentos && data[39]){

            var dataaux = data[39];
            dataaux = dataaux.split('/').reverse().join('-');
            //sem fuso horario
            
            dataaux = new Date(dataaux);
            //add 1 day
            dataaux.setDate(dataaux.getDate() + (365/reguaPadrao?.relacionamentos));
            const dataauxBrl = datetimeToDatePtBR(dataaux);
            setProximaReuniao(dataauxBrl);
        }
    },[data, reguaPadrao])

    function getOperationId() {
        // const prod_name = props?.prop?.product?.name;
        var operation_id = null
        if (props?.prop?.client && props?.prop?.client[`c${selectedProduct?.current?.id}__operation_id`]) {
            operation_id = props?.prop?.client[`c${selectedProduct?.current?.id}__operation_id`]
        }
        setOperationId(operation_id)
    }
    function clientServiceHBEdited(newValue) {
        if (rootervicoHB != newValue) {
            setServicoHBEdited(true);
        } else {
            setServicoHBEdited(false);
        }
    }
    async function submitHandleSubmitException() {
        setLoading(true);
        // return
        try {
            const client = await api.put(
                `/api/v1/vba/performanceException`,
                {
                    client_id: parseInt(data[0]),
                },
                {
                    headers: {
                        authorization: `Bearer ${tokenKey}`,
                    },
                }
            );
            setType({ name: "successClientEdit", type: "information", value: { message: `${client.data.message}` + " - " + ` ${titleize(data[1])}` } });
            setLoading(false);
        } catch (error) {
            alert(error?.response?.data?.message);
            setLoading(false);
        }
    }

    async function updateFrequency() {
        if (selectedPeriodicidadeContato) {
            try {
                const clientUpdate = await api.put(
                    `/api/v1/vba/update/${String(data[0])}/frequency/${selectedPeriodicidadeContato}`,
                    {},
                    {
                        headers: {
                            authorization: `Bearer ${tokenKey}`,
                        },
                    }
                );
                alert("Periodicidade preferencial de contato do cliente " + titleize(data[1]) + " atualizado com sucesso!");
                setLoading(false);
                setEdited(true);
                // getBrokers();
                clear();
            } catch (error) {
                alert(error?.response?.data?.message);
                setLoading(false);
            }
        }
    }


    async function updateEvasion() {
        if (selectedEvasion) {
            try {
                const clientUpdate = await api.put(
                    `api/v1/vba/update/${String(data[0])}/evasion/${selectedEvasion == 5 ? textInner : selectedEvasion}`,
                    {},
                    {
                        headers: {
                            authorization: `Bearer ${tokenKey}`,
                        },
                    }
                );
                alert("Cliente " + titleize(data[1]) + " atualizado com sucesso!");
                setLoading(false);
                setEdited(true);
                setToggleEvasion(false)
                // getBrokers();
                clear();
            } catch (error) {
                alert(error?.response?.data?.message);
                setLoading(false);
            }
        }
    }

    function clientExceptionEdited(newValue) {
        const exception = props.prop?.clientData[29] === "Sim" ? true : false;
        if (exception != newValue) {
            setClientExcecaoEdited(true);
        } else {
            setClientExcecaoEdited(false);
        }
    }

    useEffect(() => {
        if (selectedProduct?.current?.description) {
          setDefaultComments({
            2: { comment: `Feito contato com o cliente sobre o produto ${selectedProduct.current.description}. Cliente ficou de avaliar a sugestão.` },
            3: { comment: `Feito o envio de ordem do produto ${selectedProduct.current.description} para o cliente.` },
            6: { comment: `Feito contato com o cliente sobre o produto ${selectedProduct.current.description}. Cliente não teve interesse na oferta e recusou o produto.` },
            7: { comment: `Cliente não possui perfil para o produto ${selectedProduct.current.description}.` },
          });
      
          setRefusalResasons([
            {
                id: "recurso",
                label: "Falta de recurso p/ alocar",
                comment: `Abordado o investidor sobre a oportunidade ${selectedProduct?.current?.description}, porém o cliente está sem recurso disponível.`,
            },
            {
                id: "prazo",
                label: "Prazo do produto",
                comment: `Abordado o investidor sobre a oportunidade ${selectedProduct?.current?.description}, porém o cliente não aceitou o prazo do produto.`,
            },
            {
                id: "cenario",
                label: "Cenário Adverso",
                comment: `Abordado o investidor sobre a oportunidade ${selectedProduct?.current?.description}, porém o cliente não aceitou o produto por receio com o cenário macro atual.`,
            },
            {
                id: "preferencia",
                label: "Prefere outro produto",
                comment: `Abordado o investidor sobre a oportunidade ${selectedProduct?.current?.description}, porém o cliente não aceitou o produto por preferir outras classes de ativos no momento.`,
            },
            {
                id: "taxa",
                label: "Taxa não atrativa",
                comment: `Abordado o investidor sobre a oportunidade ${selectedProduct?.current?.description}, porém o cliente não aceitou o produto por não achar atrativa a taxa do ativo.`,
            },
            { id: "outros", label: "Outros", comment: `Escreva o motivo!` },
        ]);
        setNewStatus(-1)
        }
        if(selectedProduct?.current?.data_liquidacao || selectedProduct?.current?.finish )
            getLiquidez()   
      }, [selectedProduct.current]);

    function getCategory() {
        // const prod_name = props.prop.product.name;
        var cat_id = null
        if (props?.prop?.client && props?.prop?.client[`c${selectedProduct?.current?.id}__category_id`]) {
            cat_id = props?.prop?.client[`c${selectedProduct?.current?.id}__category_id`]
        }
        const cat = selectedProduct?.current?.categories?.find((x) => x.id == cat_id)
        setCategoryInfos(cat)
        var aux = []
        let linkSplited = cat?.link?.split(" ")
        linkSplited?.forEach(() => {
            aux.push(false)
        })
        setCopyingLink(aux)
    }

    async function submitBoleta() {
        setLoading(true)

        // console.log('props',props)
        
        try {
            const res = await api.put(
                `/api/v1/alocacao/value/${props?.prop?.client?.client_id}`,
                {
                    operation_id: operationId,
                    boletarValue: boletarValue,
                    abc_products_id: selectedProduct?.current?.id,
                },
                {
                    headers: {
                        authorization: `Bearer ${tokenKey}`,
                    },
                }
            )
            // setType({ name: "alertMsg", type: "information", value: { message: `Boleta enviada com sucesso.` } })
            // setEdited(true)
            alert("Status alterado com sucesso! Lembre-se que a boleta deve ser enviada via HUB")
            setCurretnStatus("Aceito")
            props.prop.client[`c${selectedProduct?.current?.id}__status`] = "Aceito"
            props.prop.client[`c${selectedProduct?.current?.id}__value`] = boletarValue
        } catch (error) {
            setLoading(false)
            setType({
                name: "alertMsg",
                type: "information",
                value: { message: error?.response?.data?.message == undefined ? "Erro interno ao enviar a boleta" : error?.response?.data?.message },
            })
            setShow(true)
        }
        setLoading(false)
    }

    function getSpeech() {
        var client_speech = null
        if (props?.prop?.client && props?.prop?.client[`c${selectedProduct?.current?.id}__speech`]) {
            client_speech = props?.prop?.client[`c${selectedProduct?.current?.id}__speech`]
        }
        setClientSpeech(client_speech != null ? client_speech : "")
    }

    async function updateNotasGerais() {
        if (notasGerais) {
            try {
                const clientUpdate = await api.put(
                    `api/v1/vba/update/${String(data[0])}/notas_gerais/${notasGerais}`,
                    {},
                    {
                        headers: {
                            authorization: `Bearer ${tokenKey}`,    
                        },
                    }
                );
                alert("Cliente " + titleize(data[1]) + " atualizado com sucesso!");
                setLoading(false);
                setEdited(true);
                setToggleNotasGerais(false)
                // getBrokers();
                clear();
            } catch (error) {
                alert(error?.response?.data?.message);
                setLoading(false);
            }
        }
    }

    function getLink() {
        const prod_type = selectedProduct?.current?.type.id
        if (prod_type == 1 || prod_type == 3) {
            // ESTRUTURADAS E IMPORTADAS
            setLink({
                boletar: "#/books",
                acompanhamento: user.type < 3 ? "#/clients" : "#/clientesMesa",
            })
        } else if (prod_type == 2) {
            // OFERTA PÚBLICA
            setLink({
                boletar: "#/fiis",
                acompanhamento: user.type < 3 ? "#/clients" : "#/clientesMesa",
            })
        }
    }

    async function updateStatus(status_id) {
        setLoading(true)
        setNewStatus(status_id)
        setComentTimeline(defaultComments[status_id].comment)

    }

    async function updateServiceModel() {
        if (selectedServiceModel) {
            try {
                const clientUpdate = await api.put(
                    `/api/v1/vba/update/${String(data[0])}/serviceModel/${selectedServiceModel}`,
                    {},
                    {
                        headers: {
                            authorization: `Bearer ${tokenKey}`,
                        },
                    }
                );
                alert("Modelo de serviço do cliente " + titleize(data[1]) + " atualizado com sucesso!");
                setLoading(false);
                setEdited(true);
                clear();
            } catch (error) {
                alert(error?.response?.data?.message);
                setLoading(false);
            }
        }
    }

    async function submitHandleSubmitSegClientDigitalException() {
        setLoading(true);
        try {
            const client = await api.put(
                `/api/v1/vba/update/${String(data[0])}/seg_client_digital_exception/${segClientDigitalExcecao ? 0 : 1}`,
                {
                    client_id: parseInt(data[0]),
                },
                {
                    headers: {
                        authorization: `Bearer ${tokenKey}`,
                    },
                }
            );
            alert("Cliente " + titleize(data[1]) + " atualizado com sucesso!");
            setSegClientDigitalExcecaoEdited(false);
            setLoading(false);
            setEdited(true);
        } catch (error) {
            alert(error?.response?.data?.message);
            setLoading(false);
        }
    }

    async function submitHandleSubmitLanguageFormalClientException() {
        setLoading(true);
        try {
            const client = await api.put(
                `/api/v1/vba/update/${String(data[0])}/language_formal_client_exception/${languageFormalClientExcecao ? 0 : 1}`,
                {
                    client_id: parseInt(data[0]),
                },
                {
                    headers: {
                        authorization: `Bearer ${tokenKey}`,
                    },
                }
            );
            alert("Cliente " + titleize(data[1]) + " atualizado com sucesso!");
            setLanguageFormalClientExcecaoEdited(false);
            setLoading(false);
            setEdited(true);
        } catch (error) {
            alert(error?.response?.data?.message);
            setLoading(false);
        }
    }

    async function submitHandleSubmitLanguageInformalClientException() {
        setLoading(true);
        try {
            const client = await api.put(
                `/api/v1/vba/update/${String(data[0])}/language_informal_client_exception/${languageInformalClientExcecao ? 0 : 1}`,
                {
                    client_id: parseInt(data[0]),
                },
                {
                    headers: {
                        authorization: `Bearer ${tokenKey}`,
                    },
                }
            );
            alert("Cliente " + titleize(data[1]) + " atualizado com sucesso!");
            setLanguageInformalClientExcecaoEdited(false);
            setLoading(false);
            setEdited(true);
        } catch (error) {
            alert(error?.response?.data?.message);
            setLoading(false);
        }
    }


    async function submitHandleSubmitSegClientPrivateException() {
        setLoading(true);
        try {
            const client = await api.put(
                `/api/v1/vba/update/${String(data[0])}/seg_client_private_exception/${segClientPrivateExcecao ? 1 : 0}`,
                {
                    client_id: parseInt(data[0]),
                },
                {
                    headers: {
                        authorization: `Bearer ${tokenKey}`,
                    },
                }
            );
            alert("Cliente " + titleize(data[1]) + " atualizado com sucesso!");
            setSegClientPrivateExcecaoEdited(false);
            setLoading(false);
            setEdited(true);
        } catch (error) {
            alert(error?.response?.data?.message);
            setLoading(false);
        }
    }

    async function sendNewStatus() {
        if (newStatus < 0) {
            return
        }
        try {
            const res = await updateABCStatus(tokenKey, operationId, newStatus, selectedProduct?.current?.id, props?.prop?.client?.client_id)
            // setType({ name: 'alertMsg', type: 'information', value: { message: `Status alterado com sucesso.` } })
            // setEdited(true)
            alert("Status alterado com sucesso!")

            if (newStatus == 6) {
                props.prop.client[`c${selectedProduct?.current?.id}__status`] = "Recusado"
                props.prop.client[`c${selectedProduct?.current?.id}__receita_estimada`] = '-'
            } else if (newStatus == 7) {
                props.prop.client[`c${selectedProduct?.current?.id}__status`] = "Não aderente"
                props.prop.client[`c${selectedProduct?.current?.id}__receita_estimada`] = '-'
            } else if (newStatus == 2) {
                props.prop.client[`c${selectedProduct?.current?.id}__status`] = "Abordado"
            } else if (newStatus == 3) {
                props.prop.client[`c${selectedProduct?.current?.id}__status`] = "Aceito"
            }
            setNewStatus(-1)
            props.prop?.doSum()
        } catch (error) {
            setLoading(false)
            setType({
                name: "alertMsg",
                type: "information",
                value: { message: error?.response?.data?.message == undefined ? "Erro interno ao atualizar o status do cliente" : error?.response?.data?.message },
            })
            setShow(true)
        }
        setLoading(false)
    }
    async function showClientActivation(type) {
        // type - função
        // 1 - ativação inicial do cliente
        // 2 - vizualização do formulário de ativação já preenchido
        // 3 - vizualização e edição de formulário para transferência
        // 4 - cliente transferido, o broker precisa visualizar para ativá-lo
        // 5 - edição de formulário atualmente vigente
        setShow(true);
        setType({ name: "showVbaClientActivation", value: props?.prop?.clientData, type: type, handleSubmit: handleSubmit });
    }
    async function submitEvasion() {
        setLoading(false);
        if (comment !== null && comment != "") {
            try {
                const clientUpdate = await api.post(
                    `/api/v1/vba/setEvasion/${String(data[0])}`,
                    {
                        comment: comment,
                    },
                    {
                        headers: {
                            authorization: `Bearer ${tokenKey}`,
                        },
                    }
                );

                setType({ name: "successClientEdit", type: "information", value: { message: `${clientUpdate.data.message}` + " - " + ` ${titleize(data[1])}` } });
                setLoading(false);
                setEdited(true);
            } catch (error) {
                alert(error?.response?.data?.message);
                setLoading(false);
            }
        } else {
            alert("Você precisa deixar um comentário do motivo de marcar o cliente como evasão");
        }
        setLoading(false);
    }
    useEffect(() => {
        optUltraHigh.map((item) => {
            if(item.value == cloneAssuntoSelecionado){
                setAssuntoSelecionado(item.value)
            }
        })
    }, [cloneAssuntoSelecionado])

    async function handleSubmit(clientStatus) {
        setLoading(true);
        if (selectedBroker == null) {
            return;
        }
        if (selectedBroker == user.external_id && data[20] == null) {
            try {
                const clientUpdate = await api.get(`/api/v1/vba/set/${String(selectedBroker)}/${String(data[0])}`, {
                    headers: {
                        authorization: `Bearer ${tokenKey}`,
                    },
                });

                setType({ name: "successClientEdit", type: "information", value: { message: `${clientUpdate.data.message}` + " - " + ` ${titleize(data[1])}` } });
                setLoading(false);
                setEdited(true);
            } catch (error) {
                alert(error?.response?.data?.message);
                setLoading(false);
            }
        } else {
            try {
                const clientUpdate = await api.get(`/api/v1/vba/transfer/${String(selectedBroker)}/${String(data[0])}/${clientStatus}`, {
                    headers: {
                        authorization: `Bearer ${tokenKey}`,
                    },
                });

                setType({ name: "successClientEdit", type: "information", value: { message: `${clientUpdate.data.message}` + " - " + ` ${titleize(data[1])}` } });
                setLoading(false);
                setEdited(true);
            } catch (error) {
                alert(error?.response?.data?.message);
                setLoading(false);
            }
        }
    }

    const frequency = [
        { value: 1, description: "Semanal" },
        { value: 2, description: "Quinzenal" },
        { value: 3, description: "Mensal" },
        { value: 4, description: "Bimestral" },
    ];

    const preferency = [
        { value: 1, description: "Whatsapp" },
        { value: 2, description: "Ligação" },
        { value: 4, description: "Reunião presencial" },
        { value: 5, description: "Reunião online" },
    ];

    async function getData() {
        if (show) {
            selectedProduct.current = props.prop.product
            setTypeAbc(selectedProduct.current.type.id)
            setItems([])
            setFilteredItems([])
            getOperationId()
            getCategory()
            getSpeech()
            getLink()
            setDefaultComments({
                2: { comment: `Feito contato com o cliente sobre o produto ` + selectedProduct?.current?.description + `. Cliente ficou de avaliar a sugestão.` },
                3: { comment: `Feito o envio de ordem do produto ` + selectedProduct?.current?.description + ` para o cliente.` },
                6: { comment: `Feito contato com o cliente sobre o produto ` + selectedProduct?.current?.description + `. Cliente não teve interesse na oferta e recusou o produto.` },
                7: { comment: `Cliente não possui perfil para o produto ` + selectedProduct?.current?.description + `.` },
            })
            setRefusalResasons([
                {
                    id: "recurso",
                    label: "Falta de recurso p/ alocar",
                    comment: `Abordado o investidor sobre a oportunidade ${selectedProduct?.current?.description}, porém o cliente está sem recurso disponível.`,
                },
                {
                    id: "prazo",
                    label: "Prazo do produto",
                    comment: `Abordado o investidor sobre a oportunidade ${selectedProduct?.current?.description}, porém o cliente não aceitou o prazo do produto.`,
                },
                {
                    id: "cenario",
                    label: "Cenário Adverso",
                    comment: `Abordado o investidor sobre a oportunidade ${selectedProduct?.current?.description}, porém o cliente não aceitou o produto por receio com o cenário macro atual.`,
                },
                {
                    id: "preferencia",
                    label: "Prefere outro produto",
                    comment: `Abordado o investidor sobre a oportunidade ${selectedProduct?.current?.description}, porém o cliente não aceitou o produto por preferir outras classes de ativos no momento.`,
                },
                {
                    id: "taxa",
                    label: "Taxa não atrativa",
                    comment: `Abordado o investidor sobre a oportunidade ${selectedProduct?.current?.description}, porém o cliente não aceitou o produto por não achar atrativa a taxa do ativo.`,
                },
                { id: "outros", label: "Outros", comment: `Escreva o motivo!` },
            ])

            //separa os produtos que o cliente tem e nao tem oportunidade > que 0. Um campo novo é inserido no objeto do produto
            props.prop.products.map((p) => {
                if (props.prop.client[`c${p.id}__objective`] > 0) {
                    p.hasOpportunity = true
                } else {
                    p.hasOpportunity = false
                }
            })
        }
    }
    const autocompleteRef = useRef(null);
    useEffect(() => {
        if(segClientDigitalExcecaoEdited || segClientPrivateExcecaoEdited){
            autocompleteRef.current.click()
            autocompleteRef.current.click()
            autocompleteRef.current.inputMode = ' '
            // autocompleteRef.current.setOpen(true)
        }
    }, [segClientDigitalExcecaoEdited, segClientPrivateExcecaoEdited])

    useEffect(() => {
        if (show) {
            getBrokers()
            getData()
            getLiquidez()
            setTabActive(0)

        }
        else{
            clear()
            setReuniao_realizada(false)
        }
    }, [show])

    useEffect(() => {
        if(tabActive == 1) {
            getData()
            setData(props?.prop?.clientData)
        }
    }
    , [tabActive])

    useEffect(() => {
        if (selectedProduct.current) {
            getLiquidez()
            getOperationId()
            getCategory()
            getSpeech()
            getLink()
            getTimeline()
            setComentTimeline("")
            setNewStatus(-1)
        }
    }, [selectedProduct.current])

    useEffect(() => {
        var aux = {}
        selectedProduct.current?.categories?.map((x, index) => {
            x.displayId = index + 1
            aux[x.id] = x
        })
        setCategories(aux)

        const geral = selectedProduct.current?.categories?.filter((x) => x.geral_reference == true)
        if (geral?.length > 0) {
            setSelectedCategory(geral[0].id)
            setGeralCategory(geral[0].id)
        }

        if (show) selectedProduct.current = props.prop.product
    }, [show])

    function disableCopying() {
        setCopyingDescription(false)
        var aux = copyingLink.map((l) => false)
        setCopyingLink(aux)
    }

    async function getBrokers() {
        try {
            const brokersResult = await api.get(`/api/v1/vba/availableBrokers/${brokerID}`, {
                headers: {
                    authorization: `Bearer ${tokenKey}`,
                },
            });
            let defaultBruki = brokersResult.data.data.default[0]?.broker_id ? brokersResult.data.data.default[0]?.broker_id : "";
            setDefaultBroker(defaultBruki);
            const aux = defaultBruki == props.prop.client.broker_id ? brokersResult.data.data.brokers.filter((x) => parseInt(x.external_id) != defaultBruki) : brokersResult.data.data.brokers.sort((x, y) => (x.external_id == defaultBruki ? -1 : y.external_id == defaultBruki ? 1 : 0));
            setBrokerListDigital(aux.filter((x) => x.profile_id == 2));
            setBrokerListPrivate(aux.filter((x) => x.profile_id == 3));
            setBrokerListAll(aux);

            setLoading(false);
        } catch (error) {
            alert("brokers não encontrados", error?.response?.data?.message);
        }
    }

    function clear() {
        setLink({
            boletar: "#/",
            acompanhamento: "#/",
        })
        setComentTimeline("")
        setContactType(5)
        setFilters([])
        setItems([])
        setFilteredItems([])
        setClientSpeech("")
        setNewStatus(-1)
        setActiveRefusalReasonBox(false)
        setSelectedReason("")
        setRefusalReason("")
        setBoletar(false)
        setBoletarValue(0)
        setOperationId(null)
        setCategoryInfos(null)
        setCopyingDescription(false)
        setCopyingLink([])

        setSegClientDigitalExcecaoEdited(false);
        setSegClientPrivateExcecaoEdited(false);
        setLanguageFormalClientExcecaoEdited(false);
        setLanguageInformalClientExcecaoEdited(false);
        setClientExcecaoEdited(false);
        setContactClientExcecaoEdited(false);
        setNonContactClientExcecaoEdited(false);
        setServicoHBEdited(false);
        setEdited(false);
        setSegClientDigitalExcecao(false);
        setSegClientPrivateExcecao(false);
        setLanguageFormalClientExcecao(false);
        setLanguageInformalClientExcecao(false);
        setClientExcecao(false);
        setContactClientExcecao(false);
        setNonContactClientExcecao(false);


        selectedProduct.current = null
        // selectedClient.current = null
        setOperationId(null)
        setCategoryInfos(null)
        setCopyingDescription(false)
        setCopyingLink([])
        setRefusalReason("")
    }

    useEffect(() => {
        if (show) {
            // pegar os dados do cliente
            setBrokerID(props?.prop?.clientData[15]);
            setPreferrendAtivos(props?.prop?.clientData[33])
            setRestricoesAtivos(props?.prop?.clientData[34])
            setSegClientDigitalExcecao(props.prop.clientData[29] == 'Digital' ? true : false)
            setSegClientPrivateExcecao(props.prop.clientData[29] == 'Private' ? true : false)
            setLanguageFormalClientExcecao(props.prop.clientData[35] == 'Formal' ? true : false)
            setLanguageInformalClientExcecao(props.prop.clientData[35] == 'Informal' ? true : false)
            setNotasGerais(props.prop.clientData[36])
            setContactClientExcecao(props.prop.clientData[32] == 'Sim' ? true : false)
            setNonContactClientExcecao(props.prop.clientData[32] == 'Não' ? true : false)
        }
    }, [show])

    async function handleSubmitComment() {
        if (comentTimeline !== null && comentTimeline != "") {
            //se status recusado add o motivo da recusa antes do texto
            var newMessage = ""
            if (newStatus == 6) {
                const reason = refusalReasons.filter((r) => r.id == selectedReason)
                newMessage = `Feito contato com o cliente sobre o produto ${selectedProduct.current?.description} porém o cliente recusou. Motivo: ${reason[0].label}. ${comentTimeline} `
            }
            try {
                const clientUpdate = await api.post(
                    `/api/v1/vba/sendComment/${props?.prop?.client?.client_id}?abc_products_id=${selectedProduct.current?.id}`,
                    {
                        comment: newStatus == 6 ? newMessage.replaceAll("'", "''") : comentTimeline.replaceAll("'", "''"),
                        commentType: contactType,
                        reuniao_realizada: reuniao_realizada,
                        tipoReuniao: cloneAssuntoSelecionado,
                    },
                    {
                        headers: {
                            authorization: `Bearer ${tokenKey}`,
                            // abc_products_id: props.prop.product.id,
                        },
                    }
                )
                setComentTimeline("")
                setContactType(5)
                getTimeline()
                if (newStatus >= 0) {
                    sendNewStatus()
                }
            } catch (error) {
                alert(error?.response?.data?.message)
            }
        } else {
            alert("O comentário nao pode ser vazio")
        }
    }

    async function filterTimeline(all) {
        var todosItems = all ? all : structuredClone(allItems ? allItems : [])
        var filteredItemsAux = []
        if (filters?.length > 0) {
            filteredItemsAux = todosItems?.filter((e) => filters?.indexOf(parseInt(e.type)) >= 0)
        } else {
            filteredItemsAux = todosItems
        }
        if (filterProduct.current) {
            filteredItemsAux = filteredItemsAux?.filter((e) => e?.abc_products_id == selectedProduct?.current?.id)
        }

        await setFilteredItems(filteredItemsAux)
        await setItems(filteredItemsAux?.length - 30 < 0 ? filteredItemsAux : filteredItemsAux?.slice(filteredItemsAux?.length - 30))
        if (show) scrollBottom()
    }

    async function updatePreferredContact() {
        if (selectedTipoPreferencialContato) {
            try {
                const clientUpdate = await api.put(
                    `/api/v1/vba/update/${String(data[0])}/preference/${selectedTipoPreferencialContato}`,
                    {},
                    {
                        headers: {
                            authorization: `Bearer ${tokenKey}`,
                        },
                    }
                );
                alert("Tipo de contato preferencial do cliente " + titleize(data[1]) + " atualizado com sucesso!");
                setLoading(false);
                setEdited(true);
                clear();
            } catch (error) {
                alert(error?.response?.data?.message);
                setLoading(false);
            }
        }
    }

    async function getTimeline() {
        await setLoadingTimeline(true)
        try {
            const timeline = await api.get(`/api/v1/vba/clientTimeline/${props?.prop?.client?.client_id}`, {
                headers: {
                    authorization: `Bearer ${tokenKey}`,
                },
            })

            await setAllItems(timeline?.data?.data)

            await filterTimeline(timeline?.data?.data)

            await setLoadingTimeline(false)
            if (show) scrollBottom()
        } catch (error) {
            alert(error?.response?.data?.message)
            await setLoadingTimeline(false)
        }
    }

    async function updateItemsList() {
        var auxAllItems = structuredClone(filteredItems)
        var objDiv = document.getElementById("timelineCommentBoxAbc")
        var scrollNext = objDiv?.scrollHeight
        if (itemQty != auxAllItems.length) {
            if (auxAllItems.length - (itemQty + 30) <= 0) {
                await setItems(auxAllItems)
                await setItemQty(auxAllItems.length)
            } else {
                await setItems(auxAllItems.slice(auxAllItems.length - (itemQty + 30)))
                await setItemQty(itemQty + 30)
            }
            if (objDiv) objDiv.scrollTop = objDiv?.scrollHeight - scrollNext
        }
    }

    async function scrollBottom() {
        var objDiv = document.getElementById("timelineCommentBoxAbc")
        if (objDiv) {
            objDiv.scrollTop = objDiv?.scrollHeight
        }
    }
    function segClientDigitalExceptionEdited(newValue) {
        const exception = props.prop.clientData[25] === 0 ? true : false;
        if (exception != newValue) {
            setSegClientDigitalExcecaoEdited(true);
        } else {
            setSegClientDigitalExcecaoEdited(false);
        }
    }
    function segClientPrivateExceptionEdited(newValue) {
        const exception = props.prop.clientData[25] === 1 ? true : false;
        if (exception != newValue) {
            setSegClientPrivateExcecaoEdited(true);
        } else {
            setSegClientPrivateExcecaoEdited(false);
        }
    }

    function languageFormalClientExceptionEdited(newValue) {
        const exception = props.prop.clientData[36] === 0 ? true : false;
        if (exception != newValue) {
            setLanguageFormalClientExcecaoEdited(true);
        } else {
            setLanguageFormalClientExcecaoEdited(false);
        }
    }

    function languageInformalClientExceptionEdited(newValue) {
        const exception = props.prop.clientData[36] === 1 ? true : false;
        if (exception != newValue) {
            setLanguageInformalClientExcecaoEdited(true);
        } else {
            setLanguageInformalClientExcecaoEdited(false);
        }
    }

    function contactClientExceptionEdited(newValue) {
        const exception = props.prop.clientData[28] === "Sim" ? true : false;
        if (exception != newValue) {
            setContactClientExcecaoEdited(true);
        } else {
            setContactClientExcecaoEdited(false);
        }
    }

    function nonContactClientExceptionEdited(newValue) {
        const exception = props.prop.clientData[29] === "Sim" ? true : false;
        if (exception != newValue) {
            setNonContactClientExcecaoEdited(true);
        } else {
            setNonContactClientExcecaoEdited(false);
        }
    }


    return (
        <div className="containerModalDashDetails" style={{ zoom: `${window.innerHeight < 575 ? 0.95 : 1}` }}>
            <div className="clientControlHeader" style={{ zoom: `${window.innerHeight > 760 ? 1 : window.innerHeight > 575 ? 0.9 : 0.8}` }}>
                <div className="editBrancheBoxHeaderDash">
                    <div className="containerProductNameDash">
                        <p>
                            {props.prop?.client?.client_id} - {props.prop?.client?.client}:
                        </p>
                        <p>{selectedProduct.current?.description} </p>
                    </div>
                    <div style={{ display: "grid", gridTemplateColumns: "auto auto", alignItems: "end" }}>
                        <p style={{ marginRight: "1rem" }}>{user.external_id + " - " + user.full_name} </p>
                        <button
                            onClick={() => {
                                // setEdited(true)
                                clear()
                                setShow(false)
                            }}
                            className="modalCloseButtonDashboard"
                            style={{ marginRight: "12px", height: "36px", width: "36px" }}
                        >
                            <AiFillCloseCircle />
                        </button>
                    </div>
                </div>
            </div>
            <div className="containerClientMenuDashActionsAbas" style={{display:"flex", flexDirection:'row', alignItems:'center'}}>
                <span 
                    className={tabActive == 0 ? 'campanhaSelecionada' : 'campanhasNaoSelecionada'}
                    onClick={() => {
                        setTabActive(0)
                    }}
                    >Campanhas
                </span>
                <span 
                    className={tabActive == 1 ? 'campanhaSelecionada' : 'campanhasNaoSelecionada'}
                    onClick={() => {
                        setTabActive(1)
                    }}
                    >
                        Informações do Cliente
                </span>
            </div>
            <div
                className={tabActive == 0 ? 'containerClientMenuAbcActionsCampanhas' : 'containerClientMenuAbcActionsInfosClient'}
                style={{
                    marginTop: '-1rem' ,
                    zoom: `${window.innerHeight > 760 ? 1 : window.innerHeight > 575 ? 0.9 : window.innerHeight > 500 ? 0.8 : 0.65}`,
                    height: `${window.innerHeight > 760 ? "calc(100vh - 10rem)" : window.innerHeight > 575 ? "calc(100vh - 4rem)" : "calc(100vh - 4rem)"}`,
                }}
            >
                
                {/* lista de produtos */}
            

                
                    <div style={{display: tabActive != 0 ? 'none' : 'block'}} className="listClientsModalAbcDash">
                        <div
                            style={{
                                color: "#D4AC00",
                                fontWeight: "bold",
                                paddingLeft: "1rem",
                                paddingBottom: "1rem",
                            }}
                        >
                            Nome do produto
                        </div>

                        <>
                            <div
                                style={{
                                    color: "#D4AC00",
                                    // fontWeight: "bold",
                                    paddingLeft: "1rem",
                                    paddingBottom: "1rem",
                                }}
                            >
                                Campanhas para o cliente
                            </div>
                            <div className="listClientsBodyModalAbcAbrir">
                                {props.prop?.products?.map((p, index) => {
                                    if (!p.hasOpportunity) return null
                                    const client_cat = props?.prop?.client["c" + p?.id + "__category_id"]
                                    var statusClient = String(props?.prop?.client["c" + p?.id + "__status"]).toUpperCase()
                                    var statusColor =
                                        statusClient == "PENDENTE"
                                            ? "#D4AC00"
                                            : statusClient == "ABORDADO"
                                            ? "#6B94DE"
                                            : statusClient == "ACEITO"
                                            ? "#c084fc"
                                            : statusClient == "BOLETADO"
                                            ? "#58B935"
                                            : statusClient == "CANCELADO"
                                            ? "#FF83FE"
                                            : statusClient == "RECUSADO"
                                            ? "#f76a6a"
                                            : statusClient == "NÃO ADERENTE"
                                            ? "#000000"
                                            : statusClient == "ANÁLISE DO BACKOFFICE"
                                            ? "#2BC3BB"
                                            : "#8C34BA"

                                    return (
                                        <button
                                            key={index}
                                            className={selectedProduct.current && p.id == selectedProduct.current.id ? "listClientsItemActiveAbcAbrirDash" : "listClientsItemInativeAbcAbrirDash"}
                                            title={titleize(p.description)}
                                            onClick={() => {
                                                // c.categoryName = id_display.title
                                                // c.categoryLink = id_display?.link?.split(" ")
                                                selectedProduct.current = p
                                                var aux = [false]
                                                // p.categoryLink?.forEach(() => {
                                                //     aux.push(false)
                                                // })
                                                setCopyingLink(aux)
                                            }}
                                        >
                                            <div className="statusColorDivAbc" title={titleize(String(props.prop?.client["c" + selectedProduct.current?.id + "__status"]))}>
                                                <BsFillSquareFill
                                                    color={statusColor}
                                                    className="statusColorDivAbc"
                                                    title={titleize(String(props.prop?.client["c" + p.id + "__status"] ? props.prop?.client["c" + p.id + "__status"] : "Indefinido"))}
                                                />
                                            </div>
                                            {titleize(p.description)}
                                        </button>
                                    )
                                })}
                            </div>

                            <div
                                style={{
                                    color: "#D4AC00",
                                    // fontWeight: "bold",
                                    paddingLeft: "1rem",
                                    paddingBottom: "1rem",
                                }}
                            >
                                Outras Campanhas
                            </div>
                            <div className="listClientsBodyModalAbcAbrir">
                                {props.prop?.products?.map((p, index) => {
                                    if (p.hasOpportunity) return null
                                    const client_cat = props.prop.client["c" + p.id + "__category_id"]
                                    var statusClient = String(props.prop.client["c" + p.id + "__status"]).toUpperCase()
                                    var statusColor =
                                        statusClient == "PENDENTE"
                                            ? "#D4AC00"
                                            : statusClient == "ABORDADO"
                                            ? "#6B94DE"
                                            : statusClient == "ACEITO"
                                            ? "#c084fc"
                                            : statusClient == "BOLETADO"
                                            ? "#58B935"
                                            : statusClient == "CANCELADO"
                                            ? "#FF83FE"
                                            : statusClient == "RECUSADO"
                                            ? "#f76a6a"
                                            : statusClient == "NÃO ADERENTE"
                                            ? "#000000"
                                            : statusClient == "ANÁLISE DO BACKOFFICE"
                                            ? "#2BC3BB"
                                            : "#8C34BA"

                                    return (
                                        <button
                                            key={index}
                                            className={selectedProduct.current && p.id == selectedProduct.current.id ? "listClientsItemActiveAbcAbrirDash" : "listClientsItemInativeAbcAbrirDash"}
                                            title={titleize(p.description)}
                                            onClick={() => {
                                                // c.categoryName = id_display.title
                                                // c.categoryLink = id_display?.link?.split(" ")
                                                selectedProduct.current = p
                                                var aux = [false]
                                                // p.categoryLink?.forEach(() => {
                                                //     aux.push(false)
                                                // })
                                                filterTimeline()
                                                setCopyingLink(aux)
                                            }}
                                        >
                                            <div className="statusColorDivAbc" title={titleize(String(props.prop?.client["c" + selectedProduct.current?.id + "__status"]))}>
                                                <BsFillSquareFill
                                                    color={statusColor}
                                                    className="statusColorDivAbc"
                                                    title={titleize(String(props.prop?.client["c" + p.id + "__status"] ? props.prop?.client["c" + p.id + "__status"] : "Indefinido"))}
                                                />
                                            </div>
                                            {titleize(p.description)}
                                        </button>
                                    )
                                })}
                            </div>
                        </>
                    </div>
                    {/* <div className="divisionNameProductSubcategory"></div> */}
                    {/* speech do cliente */}
                    <div
                        style={{
                            display: tabActive != 0 ? 'none' : "grid",
                            gridTemplateRows: "auto auto 3fr",
                            margin: "1rem",
                        }}
                    >
                        <div >
                            <div className="containerInfosUserDetailsModal">
                                {
                                    tabActiveInfos == 0 ?
                                    <div style={{ marginLeft: "26px", overflowX: "auto", maxHeight: "10rem" }}>
                                        <span>
                                            <span style={{ color: "#D4AC00", fontWeight: "bold", fontSize: "1.2rem" }}>Informações do produto</span> <br />
                                        </span>
                                        <span>
                                            <span style={{ color: "#D4AC00", fontWeight: "bold" }}>Oportunidade: </span>
                                            {props.prop.client[`c${selectedProduct.current?.id}__objective`] ? toBRL(props.prop.client[`c${selectedProduct.current?.id}__objective`]) : "-"}
                                        </span>
                                        <br />

                                        <span>
                                            <span style={{ color: "#D4AC00", fontWeight: "bold" }}>Realizado: </span>
                                            {props.prop.client[`c${selectedProduct.current?.id}__value`] ? toBRL(props.prop.client[`c${selectedProduct.current?.id}__value`]) : "-"}
                                        </span>
                                        <br />

                                        <span>
                                            <span style={{ color: "#D4AC00", fontWeight: "bold" }}>Receita estimada: </span>
                                            {props.prop.client
                                                ? props.prop.client["c" +selectedProduct.current?.id + "__value"] > 0 && props.prop.client["c" +selectedProduct.current?.id + "__value"] != null
                                                    ? toBRL(props.prop.client["c" +selectedProduct.current?.id + "__value"] * selectedProduct.current?.fee)
                                                    : props.prop.client["c" +selectedProduct.current?.id + "__objective"] > 0 && props.prop.client["c" +selectedProduct.current?.id + "__objective"] != null
                                                    ? toBRL(props.prop.client["c" +selectedProduct.current?.id + "__objective"] * selectedProduct.current?.fee)
                                                    : '-'
                                                : "-"}
                                        </span>

                                        <br />
                                        <span>
                                            <span style={{ color: "#D4AC00", fontWeight: "bold" }}>FEE: </span>
                                            {selectedProduct.current?.fee ? (selectedProduct.current?.fee * 100).toFixed(2) + "%" : "N/C"}
                                        </span>
                                        <br />

                                        <span>
                                            <span style={{ color: "#D4AC00", fontWeight: "bold" }}>Descrição: </span>
                                            {selectedProduct.current?.card_description}
                                        </span>

                                        <br />
                                        <span>
                                            <span style={{ color: "#D4AC00", fontWeight: "bold" }}>Prioridade: </span>
                                            {props.prop?.prioritys.find((p) => p.id == selectedProduct.current?.priority)?.value}
                                        </span>
                                        <br />
                                        <span>
                                            <span style={{ color: "#D4AC00", fontWeight: "bold" }}>Produto: </span>
                                            {selectedProduct.current?.type?.description}
                                        </span>
                                        <br />
                                        <span>
                                            <span style={{ color: "#D4AC00", fontWeight: "bold" }}>Data Inicío: </span>
                                            {selectedProduct.current?.start ? datetimeToDatePtBR(selectedProduct.current?.start) : null}
                                        </span>

                                        <br />
                                        <span>
                                            <span style={{ color: "#D4AC00", fontWeight: "bold" }}>Data Fim: </span>
                                            {selectedProduct.current?.finish ? datetimeToDatePtBR(selectedProduct.current?.finish) : null}
                                        </span>

                                        <br />
                                        <span>
                                            <span style={{ color: "#D4AC00", fontWeight: "bold" }}>Data Liquidação: </span>
                                            {selectedProduct.current?.data_liquidacao ? datetimeToDatePtBR(selectedProduct.current?.data_liquidacao) : null}
                                        </span>
                                    </div>
                                    : <></>
                                }
                            </div>
                        </div>
                        {typeAbc != 1 ? (
                        <>
                        {/* <div className="divisionNameProductSubcategory"></div> */}
                                <div className="containerClientMenuOptionsAbcActionsDash" style={{ justifyContent:'center', minWidth: "16rem", justifyItems:'center', borderRadius:'0.5rem', margin:'0.5rem 0', minHeight:'10rem', height: boletar || newStatus == 3 ? '250px' : 'inherit'}}>
                                <div className="containerNameAbcActionsDash" style={{margin:'0',width:'inherit'}}>
                                    <div className="clientInfoAbcActionsDash">
                                        <div
                                            style={{
                                                color: "#D4AC00",
                                                fontWeight: "bold",
                                                fontSize: "1.2rem",
                                                marginTop: "1rem",
                                            }}
                                        >
                                            Alterar Status{" "}
                                            </div>
                                    </div>
                                </div>
                                <div>
                                <span
                                        style={{
                                            fontWeight: "bold",
                                            paddingTop: "1rem",
                                            // marginTop: '1rem'
                                            color: "#BCBCBC",
                                        }}
                                    >
                                        Status Atual:{" "}
                                        {titleize(
                                            props.prop.client && props.prop.client[`c${selectedProduct.current?.id}__status`] ? props.prop.client[`c${selectedProduct.current?.id}__status`] : "Indefinido"
                                        )}
                                    </span>
                                </div>
                                <div className="containerGeneralChangeStatusAbcActions" style={{height:'0'}}>
                                    {/* <div className="containerChangeStatusTitleAbcActions">
                                        <div className="ChangeStatusTitleAbcActions">Alterar Status</div>
                                    </div> */}
                                    <div className="containerChangeStatusAbcActionsDash"
                                        style={{display:"flex", flexDirection:'row', justifyContent:"center", scale:'0.9'}}
                                    >
                                        
                                        <button
                                            style={{ background: newStatus === 2 ? "#C49E18" : "#868686" }}
                                            className="buttomGray360"
                                            onClick={() => {
                                                setActiveRefusalReasonBox(false)
                                                setBoletar(false)
                                                setCloseMessage(false)
                                                if (newStatus === 2) {
                                                    setCloseMessage(true)
                                                    setNewStatus(-1)
                                                } else updateStatus(2)
                                            }}
                                        >
                                        <p>Abordado</p>
                                        </button>
                                        <button
                                            style={{ background: activeRefusalReasonBox ? "#C49E18" : "#868686" }}
                                            className="buttomGray360"
                                            onClick={() => {
                                                setActiveRefusalReasonBox(!activeRefusalReasonBox)
                                                setBoletar(false)
                                                setNewStatus(-1)
                                            }}
                                        >
                                            <p>Recusado</p>
                                        </button>
                                        <button
                                            className="buttomGray360"
                                            style={{ background: newStatus === 7 ? "#C49E18" : "#868686" }}
                                            onClick={() => {
                                                setActiveRefusalReasonBox(false)
                                                setBoletar(false)
                                                setCloseMessage(false)
                                                if (newStatus === 7) {
                                                    setCloseMessage(true)
                                                    setNewStatus(-1)
                                                } else updateStatus(7)
                                            }}
                                        >
                                            <p>Não Aderente</p>
                                        </button>
                                    
                                        <button
                                            className="buttomGray360"
                                            style={{ background: boletar ? "#C49E18" : "#868686", marginRight: '0', display: user.type == 3  ? 'none' : 'block' }}
                                            onClick={() => {
                                                setActiveRefusalReasonBox(false)
                                                setBoletar(!boletar)
                                                setNewStatus(-1)
                                                setCloseMessage(true)
                                            }}
                                        >
                                            Aceito
                                        </button>
                                        {user.type <= 3 ? (
                                            <>
                                                {selectedProduct.current?.type.id != 3 ? <button
                                                    className="buttomGray360"
                                                    style={{ }}
                                                    onClick={() => {
                                                    //   const win = window.open(link.boletar, "_blank");
                                                    //   win.focus();
                                                    setType({ 
                                                        name: selectedProduct.current?.type.id == 1 ? "boletarEstruturada" : 'boletarOfertaPublica', 
                                                        props: {product : props?.prop?.product, client: props?.prop?.client, 
                                                                clients: props?.prop?.clients, 
                                                                products: props?.prop?.products,
                                                                prioritys: props?.prop?.prioritys,
                                                                clientData: props?.prop?.clientData,
                                                                doSum: props?.prop?.doSum,
                                                                outros : props?.prop?.outros,
                                                                returnTo: 'detailsClient'} });
                                                    
                                                    }}>
                                                    Boletar
                                                    </button> : 
                                                    <button
                                                    style={{ background: newStatus === 3 ? "#C49E18" : "#868686", }}
                                                    className="buttomGray360"
                                                    onClick={() => {
                                                        setActiveRefusalReasonBox(false)
                                                        setBoletar(false )
                                                        setCloseMessage(false)
                                                        if (newStatus === 3) {
                                                            setCloseMessage(true)
                                                            setNewStatus(-1)
                                                        } else updateStatus(3)
                                                    }}>
                                                    Solicitar
                                                </button>}
                                            </>) : (<></>
                                        )}
                                    </div>


                                        {boletar || newStatus == 3 ? (
                                            <div style={{padding:'0 2rem'}}>
                                                <div style={{display:"flex", flexDirection: "row", gap:'1rem'}}>
                                                        <CurrencyTextField
                                                            style={{ height: "2.4rem", width: '80%'}}
                                                            variant="outlined"
                                                            value={boletarValue}
                                                            currencySymbol="R$"
                                                            className={classes.styleForClients}
                                                            outputFormat="number"
                                                            decimalCharacter=","
                                                            digitGroupSeparator="."
                                                            onChange={(event, value) => {
                                                                setBoletarValue(value)
                                                            }}
                                                        />

                                                        <button
                                                            className="buttomGray360"
                                                            style={{ background: boletar ? "#C49E18" : "#868686", width: '30%' }}
                                                            onClick={() => {
                                                                setActiveRefusalReasonBox(false)
                                                                setBoletar(!boletar)
                                                                submitBoleta()
                                                            }}
                                                        >
                                                            Enviar
                                                        </button>
                                                    </div>
                                                <span style={{ color: "red", fontSize: "0.9rem" }}>* A boleta deve ser enviada via HUB e será registarda aqui automaticamente no próximo dia útil</span>
                                            </div>
                                        ) : (
                                            <></>
                                        )}
                                </div>

                                {newStatus >= 0 && newStatus != 3 ? (
                                    <div className="suggestionMessageDash alertMessageAbc" style={{marginTop:'60px'}}>
                                        <div className="suggestionMessageHeader">A mensagem ao lado será registrada.</div>

                                        <div
                                            className="suggestionMessageBody"
                                            style={{
                                                display: "grid",
                                                gridTemplateColumns: "auto 2rem",
                                                bottom: "0px",
                                                paddingTop: "0px"
                                            }}
                                        >
                                            <div>Adicione uma descrição mais completa se necessário.</div>
                                            <div>
                                            <FiArrowRight color size="40" />
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div></div>
                                )}
                            </div>
                            {/* <div className="divisionNameProductSubcategory"></div> */}
                        
                        </>
                    ) : (
                        <>
                            <div style={{display: tabActive != 0 ? 'none' : 'block'}}></div>
                            <div style={{display: tabActive != 0 ? 'none' : 'block'}}></div>
                            <div style={{display: tabActive != 0 ? 'none' : 'block'}}></div>

                            {/* <div className="divisionNameProductSubcategory"></div> */}
                        </>
                    )}
                        <div
                            className="containerClientMenuSubcategoryAbcActionsDash"
                            style={{
                                height: newStatus == 3 || ( newStatus != -1) && closeMessage == false || boletar ? `${
                                    window.innerHeight > 760
                                        ? "calc(100vh - 40.5rem)"
                                        : window.innerHeight > 575
                                        ? "calc(100vh - 34.5rem)"
                                        : window.innerHeight > 480
                                        ? "calc(100vh - 30.8rem)"
                                        : "calc(100vh - 25.5rem)"
                                }`: newStatus == -1 ||!boletar ? `${
                                    window.innerHeight > 760
                                        ? "calc(100vh - 35rem)"
                                        : window.innerHeight > 575
                                        ? "calc(100vh - 29rem)"
                                        : window.innerHeight > 480
                                        ? "calc(100vh - 25.3rem)"
                                        : "calc(100vh - 20rem)"
                                }` : '' ,
                            }}
                        >
                        
                
                            <div className="headerSubcategoryAbcActions"  >
                                <div className="headerSubcategoryTitleAbcActions" style={{paddingTop:'0',}}>
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            fontWeight: "bold",
                                            paddingLeft: "1rem",
                                            alignItems: 'center',
                                            justifyContent: 'space-between',
                                        }}
                                    >
                                        <span style={{color: "#D4AC00",}}>
                                            Discurso
                                        </span> 
                                        {(client_speech != null && client_speech != "") || 
                                        (selectedProduct.current?.vendor_speech != null && selectedProduct.current?.vendor_speech != "") ||
                                        (selectedProduct.current?.general_speech != null && selectedProduct.current?.general_speech != "") ? (
                                            <div
                                                className="containerIconCopyContentAbcActions"
                                                style={{ marginRight: "1rem" }}
                                                onClick={(e) => {
                                                    setCopyingDescription(true)
                                                    navigator.clipboard.writeText(client_speech != null && client_speech != "" ? client_speech : (selectedProduct.current?.vendor_speech ? selectedProduct.current?.vendor_speech : selectedProduct.current?.general_speech) )
                                                    setTimeout(disableCopying, 1000)
                                                }}
                                            >
                                                {!copyingDescription ? <MdContentCopy size={20} /> : <FaCheck size={20} className="iconCopyContentAbcActionsAtive" />}
                                            </div>
                                        ) : (
                                            <div></div>
                                        )}
                                    </div>
                                </div>
                                <div></div>
                                <span
                                    style={{
                                        fontWeight: "bold",
                                        paddingLeft: "2rem",
                                        // paddingTop: "1rem",
                                        // marginTop: '1rem'
                                        color: "#BCBCBC",
                                    }}
                                >
                                    {/* {categoryInfos?.title} */}
                                    {client_speech != null && client_speech != "" ? categoryInfos?.title : "Discurso comercial do produto"}
                                </span>
                            </div>
                            <div
                                className="containerSubcategoryDescriptionAbcActionsDash"
                                style={{
                                    display: "grid",
                                    // gridTemplateColumns: "auto 2.5rem",
                                    color: "black",
                                }}
                                >
                                <pre className="textAreaSpeechAbcDash">{
                                    (client_speech != null && client_speech != "") ? client_speech : (selectedProduct.current?.vendor_speech ? selectedProduct.current?.vendor_speech : selectedProduct.current?.general_speech)
                                }</pre>
                                
                                {/* <p className="descriptionTextAbc">{client_speech}</p> */}
                            </div>
                            <div className="linksBoxAbc">
                                {categoryInfos?.link ? (
                                    categoryInfos?.link.split(" ").map((l, index) => {
                                        return (
                                            <>
                                                <div key={index} className="containerClientMenuSubcategoryAbcActionsDash">
                                                    {l}
                                                </div>
                                                <div
                                                    className="containerIconCopyContentAbcActions"
                                                    onClick={async () => {
                                                        let aux = copyingLink.map((v, i, a) => (i == index ? true : v))
                                                        await setCopyingLink(aux)
                                                        navigator.clipboard.writeText(l)
                                                        setTimeout(disableCopying, 1000)
                                                    }}
                                                >
                                                    <div className="linkMenuModalBoxAbc">
                                                        {!copyingLink[index] ? <MdContentCopy size={20} /> : <FaCheck size={20} className="iconCopyContentAbcActionsAtive" />}
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    })
                                    ) : (
                                    <div className="headerSubcategoryTitleAbcActions">Link Indisponível</div>
                                )}
                            </div>
                        </div>
                    </div>
                    {/* Botões só aparacem se o produto nao for re RV */}
                
                { tabActive == 1 ? (
                    <div className="containerDetalhesEspecificos">
                        <span className="subTitle">Cliente:</span>
                        <div className="clientDetails">
                    <span className="itemDetail">
                        <a id="clientDetailFirstAElementVBA">Nome: </a>
                        <a>{titleize(data[1])}</a>
                    </span>
                    <span className="itemDetail">
                        <a id="clientDetailFirstAElementVBA">Email: </a>
                        <a>{data[2]}</a>
                    </span>
                    <span className="itemDetail">
                        <a id="clientDetailFirstAElementVBA">Net RV: </a>
                        <a>{data[23]}</a>
                    </span>
                    <span className="itemDetail">
                        <a id="clientDetailFirstAElementVBA">Perfil: </a>
                        <a>{titleize(data[3])}</a>
                    </span>
                    
                    <span className="itemDetail"  style={{display: props.prop.clientData[40] != 0 ? 'block': "none"}}>
                        <a id="clientDetailFirstAElementVBA">Quantidade de Reuniões Realizadas: </a>
                        <a>{data[40]}</a>
                    </span>
                    <span className="itemDetail"  style={{display: props.prop.clientData[40] != 0 ? 'block': "none"}}>
                        <a id="clientDetailFirstAElementVBA">Data da Última Reunião: </a>
                        <a>{data[39]}</a>
                    </span>
                    <span className="itemDetail" style={{display: props.prop.clientData[30] == 'Top Tier' ? 'block' : 'none' }} >
                        <a id="clientDetailFirstAElementVBA">Próxima Reunião Sugerida: </a>
                        <a>{proximaReuniao}</a>
                    </span>   
                    <span className="itemDetail">
                        <a id="clientDetailFirstAElementVBA">Status Receita: </a>
                        <a>{titleize(data[12])}</a>
                    </span>
                    <span className="itemDetail">
                    <button
                                className="botaoEvasaoVBA"
                                onClick={() => {
                                    showClientActivation(5);
                                }}>
                                Editar formulário de primeira reunião
                                <BsBoxArrowUpLeft style={{ marginLeft: "0.4rem" }} size={14} />
                            </button>
                    </span>
                    <span className="itemDetail">
                        <a id="clientDetailFirstAElementVBA">Tipo de contato <br/> preferencial: </a>
                        <select
                            className="selectClientDetails"
                            onChange={(e) => {
                                setToggleTipoPreferencialContato(true);
                                setSelectedTipoPreferencialContato(e.target.value);
                            }}>
                            {props?.prop?.clientData[37] == null ? (
                                <option selected value={0}>
                                    Pendente
                                </option>
                            ) : (
                                <></>
                            )}
                            {preferency.map((x) => {
                                if (props?.prop?.clientData[35] == x.value) {
                                    return (
                                        <option selected value={x.value}>
                                            {x.description}
                                        </option>
                                    );
                                    // <option selected value={props?.prop?.clientData[37] ? props?.prop?.clientData[37] : 0} >{props?.prop?.clientData[38] ? props?.prop?.clientData[38] : 'Pendente'}</option>
                                } else {
                                    return <option value={x.value}>{x.description}</option>;
                                }
                            })}
                        </select>
                        {toggleTipoPreferencialContato ? (
                            <>
                                <button
                                    className={toggleTipoPreferencialContato ? "selectClientDetailsButton" : "selectClientDetailsButtonDISABLED"}
                                    disabled={!toggleTipoPreferencialContato}
                                    onClick={() => {
                                        setToggleTipoPreferencialContato(false);
                                        setSelectedTipoPreferencialContato(rootTipoPreferencialContato);
                                    }}>
                                    Cancelar
                                </button>
                                <button
                                    className={toggleTipoPreferencialContato ? "selectClientDetailsButton" : "selectClientDetailsButtonDISABLED"}
                                    disabled={!toggleTipoPreferencialContato}
                                    onClick={() => {
                                        setToggleTipoPreferencialContato(false);
                                        setRootTipoPreferencialContato(selectedTipoPreferencialContato);
                                        updatePreferredContact();
                                    }}>
                                    Ok
                                </button>
                            </>
                        ) : (
                            <></>
                        )}
                    </span>
                    {/* <span className="itemDetail">
                        <a id="clientDetailFirstAElementVBA">Periodicidade de contato: </a>
                        <select
                            className="selectClientDetails"
                            onChange={(e) => {
                                setTogglePeriodicidadeContato(true);
                                setSelectedPeriodicidadeContato(e.target.value);
                            }}>
                            {props?.prop?.clientData[37] == null ? (
                                <option selected value={0}>
                                    Pendente
                                </option>
                            ) : (
                                <></>
                            )}
                            {frequency.map((x) => {
                                if (props?.prop?.clientData[37] == x.value) {
                                    return (
                                        <option selected value={x.value}>
                                            {x.description}
                                        </option>
                                    );
                                } else {
                                    return <option value={x.value}>{x.description}</option>;
                                }
                            })}
                        </select>
                        {togglePeriodicidadeContato ? (
                            <>
                                <button
                                    className={togglePeriodicidadeContato ? "selectClientDetailsButton" : "selectClientDetailsButtonDISABLED"}
                                    disabled={!togglePeriodicidadeContato}
                                    onClick={() => {
                                        setTogglePeriodicidadeContato(false);
                                        setSelectedPeriodicidadeContato(rootPeriodicidadeContato);
                                    }}>
                                    Cancelar
                                </button>
                                <button
                                    className={togglePeriodicidadeContato ? "selectClientDetailsButton" : "selectClientDetailsButtonDISABLED"}
                                    disabled={!togglePeriodicidadeContato}
                                    onClick={() => {
                                        setTogglePeriodicidadeContato(false);
                                        setRootPeriodicidadeContato(selectedPeriodicidadeContato);
                                        updateFrequency();
                                    }}>
                                    Ok
                                </button>
                            </>
                        ) : (
                            <></>
                        )}
                    </span> */}
                    <span className="itemDetail">
                                        <a id="clientDetailFirstAElementVBA">Modelo de Atendimento: </a>
                                        <select
                                            className="selectClientDetails"
                                            onChange={(e) => {
                                                setToggleServiceModel(true);
                                                setSelectedServiceModel(e.target.value);
                                            }}>
                                            {props?.prop?.clientData[30] == '-' ? (
                                                <option selected >
                                                    Selecione
                                                </option>
                                            ) : (
                                                <></>
                                            )}
                                            {serviceModel.map((x) => {
                                                if (props?.prop?.clientData[30] == x.description) {
                                                    return (
                                                        <option selected value={x.value}>
                                                            {x.description}
                                                        </option>
                                                    );
                                                } else {
                                                    return <option value={x.value }>{x.description}</option>;
                                                }
                                            })}
                                        </select>
                                        {toggleServiceModel ? (
                                            <>
                                                <button
                                                    className={toggleServiceModel ? "selectClientDetailsButton" : "selectClientDetailsButtonDISABLED"}
                                                    disabled={!toggleServiceModel}
                                                    onClick={() => {
                                                        setToggleServiceModel(false);
                                                        setSelectedServiceModel(serviceModel);
                                                    }}>
                                                    Cancelar
                                                </button>
                                                <button
                                                    className={toggleServiceModel ? "selectClientDetailsButton" : "selectClientDetailsButtonDISABLED"}
                                                    disabled={!toggleServiceModel}
                                                    onClick={() => {
                                                        setToggleServiceModel(false);
                                                        // setRootServiceModel(serviceModel);
                                                        updateServiceModel();
                                                    }}>
                                                    Ok
                                                </button>
                                            </>
                                        ) : (
                                            <></>
                                        )}
                                    </span>

                    <span className="itemDetail">
                        <a id="clientDetailFirstAElementVBA">Status Relacionamento: </a>
                        <a> {props.prop.clientData[26] ? props.prop.clientData[26] : ' '}</a>
                    </span>

                    
                        </div>

                        <span className="subTitle">Assessor:</span>
                        <div className="clientDetails">
                            <div className="clientDetailDualDetail">
                                <span className="itemDetail">
                                    <a id="clientDetailFirstAElementVBA">Nome: </a>
                                    <a>{titleize(data[10])}</a>
                                </span>

                                <span className="itemDetail">
                                    <a id="clientDetailFirstAElementVBA">Filial: </a>
                                    <a>{titleize(data[11])}</a>
                                </span>

                                <span className="itemDetail" style={{ gridColumnStart: 1, gridColumnEnd: 3 }}>
                                    <a id="clientDetailFirstAElementVBA">Time: </a>
                                    <a>{titleize(data[14])}</a>
                                </span>
                            </div>
                        </div>

                        <span className="subTitle">Broker:</span>
                        <div className="clientDetails2">
                        
                            <span className="itemDetail" style={{ marginTop: "0.8rem", marginRight: "1rem" }}>
                                {data[15] !== null ? (
                                    <>
                                        <a id="clientDetailFirstAElementVBA">Nome: </a>
                                        <a>{titleize(data[16])}</a>
                                    </>
                                ) : (
                                    <a className="itemNull">{titleize("sem broker associado")}</a>
                                )}
                            </span>
                            <div className="clientDetailDualDetail2">
                                    <span className="itemDetail">
                                    <span className="itemDetail">
                                <a id="clientDetailFirstAElementVBA">Segmento Cliente: </a> <br />

                                <a id="clientDetailSecondtAElementVBA">Digital: </a>
                                <input
                                    defaultChecked={props.prop.clientData[29] == 'Digital' ? "checked" : ""}
                                    checked={segClientDigitalExcecao ? "checked" : ""}
                                    type="checkbox"
                                    id="check"
                                    name="check"
                                    className="checkboxVBAExceptionClient"
                                    onChange={(e) => {
                                        setSegClientDigitalExcecao(true);
                                        segClientDigitalExceptionEdited(true);
                                        if (e.target.checked) {
                                            setSegClientPrivateExcecao(false);
                                            setSegClientPrivateExcecaoEdited(false);
                                        }
                                        alert('Você está mudando o segmento de atendimento deste cliente. Para concluir, selecione um broker na lista. Dê preferência ao broker referência.');
                                    }}></input>
                                <a id="clientDetailSecondtAElementVBA">Private: </a>
                                <input
                                    defaultChecked={props.prop.clientData[29] == 'Private' ? "checked" : ""}
                                    checked={segClientPrivateExcecao ? "checked" : ""}
                                    type="checkbox"
                                    id="check"
                                    name="check"
                                    className="checkboxVBAExceptionClient"
                                    onChange={(e) => {
                                        setSegClientPrivateExcecao(true);
                                        segClientPrivateExceptionEdited(true);
                                        if (e.target.checked) {
                                            setSegClientDigitalExcecao(false);
                                            setSegClientDigitalExcecaoEdited(false);
                                        }
                                        alert('Você está mudando o segmento de atendimento deste cliente. Para concluir, selecione um broker na lista. Dê preferência ao broker referência.');
                                    }}></input>
                                {/* {segClientDigitalExcecaoEdited ? (
                                    <button
                                        className="botaoSubmitExcecaoVBA"
                                        onClick={() => {
                                            submitHandleSubmitSegClientDigitalException();
                                        }}>
                                        Confirmar
                                    </button>
                                ) : (
                                    segClientPrivateExcecao ? (
                                        <button
                                            className="botaoSubmitExcecaoVBA"
                                            onClick={() => {
                                                submitHandleSubmitSegClientPrivateException();
                                            }}>
                                            Confirmar
                                        </button>
                                    ) : (
                                        <></>
                                    )
                                )} */}
                            </span>
                            
                        </span>
                        {parseInt(props.prop.client.broker_id) !== null || user.type < 3 ? (
                                <Autocomplete
                                    ref={autocompleteRef}
                                    options={segClientDigitalExcecao ? brokerListDigital.filter((x) => x.profile_id == 2) : segClientPrivateExcecao ? brokerListPrivate.filter((x) => x.profile_id == 3) : brokerListAll}
                                    onFocus={(e) => e.target.click()}
                                    hasPopupIcon={false}
                                    onChange={(event, newValue) => {
                                        setSelectedBroker(newValue ? newValue.external_id : null);
                                    }}
                                    className={"selectBroker"}
                                    getOptionLabel={(option) => parseInt(option?.external_id) + " - " + titleize(option?.full_name) + (option?.external_id == defaultBroker ? " (referência)" : "")}
                                    id="client"
                                    renderInput={(params) => (
                                        <TextField
                                            // value = {searchClientValue}
                                            label="Transferir para:"
                                            required={false}
                                            placeholder="Selecione:"
                                            {...params}
                                            onChange={(e) => {
                                                // handleChange(e)
                                            }}
                                        />
                                    )}
                                    renderOption={(props) => {
                                        return (
                                            <span {...props} className={props?.external_id == defaultBroker ? "defaultBroker" : ""} title="Este é o broker referência do assessor deste cliente.">
                                                {parseInt(props?.external_id) + " - " + titleize(props?.full_name) + (props?.external_id == defaultBroker ? " (referência)" : "")}
                                            </span>
                                        );
                                    }}
                                />
                            ) : user.type === 3 &&  parseInt(props?.external_id) === null ? (
                                <>
                                    <input
                                        type="Checkbox"
                                        className="checkBoxVBA"
                                        name="solicitar"
                                        onChange={(e) => {
                                            setSelectedBroker(e.target.checked ? user.external_id : null);
                                        }}
                                    />
                                    <a className="getClient">Gostaria de aderir esse cliente à minha base </a>
                                </>
                            ) : (
                                <></>
                            )}
                        <span className="itemDetail" style={{ gridColumnStart: 1, gridColumnEnd: 3, marginLeft: "auto", marginTop: "0.6rem", marginBottom: "0.6rem" }}>
                            {/* {(selectedBroker !== null) ? <button className='botaoSolicitarVBA' onClick={() => {handleSubmit()}}>Solicitar</button> : <></>} */}
                            {selectedBroker !== null ? (
                                <button
                                    className="botaoSolicitarVBA"
                                    style={{ margin: '0', paddingTop: '0' }}
                                    onClick={() => {
                                        verifyClientSegment();
                                        segClientDigitalExcecao ? submitHandleSubmitSegClientDigitalException() : segClientPrivateExcecao ? submitHandleSubmitSegClientPrivateException() : <></>;
                                    }}>
                                    Solicitar
                                </button>
                            ) : (
                                <></>
                            )}
                        </span>
                            
                        </div>
                            </div>
            </div>
        ) : null }

                { tabActive == 1 ? (
                    <div className="containerDetalhesEspecificos" style={{marginTop:'2rem'}}>
                        <div className="clientDetails" style={{marginTop:'1rem'}}>
                        <span className="itemDetail">
                        <a id="clientDetailFirstAElementVBA">Apelido Administrador da conta: </a>
                        <input
                            type="text"
                            className="campoTextoAtivoAdm"
                            value={admAcount}
                            onChange={(e) => {
                                if (e.target.value === props.prop?.clientData[6]) {
                                    setToggleAdmAcount(false);
                                    setAdmAcount(e.target.value);
                                } else {
                                    setToggleAdmAcount(true);
                                    setAdmAcount(e.target.value);
                                }
                            }}
                        />
                        {toggleAdmAcount ? (
                            <>
                                <button
                                    className={toggleAdmAcount ? "selectClientDetailsButton" : "selectClientDetailsButtonDISABLED"}
                                    disabled={!toggleAdmAcount}
                                    onClick={() => {
                                        setToggleAdmAcount(false);
                                        setAdmAcount(props.prop?.clientData[9] ? props.prop?.clientData[9] : '-');
                                    }}>
                                    Cancelar
                                </button>
                                <button
                                    className={toggleAdmAcount ? "selectClientDetailsButton" : "selectClientDetailsButtonDISABLED"}
                                    disabled={!toggleAdmAcount}
                                    onClick={() => {
                                        setToggleAdmAcount(false);
                                        updateAdmAcount();
                                    }}>
                                    Ok
                                </button>
                            </>
                        ) : (
                            <></>
                        )}
                        </span>
                    <span className="itemDetail">
                        <a id="clientDetailFirstAElementVBA">Contatar?: </a>
                        <a id="clientDetailSecondtAElementVBA">Sim: </a>
                        <input
                            defaultChecked={props.prop.clientData[32] == 'Sim' ? "checked" : ""}
                            checked={contactClientExcecao ? "checked" : ""}
                            type="checkbox"
                            id="check"
                            name="check"
                            className="checkboxVBAExceptionClient"
                            onChange={(e) => {
                                setContactClientExcecao(e.target.checked);
                                contactClientExceptionEdited(e.target.checked);
                                if (e.target.checked) {
                                    setNonContactClientExcecao(false);
                                    setNonContactClientExcecaoEdited(false);
                                }
                            }}></input>
                        <a id="clientDetailSecondtAElementVBA">Não: </a>
                        <input
                            defaultChecked={props.prop.clientData[32] == 'Não' ? "checked" : ""}
                            checked={nonContactClientExcecao ? "checked" : ""}
                            type="checkbox"
                            id="check"
                            name="check"
                            className="checkboxVBAExceptionClient"
                            onChange={(e) => {
                                setNonContactClientExcecao(e.target.checked);
                                nonContactClientExceptionEdited(e.target.checked);
                                if (e.target.checked) {
                                    setContactClientExcecao(false);
                                    setContactClientExcecaoEdited(false);
                                }
                            }}></input>

                        {contactClientExcecaoEdited ? (
                            <button
                                className="botaoSubmitExcecaoVBA"
                                onClick={() => {
                                    submitHandleSubmitContactClientException();
                                }}>
                                Confirmar
                            </button>
                        ) : (
                            nonContactClientExcecaoEdited ? (
                                <button
                                    className="botaoSubmitExcecaoVBA"
                                    onClick={() => {
                                        submitHandleSubmitNonContactClientException();
                                    }}>
                                    Confirmar
                                </button>
                            ) : (
                                <></>
                            )
                        )}
                    </span>
                    <span className="itemDetail">
                                    <a id="clientDetailFirstAElementVBA">Evasão: </a>
                                    <select
                                        style={{display:"inline-block" }}
                                        className="selectClientDetails"
                                        onChange={(e) => {
                                            setToggleEvasion(true);
                                            setSelectedEvasion(e.target.value);
                                        }}>
                                        {props?.prop?.clientData[31] == '-' ? (
                                            <option selected >
                                                Selecione
                                            </option>
                                        ) : (
                                            <></>
                                        )}
                                        {evasao.map((x) => {
                                            if (props?.prop?.clientData[31] == x.description) {
                                                return (
                                                    <option selected value={x.value}>
                                                        {x.description}
                                                    </option>
                                                );
                                            } else {
                                                return <option value={x.value }>{x.description}</option>;
                                            }
                                        })}
                                    </select>
                                    {selectedEvasion == 5 || props.prop.clientData[31] == 'Outros' ? (
                                        <textarea
                                            className="campoTextoAtivoAdm"
                                            value={textInner}
                                            style={{ maxHeight: "9rem", resize: "none", width: "90%", padding: "0.5rem"}}
                                            onKeyUp={(e) => {
                                                e.target.style.height = "inherit";
                                                e.target.style.height = `${e.target.scrollHeight}px`;
                                            }}
                                            onChange={(e) => {
                                                if (e.target.value === props.prop?.clientData[31]) {
                                                    setToggleEvasion(false);
                                                    setTextInner(e.target.value);
                                                } else {
                                                    setToggleEvasion(true);
                                                    setTextInner(e.target.value);
                                                }
                                            }}
                                        />
                                    ) : (
                                        <></>
                                    )
                                }
                                    {toggleEvasion ? (
                                        <>
                                            <button
                                                className={!toggleEvasion ? "selectClientDetailsButton" : "selectClientDetailsButtonDISABLED"}
                                                // disabled={!toggleEvasion}
                                                onClick={() => {
                                                    setToggleEvasion(false);
                                                    setSelectedEvasion(rootEvasion);
                                                    updateEvasion();
                                                    setMarcarEvasao(true)
                                                    if(selectedEvasion == 3){

                                                        setRootBrokerRelationship('Evasão')
                                                        setSelectedBrokerRelationship('Evasão')
                                                        updateBrokerRelationship();
                                                    }
                                                }}>
                                                Marcar como evasão
                                            </button>
                                            
                                            {/* botao cancelar */}
                                            <button
                                                className={toggleEvasion ? "selectClientDetailsButton" : "selectClientDetailsButtonDISABLED"}
                                                // disabled={!toggleEvasion}
                                                onClick={() => {
                                                    setToggleEvasion(false);
                                                    setSelectedEvasion(rootEvasion);
                                                    updateEvasion();
                                                    setMarcarEvasao(true)
                                                    if(selectedEvasion == 3){

                                                        setRootBrokerRelationship('Evasão')
                                                        setSelectedBrokerRelationship('Evasão')
                                                        updateBrokerRelationship();
                                                    }
                                                }}>
                                                Cancelar

                                            </button>
                                            
                                        </>
                                    ) : (
                                        <>
                                        </>
                                    )}
                                    
                                </span>
                    <span className="itemDetail">
                        <a id="clientDetailFirstAElementVBA">Preferências de ativos: </a> <br />
                        <textarea
                            className="campoTextoAtivoAdm"
                            value={preferrendAtivos}
                            style={{ maxHeight: "9rem", resize: "none", width: "90%", padding: "0.5rem"}}
                            onKeyUp={(e) => {
                                e.target.style.height = "inherit";
                                e.target.style.height = `${e.target.scrollHeight}px`;
                            }}
                            onChange={(e) => {
                                if (e.target.value === props.prop?.clientData[33]) {
                                    setTogglePreferrendAtivos(false);
                                    setPreferrendAtivos(e.target.value);
                                } else {
                                    setTogglePreferrendAtivos(true);
                                    setPreferrendAtivos(e.target.value);
                                }
                            }}
                        />
                        <br/>
                        {togglePreferrendAtivos ? (
                            <>
                                <button
                                    className={togglePreferrendAtivos ? "selectClientDetailsButton" : "selectClientDetailsButtonDISABLED"}
                                    disabled={!togglePreferrendAtivos}
                                    onClick={() => {
                                        setTogglePreferrendAtivos(false);
                                        setPreferrendAtivos(props.prop?.clientData[33] ? props.prop?.clientData[33] : '-');
                                    }}>
                                    Cancelar
                                </button>
                                <button
                                    className={togglePreferrendAtivos ? "selectClientDetailsButton" : "selectClientDetailsButtonDISABLED"}
                                    disabled={!togglePreferrendAtivos}
                                    onClick={() => {
                                        setTogglePreferrendAtivos(false);
                                        updatePreferrendAtivos();
                                    }}>
                                    Ok
                                </button>
                            </>
                        ) : (
                            <></>
                        )}
                    </span>
                    <span className="itemDetail">
                        <a id="clientDetailFirstAElementVBA">Restrições de ativos: </a> <br />
                        <textarea
                            type="text"
                            className="campoTextoAtivoAdm"
                            value={restricoesAtivos}
                            style={{ maxHeight: "9rem", resize: "none", width: "90%", padding: "0.5rem"}}
                            onKeyUp={(e) => {
                                e.target.style.height = "inherit";
                                e.target.style.height = `${e.target.scrollHeight}px`;
                            }}
                            onChange={(e) => {
                                if (e.target.value === props.prop?.clientData[34]) {
                                    setToggleRestricoesAtivos(false);
                                    setRestricoesAtivos(e.target.value);
                                } else {
                                    setToggleRestricoesAtivos(true);
                                    setRestricoesAtivos(e.target.value);
                                }
                            }}
                        />
                        <br/>
                        {toggleRestricoesAtivos ? (
                            <>
                                <button
                                    className={toggleRestricoesAtivos ? "selectClientDetailsButton" : "selectClientDetailsButtonDISABLED"}
                                    disabled={!toggleRestricoesAtivos}
                                    onClick={() => {
                                        setToggleRestricoesAtivos(false);
                                        setRestricoesAtivos(props.prop?.clientData[34] ? props.prop?.clientData[34] : '-');
                                    }}>
                                    Cancelar
                                </button>
                                <button
                                    className={toggleRestricoesAtivos ? "selectClientDetailsButton" : "selectClientDetailsButtonDISABLED"}
                                    disabled={!toggleRestricoesAtivos}
                                    onClick={() => {
                                        setToggleRestricoesAtivos(false);
                                        updateRestricoesAtivos();
                                    }}>
                                    Ok
                                </button>
                            </>
                        ) : (
                            <></>
                        )}
                    </span>
                    <span className="itemDetail">
                                    <a id="clientDetailFirstAElementVBA">Notas Gerais: </a> <br />
                                    <textarea
                                        type="text"
                                        className="campoTextoAtivoAdm"
                                        value={notasGerais}
                                        style={{ maxHeight: "9rem", resize: "none", width: "90%", padding: "0.5rem"}}
                                        onKeyUp={(e) => {
                                            e.target.style.height = "inherit";
                                            e.target.style.height = `${e.target.scrollHeight}px`;
                                        }}
                                        onChange={(e) => {
                                            if (e.target.value === props.prop.clientData[36]) {
                                                setToggleNotasGerais(false);
                                                setNotasGerais(e.target.value);
                                            } else {
                                                setToggleNotasGerais(true);
                                                setNotasGerais(e.target.value);
                                            }
                                        }}
                                    />
                                    <br/>
                                    {toggleNotasGerais ? (
                                        <>
                                            <button
                                                className={toggleNotasGerais ? "selectClientDetailsButton" : "selectClientDetailsButtonDISABLED"}
                                                disabled={!toggleNotasGerais}
                                                onClick={() => {
                                                    setToggleNotasGerais(false);
                                                    setNotasGerais(props.prop.clientData[36] ? props.prop.clientData[36] : ' ');
                                                }}>
                                                Cancelar
                                            </button>
                                            <button
                                                className={toggleNotasGerais ? "selectClientDetailsButton" : "selectClientDetailsButtonDISABLED"}
                                                disabled={!toggleNotasGerais}
                                                onClick={() => {
                                                    setToggleNotasGerais(false);
                                                    updateNotasGerais();
                                                }}>
                                                Ok
                                            </button>
                                        </>
                                    ) : (
                                        <></>
                                    )}
                                </span>
                    <span className="itemDetail">
                        <a id="clientDetailFirstAElementVBA">Linguagem Desejada: </a> <br />

                        <a id="clientDetailSecondtAElementVBA">Formal: </a>
                        <input
                            defaultChecked={props.prop.clientData[35] == 'Formal' ? "checked" : ""}
                            checked={languageFormalClientExcecao ? "checked" : ""}
                            type="checkbox"
                            id="check"
                            name="check"
                            className="checkboxVBAExceptionClient"
                            onChange={(e) => {
                                setLanguageFormalClientExcecao(e.target.checked);
                                languageFormalClientExceptionEdited(e.target.checked);
                                if (e.target.checked) {
                                    setLanguageInformalClientExcecao(false);
                                    setLanguageInformalClientExcecaoEdited(false);
                                }

                            }}></input>
                        <a id="clientDetailSecondtAElementVBA">Informal: </a>
                        <input
                            defaultChecked={props.prop.clientData[35] == 'Informal' ? "checked" : ""}
                            checked={languageInformalClientExcecao ? "checked" : ""}
                            type="checkbox"
                            id="check"
                            name="check"
                            className="checkboxVBAExceptionClient"
                            onChange={(e) => {
                                setLanguageInformalClientExcecao(e.target.checked);
                                languageInformalClientExceptionEdited(e.target.checked);
                                if (e.target.checked) {
                                    setLanguageFormalClientExcecao(false);
                                    setLanguageFormalClientExcecaoEdited(false);
                                }
                            }}></input>

                        {languageFormalClientExcecaoEdited ? (
                            <button
                                className="botaoSubmitExcecaoVBA"
                                onClick={() => {
                                    submitHandleSubmitLanguageFormalClientException();
                                }}>
                                Confirmar
                            </button>
                        ) : (
                            languageInformalClientExcecaoEdited ? (
                                <button
                                    className="botaoSubmitExcecaoVBA"
                                    onClick={() => {
                                        submitHandleSubmitLanguageInformalClientException();
                                    }}>
                                    Confirmar
                                </button>
                            ) : (
                                <></>
                            )
                        )}
                    </span>
                    </div>
                    </div>
                ) : null }

                {/* timeline do cliente */}
                <div
                // style={{height: `${window.innerHeight > 760 ? 'calc(100vh - 10rem)' : window.innerHeight > 530 ? 'calc(100vh - 4rem)' : 'calc(100vh - 4rem)'}`}}
                >
                    {activeRefusalReasonBox ? (
                        <div className={"refusalReasonBox"} style={{top:'28rem', left: '34rem'}}>
                            <>
                                <div className="refuseReasonHeaderAbc">
                                    <div></div>
                                    <div className="refuseReasontitleAbc">Motivo da recusa</div>
                                    <button onClick={() => setActiveRefusalReasonBox(false)} className="modalCloseButton">
                                        X
                                    </button>
                                </div>
                                <FormGroup className="reasonsRefusalList">
                                    <RadioGroup aria-labelledby="demo-radio-buttons-group-label" name="radio-buttons-group">
                                        {refusalReasons.map((e, i) => {
                                            return (
                                                <FormControlLabel
                                                    value={e.id}
                                                    control={<Radio />}
                                                    label={e.label}
                                                    onClick={(e) => {
                                                        setSelectedReason(e.target.value)
                                                        const refusalReasonAux = refusalReasons.filter((r) => r.id == e.target.value)
                                                        setComentTimeline(refusalReasonAux[0]?.comment)
                                                    }}
                                                />
                                            )
                                        })}
                                    </RadioGroup>
                                </FormGroup>
                                <button
                                    className="buttomGray360"
                                    onClick={() => {
                                        setActiveRefusalReasonBox(false)
                                        setNewStatus(6)
                                    }}
                                >
                                    Ok
                                </button>
                            </>
                        </div>
                    ) : (
                        <></>
                    )}
                    <div
                        className="containerTimelineAbcDash"
                        style={{
                            height: `${window.innerHeight > 760 ? "calc(100vh - 12rem)" : window.innerHeight > 575 ? "calc(100vh - 6rem)" : window.innerHeight > 480 ? "calc(100vh - 3rem)" : "110vh"}`, display: tabActive != 0 ? 'none' : 'grid'
                        }}
                    >
                        <>
                            <div className="timelineTitleAbcAbrirDash">
                                <div style={{ display: "grid", gridTemplateColumns: "auto auto" }}>
                                    <div style={{ marginTop: "0.2rem", color: "rgb(212, 172, 0)", fontWeight: "bold", fontSize: "1.2rem" }}>Comentários</div>
                                    <div style={{ display: "grid", gridTemplateColumns: "auto auto" }}>
                                        <div
                                            style={{ display: "grid", gridTemplateColumns: "auto auto", marginLeft: "auto", fontSize: "1rem", alignItems: "center" }}
                                            className={filterProduct.current ? classesContactType.timelineFilterTypeProduct : classesContactType.timelineFilterTypeProductInactive}
                                            onClick={() => {
                                                filterProduct.current = !filterProduct.current
                                                filterTimeline()
                                            }}
                                            title="Apenas da Campanha"
                                        >
                                            Apenas da Campanha
                                        </div>
                                        <div
                                            style={{ display: "grid", gridTemplateColumns: "auto auto", marginLeft: "auto", fontSize: "1rem", alignItems: "center" }}
                                            className={!filterProduct.current ? classesContactType.timelineFilterTypeProduct : classesContactType.timelineFilterTypeProductInactive}
                                            onClick={() => {
                                                filterProduct.current = !filterProduct.current
                                                filterTimeline()
                                            }}
                                            title="Geral"
                                        >
                                            Geral
                                        </div>
                                    </div>
                                </div>
                                <div className="timelineFiltersDash">
                                    Filtrar:
                                    <div
                                        className={filters.indexOf(1) >= 0 ? classesContactType.timelineFilterType1Active : classesContactType.timelineFilterTypeInactive}
                                        onClick={() => {
                                            if (filters.indexOf(1) >= 0) {
                                                filters.splice(filters.indexOf(1), 1)
                                            } else {
                                                filters.push(1)
                                            }
                                            filterTimeline()
                                        }}
                                        title="Whatsapp"
                                    >
                                        <BsWhatsapp style={{ margin: "auto" }} size={18} />
                                    </div>
                                    <div
                                        className={filters.indexOf(2) >= 0 ? classesContactType.timelineFilterType2Active : classesContactType.timelineFilterTypeInactive}
                                        onClick={() => {
                                            if (filters.indexOf(2) >= 0) {
                                                filters.splice(filters.indexOf(2), 1)
                                            } else {
                                                filters.push(2)
                                            }
                                            filterTimeline()
                                        }}
                                        title="Ligação"
                                    >
                                        <BsTelephone style={{ margin: "auto" }} size={18} />
                                    </div>
                                    <div
                                        className={filters.indexOf(3) >= 0 ? classesContactType.timelineFilterType3Active : classesContactType.timelineFilterTypeInactive}
                                        onClick={() => {
                                            if (filters.indexOf(3) >= 0) {
                                                filters.splice(filters.indexOf(3), 1)
                                            } else {
                                                filters.push(3)
                                            }
                                            filterTimeline()
                                        }}
                                        title="E-mail"
                                    >
                                        <AiOutlineMail style={{ margin: "auto" }} size={18} />
                                    </div>
                                    <div
                                        className={filters.indexOf(4) >= 0 ? classesContactType.timelineFilterType4Active : classesContactType.timelineFilterTypeInactive}
                                        onClick={() => {
                                            if (filters.indexOf(4) >= 0) {
                                                filters.splice(filters.indexOf(4), 1)
                                            } else {
                                                filters.push(4)
                                            }
                                            filterTimeline()
                                        }}
                                        title="Reunião"
                                    >
                                        <BsPeople style={{ margin: "auto" }} size={18} />
                                    </div>
                                    <div
                                        className={filters.indexOf(5) >= 0 ? classesContactType.timelineFilterType5Active : classesContactType.timelineFilterTypeInactive}
                                        onClick={() => {
                                            if (filters.indexOf(5) >= 0) {
                                                filters.splice(filters.indexOf(5), 1)
                                            } else {
                                                filters.push(5)
                                            }
                                            filterTimeline()
                                        }}
                                        title="Apenas Comentário"
                                    >
                                        <BsChatDots style={{ margin: "auto" }} size={18} />
                                    </div>
                                    <div
                                        className={filters.indexOf(0) >= 0 ? classesContactType.timelineFilterType0Active : classesContactType.timelineFilterTypeInactive}
                                        onClick={() => {
                                            if (filters.indexOf(0) >= 0) {
                                                filters.splice(filters.indexOf(0), 1)
                                            } else {
                                                filters.push(0)
                                            }
                                            filterTimeline()
                                        }}
                                        title="Sistema"
                                    >
                                        <BsCpu style={{ margin: "auto" }} size={18} />
                                    </div>
                                    <div
                                        className={filters.indexOf(6) >= 0 ? classesContactType.timelineFilterType6Active : classesContactType.timelineFilterTypeInactive}
                                        onClick={() => {
                                            if (filters.indexOf(6) >= 0) {
                                                filters.splice(filters.indexOf(6), 1)
                                            } else {
                                                filters.push(6)
                                            }
                                            filterTimeline()
                                        }}
                                        title="Formulário Ativação"
                                    >
                                        <BsCardList style={{ margin: "auto" }} size={18} />
                                    </div>
                                </div>
                            </div>
                            <div
                                className="timelineCommentBoxAbcDash"
                                id="timelineCommentBoxAbc"
                                style={{ height: reuniao_realizada ? "calc(initial - 20rem)" : "initial" }}
                                onScroll={(e) => {
                                    if (e.target.scrollTop < 10) updateItemsList()
                                }}
                            >
                                {loadingTimeline ? (
                                    <ArrowLoader />
                                ) : items.length > 0 ? (
                                    items.map((item) => {
                                        return item.user.id == user.external_id ? (
                                            <div className="timelineComentBoxUserComentRight">
                                                <div
                                                    className={
                                                        item.type == 1
                                                            ? "timelineComentBoxUserComentHeaderRight commentMessageDash"
                                                            : item.type == 2
                                                            ? "timelineComentBoxUserComentHeaderRight commentMessageDash"
                                                            : item.type == 3
                                                            ? "timelineComentBoxUserComentHeaderRight commentMessageDash"
                                                            : item.type == 4
                                                            ? "timelineComentBoxUserComentHeaderRight commentMessageDash"
                                                            : item.type == 5
                                                            ? "timelineComentBoxUserComentHeaderRight commentMessageDash"
                                                            : item.type == 0
                                                            ? "timelineComentBoxUserComentHeaderRight commentMessageDash"
                                                            : "timelineComentBoxUserComentHeaderRight commentMessageDash"
                                                    }
                                                >
                                                    <div className="timelineComentBoxUserComentIconRight">
                                                        {item.type == 1 ? (
                                                            <BsWhatsapp title="Contato por WhatsApp" />
                                                        ) : item.type == 2 ? (
                                                            <BsTelephone title="Contato por ligação" />
                                                        ) : item.type == 3 ? (
                                                            <AiOutlineMail title="Contato por e-mail" />
                                                        ) : item.type == 4 ? (
                                                            <BsPeople title="Contato por reunião" />
                                                        ) : item.type == 5 ? (
                                                            <BsChatDots title="Mensagem sem classificação" />
                                                        ) : item.type == 0 ? (
                                                            <BsCpu title="Mensagem do sistema" />
                                                        ) : (
                                                            <BsPersonPlus title="Indicação de cliente" />
                                                        )}
                                                    </div>
                                                    <div className="timelineComentBoxUserComentNameRight">{item.user.name}</div>
                                                </div>
                                                <div
                                                    className={
                                                        item.type == 1
                                                            ? "timelineComentBoxUserComentMessageRight commentMessageDash"
                                                            : item.type == 2
                                                            ? "timelineComentBoxUserComentMessageRight commentMessageDash"
                                                            : item.type == 3
                                                            ? "timelineComentBoxUserComentMessageRight commentMessageDash"
                                                            : item.type == 4
                                                            ? "timelineComentBoxUserComentMessageRight commentMessageDash"
                                                            : item.type == 5
                                                            ? "timelineComentBoxUserComentMessageRight commentMessageDash"
                                                            : item.type == 0
                                                            ? "timelineComentBoxUserComentMessageRight commentMessageDash"
                                                            : "timelineComentBoxUserComentMessageRight commentMessageDash"
                                                    }
                                                >
                                                    {item.text}
                                                    <>
                                                        {item?.informations?.map((line) => {
                                                            return (
                                                                <div>
                                                                    {line.key} <b>: </b> {line.value}
                                                                </div>
                                                            )
                                                        })}
                                                    </>
                                                </div>
                                                <div
                                                    className={
                                                        item.type == 1
                                                            ? "timelineComentBoxUserComentDateRight commentMessageDash"
                                                            : item.type == 2
                                                            ? "timelineComentBoxUserComentDateRight commentMessageDash"
                                                            : item.type == 3
                                                            ? "timelineComentBoxUserComentDateRight commentMessageDash"
                                                            : item.type == 4
                                                            ? "timelineComentBoxUserComentDateRight commentMessageDash"
                                                            : item.type == 5
                                                            ? "timelineComentBoxUserComentDateRight commentMessageDash"
                                                            : item.type == 0
                                                            ? "timelineComentBoxUserComentDateRight commentMessageDash"
                                                            : "timelineComentBoxUserComentDateRight commentMessageDash"
                                                    }
                                                >
                                                    {item.created_at.split("T")[0].split("-")[2] +
                                                        "/" +
                                                        item.created_at.split("T")[0].split("-")[1] +
                                                        "/" +
                                                        item.created_at.split("T")[0].split("-")[0] +
                                                        " - " +
                                                        item.created_at.split("T")[1].split(".")[0]}
                                                </div>
                                            </div>
                                        ) : item.type == "6" ? (
                                            <div className="timelineComentBoxUserComentLeft">
                                                <div className={"timelineComentBoxUserComentHeaderLeft formHeaderColor"}>
                                                    <div className="timelineComentBoxUserComentNameLeft">{item.user.name}</div>
                                                    <div className="timelineComentBoxUserComentIconLeft">
                                                        <BsCardList title="Formulário respondido antes da ativação" />
                                                    </div>
                                                </div>
                                                <div className={"timelineComentBoxUserComentMessageLeft formMessageColor"}>
                                                    <button className="buttonShowFinishedForm" onClick={() => {}}>
                                                        Formulário de primeira reunião
                                                        <BsBoxArrowUpLeft style={{ marginLeft: "0.4rem" }} size={14} />
                                                    </button>
                                                </div>
                                                <div className={"timelineComentBoxUserComentDateLeft formDateColor"}>
                                                    {item.created_at.split("T")[0].split("-")[2] +
                                                        "/" +
                                                        item.created_at.split("T")[0].split("-")[1] +
                                                        "/" +
                                                        item.created_at.split("T")[0].split("-")[0] +
                                                        " - " +
                                                        item.created_at.split("T")[1].split(".")[0]}
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="timelineComentBoxUserComentLeft">
                                                <div
                                                    className={
                                                        item.type == 1
                                                            ? "timelineComentBoxUserComentHeaderLeft commentMessageDash"
                                                            : item.type == 2
                                                            ? "timelineComentBoxUserComentHeaderLeft commentMessageDash"
                                                            : item.type == 3
                                                            ? "timelineComentBoxUserComentHeaderLeft commentMessageDash"
                                                            : item.type == 4
                                                            ? "timelineComentBoxUserComentHeaderLeft commentMessageDash"
                                                            : item.type == 5
                                                            ? "timelineComentBoxUserComentHeaderLeft commentMessageDash"
                                                            : item.type == 0
                                                            ? "timelineComentBoxUserComentHeaderLeft commentMessageDash"
                                                            : "timelineComentBoxUserComentHeaderLeft commentMessageDash"
                                                    }
                                                >
                                                    <div className="timelineComentBoxUserComentNameLeft">{item.user.name}</div>
                                                    <div className="timelineComentBoxUserComentIconLeft">
                                                        {item.type == 1 ? (
                                                            <BsWhatsapp title="Contato por WhatsApp" />
                                                        ) : item.type == 2 ? (
                                                            <BsTelephone title="Contato por ligação" />
                                                        ) : item.type == 3 ? (
                                                            <AiOutlineMail title="Contato por e-mail" />
                                                        ) : item.type == 4 ? (
                                                            <BsPeople title="Contato por reunião" />
                                                        ) : item.type == 5 ? (
                                                            <BsChatDots title="Mensagem sem classificação" />
                                                        ) : item.type == 0 ? (
                                                            <BsCpu title="Mensagem do sistema" />
                                                        ) : (
                                                            <BsPersonPlus title="Indicação de cliente" />
                                                        )}
                                                    </div>
                                                </div>
                                                <div
                                                    className={
                                                        item.type == 1
                                                            ? "timelineComentBoxUserComentMessageLeft commentMessageDash"
                                                            : item.type == 2
                                                            ? "timelineComentBoxUserComentMessageLeft commentMessageDash"
                                                            : item.type == 3
                                                            ? "timelineComentBoxUserComentMessageLeft commentMessageDash"
                                                            : item.type == 4
                                                            ? "timelineComentBoxUserComentMessageLeft commentMessageDash"
                                                            : item.type == 5
                                                            ? "timelineComentBoxUserComentMessageLeft commentMessageDash"
                                                            : item.type == 0
                                                            ? "timelineComentBoxUserComentMessageLeft commentMessageDash"
                                                            : "timelineComentBoxUserComentMessageLeft commentMessageDash"
                                                    }
                                                >
                                                    {item.text}
                                                    <>
                                                        {item?.informations?.map((line) => {
                                                            return (
                                                                <div>
                                                                    {line.key} <b>: </b> {line.value}
                                                                </div>
                                                            )
                                                        })}
                                                    </>
                                                </div>
                                                <div
                                                    className={
                                                        item.type == 1
                                                            ? "timelineComentBoxUserComentDateLeft commentMessageDash"
                                                            : item.type == 2
                                                            ? "timelineComentBoxUserComentDateLeft commentMessageDash"
                                                            : item.type == 3
                                                            ? "timelineComentBoxUserComentDateLeft commentMessageDash"
                                                            : item.type == 4
                                                            ? "timelineComentBoxUserComentDateLeft commentMessageDash"
                                                            : item.type == 5
                                                            ? "timelineComentBoxUserComentDateLeft commentMessageDash"
                                                            : item.type == 0
                                                            ? "timelineComentBoxUserComentDateLeft commentMessageDash"
                                                            : "timelineComentBoxUserComentDateLeft commentMessageDash"
                                                    }
                                                >
                                                    {item.created_at.split("T")[0].split("-")[2] +
                                                        "/" +
                                                        item.created_at.split("T")[0].split("-")[1] +
                                                        "/" +
                                                        item.created_at.split("T")[0].split("-")[0] +
                                                        " - " +
                                                        item.created_at.split("T")[1].split(".")[0]}
                                                </div>
                                            </div>
                                        )
                                    })
                                ) : (
                                    <div className="emptyTimelineMessage">Não há mensagens...</div>
                                )}
                            </div>

                            <div className="timelineNewCommentBox">
                                <div className="timelineNewCommentContactTypeAbc">
                                    <div className="timelineNewCommentContactTypeTitle">Contato</div>
                                    <div
                                        className={contactType == 1 ? classesContactType.timelineNewCommentContactTypeActive : classesContactType.timelineNewCommentContactTypeInactive}
                                        onClick={() => {
                                            setContactType(1)
                                            if (contactType == 1) {
                                                setContactType(5)
                                            }
                                        }}
                                        title="Whatsapp"
                                    >
                                        <BsWhatsapp style={{ margin: "auto" }} size={24} />
                                    </div>
                                    <div
                                        className={contactType == 2 ? classesContactType.timelineNewCommentContactTypeActive : classesContactType.timelineNewCommentContactTypeInactive}
                                        onClick={() => {
                                            setContactType(2)
                                            if (contactType == 2) {
                                                setContactType(5)
                                            }
                                        }}
                                        title="Ligação"
                                    >
                                        <BsTelephone style={{ margin: "auto" }} size={24} />
                                    </div>
                                    <div
                                        className={contactType == 3 ? classesContactType.timelineNewCommentContactTypeActive : classesContactType.timelineNewCommentContactTypeInactive}
                                        onClick={() => {
                                            setContactType(3)
                                            if (contactType == 3) {
                                                setContactType(5)
                                            }
                                        }}
                                        title="E-mail"
                                    >
                                        <AiOutlineMail style={{ margin: "auto" }} size={24} />
                                    </div>
                                    <div
                                        className={contactType == 4 ? classesContactType.timelineNewCommentContactTypeActive : classesContactType.timelineNewCommentContactTypeInactive}
                                        onClick={() => {
                                            setReuniao_realizada(true)
                                            setContactType(4)
                                            if (contactType == 4) {
                                                setContactType(5)
                                            }
                                        }}
                                        title="Reunião"
                                    >
                                        <BsPeople style={{ margin: "auto" }} size={24} />
                                    </div>
                                    <div
                                            onClick={() => {
                                                // submitReuniaoRealizada()
                                            }}
                                            style={{ display: contactType == 4 ? "flex" : "none", flexDirection: 'row', alignItems: 'center', overflowX:'hidden' }}
                                        >
                                        {
                                        reuniao_realizada || contactType == 4 ?
                                            (props.prop.clientData[30] != 'Ultra High' ? (
                                                <div style={{display:"flex", flexDirection:"column", marginLeft:'5px'}}>
                                                    <a style={{fontWeight:"bold", marginLeft:'5px'}}>Assunto</a>
                                                    <select
                                                        onChange={(e) => {
                                                            setCloneAssuntoSelecionado(e.target.value);
                                                        }}
                                                        style={{ display: props.prop.clientData[30] != 'Ultra High' ? "block" : "none", padding: '5px', borderRadius: '5px', border: '1px solid #ccc' }}
                                                    >
                                                        <option value="" selected disabled className="optionsSelectComment">
                                                            Selecione
                                                        </option>
                                                        {optTopTier.map((e, i) => (
                                                            <option key={i} value={e.value} className="optionsSelectComment">
                                                                {e.description}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            ) : (
                                                props.prop.clientData[30] == 'Ultra High' ? (
                                                    <div style={{display:"flex", flexDirection:"column", marginLeft:'5px'}}>
                                                    <a style={{fontWeight:"bold", marginLeft:'5px'}}>Assunto</a>
                                                    <select
                                                        onChange={(e) => {
                                                            setCloneAssuntoSelecionado(e.target.value);
                                                        }}
                                                        style={{ display: props.prop.clientData[30] == 'Ultra High' ? "block" : "none", padding: '5px', borderRadius: '5px', border: '1px solid #ccc' }}
                                                    >
                                                        <option value="" selected disabled className="optionsSelectComment">
                                                            Selecione
                                                        </option>
                                                        {optUltraHigh.map((e, i) => (
                                                            <option key={i} value={e.value} className="optionsSelectComment">
                                                                {e.description}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    </div>  
                                                ) : (
                                                    <></>
                                                ))
                                            ) : (
                                                <></>
                                            )
                                        }
                                    </div>
                                </div>
                                
                                <div className="timelineNewCommentWriteSendDash">
                                    <textarea
                                        className={classesContactType.timelineNewComentWriteArea}
                                        value={comentTimeline}
                                        label="Novo comentário"
                                        onChange={(e) => {
                                            setComentTimeline(e.target.value)
                                        }}
                                    ></textarea>
                                    <button
                                        className={
                                            comentTimeline !== "" && comentTimeline !== null ? classesContactType.timelineNewCommentSendActive : classesContactType.timelineNewCommentSendInative
                                        }
                                        onClick={() => {
                                            if (comentTimeline !== "" && comentTimeline !== null) {
                                                handleSubmitComment()
                                                setReuniao_realizada(false)
                                            }
                                        }}
                                    >
                                        <BsSend size={26} />
                                    </button>
                                </div>
                            </div>
                        </>
                    </div>
                </div>
            </div>
        </div>
    )
}
