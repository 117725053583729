import React from "react";
/*Styles*/
// import "./styles/recomendations.css";
/*Components*/
import Modal from "../../../components/modal/modal";
import { ConfirmationBox } from "../../../components/confirmationBox/confirmationBox";
// import { WisirTrackingTable } from "./tables/wisirTracking.table";
// import { ClientRecord } from "./modalWisir/componentsModalWisir/clientRecord";
/*Contexts*/
import { useModal } from "../../../contexts/useModal";
// import {ImportMovimentation} from './modalWisir/componentsModalWisir/importMovimentation';
import { WisirTable } from './tables/wisir.table'
import { Import } from './modal/wisir.import'
import { ImportCarteira } from "./modal/wisir.carteira.import";
import { WisirDetails } from './modal/wisir.details'
import { AddClient } from './modal/wisir.new'

export function Wisir() {
  const { type } = useModal();

  return (
    <div className="recomendationsContainer">
      <Modal>
        {
            type.name === "alertMsg" ? (
                <ConfirmationBox msgData={type} />
            ) : 
            type.name === "showDetails" ? (
                <WisirDetails value={type.value}/>
            ) : 
            type.name === "showImport" ? (
                <Import value={type.value} strategy={type.strategy} update={type.update} />
            ) :
            type.name === "showImportCarteira" ? (
                <ImportCarteira value={type.value} strategy={type.strategy} />
            ) :
            type.name === "showAddClient" ? (
                <AddClient value={type.value} strategy={type.strategy} reload={type.reloadTable} />
            ) : (
                <></>
            )
        }
      </Modal>
      <WisirTable prop={type} />
    </div>
  );
}
