/*Componente detalhe de clientes
/*Descrição: Exibe os detalhes e permite alteração do cliente
/*Desenvolvido por: Equipe Rafael Cezário
*/

//libraries
import React, { useState, useEffect } from 'react';
import AntSwitch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { FiEdit, FiTrash2, FiX, FiMinus, FiPlus } from 'react-icons/fi';
import { BsWhatsapp, BsTelephone, BsPeople, BsChatSquareText, BsCpu, BsChatDots, BsArrowLeftShort } from 'react-icons/bs';
import {MdDoneOutline} from 'react-icons/md'
import { AiOutlineMail, AiOutlineSend } from 'react-icons/ai';
import { TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab';
//utils
import { titleize } from '../../../../utils/formatNumberAndText/formatTextUpperFirstLetter';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import { numberBRLFormat } from '../../../../utils/formatNumberAndText/formatNumbertoBRL';
import { ModalSizeFitToScreen } from '../../../../utils/responsiveFunctions/modalSizeFitToScreen'
import { toBRL } from "../../../../utils/formatNumberAndText/toBRLCurrency";
//components
import { ArrowLoader } from '../../../../components/loaders/arrowLoader';
import { useModal } from '../../../../contexts/useModal';
//services
import { api } from '../../../../services/api';
//styles
import { styleClientsInput } from '../../../../components/styles/autocompletField';
import { makeStyles } from '@material-ui/core/styles';
import './styles/vbaClients.details.css';
import './styles/formActivation.css';
//Contexts
import { useAuth } from '../../../../contexts/useAuth';

export function VbaClientActivation(props) {
    // console.log('props', props)
    const { user, tokenKey } = useAuth();
    const { show, setShow, setType, type, edited, setEdited } = useModal();
    const [loading, setLoading] = useState(false);
    const [selectedClientName, setSelectedClientName] = useState('');
    const [selectedClientProfile, setSelectedClientProfile] = useState('');
    const token = localStorage.getItem('@auth:token');
    //idMax 73
    const [strutureJson, setStrutureJson] = useState(props?.clientData?.type != 2 ?
      {
        "id": 1,
        "title": "",
        "description": "",
        "profile": 4,
        "form_creation": "",
        "session_id": 0,
        "session_owner_id": "0",
        "session_owner": "System Admin",
        "session_description": "",
        "session_pending": true,
        "session_creation": "",
        "session_update": "",
        "sections": [
        //   {
        //     "id": 2,
        //     "title": "Pré-Reunião",
        //     "description": "",
        //     "isClose": false,
        //     "questions": [
        //       {
        //         "id": 3,
        //         "belongs_to": null,
        //         "description": "Resuma o perfil do cliente, preferências, etc.",
        //         "type": 1,
        //         "observation": false,
        //         "answer_observation": null,
        //         "options": [],
        //         "answer": null
        //       },
        //     ]
        //   },
          {
            "id": 4,
            "title": "Coleta de dados na reunião",
            "description": "",
            "isClose": false,
            "questions": [
            //   {
            //     "id": 5,
            //     "belongs_to": null,
            //     "description": "O cliente já investiu em RV anteriormente?",
            //     "type": 2,
            //     "observation": false,
            //     "answer_observation": null,
            //     "options": [
            //       {
            //         "id": 6,
            //         "belongs_to": 2457,
            //         "description": "Sim",
            //         "tag": null,
            //         "questions": []
            //       },
            //       {
            //         "id": 7,
            //         "belongs_to": 2457,
            //         "description": "Não",
            //         "tag": null,
            //         "questions": []
            //       }
            //     ],
            //     "answer": null
            //   },
            {
                "id": 5,
                "belongs_to": null,
                "description": "Quais classes de ativos você tem interesse?",
                "type": 2,
                "observation": false,
                "answer_observation": null,
                "options": [
                  {
                    "id": 6,
                    "belongs_to": 2457,
                    "description": "Ações ",
                    "tag": null,
                    "questions": []
                  },
                  {
                    "id": 7,
                    "belongs_to": 2457,
                    "description": "Fundos Imobiliários ",
                    "tag": null,
                    "questions": []
                  },
                  {
                    "id": 8,
                    "belongs_to": 2457,
                    "description": "Ambos",
                    "tag": null,
                    "questions": [
                        {
                            "id": 11,
                            "belongs_to": null,
                            "description": "Qual instituição?",
                            "type": 1,
                            "observation": false,
                            "answer_observation": null,
                            "options": [],
                            "answer": null
                        }
                    ]
                  },

                //   {
                //     "id": 13,
                //     "belongs_to": 2457,
                //     "description": "Outros",
                //     "tag": null,
                //     "questions": [
                //         {
                //             "id": 14,
                //             "belongs_to": null,
                //             "description": "Qual?",
                //             "type": 1,
                //             "observation": false,
                //             "answer_observation": null,
                //             "options": [],
                //             "answer": null
                //         }
                //     ]
                //   }
                ],
                "answer": []
              },
              {
                "id": 8,
                "belongs_to": null,
                "description": "Qual a sua experiência de investimentos em renda variável?",
                "type": 3,
                "observation": false,
                "answer_observation": null,
                "options": [
                  {
                    "id": 9,
                    "belongs_to": 2457,
                    "description": "Nunca operou renda variável ",
                    "tag": null,
                    "questions": []
                  },
                  {
                    "id": 12,
                    "belongs_to": 2457,
                    "description": "Opera por conta própria via Home Broker ",
                    "tag": null,
                    "questions": []
                  },
                  {
                    "id": 10,
                    "belongs_to": 2457,
                    "description": "Opera com assessoria de renda variável",
                    "tag": null,
                    "questions": [
                        {
                            "id": 11,
                            "belongs_to": null,
                            "description": "Qual instituição?",
                            "type": 1,
                            "observation": false,
                            "answer_observation": null,
                            "options": [],
                            "answer": null
                        }
                    ]
                  },

                //   {
                //     "id": 13,
                //     "belongs_to": 2457,
                //     "description": "Outros",
                //     "tag": null,
                //     "questions": [
                //         {
                //             "id": 14,
                //             "belongs_to": null,
                //             "description": "Qual?",
                //             "type": 1,
                //             "observation": false,
                //             "answer_observation": null,
                //             "options": [],
                //             "answer": null
                //         }
                //     ]
                //   }
                ],
                "answer": []
              },
            //   {
            //     "id": 47,
            //     "belongs_to": null,
            //     "description": "O cliente utilizará o serviço HB?",
            //     "type": 2,
            //     "observation": false,
            //     "answer_observation": null,
            //     "options": [
            //       {
            //         "id": 48,
            //         "belongs_to": 2457,
            //         "description": "Sim",
            //         "tag": null,
            //         "questions": [
            //             {
            //                 "id": 50,
            //                 "belongs_to": null,
            //                 "description": "Selecione os relatórios desejados.",
            //                 "type": 3,
            //                 "observation": false,
            //                 "answer_observation": null,
            //                 "options": [
            //                     {
            //                         //edição desses ids afeta função de exportação da VBA. Existem colunas que recebem as respostas dos relatórios desejados
            //                         "id": 51,
            //                         "belongs_to": 2457,
            //                         "description": "Resumos de mercado",
            //                         "tag": null,
            //                         "questions": []
            //                     },
            //                     {
            //                         "id": 52,
            //                         "belongs_to": 2457,
            //                         "description": "Relatório semanal (Economia em Destaque e Resumo semanal Bolsa na sexta, Semana em foco e Política em destaque na segunda",
            //                         "tag": null,
            //                         "questions": []
            //                     },
            //                     {
            //                         "id": 53,
            //                         "belongs_to": 2457,
            //                         "description": "Relatório quinzenal (Stock Guide que é um documento com todos os ativos que as nossas casas de análises cobrem, nele você encontra recomendação e preço alvo)",
            //                         "tag": null,
            //                         "questions": []
            //                     },
            //                     {
            //                         "id": 54,
            //                         "belongs_to": 2457,
            //                         "description": "Relatório mensal (Brasil Macro Mensal e Raio XP da Bolsa",
            //                         "tag": null,
            //                         "questions": []
            //                     },
            //                     {
            //                         "id": 55,
            //                         "belongs_to": 2457,
            //                         "description": "Resultados trimestrais (Sempre que sair o resultado das empresas)",
            //                         "tag": null,
            //                         "questions": []
            //                     },
            //                     {
            //                         "id": 56,
            //                         "belongs_to": 2457,
            //                         "description": "Oportunidades recomendadas por casas de análise",
            //                         "tag": null,
            //                         "questions": []
            //                     },
            //                     {
            //                         "id": 57,
            //                         "belongs_to": 2457,
            //                         "description": "Ofertas públicas em andamento",
            //                         "tag": null,
            //                         "questions": []
            //                     },
            //                     {
            //                         "id": 58,
            //                         "belongs_to": 2457,
            //                         "description": "Operações de Day trade e Swing trade",
            //                         "tag": null,
            //                         "questions": []
            //                     },
            //                 ],
            //                 "answer": []
            //             }
            //         ]
            //       },
            //       {
            //         "id": 49,
            //         "belongs_to": 2457,
            //         "description": "Não",
            //         "tag": null,
            //         "questions": []
            //       }
            //     ],
            //     "answer": null
            //   },
              {
                "id": 12,
                "belongs_to": null,
                "description": "Como você toma suas decisões de investimentos em renda variável?",
                "type": 3,
                "observation": false,
                "answer_observation": null,
                "options": [
                    {
                        "id": 13,
                        "belongs_to": 2457,
                        "description": "Ainda não tenho experiência em renda variável "
                    },
                    {
                        "id": 14,
                        "belongs_to": 2457,
                        "description": "Relatório de casas de análise",
                        "tag": null,
                        "questions": [
                            {
                                "id": 15,
                                "belongs_to": null,
                                "description": "Qual casa de análise??",
                                "type": 1,
                                "observation": false,
                                "answer_observation": null,
                                "options": [],
                                "answer": null
                            }
                        ]
                    },
                    {
                      "id": 16,
                      "belongs_to": 2457,
                      "description": "Sites de notícias / redes sociais",
                      "tag": null,
                      "questions": []
                    },
                  {
                    "id": 17,
                    "belongs_to": 2457,
                    "description": "Dicas de amigos",
                    "tag": null,
                    "questions": []
                  },
                  {
                    "id": 18,
                    "belongs_to": 2457,
                    "description": "Assessoria de renda variável",
                    "tag": null,
                    "questions": []
                  },
                  {
                    "id": 19,
                    "belongs_to": 2457,
                    "description": "Outros",
                    "tag": null,
                    "questions": [
                        {
                            "id": 20,
                            "belongs_to": null,
                            "description": "Qual?",
                            "type": 1,
                            "observation": false,
                            "answer_observation": null,
                            "options": [],
                            "answer": null
                        }
                    ]
                  }
                ],
                "answer": []
              },
              {
                "id": 22,
                "belongs_to": null,
                "description": "Como você prefere atuar no mercado de renda variável? ",
                "type": 3,
                "observation": false,
                "answer_observation": null,
                "options": [
                    {
                        "id": 23,
                        "belongs_to": 2457,
                        "description": 'Estratégias de curto prazo (day trade, swing trade, etc) ',
                        "tag": null,
                        "questions": []
                    },
                    {
                        "id": 24,
                        "belongs_to": 2457,
                        "description": "Estratégias de médio prazo (carteiras recomendadas, gestão ativa de posições) ",
                        "tag": null,
                        "questions": []
                    },
                    {
                        "id": 25,
                        "belongs_to": 2457,
                        "description": "Longo prazo (buy and hold)",
                        "tag": null,
                        "questions": []
                    },
                ],
                "answer": []
              },
              {
                "id": 26,
                "belongs_to": null,
                "description": "Quais estratégias você tem mais interesse no mercado de ações?",
                "type": 3,
                "observation": false,
                "answer_observation": null,
                "options": [
                    {
                        "id": 70,
                        "belongs_to": 2457,
                        "description": "Ações pagadoras de dividendos ",
                        "tag": null,
                        "questions": []
                    },
                    {
                        "id": 71,
                        "belongs_to": 2457,
                        "description": "Ações de alto potencial de crescimento (small caps)  ",
                        "tag": null,
                        "questions": []
                    },
                    {
                        "id": 72,
                        "belongs_to": 2457,
                        "description": "Ações de grande liquidez (blue chips)  ",
                        "tag": null,
                        "questions": []
                    },
                    {
                        "id": 74,
                        "belongs_to": 2457,
                        "description": "Ações internacionais / BDRs  ",
                        "tag": null,
                        "questions": []
                    },
                    {
                        "id": 28,
                        "belongs_to": 2457,
                        "description": "Estratégia livre envolvendo os ativos mais adequados para cada momento ",
                        "tag": null,
                        "questions": []
                    },
                    {
                        "id": 90,
                        "belongs_to": 2457,
                        "description": "Derivativos com finalidade de proteção de carteira ",
                    },
                    {
                        "id": 29,
                        "belongs_to": 2457,
                        "description": "Derivativos com finalidade de especulação e alavancagem nos ganhos ",
                        "tag": null,
                        "questions": []
                    },
                    {
                        "id": 91,
                        "belongs_to": 2457,
                        "description": "Swing Trade (operações de curto prazo) ",
                        "tag": null,
                        "questions": []
                    },
                    {
                        "id": 30,
                        "belongs_to": 2457,
                        "description": "Long Short ",
                        "tag": null,
                        "questions": []
                    },
                    {
                        "id": 31,
                        "belongs_to": 2457,
                        "description": "Day Trade ",
                        "tag": null,
                        "questions": []
                    },
                    {
                        "id": 32,
                        "belongs_to": 2457,
                        "description": "Outros ",
                        "tag": null,
                        "questions": [
                            {
                                "id": 33,
                                "belongs_to": null,
                                "description": "Qual?",
                                "type": 1,
                                "observation": false,
                                "answer_observation": null,
                                "options": [],
                                "answer": null
                            }
                        ]
                    },
                ],
                "answer": []
              },
            //   {
            //     "id": 32,
            //     "belongs_to": null,
            //     "description": "Formato de contato ideal para o cliente.",
            //     "type": 2,
            //     "observation": false,
            //     "answer_observation": null,
            //     "options": [
            //         {
            //             "id": 33,
            //             "belongs_to": 2457,
            //             "description": "WhatsApp",
            //             "tag": null,
            //             "questions": []
            //         },
            //         {
            //             "id": 34,
            //             "belongs_to": 2457,
            //             "description": "Ligação",
            //             "tag": null,
            //             "questions": []
            //         },
            //         {
            //             "id": 35,
            //             "belongs_to": 2457,
            //             "description": "Reunião Online",
            //             "tag": null,
            //             "questions": []
            //         },
            //         {
            //             "id": 36,
            //             "belongs_to": 2457,
            //             "description": "Reunião Presencial",
            //             "tag": null,
            //             "questions": []
            //         },
            //     ],
            //     "answer": []
            //   },
            //   {
            //     "id": 59,
            //     "belongs_to": null,
            //     "description": "Qual a periodicidade de contato combinada com o cliente?",
            //     "type": 2,
            //     "observation": false,
            //     "answer_observation": null,
            //     "options": [
            //       {
            //         "id": 60,
            //         "belongs_to": 2457,
            //         "description": "Semanal",
            //         "tag": null,
            //         "questions": []
            //       },
            //       {
            //         "id": 61,
            //         "belongs_to": 2457,
            //         "description": "Quinzenal",
            //         "tag": null,
            //         "questions": []
            //       },
            //       {
            //         "id": 62,
            //         "belongs_to": 2457,
            //         "description": "Mensal",
            //         "tag": null,
            //         "questions": []
            //       },
            //       {
            //         "id": 63,
            //         "belongs_to": 2457,
            //         "description": "Bimestral",
            //         "tag": null,
            //         "questions": []
            //       }
            //     ],
            //     "answer": null
            //   },
            {
                "id": 49,
                "belongs_to": null,
                "description": "Quais estratégias você tem mais interesse no mercado de FIIs?",
                "type": 3,
                "observation": false,
                "answer_observation": null,
                "options": [
                    {
                        "id": 50,
                        "belongs_to": 2457,
                        "description": "Estratégia com foco em geração de renda mensal  ",
                        "tag": null,
                        "questions": []
                    },
                    {
                        "id": 51,
                        "belongs_to": 2457,
                        "description": "Estratégia com foco em renda mensal e ganho patrimonial nas cotas",
                        "tag": null,
                        "questions": []
                    },
                    {
                        "id": 52,
                        "belongs_to": 2457,
                        "description": "Participação em ofertas públicas de fundos cetipados  ",
                        "tag": null,
                        "questions": []
                    },
                    {
                        "id": 53,
                        "belongs_to": 2457,
                        "description": "Outros ",
                        "tag": null,
                        "questions": [
                            {
                                "id": 33,
                                "belongs_to": null,
                                "description": "Qual?",
                                "type": 1,
                                "observation": false,
                                "answer_observation": null,
                                "options": [],
                                "answer": null
                            }
                        ]
                    },
                ],
                "answer": []
              },
              {
                "id": 37,
                "belongs_to": null,
                "description": "Como você controla o seu imposto de renda?",
                "type": 2,
                "observation": false,
                "answer_observation": null,
                "options": [
                    {
                        "id": 38,
                        "belongs_to": 2457,
                        "description": "Contador próprio",
                        "tag": null,
                        "questions": []
                    },
                    {
                        "id": 39,
                        "belongs_to": 2457,
                        "description": "Calculadora de IR automatizada",
                        "tag": null,
                        "questions": [
                            {
                                "id": 40,
                                "belongs_to": null,
                                "description": "Qual?",
                                "type": 1,
                                "observation": false,
                                "answer_observation": null,
                                "options": [],
                                "answer": null
                            }
                        ]
                    },
                    {
                        "id": 41,
                        "belongs_to": 2457,
                        "description": "Controle próprio",
                        "tag": null,
                        "questions": []
                    },
                    {
                        "id": 42,
                        "belongs_to": 2457,
                        "description": "Ainda não possuo um controle organizado",
                        "tag": null,
                        "questions": []
                    },
                ],
                "answer": null
              },
              {
                "id": 43,
                "belongs_to": null,
                "description": "Descreva informações adicionais sobre as suas preferências em renda variável para que possamos lhe assessorar da melhor forma: ",
                "type": 1,
                "observation": false,
                "answer_observation": null,
                "options": [],
                "answer": null

              },
            //   {
            //     "id": 64,
            //     "belongs_to": null,
            //     "description": "Descreva eventuais produtos que o cliente não queira receber ofertas",
            //     "type": 3,
            //     "observation": true,
            //     "answer_observation": null,
            //     "options": [
            //         {
            //             "id": 65,
            //             "belongs_to": 2457,
            //             "description": "Ações",
            //             "tag": null,
            //             "questions": []
            //         },
            //         {
            //             "id": 66,
            //             "belongs_to": 2457,
            //             "description": "FIIs",
            //             "tag": null,
            //             "questions": []
            //         },
            //         {
            //             "id": 67,
            //             "belongs_to": 2457,
            //             "description": "Cetipados",
            //             "tag": null,
            //             "questions": []
            //         },
            //         {
            //             "id": 68,
            //             "belongs_to": 2457,
            //             "description": "Ofertas públicas",
            //             "tag": null,
            //             "questions": []
            //         },
            //         {
            //             "id": 69,
            //             "belongs_to": 2457,
            //             "description": "Estruturadas Black",
            //             "tag": null,
            //             "questions": []
            //         },
            //         {
            //             "id": 70,
            //             "belongs_to": 2457,
            //             "description": "Carteiras Recomendadas",
            //             "tag": null,
            //             "questions": []
            //         },
            //         {
            //             "id": 71,
            //             "belongs_to": 2457,
            //             "description": "Outros",
            //             "tag": null,
            //             "questions": [
            //                 {
            //                     "id": 72,
            //                     "belongs_to": null,
            //                     "description": "Qual?",
            //                     "type": 1,
            //                     "observation": false,
            //                     "answer_observation": null,
            //                     "options": [],
            //                     "answer": null
            //                 }
            //             ]
            //         },
            //     ],
            //     "answer": []
            //   },
            ]
          },
        //   {
        //     "id": 42,
        //     "title": "Finalização da reunião",
        //     "description": "",
        //     "isClose": false,
        //     "questions": [
        //       {
        //         "id": 43,
        //         "belongs_to": null,
        //         "description": "O cliente aderiu à produtos oficiais da mesa (produtos do planejamento ABC)?",
        //         "type": 2,
        //         "observation": false,
        //         "answer_observation": null,
        //         "options": [
        //             {
        //                 "id": 44,
        //                 "belongs_to": 2457,
        //                 "description": "Sim",
        //                 "tag": null,
        //                 "questions": [
        //                     {
        //                     "id": 45,
        //                     "belongs_to": null,
        //                     "description": "Qual?",
        //                     "type": 1,
        //                     "observation": false,
        //                     "answer_observation": null,
        //                     "options": [],
        //                     "answer": null
        //                     }
        //                 ]
        //               },
        //               {
        //                 "id": 46,
        //                 "belongs_to": 2457,
        //                 "description": "Não",
        //                 "tag": null,
        //                 "questions": []
        //               }
        //         ],
        //         "answer": null
        //       }
        //     ]
        //   },
        ]
      }
      :
      {}
    )
    const [refresh, setRefresh] = useState(0)
    const [finished, setFinished] = useState(props?.clientData?.type == 2 || props?.clientData?.type == 4 ? true : false)
    const [saving, setSaving] = useState(false)
    const [formResponseDate, setFormResponseDate] = useState("")
    const [unlockButton, setUnlockButton] = useState(false)
    const [idReply, setIdReply] = useState(null)
    const requiredFields = [
        { field: 'selectedClientName', value: selectedClientName },
        { field: 'selectedClientProfile', value: selectedClientProfile },
    ]
    const [errorFields, setErrorFields] = useState({
        selectedClientName: false,
        selectedClientProfile: false
    })

    const useStyles = makeStyles((theme) => ({
      title: {
        color: strutureJson?.profile === 3 ? '#270000' : strutureJson?.profile === 2 ? '#302700' : strutureJson?.profile === 1 ? '#00323f' : '#3a3a3a',
        fontSize: '1.5rem',
        fontWeight: '400',
        textAlign: 'center',
        height: 'fit-content',
        width: 'fit-content',
        margin: 'auto',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        paddingLeft: '2rem',
        paddingRight: '2rem',
        maxWidth: '100%',
      },
  
      profile:{
          color: strutureJson?.profile === 3 ? '#270000' : strutureJson?.profile === 2 ? '#302700' : strutureJson?.profile === 1 ? '#00323f' : '#3a3a3a',
        marginLeft: '1rem',
      },
  
      sectionClosed:{
          margin: '1rem 1rem 1rem 1rem',
          backgroundColor: '#E4E4E4',
          fontSize:' 1.5rem',
          fontWeight: '400',
          textAlign: 'center',
          border: '5px',
          borderStyle: 'none solid none solid' ,
          // borderColor: strutureJson?.profile === 3 ? '#FF0000' : strutureJson?.profile === 2 ? '#ffae00' : '#c49e17',
          borderColor: strutureJson?.profile === 3 ? '#FF0000' : strutureJson?.profile === 2 ? '#ffae00' : strutureJson?.profile === 1 ? '#c49e17' : '#3a3a3a',
          height: '3rem',
          display: 'grid',
          gridTemplateColumns: "3rem auto 3rem",
          '&:hover' : {
              backgroundColor: '#e9e9e9',
              cursor: 'pointer',
          }
          
      },
  
      boxQuestion:{
          color: strutureJson?.profile === 3 ? '#270000' : strutureJson?.profile === 2 ? '#302700' : strutureJson?.profile === 1 ? '#00323f' : '#3a3a3a',
          borderColor: strutureJson?.profile === 3 ? '#FF0000' : strutureJson?.profile === 2 ? '#ffae00' : strutureJson?.profile === 1 ? '#00323f' : '#ebebeb',
          borderStyle: 'solid none none none ' ,
          fontSize:' 2rem',
          fontWeight: '400',
          backgroundColor: '#E4E4E4',
          margin: '1rem 5rem 1rem 5rem',
      },
      multipleAnswerBox: {
          marginLeft: "1rem",
          fontSize:' 1.5rem',
          fontWeight: '400',
          color: 'black',
      },
      separacaoOpcoes: {
          width: '4px',
          height: '80%',
          // backgroundColor: strutureJson?.profile === 3 ? '#FF0000' : strutureJson?.profile === 2 ? '#ffae00' : '#c49e17',
          backgroundColor: strutureJson?.profile === 3 ? '#FF0000' : strutureJson?.profile === 2 ? '#ffae00' : strutureJson?.profile === 1 ? '#c49e17' : '#3a3a3a',
          
          margin: 'auto',
          marginRight: '0.3rem',
      },
      inputTextAnswer: {
          overflowY: 'auto',
          height: '1.8rem',
          width: '80%',
          margin: 'auto',
          marginLeft: '0',
          marginRight: '0',
          fontSize: '1.2rem',
          paddingTop: '0.3rem',
          paddingLeft: '0.5rem',
          paddingRight: '0.5rem',
          backgroundColor: '#D9D9D9',
          border: 'none',
          borderRadius: '5px',
          borderBottom: '2px solid',
          borderColor: strutureJson?.profile === 3 ? '#FF0000' : strutureJson?.profile === 2 ? '#ffae00' : strutureJson?.profile === 1 ? '#00323f' : '#ebebeb',
          '&::placeholder' : {
              color: '#5F5F5F',
              textAlign: 'left'
          },
          '&:focus' : {
              outline: 'none',
              borderBottom: '4px solid',
              borderColor: strutureJson?.profile === 3 ? '#FF0000' : strutureJson?.profile === 2 ? '#ffae00' : strutureJson?.profile === 1 ? '#00323f' : '#ebebeb',
          }
  
      },
      inputMonetaryAnswer : {
          overflowY: 'auto',
          height: 'fit-content',
          width: '16rem',
          margin: 'auto',
          marginLeft: '0',
          marginRight: '0',
          fontSize: '1.2rem',
          backgroundColor: '#D9D9D9',
          border: 'none',
          borderRadius: '5px',
          borderBottom: '2px solid',
          borderColor: strutureJson?.profile === 3 ? '#FF0000' : strutureJson?.profile === 2 ? '#ffae00' : strutureJson?.profile === 1 ? '#00323f' : '#ebebeb',
          '&:focus' : {
              outline: 'none',
              borderColor: strutureJson?.profile === 3 ? '#FF0000' : strutureJson?.profile === 2 ? '#ffae00' : strutureJson?.profile === 1 ? '#00323f' : '#ebebeb',
          }
      },
      inputPercentageAnswer: {
          overflowY: 'auto',
          height: 'fit-content',
          width: '16rem',
          margin: 'auto',
          marginLeft: '0',
          marginRight: '0',
          fontSize: '1.2rem',
          backgroundColor: '#D9D9D9',
          border: 'none',
          borderRadius: '5px',
          borderBottom: '2px solid',
          borderColor: strutureJson?.profile === 3 ? '#FF0000' : strutureJson?.profile === 2 ? '#ffae00' : strutureJson?.profile === 1 ? '#00323f' : '#ebebeb',
          '&:focus' : {
              outline: 'none',
              borderColor: strutureJson?.profile === 3 ? '#FF0000' : strutureJson?.profile === 2 ? '#ffae00' : strutureJson?.profile === 1 ? '#00323f' : '#ebebeb',
          }
      }
    }));

    useEffect(() => {
        if(props?.clientData?.type != 1){
            getFinishedJson()
        }
    }, []);

    const classes = useStyles();

    async function autoSave(isObservation,idQuestion, answer){
    }

    async function getFinishedJson(){
        // console.log("Broker:",props?.clientData?.value[24])
        // console.log("Cliente:",props?.clientData?.value[0])
        setLoading(true)
        try {
            const jsonResponse = await api.get(`/api/v1/vba/clientActivationForm/${parseInt(props?.clientData?.value[0])}`,
            { headers: {
                authorization: `Bearer ${tokenKey}`
            }
            })
            // console.log('jsonResponse',jsonResponse?.data?.data[0].form)
            setFormResponseDate(jsonResponse?.data?.data[0].created_at.split("T")[0].split("-")[2] + "/" + jsonResponse?.data?.data[0].created_at.split("T")[0].split("-")[1] + "/" + jsonResponse?.data?.data[0].created_at.split("T")[0].split("-")[0])
            var closedJsonResponse = JSON.parse(jsonResponse?.data?.data[0].form)
            closedJsonResponse.sections?.forEach(element => {
                element.isClose = true
            })
            if(props?.clientData?.type == 3 || props?.clientData?.type == 4 || props?.clientData?.type == 5){
                strutureJson.sections?.forEach(element => {
                    element.isClose = false
                })
                closedJsonResponse.sections?.forEach(element => {
                    element.isClose = false
                })
            }
            if(props?.clientData?.type == 2 || props?.clientData?.type == 4){
                setStrutureJson(closedJsonResponse)
            }
            if(props?.clientData?.type == 3 || props?.clientData?.type == 5){
                strutureJson?.sections?.map(section => {
                    closedJsonResponse?.sections?.map(sectionAux => {
                        
                        if(section.id == sectionAux.id){
                            section?.questions?.map(question => {
                                sectionAux?.questions?.map(questionAux => {
                                    if(question.id == questionAux.id){
                                        question.answer = questionAux.answer
                                        
                                        if(question.options?.length > 0){
                                            question?.options?.map(option => {
                                                
                                                if(option.questions?.length > 0){
                                                    option?.questions?.map(question2 => {
                                                        if(questionAux.length > 0){
                                                            questionAux?.map(optionAux => {
                                                                if(option.id == optionAux.id){
                                                                    if(optionAux?.questions?.length > 0){
                                                                        optionAux?.questions?.map(questionAux2 => {
                                                                            if(question2.id == questionAux2.id){
                                                                                question2.answer = questionAux2.answer
                                                                            }
                                                                        })
                                                                    }
                                                                }
                                                            })
                                                        }
                                                    })
                                                }
                                            })
                                        }
                                    }
                                })
                            })
                        }
                    })
                })
            }
            setLoading(false)
            // setEdited(true)
        } catch (error) {
            setLoading(false)
        }
    }

    async function sendForm(){
        setLoading(true)
        
        setLoading(false)
        // console.log(parseInt(props.clientData.value[0]))
        // return
        try {
            await api.post(`/api/v1/vba/clientActivationForm`, 
                {
                    client_id: parseInt(props.clientData.value[0]),
                    form: JSON.stringify(strutureJson)
                },
                { headers: {
                    authorization: `Bearer ${tokenKey}`
                }
            },
            {

            })
            // console.log('props.clientData.value[0]',props.clientData)
            props.clientData.handleSubmit(6)
            setLoading(false)
            setType({ name: 'alertMsg', type: 'information', value: { message: 'Ativação do cliente realizada com sucesso!' } })
            // setEdited(true)
        } catch (error) {
            setType({ name: 'alertMsg', type: 'information', value: { message: 'Erro ao finalizar sessão de respostas' } })
            setShow(true)
        }
    }

    async function updateForm(){
        setLoading(true)
        // console.log(parseInt(props.clientData.value[0]))
        // console.log(strutureJson)
        try {
            await api.post(`/api/v1/vba/clientActivationForm`, 
                {
                    client_id: parseInt(props.clientData.value[0]),
                    form: JSON.stringify(strutureJson)
                },
                { headers: {
                    authorization: `Bearer ${tokenKey}`
                }
            },
            {

            })
            setLoading(false)
            setType({ name: 'alertMsg', type: 'information', value: { message: 'Atualização de Formulário realizada com sucesso!' } })
            // setEdited(true)
        } catch (error) {
            setType({ name: 'alertMsg', type: 'information', value: { message: 'Erro ao atualizar sessão de respostas' } })
            setShow(true)
        }
    }

    async function finishAndSend(){
      setLoading(true)
      try {
          await api.post(`/api/v1/forms/finish/${idReply}`, {},
          { headers: {
                  authorization: `Bearer ${tokenKey}`
          }})
          setLoading(false)
      } catch (error) {
          setType({ name: 'alertMsg', type: 'information', value: { message: 'Erro ao finalizar sessão de respostas' } })
          setShow(true)
      }
    }

    async function activeClient(){
        setLoading(true)
        try {
            await api.put(`/api/v1/vba/activeClient/${parseInt(props?.clientData?.value[0])}`, {},
            { headers: {
                    authorization: `Bearer ${tokenKey}`
            }})
            setType({ name: 'alertMsg', type: 'information', value: { message: 'Cliente ativado com sucesso!' } })
            // setEdited(true)
            setLoading(false)
        } catch (error) {
            setType({ name: 'alertMsg', type: 'information', value: { message: 'Erro ao ativar cliente' } })
            setShow(true)
        }
      }

    async function showClientDetails() {
      setShow(true);
      setStrutureJson({})
      setType({ name: 'showVbaClientDetails', value: { client: props?.clientData?.value} });
    }

    function checkScroll(e){
        // console.log((e.target?.scrollHeight - e.target.scrollTop), e.target.clientHeight + 100 )
        if(e.target?.scrollHeight - e.target.scrollTop <= e.target.clientHeight + 100){
            setUnlockButton(true)
        }
    }

    return (
        <div className='clientDetailsContainer'>
            {loading ?
              (<div id='clientDetailsLoader'>
                <ArrowLoader />
              </div>) 
              :
              (
              <>
                <div className='clientsDetailsHeader'>
                  <div>
                    <p>{props?.clientData?.type == 1 ? "Ativação do Cliente:" : (props?.clientData?.type == 2 || props?.clientData?.type == 5 ? "Formulário de ativação respondido do cliente:" : "Conferência de preenchimento do formulário:")}</p>
                    <p>{props?.clientData?.value[0]} - {titleize(props?.clientData?.value[1])}</p>
                    {/* <button onClick={() => {console.log(strutureJson)}}>PRINT</button> */}
                  </div>
                  <button onClick={() => {showClientDetails()}}><BsArrowLeftShort size={22}/></button>
                </div>
                <div className="containerGeralCreateFormStructureVBACLIENTSACTIVATION">
                <>
                {loading ? 
                <div className="loaderAllFormsVBACLIENTSACTIVATION">
                  <ArrowLoader style={{marginBottom: "5rem"}} />
                </div>
                : 
                (strutureJson?.sections == undefined ?
                <div className='containerWarningEmptyStrutureJsonVBACLIENTSACTIVATION'>Não foi encontrado nenhum registro deste formulário...</div>
                :
                <div className="boxAnswersVBACLIENTSACTIVATION" onScroll={(e) => {checkScroll(e)}}>
                    {strutureJson?.sections?.map((section, index) => {
                    return(
                        <>
                        <div key= {index} className={classes.sectionClosed} onClick={() => {
                                strutureJson.sections[index].isClose = !strutureJson.sections[index].isClose;
                                setRefresh(refresh+1)
                                }}>
                            <div></div>
                            <div className='sectionTitleVBACLIENTSACTIVATION'>
                                <p>{section?.title}</p>
                            </div>
                            <div className='buttonOpenCloseSectionVBACLIENTSACTIVATION'>
                                {strutureJson.sections[index].isClose ? <FiPlus size = '30px'/> : <FiMinus size = '30px'/>}
                                </div>
                        </div>
                        {section?.questions.map((question, indexq) => {
                        if (!section?.isClose){
                            // NÍVEL 1 ////////////////////////////////////////////
                            return(
                                <>
                                <div key={indexq} className={classes.boxQuestion}>
                                    <div className="questionDescriptionVBACLIENTSACTIVATION">
                                        {question?.description}
                                    </div>
                                    <div className="gridtesteVBACLIENTSACTIVATION">
                                        <div className={classes.separacaoOpcoes}></div>
                                        <div className="opcoesDaPerguntaVBACLIENTSACTIVATION">
                                        {question?.type=== 3 ? (
                                            question?.options.map((options, indexO) => {
                                                return (
                                                    <>
                                                        <div className={classes.multipleAnswerBox} key={indexO}>
                                                        <input disabled={finished ?? "disabled"} type="checkbox" id="check" name="check" defaultChecked={question.answer?.indexOf(question.options[indexO].id)  !== -1 ?? 'checked'} className="checkboxVBACLIENTSACTIVATION" onChange={e => {
                                                            if (question.answer?.indexOf(question.options[indexO].id) === -1)question.answer.push(question.options[indexO].id)
                                                            else question.answer?.splice(question.answer?.indexOf(question.options[indexO].id), 1)
                                                            question.options[indexO].answer = e.target.checked
                                                            setRefresh(refresh+1)
                                                            autoSave(false,question.id, question.answer)
                                                            }}></input>
                                                        {options?.description}
                                                        {/* Se a opção estiver marcada, abre as possiveis subperguntas */}
                                                        
                                                        {(options?.questions?.length > 0 && question.answer?.indexOf(question.options[indexO].id)  !== -1) ?
                                                            options?.questions?.map((question2,indexq2) => {
                                                                // NIVEL 2 //////////////////////////
                                                                return (




                                                                    <>
                                                                    <div key={indexq2} className={classes.boxQuestion}>
                                                                        <div className="questionDescriptionVBACLIENTSACTIVATION">
                                                                            {question2?.description}
                                                                        </div>
                                                                        <div className="gridtesteVBACLIENTSACTIVATION">
                                                                            <div className={classes.separacaoOpcoes}></div>
                                                                            <div className="opcoesDaPerguntaVBACLIENTSACTIVATION">
                                                                            {question2?.type=== 3 ? (
                                                                                question2?.options.map((options2, indexO2) => {
                                                                                    return (
                                                                                        <>
                                                                                            <div className={classes.multipleAnswerBox} key={indexO2}>
                                                                                            <input disabled={finished ?? "disabled"} type="checkbox" id="check" name="check" defaultChecked={question2.answer?.indexOf(question2.options[indexO2].id)  !== -1 ?? 'checked'}className="checkboxVBACLIENTSACTIVATION" onChange={e => {
                                                                                                if (question2.answer?.indexOf(question2.options[indexO2].id) === -1)question2.answer.push(question2.options[indexO2].id)
                                                                                                else question2.answer?.splice(question2.answer?.indexOf(question2.options[indexO2].id), 1)
                                                                                                question2.options[indexO2].answer = e.target.checked
                                                                                                setRefresh(refresh+1)
                                                                                                autoSave(false,question2.id, question2.answer)
                                                                                                }}></input>
                                                                                            {options2?.description}
                                                                                            {/* Se a opção estiver marcada, abre as possiveis subperguntas */}
                                                                                            
                                                                                            {(options2?.questions?.length > 0 && question2.answer?.indexOf(question2.options[indexO2].id)  !== -1) ?
                                                                                                options2?.questions?.map((question3,indexq3) => {
                                                                                                    // NIVEL 3 //////////////////////////
                                                                                                    return (
                                            
                                            
                                            
                                            
                                                                                                        <>
                                                                                                        <div key={indexq3} className={classes.boxQuestion}>
                                                                                                            <div className="questionDescriptionVBACLIENTSACTIVATION">
                                                                                                                {question3?.description}
                                                                                                            </div>
                                                                                                            <div className="gridtesteVBACLIENTSACTIVATION">
                                                                                                                <div className={classes.separacaoOpcoes}></div>
                                                                                                                <div className="opcoesDaPerguntaVBACLIENTSACTIVATION">
                                                                                                                {question3?.type=== 3 ? (
                                                                                                                    question3?.options.map((options3, indexO3) => {
                                                                                                                        return (
                                                                                                                            <>
                                                                                                                                <div className={classes.multipleAnswerBox} key={indexO3}>
                                                                                                                                <input disabled={finished ?? "disabled"} type="checkbox" id="check" name="check" defaultChecked={question3.answer?.indexOf(question3.options[indexO3].id)  !== -1 ?? 'checked'} className="checkboxVBACLIENTSACTIVATION" onChange={e => {
                                                                                                                                    if (question3.answer?.indexOf(question3.options[indexO3].id) === -1)question3.answer.push(question3.options[indexO3].id)
                                                                                                                                    else question3.answer?.splice(question3.answer?.indexOf(question3.options[indexO3].id), 1)
                                                                                                                                    question3.options[indexO3].answer = e.target.checked
                                                                                                                                    setRefresh(refresh+1)
                                                                                                                                    autoSave(false,question3.id, question3.answer)
                                                                                                                                    }}></input>
                                                                                                                                {options3?.description}
                                                                                                                                {/* Se a opção estiver marcada, abre as possiveis subperguntas */}
                                                                                                                                
                                                                                                                                {(options3?.questions?.length > 0 && question3.answer?.indexOf(question3.options[indexO3].id)  !== -1) ?
                                                                                                                                    options3?.questions?.map((question4,indexq4) => {
                                                                                                                                        // NIVEL 4 //////////////////////////
                                                                                                                                        return (
                                                                                
                                                                                
                                                                                
                                                                                
                                                                                                                                            <>
                                                                                                                                            <div key={indexq4} className={classes.boxQuestion}>
                                                                                                                                                <div className="questionDescriptionVBACLIENTSACTIVATION">
                                                                                                                                                    {question4?.description}
                                                                                                                                                </div>
                                                                                                                                                <div className="gridtesteVBACLIENTSACTIVATION">
                                                                                                                                                    <div className={classes.separacaoOpcoes}></div>
                                                                                                                                                    <div className="opcoesDaPerguntaVBACLIENTSACTIVATION">
                                                                                                                                                    {question4?.type=== 3 ? (
                                                                                                                                                        question4?.options.map((options4, indexO4) => {
                                                                                                                                                            return (
                                                                                                                                                                <>
                                                                                                                                                                    <div className={classes.multipleAnswerBox} key={indexO4}>
                                                                                                                                                                    <input disabled={finished ?? "disabled"} type="checkbox" id="check" name="check" defaultChecked={question4.answer?.indexOf(question4.options[indexO4].id)  !== -1 ?? 'checked'}className="checkboxVBACLIENTSACTIVATION" onChange={e => {
                                                                                                                                                                        if (question4.answer?.indexOf(question4.options[indexO4].id) === -1)question4.answer.push(question4.options[indexO4].id)
                                                                                                                                                                        else question4.answer?.splice(question4.answer?.indexOf(question4.options[indexO4].id), 1)
                                                                                                                                                                        question4.options[indexO4].answer = e.target.checked
                                                                                                                                                                        setRefresh(refresh+1)
                                                                                                                                                                        autoSave(false,question4.id, question4.answer)
                                                                                                                                                                        }}></input>
                                                                                                                                                                    {options4?.description}
                                                                                                                                                                    </div>
                                                                                                                                                                    
                                                                                                                                                                    
                                                                                                                                                                </>
                                                                                                                                                            )
                                                                                                                                                        }) ) :
                                                                                                                                                    question4?.type=== 1 ? 
                                                                                                                                                        <textarea type="text" id="answer" name="answer" placeholder='Digite sua resposta aqui' disabled={finished ?? "disabled"} className={classes.inputTextAnswer} onChange={(e) => question4.answer =  e.target.value} defaultValue={question4.answer?? question4.answer} onBlur={() => autoSave(false,question4.id, question4.answer)}></textarea> : 
                                                                                                                                                    question4?.type=== 2 ? 
                                                                                                                                                        (question4?.options.map((options4, indexO4) => {
                                                                                                                                                            return (
                                                                                                                                                                <div className={classes.multipleAnswerBox} key={indexO4}>
                                                                                                                                                                <input disabled={finished ?? "disabled"} type="radio" id="radio4" name={question4.id} defaultChecked={question4.answer === options4.id ?? 'checked'} className="radioboxVBACLIENTSACTIVATION" onClick={
                                                                                                                                                                    () => {
                                                                                                                                                                        question4.answer = options4.id
                                                                                                                                                                        setRefresh(refresh+1)
                                                                                                                                                                        autoSave(false,question4.id, question4.answer)
                                                                                                                                                                    }}></input>
                                                                                                                                                                {options4?.description}
                                                                                                                                                                </div>
                                                                                                                                                            )
                                                                                                                                                        }) ) :
                                                                                                                                                    question4?.type=== 4 ? 
                                                                                                                                                    
                                                                                                                                                        (<CurrencyTextField
                                                                                                                                                            disabled={finished ?? "disabled"}
                                                                                                                                                            variant="outlined"
                                                                                                                                                            // value={0.00}
                                                                                                                                                            className={classes.inputMonetaryAnswer}
                                                                                                                                                            currencySymbol="R$"
                                                                                                                                                            outputFormat="number"
                                                                                                                                                            decimalCharacter=","
                                                                                                                                                            // required={true}
                                                                                                                                                            digitGroupSeparator="."
                                                                                                                                                            onChange={(e) => question4.answer =  e.target.value} defaultValue={question4.answer?? question4.answer} onBlur={() => autoSave(false,question4.id, question4.answer)}
                                                                                                                                                        />) :
                                                                                                                                                    question4?.type=== 5 ? 
                                                                                                                                                        <TextField type='number' 
                                                                                                                        onKeyPress={(e) => { if (e.code === 'Backquote') e.preventDefault() }} 
                                                                                                                        step='0.00' 
                                                                                                                        onKeyDown={(e) => { if (e.key === 'e' || e.key === 'E') { e.preventDefault() } }} 
                                                                                                                        variant='outlined'
                                                                                                                        onBlur={() => autoSave(false,question4.id, question4.answer)}
                                                                                                                        disabled={finished ?? "disabled"}
                                                                                                                        id="answer" name="answer"
                                                                                                                        className={classes.inputPercentageAnswer}
                                                                                                                        onChange={(e) => question4.answer =  (e.target.value).replace(',','.')}
                                                                                                                        defaultValue={question4.answer?? question4.answer}
                                                                                                                    /> :
                                                                                                                                                    <></>}
                                                                                                                                                    </div>
                                                                                                                                            </div>
                                                                                                                                                    {question4?.observation === true ? <div className="observationVBACLIENTSACTIVATION">
                                                                                                                                                <p className="observationTextVBACLIENTSACTIVATION">Observações:</p>
                                                                                                                                                <textarea disabled={finished ?? "disabled"} type="text" id="obs" name="obs" placeholder='Observações aqui' className={classes.inputTextAnswer} onChange={(e) => question4.answer_observation =  e.target.value} defaultValue={question4.answer_observation ?? question4.answer_observation} onBlur={() => autoSave(true,question4.id, question4.answer_observation)}></textarea> 
                                                                                                                                            </div>
                                                                                                                                            : <></>}
                                                                                                                                            </div>
                                                                                                                                            </>
                                                                                
                                                                                
                                                                                
                                                                                
                                                                                                                                        )
                                                                                                                                    })
                                                                                                                                    
                                                                                                                                    : <></>
                                                                                                                                }
                                                                                                                                </div>
                                                                                                                                
                                                                                                                                
                                                                                                                            </>
                                                                                                                        )
                                                                                                                    }) ) :
                                                                                                                question3?.type=== 1 ? 
                                                                                                                    <textarea type="text" id="answer" name="answer" placeholder='Digite sua resposta aqui' disabled={finished ?? "disabled"} className={classes.inputTextAnswer} onChange={(e) => question3.answer =  e.target.value} defaultValue={question3.answer?? question3.answer} onBlur={() => autoSave(false,question3.id, question3.answer)}></textarea> : 
                                                                                                                question3?.type=== 2 ? 
                                                                                                                    (question3?.options.map((options3, indexO3) => {
                                                                                                                        return (
                                                                                                                            <div className={classes.multipleAnswerBox} key={indexO3}>
                                                                                                                            <input disabled={finished ?? "disabled"} type="radio" id="radio3" defaultChecked={question3.answer === options3.id ?? 'checked'} name={question3.id} className="radioboxVBACLIENTSACTIVATION" onClick={
                                                                                                                                () => {
                                                                                                                                    question3.answer = options3.id
                                                                                                                                    setRefresh(refresh+1)
                                                                                                                                    autoSave(false,question3.id, question3.answer)
                                                                                                                                }}></input>
                                                                                                                            {options3?.description}
                                                                                                                            {/* Se a opção estiver marcada, abre as possiveis subperguntas */}
                                                                                                                            {(options3?.questions?.length > 0 && question3?.answer === options3?.id) ?
                                                                                                                                options3?.questions?.map((question4,indexq4) => {
                                                                                                                                    // NIVEL 4 //////////////////////////
                                                                                                                                    return (
                                                                                
                                                                                
                                                                                
                                                                                
                                                                                                                                        <>
                                                                                                                                        <div key={indexq4} className={classes.boxQuestion}>
                                                                                                                                            <div className="questionDescriptionVBACLIENTSACTIVATION">
                                                                                                                                                {question4?.description}
                                                                                                                                            </div>
                                                                                                                                            <div className="gridtesteVBACLIENTSACTIVATION">
                                                                                                                                                <div className={classes.separacaoOpcoes}></div>
                                                                                                                                                <div className="opcoesDaPerguntaVBACLIENTSACTIVATION">
                                                                                                                                                {question4?.type=== 3 ? (
                                                                                                                                                    question4?.options.map((options4, indexO4) => {
                                                                                                                                                        return (
                                                                                                                                                            <>
                                                                                                                                                                <div className={classes.multipleAnswerBox} key={indexO4}>
                                                                                                                                                                <input disabled={finished ?? "disabled"} type="checkbox" id="check" name="check" defaultChecked={question4.answer?.indexOf(question4.options[indexO4].id)  !== -1 ?? 'checked'} className="checkboxVBACLIENTSACTIVATION" onChange={e => {
                                                                                                                                                                    if (question4.answer?.indexOf(question4.options[indexO4].id) === -1)question4.answer.push(question4.options[indexO4].id)
                                                                                                                                                                    else question4.answer?.splice(question4.answer?.indexOf(question4.options[indexO4].id), 1)
                                                                                                                                                                    question4.options[indexO4].answer = e.target.checked
                                                                                                                                                                    setRefresh(refresh+1)
                                                                                                                                                                    autoSave(false,question4.id, question4.answer)
                                                                                                                                                                    }}></input>
                                                                                                                                                                {options4?.description}
                                                                                                                                                                </div>
                                                                                                                                                                
                                                                                                                                                                
                                                                                                                                                            </>
                                                                                                                                                        )
                                                                                                                                                    }) ) :
                                                                                                                                                question4?.type=== 1 ? 
                                                                                                                                                    <textarea type="text" id="answer" name="answer" placeholder='Digite sua resposta aqui' disabled={finished ?? "disabled"} className={classes.inputTextAnswer} onChange={(e) => question4.answer =  e.target.value} defaultValue={question4.answer?? question4.answer} onBlur={() => autoSave(false,question4.id, question4.answer)}></textarea> : 
                                                                                                                                                question4?.type=== 2 ? 
                                                                                                                                                    (question4?.options.map((options4, indexO4) => {
                                                                                                                                                        return (
                                                                                                                                                            <div className={classes.multipleAnswerBox} key={indexO4}>
                                                                                                                                                            <input disabled={finished ?? "disabled"} type="radio" id="radio4" defaultChecked={question4.answer === options4.id ?? 'checked'} name={question4.id} className="radioboxVBACLIENTSACTIVATION" onClick={
                                                                                                                                                                () => {
                                                                                                                                                                    question4.answer = options4.id
                                                                                                                                                                    setRefresh(refresh+1)
                                                                                                                                                                    autoSave(false,question4.id, question4.answer)
                                                                                                                                                                }}></input>
                                                                                                                                                            {options4?.description}
                                                                                                                                                            </div>
                                                                                                                                                        )
                                                                                                                                                    }) ) :
                                                                                                                                                question4?.type=== 4 ? 
                                                                                                                                                
                                                                                                                                                    (<CurrencyTextField
                                                                                                                                                        disabled={finished ?? "disabled"}
                                                                                                                                                        variant="outlined"
                                                                                                                                                        // value={0.00}
                                                                                                                                                        className={classes.inputMonetaryAnswer}
                                                                                                                                                        currencySymbol="R$"
                                                                                                                                                        outputFormat="number"
                                                                                                                                                        decimalCharacter=","
                                                                                                                                                        // required={true}
                                                                                                                                                        digitGroupSeparator="."
                                                                                                                                                        onChange={(e) => question4.answer =  e.target.value} defaultValue={question4.answer?? question4.answer} onBlur={() => autoSave(false,question4.id, question4.answer)}
                                                                                                                                                    />) :
                                                                                                                                                question4?.type=== 5 ? 
                                                                                                                                                    <TextField type='number' 
                                                                                                                        onKeyPress={(e) => { if (e.code === 'Backquote') e.preventDefault() }} 
                                                                                                                        step='0.00' 
                                                                                                                        onKeyDown={(e) => { if (e.key === 'e' || e.key === 'E') { e.preventDefault() } }} 
                                                                                                                        variant='outlined'
                                                                                                                        onBlur={() => autoSave(false,question4.id, question4.answer)}
                                                                                                                        disabled={finished ?? "disabled"}
                                                                                                                        id="answer" name="answer"
                                                                                                                        className={classes.inputPercentageAnswer}
                                                                                                                        onChange={(e) => question4.answer =  (e.target.value).replace(',','.')}
                                                                                                                        defaultValue={question4.answer?? question4.answer}
                                                                                                                    /> :
                                                                                                                                                <></>}
                                                                                                                                                </div>
                                                                                                                                        </div>
                                                                                                                                                {question4?.observation === true ? <div className="observationVBACLIENTSACTIVATION">
                                                                                                                                                <p className="observationTextVBACLIENTSACTIVATION">Observações:</p>
                                                                                                                                                <textarea disabled={finished ?? "disabled"} type="text" id="obs" name="obs" placeholder='Observações aqui' className={classes.inputTextAnswer} onChange={(e) => question4.answer_observation =  e.target.value} defaultValue={question4.answer_observation ?? question4.answer_observation} onBlur={() => autoSave(true,question4.id, question4.answer_observation)}></textarea> 
                                                                                                                                            </div>
                                                                                                                                            : <></>}
                                                                                                                                        </div>
                                                                                                                                        </>
                                                                                
                                                                                
                                                                                
                                                                                
                                                                                                                                    )
                                                                                                                                })
                                                                                                                                
                                                                                                                                : <></>
                                                                                                                            }
                                                                                                                            </div>
                                                                                                                        )
                                                                                                                    }) ) :
                                                                                                                question3?.type=== 4 ? 
                                                                                                                
                                                                                                                    (<CurrencyTextField
                                                                                                                        disabled={finished ?? "disabled"}
                                                                                                                        variant="outlined"
                                                                                                                        // value={0.00}
                                                                                                                        className={classes.inputMonetaryAnswer}
                                                                                                                        currencySymbol="R$"
                                                                                                                        outputFormat="number"
                                                                                                                        decimalCharacter=","
                                                                                                                        // required={true}
                                                                                                                        digitGroupSeparator="."
                                                                                                                        onChange={(e) => question3.answer =  e.target.value} defaultValue={question3.answer?? question3.answer} onBlur={() => autoSave(false,question3.id, question3.answer)}
                                                                                                                    />) :
                                                                                                                question3?.type=== 5 ? 
                                                                                                                    <TextField type='number' 
                                                                                                                        onKeyPress={(e) => { if (e.code === 'Backquote') e.preventDefault() }} 
                                                                                                                        step='0.00' 
                                                                                                                        onKeyDown={(e) => { if (e.key === 'e' || e.key === 'E') { e.preventDefault() } }} 
                                                                                                                        variant='outlined'
                                                                                                                        onBlur={() => autoSave(false,question3.id, question3.answer)}
                                                                                                                        disabled={finished ?? "disabled"}
                                                                                                                        id="answer" name="answer"
                                                                                                                        className={classes.inputPercentageAnswer}
                                                                                                                        onChange={(e) => question3.answer =  (e.target.value).replace(',','.')}
                                                                                                                        defaultValue={question3.answer?? question3.answer}
                                                                                                                    /> :
                                                                                                                <></>}
                                                                                                                </div>
                                                                                                        </div>
                                                                                                                {question3?.observation === true ? <div className="observationVBACLIENTSACTIVATION">
                                                                                                                                                <p className="observationTextVBACLIENTSACTIVATION">Observações:</p>
                                                                                                                                                <textarea disabled={finished ?? "disabled"} type="text" id="obs" name="obs" placeholder='Observações aqui' className={classes.inputTextAnswer} onChange={(e) => question3.answer_observation =  e.target.value} defaultValue={question3.answer_observation ?? question3.answer_observation} onBlur={() => autoSave(true,question3.id, question3.answer_observation)}></textarea> 
                                                                                                                                            </div>
                                                                                                                                            : <></>}
                                                                                                        </div>
                                                                                                        </>
                                            
                                            
                                            
                                            
                                                                                                    )
                                                                                                })
                                                                                                
                                                                                                : <></>
                                                                                            }
                                                                                            </div>
                                                                                            
                                                                                            
                                                                                        </>
                                                                                    )
                                                                                }) ) :
                                                                            question2?.type=== 1 ? 
                                                                                <textarea type="text" id="answer" name="answer" placeholder='Digite sua resposta aqui' disabled={finished ?? "disabled"} className={classes.inputTextAnswer} onChange={(e) => question2.answer =  e.target.value} defaultValue={question2.answer?? question2.answer} onBlur={() => autoSave(false,question2.id, question2.answer)}></textarea> : 
                                                                            question2?.type=== 2 ? 
                                                                                (question2?.options.map((options2, indexO2) => {
                                                                                    return (
                                                                                        <div className={classes.multipleAnswerBox} key={indexO2}>
                                                                                        <input disabled={finished ?? "disabled"} type="radio" id="radio2" defaultChecked={question2.answer === options2.id ?? 'checked'} name={question2.id} className="radioboxVBACLIENTSACTIVATION" onClick={
                                                                                            () => {
                                                                                                question2.answer = options2.id
                                                                                                setRefresh(refresh+1)
                                                                                                autoSave(false,question2.id, question2.answer)
                                                                                            }}></input>
                                                                                        {options2?.description}
                                                                                        {/* Se a opção estiver marcada, abre as possiveis subperguntas */}
                                                                                        {(options2?.questions?.length > 0 && question2?.answer === options2?.id) ?
                                                                                            options2?.questions?.map((question3,indexq3) => {
                                                                                                // NIVEL 3 //////////////////////////
                                                                                                return (
                                            
                                            
                                            
                                            
                                                                                                    <>
                                                                                                    <div key={indexq3} className={classes.boxQuestion}>
                                                                                                        <div className="questionDescriptionVBACLIENTSACTIVATION">
                                                                                                            {question3?.description}
                                                                                                        </div>
                                                                                                        <div className="gridtesteVBACLIENTSACTIVATION">
                                                                                                            <div className={classes.separacaoOpcoes}></div>
                                                                                                            <div className="opcoesDaPerguntaVBACLIENTSACTIVATION">
                                                                                                            {question3?.type=== 3 ? (
                                                                                                                question3?.options.map((options3, indexO3) => {
                                                                                                                    return (
                                                                                                                        <>
                                                                                                                            <div className={classes.multipleAnswerBox} key={indexO3}>
                                                                                                                            <input disabled={finished ?? "disabled"} type="checkbox" id="check" name="check" defaultChecked={question3.answer?.indexOf(question3.options[indexO3].id)  !== -1 ?? 'checked'} className="checkboxVBACLIENTSACTIVATION" onChange={e => {
                                                                                                                                if (question3.answer?.indexOf(question3.options[indexO3].id) === -1)question3.answer.push(question3.options[indexO3].id)
                                                                                                                                else question3.answer?.splice(question3.answer?.indexOf(question3.options[indexO3].id), 1)
                                                                                                                                question3.options[indexO3].answer = e.target.checked
                                                                                                                                setRefresh(refresh+1)
                                                                                                                                autoSave(false,question3.id, question3.answer)
                                                                                                                                }}></input>
                                                                                                                            {options3?.description}
                                                                                                                            {/* Se a opção estiver marcada, abre as possiveis subperguntas */}
                                                                                                                            
                                                                                                                            {(options3?.questions?.length > 0 && question3.answer?.indexOf(question3.options[indexO3].id)  !== -1) ?
                                                                                                                                options3?.questions?.map((question4,indexq4) => {
                                                                                                                                    // NIVEL 4 //////////////////////////
                                                                                                                                    return (
                                                                            
                                                                            
                                                                            
                                                                            
                                                                                                                                        <>
                                                                                                                                        <div key={indexq4} className={classes.boxQuestion}>
                                                                                                                                            <div className="questionDescriptionVBACLIENTSACTIVATION">
                                                                                                                                                {question4?.description}
                                                                                                                                            </div>
                                                                                                                                            <div className="gridtesteVBACLIENTSACTIVATION">
                                                                                                                                                <div className={classes.separacaoOpcoes}></div>
                                                                                                                                                <div className="opcoesDaPerguntaVBACLIENTSACTIVATION">
                                                                                                                                                {question4?.type=== 3 ? (
                                                                                                                                                    question4?.options.map((options4, indexO4) => {
                                                                                                                                                        return (
                                                                                                                                                            <>
                                                                                                                                                                <div className={classes.multipleAnswerBox} key={indexO4}>
                                                                                                                                                                <input disabled={finished ?? "disabled"} type="checkbox" id="check" name="check" defaultChecked={question4.answer?.indexOf(question4.options[indexO4].id)  !== -1 ?? 'checked'} className="checkboxVBACLIENTSACTIVATION" onChange={e => {
                                                                                                                                                                    if (question4.answer?.indexOf(question4.options[indexO4].id) === -1)question4.answer.push(question4.options[indexO4].id)
                                                                                                                                                                    else question4.answer?.splice(question4.answer?.indexOf(question4.options[indexO4].id), 1)
                                                                                                                                                                    question4.options[indexO4].answer = e.target.checked
                                                                                                                                                                    setRefresh(refresh+1)
                                                                                                                                                                    autoSave(false,question4.id, question4.answer)
                                                                                                                                                                    }}></input>
                                                                                                                                                                {options4?.description}
                                                                                                                                                                </div>
                                                                                                                                                                
                                                                                                                                                                
                                                                                                                                                            </>
                                                                                                                                                        )
                                                                                                                                                    }) ) :
                                                                                                                                                question4?.type=== 1 ? 
                                                                                                                                                    <textarea type="text" id="answer" name="answer" placeholder='Digite sua resposta aqui' disabled={finished ?? "disabled"} className={classes.inputTextAnswer} onChange={(e) => question4.answer =  e.target.value} defaultValue={question4.answer?? question4.answer} onBlur={() => autoSave(false,question4.id, question4.answer)}></textarea> : 
                                                                                                                                                question4?.type=== 2 ? 
                                                                                                                                                    (question4?.options.map((options4, indexO4) => {
                                                                                                                                                        return (
                                                                                                                                                            <div className={classes.multipleAnswerBox} key={indexO4}>
                                                                                                                                                            <input disabled={finished ?? "disabled"} type="radio" id="radio4" defaultChecked={question4.answer === options4.id ?? 'checked'} name={question4.id} className="radioboxVBACLIENTSACTIVATION" onClick={
                                                                                                                                                                () => {
                                                                                                                                                                    question4.answer = options4.id
                                                                                                                                                                    setRefresh(refresh+1)
                                                                                                                                                                    autoSave(false,question4.id, question4.answer)
                                                                                                                                                                }}></input>
                                                                                                                                                            {options4?.description}
                                                                                                                                                            </div>
                                                                                                                                                        )
                                                                                                                                                    }) ) :
                                                                                                                                                question4?.type=== 4 ? 
                                                                                                                                                
                                                                                                                                                    (<CurrencyTextField
                                                                                                                                                        disabled={finished ?? "disabled"}
                                                                                                                                                        variant="outlined"
                                                                                                                                                        // value={0.00}
                                                                                                                                                        className={classes.inputMonetaryAnswer}
                                                                                                                                                        currencySymbol="R$"
                                                                                                                                                        outputFormat="number"
                                                                                                                                                        decimalCharacter=","
                                                                                                                                                        // required={true}
                                                                                                                                                        digitGroupSeparator="."
                                                                                                                                                        onChange={(e) => question4.answer =  e.target.value} defaultValue={question4.answer?? question4.answer} onBlur={() => autoSave(false,question4.id, question4.answer)}
                                                                                                                                                    />) :
                                                                                                                                                question4?.type=== 5 ? 
                                                                                                                                                    <TextField type='number' 
                                                                                                                        onKeyPress={(e) => { if (e.code === 'Backquote') e.preventDefault() }} 
                                                                                                                        step='0.00' 
                                                                                                                        onKeyDown={(e) => { if (e.key === 'e' || e.key === 'E') { e.preventDefault() } }} 
                                                                                                                        variant='outlined'
                                                                                                                        onBlur={() => autoSave(false,question4.id, question4.answer)}
                                                                                                                        disabled={finished ?? "disabled"}
                                                                                                                        id="answer" name="answer"
                                                                                                                        className={classes.inputPercentageAnswer}
                                                                                                                        onChange={(e) => question4.answer =  (e.target.value).replace(',','.')}
                                                                                                                        defaultValue={question4.answer?? question4.answer}
                                                                                                                    /> :
                                                                                                                                                <></>}
                                                                                                                                                </div>
                                                                                                                                        </div>
                                                                                                                                                {question4?.observation === true ? <div className="observationVBACLIENTSACTIVATION">
                                                                                                                                                <p className="observationTextVBACLIENTSACTIVATION">Observações:</p>
                                                                                                                                                <textarea disabled={finished ?? "disabled"} type="text" id="obs" name="obs" placeholder='Observações aqui' className={classes.inputTextAnswer} onChange={(e) => question4.answer_observation =  e.target.value} defaultValue={question4.answer_observation ?? question4.answer_observation} onBlur={() => autoSave(true,question4.id, question4.answer_observation)}></textarea> 
                                                                                                                                            </div>
                                                                                                                                            : <></>}
                                                                                                                                        </div>
                                                                                                                                        </>
                                                                            
                                                                            
                                                                            
                                                                            
                                                                                                                                    )
                                                                                                                                })
                                                                                                                                
                                                                                                                                : <></>
                                                                                                                            }
                                                                                                                            </div>
                                                                                                                            
                                                                                                                            
                                                                                                                        </>
                                                                                                                    )
                                                                                                                }) ) :
                                                                                                            question3?.type=== 1 ? 
                                                                                                                <textarea type="text" id="answer" name="answer" placeholder='Digite sua resposta aqui' disabled={finished ?? "disabled"} className={classes.inputTextAnswer} onChange={(e) => question3.answer =  e.target.value} defaultValue={question3.answer?? question3.answer} onBlur={() => autoSave(false,question3.id, question3.answer)}></textarea> : 
                                                                                                            question3?.type=== 2 ? 
                                                                                                                (question3?.options.map((options3, indexO3) => {
                                                                                                                    return (
                                                                                                                        <div className={classes.multipleAnswerBox} key={indexO3}>
                                                                                                                        <input disabled={finished ?? "disabled"} type="radio" id="radio3" defaultChecked={question3.answer === options3.id ?? 'checked'} name={question3.id} className="radioboxVBACLIENTSACTIVATION" onClick={
                                                                                                                            () => {
                                                                                                                                question3.answer = options3.id
                                                                                                                                setRefresh(refresh+1)
                                                                                                                                autoSave(false,question3.id, question3.answer)
                                                                                                                            }}></input>
                                                                                                                        {options3?.description}
                                                                                                                        {/* Se a opção estiver marcada, abre as possiveis subperguntas */}
                                                                                                                        {(options3?.questions?.length > 0 && question3?.answer === options3?.id) ?
                                                                                                                            options3?.questions?.map((question4,indexq4) => {
                                                                                                                                // NIVEL 4 //////////////////////////
                                                                                                                                return (
                                                                            
                                                                            
                                                                            
                                                                            
                                                                                                                                    <>
                                                                                                                                    <div key={indexq4} className={classes.boxQuestion}>
                                                                                                                                        <div className="questionDescriptionVBACLIENTSACTIVATION">
                                                                                                                                            {question4?.description}
                                                                                                                                        </div>
                                                                                                                                        <div className="gridtesteVBACLIENTSACTIVATION">
                                                                                                                                            <div className={classes.separacaoOpcoes}></div>
                                                                                                                                            <div className="opcoesDaPerguntaVBACLIENTSACTIVATION">
                                                                                                                                            {question4?.type=== 3 ? (
                                                                                                                                                question4?.options.map((options4, indexO4) => {
                                                                                                                                                    return (
                                                                                                                                                        <>
                                                                                                                                                            <div className={classes.multipleAnswerBox} key={indexO4}>
                                                                                                                                                            <input disabled={finished ?? "disabled"} type="checkbox" id="check" name="check" defaultChecked={question4.answer?.indexOf(question4.options[indexO4].id)  !== -1 ?? 'checked'}className="checkboxVBACLIENTSACTIVATION" onChange={e => {
                                                                                                                                                                if (question4.answer?.indexOf(question4.options[indexO4].id) === -1)question4.answer.push(question4.options[indexO4].id)
                                                                                                                                                                else question4.answer?.splice(question4.answer?.indexOf(question4.options[indexO4].id), 1)
                                                                                                                                                                question4.options[indexO4].answer = e.target.checked
                                                                                                                                                                setRefresh(refresh+1)
                                                                                                                                                                autoSave(false,question4.id, question4.answer)
                                                                                                                                                                }}></input>
                                                                                                                                                            {options4?.description}
                                                                                                                                                            </div>
                                                                                                                                                            
                                                                                                                                                            
                                                                                                                                                        </>
                                                                                                                                                    )
                                                                                                                                                }) ) :
                                                                                                                                            question4?.type=== 1 ? 
                                                                                                                                                <textarea type="text" id="answer" name="answer" placeholder='Digite sua resposta aqui' disabled={finished ?? "disabled"} className={classes.inputTextAnswer} onChange={(e) => question4.answer =  e.target.value} defaultValue={question4.answer?? question4.answer} onBlur={() => autoSave(false,question4.id, question4.answer)}></textarea> : 
                                                                                                                                            question4?.type=== 2 ? 
                                                                                                                                                (question4?.options.map((options4, indexO4) => {
                                                                                                                                                    return (
                                                                                                                                                        <div className={classes.multipleAnswerBox} key={indexO4} >
                                                                                                                                                        <input disabled={finished ?? "disabled"} type="radio" id="radio4" defaultChecked={question4.answer === options4.id ?? 'checked'} name={question4.id} className="radioboxVBACLIENTSACTIVATION" onClick={
                                                                                                                                                            () => {
                                                                                                                                                                question4.answer = options4.id
                                                                                                                                                                setRefresh(refresh+1)
                                                                                                                                                                autoSave(false,question4.id, question4.answer)
                                                                                                                                                            }}></input>
                                                                                                                                                        {options4?.description}
                                                                                                                                                        </div>
                                                                                                                                                    )
                                                                                                                                                }) ) :
                                                                                                                                            question4?.type=== 4 ? 
                                                                                                                                            
                                                                                                                                                (<CurrencyTextField
                                                                                                                                                    disabled={finished ?? "disabled"}
                                                                                                                                                    variant="outlined"
                                                                                                                                                    // value={0.00}
                                                                                                                                                    className={classes.inputMonetaryAnswer}
                                                                                                                                                    currencySymbol="R$"
                                                                                                                                                    outputFormat="number"
                                                                                                                                                    decimalCharacter=","
                                                                                                                                                    // required={true}
                                                                                                                                                    digitGroupSeparator="."
                                                                                                                                                    onChange={(e) => question4.answer =  e.target.value} defaultValue={question4.answer?? question4.answer} onBlur={() => autoSave(false,question4.id, question4.answer)}
                                                                                                                                                />) :
                                                                                                                                            question4?.type=== 5 ? 
                                                                                                                                                <TextField type='number' 
                                                                                                                        onKeyPress={(e) => { if (e.code === 'Backquote') e.preventDefault() }} 
                                                                                                                        step='0.00' 
                                                                                                                        onKeyDown={(e) => { if (e.key === 'e' || e.key === 'E') { e.preventDefault() } }} 
                                                                                                                        variant='outlined'
                                                                                                                        onBlur={() => autoSave(false,question4.id, question4.answer)}
                                                                                                                        disabled={finished ?? "disabled"}
                                                                                                                        id="answer" name="answer"
                                                                                                                        className={classes.inputPercentageAnswer}
                                                                                                                        onChange={(e) => question4.answer =  (e.target.value).replace(',','.')}
                                                                                                                        defaultValue={question4.answer?? question4.answer}
                                                                                                                    /> :
                                                                                                                                            <></>}
                                                                                                                                            </div>
                                                                                                                                    </div>
                                                                                                                                            {question4?.observation === true ? <div className="observationVBACLIENTSACTIVATION">
                                                                                                                                                <p className="observationTextVBACLIENTSACTIVATION">Observações:</p>
                                                                                                                                                <textarea disabled={finished ?? "disabled"} type="text" id="obs" name="obs" placeholder='Observações aqui' className={classes.inputTextAnswer} onChange={(e) => question4.answer_observation =  e.target.value} defaultValue={question4.answer_observation ?? question4.answer_observation} onBlur={() => autoSave(true,question4.id, question4.answer_observation)}></textarea> 
                                                                                                                                            </div>
                                                                                                                                            : <></>}
                                                                                                                                    </div>
                                                                                                                                    </>
                                                                            
                                                                            
                                                                            
                                                                            
                                                                                                                                )
                                                                                                                            })
                                                                                                                            
                                                                                                                            : <></>
                                                                                                                        }
                                                                                                                        </div>
                                                                                                                    )
                                                                                                                }) ) :
                                                                                                            question3?.type=== 4 ? 
                                                                                                            
                                                                                                                (<CurrencyTextField
                                                                                                                    disabled={finished ?? "disabled"}
                                                                                                                    variant="outlined"
                                                                                                                    // value={0.00}
                                                                                                                    className={classes.inputMonetaryAnswer}
                                                                                                                    currencySymbol="R$"
                                                                                                                    outputFormat="number"
                                                                                                                    decimalCharacter=","
                                                                                                                    // required={true}
                                                                                                                    digitGroupSeparator="."
                                                                                                                    onChange={(e) => question3.answer =  e.target.value} defaultValue={question3.answer?? question3.answer} onBlur={() => autoSave(false,question3.id, question3.answer)}
                                                                                                                />) :
                                                                                                            question3?.type=== 5 ? 
                                                                                                                <TextField type='number' 
                                                                                                                        onKeyPress={(e) => { if (e.code === 'Backquote') e.preventDefault() }} 
                                                                                                                        step='0.00' 
                                                                                                                        onKeyDown={(e) => { if (e.key === 'e' || e.key === 'E') { e.preventDefault() } }} 
                                                                                                                        variant='outlined'
                                                                                                                        onBlur={() => autoSave(false,question3.id, question3.answer)}
                                                                                                                        disabled={finished ?? "disabled"}
                                                                                                                        id="answer" name="answer"
                                                                                                                        className={classes.inputPercentageAnswer}
                                                                                                                        onChange={(e) => question3.answer =  (e.target.value).replace(',','.')}
                                                                                                                        defaultValue={question3.answer?? question3.answer}
                                                                                                                    /> :
                                                                                                            <></>}
                                                                                                            </div>
                                                                                                    </div>
                                                                                                            {question3?.observation === true ? <div className="observationVBACLIENTSACTIVATION">
                                                                                                                                                <p className="observationTextVBACLIENTSACTIVATION">Observações:</p>
                                                                                                                                                <textarea disabled={finished ?? "disabled"} type="text" id="obs" name="obs" placeholder='Observações aqui' className={classes.inputTextAnswer} onChange={(e) => question3.answer_observation =  e.target.value} defaultValue={question3.answer_observation ?? question3.answer_observation} onBlur={() => autoSave(true,question3.id, question3.answer_observation)}></textarea> 
                                                                                                                                            </div>
                                                                                                                                            : <></>}
                                                                                                    </div>
                                                                                                    </>
                                            
                                            
                                            
                                            
                                                                                                )
                                                                                            })
                                                                                            
                                                                                            : <></>
                                                                                        }
                                                                                        </div>
                                                                                    )
                                                                                }) ) :
                                                                            question2?.type=== 4 ? 
                                                                            
                                                                                (<CurrencyTextField
                                                                                    disabled={finished ?? "disabled"}
                                                                                    variant="outlined"
                                                                                    // value={0.00}
                                                                                    className={classes.inputMonetaryAnswer}
                                                                                    currencySymbol="R$"
                                                                                    outputFormat="number"
                                                                                    decimalCharacter=","
                                                                                    // required={true}
                                                                                    digitGroupSeparator="."
                                                                                    onChange={(e) => question2.answer =  e.target.value} defaultValue={question2.answer?? question2.answer} onBlur={() => autoSave(false,question2.id, question2.answer)}
                                                                                />) :
                                                                            question2?.type=== 5 ? 
                                                                                <TextField type='number' 
                                                                                                                        onKeyPress={(e) => { if (e.code === 'Backquote') e.preventDefault() }} 
                                                                                                                        step='0.00' 
                                                                                                                        onKeyDown={(e) => { if (e.key === 'e' || e.key === 'E') { e.preventDefault() } }} 
                                                                                                                        variant='outlined'
                                                                                                                        onBlur={() => autoSave(false,question2.id, question2.answer)}
                                                                                                                        disabled={finished ?? "disabled"}
                                                                                                                        id="answer" name="answer"
                                                                                                                        className={classes.inputPercentageAnswer}
                                                                                                                        onChange={(e) => question2.answer =  (e.target.value).replace(',','.')}
                                                                                                                        defaultValue={question2.answer?? question2.answer}
                                                                                                                    /> :
                                                                            <></>}
                                                                            </div>
                                                                    </div>
                                                                            {question2?.observation === true ? <div className="observationVBACLIENTSACTIVATION">
                                                                            <p className="observationTextVBACLIENTSACTIVATION">Observações:</p>
                                                                            <textarea disabled={finished ?? "disabled"} type="text" id="obs" name="obs" placeholder='Observações aqui' className={classes.inputTextAnswer} onChange={(e) => question2.answer_observation =  e.target.value} defaultValue={question2.answer_observation ?? question2.answer_observation} onBlur={() => autoSave(true,question2.id, question2.answer_observation)}></textarea> 
                                                                                                                                            </div>
                                                                                                                                            : <></>}
                                                                    </div>
                                                                    </>




                                                                )
                                                            })
                                                            
                                                            : <></>
                                                        }
                                                        </div>
                                                        
                                                        
                                                    </>
                                                )
                                            }) ) :
                                        question?.type=== 1 ? 
                                            <textarea disabled={finished ?? "disabled"} type="text" id="answer" name="answer" placeholder='Digite sua resposta aqui' className={classes.inputTextAnswer} onChange={(e) => question.answer =  e.target.value} defaultValue={question.answer?? question.answer} onBlur={() => autoSave(false,question.id, question.answer)}></textarea> : 
                                        question?.type=== 2 ? 
                                            (question?.options.map((options, indexO) => {
                                                return (
                                                    <div className={classes.multipleAnswerBox} key = {indexO}>
                                                    <input disabled={finished ?? "disabled"} type="radio"  defaultChecked={question.answer === options.id ?? 'checked'} name={question.id} className="radioboxVBACLIENTSACTIVATION" onClick={
                                                        () => {
                                                            question.answer = options.id
                                                            setRefresh(refresh+1)
                                                            autoSave(false,question.id, question.answer)
                                                        }}></input>
                                                    {options?.description}
                                                    {/* Se a opção estiver marcada, abre as possiveis subperguntas */}
                                                    {(options?.questions?.length > 0 && question?.answer === options?.id) ?
                                                        options?.questions?.map((question2,indexq2) => {
                                                            // NIVEL 2 //////////////////////////
                                                            return (




                                                                <>
                                                                <div key={indexq2} className={classes.boxQuestion}>
                                                                    <div className="questionDescriptionVBACLIENTSACTIVATION">
                                                                        {question2?.description}
                                                                    </div>
                                                                    <div className="gridtesteVBACLIENTSACTIVATION">
                                                                        <div className={classes.separacaoOpcoes}></div>
                                                                        <div className="opcoesDaPerguntaVBACLIENTSACTIVATION">
                                                                        {question2?.type=== 3 ? (
                                                                            question2?.options.map((options2, indexO2) => {
                                                                                return (
                                                                                    <>
                                                                                        <div className={classes.multipleAnswerBox} key={indexO2}>
                                                                                        <input disabled={finished ?? "disabled"} type="checkbox" id="check" name="check" defaultChecked={question2.answer?.indexOf(question2.options[indexO2].id)  !== -1 ?? 'checked'}className="checkboxVBACLIENTSACTIVATION" onChange={e => {
                                                                                            if (question2.answer?.indexOf(question2.options[indexO2].id) === -1)question2.answer.push(question2.options[indexO2].id)
                                                                                            else question2.answer?.splice(question2.answer?.indexOf(question2.options[indexO2].id), 1)
                                                                                            question2.options[indexO2].answer = e.target.checked
                                                                                            setRefresh(refresh+1)
                                                                                            autoSave(false,question2.id, question2.answer)
                                                                                            }}></input>
                                                                                        {options2?.description}
                                                                                        {/* Se a opção estiver marcada, abre as possiveis subperguntas */}
                                                                                        
                                                                                        {(options2?.questions?.length > 0 && question2.answer?.indexOf(question2.options[indexO2].id)  !== -1) ?
                                                                                            options2?.questions?.map((question3,indexq3) => {
                                                                                                // NIVEL 3 //////////////////////////
                                                                                                return (
                                        
                                        
                                        
                                        
                                                                                                    <>
                                                                                                    <div key={indexq3} className={classes.boxQuestion}>
                                                                                                        <div className="questionDescriptionVBACLIENTSACTIVATION">
                                                                                                            {question3?.description}
                                                                                                        </div>
                                                                                                        <div className="gridtesteVBACLIENTSACTIVATION">
                                                                                                            <div className={classes.separacaoOpcoes}></div>
                                                                                                            <div className="opcoesDaPerguntaVBACLIENTSACTIVATION">
                                                                                                            {question3?.type=== 3 ? (
                                                                                                                question3?.options.map((options3, indexO3) => {
                                                                                                                    return (
                                                                                                                        <>
                                                                                                                            <div className={classes.multipleAnswerBox} key={indexO3}>
                                                                                                                            <input disabled={finished ?? "disabled"} type="checkbox" id="check" name="check" defaultChecked={question3.answer?.indexOf(question3.options[indexO3].id)  !== -1 ?? 'checked'}className="checkboxVBACLIENTSACTIVATION" onChange={e => {
                                                                                                                                if (question3.answer?.indexOf(question3.options[indexO3].id) === -1)question3.answer.push(question3.options[indexO3].id)
                                                                                                                                else question3.answer?.splice(question3.answer?.indexOf(question3.options[indexO3].id), 1)
                                                                                                                                question3.options[indexO3].answer = e.target.checked
                                                                                                                                setRefresh(refresh+1)
                                                                                                                                autoSave(false,question3.id, question3.answer)
                                                                                                                                }}></input>
                                                                                                                            {options3?.description}
                                                                                                                            {/* Se a opção estiver marcada, abre as possiveis subperguntas */}
                                                                                                                            
                                                                                                                            {(options3?.questions?.length > 0 && question3.answer?.indexOf(question3.options[indexO3].id)  !== -1) ?
                                                                                                                                options3?.questions?.map((question4,indexq4) => {
                                                                                                                                    // NIVEL 4 //////////////////////////
                                                                                                                                    return (
                                                                            
                                                                            
                                                                            
                                                                            
                                                                                                                                        <>
                                                                                                                                        <div key={indexq4} className={classes.boxQuestion}>
                                                                                                                                            <div className="questionDescriptionVBACLIENTSACTIVATION">
                                                                                                                                                {question4?.description}
                                                                                                                                            </div>
                                                                                                                                            <div className="gridtesteVBACLIENTSACTIVATION">
                                                                                                                                                <div className={classes.separacaoOpcoes}></div>
                                                                                                                                                <div className="opcoesDaPerguntaVBACLIENTSACTIVATION">
                                                                                                                                                {question4?.type=== 3 ? (
                                                                                                                                                    question4?.options.map((options4, indexO4) => {
                                                                                                                                                        return (
                                                                                                                                                            <>
                                                                                                                                                                <div className={classes.multipleAnswerBox} key={indexO4}>
                                                                                                                                                                <input disabled={finished ?? "disabled"} type="checkbox" id="check" name="check" defaultChecked={question4.answer?.indexOf(question4.options[indexO4].id)  !== -1 ?? 'checked'} className="checkboxVBACLIENTSACTIVATION" onChange={e => {
                                                                                                                                                                    if (question4.answer?.indexOf(question4.options[indexO4].id) === -1)question4.answer.push(question4.options[indexO4].id)
                                                                                                                                                                    else question4.answer?.splice(question4.answer?.indexOf(question4.options[indexO4].id), 1)
                                                                                                                                                                    question4.options[indexO4].answer = e.target.checked
                                                                                                                                                                    setRefresh(refresh+1)
                                                                                                                                                                    autoSave(false,question4.id, question4.answer)
                                                                                                                                                                    }}></input>
                                                                                                                                                                {options4?.description}
                                                                                                                                                                </div>
                                                                                                                                                                
                                                                                                                                                                
                                                                                                                                                            </>
                                                                                                                                                        )
                                                                                                                                                    }) ) :
                                                                                                                                                question4?.type=== 1 ? 
                                                                                                                                                    <textarea type="text" id="answer" name="answer" placeholder='Digite sua resposta aqui' disabled={finished ?? "disabled"} className={classes.inputTextAnswer} onChange={(e) => question4.answer =  e.target.value} defaultValue={question4.answer?? question4.answer} onBlur={() => autoSave(false,question4.id, question4.answer)}></textarea> : 
                                                                                                                                                question4?.type=== 2 ? 
                                                                                                                                                    (question4?.options.map((options4, indexO4) => {
                                                                                                                                                        return (
                                                                                                                                                            <div className={classes.multipleAnswerBox} key={indexO4}>
                                                                                                                                                            <input disabled={finished ?? "disabled"} type="radio" id="radio4" defaultChecked={question4.answer === options4.id ?? 'checked'} name={question4.id} className="radioboxVBACLIENTSACTIVATION" onClick={
                                                                                                                                                                () => {
                                                                                                                                                                    question4.answer = options4.id
                                                                                                                                                                    setRefresh(refresh+1)
                                                                                                                                                                    autoSave(false,question4.id, question4.answer)
                                                                                                                                                                }}></input>
                                                                                                                                                            {options4?.description}
                                                                                                                                                            </div>
                                                                                                                                                        )
                                                                                                                                                    }) ) :
                                                                                                                                                question4?.type=== 4 ? 
                                                                                                                                                
                                                                                                                                                    (<CurrencyTextField
                                                                                                                                                        disabled={finished ?? "disabled"}
                                                                                                                                                        variant="outlined"
                                                                                                                                                        // value={0.00}
                                                                                                                                                        className={classes.inputMonetaryAnswer}
                                                                                                                                                        currencySymbol="R$"
                                                                                                                                                        outputFormat="number"
                                                                                                                                                        decimalCharacter=","
                                                                                                                                                        // required={true}
                                                                                                                                                        digitGroupSeparator="."
                                                                                                                                                        onChange={(e) => question4.answer =  e.target.value} defaultValue={question4.answer?? question4.answer} onBlur={() => autoSave(false,question4.id, question4.answer)}
                                                                                                                                                    />) :
                                                                                                                                                question4?.type=== 5 ? 
                                                                                                                                                    <TextField type='number' 
                                                                                                                        onKeyPress={(e) => { if (e.code === 'Backquote') e.preventDefault() }} 
                                                                                                                        step='0.00' 
                                                                                                                        onKeyDown={(e) => { if (e.key === 'e' || e.key === 'E') { e.preventDefault() } }} 
                                                                                                                        variant='outlined'
                                                                                                                        onBlur={() => autoSave(false,question4.id, question4.answer)}
                                                                                                                        disabled={finished ?? "disabled"}
                                                                                                                        id="answer" name="answer"
                                                                                                                        className={classes.inputPercentageAnswer}
                                                                                                                        onChange={(e) => question4.answer =  (e.target.value).replace(',','.')}
                                                                                                                        defaultValue={question4.answer?? question4.answer}
                                                                                                                    /> :
                                                                                                                                                <></>}
                                                                                                                                                </div>
                                                                                                                                        </div>
                                                                                                                                                {question4?.observation === true ? <div className="observationVBACLIENTSACTIVATION">
                                                                                                                                                <p className="observationTextVBACLIENTSACTIVATION">Observações:</p>
                                                                                                                                                <textarea disabled={finished ?? "disabled"} type="text" id="obs" name="obs" placeholder='Observações aqui' className={classes.inputTextAnswer} onChange={(e) => question4.answer_observation =  e.target.value} defaultValue={question4.answer_observation ?? question4.answer_observation} onBlur={() => autoSave(true,question4.id, question4.answer_observation)}></textarea> 
                                                                                                                                            </div>
                                                                                                                                            : <></>}
                                                                                                                                        </div>
                                                                                                                                        </>
                                                                            
                                                                            
                                                                            
                                                                            
                                                                                                                                    )
                                                                                                                                })
                                                                                                                                
                                                                                                                                : <></>
                                                                                                                            }
                                                                                                                            </div>
                                                                                                                            
                                                                                                                            
                                                                                                                        </>
                                                                                                                    )
                                                                                                                }) ) :
                                                                                                            question3?.type=== 1 ? 
                                                                                                                <textarea type="text" id="answer" name="answer" placeholder='Digite sua resposta aqui' disabled={finished ?? "disabled"} className={classes.inputTextAnswer} onChange={(e) => question3.answer =  e.target.value} defaultValue={question3.answer?? question3.answer} onBlur={() => autoSave(false,question3.id, question3.answer)}></textarea> : 
                                                                                                            question3?.type=== 2 ? 
                                                                                                                (question3?.options.map((options3, indexO3) => {
                                                                                                                    return (
                                                                                                                        <div className={classes.multipleAnswerBox} key={indexO3}>
                                                                                                                        <input disabled={finished ?? "disabled"} type="radio" id="radio3" defaultChecked={question3.answer === options3.id ?? 'checked'} name={question3.id} className="radioboxVBACLIENTSACTIVATION" onClick={
                                                                                                                            () => {
                                                                                                                                question3.answer = options3.id
                                                                                                                                setRefresh(refresh+1)
                                                                                                                                autoSave(false,question3.id, question3.answer)
                                                                                                                            }}></input>
                                                                                                                        {options3?.description}
                                                                                                                        {/* Se a opção estiver marcada, abre as possiveis subperguntas */}
                                                                                                                        {(options3?.questions?.length > 0 && question3?.answer === options3?.id) ?
                                                                                                                            options3?.questions?.map((question4,indexq4) => {
                                                                                                                                // NIVEL 4 //////////////////////////
                                                                                                                                return (
                                                                            
                                                                            
                                                                            
                                                                            
                                                                                                                                    <>
                                                                                                                                    <div key={indexq4} className={classes.boxQuestion}>
                                                                                                                                        <div className="questionDescriptionVBACLIENTSACTIVATION">
                                                                                                                                            {question4?.description}
                                                                                                                                        </div>
                                                                                                                                        <div className="gridtesteVBACLIENTSACTIVATION">
                                                                                                                                            <div className={classes.separacaoOpcoes}></div>
                                                                                                                                            <div className="opcoesDaPerguntaVBACLIENTSACTIVATION">
                                                                                                                                            {question4?.type=== 3 ? (
                                                                                                                                                question4?.options.map((options4, indexO4) => {
                                                                                                                                                    return (
                                                                                                                                                        <>
                                                                                                                                                            <div className={classes.multipleAnswerBox} key={indexO4}>
                                                                                                                                                            <input disabled={finished ?? "disabled"} type="checkbox" id="check" name="check" defaultChecked={question4.answer?.indexOf(question4.options[indexO4].id)  !== -1 ?? 'checked'} className="checkboxVBACLIENTSACTIVATION" onChange={e => {
                                                                                                                                                                if (question4.answer?.indexOf(question4.options[indexO4].id) === -1)question4.answer.push(question4.options[indexO4].id)
                                                                                                                                                                else question4.answer?.splice(question4.answer?.indexOf(question4.options[indexO4].id), 1)
                                                                                                                                                                question4.options[indexO4].answer = e.target.checked
                                                                                                                                                                setRefresh(refresh+1)
                                                                                                                                                                autoSave(false,question4.id, question4.answer)
                                                                                                                                                                }}></input>
                                                                                                                                                            {options4?.description}
                                                                                                                                                            </div>
                                                                                                                                                            
                                                                                                                                                            
                                                                                                                                                        </>
                                                                                                                                                    )
                                                                                                                                                }) ) :
                                                                                                                                            question4?.type=== 1 ? 
                                                                                                                                                <textarea type="text" id="answer" name="answer" placeholder='Digite sua resposta aqui' disabled={finished ?? "disabled"} className={classes.inputTextAnswer} onChange={(e) => question4.answer =  e.target.value} defaultValue={question4.answer?? question4.answer} onBlur={() => autoSave(false,question4.id, question4.answer)}></textarea> : 
                                                                                                                                            question4?.type=== 2 ? 
                                                                                                                                                (question4?.options.map((options4, indexO4) => {
                                                                                                                                                    return (
                                                                                                                                                        <div className={classes.multipleAnswerBox} key={indexO4}>
                                                                                                                                                        <input disabled={finished ?? "disabled"} type="radio" id="radio4" defaultChecked={question4.answer === options4.id ?? 'checked'} name={question4.id} className="radioboxVBACLIENTSACTIVATION" onClick={
                                                                                                                                                            () => {
                                                                                                                                                                question4.answer = options4.id
                                                                                                                                                                setRefresh(refresh+1)
                                                                                                                                                                autoSave(false,question4.id, question4.answer)
                                                                                                                                                            }}></input>
                                                                                                                                                        {options4?.description}
                                                                                                                                                        </div>
                                                                                                                                                    )
                                                                                                                                                }) ) :
                                                                                                                                            question4?.type=== 4 ? 
                                                                                                                                            
                                                                                                                                                (<CurrencyTextField
                                                                                                                                                    disabled={finished ?? "disabled"}
                                                                                                                                                    variant="outlined"
                                                                                                                                                    // value={0.00}
                                                                                                                                                    className={classes.inputMonetaryAnswer}
                                                                                                                                                    currencySymbol="R$"
                                                                                                                                                    outputFormat="number"
                                                                                                                                                    decimalCharacter=","
                                                                                                                                                    // required={true}
                                                                                                                                                    digitGroupSeparator="."
                                                                                                                                                    onChange={(e) => question4.answer =  e.target.value} defaultValue={question4.answer?? question4.answer} onBlur={() => autoSave(false,question4.id, question4.answer)}
                                                                                                                                                />) :
                                                                                                                                            question4?.type=== 5 ? 
                                                                                                                                                <TextField type='number' 
                                                                                                                        onKeyPress={(e) => { if (e.code === 'Backquote') e.preventDefault() }} 
                                                                                                                        step='0.00' 
                                                                                                                        onKeyDown={(e) => { if (e.key === 'e' || e.key === 'E') { e.preventDefault() } }} 
                                                                                                                        variant='outlined'
                                                                                                                        onBlur={() => autoSave(false,question4.id, question4.answer)}
                                                                                                                        disabled={finished ?? "disabled"}
                                                                                                                        id="answer" name="answer"
                                                                                                                        className={classes.inputPercentageAnswer}
                                                                                                                        onChange={(e) => question4.answer =  (e.target.value).replace(',','.')}
                                                                                                                        defaultValue={question4.answer?? question4.answer}
                                                                                                                    /> :
                                                                                                                                            <></>}
                                                                                                                                            </div>
                                                                                                                                    </div>
                                                                                                                                            {question4?.observation === true ? <div className="observationVBACLIENTSACTIVATION">
                                                                                                                                                <p className="observationTextVBACLIENTSACTIVATION">Observações:</p>
                                                                                                                                                <textarea disabled={finished ?? "disabled"} type="text" id="obs" name="obs" placeholder='Observações aqui' className={classes.inputTextAnswer} onChange={(e) => question4.answer_observation =  e.target.value} defaultValue={question4.answer_observation ?? question4.answer_observation} onBlur={() => autoSave(true,question4.id, question4.answer_observation)}></textarea> 
                                                                                                                                            </div>
                                                                                                                                            : <></>}
                                                                                                                                    </div>
                                                                                                                                    </>
                                                                            
                                                                            
                                                                            
                                                                            
                                                                                                                                )
                                                                                                                            })
                                                                                                                            
                                                                                                                            : <></>
                                                                                                                        }
                                                                                                                        </div>
                                                                                                                    )
                                                                                                                }) ) :
                                                                                                            question3?.type=== 4 ? 
                                                                                                            
                                                                                                                (<CurrencyTextField
                                                                                                                    disabled={finished ?? "disabled"}
                                                                                                                    variant="outlined"
                                                                                                                    // value={0.00}
                                                                                                                    className={classes.inputMonetaryAnswer}
                                                                                                                    currencySymbol="R$"
                                                                                                                    outputFormat="number"
                                                                                                                    decimalCharacter=","
                                                                                                                    // required={true}
                                                                                                                    digitGroupSeparator="."
                                                                                                                    onChange={(e) => question3.answer =  e.target.value} defaultValue={question3.answer?? question3.answer} onBlur={() => autoSave(false,question3.id, question3.answer)}
                                                                                                                />) :
                                                                                                            question3?.type=== 5 ? 
                                                                                                                <TextField type='number' 
                                                                                                                        onKeyPress={(e) => { if (e.code === 'Backquote') e.preventDefault() }} 
                                                                                                                        step='0.00' 
                                                                                                                        onKeyDown={(e) => { if (e.key === 'e' || e.key === 'E') { e.preventDefault() } }} 
                                                                                                                        variant='outlined'
                                                                                                                        onBlur={() => autoSave(false,question3.id, question3.answer)}
                                                                                                                        disabled={finished ?? "disabled"}
                                                                                                                        id="answer" name="answer"
                                                                                                                        className={classes.inputPercentageAnswer}
                                                                                                                        onChange={(e) => question3.answer =  (e.target.value).replace(',','.')}
                                                                                                                        defaultValue={question3.answer?? question3.answer}
                                                                                                                    /> :
                                                                                                            <></>}
                                                                                                            </div>
                                                                                                    </div>
                                                                                                            {question3?.observation === true ? <div className="observationVBACLIENTSACTIVATION">
                                                                                                                <p className="observationTextVBACLIENTSACTIVATION">Observações:</p>
                                                                                                                <textarea disabled={finished ?? "disabled"} type="text" id="obs" name="obs" placeholder='Observações aqui' className={classes.inputTextAnswer} onChange={(e) => question3.answer_observation =  e.target.value} defaultValue={question3.answer_observation ?? question3.answer_observation} onBlur={() => autoSave(true,question3.id, question3.answer_observation)}></textarea> 
                                                                                                            </div>
                                                                                                            : <></>}
                                                                                                    </div>
                                                                                                    </>
                                        
                                        
                                        
                                        
                                                                                                )
                                                                                            })
                                                                                            
                                                                                            : <></>
                                                                                        }
                                                                                        </div>
                                                                                        
                                                                                        
                                                                                    </>
                                                                                )
                                                                            }) ) :
                                                                        question2?.type=== 1 ? 
                                                                            <textarea type="text" id="answer" name="answer" placeholder='Digite sua resposta aqui' disabled={finished ?? "disabled"} className={classes.inputTextAnswer} onChange={(e) => question2.answer =  e.target.value} defaultValue={question2.answer?? question2.answer} onBlur={() => autoSave(false,question2.id, question2.answer)}></textarea> : 
                                                                        question2?.type=== 2 ? 
                                                                            (question2?.options.map((options2, indexO2) => {
                                                                                return (
                                                                                    <div className={classes.multipleAnswerBox} key={indexO2}>
                                                                                    <input disabled={finished ?? "disabled"} type="radio" id="radio2" defaultChecked={question2.answer === options2.id ?? 'checked'} name={question2.id} className="radioboxVBACLIENTSACTIVATION" onClick={
                                                                                        () => {
                                                                                            question2.answer = options2.id
                                                                                            setRefresh(refresh+1)
                                                                                            autoSave(false, question2.id, question2.answer)
                                                                                        }}></input>
                                                                                    {options2?.description}
                                                                                    {/* Se a opção estiver marcada, abre as possiveis subperguntas */}
                                                                                    {(options2?.questions?.length > 0 && question2?.answer === options2?.id) ?
                                                                                        options2?.questions?.map((question3,indexq3) => {
                                                                                            // NIVEL 3 //////////////////////////
                                                                                            return (
                                        
                                        
                                        
                                        
                                                                                                <>
                                                                                                <div key={indexq3} className={classes.boxQuestion}>
                                                                                                    <div className="questionDescriptionVBACLIENTSACTIVATION">
                                                                                                        {question3?.description}
                                                                                                    </div>
                                                                                                    <div className="gridtesteVBACLIENTSACTIVATION">
                                                                                                        <div className={classes.separacaoOpcoes}></div>
                                                                                                        <div className="opcoesDaPerguntaVBACLIENTSACTIVATION">
                                                                                                        {question3?.type=== 3 ? (
                                                                                                            question3?.options.map((options3, indexO3) => {
                                                                                                                return (
                                                                                                                    <>
                                                                                                                        <div className={classes.multipleAnswerBox} key={indexO3}>
                                                                                                                        <input disabled={finished ?? "disabled"} type="checkbox" id="check" name="check" defaultChecked={question3.answer?.indexOf(question3.options[indexO3].id)  !== -1 ?? 'checked'} className="checkboxVBACLIENTSACTIVATION" onChange={e => {
                                                                                                                            if (question3.answer?.indexOf(question3.options[indexO3].id) === -1)question3.answer.push(question3.options[indexO3].id)
                                                                                                                            else question3.answer?.splice(question3.answer?.indexOf(question3.options[indexO3].id), 1)
                                                                                                                            question3.options[indexO3].answer = e.target.checked
                                                                                                                            setRefresh(refresh+1)
                                                                                                                            autoSave(false, question3.id, question3.answer)
                                                                                                                            }}></input>
                                                                                                                        {options3?.description}
                                                                                                                        {/* Se a opção estiver marcada, abre as possiveis subperguntas */}
                                                                                                                        
                                                                                                                        {(options3?.questions?.length > 0 && question3.answer?.indexOf(question3.options[indexO3].id)  !== -1) ?
                                                                                                                            options3?.questions?.map((question4,indexq4) => {
                                                                                                                                // NIVEL 4 //////////////////////////
                                                                                                                                return (
                                                                        
                                                                        
                                                                        
                                                                        
                                                                                                                                    <>
                                                                                                                                    <div key={indexq4} className={classes.boxQuestion}>
                                                                                                                                        <div className="questionDescriptionVBACLIENTSACTIVATION">
                                                                                                                                            {question4?.description}
                                                                                                                                        </div>
                                                                                                                                        <div className="gridtesteVBACLIENTSACTIVATION">
                                                                                                                                            <div className={classes.separacaoOpcoes}></div>
                                                                                                                                            <div className="opcoesDaPerguntaVBACLIENTSACTIVATION">
                                                                                                                                            {question4?.type=== 3 ? (
                                                                                                                                                question4?.options.map((options4, indexO4) => {
                                                                                                                                                    return (
                                                                                                                                                        <>
                                                                                                                                                            <div className={classes.multipleAnswerBox} key={indexO4}>
                                                                                                                                                            <input disabled={finished ?? "disabled"} type="checkbox" id="check" name="check" defaultChecked={question4.answer?.indexOf(question4.options[indexO4].id)  !== -1 ?? 'checked'} className="checkboxVBACLIENTSACTIVATION" onChange={e => {
                                                                                                                                                                if (question4.answer?.indexOf(question4.options[indexO4].id) === -1)question4.answer.push(question4.options[indexO4].id)
                                                                                                                                                                else question4.answer?.splice(question4.answer?.indexOf(question4.options[indexO4].id), 1)
                                                                                                                                                                question4.options[indexO4].answer = e.target.checked
                                                                                                                                                                setRefresh(refresh+1)
                                                                                                                                                                autoSave(false, question4.id, question4.answer)
                                                                                                                                                                }}></input>
                                                                                                                                                            {options4?.description}
                                                                                                                                                            </div>
                                                                                                                                                            
                                                                                                                                                            
                                                                                                                                                        </>
                                                                                                                                                    )
                                                                                                                                                }) ) :
                                                                                                                                            question4?.type=== 1 ? 
                                                                                                                                                <textarea type="text" id="answer" name="answer" placeholder='Digite sua resposta aqui' disabled={finished ?? "disabled"} className={classes.inputTextAnswer} onChange={(e) => question4.answer =  e.target.value} defaultValue={question4.answer?? question4.answer} onBlur={() => autoSave(false,question4.id, question4.answer)}></textarea> : 
                                                                                                                                            question4?.type=== 2 ? 
                                                                                                                                                (question4?.options.map((options4, indexO4) => {
                                                                                                                                                    return (
                                                                                                                                                        <div className={classes.multipleAnswerBox} key={indexO4}>
                                                                                                                                                        <input disabled={finished ?? "disabled"} type="radio" id="radio4" defaultChecked={question4.answer === options4.id ?? 'checked'} name={question4.id} className="radioboxVBACLIENTSACTIVATION" onClick={
                                                                                                                                                            () => {
                                                                                                                                                                question4.answer = options4.id
                                                                                                                                                                setRefresh(refresh+1)
                                                                                                                                                                autoSave(false, question4.id, question4.answer)
                                                                                                                                                            }}></input>
                                                                                                                                                        {options4?.description}
                                                                                                                                                        </div>
                                                                                                                                                    )
                                                                                                                                                }) ) :
                                                                                                                                            question4?.type=== 4 ? 
                                                                                                                                            
                                                                                                                                                (<CurrencyTextField
                                                                                                                                                    disabled={finished ?? "disabled"}
                                                                                                                                                    variant="outlined"
                                                                                                                                                    // value={0.00}
                                                                                                                                                    className={classes.inputMonetaryAnswer}
                                                                                                                                                    currencySymbol="R$"
                                                                                                                                                    outputFormat="number"
                                                                                                                                                    decimalCharacter=","
                                                                                                                                                    // required={true}
                                                                                                                                                    digitGroupSeparator="."
                                                                                                                                                    onChange={(e) => question4.answer =  e.target.value} defaultValue={question4.answer?? question4.answer} onBlur={() => autoSave(false,question4.id, question4.answer)}
                                                                                                                                                />) :
                                                                                                                                            question4?.type=== 5 ? 
                                                                                                                                                <TextField type='number' 
                                                                                                                        onKeyPress={(e) => { if (e.code === 'Backquote') e.preventDefault() }} 
                                                                                                                        step='0.00' 
                                                                                                                        onKeyDown={(e) => { if (e.key === 'e' || e.key === 'E') { e.preventDefault() } }} 
                                                                                                                        variant='outlined'
                                                                                                                        onBlur={() => autoSave(false,question4.id, question4.answer)}
                                                                                                                        disabled={finished ?? "disabled"}
                                                                                                                        id="answer" name="answer"
                                                                                                                        className={classes.inputPercentageAnswer}
                                                                                                                        onChange={(e) => question4.answer =  (e.target.value).replace(',','.')}
                                                                                                                        defaultValue={question4.answer?? question4.answer}
                                                                                                                    /> :
                                                                                                                                            <></>}
                                                                                                                                            </div>
                                                                                                                                    </div>
                                                                                                                                            {question4?.observation === true ? <div className="observationVBACLIENTSACTIVATION">
                                                                                                                                                <p className="observationTextVBACLIENTSACTIVATION">Observações:</p>
                                                                                                                                                <textarea disabled={finished ?? "disabled"} type="text" id="obs" name="obs" placeholder='Observações aqui' className={classes.inputTextAnswer} onChange={(e) => question4.answer_observation =  e.target.value} defaultValue={question4.answer_observation ?? question4.answer_observation} onBlur={() => autoSave(true,question4.id, question4.answer_observation)}></textarea> 
                                                                                                                                            </div>
                                                                                                                                            : <></>}
                                                                                                                                    </div>
                                                                                                                                    </>
                                                                        
                                                                        
                                                                        
                                                                        
                                                                                                                                )
                                                                                                                            })
                                                                                                                            
                                                                                                                            : <></>
                                                                                                                        }
                                                                                                                        </div>
                                                                                                                        
                                                                                                                        
                                                                                                                    </>
                                                                                                                )
                                                                                                            }) ) :
                                                                                                        question3?.type=== 1 ? 
                                                                                                            <textarea type="text" id="answer" name="answer" placeholder='Digite sua resposta aqui' disabled={finished ?? "disabled"} className={classes.inputTextAnswer} onChange={(e) => question3.answer =  e.target.value} defaultValue={question3.answer?? question3.answer} onBlur={() => autoSave(false,question3.id, question3.answer)}></textarea> : 
                                                                                                        question3?.type=== 2 ? 
                                                                                                            (question3?.options.map((options3, indexO3) => {
                                                                                                                return (
                                                                                                                    <div className={classes.multipleAnswerBox} key={indexO3}>
                                                                                                                    <input disabled={finished ?? "disabled"} type="radio" id="radio3" defaultChecked={question3.answer === options3.id ?? 'checked'} name={question3.id} className="radioboxVBACLIENTSACTIVATION" onClick={
                                                                                                                        () => {
                                                                                                                            question3.answer = options3.id
                                                                                                                            setRefresh(refresh+1)
                                                                                                                            autoSave(false, question3.id, question3.answer)
                                                                                                                        }}></input>
                                                                                                                    {options3?.description}
                                                                                                                    {/* Se a opção estiver marcada, abre as possiveis subperguntas */}
                                                                                                                    {(options3?.questions?.length > 0 && question3?.answer === options3?.id) ?
                                                                                                                        options3?.questions?.map((question4,indexq4) => {
                                                                                                                            // NIVEL 4 //////////////////////////
                                                                                                                            return (
                                                                        
                                                                        
                                                                        
                                                                        
                                                                                                                                <>
                                                                                                                                <div key={indexq4} className={classes.boxQuestion}>
                                                                                                                                    <div className="questionDescriptionVBACLIENTSACTIVATION">
                                                                                                                                        {question4?.description}
                                                                                                                                    </div>
                                                                                                                                    <div className="gridtesteVBACLIENTSACTIVATION">
                                                                                                                                        <div className={classes.separacaoOpcoes}></div>
                                                                                                                                        <div className="opcoesDaPerguntaVBACLIENTSACTIVATION">
                                                                                                                                        {question4?.type=== 3 ? (
                                                                                                                                            question4?.options.map((options4, indexO4) => {
                                                                                                                                                return (
                                                                                                                                                    <>
                                                                                                                                                        <div className={classes.multipleAnswerBox} key={indexO4}>
                                                                                                                                                        <input disabled={finished ?? "disabled"} type="checkbox" id="check" name="check" defaultChecked={question4.answer?.indexOf(question4.options[indexO4].id)  !== -1 ?? 'checked'} className="checkboxVBACLIENTSACTIVATION" onChange={e => {
                                                                                                                                                            if (question4.answer?.indexOf(question4.options[indexO4].id) === -1)question4.answer.push(question4.options[indexO4].id)
                                                                                                                                                            else question4.answer?.splice(question4.answer?.indexOf(question4.options[indexO4].id), 1)
                                                                                                                                                            question4.options[indexO4].answer = e.target.checked
                                                                                                                                                            setRefresh(refresh+1)
                                                                                                                                                            autoSave(false,question4.id, question4.answer)
                                                                                                                                                            }}></input>
                                                                                                                                                        {options4?.description}
                                                                                                                                                        </div>
                                                                                                                                                        
                                                                                                                                                        
                                                                                                                                                    </>
                                                                                                                                                )
                                                                                                                                            }) ) :
                                                                                                                                        question4?.type=== 1 ? 
                                                                                                                                            <textarea type="text" id="answer" name="answer" placeholder='Digite sua resposta aqui' disabled={finished ?? "disabled"} className={classes.inputTextAnswer} onChange={(e) => question4.answer =  e.target.value} defaultValue={question4.answer?? question4.answer} onBlur={() => autoSave(false,question4.id, question4.answer)}></textarea> : 
                                                                                                                                        question4?.type=== 2 ? 
                                                                                                                                            (question4?.options.map((options4, indexO4) => {
                                                                                                                                                return (
                                                                                                                                                    <div className={classes.multipleAnswerBox} key={indexO4}>
                                                                                                                                                    <input disabled={finished ?? "disabled"} type="radio" id="radio4" defaultChecked={question4.answer === options4.id ?? 'checked'} name={question4.id} className="radioboxVBACLIENTSACTIVATION" onClick={
                                                                                                                                                        () => {
                                                                                                                                                            question4.answer = options4.id
                                                                                                                                                            setRefresh(refresh+1)
                                                                                                                                                            autoSave(false,question4.id, question4.answer)
                                                                                                                                                        }}></input>
                                                                                                                                                    {options4?.description}
                                                                                                                                                    </div>
                                                                                                                                                )
                                                                                                                                            }) ) :
                                                                                                                                        question4?.type=== 4 ? 
                                                                                                                                        
                                                                                                                                            (<CurrencyTextField
                                                                                                                                                disabled={finished ?? "disabled"}
                                                                                                                                                variant="outlined"
                                                                                                                                                // value={0.00}
                                                                                                                                                className={classes.inputMonetaryAnswer}
                                                                                                                                                currencySymbol="R$"
                                                                                                                                                outputFormat="number"
                                                                                                                                                decimalCharacter=","
                                                                                                                                                // required={true}
                                                                                                                                                digitGroupSeparator="."
                                                                                                                                                onChange={(e) => question4.answer =  e.target.value} defaultValue={question4.answer?? question4.answer} onBlur={() => autoSave(false,question4.id, question4.answer)}
                                                                                                                                            />) :
                                                                                                                                        question4?.type=== 5 ? 
                                                                                                                                            <TextField type='number' 
                                                                                                                        onKeyPress={(e) => { if (e.code === 'Backquote') e.preventDefault() }} 
                                                                                                                        step='0.00' 
                                                                                                                        onKeyDown={(e) => { if (e.key === 'e' || e.key === 'E') { e.preventDefault() } }} 
                                                                                                                        variant='outlined'
                                                                                                                        onBlur={() => autoSave(false,question4.id, question4.answer)}
                                                                                                                        disabled={finished ?? "disabled"}
                                                                                                                        id="answer" name="answer"
                                                                                                                        className={classes.inputPercentageAnswer}
                                                                                                                        onChange={(e) => question4.answer =  (e.target.value).replace(',','.')}
                                                                                                                        defaultValue={question4.answer?? question4.answer}
                                                                                                                    /> :
                                                                                                                                        <></>}
                                                                                                                                        </div>
                                                                                                                                </div>
                                                                                                                                        {question4?.observation === true ? <div className="observationVBACLIENTSACTIVATION">
                                                                                                                                            <p className="observationTextVBACLIENTSACTIVATION">Observações:</p>
                                                                                                                                            <textarea disabled={finished ?? "disabled"} type="text" id="obs" name="obs" placeholder='Observações aqui' className={classes.inputTextAnswer} onChange={(e) => question4.answer_observation =  e.target.value} defaultValue={question4.answer_observation ?? question4.answer_observation} onBlur={() => autoSave(true,question4.id, question4.answer_observation)}></textarea> 
                                                                                                                                        </div>
                                                                                                                                        : <></>}
                                                                                                                                </div>
                                                                                                                                </>
                                                                        
                                                                        
                                                                        
                                                                        
                                                                                                                            )
                                                                                                                        })
                                                                                                                        
                                                                                                                        : <></>
                                                                                                                    }
                                                                                                                    </div>
                                                                                                                )
                                                                                                            }) ) :
                                                                                                        question3?.type=== 4 ? 
                                                                                                        
                                                                                                            (<CurrencyTextField
                                                                                                                disabled={finished ?? "disabled"}
                                                                                                                variant="outlined"
                                                                                                                // value={0.00}
                                                                                                                className={classes.inputMonetaryAnswer}
                                                                                                                currencySymbol="R$"
                                                                                                                outputFormat="number"
                                                                                                                decimalCharacter=","
                                                                                                                // required={true}
                                                                                                                digitGroupSeparator="."
                                                                                                                onChange={(e) => question3.answer =  e.target.value} defaultValue={question3.answer?? question3.answer} onBlur={() => autoSave(false,question3.id, question3.answer)}
                                                                                                            />) :
                                                                                                        question3?.type=== 5 ? 
                                                                                                            <TextField type='number' 
                                                                                                                        onKeyPress={(e) => { if (e.code === 'Backquote') e.preventDefault() }} 
                                                                                                                        step='0.00' 
                                                                                                                        onKeyDown={(e) => { if (e.key === 'e' || e.key === 'E') { e.preventDefault() } }} 
                                                                                                                        variant='outlined'
                                                                                                                        onBlur={() => autoSave(false,question3.id, question3.answer)}
                                                                                                                        disabled={finished ?? "disabled"}
                                                                                                                        id="answer" name="answer"
                                                                                                                        className={classes.inputPercentageAnswer}
                                                                                                                        onChange={(e) => question3.answer =  (e.target.value).replace(',','.')}
                                                                                                                        defaultValue={question3.answer?? question3.answer}
                                                                                                                    /> :
                                                                                                        <></>}
                                                                                                        </div>
                                                                                                </div>
                                                                                                        {question3?.observation === true ? <div className="observationVBACLIENTSACTIVATION">
                                                                                                            <p className="observationTextVBACLIENTSACTIVATION">Observações:</p>
                                                                                                            <textarea disabled={finished ?? "disabled"} type="text" id="obs" name="obs" placeholder='Observações aqui' className={classes.inputTextAnswer} onChange={(e) => question3.answer_observation =  e.target.value} defaultValue={question3.answer_observation ?? question3.answer_observation} onBlur={() => autoSave(true,question3.id, question3.answer_observation)}></textarea> 
                                                                                                        </div>
                                                                                                        : <></>}
                                                                                                </div>
                                                                                                </>
                                        
                                        
                                        
                                        
                                                                                            )
                                                                                        })
                                                                                        
                                                                                        : <></>
                                                                                    }
                                                                                    </div>
                                                                                )
                                                                            }) ) :
                                                                        question2?.type=== 4 ? 
                                                                        
                                                                            (<CurrencyTextField
                                                                                disabled={finished ?? "disabled"}
                                                                                variant="outlined"
                                                                                // value={0.00}
                                                                                className={classes.inputMonetaryAnswer}
                                                                                currencySymbol="R$"
                                                                                outputFormat="number"
                                                                                decimalCharacter=","
                                                                                // required={true}
                                                                                digitGroupSeparator="."
                                                                                onChange={(e) => question2.answer =  e.target.value} defaultValue={question2.answer?? question2.answer} onBlur={() => autoSave(false,question2.id, question2.answer)}
                                                                            />) :
                                                                        question2?.type=== 5 ? 
                                                                            <TextField type='number' 
                                                                                                                        onKeyPress={(e) => { if (e.code === 'Backquote') e.preventDefault() }} 
                                                                                                                        step='0.00' 
                                                                                                                        onKeyDown={(e) => { if (e.key === 'e' || e.key === 'E') { e.preventDefault() } }} 
                                                                                                                        variant='outlined'
                                                                                                                        onBlur={() => autoSave(false,question2.id, question2.answer)}
                                                                                                                        disabled={finished ?? "disabled"}
                                                                                                                        id="answer" name="answer"
                                                                                                                        className={classes.inputPercentageAnswer}
                                                                                                                        onChange={(e) => question2.answer =  (e.target.value).replace(',','.')}
                                                                                                                        defaultValue={question2.answer?? question2.answer}
                                                                                                                    /> :
                                                                        <></>}
                                                                        </div>
                                                                </div>
                                                                        {question2?.observation === true ? <div className="observationVBACLIENTSACTIVATION">
                                                                        <p className="observationTextVBACLIENTSACTIVATION">Observações:</p>
                                                                        <textarea disabled={finished ?? "disabled"} type="text" id="obs" name="obs" placeholder='Observações aqui' className={classes.inputTextAnswer} onChange={(e) => question2.answer_observation =  e.target.value} defaultValue={question2.answer_observation ?? question2.answer_observation} onBlur={() => autoSave(true,question2.id, question2.answer_observation)}></textarea> 
                                                                    </div>
                                                                    : <></>}
                                                                </div>
                                                                </>




                                                            )
                                                        })
                                                        
                                                        : <></>
                                                    }
                                                    </div>
                                                )
                                            }) ) :
                                        question?.type=== 4 ? 
                                            (<CurrencyTextField
                                                disabled={finished ?? "disabled"}
                                                variant="outlined"
                                                // value={0.00}
                                                className={classes.inputMonetaryAnswer}
                                                currencySymbol="R$"
                                                outputFormat="number"
                                                decimalCharacter=","
                                                // required={true}
                                                digitGroupSeparator="."
                                                onChange={(e) => question.answer =  e.target.value}defaultValue={question.answer?? question.answer} onBlur={() => autoSave(false,question.id, question.answer)}
                                            />) :
                                        question?.type=== 5 ? 
                                            <div className="containerTipoPerguntaPorcentagem">
                                            <TextField type='number' 
                                                onKeyPress={(e) => { if (e.code === 'Backquote') e.preventDefault() }} 
                                                step='0.00' 
                                                onKeyDown={(e) => { if (e.key === 'e' || e.key === 'E') { e.preventDefault() } }} 
                                                variant='outlined'
                                                onBlur={() => autoSave(false,question.id, question.answer)}
                                                disabled={finished ?? "disabled"}
                                                id="answer" name="answer"
                                                className={classes.inputPercentageAnswer}
                                                onChange={(e) => question.answer =  (e.target.value).replace(',','.')}
                                                defaultValue={question.answer?? question.answer}
                                            />
                                            <div className="porcentagem">%</div>
                                            
                                        </div>
                                        :
                                        <></>
                                        }
                                    
                                    </div>
                                    
                                </div>
                                    {question?.observation === true ? <div className="observationVBACLIENTSACTIVATION">
                                        <p className="observationTextVBACLIENTSACTIVATION">Observações:</p>
                                        <textarea disabled={finished ?? "disabled"} type="text" id="obs" name="obs" placeholder='Observações aqui' className={classes.inputTextAnswer} onChange={(e) => question.answer_observation =  e.target.value} defaultValue={question.answer_observation ?? question.answer_observation} onBlur={() => autoSave(true,question.id, question.answer_observation)}></textarea> 
                                    </div>
                                    : <></>}
                                
                                </div>
                                
                                </>
                            )
                        }
                    })}
                        </>
                    )
                        
                    })}
                </div>)
                }
                </>
            </div> 
            {props?.clientData?.type == 2 ? 
                <div className='containerShowFinishedFormVBACLIENTSACTIVATION'>
                    <div className='dateFinishedFormVBACLIENTSACTIVATION'>Formulário respondido em: {formResponseDate}</div>
                </div>
                : 
                <div className='containerSendFormClientActivationVBACLIENTSACTIVATION'>
                    <div className='obsVBACLIENTSACTIVATION'>{props?.clientData?.type == 1 ? "Lembre-se de preencher todas as informações antes de realizar a ativação!" : props?.clientData?.type == 3 ? "Lembre-se de conferir todas as informações (vá até o final da página) antes de realizar a transferência!" : `Percorra todo o formulário antes de ativar o cliente.
                    Formulário respondido em: ${formResponseDate}` }</div>
                    {props?.clientData?.type == 1 ?
                        <button className='activateClientButtonVBACLIENTSACTIVATION' onClick={() => {sendForm()}}>Ativar cliente</button>
                        :
                        props?.clientData?.type == 5 ? 
                        <button className={'activateClientButtonVBACLIENTSACTIVATION' }  onClick={() => {
                            updateForm();
                        }}>Salvar alterações</button>
                        :
                        <button className={unlockButton ? 'activateClientButtonVBACLIENTSACTIVATION' : 'activateClientButtonVBACLIENTSACTIVATIONINACTIVE'} disabled={!unlockButton} title={unlockButton ? '' : 'Vá até o final da página para liberar a transferência'} onClick={() => {
                            if(props?.clientData?.type == 3){
                                sendForm();
                            }
                            else if(props?.clientData?.type == 4){
                                activeClient();
                            }
                        }}>{props?.clientData?.type == 3 ? 'Transferir cliente' : 'Ativar Cliente'}</button>
                    }
                </div>  
            }
              </>  
              )
            }
        </div>
    )
}