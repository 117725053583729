import React, { useEffect, useState,useRef } from "react";
/*Contexts*/
import { useAuth } from "../../../../contexts/useAuth";
import { useModal } from "../../../../contexts/useModal";
/*Services*/
import { FiArrowRight } from "react-icons/fi";
 
import { api } from "../../../../services/api";
/*Components*/
// import { ArrowLoader } from "../../../../components/loaders/arrowLoader";
/*Utils*/
import { toBRL } from "../../../../utils/formatNumberAndText/toBRLCurrency";
import { titleize } from "../../../../utils/formatNumberAndText/formatTextUpperFirstLetter";
import { MdOutlineMonetizationOn, MdOutlineCheckCircleOutline, MdOutbox, MdContentCopy,MdSearch,MdOutlineArrowBackIosNew, MdOutlineArrowForwardIos} from "react-icons/md";
import { BsWhatsapp, BsTelephone, BsPeople, BsChatSquareText, BsCpu, BsChatDots, BsCardList, BsBoxArrowUpLeft, BsPersonPlus } from "react-icons/bs";
import { AiOutlineMail, AiOutlineSend } from "react-icons/ai";
import { FaCheck } from "react-icons/fa";
import { updateABCStatus } from "../../../../services/asyncFunctions";
import { makeStyles } from "@material-ui/core/styles";
import { ArrowLoader } from "../../../../components/loaders/arrowLoader";
import { BsFillSquareFill } from "react-icons/bs";


import "./abcModal.css";
import { FormControlLabel, FormGroup, Radio, RadioGroup } from "@mui/material";

export function AbcDetailsModal(props) {
  console.log('propsDetails', props)
    const { tokenKey, user } = useAuth();
    const { show, setShow, type, setType, edited, setEdited } = useModal();
    const [operationId, setOperationId] = useState(null);
    const [categoryInfos, setCategoryInfos] = useState(null);
    const [filters, setFilters] = useState([]);
    const [items, setItems] = useState([]);
    const [allItems, setAllItems] = useState([]);
    const [filteredItems, setFilteredItems] = useState([]);
    const [itemQty, setItemQty] = useState(30);
    const [loading, setLoading] = useState(true);
    const [loadingTimeline, setLoadingTimeline] = useState(false);
    const [copyingDescription, setCopyingDescription] = useState(false);
    const [copyingLink, setCopyingLink] = useState([]);
    const [client_speech, setClientSpeech] = useState("");
    const [newStatus, setNewStatus] = useState(-1);
    const [refuseReason, setRefusalReason] = useState('')
    const [link, setLink] = useState({
        boletar: "#/",
        acompanhamento: "#/",
    });
    const [contactType, setContactType] = useState(5);
    const [comentTimeline, setComentTimeline] = useState("");
    const [defaultComments, setDefaultComments] = useState({
        2:{comment: `Feito contato com o cliente sobre o produto ` + props?.prop?.product?.description + `. Cliente ficou de avaliar a sugestão.`},
        6:{comment: `Feito contato com o cliente sobre o produto ` + props?.prop?.product?.description + `. Cliente não teve interesse na oferta e recusou o produto.`},
        7:{comment: `Cliente não possui perfil para o produto `+ props?.prop?.product?.description + `.`}
    });
    const [activeRefusalReasonBox, setActiveRefusalReasonBox] = useState(false);
    const [selectedReason, setSelectedReason] = useState("");
    const [refusalReasons, setRefusalResasons] = useState([
    {id:'recurso' , label: "Falta de recurso p/ alocar", comment : `Abordado o investidor sobre a oportunidade ${props?.prop?.product?.description}, porém o cliente está sem recurso disponível.`},
    {id:'prazo' , label: "Prazo do produto", comment : `Abordado o investidor sobre a oportunidade ${props?.prop?.product?.description}, porém o cliente não aceitou o prazo do produto.`},
    {id:'cenario' , label: "Cenário Adverso", comment : `Abordado o investidor sobre a oportunidade ${props?.prop?.product?.description}, porém o cliente não aceitou o produto por receio com o cenário macro atual.`},
    {id:'preferencia' , label: "Prefere outro produto", comment : `Abordado o investidor sobre a oportunidade ${props?.prop?.product?.description}, porém o cliente não aceitou o produto por preferir outras classes de ativos no momento.`},
    {id:'taxa' , label: "Taxa não atrativa", comment : `Abordado o investidor sobre a oportunidade ${props?.prop?.product?.description}, porém o cliente não aceitou o produto por não achar atrativa a taxa do ativo.` },
    {id:'outros' , label: "Outros", comment : `Escreva o motivo!` },
    ]);

    const [searchActive, setSearchActive] = useState(false)
    const [searchText, setSearchText] = useState('')
    const [clients, setClients] = useState([]);
    const selectedClient = useRef()
    const [filterCategory, setFilterCategory] = useState(0)
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [geralCategory, setGeralCategory] = useState(null);
    const [categories, setCategories] = useState({});

    const [currentPosition, setCurrentPosition] = useState(0);
    const menuItemsRef = React.createRef();

    const useStyles = makeStyles((theme) => ({
      timelineNewCommentContactTypeActive: {
          width: "2rem",
          height: "2rem",
          backgroundColor: contactType == 5 ? "#EADDCA" : contactType == 1 ? "#A4F097" : contactType == 2 ? "#CBE6FF" : contactType == 3 ? "#FFC8C8" : "#FEFFBC",
          color: contactType == 5 ? "#412500" : contactType == 1 ? "#165102" : contactType == 2 ? "#001D82" : contactType == 3 ? "#A90000" : "#BA9C00",
          display: "grid",
          padding: "0.2rem",
          paddingTop: "0.15rem",
          borderRadius: "8px",
          transition: "all ease-in-out 0.2s",
          marginLeft: "0.4rem",
          marginRight: "0.4rem",
          cursor: "pointer",
      },
      timelineNewCommentContactTypeInactive: {
          width: "2rem",
          height: "2rem",
          backgroundColor: "#d3d3d3",
          display: "grid",
          padding: "0.2rem",
          paddingTop: "0.15rem",
          borderRadius: "8px",
          transition: "all ease-in-out 0.2s",
          cursor: "pointer",
          marginLeft: "0.4rem",
          marginRight: "0.4rem",
          "&:hover": {
              backgroundColor: "#b8b8b8",
          },
      },
      timelineFilterType1Active: {
          width: "1.5rem",
          height: "1.5rem",
          backgroundColor: "#A4F097",
          color: "#165102",
          display: "grid",
          padding: "0.2rem",
          paddingTop: "0.15rem",
          borderRadius: "8px",
          transition: "all ease-in-out 0.2s",
          marginLeft: "0.2rem",
          marginRight: "0.2rem",
          cursor: "pointer",
      },
      timelineFilterType2Active: {
          width: "1.5rem",
          height: "1.5rem",
          backgroundColor: "#CBE6FF",
          color: "#001D82",
          display: "grid",
          padding: "0.2rem",
          paddingTop: "0.15rem",
          borderRadius: "8px",
          transition: "all ease-in-out 0.2s",
          marginLeft: "0.2rem",
          marginRight: "0.2rem",
          cursor: "pointer",
      },
      timelineFilterType3Active: {
          width: "1.5rem",
          height: "1.5rem",
          backgroundColor: "#FFC8C8",
          color: "#A90000",
          display: "grid",
          padding: "0.2rem",
          paddingTop: "0.15rem",
          borderRadius: "8px",
          transition: "all ease-in-out 0.2s",
          marginLeft: "0.2rem",
          marginRight: "0.2rem",
          cursor: "pointer",
      },
      timelineFilterType4Active: {
          width: "1.5rem",
          height: "1.5rem",
          backgroundColor: "#FEFFBC",
          color: "#BA9C00",
          display: "grid",
          padding: "0.2rem",
          paddingTop: "0.15rem",
          borderRadius: "8px",
          transition: "all ease-in-out 0.2s",
          marginLeft: "0.2rem",
          marginRight: "0.2rem",
          cursor: "pointer",
      },
      timelineFilterType5Active: {
          width: "1.5rem",
          height: "1.5rem",
          backgroundColor: "#f3ece1",
          color: "#2b1800",
          display: "grid",
          padding: "0.2rem",
          paddingTop: "0.15rem",
          borderRadius: "8px",
          transition: "all ease-in-out 0.2s",
          marginLeft: "0.2rem",
          marginRight: "0.2rem",
          cursor: "pointer",
      },
      timelineFilterType0Active: {
          width: "1.5rem",
          height: "1.5rem",
          backgroundColor: "#a8a8a8",
          color: "#0e0e0e",
          display: "grid",
          padding: "0.2rem",
          paddingTop: "0.15rem",
          borderRadius: "8px",
          transition: "all ease-in-out 0.2s",
          marginLeft: "0.2rem",
          marginRight: "0.2rem",
          cursor: "pointer",
      },
      timelineFilterType6Active: {
          width: "1.5rem",
          height: "1.5rem",
          backgroundColor: "#cee4e0",
          color: "#003a2f",
          display: "grid",
          padding: "0.2rem",
          paddingTop: "0.15rem",
          borderRadius: "8px",
          transition: "all ease-in-out 0.2s",
          marginLeft: "0.2rem",
          marginRight: "0.2rem",
          cursor: "pointer",
      },
      timelineFilterTypeInactive: {
          width: "1.5rem",
          height: "1.5rem",
          backgroundColor: "#d3d3d3",
          display: "grid",
          padding: "0.2rem",
          paddingTop: "0.15rem",
          borderRadius: "8px",
          transition: "all ease-in-out 0.2s",
          cursor: "pointer",
          marginLeft: "0.2rem",
          marginRight: "0.2rem",
          "&:hover": {
              backgroundColor: "#b8b8b8",
          },
      },
      timelineNewComentWriteArea: {
          resize: "none",
          borderRadius: "10px",
          border: "1px solid #dadada",
          backgroundColor: "#f8f8f8",
          padding: "0.5rem",
          fontSize: "1rem",
          width: "calc(100% - 2rem)",
          "&:focus": {
              outline: "none",
              border: contactType == 5 ? "2px solid #412500" : contactType == 1 ? "2px solid #165102" : contactType == 2 ? "2px solid #001D82" : contactType == 3 ? "2px solid #A90000" : "2px solid #BA9C00",
          },
      },
      timelineNewCommentSendActive: {
          width: "3rem",
          height: "3rem",
          backgroundColor: contactType == 5 ? "#d3d3d3" : contactType == 1 ? "#A4F097" : contactType == 2 ? "#CBE6FF" : contactType == 3 ? "#FFC8C8" : "#FEFFBC",
          color: contactType == 5 ? "#412500" : contactType == 1 ? "#165102" : contactType == 2 ? "#001D82" : contactType == 3 ? "#A90000" : "#BA9C00",
          display: "grid",
          padding: "0.55rem",
          paddingLeft: "0.65rem",
          borderRadius: "8px",
          transition: "all ease-in-out 0.2s",
          marginTop: "auto",
          marginRight: "0.4rem",
          marginBottom: "0.15rem",
          border: "none",
          "&:hover": {
              backgroundColor: contactType == 5 ? "#b8b8b8" : contactType == 1 ? "#c5ffbb" : contactType == 2 ? "#e9f4ff" : contactType == 3 ? "#ffdbdb" : "#feffd3",
              boxShadow: contactType == 5 ? "0px 0px 4px #b8b8b8" : contactType == 1 ? "0px 0px 4px #165102" : contactType == 2 ? "0px 0px 4px #001D82" : contactType == 3 ? "0px 0px 4px #A90000" : "0px 0px 4px #BA9C00",
          },
      },
      timelineNewCommentSendInative: {
          width: "3rem",
          height: "3rem",
          backgroundColor: "#d3d3d3",
          color: "#b8b8b8",
          display: "grid",
          padding: "0.55rem",
          paddingLeft: "0.65rem",
          borderRadius: "8px",
          transition: "all ease-in-out 0.2s",
          marginTop: "auto",
          marginRight: "0.4rem",
          marginBottom: "0.15rem",
          border: "none",
          cursor: "default",
      },
      timelineComentBoxUserComentName: {
          width: "3rem",
          height: "3rem",
          backgroundColor: "#d3d3d3",
          color: "#b8b8b8",
          display: "grid",
          padding: "0.55rem",
          paddingLeft: "0.65rem",
          borderRadius: "8px",
          transition: "all ease-in-out 0.2s",
          marginTop: "auto",
          marginRight: "0.4rem",
          marginBottom: "0.15rem",
          border: "none",
          cursor: "default",
      },
      inputTextSearch: {
        overflowY: "auto",
        height: "1.6rem",
        width: "90%",
        marginLeft: "0",
        marginRight: "0",
        fontSize: "1.2rem",
        fontWeight: "400",
        paddingLeft: "0.5rem",
        paddingRight: "0.5rem",
        backgroundColor: "#D9D9D9",
        border: "none",
        borderRadius: "2px",
        borderBottom: "2px solid",
        borderColor: "#c49e17",
        "&::placeholder": {
            color: "#5F5F5F",
            textAlign: "left",
        },
        "&:focus": {
            outline: "none",
            borderBottom: "4px solid",
            borderColor: "#c49e17",
        },
      },
  }));

    const classesContactType = useStyles();

    function getOperationId() {
        // const prod_name = props?.prop?.product?.name;
        // console.log(props?.prop)
        const operation_id = selectedClient.current[`${props?.prop?.product?.name}_operation_id`];
        setOperationId(operation_id);
    }

    function getCategory() {
        // const prod_name = props.prop.product.name;
        const cat_id = selectedClient.current[`${props.prop.product.name}_category_id`];
        const cat = props.prop.product.categories.filter((x) => x.id == cat_id);
        setCategoryInfos(cat[0]);
        var aux = []
        let linkSplited = cat[0]?.link?.split(' ')
        linkSplited?.forEach(() => {
            aux.push(false)
        })
        setCopyingLink(aux)
}

    function getSpeech() {
        const prod_name = props.prop.product.name;
        const client_speech = selectedClient.current[`${prod_name}_speech`];
        setClientSpeech(client_speech != null ? client_speech : '');
    }

    function getLink() {
        const prod_type = props.prop.product.type.id;
        if (prod_type == 1 || prod_type == 3) {
            // ESTRUTURADAS E IMPORTADAS
            setLink({
                boletar: "#/books",
                acompanhamento: user.type < 3 ? "#/clients" : "#/clientesMesa",
            });
        } else if (prod_type == 2) {
            // OFERTA PÚBLICA
            setLink({
                boletar: "#/fiis",
                acompanhamento: user.type < 3 ? "#/clients" : "#/clientesMesa",
            });
        }
    }

    async function updateStatus(status_id) {
        setLoading(true);
        setNewStatus(status_id);
        setComentTimeline(defaultComments[status_id].comment)
        // try {
        //     const res = await updateABCStatus(tokenKey, operationId, status_id);
        //     // console.log(res?.data?.data);
        //     setType({ name: 'alertMsg', type: 'information', value: { message: `Status alterado com sucesso.` } })
        //     setEdited(true)
        // } catch (error) {
        //     setLoading(false);
        //     setType({ name: "alertMsg", type: "information", value: { message: error?.response?.data?.message == undefined ? "Erro interno ao carregar os dados" : error?.response?.data?.message } });
        //     setShow(true);
        // }
        // setLoading(false);
    }

    async function sendNewStatus(){
        if(newStatus < 0){
            return
        }
        try {
            const res = await updateABCStatus(tokenKey, operationId, newStatus);
            // console.log(res?.data?.data);
            // setType({ name: 'alertMsg', type: 'information', value: { message: `Status alterado com sucesso.` } })
            setEdited(true)
            alert('Status alterado com sucesso!')
            setNewStatus(-1)
        } catch (error) {
            setLoading(false);
            setType({ name: "alertMsg", type: "information", value: { message: error?.response?.data?.message == undefined ? "Erro interno ao atualizar o status do cliente" : error?.response?.data?.message } });
            setShow(true);
        }
        setLoading(false);
    }

    async function getData () {
        if (show){
            selectedClient.current = props.prop.client
            setItems([])
            setFilteredItems([])
            getOperationId();
            getCategory();
            getSpeech();
            getLink();
            getTimeline();
            setDefaultComments({
                2:{comment: `Feito contato com o cliente sobre o produto ` + props?.prop?.product?.description + `. Cliente ficou de avaliar a sugestão.`},
                3:{comment: `Feito o envio de ordem do produto ` + props?.prop?.product?.description + ` para o cliente.`},
                6:{comment: `Feito contato com o cliente sobre o produto ` + props?.prop?.product?.description + `. Cliente não teve interesse na oferta e recusou o produto.`},
                7:{comment: `Cliente não possui perfil para o produto `+ props?.prop?.product?.description + `.`}
            })
            setRefusalResasons([
                {id:'recurso' , label: "Falta de recurso p/ alocar", comment : `Abordado o investidor sobre a oportunidade ${props?.prop?.product?.description}, porém o cliente está sem recurso disponível.`},
                {id:'prazo' , label: "Prazo do produto", comment : `Abordado o investidor sobre a oportunidade ${props?.prop?.product?.description}, porém o cliente não aceitou o prazo do produto.`},
                {id:'cenario' , label: "Cenário Adverso", comment : `Abordado o investidor sobre a oportunidade ${props?.prop?.product?.description}, porém o cliente não aceitou o produto por receio com o cenário macro atual.`},
                {id:'preferencia' , label: "Prefere outro produto", comment : `Abordado o investidor sobre a oportunidade ${props?.prop?.product?.description}, porém o cliente não aceitou o produto por preferir outras classes de ativos no momento.`},
                {id:'taxa' , label: "Taxa não atrativa", comment : `Abordado o investidor sobre a oportunidade ${props?.prop?.product?.description}, porém o cliente não aceitou o produto por não achar atrativa a taxa do ativo.` },
                {id:'outros' , label: "Outros", comment : `Escreva o motivo!` },
                ])
        }
    }

    useEffect(() => {
        getData()
    }, []);

    useEffect(() => {
        getOperationId();
        getCategory();
        getSpeech();
        getLink();
        getTimeline();
    }, [selectedClient.current])

    useEffect(() => {
        var aux = {}
        props.prop.product.categories.map((x,index) => {
          x.displayId = index+1
          aux[x.id] = x
        })
        setCategories(aux)
    
        const geral = props.prop.product.categories.filter((x) => x.geral_reference == true)
        if (geral.length > 0) {
          setSelectedCategory(geral[0].id)
          setGeralCategory(geral[0].id)
        }
    
        setClients(props.prop.clients.filter((x) => x[`${props.prop.product.name}_operation_id`] != null))
        selectedClient.current = props.prop.client
      }, [show]);

    function disableCopying() {
      setCopyingDescription(false);
      var aux = copyingLink.map((l) => false)
      setCopyingLink(aux);
    }

    function clear() {
      setLink({
        boletar: "#/",
        acompanhamento: "#/",
      });
      setComentTimeline("");
      setContactType(5)
      setFilters([])
      setItems([])
      setFilteredItems([])
      setClientSpeech('')
      setNewStatus(-1)
      setActiveRefusalReasonBox(false)
      setSelectedReason('')
    }

    async function handleSubmitComment() {
      if (comentTimeline !== null && comentTimeline != "") {
        //se status recusado add o motivo da recusa antes do texto
        var newMessage = ''
        if(newStatus == 6){
            const reason = refusalReasons.filter(r => r.id==selectedReason)
            newMessage = `Feito contato com o cliente sobre o produto ${props?.prop?.product?.description} porém o cliente recusou. Motivo: ${reason[0].label}. ${comentTimeline} `
        }
          try {
              
              const clientUpdate = await api.post(
                  `/api/v1/vba/sendComment/${selectedClient.current.codigo}?abc_products_id=${props.prop.product.id}`,
                  {
                      comment: newStatus == 6 ? newMessage.replaceAll("'", "''") : comentTimeline.replaceAll("'", "''"),
                      commentType: contactType
                  },
                  {
                      headers: {
                          authorization: `Bearer ${tokenKey}`,
                        //   abc_products_id: props.prop.product.id // !TIP DO GD: COMENTÁRIO ESPECÍFICO PARA UM PRODUTO, SE QUISER QUE O COMENTÁRIO SEJA PARA TODOS, BASTA REMOVER ESSA LINHA
                      },
                  }
              );
              setComentTimeline("");
              setContactType(5);
              getTimeline();
              if(newStatus >=0){
                sendNewStatus()
              }
              // setType({ name: 'alertMsg', type: 'information', value: { message: `Comentário cadastrado.` } })
          } catch (error) {
              alert(error?.response?.data?.message);
          }
      } else {
          alert("O comentário nao pode ser vazio");
      }
  }

  async function filterTimeline(all) {
    var todosItems = all ? all : structuredClone(allItems)
    var filteredItemsAux = []
    if(filters.length > 0){
        filteredItemsAux = todosItems.filter((e) => filters.indexOf(parseInt(e.type)) >= 0)
    }
    else{
        filteredItemsAux = todosItems
    }
    await setFilteredItems(filteredItemsAux)
    await setItems(filteredItemsAux.length - 30 < 0 ? filteredItemsAux : filteredItemsAux.slice(filteredItemsAux.length - 30))
    scrollBottom()
}

async function getTimeline() {
  await setLoadingTimeline(true);
  try {
      const timeline = await api.get(`/api/v1/vba/clientTimeline/${selectedClient.current.codigo}`, {
          headers: {
              authorization: `Bearer ${tokenKey}`,
            //   abc_products_id: props.prop.product.id // !TIP DO GD: COMENTÁRIO ESPECÍFICO PARA UM PRODUTO, SE QUISER TRAZER TODOS, BASTA REMOVER ESSA LINHA
          },
      });
      
      await setAllItems(timeline?.data?.data);
      // await setFilteredItems(timeline?.data?.data);
      await filterTimeline(timeline?.data?.data)
      // await setItems(timeline?.data?.data.length - itemQty < 0 ? timeline?.data?.data : timeline?.data?.data.slice(timeline?.data?.data.length - itemQty));
      
      await setLoadingTimeline(false);
      scrollBottom();
  } catch (error) {
      alert(error?.response?.data?.message);
      await setLoadingTimeline(false);
  }
}

async function updateItemsList() {
  var auxAllItems = structuredClone(filteredItems);
  var objDiv = document.getElementById("timelineCommentBoxAbc");
  var scrollNext = objDiv?.scrollHeight;
  if (itemQty != auxAllItems.length) {
      if (auxAllItems.length - (itemQty + 30) <= 0) {
          await setItems(auxAllItems);
          await setItemQty(auxAllItems.length);
      } else {
          await setItems(auxAllItems.slice(auxAllItems.length - (itemQty + 30)));
          await setItemQty(itemQty + 30);
      }
      if (objDiv) objDiv.scrollTop = objDiv?.scrollHeight - scrollNext;
  }
}

async function scrollBottom() {
  if (props?.clientData?.value?.client[15] != "Prospect" && props?.clientData?.value?.client[15] != "Transferido") {
      var objDiv = document.getElementById("timelineCommentBoxAbc");
      if (objDiv){
          objDiv.scrollTop = objDiv?.scrollHeight;
        }
  }
}

async function showClientActivation(type) {
  // type - função
  // 1 - ativação inicial do cliente
  // 2 - vizualização do formulário de ativação já preenchido
  // 3 - vizualização e edição de formulário para transferência
  // 4 - cliente transferido, o broker precisa visualizar para ativá-lo
  setShow(true);
  setType({ name: "showVbaClientActivation", value: props?.clientData?.value?.client, type: type });
}

function handleRefusalReason() {
    updateStatus(6);
}

// funções do carrossel de catergorias de um produto
const slideLeft = () => {
    setCurrentPosition(prevPosition => {
    let newPosition = prevPosition + 200;
    if (newPosition > 0) {
        newPosition = 0;
    }
    return newPosition;
    });
};

const slideRight = () => {
const menuItems = menuItemsRef.current;
if (menuItems) {
    const menuWidth = menuItems.offsetWidth;
    const containerWidth = menuItems.parentElement.offsetWidth;
    const maxPosition = containerWidth - menuWidth;
    setCurrentPosition(prevPosition => {
    let newPosition = prevPosition - 200;
    if (newPosition < maxPosition) {
        newPosition = maxPosition;
    }
    return newPosition;
    });
}
};

    return (
        <div className="containerModalAbcDetails">
            <div className="clientControlHeader">
                <div className="editBrancheBoxHeader">
                    <div className="containerProductName">
                        <p>{props?.prop?.product?.description}: </p>
                        <p>{selectedClient?.current?.codigo} - {selectedClient?.current?.nome}</p>
                    </div>
                    <button onClick={() => { clear(); setShow(false)}} className="modalCloseButton">
                        X
                    </button>
                </div>
            </div>
            <div className="containerClientMenuAbcActions">
                {/* lista de clientes */}
                <div className="listClientsModalAbc">
                    
                    {/* <p style={{marginLeft: '1rem'}}>Categorias</p> */}
                    <div className="clientInfoAbcActions">
                    Categorias
                        </div>
                    <div className="carousel-container">
                    <button onClick={slideLeft} className="carousel-button"><MdOutlineArrowBackIosNew size={24}/></button>
                        <div id="menu" className="carousel-menu">
                            <ul id="menu-items" ref={menuItemsRef} style={{ transform: `translateX(${currentPosition}px)` }}>
                            {props?.prop?.product?.categories?.map((c,index) => {
                                if (c.title != "AVULSA"){

                                    return (
                                        <li>
                                        <button key={index} className={c.id == filterCategory ? "menuCategoryItemAbcActiveAbrir" : 'menuCategoryItemAbcInativeAbrir'}
                                        onClick={() => {
                                            if (filterCategory == c.id){
                                                setFilterCategory(0)
                                            }
                                            else{
                                                setFilterCategory(c.id)
                                            }
                                        }}>
                                        {c.title}
                                        </button>
                                    </li>
                                    );
                                }
                                })}
                            </ul>
                        </div>
                        
                        <button onClick={slideRight} className="carousel-button"><MdOutlineArrowForwardIos size={24}/></button>
                    </div>
                    <div className="listClientsHeaderModalAbcAbrir">
                        <div>
                        {
                            searchActive ? 
                            <input
                                type="text"
                                id="searchClient"
                                name="searchClient"
                                placeholder="Pesquise aqui"
                                autoFocus 
                                className={classesContactType.inputTextSearch}
                                onChange={(e) => (
                                setSearchText(e.target.value)
                                )}
                                defaultValue={searchText ?? searchText}
                                onBlur={() => {
                                if(searchText == ''){
                                    setSearchActive(false)
                                }
                                }
                                }>
                            </input>
                            : 
                            <div className="clientInfoAbcActions" style={{marginLeft: "1rem"}}>
                                Clientes
                        </div>
                        }
                        </div>
                        <button className={"searchIconModalAbc"} onClick={() => {
                        // this.input_2.focus()
                        setSearchActive (true)
                        }}>
                        <MdSearch size={28}/>
                        </button>
                    </div>
                    <div className="listClientsBodyModalAbcAbrir">
                    {clients?.map((c,index) => {
                        const client_cat= c[props.prop.product.name+ "_category_id"]
                        var statusClient = String(c[props.prop.product.name+ "_status"]).toUpperCase()
                        var statusColor = statusClient == "PENDENTE" ? "#FAF5C7" : statusClient == "ABORDADO" ? "#7893DB" : statusClient == "SOLICITADO" ? "#F0B357" : statusClient == "BOLETADO" ? "#CBF0BE" : statusClient == "CANCELADO" ? "#E2BBE3" : statusClient == "RECUSADO" ? "#E87F7F" : statusClient == "NÃO ADERENTE" ? "#000000" : statusClient == "ANÁLISE DO BACKOFFICE" ? "#40E0D0" : "#9013FE";
                        
                        if ((filterCategory == 0 || filterCategory == client_cat) && ((c.codigo+c.nome).toLowerCase()).includes(searchText.toLowerCase())){

                        
                        const id_display = props.prop.product.categories.filter((e) => e.id == client_cat)[0]
                        return (
                            <button key={index} 
                            className={selectedClient && c.codigo == selectedClient.current.codigo ? "listClientsItemActiveAbcAbrir" : "listClientsItemInativeAbcAbrir"}
                            title={c.codigo + '-' + titleize(c.nome)}
                            onClick={() => 
                            {c.categoryName = id_display.title
                                c.categoryLink = id_display?.link?.split(' ')
                                selectedClient.current = c
                                var aux = []
                                c.categoryLink?.forEach(() => {
                                    aux.push(false)
                                })
                                setCopyingLink(aux)
                            }
                            }>
                            
                            <div className="statusColorDivAbc" title={titleize(String(c[props.prop.product.name+ "_status"]))} >
                                <BsFillSquareFill color={statusColor } className="statusColorDivAbc" title={titleize(String(c[props.prop.product.name+ "_status"]))}/>
                            </div>
                            {c.codigo + " - " + titleize(c.nome)}
                        </button>);
                            }
                    })}

                    </div>
                </div>
                <div className="divisionNameProductSubcategory"></div>

                {/* speech do cliente */}
                <div className="containerClientMenuSubcategoryAbcActions">
                    <div className="headerSubcategoryAbcActions">
                        <div className="headerSubcategoryTitleAbcActions">{categoryInfos?.title}</div>
                        {client_speech != null && client_speech != '' ? <div
                            className="containerIconCopyContentAbcActions"
                            onClick={(e) => {
                              setCopyingDescription(true);
                                navigator.clipboard.writeText(client_speech);
                                setTimeout(disableCopying, 1000);
                            }}>
                            {!copyingDescription ? <MdContentCopy size={20} /> : <FaCheck size={20} className="iconCopyContentAbcActionsAtive" />}
                        </div> : <div></div>}
                    </div>
                    <div className="containerSubcategoryDescriptionAbcActions">
                      
                      <textarea className="textAreaSpeechAbc" value={client_speech} readOnly>
                        
                        </textarea>
                        {/* <p className="descriptionTextAbc">{client_speech}</p> */}
                    </div>
                        <div className="linksBoxAbc">
                        {
                            categoryInfos?.link ? categoryInfos?.link.split(' ').map((l,index) => {
                                
                                return (
                                    <>
                                        <div key={index} className="headerSubcategoryTitleAbcActions">{l}</div>
                                        <div
                                            className="containerIconCopyContentAbcActions"
                                            onClick={async () => {
                                                let aux = copyingLink.map((v, i, a) => i == index ? true : v )
                                                await setCopyingLink(aux);
                                                navigator.clipboard.writeText(l);
                                                setTimeout(disableCopying, 1000);
                                            }}>
                                            <div className="linkMenuModalBoxAbc">
                                            {!copyingLink[index] ? <MdContentCopy size={20} /> : <FaCheck size={20} className="iconCopyContentAbcActionsAtive" />}

                                            </div>
                                        </div>
                                    </>
                                )
                            }) : <div className="headerSubcategoryTitleAbcActions">Link Indisponível</div>
                        }
                    </div>
                </div>
                <div className="divisionNameProductSubcategory"></div>
                
                {/* botões de ação e alteração de status */}
                <div className="containerClientMenuOptionsAbcActions">
                    <div className="containerNameAbcActions">
                        <div className="clientInfoAbcActions">
                            Ações Disponíveis
                        </div>
                    </div>
                    <div className="containerGeneralOptionsAbcActions">
                        <div className="containerOptionsTitleAbcActions">
                            <div className="optionsTitleAbcActions">{props?.prop?.product?.description}</div>
                        </div>
                        <div className="containerOptionsAbcActions">
                            {props?.prop?.product?.type.id != 3 ? <button
                                className="buttonAbcActions"
                                
                                onClick={() => {
                                //   const win = window.open(link.boletar, "_blank");
                                //   win.focus();
                                setType({ name: props.prop.product.type.id == 1 ? "boletarEstruturada" : 'boletarOfertaPublica', props: {product : props.prop.product, client: selectedClient.current, clients: props.prop.clients, returnTo: 'details'} }); 
                                }}>
                                Boletar
                            </button> : 
                            <button
                            className="buttonAbcActionsSolicit"
                            onClick={() => {
                                updateStatus(3)
                            }}>
                            Solicitar
                        </button>}
                        </div>
                    </div>
                    
                    <div className="containerGeneralChangeStatusAbcActions">
                        <div className="containerChangeStatusTitleAbcActions">
                            <div className="ChangeStatusTitleAbcActions">Alterar Status</div>
                        </div>
                        <div className="containerChangeStatusAbcActions">
                            <button
                                className="buttonAbordadoAbcActions"
                                onClick={() => {
                                    updateStatus(2);
                                }}>
                                Abordado
                            </button>
                            <button
                                className="buttonRecusadoAbcActions"
                                onClick={() => {
                                    setActiveRefusalReasonBox(true);
                                }}>
                                Recusado
                            </button>
                            <button
                                className="buttonNaoAderenteAbcActions"
                                onClick={() => {
                                    updateStatus(7);
                                }}>
                                Não Aderente
                            </button>
                        </div>
                    </div>
                    {newStatus >=0 ? <div className="suggestionMessage alertMessageAbc">
                        <div className="suggestionMessageHeader">A mensagem ao lado será enviada ao assessor.</div>
                        <div className="iconArrowRightAbc"><FiArrowRight color size='40' /></div>
                        <div className="suggestionMessageBody"><span style={{fontWeight:'500'}}>Sugestão: </span>adicione uma descrição mais completa para deixar o assessor ainda mais informado</div>
                    </div> : <div></div>}
                </div>
                <div className="divisionNameProductSubcategory"></div>
                
                {/* timeline do cliente */}
                <div className='timelineABC'>
                <div className={"commentBoxAbc"}>
                    {activeRefusalReasonBox ? 
                <div className={"refusalReasonBox"}>
                    <>
                    <div className="refuseReasonHeaderAbc">
                        <div></div>
                        <div className="refuseReasontitleAbc">Motivo da recusa</div>
                        <button onClick={() => setActiveRefusalReasonBox(false)} className="modalCloseButton">X</button>
                    </div>
                    <FormGroup className="reasonsRefusalList">
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            name="radio-buttons-group"
                            >
                        {refusalReasons.map((e,i)=> {
                            return(
                                <FormControlLabel value={e.id} control={<Radio />} label={e.label} onClick={(e) => {
                                    setSelectedReason(e.target.value)
                                    const refusalReasonAux = refusalReasons.filter(r => r.id == e.target.value)
                                    setComentTimeline(refusalReasonAux[0]?.comment)
                                }
                            }/>
                            )
                        })}
                        </RadioGroup>
                    </FormGroup>
                    <button
                        className="buttonAbcActions"
                        onClick={() => {
                            setActiveRefusalReasonBox(false)
                            setNewStatus(6)
                        }}>
                        Ok
                    </button>
                    </>
                    </div>
                     : <></>}
                <div className="containerTimelineAbcDetails">
                                <>
                                    <div className="timelineTitleAbc">
                                        <div className="clientInfoAbcActions">Linha do tempo</div>
                                        <div className="timelineFilters">
                                            <p style={{fontWeight: '400'}}>Filtrar:</p>
                                            <div
                                                className={filters.indexOf(1) >= 0 ? classesContactType.timelineFilterType1Active : classesContactType.timelineFilterTypeInactive}
                                                onClick={() => {
                                                    if (filters.indexOf(1) >= 0) {
                                                        filters.splice(filters.indexOf(1),1)
                                                    }
                                                    else{
                                                        filters.push(1)
                                                    }
                                                    filterTimeline()
                                                }}
                                                title="Whatsapp">
                                                <BsWhatsapp style={{ margin: "auto" }} size={18} />
                                            </div>
                                            <div
                                                className={filters.indexOf(2) >= 0 ? classesContactType.timelineFilterType2Active : classesContactType.timelineFilterTypeInactive}
                                                onClick={() => {
                                                    if (filters.indexOf(2) >= 0) {
                                                        filters.splice(filters.indexOf(2),1)
                                                    }
                                                    else{
                                                        filters.push(2)
                                                    }
                                                    filterTimeline()
                                                }}
                                                title="Ligação">
                                                <BsTelephone style={{ margin: "auto" }} size={18} />
                                            </div>
                                            <div
                                                className={filters.indexOf(3) >= 0 ? classesContactType.timelineFilterType3Active : classesContactType.timelineFilterTypeInactive}
                                                onClick={() => {
                                                    if (filters.indexOf(3) >= 0) {
                                                        filters.splice(filters.indexOf(3),1)
                                                    }
                                                    else{
                                                        filters.push(3)
                                                    }
                                                    filterTimeline()
                                                }}
                                                title="E-mail">
                                                <AiOutlineMail style={{ margin: "auto" }} size={18} />
                                            </div>
                                            <div
                                                className={filters.indexOf(4) >= 0 ? classesContactType.timelineFilterType4Active : classesContactType.timelineFilterTypeInactive}
                                                onClick={() => {
                                                    if (filters.indexOf(4) >= 0) {
                                                        filters.splice(filters.indexOf(4),1)
                                                    }
                                                    else{
                                                        filters.push(4)
                                                    }
                                                    filterTimeline()
                                                }}
                                                title="Reunião">
                                                <BsPeople style={{ margin: "auto" }} size={18} />
                                            </div>
                                            <div
                                                className={filters.indexOf(5) >= 0 ? classesContactType.timelineFilterType5Active : classesContactType.timelineFilterTypeInactive}
                                                onClick={() => {
                                                    if (filters.indexOf(5) >= 0) {
                                                        filters.splice(filters.indexOf(5),1)
                                                        
                                                    }
                                                    else{
                                                        filters.push(5)
                                                    }
                                                    filterTimeline()
                                                }}
                                                title="Apenas Comentário">
                                                <BsChatDots style={{ margin: "auto" }} size={18} />
                                            </div>
                                            
                                            <div
                                                className={filters.indexOf(0) >= 0 ? classesContactType.timelineFilterType0Active : classesContactType.timelineFilterTypeInactive}
                                                onClick={() => {
                                                    if (filters.indexOf(0) >= 0) {
                                                        filters.splice(filters.indexOf(0),1)
                                                    }
                                                    else{
                                                        filters.push(0)
                                                    }
                                                    filterTimeline()
                                                }}
                                                title="Sistema">
                                                <BsCpu style={{ margin: "auto" }} size={18} />
                                            </div>
                                            <div
                                                className={filters.indexOf(6) >= 0 ? classesContactType.timelineFilterType6Active : classesContactType.timelineFilterTypeInactive}
                                                onClick={() => {
                                                    if (filters.indexOf(6) >= 0) {
                                                        filters.splice(filters.indexOf(6),1)
                                                    }
                                                    else{
                                                        filters.push(6)
                                                    }
                                                    filterTimeline()
                                                }}
                                                title="Formulário Ativação">
                                                <BsCardList style={{ margin: "auto" }} size={18} />
                                            </div>
                                        </div>
                                    </div>

                                    <div
                                        className="timelineCommentBoxAbc"
                                        id="timelineCommentBoxAbc"
                                        onScroll={(e) => {
                                            if (e.target.scrollTop < 10) updateItemsList();
                                        }}>
                                        {(items.length > 0 && !loadingTimeline) ? (
                                            items.map((item) => {
                                                return item.user.id == user.external_id ? (
                                                    item.type == "6" ? (
                                                        <div className="timelineComentBoxUserComentRight">
                                                            <div className={"timelineComentBoxUserComentHeaderRight formHeaderColor"}>
                                                                <div className="timelineComentBoxUserComentIconRight">
                                                                    <BsCardList title="Formulário respondido antes da ativação" />
                                                                </div>
                                                                <div className="timelineComentBoxUserComentNameRight">{item.user.name}</div>
                                                            </div>
                                                            <div className={"timelineComentBoxUserComentMessageRight formMessageColor"}>
                                                                <button
                                                                    className="buttonShowFinishedForm"
                                                                    onClick={() => {
                                                                        showClientActivation(2);
                                                                    }}>
                                                                    Formulário de primeira reunião
                                                                    <BsBoxArrowUpLeft style={{ marginLeft: "0.4rem" }} size={14} />
                                                                </button>
                                                            </div>
                                                            <div className={"timelineComentBoxUserComentDateRight formDateColor"}>{item.created_at.split("T")[0].split("-")[2] + "/" + item.created_at.split("T")[0].split("-")[1] + "/" + item.created_at.split("T")[0].split("-")[0] + " - " + item.created_at.split("T")[1].split(".")[0]}</div>
                                                        </div>
                                                    ) : (
                                                        <div className="timelineComentBoxUserComentRight">
                                                            <div
                                                                className={
                                                                    item.type == 1
                                                                        ? "timelineComentBoxUserComentHeaderRight whatsAppHeaderColor"
                                                                        : item.type == 2
                                                                        ? "timelineComentBoxUserComentHeaderRight phoneHeaderColor"
                                                                        : item.type == 3
                                                                        ? "timelineComentBoxUserComentHeaderRight emailHeaderColor"
                                                                        : item.type == 4
                                                                        ? "timelineComentBoxUserComentHeaderRight meetHeaderColor"
                                                                        : item.type == 5
                                                                        ? "timelineComentBoxUserComentHeaderRight commentHeaderColor"
                                                                        : item.type == 0
                                                                        ? "timelineComentBoxUserComentHeaderRight systemHeaderColor"
                                                                        : "timelineComentBoxUserComentHeaderRight indicationHeaderColor"
                                                                }>
                                                                <div className="timelineComentBoxUserComentIconRight">
                                                                    {item.type == 1 ? <BsWhatsapp title="Contato por WhatsApp" /> : item.type == 2 ? <BsTelephone title="Contato por ligação" /> : item.type == 3 ? <AiOutlineMail title="Contato por e-mail" /> : item.type == 4 ? <BsPeople title="Contato por reunião" /> : item.type == 5 ? <BsChatDots title="Mensagem sem classificação" /> : item.type == 0 ? <BsCpu title="Mensagem do sistema" /> : <BsPersonPlus title="Indicação de cliente" />}
                                                                </div>
                                                                <div className="timelineComentBoxUserComentNameRight">{item.user.name}</div>
                                                            </div>
                                                            <div
                                                                className={
                                                                    item.type == 1
                                                                        ? "timelineComentBoxUserComentMessageRight whatsAppMessageColor"
                                                                        : item.type == 2
                                                                        ? "timelineComentBoxUserComentMessageRight phoneMessageColor"
                                                                        : item.type == 3
                                                                        ? "timelineComentBoxUserComentMessageRight emailMessageColor"
                                                                        : item.type == 4
                                                                        ? "timelineComentBoxUserComentMessageRight meetMessageColor"
                                                                        : item.type == 5
                                                                        ? "timelineComentBoxUserComentMessageRight commentMessageColor"
                                                                        : item.type == 0
                                                                        ? "timelineComentBoxUserComentMessageRight systemMessageColor"
                                                                        : "timelineComentBoxUserComentMessageRight indicationMessageColor"
                                                                }>
                                                                {item.text}
                                                                <>
                                                                    {item?.informations?.map((line) => {
                                                                        return (
                                                                            <div>
                                                                                {line.key} <b>: </b> {line.value}
                                                                            </div>
                                                                        );
                                                                    })}
                                                                </>
                                                            </div>
                                                            <div
                                                                className={
                                                                    item.type == 1
                                                                        ? "timelineComentBoxUserComentDateRight whatsAppDateColor"
                                                                        : item.type == 2
                                                                        ? "timelineComentBoxUserComentDateRight phoneDateColor"
                                                                        : item.type == 3
                                                                        ? "timelineComentBoxUserComentDateRight emailDateColor"
                                                                        : item.type == 4
                                                                        ? "timelineComentBoxUserComentDateRight meetDateColor"
                                                                        : item.type == 5
                                                                        ? "timelineComentBoxUserComentDateRight commentDateColor"
                                                                        : item.type == 0
                                                                        ? "timelineComentBoxUserComentDateRight systemDateColor"
                                                                        : "timelineComentBoxUserComentDateRight indicationDateColor"
                                                                }>
                                                                {item.created_at.split("T")[0].split("-")[2] + "/" + item.created_at.split("T")[0].split("-")[1] + "/" + item.created_at.split("T")[0].split("-")[0] + " - " + item.created_at.split("T")[1].split(".")[0]}
                                                            </div>
                                                        </div>
                                                    )
                                                ) : item.type == "6" ? (
                                                    <div className="timelineComentBoxUserComentLeft">
                                                        <div className={"timelineComentBoxUserComentHeaderLeft formHeaderColor"}>
                                                            <div className="timelineComentBoxUserComentNameLeft">{item.user.name}</div>
                                                            <div className="timelineComentBoxUserComentIconLeft">
                                                                <BsCardList title="Formulário respondido antes da ativação" />
                                                            </div>
                                                        </div>
                                                        <div className={"timelineComentBoxUserComentMessageLeft formMessageColor"}>
                                                            <button
                                                                className="buttonShowFinishedForm"
                                                                onClick={() => {
                                                                    showClientActivation(2);
                                                                }}>
                                                                Formulário de primeira reunião
                                                                <BsBoxArrowUpLeft style={{ marginLeft: "0.4rem" }} size={14} />
                                                            </button>
                                                        </div>
                                                        <div className={"timelineComentBoxUserComentDateLeft formDateColor"}>{item.created_at.split("T")[0].split("-")[2] + "/" + item.created_at.split("T")[0].split("-")[1] + "/" + item.created_at.split("T")[0].split("-")[0] + " - " + item.created_at.split("T")[1].split(".")[0]}</div>
                                                    </div>
                                                ) : (
                                                    <div className="timelineComentBoxUserComentLeft">
                                                        <div
                                                            className={
                                                                item.type == 1
                                                                    ? "timelineComentBoxUserComentHeaderLeft whatsAppHeaderColor"
                                                                    : item.type == 2
                                                                    ? "timelineComentBoxUserComentHeaderLeft phoneHeaderColor"
                                                                    : item.type == 3
                                                                    ? "timelineComentBoxUserComentHeaderLeft emailHeaderColor"
                                                                    : item.type == 4
                                                                    ? "timelineComentBoxUserComentHeaderLeft meetHeaderColor"
                                                                    : item.type == 5
                                                                    ? "timelineComentBoxUserComentHeaderLeft commentHeaderColor"
                                                                    : item.type == 0
                                                                    ? "timelineComentBoxUserComentHeaderLeft systemHeaderColor"
                                                                    : "timelineComentBoxUserComentHeaderLeft indicationHeaderColor"
                                                            }>
                                                            <div className="timelineComentBoxUserComentNameLeft">{item.user.name}</div>
                                                            <div className="timelineComentBoxUserComentIconLeft">
                                                                {item.type == 1 ? <BsWhatsapp title="Contato por WhatsApp" /> : item.type == 2 ? <BsTelephone title="Contato por ligação" /> : item.type == 3 ? <AiOutlineMail title="Contato por e-mail" /> : item.type == 4 ? <BsPeople title="Contato por reunião" /> : item.type == 5 ? <BsChatDots title="Mensagem sem classificação" /> : item.type == 0 ? <BsCpu title="Mensagem do sistema" /> : <BsPersonPlus title="Indicação de cliente" />}
                                                            </div>
                                                        </div>
                                                        <div
                                                            className={
                                                                item.type == 1
                                                                    ? "timelineComentBoxUserComentMessageLeft whatsAppMessageColor"
                                                                    : item.type == 2
                                                                    ? "timelineComentBoxUserComentMessageLeft phoneMessageColor"
                                                                    : item.type == 3
                                                                    ? "timelineComentBoxUserComentMessageLeft emailMessageColor"
                                                                    : item.type == 4
                                                                    ? "timelineComentBoxUserComentMessageLeft meetMessageColor"
                                                                    : item.type == 5
                                                                    ? "timelineComentBoxUserComentMessageLeft commentMessageColor"
                                                                    : item.type == 0
                                                                    ? "timelineComentBoxUserComentMessageLeft systemMessageColor"
                                                                    : "timelineComentBoxUserComentMessageLeft indicationMessageColor"
                                                            }>
                                                            {item.text}
                                                            <>
                                                                {item?.informations?.map((line) => {
                                                                    return (
                                                                        <div>
                                                                            {line.key} <b>: </b> {line.value}
                                                                        </div>
                                                                    );
                                                                })}
                                                            </>
                                                        </div>
                                                        <div
                                                            className={
                                                                item.type == 1
                                                                    ? "timelineComentBoxUserComentDateLeft whatsAppDateColor"
                                                                    : item.type == 2
                                                                    ? "timelineComentBoxUserComentDateLeft phoneDateColor"
                                                                    : item.type == 3
                                                                    ? "timelineComentBoxUserComentDateLeft emailDateColor"
                                                                    : item.type == 4
                                                                    ? "timelineComentBoxUserComentDateLeft meetDateColor"
                                                                    : item.type == 5
                                                                    ? "timelineComentBoxUserComentDateLeft commentDateColor"
                                                                    : item.type == 0
                                                                    ? "timelineComentBoxUserComentDateLeft systemDateColor"
                                                                    : "timelineComentBoxUserComentDateLeft indicationDateColor"
                                                            }>
                                                            {item.created_at.split("T")[0].split("-")[2] + "/" + item.created_at.split("T")[0].split("-")[1] + "/" + item.created_at.split("T")[0].split("-")[0] + " - " + item.created_at.split("T")[1].split(".")[0]}
                                                        </div>
                                                    </div>
                                                );
                                            })
                                        ) : loadingTimeline ? (
                                            <ArrowLoader />
                                        ) : (
                                            <div className="emptyTimelineMessage">Não há mensagens...</div>
                                        )}
                                    </div>
                                    <div className="timelineNewCommentBox">
                                        <div className="timelineNewCommentContactType">
                                            <div className="timelineNewCommentContactTypeTitle">Tipo de contato</div>
                                            <div
                                                className={contactType == 1 ? classesContactType.timelineNewCommentContactTypeActive : classesContactType.timelineNewCommentContactTypeInactive}
                                                onClick={() => {
                                                    setContactType(1);
                                                    if (contactType == 1) {
                                                        setContactType(5);
                                                    }
                                                }}
                                                title="Whatsapp">
                                                <BsWhatsapp style={{ margin: "auto" }} size={24} />
                                            </div>
                                            <div
                                                className={contactType == 2 ? classesContactType.timelineNewCommentContactTypeActive : classesContactType.timelineNewCommentContactTypeInactive}
                                                onClick={() => {
                                                    setContactType(2);
                                                    if (contactType == 2) {
                                                        setContactType(5);
                                                    }
                                                }}
                                                title="Ligação">
                                                <BsTelephone style={{ margin: "auto" }} size={24} />
                                            </div>
                                            <div
                                                className={contactType == 3 ? classesContactType.timelineNewCommentContactTypeActive : classesContactType.timelineNewCommentContactTypeInactive}
                                                onClick={() => {
                                                    setContactType(3);
                                                    if (contactType == 3) {
                                                        setContactType(5);
                                                    }
                                                }}
                                                title="E-mail">
                                                <AiOutlineMail style={{ margin: "auto" }} size={24} />
                                            </div>
                                            <div
                                                className={contactType == 4 ? classesContactType.timelineNewCommentContactTypeActive : classesContactType.timelineNewCommentContactTypeInactive}
                                                onClick={() => {
                                                    setContactType(4);
                                                    if (contactType == 4) {
                                                        setContactType(5);
                                                    }
                                                }}
                                                title="Reunião">
                                                <BsPeople style={{ margin: "auto" }} size={24} />
                                            </div>
                                        </div>
                                        <div className="timelineNewCommentWriteSend">
                                            <textarea
                                                className={classesContactType.timelineNewComentWriteArea}
                                                value={comentTimeline}
                                                onChange={(e) => {
                                                    setComentTimeline(e.target.value);
                                                }}></textarea>
                                            <button
                                                className={comentTimeline !== "" && comentTimeline !== null ? classesContactType.timelineNewCommentSendActive : classesContactType.timelineNewCommentSendInative}
                                                onClick={() => {
                                                    if (comentTimeline !== "" && comentTimeline !== null) {
                                                        handleSubmitComment();
                                                    }
                                                }}>
                                                <AiOutlineSend size={30} />
                                            </button>
                                        </div>
                                    </div>
                                </>
                        </div>
                </div>
                </div>
                
                
            </div>
        </div>
    );
}
