/*Componente cadastro de clientes
/*Descrição: Efetua o cadastro de novos clientes no sistema e chama o componente de importação
/*Desenvolvido por: Equipe Rafael Cezário
*/

import React, { useState, useEffect } from 'react';
import { TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
//styles
import './styles/clients.cadastration.css';
import { styleClientsInput } from '../../../../components/styles/autocompletField';
//services
import { api } from '../../../../services/api';
//hooks
import { useModal } from '../../../../contexts/useModal';
//Contexts
import { useAuth } from '../../../../contexts/useAuth';
//components
import { ArrowLoader } from '../../../../components/loaders/arrowLoader';
//utils
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import { titleize } from '../../../../utils/formatNumberAndText/formatTextUpperFirstLetter';
import { ModalSizeFitToScreen } from '../../../../utils/responsiveFunctions/modalSizeFitToScreen';

export function ClientsCadastration() {

    const { tokenKey } = useAuth();
    const [selectedClientCode, setSelectedClientCode] = useState('')
    const [selectedClientName, setSelectedClientName] = useState('')
    const [selectedAdvisor, setSelectedAdvisor] = useState('')
    const [selectedBroker, setSelectedBroker] = useState('')
    const [selectedClientProfile, setSelectedClientProfile] = useState('')
    const [selectedClientEmail, setSelectedClientEmail] = useState('')
    const [selectedClientOperatesAlone, setSelectedClientOperatesAlone] = useState(0)
    const [selectedClientNetTotal, setSelectedClientNetTotal] = useState('')
    const [selectedClientNetStocks, setSelectedClientNetStocks] = useState('')
    const [selectedClientStatus, setSelectedClientStatus] = useState({id: 1})
    const [selectedClientCPF, setSelectedClientCPF] = useState('')
    const [brokerList, setBrokerList] = useState([])
    const [advisorList, setAdvisorList] = useState([])
    const [clientsStatusList, setClientsStatusList] = useState([])
    const classes = styleClientsInput()
    const token = localStorage.getItem('@auth:token')
    const { show, setShow, setType } = useModal()
    const [loading, setLoading] = useState(true)
    const { screenY } = ModalSizeFitToScreen()
    const requiredFields = [
        { field: 'selectedClientName', value: selectedClientName },
        { field: 'selectedClientProfile', value: selectedClientProfile },
        { field: 'selectedClientCode', value: selectedClientCode },
        { field: 'selectedAdvisor', value: selectedAdvisor },
        { field: 'selectedClientEmail', value: selectedClientEmail },
    ]
    const [errorFields, setErrorFields] = useState({
        selectedClientCode: false,
        selectedClientName: false,
        selectedAdvisor: false,
        selectedClientProfile: false,
        selectedClientOperatesAlone: false,
        selectedClientNetTotal: false,
        selectedClientNetStocks: false,
        selectedClientEmail: false
    })

    useEffect(() => {

        // async function getBrokers() {
        //     try {
        //         //0 é o assessor. 0 pq não queremos o broker padrão de nenhum assessor
        //         const brokersResult = await api.get(`/api/v1/vba/availableBrokers/0`, {
        //             headers: {
        //                 authorization: `Bearer ${tokenKey}`
        //             }
        //         })
        //         setBrokerList(brokersResult?.data?.data?.brokers);
        //     } catch (error) {
        //         alert(error?.response?.data?.message)
        //     }
        // }

        async function getAdvisors() {
            const advisorsResult = await api.get('/api/v1/users?status=1&type_access=4', {
                headers: {
                    authorization: `Bearer ${token}`
                }
            })
            setAdvisorList(advisorsResult.data.data)
        }

        async function getClientStatus() {

            const listStatus = await api.get(`/api/v1/statusClients`, {
                headers: {
                    authorization: `Bearer ${token}`
                }
            })

            setClientsStatusList(listStatus?.data?.data)
            console.log(listStatus?.data?.data)

            setLoading(false)

        }
        // getBrokers()
        getAdvisors()
        getClientStatus()
        clear()

    }, [show])


    useEffect(() => {
        async function getBrokers() {
            console.log(selectedAdvisor.external_id)
            const brokersResult = await api.get(`/api/v1/users/${parseInt(selectedAdvisor.external_id)}/myBrokers`, {
                headers: {
                    authorization: `Bearer ${token}`
                }
            })
            console.log(brokersResult)
            setBrokerList(brokersResult.data.data)
        }
        getBrokers()
    }, [selectedAdvisor])

    async function handleSubmit(e) {

        setLoading(true)
        let errors = false
        
        requiredFields.map((required) => {
            if (typeof (required.value) !== 'object' && !required?.value?.toString()?.trim()?.length) {
                errorFields[required.field] = true
                errors = true
            } else {
                errorFields[required.field] = false
            }
            setErrorFields(errorFields)
        })

        if (errors) {

            await new Promise(resolve => setTimeout(resolve, 1000))
            alert('Campos obrigatórios não preenchidos')
            setLoading(false)
            return
        }

        e.preventDefault();

        if (selectedClientCode === '' || selectedClientName === ''
            || selectedAdvisor === '' || selectedClientOperatesAlone === ''
            || selectedClientProfile === '' || selectedClientEmail === '') {
            alert('Preencha todos os campos')
            return
        }

        try {
            setLoading(true)
            await api.post('/api/v1/clients', {
                "external_id": parseInt(selectedClientCode),
                "id_advisor": parseInt(selectedAdvisor?.external_id),
                "id_broker": !isNaN(parseInt(selectedBroker?.external_id)) ? parseInt(selectedBroker?.external_id) : undefined,
                "name": selectedClientName,
                "email": selectedClientEmail,
                "client_profile": selectedClientProfile,
                "operate_alone": selectedClientOperatesAlone.id,
                "net_total": selectedClientNetTotal > 0 ? selectedClientNetTotal : 0,
                "net_stock": selectedClientNetStocks > 0 ? selectedClientNetStocks : 0,
                //se o broker for selecionado, o status será prospect para futura ativação via formulário pelo broker
                "status": !isNaN(parseInt(selectedBroker?.external_id)) ? 3 : parseInt(selectedClientStatus.id)
            },
                {
                    headers: {
                        authorization: `Bearer ${token}`
                    }
                })
            setLoading(false);
            setType({ name: 'successClientCadastration', type: 'information', value: { message: `Sucesso ao cadastrar cliente ${selectedClientName}` } })

        } catch (error) {
            console.log(error.response)
            alert(error?.response?.data?.message)
            setShow(false)
        }

    }

    function clear() {
        setSelectedClientCode('')
        setSelectedClientName('')
        setSelectedAdvisor('')
        setSelectedBroker('')
        setSelectedClientCPF('')
        setSelectedClientOperatesAlone('')
        setSelectedClientProfile('')
        setSelectedClientNetTotal('')
        setSelectedClientNetStocks('')
        setSelectedClientEmail('')
    }

    return (
        
        <div className='clientsCadastrationContainer' style={{
            maxHeight: screenY
        }}>
            {loading ?
                (
                    <div className='clientsCadastrationLoading'>
                        <ArrowLoader />
                    </div>
                ) :
                (
                    <>
                        <div className='clientsCadastrationHeader'>
                            <p>Efetue o cadastro dos clientes abaixo</p>
                            <button onClick={() => setShow(false)}>x</button>
                        </div>
                        <div className='clientsCadastrationBody'>
                            <form onSubmit={handleSubmit}>
                                <div className='clientsCadastrationDualMenu'>
                                    <label>
                                        <TextField
                                            label='Nome'
                                            onKeyPress={(e) => { if (e.code === 'Backquote') e.preventDefault() }}
                                            value={titleize(selectedClientName)}
                                            inputProps={{ maxLength: 80 }}
                                            className={errorFields['selectedClientName'] && !selectedClientName.trim().length ? classes.rootWithError : classes.styleForClients}
                                            onChange={(e) => { setSelectedClientName(e.target.value) }}
                                            variant="outlined" />

                                    </label>
                                    <label>
                                        <TextField
                                            label='Email'
                                            onKeyPress={(e) => { if (e.code === 'Backquote') e.preventDefault() }}
                                            value={selectedClientEmail}
                                            inputProps={{ maxLength: 100 }}
                                            className={errorFields['selectedClientEmail'] && !selectedClientEmail.trim().length ? classes.rootWithError : classes.styleForClients}
                                            onChange={(e) => { setSelectedClientEmail(e.target.value) }}
                                            variant="outlined" />
                                    </label>
                                    <label>
                                        <TextField
                                            label='Código do Cliente'
                                            inputProps={{ maxLength: 100 }}
                                            type='number'
                                            onKeyPress={(e) => { if (e.key === 'e' || e.key === 'E' || e.code === 'Backquote') { e.preventDefault() } }}
                                            value={selectedClientCode}
                                            className={errorFields['selectedClientCode'] && !selectedClientCode.trim().length ? classes.rootWithError : classes.styleForClients}
                                            onChange={(e) => { if (e.target.value.length < 20) { setSelectedClientCode(e.target.value) } }}
                                            variant="outlined" />
                                    </label>
                                </div>
                                <div className='clientsCadastrationDualMenu'>
                                    <label>
                                        <Autocomplete
                                            options={[{ id: 'Sim', value: 'Sim' }, { id: 'Não', value: 'Não' }]}
                                            onChange={(event, newValue) => {
                                                setSelectedClientOperatesAlone(newValue);
                                            }}
                                            getOptionLabel={(option) => option?.value}
                                            getOptionSelected={(option) => option?.value}
                                            id='operatesAlone'
                                            renderInput={(params) => <TextField
                                                label='Opera sozinho'
                                                onKeyPress={(e) => { if (e.code === 'Backquote') e.preventDefault() }}
                                                className={errorFields['selectedClientOperatesAlone'] ? classes.rootWithError : classes.styleForClients}

                                                {...params} variant="outlined" />}
                                        />
                                    </label>
                                    <label>
                                        <TextField
                                            label='Perfil do Cliente'
                                            onKeyPress={(e) => { if (e.code === 'Backquote') e.preventDefault() }}
                                            value={titleize(selectedClientProfile)}
                                            inputProps={{ maxLength: 100 }}
                                            className={errorFields['selectedClientProfile'] && !selectedClientProfile?.trim()?.length ? classes.rootWithError : classes.styleForClients}
                                            onChange={(e) => { setSelectedClientProfile(e.target.value) }}
                                            variant="outlined" />
                                    </label>
                                    <label>
                                        <Autocomplete
                                            options={clientsStatusList}
                                            onChange={(event, newValue) => {
                                                setSelectedClientStatus(newValue);
                                            }}
                                            getOptionLabel={(option) => option?.id + ' - ' + titleize(option?.description)}
                                            getOptionSelected={(option) => option?.description}
                                            id='statusClient'
                                            renderInput={(params) => <TextField
                                                label='Status'
                                                onKeyPress={(e) => { if (e.code === 'Backquote') e.preventDefault() }}
                                                className={errorFields['selectedClientOperatesAlone'] ? classes.rootWithError : classes.styleForClients}

                                                {...params} variant="outlined" />}
                                        />
                                    </label>

                                </div>
                                <div className='clientsCadastrationDualMenu'>
                                    <label>
                                        <Autocomplete
                                            options={advisorList}
                                            onChange={(event, newValue) => {
                                                setSelectedAdvisor(newValue);
                                            }}
                                            value={selectedAdvisor !== '' ? selectedAdvisor : { full_name: '', external_id: '' }}
                                            getOptionLabel={(option) => option?.external_id + ' - ' + titleize(option?.full_name)}
                                            id='Assessor'
                                            renderInput={(params) => <TextField
                                                label='Assessor'
                                                onKeyPress={(e) => { if (e.code === 'Backquote') e.preventDefault() }}
                                                className={errorFields['selectedAdvisor'] ? classes.rootWithError : classes.styleForClients}
                                                {...params} variant="outlined" />}
                                        />
                                    </label>
                                    <label>
                                        <Autocomplete
                                            options={brokerList}
                                            onChange={(event, newValue) => {
                                                setSelectedBroker(newValue);
                                            }}
                                            value={selectedBroker !== '' ? selectedBroker : { full_name: '', external_id: '' }}
                                            getOptionLabel={(x) => x.external_id + ' - ' + titleize(x.full_name)}
                                            id='Broker'
                                            renderInput={(params) => <TextField
                                                label='Broker'
                                                onKeyPress={(e) => { if (e.code === 'Backquote') e.preventDefault() }}
                                                className={errorFields['selectedBroker'] ? classes.rootWithError : classes.styleForClients}
                                                {...params} variant="outlined" />}
                                        />
                                    </label>
                                </div>
                                <div className='clientsCadastrationDualMenu'>
                                    <label>
                                        <CurrencyTextField
                                            label="Net Total"
                                            variant="outlined"
                                            value={selectedClientNetTotal !== '' ? selectedClientNetTotal : ''}
                                            currencySymbol="R$"
                                            className={classes.styleForClients}
                                            outputFormat="number"
                                            decimalCharacter=","
                                            digitGroupSeparator="."
                                            onChange={(event, value) => setSelectedClientNetTotal(value)}
                                        />
                                    </label>
                                    <label>
                                        <CurrencyTextField
                                            label="Net Modalidades"
                                            variant="outlined"
                                            value={selectedClientNetStocks !== '' ? selectedClientNetStocks : ''}
                                            currencySymbol="R$"
                                            className={classes.styleForClients}
                                            outputFormat="number"
                                            decimalCharacter=","
                                            digitGroupSeparator="."
                                            onChange={(event, value) => setSelectedClientNetStocks(value)}
                                        />
                                    </label>
                                </div>
                                <button className='buttonWhiteBg' type='submit' onClick={handleSubmit}>Cadastrar</button>
                            </form>
                        </div>
                    </>)}
        </div>
    )
}