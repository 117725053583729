/*Tela de empresas
/*Centraliza os componentes de empresas
/*Desenvolvido por: Equipe Rafael Cezário
*/

import React from 'react';
//styles
import './styles/branches.css';
//hooks
import { useModal } from '../../../../contexts/useModal';
//components
import { BranchesTable } from './branches.table'
import { BranchesCadastration } from './branches.cadastration';
import { BrancheEditBox } from './brancheEditBox';
import { ConfirmationBox } from '../../../../components/confirmationBox/confirmationBox';
import Modal from '../../../../components/modal/modal';

export function Companies() {

    const { type, show } = useModal();

    return (
        <div className='companiesContainer' style={{minWidth: !show ? '1000px' : '0'}}>
            <Modal>
                {

                    type.name === 'alertMsg' ?
                        (
                            <ConfirmationBox msgData={type} />
                        ) :
                        type.name === 'companiesCadastration' ?
                            (
                                <div className='companiesModal'>
                                    <BranchesCadastration />
                                </div>
                            ) : type.name === 'editBranche' ? (<BrancheEditBox msgData={type} />) : (<></>)
                }

            </Modal>
            <div className='companiesTable'>
                <BranchesTable />
            </div>
        </div>
    )
}