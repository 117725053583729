import React, { useState, useEffect } from 'react'
import Chart from "react-apexcharts";

export function GraphCubastarAlocation(data){
  const secoes = [];

  data.allocation.forEach(x => {
    // Categoria Liquidez precisa ter desconto para o gráfico, por conta de Planejamento Patrimonial.
    var nomeSecao = x.category;
    var somaSecao = x.allocation;
    secoes.push({nomeSecao, somaSecao});
  });

  if(data?.allocation[0]?.category === '1 - Liquidez' && data?.allocation[0]?.allocation > 0){
    secoes.splice(0, 0, {nomeSecao: '0 - Planejamento Patrimonial', somaSecao: 1});
    secoes[1].somaSecao = secoes[1].somaSecao - 1;
  } else {
    secoes.splice(0, 0, {nomeSecao: '0 - Planejamento Patrimonial', somaSecao: 0});
  }

  const secoesGraph = [];

  for (let index = 0; index < secoes.length; index++) {
    secoesGraph.push(secoes[index].nomeSecao);
  }

  const secoesData = [];

  for (let index = 0; index < secoes.length; index++) {
    secoesData.push(secoes[index].somaSecao.toFixed(2));
  }

  const state = {
    options: {
      chart: {
        type: "bar",
        // width: "100%",
        background: '#fff',
        toolbar: {
            show: false
        },
    },
    fill: {
      colors: '#09033B'
    },
    plotOptions: {
      bar: {
          barHeight: '90%',
          dataLabels: {
              position: 'center',
              total: {
                enabled: true,
                style: {
                  fontSize: '14px',
                  fontWeight: 900,
                  fontFamily: 'Roboto'
                }
              },
          },
      borderRadius: 4,
      horizontal: true,
      },
    },
    dataLabels: {
        enabled: true,
        textAnchor: 'start',
        style: {
          colors: ['#c49e17']
        },
        offsetX: 30,
        // dropShadow: {
        //   enabled: true
        // },
        formatter: function(value) {
          return value.toFixed(2) + ('%')
        },
    },
    grid: {
        show: false
    },
    tooltip: {
        enabled: false
    },
    xaxis: {
        show: true,
        categories: secoesGraph,
        labels: {
            show: false
          },
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          }
    },
    yaxis: {
      show: true,
      width: 600,
      trim: false,
      labels: {
        style: {
          fontSize: '12px',
          fontWeight: 900,
          fontFamily: 'Roboto',
          color: '#333'
        },
        offsetY: 1
      }
    }
    },
    legend: true,
    series: [
      {
        data: secoesData
      }
    ],
  };
  return (
    <>    
        <div className="app">
          <div className="row">
            <div className='cubastar-table-label'>Alocação Sugerida</div>
              <Chart
                options={state.options}
                series={state.series}
                type="bar"
                width="100%"
              />
            </div>
          </div>
          </>
  );
}